import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import FilterSidebar from '../../ActionOriented/FilterSidebar';
import DevicesList from '../../DeviceSpecific/DevicesList';
import Loading from '../../DisplayOriented/Loading';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';

//icons
import FilterListIcon from '@material-ui/icons/FilterList';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

//services
import EscrowDevice from '../../../services/DataModels/EscrowDevice';
import Device from '../../../services/DataModels/Device';
import { AssignNestedModels, GetAll } from '../../../services/CLURDUtilities';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Auth from '../../../services/Auth';

class DevicesTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.defaultEscrowTranferStatusValue = "not_initiated";
		this.state = {
			show_filters: false,
			items: null,
			selections: [],
			all_selected: false,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null,
			},
			filter_data: null,
			sort: null,
			showing_escrow: false
		};
		this.filter_key = 0;
		this.set_filters();
		this.load_types().then(this.load);
		this.props.tabHostProxy.setRootRefresh(this.load);
	}

	capitalize = (word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}

	set_filters = () => {
		this.filter_key = this.filter_key + 1;
		this.filters = [];
		if (this.state.is_escrow) {
			this.filters = [
				{
					label: 'Unique ID',
					type: 'text',
					placeholder: "Filter by escrow device unique id",
					field: "unique_id"
				},
				{
					label: 'Token',
					type: 'text',
					placeholder: "Filter by escrow device token",
					field: "token"
				},
				{
					label: 'Transfer Status',
					type: 'select',
					options: [
						{ display: "Not Yet Initiated", value: 'not_initiated' },
						{ display: "All", value: '' },
						{ display: "Transfer Initiated", value: 'transfer_initiated' },
						{ display: "Transfer Completed", value: 'transfer_completed' }
					],
					value: this.defaultEscrowTranferStatusValue,
					field: 'transfer_completed_at'
				}
			];
		} else {
			this.filters = [
				{
					label: 'Device Type',
					type: 'lookup',
					placeholder: "Filter by device type",
					field: "device_type_id",
					model: "device_types",
					valueField: "_id",
					labelField: "name"
				},
				{
					label: 'Tags',
					type: 'tags',
					placeholder: "Filter by device tags",
				},
				{
					label: 'Device Name',
					type: 'text',
					placeholder: "Filter by device name",
					field: "name",
				},
				{
					label: 'Unique ID',
					type: 'text',
					placeholder: "Filter by device unique id",
					field: "unique_id"
				},
				{
					label: 'Device Category',
					type: 'select',
					options: [
						{ display: "All", value: "" },
						{ display: this.capitalize(Auth.currentCompany().aliases.gateway), value: 'gateway' },
						{ display: "Endpoint", value: "endpoint" },
						{ display: "Cloud Native", value: "cloud_native" },
					],
					value: '',
					field: 'category'
				},
				{
					label: 'Online Status',
					type: 'select',
					options: [
						{ display: "All", value: '' },
						{ display: "Never Reported", value: "never_reported" },
						{ display: "Online", value: "online" },
						{ display: "Offline", value: "offline" },
						{ display: "Idle", value: "idle" }
					],
					value: '',
					field: 'status'
				},
				{
					label: 'Health Status',
					type: 'select',
					options: [
						{ display: "All", value: '' },
						{ display: "No Status", value: 'none' },
						{ display: "Normal", value: "normal" },
						{ display: "Needs Attention", value: "needs_attention" },
						{ display: "Critical", value: "critical" }
					],
					value: '',
					field: 'health_status'
				},
				{
					label: 'Activation Status',
					type: 'select',
					options: [
						{ display: "All", value: '' },
						{ display: "Activated", value: 'activated' },
						{ display: "Deactivated", value: "deactivated" },
						{ display: "Not Yet Activated", value: 'not_activated' }
					],
					value: '',
					field: 'activated_at'
				},
				{
					label: 'Location',
					type: 'text',
					placeholder: "Filter by device location",
					field: "location"
				},
				{
					label: 'Metadata',
					type: 'metadata',
				},
			];
		}
		if (Auth.currentUser().company_ids.length > 1) {
			this.filters.push({
				label: 'Account',
				type: 'lookup',
				placeholder: "Filter by account",
				field: "company_id",
				model: "companies",
				valueField: "_id",
				labelField: "name"
			});
		}
	}

	load_types = () => {
		return new Promise((resolve, reject) => {
			GetAll("device_types")
				.then((types) => {
					this.gateways = types.reduce((total, current) => {
						if (current.type === "gateway" && total === "") {
							return current._id;
						} else if (current.type === "gateway") {
							return total + "," + current._id;
						} else {
							return total;
						}
					}, "");
					this.endpoints = types.reduce((total, current) => {
						if ((current.type != "gateway" && current.type != "cloud_native") && total === "") {
							return current._id;
						} else if (current.type != "gateway" && current.type != "cloud_native") {
							return total + "," + current._id;
						} else {
							return total;
						}
					}, "");
					this.cloud_native_device_type_ids = types.reduce((total, current) => {
						if (current.type === "cloud_native" && total === "") {
							return current._id;
						} else if (current.type === "cloud_native") {
							return total + "," + current._id;
						} else {
							return total;
						}
					}, "");
					resolve();
				})
				.catch((error) => {
					this.context.openSnackbar(error, "error");
					reject();
				});
		});
	}

	table_select = id => event => {
		this.handleBulkSelection(id);
	}

	prepare_table_fields = (device) => {
		const { classes } = this.props;
		let name = device.name;
		device.select = () => (
			<Checkbox
				onChange={this.table_select(device._id)}
				value="selected"
				color="primary"
				key={device._id}
				checked={(this.state.all_selected && this.state.selections.indexOf(device._id) === -1) || (!this.state.all_selected && this.state.selections.indexOf(device._id) > -1)}
			/>
		);
		if (!device.nested_device_type || !device.nested_device_type._id) {
			device.table_name = () => (<div style={{ color: "grey" }}>{name} <span style={{ color: "red" }}><ErrorIcon style={{ fontSize: "12px", marginRight: "4px" }} />Device Type Unavailable</span></div>);
		} else {
			device.table_name = () => (<div onClick={() => this.props.tabHostProxy.addTab("device", device)} className={classes.link}>{name}</div>);
		}
		device.table_heartbeat = () => (this.render_table_heartbeat(device));
		device.table_health = () => (this.render_table_health(device));
		device.table_active = () => (this.render_table_active(device));
		return device;
	}

	render_table_heartbeat = (device) => {
		const hb_map = {
			online: this.props.theme.palette.green.main,
			offline: this.props.theme.palette.heartbeat.offline.main,
			idle: this.props.theme.palette.heartbeat.idle.main,
			never_reported: "grey"
		};
		const color = hb_map[device.heartbeat_status];
		const display = device.heartbeat_status.replace(/_/g, " ");
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color }}>
					&nbsp;
				</div>
				<div style={{ textTransform: "capitalize" }}>
					{display}
				</div>
			</div>
		);
	}

	render_table_health = (device) => {
		const green = this.props.theme.palette.green.main;
		const yellow = this.props.theme.palette.caution.main;
		const red = this.props.theme.palette.red.main;
		const grey = this.props.theme.palette.grey.main;
		const healthStatusDisplayMap = {
			"normal": { color: green, label: "Normal" },
			"needs_attention": { color: yellow, label: "Needs Attention" },
			"critical": { color: red, label: "Critical" },
			"": { color: grey, label: "No Status" },
		};
		let healthStatusDisplay = healthStatusDisplayMap[device.health_status];
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: healthStatusDisplay.color }}>
					&nbsp;
				</div>
				<div style={{ textTransform: "capitalize" }}>
					{healthStatusDisplay.label}
				</div>
			</div>
		);
	}

	render_table_active = (device) => {
		const green = this.props.theme.palette.green.main;
		const yellow = this.props.theme.palette.caution.main;
		const red = this.props.theme.palette.red.main;
		const grey = this.props.theme.palette.grey.main;
		const activeStatusDisplayMap = {
			"active": { color: green, label: "Active" },
			"inactive": { color: red, label: "Inactive" },
			"": { color: grey, label: "N/A" },
		};
		let activeStatus = "";
		if (device.nested_device_type.type === "cloud_native") {
			activeStatus = "inactive";
			if (device.activated_at != null) {
				activeStatus = "active";
			}
		}
		let activeStatusDisplay = activeStatusDisplayMap[activeStatus];
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: activeStatusDisplay.color }}>
					&nbsp;
				</div>
				<div style={{ textTransform: "capitalize" }}>
					{activeStatusDisplay.label}
				</div>
			</div>
		);
	}

	load = () => {
		if (this.state.items !== null) this.setState({ items: null });
		this.state.filter_data = null;
		this.set_filters();
		if (this.state.is_escrow) {
			this.apply_filters({ "Transfer Status": "not_initiated" });
		} else {
			this.load_devices();
		}
	}

	load_devices = () => {
		if (this.state.is_escrow) {
			let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
			delete params.total;
			if (this.state.items !== null) this.setState({ items: null });
			new EscrowDevice().listFromAPI(params).then((result) => {
				let escrow_devices = result.items;
				const total = result.total;
				EscrowDevice.loadRequirements(escrow_devices).then(() => {
					this.setState((state) => {
						state.items = escrow_devices;
						state.page_data = {
							page: params.page,
							per_page: params.per_page,
							page_meta: true,
							total: total
						};
						return state;
					});
				}).catch((error) => {
					this.context.openSnackbar(error, "error");
				});
			}).catch((error) => {
				this.context.openSnackbar(error, "error");
			});
		} else {
			let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
			delete params.total;
			if (this.state.items !== null) this.setState({ items: null });
			new Device().listFromAPI(params).then((results) => {
				let devices = results.items;
				let promises = Promise.all([
					AssignNestedModels("integrations", "device_integration_id", devices),
					AssignNestedModels("device_ha_groups", "device_ha_group_id", devices),
					AssignNestedModels("companies", "company_id", devices),
					AssignNestedModels("device_types", "device_type_id", devices),
					Device.AssignRecentErrors(devices),
					Device.AssignRecentAlerts(devices)
				]);
				promises.then(() => {
					devices.forEach((device) => {
						device = this.prepare_table_fields(device);
					});
					this.setState((state) => {
						state.items = devices;
						state.page_data = {
							page: params.page,
							per_page: params.per_page,
							page_meta: true,
							total: results.total,
						}
						return state;
					});
				});
			}).catch((error) => {
				this.context.openSnackbar(error, "error");
			});
		}
	}

	prepare_filter(params) {
		let body = {};
		if (!params) {
			params = {};
		}

		if (this.state.is_escrow) {
			if (params["Unique ID"]) {
				body.unique_id_like = params["Unique ID"];
			}
			if (params["Token"]) {
				body.token_like = params["Token"];
			}
			if (params["Transfer Status"] && params["Transfer Status"] !== '') {
				if (params["Transfer Status"] === 'not_initiated') {
					body.transfer_initiated_at = "null";
				} else if (params["Transfer Status"] === 'transfer_initiated') {
					body.transfer_initiated_at_ne = "null";
					body.transfer_completed_at = "null";
				} else if (params["Transfer Status"] === 'transfer_completed') {
					body.transfer_completed_at_ne = "null";
				}
			}
		} else {
			if (params["Tags"] && params["Tags"].values && params["Tags"].values.length > 0) {
				body.tags_incall = params["Tags"].values.map((tag) => (tag.value));
			}
			if (params["Device Name"]) {
				body.name_like = params["Device Name"];
			}
			if (params["Unique ID"]) {
				body.unique_id_like = params["Unique ID"];
			}
			if (params["Online Status"] && params["Online Status"] !== '') {
				body.heartbeat_status = params["Online Status"];
			}
			if (params["Health Status"] && params["Health Status"] !== '') {
				if (params["Health Status"] === 'none') {
					body.health_status = null;
				} else {
					body.health_status = params["Health Status"];
				}
			}
			if (params["Activation Status"] && params["Activation Status"] !== '') {
				if (params["Activation Status"] === 'activated') {
					delete body.deactivated_at;
					body.activated_at_ne = "null";
				} else if (params["Activation Status"] === 'not_activated') {
					delete body.deactivated_at;
					body.activated_at = "null";
					body.deactivated_at = "null";
				} else if (params["Activation Status"] === 'deactivated') {
					delete body.activated_at;
					body.deactivated_at_ne = "null";
				}
			}
			if (params["Location"]) {
				body.location = params["Location"];
			}
			if (params["Device Type"] && params["Device Type"].values && params["Device Type"].values.length > 0) {
				var deviceTypeIDs = params["Device Type"].values.map((option) => {
					return option.value;
				});
				body.device_type_id_in = deviceTypeIDs.join(",");
			}
			if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
				var companyIDs = params["Account"].values.map((option) => {
					return option.value;
				});
				body.company_id_in = companyIDs.join(",");
			}
			if (params["Device Category"] && params["Device Category"] !== "") {
				if (params["Device Category"] === "gateway") {
					body.device_type_id_in = body.device_type_id_in ? body.device_type_id_in + "," + this.gateways : this.gateways;
				} else if (params["Device Category"] === "endpoint") {
					body.device_type_id_in = body.device_type_id_in ? body.device_type_id_in + "," + this.endpoints : this.endpoints;
				} else if (params["Device Category"] === "cloud_native") {
					body.device_type_id_in = body.device_type_id_in ? body.device_type_id_in + "," + this.cloud_native_device_type_ids : this.cloud_native_device_type_ids;
				}
			}
			if (params["Metadata"] && Array.isArray(params["Metadata"]) && params["Metadata"].length > 0) {
				params["Metadata"].forEach(({ key, value }, index) => {
					if (params["Metadata"][index].key !== "" && params["Metadata"][index].value !== "") {
						body[`metadata.${params["Metadata"][index].key}`] = params["Metadata"][index].value;
					}
				});
			}
		}
		return body;
	}

	apply_filters = (filters) => {
		this.setState({ selections: [], filter_data: this.prepare_filter(filters) }, () => {
			this.load_devices();
		});
	}

	page_change = (params) => {
		this.setState((prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_devices();
		});
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: "" };
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({ sort: sort }, () => {
			this.load_devices();
		});
	}

	handleBulkSelection = (id, unchecked, table) => {
		if (table) {
			this.setState({
				selections: id,
			});
		} else {
			let index = this.state.selections.indexOf(id);
			let new_sel = this.state.selections;
			if (this.state.all_selected === true) {
				if (unchecked) {
					new_sel.splice(index, 1);
				} else {
					new_sel.push(id);
				}
			} else {
				if (index === -1) {
					new_sel.push(id);
				} else if (index > -1) {
					new_sel.splice(index, 1);
				}
			}
			this.setState({
				selections: new_sel
			});
		}
	};

	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}

	clear_selections = () => {
		this.setState({ all_selected: false, selections: [] });
	}

	all_selected_toggle = () => {
		this.setState({
			all_selected: true,
			selections: []
		});
	}

	model_change = (new_tab) => {
		this.setState((state) => {
			state.items = null;
			state.is_escrow = new_tab.label === "Devices" ? false : true;
			state.selections = [];
			state.all_selected = false;
			state.bulk_configure_shown = false;
			state.sort = null;
			state.page_data = null;
			state.page_data = {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null,
			};
			return state;
		}, this.load);
	}

	render() {
		const { tabHostProxy, classes } = this.props;
		const { items, selections, all_selected, page_data, filter_data, is_escrow } = this.state;
		return (
			<div className={classes.container}>
				<div style={{ visibility: this.state.show_filters ? "visible" : "hidden" }} onClick={() => this.setState({ show_filters: false })} className={classes.filterBg}>
					<Card
						onClick={(event) => event.stopPropagation()}
						className={classes.filterContainer}
					>
						<FilterSidebar
							filters={this.filters}
							triggerFilter={this.apply_filters}
							key={this.filter_key}
							closeFilters={() => this.setState({ show_filters: false })}
						/>
					</Card>
				</div>
				<DevicesList
					filtering={this.is_filtering()}
					show_filters={() => this.setState({ show_filters: true })}
					all_selected={all_selected}
					all_selected_toggle={this.all_selected_toggle}
					selection_change={this.handleBulkSelection}
					clear_selections={this.clear_selections}
					page_change={this.page_change}
					items={items}
					filter_data={filter_data}
					selections={selections}
					page_data={page_data}
					perform_sort={this.perform_sort}
					tabHostProxy={tabHostProxy}
					isEscrow={is_escrow}
					modelChange={this.model_change}
				/>
			</div>
		);
	}
}

const styles = theme => ({
	container: {
		display: "flex",
		width: "100%",
		position: "relative",
	},
	filterToggleWrapper: {
		position: "absolute",
		top: "62px",
	},
	link: {
		color: theme.palette.pending.main,
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": theme.palette.pending.main,
			textDecorationColor: theme.palette.pending.main,
			cursor: "pointer",
		}
	},
	filterBg: {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		zIndex: 9999
	},
	filterContainer: {
		position: "relative",
		zIndex: 3,
		width: "800px",
		backgroundColor: "white",
		maxHeight: "750px",
		boxSizing: "border-box",
		padding: "72px 48px 35px 48px",
	}
});

DevicesTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DevicesTab));
