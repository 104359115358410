import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import TableList from '../../Table/TableList';

//inputs
import DeviceSelectionInput from '../../Inputs/DeviceSelectionInput';

//services
import { GetAll } from '../../../services/CLURDUtilities';
import Auth from '../../../services/Auth';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Device from '../../../services/DataModels/Device';

class CDStep4 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			attachable_types: null,
			full_types: null,
			device_selection: {
				status: "normal",
				devices: [],
				device_ids: [],
				devices_prepped: false
			},
			updated_device: {
				prepped_devices: []
			},
			preset_parent: null,
			preset_endpoints: null
		};
		if (this.props.preset) {
			if (this.props.preset.parent_device_id) {
				this.load_preset_parent();
			} else if (this.props.preset.attached_device_ids) {
				this.load_preset_endpoints();
			}
		}
		this.company_id = this.props.preset ? this.props.preset.company_id : this.props.device.company_id;
		this.gateway_alias = Auth.currentCompany().aliases.gateway;
		this.load_types();
		this.heading_info = [
			{label: "Name", value: "table_name", nested_field: false, field: "table_name", align: "left", disablePadding: false, sortable: false},
			{label: "Unique ID", value: "unique_id", field: "unique_id", nested_field: false, align: "left", disablePadding: false, sortable: false},
			{label: "Account", value: "nested_company.name", nested_field: true, field: "company_name", align: "left", disablePadding: false, sortable: false},
			{label: "Tags", value: "table_tags", field: "table_tags", nested_field: false, align: "left", disablePadding: false, sortable: false},
		];
	}

	load_preset_parent = () => {
		if (!this.props.device.nested_company) return;
		const _id = this.props.preset.parent_device_id;
		new Device({_id: _id}).readFromAPI().then( (device) => {
			let devices = [device];
			this.assign_table_fields(devices);
			devices.forEach( (device) => {
				device.nested_company = this.props.device.nested_company;
			});
			this.setState( (state) => {
				state.preset_parent = devices;
				state.updated_device.prepped_devices = devices;
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.props.closeModal();
		});
	}

	load_preset_endpoints = () => {
		const params = { _id_in: this.props.preset.attached_device_ids };
		new Device().listFromAPI(params).then( (devices) => {
			this.assign_table_fields(devices);
			devices.forEach( (device) => {
				device.nested_company = this.props.device.nested_company;
			});
			this.setState( (state) => {
				state.preset_endpoints = devices;
				state.updated_device.prepped_devices = devices;
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.props.closeModal();
		});
	}

	load_types = () => {
		if (!this.company_id || this.company_id == '') return;
		let params = { company_id: this.company_id };
		if (this.props.device.is_gateway) {
			params.type_ne = "gateway";
		} else {
			params.type = "gateway";
		}
		GetAll("device_types", params).then( (types) => {
			const attachable_types = types.map( ({_id}) => _id).join(",");
			this.setState({attachable_types: attachable_types, full_types: types});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_devices = (params, resolve, reject) => {
		GetAll("devices", params).then( (devices) => {
			devices.forEach( (device) => {
				device.nested_device_type = this.state.full_types.find( ({_id}) => _id === device.device_type_id);
				device.nested_company = this.props.device.nested_company;
			});
			this.assign_table_fields(devices);
			resolve(devices);
		}).catch( (error) => {
			reject(error);
		});
	}

	assign_table_fields = (devices) => {
		const {theme, classes } = this.props;
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		if (!devices || devices.length === 0) return [];
		devices.forEach( (device) => {
			device.table_tags = device.tags && device.tags.length > 0 ? device.tags.join(", ") : "No Tags";
			device.table_name = (
				<div className={classes.nameContainer}>
					<div style={{backgroundColor: status_map[device.heartbeat_status]}} className={classes.statusDot}>
						&nbsp;
					</div>
					<span className={classes.name}>{device.name}</span>
				</div>
			);
		});
	}

	device_selection_change = (device_ids, devices, all_selected, formerly_all_selected, filters) => {
		let prepare = null;
		if (all_selected || formerly_all_selected) {
			prepare = new Promise( (resolve, reject) => {
				let params = {};
				if (all_selected) {
					params = Object.entries(filters).reduce( (map, [key, value]) => {
						if (value != null && value !== '') {
							if (key == "tags_incall") {
								map[key] = value.map( ({value}) => value);
							} else if (key !== "company_id_in") {
								map[key] = value;
							}
						}
						return map;
					}, params);
				} else if (formerly_all_selected) {
					params = Object.entries(filters).reduce( (map, [key, value]) => {
						if (value != null && value !== '') {
							if (key == "tags_incall") {
								map[key] = value.map( ({value}) => value);
							} else if (key !== "company_id_in") {
								map[key] = value;
							}
						}
						return map;
					}, params);
					params._id_nin = device_ids;
				}
				params.device_type_id_in = this.state.attachable_types;
				params.company_id = this.company_id;
				this.load_devices(params, resolve, reject);
			});
		} else {
			prepare = new Promise( (resolve) => {
				devices.forEach( (device) => {
					device.nested_device_type = this.state.full_types.find( ({_id}) => _id === device.device_type_id);
					device.nested_company = this.props.device.nested_company;
				});
				this.assign_table_fields(devices);
				resolve(devices);
			});
		}
		prepare.then( (devices) => {
			this.setState( (state) => {
				let devices_selected = all_selected || formerly_all_selected || devices.length > 0;
				state.device_selection = {
					status: all_selected === true ? "all_selected" : formerly_all_selected  === true ? "formerly_all_selected" : "normal",
					device_ids: device_ids,
					devices: devices,
					devices_prepped: all_selected === true || formerly_all_selected  === true ? false : true,
					filters: filters
				};
				state.updated_device.prepped_devices = devices;
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_relationship = () => {
		if (this.props.preset) {
			if (this.props.preset.attached_device_ids) {
				return this.render_preset_relations(this.state.preset_endpoints);
			} else if (this.props.preset.parent_device_id) {
				return this.render_preset_relations(this.state.preset_parent);
			}
		}
		const is_gateway = this.props.device.is_gateway;
		if (!this.company_id || this.company_id == '') return;
		const types = this.state.attachable_types;
		let params = {company_id: this.company_id, device_type_id_in: types};
		if (is_gateway) {
			params.parent_device_id = "null";
		}
		return (
			<DeviceSelectionInput single={!is_gateway} onChange={this.device_selection_change} params={params}/>
		);
	}

	render_preset_relations = (relations) => {
		const { classes } = this.props;
		return (
			<TableList
				headings={this.heading_info} 
				items={relations}
			/>
		);
	}

	render_description = () => {
		const { classes, device } = this.props;
		let description = "";
		if (device.is_gateway) {
			description = `Since your device is a ${this.gateway_alias} you can find and select endpoint devices to attach to it. If you don't see an endpoint device you're looking for, it either belongs to a different account or already is attached to a ${this.gateway_alias} device.`;
		} else {
			description = `The device you're creating is an endpoint device. This means that you can find and select a ${this.gateway_alias} for it to be attached to. You can also skip this step and attach your endpoint device later.`;
		}
		return (
			<div className={classes.description}>
				{description}
			</div>
		);
	}

	render() {
		const { renderButtons, renderTitle, preset, classes } = this.props;
		const { updated_device, attachable_types, full_types, preset_parent, preset_endpoints } = this.state;
		const loading_parent = ( preset && preset.parent_device_id ) && preset_parent === null;
		const loading_endpoints = ( preset && preset.attached_device_ids ) && preset_endpoints === null;
		if (attachable_types == null || full_types === null || loading_parent || loading_endpoints) return <Loading />;
		return (
			<React.Fragment>
				<div>
					{renderTitle()}
					<div className={classes.inputWrapper}>
						{this.render_description()}
						{this.render_relationship()}
					</div>
				</div>
				{updated_device.prepped_devices ? renderButtons(() => true, updated_device) : <Loading/>}
			</React.Fragment>
		);
	}
}

const styles = (theme) => {
	return ({
		inputWrapper: {
			height: "449px",
			overflowY: "auto",
			paddingRight: "16px",
		},
		description: {
			marginBottom: "16px",
			color: "#8e8e93",
			fontSize: "14px",
			lineHeight: "22px",
		},
		nameContainer: {
			display: "flex",
			alignItems: "center",
		},
		name: {
			whiteSpace: "nowrap"
		},
		statusDot: {
			width: "8px",
			height: "8px",
			minWidth: "8px",
			minHeight: "8px",
			maxWidth: "8px",
			maxHeight: "8px",
			borderRadius: "50%",
			display: "inline-flex",
			marginRight: "4px"
		},
	});
};

CDStep4.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CDStep4));