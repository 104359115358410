import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { darken } from '@material-ui/core/styles/colorManipulator';
import PaginationContainer from '../Containers/PaginationContainer';
import ContentLoader from "react-content-loader"
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import DeviceTypeForm from './DeviceTypeForm';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import DeviceType from '../../services/DataModels/DeviceType';
import Permissions from '../../services/Permissions';
import Auth from '../../services/Auth';
import { BULK_EDIT } from '../../services/CLURD';
import { GetAll } from '../../services/CLURDUtilities';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

//icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import EventIcon from '@material-ui/icons/Event';
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import MemoryIcon from '@material-ui/icons/Memory';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import HearingIcon from '@material-ui/icons/Hearing';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import UpdateIcon from '@material-ui/icons/Update';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import UsbIcon from '@material-ui/icons/Usb';

class TypesList extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			layout: "card",
			tab_index: 0,
			modal: {
				open: false,
				children: () => ""
			},
			menus: {},
			type_ids: [],
			types: [],
			devices_shown: null
		};
		this.set_headings();
	}

	set_buttons = () => {
		let buttons = [];
		let accounts = Auth.currentUser().company_ids;
		let valid_accounts = accounts.filter( (account) => Permissions.allow(["create"], "device_type", account._id));
		if (valid_accounts.length > 0) {
			buttons.push(
				{onSelectShow: false, display: `Create New Device Type`, label: `Create New Device Type`, icon: (<AddIcon />), action: this.open_new_form, function: this.open_new_form},
			);
		}
		return buttons;
	}

	open_new_form = () => {
		const classes = this.props.classes;
		this.setState({
			modal: {
				open: true,
				children: () =>
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle}>
							Creating a new Device Type
						</div>
						<DeviceTypeForm
							onCreate={this.on_update_create}
							onCancel={this.close_modal}
						/>
					</div>
			}
		});
	}

	on_update_create = () => {
		this.close_modal();
		this.props.tabHostProxy.refresh();
	}

	set_headings = () => {
		this.headings = [
			{label: "Select", field: "select", align: "center", sortable: false},
			{label: "Name", value: "name", field: "name", align: "left", sortable: false},
			{label: "Account", field: "account_name", align: "left", sortable: false},
			//todo
			{label: "Action", field: "action", align: "center", sortable: false},
		];
	}

	handleChangePage = (event, page) => {
		this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
	}

	handleChangeRowsPerPage = event => {
		this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
	}

	open_tab = (type) => {
		this.props.tabHostProxy.addTab("type", type);
	}

	on_create = () => {
		this.close_modal();
		this.props.tabHostProxy.refresh();
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	table_select = (type) => {
		this.setState( (state) => {
			let index = state.type_ids.indexOf(type._id);
			if (index >= 0) {
				state.types.splice(index, 1);
				state.type_ids.splice(index, 1);
			} else {
				state.types.push(type);
				state.type_ids.push(type._id);
			}
			return state;
		});
	}

	prepare_items = () => {
		const { items, model } = this.props;
		if (!items) return;
		return this.prepare_types();
	}

	prepare_types = () => {
		let types = this.props.items;
		const classes = this.props.classes;
		types.forEach( (type) => {
			let inherited = !type.nested_company;
			if (inherited) {
				type.account_name = <span className={classes.inheritedInformation}>Inherited</span>;;
			} else {
				type.account_name = type.nested_company.name;
			}
			type.action = (<span className={classes.tableMenu}>{this.render_menu(type)}</span>);
			let checked = this.state.type_ids.includes(type._id);
			const allowed = Permissions.allow(["delete"], "device_type", type.company_id) && !inherited;
			type.select = (allowed ? <CustomCheckbox
				onChange={() => this.table_select(type)}
				value="selected"
				color="primary"
				key={type._id}
				checked={checked}
			/> : "");
		});
		return types;
	}

	render_select_wrapper = () => {
		const { classes } = this.props;
		return this.render_select();
	}

	render_select = () => {
		const { classes } = this.props;
		const { types, type_ids } = this.state;
		if (!type_ids || type_ids.length === 0) {
			return "";
		}
		let selection_message = type_ids.length + " Type" + (type_ids.length > 1 ? 's' : '') + " Selected.";
		return (
			<div className={classes.selectWrapper}>
				<div className={classes.selectionCount}>
					{selection_message}
					{/* <div onClick={() =>( all_selected === false || (all_selected === true && selections.length > 0)) ? this.selectAllFunction() : ""} className={classes.secondaryButton + " " + ( (all_selected === true && selections.length === 0) ? classes.selection : "")}>
						Select All
					</div>
					<div onClick={() => selections.length > 0 || all_selected === true ? this.clearSelections() : ""} className={classes.secondaryButton + " " + (selections.length === 0 && all_selected === false ? classes.selection : "")}>
						Deselect All
					</div> */}
				</div>
				<div className={classes.selectButtonsWrapper}>
					<div className={classes.bulkButtonsWrapper}>
						{Permissions.allow(["delete"], "device_type") ? <Button
							className={classes.deleteBorderButton}
							variant="outlined"
							color="primary"
							onClick={() => this.delete_types()}
						>
							<DeleteForeverIcon className={classes.bulkIcon}/>
							DELETE
						</Button> : ""}
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { page_data, modelChange, model, classes } = this.props;
		let items = this.prepare_items();
		let selected_shown = this.state.types && this.state.types.length > 0 ? classes.moreSpace : "";
		let buttons = this.set_buttons();
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<PaginationContainer
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={layout =>
						this.setState({ layout: layout })
					}
					toggle_filter={this.props.show_filters}
				/>
				{this.render_select_wrapper()}
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer + " " + selected_shown}>
						{this.render_card_layout(items)}
					</div> :
					<div className={classes.tableItemsContainer + " " + selected_shown}>
						{this.render_table_layout(items)}
					</div>
				}
			</div>
		);
	}

	delete_types = () => {
		const classes = this.props.classes;
		this.setState({
			modal: {
				open: true,
				yesFunction: () => this.perform_deletes(),
				functionText: "Delete Types",
				prompt: "Are you sure you want to delete these types?",
				children: () => {}
			}
		});
	}

	delete = (item) => {
		const classes = this.props.classes;
		let data_model = new DeviceType(item);
		this.setState({
			modal: {
				open: true,
				yesFunction: () => this.perform_delete(data_model),
				functionText: `Delete Device Type`,
				prompt: `Are you sure you want to delete this Device Type?`,
				children: () => {}
			}
		});
	}

	perform_delete = (data_model) => {
		this.setState({
			modal: {
				open: true,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle + " " + classes.titleWithLoading}>
							Deleting this Device Type...
						</div>
						<Loading />
					</div>
				)
			}
		});
		data_model.deleteFromAPI().then( (result) => {
			this.close_modal();
			this.props.tabHostProxy.closeOther(data_model.data._id, true);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar("Device Type deleted successfully.", "success");
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.close_modal();
		});
	}

	perform_deletes = () => {
		this.setState({
			modal: {
				open: true,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle + " " + classes.titleWithLoading}>
							Deleting these Device Types...
						</div>
						<Loading />
					</div>
				)
			}
		});
		let body = { ids: this.state.type_ids };
		BULK_EDIT('device_types', "delete", body, Auth.token()).then( (result) => {
			let message = `The bulk delete process has successfully begun.`;
			this.context.openSnackbar(message, 'success');
			this.props.tabHostProxy.refresh();
			this.clear_selections();
			this.close_modal();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.clear_selections();
			this.close_modal();
		});
	}

	clear_selections = () => {
		this.setState( (state) => {
			state.types = [];
			state.type_ids = [];
			return state;
		});
	}

	render_error = (type) => {
		const classes = this.props.classes;
		if (!type.error) return "";
		return (
			<Tooltip classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{type.error}</span>}>
				<ErrorIcon className={classes.errorIcon}/>
			</Tooltip>
		);
	}

	get_connections = (type) => {
		if (type.capabilities.network_connections && Array.isArray(type.capabilities.network_connections) && type.capabilities.network_connections.length > 0) {
			const count = type.capabilities.network_connections.length;
			return (count + " connection" + ((count === 0 || count > 1) ? "s" : ""));
		} else {
			return "No connections";
		}
	}

	get_ingestors = (type) => {
		if (type.ingestor_ids && Array.isArray(type.ingestor_ids) && type.ingestor_ids.length > 0) {
			const count = type.ingestor_ids.length;
			return (count + " ingestor" + ((count === 0 || count > 1) ? "s" : ""));
		} else {
			return "No ingestors";
		}
	}

	render_card = (type) => {
		const { classes } = this.props;
		let selected = this.state.type_ids.includes(type._id) ? classes.selected : "";
		return (
			<div key={type._id} className={classes.cardContainer}>
				<Card className={classes.card + " " + selected}>
					<div className={classes.optionsIconsContainer}>
						<div className={classes.leftOptionsContainer}>
							<div className={classes.selectContainer}>{type.select}</div>
						</div>
						<div className={classes.rightOptionsContainer}>
							<Tooltip onClick={() => this.open_tab(type)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_edit(type) ? "Edit Device Type" : "Insufficient permissions for editing"}</span>}>
								<span className={classes.optionsIcon + " " + !(Boolean(type.error) ? "" : classes.tipDisabled)}>
									<EditIcon />
								</span>
							</Tooltip>
							<Tooltip onClick={(event) => this.can_delete(type) && this.delete(type)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_delete(type) ? "Delete Device Type" : "Insufficient permissions for deleting"}</span>}>
								<span className={classes.optionsIcon + " " + (this.can_delete(type) ? "" : classes.tipDisabled)}>
									<DeleteIcon />
								</span>
							</Tooltip>
						</div>
					</div>
					<div onClick={() => this.open_tab(type)} className={classes.devicesTypesInfo}>
						<div className={classes.topRow}>
							<span className={classes.deviceName}>
								{type.name}
							</span>
							{this.render_error(type)}
						</div>
						<div>
							<div className={classes.makeModelRow}>Manufacturer:<span className={classes.makeModelValue}>{type.manufacturer}</span></div>
							<div className={classes.makeModelRow}>Model:<span className={classes.makeModelValue}>{type.model}</span></div>
						</div>
						<div className={classes.cardLabelContainer}>
							<div className={classes.cardLabel}>
								<Tooltip title="Account">
									<AccountCircleIcon className={classes.iconLabel}/>
								</Tooltip>
								<span className={classes.cardText}>{type.account_name}</span>
							</div>
						</div>
						<div className={classes.cardLabelContainer}>
							<div className={classes.cardLabel}>
								<Tooltip title="Connections">
									<SettingsEthernetIcon className={classes.iconLabel}/>
								</Tooltip>
								<span className={classes.cardText}>{this.get_connections(type)}</span>
							</div>
						</div>
					</div>
				</Card>
			</div>
		);
	}
	
	render_menu = (item, table) => {
		let anchorEl =  this.state.menus[item._id];
		let open = Boolean(anchorEl);
		let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, item._id)}/> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, item._id)}/>;
		return (
			<React.Fragment>
				{icon}
				{this.render_action_menu(open, anchorEl, item)}
			</React.Fragment>
		);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState( (state) => {
			state.menus[id] = element;
			return state;
		});
	}

	can_edit = (item) => {
		return Permissions.allow(["update"], "device_type", item.company_id);
	}

	can_delete = (item) => {
		let inherited = item.nested_company == null;
		return Permissions.allow(["delete"], "device_type", item.company_id) && !inherited;
	}

	close_action_menu = (id) => {
		this.setState( (state) => {
			state.menus[id] = null;
			return state;
		});
	};

	render_action_menu = (open, anchorEl, item) => {
		const { classes } = this.props;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={() => this.close_action_menu(item._id)}
				onClose={() => this.close_action_menu(item._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit(item) && this.open_tab(item)}>
					<MenuItem disabled={!this.can_edit(item)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<VisibilityIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							View & Manage
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_delete(item) && this.delete(item)}>
					<MenuItem disabled={!this.can_delete(item)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Delete Device Type
						</Typography>
					</MenuItem>
				</div>
			</Menu>
		);
	}

	render_card_layout = (items) => {
		return (
			<React.Fragment>
				{items ? items.map( (item, index) => (
					this.render_card(item)
				)) : this.render_placeholder_cards()}
			</React.Fragment>
		);
	}

	render_placeholder_cards = () => {
		const classes = this.props.classes;
		let placeholders = [0,0,0,0,0,0,0,0,0];
		return (
			<React.Fragment>
				{placeholders.map( (item, index) => (
					<div key={"placeholder_" + index} className={classes.cardContainer}>
						<Card className={classes.card}>
							<div className={classes.contentLoaderContainer}>
								<ContentLoader
									width={200}
									speed={2}
									interval={.25}
									primaryColor="#f3f3f3"
									secondaryColor="#ecebeb"
								>
									<rect x="0" y="6" rx="4" ry="4" width={200} height="18" /> 
									<rect x="12" y="33" rx="4" ry="4" width={200} height="16" /> 
									<rect x="12" y="63" rx="4" ry="4" width={200} height="16" /> 
								</ContentLoader>
							</div>
							<div className={classes.cardIconRow}>
								<Tooltip style={{visibility: "hidden"}} title={<span className={classes.tip}>Issue Update</span>}>
									<span className={classes.cardIconButton}>
										<GetAppIcon />
									</span>
								</Tooltip>
							</div>
						</Card>
					</div>
				))}
			</React.Fragment>
		)
	}

	render_table_layout = (items) => {
		return (
			<TableList
				headings={this.headings} 
				items={items}
				noCheckBox
			/>
		);
	}
}

TypesList.contextType = SnackbarContext;

const CustomCheckbox = withStyles({
	root: {
		padding: 0,
		color: "rgba(0, 0, 0, 0.24)",
		'&$checked': {
			color: "#1153b6",
		},
	},
	checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			flexGrow: 2,
			display: "flex",
			flexWrap: "wrap",
			overflow: "hidden"
		},
		actionListTitle: {
			marginBottom: "8px",
			padding: "11px 16px",
			borderBottom: "solid #80808073 1px",
			backgroundColor: "white",
			cursor: "unset",
			'&:hover': {
				backgroundColor: "white",
				cursor: "unset",
			},
			outline: "none",
			fontFamily: "Inter",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "1rem",
			width: "auto",
			height: "24px",
			whiteSpace: "nowrap",
			boxSizing: "content-box",
			fontWeight: 400,
			lineHeight: "1.5em"
		},
		actionMenuItem: {
			outline: "none",
		},
		noOutline: {
			outline: "none",
			"&:focus": {
				outline: "none"
			}
		},
		modalWrapper: {
			width: "100%",
			height: "100%",
			fontFamily: "Inter",
			boxSizing: "border-box"
		},
		tableMenu: {
			color: "grey",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		modalTitle: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
		},
		tableListWrapper: {
			margin: "24px 0 32px 0"
		},
		titleWithLoading: {
			marginBottom: "12px"
		},
		noDevices: {
			marginTop: "18px"
		},
		itemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			width: "100%",
			padding: "12px",
			alignItems: "flex-start",
			flexWrap: "wrap",
			backgroundColor: "#f5f5f7",
			boxSizing: "border-box",
		},
		cardItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			width: "100%",
			padding: "12px",
			alignItems: "flex-start",
			flexWrap: "wrap",
			backgroundColor: "#f5f5f7",
			boxSizing: "border-box",
		},
		tableItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			width: "100%",
			alignItems: "flex-start",
			flexWrap: "wrap",
			backgroundColor: "#ffffff",
			boxSizing: "border-box",
		},
		moreSpace: {
			height: "calc(100% - 112px)"
		},
		sideBarContainer: {
			display: "flex",
			width: "15%",
			boxSizing: "border-box",
			marginTop: "-1px",
			backgroundColor: "white",
			zIndex: 5,
			borderRight: "solid lightgrey 2px",
		},
		cardContainer: {
			width: "33.33%",
			display: "inline-flex",
			position: "relative",
			justifyContent: "center",
		},
		selectCardWrapper: {
			marginLeft: "12px"
		},
		placeholder: {
			opacity: .8,
			zIndex: 10,
			position: "absolute"
		},
		card: {
			boxShadow: "0px 3px 7px 2px #00000017",
			backgroundColor: "#fafafa",
			margin: "12px",
			width: "100%",
			minWidth: "250px",
			maxWidth: "380px",
			height: "auto",
			minHeight: "209px",
			border: "#ffffff solid 1px",
			transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
			"&:hover": {
				border: `${theme.palette.primary.main} solid 1px`,
			},
		},
		selected: {
			border: `solid ${theme.palette.pending.main} 1px`,
		},
		checkbox: {
			marginLeft: "auto",
			marginRight: "6px",
		},
		link: {
			color: theme.palette.pending.main,
			cursor: "pointer",
			"&:hover": {
				textDecoration: "underline"
			}
		},
		deviceLink: {
			marginLeft: "4px"
		},
		topRow: {
			display: "flex",
			flexWrap: "nowrap",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom: "16px",
		},
		name: {
			width: "80%",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			fontSize: "18px"
		},
		fileCount: {
			width: "33.33%",
			display: "flex",
			alignItems: "center"
		},
		fileCountText: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},
		iconLabel: {
			color: "#636366",
			marginRight: "8px",
			fontSize: "20px",
		},
		attachmentIcon: {
			marginLeft: "auto"
		},
		contentLoaderContainer: {
			height: "172px"
		},
		cardIconRow: {
			margin: "-12px",
			padding: "12px 0",
			marginTop: "4px",
			display: "flex",
			color: "grey",
			borderTop: "solid lightgrey 1px",
			backgroundColor: "#FAFAFA"
		},
		cardIconButton: {
			width: "25%",
			textAlign: "center",
			borderRight: "solid lightgrey 1px",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		editButtonContainer: {
			width: "50%",
			display: "flex"
		},
		editButton: {
			width: "100%",
			alignItems: "center",
			display: "flex",
			justifyContent: "center"
		},
		updateIconButton: {
			width: "100%",
			justifyContent: "center",
			display: "flex",
			alignItems: "center",
			borderRight: "unset"
		},
		viewDevices: {
			marginLeft: "4px",
		},
		tip: {
			fontSize: "16px",
		},
		cardTextLabel: {
			color: "black"
		},
		tipOverride: {
			padding: "8px",
			boxSizing: "border-box",
			marginTop: "0px",
		},
		tipDisabled: {
			cursor: "not-allowed",
			color: "#bababa",
			"&:hover": {
				color: "#bababa"
			}
		},
		nameContainer: {
			display: "flex",
			alignItems: "center",
		},
		deviceName: {
			width: "80%",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			fontSize: "16px",
			color: "#000000",
			opacity: "0.87",
		},
		statusDot: {
			width: "8px",
			height: "8px",
			minWidth: "8px",
			minHeight: "8px",
			maxWidth: "8px",
			maxHeight: "8px",
			borderRadius: "50%",
			display: "inline-flex",
			marginRight: "4px"
		},
		menuIcon: {
			border: "none",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		cardLabelContainer: {
			marginBottom: "8px",
		},
		cardLabel: {
			display: "flex",
			alignItems: "center",
			color: "#636366",
		},
		cardText: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			fontSize: "14px"
		},
		errorIcon: {
			color: theme.palette.red.main,
		},
		script: {
			backgroundColor: "#0f0f0fd4",
			color: "white",
			padding: "4px",
			borderRadius: "4px",
			fontFamily: "Courier New"
		},
		scriptInModal: {
			margin: "12px",
			overflow: "auto",
			padding: "12px"
		},
		inheritedInformation: {
			fontStyle: "italic"
		},
		notFound: {
			color: theme.palette.red.main
		},
		selectWrapper: {
			display: "flex",
			width: "100%",
			padding: "12px",
			boxSizing: "border-box",
			alignItems: "center",
			backgroundColor: "white",
			position: "relative",
			zIndex: 5,
			marginTop: "-4px",
			borderBottom: "1px solid lightgrey",
		},
		selectionCount: {
			fontSize: "16px",
		},
		selectButtonsWrapper: {
			position: "relative",
			marginLeft: "auto",
			marginRight: 0,
			display: "flex"
		},
		bulkButtonsWrapper: {
			marginRight: "8px"
		},
		deleteBorderButton: {
			marginLeft: "12px",
		},
		issueButton: {
			marginLeft: "12px",
			borderColor: theme.palette.pending.main,
			color: theme.palette.pending.main
		},
		bulkIcon: {
			marginRight: "4px"
		},
		unset: {
			fontStyle: "italic",
			paddingRight: "2px"
		},
		makeModelRow: {
			fontSize: "14px",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
			marginBottom: "8px",
			color: "#636366",
		},
		makeModelValue: {
			color: "#636366",
			marginLeft: "4px",
		},
		optionsIconsContainer: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			width: "100%",
			height: "36px",
			backgroundColor: "#ffffff",
			padding: "8px",
			boxSizing: "border-box",
		},
		leftOptionsContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-start",
			width: "25%",
		},
		rightOptionsContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			width: "75%",
		},
		selectContainer: {
			"& svg":  {
				width: "20px",
				height: "20px",
			},
		},
		optionsIcon: {
			marginLeft: "16px",
			color: "#8e8e93",
			width: "20px",
			height: "20px",
			"& svg": {
				fontSize: "20px",
			},
			cursor: "pointer",
			"&:hover": {
				color: "#0263fc",
			},
			transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
		},
		devicesTypesInfo: {
			position: "relative",
			padding: "16px 24px 24px 24px",
			"&:hover": {
				cursor: "pointer",
			},
		},
	})
};

export default compose(
	withRouter,
	withStyles(styles),
	withTheme(),
)(TypesList);
