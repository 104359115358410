import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

//icons
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';

function getModalStyle() {
	return {
		top: `calc(50vh - 100px)`,
		margin:'auto',
		padding: "24px"
	};
}

const styles = theme => ({
	paper: {
		width: "800px",
		outline: "none",
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		boxShadow: "0px 1px 7px 2px rgba(0, 0, 0, 0.1)",
		borderRadius: "12px",
		boxSizing: "border-box",
		padding: "72px 48px 35px 48px",
	},
	scrollable: {
		maxHeight: "643px",
		width: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	closeModalIcon: {
		position: "absolute",
		top: 0,
		right: 0,
		cursor: "pointer",
		color: "rgba(0, 0, 0, 0.87)",
		margin: "21px"
	},
	buttonOverride: {
		marginLeft: "8px",
	}
});

class SimpleModal extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
	}

	render() {
		const { info: {open, prompt, yesFunction, functionText, submitButtonClass}, classes, closeModal, children } = this.props;
		return (
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={open}
				onClose={closeModal}
				style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius: "18px"}}
			>
				{!yesFunction ?
					<div className={classes.paper}>
						<div className={classes.scrollable}>
							{children}
						</div>
						<ClearIcon onClick={closeModal} className={classes.closeModalIcon}/>
					</div>
				:
					<div className={classes.paper}>
						<div className={classes.prompt}>
							{prompt}
						</div>
						{children}
						<ClearIcon onClick={closeModal} className={classes.closeModalIcon}/>
						<div
							style={{marginTop: "32px", display:'flex',alignItems:'center',justifyContent:'flex-end'}}
						>
							<Button onClick={closeModal} color="primary">
								Cancel
							</Button>
							<Button
								onClick={yesFunction}
								classes={{root: submitButtonClass}}
								className={classes.buttonOverride}
								variant="contained"
								color="primary"
								size="large"
							>
								{functionText}
							</Button>
						</div>
						
					</div>
				}
			</Modal>
		);
	}
}

SimpleModal.propTypes = {
	classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;
