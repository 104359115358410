import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import GatewayCommand from "../../services/DataModels/GatewayCommand";

const styles = {
};

class SoftwareUpdateInfoView extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.recentActivityToLoad = 20;
		this.state = {
			recentGatewayCommands: []
		}
	}

	componentWillMount() {
		const { softwareUpdate } = this.props;
		const { recentGatewayCommands } = this.state;
		if (recentGatewayCommands == null || recentGatewayCommands.length === 0) {
			this.loadGatewayCommands(softwareUpdate);
		}
	}

	loadGatewayCommands(softwareUpdate) {
		let self = this;
		new GatewayCommand().listFromAPI({ per_page: this.recentActivityToLoad, command_type: 'software_update', software_update_id: softwareUpdate._id }).then((results) => {
			self.setState({ recentGatewayCommands: results.items });
		});
	}

	renderRecentGatewayCommands(commands) {
		if (commands == null) {
			return null;
		} else {
			return (
				<React.Fragment>
					<b>Recent Activity (Last {this.recentActivityToLoad})</b>
					{commands.map((cmd) => {
						return (
							<div>
								<div>Gateway Device: {cmd.device_unique_id}</div>
								<div>Last Activity: {cmd.updated_at}</div>
								<b>Device Activity</b>
								{this.renderGatewayCommandStatuses(cmd)}
							</div>
						)
					})}
				</React.Fragment>
			)
		}
	}

	renderGatewayCommandStatuses(command) {
		if (command.statuses == null) {
			return (<i>None</i>);
		} else {
			let statuses = command.statuses;
			let statusKeys = Object.keys(statuses);
			return (
				<div>
					{statusKeys.map((key) => {
						let status = statuses[key];
						return (
							<div>
								<div>Device: {key}</div>
								<div>Most Recent Status: {status.status}</div>
								<div>{ status.message ? <span>Message: {status.message}</span> : "" }</div>
							</div>
						);
					})}
				</div>
			);
		}
	}

	render() {
		const { softwareUpdate, classes, tabHostProxy } = this.props;
		const { recentGatewayCommands } = this.state;
		return(
			<div>
				<div className={classes.container}>
					<div>
						Note that, once created, a software update cannot be modified.
					</div>
					<div>
						Created: {softwareUpdate.created_at}
					</div>
					<div>
						Script Command: <pre>{softwareUpdate.script}</pre>
					</div>
					<div>
						Reboot?: {softwareUpdate.reboot + ""}
					</div>
						Files: {softwareUpdate.files.length === 0 ? "None" : "(" + softwareUpdate.files.length + ")"}
					<ul>
						{softwareUpdate.files.map((file) => {
							return (<li><a href={file.link} target="_blank">{file.name}</a></li>)
						})}
					</ul>
				</div>
				<div>
					{this.renderRecentGatewayCommands(recentGatewayCommands)}
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(SoftwareUpdateInfoView);

