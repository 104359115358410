import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import DeviceHAGroup from "../../../services/DataModels/DeviceHAGroup";
import DeviceType from "../../../services/DataModels/DeviceType";
import Device from "../../../services/DataModels/Device";
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import TableList from '../../Table/TableList';
import Permissions from '../../../services/Permissions';
import { AssignNestedModels } from '../../../services/CLURDUtilities';
import AddHAGroupNode from '../../HAGroupsSpecific/AddHAGroupNode';

//inputs
import TextInput from '../../Inputs/TextInput';
import SelectInput from '../../Inputs/SelectInput';

//mui
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//icon
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import EjectIcon from '@material-ui/icons/Eject';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import RouterIcon from '@material-ui/icons/Router';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

//context
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
    container: {
        fontFamily: "Inter",
        width: "100%",
        height: "100%",
        backgroundColor: "#ebebeb",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    rootContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "30px",
    },
    cardContainer: {
        padding: "12px",
        overflow: "visible",
        position: "relative",
        minWidth: "33.33%"
    },
    cardHovered: {
        boxShadow: "0 3px 6px 0 rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.23)",
        cursor: "pointer"
    },
    childCardContainer: {
        width: "100%",
        margin: "12px",
        padding: "12px",
        paddingTop: "24px"
    },
    attachButtonContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    attachContainer: {
        display: "flex",
        boxShadow: "none",
        backgroundColor: "#ebebeb"
    },
    designationContainer: {
        top: "-30px",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        color: "#706f6f",
        left: 0
    },
    designationIcon: {
        marginRight: "4px"
    },
    nodeLineContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: "30px"
    },
    leftLineArea: {
        height: "40px",
        width: "33%",
        borderTop: "solid grey 2px",
        borderLeft: "solid grey 2px",
    },
    floatingMenuContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "48px",
    },
    rightLineArea: {
        height: "40px",
        width: "33%",
        borderTop: "solid grey 2px",
        borderRight: "solid grey 2px",
    },
    outerLinks: {
        position: "absolute",
        right: "12%",
        top: "-200%",
        display: "flex",
        flexDirection: "column"
    },
    rootNodeLine: {
        width: "2px",
        height: "100px",
        backgroundColor: "#706f6f"
    },
    link: {
        padding: "4px",
        marginBottom: "4px"
    },
    addNodeLink: {
        padding: "6px",
        border: "solid 1px",
        borderRadius: "4px",
        marginBottom: "4px",

    },
    statusContainer: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        width: "90%"
    },
    statusDot: {
        width: "12px",
        height: "12px",
        minWidth: "12px",
        minHeight: "12px",
        maxWidth: "12px",
        maxHeight: "12px",
        borderRadius: "50%",
        display: "inline-flex",
        marginRight: "8px"
    },
    uidText: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    tooltipContainer: {
        display: "flex",
        alignItems: "center",
        color: "grey",
        margin: "12px 0 12px 0",
        whiteSpace: "nowrap"
    },
    menuIconButton: {
        padding: "4px",
        marginRight: "4px"
    },
    tooltipIcon: {
        width: "18px",
        height: "18px",
        marginRight: "8px"
    },
    idLabel: {
        fontSize: "16px",
        color: "rgba(0,0,0,.42)",
        marginRight: "6px"
    },
    actionListTitle: {
        marginBottom: "8px",
        padding: "11px 16px",
        borderBottom: "solid #80808073 1px",
        backgroundColor: "white",
        cursor: "unset",
        '&:hover': {
            backgroundColor: "white",
            cursor: "unset",
        },
        outline: "none",
        fontFamily: "Inter",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        width: "auto",
        height: "24px",
        whiteSpace: "nowrap",
        boxSizing: "content-box",
        fontWeight: 400,
        lineHeight: "1.5em"
    },
    noNodes: {
        textAlign: "center",
    },
    deviceImage: {
        maxWidth: "100%",
        maxHeight: "100px",
        justifyContent: "center",
        display: "flex",
        marginBottom: "12px"
    },
    devicePlaceholderImage: {
        height: "60px",
        width: "auto",
        color: theme.palette.primary.main
    },
    deviceTypeImage: {
        maxHeight: "60px",
        maxWidth: "100%"
    },
    deviceButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "12px 0 0 0"
    },
    deleteButton: {
        borderColor: theme.palette.red.main,
        color: theme.palette.red.main,
        marginRight: "12px"
    },
    editButton: {
        borderColor: theme.palette.pending.main,
        color: theme.palette.pending.main
    },
    saveButton: {
        marginLeft: "12px",
        borderColor: theme.palette.green.main,
        color: theme.palette.green.main
    },
    cancelButton: {
        marginLeft: "12px",
        borderColor: "white",
        color: theme.palette.grey.main
    },
    buttonIcon: {
        marginRight: "4px"
    },
    infoContainer: {
        zIndex: 1,
        display: "flex",
        width: "100%",
        minHeight: "180px",
        backgroundColor: "white",
        boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, .14), 0px 3px 1px -2px rgba(0, 0, 0, .12)",
    },
    nodesContainer: {
        height: "100%",
        width: "100%",
        overflowY: "auto",
        boxSizing: "border-box",
        padding: "24px",
        paddingTop: "30px"
    },
    leftInfo: {
        width: "50%",
        padding: "12px",
        boxSizing: "border-box",
    },
    rightInfo: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    editContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    editingContainer: {
        width: "100%",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    inputContainer: {
        margin: "12px 0 12px 0",
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
        position: "relative"
    },
    portContents: {
        padding: "12px 0",
        width: "100%",
        padding: "12px",
        paddingTop: "20px",
        borderBottom: "solid rgba(0, 0, 0, 0.23) 1px"
    },
    nonEditingContainer: {
        marginBottom: "20.5px",
        width: "100%",
        padding: "14px",
        paddingLeft: 0,
        borderBottom: "solid rgba(0, 0, 0, 0.23) 1px"
    },
    lineContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        marginBottom: 0,
        marginTop: "-40px",
        position: "relative"
    },
    noPortsValue: {
        top: "14px"
    },
    nonEditingLabel: {
        color: "#757575",
        fontSize: "15px",
        position: "absolute",
        top: "-10px",
        left: "40px"
    },
    inputIcon: {
        color: theme.palette.greyIcon.main,
        margin: "8px",
        paddingBottom: "14px"
    },
    nonEditingIcon: {
        marginTop: "10px"
    },
    editing: {
        border: "none"
    },
    portsIcon: {
        width: "20px",
        margin: "8px",
        marginRight: "12px",
        paddingBottom: "14px"
    },
    portsEditingIcon: {
        width: "20px",
        margin: "8px",
        marginRight: "10px",
        paddingLeft: "2px"
    },
    chipRoot: {
        marginRight: "4px"
    },
    modalWrapper: {
        padding: "24px",
        minHeight: "600px",
        minWidth: "50vw",
        fontFamily: "Inter",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    allNodesWrapper: {
        flexDirection: "row",
        minHeight: "unset"
    },
    modalTitle: {
        fontSize: "24px",
        margin: "12px 0 12px 12px",
        display: "flex",
    },
    allNodesTitle: {
        marginLeft: 0
    },
    subTitle: {
        fontSize: "16px",
        color: "grey",
        margin: "0 0 24px 12px",
        display: "flex",
        alignItems: "center"
    },
    portsContainer: {
        flexWrap: "wrap",
    },
    portLabelContainer: {
        width: "100%",
        alignItems: "center",
        display: "flex",
    },
    portsTitle: {
        color: theme.palette.greyIcon.main,
        fontSize: "18px"
    },
    portsError: {
        color: "red",
        fontSize: "16px",
        marginBottom: "12px"
    },
    portEditingContents: {
        padding: "0 0 0 40px",
        width: "100%"
    },
    noPorts: {
        marginBottom: "36px",
        fontStyle: "italic",
        color: "grey",
        marginTop: "4px"
    },
    portsChipsContainer: {
        marginBottom: "36px",
    },
    addPortContainer: {
        display: "flex",
        alignItems: "center",
        width: "80%"
    },
    addIcon: {
        height: "28px",
        width: "28px",
        color: theme.palette.greyIcon.main,
        margin: "0 0 0 12px",
        padding: 0,
        cursor: "pointer"
    },
    addPortIcon: {
        marginBottom: "18px"
    },
    childContainer: {
        width: "33%",
        display: "inline-flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    ggStatus: {
        marginLeft: "4px",
        display: "flex"
    }
});

class HAGroupTab extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            menus: {},
            editing: false,
            modal: {
                open: false,
                children: () => ""
            },
            read_only_ha_group: JSON.parse(JSON.stringify(this.props.data)),
            editable_ha_group: JSON.parse(JSON.stringify(this.props.data)),
            custom_port_error: null,
            controller_type: null,
            nodes: null,
            company: this.props.data.nested_company,
            integration: this.props.data.nested_integration
        };
        this.prepare(this.props.data, false);
        this.state.new_standard_port = this.get_port_options().length > 0 ? this.get_port_options()[0].value : null;
        this.props.tabHostProxy.setTabRefresh(this.refresh);
    }

    prepare = (ha_group, is_async) => {
        this.actions = [
            { label: "Remove from HA Group", action: "remove", confirm: "Are you sure you'd like to remove this device from the HA Group?", icon: <EjectIcon /> },
            { label: "Reboot", action: "reboot", confirm: "Are you sure you'd like to reboot this device?", icon: <PowerSettingsNewIcon /> },
            { label: "Delete", action: "delete", confirm: "Are you sure you'd like to delete this device?", icon: <DeleteForeverIcon /> },
            { label: "Reintialize Greengrass", action: "greengrass_initialize", confirm: "Are you sure you'd like to reinitialize Greengrass on this device?", icon: <GetAppIcon /> }
        ];
        this.networking_ports = [
            { name: "HTTP: 80", value: 80 },
            { name: "HTTPS: 443", value: 443 },
            { name: "SSH: 22", value: 22 },
            { name: "MQTT: 1883", value: 1883 },
            { name: "MQTTS: 8883", value: 8883 }
        ];
        this.nodes_heading_info = [
            { label: "Name", value: "table_name", field: "name", align: "left", disablePadding: false, sortable: false, content: "function" },
            { label: "Unique ID", field: "unique_id", align: "left", disablePadding: false, sortable: false },
            { label: "Interface", field: "interface", align: "left", disablePadding: false, sortable: false },
            { label: "Greengrass Status", field: "table_greengrass", align: "left", disablePadding: false, sortable: false, content: "function" }
        ];
        this.show_attach = Permissions.allow(["update"], "device_ha_group", ha_group.company_id) && Permissions.allow(["update"], "device", ha_group.company_id);
        if (is_async) {
            this.setState({ new_standard_port: this.get_port_options().length > 0 ? this.get_port_options()[0].value : null });
        } else {
            this.state.new_standard_port = this.get_port_options().length > 0 ? this.get_port_options()[0].value : null;
        }
        this.load_controller_type(ha_group);
        if (ha_group.node_devices && ha_group.node_devices.length > 0) {
            this.load_nodes(ha_group);
        } else {
            if (is_async) {
                this.setState({ nodes: [] });
            } else {
                this.state.nodes = [];
            }
        }
    }

    refresh = () => {
        this.setState({ controller_type: null, nodes: null });
        new DeviceHAGroup({ _id: this.props.data._id }).readFromAPI().then((ha_group) => {
            let ha_groups = [ha_group];
            DeviceHAGroup.loadRequirements([ha_group])
                .then(() => {
                    this.props.tabHostProxy.updateTitle(ha_groups[0]._id, ha_groups[0]);
                    this.props.tabHostProxy.setTabRefresh(this.refresh);
                    this.setState((state) => {
                        state.custom_port_error = null;
                        state.nodes = null;
                        state.controller_type = null;
                        state.menus = {};
                        state.editing = false;
                        state.company = ha_groups[0].nested_company;
                        state.integration = ha_groups[0].nested_integration;
                        state.editable_ha_group = JSON.parse(JSON.stringify(ha_groups[0]));
                        state.read_only_ha_group = JSON.parse(JSON.stringify(ha_groups[0]));
                        return state;
                    }, () => this.prepare(ha_groups[0]), true);
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    load_nodes = (ha_group) => {
        let node_ids = ha_group.node_devices.map(({ device_id }) => device_id).join(",");
        let params = { _id_in: node_ids };
        new Device().listFromAPI(params).then(({ items }) => {
            AssignNestedModels("device_types", "device_type_id", items).then(() => {
                items.forEach((device) => {
                    device = this.prepare_table_fields(device);
                });
                if (items.length !== ha_group.node_devices.length) {
                    this.context.openSnackbar("One or more nodes in this HA Group cannot be found. Their entries may have been deleted.", "warning");
                }
                this.setState({ nodes: items });
            }).catch((error) => {
                console.log(error);
                this.context.openSnackbar(error, "error");
            });
        }).catch((error) => {
            console.log(error);
            this.context.openSnackbar(error, "error");
        });
    }

    load_controller_type = (ha_group) => {
        let type_id = ha_group.nested_controller.device_type_id;
        new DeviceType({ _id: type_id }).readFromAPI().then((type) => {
            this.setState({ controller_type: type });
        }).catch((error) => {
            console.log(error);
            this.context.openSnackbar(error, "error");
            this.props.tabHostProxy.closeSelf();
        })
    }

    close_modal = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    handle_change = (input) => {
        let field = input.field;
        this.setState((state) => {
            state[field] = input.value;
            if (field === "new_port" && input.value === "") {
                state.custom_port_error = null;
            }
            return state;
        });
    }

    render_name = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.inputContainer}>
                <Tooltip className={classes.inputIcon + " " + classes.nonEditingIcon} title="Name">
                    <DescriptionIcon />
                </Tooltip>
                <div className={classes.nonEditingLabel}>
                    HA Group Name
                </div>
                <div className={classes.nonEditingContainer}>
                    {this.state.read_only_ha_group.name}
                </div>
            </div>
        );
    }

    render_company = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.inputContainer}>
                <Tooltip className={classes.inputIcon + " " + classes.nonEditingIcon} title="Account">
                    <AccountCircleIcon />
                </Tooltip>
                <div className={classes.nonEditingLabel}>
                    Account
                </div>
                <div className={classes.nonEditingContainer}>
                    {this.state.company.name}
                </div>
            </div>
        );
    }

    prepare_table_fields = (device) => {
        device.table_name = () => this.render_table_name(device);
        device.table_greengrass = () => this.render_table_greengrass(device);
        device.interface = this.state.read_only_ha_group.node_devices.find(({ device_id }) => device_id === device._id).interface;
        return device;
    }

    render_greengrass_status = (device) => {
        const classes = this.props.classes;
        const { status, display } = this.get_gg_status(device);
        return (
            <div className={classes.tooltipContainer}>
                <Tooltip title={"Greengrass Status"}><ExtensionIcon className={classes.idLabel} /></Tooltip>
                {display}
                <span className={classes.ggStatus}>{status}</span>
            </div>
        );
    }

    get_gg_status = (device) => {
        let status = "";
        let display = "";
        if (device.config_status && device.config_status.greengrass_installed) {
            display = "Installed";
            status = <CheckCircleIcon style={{ fontSize: "18px", marginRight: "4px", color: this.props.theme.palette.green.main }} />;
        } else if (device.config_status && !device.config_status.greengrass_installed) {
            display = "Error";
            status = <ErrorOutlineIcon style={{ fontSize: "18px", marginRight: "4px", color: this.props.theme.palette.red.main }} />;
        } else if (device.status_at === "0001-01-01T00:00:00Z" && device.heartbeat_at === "0001-01-01T00:00:00Z") {
            display = "Install Pending";
            status = <AccessTimeIcon style={{ fontSize: "18px", marginRight: "4px", color: this.props.theme.palette.grey.main }} />;
        }
        return { status: status, display: display };
    }

    render_table_greengrass = (device) => {
        //todo when the ha group isn't limited to greengrass change this
        const { status, display } = this.get_gg_status(device);
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {status}
                {display}
            </div>
        );
    }

    render_table_name = (device) => {
        const hb_map = {
            online: this.props.theme.palette.green.main,
            offline: this.props.theme.palette.heartbeat.offline.main,
            idle: this.props.theme.palette.heartbeat.idle.main,
            never_reported: "grey"
        };
        const color = hb_map[device.heartbeat_status];
        const display = device.name
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color }}>
                    &nbsp;
                </div>
                <div onClick={() => this.open_device_from_table(device)} style={{ cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main }}>
                    {display}
                </div>
            </div>
        );
    }

    open_device_from_table = (device) => {
        this.close_modal();
        this.props.tabHostProxy.addTab("device", device);
    }

    render_integration = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.inputContainer}>
                <Tooltip className={classes.inputIcon + " " + classes.nonEditingIcon} title="Integration">
                    <ExtensionIcon />
                </Tooltip>
                <div className={classes.nonEditingLabel}>
                    Integration
                </div>
                <div className={classes.nonEditingContainer}>
                    {this.state.integration.name}
                </div>
            </div>
        );
    }

    render_ports = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.inputContainer}>
                <Tooltip title="Ports">
                    <img alt="port_icon" className={classes.portsIcon} src={require("../../../images/ports.png")} />
                </Tooltip>
                <div className={classes.nonEditingLabel}>
                    Network Ports
                </div>
                {this.render_port_values()}
            </div>
        );
    }

    render_port_values = () => {
        const classes = this.props.classes;
        if (!this.state.read_only_ha_group.ports || this.state.read_only_ha_group.ports.length === 0) return <div className={classes.nonEditingContainer + " " + classes.noPortsValue}>No Ports Added</div>
        return (
            <div className={classes.nonEditingContainer}>
                {this.state.read_only_ha_group.ports.join(", ")}
            </div>
        );
    }

    render_info_area = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.infoContainer}>
                <div className={classes.leftInfo}>
                    {this.render_name()}
                    {this.render_ports()}
                </div>
                <div className={classes.leftInfo}>
                    {this.render_company()}
                    {this.render_integration()}
                </div>
                {this.render_edit_button()}
            </div>
        );
    }

    discard_edits = () => {
        this.setState({
            modal: { open: false, children: () => "" },
            editable_ha_group: JSON.parse(JSON.stringify(this.state.read_only_ha_group))
        });
    }

    delete_ha_group = () => {
        new DeviceHAGroup({ _id: this.props.data._id }).deleteFromAPI().then(() => {
            this.context.openSnackbar("HA Group deleted.", "success");
            this.props.tabHostProxy.refresh();
            this.close_modal();
            this.props.tabHostProxy.closeSelf();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        });
    }

    save_edits = () => {
        const classes = this.props.classes;
        if (this.state.editable_ha_group.name === '' || (!this.state.editable_ha_group.ports || this.state.editable_ha_group.ports.length === 0) || (this.state.new_port && this.state.custom_port_error == null)) {
            let new_state = { show_errors: true };
            if (this.state.new_port && this.state.custom_port_error == null) {
                new_state.custom_port_error = "If you'd like to add this port, click the add button. If you don't want to add this port, please clear out this input.";
            }
            this.setState(new_state);
            return;
        }
        this.setState({ modal: { open: true, children: () => <div className={classes.modalWrapper}><Loading /></div> } });
        new DeviceHAGroup({ _id: this.props.data._id, name: this.state.editable_ha_group.name, ports: this.state.editable_ha_group.ports }).createOrSave().then((result) => {
            DeviceHAGroup.loadRequirements([result]).then(([new_ha_group]) => {
                this.setState({ modal: { open: true, children: () => "" } });
                this.context.openSnackbar("HA Group saved.", "success");
                this.props.tabHostProxy.refresh();
                this.props.tabHostProxy.closeSelf();
                this.props.tabHostProxy.addTab("haGroup", new_ha_group);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    open_delete = () => {
        this.setState({
            modal: {
                open: true,
                prompt: "Are you sure you want to delete this HA Group?",
                yesFunction: this.delete_ha_group,
                functionText: "DELETE HA GROUP",
                children: () => ""
            }
        });
    }

    open_editing = () => {
        this.setState({
            new_port: "",
            custom_port_error: null,
            new_standard_port: this.get_port_options().length > 0 ? this.get_port_options()[0].value : null,
            modal: {
                open: true,
                children: this.render_editing
            }
        });
    }

    render_editing = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.modalWrapper}>
                <div>
                    <div className={classes.modalTitle}>
                        Edit this HA Group
                    </div>
                    <div className={classes.subTitle}>
                        The account this HA Group belongs to cannot be changed after it has been created. The integration that all the devices share cannot be changed either.
                    </div>
                    <div className={classes.inputContainer}>
                        <Tooltip className={classes.inputIcon} title="Name">
                            <DescriptionIcon />
                        </Tooltip>
                        <TextInput
                            emitChange={this.handle_change}
                            priorState={this.state.editable_ha_group.name}
                            error_message="An HA Group's name cannot be blank."
                            error={this.state.editable_ha_group.name === "" && this.state.show_errors}
                            label="Name*"
                            key="name"
                            field="name"
                        />
                    </div>
                    <div className={classes.inputContainer + " " + classes.portsContainer}>
                        <div className={classes.portLabelContainer}>
                            <Tooltip title="Ports">
                                <img alt="port_icon" className={classes.portsEditingIcon} src={require("../../../images/ports.png")} />
                            </Tooltip>
                            <div className={classes.portsTitle}>
                                Ports* {this.state.show_errors && (!this.state.editable_ha_group.ports || this.state.editable_ha_group.ports.length === 0) ? <span className={classes.portsError}>Ports Must be Added</span> : ""}
                            </div>
                        </div>
                        <div className={classes.portEditingContents}>
                            {this.render_port_editing()}
                            {this.render_add_port()}
                        </div>
                    </div>
                </div>
                {this.render_editing_modal_buttons()}
            </div>
        );
    }

    render_port_editing = () => {
        const classes = this.props.classes;
        if (!this.state.editable_ha_group.ports || (!this.state.editable_ha_group.ports || this.state.editable_ha_group.ports.length === 0)) return <div className={classes.noPorts}>No Ports Added</div>
        return (
            <div className={classes.portsChipsContainer}>
                {this.state.editable_ha_group.ports.map((port) => (
                    <Chip clickable={false} classes={{ root: classes.chipRoot }} key={port} label={port} onDelete={() => this.remove_port(port)} />
                ))}
            </div>
        );
    }

    get_port_options = () => {
        if (!this.state.editable_ha_group.ports || this.state.editable_ha_group.ports.length === 0) {
            return this.networking_ports.map((option) => ({ display: option.name, value: option.value }));
        }
        return this.networking_ports.filter((port) => (this.state.editable_ha_group.ports.indexOf(port.value) === -1)).map((option) => ({ display: option.name, value: option.value }));
    }

    add_standard_port = () => {
        this.setState((state) => {
            if (state.editable_ha_group.ports && Array.isArray(state.editable_ha_group.ports)) {
                state.editable_ha_group.ports.push(parseInt(state.new_standard_port, 10));
            } else {
                state.editable_ha_group.ports = [parseInt(state.new_standard_port, 10)];
            }
            return state;
        }, () => {
            if (this.get_port_options().length > 0) {
                this.setState({ new_standard_port: this.get_port_options()[0].value })
            }
        });
    }

    add_custom_port = () => {
        let numbers = /^\d*[1-9]\d*$/;
        if (this.state.editable_ha_group.ports.indexOf(parseInt(this.state.new_port, 10)) !== -1) {
            this.setState({ show_errors: true, custom_port_error: "That port number has already been added." });
            return;
        } else if (!numbers.test(this.state.new_port) || this.state.new_port === '' || this.state.new_port === 0) {
            this.setState({ show_errors: true, custom_port_error: "Please enter a valid port number." });
            return;
        }
        this.setState((state) => {
            state.editable_ha_group.ports.push(parseInt(state.new_port, 10));
            return { editable_ha_group: state.editable_ha_group, custom_port_error: null, new_port: null };
        }, () => {
            if (this.get_port_options().length > 0) {
                this.setState({ new_standard_port: this.get_port_options()[0].value })
            }
        });
    }

    render_add_port = () => {
        const classes = this.props.classes;
        return (
            <div>
                <div className={classes.addPortContainer}>
                    {this.get_port_options().length > 0 ?
                        <React.Fragment>
                            <SelectInput
                                field="new_standard_port"
                                emitChange={this.handle_change}
                                label="Add Standard Port"
                                priorState={this.state.new_standard_port}
                                options={this.get_port_options()}
                            />
                            <IconButton onClick={this.add_standard_port} className={classes.addIcon + " " + classes.addPortIcon} aria-label="Add">
                                <AddCircleIcon />
                            </IconButton>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <SelectInput
                                field="new_standard_port"
                                emitChange={this.handle_change}
                                disabled={true}
                                label="Add Standard Port"
                                priorState={''}
                                options={[{ value: '', display: "All Standard Ports Added" }]}
                            />
                        </React.Fragment>
                    }
                </div>
                <div className={classes.addPortContainer}>
                    <TextInput
                        emitChange={this.handle_change}
                        priorState={this.state.new_port}
                        error_message={this.state.custom_port_error}
                        error={this.state.custom_port_error && this.state.show_errors}
                        label="Add Custom Port Number"
                        key="name"
                        field="new_port"
                    />
                    <IconButton onClick={this.add_custom_port} className={classes.addIcon + " " + classes.addPortIcon} aria-label="Add">
                        <AddCircleIcon />
                    </IconButton>
                </div>
            </div>

        )
    }

    remove_port = (port) => {
        this.setState((state) => {
            let ports = state.editable_ha_group.ports;
            ports.splice(ports.indexOf(port), 1);
            if (this.networking_ports.find(({ value }) => port === value)) {
                state.new_standard_port = this.get_port_options()[0].value;
            }
            state.editable_ha_group.ports = ports;
            return state;
        });
    }

    render_edit_button = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.editContainer}>
                <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    onClick={this.open_delete}
                >
                    <DeleteForeverIcon className={classes.buttonIcon} />
                    DELETE HA GROUP
                </Button>
                <Button
                    className={classes.editButton}
                    variant="outlined"
                    onClick={this.open_editing}
                >
                    <EditIcon className={classes.buttonIcon} />
                    EDIT HA GROUP
                </Button>
            </div>
        );
    }

    render_editing_modal_buttons = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.editingContainer}>
                <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={this.discard_edits}
                >
                    DISCARD CHANGES
                </Button>
                <Button
                    className={classes.saveButton}
                    variant="outlined"
                    onClick={this.save_edits}
                >
                    <SaveIcon className={classes.buttonIcon} />
                    SAVE HA GROUP
                </Button>

            </div>
        );
    }

    open_device = (device) => {
        this.props.tabHostProxy.addTab("device", device);
    }

    render_controller = () => {
        const classes = this.props.classes;
        const connection = this.props.data.controller_device.interface;
        const controller = this.props.data.nested_controller;
        return (
            <div className={classes.rootContainer}>
                <Card
                    onClick={() => this.open_device(controller)}
                    className={classes.cardContainer + " " + (this.state.hovering === controller._id ? classes.cardHovered : "")}
                    onMouseEnter={() => this.setState({ hovering: controller._id })}
                    onMouseLeave={() => this.setState({ hovering: null })}
                >
                    <div className={classes.designationContainer}>
                        <RouterIcon className={classes.designationIcon} />
                        <span>Controller Device</span>
                    </div>
                    {this.render_device_status(controller)}
                    {this.render_device_unique_id(controller)}
                    {this.render_connection(connection)}
                    {this.render_device_image(this.state.controller_type)}
                </Card>
                <div style={{ marginBottom: "0" }} className={classes.nodeLineContainer}>
                    <div className={classes.rootNodeLine}>
                        &nbsp;
                    </div>
                </div>
            </div>
        );
    }

    render_menu_area = (device) => {
        const classes = this.props.classes;
        return (
            <div className={classes.floatingMenuContainer}>
                {this.render_menu(device)}
            </div>
        );
    }

    render_device_status = (device) => {
        const { classes, theme } = this.props;
        const status = device.heartbeat_status;
        const status_map = { offline: theme.palette.red.main, online: theme.palette.green.main, idle: theme.palette.caution.main, never_reported: theme.palette.grey.main };
        return (
            <div className={classes.statusContainer}>
                <div style={{ backgroundColor: status_map[status] }} className={classes.statusDot}>
                    &nbsp;
                </div>
                <div className={classes.uidText}>
                    {device.name}
                </div>
            </div>
        );
    }

    render_device_unique_id = (device) => {
        const { classes } = this.props;
        return (
            <div className={classes.tooltipContainer}>
                <Tooltip title="Unique ID"><div className={classes.idLabel}>ID</div></Tooltip>
                <span className={classes.uidText}>
                    {device.unique_id}
                </span>
            </div>
        );
    }


    render_connection = (connection_name) => {
        const { classes } = this.props;
        return (
            <div className={classes.tooltipContainer}>
                <Tooltip title="Interface"><SettingsEthernetIcon className={classes.tooltipIcon} /></Tooltip>
                {connection_name ?
                    <div className={classes.uidText}>
                        {connection_name}
                    </div>
                    :
                    <div style={{ color: this.props.theme.palette.red.main }} className={classes.uidText}>
                        No Interface Defined
                    </div>
                }
            </div>
        );
    }

    render_device_image = (type) => {
        const { classes } = this.props;
        let image = type.image && type.image !== "" ? type.image : null;
        return (
            <div>
                {image === null ?
                    <div className={classes.deviceImage}>
                        <DvrIcon className={classes.devicePlaceholderImage} />
                    </div>
                    :
                    <div className={classes.deviceImage}>
                        <img className={classes.deviceTypeImage} src={image} />
                    </div>
                }
            </div>
        );
    }

    render_nodes = () => {
        const { classes } = this.props;
        const group = this.props.data.node_devices;
        if (group.length === 0) {
            if (!this.show_attach) return "";
            return (
                <div className={classes.noNodes}>
                    {this.render_attach_node()}
                </div>
            );
        }
        return (
            <div className={classes.nodesArea}>
                {this.render_lines()}
                {this.render_nodes_cards()}
            </div>
        );
    }

    render_lines = () => {
        const { classes } = this.props;
        const group = this.props.data.node_devices;
        const root = this.props.data.nested_controller;
        switch (group.length) {
            case 0:
                return "";
            case 1:
                return (
                    <div className={classes.lineContainer}>
                        <div className={classes.leftLineArea}>
                            &nbsp;
                        </div>
                        <div style={{ visibility: "hidden" }} className={classes.rightLineArea}>
                            &nbsp;
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={classes.lineContainer}>
                        <div className={classes.leftLineArea}>
                            &nbsp;
                        </div>
                        <div className={classes.rightLineArea}>
                            &nbsp;
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={classes.lineContainer}>
                        <div className={classes.leftLineArea}>
                            &nbsp;
                        </div>
                        <div className={classes.rightLineArea}>
                            &nbsp;
                        </div>
                        <div className={classes.outerLinks}>
                            <Button variant="outlined" onClick={this.view_all} style={{ display: (group.length > 3 ? "unset" : "none") }} className={classes.editButton + " " + classes.link}>
                                {group.length - 3} more...
                            </Button>
                            {this.show_attach ? <Button variant="outlined" onClick={this.attach_node} className={classes.editButton + " " + classes.link}>
                                Add Node
                            </Button> : ""}
                        </div>
                    </div>
                );
        }
    }

    view_all = () => {
        this.setState({
            modal: {
                open: true,
                children: this.render_all_nodes
            }
        });
    }

    render_all_nodes = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.modalWrapper + " " + classes.allNodesWrapper}>
                <div className={classes.modalTitle + " " + classes.allNodesTitle}>
                    All Nodes
                </div>
                <TableList
                    headings={this.nodes_heading_info}
                    items={this.state.nodes}
                />
            </div>
        );
    }

    render_attach_node = () => {
        const { classes } = this.props;
        if (!this.show_attach) return "";
        return (
            <div className={classes.childContainer}>
                <Card
                    className={classes.cardContainer + " " + classes.childCardContainer + " " + classes.attachContainer}
                >
                    <div className={classes.attachButtonContainer}>
                        <Button
                            className={classes.editButton}
                            variant="outlined"
                            onClick={this.attach_node}
                        >
                            ADD NODE
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }

    attach_node = () => {
        this.setState({
            modal: {
                open: true,
                children: () => <AddHAGroupNode HAGroup={this.props.data} onAdd={this.on_add} cancel={() => this.setState({ modal: { open: false, children: () => "" } })} />
            }
        });
    }

    on_add = (new_ha_group) => {
        DeviceHAGroup.loadRequirements([new_ha_group]).then(([new_ha_group]) => {
            this.context.openSnackbar("Devices add to HA Group.", "success");
            this.props.tabHostProxy.refresh();
            this.close_modal();
            this.props.tabHostProxy.closeSelf();
            this.props.tabHostProxy.addTab("haGroup", new_ha_group);
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        });
    }

    render_nodes_cards = () => {
        const { classes } = this.props;
        const group = this.state.nodes.slice(0, 3);
        const root = this.props.data.nested_controller;
        return (
            <div className={classes.nodesContainer}>
                {group.map((device, index) => (
                    this.render_child(device, group.length === 1)
                ))}
                {group.length < 3 ? this.render_attach_node() : ""}
            </div>
        );
    }

    render_child = (device, single) => {
        const { classes } = this.props;
        const connection = this.props.data.node_devices.find((node_device) => node_device.device_id === device._id).interface;
        return (
            <div key={device._id} className={classes.childContainer}>
                <Card
                    onClick={() => this.open_device(device)}
                    className={classes.cardContainer + " " + classes.childCardContainer + " " + (this.state.hovering === device._id ? classes.cardHovered : "")}
                    onMouseEnter={() => this.setState({ hovering: device._id })}
                    onMouseLeave={() => this.setState({ hovering: null })}
                >
                    <div className={classes.designationContainer}>
                        <SettingsRemoteIcon className={classes.designationIcon} />
                        <span className={classes.designationText}>Node Device</span>
                    </div>
                    {this.render_menu_area(device)}
                    {this.render_device_status(device)}
                    {this.render_device_unique_id(device)}
                    {this.render_connection(connection)}
                    {this.render_greengrass_status(device)}
                    {this.render_device_image(device.nested_device_type)}
                </Card>
            </div>
        );
    }

    open_action_menu = (event, id) => {
        event.stopPropagation();
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }

    render_menu = (device) => {
        const classes = this.props.classes;
        let anchorEl = this.state.menus[device._id];
        let open = Boolean(anchorEl);
        return (
            <div>
                <div>
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => this.open_action_menu(event, device._id)}
                        className={classes.menuIconButton}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>
                {this.render_action_menu(open, anchorEl, device)}
            </div>
        );
    }

    close_action_menu = (event, id) => {
        event.stopPropagation();
        this.setState((state) => {
            state.menus[id] = null;
            return state;
        });
    };

    render_action_menu = (open, anchorEl, device) => {
        const classes = this.props.classes;
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(event) => this.close_action_menu(event, device._id)}
                PaperProps={{
                    style: { overflow: "visible" }
                }}
            >
                <div className={classes.actionListTitle}>
                    Perform Action...
                </div>
                {this.actions.map((action, index) => (
                    <div onClick={(event) => this.quick_action(event, action, device)} key={action.label} className={classes.menuItemContainer}>
                        <MenuItem className={classes.actionMenuItem}>
                            <ListItemIcon>
                                {action.icon}
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                {action.label}
                            </Typography>
                        </MenuItem>
                    </div>
                ))}
            </Menu>
        );
    }

    quick_action = (event, action, device) => {
        event.stopPropagation();
        this.setState((state) => {
            state.menus[device._id] = null;
            state.modal = {
                open: true,
                yesFunction: () => this.quick_issue(action, device),
                functionText: action.label,
                prompt: action.confirm,
                children: () => { }
            };
            return state;
        });
    }

    delete = (device) => {
        this.remove(device, true).then((new_ha_group) => {
            new Device({ _id: device._id }).deleteFromAPI().then(() => {
                this.context.openSnackbar("Device deleted.", "success");
                this.props.tabHostProxy.refresh();
                this.close_modal();
                this.props.tabHostProxy.closeSelf();
                this.props.tabHostProxy.addTab("haGroup", new_ha_group);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                this.close_modal();
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        });

    }

    remove = (device, and_delete) => {
        return new Promise((resolve, reject) => {
            let new_nodes = this.props.data.node_devices.filter(({ device_id }) => device_id !== device._id);
            new DeviceHAGroup({ _id: this.props.data._id, node_devices: new_nodes }).saveOrCreate().then((result) => {
                DeviceHAGroup.loadRequirements([result]).then(([new_ha_group]) => {
                    if (!and_delete) {
                        this.context.openSnackbar("Device removed from HA Group.", "success");
                        this.props.tabHostProxy.refresh();
                        this.close_modal();
                        this.props.tabHostProxy.closeSelf();
                        this.props.tabHostProxy.addTab("haGroup", new_ha_group);
                    }
                    resolve(new_ha_group);
                }).catch((error) => {
                    this.props.tabHostProxy.closeSelf();
                    this.context.openSnackbar(error, "error");
                    this.close_modal();
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                this.close_modal();
                reject();
            });
        });
    }

    quick_issue = (action, device) => {
        this.setState((state) => {
            state.modal = {
                open: true,
                children: () => (<div style={{ height: "100px", width: "100px", margin: "auto" }}><Loading /></div>)
            };
            return state;
        });
        if (action.action === "delete") {
            this.delete(device);
        } else if (action.action === "remove") {
            this.remove(device);
        } else {
            Device.issueGatewayCommand(action.action, null, device._id).then(() => {
                this.context.openSnackbar("Command issued. Monitor the status of the command by viewing this device's commands.", "success");
                this.close_modal();
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                this.close_modal();
            });
        }
    }

    render() {
        const classes = this.props.classes;
        if (this.state.controller_type === null || this.state.nodes === null) return <Loading />;
        return (
            <div className={classes.container}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                {this.render_info_area()}
                <div className={classes.nodesContainer}>
                    {this.render_controller()}
                    {this.render_nodes()}
                </div>
            </div>
        );
    }
}

HAGroupTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(HAGroupTab));
