import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'

const styles = theme => ({
    container: {
        position: "relative",
        width: "100%",
    },
    dropdown: {
        width: "100%",
        paddingRight: 0,
        color: "#8e8e93",
        fontSize: "14px",
    },
    optionIcon: {
        height: "40px",
        verticalAlign: "middle",
        marginTop: "-5px",
        marginRight: "5px"
    },
    label: {
        position: "absolute",
        top: "-20px",
        fontSize: "14px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%"
    },
    errorMessage: {
        color: "red",
        marginLeft: "14px",
    },
    invisible: {
        visibility: "hidden"
    },
    basic: {
        display: "flex",
        marginBottom: "2px"
    },
    heartBeatstatusDot: {
        width: "12px",
        height: "12px",
        minWidth: "12px",
        minHeight: "12px",
        maxWidth: "12px",
        maxHeight: "12px",
        borderRadius: "50%",
        display: "inline-flex",
        marginRight: "8px",
        color: 'white'
    }
});

//todo: select inputs need to be disableable
class SelectInput extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    localChange = (event) => {
        let { value } = function () {
            return {
                value: this.target.value
            };
        }.bind(event)();
        this.value = value;
        this.props.emitChange({ label: this.props.label, value: value, field: this.props.field });
    }

    handleKeyPress = (event) => {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            this.props.onEnter ? this.props.onEnter() : "";
        }
    }

    render() {
        const { priorState, label, options, note, classes, className, field } = this.props;
        if (priorState !== undefined) {
            this.value = priorState;
        } else {
            if (options) {
                this.value = options[0].value;
            } else {
                this.value = "";
            }
        }
        let outlined = <OutlinedInput
            name={"outlined_select_" + label}
            labelWidth={0}
        />;
        if (this.props.basic) {
            outlined = <Input name={"select_" + label} />;
        }
        return (
            <div className={classes.container + " " + (this.props.basic ? classes.basic : "")}>
                {label && label !== "" ?
                    <InputLabel className={classes.label} htmlFor={"SelectInput_" + label}>{label}</InputLabel>
                    : ""}
                <Select
                    error={this.props.error || false}
                    disabled={this.props.disabled}
                    className={classes.dropdown + " " + this.props.overrideClass}
                    autoWidth={true}
                    id={"select_" + label}
                    onKeyDown={this.handleKeyPress}
                    name={label}
                    value={this.value}
                    displayEmpty={true}
                    native={false}
                    onChange={this.localChange}
                    input={outlined}
                    renderValue={(value) => this.optionIcon(classes)}
                    inputProps={{
                        name: "SelectInput_" + label,
                        id: "SelectInput_" + label,
                        margin: "none"
                    }}
                    classes={{ select: this.props.selectOverrideClass, icon: this.props.iconOverrideClass }}
                >
                    {options.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {
                                field === 'status' ?
                                    <HeartbeatStatus status={option.value} className={classes.heartBeatstatusDot} disabletooltip={true} /> : null
                            }
                            {option.display}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText className={classes.errorMessage + " " + (this.props.error ? "" : classes.invisible)} id="component-error-text">{this.props.error_message}</FormHelperText>
            </div>
        );
    }

    optionIcon = (classes) => {
        let option = this.props.options.find((option) => {
            return option.value === this.value;
        });
        if (option) {
            if (option.image) {
                return (
                    <span>
                        <img className={classes.optionIcon} src={require("../../images/" + option.image).default} />
                        {option.display}
                    </span>
                );
            } else {
                return option.display;
            }
        } else {
            return "";
        }
    }

}

export default withStyles(styles)(withTheme()(SelectInput));
