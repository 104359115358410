import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

//icons
import ReorderIcon from '@material-ui/icons/Reorder';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = theme => ({
	container: {
		display: "block",
		borderSpacing: 0,
		position: "relative",
		zIndex: 5,
		width: "100%",
		borderBottom: "solid lightgrey 1px",
	},
	buttonIcon: {
		marginRight: "8px",
		display: "flex",
		[theme.breakpoints.down(1400)]: {
			marginRight: 0
		}
	},
	buttonText: {
		[theme.breakpoints.down(1400)]: {
			display: "none"
		}
	},
	actionContainer: {
		display: "flex",
		justifyContent: "flex-end",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginRight: "24px",
		marginLeft: "auto"
	},
	buttonTd: {
		marginLeft: "12px"
	},
	buttonOverride: {
		textTransform: "unset",
		boxShadow: "none",
		color: theme.palette.pending.main,
		backgroundColor: "white",
		border: `solid ${theme.palette.pending.main} 1px`,
		'&:hover': {
			backgroundColor: lighten(theme.palette.pending.main, .8),
		},
	},
	linkButton: {
		boxShadow: "none",
		color: theme.palette.pending.main,
		backgroundColor: "white",
		padding: '6px 8px',
		'&:hover': {
			backgroundColor: "white",
			color: '#0263FC',
		},
	},
	mainButton: {
		color: "white",
		backgroundColor: theme.palette.pending.main,
		'&:hover': {
			backgroundColor: '#0263FC',
			borderColor: '#0263FC',
			color: "white",
		},
		paddingRight: "16px",
		paddingLeft: "16px",
	},
	paginationContainer: {
		height: "57px",
		display: "flex",
	},
	pagination: {
		display: "flex",
		padding: 0,
		border: "none",
		"&:last-child": {
			paddingRight: 0
		}
	},
	paginationRow: {
		alignItems: "center",
		position: "relative",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
		justifyContent: "flex-end",
		zIndex: "2",
	},
	paginationRowNoShadow: {
		alignItems: "center",
		position: "relative",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
		justifyContent: "flex-end",
		zIndex: "2",
	},
	noShow: {
		display: "none"
	},
	rightContents: {
		display: "flex",
		justifyContent: "flex-end",
	},
	rightContentsAbsolute: {
		position: "absolute",
		top: "-50px",
		zIndex: 5,
		display: "flex",
		backgroundColor: "white",
		borderRadius: "6px",
	},
	layoutContainer: {
		color: "grey",
		display: "flex",
		marginLeft: "24px",
		alignItems: "center",
	},
	layoutDivider: {
		borderRight: "solid grey 2px",
	},
	layoutButton: {
		padding: "4px",
	},
	filtering: {
		color: "white",
		background: theme.palette.pending.main
	},
	layoutButtonRight: {
		marginLeft: "4px",
	},
	selectionArea: {
		alignItems: "center",
		flex: "15%",
	},
	selectionText: {
		color: theme.palette.primary.main,
		margin: "0 12px",
		fontSize: "16px",
		whiteSpace: "nowrap"
	},
	editFab: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)",
	},
	actionButtonContainer: {
		display: "flex",
		margin: "0 4px 0 auto"
	},
	active: {
		backgroundColor: theme.palette.green.main,
		color: "white",
	},
	label: {
		marginRight: "4px"
	},
	buttonLabel: {
		whiteSpace: "nowrap",
		alignItems: "center",
	},
	activeLayout: {
		backgroundColor: theme.palette.pending.main,
		color: "white",
		cursor: "unset",
		"&:hover": {
			backgroundColor: theme.palette.pending.main
		}
	},
	toggleContainer: {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		color: "grey"
	},
	paginationText: {
		fontSize: "14px",
		color: "#636366",
	}
});

class PaginationContainer extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			action: "SELECT ACTION",
			layout: this.props.layout ? this.props.layout : "card"
		};
		if (this.props.actions) {
			this.actions = ["SELECT ACTION"];
			this.actions = this.actions.concat(this.props.actions);
		} else {
			this.actions = null;
		}
	}

	handleActionChange = ({target}) => {
		this.setState({action: target.value});
	}

	toggle_view = (layout) => {
		this.setState({layout: layout}, () => {
			this.props.toggleView(layout);
		});
	}

	render_filter_toggle = () => {
		const classes = this.props.classes;
		if (!this.props.toggle_filter) return <td></td>;
		const active_class = this.props.filter_active ? classes.filterIconActive : "";
		return (
			<td className={classes.toggleContainer}>
				<Tooltip className={active_class} onClick={this.props.toggle_filter} title="Filter">
					<IconButton
						aria-label="filter"
						color="inherit"
						className={classes.layoutButton + " " + (this.props.filtering ? classes.filtering: "")}
					>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			</td>
		);
	}

	render() {
		const { count, rowsPerPage, noPerPage, currentPage, onChangePage, onChangeRowsPerPage, classes, noShadow } = this.props;
		let fromVal = 0;
		let toVal = 0;
		let ready = true;
		if (count != null && count !== 0) {
			fromVal = 1 + (rowsPerPage * (currentPage - 1));
			toVal = rowsPerPage * currentPage > count ? count : rowsPerPage * currentPage;
		} else if (noPerPage) {
			ready = true;
		} else if (count === 0) {
			ready = true;
		} else {
			ready = false;
		}
		let style = {};
		if (this.props.bgColor) {
			style = { backgroundColor: this.props.bgColor };
		}
		let buttons = this.props.buttons ? this.props.buttons.reverse() : null;
		return (
			<div className={classes.container}>
				<table className={classes.paginationContainer} >
					<tbody className={noShadow ? classes.paginationRowNoShadow : classes.paginationRow}>
						{ready && buttons ?
							<tr className={classes.buttonContainer}>
								{buttons.map( (button, index) => 
									<td className={classes.buttonTd} key={"pagination_button_" + index} >
										<Button disabled={Boolean(button.disabled)} classes={{root: classes.linkButton + " " + (index === buttons.length - 1 ? classes.mainButton : "")}} onClick={button.action} variant="contained">
											<span className={classes.buttonIcon}>{button.icon}</span>
											<span className={classes.buttonText}>{button.label}</span>
										</Button>
									</td>
								)}
							</tr>
							: <tr className={classes.noShow}></tr>
						}
						<tr>{ready ? this.render_filter_toggle() : <td></td>}</tr>
						{this.props.toggleView && ready ? <tr className={classes.layoutContainer}>
							<td className={classes.toggleContainer}>
								<Tooltip onClick={() => this.state.layout !== "card" ? this.toggle_view("card") : ""} key="Card Layout" title="Card Layout">
									<IconButton
										aria-label="Card Layout"
										color="inherit"
										className={classes.layoutButton + " " + (this.state.layout === "card" ? classes.activeLayout: "")}
									>
										<ViewModuleIcon />
									</IconButton>
								</Tooltip>
								<span className={classes.layoutDivider}>&nbsp;</span>
								<Tooltip onClick={() => this.state.layout === "card" ? this.toggle_view("table") : ""} key="Table Layout" title="Table Layout">
									<IconButton
										aria-label="Table Layout"
										color="inherit"
										className={classes.layoutButton + " " + classes.layoutButtonRight + " " + (this.state.layout !== "card" ? classes.activeLayout: "")}
									>
										<ReorderIcon />
									</IconButton>
								</Tooltip>
							</td>
						</tr> : <tr></tr> }
						{ready ? <tr>
							<TablePagination
								labelDisplayedRows={({ from, to, count }) => `${fromVal}-${toVal} of ${count}` }
								rowsPerPageOptions={noPerPage ? [noPerPage] : [12, 24, 60, 120]}
								className={classes.pagination}
								count={count}
								rowsPerPage={rowsPerPage}
								page={currentPage - 1}
								backIconButtonProps={{
									'aria-label': 'Previous Page',
								}}
								nextIconButtonProps={{
									'aria-label': 'Next Page',
								}}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
								classes={{caption: classes.paginationText}}
							/>
						</tr> : <tr className={classes.noShow}></tr>}
					</tbody>
				</table>
			</div>
		)
	}
}
export default compose(
	withWidth(),
	withStyles(styles),
)(PaginationContainer);
