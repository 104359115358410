import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Loading from '../DisplayOriented/Loading';
import Tooltip from '@material-ui/core/Tooltip';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import DeviceErrors from '../../services/DataModels/DeviceErrors';
import EnhancedTable from '../Table/EnhancedTable';
import Permissions from '../../services/Permissions';

//icons
import TodayIcon from '@material-ui/icons/Today';
import ErrorIcon from '@material-ui/icons/Error';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
	container: {
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
		boxShadow: "none"
	},
	errorsContainer: {
		width: "100%",
		height: "100%"
	},
	link: {
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		},
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		margin: "8px"
	},
	errorContainer: {
		overflow: "scroll",
		maxWidth: "100%"
	}
});

const heading_info = [
	{label: "DATE/TIME", value: "created_at", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date"},
	{label: "SEVERITY", value: "display_level", field: "display_level", align: "center", disablePadding: false, sortable: true, content: "function"},
	{label: "ERROR", value: "error", field: "error", align: "left", disablePadding: false, sortable: false, content: "function"},
	{label: "ACCOUNT", value: "company_id", field: "company_id", align: "left", disablePadding: false, sortable: true},
];


class MessagesErrors extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
		};
	}

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	}

	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	}

	prepare_filter = (params) => {
		let body = {};
		if (params["Device Name"]) {
			body.device_name_like = params["Device Name"];
		}
		if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
			var companyIDs = params["Account"].values.map((option) => {
				return option.value;
			});
			body.company_id_in = companyIDs.join(",");
		}
		return body;
	}

	render_error_message = (error, id, classes, company_id) => {
		return (
			<div>
				{error}
				{this.props.device_id || !Permissions.allow(["read"], "device", company_id) || !Permissions.allow(["read"], "device_type", company_id) ? "" :
					<span style={{marginLeft: "12px", cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}} onClick={() => this.openDeviceTab(id)} className={classes.link}>
						View Device
					</span>
				}
			</div>
		);
	}

	render_severity = (level) => {
		if (level < 4) {
			return (
				<Tooltip key="warning" title="Warning">
					<WarningIcon style={{marginRight: "16px", color: this.props.theme.palette.caution.main}}/>
				</Tooltip>
			);
		} else if ( level === 4 ) {
			return (
				<Tooltip key="error" title="Error">
					<ErrorIcon style={{marginRight: "16px", color: this.props.theme.palette.red.main}}/>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip key="critical_error" title="Critical Error">
					<NewReleasesIcon style={{marginRight: "16px", color: this.props.theme.palette.red.main}}/>
				</Tooltip>
			);
		}
	}

	load_errors = (params) => {
		return new Promise( (resolve, reject) => {
			if (this.props.device_id) {
				params.device_id = this.props.device_id;
			}
			delete params.total;
			new DeviceErrors()
				.listFromAPI(params)
				.then( (result) => {
					let errors = result.items;
					errors.forEach( (error) => {
						error._id = error.id;
						const error_message = error.error;
						error.error = (classes) => this.render_error_message(error_message, error.device_id, classes, error.company_id);
						error.display_level = (classes) => this.render_severity(error.level, classes);
					});
					resolve({items: errors, total: result.total});
				})
				.catch( (error) => {
					this.context.openSnackbar(error, "error");
					reject();
				});
		});
	}

	closeModal = () => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	};

	openDeviceTab = deviceId => {
		this.props.tabHostProxy.addTab("device", deviceId);
	}

	render() {
		const { classes } = this.props;

		return (
			<Paper className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.errorsContainer}>
					<EnhancedTable 
						noSelect={true} 
						headings={heading_info} 
						loadFunction={this.load_errors} 
						actions={[]} 
						filters={[]}>
					</EnhancedTable>
				</div>
			</Paper>
		);

	}
}

MessagesErrors.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(MessagesErrors));
