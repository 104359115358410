import DataModel from "./DataModel";

class CommandCSVUpload extends DataModel {
	constructor(command) {
		super("devices/csv_bulk_upload/" + command);
	}

	// `file` is expected to be a File (https://www.w3.org/TR/FileAPI/#dfn-file)
	uploadCSV(file) {
		return this.uploadFiles('csv_ids', file);
	}
}

export default CommandCSVUpload;
