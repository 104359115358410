import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const styles = theme => ({
	container: {
		display: "flex",
		alignItems: "center",
		flex: "100%",
		margin: "12px",
		fontFamily: "Inter",
	},
	profileImage: {
		width: "40px",
		height: "40px",
		margin: "0 12px",
		color: "grey",
		display: "inline",
	},
	profileInfo: {
		display: "inline",
	},
	profileName: {
		display: "block",
		color: "black",
		fontWeight: 400
	},
	profileRole: {
		display: "block",
		color: "grey",
		fontWeight: 200
	},
	roleNotFound: {
		color: "red",
		textTransform: "uppercase"
	},
	actionIconArea: {
		margin: "auto 0 auto auto",
		display: "inline"
	}
});

class UserContainer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
		const {name, role, image, action, actionIcon, user, classes} = this.props;
		return (
			<div className={classes.container}>
				{image && image !== "" ?
					<img src={image} className={classes.profileImage}/>
				:
					<AccountCircleIcon className={classes.profileImage}/>
				}
				<div className={classes.profileInfo}>
					<div className={classes.profileName}>
						{name}
					</div>
					{role && role !== "" ? 
						<div className={classes.profileRole}>
							{role}
						</div>
					:
						<div className={classes.roleNotFound}>
							Role Not Found
						</div>
					}
				</div>
				{action ? 
					<div className={classes.actionIconArea} onClick={event => action(user)}>
						{actionIcon}
					</div>
				: ""}
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(UserContainer));
