import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import CreateUserForm from '../../components/UserSpecific/CreateUserForm';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Step from '@material-ui/core/Step';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompanyIDInput from '../../components/Inputs/CompanyIDInput';
import StepLabel from '@material-ui/core/StepLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Company from '../../services/DataModels/Company';
import User from '../../services/DataModels/User';
import { GetAll } from '../../services/CLURDUtilities';
import Role from '../../services/DataModels/Role';
import Auth from '../../services/Auth';
import SimpleModalWrapped from '../../components/Containers/SimpleModalWrapped';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CardContainer from '../../components/Containers/CardContainer';
import UserContainer from '../../components/UserSpecific/UserContainer';

const styles = theme => ({
	container: {
		height: "100%",
		position: "relative",
	},
	stepContainer: {
		height: "calc(100vh - 166px)",
		marginTop: "-48px",
		paddingRight: "12px",
		overflowY: "scroll"
	},
	stepContentTitle: {
		fontFamily: "Inter",
		fontSize: "1.25rem",
		marginBottom: "8px",
		flex: "100%",
	},
	input: {
		display: "block",
	},
	matchMessage: {
		color: "grey",
		fontFamily: "Inter,sans-serif",
		textDecoration: "none",
		fontSize: "16px",
	},
	stepOverride: {
		margin: "0 -24px 24px -24px",
		paddingTop: "5px",
		paddingBottom: "5px",
		position: "relative",
		top: "-48px",
		width: "100%",
		boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
		zIndex: 1099,
		height: "44px",
		display: "flex",
		alignItems: "center",
		backgroundColor: "#f1f1f1",
		color: "grey",
		fontFamily: "Inter"
	},
	buttonContainer: {
		marginTop: "16px",
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginLeft: "24px",
	},
	customizeLeft: {
		width: "50%",
	},
	customizeRight: {
		width: "40%",
		marginLeft: "16px",
		textAlign: "center",
		marginTop: "16px",
		maxHeight: "59vh",
		overflow: "scroll",
	},
	stepContentSubtitle: {
		fontFamily: "Inter",
		fontWeight: 400,
		color: "grey",
		flex: "100%",
		marginBottom: "12px",
	},
	mockPortal: {
		// height: "80vh",
	},
	customizeContainer: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "flex-start",
		justifyContent: "space-evenly",
	},
	brandInput: {
		width: "48%",
	},
	brandingContainer: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-evenly",
	},
	pageToggleLabel: {
		fontFamily: "Inter",
		fontWeight: 400,
		marginBottom: "5px",
		width: "50%",
		color: "grey",
	},
	pageToggleContainer: {
		width: "48%",
		display: "flex",
		alignItems: "center",
	},
	pageToggles: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-evenly",
	},
	loading: {
		width: "40px",
		margin: "20px auto",
		display: "block",
		height: "40px",
	},
	done: {
		color: "green",
	},
	loadingText: {
		width: "100%",
		display: "flex",
		fontFamily: "Inter",
		color: "grey",
		textAlign: "center",
		justifyContent: "center",
	},
	loadingContainer: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		alignItems: "center",
	},
	contentContainer: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
	},
	usersContainer: {
		marginBottom: "12px",
	},
	userName: {
		color: "black",
		margin: "4px 0",
		flex: "50%",
		fontSize: "18px",
		fontFamily: "Inter",
	},
	assignArea: {
		width: "100%",
		height: "calc(100% - 65px)",
		overflowY: "scroll"
	},
	assignUserRow: {
		display: "flex",
		margin: "0 12px",
		alignItems: "center",
		fontFamily: "Inter",
		padding: "6px 0",
	},
	assigneeRow: {
		marginLeft: "36px",
		flex: "50%",
	},
	assignClick: {
		color: theme.palette.primary.main,
		cursor: "pointer",
	},
	userList: {
		fontFamily: "Inter",
		display: "flex",
		height: "calc(100% - 89px)",
		padding: "12px",
		overflowX: "hidden",
		overflowY: "scroll",
		flexWrap: "wrap",
	},
	userListHalf: {
		display: "flex",
		flexWrap: "wrap",
		minWidth: "inherit"
	},
	note: {
		fontStyle: "italic",
		color: "black",
		fontSize: "18px",
		margin: "10px 0 10px 36px"
	},
	userListTitle: {
		marginLeft: "12px",
	},
	userListTitleContainer: {
		display: "flex",
		margin: "10px 0",
		alignItems: "center",
		width: "100%",
		textTransform: "uppercase",
		color: "grey",
	},
	cancelIcon: {
		color: "red",
		cursor: "pointer",
	},
	contentCard: {
		width: "32%",
		display: "flex",
	},
	adminContainer: {
		height: "100%"
	},
	adminContentContainer: {
		height: "75%",
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "space-between",
	}
});

class CreateCompany extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			done: false,
			arrowRef: null,
			modal: {
				open: false
			},
			new_user: {
				first_name: "",
				last_name: "",
				email: "",
				password: "",
				user_type_id: "",
			},
			team_members: [],
			assignees: [],
			customers: [],
			activeStep: 0,
			created: null,
			name: '',
			id: '',
			website: '',
			branding: {
				logo_url: '',
				icon_url: '',
				primary_color: ''
			},
			snackbar: {
				open: false,
			},
			pages: {
				Action_Hub: true,
				Onboarding: true,
				Overview: true,
				Alerts: true,
				Messages: true,
				Products: true,
				Rules: true,
				Software: true,
				Integrations: true
			},
			steps: [
				{
					name: "Identification",
					active: true,
					done: false,
				},
				{
					name: "Customization",
					active: false,
					done: false,
				},
				{
					name: "Administration",
					active: false,
					done: false,
				},
			],
			roles: [],
			rolesMap: {},
		};
		this.base_state = this.state;
		this.getRoles().then((roles) => {
			let new_user = this.state.new_user;
			new_user.user_type_id = roles[0]._id;
			let rolesMap = {};
			roles.forEach( (role) => {
				role.ui_role_name = Role.defaultRoleReadable(role.name);
				rolesMap[role._id] = role;
			});
			this.setState({roles: roles, rolesMap: rolesMap});
			this.getTeam().then((team) => {
				if (team.length > 0) {
					let index = team.findIndex((user) => {
						return user._id === Auth.user()._id;
					});
					team.splice(index, 1);
					team = this.assignRoles(team);
					this.setState({ team_members: team });
				};
			}).catch((error) => {
				this.setState({snackbar: {open: {message: "An error occurred.", result: "error"}}});
			});
		})
		
	}

	assignRoles = (team) => {
		team.forEach( (user) => {
			if (this.state.rolesMap[user.user_type_id]) {
				user.ui_role_name = this.state.rolesMap[user.user_type_id].name;
			} else {
				user.ui_role_name = null;
			}
		});
		return team;
	}

	noCustomersPrompt = () => {
		this.setState({
			modal: {
				"open": true,
				"prompt": "It looks like you haven't created any users for this account yet. No worries; you can create users for this account whenever you're ready.",
				"yesFunction": () => {
					this.closeModal();
					this.assignAssignees().then( () => {
						this.setState({done: true}, function(){
							setTimeout(function () {
								this.props.history.push('/onboarding');
							}.bind(this), 4000);
						});
					}).catch( (error) => {
						this.setState({snackbar: {open: {message: "An error occurred.", result: "error"}}});
					});
				},
				"functionText": "FINISH ACCOUNT CREATION"
			}
		});
	}

	closeModal = () => {
		this.setState({
			modal: {
				"open": false
			}
		});
	}

	getRoles = () => {
		return GetAll("user_types");
	}

	getCustomers = () => {
		let params = { company_id: this.state.id };
		return GetAll("users", params);
	}

	getTeam = () => {
		let params = { company_id: Auth.user().company_id };
		return GetAll("users", params);
	}

	closeSnackbar = () => {
		let newState = {snackbar: {open: false}};
		this.setState(newState);
	}

	emailChange = (email) => {
		let state_object = {};
		state_object.new_user = { ...this.state.new_user };
		state_object.new_user.email = email;

		this.setState(state_object);
	}

	handleChange = (event) => {
		let { input, value } = function () {
			return {
				input: this.target.id,
				value: this.target.value
			};
		}.bind(event)();
		let state_object = {};
		if (this.state.pages[input] !== undefined) {
			value = !this.state.pages[input];
			state_object.pages = { ...this.state.pages };
			state_object.pages[input] = value;
		} else if (this.state.new_user[input] !== undefined) {
			state_object[input] = value;
			state_object.new_user = { ...this.state.new_user };
			state_object.new_user[input] = value;
		} else if (this.state.branding[input] !== undefined) {
			state_object[input] = value;
			state_object.branding = { ...this.state.branding };
			state_object.branding[input] = value;
		} else {
			state_object[input] = value;
		}
		this.setState(state_object);
	}

	render() {
		const { steps, activeStep } = this.state;
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				{this.state.done ?
					""
					:
					<Stepper className={classes.stepOverride} activeStep={activeStep}>
						{steps.map((step) => {
							const props = {};
							const labelProps = {};
							return (
								<Step key={step.name} {...props}>
									<StepLabel {...labelProps}>{step.name}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				}
				
				<div className={classes.stepContainer}>
					{this.showStep(classes)}
				</div>
			</div>
		);
	}

	createCompany = () => {
		return new Promise( (resolve, reject) => {
			let body = {
				_id: this.state.id,
				name: this.state.name,
			};
			//, snackbar: {open: {message: "Company created.", result: "success"}}});
			new Company(body).create().then((post_response) => {
				this.setState({ created: true });
				resolve();
			}).catch((error) => {
				this.setState({ created: false, snackbar: {open: {message: "An error occurred.", result: "error"}}}, function() {
					this.setState(this.base_state);
				});
				reject();
			});
		});
	}

	next = () => {
		if (this.state.activeStep === 1) {
			this.createCompany().then( () => {
				let new_steps = this.state.steps.slice();
				new_steps[this.state.activeStep].active = false;
				new_steps[this.state.activeStep + 1].active = true;
				this.setState({ activeStep: this.state.activeStep + 1, steps: new_steps });
			});
		} else {
			let new_steps = this.state.steps.slice();
			new_steps[this.state.activeStep].active = false;
			new_steps[this.state.activeStep + 1].active = true;
			this.setState({ activeStep: this.state.activeStep + 1, steps: new_steps });
		}
		
	}

	back = () => {
		let new_steps = this.state.steps.slice();
		new_steps[this.state.activeStep].active = false;
		new_steps[this.state.activeStep - 1].active = true;
		this.setState({ activeStep: this.state.activeStep - 1, steps: new_steps });
	}

	showStep = (classes) => {
		switch (this.state.activeStep) {
			case 0:
				return (
					<div>
						{this.idStep(classes)}
						{this.buttons(0, classes)}
					</div>
				);
			case 1:
				return (
					<div>
						{this.customizeStep(classes)}
						{this.buttons(1, classes)}
					</div>
				);
			case 2:
				return (
					<div className={classes.adminContainer}>
						{this.adminStep(classes)}
					</div>
				);
		}
	}

	updateID = (id) => {
		if (id) {
			this.setState({ id: id });
		} else {
			this.setState({ id: '' });
		}
	}

	canProgress = () => {
		if (this.state.id !== '' && this.state.name) {
			return true;
		} else {
			return false;
		}
	}

	canCreateUser = () => {
		if (this.state.new_user.first_name !== "" &&
			this.state.new_user.email !== "" &&
			this.state.new_user.password && this.state.new_user.password.length > 7
		) {
			return true;
		} else {
			return false;
		}
	}

	assignUserArea = (classes) => {
		return (
			<div className={classes.assignArea}>
				{this.state.team_members.map((user) => (
					<UserContainer
						key={user._id}
						name={user.first_name + " " + user.last_name}
						role={user.ui_role_name}
						image={user.logo_url}
						user={user}
						action={this.addAssignee}
						actionIcon={
							<React.Fragment>
								<AddBoxIcon className={classes.assignClick}/>
							</React.Fragment>
						}
					>
					</UserContainer>
				))}
			</div>
		);
	}

	createUser = (created_user) => {
		this.getCustomers().then((users) => {
			users = this.assignRoles(users);
			this.setState({ customers: users });
		}).catch((error) => {
			this.setState({snackbar: {open: {message: "An error occurred while updating the users.", result: "error"}}});
		});
	}

	handleArrowRef = node => {
		this.setState({
			arrowRef: node,
		});
	};

	createAssignedUsers = (classes) => {
		return (
			<div className={classes.userList}>
				<div className={classes.userListHalf}>
					<div className={classes.userListTitleContainer}>
						<div className={classes.userListTitle}>
							Users
						</div>
					</div>
					{this.state.customers.length === 0 ?
						<div className={classes.note}>
							Create user profiles using the form to the left.
						</div>
						: this.state.customers.map((customer) => (
							<UserContainer
								key={customer._id}
								name={customer.first_name + " " + customer.last_name}
								role={customer.ui_role_name}
								image={customer.logo_url}
							/>
						))}
				</div>
				<div className={classes.userListHalf}>
					<div className={classes.userListTitleContainer}>
						<div className={classes.userListTitle}>
							Managers
						</div>
					</div>
					<UserContainer
						key={Auth.user()._id}
						name={"Me (" + Auth.user().first_name + " " + Auth.user().last_name + ")"}
						role={Auth.currentRole().name}
						image={Auth.user().logo_url}
					/>
					{this.state.assignees.map((user) => (
						<UserContainer
							key={user._id}
							name={user.first_name + " " + user.last_name}
							role={user.ui_role_name}
							image={user.logo_url}
							user={user}
							action={this.removeAssignee}
							actionIcon={
								<React.Fragment>
									<CancelIcon className={classes.cancelIcon}/>
								</React.Fragment>
							}
						/>
					))}
				</div>
			</div>
		);
	}

	adminStep = (classes) => {
		if (this.state.created === null) {
			return (
				<div className={classes.loadingContainer}>
					<CircularProgress size={30} className={classes.loading} />
					<div className={classes.loadingText}>Your customer's account is being created. In the next step we'll assign it users.</div>
				</div>
			);
		} else if (this.state.created === false) {
			return (
				<div className={classes.loadingContainer}>
					<div className={classes.loadingText}>There was an error creating this account. Please try again.</div>
				</div>
			);
		} else {
			if (this.state.done) {
				return (
					<div className={classes.loadingContainer}>
						<CheckCircleIcon size={30} className={classes.loading + " " + classes.done} />
						<div className={classes.loadingText}>You're done! Returning to the Onboarding page...</div>
					</div>
				);
			} else {
				return (
					<div className={classes.adminContainer}>
						<div className={classes.stepContentTitle}>
							Onboarding
						</div>
						<div className={classes.stepContentSubtitle}>
							Create user profiles for your new customers and grant members of your team access to manage this account.
						</div>
						<div className={classes.adminContentContainer}>
							<div className={classes.contentCard}>
								<CardContainer
									title="Create User Profiles"
									infoText="Create a new user profile for this account."
									shaded={false}
								>
									<CreateUserForm afterCreate={this.createUser} roles={this.state.roles} company_id={this.state.id}/>
								</CardContainer>
							</div>
							<div className={classes.contentCard}>
								<CardContainer
									title="Assign Managers"
									infoText="Choose team members to help you manage this account."
									children={this.assignUserArea(classes)}
									shaded={true}
								/>
							</div>
							<div className={classes.contentCard}>
								<CardContainer
									title="Account User Profiles"
									infoText="Customer profiles and fellow managers of this account will appear here."
									children={this.createAssignedUsers(classes)}
									shaded={true}
								/>
							</div>
						</div>
						<div className={classes.buttonContainer}>
							<Button onClick={this.finish} variant="contained" color="primary">
								FINISH
							</Button>
						</div>
						<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
					</div>
				);
			}
			
		}

	}

	assignAssignees = () => {
		return new Promise((resolve, reject) => {
			let assignmentPromises = [];
			this.state.assignees.forEach((user) => {
				let updated_companies = user.company_ids;
				updated_companies.push(this.state.id);
				let body = {
					_id: user._id,
					company_ids: updated_companies
				};
				assignmentPromises.push(new User(body).save());
			});
			Promise.all(assignmentPromises).then((results) => {
				resolve();
			}).catch((error) => {
				reject();
			});
		});
	}

	finish = () => {
		if (this.state.customers.length === 0) {
			this.noCustomersPrompt();
		} else {
			this.assignAssignees().then( () => {
				this.setState({done: true}, function(){
					setTimeout(function () {
						this.props.history.push('/onboarding');
					}.bind(this), 4000);
				});
			}).catch( (error) => {
				this.setState({snackbar: {open: {message: "An error occurred.", result: "error"}}});
			});
		}
	}

	addAssignee = (assignee) => {
		let new_assignees = this.state.assignees;
		new_assignees.push(assignee);
		let new_team_members = this.state.team_members;
		let index = this.state.team_members.findIndex((user) => {
			return user._id === assignee._id;
		});
		new_team_members.splice(index, 1);
		this.setState({ team_members: new_team_members, assignees: new_assignees });
	}

	removeAssignee = (assignee) => {
		let new_team_members = this.state.team_members;
		new_team_members.push(assignee);
		let new_assignees = this.state.assignees;
		let index = this.state.assignees.findIndex((user) => {
			return user._id === assignee._id;
		});
		new_assignees.splice(index, 1);
		this.setState({ team_members: new_team_members, assignees: new_assignees });
	}

	customizeStep = (classes) => {
		return (
			<div className={classes.customizeContainer}>
				<div className={classes.stepContentTitle}>
					Customize Your Customer's Portal
				</div>
				<div className={classes.stepContentSubtitle}>
					Define the brand-specific styling and add or remove pages in your customer's portal.
				</div>
				<div className={classes.customizeLeft}>
					<div className={classes.brandingContainer}>
						<TextField
							fullWidth={true}
							id="logo_url"
							label="Brand Logo"
							name="logo"
							margin="normal"
							className={classes.brandInput}
							variant="outlined"
							value={this.state.branding.logo_url}
							onChange={this.handleChange}
						/>
						<TextField
							fullWidth={true}
							id="primary_color"
							label="Primary Color"
							name="color"
							margin="normal"
							className={classes.brandInput}
							variant="outlined"
							value={this.state.branding.primary_color}
							onChange={this.handleChange}
						/>
						<TextField
							fullWidth={true}
							id="icon_url"
							label="Tab Icon"
							name="icon"
							margin="normal"
							className={classes.brandInput}
							variant="outlined"
							value={this.state.branding.icon_url}
							onChange={this.handleChange}
						/>
						<TextField
							fullWidth={true}
							id="text"
							label="Tab Text"
							name="text"
							margin="normal"
							className={classes.brandInput}
							variant="outlined"
							value={this.state.text}
							onChange={this.handleChange}
						/>
					</div>
					<div className={classes.pageToggles}>
						{Object.entries(this.state.pages).map((page_name) => (
							<div key={page_name[0]} className={classes.pageToggleContainer}>
								<span className={classes.pageToggleLabel}>
									{page_name[0].replace(/_/g, " ")}
								</span>
								<Switch
									id={page_name[0]}
									checked={this.state.pages[page_name[0]]}
									onChange={this.handleChange}
									value={page_name[0]}
									color="primary"
									className={classes.switch}
								/>
							</div>
						))}
					</div>
				</div>
				{/* <div className={classes.customizeRight}>
					<img className={classes.mockPortal} src={require('../../images/company_customize.png')} />
				</div> */}
			</div>
		);
	}

	idStep = (classes) => {
		return (
			<div>
				<div className={classes.stepContentTitle}>
					Identify Your Customer's Account
				</div>
				<TextField
					fullWidth={true}
					id="name"
					label="Name"
					name="name"
					margin="normal"
					className={classes.input}
					variant="outlined"
					value={this.state.name}
					onChange={this.handleChange}
				/>
				{this.state.name === '' ?
					<div className={classes.matchMessage}>
						The name of this account cannot be blank.
					</div>
					: ''}
				<CompanyIDInput updateID={this.updateID} initial={this.state.id} />
				<TextField
					fullWidth={true}
					id="website"
					label="Website"
					name="website"
					className={classes.input}
					margin="normal"
					variant="outlined"
					value={this.state.website}
					onChange={this.handleChange}
				/>
			</div>
		);
	}

	buttons = (index, classes) => {
		if (index === 0) {
			return (
				<div className={classes.buttonContainer}>
					<Button disabled={!this.canProgress()} onClick={this.next} variant="contained" color="primary">
						Next
					</Button>
				</div>
			);
		} else if (index === this.state.steps.length - 1) {
			return (
				<div className={classes.buttonContainer}>
					<Button onClick={this.back} variant="contained" color="primary">
						Back
					</Button>
				</div>
			);
		}
		else {
			return (
				<div className={classes.buttonContainer}>
					<Button onClick={this.back} variant="contained" color="primary">
						Back
					</Button>
					<Button className={classes.button} disabled={!this.canProgress()} onClick={this.next} variant="contained" color="primary">
						Next
					</Button>
				</div>

			);
		}
	}
}

export default withStyles(styles)(withTheme()(CreateCompany));
