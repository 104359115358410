import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import LookupInput from '../Inputs/LookupInput';
import { GetAll } from '../../services/CLURDUtilities';
import Device from '../../services/DataModels/Device';
import Tooltip from '@material-ui/core/Tooltip';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Loading from '../DisplayOriented/Loading';
import Button from '@material-ui/core/Button';
import Permissions from '../../services/Permissions';

//icons
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

const styles = theme => ({
	prompt: {
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	modalWrapper: {
		fontFamily: "Inter",
		height: "100%",
		minHeight: "300px",
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
	},
	inputIcon: {
		color: "#8e8e93",
		margin: "8px",
	},
	buttonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "200px",
	},
	saveButton: {
		marginLeft: "12px"
	},
	link: {
		marginLeft: "40px",
		paddingLeft: 0,
		paddingRight: 0,
	},
});

class AddConfig extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			device_config: null,
			config_options: null,
			show_errors: false,
			no_config: true
		};
		this.can_create = Permissions.allow(["create"], "device_config", this.props.device.company_id);
		this.load_configs();
	}

	load_configs = () => {
		GetAll("device_configs", {device_type_id: this.props.device.device_type_id}).then( (configs) => {
			configs = configs.map( (config) => ({value: config._id, label: config.name}));
			this.setState({config_options: configs});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_config_change = ({value}) => {
		this.setState({device_config: value || null, show_errors: value ? false : true});
	}

	save_device = () => {
		if (this.state.device_config === null) {
			this.setState({show_errors: true});
			return;
		} else {
			let device = {_id: this.props.device._id};
			device.device_config_id = this.state.device_config.value;
			new Device().setData(device).saveOrCreate().then( (result) => {
				this.props.closeModal();
				this.context.openSnackbar("Network config added to this device.", "success");
				this.props.onSave(result);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			})
		}
	}

	create_config = () => {
		this.props.closeModal()
		this.props.tabHostProxy.addTab("config", {company_id: this.props.device.company_id, device_type_id: this.props.device.device_type_id});
	}

	render() {
		const { classes, closeModal } = this.props;
		const { device_config, config_options } = this.state;
		if (config_options === null) {
			return (
				<div className={classes.modalWrapper}>
					<Loading></Loading>
				</div>);
		}
		if (config_options.length === 0 && !this.can_create) {
			return (
				<div className={classes.modalWrapper}>
					<div className={classes.prompt}>
						Add a Network Config
					</div>
					<div className={classes.lesserPrompt}>
						No network configs exist for this device and you don't have the permissions to create one. You'll need to ask your account manager for help.
					</div>
					<div className={classes.buttonsContainer}>
						<Button variant="contained" color="primary" onClick={closeModal}>
							cancel
						</Button>
					</div>
				</div>
			)
		}
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Add a Network Config
				</div>
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Network Config">
						<SettingsIcon />
					</Tooltip>
					{config_options === null ? <Loading /> :
						<LookupInput
							priorState={{ suggestions: config_options, values: device_config }}
							label="Network Config"
							single
							emitChange={this.handle_config_change}
							error={this.state.show_errors}
							error_message="Please select a network config"
						/>
					}
				</div>
				{this.can_create ? <Button color="primary" onClick={this.create_config} className={classes.link}>
					Create New Network Config
				</Button> : ""}
				<div className={classes.buttonsContainer}>
					<Button color="primary" onClick={closeModal}>
						cancel
					</Button>
					<Button className={classes.saveButton} variant="contained" color="primary" size="large" onClick={this.save_device}>
						Save
					</Button>
				</div>
			</div>
		);
	}
}
AddConfig.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(AddConfig));