import DataModel from "./DataModel";
import { CREATE, LIST, UPDATE, READ, DELETE } from '../CLURD';
import Auth from '../Auth';
import { AssignNestedModels, GetAll} from '../CLURDUtilities';

class Company extends DataModel {
	constructor(data = BLANK) {
		super('companies', data)
	}

	create() {
		if (this.data.created_at) {
			return Promise.reject("Account already exists.");
		} else {
			return CREATE(this.endpoint, this.data, Auth.token());
		}
	}

	// Given a parent company and a list of other companies,
	// this function returns the list of companies that descend
	// from the parent.
	// Yes, this is easily O(n^2) or maybe worse. An improvement would
	// be to build a tree on the backend.
	static flattenedListOfDescendants(parentCompanyId, candidateList) {
		let companyIds = new Set();
		companyIds.add(parentCompanyId);
		let addedParentId = true;
		while(addedParentId) {
			addedParentId = false;
			candidateList.forEach(function(company) {
				if (companyIds.has(company.company_id)) {
					if (!companyIds.has(company._id)) {
						companyIds.add(company._id)
						addedParentId = true;
					}
				}
			});
		}

		let descendents = candidateList.filter((company) => companyIds.has(company.company_id));
		return descendents;
	}

	static prepForList(accounts) {
		return AssignNestedModels("companies", "company_id", accounts, "accounts");
	}

	static getAllTags() {
		return new Promise( (resolve, reject) => {
			//todo once the field is queryable to only see non empty device tag arrays
			// let params = {device_tags_ne: null}
			GetAll("companies").then( (companies) => {
				let tags = companies.reduce( (total, {device_tags}) => {
					if (device_tags && device_tags.length > 0) {
						device_tags.forEach( (tag) => {
							total[tag] = tag;
						});
					}
					return total;
				}, {});
				if (tags) {
					tags = Object.entries(tags).map( ([key]) => (key));
					resolve(tags);
				} else {
					resolve([]);
				}
			}).catch( (error) => {
				reject(error);
			});
		});
	}
}


const BLANK = {
	name: "",
	website: "",
	branding: {
		logo_url: "",
		icon_url: "",
		primary_color: "",
		logo_background_color: "",
		portal_title: "",
	},
	inherited_integration_ids: [],
	inherited_device_type_ids: []
}

export default Company;
