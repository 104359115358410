import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TypesList from '../../TypesSpecific/TypesList';
import Loading from '../../DisplayOriented/Loading';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';

//services
import Auth from '../../../services/Auth';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import { AssignNestedModels, GetAll } from '../../../services/CLURDUtilities';
import DeviceType from '../../../services/DataModels/DeviceType';


class TypesTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			items: null,
			page_data: {
				page: 1,
				per_page: 12,
				page_meta: true,
				total: null
			},
		};
		this.load();
		this.props.tabHostProxy.setRootRefresh(this.load);
	}


	load = () => {
		if (this.state.items !== null) this.setState({items: null});
		this.load_types();
	}

	load_types = () => {
		const classes = this.props.classes;
		let params = Object.assign(this.state.page_data, {});
		delete params.total;
		new DeviceType().listFromAPI(params).then( (result) => {
			let total = result.total;
			let types = result.items;
			DeviceType.loadRequirements(types).then( () => {
				this.setState( (state) => {
					state.items = types;
					state.page_data = {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: total
					};
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
			});
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	page_change = (params) => {
		this.setState( (state) => {
			state.page_data.page = params.page;
			state.page_data.per_page = params.per_page;
			return state;
		}, this.load);
	}

	render() {
		const { tabHostProxy, classes } = this.props;
		const { items, page_data } = this.state;
		return (
			<div className={classes.container}>
				<TypesList
					page_change={this.page_change}
					page_data={page_data}
					items={items}
					tabHostProxy={tabHostProxy}
				/>
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		container: {
			display: "flex",
			width: "100%",
		},
	});
};



TypesTab.contextType = SnackbarContext;

export default compose(
	withRouter,
	withStyles(styles),
)(TypesTab);