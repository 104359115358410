import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Loading from '../DisplayOriented/Loading';
import ContentLoader from "react-content-loader"

//icons
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import DeleteIcon from '@material-ui/icons/Delete';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

//mui
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

let counter = 0;
function createData(name, manufacturer, model, online_status, config) {
	counter += 1;
	return { id: counter, name, manufacturer, model, online_status, config };
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {

	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { onSelectAllClick, perPage, selectOne, noSelect, order, orderBy, numSelected, rowCount, headings, root_class, noCheckBox, classes, noPadding } = this.props;
		return (
			<TableHead classes={{root: root_class}}>
				<TableRow key={"header"}>
					{noCheckBox ? <TableCell style={{display: "none"}}/> : <TableCell padding="checkbox">
					</TableCell>}
					{headings.map( (row) => {
						if (row.sortable) {
							return (
								<TableCell
									key={row.label}
									align={row.align}
									padding={'default'}
									className={noPadding ? classes.noPadding : ""}
									sortDirection={orderBy === row.field ? order : false}
								>
									<Tooltip
										title="Sort"
										placement={row.align  ? 'bottom-end' : 'bottom-start'}
										enterDelay={300}
									>
										<TableSortLabel
											classes={{root: classes.headingRoot}}
											active={orderBy === row.field}
											direction={order}
											onClick={this.createSortHandler(row.field)}
										>
											{row.label}
										</TableSortLabel>
									</Tooltip>
								</TableCell>
							);
						} else {
							return (
								<TableCell
									classes={{root: classes.headingRoot}}
									className={noPadding ? classes.noPadding : ""}
									key={row.label}
									align={row.align}
									padding={'default'}
									sortDirection={false}
								>
									{row.label}
								</TableCell>
							);
						}
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%",
		overflow: "hidden",
		position: "relative",
		boxShadow: "unset",
		borderRadius: "unset",
	},
	loaderWrapper: {
		height: "24px",
		overflow: "hidden",
		display: "initial",
	},
	noBoxShadow: {
		boxShadow: "unset"
	},
	table: {
		minWidth: "100%",
		maxHeight: "100%",
		overflow: "scroll",
		flexWrap: "wrap",
		display: "table",
		paddingBottom: "18px",
	},
	tableWrapper: {
		overflow: 'scroll',
		height: "calc(100% - 132px)",
	},
	notFound: {
	},
	paginationContainer: {
		display: "flex",
		alignItems: "center",
		float: "right",
		margin: "4px",
		fontSize: "14px",
	},
	pageButton: {
		padding: "4px",
		color: "#8e8e93"
	},
	pageButtonDisabled: {
		color: "rgba(0, 0, 0, 0.24)",
		cursor: "unset"
	},
	noData: {
		margin: "12px",
		display: "flex",
	},
	itemsContainer: {
		overflow: "auto"
	},
	fullWidth: {
		width: "100%"
	},
	tableContents: {
		width: "100%"
	},
	popper: {
		zIndex: 1,
		maxWidth: "33vw",
		minWidth: "33vw",
		position: "relative",
	},
	popperClose: {
		position: "absolute",
		top: "12px",
		right: "12px",
		width: "24px",
		height: "24px",
		cursor: "pointer"
	},
	cell: {
		overflow: "hidden",
		display: "flex",
		alignItems: "center"
	},
	tableHeader: {
	},
	headingRoot: {
		whiteSpace: "nowrap",
		fontSize: "13px",
		lineHeight: "24px",
		color: "#aeaeb2",
	},
	rowSelected: {
		backgroundColor: "#2b8deb36"
	},
	noPadding: {
		padding: 0,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		maxWidth: "60px",
		overflow: "hidden"
	},
	paginationText: {
		fontSize: "14px",
		color: "#636366",
	},
});

const StyledPopper = styled(Popper)`&&{
	z-index: 1;
	&[x-placement*="bottom"] .arrow{
  
	  width: 0; 
	  height: 0; 
	  border-left: 1em solid transparent;
	  border-right: 1em solid transparent;
	  border-bottom: 1em solid white;
	  margin-top: -0.9em;
	  
	  &:before {
		border-width: '0 1em 1em 1em';
		border-color: 'transparent transparent white transparent';
	  }
	}
  
	&[x-placement*="top"] .arrow{
  
	  bottom: 0;
	  width: 0; 
	  height: 0; 
	  border-left: 1em solid transparent;
	  border-right: 1em solid transparent;
	  border-top: 1em solid white;
	  margin-bottom: -0.9em;
  
	  &:before {
		border-width: 1em 1em 0 1em;
		border-color: white transparent transparent transparent;
	  }
	}
  
	&[x-placement*="right"] .arrow{
  
	  left: 0;
	  width: 0; 
	  height: 0; 
	  border-top: 1em solid transparent;
	  border-bottom: 1em solid transparent;
	  border-right: 1em solid white;
	  margin-left: -0.9em;
  
	  &:before {
		border-width: 1em 1em 1em 0;
		border-color: transparent white transparent transparent;
	  }
	}
  
	&[x-placement*="left"] .arrow{
	  
	  right: 0;
	  width: 0; 
	  height: 0; 
	  border-top: 1em solid transparent;
	  border-bottom: 1em solid transparent;
	  border-left: 1em solid white;
	  margin-right: -0.9em;
  
	  &:before {
		border-width: 1em 0 1em 1em;
		border-color: transparent transparent transparent white;
	  }
	}
  
	.arrow {
	  position: absolute;
	  font-size: 7px;
	  width: 3em;
	  height: 3em;
  
	  &:before {
		content: '""',
		margin: auto;
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
	  }
	}
  
	.popper-content {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  background-color: white;
	  color: white;
	  height: 90px;
	  width: 160px;
	}
  
  }`;

class TableList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			order: 'asc',
			orderBy: null,
			hover: null,
			contents: null,
			arrowRef: null
		};
		this.props = props;
	}

	handleRequestSort = (event, orderBy) => {
		let order = '';
		if (this.state.orderBy === orderBy && this.state.order === 'desc') {
			order = 'asc';
		} else {
			order = 'desc';
		}
		this.setState({order: order, orderBy: orderBy});
		this.props.perform_sort({order: order, order_by: orderBy});
	};

	get_nested = (item, column) => {
		let fields = column.value.split(".");
		let value = item[fields[0]];
		fields.forEach( (field, index) => {
			if (index === 0) return;
			if (value) value = value[field];
		});
		return value;
	}
	
	render_date = (date) => {
		function showDate(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleDateString();
		}

		function showTime(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleTimeString();
		}
		return (
			<div >
				<div style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>
					{showTime(date)}
				</div>
				<div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>
					{showDate(date)}
				</div>
			</div>
		);
	}

	row_hover = (event, item) => {
		this.setState({hover: event.currentTarget, contents: item});
	}

	unhover = () => {
		this.setState({hover: null, contents: null});
	}

	handle_arrow_ref = node => {
		this.setState({
		  arrowRef: node,
		});
	};

	render_hover = () => {
		const classes = this.props.classes;
		let anchorEl = this.state.hover;
		return (
			<StyledPopper
				open={true}
				id="hover"
				anchorEl={anchorEl}
				className={classes.popper}
				placement='bottom'
				modifiers={{
					flip: {
					  enabled: true,
					},
					arrow: {
					  enabled: true,
					  element: this.state.arrowRef,
					},
				}}
			>
				<span className="arrow" ref={this.handle_arrow_ref} />
				<CloseIcon onClick={this.unhover} className={classes.popperClose}/>
				{this.props.hover(this.state.contents)}
			</StyledPopper>
		);
	}

	render_row_area = () => {
		const { perPage, items, headings, classes } = this.props;
		const { page } = this.state;
		if (items.length === 0) {
			return (
				<TableRow>
					{headings.map( (column, index) => {
						return (
							<TableCell  align={column.align} key={"nodata_" + index} align={column.align}>{index === 0 ? "No Data" : ""}</TableCell>
						);
					})}
				</TableRow>
			);
		} else if (perPage != null) {
			let start = (perPage * (page - 1)) + 1;
			let shown = items.slice(start - 1, start + perPage - 1);
			let end = shown.length + start - 1; 
			return (
				<React.Fragment>
					{this.render_rows(shown)}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{this.render_rows(items)}
				</React.Fragment>
			);
		}
	}

	render_pagination_info = () => {
		const { page } = this.state;
		const { perPage, items, classes } = this.props;
		let start = (perPage * (page - 1)) + 1;
		let shown = items.slice(start - 1, start + perPage - 1 );
		let end = shown.length + start - 1; 
		const total = this.props.items.length;
		let next_disabled = end === total;
		let before_disabled = start <= 1;
		return (
			<div className={classes.paginationContainer}>
				<div className={classes.paginationText}>
					{start} - {end} of {total}
				</div>
				<div className={classes.pageArrowsContainer}>
					<IconButton disabled={before_disabled} className={classes.pageButton} onClick={() => this.page_change(-1)} aria-label="page_left">
						<NavigateBeforeIcon />
					</IconButton>
					<IconButton disabled={next_disabled} className={classes.pageButton} onClick={() => this.page_change(1)} aria-label="page_right">
						<NavigateNextIcon />
					</IconButton>
				</div>
			</div>
		);
	}

	page_change = (difference) => {
		this.setState( (state) => {
			state.page += difference;
			return state;
		});
	}

	render_rows = (items) => {
		const { classes, headings, noCheckBox, noPadding } = this.props;
		return (
			<React.Fragment>
				{items.map( (item, index) => (
						<TableRow
							key={item ? item._id || item.id || (item.device ? item.device._id : index) : index}
							className={(item === this.state.contents ? classes.rowSelected : "") + (item ? item.device ? item.device.table_highlighted ? classes.rowSelected : "" : "" : "")}
							tabIndex={-1}
							onMouseEnter={(event) => this.props.hover ? this.row_hover(event, item) : null}
							aria-owns={this.state.hover ? 'hover' : undefined}
							aria-describedby={'hover'}
							aria-haspopup="true"
						>
							{noCheckBox ? <TableCell style={{display: "none"}}/> : <TableCell key={"ns" + item._id} padding="checkbox">
							</TableCell>}
							{headings.map( (column) => {
								let item_value = column.nested_field ? this.get_nested(item, column) : column.value ? item[column.value] : item[column.field];
								if (item_value == null || item_value === "" || (column.content === "function" && item_value(classes) === null)) {
									return <TableCell padding={'default'} className={classes.notFound} key={item._id + '_' + column.field} align={column.align}>Unset</TableCell>
								} else {
									return (
										<TableCell padding={'default'} className={noPadding ? classes.noPadding : ""} key={item._id + '_' + column.field} align={column.align}>{column.content === "function" ? item_value(classes) : column.content === "date" ? this.render_date(item_value) : item_value}</TableCell>
									);
								}
							})}
						</TableRow>
				))}
			</React.Fragment>
		);
	}

	render_table_loading = () => {
		const { headings, classes, noPadding, noCheckBox } = this.props;
		const { orderBy, order } = this.state;
		let placeholders = [0,0,0,0,0,0,0,0,0,0,0,0];
		return (
			<React.Fragment>
				{placeholders.map( (row, row_index) => (
					<TableRow key={"nodata_row" + row_index}>
						{headings.map( (column, index) => (
							<TableCell padding={'default'} className={noPadding ? classes.noPadding : ""} align={column.align} key={"nodata_" + index} align={column.align}>
								<div className={classes.loaderWrapper}>
									<ContentLoader 
										width={100}
										height={12}
										speed={2}
										interval={.25}
										primaryColor="#f3f3f3"
										secondaryColor="#ecebeb"
									>
										<rect x="0" y="0" rx="4" ry="4" width={100} height={12} />
									</ContentLoader>
								</div>
							</TableCell>
						))}
					</TableRow>
				))}
			</React.Fragment>
		);
	}

	render() {
		const { classes, headings, items, perPage, selections, noBoxShadow, noCheckBox, noPadding} = this.props;
		const { page } = this.state;
		return (
			<React.Fragment>
				<Paper 
					onMouseLeave={this.unhover}
					className={classes.root + " " + (noBoxShadow ? classes.noBoxShadow : "")}
				>
					<div className={classes.itemsContainer}>
						<div>
							<Table className={classes.table} aria-labelledby="tableTitle">
								<EnhancedTableHead
									classes={classes}
									noPadding={this.props.noPadding}
									noCheckBox={this.props.noCheckBox}
									root_class={classes.tableHeader}
									numSelected={selections ? selections.length : []}
									onRequestSort={this.handleRequestSort}
									headings={headings}
									orderBy={this.state.orderBy}
									order={this.state.order}
								/>
								<TableBody
									className={classes.tableBody}
								>
									{!items ? this.render_table_loading() : this.render_row_area()}
								</TableBody>
							</Table>
							{this.state.hover ? this.render_hover() : ""}
						</div>
					</div>
				</Paper>
				{perPage != null && items && items.length > 0 ? this.render_pagination_info() : ""}
			</React.Fragment>
		);
	}
}

TableList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme()(TableList));
