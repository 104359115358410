import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import TextInput from '../Inputs/TextInput';
import Button from '@material-ui/core/Button';
import User from '../../services/DataModels/User';
import CardContainer from '../Containers/CardContainer';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = theme => ({
	rootClass: {
		maxHeight: "calc(100% - 70px)",
		overflow: "scroll"
	},
	createUserInput: {
		width: "100%",
		marginBottom: "16px"
	},
	matchMessage: {
		color: "grey",
		fontFamily: "Inter,sans-serif",
		textDecoration: "none",
		fontSize: "16px",
	},
	roleDropdown: {
		width: "100%",
		paddingRight: 0,
		marginTop: "16px",
		marginBottom: "8px",
	},
	createButton: {
		margin: "16px 0 0 auto",
		display: "block",
	},
	createUserForm: {
		boxSizing: "border-box",
		display: "flex",
		padding: "0 12px 12px 12px",
		flexWrap: "wrap",
		maxHeight: "calc(100% - 49px)",
		overflowY: "scroll",
		overflowX: "hidden"
	}
});

class CreateUserForm extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			show: true,
			new_user: {
				first_name: "",
				last_name: "",
				email: "",
				password: "",
				user_type_id: this.props.roles[0]._id,
			},
			email_valid: null
		};
	}

	can_save = () => {
		let show_errors = false;
		const editable_user = this.state.new_user;
		if (editable_user.first_name === "" || this.password_error() || editable_user.email === "" || !editable_user.email_valid || this.company_error() || this.role_error() ) {
			show_errors = true;
		}
		this.setState({ show_errors: show_errors });
		return !show_errors;
	}

	handleChange = (event) => {
		let { input, value } = function () {
			return {
				input: this.target.id,
				value: this.target.value
			};
		}.bind(event)();
		let state_object = {};
		state_object[input] = value;
		state_object.new_user = { ...this.state.new_user };
		state_object.new_user[input] = value;
		this.setState(state_object);
	}

	password_error = () => {
		if (this.state.new_user._id) {
			if (this.state.new_user.ui_password_confirmation === "" && this.state.new_user.password === "") {
				return false;
			} else {
				if (this.state.new_user.ui_password_confirmation.length >= 8 && this.state.new_user.ui_password_confirmation === this.state.new_user.password) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			if ((this.state.new_user.ui_password_confirmation !== "" && this.state.new_user.ui_password_confirmation.length >= 8) && this.state.new_user.ui_password_confirmation === this.state.new_user.password) {
				return false;
			} else {
				return true;
			}
		}
	}

	company_error = () => {
		if (this.state.editable_user.company_id === "") {
			return true;
		} else {
			return false;
		}
	}

	role_error = () => {
		if (this.state.editable_user.user_type_id === "") {
			return true;
		} else {
			return false;
		}
	}

	password_error_message = () => {
		const pwd = this.state.new_user.password;
		const pwd_confirm = this.state.new_user.ui_password_confirmation;
		if ( pwd !== pwd_confirm || pwd.length < 8 ) {
			return "Passwords must match and have at least 8 characters."
		}
	}

	createUser = () => {
		let body = this.state.new_user;
		body.company_id = this.props.company_id;
		body.password_confirmation = this.state.new_user.password;
		let dataModel = new User(body);
		dataModel.create().then((result) => {
			this.props.afterCreate();
			this.setState({
				show: false,
				new_user: {
					first_name: "",
					last_name: "",
					email: "",
					password: "",
					user_type_id: this.props.roles[0]._id
				}
			}, () => this.setState({ show: true }));
			this.context.openSnackbar("User created.", "success");
		}).catch( (error) => {
			if (error === "An error occurred while saving this profile. The email is already in use.") {
				this.setState({ email_valid: false });
			} else {
				this.context.openSnackbar(error, "error");
			}
		});
	}

	closeSnackbar = () => {
		let newState = { snackbar: { open: false } };
		this.setState(newState);
	}

	render() {
		const { classes, show_errors } = this.props;
		return (
			<div className={classes.createUserForm}>
				{this.state.show ?
					<React.Fragment>
						<TextField
							fullWidth={true}
							id="first_name"
							label="First Name*"
							margin="none"
							name="logo"
							margin="normal"
							className={classes.createUserInput}
							variant="outlined"
							value={this.state.new_user.first_name}
							onChange={this.handleChange}
						/>
						{this.state.new_user.first_name === "" ?
							<div className={classes.matchMessage}>
							The user's first name is required.
							</div>
						: ""}
						<TextField
							fullWidth={true}
							id="last_name"
							label="Last Name"
							name="logo"
							margin="none"
							margin="normal"
							className={classes.createUserInput}
							variant="outlined"
							value={this.state.new_user.last_name}
							onChange={this.handleChange}
						/>
						<TextInput
							margin="none"
							value={this.state.new_user.email}
							emitChange={this.handleTextChange}
							priorState={this.state.new_user.email}
							error={this.state.new_user.email === "" && show_errors || this.state.email_valid === false}
							error_message="Invalid username or email"
							label={"Email/Username*"}
							model={"users"}
							field={"email"}
						/>
						{this.state.new_user.email === '' ?
							<div className={classes.matchMessage}>
								The user's email address must be unique.
							</div>
							: ''}
						<TextField
							type="password"
							fullWidth={true}
							margin="none"
							id="password"
							label="Password*"
							name="logo"
							margin="normal"
							className={classes.createUserInput}
							variant="outlined"
							value={this.state.new_user.password}
							onChange={this.handleChange}
						/>
						{this.state.new_user.password.length < 8 ?
							<div className={classes.matchMessage}>
								Passwords must be at least 8 characters long.
							</div>
							: ''}
						<Select
							className={classes.roleDropdown}
							autoWidth={true}
							native
							id="user_type_id"
							value={this.state.new_user.user_type_id}
							onChange={this.handleChange}
							input={
								<OutlinedInput
									name="role"
									labelWidth={0}
									id="outlined-age-native-simple"
								/>
							}
						>
							{this.props.roles.map((role) => (
								<option value={role._id} key={role._id}>
									{role.ui_role_name}
								</option>
							))}
						</Select>
						<div className={classes.matchMessage}>
							Select a role for this user.
						</div>
						<Button className={classes.createButton} onClick={this.createUser} variant="contained" color="primary">
							CREATE
						</Button>
					</React.Fragment>
				: ""
			}
			</div>
		);
	}

	handleTextChange = ({field, value}) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState( (prev_state) => {
			return Object.assign(prev_state.new_user, overwrite);
		});
	}
}

CreateUserForm.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(CreateUserForm));
