import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';

//inputs
import TextInput from './TextInput';
import NumberInput from './NumberInput';
import SwitchInput from './SwitchInput';

//icons
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
	numberField: {
		width: "100%",
	},
	inputContainer: {
		display: "flex",
		alignItems: "center",
		margin: "24px 0 -20px 0"
	},
	intInput: {
		marginBottom: "20px"
	},
	inputWrapper: {
		width: "50%"
	},
	switchWrapper: {
		marginLeft: "12px",
		marginBottom: "28px"
	},
	validContainer: {
		display: "flex",
		alignItems: "center",
		marginBottom: "26px",
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	check: {
		color: theme.palette.green.main,
		marginRight: "4px"
	},
	error: {
		color: theme.palette.red.main,
		marginRight: "4px"
	},
});

class NumberOrHexInput extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			is_hex: false
		}
	}

	change = ({value}) => {
		if (value.length > 8) return;
		this.props.emitChange({label: this.props.label, value: value, field: this.props.field});
	}

	hex_change = ({value}) => {
		this.setState({is_hex: value});
		let new_value = 0;
		if (value) {
			let hex = 0;
			if (!isNaN(this.props.priorState)) {
				hex = this.props.priorState.toString(16);
			}
			new_value = "0x" + hex;
		} else {
			new_value = parseInt(this.props.priorState, 16) || 0;
		}
		this.props.emitChange({label: this.props.label, value: new_value, field: this.props.field});
	}

	hex_check = () => {
		if (!this.state.is_hex) return;
		const classes = this.props.classes;
		let regex = /^0[xX][0-9a-fA-F]+$/g
		const pass = regex.test(this.props.priorState);
		const icon = pass ? <CheckIcon className={classes.check}/> : <ErrorIcon className={classes.error}/>;
		const text = pass ? <span>Valid Hex</span> : <span>Invalid Hex</span>;
		return (
			<div className={classes.validContainer}>
				{icon}
				{text}
			</div>
		)
	}

	render() {
		const {disabled, variant, priorState, label, rootClass, classes } = this.props;
		const value = priorState;
		let root_class = rootClass || classes.numberField;
		const input = !this.state.is_hex ? 
			<div className={classes.intInput}>
				<NumberInput
					variant={"outlined"}
					label={label}
					priorState={value}
					emitChange={this.change}
				/>
			</div>
		:
			<div>
				<TextInput
					label={label}
					variant={variant}
					priorState={value}
					emitChange={this.change}
				/>
			</div>
		;
		return (
			<div className={classes.inputContainer}>
				<div className={classes.inputWrapper}>{input}</div>
				<div className={classes.switchWrapper}>
					<SwitchInput
						initial={this.state.is_hex}
						emitChange={this.hex_change}
						onLabel="Hex"
						offLabel="Hex"
						location="start"
					/>
				</div>
				{this.hex_check()}
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(NumberOrHexInput));
