import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class DeviceDataUsageRecords extends DataModel {
	constructor() {
		super("device_data_usage_records");
	}
}

export default DeviceDataUsageRecords;
