import React from 'react';
import Loading from '../DisplayOriented/Loading';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import SwitchInput from "../Inputs/SwitchInput";

//icons
import ErrorIcon from '@material-ui/icons/Error';
import HearingIcon from '@material-ui/icons/Hearing';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';
import CodeIcon from '@material-ui/icons/Code';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MemoryIcon from '@material-ui/icons/Memory';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import IngestorTypeIcon from '@material-ui/icons/Equalizer';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Ingestor from '../../services/DataModels/Ingestor';
import Company from '../../services/DataModels/Company';
import Auth from '../../services/Auth.js';
import Permissions from '../../services/Permissions';

class CreateIngestorForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			editable_copy: {
				type: 'edge',
				company_id: '',
				translator_id: '',
				name: '',
				listener_type: '',
				listener: {},
				handler_type: 'passthrough',
				handler: {}
			},
			show_errors: false,
			editing: false,
			account_options: null,
			translator_options: null,
			verifying: false,
			submitting: false
		};
		if (this.props.ingestor._id) {
			this.state.editing = true;
			let editable_ingestor = Ingestor.convert_for_ui(this.props.ingestor);
			this.state.editable_copy = editable_ingestor;
		}
		this.prep_options();
		this.set_account_setting();
		this.setup_translator();
	}

	prep_options = () => {
		this.ingestor_type_options = [
			{ value: 'edge', display: "Edge" },
			{ value: 'cloud', display: "Cloud" }
		]
		this.listener_options = Object.entries(Ingestor.listenerTypes()).map(([key, { display }]) => ({ value: key, display: display }));
		this.listener_options.push({ value: '', display: "Select a Listener Type*" });
		this.handler_options = Object.entries(Ingestor.handlerTypes()).map(([key, { display }]) => ({ value: key, display: display }));
		// this.handler_options.push({ value: '', display: "Select a Handler Type*" });
	}

	setup_translator = () => {
		this.load_translator_options();
	}

	translator_option_for_id = (translator_id) => {
		const translator_options = this.state.translator_options;
		if (translator_options) {
			return translator_options.find(opt => opt.value && opt.value === translator_id);
		}
		return null;
	}

	set_account_setting = () => {
		this.show_account_selection = false;
		this.state.editable_copy.company_id = this.state.editing ? this.props.ingestor.company_id : Auth.currentCompany()._id;
		let sub_accounts = Auth.currentUser().company_ids;
		if (sub_accounts.length > 1) {
			let valid_accounts = sub_accounts.filter((account) => Permissions.allow(["create"], "ingestor", account._id));
			if (valid_accounts.length > 1) {
				this.show_account_selection = true;
				this.load_account_options(valid_accounts);
			}
		}
	}

	load_account_options = (account_ids) => {
		let params = { _id_in: account_ids };
		GetAll("companies", params).then((result) => {
			let accounts = result.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
			this.setState({ account_options: accounts.map((account) => ({ display: account.name, value: account._id })) });
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_translator_options = () => {
		let params = { company_id: this.state.editable_copy.company_id, type_notlike: '_cmd' };
		GetAll("translators", params).then((result) => {
			let translators = result;
			translators.push({ name: "None", _id: '', type: 'none' });
			// translators.push({ name: "Select a Translator*", _id: '' });
			this.setState((state) => {
				state.translator_options = translators.map((translator) => ({ display: translator.name, value: translator._id, translator_type: translator.type }));
				if (!state.translator_options.find((tr) => tr.value === state.editable_copy.translator_id)) {
					state.editable_copy.translator_id = "";
				}
				return state;
			});
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	onFormUpdate = ({ field, value }, nested) => {
		this.setState((state) => {
			let new_editable_copy = state.editable_copy;
			if (nested) {
				new_editable_copy[nested][field] = value;
			} else {
				new_editable_copy[field] = value;
			}
			if (field === "listener_type") {
				new_editable_copy.listener = Ingestor.getDefaultListener(new_editable_copy.listener_type);
			}
			if (field === "handler_type") {
				new_editable_copy.handler = Ingestor.getDefaultHandler(new_editable_copy.handler_type);
			}
			return state;
		}, () => {
			if (field === "company_id" && value !== "") {
				this.load_translator_options();
			}
		});
	}

	basic_validation_failed = () => {
		const ingestor = this.state.editable_copy;
		return (ingestor.name === '' || (this.show_account_selection && ingestor.company_id == '') || ingestor.listener_type === '' || ingestor.handler_type === '');
	}

	onSubmit = (event) => {
		event.preventDefault();
		this.setState({ verifying: true });
		let ingestor = this.state.editable_copy;
		if (this.basic_validation_failed() || this.render_errors() !== null) {
			this.setState({ show_errors: true, verifying: false });
			return;
		}
		this.setState({ submitting: true });
		this.create(ingestor);
	}

	create = (ingestor, files) => {
		let modified_ingestor = Ingestor.convert_for_api(ingestor);
		new Ingestor(modified_ingestor).setData(modified_ingestor).saveOrCreate().then((result) => {
			let message = this.state.editing ? "Ingestor saved." : "Ingestor created.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate(result);
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	render_account_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors, account_options } = this.state;
		if (!this.show_account_selection) return "";
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.company_id === ""}
					error_message="Please select an account."
					label="Account"
					field="company_id"
					emitChange={this.onFormUpdate}
					priorState={this.state.editable_copy.company_id}
					options={account_options}
				/>
			</div>
		);
	}

	render_name_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<DescriptionIcon className={classes.inputIcon} />
				<TextInput
					id="name"
					label="Name*"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.name}
					field="name"
					error={show_errors && editable_copy.name === ""}
					error_message={"Please define a name for this ingestor."}
				/>
			</div>
		);
	}

	render_ingestor_type = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<IngestorTypeIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.type === ""}
					error_message="Please select an ingestor type."
					label="Ingestor Type"
					field="type"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.type}
					options={this.ingestor_type_options}
				/>
			</div>
		);
	}

	render_translator = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors, translator_options } = this.state;
		let input = null;
		if (editable_copy.company_id === "") {
			input = <span className={classes.set}>Select the account before selecting the translator.</span>;
		} else if (translator_options !== null && translator_options.length === 1) {
			input = <span className={classes.set}>There are no translators for this account.</span>;
		} else if (translator_options === null) {
			input = <Loading />;
		} else {
			input = <SelectInput
				error={false}
				error_message="Please select a translator."
				label="Translator"
				field="translator_id"
				emitChange={this.onFormUpdate}
				priorState={this.state.editable_copy.translator_id}
				options={translator_options}
			/>;
		}
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<SwapHorizIcon className={classes.inputIcon} />
				{input}
			</div>
		);
	}

	render_listener_type = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<HearingIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.listener_type === ""}
					error_message="Please select a listener type."
					label="Listener Type"
					field="listener_type"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.listener_type}
					options={this.listener_options}
				/>
			</div>
		);
	}

	render_listener = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		if (editable_copy.listener_type === '') return;
		const inputs = Ingestor.listenerTypes(editable_copy.listener_type).inputs;
		if (inputs.length === 0) {
			return <React.Fragment />
		} else {
			return (
				<div className={classes.inputsContainer}>
					{(inputs && inputs.length > 0) ? <div className={classes.infoText}>Please fill out the following inputs for the listener type you've selected.</div> : ""}
					{inputs.map((input) => Ingestor.get_input(input, editable_copy.listener, (input_data) => this.onFormUpdate(input_data, "listener"), classes))}
				</div>
			);
		}
	}

	render_handler_type = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<PlaylistAddCheckIcon className={classes.inputIcon} />
				<SelectInput
					error={false}
					error_message="Please select a handler type."
					label="Handler Type*"
					field="handler_type"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.handler_type || 'passthrough'}
					options={this.handler_options}
				/>
			</div>
		);
	}

	render_handler = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		if (editable_copy.handler_type === '' || editable_copy.handler_type === "passthrough") return;
		const inputs = Ingestor.handlerTypes(editable_copy.handler_type).inputs;
		return (
			<div className={classes.inputsContainer}>
				{(inputs && inputs.length > 0) ? <div className={classes.infoText}>Please fill out the following inputs for the handler type you've selected.</div> : ""}
				{inputs.map((input) => Ingestor.get_input(input, editable_copy.handler, (input_data) => this.onFormUpdate(input_data, "handler"), classes))}
			</div>
		);
	}

	render_errors = () => {
		const classes = this.props.classes;
		const ingestor = this.state.editable_copy;
		const translator_options = this.state.translator_options;
		let errors = [];
		let error_text = "";
		const icon = <ErrorIcon className={classes.errorIcon} />;

		if ((ingestor.type && ingestor.type != "") && (ingestor.listener_type && ingestor.listener_type != "")) {
			let listener_type_allowed_for_ingestor_type =
				Ingestor.listenerTypeAllowedForIngestorType(ingestor.listener_type, ingestor.type);
			if (!listener_type_allowed_for_ingestor_type) {
				error_text = "The listener type is incompatible with the " + ingestor.type + " ingestor type.";
				errors.push(
					<div key="http_server_error" className={classes.errorContainer}>
						{icon}
						<span>{error_text}</span>
					</div>
				);
			}
		}
		if ((ingestor.type && ingestor.type != "") && (ingestor.handler_type && ingestor.handler_type != "")) {
			let handler_type_allowed_for_ingestor_type =
				Ingestor.handlerTypeAllowedForIngestorType(ingestor.handler_type, ingestor.type);
			if (!handler_type_allowed_for_ingestor_type) {
				error_text = "The handler type is incompatible with the " + ingestor.type + " ingestor type.";
				errors.push(
					<div key="http_server_error" className={classes.errorContainer}>
						{icon}
						<span>{error_text}</span>
					</div>
				);
			}
		}

		if (ingestor.type && ingestor.type != "" && ingestor.translator_id != null && ingestor.translator_id != "") {
			let translator = this.translator_option_for_id(ingestor.translator_id);
			if ((translator && translator.translator_type && translator.translator_type != "")) {
				let translator_type_allowed_for_ingestor_type =
					Ingestor.translatorTypeAllowedForIngestorType(translator.translator_type, ingestor.type);
				if (!translator_type_allowed_for_ingestor_type) {
					error_text = "The translator type is incompatible with the " + ingestor.type + " ingestor type.";
					errors.push(
						<div key="http_server_error" className={classes.errorContainer}>
							{icon}
							<span>{error_text}</span>
						</div>
					);
				}
			}
		}
		if (
			(ingestor.listener_type === "http" && ingestor.handler_type !== '' && ingestor.handler_type !== "router") ||
			(ingestor.handler_type === "router" && ingestor.listener_type !== '' && ingestor.listener_type !== "http")
		) {
			error_text = "An HTTP Server listener must be combined with a Router handler.";
			errors.push(
				<div key="http_server_error" className={classes.errorContainer}>
					{icon}
					<span>{error_text}</span>
				</div>
			);
		}
		if (
			(ingestor.listener_type === "dbus_signal" && ingestor.handler_type !== '' && ingestor.handler_type !== "dbus") ||
			(ingestor.handler_type === "dbus" && ingestor.listener_type !== '' && ingestor.listener_type !== "dbus_signal")
		) {
			error_text = "A D-Bus Signal listener must be combined with a D-Bus handler.";
			errors.push(
				<div key="dbus_error" className={classes.errorContainer}>
					{icon}
					<span>{error_text}</span>
				</div>
			);
		}

		if (!Ingestor.hexCheck(ingestor)) {
			error_text = "Ensure your hex values are correctly formatted.";
			errors.push(
				<div key="hex_error" className={classes.errorContainer}>
					{icon}
					<span>{error_text}</span>
				</div>
			);
		}
		if (!Ingestor.validateRoutes(ingestor)) {
			error_text = "Ensure that none of the handler's routes are missing methods and/or a path.";
			errors.push(
				<div key="target_host_map_error" className={classes.errorContainer}>
					{icon}
					<span>{error_text}</span>
				</div>
			);
		}
		if (!Ingestor.validateKeys(ingestor)) {
			error_text = "Ensure that none of the handler's keys are empty strings.";
			errors.push(
				<div key="empty_key_error" className={classes.errorContainer}>
					{icon}
					<span>{error_text}</span>
				</div>
			);
		}
		if (errors.length === 0) return null;
		return errors.map((error) => (error));
	}

	render() {
		const { classes, theme } = this.props;
		const { editable_copy, show_errors, type_options, account_options, submitting } = this.state;
		this.validation = Ingestor.validate(editable_copy);
		return (
			<div className={classes.container}>
				{submitting || (this.show_account_selection && account_options == null) ? <Loading />
					:
					<form onSubmit={this.onSubmit}>
						{this.render_account_input()}
						{this.render_name_input()}
						{this.render_ingestor_type()}
						{this.render_listener_type()}
						{this.render_listener()}
						{this.render_handler_type()}
						{this.render_handler()}
						{this.render_translator()}
						{this.render_errors()}
						<div className={classes.buttonContainer}>
							<Button
								onClick={this.props.onCancel}
								color="primary"
								className={classes.buttonOverride}
								aria-label="cancel"
							>
								Cancel
							</Button>
							<Button
								disabled={this.state.verifying}
								variant="contained"
								color="primary"
								size="large"
								className={classes.buttonOverride}
								aria-label="create"
								type="submit"
							>
								{this.state.editing ? "Save" : "Create"} Ingestor
							</Button>
						</div>
					</form>}
			</div>
		)
	}
}

CreateIngestorForm.contextType = SnackbarContext;

const styles = (theme) => {
	return ({
		container: {
			width: "100%",
			fontFamily: "Inter"
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "42px"
		},
		selectInputWrapper: {
			marginTop: "18px",
			minHeight: "78px",
			marginBottom: "26px"
		},
		set: {
			color: "grey",
			paddingBottom: "24px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		button: {
			marginLeft: "8px"
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		buttonOverride: {
			marginLeft: "8px"
		},
		mainButton: {
			margin: "0 0 12px 12px"
		},
		deleteIcon: {
			marginBottom: "26px",
			cursor: "pointer",
			color: "#8e8e93",
			"&:hover": {
				color: "#0263fc",
			}
		},
		deleteOIDIcon: {
			cursor: "pointer",
			marginBottom: "6px",
			color: "#8e8e93",
			"&:hover": {
				color: "#0263fc",
			}
		},
		buttonIcon: {
			marginRight: "8px",
			display: "flex",
		},
		targetHostMapPairRow: {
			display: "flex",
			alignItems: "center",
			margin: "0 0 12px 12px"
		},
		inputsContainer: {
			marginLeft: "36px",
			borderLeft: "solid lightgrey 1px",
			paddingLeft: "12px",
			marginBottom: "48px",
			paddingTop: "12px"
		},
		infoText: {
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "12px",
			fontSize: "14px",
		},
		numberInput: {
			marginBottom: "16px"
		},
		generalWrapper: {
			marginTop: "34px"
		},
		label: {
			color: "#8e8e93",
			marginBottom: "12px",
			fontSize: "14px",
		},
		targetHostMapItem: {
			marginBottom: "-18px",
			marginRight: "8px"
		},
		noPairings: {
			margin: "0 0 12px 12px",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "14px",
		},
		selectInput: {
			marginTop: "24px"
		},
		requestSelectInput: {
			marginTop: "34px"
		},
		filterContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		filterContainerItem: {
			marginRight: "8px"
		},
		deviceTypeMappingContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		deviceTypeMappingContainerItem: {
			marginRight: "18px"
		},
		routeContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		routeInputSelect: {
			marginBottom: "14px",
			marginRight: "8px",
			width: "33%"
		},
		routeInput: {
			marginRight: "8px",
			width: "33%"
		},
		errorIcon: {
			color: theme.palette.red.main,
			marginRight: "4px"
		},
		errorContainer: {
			display: "flex",
			alignItems: "center",
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.87)",
		}
	})
};

export default withStyles(styles)(withTheme()(CreateIngestorForm));

