import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import PaginationContainer from '../Containers/PaginationContainer';
import VerticalTabs from '../DisplayOriented/VerticalTabs';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

//services
import Permissions from '../../services/Permissions';
import Report from '../../services/DataModels/Report';
import DeviceErrors from '../../services/DataModels/DeviceErrors';
import BulkResponse from '../../services/DataModels/BulkResponse';
import Auth from '../../services/Auth';
import GatewayCommand from '../../services/DataModels/GatewayCommand';
import IntegrationEvent from '../../services/DataModels/IntegrationEvent';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import { GetAll } from '../../services/CLURDUtilities';
import Humanize from '../../services/Humanize';

//icons
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = theme => ({
	container: {
		fontFamily: "Inter",
		flexGrow: 2,
		display: "flex",
		flexWrap: "wrap",
		overflow: "hidden"
	},
	manageDeviceLink: {
		color: theme.palette.pending.main,
		textDecoration: "underline",
		cursor: "pointer"
	},
	messagesContainer: {
		marginLeft: "48px"
	},
	redErrorOutline: {
		color: theme.palette.red.main,
		marginRight: "4px",
	},
	messageContainer: {
		marginLeft: "12px"
	},
	greenCheck: {
		color: theme.palette.green.main,
		marginRight: "4px"
	},
	deletedRow: {
		alignItems: "center"
	},
	statuses: {
		marginLeft: "12px",
		width: "100%"
	},
	statusRow: {
		margin: "0 0 12px 0",
		display: "flex",
		alignItems: "center"
	},
	bulkStatusRowLabel: {
		width: "100%",
		marginBottom: "12px"
	},
	bulkStatusRow: {
		flexWrap: "wrap"
	},
	hoverRow: {
		padding: "6px",
		display: "flex",
		alignItems: "center"
	},
	hoverRowLabel: {
		textTransform: "uppercase",
		color: "#8e8e93",
		minWidth: "22%",
		maxWidth: "22%",
		fontSize: "14px",
		marginRight: "12px"
	},
	devicesNotFound: {
		display: "flex",
		alignItems: "center"
	},
	devicesNotFoundIcon: {
		color: theme.palette.caution.main,
		marginRight: "8px"
	},
	statusLabel: {
		marginLeft: "12px",
	},
	statusValue: {
		color: "#8e8e93"
	},
	hoverRowValue: {
		overflowX: "auto",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "6px",
			height: "10px"
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#d5d6d6"
		}
	},
	scriptInModal: {
		overflow: "auto",
		padding: "12px",
		backgroundColor: "#0f0f0fd4",
		color: "white",
		borderRadius: "4px",
		fontFamily: "Courier New"
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end"
	},
	sideBarContainer: {
		display: "flex",
		width: "15%",
		boxSizing: "border-box",
		marginTop: "-1px",
		backgroundColor: "white",
		zIndex: 5,
		borderRight: "solid lightgrey 1px",
		borderTop: "solid lightgrey 1px",
	},
	itemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 58px)",
		alignItems: "flex-start",
		display: "flex",
		flexWrap: "wrap",
		width: "85%",
		backgroundColor: "#ffffff",
		boxSizing: "border-box",
	},
	link: {
		color: theme.palette.pending.main,
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": theme.palette.pending.main,
			textDecorationColor: theme.palette.pending.main,
			cursor: "pointer",
		}
	},
	modalWrapper: {
		padding: "24px",
		fontFamily: "Inter"
	},
	payload: {
		height: "134px",
		overflow: "auto",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
});

class ReportsList extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			filter_data: {},
			modal: {
				open: false,
				children: () => ""
			},
			tab_index: 0,
			items: null,
		};
		this.tabs = [];
		this.tab_headings = [];
		this.check_permissions();
		this.check_params();
		this.load();
		this.props.tabHostProxy.setRootRefresh(this.load);
	}

	check_permissions = () => {	
		if (Permissions.allow(["read"], "report")) {
			this.tabs.push({label: "Heartbeats", load: () => this.load_heartbeats_or_reports(true)});
			this.tabs.push({label: "Reports", load: () => this.load_heartbeats_or_reports(false)});
			this.tab_headings.push(Report.getHeadings());
			this.tab_headings.push(Report.getHeadings());
		}
		if (Permissions.allow(["read"], "device_error")) {
			this.tabs.push({label: "Errors", load: this.load_errors});
			this.tab_headings.push(DeviceErrors.getHeadings());
		}
		if (Permissions.allow(["read"], "bulk_response")) {
			this.tabs.push({label: "Bulk Jobs", load: this.load_bulk_jobs});
			this.tab_headings.push(BulkResponse.getHeadings());
		}
		if (Permissions.allow(["read"], "gateway_command")) {
			this.tabs.push({label: "Commands", load: this.load_commands});
			this.tab_headings.push(GatewayCommand.getHeadings());
		}
		if (Permissions.allow(["read"], "integration")) {
			this.tab_headings.push(IntegrationEvent.getHeadingsForMessages());
			this.tabs.push({label: "Integration Events", load: this.load_integration_events});
			//this.tab_headings.push(DeviceErrors.getHeadings());
		}
	}

	load = () => {
		if (this.state.items !== null) this.setState({items: null});
		this.tabs.length > 0 ? this.tabs[this.state.tab_index].load() : this.context.openSnackbar("error", "You lack the permissions to access this page. Please log out.");
	}

	load_integration_events = () => {
		const { classes, theme } = this.props;
		const { page_data, filter_data } = this.state;
		let params = Object.assign(page_data, filter_data);
		delete params.total;
		IntegrationEvent.loadForMessageDisplay(params).then( (result) => {
			console.log(result);
			this.setState( (state) => {
				result.items.forEach( (event) => {
					event.table_detail = <span className={classes.link} onClick={() => this.open_integration(event)}>View Details</span>
				});
				state.items = result.items;
				state.page_data = {
					page: params.page,
					per_page: params.per_page,
					page_meta: true,
					total: result.total
				};
				return state;
			});
		}).catch( (error) => {
			console.log(error);
			this.context.openSnackbar(error, "error");
		});
	}

	load_commands = () => {
		const { classes, theme } = this.props;
		const { page_data, filter_data } = this.state;
		let params = Object.assign(page_data, filter_data);
		params.command_type_ne = "software_update";
		delete params.total;
		GatewayCommand.getCommands(params, theme).then( (result) => {
			this.setState( (state) => {
				result.items.forEach( (command) => {
					command.table_detail = <span className={classes.link} onClick={() => this.open_command_modal(command)}>View Details</span>
				});
				state.items = result.items;
				state.page_data = {
					page: params.page,
					per_page: params.per_page,
					page_meta: true,
					total: result.total
				};
				return state;
			});
		}).catch( (error) => {
			console.log(error);
			this.context.openSnackbar(error, "error");
		});
	}

	load_bulk_jobs = () => {
		const { classes } = this.props;
		const { page_data, filter_data } = this.state;
		let params = Object.assign(page_data, filter_data);
		delete params.total;
		BulkResponse.getBulkResponses(params).then( (result) => {
			let jobs = result.items;
			jobs.forEach((bulk_job) => {
				bulk_job.brs_successes_by_device = {};
				bulk_job.brs_failures_by_device = {};
				bulk_job.table_detail = <span className={classes.link} onClick={() => this.open_bulk_modal(bulk_job)}>View Details</span>
				const no_statuses = bulk_job.bulk_response_statuses == null || Object.entries(bulk_job.bulk_response_statuses).length === 0;
				if (no_statuses) {
					bulk_job.table_successes = <span style={{color: "#8e8e93"}}>Unset</span>;
					bulk_job.table_failures = <span style={{color: "#8e8e93"}}>Unset</span>;
					bulk_job.table_summary = () => this.render_summary(null);
				} else {
					if (bulk_job.bulk_response_statuses != null) {
						bulk_job.bulk_response_statuses.forEach((bulk_response_status) => {
							if (bulk_response_status.status == 'fail') {
								bulk_job.brs_failures_by_device[bulk_response_status.device_id] =  (bulk_response_status.device_id in bulk_job.brs_failures_by_device) ? [...bulk_job.brs_failures_by_device[bulk_response_status.device_id], bulk_response_status] : [bulk_response_status];
								delete bulk_job.brs_successes_by_device[bulk_response_status.device_id];
							} else {
								bulk_job.brs_successes_by_device[bulk_response_status.device_id] =  (bulk_response_status.device_id in bulk_job.brs_successes_by_device) ? [...bulk_job.brs_successes_by_device[bulk_response_status.device_id], bulk_response_status] : [bulk_response_status];
							}
						});
					}
					bulk_job.table_successes = bulk_job.brs_successes_by_device ? Object.entries(bulk_job.brs_successes_by_device).length : 0;
					bulk_job.table_failures = bulk_job.brs_failures_by_device ? Object.entries(bulk_job.brs_failures_by_device).length : 0;
					bulk_job.table_summary = () => this.render_summary(bulk_job);
				}
			});
			this.setState( (state) => {
				state.items = jobs;
				state.page_data = {
					page: params.page,
					per_page: params.per_page,
					page_meta: true,
					total: result.total
				};
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_summary = (bulk_job, with_label) => {
		let title = "Pending";
		let icon = <AccessTimeIcon style={{color: "#8e8e93"}}/>;
		if (bulk_job != null) {
			let fail_count = bulk_job.table_failures;
			let success_count = bulk_job.table_successes;
			if ((fail_count + success_count) == bulk_job.total) {
				if (fail_count === 0) {
					title = "Completed with No Failures";
					icon = <CheckCircleIcon style={{color: this.props.theme.palette.green.main}}/>;
				} else if (fail_count > 0 && success_count > 0) {
					title = "Completed with Some Failures";
					icon = <WarningIcon style={{color: this.props.theme.palette.caution.main}}/>;
				} else if (fail_count > 0 && success_count === 0) {
					title = "Completed with No Successes";
					icon = <ErrorIcon style={{color: this.props.theme.palette.red.main}}/>;
				}
			}
		}
		return (
			<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
				<Tooltip title={title}>
					{icon}
				</Tooltip>
				{with_label ? <span style={{marginLeft: "6px"}}>{title}</span> : ""}
			</div>
		);
	}

	load_errors = () => {
		const { page_data, filter_data } = this.state;
		let params = Object.assign(page_data, filter_data);
		delete params.total;
		DeviceErrors.getErrors(params).then( (result) => {
			let errors = result.items;
			errors.forEach( (error) => {
				error._id = error.id;
				if (!error.nested_company) {
					error.nested_company = {name: "Company Not Found"};
				}
				const error_message = error.error;
				error.error = () => this.render_error_message(error_message, error.device_id, error.company_id);
				error.display_level = () => this.render_severity(error.level);
			});
			this.setState( (state) => {
				state.items = errors;
				state.page_data = {
					page: params.page,
					per_page: params.per_page,
					page_meta: true,
					total: result.total
				};
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_error_message = (error, id, company_id) => {
		const { classes } = this.props;
		return (
			<div>
				{error}
				{this.props.device_id || !Permissions.allow(["read"], "device", company_id) || !Permissions.allow(["read"], "device_type", company_id) ? "" :
					<span style={{marginLeft: "12px"}} className={classes.link} onClick={() => this.open_device({_id: id})}>
						View Device
					</span>
				}
			</div>
		);
	}

	render_severity = (level) => {
		if (level < 4) {
			return (
				<Tooltip key="warning" title="Warning">
					<WarningIcon style={{marginRight: "16px", color: this.props.theme.palette.caution.main}}/>
				</Tooltip>
			);
		} else if ( level === 4 ) {
			return (
				<Tooltip key="error" title="Error">
					<ErrorIcon style={{marginRight: "16px", color: this.props.theme.palette.red.main}}/>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip key="critical_error" title="Critical Error">
					<NewReleasesIcon style={{marginRight: "16px", color: this.props.theme.palette.red.main}}/>
				</Tooltip>
			);
		}
	}

	load_heartbeats_or_reports = (heartbeats) => {
		const { classes } = this.props;
		const { page_data, filter_data } = this.state;
		let params = Object.assign(page_data, filter_data);
		delete params.total;
		let request = heartbeats ? Report.getHeartbeats : Report.getReports;
		const link = {
			color: this.props.theme.palette.pending.main,
			cursor: "pointer",
		};
		request(params).then( (result) => {
			let items = result.items;
			items.forEach( (report) => {
				const no_payload = report.payload == null || Object.entries(report.payload).length === 0;
				if (!report.nested_company) {
					report.nested_company = {name: "Company Not Found"};
				}
				let payload_title = "No Payload";
				if ( !Permissions.allow(["read_payload"], "report", report.company_id) ) {
					payload_title = "Payload Hidden";
				}
				report.table_payload = no_payload ? payload_title : (
					<div
						onClick={() => this.open_payload_modal(report.payload, classes)} 
						style={link}
					>
						View Payload
					</div>
				);
				if (Permissions.allow(["read"], "device", report.company_id) && Permissions.allow(["read"], "device_type", report.company_id)) {
					report.table_unique_id = (
						<div 
							onClick={() => this.props.tabHostProxy.addTab("device", report.device_id, true)}
							style={link}
						>
							{report.device_name}
						</div>
					);
				} else {
					report.table_unique_id = (<div>Device Unknown</div>);
				}
			});
			this.setState( (state) => {
				state.items = items;
				state.page_data = {
					page: params.page,
					per_page: params.per_page,
					page_meta: true,
					total: result.total
				};
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	check_params = () => {
		const qs = require('qs');
		const parsed = qs.parse(this.props.location.search.split("?")[1]);
		if (parsed.bulk_response_id) {
			let index = this.tabs.findIndex( (tab) => tab.label === "Bulk Jobs");
			this.state.tab_index = index;
		} else if (parsed.bulk != null) {
			let index = this.tabs.findIndex( (tab) => tab.label === "Bulk Jobs");
			this.state.tab_index = index;
		} else if (parsed.errors != null) {
			let index = this.tabs.findIndex( (tab) => tab.label === "Errors");
			this.state.tab_index = index;
		} else if (parsed.heartbeats != null) {
			let index = this.tabs.findIndex( (tab) => tab.label === "Heartbeats");
			this.state.tab_index = index;
		} else if (parsed.reports != null) {
			let index = this.tabs.findIndex( (tab) => tab.label === "Reports");
			this.state.tab_index = index;
		} else if (parsed.commands != null) {
			let index = this.tabs.findIndex( (tab) => tab.title === "Commands");
			this.state.tab_index = index;
		}
		this.props.history.replace({
			pathname: '/Messages',
			search: ''
		});
		
	}

	page_change = (event, page) => {
		this.setState( (state) => {
			state.page_data.page = page + 1;
			return state;
		}, this.load);
	}

	per_page_change = event => {
		this.setState( (state) => {
			state.page_data.per_page = event.target.value;
			return state;
		}, this.load);
	}

	render() {
		const { classes } = this.props;
		const { page_data } = this.state;
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<PaginationContainer
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.page_change}
					onChangeRowsPerPage={this.per_page_change}
				/>
				<div className={classes.sideBarContainer}>
					<VerticalTabs initial={this.state.tab_index} tabs={this.tabs} onChange={this.tab_change}/> 
				</div>
				<div className={classes.itemsContainer}>
					{this.render_table_layout()}
				</div>
			</div>
		);
	}

	render_table_layout = () => {
		const { tab_index, items } = this.state;
		let headings = this.tab_headings[tab_index];
		return (
			<TableList
				headings={headings} 
				items={items}
				noCheckBox
			/>
		);
	}

	tab_change = (new_tab, index) => {
		this.setState({ tab_index: index }, this.load);
	}

	close_modal = () => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	}

	lookup_devices = (command) => {
		let device_unique_ids = JSON.parse(JSON.stringify(command.attached_device_unique_ids));
		device_unique_ids.push(command.device_unique_id);
		let params = { unique_id_in: device_unique_ids};
		return GetAll("devices", params);
	}

	open_integration = (integration_event) => {
		if (integration_event.nested_integration) {
			this.props.tabHostProxy.addTab("integration", integration_event.nested_integration, false);
		}
	}

	open_command_modal = (command) => {
		this.setState({modal: {
			"open": true,
			yesFunction: null,
			children: (classes) => (
				<div className={classes.modalWrapper}>
					<Loading />
				</div>
			)
		}});
		this.lookup_devices(command).then( (devices) => {
			this.setState( (state) => {
				const gateway_alias = capitalize(Auth.currentCompany().aliases.gateway);
				let status_count = Object.entries(command.statuses).length;
				const gateway = devices ? devices.find( (device) => device.unique_id === command.device_unique_id) : null;
				const gateway_status = capitalize(command.statuses[command.device_unique_id].status);
				const gateway_display = gateway ? Device.renderStatusName(gateway, this.props.theme, () => this.open_device(gateway)) : command.device_unique_id + " (device not found)";
				const only_gateway = command.attached_device_unique_ids.length === 0;
				this.state.modal = {
					"open": true,
					yesFunction: null,
					children: (classes) => (
						<div className={classes.modalWrapper}>
							<div className={classes.hoverRow}>
								<span className={classes.hoverRowLabel}>Date</span>
								<span className={classes.hoverRowValue}>{this.render_date(command.created_at)}</span>
							</div>
							<div className={classes.hoverRow}>
								<span className={classes.hoverRowLabel}>Status Summary</span>
								<span className={classes.hoverRowValue}>{GatewayCommand.renderStatusSummary(command, true, this.props.theme)}</span>
							</div>
							<div className={classes.hoverRow}>
								<span className={classes.hoverRowLabel}>Action</span>
								<span className={classes.hoverRowValue}>{GatewayCommand.renderAction(command.command_type)}</span>
							</div>
							<div className={classes.hoverRow}>
								<span className={classes.hoverRowLabel}>{gateway_alias}</span>
								<span className={classes.hoverRowValue}>
									{gateway_display} 
									<span className={classes.statusLabel}>
										<span className={classes.statusValue}>Status: </span>{gateway_status}
									</span>
								</span>
							</div>
							{only_gateway ? "" :
								<div className={classes.hoverRow}>
									<span className={classes.hoverRowLabel}>Statuses</span>
									{devices.length !== status_count ?
										<div className={classes.devicesNotFound}>
											<WarningIcon className={classes.devicesNotFoundIcon}/>
											<span>
												Some of the devices referenced in this command could not be loaded. They have either been deleted or are not visible to you.
											</span>
										</div>
										: ""
									}
									<div>
										{devices.map( (device) => {
											if (device.unique_id === command.device_unique_id) return "";
											const device_display = command.device_unique_ids.indexOf(device.unique_id) !== -1 ? Device.renderStatusName(device, this.props.theme, () => this.open_device(device)) : device.unique_id + " (device not found)";
											const status = command.statuses[device.unique_id] ? this.capitalize(command.statuses[device.unique_id].status) : "Pending";
											return (
												<span className={classes.hoverRowValue}>
													{device_display} 
													<span className={classes.statusLabel}>
														<span className={classes.statusValue}>Status: </span>{status}
													</span>
												</span>
											);
										})}
									</div>
								</div>
							}
						</div>
					)
				};
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
		
	}

	open_bulk_modal = (job) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.hoverRow}>
							<span className={classes.hoverRowLabel}>Date</span>
							<span className={classes.hoverRowValue}>{this.render_date(job.created_at)}</span>
						</div>
						<div className={classes.hoverRow}>
							<span className={classes.hoverRowLabel}>Action</span>
							<span className={classes.hoverRowValue}>{Humanize.humanizeSnakeCaseString(job.type)}</span>
						</div>
						<div className={classes.hoverRow}>
							<span className={classes.hoverRowLabel}>Status</span>
							<span className={classes.hoverRowValue}>{this.render_summary(job, true)}</span>
						</div>
						<React.Fragment>
							{this.render_successes(job)}
							{this.render_failures(job)}
						</React.Fragment>
						
						<div className={classes.buttonContainer}>
							<Button
								onClick={this.close_modal}
								variant="outlined"
								color="primary"
							>
								CLOSE
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	render_successes = (bulk) => {
		if (bulk.bulk_response_statuses == null || !bulk.bulk_response_statuses || bulk.bulk_response_statuses.length === 0) return "";
		const status_code_map = { 200: "saved", 201: "created", 202: "sent"};
		const classes = this.props.classes;
		const success = bulk.bulk_response_statuses.filter( value => (value.status !== 'fail' && value.status_code !== 204) ).map( value => ({device: value.device_id, action: status_code_map[value.status_code]}) )
		const deleted_count = Object.entries(bulk.bulk_response_statuses).filter( (value) => value.status_code === 204 ).length;
		if ( deleted_count > 0 || success.length > 0 ) {
			return (
				<div className={classes.hoverRow + " " + classes.bulkStatusRow}>
					<span className={classes.hoverRowLabel + " " + classes.bulkStatusRowLabel}>Successes</span>
					{deleted_count > 0 ?
						<div className={classes.statusRow + " " + classes.deletedRow}>
							<CheckIcon className={classes.greenCheck}/>
							<span>
								{`${deleted_count} item${deleted_count > 1 ? 's' : ''} succesfully deleted.`}
							</span>
						</div>
						: ''}
					<div className={classes.statuses}>
						{success.map( (success) => (
							<div key={success.device} className={classes.statusRow}>
								<CheckIcon className={classes.greenCheck}/>
								<span>
									Item <span onClick={() => this.open_device(success.device)} className={classes.manageDeviceLink}>{success.device}</span> was succesfully {success.action}.
								</span>
							</div>
						))}
					</div>
				</div>
			);
		}
		return null;
	}

	open_device = (device) => {
		this.close_modal();
		if (device) {
			if (device._id) {
				this.props.tabHostProxy.addTab("device", device._id, true);
			} else {
				this.props.tabHostProxy.addTab("device", device, true);
			}
		}
	}

	render_failures = (bulk) => {
		if (bulk.bulk_response_statuses == null || !bulk.bulk_response_statuses || bulk.bulk_response_statuses === 0) return "";
		const classes = this.props.classes;
		const errors = bulk.bulk_response_statuses.filter( value => value.status === 'fail' ).map( value => ({device: value.device_id, error: value.error, messages: value.messages}));
		if ( errors.length > 0 ) {
			return (
				<div className={classes.hoverRow + " " + classes.bulkStatusRow}>
					<span className={classes.hoverRowLabel + " " + classes.bulkStatusRowLabel}>Failures</span>
					<div className={classes.statuses}>
						{errors.map( (error, index) => (
							<div key={error.device + "_" + index}>
								<div className={classes.statusRow + " " + (!error.messages || error.messages.length === 0 ? classes.deletedRow : "")}>
									<ErrorOutlineIcon className={classes.redErrorOutline}/>
									<span>
										An error occurred for item {error.device}: {error.error}
									</span>
								</div>
								{error.messages && error.messages.length > 0 ? <div className={classes.messagesContainer}>
									<span className={classes.hoverRowLabel + " " + classes.messagesLabel}>Messages</span>
									{error.messages.map( (message, index) => (
										<div className={classes.messageContainer} key={error.device + "_error_" + index}>
											{message}
										</div>
									))}
								</div> : ""}
							</div>
						))}
					</div>
				</div>
			);
		} 
		return null;
	}

	render_date = (date) => {
		function showDate(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleDateString();
		}

		function showTime(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleTimeString();
		}
		return (
			<span>
				<span style={{whiteSpace: "nowrap", marginRight: "12px"}}>
					{showTime(date)}
				</span>
				<span style={{color: "#8e8e93"}}>
					{showDate(date)}
				</span>
			</span>
		);
	}

	open_payload_modal = (payload, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<pre className={classes.scriptInModal}>
							{JSON.stringify(payload, null, 2)}
						</pre>
						<div className={classes.buttonContainer}>
							<Button
								onClick={this.close_modal}
								variant="outlined"
								color="primary"
							>
								CLOSE
							</Button>
						</div>
					</div>
				)
			}
		});
	}
}

ReportsList.contextType = SnackbarContext;

function capitalize(word){
	return word.charAt(0).toUpperCase() + word.slice(1);
}

export default compose(
	withRouter,
	withStyles(styles),
	withTheme(),
)(ReportsList);
