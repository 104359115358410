import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PaginationContainer from '../Containers/PaginationContainer';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Config from '../../services/DataModels/Config';
import Device from '../../services/DataModels/Device';
import DeviceHAGroup from '../../services/DataModels/DeviceHAGroup';
import TableList from '../Table/TableList';
import Permissions from '../../services/Permissions';
import HAGroupCreationFlow from './HAGroupCreationFlow';
import Loading from '../DisplayOriented/Loading';
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'

//mui
import { darken } from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';


//icons
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import MemoryIcon from '@material-ui/icons/Memory';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RouterIcon from '@material-ui/icons/Router';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

class HAGroupsList extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            modal: {
                open: false,
                children: () => ""
            },
            menus: {},
            layout: "card",
            hovering: null
        };
        this.actions = [
            { label: "Reintialize Greengrass", action: "greengrass_initialize", confirm: "Reinitialize Greengrass on all of the node devices in this HA Group?", icon: <GetAppIcon />, function: this.reinitialize_gg },
            { label: "Delete", action: "delete", confirm: "Delete this HA Group?", icon: <DeleteForeverIcon />, function: this.delete_ha_group }
        ];
        this.heading_info = [
            { label: "Name", value: "table_name", field: "name", align: "left", disablePadding: false, sortable: true, content: "function" },
            { label: "Account", value: "nested_company.name", nested_field: true, field: "account", align: "left", disablePadding: false, sortable: false },
            { label: "Ports", value: "table_ports", field: "ports", nested_field: true, align: "left", disablePadding: false, sortable: true },
            { label: "Integration", value: "table_integration", field: "integration", align: "left", disablePadding: false, sortable: false },
            { label: "Controller", value: "table_controller", field: "controller", align: "left", disablePadding: false, sortable: false, content: "function" },
        ];
    }

    delete_ha_group = (ha_group) => {
        new DeviceHAGroup({ _id: ha_group._id }).deleteFromAPI().then(() => {
            this.context.openSnackbar("HA Group deleted.", "success");
            this.props.tabHostProxy.closeOther(ha_group._id, true);
            this.props.tabHostProxy.refresh();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        });
    }

    reinitialize_gg = (ha_group) => {
        let promises = [];
        ha_group.node_devices.forEach((device) => {
            promises.push(Device.issueGatewayCommand("greengrass_initialize", null, device.device_id));
        });
        Promise.all(promises).then(() => {
            this.context.openSnackbar("Each device has been issued the command to reinitialize Greengrass. Go to the Devices page and click on a device to monitor its Greengrass status.", "success");
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        })
    }

    open_ha_group_tab = (ha_group) => {
        this.props.tabHostProxy.addTab("haGroup", ha_group);
    }

    create_ha_group = () => {
        this.setState({
            modal: {
                open: true,
                children: () => <HAGroupCreationFlow tabHostProxy={this.props.tabHostProxy} closeModal={this.close_modal} />
            }
        })
    }

    handleChangePage = (event, page) => {
        this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
    }

    handleChangeRowsPerPage = event => {
        this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
    }

    close_modal = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    render_table_layout = () => {
        const { ha_groups, page_data } = this.props;
        return (
            <TableList
                headings={this.heading_info}
                items={ha_groups}
                page_data={page_data}
                perform_sort={this.props.perform_sort}
            />
        );
    }

    render_card_layout = () => {
        return this.props.ha_groups.map((ha_group) => this.render_ha_group_card(ha_group));
    }

    render_ha_group_card = (ha_group) => {
        const classes = this.props.classes;
        return (
            <div key={ha_group._id} className={classes.cardContainer}>
                <Card
                    className={classes.cardOverride + " " + (this.state.hovering === ha_group._id ? classes.cardHovered : "")}
                >
                    {this.render_floating_icons(ha_group)}
                    {this.render_bg(ha_group)}
                    {this.render_ha_group_info(ha_group)}
                </Card>
            </div>
        );
    }

    render_bg = (ha_group) => {
        const classes = this.props.classes;
        return (
            <div onClick={() => !ha_group.error && this.open_ha_group_tab(ha_group)} className={classes.haGroupBgContainer}>
                {this.render_controller_title(ha_group)}
            </div>
        );

    }

    render_controller_title = (ha_group) => {
        const classes = this.props.classes;
        if (ha_group.error) {
            return (
                <React.Fragment>
                    <div className={classes.titleContainer}>
                        <div className={classes.title + " " + classes.valueError}>{ha_group.error}</div>
                    </div>
                    <div className={classes.titleContainer}>
                        <div className={classes.title + " " + classes.valueError}>This HA Group cannot be edited.</div>
                    </div>
                </React.Fragment>
            );
        } else {
            let device = ha_group.nested_controller;
            const status = device.heartbeat_status;
            return (
                <React.Fragment>
                    <div className={classes.infoContainer + " " + classes.controllerLabel}>
                        <Tooltip title="Device Category"><RouterIcon className={classes.infoLabel} /></Tooltip>
                        <div className={classes.infoValue}>Controller Device</div>
                    </div>
                    <div className={classes.titleContainer}>
                        <HeartbeatStatus status={status} className={classes.heartBeatstatusDot} />
                        <div className={classes.title}>{device.name}</div>
                    </div>
                    <div className={classes.infoContainer}>
                        <Tooltip title="Interface"><SettingsEthernetIcon className={classes.infoLabel} /></Tooltip>
                        <div className={classes.infoValue}>{ha_group.controller_device.interface}</div>
                    </div>
                </React.Fragment>
            );
        }
    }

    render_ha_group_info = (ha_group) => {
        const classes = this.props.classes;
        return (
            <div
                onClick={() => !ha_group.error && this.open_ha_group_tab(ha_group)}
                onMouseLeave={() => this.setState({ hovering: null })}
                onMouseEnter={() => this.setState({ hovering: ha_group._id })}
                className={classes.haGroupInfo + " " + (this.state.hovering === ha_group._id ? classes.infoHovered : "")}
            >
                {this.render_title(ha_group)}
                {this.render_account(ha_group)}
                {this.render_integration(ha_group)}
                {this.render_ports(ha_group)}
                {this.render_node_count(ha_group)}
            </div>
        );
    }

    render_node_count = (ha_group) => {
        const classes = this.props.classes;
        return (
            <div className={classes.infoContainer}>
                <Tooltip title="Node Devices"><SettingsRemoteIcon className={classes.infoLabel} /></Tooltip>
                <div className={classes.infoValue}>
                    {ha_group.node_devices.length + " Node" + (ha_group.node_devices.length === 1 ? "" : "s")}
                </div>
            </div>
        );
    }

    render_ports = (ha_group) => {
        const classes = this.props.classes;
        return (
            <div className={classes.infoContainer + " " + classes.chipSpecialContainer}>
                <Tooltip title="Ports">
                    <img alt="port_icon" className={classes.portsIcon} src={require("../../images/ports.png").default} />
                </Tooltip>
                {ha_group.ports && ha_group.ports.length > 0 ?
                    <div className={classes.chipContainer}>
                        {ha_group.ports.map((port) => (
                            <div key={port} className={classes.chip}>
                                {port}
                            </div>
                        ))}
                    </div>
                    :
                    <div className={classes.infoValue + " " + classes.valueError}>No Ports</div>
                }
            </div>
        );
    }

    render_integration = (ha_group) => {
        const classes = this.props.classes;
        if (ha_group.nested_integration) {
            return (
                <div className={classes.infoContainer}>
                    <Tooltip title="Integration"><ExtensionIcon className={classes.infoLabel} /></Tooltip>
                    <div className={classes.infoValue}>{ha_group.nested_integration.name}</div>
                </div>
            );
        } else if (ha_group.nested_integration == null) {
            return (
                <div className={classes.infoContainer}>
                    <ExtensionIcon className={classes.infoLabel} />
                    <div className={classes.infoValue + " " + classes.valueError + " " + classes.errorText}>Integration Error</div>
                </div>
            );
        }
    }

    render_title = (ha_group) => {
        const classes = this.props.classes;
        return (
            <div className={classes.titleContainer}>
                <div className={classes.title}>{ha_group.name}</div>
            </div>
        );
    }

    render_account = (ha_group) => {
        const classes = this.props.classes;
        if (!ha_group.nested_company) {
            return (
                <div className={classes.infoContainer}>
                    <Tooltip title="Account"><AccountCircleIcon className={classes.infoLabel} /></Tooltip>
                    <div className={classes.infoValue + " " + classes.valueError}>Account Error</div>
                </div>
            );
        }
        return (
            <div className={classes.infoContainer}>
                <Tooltip title="Account"><AccountCircleIcon className={classes.infoLabel} /></Tooltip>
                <div className={classes.infoValue}>{ha_group.nested_company.name}</div>
            </div>
        );
    }

    render_floating_icons = (ha_group) => {
        const classes = this.props.classes;
        let menu = this.render_menu(ha_group);
        return (
            <div className={classes.floatingIconsContainer}>
                <div className={classes.menuContainer}>
                    {/* {this.render_alert(ha_group)} */}
                    {menu}
                </div>
            </div>
        );
    }

    render_alert = (ha_group) => {
        const classes = this.props.classes;
        if (ha_group.error) {
            return (
                <div className={classes.errorContainer}>
                    <ErrorOutlineIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>{ha_group.error}</div>
                </div>
            );
        }
    }

    render_menu = (ha_group) => {
        const classes = this.props.classes;
        let anchorEl = this.state.menus[ha_group._id];
        let open = Boolean(anchorEl);
        return (
            <div>
                <div>
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => this.open_action_menu(event, ha_group._id)}
                        className={classes.menuIconButton}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>
                {this.render_action_menu(open, anchorEl, ha_group)}
            </div>
        );
    }

    render_action_menu = (open, anchorEl, ha_group) => {
        const classes = this.props.classes;
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => this.close_action_menu(ha_group._id)}
                PaperProps={{
                    style: { overflow: "visible" }
                }}
            >
                <div className={classes.actionListTitle}>
                    Perform Action...
                </div>
                {this.actions.map((action, index) => {
                    if (ha_group.error && action.label !== "Delete") {
                        return "";
                    }
                    return (
                        <div onClick={() => this.quick_action(action, ha_group)} key={action.label} className={classes.menuItemContainer}>
                            <MenuItem className={classes.actionMenuItem}>
                                <ListItemIcon>
                                    {action.icon}
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    {action.label}
                                </Typography>
                            </MenuItem>
                        </div>
                    );
                })}
            </Menu>
        );
    }

    quick_action = (action, ha_group) => {
        this.setState((state) => {
            state.menus[ha_group._id] = null;
            state.modal = {
                open: true,
                yesFunction: () => this.quick_issue(action, ha_group),
                functionText: action.label,
                prompt: action.confirm,
                children: () => { }
            };
            return state;
        });
    }

    quick_issue = (action, ha_group) => {
        this.setState((state) => {
            state.modal = {
                open: true,
                children: () => (<div style={{ height: "100px", width: "100px", margin: "auto" }}><Loading /></div>)
            };
            return state;
        });
        action.function(ha_group);
    }

    close_action_menu = (id) => {
        this.setState((state) => {
            state.menus[id] = null;
            return state;
        });
    };

    open_action_menu = (event, id) => {
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }

    render() {
        const { ha_groups, page_data, classes } = this.props;
        let buttons = Permissions.allow(["create"], "device_ha_group") ? [{ label: "CREATE HA GROUP", icon: (<AddIcon />), action: this.create_ha_group }] : undefined;
        return (
            <div className={classes.container}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                <PaginationContainer
                    buttons={buttons}
                    count={page_data.total}
                    rowsPerPage={page_data.per_page}
                    currentPage={page_data.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    toggleView={(layout) => this.setState({ layout: layout })}
                />
                {this.state.layout === "card" ?
                    <div className={classes.cardItemsContainer}>
                        {this.render_card_layout()}
                    </div> :
                    <div className={classes.tableItemsContainer}>
                        {this.render_table_layout()}
                    </div>
                }
            </div>
        );
    }
}



const styles = (theme) => {
    return ({
        container: {
            fontFamily: "Inter",
            flexGrow: 2,
            width: "75%",
        },
        itemsContainer: {
            overflowY: "scroll",
            height: "calc(100% - 81px)",
            padding: "12px",
            backgroundColor: "#ebebeb"
        },
        cardItemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 82px)",
            padding: "12px",
            backgroundColor: "#f5f5f7",
        },
        tableItemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 82px)",
            backgroundColor: "#ffffff",
        },
        cardContainer: {
            width: "33.33%",
            display: "inline-flex",
            position: "relative",
        },
        cardOverride: {
            boxShadow: "0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24)",
            backgroundColor: "white",
            margin: "12px",
            width: "100%",
            height: "240px",
        },
        cardHovered: {
            boxShadow: "0 3px 6px 0 rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.23)",
        },
        prompt: {
            fontFamily: "Inter",
            fontSize: "22px",
            marginBottom: "24px",
        },
        modalButtons: {
            display: "flex",
            justifyContent: "flex-end"
        },
        iconButton: {
            padding: "7px",
            color: "grey",
        },
        deleteButton: {
            backgroundColor: theme.palette.red.main,
            color: "white",
            '&:hover': {
                backgroundColor: darken(theme.palette.red.main, .2),
            }
        },
        floatingIconsContainer: {
            position: "absolute",
            top: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "calc(100% - 24px)",
            height: "48px",
        },
        menuContainer: {
            display: "flex",
            alignItems: "center"
        },
        menuIconButton: {
            padding: "4px",
            marginRight: "4px"
        },
        actionMenuItem: {
            outline: "none"
        },
        actionListTitle: {
            marginBottom: "8px",
            padding: "11px 16px",
            borderBottom: "solid #80808073 1px",
            backgroundColor: "white",
            cursor: "unset",
            '&:hover': {
                backgroundColor: "white",
                cursor: "unset",
            },
            outline: "none",
            fontFamily: "Inter",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "1rem",
            width: "auto",
            height: "24px",
            whiteSpace: "nowrap",
            boxSizing: "content-box",
            fontWeight: 400,
            lineHeight: "1.5em"
        },
        haGroupBgContainer: {
            margin: "24px",
            marginBottom: 0,
            padding: "6px",
            height: "88px",
            overflow: "hidden",
            boxSizing: "border-box",
            textAlign: "center",
            backgroundColor: "#2b8ceb2e",
            borderRadius: "4px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        infoHovered: {
            marginTop: "-35px"
        },
        haGroupInfo: {
            boxShadow: "0 2px 6px 0 rgba(0,0,0,.3)",
            backgroundColor: "white",
            borderBottom: "1px solid #ededed",
            zIndex: "3",
            position: "relative",
            padding: "8px",
            cursor: "pointer",
            transition: "margin .25s ease-out"
        },
        infoContainer: {
            display: "flex",
            alignItems: "center",
            padding: "5px",
            marginLeft: "21px"
        },
        controllerLabel: {
            marginLeft: 0
        },
        chipSpecialContainer: {
            padding: "0 5px",
            minHeight: "30px"
        },
        chipContainer: {
            display: "flex",
        },
        chip: {
            marginRight: "5px",
            backgroundColor: "#e0e0e0",
            color: "#000000a8",
            borderRadius: "16px",
            fontSize: "12px",
            marginBottom: "4px",
            marginTop: "4px",
            padding: "4px",
        },
        infoLabel: {
            fontSize: "16px",
            color: "rgba(0,0,0,.42)",
            marginRight: "6px"
        },
        noValue: {
            fontStyle: "italic",
            color: "rgba(0,0,0,.42)"
        },
        infoValue: {
            fontSize: "16px",
            color: "rgba(0,0,0,.67)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        },
        valueError: {
            color: theme.palette.red.main
        },
        titleContainer: {
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            padding: "5px"
        },
        title: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        portsIcon: {
            width: "14px",
            marginRight: "8px"
        },
        errorIcon: {
            marginRight: "4px",
            color: theme.palette.red.main
        },
        errorContainer: {
            display: "flex",
            color: theme.palette.red.main,
            alignItems: "center",
            marginRight: "12px",
            maxWidth: "calc(100% - 42px)"
        },
        errorText: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        heartBeatstatusDot: {
            width: "12px",
            height: "12px",
            minWidth: "12px",
            minHeight: "12px",
            maxWidth: "12px",
            maxHeight: "12px",
            borderRadius: "50%",
            display: "inline-flex",
            marginRight: "8px",
            color: 'white'
        },
    })
};

HAGroupsList.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(HAGroupsList));
