import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

const styles = theme => ({
});

class HeartbeatStatus extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const status_map = { offline: this.props.theme.palette.heartbeat.offline.main, online: this.props.theme.palette.green.main, idle: this.props.theme.palette.heartbeat.idle.main, never_reported: this.props.theme.palette.grey.main };
        const { status, className, backgroundcolor, disabletooltip } = this.props;
        const tooltip = status.charAt(0).toUpperCase() + status.slice(1).replace('_',' ')
        return (
            <Tooltip title={tooltip} disableTouchListener={disabletooltip} disableFocusListener={disabletooltip} disableHoverListener={disabletooltip} >
                {
                    status === 'online' ? <ArrowUpwardIcon style={{ backgroundColor: status_map[status] }} className={className} /> :
                        status === 'offline' ? <ArrowDownwardIcon style={{ backgroundColor: status_map[status] }} className={className} /> :
                            status === 'idle' ? <RemoveIcon style={{ backgroundColor: status_map[status] }} className={className} /> :
                                status === 'never_reported' ? <FiberManualRecordOutlinedIcon style={{ backgroundColor: status_map[status] }} className={className} /> :
                                    <div style={{ backgroundColor: backgroundcolor }} className={className}>&nbsp;</div>
                }
            </Tooltip>
        )
    }
}

export default withStyles(styles)(withTheme()(HeartbeatStatus));