import DataModel from "./DataModel";

class DeviceCSVUpload extends DataModel {
	constructor() {
		super("devices/csv_bulk_upload");
	}

	// `file` is expected to be a File (https://www.w3.org/TR/FileAPI/#dfn-file)
	uploadCSV(file) {
		return this.uploadFiles('gateway_csv_upload', file);
	}
}

export default DeviceCSVUpload;
