import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../DisplayOriented/Loading';

//mui
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//steps
import CDStep1 from './CreateDeviceSteps/CDStep1';
import CDStep2 from './CreateDeviceSteps/CDStep2';
import CDStep3 from './CreateDeviceSteps/CDStep3';
import CDStep4 from './CreateDeviceSteps/CDStep4';
import CDStep5 from './CreateDeviceSteps/CDStep5';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Auth from '../../services/Auth';
import Device from '../../services/DataModels/Device';

//icons
import HelpIcon from '@material-ui/icons/Help';
import { ADD_RELATIONS } from '../../services/Relation';

class DeviceCreationFlow extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			active_step: 0,
			device: {},
			creating_device: false,
			created_id: null
		};
		this.gateway_alias = Auth.currentCompany().aliases.gateway;
		this.prepare_steps();
	}

	back = () => {
		this.setState( (state) => {
			state.active_step = state.active_step - 1;
			return state;
		});
	}

	progress = (updated_device) => {
		this.setState( (state) => {
			state.device = Object.assign(state.device, updated_device);
			state.active_step = state.active_step + 1;
			return state;
		});
	}

	create_device = () => {
		this.setState({creating_device: true});
		const { device } = this.state;
		const company_id = this.props.preset && this.props.preset.company_id ? this.props.preset.company_id : device.company_id;
		let formatted_device = {
			name: device.name,
			unique_id: device.unique_id,
			company_id: company_id,
			serial: device.serial,
			device_type_id: device.device_type_id,
			log_config: {
				forward_level: device.log_config.forward_level,
				local_level: device.log_config.local_level
			},
			location: device.location,
			tags: device.tags,
			heartbeat_period: this.heartbeat_to_seconds(),
			heartbeat_values: device.is_gateway ? device.heartbeat_data.heartbeat_values : []
		}
		if (!device.is_gateway) {
			if (device.prepped_devices && device.prepped_devices[0]) {
				formatted_device.parent_device_id = device.prepped_devices[0]._id;
			}
		} else if (device.prepped_devices && device.prepped_devices.length > 0) {
			formatted_device.attached_device_ids = device.prepped_devices.map( ({_id}) => _id);
		}
		if (device.device_config_id) {
			formatted_device.device_config_id = device.device_config_id;
		}
		if (device.device_integration_id) {
			formatted_device.device_integration_id = device.device_integration_id;
		}
		if (device.cloud_native_integration_id) {
			formatted_device.cloud_native_integration_id = device.cloud_native_integration_id;
		}
		this.submit_device(formatted_device);
	}

	submit_device = (device) => {
		new Device(device).saveOrCreate().then( (result) => {
			Promise.all([this.attach_rules(result._id),this.attach_ingestors(result._id)]).then( () => {
				this.context.openSnackbar("The device has been created.", "success");
				this.props.onCreate(result);
			}).catch( (error) => {
				console.log(error);
				this.context.openSnackbar("The device has been created, but there was an issue attaching the policies or ingestors to this device.", "warning");
				this.props.onCreate(result);
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.setState({creating_device: false});
		});
	}

	attach_rules = (device_id) => {
		if (!this.state.device.rules || this.state.device.rules.length === 0) return Promise.resolve();
		const rule_ids = this.state.device.rules.map( ({value}) => value);
		return ADD_RELATIONS("devices", device_id, "rules", rule_ids);
	}
	attach_ingestors = (device_id) => {
		if (!this.state.device.ingestors || this.state.device.ingestors.length === 0) return Promise.resolve();
		const ingestor_ids = this.state.device.ingestors.map( ({value}) => value);
		return ADD_RELATIONS("devices", device_id, "ingestors", ingestor_ids);
	}

	heartbeat_to_seconds = () => {
		const { device } = this.state;
		const heartbeat_period = device.heartbeat_data.heartbeat_period;
		const heartbeat_time = device.heartbeat_data.heartbeat_time;
		switch (heartbeat_time) {
			case "Seconds":
				return parseInt(heartbeat_period);
			case "Minutes":
				return parseInt(heartbeat_period) * 60;
			case "Hours":
				return parseInt(heartbeat_period) * 3600;
			case "Days":
				return parseInt(heartbeat_period) * 86400;
		}
	}

	prepare_steps = () => {
		const { preset } = this.props;
		const device = this.state.device;
		this.button_configs = [
			{ back: {text: "Cancel", action: this.props.closeModal}, next: {text: "Next", action: this.progress} },
			{ back: {text: "Back", action: this.back}, next: {text: "Next", action: this.progress} },
			{ back: {text: "Back", action: this.back}, next: {text: "Next", action: this.progress} },
			{ back: {text: "Back", action: this.back}, next: {text: "Review", action: this.progress} },
			{ back: {text: "Back", action: this.back}, next: {text: "Create Device", action: this.create_device} }
		];
		this.title_information = [
			{
				title: "Basic Device Information",
				content: "In the first step of the device creation process we need to select the account the device will belong to, the unique identifier for that device, and a recognizeable but not necessarily unique name for the device. You can optionally add a serial number as well. The unique identifier for the device can only contain lowercase letters, numbers, or the special charaters - , : , or _",
				link: "https://dev.edgeiq.io/docs/",
				linkText: "Read More"
			},
			{
				title: "Device Configuration",
				content: "Please select the type of this device. The available types are determined by the account chosen in the previous step. If the device supports network configurations, you can also select a networking config once you've chosen your device type. Finally, you can assign an integration and define the Edge log levels for this device. Forwarded logs provide Edge errors and are automatically uploaded to the cloud. Setting the level of the forwarded logs lets you fine-tune the severity level of errors that are relayed. The device's filesystem logs errors as well, so setting the local log levels determines what severity of error appears in the device's local filesystem.",
				link: "https://dev.edgeiq.io/docs/",
				linkText: "Read More"
			},
			{
				title: "Extra Device Information",
				content: `In this step we can define the device further by setting its location, tags, and policies. If the device is a ${this.gateway_alias}, we can find and select endpoint devices to attach to it. If the device is an endpoint device, we can attach it to a ${this.gateway_alias}. Integrations allow the device to be integrated into the third-party IoT services you have available to your account. Policies allow you to orchestrate events that occur as a result of this device's status, reports, and more.`,
				link: "https://dev.edgeiq.io/docs/",
				linkText: "Read More"
			},
			{
				title: "Device Relations",
				content: `If your device is an endpoint device we can attach it to a ${this.gateway_alias}. If your device is a ${this.gateway_alias} device we can find and select several endpoints that will be connected to it.`,
				link: "https://dev.edgeiq.io/docs/",
				linkText: "Read More"
			},
			{
				title: "Review"
			}
		];
		this.steps = ["Basic Information", "Device Configuration", "Extra Information", "Device Relations", "Review"];
		this.step_render_functions = [
			() => (<CDStep1 preset={preset} device={device} renderTitle={this.render_title} renderButtons={this.render_buttons} closeModal={this.props.closeModal} />),
			() => (<CDStep2 preset={preset} key={(preset ? preset.company_id : device.nested_company? device.nested_company.company_id : undefined) + "_step_2"} device={device} renderTitle={this.render_title} renderButtons={this.render_buttons} closeModal={this.props.closeModal} />),
			() => (<CDStep3 preset={preset} key={(preset ? preset.company_id : device.nested_company? device.nested_company.company_id : undefined) + "_step_3"} device={device} renderTitle={this.render_title} renderButtons={this.render_buttons} closeModal={this.props.closeModal} />),
			() => (<CDStep4 preset={preset} key={(preset ? preset.company_id : device.nested_company? device.nested_company.company_id : undefined) + device.is_gateway + "_step_4"} device={device} renderTitle={this.render_title} renderButtons={this.render_buttons} closeModal={this.props.closeModal} />),
			() => (<CDStep5 preset={preset} key={(preset ? preset.company_id : device.nested_company? device.nested_company.company_id : undefined) + "_step_5"} device={device} renderTitle={this.render_title} renderButtons={this.render_buttons} closeModal={this.props.closeModal} />),
		];
	}

	render_buttons = (can_progress, updated_device) => {
		const { classes } = this.props;
		const button_configuration = this.button_configs[this.state.active_step];
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={button_configuration.back.action}
					className={classes.buttonOverride}
					color="primary"
				>
					{button_configuration.back.text}
				</Button>
				<Button
					variant="contained"
					className={classes.buttonOverride}
					aria-label="create"
					color="primary"
					size="large"
					onClick={() => can_progress() && button_configuration.next.action(updated_device)}
				>
					{button_configuration.next.text}
				</Button>
			</div>
		);
	}

	render_title = () => {
		const { classes } = this.props;
		const { active_step, device, creating_device } = this.state;
		const title_information = this.title_information[this.state.active_step];
		return (
			<div className={classes.title}>
				{title_information.title}
				{title_information.content ?
					<IconButton onClick={() => this.context.openHelp(title_information)} className={classes.infoIcon} aria-label="help">
						<HelpIcon/>
					</IconButton>
					: ""
				}
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		const { active_step, device, creating_device } = this.state;
		if (creating_device) {
			return (
				<div className={classes.modalWrapper + " " + classes.creatingModal}>
					<div className={classes.title + " " + classes.creatingDevice}>
						Creating Device...
					</div>
					<Loading />
				</div>
			);
		}
		return (
			<div className={classes.modalWrapper}>
				<div>
					<Stepper classes={{root: classes.stepperRoot}} activeStep={active_step}>
						{this.steps.map( (label, index) => (
							<Step key={label}>
								<StepLabel></StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
				{this.steps.map( (step, index) => {
					return index !== active_step ? 
						<div
							key={step + "_content"}
							className={classes.stepContentContainer}
							style={{display: "none"}}
						>
							{this.step_render_functions[index]()}
						</div>
					: 
						<div
							className={classes.stepContentContainer}
							key={step + "_content"}
							style={{display: "flex"}}
						>
							{this.step_render_functions[index]()}
						</div>
				})}
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		stepContentContainer: {
			justifyContent: "space-between",
			flexDirection: "column",
			minHeight: "528px"
		},
		modalWrapper: {
			width: "100%",
			height: "643px",
			fontFamily: "Inter",
			boxSizing: "border-box",
		},
		creatingModal: {
			alignItems: "center",
			flexDirection: "column",
			display: "flex"
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "24px",
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		title: {
			fontSize: "20px",
			lineHeight: "32px",
			fontWeight: "700",
			color: "rgba(0, 0, 0, 0.87)",
			margin: "0 0 32px 0",
			display: "flex",
		},
		infoIcon: {
			height: "28px",
			width: "28px",
			color: "#636366",
			margin: "0 0 0 12px",
			padding: 0,
			cursor: "pointer"
		},
		creatingDevice: {
			margin: "25% 0 24px 0",
		},
		stepperRoot: {
			padding: 0,
			marginBottom: "32px",
		},
	})
};

DeviceCreationFlow.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceCreationFlow));
