/* eslint-disable no-unused-expressions */
import { LIST, API_PROXY } from './CLURD';
import Integration from './DataModels/Integration'
import Auth from './Auth';
import Device from './DataModels/Device';
import DeviceType from './DataModels/DeviceType';
import { GET_RELATIONS } from './Relation';

let env = window.API;
//ensures a lack of a trailing backslash doesn't break everything.
if (env.charAt(env.length - 1) !== '/') {
    env += "/";
}

//Recursively gets ALL items from a model, optionally takes params.
export function GetAll(model, params) {
    return new Promise((resolve, reject) => {
        // reject();
        let totalLoaded = 0;
        let finalResults = [];
        let pageSize = 1000;

        recursive_request(totalLoaded, 1, params);

        function recursive_request(totalLoaded, page_number, params) {
            params = Object.assign({
                page: page_number,
                per_page: pageSize,
                page_meta: true
            }, params);
            LIST(model, params, Auth.token()).then((data) => {
                totalLoaded += pageSize;
                finalResults = finalResults.concat(data.items);
                if (totalLoaded < data.total) {
                    recursive_request(totalLoaded, page_number++);
                } else {
                    resolve(finalResults);
                }
            })
                .catch((error) => {
                    reject(error);
                    return;
                });
        }
    });
}

export function AssignRules(device) {
    return new Promise((resolve, reject) => {
        if (!device._id || device._id == '') {
            resolve();
            return;
        }
        Promise.all([GET_RELATIONS("devices", device._id, "rules"), GET_RELATIONS("device_types", device.device_type_id, "rules")]).then((results) => {
            if (results[0]) {
                device.nested_rules = results[0];
            }
            if (results[1]) {
                device.nested_type_rules = results[1];
            }
            resolve();
        }).catch((error) => reject(error));
    });
}

export function AssignIngestors(device) {
    return new Promise((resolve, reject) => {
        if (!device._id || device._id == '') {
            resolve();
            return;
        }
        Promise.all([GET_RELATIONS("devices", device._id, "ingestors"), GET_RELATIONS("device_types", device.device_type_id, "ingestors")]).then((results) => {
            if (results[0]) {
                device.nested_ingestors = results[0];
            }
            if (results[1]) {
                device.nested_type_ingestors = results[1];
            }
            resolve();
        }).catch((error) => reject(error));
    });
}

//Given an array of items (array items) where each item has a relation to another model (string nested_model) defined by an id field (string nested_model_field),
// lookup each of those relations and assign them to the items.
export function AssignNestedModels(nested_model, nested_model_field, items, custom) {
    return new Promise((resolve, reject) => {
        if (!items || items.length === 0) {
            resolve(items);
            return;
        }
        if (custom === "rules") {
            GetRuleActionIntegrations(items, resolve, reject);
        } else {
            let hash = {};
            let id_list = '';
            items.forEach((item) => {
                if (item[nested_model_field] && item[nested_model_field] !== '' && item[nested_model_field] !== null) {
                    if (Array.isArray(item[nested_model_field])) {
                        item[nested_model_field].forEach(addId);
                    } else {
                        addId(item[nested_model_field]);
                    }
                    function addId(id) {
                        if (id_list == '') {
                            id_list = id;
                        } else if (!hash[id]) {
                            id_list += "," + id;
                        }
                        hash[id] = true;
                    }
                }
            });
            if (id_list === "") {
                resolve(items);
                return;
            }
            LIST(nested_model, { _id_in: id_list, per_page: Object.entries(hash).length }, Auth.token()).then((data) => {
                items.forEach((item, index) => {
                    let field_name = "nested_" + nested_model_field.split("_id")[0];
                    if (Array.isArray(items[index][nested_model_field])) {
                        field_name = "nested_" + nested_model_field.split("_id")[0] + "s";
                    }
                    let match = data.items.find(({ _id }) => _id === item[nested_model_field]);
                    if (match) {
                        if (Array.isArray(item[nested_model_field])) {
                            item[field_name].push(JSON.parse(JSON.stringify(match)));
                        } else {
                            item[field_name] = JSON.parse(JSON.stringify(match));
                        }
                    }
                });
                resolve(items);
            }).catch((error) => {
                reject(error);
            });
        }
    });
}

function GetRuleActionIntegrations(rules, resolve, reject) {
    let hash = {};
    let id_list = '';
    rules.forEach((rule) => {
        if (rule.then_actions && rule.then_actions.length > 0) {
            rule.then_actions.forEach((action) => {
                if (action.integration_id && !hash[action.integration_id]) {
                    if (id_list == '') {
                        id_list = action.integration_id;
                    } else {
                        id_list += "," + action.integration_id;
                    }
                    hash[action.integration_id] = true;
                }
            });
        }
        if (rule.else_actions && rule.else_actions.length > 0) {
            rule.else_actions.forEach((action) => {
                if (action.integration_id && !hash[action.integration_id]) {
                    if (id_list == '') {
                        id_list = action.integration_id;
                    } else {
                        id_list += "," + action.integration_id;
                    }
                    hash[action.integration_id] = true;
                }
            });
        }
    });
    new Integration().listFromAPI({ _id_in: id_list, per_page: Object.entries(hash).length }).then((data) => {
        rules.forEach((rule) => {
            rule.then_actions ? rule.then_actions.forEach((action) => {
                data.items.forEach((integration) => {
                    if (integration._id == action.integration_id) {
                        switch (integration.type) {
                            case "aws_device_integrations":
                                integration.logo = "aws_icon.jpg";
                                break;
                            case "azure_device_integrations":
                                integration.logo = "azure_logo.png";
                                break;
                            case "bluemix_device_integrations":
                                integration.logo = "bluemix_icon.png";
                                break;
                            case "twilio_rule_action_integrations":
                                integration.logo = "twilio_icon.png";
                                break;
                            case "postmark_rule_action_integrations":
                                integration.logo = "postmark_icon.jpg";
                                break;
                        }
                        action.nested_integration = integration;
                    }
                });
            }) : "";
            rule.else_actions ? rule.else_actions.forEach((action) => {
                data.items.forEach((integration) => {
                    if (integration._id == action.integration_id) {
                        switch (integration.type) {
                            case "aws_device_integrations":
                                integration.logo = "aws_icon.jpg";
                                break;
                            case "azure_device_integrations":
                                integration.logo = "azure_logo.png";
                                break;
                            case "bluemix_device_integrations":
                                integration.logo = "bluemix_icon.png";
                                break;
                            case "twilio_rule_action_integrations":
                                integration.logo = "twilio_icon.png";
                                break;
                            case "postmark_rule_action_integrations":
                                integration.logo = "postmark_icon.jpg";
                                break;
                        }
                        action.nested_integration = integration;
                    }
                });
            }) : "";
        });
        resolve(rules);
    }).catch((error) => {
        reject(error);
    });
}

// `file` is expected to be a File - https://www.w3.org/TR/FileAPI/#dfn-file
export function UploadSoftwareUpdateFiles(endpoint, files) {
    const token = Auth.token();
    let fd = new FormData();
    files.forEach((file) => fd.append("files[]", file));
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        },
        method: 'POST',
        url: env + endpoint,
        data: fd,
    };
    return new Promise((resolve, reject) => {
        API_PROXY(options, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}
