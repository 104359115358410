import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Device from '../../../services/DataModels/Device';
import DeviceView from '../../DeviceSpecific/DeviceView';
import Auth from '../../../services/Auth';
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = {
	container: {
		overflow: "hidden",
		width: "100%",
		fontFamily: "Inter",
		overflow: "visible"
	}
};

class DeviceDetail extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			dataModel: null,
		};
		this.props.tabHostProxy.setTabRefresh(this.refresh);
		this.loadAndEnsureDevice();
	}

	refresh = () => {
		this.setState({dataModel: null});
		return new Device({_id: this.state.dataModel.data._id}).readFromAPI().then( (result) => {
			this.props.tabHostProxy.updateTabData('device', result._id, result, false);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			this.ensureDeviceIsReady(result);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	loadAndEnsureDevice() {
		let od = this.props.data;
		let typeOfData = typeof od;
		if (typeOfData === 'object' || od === undefined ) {
			this.ensureDeviceIsReady(od);
		} else {
			new Device({_id: od}).readFromAPI().then((result) => {
				this.props.tabHostProxy.updateTabData('device', result._id, result, false);
				this.ensureDeviceIsReady(result);
			}).catch((error) => {
				this.context.openSnackbar(error, "error");
				this.props.tabHostProxy.closeSelf();
			});
		}
	}

	ensureDeviceIsReady = (originalDevice) => {
		if (originalDevice && originalDevice._id && originalDevice._id !== "") {
			let dataModel = new Device(originalDevice);
			Device.decode(dataModel.data).then( (device) => {
				Device.loadRequirements(device).then( (device) => {
					if (device.omit_endpoint_types) {
						device.type_options = device.type_options.filter( ({type}) => type === "gateway");
					}
					device.type_options.push({_id: "", name: "Select Device Type*"});
					device.reqs_met = true;
					dataModel.setData(device);
					this.setState({dataModel: dataModel});
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
					this.props.tabHostProxy.closeSelf();
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.props.tabHostProxy.closeSelf();
			});
		} else {
			let dataModel = new Device();
			if (originalDevice) {
				dataModel.setData(originalDevice);
			}
			Device.loadRequirements(dataModel.data).then( (device) => {
				if (device.omit_endpoint_types) {
					device.type_options = device.type_options.filter( ({type}) => type === "gateway");
				}
				device.type_options.push({_id: "", name: "Select Device Type*"});
				device.company_id = Auth.user().company_id;
				device.reqs_met = true;
				dataModel.setData(device);
				this.setState({dataModel: dataModel});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.props.tabHostProxy.closeSelf();
			});
		}
	}

	render() {
		const {classes, tabHostProxy} = this.props;
		const { dataModel } = this.state;
		return (
			<div className={classes.container}>
				{dataModel === null ?
					<Loading/>
					:
					<DeviceView tabHostProxy={tabHostProxy} dataModel={dataModel} />
				}
			</div>
		)
	}
}

DeviceDetail.contextType = SnackbarContext;
export default withStyles(styles)(DeviceDetail);
