import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import TextInput from '../../Inputs/TextInput';
import NumberInput from '../../Inputs/NumberInput';
import SwitchInput from '../../Inputs/SwitchInput';
import Config from '../../../services/DataModels/Config';
import DeviceType from '../../../services/DataModels/DeviceType';
import Tooltip from '@material-ui/core/Tooltip';
import { GetAll } from '../../../services/CLURDUtilities';
import Button from '@material-ui/core/Button';
import PasswordInput from '../../Inputs/PasswordInput';
import TimeInput from '../../Inputs/TimeInput';
import Loading from '../../DisplayOriented/Loading';
import SelectInput from '../../Inputs/SelectInput';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Auth from '../../../services/Auth';
import Permissions from '../../../services/Permissions';
import Card from '@material-ui/core/Card';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

//icons
import DvrIcon from '@material-ui/icons/DvrOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import LanguageIcon  from '@material-ui/icons/Language';
import RouterIcon  from '@material-ui/icons/Router';
import SettingsEthernetIcon  from '@material-ui/icons/SettingsEthernet';
import SignalCellularAltIcon  from '@material-ui/icons/SignalCellularAlt';
import WifiIcon  from '@material-ui/icons/Wifi';
import NotesIcon from '@material-ui/icons/Notes';
import DescriptionIcon  from '@material-ui/icons/Description';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
	scrollContainer: {
		width: "inherit",
		overflowY: "auto",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	container: {
		margin: "32px 20%",
		display: "flex",
		flexWrap: "wrap"
	},
	iptablesGrid: {
		overflowY: "auto",
		display: "flex",
		flexWrap: "wrap",
		height: "calc(100% - 81px)",
		padding: "12px",
		width: "100%",
	},
	addIPTableEntryCard: {
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		color: theme.palette.pending.main,
		cursor: "pointer"
	},
	addText: {
		marginLeft: "8px",
	},
	iptableEntryContainer: {
		width: "33%",
		display: "flex",
		minHeight: "120px",
		fontFamily: "Inter"
	},
	largeIPTableEntry: {
		width: "33%",
		minWidth: "200px",
	},
	iptableEntryCard: {
		margin: "12px",
		padding: "8px",
		width: "100%",
		backgroundColor: "#f3f3f3",
		position: "relative",
	},
	iptableEntryWrapper: {
		marginBottom: "24px"
	},
	iptablesEntryIcon: {
		padding: "4px",
		margin: "2px",
	},
	selectInput: {
		backgroundColor: "white",
	},
	iconContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end"
	},
	tabsTitle: {
		fontSize: "16px",
		color: "#8e8e93",
		fontFamily: "Inter",
		marginLeft: "8px"
	},
	leftColumn: {
		flex: "33%",
		boxSizing: "border-box",
		padding: "12px 6px 12px 0"
	},
	rightColumn: {
		width: "100%",
		boxSizing: "border-box",
	},
	buttonContainer: {
		width: "100%",
		justifyContent: "flex-end",
		display: "flex",
		marginTop: "32px",
		marginBottom: "8px",
	},
	buttonOverride: {
		marginLeft: "8px",
		whiteSpace: "nowrap"
	},
	deleteButton: {
		marginRight: "8px",
		backgroundColor: theme.palette.red.main,
		color: "white",
		"&:hover": {
			backgroundColor: darken(theme.palette.red.main, .2),
		}
	},
	profileContainer: {
		border: "solid lightgrey 2px",
		borderRadius: "5px",
		width: "100%",
		textAlign: "center",
		boxSizing: "border-box"
	},
	modalWrapper: {
		padding: "24px"
	},
	gentleWarning: {
		fontFamily: "Inter",
		fontSize: "12px",
		marginBottom: "24px",
		fontStyle: "italic",
		color: "darkgrey",
	},
	linkButton: {
		padding: 0,
		textTransform: "lowercase",
		fontSize: "12px",
		verticalAlign: "baseline",
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "22px",
		marginBottom: "24px",
	},
	profileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto"
	},
	placeholderProfileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto",
		color: theme.palette.primary.main
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap"
	},
	selectType: {
		fontFamily: "Inter",
		marginLeft: "16px",
		marginTop: "12px",
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	modalButtons: {
		display: "flex",
		justifyContent: "flex-end"
	},
	inputIcon: {
		color: theme.palette.greyIcon.main,
		margin: "8px",
		paddingBottom: "20px"
	},
	noIcon: {
		visibility: "hidden"
	},
	labelOverride: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "88%"
	},
	badge: {
		right: "-6px"
	},
	badgeUnselected: {
		backgroundColor: "grey",
		color: "white"
	},
	rightButtons: {
		flex: "50%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	leftIcon: {
		marginRight: "12px",
	},
	notSupported: {
		fontFamily: "Inter",
		color: "#8e8e93",
		fontSize: "14px",
	},
	connectionName: {
		textTransform: "uppercase",
		color: "#8e8e93",
		marginBottom: "12px",
		fontFamily: "Inter",
	},
	configContainer: {
		paddingLeft: "12px"
	},
	viewTabs: {
		marginBottom: "12px"
	},
	switchWrapper: {
		width: "50%",
		marginBottom: "12px",
		marginTop: "-12px"
	},
	numberWrapper: {
		marginBottom: "12px",
	},
	selectWrapper: {
		marginTop: "32px"
	},
	selectTypeWrapper: {
		marginTop: "24px"
	},
	timeLabel: {
		fontFamily: "Inter",
		fontSize: "16px",
		color: "grey"
	},
	timeInputWrapper: {
		marginBottom: "12px"
	},
});

const iptable_entry_fields = [
	{"field":"action", "display":"Action*", "type":"action"},
	{"field":"chain", "display":"Chain*", "type":"string"},
	{"field":"table", "display":"Table", "type":"table"},
	{"field":"protocol", "display":"Protocol", "type":"protocol"},
	{"field":"source", "display":"Source", "type":"string"},
	{"field":"destination", "display":"Destination", "type":"string"},
	{"field":"source_port", "display":"Source Port", "type":"string"},
	{"field":"destination_port", "display":"Destination Port", "type":"string"},
	{"field":"in_interface", "display":"In Interface", "type":"string"},
	{"field":"out_interface", "display":"Out Interface", "type":"string"},
	{"field":"jump", "display":"Jump", "type":"string"}
];
const iptable_entry_protocol_options = [
	{"display":"", "value":""},
	{"display":"TCP", "value":"tcp"},
	{"display":"UDP", "value":"udp"}
];
const iptable_entry_action_options = [
	{"display":"", "value":""},
	{"display":"Append", "value":"append"},
	{"display":"Flush", "value":"flush"}
];
const iptable_entry_table_options = [
	{"display":"", "value":""},
	{"display":"Filter", "value":"filter"},
	{"display":"NAT", "value":"nat"},
	{"display":"Mangle", "value":"mangle"},
	{"display":"Raw", "value":"raw"},
	{"display":"Security", "value":"security"}
];
class ConfigTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			tab_index: 0,
			modal: {
				open: false,
				children: () => ""
			},
			editable_config: new Config(this.props.data).editableCopy(),
			type_options: null,
			company_options: null
		};
		this.load_companies();
		this.prepare_template();
		this.prepare_config();
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	prepare_config = (do_async) => {
		if ((this.state.editable_config._id && this.state.editable_config._id !== '') && this.state.editable_config.device_type_id !== "" && (!this.state.editable_config.nested_device_type || !this.state.editable_config.nested_device_type._id) ) {
			if (do_async) {
				this.setState({type_options: [{_id: this.state.editable_config.device_type_id, name: "TYPE UNAVAILABLE"}]});
			} else {
				this.state.type_options = [{_id: this.state.editable_config.device_type_id, name: "TYPE UNAVAILABLE"}];
			}
		} else if ( (!this.state.editable_config._id || this.state.editable_config._id === '') && this.state.editable_config.device_type_id !== "" && (!this.state.editable_config.nested_device_type || !this.state.editable_config.nested_device_type._id) ) {
			let model = new DeviceType().setData({_id: this.state.editable_config.device_type_id});
			model.readFromAPI().then( (type) => {
				this.setState((state) => {
					state.editable_config.nested_device_type = type;
					state.type_options = [{_id: type._id, name: type.name}];
					return state;
				}, () => {
					this.build_connections(do_async);
				});
			}).catch( (error) => {
				this.props.tabHostProxy.closeSelf();
				this.context.openSnackbar(error, "error");
			});
		} else {
			this.build_connections(do_async);
			if (!this.state.editable_config._id) {
				this.load_types();
			} else {
				if (do_async) {
					this.setState({type_options: [{_id: this.state.editable_config.nested_device_type._id, name: this.state.editable_config.nested_device_type.name}]});
				} else {
					this.state.type_options = [{_id: this.state.editable_config.nested_device_type._id, name: this.state.editable_config.nested_device_type.name}];
				}
			}
		}
		this.editing_disabled = this.state.editable_config._id && !Permissions.allow(["update"], "device_config", this.state.editable_config.company_id);
		this.can_delete = this.state.editable_config._id && Permissions.allow(["delete"], "device_config", this.state.editable_config.company_id);
	}

	refresh = () => {
		this.setState({type_options: null, editable_config: null});
		new Config({_id: this.props.data._id}).readFromAPI().then( (config) => {
			let configs = [config];
			Config.loadRequirements(configs).then( () => {
				config = configs[0];
				this.setState({editable_config: config}, () => this.prepare_config(true));
			})
			this.props.tabHostProxy.updateTitle(config._id, config);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_companies = () => {
		GetAll("companies").then( (companies) => {
			companies.push({_id: "", name: "Account determined by Device Type Selected"});
			this.setState({company_options: companies});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_types = () => {
		let params = {type: "gateway"};
		if (Auth.currentUser().company_ids.length > 1 && !Permissions.allow(["create"], "device_config", Auth.currentUser().company_ids[1])) {
			params.company_id = Auth.currentUser().company_ids[0];
		}
		GetAll("device_types", params).then( (types) => {
			types.push({_id: "", name: "Select Device Type*"});
			this.setState({type_options: types});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	build_connections = (do_async) => {
		if (this.state.editable_config.device_type_id !== "" && this.state.editable_config.nested_device_type) {
			let connections = this.state.editable_config.nested_device_type.capabilities.network_connections;
			if (!connections || connections.length === 0) {
				if (do_async) {
					this.setState( (state) => {
						state.editable_config.connections = [];
						return state;
					});
				} else {
					this.state.editable_config.connections = [];
				}
				return;
			}
			Object.entries(this.connection_template).forEach( ([connection_type, connection]) => {
				let names = connections.reduce( (total, {type, name, ...others}) => {
					if (type === connection_type) {
						total.push(name && name !== "" ? name : others.interface);
					}
					return total;
				}, []);
				connection.supported = names.length === 0 ? false : true;
				connection.names = names;
			});
			let new_connections = [];
			connections.forEach( (connection) => {
				if (connection.type === "firewall") {
					return;
				}
				let config_connection = this.state.editable_config.connections ? this.state.editable_config.connections.find( ({type, name}) => (connection.name === name || connection.interface === name )&& connection.type === type) || {} : {};
				let new_connection = {
					name: connection.name && connection.name !== "" ? connection.name : connection.interface,
					type: connection.type,
					config: Object.assign(JSON.parse(JSON.stringify(this.connection_template[connection.type].config)), config_connection.config)
				};
				new_connection.interface = new_connection.name;
				Object.entries(new_connection.config).forEach( ([field, value]) => {
					if (typeof value === "object") {
						new_connection.config[field] = value[0];
					}
					if (field === "dhcp_lease" || field === "cell_reconn_delay") {
						new_connection.config[field] = this.prepare_time_input(value);
					}
				});
				new_connections.push(new_connection);
			});
			if (do_async) {
				this.setState( (state) => {
					state.editable_config.connections = new_connections;
					return state;
				});
			} else {
				this.state.editable_config.connections = new_connections;
			}
		}
	}

	to_seconds = (time_state) => {
		switch (time_state.time) {
			case "Seconds":
				return parseInt(time_state.value);
			case "Minutes":
				return parseInt(time_state.value) * 60;
			case "Hours":
				return parseInt(time_state.value) * 3600;
			case "Days":
				return parseInt(time_state.value) * 86400;
		}
	}

	prepare_time_input = (seconds) => {
		let time_state = {value: null, time: null};
		if (seconds % 86400 === 0) {
			time_state.time = "Days";
			time_state.value = seconds / 86400;
		} else if (seconds % 3600 === 0) {
			time_state.time = "Hours";
			time_state.value = seconds / 3600;
		} else if (seconds % 60 === 0) {
			time_state.time = "Minutes";
			time_state.value = seconds / 60;
		} else {
			time_state.time = "Seconds";
			time_state.value = seconds;
		}
		return time_state;
	}

	prepare_template = () => {
		const eth_config = {
			net_enable: 0,
			net_nat: 0,
			net_ip_assign: ["static", "auto"],
			net_ip: "",
			net_gateway: "",
			net_mask: "",
			net_broadcast: "",
			net_dns_1: "",
			net_dns_2: "",
			net_mtu: 1500,
			net_metric: 1
		};

		function dhcp_template(template) {
			template = Object.assign(JSON.parse(JSON.stringify(template)), {});
			template.dhcp_enable = 0;
			template.dhcp_relay = 0;
			template.dhcp_dns1 = "";
			template.dhcp_dns2 = "";
			template.dhcp_start = "";
			template.dhcp_end = "";
			template.dhcp_lease = 86400;
			return template;
		}

		function wlan_template(template) {
			template = Object.assign(JSON.parse(JSON.stringify(template)), {net_mode: ["LAN", "WAN"]});
			template = dhcp_template(template);
			return template;
		}

		this.connection_template = {
			'ethernet-wan': {
				label: "Ethernet-WAN",
				config: eth_config,
				icon: (<LanguageIcon />)
			},
			'ethernet-wan-lan': {
				label: "Ethernet-WAN/LAN",
				config: wlan_template(eth_config),
				icon: (<RouterIcon />)
			},
			'ethernet-lan': {
				label: "Ethernet-LAN",
				config: dhcp_template(eth_config),
				icon: (<SettingsEthernetIcon />)
			},
			'cellular': {
				label: "Cellular",
				config: {
					cell_enable: 0,
					cell_auth: ["chap", "pap"],
					cell_pass: "",
					cell_reconn_delay: 300,
					cell_user: "",
					cell_auto_apn: 0,
					cell_apn: "",
					cell_reconn_retries: 1,
					cell_mtu: 1500
				},
				icon: (<SignalCellularAltIcon />)
			},
			'wifi': {
				label: "WIFI",
				config: {
					wifi_enable: 0,
					wifi_hidden: 0,
					wifi_ssid: "",
					wifi_psk: "",
					wifi_auth: ["wpa2psk", "wpapsk"],
					wifi_crypt: ["aes", "tkip"],
					wifi_mode: ["ap", "client"],
					wifi_channel: 1,
					net_ip_assign: ["static", "auto"],
					net_ip: "",
					net_gateway: "",
					net_mask: "",
					net_broadcast: "",
					net_dns_1: "",
					net_dns_2: ""
				},
				icon: (<WifiIcon />)
			},
			'iptables': {
				label: "IP-TABLES",
				config: {},
				icon: (<NotesIcon />)
			}
		};
		this.fields_formatted = {
			net_enable: "Enabled",
			net_nat: "NAT",
			net_broadcast: "Broadcast IP",
			net_gateway: "Gateway IP",
			net_ip_assign: "IP Assignment",
			net_ip: "IP Address",
			net_dns_2: "DNS 2",
			net_dns_1: "DNS 1",
			net_mode: "Net Mode",
			cell_reconn_retries: "Reconnection Retries",
			cell_mtu: "Cellular Max Transmission Unit",
			net_mtu: "Max Transmission Unit",
			net_mask: "Net Mask",
			net_metric: "Metric",
			firewall_enable: "Enabled",
			firewall_rules: "Firewall Rules",
			wifi_enable: "Enabled",
			wifi_channel: "Channel (1-14, use keyboard's up and down arrows)",
			wifi_crypt: "Encryption",
			wifi_ssid: "SSID",
			wifi_psk: "Password/Secret",
			wifi_mode: "WIFI Mode",
			wifi_auth: "WIFI Authentication",
			cell_enable: "Enabled",
			cell_auth: "Cell Authentication",
			cell_pass: "Password",
			cell_reconn_delay: "Reconnect Delay",
			cell_user: "Username",
			cell_apn: "Access Point Name (APN)",
			cell_auto_apn: "Automatic APN",
			dhcp_enable: "DHCP Enabled",
			wifi_hidden: "Hidden Network",
			dhcp_dns1: "DHCP DNS 1",
			dhcp_dns2: "DHCP DNS 2",
			dhcp_start: "DHCP Start",
			dhcp_relay: "DHCP Relay",
			dhcp_end: "DHCP End",
			dhcp_lease: "DHCP LEASE",
		};
	}

	can_save = () => {
		let show_errors = false;
		const editable_config = this.state.editable_config;
		if (editable_config.name === "" || editable_config.device_type_id === "" ) {
			show_errors = true;
		}
		this.setState({ show_errors: show_errors });
		return !show_errors;
	}

	save_config = () => {
		if (this.can_save()) {
			new Config(this.format_config())
				.createOrSave()
				.then((result) => Config.loadRequirements([result]))
				.then( ([result]) => {
					if (this.state.editable_config._id) {
						this.context.openSnackbar("Config saved.", "success");
					} else {
						this.context.openSnackbar("Config created.", "success");
					};
					this.props.tabHostProxy.refresh();
					this.props.tabHostProxy.closeSelf();
					this.props.tabHostProxy.addTab("config", result);
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
				});
		}
	}

	format_config = () => {
		let body = new Config(this.state.editable_config).editableCopy();
		delete body.nested_device_type;
		body.connections.forEach( (connection) => {
			Object.entries(connection.config).forEach( ([key, value]) => {
				if (key === "cell_reconn_delay" || key === "dhcp_lease") {
					connection.config[key] = this.to_seconds(connection.config[key]);
				}
			});
		});
		console.log(body);
		return body;
	}

	confirm_delete = (config, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Delete this network config? This action cannot be reversed.
						</div>
						<div className={classes.modalButtons}>
							<Button
								variant="outlined"
								color="primary"
								className={classes.buttonOverride}
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.buttonOverride}
								onClick={() => this.delete_config(config)}
							>
								DELETE CONFIG
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	delete_config = (config) => {
		new Config(config)
			.deleteFromAPI()
			.then( () => {
				this.context.openSnackbar("Network config deleted.", "success");
				this.close_modal();
				this.props.tabHostProxy.refresh();
				this.props.tabHostProxy.closeSelf();
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	render() {
		const { classes } = this.props;
		const {email_valid, modal, editable_config, valid_profile_image, show_errors, company_options, type_options, ready } = this.state;
		if (editable_config == null || editable_config.connections == null || type_options === null || company_options === null ) return (<Loading />);
		return (
			<div className={classes.scrollContainer}>
				<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
					{modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.container}>
					<div className={classes.rightColumn}>
						<div className={classes.gentleWarning}>
							Network configuration is important to get right. If a connection is misconfigured it's possible to lose connection to the device. Please review <Button color="primary" className={classes.linkButton} target="_new" href="https://dev.edgeiq.io/docs/network-configuration">our documentation</Button> if you have any questions.
						</div>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon} key="Name" title="Name">
								<DescriptionIcon />
							</Tooltip>
							<TextInput
								emitChange={this.handleTextChange}
								priorState={editable_config.name}
								disabled={this.editing_disabled}
								error_message="A network config's name cannot be blank."
								error={editable_config.name === "" && show_errors}
								label="Name*"
								key="name"
								field="name"
							/>
						</div>
						<div className={classes.inputContainer + " " + classes.selectTypeWrapper}>
							<Tooltip className={classes.inputIcon} title="Device Type">
								<DvrIcon />
							</Tooltip>
							<SelectInput
								disabled={editable_config._id ? true : false}
								label="Device Type*"
								disabled={this.editing_disabled}
								error={show_errors && editable_config.device_type_id === ""}
								error_message="Please select a device type."
								emitChange={this.handle_device_type_change}
								priorState={editable_config.device_type_id}
								options={type_options.map((option) => ({ display: option.name, value: option._id }))}
							/>
						</div>
						{Auth.currentUser().company_ids.length > 1 ? <div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon} key="Account" title="Account">
								<AccountCircleIcon />
							</Tooltip>
							<SelectInput
								field="company_id"
								disabled={true}
								emitChange={this.handleChange}
								label="Account"
								priorState={editable_config.company_id}
								options={company_options.map((option) => ({ display: option.name, value: option._id }))}
							/>
						</div> : "" }
						<div className={classes.tabsTitle}>
							Connections
						</div>
						{editable_config.device_type_id === "" ?
							<div className={classes.selectType}>Select a device type in order to configure the appropriate the network settings.</div>
							: 
							<React.Fragment>
								<Tabs
									className={classes.viewTabs}
									value={this.state.tab_index}
									onChange={(event, value) => this.setState({ tab_index: value })}
									variant="scrollable"
									scrollButtons="auto"
									indicatorColor="primary"
									textColor="primary"
								>
									{Object.entries(this.connection_template).map( ([type, {names, label, icon, config}], index) => (
										<Tab
											key={type}
											icon={icon}
											label={this.render_tab_label(type, names, index)}
										/>
									))}
								</Tabs>
								{Object.entries(this.connection_template).map( ([type, {names, supported, config}], index) => (
									   (() => {
										if (index === this.state.tab_index) {
											if (type === "iptables")
												return this.render_iptables()
											else
												return this.render_connection(type, supported, config, names)
										} else { 
										   return ""
										}
									})()									
								))}
							</React.Fragment>
						}
					</div>
					<div className={classes.buttonContainer}>
						<div className={classes.rightButtons}>
							{editable_config._id ? 
								<Button
									disabled={!this.can_delete}
									className={classes.buttonOverride}
									onClick={() => this.confirm_delete(editable_config)}
									color="primary"
								>
									DELETE
								</Button>
							:""}
							<Button
								disabled={this.editing_disabled}
								className={classes.buttonOverride}
								onClick={this.save_config}
								variant="contained"
								color="primary"
								size="large"
							>
								{editable_config._id ? "SAVE CONFIG" : "CREATE CONFIG"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
		
	}

	render_tab_label = (connection_type, names, index) => {
		if (!names || names.length === 0) {
			return connection_type;
		}
		return (
			<Badge classes={{badge: this.props.classes.badge + " " + (index !== this.state.tab_index ? this.props.classes.badgeUnselected : "")}} color={index === this.state.tab_index ? "primary" : "default"} badgeContent={names.length}>
				{connection_type}
			</Badge>
		);
	}

	render_iptables = () => {
		if (!this.state.editable_config.nested_device_type.capabilities.iptables) {
			return <div key={"iptables_not_supported"} className={this.props.classes.notSupported}>This type of connection setting isn't supported for this type of device.</div>;
		}
		return (
			<div key={"iptables_container"} className={this.props.classes.container}>
				<div className={this.props.classes.iptablesGrid}>
					{this.state.editable_config.iptables != null ? this.state.editable_config.iptables.map( (entry, index) => (
						this.render_iptable_entry(entry, index)
					)) : ""}
					{this.props.disabled ? "" : <div className={this.props.classes.iptableEntryContainer}>
						<Card className={this.props.classes.iptableEntryCard}>
							<div onClick={() => this.add_iptable_entry()} className={this.props.classes.addIPTableEntryCard}>
								<AddBoxIcon />
								<span className={this.props.classes.addText}>
									IP Table Entry
								</span>
							</div>
						</Card>
					</div>}
				</div>
			</div>
		);
	}


	render_iptable_entry = (entry, index) => {
		return (
			<div
				key={"iptable_entry_" + index}
 				className={this.props.classes.iptableEntryContainer + " " + this.props.classes.largeIPTableEntry }
			>
				<Card className={this.props.classes.iptableEntryCard}>
					IP Table Entry ({index})
					<div className={this.props.classes.iconContainer}>
						{this.props.disabled ? "" :
						<Tooltip className={this.props.classes.iptableEntryIcon} title="Remove Entry">
							<IconButton onClick={() => this.remove_iptable_entry(index)} color="inherit">
								<CloseIcon />
							</IconButton>
						</Tooltip>}
					</div>

					{this.render_iptable_entry_fields(entry, index)}
				</Card>
			</div>
		);
	}

	remove_iptable_entry = (index) => {
		this.state.editable_config.iptables.splice(index, 1);
		this.setState({editable_config: this.state.editable_config});
	}
	add_iptable_entry = () => {
		let newIPTableEntry = {};
		if (!this.state.editable_config.iptables) {
			this.state.editable_config.iptables = [];
		}
		this.state.editable_config.iptables.push(newIPTableEntry);
		this.setState({editable_config: this.state.editable_config});
	}

	render_iptable_entry_fields = (entry, index) => {
		let fields = [];
		iptable_entry_fields.forEach( (field, field_index) => {
			let key = "iptables_entry_" + index + "_" + field.field + "_field";
			switch (field.type) {
				case "action":
					fields.push(
						<div key={key} className={this.props.classes.iptableEntryWrapper}>
							<SelectInput
								disabled={this.props.disabled}
								basic
								emitChange={(input) => this.render_iptable_entry_field_change(input, index,field)}
								priorState={this.state.editable_config.iptables[index][field.field]}
								label={field.display}
								options={iptable_entry_action_options}
							/>
						</div>
					);
					break;
				case "protocol":
					fields.push(
						<div key={key} className={this.props.classes.iptableEntryWrapper}>
							<SelectInput
								disabled={this.props.disabled}
								basic
								emitChange={(input) => this.render_iptable_entry_field_change(input, index,field)}
								priorState={this.state.editable_config.iptables[index][field.field]}
								label={field.display}
								options={iptable_entry_protocol_options}
							/>
						</div>
					);
					break;
				case "table":
					fields.push(
						<div key={key} className={this.props.classes.iptableEntryWrapper}>
							<SelectInput
								disabled={this.props.disabled}
								basic
								emitChange={(input) => this.render_iptable_entry_field_change(input, index,field)}
								priorState={this.state.editable_config.iptables[index][field.field]}
								label={field.display}
								options={iptable_entry_table_options}
							/>
						</div>
					);
					break;
				case "string":
					fields.push(
						<div key={key} className={this.props.classes.iptableEntryWrapper}>
							<TextInput
								disabled={this.props.disabled}
								multiline={true}
								label={field.display}
								name={key}
								type="text"
								margin="none"
								priorState={this.state.editable_config.iptables[index][field.field]}
								emitChange={(input) => this.render_iptable_entry_field_change(input, index, field)}
							/>
						</div>
					);
					break;
				default:
					break;
			};
		});
		return fields;
	}


	render_iptable_entry_field_change = (input, index, field) => {
		switch (field.type) {
			case "action":
				if (input.value == "flush") {
					// TODO: disable all fields except chainn
				}
				break;
			case "protocol":
				if (input.value != "") {
					// TODO: enable port fields
				}
				break;
		}
		this.state.editable_config.iptables[index][field.field] = input.value;
		this.setState({editable_config: this.state.editable_config});
	}

	render_connection = (type, supported, config, names) => {
		if (!supported) {
			return <div key={type + "_not_supported"} className={this.props.classes.notSupported}>This type of connection setting isn't supported for this type of device.</div>;
		}
		return (
			names.map( (name) => (
				<div key={type + "_" + name}>
					<div className={this.props.classes.connectionName}>
						{name}
					</div>
					<div className={this.props.classes.configContainer}>
						{Object.entries(config).map( ([field, value]) => (
							this.render_field(field, value, name, type)
						))}
					</div>
					
				</div>
			))
		);
	}

	render_field = (field, value, con_name, con_type) => {
		let field_type = typeof value;
		let connection = this.state.editable_config.connections.find( (connection) => connection.type === con_type && (connection.interface === con_name || connection.name === con_name)).config;
		let state = connection[field];
		if (field === "net_ip_assign" && con_type === "ethernet-lan") {
			return "";
		}
		if (connection.net_ip_assign === "auto") {
			if (field === "net_ip" || field === "net_gateway" || field === "net_mask" || field === "net_broadcast" || field === "net_dns_1" || field === "net_dns_2") {
				return "";
			}
		}
		if (field.indexOf("dhcp_") !== -1) {
			if (connection.net_mode === "WAN") {
				return "";
			}
		}
		if (field === "cell_apn" && connection.cell_auto_apn === 1) {
			return "";
		}
		if (connection.wifi_mode === "ap" && 
			(field === "net_ip_assign" ||
			field === "net_ip" ||
			field === "net_gateway" ||
			field === "net_mask" ||
			field === "net_broadcast" ||
			field === "net_dns_1" ||
			field === "net_dns_2"
		)) {
			return "";
		}
		switch (field_type) {
			case "string":
				if (field === "wifi_psk") {
					return (
						<PasswordInput
							emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							label={this.fields_formatted[field]}
							priorState={state}
							key={con_name + "_" + field}
							field={this.fields_formatted[field]}
							disabled={this.editing_disabled}
						/>
					);
				} else {
					return (
						<TextInput
							emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							label={this.fields_formatted[field]}
							priorState={state}
							key={con_name + "_" + field}
							field={this.fields_formatted[field]}
							disabled={this.editing_disabled}
						/>
					);
				}
			case "number":
				if (value !== 0) {
					if (field === "cell_reconn_delay" || field === "dhcp_lease") {
						return (
							<div className={this.props.classes.timeInputWrapper} key={con_name + "_" + field}>
								<div className={this.props.classes.timeLabel}>
									{this.fields_formatted[field]}
								</div>
								<TimeInput
									margin="none"
									emitChange={(input) => this.handle_time_change(field, con_name, input, con_type)}
									priorState={state}
									disabled={this.editing_disabled}
								/>
							</div>
						);
					}
					let minimum = 0;
					let maximum = undefined;
					if (field === "wifi_channel") {
						minimum = 1;
						maximum = 14;
						if (connection.wifi_mode === "client") {
							return "";
						}
					}
					return (
						<div className={this.props.classes.numberWrapper} key={con_name + "_" + field}>
							<NumberInput
								disabled={this.editing_disabled}
								minimum={minimum}
								maximum={maximum}
								label={this.fields_formatted[field]}
								field={field}
								priorState={state}
								emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							/>
						</div>
						
					);
				} else {
					return (
						<div key={con_name + "_" + field} className={this.props.classes.switchWrapper}>
							<SwitchInput
								disabled={this.editing_disabled}
								initial={state === 1 ? true : false}
								emitChange={() => this.toggle_input(con_name, field, con_type)}
								color={this.props.theme.palette.pending.main}
								onLabel={this.fields_formatted[field]}
								offLabel={this.fields_formatted[field]}
								field={field}
								location="start"
							/>
						</div>
					);
				}
			case "object":
				let options = [];
				if (field === "cell_auth") {
					options = [{display: "CHAP", value: "chap"}, {display: "PAP", value: "pap"}];
				} else if (field === "wifi_crypt") {
					options = [{display: "AES", value: "aes"}, {display: "TKIP", value: "tkip"}];
				} else if (field === "wifi_auth") {
					options = [{value: "wpa2psk", display: "WPA2-PSK"}, {display: "WPA-PSK", value: "wpapsk"}];
				} else if (field === "wifi_mode") {
					options = [{value: "ap", display: "Access Point"}, {value: "client", display: "Client"}];
				}else {
					options = this.connection_template[con_type].config[field].map( (option) => ({display: option.charAt(0).toUpperCase() + option.slice(1), value: option}));

				}
				return (
					<div key={con_name + "_" + field} className={this.props.classes.selectWrapper}>
						<SelectInput
							disabled={this.editing_disabled}
							emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							priorState={state}
							field={field}
							label={this.fields_formatted[field]}
							options={options}
						/>
					</div>
				);
		}
	}

	toggle_input = (con_name, field, type) => {
		this.state.editable_config.connections.forEach( (connection) => {
			if (connection.name === con_name && type === connection.type) {
				connection.config[field] = connection.config[field] === 1 ? 0 : 1;
			}
		});
		this.setState({editable_config: this.state.editable_config});
	}

	handle_time_change = (field, con_name, input, type) => {
		this.state.editable_config.connections.forEach( (connection) => {
			if (connection.name === con_name && type === connection.type) {
				connection.config[field] = input;
			}
		});
		this.setState({editable_config: this.state.editable_config});
	}

	handle_config_change = (field, con_name, input, type) => {
		this.state.editable_config.connections.forEach( (connection) => {
			if (connection.name === con_name && type === connection.type) {
				connection.config[field] = input.value;
			}
		});
		this.setState({editable_config: this.state.editable_config});
	}

	handleTextChange = ({field, value}) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState( (prev_state) => {
			return Object.assign(prev_state.editable_config, overwrite);
		});
	}

	handle_device_type_change = (input) => {
		let new_config = this.state.editable_config;
		new_config.device_type_id = input.value;
		if (new_config.device_type_id === "") {
			new_config.nested_device_type = null;
			this.setState({editable_config: new_config});
		} else {
			new_config.nested_device_type = this.state.type_options.find( (type) => type._id === new_config.device_type_id);
			new_config.connections = [];
			new_config.company_id = new_config.nested_device_type.company_id;
			Object.entries(this.connection_template).forEach( ([type, connection]) => connection.supported = false);
			this.setState({editable_config: new_config}, () => {
				this.build_connections(true);
				this.setState({editable_config: this.state.editable_config});
			});
		}
	}
}

ConfigTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(ConfigTab));
