import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import DeviceHAGroup from '../../services/DataModels/DeviceHAGroup';
import Loading from '../DisplayOriented/Loading';
import { GetAll, AssignNestedModels } from '../../services/CLURDUtilities';
import TableList from '../Table/TableList';
import CreateDeviceForm from '../DeviceSpecific/CreateDeviceForm';

//input
import LookupInput from '../Inputs/LookupInput';
import SelectInput from '../Inputs/SelectInput';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

//icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SaveIcon from '@material-ui/icons/Save';

const styles = theme => ({
	modalWrapper: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
		padding: "24px",
		minHeight: "600px",
		minWidth: "75vw",
		fontFamily: "Inter",
	},
	modalTitle: {
		fontSize: "24px",
		margin: "12px 0 12px 12px",
		display: "flex",
	},
	subTitle: {
		fontSize: "16px",
		color: "grey",
		margin: "0 0 24px 12px",
		display: "flex",
		alignItems: "center"
	},
	lookupNodeWrapper: {
		width: "80%",
		display: "flex",
		flexWrap: "nowrap"
	},
	inputIcon: {
		color: theme.palette.greyIcon.main,
		margin: "8px",
		paddingBottom: "20px"
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap"
	},
	configInput: {
		marginTop: "32px"
	},
	editNodeArea: {
		marginTop: "42px",
		borderBottom: "solid lightgrey 2px",
		paddingBottom: "24px" 
	},
	editNodeAreaTitle: {
		marginLeft: "12px",
		fontSize: "18px"
	},
	addNodeIcon: {
		margin: "auto",
		marginLeft: "12px",
		padding: 0
	},
	link: {
		marginLeft: "40px",
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		},
	},
	configLinks: {
		marginTop: "-18px",
		marginBottom: "32px"
	},
	nodeRenderArea: {
		marginLeft: "8px",
		marginTop: "12px"
	},
	noNodes: {
		fontStyle: "italic",
		color: "grey",
		marginLeft: "32px"
	},
	nodeAreaTitle: {
		display: "flex",
		alignItems: "center",
		color: "grey",
		marginBottom: "24px",
		marginTop: "24px",
		fontSize: "18px"
	},
	tableListContainer: {
		marginLeft: "32px"
	},
	nodeIcon: {
		marginRight: "8px"
	},
	invalidConfig: {
		marginLeft: "40px",
		marginTop: "-18px",
		color: "red"
	},
	editNodeButtonContainer: {
		marginTop: "12px",
		display: "flex",
		justifyContent: "flex-end"
	},
	iconsContainer: {
		whiteSpace: "nowrap"
	},
	borderButton: {
		marginLeft: "12px",
		borderColor: "white",
		color: theme.palette.grey.main
	},
	saveNodeButton: {
		marginLeft: "12px",
		borderColor: theme.palette.green.main,
		color: theme.palette.green.main
	},
	saveButton: {
		marginLeft: "12px",
		borderColor: theme.palette.pending.main,
		color: theme.palette.pending.main
	},
	invalidNodes: {
		marginLeft: "40px",
		marginTop: "24px",
		color: "red"
	},
	buttonIcon: {
		marginRight: "4px"
	},
	actionIcon: {
		height: "28px",
		width: "28px",
		color: theme.palette.greyIcon.main,
		padding: 0,
		cursor: "pointer"
	},
	editing: {
		color: theme.palette.pending.main
	},
});

class AddHAGroupNode extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			node_options: null,
			node: null,
			config_options: null,
			config_id: "",
			interface_options: null,
			interface: '',
			edit_node: null,
			nodes: [],
			creating_node: false,
			types: null,
			newly_created_nodes: []
		};
		this.heading_info = [
			{label: "Actions", value: "device.table_actions", nested_field: true, field: "actions", align: "center", disablePadding: false, sortable: false, content: "function"},
			{label: "Name", value: "device.table_name", nested_field: true, field: "name", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "Unique ID", value: "device.unique_id", field: "unique_id", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Config", value: "device.table_config", nested_field: true, field: "config", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "Interface", value: "interface", field: "interface", nested_field: false, align: "left", disablePadding: false, sortable: false}
		];
		this.load_node_options();
	}

	render_table_actions = (node) => {
		const classes = this.props.classes;
		return (
			<div className={classes.iconsContainer}>
				<Tooltip title="Edit Node">
					<IconButton onClick={() => node.device.table_highlighted ? this.cancel_edit() : this.edit_node(node)} className={classes.actionIcon + " " + (node.device.table_highlighted ? classes.editing : "")} aria-label="edit">
						<EditIcon/>
					</IconButton>
				</Tooltip>
				<Tooltip title="Remove Node">
					<IconButton onClick={() => this.remove_node(node)} className={classes.actionIcon} aria-label="remove">
						<DeleteIcon/>
					</IconButton>
				</Tooltip>
			</div>
		);
	}

	edit_node = (node) => {
		this.setState( (state) => {
			state.nodes.forEach( (option) => {
				if (option.device._id === node.device._id) {
					option.device.table_highlighted = true;
				} else {
					option.device.table_highlighted = false;
				}
			});
			state.show_errors = false;
			state.edit_node = node;
			return state;
		});
		this.load_node_configs(node.device.device_type_id, node.device.device_config_id);
	}

	load_node_configs = (type_id, config_id) => {
		let params = {device_type_id: type_id};
		GetAll("device_configs", params).then( (configs) => {
			configs = configs.map( (config) => ({value: config._id, display: config.name, whole: config}));
			let config = configs.find( (config) => (config.value === config_id));
			configs.push({display: "Select a Network Config", value: ''});
			this.setState( (state) => {
				state.config_options = configs || [];
				state.config_id = config ? config.value : '';
				if (config) {
					state.interface_options = this.get_interface_options(config.whole);
					state.interface_options.push({display: "Select a Network Interface", value: ''});
					state.interface = this.state.edit_node.interface || '';
				}
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	get_interface_options = (config) => {
		let options = [];
		config.connections.forEach( (connection) => {
			let name = connection.interface && connection.interface !== "" ? connection.interface : connection.name;
			if (connection.type === "ethernet-wan" && connection.config.net_ip_assign === "static") {
				options.push(name);
			} else if (connection.type === "ethernet-wan-lan" && connection.config.net_mode.toUpperCase() === "WAN" && connection.config.net_ip_assign === "static") {
				options.push(name);
			} else if (connection.type === "wifi" && connection.config.wifi_mode === "client" && connection.config.net_ip_assign === "static") {
				options.push(name);
			}
		});
		return options.length > 0 ? options.map( (option) => ({display: option, value: option})) : null;
	}

	cancel_edit = () => {
		this.setState( (state) => {
			state.edit_node = null;
			state.show_errors = false;
			state.interface = '';
			state.config_id = '';
			state.nodes.forEach( (node) => node.device.table_highlighted = false);
			return state;
		});
	}

	render_table_config = (config) => {
		if (!config) return null;
		return config.name;
	}

	render_table_heartbeat = (device) => {
		const hb_map = {
			online: this.props.theme.palette.green.main,
			offline: this.props.theme.palette.heartbeat.offline.main,
			idle: this.props.theme.palette.heartbeat.idle.main,
			never_reported: "grey"
		};
		const color = hb_map[device.heartbeat_status];
		const display = device.name;
		return (
			<div style={{display: "flex", marginLeft: "-14px", alignItems: "center"}}>
				<div style={{marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color}}>
					&nbsp;
				</div>
				<div>
					{display}
				</div>
			</div>
		);
	}

	remove_node = (node) => {
		this.setState( (state) => {
			state.nodes.splice(state.nodes.findIndex( (option) => option.device._id === node.device._id), 1);
			if (node.device.table_highlighted) {
				state.edit_node = null;
				node.device.table_highlighted = false;
			}
			return state;
		});
	}

	table_formatting = (node) => {
		node.device.table_actions = () => this.render_table_actions(node);
		node.device.table_name = () => this.render_table_heartbeat(node.device);
		node.device.table_config = () => this.render_table_config(node.config)
		return node;
	}

	load_node_options = () => {
		const company_id = this.props.HAGroup.company_id;
		let params = {type: "gateway", company_id: company_id};
		GetAll("device_types", params).then( (types) => {
			this.setState({types: types});
			let types_string = types.map( (type) => type._id).join();
			let params = {device_integration_id: "null", device_type_id_in: types_string, device_ha_group_id: "null", company_id: company_id};
			let with_int = {device_integration_id_in: this.props.HAGroup.nested_integration._id, device_type_id_in: types_string, device_ha_group_id: "null", company_id: company_id};
			Promise.all([GetAll("devices", params), GetAll("devices", with_int)]).then( (devices) => {
				devices = devices[0].concat(devices[1]);
				AssignNestedModels("device_configs", "device_config_id", devices).then( () => {
					devices.forEach( (device) => {
						device.nested_device_type = types.find( (type) => type._id === device.device_type_id);
					});
					devices = devices.map( (device) => ({value: device._id, label: device.name + ": " + device.unique_id, whole: device}));
					this.setState({node_options: devices});
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
					this.props.cancel();
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.props.cancel();
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.props.cancel();
		});
	}

	render_node_lookup = () => {
		const classes = this.props.classes;
		/*mention that only devices with
		   the same integration or no integration
		   a device type that supports one of those connections
		 */
		return (
			<React.Fragment>
				<div className={classes.lookupNodeWrapper}>
					<Tooltip className={classes.inputIcon} title="Node">
						<DvrIcon />
					</Tooltip>
					<LookupInput
						priorState={{ suggestions: this.state.node_options, values: this.state.node }}
						label="Select a Node Device"
						field="node"
						single
						model="devices"
						emitChange={this.handle_node_change}
					/>
					<IconButton onClick={this.add_node} className={classes.addNodeIcon} aria-label="Add">
						<AddCircleIcon/>
					</IconButton>
				</div>
				<span
					onClick={() => this.setState({creating_node: true})}
					className={classes.link}
				>
					Create a new node device
				</span>
			</React.Fragment>
		);
	}

	handle_node_change = (input) => {
		!input.value ? this.setState({node: null}) : this.setState({node: input.value});
	}

	add_node = () => {
		if (!this.state.node) {
			return;
		}
		let config = this.state.node.whole.nested_device_config ? this.state.node.whole.nested_device_config : null;
		this.setState( (state) => {
			let node = this.table_formatting({device: state.node.whole, interface: this.state.node.whole.interface || null, config: config});
			state.nodes.push(node);
			return state;
		}, () => {
			this.setState({node: null});
		});
	}

	save_edited_node = () => {
		if (this.state.config_id  === ''|| this.state.interface === '') {
			this.setState({show_errors: true});
			return;
		}
		this.setState({loading: true});
		new Device().setData({_id: this.state.edit_node.device._id, device_config_id: this.state.config_id}).createOrSave().then( (result) => {
			this.setState( (state) => {
				state.nodes.forEach( (node) => {
					if (node.device._id === this.state.edit_node.device._id) {
						node.device.device_config_id = this.state.config_id;
						node.config = this.state.config_options.find( (config) => (config.value === this.state.config_id)).whole;
						node.interface = this.state.interface;
						node.device.table_highlighted = false;
					}
				});
				return state;
			});
			this.cancel_edit();
		}).catch( (error) => {
			this.cancel_edit();
			this.context.openSnackbar(error, "error");
		});
	}

	handle_node_config_change = (input) => {
		let config_id = input.value;
		let config = null;
		let interface_options = null;
		if (config_id !== '') {
			config = this.state.config_options.find( ({value}) => value === config_id).whole;
			interface_options = this.get_interface_options(config);
			interface_options.push({display: "Select a Network Interface", value: ''});
		}
		this.setState( (state) => {
			state.config_id = config_id;
			state.interface_options = interface_options;
			state.interface = '';
			return state;
		});
	}

	handle_node_interface_change = (input) => {
		this.setState( (state) => {
			state.interface = input.value;
			return state;
		});
	}

	render_node_table = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.nodeRenderArea}>
				<div className={classes.nodeAreaTitle}>
					<SettingsRemoteIcon className={classes.nodeIcon}/>
					Nodes
				</div>
				{!this.state.nodes || this.state.nodes.length === 0 ? <span className={classes.noNodes}>No Nodes Added</span> :
					<div className={classes.tableListContainer}>
						<TableList
							headings={this.heading_info} 
							items={this.state.nodes}
						/>
					</div>
				}
			</div>	
		);
	}

	render_edit_node_area = () => {
		const classes = this.props.classes;
		if (!this.state.edit_node) return;
		if (this.state.edit_node && !this.state.config_options) return <Loading />;
		return (
			<div className={classes.editNodeArea}>
				<div className={classes.editNodeAreaTitle}>
					Editing {this.state.edit_node.device.name}
				</div>
				<div className={classes.inputContainer + " " + classes.configInput}>
					<Tooltip className={classes.inputIcon} title="Network Config">
						<SettingsIcon />
					</Tooltip>
					<SelectInput
						field="node_config"
						emitChange={this.handle_node_config_change}
						label="Network Config*"
						priorState={this.state.config_id}
						options={this.state.config_options}
					/>
				</div>
				<div className={classes.configLinks}>
					<span
						onClick={this.create_config}
						className={classes.link}
					>
						Create a new network config
					</span>
				</div>
				{this.state.config_id && this.state.config_id !== '' && this.state.interface_options ?
					<React.Fragment>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon} title="Connection Interface">
								<SettingsEthernetIcon />
							</Tooltip>
							<SelectInput
								field="node_interface"
								emitChange={this.handle_node_interface_change}
								label="Connection Interface*"
								priorState={this.state.interface}
								options={this.state.interface_options}
							/>
						</div>
						{this.state.show_errors && this.state.interface === '' ? 
							<div className={classes.invalidConfig}>Please select a network interface.</div> 
						: ""}
					</React.Fragment>
					:
						this.state.config_id && this.state.config_id !== '' && !this.state.interface_options ?
							<div className={classes.invalidConfig}>This network config does not have any of the necessary connection interfaces. Please edit this config or assign a different network config to this device.</div>
							:
							""
				}
				{this.state.show_errors && (!this.state.config_id || this.state.config_id === '') ? <div className={classes.invalidConfig}>Please select a network config.</div> : ""}
				<div className={classes.editNodeButtonContainer}>
					<Button
						className={classes.borderButton}
						variant="outlined"
						onClick={() => this.cancel_edit()}
					>
						discard changes
					</Button>
					<Button
						className={classes.saveNodeButton}
						variant="outlined"
						onClick={() => this.save_edited_node()}
					>
						<SaveIcon className={classes.buttonIcon}/>
						SAVE
					</Button>
				</div>
			</div>
		);
	}

	render_buttons = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.editNodeButtonContainer}>
				<Button
					className={classes.borderButton}
					variant="outlined"
					onClick={() => this.props.cancel()}
				>
					cancel
				</Button>
				<Button
					className={classes.saveButton}
					variant="outlined"
					onClick={() => this.save()}
				>
					ADD NODES
				</Button>
			</div>
		);
	}

	missing_reqs = () => {
		let missing_reqs = false;
		this.state.nodes.forEach( (node) => {
			if (!node.config || !node.interface) {
				missing_reqs = true;
			}
		});
		if (this.state.nodes.length === 0) {
			missing_reqs = true;
		}
		return missing_reqs;
	}

	save = () => {
		if (this.missing_reqs()) {
			this.setState({show_errors: true});
			return;
		}
		let nodes = this.props.HAGroup.node_devices.concat(this.state.nodes.map( (node) => ({device_id: node.device._id, interface: node.interface})));
		new DeviceHAGroup({_id: this.props.HAGroup._id, node_devices: nodes}).saveOrCreate().then( (new_ha_group) => {
			this.props.onAdd(new_ha_group);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_node_create = (node) => {
		this.setState({loading: true, creating_node: false});
		new Device(node).createOrSave().then( (result) => {
			let created_node = result;
			created_node.nested_device_config = node.nested_device_config.whole;
			created_node.interface = node.interface;
			this.setState( (state) => {
				let new_option = {value: created_node._id, label: created_node.name + ": " + created_node.unique_id, display: created_node.name + ": " + created_node.unique_id, whole: created_node};
				state.newly_created_nodes.push(new_option)
				state.node = new_option;
				state.loading = false;
			}, this.add_node);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render() {
		const classes = this.props.classes;
		if (this.state.node_options === null) return <div className={classes.modalWrapper}><Loading /></div>;
		if (this.state.creating_node) return (<div className={classes.modalWrapper}><CreateDeviceForm getInterfaceOptions={this.get_interface_options} onCreate={this.handle_node_create} cancel={() => this.setState({creating_node: false})} types={this.state.types} integration={{display: this.props.HAGroup.nested_integration.name, value: this.props.HAGroup.nested_integration._id}} company={{display: this.props.HAGroup.nested_company.name, value: this.props.HAGroup.nested_company._id}} /></div>);
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.modalTop}>
					<div className={classes.modalTitle}>
						Add One or More Nodes to this HA Group
					</div>
					{this.render_node_lookup()}
					{this.render_node_table()}
					{this.render_edit_node_area()}
				</div>
				{this.state.loading ? "" : this.render_buttons()}
			</div>
		);
	}
}

AddHAGroupNode.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(AddHAGroupNode));