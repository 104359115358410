import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import Auth from '../../../services/Auth';
import { GetAll, AssignNestedModels } from '../../../services/CLURDUtilities';
import AddIntegration from '../../DeviceSpecific/AddIntegration';

//inputs
import TextInput from '../../Inputs/TextInput';
import SelectInput from '../../Inputs/SelectInput';

//mui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

//icons
import InfoIcon  from '@material-ui/icons/Info';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import HelpIcon  from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		stepContainer: {
			height: "505px",
			overflowY: "auto",
		},
		title: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
			display: "flex",
		},
		addIcon: {
			height: "28px",
			width: "28px",
			color: "#8e8e93",
			margin: "0 0 0 12px",
			padding: 0,
			cursor: "pointer"
		},
		addPortIcon: {
			marginBottom: "18px"
		},
		inputIcon: {
			color: "#8e8e93",
			margin: "8px",
			paddingBottom: "20px"
		},
		companyMessage: {
			margin: "-18px 0 12px 40px",
			color: "#8e8e93",
			fontSize: "14px",
		},
		integrationLinks: {
			marginTop: "-18px",
			marginBottom: "12px"
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap"
		},
		configInput: {
			marginTop: "32px"
		},
		nameContainer: {
			marginBottom: "8px",
		},
		portsContainer: {
			flexWrap: "wrap",
		},
		portLabelContainer: {
			width: "100%",
			alignItems: "center",
			display: "flex",
		},
		portsIcon: {
			width: "20px",
			margin: "8px",
			marginRight: "12px"
		},
		portsTitle: {
			color: "#8e8e93",
			fontSize: "14px",
		},
		portsError: {
			color: theme.palette.red.main,
			fontSize: "14px",
			marginBottom: "12px"
		},
		portContents: {
			padding: "0 0 0 40px",
			width: "100%"
		},
		noPorts: {
			marginBottom: "36px",
			color: "#8e8e93",
			fontSize: "14px",
			marginTop: "4px"
		},
		portsChipsContainer: {
			marginBottom: "36px",
		},
		addPortContainer: {
			display: "flex",
			alignItems: "center",
			width: "80%"
		},
		chipRoot: {
			marginRight: "4px"
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "32px",
		},
		button: {
			marginLeft: "12px"
		},
		link: {
			marginLeft: "33px",
		},
		integrationError: {
			marginLeft: "40px",
			fontSize: "14px",
			color: theme.palette.red.main,
			marginBottom: "12px"
		},
		greengrassError: { 
			margin: "-6px 0 24px 40px"
		},
		greengrassChecking: {
			textAlign: "center",
			marginBottom: "24px",
		},
		loadingIntegrations: {
			marginBottom: "6px"
		},
		greengrassIcon: {
			color: theme.palette.green.main,
			marginRight: "4px"
		},
		subTitle: {
			fontSize: "14px",
			color: "#8e8e93",
			margin: "0 0 12px 40px",
			display: "flex",
			alignItems: "center"
		},
		infoIcon: {
			marginRight: "6px"
		},
		loadingText: {
			marginTop: "12px"
		},
		integrationContainer: {
			height: "579px",
			overflowY: "auto",
		}
	});
};

class Step1 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			company_options: null,
			name: '',
			company_id: '',
			ports: [],
			new_port: null,
			custom_port_error: null,
			new_standard_port: null,
			show_errors: false,
			gateway_options: null,
			loading: false,
			no_greengrass_options: null,
			greengrass_integration: '',
			create_integration: false,
		};
		this.networking_ports = [
			{name: "HTTP: 80", value: 80},
			{name: "HTTPS: 443", value: 443},
			{name: "SSH: 22", value: 22},
			{name: "MQTT: 1883", value: 1883},
			{name: "MQTTS: 8883", value: 8883}
		];
		this.state.new_standard_port = 80;
		this.state.company_id = Auth.currentCompany()._id;
		this.load_integrations();
		this.load_companies();
	}

	prepare_integrations = () => {
		let integrations = this.state.integration_options.map( (integration) => ({value: integration._id, display: integration.name, whole: integration}));
		integrations.push({display: "Select a Greengrass Integration*", value: ''});
		return integrations;
	}

	load_integrations = (preset) => {
		if (this.state.no_greengrass_options !== null) {
			this.setState({no_greengrass_options: null, greengrass_integration: ''});
		}
		let params = {company_id: this.state.company_id};
		GetAll("integrations", params).then( (integrations) => {
			integrations = integrations.filter( (integration) => integration.greengrass_core_install_url);
			this.setState( (state) => {
				if (preset) state.greengrass_integration = preset;
				state.integration_options = integrations;
				state.no_greengrass_options = integrations.length === 0;
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_companies = () => {
		GetAll("companies").then( (companies) => {
			this.setState({company_options: companies.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_change = (input) => {
		let field = input.field;
		this.setState( (state) => {
			state[field] = input.value;
			return state;
		}, () => {
			if (field === "company_id") {
				this.load_integrations();
			}
		});
	}

	handle_integration_change = (input) => {
		let integration_id = input.value;
		let integration = null;
		this.setState( (state) => {
			state.greengrass_integration = integration_id;
			return state;
		});
	}

	render_ha_group_help = () => {
		return {
			title: "What is an HA Group?",
			content: "A High Availability Group is a group of devices where one device acts as the controller and the others act as nodes. The node devices are each running Greengrass, and when one fails, another will step in to take its place. In this step of the creation process you will need to define a name for the HA Group and the account that it will belong to, along with defining the Greengrass integration and the networking ports that will be used.",
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
	}

	render_ports = () => {
		const classes = this.props.classes;
		if (this.state.ports.length === 0) return <div className={classes.noPorts}>No Ports Added</div>
		return (
			<div className={classes.portsChipsContainer}>
				{this.state.ports.map( (port) => (
					<Chip clickable={false} classes={{root: classes.chipRoot}} key={port} label={port} onDelete={() => this.remove_port(port)}/>
				))}
			</div>
		);
	}

	get_port_options = () => {
		return this.networking_ports.filter( (port) => (this.state.ports.indexOf(port.value) === -1)).map( (option) => ({ display: option.name, value: option.value }));
	}

	add_standard_port = () => {
		this.setState( (state) => {
			state.ports.push(parseInt(state.new_standard_port, 10));
			return state;
		}, () => {
			if (this.get_port_options().length > 0) {
				this.setState({new_standard_port: this.get_port_options()[0].value})
			}
		});
	}

	add_custom_port = () => {
		let numbers = /^\d*[1-9]\d*$/;
		if (this.state.ports.indexOf(parseInt(this.state.new_port, 10)) !== -1) {
			this.setState({show_errors: true, custom_port_error: "That port number has already been added."});
			return;
		} else if (!numbers.test(this.state.new_port) || this.state.new_port === '' || this.state.new_port === 0) {
			this.setState({show_errors: true, custom_port_error: "Please enter a valid port number."});
			return;
		}
		this.setState( (state) => {
			state.ports.push(parseInt(state.new_port, 10));
			return {ports: state.ports, custom_port_error: null, new_port: null};
		}, () => {
			if (this.get_port_options().length > 0) {
				this.setState({new_standard_port: this.get_port_options()[0].value})
			}
		});
	}

	render_add_port = () => {
		const classes = this.props.classes;
		return (
			<div>
				<div className={classes.addPortContainer}>
					{this.get_port_options().length > 0 ?
					<React.Fragment>
						<SelectInput
							field="new_standard_port"
							emitChange={this.handle_change}
							label="Add Standard Port"
							priorState={this.state.new_standard_port}
							options={this.get_port_options()}
						/>
						<IconButton onClick={this.add_standard_port} className={classes.addIcon + " " + classes.addPortIcon} aria-label="Add">
							<AddCircleIcon/>
						</IconButton>
					</React.Fragment>
					:
						<React.Fragment>
							<SelectInput
								field="new_standard_port"
								emitChange={this.handle_change}
								disabled={true}
								label="Add Standard Port"
								priorState={''}
								options={[{value: '', display: "All Standard Ports Added"}]}
							/>
						</React.Fragment>
					}
				</div>
				<div className={classes.addPortContainer}>
					<TextInput
						emitChange={this.handle_change}
						priorState={this.state.new_port}
						error_message={this.state.custom_port_error}
						error={this.state.custom_port_error && this.state.show_errors}
						label="Add Custom Port Number"
						key="name"
						field="new_port"
					/>
					<IconButton onClick={this.add_custom_port} className={classes.addIcon + " " + classes.addPortIcon} aria-label="Add">
						<AddCircleIcon/>
					</IconButton>
				</div>
			</div>
			
		)
	}

	remove_port = (port) => {
		this.setState( (state) => {
			let ports = state.ports;
			ports.splice(ports.indexOf(port), 1);
			if (this.networking_ports.find( ({value}) => port === value)) {
				state.new_standard_port = this.get_port_options()[0].value;
			}
			state.ports = ports;
			return state;
		});
	}

	next = () => {
		if (this.state.name === '' || this.state.ports.length === 0 || this.state.no_greengrass_options || !this.state.greengrass_integration || this.state.greengrass_integration === '') {
			this.setState({show_errors: true});
		} else {
			this.setState({loading: true});
			this.load_gateway_devices().then( (gateways) => {
				this.setState({gateway_options: gateways, loading: false}, () => {
					this.props.next(this.map_state());
				});
			});
		}
	}

	load_gateway_devices = () => {
		let params = {type: "gateway"};
		return new Promise( (resolve, reject) => {
			GetAll("device_types", params).then( (types) => {
				this.setState({device_type_options: types});
				let types_string = types.map( (type) => type._id).join();
				let params = {device_integration_id: "null", device_type_id_in: types_string, device_ha_group_id: "null", company_id: this.state.company_id};
				let with_int= {device_integration_id_in: this.state.greengrass_integration, device_type_id_in: types_string, device_ha_group_id: "null", company_id: this.state.company_id};
				Promise.all([GetAll("devices", params), GetAll("devices", with_int)]).then( (devices) => {
					devices = devices[0].concat(devices[1]);
					AssignNestedModels("device_configs", "device_config_id", devices).then( () => {
						devices.forEach( (device) => {
							device.nested_device_type = types.find( (type) => type._id === device.device_type_id);
						});
						devices = devices.map( (device) => ({value: device._id, label: device.name + ": " + device.unique_id, whole: device}));
						resolve(devices);
					}).catch( (error) => {
						this.context.openSnackbar(error, "error");
						reject();
					});
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
					reject();
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		});
	}

	map_state = () => {
		let integration = this.state.integration_options.find( ({_id}) => _id === this.state.greengrass_integration);
		return {
			account_name: this.state.company_options.find( (company) => company._id === this.state.company_id).name,
			company_id: this.state.company_id,
			ports: this.state.ports,
			name: this.state.name,
			gateway_options: this.state.gateway_options,
			integration_options: this.state.integration_options,
			device_type_options: this.state.device_type_options,
			greengrass_integration: integration
		};
	}

	render_integration_select = () => {
		const classes = this.props.classes;
		if (!this.state.integration_options) return <Loading />;
		return (
			<React.Fragment>
				<div className={classes.subTitle}>
					<InfoIcon className={classes.infoIcon}/>
					Choose a Greengrass integration. This integration will be used to define the HA Group, and will therefore be attached to the controller device and each node device.
				</div>
				<div className={classes.inputContainer + " " + classes.configInput}>
					<Tooltip className={classes.inputIcon} title="Greengrass Integration">
						<ExtensionIcon />
					</Tooltip>
					<SelectInput
						field="greengrass_integration"
						emitChange={this.handle_integration_change}
						label="Greengrass Integration*"
						priorState={this.state.greengrass_integration}
						options={this.prepare_integrations()}
						error={this.state.show_errors && this.state.greengrass_integration === ''}
					/>
				</div>
				<div className={classes.integrationLinks}>
					{/* {this.state.greengrass_integration && this.state.greengrass_integration !== '' ? 
						<span
							onClick={this.edit_integration}
							className={classes.link}
						>
							Edit this integration
						</span>
						: ""
					} */}
					<Button
						onClick={() => this.setState({create_integration: true})}
						className={classes.link}
						color="primary"
					>
						Create a new Greengrass Integration
					</Button>
				</div>
				{this.state.show_errors && this.state.greengrass_integration === '' ? <div className={classes.integrationError}>Please select or create a Greengrass integration.</div> : ""}
			</React.Fragment>
		);
	}

	render_greengrass_check = () => {
		const classes = this.props.classes;
		if (this.state.no_greengrass_options === null) {
			return (
				<div className={classes.greengrassChecking}>
					<Loading className={classes.loadingIntegrations}/>
					<div className={classes.loadingText}>Checking for available Greengrass integrations on this account...</div>
				</div>
			);
		} else if (this.state.no_greengrass_options) {
			return (
				<div className={classes.greengrassError}>
					<div className={classes.portsError}>There are no Greengrass integrations available for this account. You won't be able to proceed until one exists.</div>
				</div>
			);
		}
	}

	onSave = (integration_id) => {
		this.setState({create_integration: false})
		this.load_integrations(integration_id);
	}

	render() {
		const classes = this.props.classes;
		if (this.state.create_integration) return (<div className={classes.integrationContainer}><AddIntegration cancel={() => this.setState({create_integration: false})} creating tabHostProxy={this.props.tabHostProxy} device={{company_id: this.state.company_id}} closeModal={this.closeModal} onSave={this.onSave}/></div>);
		if (this.state.company_options === null || this.state.loading) return <Loading />;
		this.show_company = Auth.currentUser().company_ids.length > 1 ? true : false;
		return (
			<React.Fragment>
				<div className={classes.stepContainer}>
					<div className={classes.title}>
						Define the New HA Group
						<IconButton onClick={() => this.context.openHelp(this.render_ha_group_help())} className={classes.addIcon} aria-label="help">
							<HelpIcon/>
						</IconButton>
					</div>
					<div className={classes.inputContainer + " " + classes.nameContainer}>
						<Tooltip className={classes.inputIcon} title="Name">
							<DescriptionIcon />
						</Tooltip>
						<TextInput
							emitChange={this.handle_change}
							priorState={this.state.name}
							error_message="An HA Group's name cannot be blank."
							error={this.state.name === "" && this.state.show_errors}
							label="Name*"
							key="name"
							field="name"
						/>
					</div>
					{this.show_company ? <div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon} title="Account">
							<AccountCircleIcon />
						</Tooltip>
						<SelectInput
							disabled={this.props.companySet}
							field="company_id"
							emitChange={this.handle_change}
							label="Account*"
							priorState={this.state.company_id}
							options={this.state.company_options.map((option) => ({ display: option.name, value: option._id }))}
						/>
					</div> : ""}
					{this.props.companySet ? 
						<div className={classes.companyMessage}>
							If you need to change the account, close and restart the creation process.
						</div>
						: ""
					}
					{this.render_integration_select()}
					{this.render_greengrass_check()}
					<div className={classes.inputContainer + " " + classes.portsContainer}>
						<div className={classes.portLabelContainer}>
							<Tooltip title="Ports">
								<img alt="port_icon" className={classes.portsIcon} src={require("../../../images/ports.png")}/>
							</Tooltip>
							<div className={classes.portsTitle}>
								Ports* {this.state.show_errors && this.state.ports.length === 0 ? <span className={classes.portsError}>Ports Must be Added</span>:""}
							</div>
						</div>
						<div className={classes.portContents}>
							{this.render_ports()}
							{this.render_add_port()}
						</div>
					</div>
				</div>
				<div className={classes.buttonContainer}>
					<Button
						color="primary"
						onClick={this.props.closeModal}
						className={classes.button}
					>
						cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={this.next}
						className={classes.button}
					>
						next
					</Button>
				</div>
			</React.Fragment>
		);
	}
}
Step1.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(Step1));
