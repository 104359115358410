import React from 'react';
import {withStyles, withTheme} from '@material-ui/core/styles';
import TableList from '../Table/TableList';
import PaginationContainer from '../Containers/PaginationContainer';
import Loading from '../DisplayOriented/Loading';
import Button from '@material-ui/core/Button';

//icons
import CodeIcon from '@material-ui/icons/CodeOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import Permissions from '../../services/Permissions';

const heading_info = [
	{ label: "Created At", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date" },
	{ label: "Message", value: "table_message", field: "table_message", align: "left", disablePadding: false, sortable: false, content: "function" },
	{ label: "Device", field: "table_device", align: "left", disablePadding: false, sortable: false, content: "function" },
	{ label: "Policy", field: "table_rule", align: "left", disablePadding: false, sortable: false, content: "function" },
	{ label: "Account", field: "company_id", align: "left", disablePadding: false, sortable: true },
];

const styles = theme => ({
	container: {
		width: "75%",
		fontFamily: "Inter",
		flexGrow: 2,
	},
	alertsContainer: {
		overflowY: "auto",
		height: "calc(100% - 58px)",
	},
	alertContainer: {
		display: "flex",
		margin: "12px",
	},
	left: {
		width: "15%",
		textAlign: "center"
	},
	date: {
		fontSize: "14px",
		fontWeight: 500
	},
	time: {
		fontSize: "14px",
		color: "#8e8e93",
	},
	right: {
		width: "80%",
		padding: "12px",
		backgroundColor: "#f1f1f1",
		borderRadius: "5px"
	},
	alertIcon: {
		fontSize: "32px",
		color: "white",
		padding: "12px",
		borderRadius: "4px",
	},
	green: {
		backgroundColor: "#009688"
	},
	red: {
		backgroundColor: "#f60362"
	},
	blue: {
		backgroundColor: "#32419B"
	},
	line: {
		height: "40px",
		width: "2px",
		margin: "10px auto 0 auto",
		backgroundColor: "#808080a1"
	},
	bottomLine: {
		marginBottom: "-15px",
		height: "60px",
	},
	topLine: {
		margin: "0 auto 10px auto",
	},
	message: {
		color: "#8e8e93",
		fontSize: "14px",
	},
	linkRow: {
		display: "flex",
		padding: "12px",
		alignItems: "center",
	},
	unavailableText: {
		color: "#8e8e93",
		marginLeft: "12px",
		fontSize: "14px",
	},
	linkIcon: {
		color: "#8e8e93"
	},
	linkText: {
		marginLeft: "12px",
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	unread: {
		display: "flex",
		fontSize: "12px",
		alignItems: "center"
	},
	read: {
		color: "#8e8e93",
		display: "flex",
		fontSize: "12px",
		alignItems: "center"
	},
	unreadIcon: {
		fontSize: "18px",
		color: "red",
		marginRight: "4px",
	},
	categoryContainer: {
		position: "relative",
	},
	categoryLabel: {
		color: "white",
		position: "absolute",
		fontSize: "8px",
		margin: "0 auto",
		width: "100%",
		bottom: "5px"
	}
});

class AlertFeed extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			layout: "card"
		};
	}

	openDeviceTab = (alert) => {
		this.props.tabHostProxy.addTab("device", alert.device_id, true);
	}

	openRuleTab = (alert) => {
		this.props.tabHostProxy.addTab("rule", alert.rule_id, true);
	}

	handleChangePage = (event, page) => {
		this.props.page_change({page: page + 1, per_page: this.props.page_data.per_page});
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({page: this.props.page_data.page, per_page: event.target.value});
	};

	render_message = (alert) => {
		// let formatted = alert.message;
		// let section = `ObjectIdHex("${alert.device_id}")`;
		// if (alert.message.indexOf(section) !== -1) {
		// 	if (alert.device_id && Permissions.allow(["read"], "device", alert.company_id)) {
		// 		formatted = alert.nested_device.name + " " + alert.message.split(section)[1];
		// 	} else {
		// 		formatted = "Device (not found) " + alert.message.split(section)[1];
		// 	}
		// }
		return alert.message;
	}

	renderDevice = (alert, classes) => {
		if (alert.device_id && Permissions.allow(["read"], "device", alert.company_id) && Permissions.allow(["read"], "device_type", alert.company_id)) {
			return (
				<div className={classes.linkRow}>
					<DvrIcon className={classes.linkIcon}/>
					<div>
						<Button color="primary" onClick={() => this.openDeviceTab(alert)} className={classes.linkText}>
							View Device
						</Button>
					</div>
				</div>
			);
		} else {
			return (
				<div className={classes.linkRow}>
					<DvrIcon className={classes.linkIcon}/>
					<div className={classes.unavailableText}>
						Device Unavailable
					</div>
				</div>
			);
		}
	}

	renderRule = (alert, classes) => {
		if (alert.rule_id && Permissions.allow(["read"], "rule", alert.company_id)) {
			return (
				<div className={classes.linkRow}>
					<SecurityIcon className={classes.linkIcon}/>
					<div>
						<Button color="primary" onClick={() => this.openRuleTab(alert)} className={classes.linkText}>
							View Policy
						</Button>
					</div>
				</div>
			);
		} else {
			return (
				<div className={classes.linkRow}>
					<SecurityIcon className={classes.linkIcon}/>
					<div className={classes.unavailableText}>
						Policy Unavailable
					</div>
				</div>
			);
		}
	}

	render() {
		const { alerts, page_data, DEMO, tabHostProxy, classes } = this.props;
		return (
			<div className={classes.container}>
				<PaginationContainer
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={(layout) => this.setState({ layout: layout })}
					toggle_filter={this.props.show_filters}
					filtering={this.props.filtering}
				/>
				<div className={classes.alertsContainer}>
					{this.state.layout === "card" ?
						this.render_card_layout()
						:
						this.render_table_layout()
					}
				</div>
			</div>
		)
	}

	render_table_layout = (classes) => {
		const { alerts, page_data, perform_sort } = this.props;
		if (!alerts) {
			return <Loading/>;
		}
		return (
			<TableList
				headings={heading_info}
				items={alerts}
				page_data={page_data}
				perform_sort={perform_sort}
			/>
		);
	}

	render_card_layout = () => {
		const { alerts, classes, DEMO } = this.props;
		if (!alerts) {
			return <Loading/>;
		}
		return (
			<React.Fragment>
				{alerts.map( (alert) => (
					<div key={alert._id} className={classes.alertContainer}>
						<div className={classes.left}>
							<div className={classes.line + " " + classes.topLine}/>
							<div className={classes.date}>
								{this.showDate(alert.created_at)}
							</div>
							<div className={classes.time}>
								{this.showTime(alert.created_at)}
							</div>
							<div className={classes.line + " " + classes.bottomLine}/>
						</div>
						<div className={classes.right}>
							{this.showRead(DEMO, alert.read, classes)}
							<div className={classes.message}>
								{this.render_message(alert)}
							</div>
							{this.renderDevice(alert, classes)}
							{this.renderRule(alert, classes)}
						</div>
					</div>
				))}
			</React.Fragment>
		);
	}

	showRead = (demo, read, classes) => {
		if (demo) {
			//todo incorporate the seen/unseen field
			read = Math.floor(Math.random() * 2);
			if (read === 0) {
				return (
				<div className={classes.unread}>
					<ErrorIcon className={classes.unreadIcon}/>
					UNREAD
				</div>);
			} else {
				return (
					<div className={classes.read}>
						READ
					</div>
				);
			}
		}
	}

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	}

	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	}

	alertCategory = (alert, classes) => {
		//random for now, todo once category set
		// category = categories[Math.floor(Math.random() * 3)];

		//instead of random the current implementation looks at the message contents and
		// just pins the category to software, online/offline, or the default as rule.
		let categories = ["software", "rule"];
		let category = null;
		if (alert.message.indexOf("software") !== -1) {
			category = categories[1];
		} else if (alert.message.indexOf("line") !== -1) {
			category = categories[0];
		} else {
			category = categories[2];
		}
		switch (category) {
			case "software":
				return (
					<div className={classes.categoryContainer}>
						<div className={classes.categoryLabel}>
							SOFTWARE
						</div>
						<CodeIcon className={classes.alertIcon + " " + classes.green}/>
					</div>
				);
			case "rule":
				return (
					<div className={classes.categoryContainer}>
						<div className={classes.categoryLabel}>
							CONDITION
						</div>
						<SecurityIcon className={classes.alertIcon + " " + classes.red}/>
					</div>
				);
		}
	}
}

export default withStyles(styles)(withTheme()(AlertFeed));
