class Humanize {

	static humanizeSnakeCaseString(string, titleCase = true) {
		if (string == null) return string;
		var s = string.replace(/_/g, ' ');
		if (titleCase) {
			s = Humanize.titleCaseString(s);
		}
		return s;
	};

	static titleCaseString(string) {
		if (string == null) return string;
		return string.replace(/\b(\w)/g, (i) => i.toUpperCase());
	}
	
	static formatDateStringForYMD(date_string) {
		return new Date(date_string).toLocaleDateString(
		  navigator.language,
		  {
		    year: 'numeric',
		    month: 'long',
		    day: 'numeric'
		  }
		)
	}
}

export default Humanize;
