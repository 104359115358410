import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Device from '../../../services/DataModels/Device';
import Loading from '../../DisplayOriented/Loading';

import Auth from '../../../services/Auth.js';
import SelectInput from '../../Inputs/SelectInput';
import axios from 'axios';

const DEVICE_METADATA_REPORT_TAG_KEY = 'dashboard_tag';


class ReportsTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			hasGrafana: null,
			grafanaError: null,
			grafanaReady: null,
			host: null,
			devices: null,
			grafana_tags: null,
			filtered_devices: null,
			grafana_tags_to_dashboards_map: null,
			dashboards: [],
			dashboard: null,
			frameParams: {
				orgId: 1,
				refresh: '5m',
				from: 'now-6h',
				to: 'now',
				kiosk: 1,
				eiqgu: undefined
			},
			filterDates: [
				{ display: "Last 5 minutes", value: "now-5m" },
				{ display: "Last 15 minutes", value: "now-15m" },
				{ display: "Last 30 minutes", value: "now-30m" },
				{ display: "Last 1 hour", value: "now-1h" },
				{ display: "Last 3 hours", value: "now-3h" },
				{ display: "Last 6 hours", value: "now-6h" },
				{ display: "Last 12 hours", value: "now-12h" },
				{ display: "Last 24 hours", value: "now-24h" },
				{ display: "Last 2 days", value: "now-2d" },
				{ display: "Last 7 days", value: "now-7d" }
			]
		};
	}

	componentDidMount() {
		const company = Auth.currentCompany()
		const user = Auth.currentUser()
		const grafanaUrl = company.metadata && company.metadata.grafana_url
		const grafanaAuth = (company.metadata && company.metadata.eiqgu) || undefined
		const filterDates = company.portal_configuration.reports_options.time_filter_options ? company.portal_configuration.reports_options.time_filter_options : this.state.filterDates

		let defaultFilterValue = this.state.frameParams.from;
		if (user.metadata && user.metadata.report_default_filter_value) {
			defaultFilterValue = user.metadata.report_default_filter_value;
		} else if (company.metadata && company.metadata.report_default_filter_value) {
			defaultFilterValue = company.metadata.report_default_filter_value;
		}

		if (grafanaUrl) {
			this.setState({
				hasGrafana: true,
				host: grafanaUrl,
				frameParams: {
					...this.state.frameParams,
					eiqgu: grafanaAuth,
					from: defaultFilterValue
				},
				filterDates: filterDates
			})

			this.loadGrafanaUser(grafanaUrl, grafanaAuth).then((result) => {
				let user = result.data;
				if (user && user.orgId) {
					console.log("User's orgId is " + user.orgId);
					this.setState(
						{
							frameParams: {
							...this.state.frameParams,
							orgId: user.orgId,
							}
						}, () => {
							this.loadDashboardData(grafanaUrl, grafanaAuth);
						}
					);
				} else {
					this.setState({
							hasGrafana: false
					});
				}
			})
		}
		else {
			this.setState({
				hasGrafana: false
			})
		}
	}

	loadGrafanaUser(grafanaUrl, grafanaAuth) {
		return axios({
				method: 'GET',
				url: `${grafanaUrl}/api/user`,
				params: {
					eiqgu: grafanaAuth
				},
				headers: {
					'Content-Type': 'application/json'
				}
			})
	}

	loadDashboardData(grafanaUrl, grafanaAuth) {
		let deviceParams = {
			company_id: Auth.currentCompany()._id,
			page: 1,
			per_page: 1000
		}
		let dashboardPromise =
			axios({
				method: 'GET',
				url: `${grafanaUrl}/api/search`,
				params: {
					folderIds: 0,
					query: '',
					starred: false,
					type: 'dash-db',
					eiqgu: grafanaAuth
				},
				headers: {
					'Content-Type': 'application/json'
				}
			});
		let tagsPromise =
			axios({
				method: 'GET',
				url: `${grafanaUrl}/api/dashboards/tags`,
				params: {
					eiqgu: grafanaAuth
				},
				headers: {
					'Content-Type': 'application/json'
				}
			})
		let promises = Promise.all([
			new Device().listFromAPI(deviceParams),
			tagsPromise,
			dashboardPromise
		]);
		promises.then(([device_result, tags_result, dashboard_result]) => {
			let devices = device_result.items;
			let tags = tags_result.data;
			let dashboards = dashboard_result.data;

			let dashboardsByTag = this.mapTagsToDashboards(dashboards);
			let devicesWithTag = this.devicesWithCorrespondingGrafanaTag(devices, dashboardsByTag);
			if (devicesWithTag && devicesWithTag.length !== null) {
				console.log(`found ${devicesWithTag.length} devices with a corresponding dashboard tag`);
			}

			let defaultDevice = devicesWithTag[0] && devicesWithTag[0].metadata ? devicesWithTag[0].metadata[DEVICE_METADATA_REPORT_TAG_KEY] : null;
			let defaultDeviceDashboards = dashboardsByTag[defaultDevice];

			if (defaultDevice && Array.isArray(defaultDeviceDashboards) && defaultDeviceDashboards.length > 0) {
				dashboards = defaultDeviceDashboards;
			}

			if (Array.isArray(dashboards) && dashboards.length > 0) {
				this.setState({
					grafanaReady: true,
					dashboards: dashboards,
					dashboard: dashboards[0].url,
					devices: devicesWithTag,
					device: defaultDevice,
					dashboardsByTag: dashboardsByTag
				})
			}
			else {
				this.setState({
					grafanaError: true
				})
			}
		})
			.catch((error) => {
				console.error("Error preparing report view:", error);
				this.setState({
					grafanaError: true
				})
			});
	}

	tagTermFromTagData(tagData) {
		if (tagData === null) return null;
		return tagData.term;
	}

	devicesWithCorrespondingGrafanaTag(devices, tagMap) {
		let filteredDevices = devices == null ? [] : devices.filter((device) => {
			let metaTag = device.metadata ? device.metadata[DEVICE_METADATA_REPORT_TAG_KEY] : null;
			return tagMap[device.unique_id] || tagMap[metaTag];
		});
		return filteredDevices;
	}

	mapTagsToDashboards(dashboards) {
		let tagsToDashboards = dashboards == null ? [] : dashboards.reduce((acc, dashboard) => {
			let tags = dashboard.tags;
			if (tags && tags.length) {
				tags.forEach((tag) => {
					if (typeof(acc[tag]) !== 'object') {
						acc[tag] = [];
					}
					acc[tag].push(dashboard);
				});
			}
			return acc;
		}, {});
		return tagsToDashboards;
	}


	buildFrameUrl() {
		const { host, dashboard, frameParams } = this.state

		const query = Object.keys(frameParams).reduce((acc, param) => {
			if (frameParams[param] !== undefined) {
				acc.push(`${param}=${encodeURIComponent(frameParams[param])}`)
			}
			return acc
		}, [])

		return `${host}${dashboard}?${query.join('&')}`
	}

	handleDeviceChange = (value) => {
		let dashboard = null;
		let deviceTag = value.value;
		let dashboardsForDevice = this.state.dashboardsByTag[deviceTag];
		if (dashboardsForDevice[0]) {
			dashboard = dashboardsForDevice[0].url;
		}
		this.setState({
			device: deviceTag,
			dashboards: dashboardsForDevice,
			dashboard: dashboard
		})
	};

	handleDashboardChange = ({ value }) => {
		this.setState({
			dashboard: value
		})
	}

	handleDateChange = ({ value }) => {
		this.setState({
			frameParams: {
				...this.state.frameParams,
				from: value
			}
		})
	}

	renderFilters() {
		const { classes } = this.props;

		const deviceList = (this.state.devices || []).map((device) => {
			let name = device.name;
			let value = device.metadata ? device.metadata[DEVICE_METADATA_REPORT_TAG_KEY] : null;
			return { display: name, value: value }
		});
		const dashboards = (this.state.dashboards || []).map(({ title, url }) => ({
			display: title,
			value: url
		}))


		return (
			<div className={classes.filters}>
				{deviceList.length > 0 && (
					<div>
							<div className={classes.smallLabel}>Device</div>
							<SelectInput
								basic
								priorState={this.state.device}
								emitChange={this.handleDeviceChange}
								options={deviceList}
							/>
					</div>
				)}

				<div>
					{dashboards.length > 0 && (
						<div>
							<div className={classes.smallLabel}>Dashboard</div>
							<SelectInput
								basic
								priorState={this.state.dashboard}
								emitChange={this.handleDashboardChange}
								options={dashboards}
							/>
						</div>
					)}
				</div>

				<div>
					{dashboards.length > 0 && (
						<div>
							<div className={classes.smallLabel}>Timeframe</div>
							<SelectInput
								basic
								priorState={this.state.frameParams.from}
								emitChange={this.handleDateChange}
								options={this.state.filterDates}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}

	renderFrame() {
		const { classes } = this.props;
		const { dashboard, grafanaReady, grafanaError } = this.state;

		return (
			<div className={classes.content}>
				{grafanaReady && dashboard && (
					<iframe
						className={classes.frame}
						src={this.buildFrameUrl()}
						width="100%"
						height="100%"
						frameBorder="0"
					/>
				)}

				{!grafanaReady && !grafanaError && (
					<Loading />
				)}
			</div>
		)
	}

	render() {
		const { classes } = this.props;
		const { hasGrafana, grafanaReady, grafanaError } = this.state

		return (
			<div className={classes.container}>
				{!hasGrafana && (
					<div className={classes.errorMsg}>No dashboards found for this company account.</div>
				)}

				{hasGrafana && grafanaError && (
					<div className={classes.errorMsg}>Error trying to get dashboards for company account.</div>
				)}

				{hasGrafana && grafanaReady && this.renderFilters()}

				{hasGrafana && grafanaReady && this.renderFrame()}
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		smallLabel: {
			fontFamily: 'Inter, sans-serif',
			fontSize:"10px"
		},
		container: {
			display: "flex",
			flexDirection: 'column',
			width: "100%",
		},
		content: {
			height: 'calc(100% - 80px)'
		},
		filters: {
			padding: 20,
			height: 40,
			display: 'flex',
			justifyContent: 'space-between',
			borderBottom: '2px solid #DDD'
		},
		errorMsg: {
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontFamily: 'Saira, sans-serif'
		}
	});
};

ReportsTab.contextType = SnackbarContext;

export default compose(
	withRouter,
	withStyles(styles),
)(ReportsTab);

