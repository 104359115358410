import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';

//mui
import Tooltip from '@material-ui/core/Tooltip';

//icons
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

//inputs
import TextInput from '../../Inputs/TextInput';
import SelectInput from '../../Inputs/SelectInput';

//services
import { GetAll } from '../../../services/CLURDUtilities';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Integration from '../../../services/DataModels/Integration';
import Device from '../../../services/DataModels/Device';

class CDStep2 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			updated_device: {
				device_type_id: "",
				device_config_id: "",
				device_integration_id: "",
				cloud_native_integration_id: "",
				log_config: {
					local_level: "error",
					forward_level: "error",
				},
				is_gateway: null
			},
			type_options: null,
			device_integration_options: null,
			cloud_integration_options: null,
			config_options: null,
			show_errors: false
		};
		this.company_id = this.props.preset ? this.props.preset.company_id : this.props.device.company_id;
		this.load_types();
		this.load_integrations();
	}

	load_types = () => {
		if (!this.company_id || this.company_id == '') return;
		let params = {};
		const { preset } = this.props;
		if (preset) {
			if (preset.attached_device_ids) {
				params.type = "gateway";
			} else if (preset.parent_device_id) {
				params.type_ne = "gateway";
			}
		}
		GetAll("device_types", params).then( (types) => {
			types = types.map( (option) => ({ display: option.name, value: option._id, whole: option }));
			types.push({display: "Select a Type*", value: ""});
			this.setState({type_options: types});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_configs = () => {
		const device_type_id = this.state.updated_device.device_type_id;
		const type = this.state.type_options.find( (type) => type.value === this.state.updated_device.device_type_id).whole;
		if (type.type !== "gateway") return;
		const params = {device_type_id: device_type_id, company_id: this.company_id};
		GetAll("device_configs", params).then( (configs) => {
			configs = configs.map( (option) => ({ display: option.name, value: option._id, whole: option }));
			configs.push({display: "Select a Network Config", value: ""});
			this.setState({config_options: configs});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_integrations = () => {
		if (!this.company_id || this.company_id == '') return;
		const params = { archetype_in: "cloud,device" };
		GetAll("integrations", params).then( (integrations) => {
			let device_integrations = integrations.filter((i) => i.archetype === 'device').map( (option) => ({ display: option.name, value: option._id, whole: option, type: option.type, archetype: option.archetype }));
			device_integrations.push({display: "Select a Device Integration", value: ""});
			let cloud_integrations = integrations.filter((i) => i.archetype === 'cloud').map( (option) => ({ display: option.name, value: option._id, whole: option, type: option.type, archetype: option.archetype }));
			cloud_integrations.push({display: "Select a Cloud Integration", value: ""});
			this.setState({device_integration_options: device_integrations, cloud_integration_options: cloud_integrations });
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	type_is_valid = () => {
		return this.state.updated_device.device_type_id !== "";
	}

	handle_change = (input) => {
		const field = input.field;
		const value = input.value;
		this.setState( (state) => {
			state.updated_device[field] = value;
			if (field === "device_type_id") {
				if (value !== "") {
					const type = state.type_options.find( (type) => type.value === value).whole;
					state.updated_device.is_gateway = type.type === "gateway";
					state.updated_device.nested_device_type = type;
				} else {
					state.updated_device.is_gateway = null;
					state.updated_device.nested_device_type = null;
				}
				state.updated_device.nested_config = null;
				state.updated_device.device_config_id = "";
			}
			if (field === "device_config_id") {
				if (value !== "") {
					const config = state.config_options.find( (conf) => conf.value === value).whole;
					state.updated_device.nested_config = config;
				} else {
					state.updated_device.nested_config = null;
				}
			}
			if (field === "device_integration_id") {
				if (value !== "") {
					const integration = state.device_integration_options.find( (integ) => integ.value === value).whole;
					state.updated_device.nested_integration = integration;
				} else {
					state.updated_device.nested_integration = null;
				}
				
			}
			if (field === "cloud_native_integration_id") {
				if (value !== "") {
					const integration = state.cloud_integration_options.find( (integ) => integ.value === value).whole;
					state.updated_device.nested_cloud_integration = integration;
				} else {
					state.updated_device.nested_cloud_integration = null;
				}
				console.log(state.updated_device);
			}
			return state;
		}, () => {
			if (field === "device_type_id" && value !== "") {
				this.load_configs();
			}
		});
	}

	can_progress = () => {
		const passing = ( this.type_is_valid() );
		if (passing) {
			return true;
		} else {
			if (this.state.show_errors !== true) this.setState({show_errors: true});
			return false;
		}
	}

	render_type = () => {
		const { classes } = this.props;
		const { updated_device, show_errors, type_options } = this.state;
		let display = (
			<SelectInput
					error={!this.type_is_valid() && show_errors}
					error_message="Please select a device type."
					emitChange={this.handle_change}
					priorState={updated_device.device_type_id}
					options={type_options}
					field="device_type_id"
					label="Device Type"
				/>
		);
		if (type_options != null && type_options.length === 1) {
			display = ( <span className={classes.configText + " " + classes.typeText}>There are no device types for this account. You won't be able to create a device for this company until a device type exists.</span> );
		}
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Device Type" title="Device Type">
					<DvrIcon />
				</Tooltip>
				{display}
			</div>
		);
	}

	render_config = () => {
		const { classes } = this.props;
		const { updated_device, show_errors, type_options, config_options } = this.state;
		let type = type_options.find( (type) => type.value === updated_device.device_type_id);
		let display = null;
		if (updated_device.device_type_id === "") {
			display = ( <span className={classes.configText}>Select a device type to see the available network configs.</span> );
		} else {
			if (type.whole.type === "gateway") {
				if (config_options === null) {
					display = ( <Loading /> );
				} else if (config_options && config_options.length === 1) {
					display = ( <span className={classes.configText}>There are no network configs for this device type.</span> );
				} else {
					display = (
						<SelectInput
							emitChange={this.handle_change}
							priorState={updated_device.device_config_id}
							options={config_options}
							field="device_config_id"
							label={"Network Config"}
						/>
					);
				}
			} else {
				display = ( <span className={classes.configText}>There are no network configs for this device type.</span> );
			}
		}
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Network Config" title="Network Config">
					<SettingsIcon />
				</Tooltip>
				{display}
			</div>
		);
	}

	render_cloud_native_integration = () => {
		const { classes } = this.props;
		const { updated_device, cloud_integration_options, type_options } = this.state;
		const type = type_options.find( (type) => type.value === updated_device.device_type_id);
		const noDeviceTypeChosen = updated_device.device_type_id === "";
		const isCloudTypeDevice = type != null && type.whole != null && type.whole.type === 'cloud_native';
		if (noDeviceTypeChosen || !isCloudTypeDevice) {
			return (
				<React.Fragment />
			)
		} else {
			return (
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} key="Cloud Native Device Integration" title="Cloud Native Device Integration">
						<CloudIcon />
					</Tooltip>
					<SelectInput
						emitChange={this.handle_change}
						priorState={updated_device.cloud_native_integration_id}
						options={cloud_integration_options}
						field="cloud_native_integration_id"
						label={"Cloud Native Device Integration"}
					/>
				</div>
			);
		}
	}

	render_integration = () => {
		const { classes } = this.props;
		const { updated_device, device_integration_options } = this.state;
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Integration" title="Integration">
					<ExtensionIcon />
				</Tooltip>
				<SelectInput
					emitChange={this.handle_change}
					priorState={updated_device.device_integration_id}
					options={device_integration_options}
					field="device_integration_id"
					label={"Device Integration"}
				/>
			</div>
		);
	}

	handle_log_change = (input) => {
		const value = input.value;
		const field = input.field;
		this.setState( (state) => {
			state.updated_device.log_config[field] = value;
			return state;
		});
	}

	render_log = () => {
		const { classes } = this.props;
		const { updated_device, show_errors, type_options } = this.state;
		const type = type_options.find( (type) => type.value === updated_device.device_type_id);
		const noDeviceTypeChosen = updated_device.device_type_id === "";
		const isGatewayType = type != null && type.whole != null && type.whole.type === 'gateway';
		if (noDeviceTypeChosen || !isGatewayType) {
			let text = noDeviceTypeChosen ?
				'Choose a device type to configure logging' :
				'There is no logging configuration for this device type';
			return (
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} key="Logging Configuration" title="Logging Configuration">
						<MoveToInboxIcon />
					</Tooltip>
					<span className={classes.configText}>{text}.</span>
				</div>
			)
		} else {
			return (
				<React.Fragment>
					<div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon} key="Local Log Level" title="Local Log Level">
							<MoveToInboxIcon />
						</Tooltip>
						<SelectInput
							emitChange={this.handle_log_change}
							priorState={updated_device.log_config.local_level}
							options={Device.getLogLevels()}
							field="local_level"
							label="Local Logs"
						/>
					</div>
					<div className={classes.inputContainer}>
						<Tooltip title="Forwarded Log level" key="Forwarded Log Level"  className={classes.inputIcon}>
							<CloudUploadIcon />
						</Tooltip>
						<SelectInput
							emitChange={this.handle_log_change}
							priorState={updated_device.log_config.forward_level}
							options={Device.getLogLevels()}
							field="forward_level"
							label="Forwarded Logs"
						/>
					</div>
				</React.Fragment>
			);
		}
	}

	render() {
		const { renderButtons, renderTitle, classes } = this.props;
		const { updated_device, type_options, cloud_integration_options, device_integration_options } = this.state;
		if (type_options == null || device_integration_options == null || cloud_integration_options == null) return <Loading />;
		return (
			<React.Fragment>
				<div>
					{renderTitle()}
					<div className={classes.inputWrapper}>
						{this.render_type()}
						{this.render_config()}
						{this.render_cloud_native_integration()}
						{this.render_integration()}
						{this.render_log()}
					</div>
				</div>
				{renderButtons(this.can_progress, updated_device)}
			</React.Fragment>
		);
	}
}

const styles = (theme) => {
	return ({
		inputWrapper: {
			marginTop: "35px",
			height: "441px",
			overflowY: "auto",
			paddingTop: "5px",
			paddingRight: "16px",
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap",
			width: "100%",
			minHeight: "78px",
		},
		inputIcon: {
			color: "#8e8e93", 
			minWidth: "22px", 
			height: "20px",
			fontSize: "12px",
			margin: "8px 10px 32px 0",
			paddingBottom: 0,
			display: "flex",
		},
		configText: {
			margin: "auto 0 42px 0",
			color: "#8e8e93",
			fontSize: "14px",
		},
		typeText: {
			color: "red"
		}
	});
};
CDStep2.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CDStep2));
