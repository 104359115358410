import DataModel from "./DataModel";

class DiscoveredDeviceCSVUpload extends DataModel {
	constructor() {
		super("discovered_devices/csv_bulk_upload_promote");
	}

	// `file` is expected to be a File (https://www.w3.org/TR/FileAPI/#dfn-file)
	uploadCSV(file) {
		return this.uploadFiles('csv_upload', file);
	}
}

export default DiscoveredDeviceCSVUpload;

