import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import TextInput from './TextInput';

//icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
	container: {
		position: "relative",
		width: "100%"
	},
	onIcon: {
		position: "absolute",
		top: "18px",
		right: "12px",
		color: theme.palette.primary.main
	},
	offIcon: {
		position: "absolute",
		top: "18px",
		right: "12px",
		color: theme.palette.greyIcon.main,
	}
});

class PasswordInput extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			visible: false
		};
	}

	toggle_visible = () => {
		this.setState( (prev_state) => ({visible: !prev_state.visible}));
	}

	render() {
		const { classes, emitChange, priorState, label, key, field, error, error_message } = this.props;
		const { visible } = this.state;
		return (
			<div className={classes.container}>
				<TextInput
					error={error || false}
					emitChange={emitChange}
					priorState={priorState}
					label={label}
					error_message={error_message}
					key={key}
					field={field}
					type={visible ? undefined : "password"}
				>
				</TextInput>
				{visible ? 
					<VisibilityIcon className={classes.onIcon} onClick={this.toggle_visible}/>
				:
					<VisibilityOffIcon className={classes.offIcon} onClick={this.toggle_visible}/>
				}
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(PasswordInput));
