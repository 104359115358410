import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
	loginError: ErrorIcon,
};

const styles1 = theme => ({
	success: {
		backgroundColor: theme.palette.green.light,
	},
	error: {
		backgroundColor: theme.palette.red.light,
	},
	info: {
		backgroundColor: theme.palette.primary.light,
	},
	warning: {
		backgroundColor: theme.palette.caution.light,
	},
	loginError: {
		backgroundColor: "#fce7e7",
		color: "#313c4e",
		minWidth: "unset",
		boxShadow: "none",
		marginBottom: "16px",
		padding: "16px",
		[theme.breakpoints.down("sm")]: {
			height: "96px",
		},
		height: "48px",
		flexWrap: "nowrap",
		boxSizing: "border-box",
		"& svg": {
			color: "#e53935"
		},
		"& pre": {
			whiteSpace: "normal"
		},
	},
	icon: {
		fontSize: 20,
		color: "rgba(0, 0, 0, 0.87)",
		marginRight: "10px",
	},
	message: {
		display: "flex",
		alignItems: "center",
	},
	actionButton: {
		color: "white",
		border: "solid white 2px"
	},
	preMessage: {
		fontFamily:"Inter",
		color: "#313c4e",
		maxWidth: "468px",
		whiteSpace: "normal",
	},
	successIcon: {
		color: theme.palette.green.main,
	},
	errorIcon: {
		color: theme.palette.red.icon,
	},
	warningIcon: {
		color: theme.palette.caution.main,
	},
	infoIcon: {
		color: theme.palette.primary.dark,
	},
	close: {
		marginRight: 0,
	}
});

function CustomSnackbar(props) {
	const { classes, className, message, onClose, action, variant, ...other } = props;
	const Icon = variantIcon[variant];
	let actions = [];
	if (action) {
		actions.push(
			<Button
				className={classes.actionButton}
				size="small"
				key={action.display}
				onClick={action.action}
			>
				{action.display}
			</Button>
		);
	}

	if(onClose) {
		actions.push(
			<IconButton
				key="close"
				aria-label="Close"
				color="inherit"
				onClick={onClose}
			>
				<CloseIcon className={classes.icon + " " + classes.close} />
			</IconButton>
		);
	}

	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={classNames(classes.icon, classes[variant + "Icon"])} />
					<pre className={classNames(classes.preMessage)}>
					{message}
					</pre>
				</span>
			}
			action={actions}
			{...other}
		/>
	);
}

CustomSnackbar.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default withStyles(styles1)(CustomSnackbar);
