import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TransfersList from '../../TransfersSpecific/TransfersList';
import Loading from '../../DisplayOriented/Loading';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import FilterSidebar from '../../ActionOriented/FilterSidebar';

//services
import Auth from '../../../services/Auth';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import { AssignNestedModels, GetAll } from '../../../services/CLURDUtilities';
import DeviceType from '../../../services/DataModels/DeviceType';
import Translator from '../../../services/DataModels/Translator';
import DeviceTransferRequest from '../../../services/DataModels/DeviceTransferRequest';


class TransfersTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			items: null,
			page_data: {
				page: 1,
				per_page: 12,
				page_meta: true,
				total: null
			},
			is_sent: false,
			show_filters: false,
			filter_data: {
				status: "not_completed"
			}
		};
		this.set_filters();
		this.load_transfer_requests();
		this.props.tabHostProxy.setRootRefresh(this.load_transfer_requests);
	}

	set_filters = () => {
		this.filters = [{
			label: 'Status',
			type: 'select',
			field: "status",
			options: [
				{display: "All Statuses", value: ''},
				{display: "Not Completed", value: 'not_completed'},
				{display: "Pending", value: "pending"},
				{display: "Processing", value: "processing"},
				{display: "Completed", value: "completed"},
				{display: "Error", value: "error"}
			],
		}];
	}

	prepare_filters = (filters) => {
		let prepared_filters = filters;
		const status = filters.Status;
		if (status === "not_completed") {
			prepared_filters = {
				status_ne: "completed"
			};
		} else {
			prepared_filters = {
				status: filters.Status
			};
		}
		return prepared_filters;
	}

	apply_filters = (filters) => {
		this.setState({filter_data: this.prepare_filters(filters)}, () => {
			this.load_transfer_requests();
		});
	}
	
	load_transfer_requests = () => {
		if (this.state.items !== null) this.setState({items: null});
		const classes = this.props.classes;
		const is_sent = this.state.is_sent;
		let params = Object.assign(this.state.page_data, this.state.filter_data);
		if (params.status === "not_completed") {
			params.status_ne = "completed";
			delete params.status;
		}
		delete params.total;
		if (is_sent) {
			params.from_company_id = Auth.currentCompany()._id;
		} else {
			params.from_company_id_ne = Auth.currentCompany()._id;
		}
		new DeviceTransferRequest().listFromAPI(params).then( (result) => {
			let total = result.total;
			let transfers = result.items;
			DeviceTransferRequest.loadRequirements(transfers).then( () => {
				this.setState( (state) => {
					state.items = transfers;
					state.page_data = {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: total
					};
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
			});
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}

	change = (new_tab) => {
		this.setState( (state) => {
			state.items = null;
			state.is_sent = !state.is_sent;
			return state;
		}, this.load_transfer_requests);
	}

	page_change = (params) => {
		this.setState( (state) => {
			state.page_data.page = params.page;
			return state;
		}, this.load_transfer_requests);
	}

	render() {
		const { tabHostProxy, classes } = this.props;
		const { is_sent, items, page_data } = this.state;
		return (
			<div className={classes.container}>
				<div style={{visibility: this.state.show_filters ? "visible" : "hidden"}} onClick={() => this.setState({show_filters: false})} className={classes.filterBg}>
					<Card
						onClick={(event) => event.stopPropagation()}
						className={classes.filterContainer}
					>
						<FilterSidebar
							filters={this.filters}
							triggerFilter={this.apply_filters}
							closeFilters={() => this.setState({show_filters: false})}
							value={this.state.filter_data}
						/>
					</Card>
				</div>
				<TransfersList
					filtering={this.is_filtering()}
					page_change={this.page_change}
					page_data={page_data}
					items={items}
					isSent={is_sent}
					tabHostProxy={tabHostProxy}
					modelChange={this.change}
					show_filters={() => this.setState({show_filters: true})}
				/>
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		container: {
			display: "flex",
			width: "100%",
		},
		filterBg: {
			position: "fixed",
			width: "100vw",
			height: "100vh",
			backgroundColor: "rgba(0,0,0,.5)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			top: 0,
			left: 0,
			zIndex: 9999
		},
		filterContainer: {
			position: "relative",
			zIndex: 3,
			width: "800px",
			backgroundColor: "white",
			padding: "72px 48px 35px 48px",
			overflowY: "auto",
			maxHeight: "750px",
		}
	});
};



TransfersTab.contextType = SnackbarContext;

export default compose(
	withRouter,
	withStyles(styles),
)(TransfersTab);