import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PaginationContainer from '../Containers/PaginationContainer';
import Card from '@material-ui/core/Card';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import User from '../../services/DataModels/User';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableList from '../Table/TableList';
import Permissions from '../../services/Permissions';
import Auth from '../../services/Auth';
import Loading from '../DisplayOriented/Loading';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BusinessIcon from '@material-ui/icons/Business';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const heading_info = [
	{label: "Name", value: "table_name", field: "name", align: "left", disablePadding: false, sortable: true, content: "function"},
	{label: "Description", field: "description", align: "left", disablePadding: false, sortable: true},
	{label: "Account", value: "nested_company.name", field: "company_id", nested_field: "true", align: "left", disablePadding: false, sortable: true},
];


class RolesList extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
			layout: "card"
		};
	}

	openUserTab(user) {
		this.props.tabHostProxy.addTab("user", user);
	}

	createRole = () => {
		this.props.tabHostProxy.addTab("role");
	}

	handleChangePage = (event, page) => {
		this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
	};

	confirm_delete = (user, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Delete this user? This action cannot be reversed.
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								color="primary"
								onClick={() => this.delete_user(user)}
							>
								DELETE USER
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	render_table = () => {
		const { roles, page_data, classes } = this.props;
		if (!roles) {
			return <Loading/>;
		}
		return (
			<TableList
				headings={heading_info} 
				items={roles}
				page_data={page_data}
				perform_sort={this.props.perform_sort}
			/>
		);
	}

	render() {
		const { roles, page_data, classes } = this.props;
		// temporarily disable role creation for non-admins
		let disabled = (!Auth.currentRoleIsAdmin())
		let buttons = Permissions.allow(["create"], "user_type") ? [({disabled: disabled, label: "CREATE ROLE", icon: (<AddIcon />), action: this.createRole })] : undefined;
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<PaginationContainer
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggle_filter={this.props.show_filters}
					filtering={this.props.filtering}
				/>
				<div className={classes.rolesContainer}>
					{this.render_table()}
				</div>
			</div>
		);
	}
}



const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			flexGrow: 2,
			width: "75%",
		},
		rolesContainer: {
			overflowY: "auto",
			height: "calc(100% - 82px)",
			alignItems: "flex-start"
		},
		prompt: {
			fontFamily: "Inter",
			fontSize: "22px",
			marginBottom: "24px",
		},
		modalWrapper: {
			padding: "24px"
		},
		userCardContainer: {
			width: "33.33%",
			display: "inline-flex",
			position: "relative",
		},
		userCard: {
			margin: "12px",
			width: "100%",
			backgroundColor: "#f3f3f3",
			position: "relative",
		},
		userImageContainer: {
			display: "flex",
			padding: "8px",
			height: "200px",
		},
		userImage: {
			maxWidth: "100%",
			maxHeight: "200px",
			margin: "auto",
			display: "block",
		},
		textRowContainer: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			flexWrap: "wrap",
			padding: "8px",
			backgroundColor: "white"
		},
		modalButtons: {
			display: "flex",
			justifyContent: "flex-end"
		},
		userTitle: {
			fontSize: "28px",
			color: "black",
			width: "100%",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		iconWithTextContainer: {
			display: "flex",
			alignItems: "center",
			marginTop: "8px",
			width: "100%",
		},
		companyIcon: {
			fontSize: "30px",
			color: theme.palette.greyIcon.main
		},
		iconLabel: {
			color: "grey",
			fontWeight: "400",
			overflow: "hidden",
			marginLeft: "8px",
			fontSize: "18px",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		},
		buttonContainer: {
			display: "flex",
			margin: "auto",
			marginRight: 0
		},
		viewUserButton: {
			padding: "8px",
		},
		bottomRow: {
			display: "flex",
			backgroundColor: "white",
			padding: "8px",
		},
		profileImage: {
			width: "100%",
			height: "100%",
		},
		placeholderProfileImage: {
			width: "100%",
			height: "100%",
			color: theme.palette.primary.main
		},
		topRowIconsContainer: {
			boxSizing: "border-box",
			position: "absolute",
			justifyContent: "flex-end",
			top: 0,
			padding: "0 4px",
			left: 0,
			width: "100%",
			display: "flex",
		},
		iconButton: {
			padding: "7px",
			color: "grey",
		}
	})
};

RolesList.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(RolesList));
