/* eslint-disable no-unused-expressions */
import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    root: {
        width: "100%",
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center",
        position: "relative",
    },
    overrideLabelClass: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "88%",
        fontSize: "14px",
        color: "#8e8e93",
    },
    outlineOverride: {
        borderRadius: "10px"
    },
    errorMessage: {
        color: "red",
        marginLeft: "14px",
    },
    invisible: {
        display: "none",
        visibility: "hidden",
    },
    inputOverride: {
        fontSize: "14px",
    }
});

class TextInput extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    handleKeyPress = (event) => {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            this.props.onEnter ? this.props.onEnter() : "";
        }
    }

    localChange = (event) => {
        let { value } = function () {
            return {
                value: this.target.value
            };
        }.bind(event)();
        this.value = value;
        this.props.emitChange({ label: this.props.label, value: value, field: this.props.field });
    }

    render() {
        const { type, disabled, variant, placeholder, error_message, priorState, label, note, classes, overrideClass, className, helperText } = this.props;
        let input_variant = "";
        if (variant === undefined || variant === null) {
            input_variant = "outlined";
        } else {
            input_variant = variant;
        }
        let class_name = className || classes.root;
        this.value = priorState || "";
        let InputProps = Object.assign(this.props.InputProps || {}, { className: classNames(classes, overrideClass) });
        return (
            <div className={classes.container}>
                <TextField
                    type={type}
                    error={this.props.error || false}
                    placeholder={placeholder}
                    className={class_name}
                    InputLabelProps={{ className: classNames(classes, classes.overrideLabelClass) }}
                    InputProps={InputProps}
                    disabled={disabled}
                    multiline={this.props.multiline}
                    rows={this.props.rows}
                    label={label}
                    name={label}
                    margin="none"
                    fullWidth={true}
                    variant={input_variant}
                    value={this.value}
                    onChange={this.localChange}
                    onKeyDown={this.handleKeyPress}
                    helperText={!this.props.error ? helperText : ""}
                />
                {this.props.noError ? "" : <FormHelperText className={classes.errorMessage + " " + (this.props.error ? "" : classes.invisible)} id="component-error-text">{error_message}</FormHelperText>}
            </div>
        );
    }
}

export default withStyles(styles)(withTheme()(TextInput));
