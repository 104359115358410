import React from 'react';
import {
	BrowserRouter as Router,
	Redirect,
} from 'react-router-dom';
import qs from 'qs';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Auth from '../services/Auth';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import CustomSnackbar from '../components/Containers/CustomSnackbar';

const styles = theme => ({
	container: {
		display: "flex",
		width: "100%",
	},
	loginArea: {
		[theme.breakpoints.down('sm')]: {
			width: "80%",
		},
		width: "40%",
		margin: "auto",
		marginTop: "18%",
	},
	loginSymbol: {
		width: "50px",
		margin: "auto",
		display: "flex",
	},
	loginTitle: {
		fontFamily: "Saira,sans-serif",
		fontWeight: 600,
		color: "#32618c",
		fontSize: "32px",
		lineHeight: "1.2",
		width: "100%",
		fontSize: "28px",
		margin: "10px 0 10px 0",
	},
	loginInput: {
		width: "100%",
	},
	loginButton: {
		margin: "0 auto",
		display: "flex",
		marginTop: "16px",
	},
	forgotPassword: {
		cursor: "pointer",
		color: theme.palette.primary.main,
		fontFamily: "Inter,sans-serif",
		textDecoration: "none",
		fontSize: "16px",
	},
	progress: {
		top: `calc(50vh - 100px)`,
		margin:'auto',
	},
	loadingArea: {
		height: "100vh",
		display: "flex",
		alignItems: "center",
		textAlign: "center",
	},
	matchMessage: {
		color: theme.palette.primary.main,
		fontFamily: "Inter,sans-serif",
		textDecoration: "none",
		fontSize: "16px",
	}
});

class ResetPassword extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			password: '',
			passwordConfirm: '',
			matching: false,
			invalidToken: null,
			token: '',
			snackbar: {
				open: false
			},
		};
		this.testToken();
	}

	testToken = () => {
		let token = qs.parse(this.props.location.search)["?token"];
		Auth.testToken(token,
			() => {
				this.setState( {invalidToken: false, token: token} );
			},
			(error) => {
				this.openSnackbar(error, false);
				setTimeout( function(){
					this.setState( {invalidToken: true} );
				}.bind(this), 3000);
			}
		);
	}

	handleChange = (event) => {
		
		var stateObject = function () {
			let returnObj = {};
			returnObj[this.target.id] = this.target.value;
			return returnObj;
		}.bind(event)();
		this.setState(stateObject, () => {
			if (this.state.password === this.state.passwordConfirm && this.state.password.length > 6) {
				this.setState({matching: true});
			} else {
				this.setState({matching: false});
			}
		});
	}

	sendPasswordReset = () => {
		this.setState({matching: false, password: "", passwordConfirm: ""});
		Auth.resetPassword(this.state.password, this.state.token).then( () => {
			this.openSnackbar("Your password has been reset. Redirecting to login.", true);
			setTimeout( function(){
				this.props.history.push('/login');
			}.bind(this), 3000);	
		}).catch( (error) => {
			this.openSnackbar(error, false);
			this.setState({matching: false, password: "", passwordConfirm: ""});
		});
	}

	openSnackbar = (message, result) => {
		result ? result = "success" : result = "error";
		this.setState( {snackbar: {
			message: message,
			result: result,
			open: true,
		}});
	}

	closeSnackbar = () => {
		this.setState( {snackbar: {
			message: "",
			open: false,
			result: this.state.snackbar.result
		}});
	}

	render() {
		const { classes } = this.props;
		if (this.state.invalidToken === null) {
			return (
				<div className={classes.loadingArea}>
					<CircularProgress className={classes.progress} color="primary" />
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						open={this.state.snackbar.open}
						autoHideDuration={4000}
						onClose={this.closeSnackbar}
					>
						<CustomSnackbar
							onClose={this.closeSnackbar}
							variant={this.state.snackbar.result}
							message={this.state.snackbar.message}
						/>
					</Snackbar>
				</div>
			);
		} else if (this.state.invalidToken) {
			return (
				<Redirect to={"/"} />
			);
		} else {
			return (
				<div className={classes.container}>
					<div className={classes.loginArea}>
						<img className={classes.loginSymbol} src={require('../images/edge_logo.png')} />
						<h5 className={classes.loginTitle}>Reset Your Password</h5>
						<TextField
								id="password"
								label="Password"
								className={classes.loginInput}
								type="password"
								margin="normal"
								variant="outlined"
								value={this.state.password}
								onChange={this.handleChange}
							/>
							<TextField
								id="passwordConfirm"
								label="Confirm Password"
								className={classes.loginInput}
								type="password"
								margin="normal"
								variant="outlined"
								value={this.state.passwordConfirm}
								onChange={this.handleChange}
							/>
							{!this.state.matching ? 
								<div className={classes.matchMessage}>
									Your passwords must match and the new password must be more than 6 characters. 
								</div>
							: ''}
							<Button disabled={!this.state.matching} onClick={this.sendPasswordReset} className={classes.loginButton} color="primary" variant="contained">
								Reset Password
							</Button>
					</div>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						open={this.state.snackbar.open}
						autoHideDuration={4000}
						onClose={this.closeSnackbar}
					>
						<CustomSnackbar
							onClose={this.closeSnackbar}
							variant={this.state.snackbar.result}
							message={this.state.snackbar.message}
						/>
					</Snackbar>
				</div>
			)
		}
	}
}

export default withStyles(styles)(withTheme()(ResetPassword));
