import Auth from './Auth';

const Permissions = {
	allow(actions, model, item_company_id) {
		const model_permissions = Auth.currentRole().abilities[model];
		let actions_array = actions;
		if (!Array.isArray(actions)) {
			actions_array = [actions];
		}
		let allowed = true;
		actions_array.forEach( (action) => {
			if (model_permissions[action] === "not_allowed") {
				allowed = false;
			} else if (model_permissions[action] === "self" && (item_company_id != null && item_company_id !== Auth.currentCompany()._id) ) {
				allowed = false;
			}
		});
		return allowed;
	},
	only_read(model) {
		const model_permissions = Auth.currentRole().abilities[model];
		let non_read = ["create", "update", "delete"];
		let only_read = true;
		non_read.forEach( (action) => {
			if (model_permissions[action] !== "not_allowed") {
				only_read = false;
			}
		});
		return only_read;
	},
	allow_one_of(actions, model) {
		const model_permissions = Auth.currentRole().abilities[model];
		let allow_one_of = false;
		actions.forEach( (action) => {
			if (model_permissions[action] !== "not_allowed") {
				allow_one_of = true;
			}
		});
		return allow_one_of;
	}
}

export default Permissions;
