import React from 'react';
import { AssignNestedModels } from '../CLURDUtilities';
import DataModel from "./DataModel";
import Permissions from '../Permissions';

//mui
import Button from '@material-ui/core/Button';

//inputs
import TextInput from '../../components/Inputs/TextInput';
import NumberInput from '../../components/Inputs/NumberInput';
import SelectInput from '../../components/Inputs/SelectInput';
import NumberOrHexInput from '../../components/Inputs/NumberOrHexInput';
import SwitchInput from '../../components/Inputs/SwitchInput';
import LookupInput from '../../components/Inputs/LookupInput';

//icons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class Command extends DataModel {
    constructor(data = BLANK) {
        super("commands", data);
    }

    static loadRequirements(commands) {
        return new Promise((resolve, reject) => {
            Promise.all([AssignNestedModels("translators", "translator_id", commands), AssignNestedModels("companies", "company_id", commands)]).then((result) => {
                resolve(commands);
            }).catch((error) => reject(error));
        });
    }

    static prepareForTable(commands, renderMenu, classes) {
        commands.forEach((command) => {
            if (command.translator_id === '') {
                command.translator_display = "None";
            } else if (!command.nested_translator) {
                command.error = "This command does not have a valid translator. Please update or delete this command. ";
                command.translator_display = <span className={classes.notFound}>Translator Not Found</span>;
            } else {
                command.translator_display = command.nested_translator.name;
            }

            if (!command.nested_company) {
                if (!Boolean(command.error)) command.error = "";
                command.account_name = <span className={classes.inheritedInformation}>Inherited</span>;;
            } else {
                command.account_name = command.nested_company.name;
            }

            if (Boolean(command.sender_type)) {
                if (Boolean(Command.senderTypes(command.sender_type))) {
                    command.sender_display = Command.senderTypes(command.sender_type).display;
                } else {
                    if (!Boolean(command.error)) command.error = "";
                    command.error += "This command does not have a valid sender type (" + command.sender_type + "). Please delete this command. ";
                    command.sender_display = (<span className={classes.unset}>Unsupported Type</span>);
                }
            } else {
                command.sender_display = (<span className={classes.unset}>Unset</span>);
            }

            command.action = (<span className={classes.tableMenu}>{renderMenu(command, true)}</span>);
        });
        return commands;
    }

    static convert_for_ui(command) {
        delete command.action;
        delete command.select;
        delete command.sender_display;
        let new_command = JSON.parse(JSON.stringify(command));

        if (new_command.sender == null) {
            if (new_command.sender_type && new_command.sender_type !== "") {
                new_command.sender = this.getDefaultSender(new_command.sender_type);
            } else {
                new_command.sender_type = '';
                new_command.sender = {};
            }
        }

        return new_command;
    }

    static convert_for_api(command) {
        let new_command = JSON.parse(JSON.stringify(command));
        return new_command;
    }

    static getDefaultSender(sender_type) {
        const inputs = this.senderTypes(sender_type).inputs;
        if (!inputs || (Array.isArray(inputs) && inputs.length === 0)) return;
        let default_sender = {};
        inputs.forEach((input) => {
            default_sender[input.field] = input.default_value;
        });
        return default_sender;
    }

    static validate(command) {
        let validationMessages = {};
        // let o = editableCopy;
        // if (o.name == null || o.name.length == 0) {
        // 	validationMessages.name = ["Please name this software package."];
        // }
        // if (o.script == null || o.script.length == 0) {
        // 	validationMessages.script = ["Please include the script that will execute the installation."];
        // }
        // if (o.filename == null || o.filename.length == 0) {
        // 	validationMessages.filename = ["Please upload files to be used in the software installation."];
        // }
        // if (Object.keys(validationMessages).length > 0) {
        // 	validationMessages.valid = false;
        // } else {
        // 	validationMessages.valid = true;
        // }
        return validationMessages;
    }



    static get_input(input, object, onChange, classes) {
        const { display, field, type, default_value } = input;
        switch (type) {

            case "headers":


            case "bool":
                return (
                    <SwitchInput
                        key={display + "_" + field}
                        initial={object[field]}
                        emitChange={onChange}
                        onLabel={display}
                        offLabel={display}
                        field={field}
                        location="start"
                    />
                );
                break;

            case "time":
            case "int":
                return (
                    <div key={display + "_" + field} className={classes.numberInput}>
                        <NumberInput
                            label={display}
                            minimum={0}
                            emitChange={onChange}
                            priorState={object[field]}
                            field={field}
                        />
                    </div>
                );
                break;

            case "string":
                return (
                    <TextInput
                        key={display + "_" + field}
                        label={display}
                        emitChange={onChange}
                        priorState={object[field]}
                        field={field}
                    />
                );
                break;

        }
    }

    static senderTypes(sender_type) {
        // These maps define the sender types available, with the `inputs` section describing values that
        // will be stored in the command's `sender_json`
        const map = {

            "http_sender": {
                display: "HTTP",
                inputs: [
                    { display: "Method", field: "method", type: "string", default_value: "GET" },
                    { display: "URL", field: "url", type: "string", default_value: "" },
                    // { display: "Headers", field: "headers", type: "headers", default_value: "" },
                    { display: "Timeout", field: "timeout", type: "int", default_value: "" },
                ]
            },
            "shell_sender": {
                display: "Shell",
                inputs: [
                    { display: "Command", field: "command", type: "string", default_value: "" },
                ]
            },
        };
        if (Boolean(sender_type)) {
            if (!map[sender_type]) return null;
            return map[sender_type]
        } else {
            return map;
        }
    }

}

const BLANK = {
    name: "",
    company_id: "",

};

export default Command;
