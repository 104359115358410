import React from 'react';
import PollableAttribute from '../../../services/DataModels/PollableAttribute';
import PollableAttributesForm from '../../PollableAttributesSpecific/PollableAttributesForm';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
	container: {
		width: "100%",
		overflowY: "scroll",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	contentContainer: {
		display: "flex",
		flexWrap: "nowrap",
		margin: "32px 28px",
	}
});

class PollableAttributesDetailTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			pollableattributesTypes: null,
			pollableattributesDataTypes: null,
			dataModel: null,
		};
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	componentDidMount() {
		this.prepare_pollableattribute();
	}

	refresh = () => {
		this.setState({
			dataModel: null
		});
		new PollableAttribute({_id: this.props.data._id}).readFromAPI().then( (pollableattribute) => {
			this.props.tabHostProxy.updateTitle(pollableattribute._id, pollableattribute);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			this.prepare_pollableattribute(pollableattribute);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	prepare_pollableattribute = (pollableattribute) => {
		let dataModel = pollableattribute ? new PollableAttribute(pollableattribute) : new PollableAttribute(this.props.data);
		this.setState({
			dataModel: dataModel,
			pollableattributesTypes: PollableAttribute.getTypes(),
			pollableattributesDataTypes: [...PollableAttribute.getDataTypes(), { display: "Choose type", value: "" }]
		});
	}

	savePollableAttribute = (edited_pollableAttribute) => {
		// const {defaults} = edited_pollableAttribute
		// edited_pollableAttribute.defaults = null;
		let new_data_model = this.state.dataModel;
		// edited_pollableAttribute.defaults = defaults;
		new_data_model
		.setData(edited_pollableAttribute)
		.createOrSave()
			.then( (result) => {
				this.props.tabHostProxy.refresh();
				this.props.tabHostProxy.closeSelf();
				this.props.tabHostProxy.addTab("pollable_attribute", result);
			})
			.catch( (error) => {
				let message = `There was an error saving this pollable attribute: ${error}`;
				this.context.openSnackbar(message, 'error');
			});
	}

	deletePollableAttribute = () => {
		let _id = this.state.dataModel.data._id;
		this.state.dataModel.deleteFromAPI().then( (result) => {
			this.props.tabHostProxy.refresh();
			this.props.tabHostProxy.closeSelf(_id);
		}).catch( (error) => {
			let message = `There was an error deleting this pollable attribute: ${error}`;
			this.context.openSnackbar(message, 'error');
		});
	}

	render() {
		const { classes, tabHostProxy} = this.props;
		const { dataModel, pollableattributesTypes, pollableattributesDataTypes  } = this.state;
		return (
			<div className={classes.container}>
				{ dataModel === null ?
					<Loading />
					:
					<div className={classes.contentContainer}>
						<PollableAttributesForm
							className={classes.formContainer}
							pollableattributesTypes={pollableattributesTypes}
							pollableattributesDataTypes={pollableattributesDataTypes}
							dataModel={dataModel}
							tabHostProxy={tabHostProxy}
							onSave={(pollableattribute) => this.savePollableAttribute(pollableattribute)}
							onDelete={this.deletePollableAttribute}
						/>
					</div>
				}
			</div>
		)
	}
}

PollableAttributesDetailTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(PollableAttributesDetailTab));
