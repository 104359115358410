import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import SelectInput from '../Inputs/SelectInput';
import Tooltip from '@material-ui/core/Tooltip';
import TextInput from '../Inputs/TextInput';
import NumberInput from '../Inputs/NumberInput';
import SwitchInput from '../Inputs/SwitchInput';
import RuleActionType from '../../services/DataModels/RuleActionType';
import IntegrationType from '../../services/DataModels/IntegrationType';
import Integration from '../../services/DataModels/Integration';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import TimeInput from '../Inputs/TimeInput';
import Permissions from '../../services/Permissions';
import Humanize from '../../services/Humanize';
import AceEditor from "react-ace";

const styles = {
	container: {
		width: "100%",
		display: "block"
	},
	textField: {
		width: "100%",
	},
	actionFrequencyLabel: {
		color: "grey",
		fontSize: "14px",
		marginBottom: "8px"
	},
	actionGrid: {
		overflowY: "auto",
		display: "flex",
		flexWrap: "wrap",
		height: "calc(100% - 81px)",
		padding: "12px",
	},
	textWrapper: {
		margin: "12px 0 -12px 0",
	},
	integrationWrapper: {
		marginTop: "32px"
	},
	actionTypeWrapper: {
		marginBottom: "24px"
	},
	addActionCard: {
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		color: "#1153b6",
		cursor: "pointer"
	},
	addText: {
		marginLeft: "8px",
	},
	actionContainer: {
		width: "33%",
		display: "flex",
		minHeight: "120px"
	},
	largeAction: {
		width: "66%"
	},
	actionCard: {
		margin: "12px",
		padding: "8px",
		width: "100%",
		backgroundColor: "#f3f3f3",
		position: "relative",
	},
	selectInput: {
		backgroundColor: "white",
	},
	iconContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end"
	},
	actionIcon: {
		padding: "4px",
		margin: "2px",
	},
	headerLabelWrapper: {
		display: "flex",
		alignItems: "center"
	},
	headerLabel: {
		color: "#8e8e93",
		textTransform: "uppercase",
		fontSize: "14px",
	},
	warning: {
		color: "orange"
	},
	error: {
		color: "red"
	},
	device: {
		color: "red"
	},
	headersRow: {
		display: "flex",
	},
	headerInput: {
		marginRight: "8px",
	},
	closeHeaderIcon: {
		padding: "4px",
		margin: "auto",
	},
	addHeaderButton: {
		margin: "8px 0",
		"&:hover": {
			backgroundColor: "transparent",
		}
	},
	editorWrapper: {
		margin: "8px 0",
		backgroundColor: "#2f3129",
		padding: "12px 0"
	},
	scriptLabel: {
		fontSize: "14px",
		color: "#8e8393",
	},
};

const httpMethods = [
	{
		display: "Select a Method",
		value: ""
	},
	{
		display: "GET",
		value: "get"
	},
	{
		display: "PUT",
		value: "put"
	},
	{
		display: "POST",
		value: "post"
	},
	{
		display: "DELETE",
		value: "delete"
	},
];

const bodyTypes = [
	{
		display: "Select a Body Type",
		value: ""
	},
	{
		display: "html",
		value: "html"
	},
	{
		display: "text",
		value: "text"
	},
];

class RuleActions extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		if (this.props.priorState.actions === null) {
			this.prepareActionData();
		}
	}

	prepareActionData = () => {
		let preparation = Promise.all([this.loadActionTypes(), this.loadIntegrationTypes(), this.loadIntegrations()]);
		preparation
			.then(this.createAllActions)
			.catch( (error) => {
				console.log(error);
				this.context.openSnackbar(error, "error");
			});
	}
	
	loadActionTypes = () => {
		return new Promise( (resolve, reject) => {
			return new RuleActionType().listFromAPI().then( (data) => {
				this.action_types = data.items;
				resolve();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		})
	}

	loadIntegrationTypes = () => {
		return new Promise( (resolve, reject) => {
			return new IntegrationType().listFromAPI().then( (data) => {
				this.integration_types = data.items.filter((it) => it.associated_action_type != null);
				this.non_action_integration_types = data.items.filter((it) => it.associated_action_type == null);
				resolve();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		})
	}

	loadIntegrations = () => {
		return new Promise( (resolve, reject) => {
			if (Permissions.allow(["read"], "integration")) {
				return new Integration().listAllFromAPI().then( (integrations) => {
					this.integrations = integrations;
					resolve();
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
					reject();
				});
			} else {
				this.integrations = [];
				resolve();
			}
		});
	}

	createAllActions = () => {
		let actions = [];
		Object.entries(this.action_types).forEach( (keyValuePair) => {
			let type = keyValuePair[0];
			let type_object = keyValuePair[1];
			actions.push(type_object);
		});
		this.integrations.forEach( (integration) => {
			let int_types = this.integration_types;
			let type_found = int_types.find( type => type.type === integration.type);
			let non_action_type = this.non_action_integration_types.find( type => type.type === integration.type);
			integration.value = integration._id;
			integration.display = integration.name;
			if (type_found) {
				integration.action_type = type_found.associated_action_type;
				integration.action_info = this.action_types[integration.action_type];
				this.assignIntegrationLogo(integration);
			} else if (!non_action_type) {
				this.context.openSnackbar("An integration failed to load for this policy", 'error');
			}
		});
		this.actions = actions;
		this.formatActions();
		this.preserveData();
	}

	assignIntegrationLogo = (integration) => {
		switch (integration.type) {
			case "aws_device_integrations":
				integration.logo = "aws_icon.jpg";
				break;
			case "azure_device_integrations":
				integration.logo = "azure_logo.png";
				break;
			case "bluemix_device_integrations":
				integration.logo = "bluemix_icon.png";
				break;
			case "twilio_rule_action_integrations":
				integration.logo = "twilio_icon.png";
				break;
			case "postmark_rule_action_integrations":
				integration.logo = "postmark_icon.jpg";
				break;
		}
	}

	preserveData = () => {
		this.props.emitChange({
			actions: this.actions,
			thenActions: this.thenActions,
			elseActions: this.elseActions
		});
	}

	formatActions = () => {
		if (this.actions === null) {
			return;
		}
		this.thenActions = this.props.priorState.thenActions != null ? this.props.priorState.thenActions.map( (action) => this.copyActionFromTemplate(action, this.getActionTemplate(action))) : null;
		this.elseActions = this.props.priorState.elseActions !== null ? this.props.priorState.elseActions.map( (action) => this.copyActionFromTemplate(this.getActionTemplate(action)))
		: null;
	}

	getActionTemplate = (action) => {
		return this.actions.find( (action_template) => {
			return ( action.type === action_template.type );
		});
	}

	copyActionFromTemplate = (action_to_change, template) => {
		action_to_change = action_to_change || {};
		action_to_change.executed = {cloud: template.cloud, edge: template.edge};
		action_to_change.type = template.type;

		let fields = template.params || template.action_info.params;
		if (action_to_change.fields && action_to_change.fields.length !== 0) {
		} else if (fields) {
			let new_fields = [];
			fields.forEach( (field, index) => {
				let field_to_push = {};
				let field_value = action_to_change[field.field] ? action_to_change[field.field] : null;
				field_to_push = {label: field.description, value: field_value, field: field.field, type: field.type, allowed_values: field.allowed_values};
				if (field.field === "method") {
					field_to_push.value = field_value ? field_value.toLowerCase() : "get";
				} else if (field.field === "headers") {
					field_to_push.value = action_to_change.headers ? Object.entries(action_to_change.headers).map( (pair) => ({key: pair[0], value: pair[1]})) : [{key: "", value: ""}];
				}
				new_fields.push(field_to_push);
				delete action_to_change[field.field];
			});
			action_to_change.fields = new_fields;
		}
		return action_to_change;
	}

	render() {
		const {priorState, otherwisePlural, classes} = this.props;
		this.actions = priorState ? priorState.actions : null;
		this.elseActions = priorState ? priorState.elseActions : null;
		this.thenActions = priorState ? priorState.thenActions : [];
		let otherwise_text = "";
		if (otherwisePlural === "and") {
			otherwise_text = "If any one of these conditions is false, execute these actions:"
		} else if ( otherwisePlural === "or") {
			otherwise_text = "If every one of these conditions are false, execute these actions:"
		} else {
			otherwise_text = "If the condition is false, execute these actions:"
		}
		//todo loading animation for the below actions when loading
		return (
			
			<div className={classes.container}>
				{this.actions === null ?
					"loading actions"
				: 
					<div>
						<div className={classes.actionGrid}>
							{this.thenActions != null ? this.thenActions.map( (action, index) => (
								this.renderAction(action, index, "then", classes)
							)) : ""}
							{this.props.disabled ? "" : <div className={classes.actionContainer}>
								<Card className={classes.actionCard}>
									<div onClick={() => this.addAction("then")} className={classes.addActionCard}>
										<AddBoxIcon />
										<span className={classes.addText}>
											Action
										</span>
									</div>
								</Card>
							</div>}
						</div>
						{this.elseActions !== null ?
							<div>
								<span>
									{otherwise_text}
								</span>
								<div className={classes.actionGrid}>
									{this.elseActions.map( (action, index) => (
										this.renderAction(action, index, "else", classes)
									))}
									{this.props.disabled ? "" : <div className={classes.actionContainer}>
										<Card className={classes.actionCard}>
											<div onClick={() => this.addAction("else")} className={classes.addActionCard}>
												<AddBoxIcon />
												<span className={classes.addText}>
													Action
												</span>
											</div>
										</Card>
									</div>}
								</div>
							</div>
						: ""
						}
					</div>
				}
			</div>
		);
	}

	toggleRetry = ({value}, key, index) => {
		let action_to_change = null;
		if (key === "then") {
			action_to_change = this.thenActions[index];
		} else {
			action_to_change = this.elseActions[index];
		}
		action_to_change.retries = value === true ? 1 : 0;
		action_to_change.retry_timeout = value === true ? 10 : 0;
		action_to_change.retry_timeout_changed = value === true ? 10 : 0;
		this.preserveData();
	}

	handleRetriesChange = (input, key, index) => {
		console.log(input, key, index)
		let action_to_change = null;
		if (key === "then") {
			action_to_change = this.thenActions[index];
		} else {
			action_to_change = this.elseActions[index];
		}
		action_to_change.retries = input.value;
		this.preserveData();
	}

	handleRetryTimeoutSecondsChange = (new_time, time_frame, key, index) => {
		let action_to_change = null;
		if (key === "then") {
			action_to_change = this.thenActions[index];
		} else {
			action_to_change = this.elseActions[index];
		}
		action_to_change.retry_timeout_changed = new_time;
		action_to_change.retry_timeout_time = time_frame;
		switch (time_frame) {
			case "Seconds":
				action_to_change.retry_timeout = new_time;
				break;
			case "Minutes":
				action_to_change.retry_timeout = new_time * 60;
				break;
			case "Hours":
				action_to_change.retry_timeout = new_time * 3600;
				break;
			case "Days":
				action_to_change.retry_timeout = new_time * 86400;
				break;
		}
		this.preserveData();
	}

	toggleActionFrequency = ({value}, key, index) => {
		let action_to_change = null;
		if (key === "then") {
			action_to_change = this.thenActions[index];
		} else {
			action_to_change = this.elseActions[index];
		}
		action_to_change.action_frequency = value === true ? 1 : null;
		action_to_change.action_frequency_changed = value === true ? 1 : null;
		this.preserveData();
	}

	handleActionFrequencyChange = (new_time, time_frame, key, index) => {
		let action_to_change = null;
		if (key === "then") {
			action_to_change = this.thenActions[index];
		} else {
			action_to_change = this.elseActions[index];
		}
		action_to_change.action_frequency_changed = new_time;
		action_to_change.action_frequency_time = time_frame;
		switch (time_frame) {
			case "Seconds":
				action_to_change.action_frequency = new_time;
				break;
			case "Minutes":
				action_to_change.action_frequency = new_time * 60;
				break;
			case "Hours":
				action_to_change.action_frequency = new_time * 3600;
				break;
			case "Days":
				action_to_change.action_frequency = new_time * 86400;
				break;
		}
		this.preserveData();
	}
	
	handleActionTypeChange = (input, key, index) => {
		let action_to_change = null;
		if (key === "then") {
			action_to_change = this.thenActions[index];
		} else {
			action_to_change = this.elseActions[index];
		}
		action_to_change.type = input.value;
		// action_to_change.integration_id = "";
		action_to_change.fields = [];
		action_to_change = this.copyActionFromTemplate(action_to_change, this.getActionTemplate(action_to_change));
		this.preserveData();
	}

	addAction = (thenOrElse) => {
		let newAction = Object.assign({}, this.actions.find( (action) => {
			return action.type === "relay"
		}));
		// newAction.integration_id = "";
		newAction.fields = [];
		newAction.retries = 0;
		newAction.retry_timeout = 0;
		newAction.retry_timeout_changed = null;
		newAction.retry_timeout_time = "Seconds";
		newAction.action_frequency = null;
		newAction.action_frequency_changed = null;
		newAction.action_frequency_time = "Seconds";
		newAction.executed = {cloud: newAction.cloud, edge: newAction.edge};
		if (thenOrElse === "then") {
			this.thenActions.push(newAction);
		} else {
			this.elseActions.push(newAction);
		}
		this.preserveData();
	}

	removeAction = (actionIndex, thenOrElse) => {
		if (thenOrElse === "then") {
			this.thenActions.splice(actionIndex, 1);
		} else {
			this.elseAction.splice(actionIndex, 1);
		}
		this.preserveData();
	}

	renderAction = (action, index, key, classes) => {
		return (
			<div
				key={key + "_action_" + index}
 				className={classes.actionContainer + " " + ((action.fields && action.fields.find( (f) => (f.field === "body_template"))) ? classes.largeAction : "")}
			>
				<Card className={classes.actionCard}>
					<div className={classes.iconContainer}>
						{this.renderActionTypeError(action, classes)}
						{this.renderActionError(action, classes)}
						{/* {this.renderActionWarning(action, classes)} */}
						{this.props.disabled ? "" :
						<Tooltip className={classes.actionIcon} title="Remove Action">
							<IconButton onClick={() => this.removeAction(index, key)} color="inherit">
								<CloseIcon />
							</IconButton>
						</Tooltip>}
					</div>
					<div className={classes.actionTypeWrapper}>
						<SelectInput
							disabled={this.props.disabled}
							basic
							emitChange={(input) => this.handleActionTypeChange(input, key, index)}
							priorState={action.type}
							label=""
							options={this.formatActionOptions()}
						/>
					</div>

					<div className={classes.retryWrapper}>
						<div>
							<SwitchInput
								disabled={this.props.disabled}
								initial={action.retries !== 0}
								emitChange={(input) => this.toggleRetry(input, key, index)}
								onLabel="Retry Execution"
								offLabel="Retry Execution"
								field="toggleActionFrequency"
								location="start"
							/>
						</div>
						{action.retries !== 0 ?
							<React.Fragment>
								<div className={classes.actionFrequencyLabel}>
									Retry attempts
								</div>
								<NumberInput
									disabled={this.props.disabled}
									minimum={1}
									maximum={3}
									emitChange={(input) => this.handleRetriesChange(input, key, index)}
									priorState={action.retries}
								/>
								<div className={classes.actionFrequencyLabel}>
									Retry timeout
								</div>
								<TimeInput
									disabled={this.props.disabled}
									margin="none"
									emitChange={({value, time}) => this.handleRetryTimeoutSecondsChange(value, time, key, index)}
									priorState={{ value: action.retry_timeout_changed, time: action.retry_timeout_time }}
								/>
							</React.Fragment>
							: ""
						}
					</div>

					{!this.props.cloudRule ?
						<div className={classes.frequencyWrapper}>
							<div>
								<SwitchInput
									disabled={this.props.disabled}
									initial={action.action_frequency !== null}
									emitChange={(input) => this.toggleActionFrequency(input, key, index)}
									onLabel="Limit Execution"
									offLabel="Limit Execution"
									field="toggleActionFrequency"
									location="start"
								/>
							</div>
							{action.action_frequency !== null ?
								<React.Fragment>
									<div className={classes.actionFrequencyLabel}>
										Only execute this action once every...
									</div>
									<TimeInput
										disabled={this.props.disabled}
										margin="none"
										emitChange={({value, time}) => this.handleActionFrequencyChange(value, time, key, index)}
										priorState={{ value: action.action_frequency_changed, time: action.action_frequency_time }}
									/>
								</React.Fragment>
								: ""
							}
						</div>
						: ""
					}
					{this.renderActionFields(action, key, index, classes)}
				</Card>
			</div>
		);
	}

	renderActionError = (action, classes) => {
		let execution = this.props.cloudRule ? "cloud" : "edge";
		if (action.executed[execution] === false) {
			// let action_display = action.integration_id !== "" ? action.action_info.description : action.type;
			return (
				<Tooltip className={classes.actionIcon + " " + classes.error} title={`${action.type} actions cannot be carried out on the ${execution}. Toggle cloud vs edge execution at the top right.`}>
					<IconButton color="inherit">
						<ErrorIcon />
					</IconButton>
				</Tooltip>
			);
		} else {
			return "";
		}
	}

	renderActionTypeError = (action, classes) => {
		if (this.props.typeActionAbilities && this.props.typeActionAbilities[action.type] === false) {
			return (
				<Tooltip className={classes.actionIcon + " " + classes.device} title={`One or more of the devices or types do not support this action.`}>
					<IconButton color="inherit">
						<DvrIcon />
					</IconButton>
				</Tooltip>
			);
		} else {
			return "";
		}
	}

	renderActionWarning = (action, classes) => {
		if (action.integration_id !== "" && this.props.validIntegrations !== null && this.props.validIntegrations.indexOf(action.integration_id) === -1) {
			return (
				<Tooltip className={classes.actionIcon + " " + classes.warning} title="Integration not found on attached device(s).">
					<IconButton color="inherit">
						<WarningIcon />
					</IconButton>
				</Tooltip>
			);
		} else {
			return "";
		}
	}

	renderActionFields = (action, thenOrElse, index, classes) => {
		console.log(action);
		if (action.fields && action.fields.length > 0) {
			let fields = [];
			action.fields.forEach( (field, field_index) => {
				let key = thenOrElse + "_action_" + index + "_" + field.field + "_field";
				switch (field.field) {
					case "integration_id":
						let integrationOptions = [{
							display: "Select an Integration",
							value: ""
						}];
						integrationOptions = integrationOptions.concat(this.integrations.filter((ig) => action.type.includes(ig.action_type)));
						let currentValue = thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value;
						fields.push(
							<div key={key} className={classes.integrationWrapper}>
								<SelectInput
									disabled={this.props.disabled}
									label="Integration"
									priorState={currentValue == null ? integrationOptions[0].value : currentValue}
									emitChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
									options={integrationOptions}
								/>
							</div>
						);
						return;
					case "body_template":
						let value = thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value
						let actionTypeSendsPayload = action != null && action.type != null && ["http_request", "mqtt", "email", "sms", "tcp"].includes(action.type);
						let placeholder = actionTypeSendsPayload ? "(Entire report payload will be sent)" : "";
						fields.push(
								<div className={classes.scriptInput}>
									<div className={classes.scriptLabel}>
										{field.label}
									</div>
									<div className={classes.editorWrapper}>
										<AceEditor
											placeholder={placeholder}
											mode="text"
											theme="monokai"
											onChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
											fontSize={14}
											showPrintMargin={true}
											showGutter={true}
											padding={"12px"}
											highlightActiveLine={true}
											value={value ? value : ''}
											width={"100%"}
											setOptions={{
												enableBasicAutocompletion: false,
												enableLiveAutocompletion: false,
												enableSnippets: false,
												showLineNumbers: true,
												tabSize: 2,
											}}
										/>
									</div>
								</div>
								
							);
						return;
					case "body_type":
						fields.push(this.buildBodyType(thenOrElse, index, field_index, key));
						return;
					case "method":
						fields.push(this.buildMethod(thenOrElse, index, field_index, key));
						return;
					case "headers":
						fields.push(this.buildHeaders(thenOrElse, index, field_index, classes));
						return;
					case "password":
						fields.push(
							<div key={key} className={classes.textWrapper}>
								<TextInput
									disabled={this.props.disabled}
									label={field.label}
									name={key}
									type="password"
									margin="none"
									priorState={thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value}
									emitChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
								/>
							</div>
						);
						return;
					default:
						break;
				};
				if (field.field !== "integration_id") {
					fields.push(
						<div key={key} className={classes.textWrapper}>
							{this.buildGenericActionField(key, index, field, field_index, thenOrElse)}
						</div>
					);
				}
			});
			return fields.map( (field) => (
				field
			));
		} else {
			return "";
		}
	}

	buildGenericActionField = (key, index, field, field_index, thenOrElse) => {
		if (field.type === "enum") {
			let opts = field.allowed_values.map((allowed_value) => {
				let display_value = Humanize.humanizeSnakeCaseString(allowed_value);
				if (display_value === "" || display_value == null) {
					display_value = "None";
				}
				return { display: display_value, value: allowed_value}
			});
			let currentValue = thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value
			if (currentValue == null) {
				currentValue = opts[0].value;
			}
			return (
				<SelectInput
					id={key + index + field_index}
					disabled={this.props.disabled}
					key={key}
					emitChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
					priorState={currentValue}
					options={opts}
				/>
			);
		} else {
			return (
				<TextInput
					id={key + index + field_index}
					disabled={this.props.disabled}
					label={field.label}
					name={key}
					type="text"
					margin="none"
					priorState={thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value}
					emitChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
				/>
			);
		}
	}

	buildHeaders = (thenOrElse, action_index, field_index, classes) => {
		let header_action = thenOrElse === "then" ? this.thenActions[action_index].fields[field_index] : this.elseActions[action_index].fields[field_index];
		let headers = header_action.value;
		let key_section = thenOrElse + "_action_" + action_index + "_header_";
		return (
			<div key={"headers_" + key_section}>
				<div className={classes.headerLabelWrapper}>
					<div className={classes.headerLabel}>Request Headers</div>
					{header_action.error !== undefined && header_action.error !== false ? 
						<Tooltip className={classes.actionIcon + " " + classes.error} title={header_action.error}>
							<IconButton color="inherit">
								<ErrorIcon />
							</IconButton>
						</Tooltip>
					: ""}
				</div>
				{headers.map( (header, header_index) => {
					let key = key_section + header_index;
					return (
						<div key={key} className={classes.headersRow}>
							<div className={classes.textWrapper}>
								<TextInput
									disabled={this.props.disabled}
									className={classes.headerInput}
									label="Key"
									name={key}
									type="text"
									margin="none"
									priorState={headers[header_index].key}
									emitChange={(input) => this.actionHeadersChange(input, action_index, field_index, thenOrElse, header_index)}
								/>
							</div>
							<div className={classes.textWrapper}>
								<TextInput
									disabled={this.props.disabled}
									className={classes.headerInput}
									key={key + "_value"}
									label="Value"
									name={key}
									type="text"
									margin="none"
									priorState={headers[header_index].value}
									emitChange={(input) => this.actionHeadersChange(input, action_index, field_index, thenOrElse, header_index)}
								/>
							</div>
							{header_index > 0 && !this.props.disabled?
								<Tooltip className={classes.closeHeaderIcon} title="Remove Header">
									<IconButton root={{root: classes.closeHeaderIcon}} onClick={() => this.removeHeader(thenOrElse, action_index, field_index, header_index)} color="inherit">
										<CloseIcon />
									</IconButton>
								</Tooltip>
								: ''
							}
							
						</div>
					);
				})}
				{this.props.disabled ? "" : <Button className={classes.addHeaderButton} onClick={() => this.addHeader(action_index, field_index, thenOrElse)} variant="outlined" color="primary" >
					<AddIcon />
					Header
				</Button>}
			</div>
		);
	}

	actionHeadersChange = (input, action_index, field_index, thenOrElse, header_index) => {
		let header_action = thenOrElse === "then" ? this.thenActions[action_index].fields[field_index] : this.elseActions[action_index].fields[field_index];
		let headers = header_action.value;
		if (input.label === "Value") {
			headers[header_index].value = input.value;
		} else {
			headers[header_index].key = input.value;
		}
		let duplicate = headers.find( (header, index) => ((header.key === input.value) && (index !== header_index)));
		if (duplicate) {
			header_action.error = "Each header must have a unique key.";
		} else {
			header_action.error = false;
		}
		let empty = headers.find( (header, index) => header.key === '');
		if (empty) {
			header_action.error = header_action.error === false ? "Header keys cannot be empty.": header_action.error + " Header keys cannot be empty.";
		}
		let empty_value = headers.find( (header, index) => header.value === '');
		if (empty_value) {
			header_action.error = header_action.error === false ? "Header values cannot be empty.": header_action.error + " Header values cannot be empty.";
		}
		this.preserveData();
	}

	addHeader = (action_index, field_index, thenOrElse) => {
		let headers = thenOrElse === "then" ? this.thenActions[action_index].fields[field_index].value : this.elseActions[action_index].fields[field_index].value;
		headers.push({key: "", value: ""});
		this.preserveData();
	}

	removeHeader = (thenOrElse, action_index, field_index, header_index) => {
		let headers = thenOrElse === "then" ? this.thenActions[action_index].fields[field_index].value : this.elseActions[action_index].fields[field_index].value;
		headers.splice(header_index, 1);
		this.preserveData();
	}

	buildMethod = (thenOrElse, index, field_index, key) => {
		return (
			<SelectInput
				disabled={this.props.disabled}
				key={key}
				emitChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
				priorState={thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value}
				options={httpMethods}
			/>
		);
	}

	buildBodyType = (thenOrElse, index, field_index, key) => {
		return (
			<SelectInput
				disabled={this.props.disabled}
				key={key}
				emitChange={(input) => this.actionFieldsChange(input, index, field_index, thenOrElse)}
				priorState={thenOrElse === "then" ? this.thenActions[index].fields[field_index].value : this.elseActions[index].fields[field_index].value}
				options={bodyTypes}
			/>
		);
	}

	actionFieldsChange = (input, action_index, field_index, thenOrElse) => {
		let action_field_to_change = thenOrElse === "then" ? this.thenActions[action_index].fields[field_index] : this.elseActions[action_index].fields[field_index];
		action_field_to_change.value = input.value ? input.value : input;
		this.preserveData();
	}


	formatActionOptions = () => {
		if (this.actions === null) return [];
		return this.actions.map( (action) => {
			return { display: action.description, value: action.type };
		});
	}
}

RuleActions.contextType = SnackbarContext;
export default withStyles(styles)(RuleActions);
