import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	progress: {
		margin: "auto",
	},
	loading: {
		width: "100%",
		height: "100px",
		display: "flex",
		alignItems: "center",
	}
});

function Loading(props) {
	const { classes } = props;
	const override = {color: props.color || props.theme.palette.primary.main};
	return (
		<div className={classes.loading}>
			<CircularProgress classes={{root: override.color, colorPrimary: override.color}} color="inherit" className={classes.progress} />
		</div>
	);
}

Loading.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme()(Loading));
