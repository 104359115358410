import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class Report extends DataModel {
	constructor() {
		super("reports");
	}

	static getHeadings(){
		return [
			{label: "Created At", field: "created_at", align: "left", disablePadding: false, sortable: false, content: "date"},
			{label: "Device Unique ID", value: "table_unique_id", field: "table_unique_id", align: "left", disablePadding: false, sortable: false},
			{label: "Payload", value: "table_payload", field: "table_payload", align: "center", disablePadding: false, sortable: false},
			{label: "ACCOUNT", value: "nested_company.name", field: "name", align: "left", nested_field: true, disablePadding: false, sortable: true},
		];
	}

	static getHeartbeats(params) {
		params.heartbeat = true;
		return new Promise( (resolve, reject) => {
			new Report().listFromAPI(params).then( (result) => {
				let heartbeats = result.items;
				AssignNestedModels("companies", "company_id", heartbeats).then( () => {
					result.items = heartbeats;
					resolve(result);
				}).catch( (error) => {
					reject(error);
				});
			}).catch( (error) => {
				reject(error);
			});
		});
	}

	static getReports(params) {
		params.heartbeat = false;
		return new Promise( (resolve, reject) => {
			new Report().listFromAPI(params).then( (result) => {
				let reports = result.items;
				AssignNestedModels("companies", "company_id", reports).then( () => {
					result.items = reports;
					resolve(result);
				}).catch( (error) => {
					reject(error);
				});			}).catch( (error) => {
				reject(error);
			});
		});
	}
}

export default Report;
