import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { darken } from '@material-ui/core/styles/colorManipulator';
import PaginationContainer from '../Containers/PaginationContainer';
import ContentLoader from "react-content-loader"
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import TableList from '../Table/TableList';
import VerticalTabs from '../DisplayOriented/VerticalTabs';
import Loading from '../DisplayOriented/Loading';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Ingestor from '../../services/DataModels/Ingestor';
import Translator from '../../services/DataModels/Translator';
import Permissions from '../../services/Permissions';
import Auth from '../../services/Auth';
import { BULK_EDIT } from '../../services/CLURD';
import { GetAll } from '../../services/CLURDUtilities';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

//icons
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import EventIcon from '@material-ui/icons/Event';
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import MemoryIcon from '@material-ui/icons/Memory';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import HearingIcon from '@material-ui/icons/Hearing';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import UpdateIcon from '@material-ui/icons/Update';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EdgeIcon from '@material-ui/icons/DeveloperMode';
import CloudIcon from '@material-ui/icons/CloudUpload';

class IngestList extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            layout: "card",
            tab_index: 0,
            modal: {
                open: false,
                children: () => ""
            },
            menus: {},
            ingestor_ids: [],
            ingestors: [],
            translator_ids: [],
            translators: [],
            device_type_id: null,
            devices_shown: null
        };
        this.set_headings();
        this.set_tabs();
        this.check_params();
    }

    check_params = () => {
        const qs = require('qs');
        const parsed = qs.parse(this.props.location.search.split("?")[1]);
        if (parsed.ingestor_id != null) {
            new Ingestor({ _id: parsed.ingestor_id }, true).readFromAPI().then((result) => {
                this.props.tabHostProxy.addTab("ingestor", result);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        }
        this.props.history.replace({
            pathname: '/Ingestors',
            search: ''
        });
    }

    set_buttons = () => {
        let buttons = [];
        let accounts = Auth.currentUser().company_ids;
        let model = "Translator";
        if (this.props.model === "ingestors") {
            model = "Ingestor";
        }
        let valid_accounts = accounts.filter((account) => Permissions.allow(["create"], model.toLowerCase(), account._id));
        if (valid_accounts.length > 0) {
            buttons.push(
                { onSelectShow: false, display: `Create New ${model}`, label: `Create New ${model}`, icon: (<AddIcon />), action: this.create, function: this.create },
            );
        }
        return buttons;
    }

    set_tabs = () => {
        this.tabs = [
            { label: "Ingestors" },
            { label: "Translators" }
        ];
    }

    set_headings = () => {
        this.ingestor_headings = [
            { label: "Select", field: "select", align: "center", sortable: false },
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Account", field: "account_name", align: "left", sortable: false },
            { label: "Listener Type", field: "listener_display", align: "left", sortable: false },
            { label: "Handler Type", field: "handler_display", align: "left", sortable: false },
            { label: "Translator", field: "translator_display", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
        this.translator_headings = [
            { label: "Select", field: "select", align: "center", sortable: false },
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Account", field: "account_name", align: "left", sortable: false },
            { label: "Type", field: "type_display", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
    }

    handleChangePage = (event, page) => {
        this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
    };

    handleChangeRowsPerPage = event => {
        this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
    };

    create = () => {
        if (this.props.model === "ingestors") {
            this.props.tabHostProxy.addTab("ingestor");
        } else {
            this.props.tabHostProxy.addTab("translator");
        }
    }

    on_create = () => {
        this.close_modal();
        this.props.tabHostProxy.refresh();
    }

    close_modal = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    ingestor_table_select = (ingestor) => {
        this.setState((state) => {
            let index = state.ingestor_ids.indexOf(ingestor._id);
            if (index >= 0) {
                state.ingestors.splice(index, 1);
                state.ingestor_ids.splice(index, 1);
            } else {
                state.ingestors.push(ingestor);
                state.ingestor_ids.push(ingestor._id);
            }
            return state;
        });
    }

    translator_table_select = (translator) => {
        this.setState((state) => {
            let index = state.translator_ids.indexOf(translator._id);
            if (index >= 0) {
                state.translators.splice(index, 1);
                state.translator_ids.splice(index, 1);
            } else {
                state.translators.push(translator);
                state.translator_ids.push(translator._id);
            }
            return state;
        });
    }

    open_script_modal = (pkg) => {
        this.setState({
            modal: {
                open: true,
                children: (classes) =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Script for {pkg.name}
                        </div>
                        <div className={classes.script + " " + classes.scriptInModal}>
                            {pkg.script}
                        </div>
                    </div>
            }
        });
    }

    prepare_items = () => {
        const { items, model } = this.props;
        if (!items) return;
        if (model === "ingestors") {
            return this.prepare_ingestors();
        } else {
            return this.prepare_translators();
        }
    }

    prepare_ingestors = () => {
        let ingestors = this.props.items;
        const classes = this.props.classes;
        ingestors = Ingestor.prepareForTable(ingestors, this.render_menu, this.props.classes);
        ingestors.forEach((ingestor) => {
            let checked = this.state.ingestor_ids.includes(ingestor._id);
            const allowed = Permissions.allow(["delete"], "ingestor", ingestor.company_id);
            ingestor.select = (allowed ? <CustomCheckbox
                onChange={() => this.ingestor_table_select(ingestor)}
                value="selected"
                color="primary"
                key={ingestor._id}
                checked={checked}
            /> : "");
        });
        return ingestors;
    }

    prepare_translators = () => {
        let translators = this.props.items;
        const classes = this.props.classes;
        translators.forEach((translator) => {
            if (Boolean(translator.nested_company)) {
                translator.account_name = translator.nested_company.name;
            } else {
                translator.account_name = <span className={classes.inheritedInformation}>Inherited</span>;
            }
            if (Boolean(translator.type)) {
                if (Boolean(Translator.types(translator.type))) {
                    translator.type_display = Translator.types(translator.type).display;
                } else {
                    if (!Boolean(translator.error)) translator.error = "";
                    translator.error += " This translator does not have a supported type.";
                    translator.type_display = (<span className={classes.unset}>Unsupported Type</span>);
                }
            } else {
                translator.type_display = (<span className={classes.unset}>Unset</span>);
            }
            translator.action = (<span className={classes.tableMenu}>{this.render_menu(translator, true)}</span>);
            let checked = this.state.translator_ids.includes(translator._id);
            const allowed = Permissions.allow(["delete"], "translator", translator.company_id);
            translator.select = (allowed ? <CustomCheckbox
                onChange={() => this.translator_table_select(translator)}
                value="selected"
                color="primary"
                key={translator._id}
                checked={checked}
            /> : "");
        });
        return translators;
    }

    render_select_wrapper = () => {
        const { classes } = this.props;
        return this.props.model === "ingestors" ? this.render_ingestor_select() : this.render_translator_select();
    }

    render_translator_select = () => {
        const { classes } = this.props;
        const { translators, translator_ids } = this.state;
        if (!translator_ids || translator_ids.length === 0) {
            return "";
        }
        let selection_message = translator_ids.length + " Translator" + (translator_ids.length > 1 ? 's' : '') + " Selected.";
        return (
            <div className={classes.selectWrapper}>
                <div className={classes.selectionCount}>
                    {selection_message}
                    {/* <div onClick={() =>( all_selected === false || (all_selected === true && selections.length > 0)) ? this.selectAllFunction() : ""} className={classes.secondaryButton + " " + ( (all_selected === true && selections.length === 0) ? classes.selection : "")}>
						Select All
					</div>
					<div onClick={() => selections.length > 0 || all_selected === true ? this.clearSelections() : ""} className={classes.secondaryButton + " " + (selections.length === 0 && all_selected === false ? classes.selection : "")}>
						Deselect All
					</div> */}
                </div>
                <div className={classes.selectButtonsWrapper}>
                    <div className={classes.bulkButtonsWrapper}>
                        {Permissions.allow(["delete"], "translator") ? <Button
                            className={classes.deleteBorderButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.delete_translators()}
                        >
                            <DeleteForeverIcon className={classes.bulkIcon} />
                            DELETE
                        </Button> : ""}
                    </div>
                </div>
            </div>
        );
    }

    render_ingestor_select = () => {
        const { classes } = this.props;
        const { ingestors, ingestor_ids } = this.state;
        if (!ingestor_ids || ingestor_ids.length === 0) {
            return "";
        }
        let selection_message = ingestor_ids.length + " Ingestor" + (ingestor_ids.length > 1 ? 's' : '') + " Selected.";
        return (
            <div className={classes.selectWrapper}>
                <div className={classes.selectionCount}>
                    {selection_message}
                    {/* <div onClick={() =>( all_selected === false || (all_selected === true && selections.length > 0)) ? this.selectAllFunction() : ""} className={classes.secondaryButton + " " + ( (all_selected === true && selections.length === 0) ? classes.selection : "")}>
						Select All
					</div>
					<div onClick={() => selections.length > 0 || all_selected === true ? this.clearSelections() : ""} className={classes.secondaryButton + " " + (selections.length === 0 && all_selected === false ? classes.selection : "")}>
						Deselect All
					</div> */}
                </div>
                <div className={classes.selectButtonsWrapper}>
                    <div className={classes.bulkButtonsWrapper}>
                        {Permissions.allow(["delete"], "ingestor") ? <Button
                            className={classes.deleteBorderButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.delete_ingestors()}
                        >
                            <DeleteForeverIcon className={classes.bulkIcon} />
                            DELETE
                        </Button> : ""}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { page_data, modelChange, model, classes } = this.props;
        let items = this.prepare_items();
        let selected_shown = this.state.ingestors && this.state.ingestors.length > 0 ? classes.moreSpace : "";
        let buttons = this.set_buttons();
        return (
            <div className={classes.container}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                <PaginationContainer
                    buttons={buttons}
                    count={page_data.total}
                    rowsPerPage={page_data.per_page}
                    currentPage={page_data.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    toggleView={layout =>
                        this.setState({ layout: layout })
                    }
                    toggle_filter={this.props.show_filters}
                />
                {this.render_select_wrapper()}
                <div className={classes.sideBarContainer}>
                    <VerticalTabs tabs={this.tabs} onChange={this.model_change} />
                </div>
                {this.state.layout === "card" ?
                    <div className={classes.cardItemsContainer + " " + selected_shown}>
                        {this.render_card_layout(items)}
                    </div> :
                    <div className={classes.tableItemsContainer + " " + selected_shown}>
                        {this.render_table_layout(items)}
                    </div>
                }
            </div>
        );
    }

    model_change = (change_data) => {
        this.setState((state) => {
            state.ingestor_ids = [];
            state.ingestors = [];
            state.translator_ids = [];
            state.translators = [];
            return state;
        })
        this.props.modelChange(change_data);
    }

    filters_change = () => {

    }

    edit_translator = (event, item) => {
        if (item.error) {
            event.stopPropagation();
            return;
        }
        this.props.tabHostProxy.addTab("translator", item);
    }

    edit_ingestor = (event, item) => {
        if (item.error) {
            event.stopPropagation();
            return;
        }
        this.props.tabHostProxy.addTab("ingestor", item);
    }

    delete_ingestors = () => {
        const classes = this.props.classes;
        this.setState({
            modal: {
                open: true,
                yesFunction: () => this.perform_ingestor_deletes(),
                functionText: "Delete Ingestors",
                prompt: "Are you sure you want to delete these ingestors?",
                children: () => { }
            }
        });
    }

    delete_translators = () => {
        const classes = this.props.classes;
        this.setState({
            modal: {
                open: true,
                yesFunction: () => this.perform_translator_deletes(),
                functionText: "Delete Translators",
                prompt: "Are you sure you want to delete these translators?",
                children: () => { }
            }
        });
    }

    delete = (item) => {
        const classes = this.props.classes;
        let model = "translator";
        let data_model = new Translator(item);
        if (this.props.model === "ingestors") {
            model = "ingestor";
            data_model = new Ingestor(item);
        }
        this.setState({
            modal: {
                open: true,
                yesFunction: () => this.perform_delete(model, data_model),
                functionText: `Delete ${model}`,
                prompt: `Are you sure you want to delete this ${model}?`,
                children: () => { }
            }
        });
    }

    perform_delete = (model_name, data_model) => {
        this.setState({
            modal: {
                open: true,
                children: (classes) => (
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle + " " + classes.titleWithLoading}>
                            Deleting this {model_name}...
                        </div>
                        <Loading />
                    </div>
                )
            }
        });
        data_model.deleteFromAPI().then((result) => {
            this.close_modal();
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar("Deleted successfully.", "success");
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        });
    }

    perform_ingestor_deletes = () => {
        this.setState({
            modal: {
                open: true,
                children: (classes) => (
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle + " " + classes.titleWithLoading}>
                            Deleting these Ingestors...
                        </div>
                        <Loading />
                    </div>
                )
            }
        });
        let body = { ids: this.state.ingestor_ids };
        BULK_EDIT('ingestors', "delete", body, Auth.token()).then((result) => {
            let message = `The bulk delete process has successfully begun.`;
            this.context.openSnackbar(message, 'success');
            this.props.tabHostProxy.refresh();
            this.clear_selections();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.clear_selections();
            this.close_modal();
        });
    }

    perform_translator_deletes = () => {
        this.setState({
            modal: {
                open: true,
                children: (classes) => (
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle + " " + classes.titleWithLoading}>
                            Deleting these Translators...
                        </div>
                        <Loading />
                    </div>
                )
            }
        });
        let body = { ids: this.state.translator_ids };
        BULK_EDIT('translators', "delete", body, Auth.token()).then((result) => {
            let message = `The bulk delete process has successfully begun.`;
            this.context.openSnackbar(message, 'success');
            this.props.tabHostProxy.refresh();
            this.clear_selections();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.clear_selections();
            this.close_modal();
        });
    }

    clear_selections = () => {
        this.setState((state) => {
            state.ingestors = [];
            state.ingestor_ids = [];
            state.translators = [];
            state.translator_ids = [];
            return state;
        });
    }

    render_error = (ingestor) => {
        const classes = this.props.classes;
        if (!ingestor.error) return "";
        return (
            <Tooltip classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>{ingestor.error}</span>}>
                <ErrorIcon className={classes.errorIcon} />
            </Tooltip>
        );
    }

    render_ingestor_card = (ingestor) => {
        const { classes } = this.props;
        const has_translator = Boolean(ingestor.nested_translator);
        const can_edit_translator = has_translator && this.can_edit(ingestor.nested_translator, "translator");
        let renderTypeIcon = () => {
            if (ingestor.type === "cloud") {
                return (
                    <Tooltip title="Cloud Ingestor">
                        <CloudIcon className={classes.iconLabel} />
                    </Tooltip>
                );
            } else if (ingestor.type === "edge") {
                return (
                    <Tooltip title="Edge Ingestor">
                        <EdgeIcon className={classes.iconLabel} />
                    </Tooltip>
                );
            } else {
                return <React.Fragment />
            }
        }
        let selected = this.state.ingestor_ids.includes(ingestor._id) ? classes.selected : "";

        return (
            <div key={ingestor._id} className={classes.cardContainer}>
                <Card className={classes.card + " " + selected}>
                    <div className={classes.optionsIconsContainer}>
                        <div className={classes.leftOptionsContainer}>
                            <div className={classes.selectContainer}>{ingestor.select}</div>
                        </div>
                        <div className={classes.rightOptionsContainer}>
                            <Tooltip onClick={(event) => this.can_edit(ingestor, "ingestor") && this.edit_ingestor(event, ingestor)} classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>{this.can_edit(ingestor, "ingestor") ? "Edit Ingestor" : "Insufficient permissions for editing"}</span>}>
                                <span className={classes.optionsIcon + " " + (this.can_edit(ingestor, "ingestor") && !Boolean(ingestor.error) ? "" : classes.tipDisabled)}>
                                    <EditIcon />
                                </span>
                            </Tooltip>
                            <Tooltip onClick={(event) => this.can_delete(ingestor, "ingestor") && this.delete(ingestor)} classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>{this.can_delete(ingestor, "ingestor") ? "Delete Ingestor" : "Insufficient permissions for deleting"}</span>}>
                                <span className={classes.optionsIcon + " " + (this.can_delete(ingestor, "ingestor") ? "" : classes.tipDisabled)}>
                                    <DeleteIcon />
                                </span>
                            </Tooltip>
                            <Tooltip onClick={(event) => has_translator && this.edit_translator(event, ingestor.nested_translator)} classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>{has_translator ? "View/Edit Translator" : "No Translator Defined"}</span>}>
                                <span className={classes.optionsIcon + " " + ((has_translator && can_edit_translator) ? "" : classes.tipDisabled)}>
                                    <SwapHorizIcon />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div onClick={(event) => { this.edit_ingestor(event, ingestor) }} className={classes.cardLabelContainer}>
                        <div className={classes.topRow}>
                            <span onClick={(event) => { this.edit_ingestor(event, ingestor) }} className={classes.name}>
                                {ingestor.name}
                            </span>
                            {this.render_error(ingestor)}
                        </div>
                        <div className={classes.cardLabel}>
                            <Tooltip title="Listener Type">
                                <HearingIcon className={classes.iconLabel} />
                            </Tooltip>
                            <span className={classes.cardText}>{ingestor.listener_display}</span>
                        </div>
                        <div className={classes.cardLabel}>
                            <Tooltip title="Account">
                                <AccountCircleIcon className={classes.iconLabel} />
                            </Tooltip>
                            <span className={classes.cardText}>{ingestor.account_name}</span>
                        </div>
                        <div className={classes.cardLabel}>
                            <Tooltip title="Handler Type">
                                <PlaylistAddCheckIcon className={classes.iconLabel} />
                            </Tooltip>
                            <span className={classes.cardText}>{ingestor.handler_display}</span>
                        </div>
                        <div className={classes.cardLabel}>
                            <Tooltip title="Translator">
                                <SwapHorizIcon className={classes.iconLabel} />
                            </Tooltip>
                            <span className={classes.cardText}>{ingestor.translator_display}</span>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    render_translator_card = (translator) => {
        const { classes } = this.props;
        let selected = this.state.translator_ids.includes(translator._id) ? classes.selected : "";
        return (
            <div key={translator._id} className={classes.cardContainer}>
                <Card className={classes.card + " " + classes.translatorCard + " " + selected}>
                    <div className={classes.optionsIconsContainer}>
                        <div className={classes.leftOptionsContainer}>
                            <div className={classes.selectContainer}>{translator.select}</div>
                        </div>
                        <div className={classes.rightOptionsContainer}>
                            <Tooltip onClick={(event) => this.can_edit(translator, "translator") && this.edit_translator(event, translator)} classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>{this.can_edit(translator, "translator") ? "Edit Translator" : "Insufficient permissions for editing"}</span>}>
                                <span className={classes.optionsIcon + " " + (this.can_edit(translator, "translator") && !Boolean(translator.error) ? "" : classes.tipDisabled)}>
                                    <EditIcon />
                                </span>
                            </Tooltip>
                            <Tooltip onClick={(event) => this.can_delete(translator, "translator") && this.delete(translator)} classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>{this.can_delete(translator, "translator") ? "Delete Translator" : "Insufficient permissions for deleting"}</span>}>
                                <span className={classes.optionsIcon + " " + (this.can_delete(translator, "translator") ? "" : classes.tipDisabled)}>
                                    <DeleteIcon />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.cardLabelContainerTranslator}>
                        <div className={classes.topRow}>
                            <span className={classes.name}>
                                {translator.name}
                            </span>
                            {this.render_error(translator)}
                        </div>
                        <div className={classes.cardLabel}>
                            <Tooltip title="Account">
                                <AccountCircleIcon className={classes.iconLabel} />
                            </Tooltip>
                            <span className={classes.cardText}>{translator.account_name}</span>
                        </div>
                        <div className={classes.cardLabel}>
                            <Tooltip title="Translator Type">
                                <SwapHorizIcon className={classes.iconLabel} />
                            </Tooltip>
                            <span className={classes.cardText}>{translator.type_display}</span>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    render_menu = (item, table) => {
        let anchorEl = this.state.menus[item._id];
        let is_ingestors = this.props.model === "ingestors" ? true : false;
        let open = Boolean(anchorEl);
        let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, item._id)} /> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, item._id)} />;
        return (
            <React.Fragment>
                {icon}
                {is_ingestors ? this.render_ingestors_action_menu(open, anchorEl, item) : this.render_translators_action_menu(open, anchorEl, item)}
            </React.Fragment>
        );
    }

    open_action_menu = (event, id) => {
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }

    can_edit = (item, model) => {
        return Permissions.allow(["update"], model, item.company_id);
    }

    can_delete = (item, model) => {
        return Permissions.allow(["delete"], model, item.company_id);
    }

    can_dry_run = (translator) => {
        return true;
    }

    close_action_menu = (id) => {
        this.setState((state) => {
            state.menus[id] = null;
            return state;
        });
    };

    render_translators_action_menu = (open, anchorEl, translator) => {
        const { classes } = this.props;
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={() => this.close_action_menu(translator._id)}
                onClose={() => this.close_action_menu(translator._id)}
                PaperProps={{
                    style: { overflow: "visible" }
                }}
            >
                <div className={classes.actionListTitle}>
                    Perform Action...
                </div>
                <div className={classes.noOutline} onClick={(event) => this.can_edit(translator, "translator") && this.edit_translator(event, translator)}>
                    <MenuItem disabled={!this.can_edit(translator, "translator")} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Edit Translator
                        </Typography>
                    </MenuItem>
                </div>
                <div className={classes.noOutline} onClick={(event) => this.can_delete(translator, "translator") && this.delete(translator)}>
                    <MenuItem disabled={!this.can_delete(translator, "translator")} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Delete Translator
                        </Typography>
                    </MenuItem>
                </div>
                {/* <div className={classes.noOutline} onClick={() => this.can_dry_run(translator) && this.dry_run(translator)}>
						<MenuItem disabled={!this.can_dry_run(translator)} className={classes.actionMenuItem}>
							<ListItemIcon>
								<NetworkCheckIcon />
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								Issue Dry Run
							</Typography>
						</MenuItem>
					</div> */}
            </Menu>
        );
    }

    render_ingestors_action_menu = (open, anchorEl, ingestor) => {
        const { classes } = this.props;
        const has_translator = Boolean(ingestor.nested_translator);
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={() => this.close_action_menu(ingestor._id)}
                onClose={() => this.close_action_menu(ingestor._id)}
                PaperProps={{
                    style: { overflow: "visible" }
                }}
            >
                <div className={classes.actionListTitle}>
                    Perform Action...
                </div>
                <div className={classes.noOutline} onClick={(event) => this.can_edit(ingestor, "ingestor") && this.edit_ingestor(event, ingestor)}>
                    <MenuItem disabled={!this.can_edit(ingestor, "ingestor") || Boolean(ingestor.error)} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Edit Ingestor
                        </Typography>
                    </MenuItem>
                </div>
                <div className={classes.noOutline} onClick={(event) => this.can_delete(ingestor, "ingestor") && this.delete(ingestor)}>
                    <MenuItem disabled={!this.can_delete(ingestor, "ingestor")} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Delete Ingestor
                        </Typography>
                    </MenuItem>
                </div>
                <div className={classes.noOutline} onClick={() => has_translator && this.open_script_modal(ingestor)}>
                    <MenuItem disabled={!has_translator} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <SwapHorizIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            {has_translator ? "View Translator" : "No Translator Defined"}
                        </Typography>
                    </MenuItem>
                </div>
            </Menu>
        );
    }

    render_card_layout = (items) => {
        const { model } = this.props;
        return (
            <React.Fragment>
                {items ? items.map((item, index) => (
                    model === "ingestors" ? this.render_ingestor_card(item) : this.render_translator_card(item)
                )) : this.render_placeholder_cards()}
            </React.Fragment>
        );
    }

    render_placeholder_cards = () => {
        const classes = this.props.classes;
        let placeholders = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        let translator_class = this.props.model === "ingestors" ? "" : classes.translatorCard;
        return (
            <React.Fragment>
                {placeholders.map((item, index) => (
                    <div key={"placeholder_" + index} className={classes.cardContainer}>
                        <Card className={classes.card + " " + translator_class}>
                            <div className={classes.contentLoaderContainer}>
                                <ContentLoader
                                    width={200}
                                    speed={2}
                                    interval={.25}
                                    primaryColor="#f3f3f3"
                                    secondaryColor="#ecebeb"
                                >
                                    <rect x="0" y="6" rx="4" ry="4" width={200} height="18" />
                                    <rect x="12" y="33" rx="4" ry="4" width={200} height="16" />
                                    <rect x="12" y="63" rx="4" ry="4" width={200} height="16" />
                                    {this.props.model === "ingestors" ? <rect x="12" y="92" rx="4" ry="4" width={200} height="16" /> : ""}
                                </ContentLoader>
                            </div>
                        </Card>
                    </div>
                ))}
            </React.Fragment>
        )
    }

    render_table_layout = (items) => {
        const { model } = this.props;
        let headings = null;
        if (model === "ingestors") {
            headings = this.ingestor_headings;
        } else {
            headings = this.translator_headings;
        }
        return (
            <TableList
                headings={headings}
                items={items}
                noCheckBox
            />
        );
    }
}

IngestList.contextType = SnackbarContext;

const CustomCheckbox = withStyles({
    root: {
        padding: 0,
        color: "rgba(0, 0, 0, 0.24)",
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

const styles = (theme) => {
    return ({
        container: {
            fontFamily: "Inter",
            flexGrow: 2,
            display: "flex",
            flexWrap: "wrap",
            overflow: "hidden"
        },
        actionListTitle: {
            marginBottom: "8px",
            padding: "11px 16px",
            borderBottom: "solid #80808073 1px",
            backgroundColor: "white",
            cursor: "unset",
            '&:hover': {
                backgroundColor: "white",
                cursor: "unset",
            },
            outline: "none",
            fontFamily: "Inter",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "1rem",
            width: "auto",
            height: "24px",
            whiteSpace: "nowrap",
            boxSizing: "content-box",
            fontWeight: 400,
            lineHeight: "1.5em"
        },
        actionMenuItem: {
            outline: "none",
        },
        noOutline: {
            outline: "none",
            "&:focus": {
                outline: "none"
            }
        },
        modalWrapper: {
            padding: "24px",
            fontFamily: "Inter",
            width: "66vw",
            boxSizing: "border-box"
        },
        tableMenu: {
            color: "grey",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.pending.main
            }
        },
        modalTitle: {
            fontSize: "24px"
        },
        tableListWrapper: {
            margin: "24px 0 32px 0"
        },
        titleWithLoading: {
            marginBottom: "12px"
        },
        noDevices: {
            marginTop: "18px"
        },
        itemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 58px)",
            padding: "12px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "85%",
            backgroundColor: "#f5f5f7",
            boxSizing: "border-box",
        },
        cardItemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 58px)",
            padding: "12px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "85%",
            backgroundColor: "#f5f5f7",
            boxSizing: "border-box",
        },
        tableItemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 58px)",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "85%",
            backgroundColor: "#ffffff",
            boxSizing: "border-box",
        },
        moreSpace: {
            height: "calc(100% - 112px)"
        },
        sideBarContainer: {
            display: "flex",
            width: "15%",
            boxSizing: "border-box",
            backgroundColor: "white",
            zIndex: 5,
            borderRight: "solid lightgrey 1px",
        },
        cardContainer: {
            width: "33.33%",
            display: "inline-flex",
            position: "relative",
            justifyContent: "center",
        },
        selectCardWrapper: {
            marginLeft: "12px"
        },
        placeholder: {
            opacity: .8,
            zIndex: 10,
            position: "absolute"
        },
        card: {
            boxShadow: "0px 3px 7px 2px #00000017",
            backgroundColor: "#fafafa",
            margin: "12px",
            width: "100%",
            minWidth: "250px",
            maxWidth: "380px",
            border: "#ffffff solid 1px",
            transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
            "&:hover": {
                border: `solid ${theme.palette.pending.main} 1px`,
            }
        },
        translatorCard: {
            height: "173px",
        },
        selected: {
            border: `solid ${theme.palette.pending.main} 1px`,
        },
        checkbox: {
            marginLeft: "auto",
            marginRight: "6px",
        },
        link: {
            color: theme.palette.pending.main,
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        deviceLink: {
            marginLeft: "4px"
        },
        topRow: {
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
        },
        name: {
            width: "80%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "16px",
        },
        fileCount: {
            width: "33.33%",
            display: "flex",
            alignItems: "center"
        },
        fileCountText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        iconLabel: {
            marginRight: "8px",
            fontSize: "20px"
        },
        attachmentIcon: {
            marginLeft: "auto"
        },
        contentLoaderContainer: {
            height: "172px"
        },
        cardIconRow: {
            margin: "-12px",
            padding: "12px 0",
            marginTop: "4px",
            display: "flex",
            color: "grey",
            borderTop: "solid lightgrey 1px",
            backgroundColor: "#FAFAFA"
        },
        cardIconButton: {
            width: "25%",
            textAlign: "center",
            borderRight: "solid lightgrey 1px",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.pending.main
            }
        },
        translatorCardIconButton: {
            width: "33.33%",
            textAlign: "center",
            borderRight: "solid lightgrey 1px",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.pending.main
            }
        },
        updateIconButton: {
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            borderRight: "unset"
        },
        viewDevices: {
            marginLeft: "4px",
        },
        tip: {
            fontSize: "16px",
        },
        cardTextLabel: {
            color: "black"
        },
        tipOverride: {
            padding: "8px",
            boxSizing: "border-box",
            marginTop: "0px",
        },
        tipDisabled: {
            cursor: "not-allowed",
            color: "#bababa",
            "&:hover": {
                color: "#bababa"
            }
        },
        nameContainer: {
            display: "flex",
            alignItems: "center",
        },
        deviceName: {
            whiteSpace: "nowrap"
        },
        statusDot: {
            width: "8px",
            height: "8px",
            minWidth: "8px",
            minHeight: "8px",
            maxWidth: "8px",
            maxHeight: "8px",
            borderRadius: "50%",
            display: "inline-flex",
            marginRight: "4px"
        },
        menuIcon: {
            border: "none",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.pending.main
            }
        },
        cardLabelContainer: {
            position: "relative",
            padding: "16px 24px 24px 24px",
            cursor: "pointer",
        },
        cardLabelContainerTranslator: {
            position: "relative",
            padding: "16px 24px 24px 24px",
        },
        cardLabel: {
            display: "flex",
            alignItems: "center",
            color: "#636366",
            marginBottom: "8px",
        },
        cardText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px",
        },
        errorIcon: {
            color: theme.palette.red.main,
        },
        script: {
            backgroundColor: "#0f0f0fd4",
            color: "white",
            padding: "4px",
            borderRadius: "4px",
            fontFamily: "Courier New"
        },
        scriptInModal: {
            margin: "12px",
            overflow: "auto",
            padding: "12px"
        },
        deleteButton: {
            color: theme.palette.red.main,
            '&:hover': {
                color: darken(theme.palette.red.main, .2),
            },
            cursor: "pointer"
        },
        notFound: {
            color: theme.palette.red.main
        },
        inheritedInformation: {
            fontStyle: "italic"
        },
        selectWrapper: {
            display: "flex",
            width: "100%",
            padding: "12px",
            paddingLeft: "15%",
            boxSizing: "border-box",
            alignItems: "center",
            backgroundColor: "white",
            position: "relative",
            zIndex: 5,
            marginTop: "-4px",
            borderBottom: "solid lightgrey 1px",
        },
        selectionCount: {
            fontSize: "16px",
        },
        selectButtonsWrapper: {
            position: "relative",
            marginLeft: "auto",
            marginRight: 0,
            display: "flex"
        },
        bulkButtonsWrapper: {
            marginRight: "8px"
        },
        deleteBorderButton: {
            marginLeft: "12px",
        },
        issueButton: {
            marginLeft: "12px",
            borderColor: theme.palette.pending.main,
            color: theme.palette.pending.main
        },
        bulkIcon: {
            marginRight: "4px"
        },
        unset: {
            fontStyle: "italic",
            paddingRight: "2px"
        },
        optionsIconsContainer: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "36px",
            backgroundColor: "#ffffff",
            padding: "8px",
            boxSizing: "border-box",
        },
        leftOptionsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "25%",
        },
        rightOptionsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "75%",
        },
        optionsIcon: {
            marginLeft: "16px",
            color: "#8e8e93",
            width: "20px",
            height: "20px",
            "& svg": {
                fontSize: "20px",
            },
            cursor: "pointer",
            "&:hover": {
                color: "#0263fc",
            },
            transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        },
        selectContainer: {
            "& svg": {
                width: "20px",
                height: "20px",
            },
        },
    })
};

export default compose(
    withRouter,
    withStyles(styles),
    withTheme(),
)(IngestList);
