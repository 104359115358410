import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class Notification extends DataModel {
	constructor() {
		super('notifications')
	}

	static loadRequirements(alerts) {
		return new Promise( (resolve) => {
			Promise.all([AssignNestedModels("devices", "device_id", alerts), AssignNestedModels("rules", "rule_id", alerts)]).then( () => {
				resolve(alerts);
			});
		});
	}
}

export default Notification;
