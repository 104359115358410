import React from 'react';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import CreateIngestorForm from '../IngestSpecific/CreateIngestorForm';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';
import Ingestor from '../../services/DataModels/Ingestor';
import Device from '../../services/DataModels/Device';
import { GetAll } from '../../services/CLURDUtilities';

//mui
import { darken } from '@material-ui/core/styles/colorManipulator';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

//icons
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

//inputs
import LookupInput from '../Inputs/LookupInput';
import { ADD_RELATIONS, GET_RELATIONS, REMOVE_RELATION } from '../../services/Relation';

class DeviceIngestors extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			ingestors: null,
			menus: {},
			modal: {
				open: false,
				children: () => "",
			},
			show_errors: false
		};
		this.load_ingestors();
	}

	load_ingestors = () => {
		GET_RELATIONS("devices", this.props.device._id, "ingestors").then( (ingestors) => {
			Ingestor.loadRequirements(ingestors).then( () => {
				this.setState( (state) => {
					state.ingestors = ingestors;
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
			});
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	render_menu = (ingestor, table) => {
		let anchorEl =  this.state.menus[ingestor._id];
		let open = Boolean(anchorEl);
		let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, ingestor._id)}/> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, ingestor._id)}/>;
		return (
			<React.Fragment>
				{icon}
				{this.render_action_menu(open, anchorEl, ingestor)}
			</React.Fragment>
		);
	}

	close_action_menu = (id) => {
		this.setState( (state) => {
			state.menus[id] = null;
			return state;
		});
	}

	render_action_menu = (open, anchorEl, ingestor) => {
		const { classes } = this.props;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={() => this.close_action_menu(ingestor._id)}
				onClose={() => this.close_action_menu(ingestor._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit(ingestor) && this.edit(event, ingestor)}>
					<MenuItem disabled={!this.can_edit(ingestor) || Boolean(ingestor.error)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							View/Edit Ingestor
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit_device() && this.remove_ingestor(ingestor)}>
					<MenuItem disabled={!this.can_edit_device()} className={classes.actionMenuItem}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Remove Ingestor
						</Typography>
					</MenuItem>
				</div>
			</Menu>
		);
	}
	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	remove_ingestor = (ingestor) => {
		this.setState({
			modal: {
				open: true,
				prompt: `Are you sure you want to remove this ingestor? It will not delete the ingestor.`,
				yesFunction: () => this.submit_remove_ingestor(ingestor._id),
				functionText: "Remove",
				children: () => {}
			}
		});
	}

	submit_remove_ingestor = (ingestor_id) => {
		this.close_modal();
		REMOVE_RELATION("devices", this.props.device._id, "ingestors", ingestor_id).then( (result) => {
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("device", this.props.device);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar('Ingestor successfully removed.', 'success');
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	on_save = () => {
		this.load_ingestors();
		this.close_modal();
	}

	edit = (event, ingestor) => {
		const edit_flow = 
			<CreateIngestorForm 
				onCreate={this.on_save}
				onCancel={this.close_modal}
				ingestor={ingestor}
			/>;
		this.setState({
			modal: {
				open: true,
				children: (classes) => 
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle}>
							Editing {ingestor.name}
						</div>
						<div className={classes.editFormContainer}>
							{edit_flow}
						</div>
					</div>
			}
		});
	}

	can_edit = (ingestor) => {
		return Permissions.allow(["update"], "ingestor", ingestor.company_id);
	}

	can_edit_device = () => {
		return Permissions.allow(["update"], "device", this.props.device.company_id);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState( (state) => {
			state.menus[id] = element;
			return state;
		});
	}


	get_buttons = () => {

	}

	get_heading = () => {
		return [
			{label: "Name", value: "name", field: "name", align: "left", sortable: false},
			{label: "Listener Type", field: "listener_display", align: "left", sortable: false},
			{label: "Handler Type", field: "handler_display", align: "left", sortable: false},
			{label: "Translator", field: "translator_display", align: "left", sortable: false},
			{label: "Action", field: "action", align: "center", sortable: false}
		];
	}

	submit_add_ingestor = () => {
		this.setState({verifying: true});
		if (this.state.new_ingestor.values.length === 0) {
			this.setState({show_errors: true, verifying: false});
			return;
		}
		let ingestor_ids = this.state.new_ingestor.values.map( ({value}) => value);
		ADD_RELATIONS("devices", this.props.device._id, "ingestors", ingestor_ids).then( (result) => {
			this.close_modal();
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("device", this.props.device);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar("Ingestor(s) successfully added.", "success");
		}).catch( (error) => {
			this.close_modal();
			this.context.openSnackbar(error, "error");
		});
	}

	load_ingestor_suggestions = () => {
		return new Promise( (resolve, reject) => {
			let params = {};
			GET_RELATIONS("devices", this.props.device._id, "ingestors").then( (ingestors) => {
				if (ingestors.length > 0) {
					let ids = ingestors.map(function (ingestor) {
						return ingestor._id
					  });
					  params._id_nin = ids.join(",");
					}
				GetAll("ingestors", params).then( (ingestors) => {
					this.setState({new_ingestor: {values: [], suggestions: ingestors}}, resolve);
				}).catch( (error) => {
					reject();
				});
			}).catch( (error) => {
				reject();
			});
		});
	}

	add_ingestor = () => {
		const classes = this.props.classes;
		const update_new_ingestor = ({value}) => {
			this.setState( (state) => {
				state.new_ingestor.values = value;
				return state;
			});
		}
		this.load_ingestor_suggestions().then( () => {
			this.setState({
				modal: {
					open: true,
					children: () =>
						<div className={classes.modalWrapper}>
							<div className={classes.modalTitle}>
								Attach Ingestors to this Device
							</div>
							<div className={classes.newConnectionContainer}>
								{this.state.new_ingestor.suggestions.length === 0 ?
									<span>There are no ingestors available. Go to the Ingest page to create some.</span> :
									<LookupInput
										priorState={{values: this.state.new_ingestor.values, suggestions: this.state.new_ingestor.suggestions.map( (m) => ({label: m.name, value: m._id}))}}
										placeholder="Select Ingestors*"
										label="Ingestors"
										emitChange={update_new_ingestor}
										error={this.state.show_errors && this.state.new_ingestor.values.length === 0}
										error_message={"Please select at least one ingestor."}
									/>}
							</div>
							{this.render_modal_buttons(this.submit_add_ingestor)}
						</div>
				}
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_modal_buttons = (onClick) => {
		const classes = this.props.classes;
		return (
			<div className={classes.modalButtonContainer}>
				<Button onClick={this.close_modal} aria-label="cancel" color="primary">
					Cancel
				</Button>
				<Button
					onClick={onClick}
					disabled={this.state.verifying}
					className={classes.editButton}
					aria-label="create"
					variant="contained"
					color="primary"
					size="large"
				>
					Attach Ingestor
				</Button>
			</div>
		)
	}

	render() {
		const { classes } = this.props;
		const { ingestors, page_data, modal } = this.state;
		if (ingestors === null) return <Loading />;
		const buttons = this.get_buttons();
		const heading_info = this.get_heading();
		const ingestors_prepped = Ingestor.prepareForTable(ingestors, this.render_menu, this.props.classes);
		return (
			<Paper className={classes.container}>
				<React.Fragment>
					<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
						{modal.children(classes)}
					</SimpleModalWrapped>
					<div className={classes.contentContainer}>
						<div className={classes.addButtonContainer}>
							<Button
								onClick={this.add_ingestor}
								className={classes.titleButton}
								disabled={!this.can_edit_device()}
								variant="contained"
								color="primary"
							>
								<AddIcon className={classes.buttonIcon}/>
								Add Ingestor
							</Button>
						</div>
						<TableList
							headings={heading_info}
							items={ingestors_prepped}
							noCheckBox
							noBoxShadow
							perPage={5}
						/>
					</div>
				</React.Fragment>
			</Paper>
		);
	}
}
const styles = theme => ({
	actionListTitle: {
		marginBottom: "8px",
		padding: "11px 16px",
		borderBottom: "solid #80808073 1px",
		backgroundColor: "white",
		cursor: "unset",
		'&:hover': {
			backgroundColor: "white",
			cursor: "unset",
		},
		outline: "none",
		fontFamily: "Inter",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "1rem",
		width: "auto",
		height: "24px",
		whiteSpace: "nowrap",
		boxSizing: "content-box",
		fontWeight: 400,
		lineHeight: "1.5em"
	},
	noOutline: {
		outline: "none",
		"&:focus": {
			outline: "none"
		}
	},
	actionMenuItem: {
		outline: "none",
	},
	modalWrapper: {
		fontFamily: "Inter",
		minHeight: "450px",
		maxHeight: "643px",
		boxSizing: "border-box",
	},
	modalTitle: {
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	container: {
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
	},
	contentContainer: {
		overflowY: "auto",
		height: "calc(100% - 57px)",
		width: "100%",
	},
	tableMenu: {
		color: "grey",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.pending.main
		}
	},
	notFound: {
		color: theme.palette.red.main
	},
	unset: {
		fontStyle: "italic",
		paddingRight: "2px"
	},
	addButtonContainer: {
		textAlign: "right",
		borderBottom: "solid lightgrey 1px",
	},
	buttonIcon: {
		marginRight: "8px"
	},
	titleButton: {
		margin: "12px"
	},
	newConnectionContainer: {
		height: "320px",
	},
	modalButtonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "24px 0 0 auto"
	},
	editButton: {
		marginLeft: "8px",
	},
	editFormContainer: {
		maxHeight: "574px",
		height: "574px",
		overflowY: "auto",
		paddingTop: "5px",
		paddingRight: "16px",
	}
});

DeviceIngestors.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceIngestors));