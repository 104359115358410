import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TabsContainer from '../components/Containers/TabsContainer';

const styles = {
	container: {
		height: "calc(100vh - 112px)",
	}
};

class DiscoveredDevicesPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			tabs: []
		};
		this.initial = {title: "Discovered Devices", type: "discovered_devices", close: false}
	}

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.container}>
				<TabsContainer initial={this.initial} />
			</div>
		);
	}
}

export default withStyles(styles)(DiscoveredDevicesPage);
