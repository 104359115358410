import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CardContainer from '../Containers/CardContainer';

//icons
import FeedbackIcon from '@material-ui/icons/Feedback';

const styles = theme => ({
	container: {
		width: "100%",
		height: "calc(100% - 111px)",
		boxSizing: "border-box",
		margin: "12px",
		display: "flex",
		flexWrap: "wrap",
	},
	tile: {
		width: "100%",
		display: "flex",
	},
	tileMargin: {
		margin: "6px",
		width: "100%",
	},
	tileContent: {
		height: "calc(100% - 49px)",
		overflow: "scroll"
	},
	cardContainerRoot: {
		height: "100%",
	},
	constructionText: {
		fontSize: "18px",
		padding: "12px",
		textAlign: "center"
	},
	constructionIcon: {
		fontSize: "30px",
		width: "100%",
	},
	constructionContainer: {
		padding: "12px 0",
		color: "grey"
	}
});

class DeviceSoftware extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				<div className={classes.tile}>
					<div className={classes.tileMargin}>
						<CardContainer
							title="Software Packages"
							rootClass={classes.cardContainerRoot}
							infoText="Select a software package to install on this device."
						>
							<div className={classes.tileContent}>
								<div className={classes.constructionContainer}>
									<FeedbackIcon className={classes.constructionIcon} />
									<div className={classes.constructionText}>
										This portion of the DRM Portal is under construction. 
									</div>
								</div>
							</div>
						</CardContainer>
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(DeviceSoftware));
