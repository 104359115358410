import Auth from './Auth';
import Permissions from '../services/Permissions';

//todo add the others
const key_to_model = {
	"users": "user",
	"companies": "company",
	"roles": "user_type",
	"devices": "device",
	"integrations": "integration",
	"policies": "rule",
	"software": "device_type",
	"alerts": "notification",
	"configs": "device_config"
};

class PortalConfiguration {

	static navigationItemVisible(key) {
		let company = Auth.currentCompany();
		let visible = false;
		if (company && company.portal_configuration) {
			visible = company.portal_configuration.navigation[key + '_hidden'] === undefined ? true : company.portal_configuration.navigation[key + '_hidden'] === false ? true : false;
			if (visible) {
				if (key === "companies" || key === "roles") {
					//users must always have read on roles and companies, therefore if they have only read, do not show the roles and companies pages
					if (Permissions.only_read(key_to_model[key])) {
						visible = false;
					}
				} else if (key === "users") {
					if (!Permissions.allow("read", "user") || !Permissions.allow("read", "user_type") ) {
						visible = false;
					}
				} else if (key === "messages") {
					//if the role doesn't allow read on reports, device errors, and bulk responses, do not show the messages page. if any are readable, show page
					if (!Permissions.allow("read", "report") && !Permissions.allow("read", "device_error") && !Permissions.allow("read", "bulk_response") ) {
						visible = false;
					}
				} else if (key === "software") {
					//if the role doesn't allow read on software updates and device types, don't show software page
					if (!Permissions.allow("read", "software_update") || !Permissions.allow("read", "device_type")) {
						visible = false;
					}
				} else if (key === "rules") {
					//if the role doesn't allow read on rules and device types, don't show rules page
					if (!Permissions.allow("read", "rule") || !Permissions.allow("read", "device_type")) {
						visible = false;
					}
				} else if (key === "devices") {
					//if the role doesn't allow read on device and device types, don't show device page
					if (!Permissions.allow("read", "device") || !Permissions.allow("read", "device_type")) {
						visible = false;
					}
				} else if (key === "configs") {
					if (!Permissions.allow("read", "device_type") || !Permissions.allow("read", "device_config")) {
						visible = false;
					}
				} else if (key === "ha_groups") {
					if (!Permissions.allow("read", "device_ha_group") || !Permissions.allow("read", "device_config") || !Permissions.allow("read", "device_type")) {
						visible = false;
					}
				} else {
					if (key_to_model[key] !== undefined && !Permissions.allow("read", key_to_model[key])) {
						visible = false;
					}
				}
			}
		}
		return visible;
	}

	static widgetVisible(key) {
		let company = Auth.currentCompany();
		if (company) {
			if (company.portal_configuration) {
				return !company.portal_configuration.widgets[key + '_hidden'];
			}
		}
		// An optimistic strategy
		return true;
	}

}

export default PortalConfiguration;
