import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CardContainer from '../Containers/CardContainer';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import SwitchInput from '../Inputs/SwitchInput';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Device from '../../services/DataModels/Device';
import Tooltip from '@material-ui/core/Tooltip';

//icons
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import LookupInput from '../Inputs/LookupInput';
import Permissions from '../../services/Permissions';
import { ADD_RELATION, REMOVE_RELATION } from '../../services/Relation';


const styles = theme => ({
	container: {
		width: "100%",
		height: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
	},
	link: {
	},
	disabled: {
		color: "#8080808f",
		"&:hover": {
			textDecoration: "unset",
			cursor: "unset",
		}
	},
	rulesContainer: {
		padding: "6px 12px"
	},
	buttonIcon: {
		marginRight: "8px",
		display: "flex"
	},
	buttonLabel: {
		whiteSpace: "nowrap",
		alignItems: "center"
	},
	activeWrapper: {
		width: "50%",
		margin: "0 auto 0 0"
	},
	buttonWrapper: {
		display: "flex",
		justifyContent: "space-evenly",
		marginTop: "14px"
	},
	modalWrapper: {
		minHeight: "450px",
		maxHeight: "615px",
	},
	buttonRow: {
		marginTop: "32px",
		display:'flex',
		alignItems:'center',
		justifyContent:'flex-end'
	},
	attachButton :{
		marginLeft: "8px",
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px"
	},
	tileSubtitle: {
		paddingLeft: "12px",
		textTransform: "uppercase",
		color: "grey",
		display: "flex",
		alignItems: "center",
		height: "24px"
	},
	infoBlurb: {
		display: "flex",
		marginLeft: "12px",
		color: "grey"
	},
	ruleWrapper: {
		width: "50%",
		padding: "6px",
		display: "inline-block",
		boxSizing: " border-box",
	},
	rulePaper: {
		padding: "8px",
		position: "relative"
	},
	detachWrapper: {
	},
	descriptionLabel: {
		color: "grey",
		fontSize: "12px",
		textTransform: "uppercase",
		marginTop: ""
	},
	description: {
		color: "black",
		fontSize: "18px",
		textTransform: "unset"
	},
	tile: {
		width: "100%",
		display: "flex",
	},
	noMargin: {
		width: "100%",
	},
	tileContent: {
		padding: "12px 0 12px 0"
	},
	cardContainerRoot: {
		marginBottom: "12px",
		minHeight: "100%"
	},
	arrowPopper: {
		'&[x-placement*="bottom"] $arrowArrow': {
			top: 0,
			left: 0,
			marginTop: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '0 1em 1em 1em',
				borderColor: `transparent transparent ${theme.palette.grey[700]} transparent`,
			},
		},
		'&[x-placement*="top"] $arrowArrow': {
			bottom: 0,
			left: 0,
			marginBottom: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '1em 1em 0 1em',
				borderColor: `${theme.palette.grey[700]} transparent transparent transparent`,
			},
		},
		'&[x-placement*="right"] $arrowArrow': {
			left: 0,
			marginLeft: '-0.9em',
			height: '3em',
			width: '1em',
			'&::before': {
				borderWidth: '1em 1em 1em 0',
				borderColor: `transparent ${theme.palette.grey[700]} transparent transparent`,
			},
		},
		'&[x-placement*="left"] $arrowArrow': {
			right: 0,
			marginRight: '-0.9em',
			height: '3em',
			width: '1em',
			'&::before': {
				borderWidth: '1em 0 1em 1em',
				borderColor: `transparent transparent transparent ${theme.palette.grey[700]}`,
			},
		},
	},
	arrowArrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
	tooltip: {
		fontSize: "16px",
	},
	newConnectionContainer: {
		height: "312px",
	},
});

class DeviceOrchestration extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modal: {
				open: false,
			},
			arrowRef: null,
			lookup_suggestions: null,
			lookup_values: null,
			show_errors: false
		};
		this.props = props;
	}

	edit_rule = (rule) => {
		this.props.tabHostProxy.addTab("rule", rule);
	}

	detach_rule = (rule) => {
		this.setState({
			modal: {
				open: true,
				prompt: "Are you sure want to remove the policy from this device?",
				yesFunction: () => this.remove_rule(rule),
				functionText: "Remove Policy"
			}
		});
	}

	get_rule_ids = () => {
		let ids = [];
		ids = this.props.device.nested_rules.map(({_id}) => _id).concat(this.props.device.nested_type_rules.map(({_id}) => _id));
		ids = ids.join();
		return ids;
	}

	create_rule = () => {
		let preset = {device_ids: [this.props.device._id], nested_devices: [this.props.device]};
		this.props.tabHostProxy.addTab("rule", preset);
	}

	handle_lookup_change = (input) => {
		this.setState( (prev_state) => {
			return {lookup_values: input.value, lookup_suggestions: input.suggestions};
		});
	}

	submit_attached_rules = () => {
		if (this.state.lookup_values === null || this.state.lookup_values.length === 0) {
			if (this.state.show_errors === false) {
				this.setState({show_errors: true});
			}
			return;
		} else {
			let device_id = this.props.device._id;
			let rule_ids = this.state.lookup_values.map( ({value}) => value);
			let promises = rule_ids.map( (rule_id) => {
				return ADD_RELATION("devices", device_id, "rules", rule_id)
			});
			Promise.all(promises).then( (result) => {
				this.close_modal();
				this.props.tabHostProxy.closeSelf();
				this.props.tabHostProxy.addTab("device", this.props.device);
				this.props.tabHostProxy.refresh();
				this.context.openSnackbar('Device saved.', 'success'); 
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
	}

	attach_rule = () => {
		this.setState({
			modal: {
				open: true,
				yesFunction: null,
				children: () => (
					<div className={this.props.classes.modalWrapper}>
						<div className={this.props.classes.prompt}>
							Find and select policies to attach to this device.
						</div>
						<div className={this.props.classes.newConnectionContainer}>
							<LookupInput
								error_message={"Please select a policy."}
								error={this.state.show_errors && (this.state.lookup_values === null || this.state.lookup_values.length === 0)}
								params={{_id_nin: this.get_rule_ids()}}
								priorState={{values: this.state.lookup_values, suggestions: this.state.lookup_suggestions}}
								label="Policy"
								model="rules"
								emitChange={this.handle_lookup_change}
								valueField="_id"
								labelField="description"
							/>
						</div>
						<div className={this.props.classes.buttonRow}>
							<Button onClick={this.close_modal} color="primary">
								Cancel
							</Button>
							<Button
								onClick={this.submit_attached_rules}
								className={this.props.classes.attachButton}
								variant="contained"
								color="primary"
								size="large"
							>
								Attach Policies
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	handle_arrow_ref = node => {
		this.setState({
			arrowRef: node,
		});
	};

	remove_rule = (rule) => {
		REMOVE_RELATION("devices", this.props.device._id, "rules", rule._id).then( (result) => {
			this.close_modal();
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("device", this.props.device);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar('Policy removed.', 'success');
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}
	remove_ingestor = (ingestor) => {
		REMOVE_RELATION("devices", this.props.device._id, "ingestors", ingestor._id).then( (result) => {
			this.close_modal();
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("device", this.props.device);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar('Ingestor removed.', 'success');
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	close_modal = () => {
		let modal =  {
			open: false,
		};
		this.setState({modal: modal});
	}

	render_rule = (rule, type_rule) => {
		return (
			<div key={rule._id} className={this.props.classes.ruleWrapper}>
				<Paper className={this.props.classes.rulePaper}>
					<div className={this.props.classes.descriptionLabel}>
						POLICY DESCRIPTION
					</div>
					<div className={this.props.classes.description}>
						{rule.description}
					</div>
					<div className={this.props.classes.buttonWrapper}>
						{Permissions.allow(["read"], "rule", rule.company_id) ? <Button
							color="primary"
							onClick={() => this.edit_rule(rule)}
						>
							VIEW POLICY
						</Button> : ""}
						{Permissions.allow(["update"], "device", this.props.device.company_id) ? <Button
							color="primary"
							disabled={type_rule}
							onClick={() => !type_rule && this.detach_rule(rule)}
						>
							REMOVE FROM DEVICE
						</Button> : ""}
					</div>
				</Paper>
			</div>
		);
	}

	render_info = () => {
		return (
			<Tooltip
				title={
					<React.Fragment>
						Policies can be assigned to every device via device type and therefore cannot be removed per device.
						<span className={this.props.classes.arrowArrow} ref={this.handle_arrow_ref} />
					</React.Fragment>
				}
				classes={{ popper: this.props.classes.arrowPopper, tooltip: this.props.classes.tooltip }}
				PopperProps={{
					popperOptions: {
						modifiers: {
							arrow: {
								enabled: Boolean(this.state.arrowRef),
								element: this.state.arrowRef,
							},
						},
					},
				}}
			>
				<InfoIcon className={this.props.classes.infoBlurb}/>
			</Tooltip>	
		);
	}

	rule_text = () => {
		let device = this.props.device;
		if (Permissions.allow(["create"], "rule", device.company_id) && Permissions.allow(["update"], "device", device.company_id)) {
			return "Add and remove policies from this device, or create and attach new policies.";
		} else if (!Permissions.allow(["update"], "device", device.company_id)) {
			return "View the policies that are attached to this device.";
		} else if (!Permissions.allow(["create"], "rule", device.company_id)) {
			return "Add and remove policies from this device.";
		}
	}

	render() {
		const { device, classes } = this.props;
		let buttons = [];
		// if (Permissions.allow(["create"], "rule", device.company_id)) {
		// 	buttons.push({ display: "Create a New Policy", function: this.create_rule, icon: (<AddIcon />)});
		// }
		if (Permissions.allow(["update"], "device", device.company_id)) {
			buttons.push({ display: "Attach a Policy", function: this.attach_rule, icon: (<LinkIcon />)});
		}
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children ? this.state.modal.children() : ""}
				</SimpleModalWrapped>
				<div className={classes.tile}>
					<div className={classes.noMargin}>
						<CardContainer
							buttons={buttons}
							title="Policies"
							rootClass={classes.cardContainerRoot}
							infoText={this.rule_text()}
						>
							<div className={classes.tileContent}>
								<div className={classes.tileSubtitle}>
									Attached to this Device
								</div>
								<div className={classes.rulesContainer}>
									{device.nested_rules && device.nested_rules.length > 0 ?
										device.nested_rules.map( (rule) => this.render_rule(rule, false))
									: "None"}
								</div>
								<div className={classes.tileSubtitle}>
									Attached via Device Type
									{this.render_info()}
								</div>
								<div className={classes.rulesContainer}>
									{device.nested_type_rules && device.nested_type_rules.length > 0 ?
										device.nested_type_rules.map( (rule) => this.render_rule(rule, true))
									: "None"}
								</div>
							</div>
						</CardContainer>
					</div>
				</div>
			</div>
		);
	}


}

DeviceOrchestration.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceOrchestration));
