import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import TableList from '../Table/TableList';
import VerticalTabs from '../DisplayOriented/VerticalTabs';
import Loading from '../DisplayOriented/Loading';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import PaginationContainer from '../Containers/PaginationContainer';
import ContentLoader from "react-content-loader"
import IssueTransferForm from './IssueTransferForm';
import BulkTransferFlow from './BulkTransferFlow';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

//icons
import ArchiveIcon from '@material-ui/icons/Archive';
import InboxIcon from '@material-ui/icons/Inbox';
import SendIcon from '@material-ui/icons/Send';
import LoopIcon from '@material-ui/icons/Loop';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';

//services
import Auth from '../../services/Auth';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';

class TransfersList extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			layout: "card",
			tab_index: 0,
			modal: {
				open: false,
				children: () => ""
			},
			menus: {},
		};
		this.set_headings();
		this.tabs = [
			{label: "Transfers Received"},
			{label: "Transfers Sent"}
		];
		this.user_company_id = Auth.currentCompany()._id;
	}

	set_headings = () => {
		this.requests_heading = [
			{label: "Account From", field: "from_company_name", align: "left", sortable: false},
			{label: "Account To", field: "to_company_name", align: "left", sortable: false},
			{label: "Status", field: "status", align: "left", sortable: false},
			{label: "Device Type", field: "device_type_name", align: "left", sortable: false},
			{label: "Device Count", field: "device_count", align: "right", sortable: false},
			{label: "Action", field: "action", align: "center", sortable: false},
		];
	}

	handleChangePage = (event, page) => {
		this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
	};
	
	set_buttons = () => {
		let buttons = [{onSelectShow: false, display: `Issue Bulk Transfer`, label: `Issue Bulk Transfer`, icon: (<ArchiveIcon />), action: this.open_bulk_issue, function: this.open_bulk_issue}];
		return buttons;
	}

	open_bulk_issue = () => {
		this.setState({
			modal: {
				open: true,
				children: () => <BulkTransferFlow onComplete={this.on_accept} onClose={this.close_modal}/>
			}
		});
	}

	render_card_layout = (items) => {
		return (
			<React.Fragment>
				{items ? 
					items.map( (item, index) => this.render_card(item))
					:
					this.render_placeholder_cards()}
			</React.Fragment>
		);
	}

	render_action_panels = (transfer_request) => {
		if (this.props.isSent) return;
		const classes = this.props.classes;
		let text = "";
		let disabled = true;
		const icon = <ArchiveIcon className={classes.buttonIcon}/>;
		switch (transfer_request.status) {
			case "pending":
				disabled = false;
				text = "Accept Transfer";
				break;
			case "error":
				text = "Transfer Error";
				break;
			case "processing":
				text = "Processing";
				break;
			case "completed":
				text = "Transfer Completed";
				break;
		}
		return (
			<div className={classes.cardIconRow}>
				<div
					onClick={() => !disabled && this.accept_transfer(transfer_request)}
					className={classes.issueRequestButton}
				>
					<span className={classes.cardIconButton + " " + (disabled ? classes.tipDisabled : "")}>
						{icon}
						{text}
					</span>
				</div>
			</div>
		);
	}

	render_card = (transfer_request) => {
		const { classes } = this.props;
		const status_map = {
			"pending": <AccessTimeIcon className={classes.iconLabel + " " + classes.pending}/>,
			"completed": <CheckCircleIcon className={classes.iconLabel + " " + classes.completed}/>,
			"processing": <LoopIcon className={classes.iconLabel + " " + classes.processing}/>,
			"error": <ErrorIcon className={classes.iconLabel + " " + classes.error}/>
		};
		const is_sent = this.props.isSent;
		return (
			<div key={transfer_request._id} className={classes.cardContainer}>
				<Card className={classes.card + " " + (is_sent ? classes.sentCard : "")}>
					<div className={classes.cardLabelContainer}>
						<div className={classes.cardLabel}>
							<Tooltip title="To">
								<SendIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>To: {transfer_request.to_company_name}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="From">
								<AccountCircleIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>From: {transfer_request.from_company_name}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Status">
								{status_map[transfer_request.status]}
							</Tooltip>
							<span className={classes.cardText + " " + classes.statusText}>Status: {transfer_request.status}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Device Type">
								<DvrIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>{transfer_request.device_type_name}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Device Count">
								<InboxIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>{transfer_request.device_count}{this.device_count_text(transfer_request.device_count)}</span>
						</div>
					</div>
					{this.render_action_panels(transfer_request)}
				</Card>
			</div>
		);
	}

	device_count_text = (count) => {
		return (" device" + ((count === 0 || count > 1) ? "s" : ""));
	}

	render_placeholder_cards = () => {
		const classes = this.props.classes;
		let placeholders = [0,0,0,0,0,0,0,0,0];
		const is_sent = this.props.isSent;
		return (
			<React.Fragment>
				{placeholders.map( (item, index) => (
					<div key={"placeholder_" + index} className={classes.cardContainer}>
						<Card className={classes.card + " " + (is_sent ? classes.sentCard : "")}>
							<div className={classes.contentLoaderContainer}>
								<ContentLoader
									width={200}
									speed={2}
									interval={.25}
									primaryColor="#f3f3f3"
									secondaryColor="#ecebeb"
								>
									<rect x="0" y="6" rx="4" ry="4" width={200} height="18" /> 
									<rect x="12" y="33" rx="4" ry="4" width={200} height="16" /> 
									<rect x="12" y="63" rx="4" ry="4" width={200} height="16" /> 
								</ContentLoader>
							</div>
							{is_sent ? "" : <div className={classes.cardIconRow}>
								<Tooltip style={{visibility: "hidden"}} title={<span className={classes.tip}>Issue Update</span>}>
									<span className={classes.cardIconButton}>
										<DvrIcon />
									</span>
								</Tooltip>
							</div>}
						</Card>
					</div>
				))}
			</React.Fragment>
		)
	}

	render_table_layout = (items) => {
		let headings = this.requests_heading;
		return (
			<TableList
				headings={headings} 
				items={items}
				noCheckBox
			/>
		);
	}

	render_menu = (item, table) => {
		const classes = this.props.classes;
		let anchorEl =  this.state.menus[item._id];
		let open = Boolean(anchorEl);
		let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, item._id)}/> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, item._id)}/>;
		return (
			<React.Fragment>
				{icon}
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					open={open}
					onClick={() => this.close_action_menu(item._id)}
					onClose={() => this.close_action_menu(item._id)}
					PaperProps={{
						style: {overflow: "visible"}
					}}
				>
					<div className={classes.actionListTitle}>
						Perform Action...
					</div>
					{item.status === "pending" && !this.props.isSent ?
						<div className={classes.noOutline} onClick={(event) => this.accept_transfer(item)}>
							<MenuItem className={classes.actionMenuItem}>
								<ListItemIcon>
									<ArchiveIcon />
								</ListItemIcon>
								<Typography variant="inherit" noWrap>
									Accept Transfer
								</Typography>
							</MenuItem>
						</div>
						:
						<div className={classes.noActions}>No Actions Available</div>
					}
				</Menu>
			</React.Fragment>
		);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState( (state) => {
			state.menus[id] = element;
			return state;
		});
	}

	close_action_menu = (id) => {
		this.setState( (state) => {
			state.menus[id] = null;
			return state;
		});
	}

	prepare_items = () => {
		let items = this.props.items;
		if (!items) return;
		const classes = this.props.classes;
		const is_sent = this.props.isSent;
		items.forEach( (transfer_request) => {
			if (!Boolean(transfer_request.error)) transfer_request.error = "";
			transfer_request.action = (<span className={classes.tableMenu}>{this.render_menu(transfer_request, true)}</span>);
			transfer_request.device_count = Boolean(transfer_request.escrow_device_ids) && Array.isArray(transfer_request.escrow_device_ids) ? transfer_request.escrow_device_ids.length : 0;
			if (!is_sent) {
				transfer_request.from_company_name = transfer_request.from_company_id; 
				if (Boolean(transfer_request.nested_to_company)) {
					transfer_request.to_company_name =  transfer_request.nested_to_company.name;
				} else {
					transfer_request.error += "Account Not Found";
					transfer_request.to_company_name = <span className={classes.notFound}>Account Not Found</span>;
				}
				transfer_request.device_type_name = Boolean(transfer_request.nested_device_type) ? transfer_request.nested_device_type.name : "Device Type Unknown";
			} else {
				if (Boolean(transfer_request.nested_from_company)) {
					transfer_request.from_company_name =  transfer_request.nested_from_company.name;
				} else {
					transfer_request.error += " Account Not Found";
					transfer_request.from_company_name = <span className={classes.notFound}>Account Not Found</span>;
				}
				transfer_request.to_company_name = transfer_request.to_company_id;
				if (Boolean(transfer_request.nested_device_type)) {
					transfer_request.device_type_name =  transfer_request.nested_device_type.name;
				} else {
					transfer_request.error += " Device Type Not Found";
					transfer_request.device_type_name = <span className={classes.notFound}>Device Type Not Found</span>;
				}
			}
		});
		return items;
	}

	accept_transfer = (transfer_request) => {
		this.setState({
			modal: {
				open: true,
				children: () => <IssueTransferForm onComplete={this.on_accept} onClose={this.close_modal} transferRequest={transfer_request}/>
			}
		});
	}

	on_accept = () => {
		this.close_modal();
		this.props.tabHostProxy.refresh();
	}

	close_modal = () => {
		this.setState( (state) => {
			state.modal = {
				open: false,
				children: () => ""
			};
			return state;
		});
	}

	render() {
		const { page_data, modelChange, model, items, classes, show_filters } = this.props;
		let prepared_items = this.prepare_items(items);
		let buttons = this.set_buttons();
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<PaginationContainer
					filtering={this.props.filtering}
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={layout =>
						this.setState({ layout: layout })
					}
					toggle_filter={show_filters}
				/>
				<div className={classes.sideBarContainer}>
					<VerticalTabs tabs={this.tabs} onChange={this.props.modelChange}/> 
				</div>
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer}>
						{this.render_card_layout(prepared_items)}
					</div> :
					<div className={classes.tableItemsContainer}>
						{this.render_table_layout(prepared_items)}
					</div>
				}
			</div>
		);
	}
}

TransfersList.contextType = SnackbarContext;

const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			flexGrow: 2,
			display: "flex",
			flexWrap: "wrap",
			overflow: "hidden"
		},
		cardContainer: {
			width: "33.33%",
			display: "inline-flex",
			position: "relative",
		},
		card: {
			margin: "12px",
			padding: "12px",
			width: "100%",
			backgroundColor: "white",
			position: "relative",
			height: "240px",
			boxSizing: "border-box",
			border: "solid #ffffff00 2px"
		},
		sentCard: {
			height: "198px"
		},
		cardLabelContainer: {
			marginLeft: "12px"
		},
		cardLabel: {
			display: "flex",
			alignItems: "center",
			color: theme.palette.grey.main,
			height: "34px"
		},
		iconLabel: {
			color: theme.palette.grey.main,
			marginRight: "6px",
			fontSize: "22px"
		},
		cardText: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			fontSize: "14px"
		},
		statusText: {
			textTransform: "capitalize"
		},
		contentLoaderContainer: {
			height: "172px"
		},
		tip: {
			fontSize: "16px",
		},
		cardIconRow: {
			margin: "-12px",
			padding: "12px 0",
			marginTop: "4px",
			display: "flex",
			color: "grey",
			borderTop: "solid lightgrey 1px",
			backgroundColor: "#FAFAFA"
		},
		buttonIcon: {
			marginRight: "8px"
		},
		cardIconButton: {
			alignItems: "center",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			},
			display: "flex",
			margin: "auto"
		},
		tableMenu: {
			color: "grey",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		notFound: {
			color: theme.palette.red.main
		},
		sideBarContainer: {
			display: "flex",
			width: "15%",
			boxSizing: "border-box",
			marginTop: "-1px",
			backgroundColor: "white",
			zIndex: 5,
			borderRight: "solid lightgrey 1px",
			borderTop: "solid lightgrey 1px",
		},
		itemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			padding: "12px",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "85%",
			backgroundColor: "#ebebeb",
			boxSizing: "border-box",
		},
		cardItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			padding: "12px",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "85%",
			backgroundColor: "#f5f5f7",
			boxSizing: "border-box",
		},
		tableItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "85%",
			backgroundColor: "#ffffff",
			boxSizing: "border-box",
		},
		completed: {
			color: theme.palette.green.main,
		},
		error: {
			color: theme.palette.red.main
		},
		tipOverride: {
			padding: "8px",
			boxSizing: "border-box",
			marginTop: "0px",
		},
		tipDisabled: {
			cursor: "not-allowed",
			color: "#bababa",
			"&:hover": {
				color: "#bababa"
			}
		},
		actionListTitle: {
			marginBottom: "8px",
			padding: "11px 16px",
			borderBottom: "solid #80808073 1px",
			backgroundColor: "white",
			cursor: "unset",
			'&:hover': {
				backgroundColor: "white",
				cursor: "unset",
			},
			outline: "none",
			fontFamily: "Inter",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "1rem",
			width: "auto",
			height: "24px",
			whiteSpace: "nowrap",
			boxSizing: "content-box",
			fontWeight: 400,
			lineHeight: "1.5em"
		},
		actionMenuItem: {
			outline: "none",
		},
		noOutline: {
			outline: "none",
			"&:focus": {
				outline: "none"
			}
		},
		noActions: {
			color: "grey",
			fontFamily: "Inter",
			padding: "11px 16px"
		},
		issueRequestButton: {
			display: "flex",
			width: "100%"
		}
	});
};

export default compose(
	withRouter,
	withStyles(styles),
	withTheme(),
)(TransfersList); 
