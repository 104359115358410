import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Loading from '../DisplayOriented/Loading';
import Paper from '@material-ui/core/Paper';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import SelectInput from '../Inputs/SelectInput';
import Button from '@material-ui/core/Button';
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'

//icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
//todo no bueno font sizes, use different unit
const styles = theme => ({
	container: {
		overflow: "hidden",
		height: "100%",
		width: "100%",
		display: "flex",
		flexWrap: "wrap"
	},
	title: {
		width: "100%",
		fontSize: "20px",
	},
	pieContainer: {
		margin: "auto",
		display: "flex",
		height: "50%",
		overflow: "hidden",
		padding: "12px 0",
		boxSizing: "border-box"
	},
	value: {
		marginLeft: "auto",
	},
	heartBeatstatusDot: {
		minWidth: "20px",
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		marginRight: "12px"
	},
	legendContainer: {
		width: "100%",
		margin: "0 24px"
	},
	legendRow: {
		display: "flex",
		margin: "12px 22%",
		borderBottom: "solid lightgrey 1px",
		paddingBottom: "8px",
		fontSize: "14px",
	},
	filterContainer: {
		width: "50%",
		marginLeft: "6px"
	},
	ctaText: {
		marginRight: "8px",
		height: "100%",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	linkButton: {
		marginLeft: "auto",
		color: theme.palette.pending.main,
		'&:hover': {
			backgroundColor: "white",
			color: '#0263FC',
		},
	},
	bottomRow: {
		marginTop: "auto",
		width: "100%",
		display: "flex",
		flexWrap: "nowrap"
	},
});

class NewPieGraph extends React.Component {
	
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			data: null,
			filter: this.props.filterOptions ? this.props.filterOptions[0].value : null,
		};
		if (this.props.load) {
			this.props.load(this.state.filter).then((items) => this.setState({data: this.props.map(items)}));
		}
	}

	render_select_filter = () => {
		const {filterOptions, classes } = this.props;
		if (!filterOptions) return "";
		return (
			<div className={classes.filterContainer}>
				<SelectInput
					basic
					priorState={this.state.filter}
					emitChange={this.handle_change}
					options={filterOptions}
				/>
			</div>
		);
	}

	handle_change = ({field, value}) => {
		this.setState({data: null});
		this.setState( (state) => {
			state.filter = value;
			state.items = null;
			return state;
		}, () => {
			this.props.load(this.state.filter).then((items) => this.setState({data: this.props.map(items)}));
		});
	}

	render_link = () => {
		const { link, classes } = this.props;
		if (!link) return "";
		return (
			<Button className={classes.linkButton} onClick={this.link}>
				<span className={classes.ctaText}>
					{link.display}
				</span>
				<ArrowForwardIcon className={classes.ctaArrow}/>
			</Button>
		);
	}

	render_legend = () => {
		const classes = this.props.classes;
		let data = this.state.data;
		if (!this.props.load) {
			data = this.props.data;
		}
		if (data === null) return "";
		return (
			<div className={classes.legendContainer}>
				{data.map( (piece) => (
					<div key={piece.title} className={classes.legendRow}>
						<HeartbeatStatus status={piece.title.toLowerCase().replace(' ','_')} className={classes.heartBeatstatusDot} backgroundcolor={piece.color}/>
						{piece.title}
						<div className={classes.value}>
							{piece.value}
						</div>
					</div>
				))}
			</div>
		)
	}

	link = () => {
		this.props.history.push(this.props.link.link);
	}

	render() {
		const { classes, title, filterOptions, link } = this.props;
		let { data } = this.state;
		if (this.props.load == null) {
			data = this.props.data;
		}
		const total = data ? data.reduce( (total, {value}) => total = value + total, 0) : null;
		return (
			<div className={classes.container}>
				{title ? <div className={classes.title}>
					{title}
				</div> : "" }
				<div className={classes.pieContainer}>
					{data === null ?
						<Loading />
					:
						total === 0 ?
							<ReactMinimalPieChart
								animate
								style={{width: "100%"}}
								data={[{value: 1, color: "#d5d6d6"}]}
								totalValue={1}
								lineWidth={30}
								label={() => "No Data"}
								labelPosition={0}
							/>
						:
							<ReactMinimalPieChart
								animate
								style={{width: "100%"}}
								data={data}
								totalValue={total}
								lineWidth={30}
								label={false}
								labelPosition={0}
							/>
					}
				</div>
				{this.props.hideLegend ? "" : this.render_legend()}
				<div className={classes.bottomRow}>
					{this.render_select_filter()}
					{this.render_link()}
				</div>
			</div>
		);
	}

}


NewPieGraph.contextType = SnackbarContext;
export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)
(NewPieGraph);