import React from 'react';
import Company from '../../../services/DataModels/Company';
import { GetAll } from '../../../services/CLURDUtilities';
import Loading from '../../DisplayOriented/Loading';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import Auth from '../../../services/Auth';
import { ChromePicker } from 'react-color'
import Permissions from '../../../services/Permissions';
import TableList from '../../Table/TableList';

//inputs
import SwitchInput from '../../Inputs/SwitchInput';
import TextInput from '../../Inputs/TextInput';
import SelectInput from '../../Inputs/SelectInput';

//mui
import IconButton from '@material-ui/core/IconButton';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, withTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';


//icons
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import PieChartIcon from '@material-ui/icons/PieChart';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import LanguageIcon from '@material-ui/icons/Language';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import CancelIcon from '@material-ui/icons/Cancel';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import TuneIcon from '@material-ui/icons/TuneOutlined';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponentOutlined';
import LookupInput from '../../Inputs/LookupInput';
import StorageIcon from '@material-ui/icons/Storage';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import MetadataEditor from '../../common/MetadataEditor/MetadataEditorComponent'

const styles = theme => ({
	scrollContainer: {
		width: "inherit",
		overflowY: "scroll",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	container: {
		margin: "32px 20%",
		display: "flex",
		flexWrap: "wrap",
		fontFamily: "Inter"
	},
	viewTabs: {
		marginBottom: "12px"
	},
	leftColumn: {
		flex: "33%",
		boxSizing: "border-box",
		padding: "12px 6px 12px 0"
	},
	rightColumn: {
		width: "100%",
		boxSizing: "border-box",
	},
	lookupError: {
		color: "red",
	},
	buttonContainer: {
		width: "100%",
		justifyContent: "flex-end",
		display: "flex",
	},
	button: {
		marginLeft: "8px",
		whiteSpace: "nowrap",
	},
	deleteButton: {
		marginRight: "8px",
	},
	cancelButton: {
		marginRight: "8px",
	},
	profileContainer: {
		border: "solid lightgrey 2px",
		borderRadius: "5px",
		width: "100%",
		textAlign: "center",
		boxSizing: "border-box"
	},
	modalWrapper: {
	},
	notShown: {
		display: "none",
	},
	shown: {
		display: "flex",
		width: "100%",
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		fontWeight: "700",
		lineHeight: "32px",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	profileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto"
	},
	placeholderProfileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto",
		color: theme.palette.primary.main
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap"
	},
	actionIcon: {
		height: "28px",
		width: "28px",
		color: "#8e8e93",
		padding: 0,
		"&:hover": {
            color: "#0263fc",
        }
	},
	modalButtons: {
		display: "flex",
		justifyContent: "flex-end"
	},
	inputIcon: {
		color: "#8e8e93",
		margin: "8px",
		paddingBottom: "20px"
	},
	roleReserved: {
		color: "#8e8e93",
		textAlign: "right",
		fontFamily: "Inter",
		width: "100%"
	},
	colorInput: {
		borderBottom: "solid #8e8e93 1px",
		position: "relative",
		height: "56px",
		width: "calc(100% - 34px)",
		display: "inline-flex",
		fontSize: "14px",
	},
	colorPickerLabel: {
		position: "absolute",
		top: 0,
		fontSize: "14px",
		color: "#8e8e93",
		fontFamily: "Inter"
	},
	colorsRow: {
		display: "flex",
		flexWrap: "nowrap",
		padding: "12px 0"
	},
	colorPickerWrapper: {
		paddingRight: "12px",
		width: "40%",
		display: "flex",
		alignItems: "center"
	},
	colorPicker: {
		width: "100%"
	},
	popover: {
		position: 'absolute',
		zIndex: '2'
	},
	cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px'
	},
	color: {
		position: "absolute",
		bottom: "8px",
		fontSize: "14px",
		fontFamily: "Inter",
		color: "#8e8e93",
	},
	noIcon: {
		visibility: "hidden"
	},
	labelOverride: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "88%"
	},
	rightButtons: {
		flex: "50%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		marginBottom: "16px",
		marginTop: "32px",
	},
	leftIcon: {
		marginRight: "12px",
	},
	permissionsTitle: {
		fontSize: "14px",
		color: "#8e8e93",
		fontFamily: "Inter",
		marginLeft: "8px"
	},
	tile: {
		width: "100%",
		overflow: "scroll",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap"
	},
	sectionWrapper: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		marginLeft: "44px",
		overflow: "hidden"
	},
	sectionTitle: {
		width: "100%",
		fontFamily: "Inter",
		fontSize: "14px",
		color: "#8e8e93",
	},
	abilityTitle: {
		width: "50%",
		alignItems: "center",
		textTransform: "uppercase",
		alignItems: "center",
		fontFamily: "Inter",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflowX: "hidden"
	},
	abilityWrapper: {
		width: "50%",
		display: "flex",
		alignItems: "center",
		padding: "12px",
		boxSizing: "border-box",
		justifyContent: "space-between"
	},
	selectWrapper: {
		marginBottom: "-16px",
		overflow: "hidden"
	},
	previewCircle: {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		marginBottom: "-16px",
		marginRight: "8px",
		display: "inline-flex"
	},
	noColorIcon: {
		color: "#8e8e93",
		marginBottom: "-16px",
		marginRight: "8px"
	},
	subTitle: {
		fontSize: "14px",
		color: "#8e8e93",
		margin: "0 0 12px 0",
		display: "flex",
		alignItems: "center"
	},
	lookupContainer: {
		marginBottom: "24px",
	},
	infoIcon: {
		marginRight: "6px"
	},
	lookupTitle: {
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "14px",
		marginLeft: "40px",
		marginTop: "24px",
		marginBottom: "12px"
	},
	lookupListing: {
		marginLeft: "40px",
		marginBottom: "12px"
	},
	lookupInherited: {
		margin: "16px 0 8px 0",
		color: "#8e8e93",
		fontSize: "14px",
	},
	lookupWrapper: {
		width: "80%",
		display: "flex",
		flexWrap: "nowrap"
	},
	addIcon: {
		margin: "auto",
		marginLeft: "12px",
		padding: 0,
		"&:hover": {
            color: "#0263fc",
        }
	},
	noneAdded: {
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.87)",
	}
});


class AccountTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
			loading: false,
			ready: false,
			editable_account: new Company(this.props.data).editableCopy(),
			parent_company_options: null,
			tab_index: 0,
			primary_shown: false,
			background_shown: false,
			inherited_integration_ids: [],
			inherited_device_type_ids: [],
			integration_options: null,
			type_options: null,
			lookup_integration: null,
			lookup_type: null,
			lookup_ingestor: null,
			lookup_translator: null,
			lookup_pollable_attribute: null,
			lookup_rule: null
		};
		this.type_heading_info = [
			{label: "Name", value: "whole.name", nested_field: true, field: "whole.name", align: "left", disablePadding: false, sortable: false},
			{label: "Model", value: "whole.model", nested_field: true, field: "whole.model", align: "left", disablePadding: false, sortable: false},
			{label: "Manufacturer", value: "whole.manufacturer", field: "whole.manufacturer", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Actions", value: "table_delete", field: "table_delete", align: "left", disablePadding: false, sortable: false},
		];
		this.integration_heading_info = [
			{label: "Name", value: "whole.name", nested_field: true, field: "whole.name", align: "left", disablePadding: false, sortable: false},
			{label: "Type", value: "whole.readable_type", nested_field: true, field: "whole.readable_type", align: "left", disablePadding: false, sortable: false},
			{label: "Actions", value: "table_delete", field: "table_delete", align: "left", disablePadding: false, sortable: false},
		];
		this.ingestor_heading_info = [
			{label: "Name", value: "whole.name", nested_field: true, field: "whole.name", align: "left", disablePadding: false, sortable: false},
			{label: "Listener Type", value: "whole.listener_type", nested_field: true, field: "whole.type", align: "left", disablePadding: false, sortable: false},
			{label: "Actions", value: "table_delete", field: "table_delete", align: "left", disablePadding: false, sortable: false},
		];
		this.translator_heading_info = [
			{label: "Name", value: "whole.name", nested_field: true, field: "whole.name", align: "left", disablePadding: false, sortable: false},
			{label: "Type", value: "whole.type", nested_field: true, field: "whole.type", align: "left", disablePadding: false, sortable: false},
			{label: "Actions", value: "table_delete", field: "table_delete", align: "left", disablePadding: false, sortable: false},
		];
		this.pollable_attributes_heading_info = [
			{label: "Name", value: "whole.name", nested_field: true, field: "whole.name", align: "left", disablePadding: false, sortable: false},
			{label: "Type", value: "whole.type", nested_field: true, field: "whole.type", align: "left", disablePadding: false, sortable: false},
			{label: "Actions", value: "table_delete", field: "table_delete", align: "left", disablePadding: false, sortable: false},
		];
		this.rule_heading_info = [
			{label: "Description", value: "whole.description", nested_field: true, field: "whole.description", align: "left", disablePadding: false, sortable: false},
			{label: "Actions", value: "table_delete", field: "table_delete", align: "left", disablePadding: false, sortable: false},
		];
		this.my_company_id = Auth.currentCompany()._id;
		this.load_options();
		this.build_account_template();
		this.new_account = !this.state.editable_account._id ? true : false;
		this.tabs = [
			{ title: "Aliases", render: this.render_alias, icon: (classes) => (<SettingsCellIcon style={{width: "24px", height: "24px"}} />)},
			{ title: "Branding", render: this.render_branding, icon: (classes) => (<FormatPaintIcon style={{width: "24px", height: "24px"}} />)},
			{ title: "Pages", render: () => this.render_boolean(this.pages, "navigation"), icon: (classes) => (<DeveloperBoardIcon style={{width: "24px", height: "24px"}} />)},
			{ title: "Widgets", render: () => this.render_boolean(this.widgets, "widgets"), icon: (classes) => (<PieChartIcon style={{width: "24px", height: "24px"}} />)},
		];
		if ( !this.props.data || (this.props.data && this.props.data._id !== this.my_company_id) ) {
			this.tabs.push({ title: "Shared Data", render: this.render_inherited, icon: (classes) => (<FolderSharedIcon style={{width: "24px", height: "24px"}} />)});
		}
		this.tabs.push({ title: "Metadata", render: this.render_metadata, icon: (classes) => (<StorageIcon style={{width: "24px", height: "24px"}} />)});
		this.editing_disabled = this.props.data && !Permissions.allow(["update"], "company", this.props.data.company_id);
		this.delete_disabled = this.props.data && !Permissions.allow(["delete"], "company", this.props.data.company_id);
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	refresh = () => {
		this.setState({loading: true});
		new Company({_id: this.props.data._id}).readFromAPI().then( (company) => {
			this.props.tabHostProxy.updateTitle(company._id, company);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			let new_account = new Company(company).editableCopy();
			this.setState({loading: false, editable_account: new_account}, () => {
				this.new_account = !this.state.editable_account._id ? true : false;
				this.build_account_template();
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_options = () => {
		let currentCompany = Auth.currentCompany();
		let current_company_id = currentCompany._id
		let company_id_filter = {company_id: current_company_id};
		Promise.all([
			GetAll("integrations", company_id_filter),
			GetAll("device_types", company_id_filter),
			GetAll("companies", company_id_filter),
			GetAll("ingestors", company_id_filter),
			GetAll("translators", company_id_filter),
			GetAll("pollable_attributes", company_id_filter),
			GetAll("rules", company_id_filter)
		]).then( ([integrations, types, companies, ingestors, translators, pollable_attributes, rules]) => {
			integrations = integrations.map( (integration) => ({value: integration._id, label: integration.name, whole: integration, table_delete: this.render_table_delete(integration, "Integration", "inherited_integration_ids")}));
			types = types.map( (type) => ({value: type._id, label: type.name, whole: type, table_delete: this.render_table_delete(type, "Device Type", "inherited_device_type_ids")}));
			rules = rules.map( (rule) => ({value: rule._id, label: rule.description, whole: rule, table_delete: this.render_table_delete(rule, "Policy", "inherited_rule_ids")}));
			ingestors = ingestors.map( (ingestor) => ({value: ingestor._id, label: ingestor.name, whole: ingestor, table_delete: this.render_table_delete(ingestor, "Ingestor", "inherited_ingestor_ids")}));
			translators = translators.map( (translator) => ({value: translator._id, label: translator.name, whole: translator, table_delete: this.render_table_delete(translator, "Translator", "inherited_translator_ids")}));
			pollable_attributes = pollable_attributes.map( (pa) => ({value: pa._id, label: pa.name, whole: pa, table_delete: this.render_table_delete(pa, "Pollable Attribute", "inherited_pollable_attributes_ids")}));

			// this.state.inherited_integration_ids = this.props.data && this.props.data.inherited_integration_ids && this.props.data.inherited_integration_ids.length > 0 ? integrations.filter( (integration) => this.props.data.inherited_integration_ids.indexOf(integration.value) !== -1).map( ({value}) => value) : [];
			// this.state.inherited_device_type_ids = this.props.data && this.props.data.inherited_device_type_ids && this.props.data.inherited_device_type_ids.length > 0 ?  types.filter( (type) => this.props.data.inherited_device_type_ids.indexOf(type.value) !== -1).map( ({value}) => value) : [];
			// this.state.inherited_rule_ids = this.props.data && this.props.data.inherited_rule_ids && this.props.data.inherited_rule_ids.length > 0 ?  rules.filter( (rule) => this.props.data.inherited_rule_ids.indexOf(rule.value) !== -1).map( ({value}) => value) : [];
			// this.state.inherited_ingestor_ids = this.props.data && this.props.data.inherited_ingestor_ids && this.props.data.inherited_ingestor_ids.length > 0 ?  ingestors.filter( (ingestor) => this.props.data.inherited_ingestor_ids.indexOf(ingestor.value) !== -1).map( ({value}) => value) : [];
			// this.state.inherited_translator_ids = this.props.data && this.props.data.inherited_translator_ids && this.props.data.inherited_translator_ids.length > 0 ?  translators.filter( (translator) => this.props.data.inherited_translator_ids.indexOf(translator.value) !== -1).map( ({value}) => value) : [];
			// this.state.inherited_pollable_attributes_ids = this.props.data && this.props.data.inherited_pollable_attributes_ids && this.props.data.inherited_pollable_attributes_ids.length > 0 ?  pollable_attributes.filter( (pa) => this.props.data.inherited_pollable_attributes_ids.indexOf(pa.value) !== -1).map( ({value}) => value) : [];

			this.state.inherited_integration_ids = this.props.data && this.props.data.inherited_integration_ids && this.props.data.inherited_integration_ids.length > 0 ? this.props.data.inherited_integration_ids : [];
			this.state.inherited_device_type_ids = this.props.data && this.props.data.inherited_device_type_ids && this.props.data.inherited_device_type_ids.length > 0 ?  this.props.data.inherited_device_type_ids : [];
			this.state.inherited_rule_ids = this.props.data && this.props.data.inherited_rule_ids && this.props.data.inherited_rule_ids.length > 0 ?  this.props.data.inherited_rule_ids: [];
			this.state.inherited_ingestor_ids = this.props.data && this.props.data.inherited_ingestor_ids && this.props.data.inherited_ingestor_ids.length > 0 ?  this.props.data.inherited_ingestor_ids : [];
			this.state.inherited_translator_ids = this.props.data && this.props.data.inherited_translator_ids && this.props.data.inherited_translator_ids.length > 0 ?  this.props.data.inherited_translator_ids : [];
			this.state.inherited_pollable_attributes_ids = this.props.data && this.props.data.inherited_pollable_attributes_ids && this.props.data.inherited_pollable_attributes_ids.length > 0 ?  this.props.data.inherited_pollable_attributes_ids : [];


			let parent_companies = [];
			if (currentCompany !== null) {
				parent_companies = companies.filter((company) => company._id !== currentCompany._id);
				parent_companies.unshift(currentCompany);
			}

			this.setState({
				integration_options: integrations,
				type_options: types,
				ingestor_options: ingestors,
				translator_options: translators,
				pollable_attribute_options: pollable_attributes,
				rule_options: rules,
				parent_company_options: parent_companies
			});
		}).catch( (error) => {
			console.log(error);
			this.context.openSnackbar(error, "error");
		});
	}

	render_table_delete = (item, title, inherited_id_list_name) => {
		const classes = this.props.classes;
		return (
			<div className={classes.iconsContainer}>
				<Tooltip title={`Remove ${title}`}>
					<IconButton onClick={() => this.remove(item, inherited_id_list_name)} className={classes.actionIcon} aria-label="remove">
						<DeleteIcon/>
					</IconButton>
				</Tooltip>
			</div>
		);
	}

	build_account_template = () => {
		let account_template = Auth.currentCompany();
		this.aliases = [];
		Object.entries(account_template.aliases).forEach( ([key, value]) => {
			this.aliases.push(key);
		});
		if (!this.state.editable_account.aliases) {
			this.state.editable_account.aliases = {};
			this.aliases.forEach( (alias) => {
				this.state.editable_account.aliases[alias] = "";
			});
		}
		this.pages = [];
		Object.entries(account_template.portal_configuration.navigation).forEach( ([key, value]) => {
			if (key !== "action_hub_hidden" && key !== "lifecycle_hidden" && key !== "onboarding_hidden") {
				this.pages.push(key.split("_hidden")[0]);
			}
		});
		if (!this.state.editable_account.portal_configuration) {
			this.state.editable_account.portal_configuration = {};
			this.state.editable_account.portal_configuration.navigation = {};
			this.pages.forEach( (page) => {
				this.state.editable_account.portal_configuration.navigation[page] = false;
			});
		}
		this.widgets = [];
		Object.entries(account_template.portal_configuration.widgets).forEach( ([key, value]) => {
			this.widgets.push(key.split("_hidden")[0]);
		});
		if (!this.state.editable_account.portal_configuration.widgets) {
			this.state.editable_account.portal_configuration.widgets = {};
			this.widgets.forEach( (widget) => {
				this.state.editable_account.portal_configuration.widgets[widget] = false;
			});
		}
	}

	name_error = () => {
		return (this.state.editable_account.name === "");
	}

	_id_error = () => {
		let pattern = /^[a-z0-9_:-]+$/
		return (this.new_account && (!this.state.editable_account._id || this.state.editable_account._id === "" || !pattern.test(this.state.editable_account._id)));
	}

	can_save = () => {
		let show_errors = false;
		if (this.name_error() || this._id_error()) show_errors = true;
		if (this.lookup_error()) {
			show_errors = true;
			this.setState({tab_index: 4});
			this.context.openSnackbar("It looks like the form isn't quite finished. Please check the red error text below.", "warning");
		}
		this.setState({ show_errors: show_errors });
		return !show_errors;
	}

	lookup_error = () => {
		if (this.state.lookup_integration == null && this.state.lookup_type == null) {
			return false;
		} else {
			
			return true;
		}

	}

	handleAliasChange = ({field, value}) => {
		let new_account = this.state.editable_account;
		new_account.aliases[field] = value;
		this.setState({editable_account: new_account});
	}

	handleBrandingChange = ({field, value}) => {
		let new_account = this.state.editable_account;
		new_account.branding[field] = value;
		this.setState({editable_account: new_account});
	}

	render_alias = () => {
		return (
			<div>
				{this.aliases.map( (alias) => (
					<TextInput
						disabled={this.editing_disabled}
						emitChange={this.handleAliasChange}
						priorState={this.state.editable_account.aliases[alias]}
						label={this.label(alias)}
						field={alias}
						key={alias}
					/>
				))}
			</div>
		);
	}

	label = (unformatted) => {
		if (unformatted === "companies") {
			return "Accounts";
		} else if (unformatted === "rules") {
			return "Policies";
		} else if (unformatted === "overview") {
			return "Dashboard";
		}
		return unformatted.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
	}

	toggle_hidden = (prefix, item) => {
		let new_account = this.state.editable_account;
		this.state.editable_account.portal_configuration[prefix][item + "_hidden"] = !this.state.editable_account.portal_configuration[prefix][item + "_hidden"];
		this.setState({editable_account: new_account});
	}

	render_preview = (color) => {
		if (color) {
			return (
				<div style={{backgroundColor: color}} className={this.props.classes.previewCircle}>
					&nbsp;
				</div>
			);
		} else {
			return (
				<CancelIcon className={this.props.classes.noColorIcon}/>
			);
		}
		
	}

	render_boolean = (items, prefix) => {
		const { classes } = this.props;
		return (
			<div>
				{items.map( (item) => (
					<div key={prefix + "_" + item} className={classes.switchWrapper}>
						<SwitchInput
							disabled={this.editing_disabled}
							initial={!this.state.editable_account.portal_configuration[prefix][item + "_hidden"]}
							emitChange={() => this.toggle_hidden(prefix, item)}
							color={this.props.theme.palette.pending.main}
							onLabel={this.label(item)}
							offLabel={this.label(item)}
							field={item + "_hidden"}
							location="start"
						/>
					</div>
				))}
			</div>
		);
	}

	render_inherited = () => {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.subTitle}>
					<InfoIcon className={classes.infoIcon}/>
					Share data that belongs to {Auth.currentCompany().name} by inheriting specific data to this account. Shared data is read-only.
				</div>
				<div className={classes.lookupContainer}>
					<div className={classes.lookupTitle}>
						Inherit Device Types
					</div>
					{this.render_type_lookup()}
					{this.render_type_list()}
				</div>
				<div className={classes.lookupContainer}>
					<div className={classes.lookupTitle}>
						Inherit Integrations
					</div>
					{this.render_integration_lookup()}
					{this.render_integration_list()}
				<div className={classes.lookupContainer}>
					<div className={classes.lookupTitle}>
						Inherit Policies
					</div>
					{this.render_rule_lookup()}
					{this.render_rule_list()}
				</div>
				</div>
				<div className={classes.lookupContainer}>
					<div className={classes.lookupTitle}>
						Inherit Ingestors
					</div>
					{this.render_ingestor_lookup()}
					{this.render_ingestor_list()}
				</div>
				<div className={classes.lookupContainer}>
					<div className={classes.lookupTitle}>
						Inherit Translators
					</div>
					{this.render_translator_lookup()}
					{this.render_translator_list()}
				</div>
				<div className={classes.lookupContainer}>
					<div className={classes.lookupTitle}>
						Inherit Pollable Attributes
					</div>
					{this.render_pollable_attribute_lookup()}
					{this.render_pollable_attribute_list()}
				</div>
			</div>
		);
	}

	get_integration_options = () => {
		const integration_ids = this.state.inherited_integration_ids;
		return this.state.integration_options.filter( (option) => {
			return integration_ids.indexOf(option.value) === -1;
		});
	}

	get_type_options = () => {
		const type_ids = this.state.inherited_device_type_ids;
		return this.state.type_options.filter( (option) => {
			return type_ids.indexOf(option.value) === -1;
		});
	}

	get_ingestor_options = () => {
		const ingestor_ids = this.state.inherited_ingestor_ids;
		return this.state.ingestor_options.filter( (option) => {
			return ingestor_ids.indexOf(option.value) === -1;
		});
	}

	get_translator_options = () => {
		const translator_ids = this.state.inherited_translator_ids;
		return this.state.translator_options.filter( (option) => {
			return translator_ids.indexOf(option.value) === -1;
		});
	}

	get_pollable_attribute_options = () => {
		const pollable_attribute_ids = this.state.inherited_pollable_attributes_ids;
		return this.state.pollable_attribute_options.filter( (option) => {
			return pollable_attribute_ids.indexOf(option.value) === -1;
		});
	}

	get_rule_options = () => {
		const rule_ids = this.state.inherited_rule_ids;
		return this.state.rule_options.filter( (option) => {
			return rule_ids.indexOf(option.value) === -1;
		});
	}

	render_integration_lookup = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupWrapper}>
				<Tooltip className={classes.inputIcon} title="Integration">
					<ExtensionIcon />
				</Tooltip>
				<LookupInput
					priorState={{ suggestions: this.get_integration_options(), values: this.state.lookup_integration }}
					label="Select an Integration"
					field="lookup_integration"
					single
					onEnter={(event) => this.handle_key_press(event, "lookup_integration", "inherited_integration_ids")}
					model="integrations"
					emitChange={this.handle_change}
				/>
				<IconButton onClick={() => this.add("lookup_integration", "inherited_integration_ids")} className={classes.addIcon} aria-label="Add">
					<AddCircleIcon/>
				</IconButton>
			</div>
		);
	}

	render_integration_list = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupListing}>
				<div className={classes.lookupInherited}>
					Inherited Integrations
				</div>
				{this.state.inherited_integration_ids.length === 0 ? <span className={classes.noneAdded}>{"None added"}</span>: 
					<TableList
						headings={this.integration_heading_info} 
						items={this.state.integration_options.filter( (integration) => this.state.inherited_integration_ids.indexOf(integration.value) !== -1)}
					/>
				}
			</div>
		);
	}

	render_type_lookup = () => {
		const { classes } = this.props;
		return (
			<div className={classes.lookupWrapper}>
				<Tooltip className={classes.inputIcon} title="Device Type">
					<DvrIcon />
				</Tooltip>
				<LookupInput
					priorState={{ suggestions: this.get_type_options(), values: this.state.lookup_type}}
					label="Select a Device Type"
					field="lookup_type"
					single
					onEnter={(event) => this.handle_key_press(event, "lookup_type", "inherited_device_type_ids")}
					model="types"
					emitChange={this.handle_change}
				/>
				<IconButton onClick={() => this.add("lookup_type", "inherited_device_type_ids")} className={classes.addIcon} aria-label="Add">
					<AddCircleIcon/>
				</IconButton>
			</div>
		);
	}

	render_type_list = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupListing}>
				<div className={classes.lookupInherited}>
					Inherited Types
				</div>
				{this.state.inherited_device_type_ids.length === 0 ? <span className={classes.noneAdded}>{"None added"}</span>:
					<TableList
						headings={this.type_heading_info} 
						items={this.state.type_options.filter( (type) => this.state.inherited_device_type_ids.indexOf(type.value) !== -1)}
					/>
				}
			</div>
		);
	}

	render_ingestor_lookup = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupWrapper}>
				<Tooltip className={classes.inputIcon} title="Ingestor">
					<SettingsInputComponentIcon />
				</Tooltip>
				<LookupInput
					priorState={{ suggestions: this.get_ingestor_options(), values: this.state.lookup_ingestor }}
					label="Select an Ingestor"
					field="lookup_ingestor"
					single
					onEnter={(event) => this.handle_key_press(event, "lookup_ingestor", "inherited_ingestor_ids")}
					model="ingestors"
					emitChange={this.handle_change}
				/>
				<IconButton onClick={() => this.add("lookup_ingestor", "inherited_ingestor_ids")} className={classes.addIcon} aria-label="Add">
					<AddCircleIcon/>
				</IconButton>
			</div>
		);
	}

	render_ingestor_list = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupListing}>
				<div className={classes.lookupInherited}>
					Inherited Ingestors
				</div>
				{this.state.inherited_ingestor_ids.length === 0 ? <span className={classes.noneAdded}>{"None added"}</span>:
					<TableList
						headings={this.ingestor_heading_info}
						items={this.state.ingestor_options.filter( (ingestor) => this.state.inherited_ingestor_ids.indexOf(ingestor.value) !== -1)}
					/>
				}
			</div>
		);
	}

	render_translator_lookup = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupWrapper}>
				<Tooltip className={classes.inputIcon} title="Inherited Translators">
					<SettingsInputComponentIcon />
				</Tooltip>
				<LookupInput
					priorState={{ suggestions: this.get_translator_options(), values: this.state.lookup_translator }}
					label="Select a Translator"
					field="lookup_translator"
					single
					onEnter={(event) => this.handle_key_press(event, "lookup_translator", "inherited_translator_ids")}
					model="translators"
					emitChange={this.handle_change}
				/>
				<IconButton onClick={() => this.add("lookup_translator", "inherited_translator_ids")} className={classes.addIcon} aria-label="Add">
					<AddCircleIcon/>
				</IconButton>
			</div>
		);
	}

	render_translator_list = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupListing}>
				<div className={classes.lookupInherited}>
					Inherited Translators
				</div>
				{this.state.inherited_translator_ids.length === 0 ? <span className={classes.noneAdded}>{"None added"}</span>:
					<TableList
						headings={this.translator_heading_info}
						items={this.state.translator_options.filter( (translator) => this.state.inherited_translator_ids.indexOf(translator.value) !== -1)}
					/>
				}
			</div>
		);
	}

	render_pollable_attribute_lookup = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupWrapper}>
				<Tooltip className={classes.inputIcon} title="Inherited Pollable Attributes">
					<TuneIcon />
				</Tooltip>
				<LookupInput
					priorState={{ suggestions: this.get_pollable_attribute_options(), values: this.state.lookup_pollable_attribute }}
					label="Select a Pollable Attribute"
					field="lookup_pollable_attribute"
					single
					onEnter={(event) => this.handle_key_press(event, "lookup_pollable_attribute", "inherited_pollable_attributes_ids")}
					model="pollable_attributes"
					emitChange={this.handle_change}
				/>
				<IconButton onClick={() => this.add("lookup_pollable_attribute", "inherited_pollable_attributes_ids")} className={classes.addIcon} aria-label="Add">
					<AddCircleIcon/>
				</IconButton>
			</div>
		);
	}

	render_pollable_attribute_list = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupListing}>
				<div className={classes.lookupInherited}>
					Inherited Pollable Attributes
				</div>
				{this.state.inherited_pollable_attributes_ids.length === 0 ? <span className={classes.noneAdded}>{"None added"}</span>:
					<TableList
						headings={this.pollable_attributes_heading_info}
						items={this.state.pollable_attribute_options.filter( (pollable_attribute) => this.state.inherited_pollable_attributes_ids.indexOf(pollable_attribute.value) !== -1)}
					/>
				}
			</div>
		);
	}

	render_rule_lookup = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupWrapper}>
				<Tooltip className={classes.inputIcon} title="Inherited Policies">
					<SecurityIcon />
				</Tooltip>
				<LookupInput
					priorState={{ suggestions: this.get_rule_options(), values: this.state.lookup_rule }}
					label="Select a Policy"
					field="lookup_rule"
					single
					onEnter={(event) => this.handle_key_press(event, "lookup_rule", "inherited_rule_ids")}
					model="rules"
					emitChange={this.handle_change}
				/>
				<IconButton onClick={() => this.add("lookup_rule", "inherited_rule_ids")} className={classes.addIcon} aria-label="Add">
					<AddCircleIcon/>
				</IconButton>
			</div>
		);
	}

	render_rule_list = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.lookupListing}>
				<div className={classes.lookupInherited}>
					Inherited Policies
				</div>
				{this.state.inherited_rule_ids.length === 0 ? <span className={classes.noneAdded}>{"None added"}</span>:
					<TableList
						headings={this.rule_heading_info}
						items={this.state.rule_options.filter( (rule) => this.state.inherited_rule_ids.indexOf(rule.value) !== -1)}
					/>
				}
			</div>
		);
	}

	copy_branding = () => {
		let { editable_account } = this.state
		let currentBranding = Object.assign({}, Auth.currentCompany().branding);
		editable_account.branding = currentBranding;
		this.setState((s) => {
			s.editable_account = editable_account;
			return s;
		});
	}

	handle_change = (input) => {
		let field = input.field;
		!input.value ? this.setState( (state) => {
			state[field] = null;
			return state;
		}) : this.setState( (state) => {
			state[field] = input.value;
			return state;
		});
	}

	remove = (item, inherited_id_list_name) => {
		this.setState( (state) => {
			console.log(state);
			state[inherited_id_list_name].splice(state[inherited_id_list_name].indexOf(item), 1);
			return state;
		});
	}

	add = (lookup_field_name, inherited_id_list_name) => {
		let lookup = this.state[lookup_field_name];
		if (lookup == null) return;
		const id = lookup.value;
		this.setState( (state) => {
			state[lookup_field_name] = null;
			state[inherited_id_list_name].push(id);
			return state;
		});
	}

	handle_key_press = (event, lookup_field_name, inherited_id_list_name) => {
		if (event === null) return;
		this.add(lookup_field_name, inherited_id_list_name);
	}

	render_branding = () => {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.colorsRow}>
					<div className={classes.colorPickerWrapper}>
						{this.render_preview(this.state.editable_account.branding.primary_color)}
						<div
							onClick={() => !this.editing_disabled ?  this.setState({primary_shown: !this.state.primary_shown}) : ""}
							className={classes.colorInput}
						>
							<div className={classes.colorPickerLabel}>
								Primary Color
							</div>
							<div className={classes.color} style={{color: this.editing_disabled ? "rgba(0, 0, 0, 0.38)" : this.state.editable_account.branding.primary_color || "#8e8e93"}}>
								{this.state.editable_account.branding.primary_color || "Color Unset"}
							</div>
						</div>
						{this.state.primary_shown ?
							<div className={classes.popover}>
								<div className={classes.cover} onClick={() => this.setState({primary_shown: false})}/>
								<ChromePicker
									color={this.state.editable_account.branding.primary_color}
									onChangeComplete={(color) => this.handleBrandingChange({field: "primary_color", value: color.hex})}
								/>
							</div>
						: ""}
					</div>
					<div className={classes.colorPickerWrapper}>
						{this.render_preview(this.state.editable_account.branding.logo_background_color)}
						<div
							onClick={() => !this.editing_disabled ? this.setState({background_shown: !this.state.background_shown}) : ""}
							className={classes.colorInput}
						>
							<div className={classes.colorPickerLabel}>
								Logo Background Color
							</div>
							<div className={classes.color} style={{color: this.editing_disabled ? "rgba(0, 0, 0, 0.38)" : this.state.editable_account.branding.logo_background_color || "#8e8e93"}}>
								{this.state.editable_account.branding.logo_background_color || "Color Unset"}
							</div>
						</div>
						{this.state.background_shown ?
							<div className={classes.popover}>
								<div className={classes.cover} onClick={() => this.setState({background_shown: false})}/>
								<ChromePicker
									color={this.state.editable_account.branding.logo_background_color}
									onChangeComplete={(color) => this.handleBrandingChange({field: "logo_background_color", value: color.hex})}
								/>
							</div>
						: ""}
					</div>
				</div>
				<TextInput
					disabled={this.editing_disabled}
					emitChange={this.handleBrandingChange}
					priorState={this.state.editable_account.branding.logo_url}
					label="Logo URL"
					field="logo_url"
				>
				</TextInput>
				<TextInput
					disabled={this.editing_disabled}
					emitChange={this.handleBrandingChange}
					priorState={this.state.editable_account.branding.icon_url}
					label="Icon URL"
					field="icon_url"
				>
				</TextInput>
				<TextInput
					disabled={this.editing_disabled}
					emitChange={this.handleBrandingChange}
					priorState={this.state.editable_account.branding.portal_title}
					label="Portal Title"
					field="portal_title"
				>
				</TextInput>
				{ this.state.editable_account._id == null ?
					<Button
						disabled={this.editing_disabled}
						onClick={() => this.copy_branding()}
						variant="outlined"
						color="primary"
					>
						Copy Branding from Current Company
					</Button>
				: "" }
			</div>
		);
	}

	render_widgets = () => {
		return (
			<div>
			</div>
		);
	}
	
	save_account = () => {
		if (this.can_save()) {
			if (this.state.editable_account.aliases.gateway === "") {
				this.state.editable_account.aliases.gateway = "gateway";
			} if (this.state.editable_account.aliases.device === "") {
				this.state.editable_account.aliases.device = "device";
			}
			this.state.editable_account.inherited_device_type_ids = this.state.inherited_device_type_ids;
			this.state.editable_account.inherited_integration_ids = this.state.inherited_integration_ids;
			this.state.editable_account.inherited_rule_ids = this.state.inherited_rule_ids;
			this.state.editable_account.inherited_ingestor_ids = this.state.inherited_ingestor_ids;
			this.state.editable_account.inherited_translator_ids = this.state.inherited_translator_ids;
			this.state.editable_account.inherited_pollable_attributes_ids = this.state.inherited_pollable_attributes_ids;

			if (this.new_account) {
				new Company(this.state.editable_account)
				.create()
				.then((result) => {
					if (this.state.editable_account._id) {
						this.context.openSnackbar("Account saved.", "success");
					} else {
						this.context.openSnackbar("Account created.", "success");
					};
					this.props.tabHostProxy.refresh();
					this.props.tabHostProxy.closeSelf();
					this.props.tabHostProxy.addTab("account", result);
				})
				.catch((error) => {
					this.context.openSnackbar(error, "error");
				});
			} else {
				console.log(this.state.editable_account);
				const account_without_meta = { ...this.state.editable_account, metadata: null }
				new Company(account_without_meta)
				.createOrSave()
				.then((result) => {
					new Company(this.state.editable_account)
					.createOrSave()
					.then((result) => {
						if (this.state.editable_account._id) {
							this.context.openSnackbar("Account saved.", "success");
						} else {
							this.context.openSnackbar("Account created.", "success");
						};
						this.props.tabHostProxy.refresh();
						this.props.tabHostProxy.closeSelf();
						this.props.tabHostProxy.addTab("account", result);
					})
					.catch((error) => {
						this.context.openSnackbar(error, "error");
					});
				})
				.catch((error) => {
					this.context.openSnackbar(error, "error");
				});
			}
		}
	}

	confirm_delete = (classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Delete this account? This action cannot be reversed.
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								className={classes.button}
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								color="primary"
								variant="contained"
								size="large"
								className={classes.button}
								onClick={this.delete_account}
							>
								DELETE ACCOUNT
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	delete_account = () => {
		new Company(this.state.editable_account)
			.deleteFromAPI()
			.then(() => {
				this.context.openSnackbar("Account deleted.", "success");
				this.close_modal();
				this.props.tabHostProxy.refresh();
				this.props.tabHostProxy.closeSelf();
			})
			.catch((error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	renderTab = (classes) => {
		let render = () => {};
		this.tabs.forEach( (tab, index) => {
			if (index === this.state.tab_index) {
				render =  tab.render;
				return;
			}
		});
		return render();
	}

	handleIDChange = ({field, value}) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState((prev_state) => {
			return Object.assign(prev_state.editable_account, overwrite);
		}, () => {
			if (this._id_error()) {
				this.setState({show_errors: true});
			}
		});
	}

	handleTabChange = (event, value) => {
		this.setState({ tab_index: value });
	};

	render_parent_account = (classes) => {
		const { editable_account, show_errors, parent_company_options } = this.state;
		let parent_company_visible = true;
		if (editable_account.created_at != null) {
			parent_company_visible = parent_company_options.find(company => editable_account.company_id == company._id);
		}

		if (!parent_company_visible) { // User can't see parent company. Don't show field.
			return <React.Fragment />
		}

		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Parent Account" title="Parent Account">
					<AccountCircleIcon />
				</Tooltip>
				<SelectInput
					disabled={this.editing_disabled || editable_account.created_at != null}
					emitChange={this.handleTextChange}
					priorState={editable_account.company_id}
					key="company_id"
					field="company_id"
					options={parent_company_options.map( (company) => ({display: company.name, value: company._id}))}
				>
				</SelectInput>
			</div>
		);
	}
	
	submit_edit_metadata = (new_account) => {
		this.setState( (state) => {
			state.editable_account = new_account
			return state;
		});
	}

	render_metadata = () => {		
		return (<MetadataEditor model={this.state.editable_account} can_edit={!this.editing_disabled} submitMetadata={this.submit_edit_metadata}/>)
	}

	render() {
		const { classes } = this.props;
		const { modal, editable_account, show_errors } = this.state;
		if (this.state.integration_options === null || this.state.type_options === null || this.state.loading) return <Loading/>;
		return (
			<div className={classes.scrollContainer}>
				<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
					{modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.container}>
					<div className={classes.rightColumn}>

						{this.render_parent_account(classes)}

						<div className={classes.inputContainer}>
							<Tooltip key="Unique ID" title="Unique ID">
								<span style={{
									color: "white", backgroundColor: "#8e8e93", borderRadius: "4px",
									width: "18px", height: "18px", fontSize: "12px", marginLeft: "9px",
									marginRight: "9px", display: "flex", fontFamily: "Inter", marginBottom: "20px", padding: "2px"
								}}>
									<span style={{ margin: "auto" }}>
										ID
									</span>
								</span>
							</Tooltip>
							<TextInput
								emitChange={this.handleIDChange}
								disabled={editable_account.created_at != null || this.editing_disabled}
								priorState={editable_account._id}
								error_message="An account's ID cannot be blank and can only contain numbers, lower-case letters, and the dash and underscore characters: '-' and '_' "
								error={this._id_error() && show_errors}
								label="Account ID*"
								field="_id"
							>
							</TextInput>
						</div>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon} title="Name">
								<PeopleIcon />
							</Tooltip>
							<TextInput
								disabled={this.editing_disabled}
								emitChange={this.handleTextChange}
								priorState={editable_account.name}
								error_message="An account's name cannot be blank."
								error={this.name_error() && show_errors}
								label="Account Name*"
								field="name"
							>
							</TextInput>
						</div>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon} title="Website">
								<LanguageIcon />
							</Tooltip>
							<TextInput
								disabled={this.editing_disabled}
								emitChange={this.handleTextChange}
								priorState={editable_account.website}
								label="Website"
								field="website"
							>
							</TextInput>
						</div>
						<div className={classes.permissionsTitle}>
							Configuration
						</div>
						<Tabs
							className={classes.viewTabs}
							value={this.state.tab_index}
							onChange={this.handleTabChange}
							variant="scrollable"
							scrollButtons="auto"
							indicatorColor="primary"
							textColor="primary"
						>
							{this.tabs.map((tab) => {
								return (
									<Tab
										key={tab.title}
										icon={tab.icon(classes)}
										label={tab.title}
									/>
								);
							})}
						</Tabs>
						{this.renderTab(classes)}
					</div>
					{this.state.show_errors && this.lookup_error() ? <div className={classes.lookupError}>
						Please click the add button or press enter when your cursor is active in the input in order to add an integration or device type.
					</div> : ""}
					<div className={classes.buttonContainer}>
						<div className={classes.rightButtons}>
							{this.props.data && this.props.data._id ?
								<Button
									disabled={this.delete_disabled}
									className={classes.button}
									onClick={() => this.confirm_delete(classes)}
									color="primary"
								>
									DELETE
								</Button>
								: <Button
										className={classes.button}
										onClick={() => this.props.tabHostProxy.closeSelf()}
										color="primary"
										>
										CANCEL
									</Button>}
							<Button
								disabled={this.editing_disabled}
								className={classes.button}
								onClick={this.save_account}
								color="primary"
								variant="contained"
								size="large"
							>
								{this.props.data && this.props.data._id ? "SAVE ACCOUNT" : "CREATE ACCOUNT"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		);

	}

	handleTextChange = ({ field, value }) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState((prev_state) => {
			return Object.assign(prev_state.editable_account, overwrite);
		});
	}
}

AccountTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(AccountTab));
