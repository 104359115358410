import React from 'react';
import {
	BrowserRouter as Router,
	Redirect,
} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Auth from '../services/Auth';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme } from '@material-ui/core/styles';
import SimpleModalWrapped from '../components/Containers/SimpleModalWrapped';
import Loading from '../components/DisplayOriented/Loading';
import { SnackbarContext } from '../services/ContextProviders/Snackbar';

//icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
	container: {
		display: "flex",
		width: "100%",
	},
	rightSection: {
		width: "100%",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	loginArea: {
		[theme.breakpoints.down("sm")]: {
			width: "80%",
			padding: "40px 40px",
		},
		width: "40%",
		maxWidth: "471px",
		backgroundColor: "#fafafa",
		boxShadow: "0px 3px 7px 2px rgba(0, 0, 0, 0.09)",
		borderRadius: "4px",
		padding: "80px 80px",
		boxSizing: "border-box",
	},
	loginSymbol: {
		margin: "0 auto 40px auto",
		display: "flex",
		maxWidth: "300px",
		maxHeight: "90px"
	},
	loginTitle: {
		fontFamily: "Saira,sans-serif",
		fontWeight: 600,
		color: "#32618c",
		fontSize: "32px",
		lineHeight: "1.2",
		width: "100%",
		fontSize: "28px",
		margin: "10px 0 10px 0",
		"text-align": "center"
	},
	titleImage: {
		width: "66%",
		margin: "12px"
	},
	subtitle: {
		color: "#fff",
		fontFamily: "Inter,sans-serif",
		fontWeight: 300,
		fontSize: "22px",
	},
	sloganWhite: {
		color: "#fff",
		fontFamily: "Saira,sans-serif",
		fontWeight: 600,
		fontSize: "32px",
		lineHeight: "1.2",
		width: "100%",
		fontSize: "28px",
		marginBottom: "40px",
	},
	visual: {
		width: "60%",
		margin: "40px 0 40px 0",
	},
	ctaButtonContainer: {
		width: "100%",
		display: "flex",
	},
	links: {
		textDecoration: "none",
		margin: "auto",
	},
	ctaButton: {
		margin: "0 12px 0 12px",
		color: "white",
		borderColor: "white !important",
		textDecoration: "unset",
	},
	loginInput: {
		width: "100%",
		marginTop: "16px",
		marginBottom: "16px",
		position: "relative",
	},
	loginButton: {
		margin: "0 auto",
		display: "flex",
		marginTop: "24px",
		width: "100%",
		height: "56px",
	},
	forgotPassword: {
		cursor: "pointer",
		color: theme.palette.primary.main,
		fontFamily: "Inter,sans-serif",
		textDecoration: "none",
		fontSize: "16px",
		marginTop: "32px",
		textAlign: "center",
	},
	passwordContainer: {
		position: "relative",
	},
	onIcon: {
		position: "absolute",
		top: "33px",
		right: "12px",
		color: "rgba(0, 0, 0, 0.24)",
		cursor: "pointer",
	},
	offIcon: {
		position: "absolute",
		top: "33px",
		right: "12px",
		color: "rgba(0, 0, 0, 0.24)",
		cursor: "pointer",
	}
});

class Login extends React.Component {

	constructor(props) {
		super(props);
		let opts = this.configureBranding(props);
		this.state = {
			redirect: false,
			email: '',
			emailReset: '',
			password: '',
			passwordResetModal: {
				open: false
			},
			logging_in: false,
			visible: false,
			branding_image: opts.branding_image,
		}
	}

	configureBranding = (props) => {
		let hostname = window.location.hostname;
		let opts = {
			branding_image: require('../images/edge_logo.png')
		}
		if (hostname === 'localhost') {
			// put something here for testing, if you want to.
		} else if (hostname === 'app.stage.edgeiq.io') {
			document.title = "[Stage] DRM Portal";
			//logoImage = require('../images/edge_logo_stage.png');
		} else if (hostname === 'app.deviceops.tessco.com' || hostname === 'app.lifecycle.tessco.com') {
			document.title = "Tessco DRM Portal";
			opts.branding_image = require('../images/Tessco-Logo.png');
		}
		return opts;
	}

	login = () => {
		this.setState({logging_in: true});
		let body = {
			email: this.state.email,
			password: this.state.password
		};
		Auth.authenticate(body,
			(user) => {
				//index will rerender now that user is logged in, and the render function
				// below will get the user from auth and then redirect.
			},
			(error) => {
				this.setState({logging_in: false});
				this.context.openSnackbar(error, "error");
			});
	}

	handleChange = (event) => {
		var stateObject = function () {
			let returnObj = {};
			returnObj[this.target.id] = this.target.value;
			return returnObj;
		}.bind(event)();

		this.setState(stateObject);
	}

	handleKeyPress = (event) => {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		if (keycode == '13') {
			this.login();
		}
	}

	sendPasswordReset = () => {
		Auth.promptPasswordEmail(this.state.emailReset).then( () => {
			this.closePasswordResetModal();
			this.context.openSnackbar("A reset link will be sent to your email shortly.", "success");
		}).catch( (error) => {
			this.context.openSnackbar("That email address/username doesn't exist. Contact support@edgeiq.io if you need help getting your account.", "error");
		});
	}

	openPasswordResetModal = () => {
		this.setState({passwordResetModal: {
			"open": true,
			prompt: "Enter your email. We'll send you a link to reset your password.",
			yesFunction: this.sendPasswordReset,
			functionText: "SEND EMAIL",
		}});
	}

	closePasswordResetModal = () => {
		this.setState({passwordResetModal: {
			"open": false
		}});
	}

	toggle_visible = () => {
		this.setState( (prev_state) => ({visible: !prev_state.visible}));
	}

	render() {
		const { classes, theme } = this.props;
		const { visible, branding_image } = this.state;
		
		if (Auth.token() != null) {
			return (
				<Redirect to={"/"} />
			);
		} else {
			return (
				<div className={classes.container}>
					<div className={classes.rightSection}>
						{this.state.logging_in === false ? 
							<div className={classes.loginArea}>
								<img className={classes.loginSymbol} src={branding_image} />
								<TextField
									id="email"
									label="Email"
									className={classes.loginInput}
									type="email"
									name="email"
									autoComplete="email"
									margin="normal"
									variant="outlined"
									value={this.state.email}
									onChange={this.handleChange}
									onKeyPress={this.handleKeyPress}
								/>
								<div className={classes.passwordContainer}>
									<TextField
										id="password"
										label="Password"
										className={classes.loginInput}
										type={visible ? "text" : "password"}
										autoComplete="current-password"
										margin="normal"
										variant="outlined"
										value={this.state.password}
										onChange={this.handleChange}
										onKeyPress={this.handleKeyPress}
									/>
									{visible ? 
										<VisibilityIcon className={classes.onIcon} onClick={this.toggle_visible}/>
									:
										<VisibilityOffIcon className={classes.offIcon} onClick={this.toggle_visible}/>
									}
								</div>
								<Button onClick={this.login} className={classes.loginButton} color="primary" variant="contained">
									Log In
								</Button>
								<div onClick={this.openPasswordResetModal} className={classes.forgotPassword}>
									Forgot your password?
								</div>
							</div> : <Loading/>
						}
					</div>
					<SimpleModalWrapped info={this.state.passwordResetModal} closeModal={this.closePasswordResetModal}>
						<TextField
							id="emailReset"
							label="Email"
							className={classes.loginInput}
							type="email"
							name="emailReset"
							margin="normal"
							variant="outlined"
							value={this.state.emailReset}
							onChange={this.handleChange}
						/>
					</SimpleModalWrapped>
				</div>
			)
		}
	}
}

Login.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(Login));
