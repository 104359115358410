import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Report from '../../services/DataModels/Report';
import EnhancedTable from '../Table/EnhancedTable';
import Button from '@material-ui/core/Button';
import PaginationContainer from '../Containers/PaginationContainer';
import Paper from '@material-ui/core/Paper';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';

const styles = theme => ({
	container: {
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
	},
	contentContainer: {
		overflowY: "auto",
		height: "calc(100% - 57px)",
		width: "100%",
		backgroundColor: "white"
	},
	paginationContainer: {
		width: "100%"
	},
	modalWrapper: {
		padding: "24px"
	},
	link: {
		color: theme.palette.pending.main,
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": theme.palette.pending.main,
			textDecorationColor: theme.palette.pending.main,
			cursor: "pointer",
		}
	},
});

const heading_info = [
	{label: "Created At", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date"},
	{label: "Payload", value: "table_payload", field: "payload", align: "center", disablePadding: false, sortable: false, content: "function"},
	{label: "Account", field: "company_id", align: "left", disablePadding: false, sortable: true},
];

class DeviceReports extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			active: 0,
			reports: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			device_id: this.props.device_id,
			modal: {
				open: false,
				children: () => ""
			},
			sort: null
		};
		this.load_reports();
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_reports();
		});
	}

	openPayloadModal = (payload, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<pre>
							{JSON.stringify(payload, null, 2)}
						</pre>
						<div className={classes.buttonContainer}>
							<Button
								color="primary" className={classes.button}
								onClick={this.closeModal}
							>
								CLOSE
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	closeModal = () => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	}

	load_reports = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		if (this.props.heartbeats_only) {
			params.heartbeat = true;
		} else {
			params.heartbeat = false;
		}
		delete params.total; // API doesn't like trying to filter for fields that aren't in the table
		params.device_id = this.state.device_id;

		new Report()
			.listFromAPI(params)
			.then((result) => {
				let reports = result.items;
				reports.forEach( (report) => {
					report.table_payload = () => (<div onClick={() => this.openPayloadModal(report.payload)} className={classes.link}>View Payload</div>);
				});
				this.setState({
					reports: reports,
					page_data: {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: result.total
					}
				});
			})
			.catch((error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_reports();
		});
	}

	handleChangePage = (event, page) => {
		this.page_change({ page: page + 1, per_page: this.state.page_data.per_page });
	};

	handleChangeRowsPerPage = event => {
		this.page_change({ page: this.state.page_data.page, per_page: event.target.value });
	};

	render() {
		const { classes } = this.props;
		const { reports, page_data, modal } = this.state;
		if (page_data.total === null) {
			return (<Loading />);
		}
		return (
			<Paper className={classes.container}>
				<div className={classes.paginationContainer}>
					<PaginationContainer
						count={page_data.total}
						rowsPerPage={page_data.per_page}
						currentPage={page_data.page}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
				</div>
				<div className={classes.contentContainer}>
					<TableList
						headings={heading_info} 
						items={reports}
						page_data={page_data}
						perform_sort={this.perform_sort}
					/>
				</div>
				<SimpleModalWrapped info={modal} closeModal={this.closeModal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
			</Paper>
		)
	}

	headingInfo = [
		{label: "Date", field: "created_at", numeric: false, disablePadding: false, sortable: false},
		{label: "Payload", field: "payload", numeric: false, disablePadding: false, sortable: false},
	];

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	}

	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	}
}

DeviceReports.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceReports));
