/* eslint-disable no-unused-expressions */

import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import Permissions from '../services/Permissions';
import PortalConfiguration from '../services/PortalConfiguration';
import Loading from '../components/DisplayOriented/Loading';
import Auth from '../services/Auth';

//icons
import SwapHorizIcon from '@material-ui/icons/SwapHorizOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import AddIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import QueueIcon from '@material-ui/icons/Queue';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import PieChartIcon from '@material-ui/icons/PieChart';
import ReportProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponentOutlined';

const styles = theme => ({
    container: {
        fontFamily: "Inter",
        display: "flex",
        flexWrap: "wrap",
    },
    titleContainer: {
        fontSize: "32px",
        color: theme.palette.primary.main,
        width: "100%",
        paddingBottom: "16px",
        marginTop: "12px"
    },
    subtitleContainer: {
        width: "100%",
        paddingBottom: "16px",
        color: "grey",
        fontSize: "22px",
    },
    keyContainer: {
        width: "32%",
        boxSizing: "border-box",
        paddingLeft: "40px",
    },
    key: {
        fontSize: "22px",
        padding: "16px 32px 16px 16px",
        width: "100%",
        color: "black",
        borderLeft: "solid",
        borderColor: "#80808052",
        borderThickness: "2px",
        color: "grey",
        "&:hover": {
            color: "black",
            cursor: "pointer",
        }
    },
    activeKey: {
        color: "black",
        borderLeft: "solid",
        borderColor: theme.palette.pending.main,
        borderThickness: "2px"
    },
    sectionsContainer: {
        width: "66%",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        paddingRight: "24px"
    },
    sectionContainer: {
        width: "50%",
        marginBottom: "24px",
        paddingRight: "16px",
        boxSizing: "border-box",
        "&:hover": {
            cursor: "pointer",
        }
    },
    titleWrapper: {
        marginTop: "6px"
    },
    sectionTitle: {
        display: "inline",
        color: "black",
        fontSize: "22px",
        width: "100%",
    },
    sectionDescription: {
        color: "grey",
        fontSize: "16px",
        width: "100%",
        marginTop: "12px",
        textDecoration: "none !important"
    },
    linkContainer: {
        width: "100%",
        color: theme.palette.pending.main,
        paddingBottom: "22px",
    },
    link: {
        marginRight: "22px",
        textDecoration: "unset",
        color: theme.palette.pending.main,
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        },
    },
    navGroupIcon: {
        width: "20px",
        height: "20px",
        marginRight: "18px",
    },
    hidden: {
        display: 'none'
    }
});

class ActionHub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_key: 0,
            keys: null
        };
        this.props = props;
        if (this.props.location !== "/") {
            this.props.history.replace({
                pathname: '/Hub',
                search: ''
            });
        }
        this.define_keys();
    }

    can_add_section = (actions, model, page) => {
        return Permissions.allow(actions, model) && PortalConfiguration.navigationItemVisible(page);
    }

    define_onboard_sections = (key) => {
        const { theme, classes } = this.props;
        this.can_add_section(["create"], "company", "companies") ? key.sections.push({
            text: {
                title: "Create Accounts",
                description: "Onboard your customers by creating accounts to coordinate their data and users."
            },
            link: `Accounts`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AddIcon style={{ marginRight: "-5px", fontSize: "22px" }} /> <AccountCircleIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create"], "user", "users") ? key.sections.push({
            text: {
                title: "Create Users",
                description: "Create user profiles to grant your customers access to the DRM Portal."
            },
            link: `Users`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AddIcon style={{ marginRight: "-5px", fontSize: "22px" }} /> <PeopleIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["update"], "company", "companies") ? key.sections.push({
            text: {
                title: "Customize Account Portals",
                description: "Customize the appearance and functionality of your customer account portals."
            },
            link: `Accounts`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><FormatPaintIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create"], "user_type", "roles") ? key.sections.push({
            text: {
                title: "Create Roles",
                description: "Create permission-based roles to ensure your customers can manage the appropriate data."
            },
            link: "Roles",
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AddIcon style={{ marginRight: "-5px", fontSize: "22px" }} /> <PeopleIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        if (key.sections.length > 0) {
            key.hidden = false;
        }
    }

    define_manage_sections = (key) => {
        const { theme, classes } = this.props;
        this.can_add_section(["create"], "device", "devices") ? key.sections.push({
            text: {
                title: "Create a Single Device",
                description: "Provision a single device."
            },
            link: `Devices?new`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AddIcon style={{ marginRight: "-5px", fontSize: "22px" }} /><DvrIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create"], "software_update", "software") ? key.sections.push({
            text: {
                title: "Create Software Packages",
                description: "Create and upload software packages to be used in the management of your devices."
            },
            link: `Software`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AddIcon style={{ marginRight: "-5px", fontSize: "22px" }} /><CodeIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create"], "device", "devices") ? key.sections.push({
            text: {
                title: "Bulk Upload Devices",
                description: "Provision tens, hundreds, or thousands of devices through a single CSV file."
            },
            link: `Devices?upload`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><CloudUploadIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["update"], "device", "devices") &&
            this.can_add_section(["update", "create"], "software_update", "software") ? key.sections.push({
                text: {
                    title: "Issue Software Updates",
                    description: "Immediately issue software updates to all of your devices or groups of devices."
                },
                link: `Software`,
                icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><SystemUpdateIcon style={{ fontSize: "30px" }} /></div>)
            }) : "";
        this.can_add_section(["update"], "device", "devices") ? key.sections.push({
            text: {
                title: "Manage Devices in Bulk",
                description: "Manage and issue commands to all of your devices or groups of devices."
            },
            link: `Devices`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><QueueIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["update"], "device", "devices") &&
            this.can_add_section(["update", "create"], "software_update", "software") ? key.sections.push({
                text: {
                    title: "Schedule Software Updates",
                    description: "Set a window for software updates in case devices are currently offline."
                },
                link: `Software`,
                icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><EventAvailableIcon style={{ fontSize: "30px" }} /></div>)
            }) : "";
        this.can_add_section(["update", "create"], "ingestor", "ingest") ? key.sections.push({
            text: {
                title: "Manage Data Ingestion",
                description: "Define your pipeline for data ingestion: listeners, handlers, and translators."
            },
            link: `Ingestors`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><SettingsInputComponentIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        if (key.sections.length > 0) {
            key.hidden = false;
        }
    }

    define_orchestration_sections = (key) => {
        const { theme } = this.props;
        this.can_add_section(["create", "update"], "rule", "rules") ? key.sections.push({
            text: {
                title: "Create Policies",
                description: "Orchestrate your IoT by creating policies that monitor and generate alerts in reaction to data at the edge or in the cloud."
            },
            link: `Policies`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AddIcon style={{ marginRight: "-5px", fontSize: "22px" }} /><SecurityIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create"], "integration", "integrations") ? key.sections.push({
            text: {
                title: "Integrate",
                description: "Easily integrate your IoT with a third-party service such as AWS or Azure to best leverage your operational capacity."
            },
            link: `Integrations`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><ExtensionIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create", "update"], "rule", "rules") ? key.sections.push({
            text: {
                title: "Automated Reporting & Alerting",
                description: "Create policies that detect anomalies and generate alerts that can be sent via email, SMS, or a custom HTTP request."
            },
            link: `Policies`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AnnouncementIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["create", "update"], "rule", "rules") ? key.sections.push({
            text: {
                title: "Schedule Diagnostic Tests",
                description: "Keep track of your IoT's health by creating policies that routinely check status and diagnostic data."
            },
            link: `Policies`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><AssignmentLateIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["update"], "device", "devices") ? key.sections.push({
            text: {
                title: "Bulk Orchestration",
                description: "Attach policies to your all of your devices in order to easily get the data and alerts your business requires."
            },
            link: `Devices`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><MoveToInboxIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        if (key.sections.length > 0) {
            key.hidden = false;
        }
    }

    define_monitor_sections = (key) => {
        const { theme } = this.props;
        PortalConfiguration.navigationItemVisible("overview") ? key.sections.push({
            text: {
                title: "Diagnostic Dashboard",
                description: "Understand the entirety of your IoT via customizable data visualizations that monitor online status, alerts, command status, and more."
            },
            link: `Dashboard`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><PieChartIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["read"], "device_error", "messages") ? key.sections.push({
            text: {
                title: "Monitor Errors",
                description: "Keep track of anomalies."
            },
            link: `Messages`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><WarningIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["read"], "notification", "alerts") ? key.sections.push({
            text: {
                title: "View Alerts",
                description: "Stay up-to-date with your IoT's notifcations and alerts."
            },
            link: `Alerts`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><ReportProblemIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["read", "manage"], "rule", "rules") ? key.sections.push({
            text: {
                title: "Error Handling",
                description: "Manage the policies that detect and respond to errors."
            },
            link: `Policies`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><PlaylistAddCheckIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        this.can_add_section(["read", "manage"], "device_transfer_request", "device_transfer_request") ? key.sections.push({
            text: {
                title: "Device Transfers",
                description: "View and take action on device transfers created or received by your account."
            },
            link: `Transfers`,
            icon: (<div style={{ color: theme.palette.pending.main, display: "flex", alignItems: "center" }}><SwapHorizIcon style={{ fontSize: "30px" }} /></div>)
        }) : "";
        if (key.sections.length > 0) {
            key.hidden = false;
        }
    }

    define_keys = () => {
        const { classes } = this.props;
        let keys = [
            { display: "Monitoring", hidden: true, sections: [], img: <img src={require('../images/monitor.png')} style={{ width: "20px", height: "20px", marginRight: "26px", marginLeft: "-50px" }} /> },
            { display: "Management", hidden: true, sections: [], img: <img src={require('../images/manage.png')} style={{ width: "20px", height: "20px", marginRight: "26px", marginLeft: "-50px" }} /> },
            { display: "Orchestration", hidden: true, sections: [], img: <img src={require('../images/orchestrate.png')} style={{ width: "20px", height: "20px", marginRight: "26px", marginLeft: "-50px" }} /> },
            { display: "Onboard", hidden: true, sections: [], img: <img src={require('../images/onboard.png')} style={{ width: "20px", height: "20px", marginRight: "26px", marginLeft: "-50px" }} /> },
        ];
        this.define_onboard_sections(keys[3]);
        this.define_manage_sections(keys[1]);
        this.define_monitor_sections(keys[0]);
        this.define_orchestration_sections(keys[2]);
        this.state.keys = keys;
        let active_section_index = keys.findIndex((section) => (section.sections.length > 0));
        this.state.active_key = active_section_index !== -1 ? active_section_index : 0;
    }

    render_section = (section) => {
        const classes = this.props.classes;
        return (
            <div onClick={() => this.props.history.push(section.link)} className={classes.sectionContainer} key={section.text.title}>
                {section.icon}
                <div className={classes.titleWrapper}>
                    <div className={classes.sectionTitle}>
                        {section.text.title}
                    </div>
                </div>
                <div className={classes.sectionDescription}>
                    {section.text.description}
                </div>
            </div>
        )
    }

    render_service_worker_frame() {
        const classes = this.props.classes
        const company = Auth.currentCompany()
        const grafanaUrl = company.metadata && company.metadata.grafana_url

        if (!grafanaUrl) {
            return null
        }

        const serviceWorkerUrl = `${grafanaUrl}/sw/`

        return (
            <iframe src={serviceWorkerUrl} frameBorder={0} width={0} height={0} className={classes.hidden} />
        )
    }

    render() {
        const { classes } = this.props;
        const { keys, active_key } = this.state;
        let title = Auth.currentPortalTitle();
        if (keys === null) {
            return <Loading />
        }
        return (
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    {title}
                </div>
                <div className={classes.subtitleContainer}>
                    Welcome to best-in-class device management. Get started in one of the sections below.
                </div>
                <div className={classes.linkContainer}>
                    <a className={classes.link} href="http://dev.edgeiq.io/docs/">View the Documentation</a>
                    <a className={classes.link} href="mailto:support@edgeiq.io">Contact Support</a>
                </div>
                <div className={classes.keyContainer}>
                    {keys.map((key, index) => {
                        if (key.hidden) {
                            return "";
                        } else {
                            return (
                                <div onClick={() => this.setState({ active_key: index })} key={"key_" + index} className={classes.key + " " + (index === active_key ? classes.activeKey : "")}>
                                    <span>{key.img}</span>
                                    {key.display}
                                </div>
                            );
                        }
                    })}
                </div>
                <div className={classes.sectionsContainer}>
                    {keys[active_key].sections.map(this.render_section)}
                </div>
                {this.render_service_worker_frame()}
            </div>
        );
    }
}

export default withStyles(styles)(withTheme()(ActionHub));
