import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Device from '../../services/DataModels/Device';
import DeviceHAGroup from '../../services/DataModels/DeviceHAGroup';

//steps
import Step1 from './CreateFlowSteps/Step1';
import Step2 from './CreateFlowSteps/Step2';
import Step3 from './CreateFlowSteps/Step3';
import Step4 from './CreateFlowSteps/Step4';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		stepContentContainer: {
			fontFamily: "Inter",
			justifyContent: "space-between",
			flexDirection: "column",
			minHeight: "528px"
		},
		modalWrapper: {
		},
		stepperRoot: {
			padding: 0,
			marginBottom: "32px",
		},
	})
};

class HAGroupCreationFlow extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			active_step: 0,
			ha_group: null,
			greengrass_integration: {name: "", _id: ""}
		};
		this.steps = ["Define Basic Information", "Assign Controller", "Assign Nodes", "Review"];
		this.step_render_functions = [
			() => (<Step1 companySet={this.state.company_set} next={this.next} closeModal={this.props.closeModal} />),
			() => (<Step2 getInterfaceOptions={this.get_interface_options} types={this.state.device_type_options} integration={{ value: this.state.greengrass_integration._id, display: this.state.greengrass_integration.name }} company={{ display: this.state.account_name, value: this.state.company_id }} gateway_options={this.state.gateway_options} integration_options={this.state.integration_options} next={this.next} back={this.back} closeModal={this.props.closeModal}/>),
			() => (<Step3 getInterfaceOptions={this.get_interface_options} types={this.state.device_type_options} integration={{ value: this.state.greengrass_integration._id, display: this.state.greengrass_integration.name }} company={{ display: this.state.account_name, value: this.state.company_id }} gateway_options={this.state.gateway_options} controller_device={this.state.controller_device} next={this.next} back={this.back} closeModal={this.props.closeModal}/>),
			() => (<Step4 create_ha_group={this.create_ha_group} ha_group={this.state.ha_group} next={this.next} back={this.back} closeModal={this.props.closeModal} />),
		];
	}

	get_interface_options = (config) => {
		let options = [];
		config.connections.forEach( (connection) => {
			let name = connection.interface && connection.interface !== "" ? connection.interface : connection.name;
			if (connection.type === "ethernet-wan" && connection.config.net_ip_assign === "static") {
				options.push(name);
			} else if (connection.type === "ethernet-wan-lan" && connection.config.net_mode.toUpperCase() === "WAN" && connection.config.net_ip_assign === "static") {
				options.push(name);
			} else if (connection.type === "wifi" && connection.config.wifi_mode === "client" && connection.config.net_ip_assign === "static") {
				options.push(name);
			}
		});
		return options.length > 0 ? options.map( (option) => ({display: option, value: option})) : null;
	}

	back = () => {
		this.setState((state) => ({active_step: state.active_step - 1}));
	}

	assign_data = () => {
		let promises = [this.update_controller(), this.update_nodes()];
		return new Promise( (resolve, reject) => {
			Promise.all(promises).then( (results) => {
				let controller = results[0];
				let nodes = results[1];
				resolve(this.build_body(controller, nodes));
			}).catch( (error) => {
				reject(error);
			})
		});
	}

	build_body = (controller, nodes) => {
		let ha_group_body = {
			company_id: this.state.ha_group.company_id,
			name: this.state.ha_group.name,
			ports: this.state.ha_group.ports,
			controller_device: {
				device_id: controller.device._id,
				interface: controller.interface
			},
			node_devices: nodes.map( (node) => ({device_id: node.device._id, interface: node.interface}))
		};
		return ha_group_body;
	}

	update_controller = () => {
		let body = null;
		if (this.state.ha_group.controller_device.whole.device_config_id !== this.state.ha_group.controller_device.nested_device_config._id) {
			body = {device_config_id: this.state.ha_group.controller_device.nested_device_config._id};
		}
		if (this.state.ha_group.controller_device.whole.device_integration_id) {
			body = body ? Object.assign(body, {device_integration_id: this.state.greengrass_integration._id}) : {device_integration_id: this.state.greengrass_integration._id};
		}
		if (!body) {
			return Promise.resolve({device: this.state.ha_group.controller_device.whole, interface: this.state.ha_group.controller_device.interface});
		}
		body._id = this.state.ha_group.controller_device.whole._id;
		return new Promise( (resolve, reject) => {
			new Device(body).createOrSave().then( (result) => {
				let prepped = {
					device: result,
					interface: this.state.ha_group.controller_device.interface,
				};
				resolve(prepped);
			}).catch( (error) => {
				console.log(error);
				reject();
			})
		});
	}

	update_nodes = () => {
		return new Promise( (resolve, reject) => {
			let promises = [];
			let prepped_nodes = [];
			this.state.ha_group.node_devices.forEach( (node) => {
				if (node.device.device_integration_id) {
					let body = {_id: node.device._id, device_integration_id: this.state.ha_group.greengrass_integration._id};
					promises.push(new Device(body).createOrSave());
				} else {
					prepped_nodes.push(node);
				}
			});
			Promise.all(promises).then( (results) => {
				results.forEach( (node) => {
					let state_node = this.state.ha_group.node_devices.find( (state_node) => state_node.device._id === node._id);
					let prepped_node = {
						device: node,
						config: state_node.config,
						interface: state_node.interface,
					};
					prepped_nodes.push(prepped_node);
				});
				resolve(prepped_nodes);
			}).catch( (error) => {
				console.log(error);
				reject(error);
			})
		});
	}

	create_ha_group = () => {
		this.assign_data()
			.then(this.create)
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	create = (body) => {
		new DeviceHAGroup(body).createOrSave().then( (result) => {
			this.context.openSnackbar("HA Group created.", "success");
			this.props.tabHostProxy.refresh();
			this.props.closeModal();
		});
	}

	next = (passing) => {
		if (this.state.active_step === 0) {
			this.setState({company_set: true});
		}
		if (this.state.active_step === 2) {
			let ha_group = {
				name: this.state.name,
				ports: this.state.ports,
				company_id: this.state.company_id,
				account_name: this.state.account_name,
				controller_device: this.state.controller_device,
				node_devices: passing.node_devices,
				greengrass_integration: this.state.greengrass_integration
			};
			this.setState( (state) => ({ha_group: ha_group, active_step: state.active_step + 1}));
			return;
		}
		this.setState( (state) => {
			state = Object.assign(state, passing);
			state.active_step = state.active_step + 1;
			return state;
		});
	}

	render() {
		const { classes } = this.props;
		const { active_step } = this.state;
		return (
			<div className={classes.modalWrapper}>
				<div>
					<Stepper classes={{root: classes.stepperRoot}} activeStep={active_step}>
						{this.steps.map( (label, index) => (
							<Step key={label}>
								<StepLabel></StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
				{this.steps.map( (step, index) => {
					return index !== active_step ? 
						<div key={step + "_content"} className={classes.stepContentContainer} style={{display: "none"}}>{this.step_render_functions[index]()}</div>
					: 
						<div className={classes.stepContentContainer} key={step + "_content"} style={{display: "flex"}}>{this.step_render_functions[index]()}</div>
				})}
			</div>
		);
	}
}
HAGroupCreationFlow.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(HAGroupCreationFlow));