import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import NumberInput from './NumberInput';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
	container: {
		display: "flex"
	},
	menuTitleContainer: {
		display: "flex",
		alignItems: "center",
	},
	menuIcon: {
		padding: "4px",
	},
	menuIconContainer: {
		flexGrow: 2,
		textAlign: "right"
	}
});

class TimeInput extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.times = ["Seconds", "Minutes", "Hours", "Days"];
		this.state = {
			anchorEl: null,
		};
	}

	render() {
		const { classes, disabled } = this.props;
		return (
			<div className={classes.container}>
				<NumberInput
					minimum={0}
					margin={this.props.margin}
					label={this.props.priorState.time}
					priorState={this.props.priorState.value}
					emitChange={this.localChange}
					disabled={disabled}
				/>
				{disabled ? "" : this.renderMenu(classes)}
			</div>
		)
	}

	localChange = ({value}) => {
		this.props.emitChange({value: value, time: this.props.priorState.time});
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget});
	};
	
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	toggleTime = (time) => {
		this.props.emitChange({value: this.props.priorState.value, time: time});
		this.handleClose();
	}

	renderMenu = (classes) => {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		return (
			<div className={classes.menuTitleContainer}>
				<div className={classes.menuIconContainer}>
					<IconButton
						className={classes.menuIcon}
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={this.handleClick}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
				>
					{this.times.map( (time) => (
						<MenuItem selected={time === this.props.priorState.time} key={time} onClick={() => this.toggleTime(time)}>
							<ListItemText>
								{time}
							</ListItemText>
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(TimeInput));
