import React from 'react';
import { compose } from 'recompose';
import Loading from '../DisplayOriented/Loading';
import TableList from '../Table/TableList';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import DeviceTransferRequest from '../../services/DataModels/DeviceTransferRequest';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';

class TransferRequestForm extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			editable_copy: {
				to_company_id: '',
				device_type_id: "",
				escrow_device_ids: ""
			},
			show_errors: false,
			submitting: false,
			verifying: false
		};
		this.set_form();
		this.load_device_type_options();
		this.set_heading_info();
	}

	set_heading_info = () => {
		this.heading_info = [
			{label: "Unique ID", value: "unique_id", field: "unique_id", nested_field: false, align: "left", disablePadding: false, sortable: false},
			{label: "Account", value: "nested_company.name", nested_field: true, field: "name", align: "left", disablePadding: false, sortable: false},
			{label: "Token", value: "token", nested_field: false, field: "token", align: "left", disablePadding: false, sortable: false},
		];
	}
	

	set_form = () => {
		const escrow_devices = this.props.escrowDevices;
		if (escrow_devices && Array.isArray(escrow_devices) && escrow_devices.length > 0 ) {
			const escrow_device_ids = escrow_devices.map( ({_id}) => _id);
			this.state.editable_copy.escrow_device_ids = escrow_device_ids;
		}
	}

	load_device_type_options = () => {
		GetAll("device_types").then( (result) => {
			let device_types = result || [];
			device_types.push({name: "Select a Device Type*", _id: ''});
			this.setState({device_type_options: device_types.map( (type) => ({display: type.name, value: type._id}) )});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	on_form_change = ({field, value}) => {
		this.setState( (state) => {
			state.editable_copy[field] = value;
			return state;
		});
	}

	render_title = () => {
		const classes = this.props.classes;
		return (
			<React.Fragment>
				<div className={classes.modalTitle}>
					Creating a Device Transfer Request
				</div>
				<div className={classes.modalSubtitle}>
					Set the account that will receive the escrow devices and define the device type for the selected escrow devices.
				</div>
			</React.Fragment>
			
		);
	}

	render_device_type = () => {
		const { classes } = this.props;
		const { editable_copy, device_type_options, show_errors } = this.state;
		let input = (<SelectInput
			label="Device Type*"
			field="device_type_id"
			emitChange={this.on_form_change}
			priorState={editable_copy.device_type_id}
			options={device_type_options}
			error={show_errors && editable_copy.device_type_id === ""}
			error_message={"Please select a device type for the escrow device(s)."}
		/>);
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<DvrIcon className={classes.inputIcon}/>
				{input}
			</div>
		);
	}

	render_buttons = () => {
		const { closeModal, classes, backOverride } = this.props;
		const { verifying } = this.state;
		const cancel = Boolean(backOverride) ? backOverride : closeModal;
		return (
			<div className={classes.buttonContainer}>
				<Button onClick={cancel} aria-label="cancel" className={classes.buttonOverride} color="primary">
					{Boolean(backOverride) ? "Back" : "Cancel"}
				</Button>
				<Button onClick={this.submit}
					disabled={verifying}
					variant="contained"
					className={classes.buttonOverride}
					aria-label="create"
					color="primary"
					size="large"
				>
					Issue Transfer Request
				</Button>
			</div>
		);
	}

	submit = () => {
		this.setState( (state) => {
			state.editable_copy.escrow_device_ids = this.props.escrowDevices.map( ({_id}) => _id);
			state.verifying = true;
			return state;
		});
		const { editable_copy } = this.state;
		if (editable_copy.to_company_id === "" || editable_copy.device_type_id === "") {
			this.setState({show_errors: true, verifying: false});
			return;
		}
		this.setState({submitting: true});
		new DeviceTransferRequest(editable_copy).setData(editable_copy).saveOrCreate().then( (result) => {
			let message = "Transfer request successfully issued.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate();
		}).catch( (error) => {
			this.setState({verifying: false, submitting: false});
			this.context.openSnackbar(error, "error");
		});
	}

	render_account = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon}/>
				<TextInput
					label="Account ID*"
					emitChange={this.on_form_change}
					priorState={editable_copy.to_company_id}
					field="to_company_id"
					error={show_errors && editable_copy.to_company_id === ""}
					error_message={"Please define the account's ID that will receive the device transfer request."}
				/>
			</div>
		);
	}

	render_escrow_devices = () => {
		const { escrowDevices, classes } = this.props;
		return (
			<div className={classes.inputsContainer}>
				<div className={classes.infoText}>Escrow Devices to be transferred:</div>
				<TableList
					headings={this.heading_info} 
					items={escrowDevices}
					perPage={5}
				/>
			</div>
		);
	}

	render() {
		const { classes, hideTitle } = this.props;
		const { submitting, device_type_options } = this.state;
		if (submitting || device_type_options == null ) {
			return (
				<div className={classes.container}>
					<Loading />
				</div>
			);
		}
		return (
			<div className={classes.container}>
				{hideTitle ? "" : this.render_title()}
				<div className={classes.inputWrapper}>
					{this.render_account()}
					{this.render_device_type()}
					{this.render_escrow_devices()}
				</div>
				{this.render_buttons()}
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			boxSizing: "border-box",
			minHeight: "500px",
		},
		inputWrapper: {
			height: "444px",
			overflowY: "auto",
			paddingTop: "5px",
			paddingRight: "16px",
		},
		modalTitle: {
			fontSize: "20px",
			lineHeight: "32px",
			fontWeight: "700",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
		},
		modalSubtitle: {
			color: "#8e8e93",
			fontSize: "14px",
			marginBottom: "16px",
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "32px"
		},
		selectInputWrapper: {
			marginTop: "18px",
			minHeight: "78px",
			marginBottom: "26px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		idContainer: {
			display: "flex",
			alignItems: "center",
			padding: "5px",
			marginRight: "12px",
			marginBottom: "20px",
			boxSizing: "border-box",
			width: "24px"
		},
		idLabel: {
			fontSize: "16px",
			color: "rgba(0,0,0,.42)",
			marginRight: "6px"
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		inputsContainer: {
			marginLeft: "36px",
			borderLeft: "solid lightgrey 1px",
			paddingLeft: "12px",
			marginBottom: "48px",
			paddingTop: "12px"
		},
		infoText: {
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "14px",
			marginBottom: "12px"
		},
	});
};

TransferRequestForm.contextType = SnackbarContext;

export default compose(
	withStyles(styles),
)(TransferRequestForm);