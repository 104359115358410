import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class Config extends DataModel {
	constructor(data = BLANK) {
		super('device_configs', data);
	}

	static loadRequirements(configs) {
		return new Promise( (resolve, reject) => {
			let promises = [
				AssignNestedModels("device_types", "device_type_id", configs),
				AssignNestedModels("companies", "company_id", configs)
			];
			Promise.all(promises).then( () => {
				resolve(configs);
			});
		});
	}

}

const BLANK = {
	name: "",
	device_type_id: "",
	company_id: "",
	connections: [],
	device_ids: []
}

export default Config;
