import { API_PROXY } from './CLURD';
import Auth from './Auth';
import qs from 'qs';

export function GET_RELATIONS(model, model_id, relation_model, params = {}){
    const token = Auth.token();
    let env = window.API;
    if (env.charAt(env.length - 1) !== '/') {
        env += "/";
    }
    const options = {
        method: "GET",
        url: `${env}${model}/${model_id}/${relation_model}`,
        headers: {
            'accept': 'application/json',
            'authorization': token,
        }
    };
    // params.page_meta = true;
    params = "?" + qs.stringify(params, { encode: false });
    options.url += params;
    return new Promise( (resolve, reject) => {
        API_PROXY(options, token).then( (result) => {
            resolve(result.data);
        }).catch( (error) => {
            reject(error);
        });
    });
}

export function ADD_RELATION(model, model_id, relation_model, relation_id){
    const token = Auth.token();
    let env = window.API;
    if (env.charAt(env.length - 1) !== '/') {
        env += "/";
    }
    const options = {
        method: "PUT",
        url: `${env}${model}/${model_id}/${relation_model}/${relation_id}`,
        headers: {
            'accept': 'application/json',
            'authorization': token,
        }
    };
    return new Promise( (resolve, reject) => {
        API_PROXY(options, token).then( (result) => {
            resolve(result);
        }).catch( (error) => {
            reject(error);
        });
    });
}

export function ADD_RELATIONS(model, model_id, relation_model, relation_ids){
    const token = Auth.token();
    let env = window.API;
    if (env.charAt(env.length - 1) !== '/') {
        env += "/";
    }
    let body = {
        ids: relation_ids
    };
    const options = {
        method: "PUT",
        data: body,
        url: `${env}${model}/${model_id}/${relation_model}/bulk`,
        headers: {
            'accept': 'application/json',
            'authorization': token,
        }
    };
    return new Promise( (resolve, reject) => {
        API_PROXY(options, token).then( (result) => {
            resolve(result);
        }).catch( (error) => {
            reject(error);
        });
    });
}

export function REMOVE_RELATION(model, model_id, relation_model, relation_id){
    const token = Auth.token();
    let env = window.API;
    if (env.charAt(env.length - 1) !== '/') {
        env += "/";
    }
    const options = {
        method: "DELETE",
        url: `${env}${model}/${model_id}/${relation_model}/${relation_id}`,
        headers: {
            'accept': 'application/json',
            'authorization': token,
        }
    };
    return new Promise( (resolve, reject) => {
        API_PROXY(options, token).then( (result) => {
            resolve(result);
        }).catch( (error) => {
            reject(error);
        });
    });
}

export function REMOVE_RELATIONS(model, model_id, relation_model, relation_ids){
    const token = Auth.token();
    let env = window.API;
    if (env.charAt(env.length - 1) !== '/') {
        env += "/";
    }
    let body = {
        ids: relation_ids
    };
    const options = {
        method: "DELETE",
        data: body,
        url: `${env}${model}/${model_id}/${relation_model}/bulk`,
        headers: {
            'accept': 'application/json',
            'authorization': token,
        }
    };
    return new Promise( (resolve, reject) => {
        API_PROXY(options, token).then( (result) => {
            resolve(result);
        }).catch( (error) => {
            reject(error);
        });
    });
}
