import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';

//mui
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//inputs
import SelectInput from '../Inputs/SelectInput';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

//icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
		width: "100%"
	},
	title: {
		fontSize: "20px",
	},
	topRow: {
		height: "calc(100% - 48px)",
		overflowY: "auto",
	},
	bottomRow: {
		width: "100%",
		display: "flex",
		flexWrap: "nowrap"
	},
	filterContainer: {
		width: "50%",
		marginLeft: "6px"
	},
	ctaText: {
		marginRight: "8px",
		height: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	linkButton: {
		marginLeft: "auto",
		color: theme.palette.pending.main,
		'&:hover': {
			backgroundColor: "white",
			color: '#0263FC',
		},
	},
	tabRoot: {
		minWidth: "unset"
	},
	wrapper: {
		padding: "12px"
	}
});

class ListWidget extends React.Component {
	
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			items: null,
			filter: this.props.filterOptions ? this.props.filterOptions[0].value : null,
			tab: 0
		};
		this.refresh();
	}

	refresh = () => {
		if (this.state.items !== null) this.setState({items: null});
		let { load, multiple } = this.props;
		const { filter, tab } = this.state;
		if (multiple) {
			load = load[tab];
		}
		load(filter).then((items) => this.setState({items: items}));
	}

	render_link = () => {
		let { link, multiple, classes } = this.props;
		if (multiple) {
			link = link[this.state.tab];
		}
		if (!link) return "";
		return (
			<Button className={classes.linkButton} onClick={this.link}>
				<span className={classes.ctaText}>
					{link.display}
				</span>
				<ArrowForwardIcon className={classes.ctaArrow}/>
			</Button>
		);
	}

	tab_change = (event, new_value) => {
		this.setState({tab: new_value}, this.refresh);
	}

	render_tabs = () => {
		const { title, classes } = this.props;
		return (
			<Tabs value={this.state.tab} onChange={this.tab_change} aria-label="tabs widget">
				{title.map( (section) => <Tab classes={{root: classes.tabRoot, labelContainer: classes.wrapper}} key={section} label={section} />)}
			</Tabs>
		);
		
	}

	link = () => {
		let { link, multiple } = this.props;
		if (multiple) {
			link = link[this.state.tab];
		}
		this.props.history.push(link.link);
	}

	handle_change = ({field, value}) => {
		this.setState({items: null});
		this.setState( (state) => {
			state.filter = value;
			state.items = null;
			return state;
		}, this.refresh);
	}

	render_bottom_row = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.bottomRow}>
				{this.render_select_filter()}
				{this.render_link()}
			</div>
		);
	}

	render_select_filter = () => {
		const {filterOptions, classes } = this.props;
		if (!filterOptions) return "";
		return (
			<div className={classes.filterContainer}>
				<SelectInput
					basic
					priorState={this.state.filter}
					emitChange={this.handle_change}
					options={filterOptions}
				/>
			</div>
		);
	}

	render() {
		let { title, headings, multiple, hover, classes } = this.props;
		const { items, tab } = this.state;
		if (multiple) {
			title = title[tab];
			headings = headings[tab];
			hover = hover[tab];
		}
		return (
			<div className={classes.container}>
				<div className={classes.topRow}>
					{multiple ? 
						this.render_tabs() :
						title ? <div className={classes.title}>
							{title}
						</div> : ""
					}
					{items === null ? 
						<Loading />
						:
							<TableList
								headings={headings} 
								items={items}
								noBoxShadow
								noCheckBox
								noPadding
								hover={hover}
							/>
					}
				</div>
				{this.render_bottom_row()}
			</div>
		);
	}
}

ListWidget.contextType = SnackbarContext;
export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)
(ListWidget);