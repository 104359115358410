/* eslint-disable no-unused-expressions */
import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Button from '@material-ui/core/Button';
import Device from '../../services/DataModels/Device';
import TableList from '../Table/TableList';
import PaginationContainer from '../Containers/PaginationContainer';
import Loading from '../DisplayOriented/Loading';
import SelectInput from '../Inputs/SelectInput';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Permissions from '../../services/Permissions';

// icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';

const styles = theme => ({
    container: {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexWrap: "wrap",
    },
    contentContainer: {
        overflowY: "auto",
        height: "calc(100% - 57px)",
        width: "100%"
    },
    paginationContainer: {
        width: "100%"
    },
    prompt: {
        fontFamily: "Inter",
        fontSize: "20px",
        lineHeight: "32px",
        fontWeight: "700",
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom: "32px",
    },
    link: {
        color: theme.palette.pending.main,
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        }
    },
    modalWrapper: {
        minHeight: "450px",
    },
    buttonRow: {
        marginTop: "32px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    saveButton: {
        marginLeft: "8px",
    },
    infoIcon: {
        fontSize: "28px",
        marginRight: "8px",
    },
    actionInfo: {
        fontFamily: "Inter",
        color: "grey",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "14px",
    },
    actionError: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        marginTop: "8px"
    },
    errorIcon: {
        color: "red",
        marginRight: "8px",
        fontSize: "28px"
    },
    link: {
        color: theme.palette.pending.main,
        "-webkit-text-decoration": "underline transparent",
        textDecoration: "underline transparent",
        transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            "-webkit-text-decoration-color": theme.palette.pending.main,
            textDecorationColor: theme.palette.pending.main,
            cursor: "pointer",
        }
    },
    newConnectionContainer: {
        height: "312px",
    },
});

const heading_info = [
    { label: "Created At", value: "created_at", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date" },
    { label: "Download Link", value: "table_link", field: "table_link", align: "center", disablePadding: false, sortable: false },
    { label: "Size", value: "size", field: "size", align: "right", disablePadding: false, sortable: false },
];

class DeviceLogs extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.startingPerPage = 12;
        this.state = {
            logs: null,
            page_data: {
                page: 1,
                per_page: this.startingPerPage,
                page_meta: true,
                total: null
            },
            modal: {
                open: false,
                children: () => "",
            },
            action: {
                display: "",
                value: "",
                level: "",
            },
            bulk_configure_shown: false,
            sort: null,
            log_config: this.props.device.log_config,
        };
        if (this.props.device.log_config != null) {
            this.state.log_config = this.state.log_config;
            this.state.local_level = this.state.log_config.local_level;
            this.state.forward_level = this.state.log_config.forward_level;
        } else {
            this.state.log_config = {};
            this.state.local_level = "";
            this.state.forward_level = "";
        };
        this.load_logs();
        this.buttons = [];
        if (Permissions.allow(["log_upload"], "device", this.props.device.company_id) && this.props.device.nested_device_type.capabilities.actions.log_upload === true) {
            this.buttons.push({ onSelectShow: false, display: "REQUEST LOGS", label: "REQUEST LOGS", value: "log_upload", icon: (<CloudUploadIcon />), action: this.uploadLog, function: this.uploadLog });
        }
        if (Permissions.allow(["update"], "device", this.props.device.company_id)) {
            this.buttons.push({ onSelectShow: false, display: "EDIT LOG LEVELS", label: "EDIT LOG LEVELS", icon: (<EditIcon />), action: this.openModal, function: this.openModal });
        }
        this.localLogLevelOptions = [
            { display: "Trace", value: "trace" },
            { display: "Debug", value: "debug" },
            { display: "Info", value: "info" },
            { display: "Warning", value: "warn" },
            { display: "Error", value: "error" },
            { display: "Critical", value: "critical" }
        ];
        this.forwardLogLevelOptions = [
            { display: "Warning", value: "warn" },
            { display: "Error", value: "error" },
            { display: "Critical", value: "critical" }
        ];
    };

    openModal = () => {
        this.setState({
            modal: {
                "open": true,
                children: this.renderEditLogs
            }
        });
    }

    close_modal = () => {
        let newState = {
            action: {
                display: "",
                value: "",
            },
            modal: {
                open: false, children: () => ""
            },
        };
        this.setState(newState);
    }

    changeLogs = ({ value, field }) => {
        let new_state = {};
        new_state[field] = value;
        this.setState(new_state)
    }

    renderEditLogs = (classes) => {
        return (
            <div className={classes.modalWrapper}>
                <div className={classes.prompt}>
                    Edit Log Levels
                </div>
                <React.Fragment>
                    <div className={classes.newConnectionContainer}>
                        <div className={classes.actionInfoContainer}>
                            <div className={classes.actionInfo}>
                                Local Log Level
                            </div>
                        </div>
                        <SelectInput
                            field="local_level"
                            emitChange={this.changeLogs}
                            priorState={this.state.local_level === "" ? "error" : this.state.local_level}
                            options={this.localLogLevelOptions}
                        />
                        <div className={classes.actionInfoContainer}>
                            <div className={classes.actionInfo}>
                                Forward Log Level
                            </div>
                        </div>
                        <SelectInput
                            field="forward_level"
                            emitChange={this.changeLogs}
                            priorState={this.state.forward_level === "debug" || this.state.forward_level === "info" || this.state.forward_level === "" ? "error" : this.state.forward_level}
                            options={this.forwardLogLevelOptions}
                        />
                    </div>
                    <div className={classes.buttonRow}>
                        <Button onClick={this.close_modal} color="primary">Cancel</Button>
                        <Button onClick={this.saveLogs} className={classes.saveButton} variant="contained" color="primary" size="large">Save and Close</Button>
                    </div>
                </React.Fragment>
            </div>
        );
    }

    saveLogs = () => {
        let device_model = new Device(this.props.device);
        let new_device = device_model.editableCopy();
        new_device.log_config = {};
        new_device.log_config.local_level = this.state.local_level === "" ? "error" : this.state.local_level;
        new_device.log_config.forward_level = this.state.forward_level === "" ? "error" : this.state.forward_level;
        device_model.setData(new_device).save().then((result) => {
            this.props.onSave(result);
            this.context.openSnackbar('Log level changed.', 'success');
            this.close_modal;
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    uploadLog = () => {
        Device.issueGatewayCommand("log_upload", null, this.props.device._id).then((result) => {
            this.context.openSnackbar('Command sent to device', 'success');
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    bulkConfigure = () => {
        if (this.state.bulk_configure_shown === true || this.props.all_selected || this.props.selections.length > 0) {
            this.setState({ bulk_configure_shown: false }, this.props.clear_selections);
        } else {
            this.setState({ bulk_configure_shown: true });
        }
    }

    show_data = (data) => {
        let i = -1;
        let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        if (data == 0) {
            return "0" + byteUnits[1];
        }
        do {
            data = data / 1000;
            i++;
        } while (data > 1000);
        return Math.max(data, 0.1).toFixed(1) + byteUnits[i];
    }

    load_logs = () => {
        let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
        let device_id = this.props.device._id;
        new Device.list_logs(device_id, params)
            .then((result) => {
                let logs = result.data.files;
                logs.forEach((log) => {
                    const url = log.url ? log.url : log.link ? log.link : null;
                    log.created_at = this.showDate(log.created_at) + " " + this.showTime(log.created_at);
                    log.table_link = url ? <a href={url} target="_blank" className={this.props.classes.link} download>Download Log</a> : <span>Link Not Available</span>;
                    log.size = this.show_data(log.size);
                    log._id = log.created_at + "_" + log.size + "_" + url;
                });
                this.setState({
                    logs: logs,
                    page_data: {
                        page: params.page,
                        per_page: params.per_page,
                        page_meta: true,
                        total: result.data.count
                    },
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
    }

    handleChangePage = (event, page) => {
        this.page_change({ page: page + 1, per_page: this.state.page_data.per_page });
    };

    handleChangeRowsPerPage = event => {
        this.page_change({ page: this.state.page_data.page, per_page: event.target.value });
    };

    perform_sort = (sort_info) => {
        let sort = { order_by: "" };
        sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
        this.setState({ sort: sort }, () => {
            this.load_logs();
        });
    }

    render() {
        const { classes } = this.props;
        const { logs, page_data } = this.state;
        this.buttons.style = (this.state.bulk_configure_shown);
        if (logs === null) return (<Loading />);
        return (
            <Paper className={classes.container}>
                <React.Fragment>
                    <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                        {this.state.modal.children(classes)}
                    </SimpleModalWrapped>
                    <div className={classes.paginationContainer}>
                        <PaginationContainer
                            buttons={this.buttons}
                            count={page_data.total}
                            rowsPerPage={page_data.per_page}
                            currentPage={page_data.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                    <div className={classes.contentContainer}>
                        <TableList
                            headings={heading_info}
                            items={logs}
                            noBoxShadow
                            page_data={page_data}
                            perform_sort={this.perform_sort}
                        />
                    </div>
                </React.Fragment>
            </Paper>
        )
    }

    showDate = (utc) => {
        let date_object = new Date(utc);
        return date_object.toLocaleDateString();
    }

    showTime = (utc) => {
        let date_object = new Date(utc);
        return date_object.toLocaleTimeString();
    }
}

DeviceLogs.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceLogs))
