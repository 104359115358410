import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	root: {
	},
	colorSwitchBase: {
		'&$colorChecked': {
			color: "#1153b6",
			'& + $colorBar': {
				backgroundColor: "#1153b6",
				opacity: "0.3",
			},
		},
	},
	colorBar: {},
	colorChecked: {},
	label: {
		textTransform: "uppercase",
		fontSize: "14px",
		color: "#8e8e93",
		display: "inline",
		fontFamily: "Inter",
	},
	on: {
		color: "#1153b6",
	},
	off: {
		color: "#8e8e93",
	}
});

class SwitchInputRadio extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
	}

	localChange = () => {
		this.on = !this.on;
		this.props.emitChange({ label: this.props.label, value: this.on });
	}

	render() {
		const { label, disabled, initial, classes } = this.props;
		this.on = initial || "";
		return (
			<div className={classes.container}>
				<div className={classes.label + " " + (this.on ? "" : classes.off)}>
					{label}
				</div>
				<Switch
					disabled={disabled}
					checked={this.on}
					onChange={this.localChange}
					classes={{
						switchBase: classes.colorSwitchBase,
						checked: classes.colorChecked,
						bar: classes.colorBar,
						root: classes.root,
					}}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(SwitchInputRadio));
