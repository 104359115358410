import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';
import {
    convert_objects_indexes_for_save,
    convert_objects_indexes_for_ui,
} from "../../components/PollableAttributesSpecific/helpers";

class PollableAttribute extends DataModel {

    constructor(data) {
        super("pollable_attributes", data);
    }

    static loadRequirements(pollable_attributes) {
        return new Promise((resolve, reject) => {
            Promise.all([AssignNestedModels("companies", "company_id", pollable_attributes)]).then((result) => {
                resolve(pollable_attributes);
            }).catch((error) => reject(error));
        });
    }

    static convertForUI(pollable_attribute) {
        if (pollable_attribute.mib && pollable_attribute.mib.objects) {
            convert_objects_indexes_for_ui(pollable_attribute.mib.objects);
        }
        if (pollable_attribute.notifications) {
            convert_objects_indexes_for_ui(pollable_attribute.notifications);
        }
        return {
            company_id: pollable_attribute.company_id,
            created_at: pollable_attribute.created_at,
            name: pollable_attribute.name,
            origin: pollable_attribute.origin,
            type: pollable_attribute.type,
            updated_at: pollable_attribute.updated_at,
            user_id: pollable_attribute.user_id,
            value: pollable_attribute.value,
            _id: pollable_attribute._id,
            interval: pollable_attribute.interval,
            attributes: pollable_attribute.attributes,
            mib: pollable_attribute.mib,
            notifications: pollable_attribute.notifications,
            defaults: pollable_attribute.defaults
        };
    }

    static isValidJSON(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static convertForSave(pollable_attribute, defaults) {
        let map_default = {};
        defaults.map((item, item_index) => {
            return map_default[item.name] = item.value
        });
        if (pollable_attribute.mib && pollable_attribute.mib.objects) {
            convert_objects_indexes_for_save(pollable_attribute.mib.objects);
        }
        if (pollable_attribute.notifications) {
            convert_objects_indexes_for_save(pollable_attribute.notifications);
        }
        return {
            company_id: pollable_attribute.company_id,
            name: pollable_attribute.name,
            type: pollable_attribute.type,
            interval: parseInt(pollable_attribute.interval),
            defaults: map_default,
            attributes: pollable_attribute.attributes,
            mib: pollable_attribute.mib,
            notifications: pollable_attribute.notifications,
            _id: pollable_attribute._id ? pollable_attribute._id : null
        };
    }

    static validate(editableCopy) {
        let validationMessages = {};
        let o = editableCopy;
        if (o.name == null || o.name.length == 0) {
            validationMessages.name = ["Name is required"];
        }
        if (o.interval == null || o.interval.length == 0 || o.interval < 15) {
            validationMessages.interval = ["Interval is required and must be greater than 15"];
        }
        if (Object.keys(validationMessages).length > 0) {
            validationMessages.valid = false;
        } else {
            validationMessages.valid = true;
        }

        return validationMessages;
    }

    static getTypes = () => {
        return ([
            { display: "SNMP MIB", value: "snmp_mib" },
            { display: "SNMP Trap", value: "snmp_trap" }
        ])
    }

    static getDataTypes = () => {
        return ([
            { display: "SNMP MIB Integer", value: "snmp_mib_integer" },
            { display: "SNMP MIB Octet String", value: "snmp_mib_octet_string" }
        ])
    }
}

export default PollableAttribute;
