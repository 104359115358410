import DataModel from "./DataModel";

class WorkflowDefinition extends DataModel {
	constructor() {
		super('workflow_definitions')
	}

}

export default WorkflowDefinition;

