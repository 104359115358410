import React from 'react';
import classNames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Loading from '../DisplayOriented/Loading';

let counter = 0;
function createData(name, manufacturer, model, online_status, config) {
	counter += 1;
	return { id: counter, name, manufacturer, model, online_status, config };
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {

	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { onSelectAllClick, selectOne, noSelect, order, orderBy, numSelected, rowCount, headings, root_class, tableId, tableTitle, classes } = this.props;
		return (
			<TableHead classes={{root: root_class}}>
				<TableRow key={tableId + "_" + tableTitle + "_header"}>
					{ noSelect || selectOne ? 
						<TableCell padding="checkbox">
						</TableCell>
					:
						<TableCell padding="checkbox">
							<Checkbox
								indeterminate={numSelected > 0 && numSelected < rowCount}
								checked={numSelected === rowCount}
								onChange={onSelectAllClick}
							/>
						</TableCell>
					}
					{this.props.has_actions && this.props.has_rows ?
						[...Array(this.props.has_actions)].map( (und, index) => (
							<TableCell className={classes.actionCell} key={tableId + "_" + tableTitle + "_action_icon_padding_cell" + index} padding="none">
							</TableCell>
						))
					: <TableCell style={{display: "none"}} padding="checkbox">
						</TableCell>
					}
					{headings.map( (row, index) => {
						if (row.sortable) {
							return (
								<TableCell
									key={tableId + "_" + tableTitle + "_" + row.label + "_sortable"}
									align={row.align}
									padding={row.disablePadding ? 'none' : 'default'}
									sortDirection={orderBy === row.field ? order : false}
								>
									<Tooltip
										title="Sort"
										placement={row.align  ? 'bottom-end' : 'bottom-start'}
										enterDelay={300}
									>
										<TableSortLabel
											classes={{root: classes.headingRoot}}
											active={orderBy === row.field}
											direction={order}
											onClick={this.createSortHandler(row.field)}
										>
											{row.label}
										</TableSortLabel>
									</Tooltip>
								</TableCell>
							);
						} else {
							return (
								<TableCell
									classes={{root: classes.headingRoot}}
									key={tableId + "_" + tableTitle + "_" + row.label + "_unsortable_" + index}
									align={row.align}
									padding={row.disablePadding ? 'none' : 'default'}
									sortDirection={false}
								>
									{row.label}
								</TableCell>
							);
						}
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
	typography: {
		useNextVariants: true
	},
	root: {
		paddingRight: theme.spacing.unit,
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	spacer: {
		flex: '1 1 100%',
	},
	link: {
		color: "blue"
	},
	buttonContainer: {
		display: "flex",
		marginLeft: "auto",
	},
	actions: {
		color: theme.palette.text.secondary,
		textAlign: 'right',
		width: '100%',
	},
	title: {
		flex: '0 0 auto',
	},
	active: {
		backgroundColor: 'lightgrey',
	},
	inactive: {
		backgroundColor: 'unset',
	},
	filterArea: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		height: '65px',
		marginLeft: '24px',
	},
	filterInput: {
		minWidth: '120px',
		margin: "auto 12px auto 0",
	},
	filterButtonArea: {
		textAlign: 'right',
		flex: '10%',
	},
	buttonIcon: {
		marginRight: "8px",
		display: "flex"
	},
	buttonLabel: {
		whiteSpace: "nowrap",
		alignItems: "center"
	},
	fabButton: {
		borderLeft: `solid ${darken(theme.palette.primary.main, .2)} 2px`,
		boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)"
	},
	lastButton: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0
	},
	middleButton: {
		borderRadius: 0
	},
	firstButton: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	buttonWrapper: {
		display: "flex",
		justifyContent: "flex-end",
		marginRight: 0,
		marginLeft: "auto"
	}
});

class EnhancedTableToolbar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			filtersShown: false,
			filters: {}
		};
		this.props = props;
		// this.props.filters.forEach((filter) => {
		// 	this.state.filters[filter.label] = {
		// 		field: filter.field,
		// 		value: filter.initial,
		// 		custom: filter.custom || false
		// 	};
		// });
	}

	handleChange = (event) => {
		let label = event.target.id;
		let value = event.target.value;
		this.setState((state) => {
			let filters = state.filters;
			filters[label].value = value;
			return { filters: filters };
		});
	}

	handleSelectChange = name => event => {
		let label = name;
		let value = event.target.value;
		this.setState((state) => {
			let filters = state.filters;
			filters[label].value = value;
			return { filters: filters };
		});
	};

	toggleFilters = () => {
		this.setState((state) => {
			return { filtersShown: !state.filtersShown };
		});
	}

	handleKeyPress = (event) => {
		if (event.key == 'Enter') {
			this.filter();
		}
	}

	filter = () => {
		this.props.filter(this.state.filters);
	}

	clearFilter = () => {
		let cleared_filters = {};
		this.props.filters.forEach((filter) => {
			cleared_filters[filter.label] = filter;
			cleared_filters[filter.label].value = filter.initial;
		});
		this.setState({ filters: cleared_filters }, function () {
			this.props.filter(this.state.filters);
		});
	}

	render() {
		const { numSelected, classes, noSelect, title, actions, filters, buttons } = this.props;
		const icons = {
			FilterListIcon: FilterListIcon,
			DeleteIcon: DeleteIcon,
			PowerSettingsNewIcon: PowerSettingsNewIcon,
		};
		if (!title && noSelect) {
			return "";
		}
		return (
			<div>
				<Toolbar
					className={classNames(classes.root, {
						[classes.highlight]: numSelected > 0,
					})}
				>
					<div className={classes.title}>
						{numSelected > 0 ? (
							<Typography color="inherit" variant="h6">
								{numSelected} selected
							</Typography>
						) : (title ?
								<Typography variant="h6" id="tableTitle">
									{title}
								</Typography>
								: ""
							)}
					</div>
					<div className={classes.buttonWrapper}>
						{buttons ? buttons.map( (button, index) => (
							((!button.onSelectShow && numSelected === 0) || (button.onSelectShow && numSelected > 0)) ?
								<div key={this.props.tableId + "_" + this.props.title + "_toolbar_button_" + button.display} className={classes.buttonContainer}>
									<Button
										classes={{label: classes.buttonLabel}} className={(button.style ? button.style : "") + " " + classes.fabButton + " " + ( index === 0 ? ( buttons.length > 1 ? classes.firstButton : ""): (index === buttons.length - 1 ? classes.lastButton: classes.middleButton))}
										color="primary"
										variant="contained" aria-label="Edit"
										onClick={button.function}
									>
										{button.icon ? <span className={classes.buttonIcon}>{button.icon}</span> : ""}
										{button.display}
									</Button>
								</div>
							: ""
						)) : ""}
					</div>
				</Toolbar>
				{this.state.filtersShown ? (
					<div className={classes.filterArea}>
						{filters.map((filter) => {
							if (filter.type == 'text') {
								return (
									<TextField
										onKeyPress={this.handleKeyPress}
										key={this.props.tableId + "_" + this.props.title + "_toolbar_text_" + filter.label}
										className={classes.filterInput}
										id={filter.label}
										label={filter.label}
										type="text"
										margin="normal"
										value={this.state.filters[filter.label].value}
										onChange={this.handleChange}
									/>
								);
							} else if (filter.type == 'select') {
								return (
									<FormControl key={this.props.tableId + "_" + this.props.title + "_toolbar_select" + filter.label} className={classes.filterInput}>
										<InputLabel htmlFor={filter.label}>{filter.label}</InputLabel>
										<Select
											onKeyPress={this.handleKeyPress}
											label={filter.label}
											id={filter.label}
											value={this.state.filters[filter.label].value}
											onChange={this.handleSelectChange(filter.label)}
											inputProps={{
												name: filter.label,
												id: filter.label,
											}}
										>
											{filter.options.map((option) => {
												return (
													<MenuItem key={this.props.tableId + "_" + this.props.title + "_toolbar_select_option_" + option.value} value={option.value}>{option.label}</MenuItem>
												);
											})}

										</Select>
									</FormControl>
								);
							}
						})}
						<div className={classes.filterButtonArea}>
							<Button onClick={this.clearFilter} className={classes.filterInput} variant="outlined">Clear</Button>
							<Button onClick={this.filter} className={classes.filterInput} variant="outlined" color="primary">Apply</Button>
						</div>
					</div>
				) : null}
			</div>
		);
	}
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(withTheme()(EnhancedTableToolbar));

const styles = theme => ({
	root: {
		width: "100%",
		height: "100%",
		minHeight: "100%",
		overflow: "hidden",
		margin: "auto",
		position: "relative",
	},
	table: {
		minWidth: "100%",
		maxHeight: "100%",
		overflow: "scroll",
		flexWrap: "wrap",
		display: "table",
		paddingBottom: "18px",
	},
	tableWrapper: {
		overflow: "auto",
		height: "calc(100% - 132px)",
	},
	tableWrapperWithoutTitle: {
		height: "calc(100% - 69px)"
	},
	paginationContainer: {
		display: "flex",
	},
	pagination: {
	},
	paginationWrapper: {
		borderBottom: "solid lightgrey 1px",
	},
	paginationRoot: {
	},
	noData: {
		margin: "12px",
		display: "flex",
	},
	packagesContainer: {
		height: "100%"
	},
	fullWidth: {
		width: "100%"
	},
	tableContents: {
		width: "100%"
	},
	cell: {
		height: "50px",
		overflow: "hidden",
		display: "flex",
		alignItems: "center"
	},
	headingRoot: {
		whiteSpace: "nowrap",
		textTransform: "uppercase"
	},
	actionCell: {
		paddingLeft: "4px",
		color: "grey",
		cursor: "pointer"
	},
	iconButtonOverride: {
		padding: "4px"
	}
});

class EnhancedTable extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			items: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null,
			},
			order: 'asc',
			orderBy: null,
			filters: {},
			title: "",
			selected: [],
		};
		this.load_items();
	}

	filter = (filters) => {
		this.props.actions[0].function(filters, this.state.rowsPerPage).then(({ data, total }) => {
			if (total == null || !total || total == 0) {
				total = 0;
			}
			this.setState({ data, total, page: 0, orderBy: null });
		}).catch((error) => {
			console.log(error);
		});
	}

	load_items = () => {
		let params = this.state.page_data;
		if (this.state.orderBy) {
			let sort = { order_by: ""};
			sort.order_by = this.state.order === "desc" ? "-" + this.state.orderBy : this.state.orderBy;
			params = Object.assign(params, sort);
		}
		this.props.loadFunction(params).then( (results) => {
			this.setState((prev_state) => {
				let new_selected = prev_state.selected;
				if (prev_state.all_selected) {
					results.items.forEach( ({_id}) => {
						if (!new_selected.find( (id) => id === _id)) {
							new_selected.push(_id);
						}
					});
				}
				return {
					items: results.items,
					page_data: {
						total: results.total,
						page: prev_state.page_data.page,
						per_page: params.per_page,
						page_meta: true
					},
					selected: new_selected
				};
			});
		});
	}


	handleRequestSort = (event, orderBy) => {
		let order = '';
		if (this.state.orderBy === orderBy && this.state.order === 'desc') {
			order = 'asc';
		} else {
			order = 'desc';
		}
		this.setState({order: order, orderBy: orderBy}, () => {
			this.load_items({order: order, order_by: orderBy});
		});
	};

	handleSelectAllClick = event => {
		if (event.target.checked) {
			this.setState( (state) => {
				return { 
					selected: state.items.map(n => n._id),
					all_selected: true
				};
			}, () => {
				if (this.props.onSelect) {
					this.props.onSelect(this.props.title, this.state.selected, "all");
				}
			});
			return;
		} else {
			this.setState({ selected: [], all_selected: false });
			if (this.props.onSelect) {
				this.props.onSelect(this.props.title, [], "all");
			}
		}
		
	};

	handleClick = (event, id) => {
		let newSelected = [];
		if (this.props.selectOne) {
			if ( this.state.selected.indexOf(id) === 0) {
				newSelected = [];
				this.setState({ selected: newSelected });
				if (this.props.onSelect) {
					this.props.onSelect(this.props.title, null);
				}
			} else {
				newSelected = [id];
				this.setState({ selected: newSelected });
				if (this.props.onSelect) {
					this.props.onSelect(this.props.title, id);
				}
			}
		} else {
			const { selected } = this.state;
			const selectedIndex = selected.indexOf(id);
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}
			if (newSelected.length === 0) {
				this.setState({ selected: newSelected, all_selected: false });
				if (this.props.onSelect) {
					this.props.onSelect(this.props.title, [], "all");
				}
			} else {
				if (selected.length > newSelected.length) {
					this.setState({ selected: newSelected, all_selected: false });
				} else {
					this.setState({ selected: newSelected});
				}
				if (this.props.onSelect) {
					this.props.onSelect(this.props.title, id, selected.length < newSelected.length);
				}
			}
		
		}
		
	};

	handleChangePage = (event, page) => {
		this.setState({
			page_data: {
				page: page + 1,
				per_page: this.state.page_data.per_page,
				total: this.state.page_data.total,
				page_meta: true
			}
		}, () => { this.load_items(); });
	};

	handleChangeRowsPerPage = event => {
		let new_per_page = event.target.value;
		this.setState({
			page_data: {
				page: this.state.page_data.page,
				per_page: new_per_page,
				total: this.state.page_data.total,
				page_meta: true
			}
		}, () => { this.load_items(); });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	render_date = (date) => {
		function showDate(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleDateString();
		}

		function showTime(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleTimeString();
		}
		return (
			<div >
				<div style={{ whiteSpace: "nowrap" }}>
					{showTime(date)}
				</div>
				<div style={{ color: "grey", fontStyle: "italic" }}>
					{showDate(date)}
				</div>
			</div>
		);
	}

	render() {
		const { classes, headings, title, filters, actions, buttons, noSelect, selectOverride, selectOne } = this.props;
		const { items, order, orderBy, selected, page_data, all_selected } = this.state;
		const {per_page, page, total} = page_data || null;
		let fromVal = 0;
		let toVal = 0;
		if (total !== 0) {
			fromVal = 1 + (per_page * (page - 1));
			toVal = per_page * page > total ? total : per_page * page;
		}
		return (
			<Paper className={classes.root}>
				{!items ?
						<Loading/>
					:
					<div className={classes.packagesContainer}>
						<div>
							<EnhancedTableToolbar noSelect={noSelect} buttons={buttons} filter={this.filter} actions={actions.slice(1)} numSelected={all_selected ? total : selected.length} title={title} tableId={this.props.tableId} filters={filters} />
							<div className={classes.paginationContainer}>
								<table className={classes.fullWidth}>
									<tbody>
										<tr className={classes.paginationWrapper}>
											<TablePagination
												classes={{toolbar: classes.paginationRoot}}
												className={classes.pagination}
												rowsPerPageOptions={[12, 24, 60, 120]}
												labelDisplayedRows={({ from, to }) => `${fromVal}-${toVal} of ${total}`}
												count={total}
												rowsPerPage={per_page}
												page={page - 1}
												backIconButtonProps={{
													'aria-label': 'Previous Page',
												}}
												nextIconButtonProps={{
													'aria-label': 'Next Page',
												}}
												onChangePage={this.handleChangePage}
												onChangeRowsPerPage={this.handleChangeRowsPerPage}
											/>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className={classes.tableWrapper + " " + (!title && noSelect ? classes.tableWrapperWithoutTitle : "")}>
							<Table className={classes.table} aria-labelledby="tableTitle">
								<EnhancedTableHead
									tableId={this.props.tableId}
									tableTitle={this.props.title}
									classes={classes}
									root_class={classes.tableHeader}
									noSelect={noSelect}
									selectOne={selectOne}
									numSelected={selected.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={this.handleSelectAllClick}
									onRequestSort={this.handleRequestSort}
									rowCount={items.length}
									headings={headings}
									has_rows={items.length > 0}
									has_actions={this.props.actions && this.props.actions.length > 0 ? this.props.actions.length : false}
								/>
								<TableBody
									className={classes.tableBody}
								>
									{items.length > 0 ?
											items.map( (item) => {
												const isSelected = this.isSelected(item._id);
												return (
													<TableRow
														key={this.props.tableId + "_" + this.props.title + "_row_" + item._id || item.id}
														className={classes.tableRow}
														hover
														onClick={!noSelect ? (event) => this.handleClick(event, item._id) : undefined}
														role="checkbox"
														aria-checked={isSelected}
														tabIndex={-1}
														selected={isSelected}
													>
														{ noSelect ? 
															<TableCell key={this.props.tableId + "_" + this.props.title + "_no_select_" + item._id} padding="checkbox">
															</TableCell>
														:
															<TableCell key={this.props.tableId + "_" + this.props.title + "_select_" + item._id} padding="checkbox">
																<Checkbox checked={isSelected} />
															</TableCell>
														}
														{this.props.actions && this.props.actions.length > 0 ?
															this.props.actions.map( (action) => {
																return (
																	<TableCell className={classes.actionCell} key={this.props.tableId + "_" + this.props.title + "_action_" + action.label + "_" + item._id} padding="none">
																		<Tooltip onClick={() => action.fxn(item).then(this.load_items)} title={action.label}>
																			<IconButton classes={{root: classes.iconButtonOverride}} aria-label={action.label}>
																				{action.icon}
																			</IconButton>
																		</Tooltip>
																	</TableCell>
																);
															})
															:
															<TableCell style={{display: "none"}} padding="checkbox">
															</TableCell>
														}
														{headings.map( (column) => {
															if (item[column.field] == undefined) {
																return <TableCell key={this.props.tableId + "_" + this.props.title + "_not_found" + item._id + '_' + item[column.field]} align={column.align}>NOT FOUND</TableCell>
															} else {
																return (
																	<TableCell key={this.props.tableId + "_" + this.props.title + "_row_column_value_" + item._id + '_' + item[column.field]} align={column.align}>{column.content === "function" ? item[column.field](classes) : column.content === "date" ? this.render_date(item[column.field]) : item[column.field]}</TableCell>
																);
															}
														})}
													</TableRow>
												);
											})
										:
											<TableRow
												className={classes.tableRow}
												key={this.props.tableId + "_" + this.props.title + "_no_data_row"}
											>
												<TableCell key={this.props.tableId + "_" + this.props.title + "_cb_no_data_row"} padding="checkbox">
													&nbsp;
												</TableCell>
												{headings.map( (column, index) => {
													if (index === 0) {
														return <TableCell key={this.props.tableId + "_" + this.props.title + "_" + column.field + "_heading_no_data_first_" + index} align={column.align}><div className={classes.cell}>No Data</div></TableCell>
													} else {
														return <TableCell key={this.props.tableId + "_" + this.props.title + "_" + column.field + "_heading_no_data_" + index} align={column.align}>&nbsp;</TableCell>
													}
												})}
											</TableRow>
									}
								</TableBody>
							</Table>
						</div>
					</div>
				}
			</Paper>
		);
	}
}

EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme()(EnhancedTable));
