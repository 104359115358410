import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TabsContainer from '../components/Containers/TabsContainer';

const styles = {
	container: {
		height: "calc(100vh - 112px)",
	}
};

class IngestPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.initial = {type: "ingestors"}
	}

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.container}>
				<TabsContainer initial={this.initial} />
			</div>
		);
	}
}

export default withStyles(styles)(IngestPage);
