import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	container: {
		fontFamily: "Inter",
	},
	title: {
		fontSize: "20px",
		fontWeight: "700",
		lineHeight: "32px",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	content: {
		color: "#8e8e93",
		fontSize: "14px",
		marginBottom: "32px",
	},
	link: {
		paddingLeft: 0,
		paddingRight: 0,
	}
});

class ModalContent extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
	}

	render() {
		const { content, classes } = this.props;
		return (
			<div className={classes.container}>
				<div className={classes.title}>
					{content.title}
				</div>
				{Array.isArray(content.content) ?
					(content.content.map( (section, index) => (
						<div key={index} className={classes.content}>
							{section}
						</div>
					)))
					:
					<div className={classes.content}>
						{content.content}
					</div>
				}
				<div>
					<Button color="primary" className={classes.link} href={content.link} target="_blank">
						{content.linkText}
					</Button>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(ModalContent);
