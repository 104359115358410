import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme, withTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';



const styles = theme => ({
    colorSwitchBase: {
        '&$colorChecked': {
            color: "#1153b6",
            '& + $colorBar': {
                backgroundColor: "#1153b6",
                opacity: "0.3",
            },
        },
    },
    root: {
    },
    colorBar: {},
    colorChecked: {},
    label: {
        fontSize: "14px",
        display: "inline",
        fontFamily: "Inter",
        color: "#8e8e93",
    },
    inline: {
        display: "inline",
    },
    block: {
        width: "100%",
    },
    startSwitch: {
        marginLeft: "auto",
        marginRight: 0
    },
    topSwitch: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginTop: "0",
        paddingRight: 0,
        maxHeight: "39px",
        marginBottom: "8px",
        position: "relative",
    },
    switchContainer: {
    },
    off: {
        color: "#8e8e93",
    }
});

class SwitchInput extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    localChange = () => {
        this.on = !this.on;
        this.props.emitChange({ label: this.props.label, value: this.on, field: this.props.field });
    }

    render() {
        const { location, disabled, color, onLabel, offLabel, initial, label, containerClass, classes } = this.props;
        this.on = initial || "";
        return (
            <div className={classes.container + " " + (containerClass ? containerClass : "")}>
                <div
                    className={classes.label + " " + (this.on ? "" : classes.off) + " " + (location === "start" ? classes.inline : classes.block)}
                >
                    {this.on ? onLabel : offLabel}
                </div>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            color="primary"
                            checked={this.on}
                            onChange={this.localChange}
                            className={classNames(classes)}
                            classes={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                bar: classes.colorBar,
                                root: classes.root,
                            }}
                        />
                    }
                    className={(location === "start" ? classes.startSwitch : classes.topSwitch) + " " + classes.switchContainer}
                />
            </div>

        );
    }
}
export default withStyles(styles)(withTheme()(SwitchInput));
