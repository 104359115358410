import React from 'react';
import { Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import classNames from 'classnames';
import {
    get as _get,
    set as _set,
} from 'lodash';

import TextInput from '../Inputs/TextInput';
import {
    get_children_objects_state,
    get_children_objects_half_state,
    get_nested_indexes,
    re_index_objects_array,
    toggle_children_objects_state
} from './helpers';

const mibLinesColors = {
    0: "#1153B6",
    1: "#F27027",
    2: "#0BBD48",
    3: "#AF639A",
};

const newNotificationObject = {
    name: "",
    oid: "",
    status: "",
    description: "",
    enabled: true,
};

class PollableAttributeNotificationsForm extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            notifications_shown: {},
        }
    }

    handle_notification_change = (input, index, checked) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        if (index) {
            _set(
                new_pollable_attribute.notifications,
                index,
                typeof checked !== 'undefined' ? checked : input.value,
            );
        } else {
            new_pollable_attribute.notifications[input.field] = input.value;
        }
        this.props.updateState(new_pollable_attribute);
    }

    add_notification = (index) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        if (!new_pollable_attribute.notifications) {
            // If it is the first object add the array first
            new_pollable_attribute.notifications = [];
        }
        let objectsIndex = '';
        let newObject = null;
        if (!index) {
            // If no index is passed then it is an object of the root level
            objectsIndex = `objects_${new_pollable_attribute.notifications.length}`;
            newObject = { ...newNotificationObject };
            newObject[objectsIndex] = [];
            new_pollable_attribute.notifications.push(newObject);
        } else {
            const {
                objectsArrayKey,
                objectsArrayIndex,
            } = get_nested_indexes(index);
            const objectsArray = _get(new_pollable_attribute.notifications, objectsArrayIndex);
            objectsIndex = `${objectsArrayKey}_${objectsArray.length}`;
            newObject = { ...newNotificationObject };
            newObject[objectsIndex] = [];
            _set(new_pollable_attribute.notifications, `${objectsArrayIndex}.${objectsArray.length}`, newObject);
        }
        this.props.updateState(new_pollable_attribute);
    };

    remove_notification_object = (index) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        const {
            parentObjectsArrayIndex,
            objectRealIndex,
        } = get_nested_indexes(index);
        if (index === parentObjectsArrayIndex) {
            new_pollable_attribute.notifications =
                new_pollable_attribute.notifications.filter(
                    (_, index) => index !== objectRealIndex
                );
        } else {
            let objectsArray = _get(new_pollable_attribute.notifications, parentObjectsArrayIndex);
            objectsArray = objectsArray.filter((_, index) => index !== objectRealIndex);
            _set(
                new_pollable_attribute.notifications,
                index === parentObjectsArrayIndex
                    ? ''
                    : parentObjectsArrayIndex,
                objectsArray,
            );
        }
        re_index_objects_array(new_pollable_attribute.notifications);
        this.props.updateState(new_pollable_attribute);
    };

    toggle_mib_children_enabled_state = (objects, index, previousState) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        const { objectsArrayIndex } = get_nested_indexes(index);
        toggle_children_objects_state(objects, index, previousState);
        _set(new_pollable_attribute.notifications, objectsArrayIndex, objects);
        console.info(new_pollable_attribute);
        this.props.updateState(new_pollable_attribute);
    };

    toggle_mib_object_view = (index) => {
        let new_notifications_shown = this.state.notifications_shown;
        if (typeof new_notifications_shown[index] === "undefined") {
            new_notifications_shown[index] = false;
        } else {
            new_notifications_shown[index] = !new_notifications_shown[index];
        }
        this.setState({ notifications_shown: new_notifications_shown });
    };

    render_notification_object = (notificationObject, index) => {
        const { classes, validation } = this.props;
        const { notifications_shown } = this.state;
        const indexLevels = index?.split(".");
        const {
            objectsArrayKey,
            objectIndex,
        } = get_nested_indexes(index);
        const objects = notificationObject[objectsArrayKey];
        const shown = typeof notifications_shown[index] === "undefined"
            ? true : notifications_shown[index];
        let childrenEnabled = false;
        let indeterminateEnabled = false;
        if (objects && objects.length !== 0) {
            childrenEnabled = get_children_objects_state(objects, index);
            indeterminateEnabled = get_children_objects_half_state(objects, index);
        }
        return (
            <>
                <div className={classes.mibObjectRow}>
                    <div
                        className={classes.mibHorizontalLine}
                        style={{ backgroundColor: mibLinesColors[indexLevels.length - 1] }}>
                    </div>
                    <div className={classes.notifDataContainer}>
                        <div className={classes.notifData}>
                            <TextInput
                                label={"Name"}
                                field={"name"}
                                className={classes.mibInput}
                                priorState={notificationObject.name}
                                disabled={validation.editable == false || this.editing_disabled}
                                emitChange={(input) => this.handle_notification_change(input, `${objectIndex}.name`)}
                            />
                            <TextInput
                                label={"Status"}
                                field={"status"}
                                className={classes.mibInput}
                                priorState={notificationObject.status}
                                disabled={validation.editable == false || this.editing_disabled}
                                emitChange={(input) => this.handle_notification_change(input, `${objectIndex}.status`)}
                            />
                            <TextInput
                                label={"OID"}
                                field={"oid"}
                                className={classes.mibInput}
                                priorState={notificationObject.oid}
                                disabled={validation.editable == false || this.editing_disabled}
                                emitChange={(input) => this.handle_notification_change(input, `${objectIndex}.oid`)}
                            />
                        </div>
                        <div>
                            <TextInput
                                label={"Description"}
                                field={"description"}
                                className={classes.descInput}
                                priorState={notificationObject.description}
                                disabled={validation.editable == false || this.editing_disabled}
                                emitChange={(input) => this.handle_notification_change(input, `${objectIndex}.description`)}
                            />
                        </div>
                    </div>
                    <Tooltip className={classes.removeMibIcon} title="Remove Object">
                        <IconButton
                            color="inherit"
                            root={{ root: classes.removeMibIcon }}
                            onClick={() => this.remove_notification_object(index)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    {objects && objects.length !== 0 && (
                        <Tooltip className={classes.removeMibIcon} title={shown ? "Hide children" : "Show children"}>
                            <IconButton
                                color="inherit"
                                root={{ root: classes.removeMibIcon }}
                                onClick={() => this.toggle_mib_object_view(index)}>
                                {shown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                <div className={classes.mibObjectRow}>
                    <FormControlLabel
                        className={classes.formControlLabel}
                        control={
                            <Checkbox
                                color="primary"
                                checked={notificationObject.enabled}
                                onChange={() => this.handle_notification_change(
                                    null,
                                    `${objectIndex}.enabled`,
                                    !notificationObject.enabled
                                )}
                            />
                        }
                        key={`notification-object-${index}-enabled`}
                        label="Enabled"
                    />
                    {objects && objects.length !== 0 && (
                        <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={childrenEnabled}
                                    indeterminate={!childrenEnabled && indeterminateEnabled}
                                    onChange={() => this.toggle_mib_children_enabled_state(
                                        objects,
                                        index,
                                        childrenEnabled
                                    )}
                                />
                            }
                            key={`notification-object-${index}-enable-children`}
                            label={`${childrenEnabled ? 'Disable' : 'Enable'} children`}
                        />
                    )}
                </div>
                {objects && objects.length !== 0 && (
                    <div
                        className={classNames(
                            classes.mibLine,
                            classes.mibSubLine,
                            classes.notifSubline
                        )}
                        style={{ backgroundColor: mibLinesColors[indexLevels.length] }}>
                    </div>
                )}
                {shown && objects?.map((subNotification, subIndex) => (
                    <div
                        className={classes.mibObjectContainer}
                        key={`notification-object-${index}-${subIndex}`}
                    >
                        {this.render_notification_object(subNotification, `${index}.${subIndex}`)}
                    </div>
                ))}
                {this.props.disabled || indexLevels.length >= 4
                    ? "" :
                    <Button
                        className={classes.mibButton}
                        variant="outlined"
                        color="primary"
                        onClick={() => this.add_notification(index)}>
                        <AddIcon />
                        New notification
                    </Button>}
            </>
        );
    };

    render() {
        const {
            classes,
            editable_pollable_attribute,
        } = this.props;
        const { notifications } = editable_pollable_attribute;
        return (
            <div>
                <div>
                    Notifications
                </div>
                <div className={classes.mibObjectsContainer}>
                    {notifications && notifications.length !== 0 && (
                        <div
                            className={classNames(classes.mibLine, classes.notifLine)}
                            style={{ backgroundColor: mibLinesColors[0] }}>
                        </div>
                    )}
                    {notifications && notifications.map((notificationObject, index) => (
                        <div
                            className={classes.mibObjectContainer}
                            key={`notification-object-${index}`}
                        >
                            {this.render_notification_object(notificationObject, `${index}`)}
                        </div>
                    ))}
                    {this.props.disabled
                        ? "" :
                        <Button
                            className={classes.mibButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.add_notification()}>
                            <AddIcon />
                            New notification
                        </Button>}
                </div>
            </div>
        );
    }
}

export default PollableAttributeNotificationsForm;