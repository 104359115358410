import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../DisplayOriented/Loading';
import Auth from '../../services/Auth';
import { GetAll } from '../../services/CLURDUtilities';
import Device from '../../services/DataModels/Device';
import Config from '../../services/DataModels/Config';

//inputs
import PasswordInput from '../Inputs/PasswordInput';
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import TimeInput from '../Inputs/TimeInput';
import NumberInput from '../Inputs/NumberInput';
import SwitchInput from '../Inputs/SwitchInput';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Badge from '@material-ui/core/Badge';

//icons
import InfoIcon  from '@material-ui/icons/Info';
import LanguageIcon from '@material-ui/icons/Language';
import WifiIcon from '@material-ui/icons/Wifi';
import NotesIcon from '@material-ui/icons/Notes';
import RouterIcon from '@material-ui/icons/Router';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import SettingsEthernetIcon  from '@material-ui/icons/SettingsEthernet';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		title: {
			fontSize: "24px",
			margin: "0 0 12px 12px",
			display: "flex",
		},
		subTitle: {
			fontSize: "16px",
			color: "grey",
			margin: "0 0 12px 40px",
			display: "flex",
			alignItems: "center"
		},
		infoIcon: {
			marginRight: "6px"
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap"
		},
		uidInput: {
			marginBottom: "30px"
		},
		accountInput: {
			marginTop: "24px",
			marginBottom: "16px"
		},
		nameContainer: {
			marginBottom: "16px"
		},
		inputIcon: {
			color: theme.palette.greyIcon.main,
			margin: "8px",
			paddingBottom: "20px"
		},
		uidIcon: {
			color: "white", 
			backgroundColor: "grey", 
			borderRadius: "4px",
			width: "22px", 
			height: "20px",
			fontSize: "12px",
			margin: "8px 10px 32px 10px",
			paddingBottom: 0,
			display: "flex",
		},
		uidIconText: {
			margin: "auto"
		},
		configInput: {
			marginTop: "32px"
		},
		invalidConfig: {
			marginLeft: "40px",
			marginTop: "-18px",
			color: "red"
		},
		buttonContainer: {
			margin: "24px 0 0 auto",
			display: "flex",
			justifyContent: "flex-end",
		},
		timeLabel: {
			fontFamily: "Inter",
			fontSize: "16px",
			color: "grey"
		},
		button: {
			marginLeft: "12px"
		},
		notSupported: {
			fontFamily: "Inter",
			color: "grey"
		},
		tabsTitle: {
			fontSize: "18px",
			color: "grey",
			fontFamily: "Inter",
			marginLeft: "8px"
		},
		selectWrapper: {
			marginTop: "32px"
		},
		switchWrapper: {
			width: "50%",
			marginBottom: "12px",
			marginTop: "-12px"
		},
		numberWrapper: {
			marginBottom: "12px",
		},
		viewTabs: {
			marginBottom: "12px"
		},
		connectionName: {
			textTransform: "uppercase",
			color: "grey",
			marginBottom: "12px",
			fontFamily: "Inter",
		},
		configContainer: {
			paddingLeft: "12px"
		},
		timeInputWrapper: {
			marginBottom: "12px"
		},
		badge: {
			right: "-6px"
		},
		badgeUnselected: {
			backgroundColor: "grey",
			color: "white"
		},
	});
};

class CreateConfigForm extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			name: '',
			company_id: this.props.company.value,
			device_type_id: this.props.type._id,
			connections: null,
			iptables: null,
			tab_index: 0,
			show_errors: false,
			saving: false
		};
		this.prepare_template();
		this.build_connections();
	}

	handle_change = (input) => {
		let field = input.field;
		this.setState( (state) => {
			state[field] = input.value;
			return state;
		});
	}

	render_name = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer + " " + classes.nameContainer}>
				<Tooltip className={classes.inputIcon} title="Name">
					<DescriptionIcon />
				</Tooltip>
				<TextInput
					emitChange={this.handle_change}
					priorState={this.state.name}
					error_message="A network config's name cannot be blank."
					error={this.state.name === "" && this.state.show_errors}
					label="Name*"
					key="name"
					field="name"
				/>
			</div>
		);
	}

	render_company = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer + " " + classes.accountInput}>
				<Tooltip className={classes.inputIcon} title="Account">
					<AccountCircleIcon />
				</Tooltip>
				<SelectInput
					disabled={true}
					field="company_id"
					label="Account"
					emitChange={this.handle_change}
					priorState={this.state.company_id}
					options={[this.props.company]}
				/>
			</div>
		);
	}

	render_type = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} title="Device Type">
					<DvrIcon />
				</Tooltip>
				<SelectInput
					disabled={true}
					label="Device Type"
					field="device_type_id"
					emitChange={this.handle_change}
					priorState={this.state.device_type_id}
					options={[{value: this.props.type._id, display: this.props.type.name}]}
				/>
			</div>
		);
	}

	unique_id_error = () => {
		if (this.state.unique_id === "" || this.state.unique_id.indexOf(" ") !== -1 || /[A-Z]/.test(this.state.unique_id)) {
			return true;
		} else {
			return false;
		}
	}

	render_buttons = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={this.props.cancel}
					className={classes.button}
				>
					cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={this.create_config}
					className={classes.button}
				>
					CREATE CONFIG
				</Button>
			</div>
		);
	}

	build_connections = () => {
		let connections = this.props.type.capabilities.network_connections;
		if (!connections || connections.length === 0) {
			this.state.connections = [];
			return;
		}
		Object.entries(this.connection_template).forEach( ([connection_type, connection]) => {
			let names = connections.reduce( (total, {type, name, ...others}) => {
				if (type === connection_type) {
					total.push(name && name !== "" ? name : others.interface);
				}
				return total;
			}, []);
			connection.supported = names.length === 0 ? false : true;
			connection.names = names;
		});
		let new_connections = [];
		connections.forEach( (connection) => {
			if (connection.type === "firewall") {
				return;
			}
			let config_connection = this.state.connections ? this.state.connections.find( ({type, name}) => (connection.name === name || connection.interface === name )&& connection.type === type) || {} : {};
			let new_connection = {
				name: connection.name && connection.name !== "" ? connection.name : connection.interface,
				type: connection.type,
				config: Object.assign(JSON.parse(JSON.stringify(this.connection_template[connection.type].config)), config_connection.config)
			};
			new_connection.interface = new_connection.name;
			Object.entries(new_connection.config).forEach( ([field, value]) => {
				if (typeof value === "object") {
					new_connection.config[field] = value[0];
				}
				if (field === "dhcp_lease" || field === "cell_reconn_delay") {
					new_connection.config[field] = this.prepare_time_input(value);
				}
			});
			new_connections.push(new_connection);
		});

		// iptables
		if (this.props.type.capabilities.iptables) {
			new_connections.push({
				name: "iptables",
				type: "iptables",
				config: this.state.iptables
			});
		}
		this.state.connections = new_connections;
	}

	prepare_time_input = (seconds) => {
		let time_state = {value: null, time: null};
		if (seconds % 86400 === 0) {
			time_state.time = "Days";
			time_state.value = seconds / 86400;
		} else if (seconds % 3600 === 0) {
			time_state.time = "Hours";
			time_state.value = seconds / 3600;
		} else if (seconds % 60 === 0) {
			time_state.time = "Minutes";
			time_state.value = seconds / 60;
		} else {
			time_state.time = "Seconds";
			time_state.value = seconds;
		}
		return time_state;
	}

	prepare_template = () => {
		const eth_config = {
			net_enable: 0,
			net_nat: 0,
			net_ip_assign: ["static", "auto"],
			net_ip: "",
			net_gateway: "",
			net_mask: "",
			net_broadcast: "",
			net_dns_1: "",
			net_dns_2: "",
			net_mtu: 1500,
			net_metric: 1
		};

		function dhcp_template(template) {
			template = Object.assign(JSON.parse(JSON.stringify(template)), {});
			template.dhcp_enable = 0;
			template.dhcp_relay = 0;
			template.dhcp_dns1 = "";
			template.dhcp_dns2 = "";
			template.dhcp_start = "";
			template.dhcp_end = "";
			template.dhcp_lease = 86400;
			return template;
		}

		function wlan_template(template) {
			template = Object.assign(JSON.parse(JSON.stringify(template)), {net_mode: ["LAN", "WAN"]});
			template = dhcp_template(template);
			return template;
		}

		this.connection_template = {
			'ethernet-wan': {
				label: "Ethernet-WAN",
				config: eth_config,
				icon: (<LanguageIcon />)
			},
			'ethernet-wan-lan': {
				label: "Ethernet-WAN/LAN",
				config: wlan_template(eth_config),
				icon: (<RouterIcon />)
			},
			'ethernet-lan': {
				label: "Ethernet-LAN",
				config: dhcp_template(eth_config),
				icon: (<SettingsEthernetIcon />)
			},
			'cellular': {
				label: "Cellular",
				config: {
					cell_enable: 0,
					cell_auth: ["chap", "pap"],
					cell_pass: "",
					cell_reconn_delay: 300,
					cell_user: "",
					cell_auto_apn: 0,
					cell_apn: "",
					cell_reconn_retries: 1,
					cell_mtu: 1500
				},
				icon: (<SignalCellularAltIcon />)
			},
			'wifi': {
				label: "WIFI",
				config: {
					wifi_enable: 0,
					wifi_hidden: 0,
					wifi_ssid: "",
					wifi_psk: "",
					wifi_auth: ["wpa2psk", "wpapsk"],
					wifi_crypt: ["aes", "tkip"],
					wifi_mode: ["ap", "client"],
					wifi_channel: 1,
					net_ip_assign: ["static", "auto"],
					net_ip: "",
					net_gateway: "",
					net_mask: "",
					net_broadcast: "",
					net_dns_1: "",
					net_dns_2: ""
				},
				icon: (<WifiIcon />)
			},
			'iptables': {
				label: "IP-TABLES",
				config: {},
				icon: (<NotesIcon />)
			}
		};
		this.fields_formatted = {
			net_enable: "Enabled",
			net_nat: "NAT",
			net_broadcast: "Broadcast IP",
			net_gateway: "Gateway IP",
			net_ip_assign: "IP Assignment",
			net_ip: "IP Address",
			net_dns_2: "DNS 2",
			net_dns_1: "DNS 1",
			net_mode: "Net Mode",
			cell_reconn_retries: "Reconnection Retries",
			cell_mtu: "Cellular Max Transmission Unit",
			net_mtu: "Max Transmission Unit",
			net_mask: "Net Mask",
			net_metric: "Metric",
			firewall_enable: "Enabled",
			firewall_rules: "Firewall Rules",
			wifi_enable: "Enabled",
			wifi_channel: "Channel (1-14, use keyboard's up and down arrows)",
			wifi_crypt: "Encryption",
			wifi_ssid: "SSID",
			wifi_psk: "Password/Secret",
			wifi_mode: "WIFI Mode",
			wifi_auth: "WIFI Authentication",
			cell_enable: "Enabled",
			cell_auth: "Cell Authentication",
			cell_pass: "Password",
			cell_reconn_delay: "Reconnect Delay",
			cell_user: "Username",
			cell_apn: "Access Point Name (APN)",
			cell_auto_apn: "Automatic APN",
			dhcp_enable: "DHCP Enabled",
			wifi_hidden: "Hidden Network",
			dhcp_dns1: "DHCP DNS 1",
			dhcp_dns2: "DHCP DNS 2",
			dhcp_start: "DHCP Start",
			dhcp_relay: "DHCP Relay",
			dhcp_end: "DHCP End",
			dhcp_lease: "DHCP LEASE",
		};
	}

	render_connection = (type, supported, config, names) => {
		if (!supported) {
			return <div key={type + "_not_supported"} className={this.props.classes.notSupported}>This type of connection setting isn't supported for this type of device.</div>;
		}
		return (
			names.map( (name) => (
				<div key={type + "_" + name}>
					<div className={this.props.classes.connectionName}>
						{name}
					</div>
					<div className={this.props.classes.configContainer}>
						{Object.entries(config).map( ([field, value]) => (
							this.render_field(field, value, name, type)
						))}
					</div>
					
				</div>
			))
		);
	}

	handle_config_change = (field, con_name, input, type) => {
		this.state.connections.forEach( (connection) => {
			if (connection.name === con_name && type === connection.type) {
				connection.config[field] = input.value;
			}
		});
		this.setState({connections: this.state.connections});
	}

	toggle_input = (con_name, field, type) => {
		this.state.connections.forEach( (connection) => {
			if (connection.name === con_name && type === connection.type) {
				connection.config[field] = connection.config[field] === 1 ? 0 : 1;
			}
		});
		this.setState({connections: this.state.connections});
	}

	handle_time_change = (field, con_name, input, type) => {
		this.state.connections.forEach( (connection) => {
			if (connection.name === con_name && type === connection.type) {
				connection.config[field] = input;
			}
		});
		this.setState({connections: this.state.connections});
	}

	render_field = (field, value, con_name, con_type) => {
		let field_type = typeof value;
		let connection = this.state.connections.find( (connection) => connection.type === con_type && (connection.interface === con_name || connection.name === con_name)).config;
		let state = connection[field];
		if (field === "net_ip_assign" && con_type === "ethernet-lan") {
			return "";
		}
		if (connection.net_ip_assign === "auto") {
			if (field === "net_ip" || field === "net_gateway" || field === "net_mask" || field === "net_broadcast" || field === "net_dns_1" || field === "net_dns_2") {
				return "";
			}
		}
		if (field.indexOf("dhcp_") !== -1) {
			if (connection.net_mode === "WAN") {
				return "";
			}
		}
		if (field === "cell_apn" && connection.cell_auto_apn === 1) {
			return "";
		}
		if (connection.wifi_mode === "ap" && 
			(field === "net_ip_assign" ||
			field === "net_ip" ||
			field === "net_gateway" ||
			field === "net_mask" ||
			field === "net_broadcast" ||
			field === "net_dns_1" ||
			field === "net_dns_2"
		)) {
			return "";
		}
		switch (field_type) {
			case "string":
				if (field === "wifi_psk") {
					return (
						<PasswordInput
							emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							label={this.fields_formatted[field]}
							priorState={state}
							key={con_name + "_" + field}
							field={this.fields_formatted[field]}
							disabled={this.editing_disabled}
						/>
					);
				} else {
					return (
						<TextInput
							emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							label={this.fields_formatted[field]}
							priorState={state}
							key={con_name + "_" + field}
							field={this.fields_formatted[field]}
							disabled={this.editing_disabled}
						/>
					);
				}
			case "number":
				if (value !== 0) {
					if (field === "cell_reconn_delay" || field === "dhcp_lease") {
						return (
							<div className={this.props.classes.timeInputWrapper} key={con_name + "_" + field}>
								<div className={this.props.classes.timeLabel}>
									{this.fields_formatted[field]}
								</div>
								<TimeInput
									margin="none"
									emitChange={(input) => this.handle_time_change(field, con_name, input, con_type)}
									priorState={state}
									disabled={this.editing_disabled}
								/>
							</div>
						);
					}
					let minimum = 0;
					let maximum = undefined;
					if (field === "wifi_channel") {
						minimum = 1;
						maximum = 14;
						if (connection.wifi_mode === "client") {
							return "";
						}
					}
					return (
						<div className={this.props.classes.numberWrapper} key={con_name + "_" + field}>
							<NumberInput
								disabled={this.editing_disabled}
								minimum={minimum}
								maximum={maximum}
								label={this.fields_formatted[field]}
								field={field}
								priorState={state}
								emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							/>
						</div>
						
					);
				} else {
					return (
						<div key={con_name + "_" + field} className={this.props.classes.switchWrapper}>
							<SwitchInput
								disabled={this.editing_disabled}
								initial={state === 1 ? true : false}
								emitChange={() => this.toggle_input(con_name, field, con_type)}
								color={this.props.theme.palette.pending.main}
								onLabel={this.fields_formatted[field]}
								offLabel={this.fields_formatted[field]}
								field={field}
								location="start"
							/>
						</div>
					);
				}
			case "object":
				let options = [];
				if (field === "cell_auth") {
					options = [{display: "CHAP", value: "chap"}, {display: "PAP", value: "pap"}];
				} else if (field === "wifi_crypt") {
					options = [{display: "AES", value: "aes"}, {display: "TKIP", value: "tkip"}];
				} else if (field === "wifi_auth") {
					options = [{value: "wpa2psk", display: "WPA2-PSK"}, {display: "WPA-PSK", value: "wpapsk"}];
				} else if (field === "wifi_mode") {
					options = [{value: "ap", display: "Access Point"}, {value: "client", display: "Client"}];
				}else {
					options = this.connection_template[con_type].config[field].map( (option) => ({display: option.charAt(0).toUpperCase() + option.slice(1), value: option}));
				}
				return (
					<div key={con_name + "_" + field} className={this.props.classes.selectWrapper}>
						<SelectInput
							disabled={this.editing_disabled}
							emitChange={(input) => this.handle_config_change(field, con_name, input, con_type)}
							priorState={state}
							field={field}
							label={this.fields_formatted[field]}
							options={options}
						/>
					</div>
				);
		}
	}

	render_connections = () => {
		const classes = this.props.classes;
		console.log(this.connection_template);
		return (
			<React.Fragment>
				<div className={classes.tabsTitle}>
					Connections
				</div>
				<Tabs
					className={classes.viewTabs}
					value={this.state.tab_index}
					onChange={(event, value) => this.setState({ tab_index: value })}
					variant="scrollable"
					scrollButtons="auto"
					indicatorColor="secondary"
					textColor="secondary"
				>
					{Object.entries(this.connection_template).map( ([type, {names, label, icon, config}], index) => (
						<Tab
							key={type}
							icon={icon}
							label={this.render_tab_label(type, names, index)}
						/>
					))}
				</Tabs>
				{Object.entries(this.connection_template).map( ([type, {names, supported, config}], index) => (
					index === this.state.tab_index ? this.render_connection(type, supported, config, names) : ""
				))}
			</React.Fragment>
		);
	}

	render_tab_label = (connection_type, names, index) => {
		console.log(connection_type);
		if ((!names || names.length === 0) && (connection_type != "iptables)")) {
			return connection_type;
		}
		return (
			<Badge classes={{badge: this.props.classes.badge + " " + (index !== this.state.tab_index ? this.props.classes.badgeUnselected : "")}} color={index === this.state.tab_index ? "secondary" : "default"} badgeContent={names.length}>
				{connection_type}
			</Badge>
		);
	}

	valid_config = () => {
		return Boolean(this.props.getInterfaceOptions(this.state));
	}

	format_config = () => {
		let config = {
			company_id: this.state.company_id,
			device_type_id: this.state.device_type_id,
			name: this.state.name,
			connections: this.state.connections,
			iptables: this.state.iptables
		};
		let body = new Config(config).editableCopy();
		body.connections.forEach( (connection) => {
			Object.entries(connection.config).forEach( ([key, value]) => {
				if (key === "cell_reconn_delay" || key === "dhcp_lease") {
					connection.config[key] = this.to_seconds(connection.config[key]);
				}
			});
		});
		return body;
	}

	to_seconds = (time_state) => {
		switch (time_state.time) {
			case "Seconds":
				return parseInt(time_state.value);
			case "Minutes":
				return parseInt(time_state.value) * 60;
			case "Hours":
				return parseInt(time_state.value) * 3600;
			case "Days":
				return parseInt(time_state.value) * 86400;
		}
	}

	create_config = () => {
		if (this.state.name === "" || !this.valid_config()) {
			this.setState({show_errors: true});
			return;
		}
		let body = {};
		body = this.format_config();
		this.props.onCreate(body);
	}

	render() {
		const classes = this.props.classes;
		return (
			<div>
				<div className={classes.title}>
					Create a Network Config
				</div>
				<div className={classes.subTitle}>
					<InfoIcon className={classes.infoIcon}/>
					HA Group network configs have specific requirements. At least one of the described connections must be available: an ethernet-wan connection with a static IP, an ethernet-wan-lan connection set to net mode WAN with a static IP, or a wifi connection with a static IP and wifi mode set to client.
				</div>
				{this.state.saving ? <Loading /> :
					<React.Fragment>
						{this.render_name()}
						{this.render_company()}
						{this.render_type()}
						{this.render_connections()}
						{this.state.show_errors && !this.valid_config() ? 
							<div className={classes.invalidConfig}>Please ensure the connections you've defined meet the requirements described above.</div>
						: ""}
						{this.render_buttons()}
					</React.Fragment>
				}
			</div>
		)
	}
}

CreateConfigForm.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CreateConfigForm));