import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class DeviceErrors extends DataModel {
	constructor() {
		super("device_errors");
	}

	static getHeadings(){
		return [
			{label: "Created At", value: "created_at", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date"},
			{label: "SEVERITY", value: "display_level", field: "display_level", align: "center", disablePadding: false, sortable: true, content: "function"},
			{label: "ERROR", value: "error", field: "error", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "ACCOUNT", value: "nested_company.name", field: "name", align: "left", nested_field: true, disablePadding: false, sortable: true},
		];
	}

	static getErrors(params) {
		return new Promise( (resolve, reject) => {
			new DeviceErrors().listFromAPI(params).then( (result) => {
				let errors = result.items;
				errors.forEach( (error) => {
					if (error._id) {
						error.id = error._id;
					} else if (error.id) {
						error._id = error.id;
					}
				});
				AssignNestedModels("companies", "company_id", errors).then( () => {
					result.items = errors;
					resolve(result);
				}).catch( (error) => {
					reject(error);
				});
			}).catch( (error) => {
				reject(error);
			});
		});
	}
}

export default DeviceErrors;
