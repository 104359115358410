import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Loading from '../DisplayOriented/Loading';

//mui
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//inputs
import TextInput from './TextInput';
import LookupInput from './LookupInput';

//services
import EscrowDevice from '../../services/DataModels/EscrowDevice';
import Device from '../../services/DataModels/Device';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import { AssignNestedModels, GetAll } from '../../services/CLURDUtilities';

//icons
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

class DeviceSelectionInput extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			page: 1,
			per_page: 5,
			total: null,
			devices_shown: null,
			device_ids: [],
			devices: [],
			filters: {
				name_like: null,
				company_id_in: null,
				tags_incall: null,
				unique_id_like: null
			},
			all_selected: false,
			formerly_all_selected: false,
			company_options: null,
			tag_options: null
		};
		this.load_companies();
		this.load();
	}

	load_companies = () => {
		GetAll("companies").then( (companies) => {
			let tag_options = companies.reduce( (map, company) => {
				if (company.device_tags && company.device_tags.length > 0) {
					company.device_tags.forEach( (tag) => {
						if (tag && tag !== '') {
							map[tag] = tag;
						}
					});
				}
				return map;
			}, {});
			tag_options = Object.entries(tag_options).map( ([tag]) => ({value: tag, label: tag}));
			let company_options =  companies.map( (company) => ({label: company.name, value: company._id, whole: company}));
			this.setState({tag_options: tag_options, company_options: company_options});
		});
	}

	load = () => {
		const { page, per_page, filters, device_ids } = this.state;
		let valid_filters = Object.entries(filters).reduce( (map, [key, value]) => {
			if (value != null && value != '') {
				if (key == "company_id_in" || key == "tagsall") {
					map[key] = value.map( ({value}) => value);
				} else {
					map[key] = value;
				}
			}
			return map;
		}, {});
		let params = Object.assign({ page: page, per_page: per_page, page_meta: true }, valid_filters, this.props.params);
		if (this.props.isEscrow) {
			this.load_escrow_devices(params);
		} else {
			this.load_devices(params);
		}
	}

	load_escrow_devices = (params) => {
		new EscrowDevice().listFromAPI(params).then( (result) => {
			let devices = result.items;
			AssignNestedModels("companies", "company_id", devices).then( () => {
				this.setState( (state) => {
					state.devices_shown = devices;
					state.total = result.total;
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_devices = (params) => {
		new Device().listFromAPI(params).then( (result) => {
			let devices = result.items;
			AssignNestedModels("companies", "company_id", devices).then( () => {
				this.setState( (state) => {
					state.devices_shown = devices;
					state.total = result.total;
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	filter_change = ({field, value}) => {
		this.setState( (state) => {
			state.filters[field] = value;
			return state;
		});
	}
	
	page_change = (difference) => {
		this.setState( (state) => {
			state.page += difference;
			state.devices_shown = null;
			return state;
		}, this.load);
	}

	filter = () => {
		document.activeElement.blur()
		this.setState( (state) => {
			state.devices = [];
			state.device_ids = [];
			state.page = 1;
			this.props.onChange([], [], state.all_selected, state.formerly_all_selected, state.filters);
			return state;
		}, this.load);
	}

	render_search = () => {
		const { classes } = this.props;
		const { show_advanced, filters, company_options, tag_options } = this.state;
		if (!show_advanced) {
			return (
				<div className={classes.noAdvanced}>
					<div className={classes.searchName}>
						<TextInput
							emitChange={this.filter_change}
							priorState={filters.name_like}
							label="Name"
							key="name"
							field="name_like"
							variant="standard"
							onEnter={this.filter}
							InputProps={{
								startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
							}}
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div className={classes.advancedFilterContainer}>
					<div className={classes.advancedFilter + " " + classes.textInput}>
						<TextInput
							emitChange={this.filter_change}
							priorState={filters.name_like}
							label="Name"
							field="name_like"
							variant="standard"
							placeholder="Name"
							onEnter={this.filter}
							noError
						/>
					</div>
					<div className={classes.advancedFilter + " " + classes.textInput}>
						<TextInput
							emitChange={this.filter_change}
							priorState={filters.unique_id_like}
							label="Unique ID"
							field="unique_id_like"
							variant="standard"
							placeholder="Unique ID"
							onEnter={this.filter}
							noError
						/>
					</div>
					{this.render_company_select()}
					<div className={classes.advancedFilter + " " + classes.lookupWrapper}>
						<LookupInput
							emitChange={this.filter_change}
							priorState={{values: filters.tags_incall, suggestions: tag_options}}
							label="Tags"
							model="tag"
							key="tags"
							field="tags_incall"
							variant="standard"
							onEnter={this.filter}
							noLabel
							placeholder="Select Tags"
						/>
					</div>
				</div>
			);
		}
	}

	render_company_select = () => {
		const { classes, params } = this.props;
		const { filters, company_options } = this.state;
		let lookup_state = {
			values: filters.company_id_in,
			suggestions: company_options
		};
		let disabled = false;
		if (params.company_id && params.company_id !== '') {
			const company = company_options.find( ({value}) => params.company_id === value).whole;
			lookup_state = {
				values: [{label: company.name, value: params.company_id}],
				suggestions: [{label: company.name, value: company._id}]
			};
			disabled = true;
		}
		return (
			<div className={classes.advancedFilter + " " + classes.lookupWrapper}>
				<LookupInput
					disabled={disabled}
					emitChange={this.filter_change}
					priorState={lookup_state}
					label="Account"
					model="company"
					key="account"
					field="company_id_in"
					variant="standard"
					onEnter={this.filter}
					noLabel
					placeholder="Select Accounts"
				/>
			</div>
		);
	}

	render_filter_buttons = () => {
		const { classes } = this.props;
		const { show_advanced } = this.state;
		const text = (show_advanced ? "Hide" : "Show");
		return (
			<div className={classes.filterButtons}>
				<Button
					classes={{root: classes.filterButtonOverride}}
					onClick={() => this.setState({show_advanced: !this.state.show_advanced})}
					color="primary"
				>
					<span className={classes.buttonIcon}><FilterListIcon /></span>
					<span className={classes.label}>{text}</span>
				</Button>
				<Button 
					classes={{root: classes.cancelButtonOverride}} 
					onClick={() => this.setState({filters: { 
						name_like: null,
						company_id_in: null,
						tags_incall: null,
						unique_id_like: null
					}}, this.load)}
					color="primary"
				>
					<span className={classes.label}>Clear</span>
				</Button>
			</div>
		);
	}

	select_all = () => {
		this.setState( (state) => {
			state.all_selected = true;
			state.devices = [];
			state.device_ids = [];
			return state;
		}, () => this.props.onChange(this.state.device_ids, this.state.devices, true, false, this.state.filters));
	}

	deselect_all = () => {
		this.setState( (state) => {
			state.all_selected = false;
			state.formerly_all_selected = false;
			state.device_ids = [];
			state.devices = [];
			return state;
		}, () => this.props.onChange(this.state.device_ids, this.state.devices, false, false, this.state.filters));
	}

	render_buttons = () => {
		const { classes, single, hideFilters } = this.props;
		const { all_selected, devices, total } = this.state;
		const icon = all_selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
		const text = all_selected ? "Selected" : "Select";
		let select_buttons = single ? "" : (
			<React.Fragment>
				<Button
					disabled={all_selected || total === 0}
					classes={{root: classes.selectButton}}
					onClick={this.select_all}
					color="primary"
				>
					<span className={classes.selectIcon}>{icon}</span>
					<span className={classes.label}>{text}</span>
				</Button>
				<Button
					disabled={devices.length === 0 && all_selected === false}
					classes={{root: classes.deselectButton}}
					onClick={this.deselect_all}
					color="primary"
				>
					<span className={classes.label}>Deselect</span>
				</Button>
			</React.Fragment>
		);
		return (
			<div>
				<div className={classes.filterButtons}>
					{select_buttons}
					{!hideFilters ? this.render_filter_buttons() : ""}
				</div>
			</div>
		);
	}

	render_selection_info = () => {
		const { device_ids, devices_shown, all_selected, formerly_all_selected, total } = this.state;
		const { classes } = this.props;
		if ( (device_ids.length === 0 && !all_selected) || devices_shown == null) {
			return "";
		}
		if (all_selected) {
			return (
				<div className={classes.devicesSelected}>
					{total} Device{total > 1 ? 's' : ''} Selected.
				</div>
			);
		} else if (formerly_all_selected) {
			return (
				<div className={classes.devicesSelected}>
					{(total - device_ids.length)} Device{(total - device_ids.length) > 1 ? 's' : ''} Selected.
				</div>
			);
		} else {
			return (
				<div className={classes.devicesSelected}>
					{device_ids.length} Device{device_ids.length > 1 ? 's' : ''} Selected.
				</div>
			);
		}
	}

	render_table = () => {
		const { devices_shown } = this.state;
		const { classes, isEscrow } = this.props;
		return (
			<div className={classes.tableContainer}>
				{isEscrow ? this.render_escrow_headings() : this.render_headings()}
				{devices_shown ? 
					<React.Fragment>
						<div>
							{devices_shown.map( (device) => (
								isEscrow ? this.render_escrow_row(device) : this.render_row(device)
							))}
						</div>
						{this.render_pagination_info()}
					</React.Fragment>
					:
					this.render_table_loading()
				}
			</div>
		);
	}

	render_pagination_info = () => {
		const { devices_shown, page, per_page, total } = this.state;
		const { classes } = this.props;
		let start = 0;
		let end = 0;
		if (devices_shown.length !== 0) {
			start = (per_page * (page - 1)) + 1;
			end = devices_shown.length + start - 1; 
		}
		return (
			<div className={classes.paginationContainer}>
				<div className={classes.paginationText}>
					{start} - {end} of {total}
				</div>
				<div className={classes.pageArrowsContainer}>
					<IconButton disabled={start <= 1} className={classes.pageButton} onClick={() => this.page_change(-1)} aria-label="page_left">
						<NavigateBeforeIcon />
					</IconButton>
					<IconButton disabled={end === total} className={classes.pageButton} onClick={() => this.page_change(1)} aria-label="page_right">
						<NavigateNextIcon />
					</IconButton>
				</div>
			</div>
		);
	}

	render_checkbox = (device) => {
		const { device_ids, all_selected, formerly_all_selected } = this.state;
		const { classes } = this.props;
		let checked = false;
		if (all_selected) {
			checked = true;
		} else if (formerly_all_selected) {
			checked = !Boolean(device_ids.find( (id) => id === device._id));
		} else {
			checked = Boolean(device_ids.find( (id) => id === device._id));
		}
		return (
			<CustomCheckbox
				checked={checked}
				onChange={this.handle_checkbox(device._id)}
				value={device._id}
				color={"primary"}
				className={classes.checkbox}
				inputProps={{
					'aria-label': 'selection checkbox',
				}}
			/>
		);
	}

	handle_checkbox = id => event => {
		this.setState( (state) => {
			let device = state.devices_shown.find( ({_id}) => _id === id);
			if (this.props.single) {
				if (state.device_ids[0] === id) {
					state.devices = [];
					state.device_ids = [];
				} else {
					state.devices = [device];
					state.device_ids = [id];
				}
				return state;
			}
			if (state.all_selected) {
				state.all_selected = false;
				if (state.devices_shown.length !== 1) {
					state.formerly_all_selected = true;
					state.devices = [device];
					state.device_ids = [id];
				} else {
					let index = state.device_ids.indexOf(id);
					if (index >= 0) {
						state.devices.splice(index, 1);
						state.device_ids.splice(index, 1);
					}
				}
			} else {
				let index = state.device_ids.indexOf(id);
				if (index >= 0) {
					state.devices.splice(index, 1);
					state.device_ids.splice(index, 1);
				} else {
					state.devices.push(device);
					state.device_ids.push(id);
				}
				if (state.formerly_all_selected) {
					state.all_selected = state.devices.length === 0;
					if (state.all_selected) state.formerly_all_selected = false;
					if (state.devices.length === state.total) {
						state.formerly_all_selected = false;
						state.all_selected = false;
						state.devices = [];
						state.device_ids = [];
					} 
				} else {
					state.all_selected = state.devices.length === state.total;
				}
			}
			return state;
		}, () => this.props.onChange(this.state.device_ids, this.state.devices, this.state.all_selected, this.state.formerly_all_selected, this.state.filters));
	}

	render_escrow_row = (device) => {
		const { classes } = this.props;
		return (
			<div key={device._id} className={classes.row + " " + classes.deviceRow}>
				<div className={classes.selectedColumn}>
					{this.render_checkbox(device)}
				</div>
				<div className={classes.column + " " + classes.uidColumn}>
					{device.unique_id}
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					{device.nested_company.name}
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					{device.token}
				</div>
			</div>
		);
	}

	render_row = (device) => {
		const { classes, theme } = this.props;
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		return (
			<div key={device._id} className={classes.row + " " + classes.deviceRow}>
				<div className={classes.selectedColumn}>
					{this.render_checkbox(device)}
				</div>
				<div style={{backgroundColor: status_map[device.heartbeat_status]}} className={classes.statusDot}>
					&nbsp;
				</div>
				<div className={classes.column + " " + classes.nameColumn}>
					{device.name}
				</div>
				<div className={classes.column + " " + classes.uidColumn}>
					{device.unique_id}
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					{device.nested_company.name}
				</div>
				<div className={classes.column + " " + classes.tagsColumn}>
					{device.tags && device.tags.length > 0 ? device.tags.join(", ") : "No Tags"}
				</div>
			</div>
		);
	}

	render_escrow_headings = () => {
		const { classes } = this.props;
		return (
			<div className={classes.row}>
				<div className={classes.selectedColumn}>
					SELECTED
				</div>
				<div className={classes.column + " " + classes.uidColumn}>
					UNIQUE ID
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					ACCOUNT
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					TOKEN
				</div>
			</div>
		);
	}

	render_headings = () => {
		const { classes } = this.props;
		return (
			<div className={classes.row}>
				<div className={classes.selectedColumn}>
					SELECTED
				</div>
				<div className={classes.column + " " + classes.nameColumn}>
					NAME
				</div>
				<div className={classes.column + " " + classes.uidColumn}>
					UNIQUE ID
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					ACCOUNT
				</div>
				<div className={classes.column + " " + classes.tagsColumn}>
					TAGS
				</div>
			</div>
		);
	}

	render_table_loading = () => {
		return "";
	}

	render() {
		const { company_options, tag_options } = this.state;
		const { hideSearch } = this.props;
		if (company_options === null) {
			return <Loading />;
		}
		return (
			<div>
				{!hideSearch ? <div>
					{this.render_search()}
				</div> : ""}
				<div>
					{this.render_buttons()}
				</div>
				{this.render_table()}
				{this.render_selection_info()}
			</div>
		)
	}

}

DeviceSelectionInput.contextType = SnackbarContext;
const styles = (theme) => {
	return ({
		pageButton: {
			padding: "4px",
			color: "#8e8e93",
		},
		pageButtonDisabled: {
			color: "grey",
			cursor: "unset"
		},
		paginationContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			margin: "4px 0",
			fontSize: "14px",
		},
		paginationText: {
			fontSize: "14px",
			color: "#636366",
		},
		filterButtons: {
			display: "flex",
		},
		filterButtonOverride: {
			marginLeft: "12px",
		},
		cancelButtonOverride: {
			marginLeft: "12px",
		},
		selectButton: {
		},
		deselectButton: {
			marginLeft: "12px",
		},
		label: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		buttonIcon: {
			marginRight: "8px",
			display: "flex",
		},
		selectIcon: {
			marginRight: "8px",
			display: "flex",
		},
		pageArrowsContainer: {
			marginLeft: "12px"
		},
		row: {
			display: "flex",
			alignItems: "center",
			color: "grey",
			padding: "12px 0",
			fontSize: "14px",
			borderBottom: "solid lightgrey 1px",
			boxSizing: "border-box",
			position: "relative"
		},
		deviceRow: {
			color: "black"
		},
		noAdvanced: {
			display: "flex",
			marginTop: "12px"
		},
		searchName: {
			width: "100%",
			marginRight: "12px"
		},
		advancedFilter: {
			width: "48%",
			marginRight: "12px"
		},
		lookupWrapper: {
			marginBottom: 0,
			marginTop: "auto",
		},
		advancedFilterContainer: {
			display: "flex",
			alignItems: "center",
			flexWrap: "wrap",
			marginBottom: "12px"
		},
		column: {
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			padding: "0 4px",
			boxSizing: "border-box",
		},
		selectedColumn: {
			width: "15%",
			textAlign: "center",
		},
		nameColumn: {
			width: "25%",
			textAlign: "left"
		},
		uidColumn: {
			width: "20%",
			textAlign: "left"
		},
		accountColumn: {
			width: "20%",
			textAlign: "left"
		},
		tagsColumn: {
			width: "20%",
			textAlign: "left"
		},
		statusDot: {
			width: "8px",
			height: "8px",
			minWidth: "8px",
			minHeight: "8px",
			maxWidth: "8px",
			maxHeight: "8px",
			borderRadius: "50%",
			display: "inline-flex",
			position: "absolute",
			left: "13.5%",
			top: "19px"
		},
		checkbox: {
			padding: 0
		},
		tableContainer: {
			marginTop: "12px"
		},
		devicesSelected: {
			color: theme.palette.pending.main,
			marginLeft: "12px",
			fontSize: "16px",
		},
	});
};

const CustomCheckbox = withStyles({
	root: {
		color: "rgba(0, 0, 0, 0.24)",
	  '&$checked': {
		color: "#1153b6",
	  },
	},
	checked: {},
  })(props => <Checkbox color="default" {...props} />);

export default compose(
	withStyles(styles),
	withTheme(),
)(DeviceSelectionInput);
