import axios from 'axios';
import { READ, readMeIntoAuth, API_PROXY } from "./CLURD";

let env = window.API;
//ensures a lack of trailing backslash doesn't break everything.
if (env.charAt(env.length - 1) !== '/') {
	env += "/";
}

// FYI, this class has evolved somewhat, into a "Current session" manager, but retains the AuthenticationManager name
class AuthenticationManager {

	constructor() {
		this.u = null;
		this.userChangeListeners = [];
	}

	addUserChangeListener(l) {
		this.userChangeListeners.push(l);
	}

	notifyUserChangeListeners() {
		this.userChangeListeners.forEach((l) => {
			if (l.userDidChange) {
				l.userDidChange(this.u);
			} else {
				console.error("Trying to notify a user change listener that doesn't implement `userDidChange`:", l);
			}
		});
	}

	reloadUser() {
		this.setUser(null);
		this.loadMe();
	}

	setUser(newUser) {
		this.u = newUser;
		if (newUser) localStorage.setItem(TOKEN_KEY, JSON.stringify(newUser.session_token));
		this.notifyUserChangeListeners();
	}

	user() { return this.currentUser() }

	currentUser() {
		return this.u;
	}

	currentRole() {
		return this.u ? this.u.nested_user_type : null;
	}

	currentRoleIsAdmin() {
		return this.currentRole() && this.currentRole().name === 'admins';
	}

	currentCompany() {
		return this.u ? this.u.nested_company : null;
	}

	currentBranding() {
		let company = this.currentCompany();
		if (company) {
			return company.branding;
		} else {
			return null;
		}
	}
	
	// widget_id should correspond to available keys in company.portal_configuration.widgets
	widgetHidden(widget_id) {
		let company = this.currentCompany();
		if (company && company.portal_configuration && company.portal_configuration.widgets) {
			return company.portal_configuration.widgets[widget_id + "_hidden"];
		} else {
			return true;
		}
	}

	currentPortalTitle() {
		let defaultTitle = "EdgeIQ Hub";
		let branding = this.currentBranding();
		if (branding) {
			let portalTitle = branding.portal_title;
			if (portalTitle && portalTitle !== "") {
				return portalTitle;
			} else {
				return defaultTitle;
			}
		} else {
			return defaultTitle;
		}
	}

	resetToken(body) {
		return new Promise( (resolve, reject) => {
			var options = {
				method: 'POST',
				url: `${env}user/api_token_reset`,
				data: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				json: true
			};
			axios(options).then( (result) => {
				resolve(result.data.api_token);
			}).catch( (error) => {
				reject(error);
			});
		});
	}

	token() {
		if (this.u) {
			return this.u.session_token;
		} else {
			return JSON.parse(localStorage.getItem(TOKEN_KEY));
		}
	}

	logout(cb) {
		localStorage.clear();
		Auth.setUser(null);
		cb();
	}

	authenticate(body, success, failure) {
		let expirationTime = 60000 * 60 * 48;
		var now = new Date();
		var expiration = new Date(now.getTime() + expirationTime);
		body.token_expiry = expiration;
		var authOptions = {
			method: 'POST',
			url: `${env}user/authenticate`,
			data: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			json: true
		};
		axios(authOptions).then( async (response) => {
			let usr = response.data;
			usr = await this.assignRoleAndCompany(usr);
			this.setUser(usr);
			success(this.u);
		}).catch( (error) => {
			failure(error);
		});
	}

	promptPasswordEmail(email) {
		return new Promise( (resolve, reject) => {
			let body = {email: email, url: window.location.origin + "/reset_password"};
			var authOptions = {
				method: 'POST',
				url: `${env}user/password_reset_request`,
				data: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				json: true
			};
			axios(authOptions).then( (response) => {
				resolve();
			}).catch( (error) => {
				reject(error);
			});
		});
		
	}

	testToken(token, success, failure) {
		let body = {password: "these strings", password_confirmation: "do not match"};
		var authOptions = {
			method: 'POST',
			url: `${env}user/password_reset/${token}`,
			data: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			json: true
		};
		axios(authOptions).then( async (response) => {
			success();
		}).catch( (error) => {
			if (error === "Invalid token") {
				failure("This token has expired. Please request another email on the login page.");
			} else if (error === "Invalid password") {
				success();
			} else {
				failure("An error has occurred. Please request another email on the login page.");
			}
		});
	}

	resetPassword(password, token) {
		return new Promise( (resolve, reject) => {
			let body = {password: password, password_confirmation: password};
			var options = {
				method: 'POST',
				url: `${env}user/password_reset/${token}`,
				data: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				json: true
			};
			axios(options).then( () => {
				resolve();
			}).catch( (error) => {
				reject(error);
			});
		});
		
	}

	assignRoleAndCompany(user) {
		return new Promise( (resolve, reject) => {
			let company_role_promises = [
				READ("companies", user.company_ids[0], user.session_token),
				READ("user_types", user.user_type_id, user.session_token)
			];
			Promise.all(company_role_promises).then( (results) => {
				user.nested_company = results[0];
				user.nested_user_type = results[1];
				resolve(user);
			}).catch( (error) => {
				reject(error);
			});
		});	
	}
 
	loadMe() {
		return new Promise((resolve, reject) => {
			readMeIntoAuth(this.token()).then( (response) => {
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	}
}

const TOKEN_KEY = "MSTOKEN";
const Auth = new AuthenticationManager();

export default Auth;
