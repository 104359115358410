import React from 'react';
import { compose } from 'recompose';
import Loading from '../DisplayOriented/Loading';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Auth from '../../services/Auth';
import EscrowDevice from '../../services/DataModels/EscrowDevice';
import Permissions from '../../services/Permissions';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

class EscrowForm extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			editable_copy: {
				company_id: Auth.currentCompany()._id,
				unique_id: "",
				token: ""
			},
			account_options: null,
			is_editing: false,
			show_errors: false,
			submitting: false,
			verifying: false
		};
		this.set_form();
		this.set_account_input();
	}

	set_form = () => {
		if (this.props.escrowDevice) {
			this.state.is_editing = true;
			this.state.editable_copy = {
				company_id: this.props.escrowDevice.company_id,
				unique_id: this.props.escrowDevice.unique_id,
				token: this.props.escrowDevice.token,
				_id: this.props.escrowDevice._id
			};
		}
	}

	set_account_input = () => {
		let accounts = Auth.currentUser().company_ids;
		if (accounts.length > 0) {
			accounts = accounts.filter( (account) => Permissions.allow(["create", "update"], "escrow_device", account._id));
			if (accounts.length > 0) {
				this.load_account_options(accounts);
			}
		} 
	}

	load_account_options = (account_ids) => {
		let params = {_id_in: account_ids };
		GetAll("companies", params).then( (result) => {
			let accounts = result.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0)) || [];
			this.setState({account_options: accounts.map( (account) => ({display: account.name, value: account._id}) )});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	on_form_change = ({field, value}) => {
		this.setState( (state) => {
			state.editable_copy[field] = value;
			return state;
		});
	}

	render_title = () => {
		const classes = this.props.classes;
		const is_editing = this.state.is_editing;
		const text = is_editing ? "Edit this Escrow Device" : "Creating a New Escrow Device";
		return (
			<div className={classes.modalTitle}>
				{text}
			</div>
		);
	}

	render_account = () => {
		const { classes } = this.props;
		const { editable_copy, account_options } = this.state;
		let input = (<SelectInput
			label="Account"
			field="company_id"
			emitChange={this.on_form_change}
			priorState={editable_copy.company_id}
			options={account_options}
		/>);
		if (account_options.length === 0) {
			input = (<span>You lack the permissions to create or edit an escrow device for any account.</span>);
		}
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon}/>
				{input}
			</div>
		);
	}

	unique_id_error = () => {
		const uid = this.state.editable_copy.unique_id;
		if (uid === "" || uid.indexOf(" ") !== -1 || /[A-Z]/.test(uid)) {
			return true;
		} else {
			return false;
		}
	}

	render_unique_id = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				{this.render_unique_id_icon()}
				<TextInput
					label="Unique ID*"
					emitChange={this.on_form_change}
					priorState={editable_copy.unique_id}
					field="unique_id"
					error={show_errors && this.unique_id_error()}
					error_message="A unique ID can only contain lower case letters, numbers or the special charaters - , : , or _ and it must be unique."
				/>
			</div>
		);
	}

	render_unique_id_icon = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.idContainer}>
				<div className={classes.idLabel}>ID</div>
			</div>
		);
	}

	render_buttons = () => {
		const { closeModal, classes } = this.props;
		const { is_editing, verifying } = this.state;
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={closeModal}
					className={classes.buttonOverride}
					aria-label="cancel"
					color="primary"
				>
					Cancel
				</Button>
				<Button
					onClick={this.submit}
					disabled={verifying}
					variant="contained"
					className={classes.buttonOverride}
					aria-label="create"
					color="primary"
					size="large"
				>
						{is_editing ? "Save" : "Create" } Escrow Device
				</Button>
			</div>
		);
	}

	submit = () => {
		this.setState({verifying: true});
		const { editable_copy, is_editing } = this.state;
		if ( this.unique_id_error() || editable_copy.token === "" ) {
			this.setState({show_errors: true, verifying: false});
			return;
		}
		this.setState({submitting: true});
		new EscrowDevice(editable_copy).setData(editable_copy).saveOrCreate().then( (result) => {
			let message = is_editing ? "Escrow Device Saved." : "Escrow Device Created.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate();
		}).catch( (error) => {
			this.setState({verifying: false, submitting: false});
			this.context.openSnackbar(error, "error");
		});
	}

	render_token = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<VpnKeyIcon className={classes.inputIcon}/>
				<TextInput
					label="Token*"
					emitChange={this.on_form_change}
					priorState={editable_copy.token}
					field="token"
					error={show_errors && editable_copy.token === ""}
					error_message={"Please define a token for this escrow device."}
				/>
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		const { submitting, account_options } = this.state;
		if (submitting || account_options == null ) {
			return (
				<div className={classes.container}>
					<Loading />
				</div>
			);
		}
		return (
			<div className={classes.container}>
				{this.render_title()}
				{this.render_account()}
				{this.render_unique_id()}
				{this.render_token()}
				{this.render_buttons()}
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			boxSizing: "border-box",
			height: "382px",
		},
		modalTitle: {
			fontSize: "20px",
			lineHeight: "32px",
			fontWeight: "700",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "42px"
		},
		selectInputWrapper: {
			marginTop: "18px",
			minHeight: "78px",
			marginBottom: "26px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		idContainer: {
			display: "flex",
			alignItems: "center",
			padding: "5px",
			marginRight: "12px",
			marginBottom: "20px",
			boxSizing: "border-box",
			width: "24px"
		},
		idLabel: {
			fontSize: "16px",
			color: "rgba(0,0,0,.42)",
			marginRight: "6px"
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		buttonOverride: {
			marginLeft: "8px",
		},
	});
};

EscrowForm.contextType = SnackbarContext;

export default compose(
	withStyles(styles),
)(EscrowForm);
