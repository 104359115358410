import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
	tabsContainer: {
		width: "100%",
	},
	tab: {
		padding: "16px 0 16px 24px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		justifyContent: "flex-start",
		textTransform: "uppercase",
		cursor: "pointer",
		opacity: .7,
		textAlign: "center",
		fontSize: "14px",
		boxSizing: "border-box",
	},
	active: {
		opacity: 1,
		backgroundColor: lighten(theme.palette.pending.main, .9),
		color: theme.palette.pending.main,
	}
});

class VerticalTabs extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			tab_index: this.props.initial ? this.props.initial : 0
		};
	}

	tab_change = (value) => {
		this.setState({ tab_index: value }, () => {
			this.props.onChange(this.props.tabs[this.state.tab_index], value);
		});
	}

	render(){
		const { classes, tabs } = this.props;
		const { tab_index } = this.state;
		return (
			<div
				className={classes.tabsContainer}
			>
				{tabs.map( (tab, index) => (
					<div 
						style={(tab.disabled ? {color:"grey"} : {})}
						className={classes.tab + " " + (index === tab_index ? classes.active : '')}
						key={index}
						onClick={tab.disabled ? null : () => this.tab_change(index)}
					>
						{tab.label}
					</div>
				))}
			</div>
		);
	}
}


export default withStyles(styles)(withTheme()(VerticalTabs));
