import React from 'react';
import { compose } from 'recompose';
import Loading from '../../DisplayOriented/Loading';
import VerticalTabs from '../../DisplayOriented/VerticalTabs';
import TableList from '../../Table/TableList';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import CreateSoftwareUpdateForm from '../../SoftwareUpdatesSpecific/CreateSoftwareUpdateForm';
import DeviceTypeForm from '../../TypesSpecific/DeviceTypeForm';
import PaginationContainer from '../../Containers/PaginationContainer';

//services
import Permissions from '../../../services/Permissions';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import DeviceType from '../../../services/DataModels/DeviceType';
import SoftwareUpdate from '../../../services/DataModels/SoftwareUpdate';
import Ingestor from '../../../services/DataModels/Ingestor';
import Command from '../../../services/DataModels/Command';
import PollableAttribute from '../../../services/DataModels/PollableAttribute';
import Device from '../../../services/DataModels/Device';
import { GetAll } from '../../../services/CLURDUtilities';
import Rule from '../../../services/DataModels/Rule';
import Humanize from '../../../services/Humanize';
import { GET_RELATIONS, ADD_RELATIONS, REMOVE_RELATION } from '../../../services/Relation';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

//icons
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WarningIcon from '@material-ui/icons/Warning';
import BuildIcon from '@material-ui/icons/Build';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DevicesOtherIcon from '@material-ui/icons/DevicesOtherOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import RouterIcon from '@material-ui/icons/Router';
import NotesIcon from '@material-ui/icons/Notes';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

//inputs
import SwitchInput from '../../Inputs/SwitchInput';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';
import LookupInput from '../../Inputs/LookupInput';
import MetadataEditor from '../../common/MetadataEditor/MetadataEditorComponent'
import AceEditor from "react-ace";

class TypeTab extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            image: <Loading />,
            subnav: 0,
            ingestors: [],
            commands: [],
            pollable_attributes: [],
            pollable_attributes_page_data: {
                page_meta: true,
                per_page: 10,
                page: 1,
            },
            policies: [],
            software_updates: [],
            menus: {},
            tabs: null,
            modal: {
                open: false,
                children: () => ""
            },
            editable_copy: {},
            updated_type: null,
            range: 0,
            verifying: false,
            ensuring: true,
            typeIsInherited: false,
            can_edit: null,
            can_delete: null,
            show_errors: false,
            execute_payload: "",
            execute_command: {},
        };
        this.props.tabHostProxy.setTabRefresh(this.refresh);
        this.set_headings();
        this.load_and_ensure();
    }

    refresh = () => {
        this.setState({ ensuring: true, subnav: 0 });
        return new DeviceType({ _id: this.state.updated_type._id }).readFromAPI().then((result) => {
            let types = [result];
            DeviceType.loadRequirements(types).then((types) => {
                this.props.tabHostProxy.updateTitle(types[0]._id, types[0]);
                this.props.tabHostProxy.setTabRefresh(this.refresh);
                this.ensure_ready(types[0]);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    load_and_ensure() {
        new DeviceType({ _id: this.props.data._id }).readFromAPI().then((result) => {
            let types = [result];
            DeviceType.loadRequirements(types).then((types) => {
                this.ensure_ready(types[0]);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.props.tabHostProxy.closeSelf();
        });
    }

    ensure_ready = (updated_type) => {
        this.setState((state) => {
            state.typeIsInherited = updated_type.nested_company == null;
            state.updated_type = updated_type;
            state.can_edit = Permissions.allow(["update"], "device_type", updated_type.company_id) && !state.typeIsInherited;
            state.can_delete = Permissions.allow(["delete"], "device_type", updated_type.company_id) && !state.typeIsInherited;
            return state;
        });
        let can_read_rule = (Permissions.allow(["read"], "rule", updated_type.company_id));
        let can_read_ingestor = (Permissions.allow(["read"], "ingestor", updated_type.company_id));
        let can_read_command = (Permissions.allow(["read"], "command", updated_type.company_id));
        let can_read_pollable_attribute = (Permissions.allow(["read"], "pollable_attribute", updated_type.company_id));
        let can_read_software_update = (Permissions.allow(["read"], "software_update", updated_type.company_id));
        let assetLoaderPromises = [this.prep_image(updated_type)];
        if (can_read_rule) { assetLoaderPromises.push(this.load_policies(updated_type)) }
        if (can_read_software_update) { assetLoaderPromises.push(this.load_software_updates(updated_type)) }
        if (can_read_ingestor) { assetLoaderPromises.push(this.load_ingestors(updated_type)) }
        if (can_read_command) { assetLoaderPromises.push(this.load_commands(updated_type)) }
        if (can_read_pollable_attribute) { assetLoaderPromises.push(this.load_pollable_attributes(updated_type)) }

        Promise.all(assetLoaderPromises).then(() => {
            const connections = updated_type.capabilities.network_connections;
            const connection_count = (Boolean(connections) && Array.isArray(connections)) ? connections.length : 0;
            this.setState((state) => {
                state.tabs = [
                    { label: `Overview`, render: this.render_overview },
                    { label: `Abilities`, render: this.render_abilities },
                    { label: `Connections (${connection_count})`, render: this.render_connections },
                    { label: `Ingestors (${state.ingestors.length})`, render: this.render_ingestors, disabled: !can_read_ingestor },
                    { label: `Commands (${state.commands.length})`, render: this.render_commands, disabled: !can_read_command },
                    { label: `Pollable Attributes (${state.pollable_attributes_page_data.total})`, render: this.render_pollable_attributes, disabled: !can_read_pollable_attribute },
                    { label: `Policies (${state.policies.length})`, render: this.render_policies, disabled: !can_read_rule },
                    { label: `Software Updates`, render: this.render_software, disabled: !can_read_software_update }
                ];
                state.editable_copy = {
                    actions: JSON.parse(JSON.stringify(state.updated_type.capabilities.actions))
                };
                state.ensuring = false;
                return state;
            });
        }).catch((error) => {
            console.log(error);
        });

    }

    set_headings = () => {
        this.policy_headings = [
            { label: "Description", value: "description", field: "description", align: "left", sortable: false },
            { label: "Account", field: "account_name", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
        this.software_headings = [
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Account", field: "account_name", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
        this.ingestor_headings = [
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Account", field: "account_name", align: "left", sortable: false },
            { label: "Listener Type", field: "listener_display", align: "left", sortable: false },
            { label: "Handler Type", field: "handler_display", align: "left", sortable: false },
            { label: "Translator", field: "translator_display", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
        this.command_headings = [
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Account", field: "account_name", align: "left", sortable: false },
            { label: "Sender Type", field: "sender_display", align: "left", sortable: false },
            { label: "Translator", field: "translator_display", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
        this.pollable_attribute_headings = [
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Type", field: "type", align: "left", sortable: false },
            { label: "Interval", field: "interval", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false },
        ];
    }

    load_software_updates = (type) => {
        return new Promise((resolve, reject) => {
            const body = { device_type_id: type._id };
            GetAll("software_updates", body).then((software_updates) => {
                SoftwareUpdate.loadRequirements(software_updates).then(() => {
                    software_updates = this.prepare_updates(software_updates);
                    this.setState({ software_updates: software_updates });
                    resolve();
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_policies = (type) => {
        return new Promise((resolve, reject) => {
            GET_RELATIONS("device_types", type._id, "rules").then((policies) => {
                Rule.loadRequirements(policies).then(() => {
                    policies = this.prepare_policies(policies);
                    this.setState({ policies: policies });
                    resolve();
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    openPolicyTab = (policy) => {
        const policy_item = {
            account_name: policy.account_name,
            active: policy.active,
            cloud_rule: policy.cloud_rule,
            company_id: policy.company_id,
            created_at: policy.created_at,
            description: policy.description,
            else_actions: policy.else_actions,
            fail_count: policy.fail_count,
            last_run_status: policy.last_run_status,
            nested_company: policy.nested_company,
            origin: policy.origin,
            pass_count: policy.pass_count,
            plain_english: policy.plain_english,
            rule_condition: policy.rule_condition,
            then_actions: policy.then_actions,
            updated_at: policy.updated_at,
            user_id: policy.user_id,
            _id: policy._id
        };
        this.props.tabHostProxy.addTab("rule", policy_item);
    }

    prepare_policies = (policies) => {
        const classes = this.props.classes;
        policies.forEach((policy) => {
            policy.account_name = policy.nested_company ? policy.nested_company.name : "Inherited";
            policy.action =
                (<React.Fragment>
                    <Tooltip title="View">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => {
                                this.openPolicyTab(policy)
                            }}
                            className={classes.deleteIconButton}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => this.remove_policy(policy._id)}
                            className={classes.deleteIconButton}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>);
        });
        return policies;
    }

    prepare_updates = (updates) => {
        const classes = this.props.classes;
        updates.forEach((update) => {
            update.account_name = update.nested_company ? update.nested_company.name : "Inherited";
            update.action =
                (<IconButton
                    style={{ padding: "4px" }}
                    onClick={() => this.delete_update(update._id)}
                    className={classes.deleteIconButton}
                >
                    <DeleteIcon />
                </IconButton>);
        });
        return updates;
    }

    delete_update = (_id) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to delete this software update?`,
                yesFunction: () => this.submit_delete_update(_id),
                functionText: "Delete",
                children: () => { }
            }
        });
    }

    load_ingestors = (type) => {
        return new Promise((resolve, reject) => {
            GET_RELATIONS("device_types", type._id, "ingestors").then((ingestors) => {
                Ingestor.loadRequirements(ingestors).then(() => {
                    ingestors = this.prepare_ingestors(ingestors);
                    this.setState({ ingestors: ingestors });
                    resolve();
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_commands = (type) => {
        return new Promise((resolve, reject) => {
            GET_RELATIONS("device_types", type._id, "commands").then((commands) => {
                Command.loadRequirements(commands).then(() => {
                    commands = this.prepare_commands(commands);
                    this.setState({ commands: commands });
                    resolve();
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_pollable_attributes = (type) => {
        return new Promise((resolve, reject) => {
            let params = {
                page_meta: true,
                per_page: this.state.pollable_attributes_page_data.per_page,
                page: this.state.pollable_attributes_page_data.page
            }
            GET_RELATIONS("device_types", type._id, "pollable_attributes", params).then((data) => {
                var pollable_attributes = data.resources;
                PollableAttribute.loadRequirements(pollable_attributes).then(() => {
                    pollable_attributes = this.prepare_pollable_attributes(pollable_attributes);
                    this.setState({
                        pollable_attributes: pollable_attributes,
                        pollable_attributes_page_data: {
                            total: data.total,
                            page: data.page,
                            per_page: data.per_page
                        }
                    });
                    resolve();
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    openIngestorTab = (ingestor) => {
        const ingestor_item = {
            account_name: ingestor.account_name,
            company_id: ingestor.company_id,
            created_at: ingestor.created_at,
            name: ingestor.name,
            handler_display: ingestor.handler_display,
            handler_type: ingestor.handler_type,
            listener: ingestor.listener,
            listener_display: ingestor.listener_display,
            listener_type: ingestor.listener_type,
            nested_company: ingestor.nested_company,
            nested_translator: ingestor.nested_translator,
            origin: ingestor.origin,
            translator_display: ingestor.translator_display,
            translator_id: ingestor.translator_id,
            type: ingestor.type,
            updated_at: ingestor.updated_at,
            user_id: ingestor.user_id,
            _id: ingestor._id,
        }
        this.props.tabHostProxy.addTab("ingestor", ingestor_item);
    }

    openCommandTab = (command) => {
        const command_item = {
            account_name: command.account_name,
            company_id: command.company_id,
            created_at: command.created_at,
            name: command.name,
            sender: command.sender,
            sender_display: command.sender_display,
            sender_type: command.sender_type,
            nested_company: command.nested_company,
            nested_translator: command.nested_translator,
            origin: command.origin,
            translator_display: command.translator_display,
            translator_id: command.translator_id,
            type: command.type,
            updated_at: command.updated_at,
            user_id: command.user_id,
            _id: command._id,
        }
        this.props.tabHostProxy.addTab("command", command_item);
    }

    prepare_ingestors = (ingestors) => {
        const classes = this.props.classes;
        let prepped_ingestors = Ingestor.prepareForTable(ingestors, () => "", this.props.classes);
        prepped_ingestors.forEach((ingestor, index) => {
            ingestor.action =
                (<React.Fragment>
                    <Tooltip title="View">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => {
                                this.openIngestorTab(ingestor);
                            }}
                            className={classes.deleteIconButton}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => this.remove_ingestor(ingestor._id)}
                            className={classes.deleteIconButton}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>);
        });
        return prepped_ingestors;
    }


    prepare_commands = (commands) => {
        const classes = this.props.classes;
        let prepped_commands = Command.prepareForTable(commands, () => "", this.props.classes);
        prepped_commands.forEach((command, index) => {
            command.action =
                (<React.Fragment>
                    <Tooltip title="Execute">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => {
                                this.executeModal(command);
                            }}
                            className={classes.deleteIconButton}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => {
                                this.openCommandTab(command);
                            }}
                            className={classes.deleteIconButton}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => this.remove_command(command._id)}
                            className={classes.deleteIconButton}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>);
        });
        return prepped_commands;
    }

    openPollableAttributeTab = (pollable_attribute) => {
        const pollable_attribute_item = {
            attributes: pollable_attribute.attributes,
            defaults: pollable_attribute.defaults,
            interval: pollable_attribute.interval,
            account_name: pollable_attribute.account_name,
            company_id: pollable_attribute.company_id,
            created_at: pollable_attribute.created_at,
            name: pollable_attribute.name,
            nested_company: pollable_attribute.nested_company,
            origin: pollable_attribute.origin,
            type: pollable_attribute.type,
            updated_at: pollable_attribute.updated_at,
            user_id: pollable_attribute.user_id,
            _id: pollable_attribute._id,
        };
        this.props.tabHostProxy.addTab("pollable_attribute", pollable_attribute_item);
    }

    prepare_pollable_attributes = (pollable_attributes) => {
        const classes = this.props.classes;
        pollable_attributes.forEach((pollable_attribute) => {
            pollable_attribute.action =
                (<React.Fragment>
                    <Tooltip title="View">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => {
                                this.openPollableAttributeTab(pollable_attribute);
                            }}
                            className={classes.deleteIconButton}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton
                            style={{ padding: "4px" }}
                            onClick={() => this.remove_pollable_attribute(pollable_attribute._id)}
                            className={classes.deleteIconButton}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>);
        });
        return pollable_attributes;
    }

    render_policy_menu = (item, table) => {
        let anchorEl = this.state.menus[item._id];
        let open = Boolean(anchorEl);
        // onClick={(event) => this.open_ingestor_action_menu(event, item._id)
        let icon = <MoreVertIcon />;
        return (
            <React.Fragment>
                {icon}
                {/* {this.render_ingestor_action_menu(open, anchorEl, item)} */}
            </React.Fragment>
        );
    }

    render_ingestor_action_menu = (event, id) => {
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }

    render_command_action_menu = (event, id) => {
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }

    prep_image = (type) => {
        const { classes } = this.props;
        const imagePlaceholder = {
            height: "140px",
            width: "140px",
            color: "grey",
            border: "solid lightgrey 2px",
            borderRadius: "4px",
        };
        const image = {
            maxHeight: "140px",
            maxWidth: "140px",
            border: "solid lightgrey 2px",
            borderRadius: "4px",
        };
        return new Promise((resolve) => {
            if (!Boolean(type.image) || type.image === "") {
                this.state.image = <DevicesOtherIcon style={imagePlaceholder} className={classes.imagePlaceholder} />;
                resolve();
                return;
            }
            DeviceType.testImage(type.image).then(() => {
                this.setState({ image: <img style={image} src={type.image} className={classes.image} /> });
                resolve();
            }).catch((error) => {
                this.setState({ image: <DevicesOtherIcon style={imagePlaceholder} className={classes.imagePlaceholder} /> });
                resolve();
            });
        });
    }


    executeCommand = (event) => {
        event.preventDefault();
        DeviceType.executeCommand(this.state.updated_type._id, this.state.execute_command._id, this.state.execute_payload).then((result) => {
            this.context.openSnackbar('Command sent to device(s)', 'success');
        }).catch((error) => {
            this.context.openSnackbar(error, 'error');
        });

        this.close_modal();
    }

    on_payload_change = (payload) => {
        this.setState((state) => {
            state.execute_payload = payload;
            return state;
        });
    }

    executeModal = (command) => {

        this.setState({
            execute_command: command,
            modal: {
                open: true,
                children: (classes) =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Execute {command.name}
                        </div>
                        <form onSubmit={this.executeCommand}>
                            <div className={classes.editFormContainer}>
                                <div className={classes.prompt}>
                                    Payload
                                </div>
                                <div className={classes.textInputWrapper}>

                                    <AceEditor
                                        onChange={this.on_payload_change}
                                        value={this.state.execute_payload}
                                        placeholder="Payload JSON"
                                        mode="javascript"
                                        theme="monokai"
                                        fontSize={14}
                                        showPrintMargin={true}
                                        showGutter={true}
                                        padding={"12px"}
                                        highlightActiveLine={true}
                                        width={"100%"}
                                        height={"400px"}
                                        setOptions={{
                                            enableBasicAutocompletion: false,
                                            enableLiveAutocompletion: false,
                                            enableSnippets: false,
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }}
                                    />
                                </div>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        onClick={this.close_modal}
                                        color="primary"
                                        className={classes.buttonOverride}
                                        aria-label="cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.buttonOverride}
                                        aria-label="execute"
                                        type="submit"
                                    >
                                        Execute Command
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
            }
        });
    }


    add_policy = () => {
        const classes = this.props.classes;
        const update_new_policy = ({ value }) => {
            this.setState((state) => {
                state.new_policy.values = value;
                return state;
            });
        }
        this.load_policy_suggestions().then(() => {
            this.setState({
                modal: {
                    open: true,
                    children: () =>
                        <div className={classes.modalWrapper}>
                            <div className={classes.modalTitle}>
                                Attach Policies to this Device Type
                            </div>
                            <div className={classes.newConnectionContainer}>
                                {this.state.new_policy.suggestions.length === 0 ?
                                    <span>There are no policies available. Go to the Policies page to create some.</span> :
                                    <LookupInput
                                        priorState={{ values: this.state.new_policy.values, suggestions: this.state.new_policy.suggestions.map((m) => ({ label: m.description, value: m._id })) }}
                                        placeholder="Select Policies*"
                                        label="Policies"
                                        emitChange={update_new_policy}
                                        error={this.state.show_errors && this.state.new_policy.values.length === 0}
                                        error_message={"Please select at least one policy."}
                                    />}
                            </div>
                            {this.render_modal_buttons(this.submit_add_policy)}
                        </div>
                }
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    add_ingestor = () => {
        const classes = this.props.classes;
        const update_new_ingestor = ({ value }) => {
            this.setState((state) => {
                state.new_ingestor.values = value;
                return state;
            });
        }
        this.load_ingestor_suggestions().then(() => {
            this.setState({
                modal: {
                    open: true,
                    children: () =>
                        <div className={classes.modalWrapper}>
                            <div className={classes.modalTitle}>
                                Attach Ingestors to this Device Type
                            </div>
                            <div className={classes.newConnectionContainer}>
                                {this.state.new_ingestor.suggestions.length === 0 ?
                                    <span>There are no ingestors available. Go to the Ingest page to create some.</span> :
                                    <LookupInput
                                        priorState={{ values: this.state.new_ingestor.values, suggestions: this.state.new_ingestor.suggestions.map((m) => ({ label: m.name, value: m._id })) }}
                                        placeholder="Select Ingestors*"
                                        label="Ingestors"
                                        emitChange={update_new_ingestor}
                                        error={this.state.show_errors && this.state.new_ingestor.values.length === 0}
                                        error_message={"Please select at least one ingestor."}
                                    />}
                            </div>
                            {this.render_modal_buttons(this.submit_add_ingestor)}
                        </div>
                }
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    add_command = () => {
        const classes = this.props.classes;
        const update_new_command = ({ value }) => {
            this.setState((state) => {
                state.new_command.values = value;
                return state;
            });
        }
        this.load_command_suggestions().then(() => {
            this.setState({
                modal: {
                    open: true,
                    children: () =>
                        <div className={classes.modalWrapper}>
                            <div className={classes.modalTitle}>
                                Attach Commands to this Device Type
                            </div>
                            <div className={classes.newConnectionContainer}>
                                {this.state.new_command.suggestions.length === 0 ?
                                    <span>There are no commands available. Go to the Ingest page to create some.</span> :
                                    <LookupInput
                                        priorState={{ values: this.state.new_command.values, suggestions: this.state.new_command.suggestions.map((m) => ({ label: m.name, value: m._id })) }}
                                        placeholder="Select Commands*"
                                        label="Commands"
                                        emitChange={update_new_command}
                                        error={this.state.show_errors && this.state.new_command.values.length === 0}
                                        error_message={"Please select at least one command."}
                                    />}
                            </div>
                            {this.render_modal_buttons(this.submit_add_command)}
                        </div>
                }
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    add_pollable_attribute = () => {
        const classes = this.props.classes;
        const update_new_pollable_attribute = ({ value }) => {
            this.setState((state) => {
                state.new_pollable_attribute.values = value;
                return state;
            });
        }
        this.load_pollable_attribute_suggestions().then(() => {
            console.log(this.state.new_pollable_attribute)
            this.setState({
                modal: {
                    open: true,
                    children: () =>
                        <div className={classes.modalWrapper}>
                            <div className={classes.modalTitle}>
                                Attach pollable attributes to this Device Type
                            </div>
                            <div className={classes.newConnectionContainer}>
                                {this.state.new_pollable_attribute.length === 0 ?
                                    <span>There are no pollable attributes available. Go to the Pollable Attribute page to create some.</span> :
                                    <LookupInput
                                        priorState={{ values: this.state.new_pollable_attribute.values, suggestions: this.state.new_pollable_attribute.pollable_attributes.map((m) => ({ label: m.name, value: m._id })) }}
                                        placeholder="Select Pollable Attributes*"
                                        label="Pollable Attributes"
                                        emitChange={update_new_pollable_attribute}
                                        error={this.state.show_errors && this.state.new_pollable_attribute.values.length === 0}
                                        error_message={"Please select at least one pollable attribute."}
                                    />}
                            </div>
                            {this.render_modal_buttons(this.submit_add_pollable_attribute)}
                        </div>
                }
            });
        }).catch((error) => {
            console.log(error)
            this.context.openSnackbar(error, "error");
        });
    }

    load_policy_suggestions = () => {
        return new Promise((resolve, reject) => {
            let body = { company_id: this.state.updated_type.company_id };
            if (Boolean(this.state.policies) && Array.isArray(this.state.policies) && this.state.policies.length > 0) {
                body._id_nin = this.state.policies.map(({ _id }) => _id).join(",");
            }
            GetAll("rules", body).then((rules) => {
                this.setState({ new_policy: { values: [], suggestions: rules } }, resolve);
            }).catch((error) => {
                reject();
            });
        });
    }

    load_ingestor_suggestions = () => {
        return new Promise((resolve, reject) => {
            let params = {};
            GET_RELATIONS("device_types", this.state.updated_type._id, "ingestors").then((ingestors) => {
                if (ingestors.length > 0) {
                    let ids = ingestors.map(function (ingestor) {
                        return ingestor._id
                    });
                    params._id_nin = ids.join(",");
                }
                GetAll("ingestors", params).then((ingestors) => {
                    this.setState({ new_ingestor: { values: [], suggestions: ingestors } }, resolve);
                }).catch((error) => {
                    reject();
                });
            }).catch((error) => {
                reject();
            });
        });
    }


    load_command_suggestions = () => {
        return new Promise((resolve, reject) => {
            let params = {};
            GET_RELATIONS("device_types", this.state.updated_type._id, "commands").then((commands) => {
                if (commands.length > 0) {
                    let ids = commands.map(function (command) {
                        return command._id
                    });
                    params._id_nin = ids.join(",");
                }
                GetAll("commands", params).then((commands) => {
                    this.setState({ new_command: { values: [], suggestions: commands } }, resolve);
                }).catch((error) => {
                    reject();
                });
            }).catch((error) => {
                reject();
            });
        });
    }

    load_pollable_attribute_suggestions = () => {
        return new Promise((resolve, reject) => {
            let params = {};
            GET_RELATIONS("device_types", this.state.updated_type._id, "pollable_attributes").then((pollable_attributes) => {
                if (pollable_attributes.length > 0) {
                    let ids = pollable_attributes.map(function (pa) {
                        return pa._id
                    });
                    params._id_nin = ids.join(",");
                }
                GetAll("pollable_attributes", params).then((pollable_attributes) => {
                    this.setState({ new_pollable_attribute: { pollable_attributes } }, resolve);
                }).catch((error) => {
                    console.log(error)
                    reject();
                });
            }).catch((error) => {
                console.log(error)
                reject();
            });
        });
    }

    submit_add_policy = () => {
        this.setState({ verifying: true });
        if (this.state.new_policy.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        ADD_RELATIONS("device_types", this.state.updated_type._id, "rules", this.state.new_policy.values.map(({ value }) => value)).then((result) => {
            this.close_modal();
            this.refresh();
            this.context.openSnackbar("Policies successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    submit_add_ingestor = () => {
        this.setState({ verifying: true });
        if (this.state.new_ingestor.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        ADD_RELATIONS("device_types", this.state.updated_type._id, "ingestors", this.state.new_ingestor.values.map(({ value }) => value)).then((result) => {
            this.close_modal();
            this.refresh();
            this.context.openSnackbar("Ingestor(s) successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    submit_add_command = () => {
        this.setState({ verifying: true });
        if (this.state.new_command.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        ADD_RELATIONS("device_types", this.state.updated_type._id, "commands", this.state.new_command.values.map(({ value }) => value)).then((result) => {
            this.close_modal();
            this.refresh();
            this.context.openSnackbar("Command(s) successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    submit_add_pollable_attribute = () => {
        this.setState({ verifying: true });
        if (this.state.new_pollable_attribute.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        ADD_RELATIONS("device_types", this.state.updated_type._id, "pollable_attributes", this.state.new_pollable_attribute.values.map(({ value }) => value)).then((result) => {
            this.close_modal();
            this.refresh();
            this.context.openSnackbar("Pollable Attribute successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    submit_delete_update = (id) => {
        this.setState({ verifying: true });
        new SoftwareUpdate({ _id: id }).deleteFromAPI().then((result) => {
            this.close_modal();
            this.refresh();
            this.context.openSnackbar("Update successfully deleted.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    submit_add_ingestor = () => {
        this.setState({ verifying: true });
        if (this.state.new_ingestor.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        let ingestor_ids = this.state.new_ingestor.values.map(({ value }) => value);
        ADD_RELATIONS("device_types", this.state.updated_type._id, "ingestors", ingestor_ids).then((result) => {
            this.close_modal();
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar("Ingestor(s) successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    submit_add_command = () => {
        this.setState({ verifying: true });
        if (this.state.new_command.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        let command_ids = this.state.new_command.values.map(({ value }) => value);
        ADD_RELATIONS("device_types", this.state.updated_type._id, "commands", command_ids).then((result) => {
            this.close_modal();
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar("Command(s) successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    render_policies = () => {
        const policies = this.state.policies;
        const { data: type, classes } = this.props;
        return (
            <div>
                <div className={classes.contentHeader + " " + classes.contentHeaderNoMargin}>
                    <div className={classes.contentTitle}>
                        Policies Attached to this Device Type
                    </div>
                    <div className={classes.addButtonContainer}>
                        <Button
                            onClick={this.add_policy}
                            variant="contained"
                            color="primary"
                            className={classes.buttonOverride}
                            disabled={!this.state.can_edit}
                        >
                            <AddIcon className={classes.buttonIcon} />
                            Attach Policy
                        </Button>
                    </div>
                </div>
                <TableList
                    headings={this.policy_headings}
                    items={policies}
                    noCheckBox
                    perPage={5}
                />
            </div>
        );
    }

    render_ingestors = () => {
        const { updated_type: type, ingestors } = this.state;
        const { classes } = this.props;
        let error = "";
        // if (Boolean(type.ingestor_ids) && Array.isArray(type.ingestor_ids) && type.ingestor_ids.length > 0) {
        // 	if (ingestors.length !== type.ingestor_ids.length) {
        // 		error = (
        // 			<div className={classes.warningContainer}>
        // 				<WarningIcon className={classes.warningIcon}/>
        // 				<span>One or more of the ingestors defined on this device type no longer exist or are not available to you.</span>
        // 			</div>
        // 		);
        // 	}
        // }
        return (
            <div>
                <div className={classes.contentHeader + " " + classes.contentHeaderNoMargin}>
                    <div className={classes.contentTitle}>
                        Ingestors Attached to this Device Type
                    </div>
                    <div className={classes.addButtonContainer}>
                        <Button
                            onClick={this.add_ingestor}
                            variant="contained"
                            color="primary"
                            className={classes.buttonOverride}
                            disabled={!this.state.can_edit}
                        >
                            <AddIcon className={classes.buttonIcon} />
                            Attach Ingestor
                        </Button>
                    </div>
                </div>
                <TableList
                    headings={this.ingestor_headings}
                    items={ingestors}
                    noCheckBox
                    perPage={5}
                />
                {error}
            </div>
        );
    }

    render_commands = () => {
        const { updated_type: type, commands } = this.state;
        const { classes } = this.props;
        let error = "";

        return (
            <div>
                <div className={classes.contentHeader + " " + classes.contentHeaderNoMargin}>
                    <div className={classes.contentTitle}>
                        Commands Attached to this Device Type
                    </div>
                    <div className={classes.addButtonContainer}>
                        <Button
                            onClick={this.add_command}
                            variant="contained"
                            color="primary"
                            className={classes.buttonOverride}
                            disabled={!this.state.can_edit}
                        >
                            <AddIcon className={classes.buttonIcon} />
                            Attach Command
                        </Button>
                    </div>
                </div>
                <TableList
                    headings={this.command_headings}
                    items={commands}
                    noCheckBox
                    perPage={5}
                />
                {error}
            </div>
        );
    }


    pollable_attributes_page_change = (event, page) => {
        this.setState((prev_state) => {
            return {
                pollable_attributes_page_data: {
                    page: page + 1,
                    per_page: prev_state.pollable_attributes_page_data.per_page,
                    total: prev_state.pollable_attributes_page_data.total,
                    page_meta: true
                }
            };
        }, () => {
            this.load_pollable_attributes(this.state.updated_type);
        });
    };

    render_pollable_attributes = () => {
        const { updated_type: type, pollable_attributes, pollable_attributes_page_data } = this.state;
        const { classes } = this.props;
        let error = "";
        return (
            <div>
                <div className={classes.contentHeader + " " + classes.contentHeaderNoMargin}>
                    <div className={classes.contentTitle}>
                        Pollable Attributes Attached to this Device Type
                    </div>
                    <div className={classes.addButtonContainer}>
                        <Button
                            onClick={this.add_pollable_attribute}
                            variant="contained"
                            color="primary"
                            className={classes.buttonOverride}
                            disabled={!this.state.can_edit}
                        >
                            <AddIcon className={classes.buttonIcon} />
                            Attach Pollable Attribute
                        </Button>
                    </div>
                </div>
                <TableList
                    headings={this.pollable_attribute_headings}
                    items={pollable_attributes}
                    noCheckBox
                />
                <PaginationContainer
                    count={pollable_attributes_page_data.total}
                    rowsPerPage={10}
                    currentPage={pollable_attributes_page_data.page}
                    onChangePage={this.pollable_attributes_page_change}
                    noPerPage
                    noShadow
                />
                {error}
            </div>
        );
    }

    render_overview = () => {
        const { updated_type: type } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Card className={classes.contentCard}>
                    <div className={classes.contentHeader}>
                        <div className={classes.contentTitle}>
                            Description
                        </div>
                        <div className={classes.addButtonContainer}>
                            <Button
                                onClick={this.open_edit_form}
                                variant="contained"
                                color="primary"
                                className={classes.buttonOverride}
                                disabled={!this.state.can_edit}
                            >
                                <BuildIcon className={classes.buttonIcon} />
                                Edit Description
                            </Button>
                        </div>
                    </div>
                    <div key={"device_overview_description"} className={classes.capabilityPair}>
                        <span className={classes.value}>{(type.long_description && type.long_description !== "") ? type.long_description : "No Description"}</span>
                    </div>
                </Card>
                <div className={classes.metadataEditor}>
                    <MetadataEditor model={type} can_edit={this.state.can_edit} submitMetadata={this.submit_edit_metadata} />
                </div>
            </React.Fragment>
        );
    }

    submit_edit_metadata = (new_device_type) => {
        new DeviceType(new_device_type).saveOrCreate().then((result) => {
            this.close_modal();
            this.context.openSnackbar("Metadata saved successfully.", "success");
            this.refresh();
            this.props.tabHostProxy.refresh();
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    render_software = () => {
        const { updated_type: type, software_updates } = this.state;
        const { classes } = this.props;
        let error = "";
        // if (Boolean(type.software_update_ids) && Array.isArray(type.software_update_ids) && type.software_update_ids.length > 0) {
        // 	if (software_updates.length !== type.software_update_ids.length) {
        // 		error = (
        // 			<div className={classes.warningContainer}>
        // 				<WarningIcon className={classes.warningIcon}/>
        // 				<span>One or more of the software updates defined on this device type no longer exist or are not available to you.</span>
        // 			</div>
        // 		);
        // 	}
        // }
        return (
            <div>
                <div className={classes.contentHeader + " " + classes.contentHeaderNoMargin}>
                    <div className={classes.contentTitle}>
                        Software Updates
                    </div>
                    <div className={classes.addButtonContainer}>
                        <Button
                            onClick={this.create_update}
                            variant="contained"
                            color="primary"
                            className={classes.buttonOverride}
                            disabled={!this.state.can_edit}
                        >
                            <AddIcon className={classes.buttonIcon} />
                            Add Software Update
                        </Button>
                    </div>
                </div>
                <TableList
                    headings={this.software_headings}
                    items={software_updates}
                    noCheckBox
                    perPage={5}
                />
                {error}
            </div>
        );
    }

    create_update = () => {
        const classes = this.props.classes;
        this.setState({
            modal: {
                open: true,
                children: () =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Creating a New Software Package
                        </div>
                        <CreateSoftwareUpdateForm
                            onCreate={this.on_update_create}
                            onCancel={this.close_modal}
                            package={{ company_id: this.state.updated_type.company_id, device_type_id: this.state.updated_type._id }}
                        />
                    </div>
            }
        });
    }

    on_update_create = () => {
        this.refresh();
        this.props.tabHostProxy.refresh();
        this.close_modal();
    }

    render_connections = () => {
        const { classes } = this.props;
        const { updated_type: type } = this.state;
        const connections = type.capabilities.network_connections;
        const no_connections = !Boolean(connections) || !Array.isArray(connections) || connections.length === 0;
        const connection_map = (type) => Device.connectionReadable(type);
        return (
            <React.Fragment>
                <Card className={classes.contentCard}>
                    <div className={classes.contentHeader}>
                        <div className={classes.contentTitle}>
                            Connections
                        </div>
                        <div className={classes.addButtonContainer}>
                            <Button
                                onClick={this.add_connection}
                                variant="contained"
                                color="primary"
                                className={classes.buttonOverride}
                                disabled={!this.state.can_edit}
                            >
                                <AddIcon className={classes.buttonIcon} />
                                Add Connection
                            </Button>
                        </div>
                    </div>
                    {no_connections ? <span className={classes.noConnection}>{"No Connections Defined."}</span> : connections.map(({ type, name }) => (
                        <div key={type + "_" + name} className={classes.connectionContainer}>
                            <div className={classes.capabilityPair}>
                                <span className={classes.capabilityLabel}>Connection Type: </span>
                                <span className={classes.value}>{connection_map(type)}</span>
                            </div>
                            <div className={classes.capabilityPair}>
                                <span className={classes.capabilityLabel}>Connection Name: </span>
                                <span className={classes.value}>{name}</span>
                            </div>
                            <div>
                                <Button
                                    onClick={() => this.remove_connection(type, name)}
                                    variant="outlined"
                                    color="primary"
                                    className={classes.buttonOverride + " " + classes.secondaryButton}
                                    disabled={!this.state.can_edit}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                    ))}
                </Card>
            </React.Fragment>
        );
    }

    add_connection = () => {
        const classes = this.props.classes;
        const update_new_connection = ({ field, value }) => {
            this.setState((state) => {
                state.new_connection[field] = value;
                return state;
            });
        }
        this.setState({
            new_connection: { type: "ethernet-wan", name: "" },
            modal: {
                open: true,
                children: () =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Add a Connection to this Device Type
                        </div>
                        <div className={classes.newConnectionContainer}>
                            <div className={classes.connectionWrapper}>
                                <SelectInput
                                    label="Connection Type*"
                                    emitChange={update_new_connection}
                                    priorState={this.state.new_connection.type}
                                    field="type"
                                    options={DeviceType.getConnectionTypes()}
                                />
                                <TextInput
                                    label="Connection Name*"
                                    emitChange={update_new_connection}
                                    priorState={this.state.new_connection.name}
                                    field="name"
                                    error={this.state.show_errors && this.state.new_connection.name === ""}
                                    error_message="Please set a name for this connection."
                                    helperText="Connection Name must be the name of the network interface in the device's system (e.g. eth0)"
                                />
                            </div>
                        </div>
                        {this.render_modal_buttons(this.submit_add_connection)}
                    </div>
            }
        });
    }

    submit_add_connection = () => {
        this.setState({ verifying: true });
        if (this.state.new_connection.name === "") {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        const device_type = this.state.updated_type;
        let new_device_type = { _id: device_type._id, capabilities: JSON.parse(JSON.stringify(device_type.capabilities)) };
        if (new_device_type.capabilities.network_connections == null) {
            new_device_type.capabilities.network_connections = [];
        }
        new_device_type.capabilities.network_connections.push(this.state.new_connection);
        new DeviceType(new_device_type).saveOrCreate().then((result) => {
            this.close_modal();
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar("Connection successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    remove_connection = (type, name) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to remove this connection? Type: ${Device.connectionReadable(type)} Name: ${name} `,
                yesFunction: () => this.submit_remove_connection(type, name),
                functionText: "Remove",
                children: () => { }
            }
        });
    }

    remove_policy = (id) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to detach this policy from the device type? It will not delete the policy.`,
                yesFunction: () => this.submit_remove_policy(id),
                functionText: "Remove",
                children: () => { }
            }
        });
    }

    go_to_policy = (id) => {

    }

    remove_ingestor = (id) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to detach this ingestor from the device type? It will not delete the ingestor.`,
                yesFunction: () => this.submit_remove_ingestor(id),
                functionText: "Remove",
                children: () => { }
            }
        });
    }


    remove_command = (id) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to detach this command from the device type? It will not delete the command.`,
                yesFunction: () => this.submit_remove_command(id),
                functionText: "Remove",
                children: () => { }
            }
        });
    }

    remove_pollable_attribute = (id) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to detach this pollable attribute from the device type? It will not delete the pollable attribute.`,
                yesFunction: () => this.submit_remove_pollable_attribute(id),
                functionText: "Remove",
                children: () => { }
            }
        });
    }

    submit_remove_policy = (_id) => {
        this.setState({ verifying: true });
        REMOVE_RELATION("device_types", this.state.updated_type._id, "rules", _id).then((result) => {
            this.context.openSnackbar("Policy successfully removed from the device type.", "success");
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        })
    }

    submit_remove_ingestor = (_id) => {
        this.setState({ verifying: true });
        REMOVE_RELATION("device_types", this.state.updated_type._id, "ingestors", _id).then((result) => {
            this.context.openSnackbar("Ingestor successfully removed from the device type.", "success");
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        })
    }

    submit_remove_command = (_id) => {
        this.setState({ verifying: true });
        REMOVE_RELATION("device_types", this.state.updated_type._id, "commands", _id).then((result) => {
            this.context.openSnackbar("Command successfully removed from the device type.", "success");
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        })
    }

    submit_remove_pollable_attribute = (_id) => {
        this.setState({ verifying: true });
        REMOVE_RELATION("device_types", this.state.updated_type._id, "pollable_attributes", _id).then((result) => {
            this.context.openSnackbar("Pollable Attribute successfully removed from the device type.", "success");
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
            this.close_modal();
        })
    }

    submit_remove_connection = (rem_type, rem_name) => {
        this.setState({ verifying: true });
        const device_type = this.state.updated_type;
        let new_device_type = { _id: device_type._id, capabilities: JSON.parse(JSON.stringify(device_type.capabilities)) };
        let rem_index = device_type.capabilities.network_connections.findIndex(({ type, name }) => type === rem_type && name === rem_name);
        new_device_type.capabilities.network_connections.splice(rem_index, 1);
        new DeviceType(new_device_type).saveOrCreate().then((result) => {
            this.close_modal();
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar("Connection successfully removed.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    range_display = () => {
        const count = Object.entries(this.state.updated_type.capabilities.actions).length;
        const from = this.state.range + 1;
        const to = from + 9 > count ? count : from + 9;
        return `${from}-${to} of ${count}`;
    }

    nav_disabled = (change) => {
        const count = Object.entries(this.state.updated_type.capabilities.actions).length;
        const next = this.state.range + change;
        if (next < 0 || next > count) {
            return true;
        } else {
            return false;
        }
    }

    page_change_actions = (change) => {
        const next = this.state.range + change;
        this.setState({ range: next });
    }

    manage_abilities = () => {
        const classes = this.props.classes;
        const label = (key) => Device.commandReadable(key);
        this.setState((state) => {
            const actions = JSON.parse(JSON.stringify(state.updated_type.capabilities.actions));
            state.editable_copy.actions = actions;
            state.modal = {
                open: true,
                children: (classes) =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Manage this Device Type's Abilities
                        </div>
                        <div className={classes.modalContainer}>
                            <div className={classes.switchItems}>
                                {Object.entries(actions).slice(this.state.range, this.state.range + 11).map(([key, value], index) => (
                                    <div key={key} className={classes.switchWrapper}>
                                        <SwitchInput
                                            initial={value}
                                            emitChange={() => this.toggle(key)}
                                            color={this.props.theme.palette.pending.main}
                                            onLabel={label(key)}
                                            offLabel={label(key)}
                                            field={key}
                                            location="start"
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className={classes.switchNavContainer}>
                                {this.range_display()}
                                <IconButton
                                    key="before"
                                    aria-label="before"
                                    onClick={() => this.page_change_actions(-10)}
                                    className={classes.iconButtonNav}
                                    disabled={this.nav_disabled(-10)}
                                >
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                    key="back"
                                    aria-label="next"
                                    onClick={() => this.page_change_actions(10)}
                                    className={classes.iconButtonNav}
                                    disabled={this.nav_disabled(10)}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </div>
                        </div>
                        {this.render_modal_buttons(this.save_actions)}
                    </div>
            };
            return state;
        });
    }

    toggle = (field) => {
        this.setState((state) => {
            state.editable_copy.actions[field] = !state.editable_copy.actions[field];
            return state;
        });
    }

    save_actions = () => {
        this.setState({ verifying: true });
        let saved_device_type = { _id: this.state.updated_type._id, capabilities: this.state.updated_type.capabilities };
        saved_device_type.capabilities.actions = this.state.editable_copy.actions;
        new DeviceType(saved_device_type).saveOrCreate().then((result) => {
            this.context.openSnackbar("Device Type abilities saved successfully.", "success");
            this.refresh();
            this.props.tabHostProxy.refresh();
            this.close_modal();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    render_modal_buttons = (onClick) => {
        const classes = this.props.classes;
        return (
            <div className={classes.modalButtonContainer}>
                <Button
                    color="primary"
                    className={classes.buttonOverride}
                    onClick={this.close_modal}
                    aria-label="cancel"
                >
                    Cancel
                </Button>
                <Button
                    onClick={onClick}
                    disabled={this.state.verifying}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.buttonOverride}
                    aria-label="create"
                >
                    Save Changes
                </Button>
            </div>
        )
    }

    render_abilities = () => {
        const { classes } = this.props;
        const { updated_type: type } = this.state;
        const software_supported = type.capabilities.firmware.upgrade ? "Supported" : "Not Supported";
        const action_map = (key) => Device.commandReadable(key);
        return (
            <Card className={classes.contentCard}>
                <div className={classes.contentHeader}>
                    <div className={classes.contentTitle}>
                        Abilities
                    </div>
                    <div className={classes.addButtonContainer}>
                        <Button
                            onClick={this.manage_abilities}
                            variant="contained"
                            color="primary"
                            className={classes.buttonOverride}
                            disabled={!this.state.can_edit}
                        >
                            <BuildIcon className={classes.buttonIcon} />
                            Manage Abilities
                        </Button>
                    </div>
                </div>
                {Object.entries(type.capabilities.actions).map(([key, value]) => (
                    <div key={key} className={classes.capabilityPair}>
                        <span className={classes.capabilityLabel}>{action_map(key)}:</span>
                        <span className={classes.value}>{value ? "Supported" : "Not Supported"}</span>
                    </div>
                ))}
            </Card>
        );
    }

    render_detail_area = () => {
        const { classes } = this.props;
        const { image, updated_type: type } = this.state;
        let type_icon = null;
        let type_text = null;
        if (type.type === "gateway") {
            type_icon = <RouterIcon className={classes.infoIcon} />;
            type_text = "Gateway Device Type";
        } else {
            type_icon = <SettingsRemoteIcon className={classes.infoIcon} />;
            type_text = Humanize.humanizeSnakeCaseString(type.type) + " Device Type";
        }
        let role_icon = null;
        if (type.role === "gateway") {
            role_icon = <RouterIcon className={classes.infoIcon} />;
        } else {
            role_icon = <SettingsRemoteIcon className={classes.infoIcon} />;
        }
        let role_text = Humanize.humanizeSnakeCaseString(type.role);
        const software_supported = type.capabilities.firmware.upgrade ? "Firmware Upgrade Supported" : "Firmware Upgrades Not Supported";
        const iptables_supported = type.capabilities.iptables ? "IP Tables Supported" : "IP Tables Not Supported";
        const account_text = type.nested_company ? type.nested_company.name : "Inherited";
        return (
            <div className={classes.detailArea}>
                <div className={classes.imageContainer}>
                    {image}
                </div>
                <div className={classes.detailContainer}>
                    <div className={classes.nameContainer}>
                        <div className={classes.name}>
                            {type.name}
                        </div>
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.detailAreaColumn}>
                            <div className={classes.makeModel}>
                                ID: <span className={classes.value}>{type._id}</span>
                            </div>
                            <div className={classes.makeModel}>
                                Manufacturer: <span className={classes.value}>{type.manufacturer}</span>
                            </div>
                            <div className={classes.makeModel}>
                                Model: <span className={classes.value}>{type.model}</span>
                            </div>
                            <div className={classes.makeModel}>
                                Account: <span className={classes.value}>{account_text}</span>
                            </div>
                        </div>
                        <div className={classes.detailAreaColumn}>
                            <div className={classes.iconLabelPair}>
                                <CodeIcon className={classes.infoIcon} />
                                <span className={classes.detailValue}>{software_supported}</span>
                            </div>
                            <div className={classes.iconLabelPair}>
                                <NotesIcon className={classes.infoIcon} />
                                <span className={classes.detailValue}>{iptables_supported}</span>
                            </div>
                            <div className={classes.iconLabelPair}>
																<Tooltip title="Type">
																	{type_icon}
																</Tooltip>
                                <span className={classes.detailValue}>{type_text}</span>
                            </div>
                            <div className={classes.iconLabelPair}>
																<Tooltip title="Role">
																	{role_icon}
																</Tooltip>
                                <span className={classes.detailValue}>{role_text}</span>
														</div>
                        </div>
                        {this.render_buttons()}
                    </div>
                </div>
            </div>
        );
    }

    render_buttons = () => {
        const { classes } = this.props;
        return (
            <div className={classes.buttonContainer}>
                <Button
                    onClick={this.open_edit_form}
                    variant="contained"
                    color="primary"
                    className={classes.buttonOverride}
                    disabled={!this.state.can_edit}
                >
                    <EditIcon />
                </Button>
                <Button
                    onClick={this.delete_device_type}
                    variant="contained"
                    color="primary"
                    className={classes.buttonOverride}
                    disabled={!this.state.can_delete}
                >
                    <DeleteIcon />
                </Button>
            </div>
        );
    }

    open_edit_form = () => {
        const classes = this.props.classes;
        this.setState({
            modal: {
                open: true,
                children: () =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Editing this Device Type
                        </div>
                        <DeviceTypeForm
                            onCreate={this.on_update_create}
                            onCancel={this.close_modal}
                            type={this.state.updated_type}
                        />
                    </div>
            }
        });
    }

    delete_device_type = () => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to delete this device type?`,
                yesFunction: () => this.submit_delete_type(),
                functionText: "Delete",
                children: () => { }
            }
        });
    }

    submit_delete_type = () => {
        this.setState({ verifying: true });
        new DeviceType({ _id: this.state.updated_type._id }).deleteFromAPI().then((result) => {
            this.context.openSnackbar("Device type successfully deleted.", "success");
            this.props.tabHostProxy.refresh();
            this.close_modal();
            this.props.tabHostProxy.closeSelf();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    render_sub_detail_area = () => {
        const { classes } = this.props;
        const { subnav, ingestors, policies, software_updates, tabs } = this.state;
        const loading = (ingestors === null || policies === null || software_updates === null || tabs === null) ? true : false;
        return (
            <div className={classes.subdetailArea}>
                <div className={classes.verticalNavContainer}>
                    <VerticalTabs tabs={this.state.tabs} onChange={this.subnav_change} />
                </div>
                <div className={classes.content}>
                    {loading ? <Loading /> : this.state.tabs[subnav].render()}
                </div>
            </div>
        );
    }

    subnav_change = (newTab) => {
        this.setState((state) => {
            const new_subnav = this.state.tabs.findIndex(({ label }) => label === newTab.label);
            state.subnav = new_subnav;
            return state;
        });
    }

    close_modal = () => {
        this.setState({ show_errors: false, verifying: false, modal: { open: false, children: () => "" } });
    }

    render() {
        const { classes } = this.props;
        if (this.state.ensuring) return <Loading />;
        return (
            <div className={classes.topContainer}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                <div className={classes.container}>
                    {this.render_detail_area()}
                    {this.render_sub_detail_area()}
                </div>
            </div>
        );
    }
}

TypeTab.contextType = SnackbarContext;

const styles = (theme) => {
    return ({
        topContainer: {
            display: "flex",
            width: "100%"
        },
        container: {
            fontFamily: "Inter",
            flexGrow: 2,
            display: "flex",
            flexWrap: "wrap",
            overflow: "hidden",
        },
        detailArea: {
            display: "flex",
            height: "190px",
            width: "100%",
            padding: "12px",
            boxSizing: "border-box"
        },
        nameContainer: {
            width: "100%",
        },
        infoContainer: {
            width: "100%",
            display: "flex",
        },
        detailAreaColumn: {
            width: "40%"
        },
        subdetailArea: {
            height: "calc(100% - 180px)",
            width: "100%",
            display: "flex",
        },
        name: {
            fontSize: "24px",
            marginBottom: "12px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        },
        makeModel: {
            margin: "8px 0"
        },
        unset: {
            fontStyle: "italic"
        },
        buttonOverride: {
            marginLeft: "8px",
        },
        editButton: {
            backgroundColor: theme.palette.green.main,
            '&:hover': {
                backgroundColor: darken(theme.palette.green.main, .2),
            },
        },
        deleteButton: {
            backgroundColor: theme.palette.red.main,
            '&:hover': {
                backgroundColor: darken(theme.palette.red.main, .2),
            },
        },
        addButton: {
            backgroundColor: theme.palette.pending.main,
            '&:hover': {
                backgroundColor: darken(theme.palette.pending.main, .2),
            },
            width: "25%"
        },
        titleButton: {
            backgroundColor: theme.palette.pending.main,
            '&:hover': {
                backgroundColor: darken(theme.palette.pending.main, .2),
            },
        },
        buttonContainer: {
            width: "20%",
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            paddingLeft: "12px",
            justifyContent: "center",
        },
        verticalNavContainer: {
            display: "flex",
            width: "15%",
            boxSizing: "border-box",
            marginTop: "-1px",
            backgroundColor: "white",
            zIndex: 5,
            borderRight: "solid lightgrey 1px",
        },
        content: {
            overflowY: "auto",
            padding: "12px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "85%",
            backgroundColor: "#f5f5f7",
            boxSizing: "border-box",
            borderTop: "solid lightgrey 1px",
            marginTop: "-1px"
        },
        imagePlaceholder: {
            height: "140px",
            width: "140px",
            color: "grey",
            border: "solid lightgrey 2px",
            borderRadius: "4px",
        },
        image: {
            maxHeight: "140px",
            maxWidth: "140px",
            border: "solid lightgrey 2px",
            borderRadius: "4px",
        },
        imageContainer: {
            display: "flex",
            alignItems: "center",
            marginRight: "12px"
        },
        detailContainer: {
            width: "100%",
            overflow: "hidden"
        },
        buttonIcon: {
            marginRight: "8px"
        },
        iconLabelPair: {
            display: "flex",
            alignItems: "center",
            margin: "8px 0",
            color: "#8e8e93"
        },
        value: {
            color: "#8e8e93",
            fontSize: "14px",
        },
        infoIcon: {
            marginRight: "8px"
        },
        detailValue: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "14px",
        },
        notFound: {
            color: theme.palette.red.main
        },
        unset: {
            fontStyle: "italic",
            paddingRight: "2px"
        },
        tableMenu: {
            color: "grey",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.pending.main
            }
        },
        addButtonContainer: {
            padding: "12px 0",
            textAlign: "right"
        },
        contentCard: {
            padding: "12px",
            marginBottom: "12px"
        },
        capabilityLabel: {
            marginRight: "8px"
        },
        capabilityPair: {
            margin: "12px"
        },
        first: {
            borderTop: "solid lightgrey 2px"
        },
        connectionContainer: {
            borderBottom: "solid lightgrey 1px"
        },
        contentTitle: {
            fontSize: "16px"
        },
        contentHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "-12px -12px 12px -12px",
            padding: "0 12px",
            borderBottom: "solid lightgrey 1px",
        },
        contentHeaderNoMargin: {
            margin: 0,
            border: "none"
        },
        warningIcon: {
            color: theme.palette.caution.main,
            marginRight: "8px"
        },
        warningContainer: {
            borderBottom: "solid black 2px",
            padding: "12px",
            margin: "12px",
            display: "flex",
            alignItems: "center"
        },
        modalWrapper: {
            fontFamily: "Inter",
            boxSizing: "border-box",
            width: "100%",
            minHeight: "450px",
            maxHeight: "643px",
        },
        modalContainer: {
            width: "100%",
            height: "505px",
            overflowY: "auto",
        },
        modalTitle: {
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "32px",
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "32px",
        },
        switchWrapper: {
            width: "50%",
            margin: "auto"
        },
        modalButtonContainer: {
            display: "flex",
            justifyContent: "flex-end",
            width: "50%",
            margin: "32px 0 0 auto"
        },
        cancelButton: {
            color: "grey"
        },
        switchNavContainer: {
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "25%",
            fontSize: "14px",
            color: "#636366",
        },
        iconButtonNav: {
            padding: "4px",
            marginLeft: "8px",
        },
        switchItems: {
            height: "calc(80vh - 250px)",
            overflow: "auto"
        },
        secondaryButton: {
            margin: "0 12px 12px 12px",
            width: "15%",
        },
        newConnectionContainer: {
            height: "312px",
        },
        connectionWrapper: {
            marginTop: "52px",
        },
        deleteIconButton: {
            padding: "4px",
            "&:hover": {
                color: "#0263fc",
            }
        },
        keyPairDelete: {
            marginTop: "-24px"
        },
        keyPairContainer: {
            display: "flex",
            borderBottom: "solid lightgrey 2px",
            paddingTop: "6px",
            marginBottom: "18px",
            alignItems: "center"
        },
        keyPairItem: {
            width: "40%",
            marginRight: "8px"
        },
        errorText: {
            color: theme.palette.red.main
        },
        disabledTab: {
            color: "red"
        },
        metadataEditor: {
            width: "98%"
        },
        noConnection: {
            fontSize: "14px",
        }
    });
};

export default compose(
    withStyles(styles),
    withTheme(),
)(TypeTab);
