import React from 'react';
import Loading from '../DisplayOriented/Loading';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import SwitchInput from "../Inputs/SwitchInput";

//icons
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import CodeIcon from '@material-ui/icons/Code';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import SoftwareUpdate from '../../services/DataModels/SoftwareUpdate';
import Company from '../../services/DataModels/Company';
import Auth from '../../services/Auth.js';
import Permissions from '../../services/Permissions';

const styles = (theme) => {
	return ({	
		container: {
			fontFamily: "Inter"
		},
		formContainer: {
			minHeight: "300px",
			maxHeight: "500px",
			overflowY: "auto",
			paddingTop: "5px",
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "19px",
		},
		fabIcon: {
			fontSize: "22px",
			marginRight: "4px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: "#8e8e93",
		},
		powerIcon: {
			margin: "0 12px 0 0"
		},
		helpLinkContainer: {
			display: "flex",
			position: "relative",
			zIndex: 5,
			marginTop: "-18px",
			justifyContent: "flex-end",
		},
		buttonOverride: {
			marginLeft: "8px"
		},
		buttonContainer: {
			marginTop: "32px",
			display: "flex",
			justifyContent: "flex-end"
		},
		switchInputWrapper: {
			margin: "-10px 0 24px 0px",
			display: "flex",
			alignItems: "center"
		},
		switchWrapper: {
			marginTop: "-2px"
		},
		switchContainer: {
			marginBottom: "6px",
			marginTop: 0,
		},
		fileSelectContainer: {
		},
		fileSelectLabel: {
			fontSize: "18px",
			fontFamily: "Inter",
			color: "black",
			display: "flex",
			alignItems: "center",
			borderBottom: "solid rgba(0, 0, 0, 0.24) 1px",
		},
		uploadButton: {
			padding: 0,
		},
		label: {
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
		},
		fileSelectInput: {
			width: "0.1px",
			height: "0.1px",
			opacity: 0,
			overflow: "hidden",
			position: "absolute",
			zIndex: -1,
		},
		fileName: {
			fontSize: "16px",
			fontFamily: "Inter",
			color: "rgba(0, 0, 0, 0.87)",
			marginLeft: "12px",
			marginRight: "auto",
		},
		fileError: {
			border: "solid red 1px",
			borderRadius: "4px",
			padding: "6px"
		},
		filesContainer: {
			marginTop: "24px"
		},
		filesTitle: {
			fontSize: "16px",
			fontFamily: "Inter",
			color: "#8e8e93",
			textTransform: "uppercase",
		},
		file: {
			fontSize: "14px",
			fontFamily: "Inter",
			color: "#8e8e93",
		},
		struck: {
			textDecoration: "line-through",
			fontSize: "14px",
			fontFamily: "Inter",
			color: "#8e8e93",
		},
		deleteFile: {
			color: "#8e8e93",
			cursor: "pointer",
			"&:hover": {
				color: "#0263fc",
			}
		},
		fileRow: {
			display: "flex",
			flexWrap: "nowrap",
			justifyContent: "space-between",
			alignItems: "center",
			height: "36px"
		},
		iconButtonOverride: {
			padding: "8px",
			marginRight: "8px",
		},
		undoButton: {
			margin: 0,
		}
	})
};

class CreateSoftwareUpdateForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.domRef = React.createRef();
		this.state = {
			filename: "",
			software_update_type_options : [
				{
					display: "Bash Script",
					value: "bash_script"
				},
				{
					display: "LWM2M Software Update",
					value: "lwm2m_object_9"
				},
				{
					display: "LWM2M Firmware Update",
					value: "lwm2m_object_5"
				}
			],
			editableCopy: {
				company_id: Auth.currentCompany()._id,
				name: '',
				type: 'bash_script',
				url: '',
				script: '',
				reboot: false,
				filename: '',
				device_type_id: ''
			},
			show_errors: false,
			editing: false,
			type_options: null,
			account_options: null,
			verifying: false,
			submitting: false
		};
		if (this.props.package) {
			if (this.props.package._id) {
				this.state.editing = true;
			}
			this.state.editableCopy = {
				company_id: this.props.package.company_id || Auth.currentCompany()._id,
				type: this.props.package.type || "bash_script",
				url: this.props.package.url || "",
				name: this.props.package.name || "",
				script: this.props.package.script || "",
				reboot: this.props.package.reboot === undefined ? false : this.props.package.reboot,
				filename: '',
				files: this.props.package.files ? this.props.package.files.map( (file) => {
					file.removed = false;
					return file;
				}) : [],
				device_type_id: this.props.package.device_type_id || ''
			};
		}
		this.set_account_setting();
		this.set_help();
		this.load_type_options();
	}

	type_is_lwm2m = (type) => {
		return ['lwm2m_object_9', 'lwm2m_object_5'].includes(type);
	}

	set_account_setting = () => {
		this.show_account_selection = false;
		let sub_accounts = Auth.currentUser().company_ids;
		if (sub_accounts.length > 1) {
			let valid_accounts = sub_accounts.filter( (account) => Permissions.allow(["create"], "software_update", account._id));
			if (valid_accounts.length > 1) {
				this.show_account_selection = true;
				this.load_account_options(valid_accounts);
			}
		} 
	}

	set_help = () => {
		this.script_help = {
			title: "Software Package Executable Script",
			content: `The script is an executable command that must be a syntactically valid, one-line command, e.g. 'apt-get update; apt install vim'. The command may alternatively execute an uploaded script. For Linux systems, the file name must be prefixed with './', e.g. './install.sh -f'`,
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
		this.type_help = {
			title: "Software Package Device Type",
			content: `A software package is designed for a certain type of device. Please select one of the device types from the dropdown. If you don't see your type of device in the dropdown, it may not support software updates.`,
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
	}

	load_account_options = (account_ids) => {
		let params = {_id_in: account_ids };
		GetAll("companies", params).then( (result) => {
			let accounts = result.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
			this.setState({account_options: accounts.map( (account) => ({display: account.name, value: account._id}) )});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_type_options = () => {
		GetAll("device_types").then( (types) => {
			types = types.filter( (type) => {
				return type.capabilities.actions.software_update;
			});
			types.push({name: "Select a Device Type*", _id: ''})
			this.setState({type_options: types.map( (type) => ({display: type.name, value: type._id}) )});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	onFormUpdate = (input) => {
		let newEditableCopy = this.state.editableCopy;
		newEditableCopy[input.field] = input.value;
		this.setState({editableCopy: newEditableCopy});
	}

	onSubmit = (event) => {
		this.setState({verifying: true});
		let pkg = this.state.editableCopy;
		let is_lwm2m = this.type_is_lwm2m(pkg.type);
		event.preventDefault();
		if (pkg.name === '' || (!is_lwm2m && pkg.script === "") || (is_lwm2m && pkg.url === "") || pkg.device_type_id === '' || (this.show_account_selection && pkg.company_id == '')) {
			this.setState({show_errors: true, verifying: false});
			return;
		}
		this.setState({submitting: true});
		if (this.state.editing && pkg.files && pkg.files.length > 0 && pkg.files.find( (file) => file.removed)) {
			pkg.files = pkg.files.filter( (file) => !file.removed);
		}
		let files = [];
		if (!is_lwm2m) {
			files = this.domRef.current.files;
			delete pkg.filename;
		}
		this.create(pkg, files);
	}

	create = (pkg, files) => {
		let fileArray = [];
		for (var i = 0; i < files.length; i++) {
			fileArray.push(files[i]);
		}
		if (this.state.editing) {
			pkg._id = this.props.package._id;
		}
		console.log(pkg);
		new SoftwareUpdate(pkg).setData(pkg).createAndUploadFiles(fileArray).then((result) => {
			let message = this.state.editing ? "Package saved." : "Package created.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate();
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	handleFileChange = (event) => {
		let id = function(){
			let ids = [];
			if (this.target.files && this.target.files.length > 1) {
				Object.entries(this.target.files).forEach( (entry) => {
					if (entry[1] && entry[1].name) {
						ids.push(entry[1].name);
					}
				});
				return ids;
			}
			return this.target.value;
		}.bind(event)();
		let filename = "";
		if (!Array.isArray(id)) {
			filename = id.split("\\")[id.split("\\").length - 1];
		} else {
			filename = id.reduce( (total, current) => {
				if (total === "") {
					return current;
				} else {
					return total + ", " + current;
				}
			}, "");
		}
		this.setState( (prev_state) => {
			prev_state.editableCopy.filename = filename;
			return prev_state;
		});
	}

	validationMessagesFor(field) {
		let messages = this.validation[field];
		if (messages != null && messages.length > 0) {
			return messages[0];
		}
	}

	renderFiles = () => {
		const classes = this.props.classes;
		if (!this.state.editing || this.state.editableCopy.files.length === 0) return "";
		return (
			<div className={classes.filesContainer}>
				<div className={classes.filesTitle}>
					Files
				</div>
				{this.state.editableCopy.files.map( (file, index) => (
					<div className={classes.fileRow} key={"file" + index}>
						<span className={file.removed ? classes.struck : classes.file}>
							{file.name}
						</span>
						{file.removed ? 
							<Button
								color="primary"
								onClick={() => this.strikeFile(index, false)}
								className={classes.undoButton}
							>
								Undo
							</Button> 
							:
							<IconButton onClick={() => this.strikeFile(index, true)} classes={{root: classes.iconButtonOverride}}>
								<DeleteIcon className={classes.deleteFile} />
							</IconButton>
						}
					</div>
				))}
			</div>
		);
	}

	strikeFile = (index, strike) => {
		this.setState( (state) => {
			let pkg = this.state.editableCopy;
			pkg.files[index].removed = strike;
			state.editableCopy = pkg;
			return state;
		});
	}

	render_account_input = () => {
		const { classes, theme } = this.props;
		const { editableCopy, show_errors, account_options } = this.state;
		if (!this.show_account_selection) return "";
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon}/>
				<SelectInput
					error={show_errors && editableCopy.company_id === ""}
					error_message="Please select an account."
					label="Account"
					field="company_id"
					emitChange={this.onFormUpdate}
					priorState={this.state.editableCopy.company_id}
					options={account_options}
				/>
			</div>
		);
	}

	render_name_input = () => {
		const { classes } = this.props;
		const { editableCopy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<DescriptionIcon className={classes.inputIcon}/>
				<TextInput
					id="name"
					label="Name*"
					emitChange={this.onFormUpdate}
					priorState={editableCopy.name}
					field="name"
					error={show_errors && editableCopy.name === ""}
					error_message={this.validationMessagesFor('name')}
				/>
			</div>
		);
	}

	render_type_input = () => {
		const { classes, theme } = this.props;
		const { editableCopy, show_errors, software_update_type_options } = this.state;
		return (
			<React.Fragment>
				<div className={classes.textInputWrapper}>
					<DvrIcon className={classes.inputIcon}/>
					<SelectInput
						error={show_errors && editableCopy.device_type_id === ""}
						error_message="Please select a software update type."
						label="Software Update Type"
						field="type"
						emitChange={this.onFormUpdate}
						priorState={this.state.editableCopy.type}
						options={software_update_type_options}
					/>
				</div>
				<div className={classes.helpLinkContainer}>
					<Button color="primary" onClick={() => this.context.openHelp(this.type_help)} aria-label="Software Update Type Information">
						Software Update Type
					</Button>
				</div>
			</React.Fragment>
		);
	}

	render_url_input = () => {
		const { classes } = this.props;
		const { editableCopy, show_errors } = this.state;
		let lwm2m_type = this.type_is_lwm2m(editableCopy.type);
		return (
			!lwm2m_type &&
			<React.Fragment>
				<div className={classes.textInputWrapper}>
					<CodeIcon className={classes.inputIcon}/>
					<TextInput
						id="script"
						label="Executable Script*"
						emitChange={this.onFormUpdate}
						priorState={editableCopy.script}
						field="script"
						error={show_errors && editableCopy.script === ""}
						error_message={this.validationMessagesFor('script')}
					/>
				</div>
				<div className={classes.helpLinkContainer}>
					<Button color="primary" onClick={() => this.context.openHelp(this.script_help)} aria-label="Script Instructions and Information">
						Script Instructions and Information
					</Button>
				</div>
			</React.Fragment>
		);
	}

	render_script_input = () => {
		const { classes } = this.props;
		const { editableCopy, show_errors } = this.state;
		let lwm2m_type = this.type_is_lwm2m(editableCopy.type);
		return (
			lwm2m_type &&
			<React.Fragment>
				<div className={classes.textInputWrapper}>
					<CodeIcon className={classes.inputIcon}/>
					<TextInput
						id="url"
						label="Package URL"
						emitChange={this.onFormUpdate}
						priorState={editableCopy.url}
						field="url"
						error={show_errors && editableCopy.url === ""}
						error_message={this.validationMessagesFor('url')}
					/>
				</div>
			</React.Fragment>
		);
	}

	render_reboot_input = () => {
		const { classes, theme } = this.props;
		const { editableCopy } = this.state;
		let lwm2m_type = this.type_is_lwm2m(editableCopy.type);
		return (
			!lwm2m_type &&
			<div className={classes.switchInputWrapper}>
				<PowerSettingsNewIcon className={classes.inputIcon + " " + classes.powerIcon}/>
				<div className={classes.switchWrapper}>
					<SwitchInput
						containerClass={classes.switchContainer}
						initial={editableCopy.reboot}
						color={theme.palette.pending.main}
						emitChange={this.onFormUpdate}
						onLabel="Reboot Device After Installation"
						offLabel="Reboot Device After Installation"
						field="reboot"
						location="start"
					/>
				</div>
			</div>
		);
	}

	render_device_type_input = () => {
		const { classes, theme } = this.props;
		const { editableCopy, show_errors, type_options } = this.state;
		return (
			<React.Fragment>
				<div className={classes.textInputWrapper}>
					<DvrIcon className={classes.inputIcon}/>
					<SelectInput
						error={show_errors && editableCopy.device_type_id === ""}
						error_message="Please select a device type."
						label="Device Type"
						field="device_type_id"
						emitChange={this.onFormUpdate}
						priorState={this.state.editableCopy.device_type_id}
						options={type_options}
					/>
				</div>
				<div className={classes.helpLinkContainer}>
					<Button color="primary" onClick={() => this.context.openHelp(this.type_help)} aria-label="Device Type Information">
						Device Type Information
					</Button>
				</div>
			</React.Fragment>
		);
	}

	render_file_upload = () => {
		const {classes} = this.props;
		const {editableCopy} = this.state;
		let lwm2m_type = this.type_is_lwm2m(editableCopy.type);
		return (
			!lwm2m_type &&
			<div className={classes.fileSelectContainer}>
				<div className={classes.fileSelectInput}>
					<input
						id="files"
						type="file"
						multiple
						name="upload_file"
						label="Software Update Files"
						ref={this.domRef}
						onChange={this.handleFileChange}
					/>
				</div>
				<div className={classes.fileSelectLabel}>
					<Button color="primary" aria-label="Upload File">
						<label className={classes.label} htmlFor="files">
							<AttachmentIcon className={classes.fabIcon} />
							UPLOAD FILES
						</label>
					</Button>
					<div className={classes.fileName}>
						{this.state.editableCopy.filename}
					</div>
				</div>
				{this.renderFiles()}
			</div>
		);
	}

	render() {
		const { classes, theme } = this.props;
		const { editableCopy, show_errors, type_options, account_options, submitting } = this.state;
		this.validation = SoftwareUpdate.validate(editableCopy);
		return(
			<div className={classes.container}>
				{type_options == null || submitting || (this.show_account_selection && account_options == null) ? <Loading />
				:
				<form onSubmit={this.onSubmit}>
					<div className={classes.formContainer}>
						{this.render_account_input()}
						{this.render_name_input()}
						{this.render_type_input()}
						{this.render_script_input()}
						{this.render_url_input()}
						{this.render_reboot_input()}
						{this.render_device_type_input()}
						{this.render_file_upload()}
					</div>
					<div className={classes.buttonContainer}>
						<Button
							onClick={this.props.onCancel}
							className={classes.buttonOverride}
							aria-label="cancel"
							color="primary"
						>
							CANCEL
						</Button>
						<Button
							disabled={this.state.verifying}
							className={classes.buttonOverride}
							aria-label="create"
							variant="contained"
							color="primary"
							size="large"
							type="submit"
						>
							{this.state.editing ? "EDIT" : "CREATE" } PACKAGE
						</Button>
					</div>
				</form>}
			</div>
		)
	}
}

CreateSoftwareUpdateForm.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(CreateSoftwareUpdateForm));

