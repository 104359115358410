import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import GatewayCommand from '../../services/DataModels/GatewayCommand';
import EnhancedTable from '../Table/EnhancedTable';
import { AssignNestedModels, GetAll } from '../../services/CLURDUtilities';
import Paper from '@material-ui/core/Paper';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import TableList from '../Table/TableList';
import Button from '@material-ui/core/Button';
import PaginationContainer from '../Containers/PaginationContainer';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Permissions from '../../services/Permissions';
import Auth from '../../services/Auth';

const styles = theme => ({
    container: {
        width: "calc(100% + 6px)",
        boxSizing: "border-box",
        display: "flex",
        flexWrap: "wrap",
        boxShadow: "unset",
        background: "unset",
        margin: "-3px"
    },
    contentContainer: {
        overflowY: "auto",
        width: "100%",
        padding: "3px"
    },
    paginationContainer: {
        width: "100%"
    },
    modalWrapper: {
        padding: "24px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "24px"
    },
    link: {
        marginLeft: "4px",
        color: theme.palette.pending.main,
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        },
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "90%",
        textTransform: "unset"
    },
    noStatus: {
        marginLeft: "4px",
        color: "grey",
        textTransform: "unset"
    },
    status: {
        textTransform: "capitalize",
    },
    statusMessage: {
        color: "silver",
        padding: "12px",
        backgroundColor: "black",
        fontFamily: "monospace",
        overflowX: "auto",
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            backgroundColor: "#ffffffc2"
        }
    },
});

class DeviceGatewayCommands extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.startingPerPage = 6;
        this.state = {
            commands: null,
            page_data: {
                page: 1,
                per_page: this.startingPerPage,
                page_meta: true
            },
            modal: {
                open: false,
                children: () => ""
            },
            device_id: this.props.device_id,
            gateway_id: this.props.gateway_id,
            sort: null
        };
        this.load();
        this.heading_info = [
            { label: "Command Type", field: "ui_command_type", align: "left", disablePadding: false, sortable: false },
            { label: "created_at", value: "created_at", field: "created_at", align: "center", disablePadding: false, sortable: true, content: "date" },
            { label: "Issuer", field: "issuer_name", align: "left", disablePadding: false, sortable: false },
            { label: `${this.capitalize(Auth.currentCompany().aliases.gateway)} Status`, field: "gateway_status", align: "left", disablePadding: false, sortable: false },
            { label: "Endpoint Status", field: "endpoint_status", align: "left", disablePadding: false, sortable: false }
        ];
    }

    view_status_message = (message) => {
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: () => this.build_status_modal(message)
            }
        });
    }

    build_status_modal = (message) => {
        return (
            <div className={this.props.classes.modalWrapper}>
                <div className={this.props.classes.statusMessage}>
                    {message}
                </div>
                <div className={this.props.classes.buttonContainer}>
                    <Button
                        size="medium" color="primary" onClick={this.closeModal}
                    >
                        CLOSE
                    </Button>
                </div>
            </div>

        );
    }

    capitalize = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    load = () => {
        let gateway_id = this.props.gateway_id;
        let params = { device_id: gateway_id };
        params = Object.assign(params, this.state.sort, this.state.page_data);
        if (this.props.gateway_id !== this.props.device_id) {
            params.attached_device_unique_ids_inc = this.props.device_unique_id;
        }
        let commands = [];
        GetAll("gateway_commands", params).then((items) => {
            let commands = [];
            if (items && items.length > 0) {
                if (params.attached_device_unique_ids_inc) {
                    commands = GatewayCommand.flatten(items, false, this.props.device_unique_id);
                } else {
                    commands = GatewayCommand.flatten(items, false);
                }
                if (Permissions.allow(["read"], "user", this.props.device.company_id)) {
                    AssignNestedModels("users", "user_id", commands).then(() => {
                        commands.forEach((command) => {
                            command.issuer_name = command.nested_user ? command.nested_user.first_name + " " + command.nested_user.last_name : null;
                            command.ui_command_type = GatewayCommand.getActionMap()[command.command_type];
                        });
                        this.setState({ commands: commands });
                    }).catch((error) => {
                        this.context.openSnackbar(error, "error");
                    });
                } else {
                    this.setState({ commands: commands });
                }
            } else {
                this.setState({ commands: commands });
            }
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    closeModal = () => {
        this.setState({
            modal: {
                open: false, children: () => ""
            }
        });
    }

    perform_sort = (sort_info) => {
        let sort = { order_by: "" };
        sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
        this.setState({ sort: sort }, () => {
            this.load();
        });
    }

    render() {
        const { commands, page_data, modal } = this.state;
        const { classes } = this.props;
        return (
            <Paper className={classes.container}>
                <div className={classes.contentContainer}>
                    <TableList
                        noCheckBox
                        headings={this.heading_info}
                        items={commands}
                        page_data={page_data}
                        perform_sort={this.perform_sort}
                    />
                </div>
                <SimpleModalWrapped info={modal} closeModal={this.closeModal}>
                    {modal.children(classes)}
                </SimpleModalWrapped>
            </Paper>
        )
    }

    showDate = (utc) => {
        let date_object = new Date(utc);
        return date_object.toLocaleDateString();
    }

    showTime = (utc) => {
        let date_object = new Date(utc);
        return date_object.toLocaleTimeString();
    }
}

DeviceGatewayCommands.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceGatewayCommands));
