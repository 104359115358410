import React from 'react';
import { AssignNestedModels, GetAll, AssignRules } from '../CLURDUtilities';
import { LIST, READ, ISSUE_GATEWAY_COMMAND, EXECUTE_COMMAND, API_PROXY } from '../CLURD';
import Auth from '../Auth';
import DataModel from "./DataModel";
import Permissions from '../Permissions';

const BLANK = {
    name: "",
    unique_id: "",
    company_id: "",
    serial: "",
    tags: [],
    device_integration_id: '',
    heartbeat_values: [],
    heartbeat_period: 14400,
    heartbeat_time: "Hours",
    heartbeat_period_changed: 4,
    location: "",
    device_type_id: "",
    nested_device_type: {},
    nested_device_ha_group: {},
    rule_ids: [],
    attached_device_ids: [],
    parent_device_id: "",
    device_config_id: "",
    company_options: [],
    integration_options: null,
    integration_types: null,
    type_options: [],
    rule_options: null,
    parent_options: null,
    child_options: null,
    log_config: {
        local_level: "error",
        forward_level: "error"
    }
};

class Device extends DataModel {
    constructor(data = BLANK) {
        super("devices", data);
    }

    static getLogLevels() {
        return [
            { display: "Trace", value: "trace" },
            { display: "Debug", value: "debug" },
            { display: "Info", value: "info" },
            { display: "Warning", value: "warn" },
            { display: "Error (default)", value: "error" },
            { display: "Critical", value: "critical" },
        ];
    }

    static renderStatusName(device, theme, on_click) {
        const status_map = { offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main };
        const statusDot = {
            width: "8px",
            height: "8px",
            minWidth: "8px",
            minHeight: "8px",
            maxWidth: "8px",
            maxHeight: "8px",
            borderRadius: "50%",
            display: "inline-flex",
            marginRight: "4px",
            backgroundColor: status_map[device.heartbeat_status]
        };
        let text = {
            whiteSpace: "nowrap"
        }
        if (on_click) {
            const link = {
                color: theme.palette.pending.main,
                cursor: "pointer"
            };
            text = Object.assign(text, link);
        }
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={statusDot}>
                    &nbsp;
                </div>
                <span onClick={on_click} style={text}>{device.name}</span>
            </div>
        );
    }


    static loadRequirements(device) {
        return new Promise((resolve, reject) => {
            let devices = [];
            let promises = [
                GetAll("companies"),
                GetAll("integrations/types"),
                GetAll("device_types")
            ];
            if (device === undefined || device === null) {
                devices = [BLANK];
            } else {
                devices = [device];
                //3
                if (Permissions.allow(["read"], "notification", device.company_id)) {
                    promises.push(LIST("notifications", { device_id: devices[0]._id, per_page: 5, order_by: "-created_at" }, Auth.token()));
                } else {
                    promises.push(Promise.resolve([]));
                }
                //4
                if (Permissions.allow(["read"], "report", device.company_id)) {
                    promises.push(LIST("reports", { device_id: devices[0]._id, per_page: 5, order_by: "-created_at" }, Auth.token()));
                } else {
                    promises.push(Promise.resolve([]));
                }
                if (Permissions.allow(["read"], "device_config", device.company_id)) {
                    promises.push(AssignNestedModels("device_configs", "device_config_id", devices));
                }
                if (Permissions.allow(["read"], "integration", device.company_id)) {
                    promises.push(AssignNestedModels("integrations", "device_integration_id", devices));
                    promises.push(AssignNestedModels("integrations", "cloud_native_integration_id", devices));
                }
                if (Permissions.allow(["read"], "rule", device.company_id)) {
                    promises.push(AssignRules(device));
                }
                if (Permissions.allow(["read"], "device_ha_group", device.company_id)) {
                    promises.push(AssignNestedModels("device_ha_groups", "device_ha_group_id", devices));
                }
                promises = promises.concat([
                    AssignNestedModels("companies", "company_id", devices),
                    AssignNestedModels("device_types", "device_type_id", devices),
                    AssignNestedModels("devices", "attached_device_ids", devices),
                    AssignNestedModels("devices", "parent_device_id", devices),
                ]);
            }
            Promise.all(promises).then((results) => {
                devices[0].recent_alerts = results[3] ? results[3].items ? results[3].items : [] : [];
                devices[0].recent_reports = results[4] ? results[4].items ? results[4].items : [] : [];
                devices[0].company_options = results[0];
                devices[0].integration_types = results[1];
                devices[0].type_options = results[2];
                devices[0].parent_options = null;
                devices[0].child_options = null;
                if (devices[0].nested_device_type && Object.entries(devices[0].nested_device_type).length !== 0) {
                    if (Permissions.allow(["read"], "software_update", device.company_id)) {
                        LIST("software_updates", { device_type_id: devices[0].device_type_id }, Auth.token()).then((result) => {
                            devices[0].nested_device_type.nested_software_updates = result.items || [];
                            resolve(devices[0]);
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {
                        resolve(devices[0]);
                    }
                } else {
                    resolve(devices[0]);
                }
            }).catch((error) => {
                reject(error);
            });
        });

    }

    //api json converted into ui-ready json
    static decode(api_device = BLANK) {
        return new Promise((resolve, reject) => {
            formatHeartbeat(api_device);
            resolve(api_device);
        });
    }

    static list_logs(device_id) {
        const token = Auth.token();
        let env = window.API;
        if (env.charAt(env.length - 1) !== '/') {
            env += "/";
        }
        const options = {
            method: "GET",
            url: `${env}devices/${device_id}/logs`,
            headers: {
                'accept': 'application/json',
                'authorization': token,
            }
        };
        return new Promise((resolve, reject) => {
            API_PROXY(options, token).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static encode(ui_device) {
        let body = {
            name: ui_device.name,
            unique_id: ui_device.unique_id,
            company_id: ui_device.company_id,
            serial: ui_device.serial,
            device_config_id: ui_device.device_config_id.value,
            device_integration_id: ui_device.device_integration_id,
            heartbeat_values: ui_device.heartbeat_values,
            heartbeat_period: getHeartbeatSeconds(ui_device),
            location: ui_device.location,
            device_type_id: ui_device.nested_device_type._id,
            rule_ids: ui_device.rule_ids.map((rule) => rule.whole._id),
            attached_device_ids: ui_device.attached_device_ids,
            parent_device_id: getParent(ui_device),
            tags: ui_device.tags,
            log_config: {
                local_level: ui_device.log_config.local_level,
                forward_level: ui_device.log_config.forward_level
            }
        };
        if (typeof ui_device.device_integration_id !== "string") {
            if (ui_device.device_integration_id && ui_device.device_integration_id.value) {
                body.device_integration_id = ui_device.device_integration_id.value;
            } else {
                delete body.device_integration_id;
            }
        }
        return body;
    }

    static bulkCommand(command, device_ids, schedule, package_id) {
        let body = { command_type: command, ids: device_ids };
        if (schedule) {
            body.schedule = schedule;
        }
        if (command === "software_update") {
            body.software_update_id = package_id;
        }
        let env = window.API;
        if (env.charAt(env.length - 1) !== '/') {
            env += "/";
        }
        const token = Auth.token();
        const options = {
            method: "POST",
            data: body,
            url: `${env}devices/bulk/${command}`,
            headers: {
                'accept': 'application/json',
                'authorization': token,
            }
        };
        return new Promise((resolve, reject) => {
            API_PROXY(options, token).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    }


    static AssignRecentErrors(devices) {
        let id_string = devices.map(({ _id }) => (_id)).join(",");
        let time = new Date();
        time.setMinutes(time.getMinutes() - 5);
        let created_at_gt = time.toISOString();
        let params = { created_at_gt: created_at_gt, device_id_in: id_string };
        return new Promise((resolve, reject) => {
            GetAll("device_errors", params).then((errors) => {
                devices.forEach((device) => {
                    device.nested_error_count = errors.filter(({ device_id }) => device._id === device_id).length;
                });
                resolve(devices);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    static AssignRecentAlerts(devices) {
        let id_string = devices.map(({ _id }) => (_id)).join(",");
        let time = new Date();
        time.setMinutes(time.getMinutes() - 30);
        let created_at_gt = time.toISOString();
        let params = { created_at_gt: created_at_gt, device_id_in: id_string };
        return new Promise((resolve, reject) => {
            GetAll("notifications", params).then((alerts) => {
                devices.forEach((device) => {
                    device.nested_alert_count = alerts.filter(({ device_id }) => device._id === device_id).length;
                });
                resolve(devices);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    static getCellUsage() {
        return LIST("stats/cumulative_cell_usage", {}, Auth.token());
    }

    static getHeartbeatStats() {
        return LIST("stats/devices/count_by_heartbeat_status", {}, Auth.token());
    }

    static getTypesStats() {
        return LIST("stats/devices/count_by_type", {}, Auth.token());
    }

    static commandReadable(command) {
        const command_map = {
            "sms_at_command_status_request": "SMS AT Status Request",
            "notification": "Alert",
            "enable_data_restriction": "Enable Data Restrictions",
            "disable_data_restriction": "Disable Data Restrictions",
            "send_config": "Send Config",
            "deploy_deployment": "Deploy",
            "request_deployment_status": "Request Deployment Status",
            "data_restore": "Restore Data",
            "update_edge": "Update Edge",
            "update_firmware": "Update Firmware",
            "restore_backup": "Restore Backup",
            "backup": "Backup",
            "log_level": "Log Level",
            "log_config": "Log Config",
            "log_upload": "Upload Logs",
            "reboot": "Reboot",
            "reset": "Reset",
            "data_reset": "Data Reset",
            "heartbeat": "Prompt Heartbeat",
            "status": "Prompt Status",
            "software_update": "Software Update",
            "provision_marmon_button": "Provision Marmon Button",
            "greengrass_initialize": "Initialize Greengrass",
            "greengrass_restart": "Restart Greengrass",
            "greengrass_redeploy": "Redeploy Greengrass",
            "amag_add_modify_card_holder": "Add/Modify AMAG Card Holder",
            "amag_delete_card_holder": "Delete AMAG Card Holder",
            "activate_cloud_native_device": "Activate Cloud Native Device",
            "deactivate_cloud_native_device": "Deactivate Cloud Native Device",
            "lwm2m_request": "LwM2M Request",
            "log": "Log Ability",
            "sms": "SMS Ability",
            "email": "Email Ability",
            "relay": "Relay Ability",
            "http_request": "HTTP Request Ability",
            "mqtt": "MQTT Ability",
            "aws_iot": "AWS IoT Ability",
            "bluemix_iot": "Bluemix IoT Ability",
            "azure_iot": "Azure IoT Ability",
            "tcp": "TCP Ability",
            "prtg": "PRTG Ability",
            "tcp_modbus": "TCP Modbus Ability",
            "opcua": "OPC-UA Ability",
            "bacnet": "BACnet Ability"
        };
        return command_map[command];
    }

    static connectionReadable(connection_type) {
        const connection_template = {
            'ethernet-wan': "Ethernet-WAN",
            'ethernet-wan-lan': "Ethernet-WAN/LAN",
            'ethernet-lan': "Ethernet-LAN",
            'cellular': "Cellular",
            'wifi': "WiFi"
        };
        return connection_template[connection_type] || "Connection Type Not Supported";
    }

    static getHeartbeatValues() {
        return [
            { value: "connections", label: "Connections" },
            { value: "wifi_clients", label: "WIFI Clients" },
            { value: "disk_size", label: "Disk Size" },
            { value: "disk_usage", label: "Disk Space Usage" },
            { value: "disk_free", label: "Disk Space Free" },
            { value: "cell_signal", label: "Cell Signal" },
            { value: "cell_usage", label: "Cell Usage" },
            { value: "cpu_usage", label: "CPU Usage" },
            { value: "ram_usage", label: "RAM Usage" },
            { value: "sim_card", label: "SIM Card" },
        ];
    }

    static getHeartbeatOptions() {
        return {
            "connections": { enabled: false, label: "Connections" },
            "wifi_clients": { enabled: false, label: "WIFI Clients" },
            "disk_size": { enabled: false, label: "Disk Size" },
            "disk_usage": { enabled: false, label: "Disk Space Usage" },
            "disk_free": { enabled: false, label: "Disk Space Free" },
            "cell_signal": { enabled: false, label: "Cell Signal" },
            "cell_usage": { enabled: false, label: "Cell Usage" },
            "cpu_usage": { enabled: false, label: "CPU Usage" },
            "ram_usage": { enabled: false, label: "RAM Usage" },
            "sim_card": { enabled: false, label: "SIM Card" },
        };
    }

    static executeCommand(device_id, command_id, payload_string) {
        let body = {};
        if (payload_string) {
            let payload = JSON.parse(payload_string);
            body.payload = payload;
        }
        const authorization = Auth.token();
        return new Promise((resolve, reject) => {
            EXECUTE_COMMAND(body, "devices", device_id, command_id, authorization).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static issueGatewayCommand(command, schedule, _id, package_id, is_unique_id) {
        let body = { command_type: command, schedule: schedule };
        if (schedule) {
            body.schedule = schedule;
        }
        if (command === "software_update" && package_id) {
            body.software_update_id = package_id;
        }
        if (command === "enable_data_restriction" || command === "disable_data_restriction") {
            body.command_type = "data_restriction";
            body.enable = command === "enable_data_restriction" ? true : false;
        }
        const authorization = Auth.token();

        return new Promise((resolve, reject) => {
            if (is_unique_id) {
                new Device().listFromAPI({ unique_id: _id }).then(({ items }) => {
                    if (!items || items.length === 0) {
                        reject("Device not found");
                        return;
                    }
                    ISSUE_GATEWAY_COMMAND(body, items[0]._id, authorization).then((result) => {
                        resolve(result.data);
                    }).catch((error) => {
                        reject(error);
                    });
                }).catch((error) => {
                    console.log("error issuing command", error);
                    reject(error);
                });
            } else {
                ISSUE_GATEWAY_COMMAND(body, _id, authorization).then((result) => {
                    resolve(result.data);
                }).catch((error) => {
                    reject(error);
                });
            }

        });
    }
}

function getConfig(device) {
    //todo
    return null;
}

function getParent(device) {
    if (device.parent_device_id === null || device.parent_device_id === undefined || device.parent_device_id === "") {
        return null;
    } else {
        if (typeof device.parent_device_id === "string") {
            return device.parent_device_id
        } else {
            return device.parent_device_id.whole._id;
        }
    }
}

function getHeartbeatSeconds(device) {
    switch (device.heartbeat_time) {
        case "Seconds":
            return parseInt(device.heartbeat_period_changed);
        case "Minutes":
            return parseInt(device.heartbeat_period_changed) * 60;
        case "Hours":
            return parseInt(device.heartbeat_period_changed) * 3600;
        case "Days":
            return parseInt(device.heartbeat_period_changed) * 86400;
    }
}

function formatHeartbeat(device) {
    if (device.heartbeat_period % 86400 === 0) {
        device.heartbeat_time = "Days";
        device.heartbeat_period_changed = device.heartbeat_period / 86400;
    } else if (device.heartbeat_period % 3600 === 0) {
        device.heartbeat_time = "Hours";
        device.heartbeat_period_changed = device.heartbeat_period / 3600;
    } else if (device.heartbeat_period % 60 === 0) {
        device.heartbeat_time = "Minutes";
        device.heartbeat_period_changed = device.heartbeat_period / 60;
    } else {
        device.heartbeat_time = "Seconds";
        device.heartbeat_period_changed = device.heartbeat_period;
    }
}

export default Device;
