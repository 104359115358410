import DataModel from "./DataModel";
import { AssignNestedModels, GetAll } from '../CLURDUtilities';
import { LIST, EXECUTE_COMMAND, API_PROXY } from '../CLURD';
import Auth from '../Auth';
import Permissions from '../Permissions';

class DeviceType extends DataModel {
    constructor(data) {
        super('device_types', data);
    }

    static convertForUI(type) {
        return {
            company_id: type.company_id,
            name: type.name,
            image: type.image,
            manufacturer: type.manufacturer,
            model: type.model,
            firmware_upgrade: type.capabilities.firmware.upgrade,
            iptables: type.capabilities.iptables,
            type: type.type,
            role: type.role,
            long_description: type.long_description
        };
    }

    static convertForAPI(type, propsType) {
        let api_type = {
            company_id: type.company_id,
            name: type.name,
            image: type.image,
            manufacturer: type.manufacturer,
            model: type.model,
            type: type.type,
            role: type.role,
            long_description: type.long_description
        };
        if (propsType) {
            api_type.capabilities = JSON.parse(JSON.stringify(propsType.capabilities));
            api_type.capabilities.iptables = type.iptables;
            api_type.capabilities.firmware = {
                upgrade: type.firmware_upgrade,
                backup: false
            };
        } else {
            api_type.capabilities = {};
            api_type.capabilities.iptables = type.iptables;
            api_type.capabilities.firmware = {
                upgrade: type.firmware_upgrade,
                backup: false
            };
        }

        return api_type;
    }

    static getConnectionTypes() {
        return [
            { value: "ethernet-wan", display: "Ethernet-WAN" },
            { value: "ethernet-wan-lan", display: "Ethernet-WAN/LAN" },
            { value: "ethernet-lan", display: "Ethernet-LAN" },
            { value: "cellular", display: "Cellular" },
            { value: "wifi", display: "WiFi" },
        ];
    }

    static testImage(url, timeoutT) {
        return new Promise(function (resolve, reject) {
            var timeout = timeoutT || 5000;
            var timer, img = new Image();
            img.onerror = img.onabort = function () {
                clearTimeout(timer);
                reject("error");
            };
            img.onload = function () {
                clearTimeout(timer);
                resolve();
            };
            timer = setTimeout(function () {
                img.src = "//!!!!/test.jpg";
                reject("timeout");
            }, timeout);
            img.src = url;
        });
    }

    static loadRequirements(types) {
        return new Promise((resolve, reject) => {
            Promise.all([AssignNestedModels("companies", "company_id", types)]).then((result) => {
                resolve(types);
            }).catch((error) => reject(error));
        });
    }

    static getTypeOptions() {
        return [
            { value: "gateway", display: "Gateway Device" },
            { value: "sensor", display: "Sensor" },
            { value: "cloud_native", display: "Cloud Native Device" },
            { value: "modem", display: "Modem" },
            { value: "lwm2m", display: "LwM2M" },
        ];
    }

    static getRoleOptions() {
        return [
            { value: "gateway", display: "Gateway" },
            { value: "endpoint", display: "Endpoint" },
        ];
    }

    static getTypes(params = {}) {
        return LIST("device_types", params, Auth.token());
    }

    static executeCommand(device_type_id, command_id, payload_string) {
        let body = {};
        if (payload_string) {
            let payload = JSON.parse(payload_string);
            body.payload = payload;
        }
        const authorization = Auth.token();
        return new Promise((resolve, reject) => {
            EXECUTE_COMMAND(body, "device_types", device_type_id, command_id, authorization).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}

export default DeviceType;
