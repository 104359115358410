import React, { Fragment } from 'react';
import TextInput from '../Inputs/TextInput';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import TimeInput from '../Inputs/TimeInput';
import SwitchInput from '../Inputs/SwitchInput';
import Company from '../../services/DataModels/Company';
import Permissions from '../../services/Permissions';
import SelectInput from '../Inputs/SelectInput';

//mui
import IconButton from '@material-ui/core/IconButton';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//icons
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimelineIcon from '@material-ui/icons/Timeline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LookupInput from '../Inputs/LookupInput';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import StyleIcon from '@material-ui/icons/Style';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MetadataIcon from '@material-ui/icons/PictureInPictureAlt';

const styles = theme => ({
	prompt: {
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	modalWrapper: {
		fontFamily: "Inter",
		height: "643px",
		boxSizing: "border-box",
	},
	container: {
		height: "505px",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "16px",
	},
	heartbeatIconsContainer: {
		position: "relative",
		color: theme.palette.greyIcon.main,
		display: "flex",
		margin: "0 8px"
	},
	logLevelsIconsContainer: {
		position: "relative",
		color: theme.palette.greyIcon.main,
		display: "flex",
		margin: "5px 0"
	},
	heartbeatIcon: {
		color: theme.palette.greyIcon.main,
		fontSize: "24px",
	},
	overlayIcon: {
		position: "absolute",
		left: 0,
		color: "white",
		fontSize: "24px"
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
	},
	inputWrapper: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	inputIcon: {
		color: theme.palette.greyIcon.main,
		margin: "8px",
		paddingBottom: "20px"
	},
	infoIcon: {
		color: theme.palette.greyIcon.main,
		margin: "8px",
	},
	noteContainer: {
		marginLeft: "24px",
		display: "flex",
		alignItems: "center",
		fontSize: "14px",
		color: "#8e8e93",
	},
	lookupInputIcon: {
		paddingBottom: 0
	},
	noIcon: {
		visibility: "hidden"
	},
	buttonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "32px",
	},
	saveButton: {
		marginLeft: "8px",
	},
	timeContainer: {
		display: "flex",
		alignItems: "center"
	},
	logContainer: {
		// display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		margin: "0 0 0 8px"
	},
	generate: {
		marginRight: "8px",
		fontSize: "14px",
	},
	formControl: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		paddingLeft: "30px",
		marginBottom: "12px"
	},
	formControlLabel: {
		margin: 0,
		flex: "50%",
		display: "flex",
		overflow: "hidden"
	},
	formControlLabelText: {
		overflow: "hidden",
		whiteSpace: "no-wrap",
		textOverflow: "ellipsis"
	},
	clearCheckbox: {
		width: "100%",
		marginLeft: "24px",
	},
	locationContainer: {
		marginBottom: "-12px"
	},
	switchWrapper: {
		marginLeft: "8px"
	},
	addTagContainer: {
		display: "flex",
		width: "100%",
		alignItems: "center",
		paddingLeft: "40px",
	},
	newTagContainer: {
		marginBottom: "-12px",
	},
	addTagButton: {
		cursor: "pointer",
		color: "grey"
	},
	actionInfo: {
		fontFamily: "Inter",
		color: "#8e8e93",
		alignItems: "center",
		fontSize: "14px",
		margin: "5px 0"
	},
	switchItems: {
		flexWrap: "wrap",
		display: "flex"
	},
	metadataItems: {
		flexWrap: "wrap",
		display: "flex",
		paddingLeft: "40px",
	},
	keyPairContainer: {
		display: "flex",
		borderBottom: "solid lightgrey 1px",
		paddingTop: "6px",
		marginBottom: "18px",
		alignItems: "center",
		width: "100%"
	},
	keyPairItem: {
		width: "40%",
		marginRight: "8px"
	},
	deleteIconButton: {
		padding: "4px",
	},
	keyPairDelete: {
		marginTop: "-24px",
		"&:hover": {
			color: "#0263fc",
		}
	},
	secondaryButton: {
		color: theme.palette.pending.main,
		padding: "4px",
		margin: "0 12px 12px 12px",
		width: "25%",
		border: `solid ${theme.palette.pending.main} 2px`
	},
	tertiaryButton: {
		margin: "0 6px 6px 6px",
		width: "25%",
	},
	buttonOverride: {
		textTransform: "unset",
		boxShadow: "none",
		color: theme.palette.pending.main,
		width: "100%",
	},
	errorText: {
		color: theme.palette.red.main,
		fontSize: "14px",
		width: "100%",
	},
});

const INITIAL_HEARTBEAT_VALUES = {
	"connections": { enabled: false, label: "Connections"},
	"wifi_clients": { enabled: false, label: "WIFI Clients"},
	"disk_size": { enabled: false, label: "Disk Size"},
	"disk_usage": { enabled: false, label: "Disk Space Usage"},
	"disk_free": { enabled: false, label: "Disk Space Free"},
	"cell_signal": { enabled: false, label: "Cell Signal"},
	"cell_usage": { enabled: false, label: "Cell Usage"}, 
	"cpu_usage": { enabled: false, label: "CPU Usage"},
	"ram_usage": { enabled: false, label: "RAM Usage"},
	"sim_card": { enabled: false, label: "SIM Card"},
};

class BulkEditDevices extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			location: "",
			clear_location: false,
			show_errors: false,
			heartbeat_values: JSON.parse(JSON.stringify(INITIAL_HEARTBEAT_VALUES)),
			heartbeat_period_changed: 5,
			heartbeat_time: "Minutes",
			heartbeat_editing: false,
			log_level_change: false,
			device_integration_lookup: {
				value: null,
				suggestions: null,
				clear: false,
			},
			cloud_native_integration_lookup: {
				value: null,
				suggestions: null,
				clear: false,
			},
			metadata: [],
			rules_lookup: {
				value: null,
				suggestions: null,
			},
			config_lookup: {
				value: null,
				suggestions: null,
				clear: false,
			},
			tags_lookup: {
				value: [],
				suggestions: undefined,
				clear: false,
			},
			local_level: "",
			forward_level: "",
			new_tag: "",
		};
		this.logLevelOptions = [
			{ display: "Trace", value: "trace" },
			{ display: "Debug", value: "debug"},
			{ display: "Info", value: "info" },
			{ display: "Warning", value: "warn" },
			{ display: "Error", value: "error" },
			{ display: "Critical", value: "critical" },
		];
		Company.getAllTags().then( (tags) => {
			let tags_lookup = this.state.tags_lookup;
			tags_lookup.suggestions = tags.map( (tag) => ({value: tag, label: tag}));
			this.setState({tags_lookup: tags_lookup});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	toggle_clear = (field) => {
		this.setState( (prev_state) => {
			let state_object = {};
			state_object[field] = {
				suggestions: prev_state[field].suggestions,
				value: prev_state[field].value,
				clear: !prev_state[field].clear,
			};
			return state_object;
		});
	}

	handleTagChange = (tags) => {
		let new_lookup = this.state.tags_lookup;
		new_lookup.suggestions = tags.suggestions;
		new_lookup.value = tags.value ? tags.value.map( (tag) => (tag.value)) : [];
		this.setState({ tags_lookup: new_lookup });
	}

	handleLookupChange = (input) => {
		let lookup = {value: input.value, suggestions: input.suggestions};
		let state_object = null;
		switch (input.label) {
			case "Define New Tags": lookup.clear = this.state.tags_lookup.clear; state_object = {tags_lookup: lookup}; break;
			case "Network Config": lookup.clear = this.state.config_lookup.clear; state_object = {config_lookup: lookup}; break;
			case "Cloud Native Integration": lookup.clear = this.state.cloud_native_integration_lookup.clear; state_object = {cloud_native_integration_lookup: lookup}; break;
			case "Device Integration": lookup.clear = this.state.device_integration_lookup.clear; state_object = {device_integration_lookup: lookup}; break;
			case "Add Policies": state_object = {rules_lookup: lookup}; break;
		}
		this.setState(state_object);
	}

	handleChange = ({ value, field }) => {
		let new_state = {};
		new_state[field] = value;
		this.setState(new_state);
	}

	handleHeartbeatPeriodChange = (change) => {
		this.setState({heartbeat_period_changed: change.value, heartbeat_time: change.time });
	}

	handleHBValueChange = (key, enabled) => {
		let heartbeat_values = this.state.heartbeat_values;
		heartbeat_values[key].enabled = enabled;
		this.setState({heartbeat_values: heartbeat_values});
	}
	
	add_tag = () => {
		let new_lookup = this.state.tags_lookup;
		if (!new_lookup.value) {
			new_lookup.value = [];
		} else {
			new_lookup.value.push(this.state.new_tag);
		}
		new_lookup.suggestions.push({label: this.state.new_tag, value: this.state.new_tag});
		this.setState({tags_lookup: new_lookup, new_tag: ""}, () => {console.log(this.state.tags_lookup);});
	}

	renderHeartbeatValues = (classes) => {
		return (
			<React.Fragment>
				<div className={classes.formControl}>
					{Object.entries(this.state.heartbeat_values).map( ([key, {enabled, label}]) => (
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox color="primary" checked={enabled} onChange={() => this.handleHBValueChange(key, !enabled)} value={key} />
							}
							key={key}
							label={label}
							classes={{label: classes.formControlLabelText}}
						/>
					))}
				</div>
			</React.Fragment>
		);
	}

	metadata_valid = () => {
		let map = {};
		let keys_valid = true;
		let no_empties = true;
		this.state.metadata.forEach( ({key, value}) => {
			if (undefined !== map[key]) {
				keys_valid = false;
			}
			map[key] = value;
			if (value === "") {
				no_empties = false;
			}
		});
		if (!keys_valid || !no_empties) {
			return false;
		}
		return map;
	}

	render_metadata = () => {
		const { classes } = this.props;
		const { metadata } = this.state;
		const update_metadata = (field, val, index) => {
			this.setState( (state) => {
				state.metadata[index][field] = val;
				return state;
			});
		}
		const remove_key_pair = (index) => {
			this.setState( (state) => {
				state.metadata.splice(index, 1);
				return state;
			});
		}
		const add_key_pair = () => {
			this.setState( (state) => {
				state.metadata.push({key: "", value: ""});
				return state;
			});
		}
		return (
			<div className={classes.inputWrapper}>
				<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} key="Add Metadata" title="Add Metadata">
					<MetadataIcon />
				</Tooltip>
				<div className={classes.actionInfo}>
					Add/Update Device Metadata:
				</div>
				<div className={classes.metadataItems}>
					{metadata.map( ({key, value}, index) =>
						<div className={classes.keyPairContainer} key={"pair_" + index}>
							<div className={classes.keyPairItem}>
								<TextInput
									key={"key_" + index}
									label="Key*"
									emitChange={({field, value}) => update_metadata(field, value, index)}
									priorState={key}
									field="key"
								/>
							</div>
							<div className={classes.keyPairItem}>
								<TextInput
									key={"value_" + index}
									label="Value*"
									emitChange={({field, value}) => update_metadata(field, value, index)}
									priorState={value}
									field="value"
								/>
							</div>
							<IconButton onClick={() => remove_key_pair(index)} style={{padding: "4px"}} className={classes.deleteIconButton + " " + classes.keyPairDelete}>
								<DeleteIcon />
							</IconButton>
						</div>
					)}
					<div>
						<Button onClick={add_key_pair} color="primary">
							Add Key Pair
						</Button>
					</div>
					{this.state.show_errors ? <div className={classes.errorText}>
						Please ensure no metadata keys are the same and that none of the inputs are empty.
					</div> : ""}
				</div>
			</div>
		);
	}

	render() {
		const { classes, closeModal,  } = this.props;
		const { location, show_errors, heartbeat_period_changed, heartbeat_time, tags_lookup, cloud_native_integration_lookup,device_integration_lookup, config_lookup, rules_lookup, log_level_change, local_level, forward_level } = this.state;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Bulk Edit Devices
				</div>
				<div className={classes.container}>
					<div className={classes.inputWrapper}>
						<div className={classes.inputContainer + " " + classes.locationContainer}>
							{/* todo replace with react-geosuggest */}
							<Tooltip className={classes.inputIcon} key="Location" title="Location">
								<LocationOnIcon />
							</Tooltip>
							<TextInput
								field="location"
								emitChange={this.handleChange}
								priorState={this.state.clear_location ? "" : location}
								label="Location"
								margin="none"
								disabled={this.state.clear_location}
							/>
						</div>
						<FormControlLabel
							className={classes.clearCheckbox}
							control={
								<Checkbox color="primary" checked={this.state.clear_location} onChange={() => this.setState({clear_location: !this.state.clear_location})} value={"clear_location"} />
							}
							label={"Clear All Devices' Location"}
							classes={{label: classes.formControlLabelText}}
						/>
					</div>
					{Permissions.allow(["read"], "integration") ? <div className={classes.inputWrapper}>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} key="Cloud Native Integration" title="Cloud Native Integration">
								<ExtensionIcon />
							</Tooltip>
							<LookupInput
								priorState={{values: cloud_native_integration_lookup.clear ? null : cloud_native_integration_lookup.value, suggestions: cloud_native_integration_lookup.suggestions}}
								label={"Cloud Native Integration"}
								single
								placeholder="Cloud Native Integration Name"
								model="integrations"
								emitChange={this.handleLookupChange}
								valueField="_id"
								labelField="name"
								params={{type_like: "_cloud_native_device_integrations"}}
								disabled={cloud_native_integration_lookup.clear}
							/>
						</div>
						<div className={classes.noteContainer}>
							<InfoIcon className={classes.infoIcon}/>
							<div className={classes.integrationNote}>
								This integration will only be added to devices that don't already have a cloud native integration.
							</div>
						</div>
					</div> : ""}
					{Permissions.allow(["read"], "integration") ? <div className={classes.inputWrapper}>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} key="Device Integration" title="Device Integration">
								<ExtensionIcon />
							</Tooltip>
							<LookupInput
								priorState={{values: device_integration_lookup.clear ? null : device_integration_lookup.value, suggestions: device_integration_lookup.suggestions}}
								label={"Device Integration"}
								single
								placeholder="Device Integration Name"
								model="integrations"
								emitChange={this.handleLookupChange}
								valueField="_id"
								labelField="name"
								params={{type_like: "_device_integrations"}}
								disabled={device_integration_lookup.clear}
							/>
						</div>
						<div className={classes.noteContainer}>
							<InfoIcon className={classes.infoIcon}/>
							<div className={classes.integrationNote}>
								This integration will only be added to devices that don't already have a device integration.
							</div>
						</div>
					</div> : ""}
					<div className={classes.inputWrapper}>
						<div className={classes.inputContainer}>
							<Tooltip title="Tags">
								<StyleIcon className={classes.inputIcon} />
							</Tooltip>
							<LookupInput
								priorState={{values: tags_lookup.clear ? null : tags_lookup.value.map( (tag) => ({value: tag, label: tag})), suggestions: tags_lookup.suggestions}}
								placeholder="Add Tag"
								label={"Define New Tags"}
								emitChange={this.handleTagChange}
								disabled={tags_lookup.clear}
							/>
						</div>
						<div className={classes.addTagContainer}>
							<div className={classes.newTagContainer}>
								<TextInput
									variant="standard"
									field="new_tag"
									emitChange={({value}) => this.setState({new_tag: value})}
									priorState={tags_lookup.clear ? "" : this.state.new_tag}
									placeholder="Create New Tag"
									margin="none"
									disabled={tags_lookup.clear}
									onEnter={this.add_tag}
								/>
							</div>
							<AddBoxIcon onClick={() => !tags_lookup.clear ? this.add_tag() : ""} className={classes.addTagButton}/>
						</div>
						<FormControlLabel
							className={classes.clearCheckbox}
							control={
								<Checkbox color="primary" checked={this.state.tags_lookup.clear} onChange={() => this.toggle_clear("tags_lookup")} value={"tags_lookup"} />
							}
							label={"Remove All Devices' Tags"}
							classes={{label: classes.formControlLabelText}}
						/>
					</div>
					{Permissions.allow(["read"], "device_config") ? <div className={classes.inputWrapper}>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Device Config">
								<SettingsIcon />
							</Tooltip>
							<LookupInput
								priorState={{values: config_lookup.clear ? null : config_lookup.value, suggestions: config_lookup.suggestions}}
								label={"Network Config"}
								single
								placeholder="Config Name"
								model="device_configs"
								emitChange={this.handleLookupChange}
								valueField="_id"
								labelField="name"
								disabled={config_lookup.clear}
							/>
						</div>
						<FormControlLabel
							className={classes.clearCheckbox}
							control={
								<Checkbox color="primary" checked={this.state.config_lookup.clear} onChange={() => this.toggle_clear("config_lookup")} value={"config_lookup"} />
							}
							label={"Remove All Devices' Network Config"}
							classes={{label: classes.formControlLabelText}}
						/>
						<div className={classes.noteContainer}>
							<InfoIcon className={classes.infoIcon}/>
							<div className={classes.integrationNote}>
								Network configurations only apply to gateway type devices.
							</div>
						</div>
					</div> : ""}
					{Permissions.allow(["read"], "rule") ? <div className={classes.inputWrapper}>
						<div className={classes.inputContainer}>
							<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Policies">
								<SecurityIcon />
							</Tooltip>
							<LookupInput
								priorState={{values: rules_lookup.value, suggestions: rules_lookup.suggestions}}
								label={"Add Policies"}
								placeholder="Policy Description"
								model="rules"
								emitChange={this.handleLookupChange}
								valueField="_id"
								labelField="name"
							/>
						</div>
					</div> : ""}
					{this.render_metadata()}
					<div className={classes.switchWrapper}>
						<SwitchInput
							initial={this.state.heartbeat_editing}
							emitChange={() => this.setState({heartbeat_editing: !this.state.heartbeat_editing})}
							color={this.props.theme.palette.pending.main}
							onLabel="Configure Heartbeat/Expected Report Frequency"
							offLabel="Configure Heartbeat/Expected Report Frequency"
							field="heartbeat"
							location="start"
						/>
					</div>
					{this.state.heartbeat_editing ? <div>
						<div className={classes.timeContainer}>
							<div className={classes.heartbeatIconsContainer}>
								<FavoriteIcon className={classes.heartbeatIcon} />
								<TimelineIcon className={classes.overlayIcon}/>
							</div>
							<span className={classes.generate}>
								Generate a heartbeat/Expect a report every
							</span>
							<TimeInput
								margin="none"
								emitChange={this.handleHeartbeatPeriodChange}
								priorState={{ value: heartbeat_period_changed, time: heartbeat_time }}
							/>
						</div>
						{this.renderHeartbeatValues(classes)}
						<div className={classes.noteContainer}>
							<InfoIcon className={classes.infoIcon}/>
							<div className={classes.integrationNote}>
								Heartbeat fields only apply to gateway type devices.
							</div>
						</div>
					</div> : "" }
					<div className={classes.switchWrapper}>
						<SwitchInput
							initial={this.state.log_level_change}
							emitChange={() => this.setState({log_level_change: !this.state.log_level_change})}
							color={this.props.theme.palette.pending.main}
							onLabel="Configure Log Levels"
							offLabel="Configure Log Levels"
							field="log_config"
							location="start"
						/>
					</div>
					{this.state.log_level_change ? <div>
						<div className={classes.logContainer}>
							<div className={classes.logLevelsIconsContainer}>
								<span className={classes.generate}>
									Set new log levels for the devices below
								</span>
							</div>
							<div className={classes.actionInfoContainer}>
								<div className={classes.actionInfo}>
									Local Log Level
								</div>
							</div>
							<SelectInput
								field="local_level"
								emitChange={this.handleChange}
								priorState={this.state.local_level || "error"}
								options={this.logLevelOptions}
							/>
							<div className={classes.actionInfoContainer}>
								<div className={classes.actionInfo}>
									Forward Log Level
								</div>
							</div>
							<SelectInput
								field="forward_level"
								emitChange={this.handleChange}
								priorState={this.state.forward_level || "error"}
								options={this.logLevelOptions}
							/>
						</div>
						<div className={classes.noteContainer}>
							<InfoIcon className={classes.infoIcon}/>
							<div className={classes.integrationNote}>
								Log configuration only applies to gateway type devices.
							</div>
						</div>
					</div> : "" }
				</div>
				<div className={classes.buttonsContainer}>
					<Button color="primary" onClick={closeModal}>
						cancel
					</Button>
					<Button variant="contained" color="primary" size="large" className={classes.saveButton} onClick={this.save}>
						Save
					</Button>
				</div>
			</div>
		);
	}

	save = () => {
		let map = this.metadata_valid();
		if (map === false) {
			this.setState({show_errors: true});
			return;
		}
		let new_device = {};
		if (this.state.metadata.length === 0) {
			new_device.metadata = null;
		} else {
			new_device.metadata = map;
		}
		new_device.ids = this.props.ids;
		if (this.state.clear_location) {
			new_device.location = "";
		} else if (this.state.location !== "") {
			new_device.location = this.state.location;
		}
		if (this.state.device_integration_lookup.value) {
			new_device.device_integration_id = this.state.device_integration_lookup.value.value;
		}
		if (this.state.cloud_native_integration_lookup.value) {
			new_device.cloud_native_integration_id = this.state.cloud_native_integration_lookup.value.value;
		}
		if (this.state.config_lookup.clear) {
			new_device.device_config_id = null;
		} else if (this.state.config_lookup.value) {
			new_device.device_config_id = this.state.config_lookup.value.value;
		}
		if (this.state.tags_lookup.clear) {
			new_device.tags = [];
		} else if (this.state.tags_lookup.value && this.state.tags_lookup.value.length > 0) {
			new_device.tags = this.state.tags_lookup.value;
		}
		if (this.state.rules_lookup.value && this.state.rules_lookup.value.length > 0) {
			new_device.rule_ids = this.state.rules_lookup.value.map( ({value}) => value);
		}
		if (this.state.heartbeat_editing) {
			let heartbeat_values = [];
			Object.entries(this.state.heartbeat_values).forEach( ([key, {enabled}]) => {
				if (enabled) {
					heartbeat_values.push(key);
				}
			});
			switch (this.state.heartbeat_time) {
				case "Seconds":
					new_device.heartbeat_period = parseInt(this.state.heartbeat_period_changed);
					break;
				case "Minutes":
					new_device.heartbeat_period = this.state.heartbeat_period_changed * 60;
					break;
				case "Hours":
					new_device.heartbeat_period = this.state.heartbeat_period_changed * 3600;
					break;
				case "Days":
					new_device.heartbeat_period = this.state.heartbeat_period_changed * 86400;
					break;
			}
			new_device.heartbeat_values = heartbeat_values;
		}
		if (this.state.log_level_change === true) {
			new_device.log_config = {};
			new_device.log_config.local_level = this.state.local_level;
			new_device.log_config.forward_level = this.state.forward_level;
		}
		if (Object.entries(new_device).length === 1) {
			this.context.openSnackbar("No changes have been made in the form. Please use the inputs to make a change to the selected devices.", "warning");
			return;
		}
		this.props.onSave(new_device);
	}
}
BulkEditDevices.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(BulkEditDevices));
