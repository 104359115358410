import React from 'react';
import Integration from '../../../services/DataModels/Integration';
import IntegrationForm from '../../IntegrationsSpecific/IntegrationForm';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../../DisplayOriented/Loading';
import SystemIDIcon from '@material-ui/icons/Code';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
	container: {
		width: "100%",
		overflowY: "scroll",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	contentContainer: {
		display: "flex",
		flexWrap: "nowrap",
		margin: "32px 20%",
	},
	leftContainer: {
		fontFamily: "Inter",
		color: "grey",
		fontSize: "12px",
		flexWrap: "nowrap",
		margin: "20px",
	},
	iconInputContainer: {
		width: "100%",
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		marginTop: "16px",
		color: "grey"
	},
	infoText: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		marginLeft: "6px",
	},
	info: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	imageContainer: {
		display: "flex",
		marginRight: "12px"
	},
	image: {
		height: "200px",
	}
});

class IntegrationDetailTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			integrationTypes: null,
			integrationOptions: null,
			dataModel: null,
		};
		this.prepare_integration();
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	refresh = () => {
		this.setState({
			integrationTypes: null,
			integrationOptions: null,
			dataModel: null
		});
		new Integration({_id: this.props.data._id}).readFromAPI().then( (integration) => {
			this.props.tabHostProxy.updateTitle(integration._id, integration);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			this.prepare_integration(integration);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	prepare_integration = (integration) => {
		let dataModel = integration ? new Integration(integration) : new Integration(this.props.data);
		dataModel.loadIntegrationTypesAndOptions().then( (result) => {
			let { integrationTypes, integrationOptions } = result;
			dataModel.data = Integration.assignIntegrationLogo(dataModel.data);
			this.setState({
				integrationTypes: integrationTypes,
				integrationOptions: integrationOptions,
				dataModel: dataModel,
			});
		}).catch( (error) => {
			let message = `There was an error loading integration related data`;
			console.error(message);
			this.context.openSnackbar(message, 'error');
		});
	}

	saveIntegration = (edited_integration) => {
		let new_data_model = this.state.dataModel;
		new_data_model
			.setData(edited_integration)
			.createOrSave()
			.then( (result) => {
				result = Integration.assignIntegrationLogo(result);
				new_data_model.setData(result);
				this.setState({ dataModel: new_data_model }, () => {
					this.props.tabHostProxy.closeSelf();
					this.props.tabHostProxy.addTab("integration", result);
					this.props.tabHostProxy.refresh();
				});
			})
			.catch( (error) => {
				let message = `There was an error saving this integration: ${error}`;
				this.context.openSnackbar(message, 'error');
			});
	}

	deleteIntegration = () => {
		let _id = this.state.dataModel.data._id;
		this.state.dataModel.deleteFromAPI().then( (result) => {
			this.props.tabHostProxy.refresh();
			this.props.tabHostProxy.closeSelf(_id);
		}).catch( (error) => {
			let message = `There was an error deleting this integration: ${error}`;
			this.context.openSnackbar(message, 'error');
		});
	}

	render() {
		const { classes, tabHostProxy } = this.props;
		const { integrationTypes, integrationOptions, dataModel } = this.state;
		let default_photo = require("../../../images/ms_icon_new.png");
		let logo = dataModel && dataModel.data && dataModel.data.logo ? require("../../../images/" + dataModel.data.logo) : default_photo;
		return (
			<div className={classes.container}>
				{ dataModel === null || integrationTypes === null || integrationOptions === null ?
					<Loading />
					:
					<div className={classes.contentContainer}>
						{ !dataModel.isNew() ? 
							<div className={classes.leftContainer}>
								<div className={classes.imageContainer}>
									<img className={classes.image} src={logo} />
								</div>
								<div className={classes.iconInputContainer}>
									<Tooltip key="System ID" title="System ID">
										<SystemIDIcon className={classes.inputIcon} />
									</Tooltip>
									<span className={classes.infoText}>
										<span className={classes.info}>{dataModel.data._id}</span>
									</span>
								</div>
							</div>
						: ""}
						<IntegrationForm
							className={classes.formContainer}
							integrationTypes={integrationTypes}
							integrationOptions={integrationOptions}
							dataModel={dataModel}
							tabHostProxy={tabHostProxy}
							onSave={(edited_integration) => this.saveIntegration(edited_integration)}
							onDelete={this.deleteIntegration}
						/>
					</div>
				}
			</div>
		)
	}
}

IntegrationDetailTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(IntegrationDetailTab));
