import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const styles = theme => ({
	linkOverride: {
		textDecoration: 'none',
		backgroundColor: "#468ee54d",
		color: 'unset',
	},
	activeNavItem: {
		backgroundColor: "white",
		color: theme.palette.pending.main,
	},
	linkText: {
		fontFamily: "Inter, Helvetica, Arial, sans-serif",
		color: "#636366",
	},
	linkIcon: {
		color: theme.palette.pending.main,
		marginRight: 0
	},
	defaultLinkIcon: {
		color: "unset",
		marginRight: 0
	},
	listItemTextRoot: {
		fontSize: "1.1em",
		whiteSpace: "nowrap",
	},
	listItemRoot: {
		"&:focus": {
			backgroundColor: "white",
		},
		"&:hover": {
			backgroundColor: "#ebebeb",
			color: theme.palette.pending.main
		},
		paddingTop: "8px",
		paddingBottom: "8px",
	},
	listItemMin: {
		paddingLeft: "16px",
		borderRight: "solid #e3e3e3 1px"
	},
	hub: {
		marginLeft: "-12px"
	},
	badgeNumber: {
		color: "white",
		backgroundColor: theme.palette.pending.main,
		borderRadius: "50%",
		width: "24px",
		height: "24px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: "14px"
	}
});

class NavItem extends React.Component {
	
	render() {
		
		const {
			data: {
				display
			},
			icon, classes, theme, hidden, number
		} = this.props;
		const IconTag = icon;
		const url = "/" + display.replace(/ /g, "_");
		const isHub = this.props.hub;
		return (
			<div onClick={this.props.onClick}>
			{ !hidden && <Route path={url} exact={false} children={({ match }) => (
				 <div className={(match ? classes.activeNavItem + " " + classes.linkText: classes.linkText) + " " + (isHub ? classes.hub : "")}>
					<Link to={isHub ? "Hub" : url} className={match ? classes.activeNavItem + " " + classes.linkOverride: classes.linkOverride}>
						<ListItem className={classes.listItemRoot + " " + (this.props.min ? classes.listItemMin : "")} button >
							<ListItemIcon className={match ? classes.linkIcon: classes.defaultLinkIcon}>
								<IconTag />
							</ListItemIcon>
							<ListItemText classes={{root: classes.listItemTextRoot}} disableTypography={true} primary={display} />
							{number ? <div className={classes.badgeNumber}>
								{number}
							</div> : ""}
						</ListItem>
					</Link>
				</div>
			)} />}
		</div>
		);
	}
}

NavItem.propTypes = {
	classes: PropTypes.object.isRequired,
};

NavItem.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(NavItem)
