import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TextInput from '../../Inputs/TextInput';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { darken } from '@material-ui/core/styles/colorManipulator';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';

const styles = theme => ({
    contentCard: {
        padding: "12px",
        width: "100%"
    },
    contentHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "-12px -12px 12px -12px",
        padding: "0 12px",
        borderBottom: "solid lightgrey 1px",
    },
    contentTitle: {
        fontSize: "16px"
    },
    addButtonContainer: {
        padding: "12px 0",
        textAlign: "right"
    },
    errorText: {
        color: theme.palette.red.main
    },
    modalButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "24px 0 0 auto"
    },
    editButton: {
        marginLeft: "8px",
    },
    buttonIcon: {
        marginRight: "8px"
    },
    modalWrapper: {
        fontFamily: "Inter",
		minHeight: "450px",
		maxHeight: "615px",
        boxSizing: "border-box"
    },
    modalTitle: {
        fontSize: "20px",
        lineHeight: "32px",
        fontWeight: "700",
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom: "32px",
    },
    switchItems: {
        height: "calc(80vh - 296px)",
        overflow: "auto",
        paddingRight: "16px",
    },
    keyPairContainer: {
        display: "flex",
        borderBottom: "solid lightgrey 1px",
        paddingTop: "6px",
        marginBottom: "18px",
        alignItems: "center"
    },
    keyPairItem: {
        width: "40%",
        marginRight: "8px"
    },
    deleteIconButton: {
        padding: "4px",
    },
    keyPairDelete: {
        marginTop: "-24px",
        "&:hover": {
            color: "#0263fc",
        }
    },
    capabilityLabel: {
        marginRight: "8px",
        fontSize: "14px",
    },
    capabilityPair: {
        margin: "12px"
    },
    connectionContainer: {
        borderBottom: "solid lightgrey 1px"
    },
    value: {
        color: "#8e8e93",
        fontSize: "14px",
    },
    noMetadata: {
        fontSize: "14px",
        color: "#8e8e93",
    },
});

class MetadataEditor extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
			editable_metadata: [],
			modal: {
				open: false,
				children: () => ""
			},
		};
    }

	close_modal = () => {
		this.setState({show_errors: false, modal: {open: false, children: () => ""}});
	}

    edit_metadata = () => {
        const classes = this.props.classes;
        const metadata = Boolean(this.props.model.metadata) ? Object.entries(this.props.model.metadata).map(([key, value]) => ({ key: key, value: value })) : [{ key: "", value: "" }];
        const update_metadata = (field, val, index) => {
            this.setState((state) => {
                state.editable_metadata[index][field] = val;
                return state;
            });
        }
        const remove_key_pair = (index) => {
            this.setState((state) => {
                state.editable_metadata.splice(index, 1);
                return state;
            });
        }
        const add_key_pair = () => {
            this.setState((state) => {
                state.editable_metadata.push({ key: "", value: "" });
                return state;
            });
        }
        this.setState({
            editable_metadata: metadata,
            modal: {
                open: true,
                children: () =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Editing Metadata
						</div>
                        <div className={classes.switchItems}>
                            {metadata.map(({ key, value }, index) => {
                                    return (
                                        <div className={classes.keyPairContainer} key={"pair_" + index}>
                                            <div className={classes.keyPairItem}>
                                                <TextInput
                                                    key={"key_" + index}
                                                    label="Key*"
                                                    emitChange={({ field, value }) => update_metadata(field, value, index)}
                                                    priorState={key}
                                                    field="key"
                                                    disabled={typeof (value) != 'string'}
                                                />
                                            </div>
                                            <div className={classes.keyPairItem}>
                                                <TextInput
                                                    key={"value_" + index}
                                                    label="Value*"
                                                    emitChange={({ field, value }) => update_metadata(field, value, index)}
                                                    priorState={value}
                                                    field="value"
                                                    disabled={typeof (value) != 'string'}
                                                />
                                            </div>
                                            <IconButton
                                                onClick={() => remove_key_pair(index)}
                                                style={{ padding: "4px" }}
                                                className={classes.deleteIconButton + " " + classes.keyPairDelete}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>)}
                            )}
                            <div >
                                <Button onClick={add_key_pair} disabled={!this.props.can_edit} color="primary">
                                    Add Key Pair
                                </Button>
                            </div>
                            {this.state.show_errors ? <div className={classes.errorText}>
                                Please ensure no keys are the same and that none of the inputs are empty.
                            </div> : ""}
                        </div>
                        {this.render_modal_buttons(this.submit_edit_metadata)}
                    </div>
            }
        });
    }

    render_modal_buttons = (onClick) => {
        const classes = this.props.classes;
        return (
            <div className={classes.modalButtonContainer}>
                <Button onClick={this.close_modal} aria-label="cancel" color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={onClick}
                    className={classes.editButton}
                    aria-label="create"
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Save Changes
                </Button>
            </div>
        )
    }

    submit_edit_metadata = () => {
        let map = {};
        let keys_valid = true;
        let no_empties = true;
        this.state.editable_metadata.forEach(({ key, value }) => {
            if (undefined !== map[key]) {
                keys_valid = false;
            }
            map[key] = value;
            if (value === "") {
                no_empties = false;
            }
        });
        if (!keys_valid || !no_empties) {
            this.setState({ show_errors: true });
            return;
        }
        this.close_modal();
        if (this.state.editable_metadata.length === 0) {
            map = null;
        }
        const new_model = { ...this.props.model, metadata: map };
        this.props.submitMetadata(new_model);
    }

    render() {
        const { model, classes } = this.props;
        const none = !Boolean(model.metadata);
        let metadata = null;
        if (!none) {
            metadata = Object.entries(model.metadata).map(([key, value]) => ({ key: key, value: value }));
        }
        return (<React.Fragment>
            <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                {this.state.modal.children(classes)}
            </SimpleModalWrapped>
            <Card className={classes.contentCard}>
                <div className={classes.contentHeader}>
                    <div className={classes.contentTitle}>
                        Metadata
                </div>
                    <div className={classes.addButtonContainer}>
                        <Button onClick={this.edit_metadata} disabled={!this.props.can_edit} variant="contained" color="primary">
                            <BuildIcon className={classes.buttonIcon} />
                            Edit Metadata
                        </Button>
                    </div>
                </div>
                {none ? <span className={classes.noMetadata}>{"No Metadata Defined."}</span> : metadata.map(({ key, value }) => {
                        return (
                            <div key={key} className={classes.connectionContainer}>
                                <div className={classes.capabilityPair}>
                                    <span className={classes.capabilityLabel}>Key: </span>
                                    <span className={classes.value}>{key}</span>
                                </div>
                                <div className={classes.capabilityPair}>
                                    <span className={classes.capabilityLabel}>Value: </span>
                                    <span className={classes.value}>{JSON.stringify(value)}</span>
                                </div>
                            </div>
                        )
                })}
            </Card>
        </React.Fragment>)
    }
}

export default withStyles(styles)(withTheme()(MetadataEditor));