/**
 * Gets useful indexes to access data or set it for the MIB and notifications nested objects structure
 * @param {string} index is a string as it can be one digit or multiple with dots between them
 * @return {{
 *  objectIndex: string,
 *  objectsArrayKey: string,
 *  objectsArrayIndex: string,
 *  parentObjectsArrayIndex: string,
 *  objectRealIndex: number,
 * }} Object with useful indexes
 * 
 */
export const get_nested_indexes = (index) => {
  // First check if it is a single or layered index
  const indexLevels = index?.split('.');

  // objectsArrayKey => This value will be the key of the objects in the level of the index
  // This key was changed from just "objects" as it was creating problems with the _set lodash function
  // so no two "objects" keys in "mib" will be found
  // Example: if index is 0.1 => objectsArrayKey will be "objects_0_1"
  let objectsArrayKey = 'objects_';

  // objectsArrayIndex => This value will be the access string to the objects key inside "mib.objects" or "notifications" depending on the index
  // Example: if index is 0.1 => objectsArrayIndex should access the objects array of the object
  // on index 1 of the object on index 0 from the root => 0.objects_0.1.objects_0_1
  let objectsArrayIndex = '';

  // objectIndex => This value will be the access string to the object key inside "mib.objects" or "notifications"depending on the index
  // Example: if index is 0.1 => objectIndex should access the object
  // on index 1 of the object on index 0 from the root => 0.objects_0.1
  let objectIndex = '';

  // parentObjectsArrayIndex => This value will be the key of the array that contains the object of the index
  // Example: if index is 0.1 => parentObjectsArrayIndex should access the objects array
  // of the object on index 0 from the root => 0.objects_0
  let parentObjectsArrayIndex = '';

  if (indexLevels?.length !== 1) {
    let accLevel = '';
    indexLevels.forEach((level, i) => {
      parentObjectsArrayIndex = objectIndex;
      if (i !== 0) {
        accLevel += '_';
        objectIndex += '.';
      }
      accLevel += `${level}`;
      if (i === indexLevels.length - 1) {
        objectIndex += `${level}`;
        objectsArrayIndex = `${objectIndex}.objects_${accLevel}`;
      } else {
        objectIndex += `${level}.objects_${accLevel}`;
      }
    });
    objectsArrayKey += indexLevels.join('_');
  } else {
    objectIndex = index;
    parentObjectsArrayIndex = index;
    objectsArrayKey += index;
    objectsArrayIndex = `${index}.${objectsArrayKey}`;
  }

  return {
    objectIndex,
    objectsArrayKey,
    objectsArrayIndex,
    parentObjectsArrayIndex,
    objectRealIndex: parseInt(indexLevels[indexLevels.length - 1]),
  };
};

/**
 * After removing an object the array to which it belonged should be reordered.
 * And so the indexing and the names of the keys of the objects arrays.
 * @param {Array} objectsArray 
 * @param {String} index 
 * @param {String} oldIndex 
 */
export const re_index_objects_array = (objectsArray, index, oldIndex) => {
  objectsArray.forEach((childObject, i) => {
    let subObjectsIndex = null;
    let subObjectsOldIndex = null;
    let subObjectsArrayKey = null;
    if (typeof index === 'undefined') {
      subObjectsIndex = i;
      if (childObject[`objects_${i}`]) {
        subObjectsArrayKey = `objects_${i}`;
      } else {
        subObjectsOldIndex = i + 1;
        subObjectsArrayKey = `objects_${i + 1}`;
      }
    } else {
      subObjectsIndex = `${index}_${i}`;
      if (childObject[`objects_${subObjectsIndex}`]) {
        subObjectsArrayKey = `objects_${subObjectsIndex}`;
      } else if (childObject[`objects_${index}_${i + 1}`]) {
        subObjectsOldIndex = `${index}_${i + 1}`;
        subObjectsArrayKey = `objects_${subObjectsOldIndex}`;
      } else if (oldIndex && (childObject[`objects_${oldIndex}_${i}`])) {
        subObjectsOldIndex = `${oldIndex}_${i}`;
        subObjectsArrayKey = `objects_${subObjectsOldIndex}`;
      } else if (oldIndex && (childObject[`objects_${oldIndex}_${i + 1}`])) {
        subObjectsOldIndex = `${oldIndex}_${i + 1}`;
        subObjectsArrayKey = `objects_${subObjectsOldIndex}`;
      }
    }
    if (childObject[subObjectsArrayKey].length !== 0) {
      re_index_objects_array(
        childObject[subObjectsArrayKey],
        subObjectsIndex,
        subObjectsOldIndex
      );
    }

    if (subObjectsOldIndex) {
      childObject[`objects_${subObjectsIndex}`] = childObject[subObjectsArrayKey];
      delete childObject[subObjectsArrayKey];
    }
  });
};

/**
 * Converts an array with objects with the similar key "objects" to the "objects_<index>_<subIndex>_..." structure
 * So it is possible to use the _set and _get functions of lodash with these arrays
 * @param {Array} objectsArray 
 * @param {String} index 
 */
export const convert_objects_indexes_for_ui = (objectsArray, index) => {
  objectsArray.forEach((childObject, i) => {
    let subObjectsArrayKey = '';
    let newIndex = '';
    if (typeof index === 'undefined') {
      newIndex = i;
    } else {
      newIndex = `${index}_${i}`;
    }
    subObjectsArrayKey = `objects_${newIndex}`;
    if (childObject['objects']) {
      convert_objects_indexes_for_ui(childObject['objects'], newIndex);
      childObject[subObjectsArrayKey] = childObject['objects'];
      delete childObject['objects'];
    } else if (!childObject[subObjectsArrayKey]) {
      childObject[subObjectsArrayKey] = [];
    }
  });
};

/**
 * Converts an array with objects with the "objects_<index>_<subIndex>_..." structure to the similar key "objects"
 * So it is possible to send the data as expected to the API
 * @param {Array} objectsArray 
 * @param {String} index 
 */
export const convert_objects_indexes_for_save = (objectsArray, index) => {
  objectsArray.forEach((childObject, i) => {
    let subObjectsArrayKey = null;
    let newIndex = '';
    if (typeof index === 'undefined') {
      newIndex = i;
    } else {
      newIndex = `${index}_${i}`;
    }
    subObjectsArrayKey = `objects_${newIndex}`;
    if (childObject[subObjectsArrayKey] && childObject[subObjectsArrayKey].length !== 0) {
      convert_objects_indexes_for_save(childObject[subObjectsArrayKey], newIndex);
      childObject['objects'] = childObject[subObjectsArrayKey];
    }
    delete childObject[subObjectsArrayKey];
  });
};

/**
 * If all children are enabled then the "Disable all children" checkbox will appear the the checkbox itself will be checked
 * indicating that all children are enabled. If only one is disabled then it is not enabled
 * @param {Array} objects
 * @param {String} index 
 */
export const get_children_objects_state = (objects, index) => {
  let enabled = true;
  for (let i = 0; i < objects.length; i++) {
    const subObject = objects[i];
    const newIndex = `${index}.${i}`;
    const { objectsArrayKey } = get_nested_indexes(newIndex);
    if (!subObject.enabled ||
      !get_children_objects_state(subObject[objectsArrayKey], newIndex)) {
      enabled = false;
      break;
    }
  }
  return enabled;
};

/**
 * If only one child child is enabled then the "Enable all children" checkbox will appear the the checkbox itself will be half checked
 * indicating that not all children are enabled but at least one is. If only one is enabled then it is half-enabled
 * Separated from the other function to simplify the logic and the code.
 * @param {Array} objects
 * @param {String} index 
 */
export const get_children_objects_half_state = (objects, index) => {
  let enabled = false;
  for (let i = 0; i < objects.length; i++) {
    const subObject = objects[i];
    const newIndex = `${index}.${i}`;
    const { objectsArrayKey } = get_nested_indexes(newIndex);
    if (subObject.enabled ||
      get_children_objects_half_state(subObject[objectsArrayKey], newIndex)) {
      enabled = true;
      break;
    }
  }
  return enabled;
};

/**
 * Change the "enabled" value the opposite of the "Disable/Enable all children" value. If not all are enabled it will enable all of them.
 * If all are enabled it will disable all of them.
 * @param {Array} objects
 * @param {String} index 
 * @param {String} previousState
 */
export const toggle_children_objects_state = (objects, index, previousState) => {
  for (let i = 0; i < objects.length; i++) {
    objects[i].enabled = !previousState;
    const newIndex = `${index}.${i}`;
    const { objectsArrayKey } = get_nested_indexes(newIndex);
    toggle_children_objects_state(objects[i][objectsArrayKey], newIndex, previousState);
  }
};
