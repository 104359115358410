import React, { Fragment } from 'react';
import moment from 'moment';
import Device from '../../services/DataModels/Device';
import SelectInput from '../Inputs/SelectInput';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Tooltip from '@material-ui/core/Tooltip';
import DeviceAlerts from '../AlertSpecific/DeviceAlerts';
import DeviceReports from './DeviceReports';
import DeviceSoftware from './DeviceSoftware';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import DeviceOrchestration from './DeviceOrchestration';
import LookupInput from '../Inputs/LookupInput';
import DateSelection from '../Inputs/DateSelection';
import SwitchInput from "../Inputs/SwitchInput";
import DeviceGatewayCommands from './DeviceGatewayCommands';
import DeviceCommands from './DeviceCommands';
import DeviceGroup from './DeviceGroup';
import MessagesErrors from '../MessagesSpecific/MessagesErrors';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import EditDevice from './EditDevice';
import AddConfig from './AddConfig';
import AddIntegration from './AddIntegration';
import Auth from '../../services/Auth';
import DeviceLogs from './DeviceLogs';
import TableList from '../Table/TableList';
import Permissions from '../../services/Permissions';
import DeviceIngestors from './DeviceIngestors';
import DeviceAttachedDevices from './DeviceAttachedDevices';
import DeviceMetadata from './DeviceMetadata';
import DeviceCellUsage from '../DeviceSpecific/DeviceCellUsage';
import DeviceCreationFlow from '../DeviceSpecific/DeviceCreationFlow';
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'

//icons
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponentOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import StyleIcon from '@material-ui/icons/Style';
import CloudIcon from '@material-ui/icons/Cloud';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SyncIcon from '@material-ui/icons/Sync';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';
import EditIcon from '@material-ui/icons/Edit';
import SnoozeIcon from '@material-ui/icons/Snooze';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import RouterIcon from '@material-ui/icons/Router';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BuildIcon from '@material-ui/icons/Build';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SystemIDIcon from '@material-ui/icons/Code';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import ReportProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import GroupWorkIcon from '@material-ui/icons/GroupWorkOutlined';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ErrorIcon from '@material-ui/icons/Error';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import DescriptionIcon from '@material-ui/icons/Description';
import DeviceOverview from './DeviceOverview';
import PermDeviceInformation from '@material-ui/icons/PermDeviceInformation';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import HealthStatusIcon from '@material-ui/icons/LocalHospital';
import PollIcon from '@material-ui/icons/PollOutlined';
import { ArrowDownward } from '@material-ui/icons';

const styles = theme => ({
    container: {
        height: "100%",
        padding: "12px 0 0 12px",
        boxSizing: "border-box",
        display: "flex"
    },
    scrollerOverride: {
        overflowX: "auto"
    },
    leftArea: {
        width: "25%",
        paddingRight: "12px",
        height: "100%",
        boxSizing: "border-box",
    },
    rightArea: {
        width: "75%"
    },
    configPending: {
        backgroundColor: theme.palette.pending.main,
        color: "white",
        fontSize: "18px",
        padding: 0
    },
    configValid: {
        backgroundColor: theme.palette.green.main,
        color: "white",
        fontSize: "18px",
        padding: 0
    },
    configError: {
        backgroundColor: theme.palette.red.main,
        color: "white",
        fontSize: "18px",
        padding: 0
    },
    heartbeatIdle: {
        backgroundColor: theme.palette.heartbeat.idle.main,
        color: "white",
        fontSize: "18px",
        padding: 0
    },
    titleContainer: {
        display: "flex",
        marginTop: "16px",
    },
    title: {
        fontSize: "22px",
        marginLeft: "8px",
        zIndex: 1
    },
    editButton: {
        width: "25%"
    },
    commandButton: {
        marginLeft: "12px",
        width: "35%"
    },
    sendConfigButton: {
        marginLeft: "12px",
        width: "35%"
    },
    uniqueIDContainer: {
        display: "flex",
        alignItems: "center",
        color: "grey",
        margin: "10px 0"
    },
    labelOverride: {
        paddingTop: 0
    },
    buttonIcon: {
        marginRight: "8px",
        display: "flex",
        [theme.breakpoints.down(1500)]: {
            marginRight: 0
        }
    },
    buttonText: {
        [theme.breakpoints.down(1500)]: {
            display: "none"
        }
    },
    wrapButtonText: {
        fontSize: "14px",
        lineHeight: "110%",
        [theme.breakpoints.down(1500)]: {
            display: "none"
        }
    },
    statusContainer: {
        textAlign: "center",
        padding: "0 8px",
    },
    scheduleContainer: {
        marginBottom: "8px",
        marginTop: "16px",
        color: "grey",
    },
    modalWrapper: {
        minHeight: "457px",
    },
    scheduleText: {
        fontFamily: "Inter",
        fontSize: "18px",
    },
    actionContainer: {
        flex: "50%",
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        display: "flex",
        justifyContent: "flex-end"
    },
    unset: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
    },
    splitFab: {
        maxHeight: "40px"
    },
    fabIcon: {
        fontSize: "22px",
        marginRight: "4px"
    },
    devicePlaceholderImage: {
        height: "100px",
        width: "100%",
        color: theme.palette.primary.main
    },
    actionFab: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: `solid ${darken(theme.palette.primary.main, .2)} 2px`,
        boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)"
    },
    editFab: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)",
    },
    middleFab: {
        borderRadius: 0,
        boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)",
        borderLeft: `solid ${darken(theme.palette.primary.main, .2)} 2px`,
    },
    statusLabel: {
        fontSize: "12px",
        marginTop: "-8px",
        display: "block",
        color: theme.palette.greyIcon.main,
    },
    deviceButtonContainer: {
        marginTop: "24px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "center"
    },
    statusIcon: {
        fontSize: "20px",
        padding: 0
    },
    heartbeatIconsContainer: {
        position: "relative",
        color: theme.palette.greyIcon.main,
        display: "flex"
    },
    overlayIcon: {
        position: "absolute",
        left: 0,
        color: "white"
    },
    label: {
        fontFamily: "Inter",
        fontSize: "18px",
    },
    ggImg: {
        marginRight: "8px",
        height: "18px",
        width: "18px"
    },
    configNA: {
        color: "white",
        backgroundColor: "grey",
        fontSize: "18px",
        padding: 0
    },
    iconInputContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        marginTop: "16px",
        color: "grey"
    },
    tagsWrapper: {
        flexWrap: "wrap",
        borderTop: "solid lightgrey 1px",
        paddingTop: "12px"
    },
    tagData: {
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    tagLookupContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
    addTagButton: {
        cursor: "pointer"
    },
    tagsIcon: {
        paddingTop: "4px"
    },
    inputIcon: {
        color: "grey",
        fontSize: "22px",
        marginRight: "8px",
    },
    leftColumn: {
        height: "100%",
        marginLeft: "20px",
        marginRight: "12px",
    },
    topHalfLeftColumn: {
    },
    bottomHalfLeftColumn: {
        height: "calc(100% - 275px)",
        marginTop: "16px",
        overflowX: "hidden",
        overflowY: "auto",
        paddingRight: "12px",
        marginRight: "-12px",
        flexWrap: "wrap",
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            backgroundColor: "#5555552b"
        }
    },
    deviceImageContainer: {
        border: "solid lightgrey 1px",
        width: "100%",
        marginBottom: "4px",
        alignItems: "center",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box"
    },
    deviceType: {
        backgroundColor: "white",
        margin: "8px",
        textAlign: "center",
        color: "grey",
        width: "100%"
    },
    chipRoot: {
        margin: "0 4px 4px 0"
    },
    deviceImage: {
        maxWidth: "100%",
        maxHeight: "100px",
        margin: "auto",
        display: "block",
    },
    deviceTypeImage: {
        maxHeight: "100px",
        maxWidth: "100%"
    },
    tabsContainer: {
        zIndex: 1,
        overflowX: "scroll",
        display: "flex",
        width: "100%",
        height: "unset",
        position: "relative",
        boxShadow: "unset",
        borderBottom: "1px solid lightgrey",
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar": {
            width: "12px",
            height: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            backgroundColor: "#5555552b"
        }
    },
    viewTabs: {
        overflow: "unset",
    },
    tabContent: {
        height: "calc(100% - 88px)",
        width: "100%",
        padding: "12px",
        backgroundColor: "#efefef",
    },
    integrationPreview: {
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    integrationLogo: {
        width: "30px",
        marginRight: "8px",
    },
    prompt: {
        fontFamily: "Inter",
        fontSize: "20px",
        lineHeight: "32px",
        fontWeight: "700",
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom: "32px",
    },
    smallerPrompt: {
        fontFamily: "Inter",
        fontSize: "18px",
        color: "grey",
        display: "flex",
        flexWrap: "wrap",
        width: "48%",
    },
    rightPrompt: {
        borderLeft: "solid lightgrey 2px",
        paddingLeft: "8px"
    },
    leftPrompt: {
        marginRight: "8px",
    },
    attachError: {
        fontSize: "16px",
        color: "black",
        fontStyle: "italic",
        marginTop: "6px"
    },
    splitButton: {
        margin: "24px auto 0 auto",
        display: "block",
        alignSelf: "flex-end",
        display: "flex",
    },
    splitModal: {
        display: "flex",
        flexWrap: "nowrap",
    },
    orCreate: {
        margin: "12px auto 220px auto",
        textAlign: "center",
    },
    attachButtons: {
        textAlign: "center"
    },
    buttonContainer: {
        marginTop: "32px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    actionButton: {
        marginLeft: "8px",
    },
    actionInfoContainer: {
        marginTop: "8px",
    },
    ggText: {

    },
    uidText: {
        flexWrap: "wrap",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    infoText: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    info: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "90%",
    },
    actionInfo: {
        fontFamily: "Inter",
        color: "#8e8e93",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "14px",
    },
    infoIcon: {
        fontSize: "28px",
        marginRight: "8px",
    },
    actionError: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        marginTop: "8px"
    },
    errorIcon: {
        color: "red",
        marginRight: "8px",
        fontSize: "28px"
    },
    idIcon: {
        color: "white",
        backgroundColor: "grey",
        borderRadius: "4px",
        width: "18px",
        height: "18px",
        fontSize: "12px",
        marginLeft: "3px",
        marginRight: "8px",
        display: "flex",
    },
    idIconText: {
        margin: "auto",
    },
    link: {
        maxWidth: "90%",
        display: "block",
        textAlign: "start",
        padding: 0,
    },
    labelButton: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    deviceInfoContainer: {
        width: "100%",
    },
    leftColumnLink: {
        marginTop: "12px",
    },
    notShown: {
        display: "none",
    },
    shown: {
        display: "flex",
        width: "100%",
    },
    tile: {
        width: "100%",
        height: "calc(100% - 85px)",
        overflow: "auto",
        padding: "12px",
        boxSizing: "border-box",
        backgroundColor: "#f5f5f7",
    },
    deleteButton: {
        backgroundColor: theme.palette.red.main,
        color: "white",
        marginRight: "8px",
        "&:hover": {
            backgroundColor: darken(theme.palette.red.main, .2),
        }
    },
    heartBeatstatusDot: {
        width: "12px",
        height: "12px",
        minWidth: "12px",
        minHeight: "12px",
        maxWidth: "12px",
        maxHeight: "12px",
        borderRadius: "50%",
        display: "inline-flex",
        marginTop: "8px",
        color: 'white'
    },
    inputWrapper: {
        height: "319px",
        overflowY: "auto",
        overflowX: "hidden",
    }
});

const SCHEDULE_REPEAT_OPTIONS = [
    { display: "Daily", value: "daily" },
    { display: "Weekly", value: "weekly" },
]

class DeviceView extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            editable_device: this.props.dataModel.editableCopy(),
            value: 0,
            command_error: null,
            tag_input: "",
            modal: {
                open: false,
                children: () => ""
            },
            action: {
                display: "",
                value: "",
                package: ""
            },
            snackbar: {
                open: false
            },
            attached: null,
            parent_options: null,
            child_options: null
        };
        this.actionOptions = [
            { display: "Select Action", value: "" },
            { display: "Delete Device", value: "delete" },
            { display: "Activate Cloud Native Device", value: "activate_cloud_native_device" },
            { display: "Deactivate Cloud Native Device", value: "deactivate_cloud_native_device" },
            { display: "Disable Data Usage", value: "enable_data_restriction" },
            { display: "Enable Data Usage", value: "disable_data_restriction" },
            { display: "Initiate Log Upload", value: "log_upload" },
            { display: "Reboot", value: "reboot" },
            { display: "Reintialize Greengrass", value: "greengrass_initialize" },
            { display: "Redeploy Greengrass", value: "greengrass_redeploy" },
            { display: "Restart Greengrass", value: "greengrass_restart" },
            { display: "Reset to Factory Default", value: "reset" },
            { display: "Trigger Heartbeat", value: "heartbeat" },
            { display: "Trigger Status", value: "status" },
            { display: "Update Config", value: "send_config" },
            { display: "Update Software", value: "software_update" },
        ];
    }

    softwareUpdatesByID = () => {
        const device = this.props.dataModel.editableCopy();
        if (device.nested_device_type.nested_software_updates) {
            return device.nested_device_type.nested_software_updates.reduce((accumulator, sw) => {
                accumulator[sw._id] = sw;
                return accumulator;
            }, {});
        } else {
            return {};
        }
    }

    has_greengrass = () => {
        const device = this.props.dataModel.editableCopy();
        return device.nested_integrations && device.nested_integrations.find((int) => int.greengrass_core_install_url);
    }

    can_perform_action = () => {
        const device = this.props.dataModel.editableCopy();
        return Permissions.allow_one_of(["send_config", "log_upload", "heartbeat", "status", "reboot", "update", "delete", "reset", "delete", "greengrass_initialize", "enable_data_restriction", "disable_data_restriction", "greengrass_restart", "greengrass_redeploy"], "device", device.company_id);
    }

    build_tabs = () => {
        this.tabs = [];
        const device = this.props.dataModel.editableCopy();
        const deviceType = device.nested_device_type;
        this.tabRenderFunctions = [];
        let company_id = device.company_id;
        if (Permissions.allow(["read"], "gateway_command", company_id) || Permissions.allow(["read"], "report", company_id) || Permissions.allow(["read"], "notification", company_id)) {
            this.tabs.push({ title: "Overview", icon: (<ViewModuleIcon />) });
            this.tabRenderFunctions.push(() => <DeviceOverview device={device} tabHostProxy={this.props.tabHostProxy} softwareUpdatesByID={this.softwareUpdatesByID()} openSoftwareUpdateTab={this.openSoftwareUpdateTab} />);
        }
        if (device.device_ha_group_id === null || (device.device_ha_group_id && device.nested_device_ha_group && Permissions.allow(["read"], "device_ha_group", company_id))) {
            this.tabs.push({ title: "Group", icon: (<GroupWorkIcon />) });
            this.tabRenderFunctions.push(() => <DeviceGroup attachtoParent={this.attachtoParent} device={device} tabHostProxy={this.props.tabHostProxy} device_id={device._id} />);
        }
        this.tabs.push({ title: "Metadata", icon: (<PictureInPictureAltIcon />) });
        this.tabRenderFunctions.push(() => <DeviceMetadata device={device} tabHostProxy={this.props.tabHostProxy} device_id={device._id} />);
        if (deviceType.type == "gateway") {
            this.tabs.push({ title: "Endpoints", icon: (<DvrIcon />) });
            this.tabRenderFunctions.push(() => <DeviceAttachedDevices device={device} tabHostProxy={this.props.tabHostProxy} />);
        }
        if (Permissions.allow(["read"], "ingestor", company_id)) {
            this.tabs.push({ title: "Ingestors", icon: (<SettingsInputComponentIcon />) });
            this.tabRenderFunctions.push(() => <DeviceIngestors device={device} tabHostProxy={this.props.tabHostProxy} />);
        }
        if (Permissions.allow(["read"], "command", company_id)) {
            this.tabs.push({ title: "Commands", icon: (<ArrowDownwardIcon />) });
            this.tabRenderFunctions.push(() => <DeviceCommands device={device} tabHostProxy={this.props.tabHostProxy} />);
        }
        if (Permissions.allow(["read"], "notification", company_id)) {
            this.tabs.push({ title: "Alerts", icon: (<ReportProblemIcon />) });
            this.tabRenderFunctions.push(() => <DeviceAlerts device={device} tabHostProxy={this.props.tabHostProxy} device_id={device._id} />);
        }
        if (Permissions.allow(["read"], "device_error", company_id)) {
            this.tabs.push({ title: "Errors", icon: (<WarningIcon />) });
            this.tabRenderFunctions.push(() => <MessagesErrors device_id={device._id} />);
        }
        if (Permissions.allow(["read"], "report", company_id)) {
            this.tabs.push({ title: "Reports", icon: (<PollIcon />) });
            this.tabRenderFunctions.push(() => <DeviceReports device_id={device._id} />);
        }
        if (Permissions.allow(["read"], "gateway_command", company_id)) {
            this.tabs.push({ title: "Gateway Commands", icon: (<SyncProblemIcon />) });
            this.tabRenderFunctions.push(() => <DeviceGatewayCommands
                gateway_id={this.getGatewayCommandId()}
                device_id={device._id}
                device={device}
                device_unique_id={device.unique_id}
            />);
        }
        if (Permissions.allow(["read"], "rule", company_id)) {
            this.tabs.push({ title: "Policies", icon: (<SecurityIcon />) });
            this.tabRenderFunctions.push(() => <DeviceOrchestration tabHostProxy={this.props.tabHostProxy} device={device} />);
        }
        if (Permissions.allow(["read"], "device", company_id)) {
            this.tabs.push({ title: "Logs", icon: (<CloudDownloadIcon />) });
            this.tabRenderFunctions.push(() => <DeviceLogs
                device={device} onSave={this.onSave}
            />);
        }
        this.tabs.push({ title: "Data Usage", icon: (<DataUsageIcon />) });
        this.tabRenderFunctions.push(() => <DeviceCellUsage device={device} device_type={deviceType} />);
    }

    getGatewayCommandId = () => {
        let device_id = null;
        const device = this.props.dataModel.editableCopy();
        if (device.parent_device_id && device.parent_device_id !== "") {
            device_id = device.parent_device_id;
        } else if (["gateway", "cloud_native"].includes(device.nested_device_type.type)) {
            device_id = device._id;
        }
        return device_id;
    }

    openSoftwareUpdateTab = (deviceType) => {
        this.props.tabHostProxy.addTab("softwareUpdates", deviceType);
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    performAction = (classes) => {
        this.openModal();
    };

    executeSendConfig = (classes) => {
        const device = this.props.dataModel.editableCopy();
        Device.issueGatewayCommand("send_config", undefined, device._id, undefined).then((result) => {
            this.context.openSnackbar('Command sent to device', 'success');
        }).catch((error) => {
            this.context.openSnackbar(error, 'error');
        });
    };

    openModal = () => {
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: this.renderActionModal
            }
        });
    }

    closeModal = () => {
        let newState = {
            action: {
                display: "",
                value: "",
                package: ""
            },
            modal: {
                open: false, children: () => ""
            },
            attached: null,
            parent_options: null,
            child_options: null,
            scheduleGatewayCommand: false,
            gatewayCommandSchedule: null
        };
        this.setState(newState);
    }

    closeSnackbar = () => {
        let newState = { snackbar: { open: false } };
        this.setState(newState);
    }

    changeAction = ({ value }) => {
        this.setState({
            action: {
                value: value,
                display: this.actionOptions.find((action) => action.value === value).display,
                package: ""
            }
        });
    }

    packageChange = (input) => {
        this.setState({
            action: {
                value: this.state.action.value,
                display: this.state.action.display,
                package: input.value
            }
        });
    }

    scheduleCommandChanged = (switchInput) => {
        if (switchInput.value) {
            let tonight = moment().endOf('day').toDate();
            let tomorrowEarlyMorning = moment(tonight).add(6, 'hours').toDate();
            this.setState({
                scheduleGatewayCommand: true,
                gatewayCommandSchedule: {
                    start_datetime: tonight,
                    end_datetime: tomorrowEarlyMorning,
                    retry_interval: "daily"
                }
            });
        } else {
            this.setState({
                scheduleGatewayCommand: undefined,
                gatewayCommandSchedule: undefined
            });
        }
    }

    handleGatewayCommandScheduleChange = (control) => {
        let field = control.field;
        let val = control.value;
        if (typeof val === 'object') {
            // it's a date
            val = val.firstDate;
        }
        let currentScheduleOptions = this.state.gatewayCommandSchedule;
        currentScheduleOptions[field] = val;
        this.setState({
            gatewayCommandSchedule: currentScheduleOptions
        });
    }

    sendAction = () => {
        this.closeModal();
        const device = this.props.dataModel.editableCopy();
        if (this.state.action.value === "delete") {
            let dataModel = new Device(device);
            dataModel.deleteFromAPI().then((result) => {
                this.context.openSnackbar('Device successfully deleted', 'success');
                this.props.tabHostProxy.closeSelf();
                this.props.tabHostProxy.refresh();
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        } else {
            Device.issueGatewayCommand(this.state.action.value, this.state.gatewayCommandSchedule, device._id, this.state.action.value === "software_update" ? this.state.action.package : undefined).then((result) => {
                this.context.openSnackbar('Command sent to device', 'success');
            }).catch((error) => {
                this.context.openSnackbar(error, 'error');
            });
        }
    }

    render_status_dot = () => {
        const classes = this.props.classes;
        const device = this.props.dataModel.editableCopy();
        const status = device.heartbeat_status;
        return (
            <HeartbeatStatus status={status} className={classes.heartBeatstatusDot} />
        );
    }

    render() {
        const { classes } = this.props;
        const { editable_device } = this.state;
        const device = this.props.dataModel.editableCopy();
        this.build_tabs();
        return (
            <div className={classes.container}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                <div className={classes.leftArea}>
                    <div className={classes.titleContainer}>
                        {this.render_status_dot()}
                        <div className={classes.title}>{device.name}</div>
                    </div>
                    {this.renderDeviceInformation(classes)}
                </div>
                <div className={classes.rightArea}>
                    {this.renderTabs(classes)}
                    {this.renderTab(this.state.value, classes)}
                </div>
            </div>
        );
    }

    openEditModal = (classes) => {
        const device = this.props.dataModel.editableCopy();
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: () => (<EditDevice device={device} closeModal={this.closeModal} onSave={this.onSave} />)
            }
        });
    }

    onSave = (new_device) => {
        this.props.tabHostProxy.closeSelf();
        this.props.tabHostProxy.addTab("device", new_device);
        this.props.tabHostProxy.refresh();
    }

    handleTagChange = (input) => {
        this.setState({ tag_input: input.value });
    }

    update_tag = (tag) => {
        this.setState({ tag_input: tag });
    }

    add_tag = () => {
        let dataModel = this.props.dataModel;
        let tag = this.state.tag_input;
        let device = dataModel.editableCopy();
        if (this.state.tag_input.value) {
            tag = this.state.tag_input.value;
        }
        if (device.tags) {
            device.tags.push(tag);
        } else {
            device.tags = [];
            device.tags.push(tag);
        }
        dataModel.setData(device).save().then((result) => {
            this.props.tabHostProxy.closeSelf();
            this.props.tabHostProxy.addTab("device", result);
            this.props.tabHostProxy.refresh();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    handleAttachedParentDeviceChange = (input) => {
        let attached = null;
        if (input.value && input.value !== "" && input.value.value) {
            attached = input.value;
        }
        this.setState({
            attached: attached,
            parent_options: input.suggestions
        });
    }

    handleAttachedChildDeviceChange = (input) => {
        let attached = null;
        if (input.value && input.value !== "" && input.value.value) {
            attached = input.value;
        }
        this.setState({
            attached: attached,
            child_options: input.suggestions
        });
    }

    attach = (toParent, parent) => {
        if (toParent) {
            let device = this.props.dataModel.editableCopy();
            device.parent_device_id = this.state.attached.whole._id;
            let dataModel = new Device(device);
            device = dataModel.editableCopy();
            dataModel.setData(device).save().then((result) => {
                this.props.tabHostProxy.closeSelf();
                this.props.tabHostProxy.addTab("device", result);
                this.props.tabHostProxy.refresh();
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        } else {
            let device = parent ? parent : this.props.dataModel.editableCopy();
            if (!device.attached_device_ids) {
                device.attached_device_ids = [];
            }
            device.attached_device_ids.push(this.state.attached.whole._id);
            let dataModel = new Device(device);
            device = dataModel.editableCopy();
            dataModel.setData(device).save().then((result) => {
                this.props.tabHostProxy.closeSelf();
                this.props.tabHostProxy.addTab("device", result);
                this.props.tabHostProxy.refresh();
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
        }
    }

    createAttach = (toParent, parent) => {
        let preset = {};
        const device = this.props.dataModel.editableCopy();
        if (toParent) {
            preset.attached_device_ids = [device._id];
            preset.company_id = device.company_id;
            preset.omit_endpoint_types = true;
            preset.omit_endpoint_types = true;
        } else {
            preset.parent_device_id = parent ? parent._id : device._id;
            preset.company_id = parent ? parent.company_id : device.company_id;
        }
        this.setState({
            modal: {
                open: true,
                children: () => <DeviceCreationFlow onCreate={this.on_device_creation} preset={preset} tabHostProxy={this.props.tabHostProxy} closeModal={this.closeModal} />
            }
        });
    }

    on_device_creation = (new_device) => {
        this.closeModal();
        this.props.tabHostProxy.refresh();
        this.props.tabHostProxy.addTab("device", new_device);
    }

    attachtoParent = (toParent, parent) => {
        const device = this.props.dataModel.editableCopy();
        let title = toParent ? `Attach this device to a ${Auth.currentCompany().aliases.gateway} device.` : "Find or create a new device to attach.";
        let child_filter = parent ? parent._id : device._id;
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: (classes) => (
                    <div className={classes.modalWrapper}>
                        <div className={classes.prompt}>
                            {title}
                        </div>
                        {toParent ?
                            <LookupInput
                                priorState={{ suggestions: this.state.parent_options, values: this.state.attached }}
                                label={`Select a ${Auth.currentCompany().aliases.gateway} Device`}
                                model="devices"
                                custom="parent"
                                single
                                field="parent"
                                emitChange={this.handleAttachedParentDeviceChange}
                            />
                            :
                            <LookupInput
                                priorState={{ suggestions: this.state.child_options, values: this.state.attached }}
                                label="Select a Device"
                                model="devices"
                                filters={{ parent_device_id: child_filter }}
                                custom="child"
                                single
                                field="child"
                                emitChange={this.handleAttachedChildDeviceChange}
                            />
                        }
                        <div className={classes.orCreate}>
                            <Button
                                variant="contained" aria-label="Edit"
                                color="primary" className={classes.button}
                                onClick={() => this.createAttach(toParent, parent)}
                            >
                                {toParent ?
                                    `OR CREATE A NEW ${Auth.currentCompany().aliases.gateway} DEVICE`
                                    :
                                    "OR CREATE A NEW ENDPOINT DEVICE"
                                }
                            </Button>
                        </div>
                        <div className={classes.attachButtons}>
                            <Button
                                aria-label="Edit"
                                color="primary" className={classes.button}
                                onClick={this.closeModal}
                            >
                                CANCEL
                            </Button>
                            <Button
                                disabled={this.state.attached === null}
                                aria-label="Edit"
                                color="primary" className={classes.button}
                                onClick={() => this.attach(toParent, parent)}
                            >
                                {toParent ?
                                    `Attach to ${Auth.currentCompany().aliases.gateway}`
                                    :
                                    "Attach Device"
                                }
                            </Button>
                        </div>
                    </div>
                )
            }
        });
    }

    capitalize = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    command_invalid_by_type = () => {
        const device = this.props.dataModel.editableCopy();
        return device.nested_device_type.capabilities.actions[this.state.action.value] === false;
    }

    command_invalid_by_role = () => {
        const device = this.props.dataModel.editableCopy();
        return !Permissions.allow([this.state.action.value], "device", device.company_id);
    }

    renderActionModal = (classes) => {
        const device = this.props.dataModel.editableCopy();
        let packages = [{ display: "Select Software Package", value: "" }];
        if (device.nested_device_type.nested_software_updates && device.nested_device_type.nested_software_updates.length > 0) {
            packages = packages.concat(device.nested_device_type.nested_software_updates.map((upd) => ({ display: upd.name, value: upd._id })));
        }
        return (
            <div className={classes.modalWrapper}>
                <div className={classes.prompt}>
                    Select a gateway command action to send to {device.name}.
                </div>
                <div className={classes.inputWrapper}>
                    <SelectInput
                        emitChange={this.changeAction}
                        priorState={this.state.action.value}
                        options={this.actionOptions.filter((action) => device.nested_device_type.capabilities.actions[action.value] || action.value === "")}
                    />
                    {this.state.action.value === "software_update" && device.nested_device_type.nested_software_updates
                        ?
                        <React.Fragment>
                            {device.nested_device_type.nested_software_updates.length > 0
                                ?
                                <React.Fragment>
                                    <SelectInput
                                        emitChange={this.packageChange}
                                        priorState={this.state.action.package}
                                        options={packages}
                                    />
                                </React.Fragment>
                                :
                                ""
                            }
                        </React.Fragment>
                        :
                        ""
                    }
                    {this.command_invalid_by_role() ?
                        <div className={classes.actionError}>
                            <ErrorIcon className={classes.errorIcon} />
                            <span className={classes.actionInfo}>
                                You lack the sufficient permissions to carry out this action on this device.
                            </span>
                        </div>
                        : ""}
                    {this.command_invalid_by_type() ?
                        <div className={classes.actionError}>
                            <ErrorIcon className={classes.errorIcon} />
                            <span className={classes.actionInfo}>
                                The action selected is not possible on this device given its type's capabilities.
                            </span>
                        </div>
                        :
                        this.renderActionInfo(classes)
                    }
                    {this.notGatewayNoParent() ?
                        <div className={classes.actionError}>
                            <ErrorIcon className={classes.errorIcon} />
                            <span className={classes.actionInfo}>
                                The device chosen is not attached to a parent {Auth.currentCompany().aliases.gateway}, and therefore cannot receive {Auth.currentCompany().aliases.gateway} commands.
                            </span>
                        </div>
                        :
                        ""
                    }
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={this.closeModal}
                    >
                        Cancel
                    </Button>
                    {this.state.action.value !== "" ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            disabled={this.command_invalid_by_type() || this.command_invalid_by_role() || (this.state.action.value === "" || (this.notGatewayNoParent()) || (this.state.action.value === "software_update" && this.state.action.package === ""))}
                            onClick={this.sendAction}
                        >
                            {this.state.action.display}
                        </Button>
                        : ""
                    }
                </div>
            </div>
        );
    }

    determine_valid_commands = (action) => {
        const device = this.props.dataModel.editableCopy();
        if (!Permissions.allow([action], "device", device.company_id)) {
            return "You have insufficient permissions to carry out this command.";
        }
        if (!device.nested_device_type.capabilities.actions[action]) {
            return "This device doesn't support this kind of command.";
        }
        return null;
    }

    get_tags = () => {
        const device = this.props.dataModel.editableCopy();
        let tags = device.nested_company.device_tags;
        if (tags && tags.length > 0) {
            tags = tags.reduce((new_tags, tag) => {
                if (device.tags && device.tags.indexOf(tag) === -1) {
                    new_tags.push(({ value: tag, label: tag }))
                }
                return new_tags;
            }, []);
        }
        if (tags && tags.length > 0) {
            return tags;
        } else {
            return [];
        }
    }

    notGatewayNoParent = () => {
        const device = this.props.dataModel.editableCopy();
        if (this.state.action.value !== "delete" && this.state.action.value !== "") {
            if (device.nested_device_type.type !== "gateway" && device.nested_device_type.type !== "cloud_native" && (device.parent_device_id === undefined || device.parent_device_id === "" ||
                device.nested_parent_device === undefined)) {
                return true;
            } else {
                return false;
            }
        }

    }

    renderActionInfo = (classes) => {
        let info = "";
        const device = this.props.dataModel.editableCopy();
        switch (this.state.action.value) {
            case "software_update":
                console.log(device.nested_device_type.nested_software_updates);
                if (device.nested_device_type.nested_software_updates && device.nested_device_type.nested_software_updates.length > 0) {
                    info = "Select a software package above to issue a software update.";
                } else {
                    info = "No software packages are available for this device's type. Go to the Software page to create software packages for the " + device.nested_device_type.name + " type.";
                }
                break;
            case "send_config":
                info = "This command will ensure that the device is up to date with the latest Edge configuration.";
                break;
            case "log_upload":
                info = "Initiate the upload of all of the device's Edge logs. Once the logs have been uploaded they'll be available to view on the device's detail page.";
                break;
            case "reboot":
                info = "Issue a command to reboot the device.";
                break;
            case "reset":
                info = "Reset the device to its factory default settings.";
                break;
            case "enable_data_restriction":
                info = "Issue a command to prevent the device from using further data.";
                break;
            case "disable_data_restriction":
                info = "Issue a command to remove data restriction.";
                break;
            case "delete":
                info = "Delete this device from the system.";
                break;
            case "heartbeat":
                info = "Trigger the device to generate a heartbeat.";
                break;
            case "status":
                info = "Trigger the device to generate a status.";
                break;
            case "greengrass_initialize":
                info = "Reinitialize AWS Greengrass on this device.";
                break;
            case "greengrass_restart":
                info = "Restart AWS Greengrass on this device.";
                break;
            case "greengrass_redeploy":
                info = "Redeploy AWS Greengrass on this device.";
                break;
        }
        if (info === "") {
            return "";
        }
        return (
            <div className={classes.actionInfoContainer}>
                <div className={classes.actionInfo}>
                    <InfoIcon className={classes.infoIcon} />
                    {info}
                </div>
            </div>
        );
    }

    open_config = () => {
        const device = this.props.dataModel.editableCopy();
        let config = device.nested_device_config;
        config.nested_device_type = device.nested_device_type;
        this.props.tabHostProxy.addTab("config", config);
    }

    render_device_type_image = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.deviceImageContainer}>
                {device.nested_device_type.image === "" ?
                    <div className={classes.deviceImage}>
                        <DvrIcon className={classes.devicePlaceholderImage} />
                    </div>
                    :
                    <div className={classes.deviceImage}>
                        <img className={classes.deviceTypeImage} src={device.nested_device_type.image} />
                    </div>
                }
            </div>
        );
    }
    render_device_buttons = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.deviceButtonContainer}>
                <Button
                    color="primary"
                    className={classes.editButton}
                    onClick={() => this.openEditModal(classes)}
                >
                    <EditIcon className={classes.buttonIcon} />
                    <span className={classes.buttonText}>EDIT</span>
                </Button>
                <Button
                    color="primary"
                    className={classes.commandButton}
                    onClick={() => this.performAction(classes)}
                >
                    <BuildIcon className={classes.buttonIcon} />
                    <span className={classes.wrapButtonText}>GATEWAY COMMAND</span>
                </Button>
                {device.nested_device_type.type === "gateway" ?
                    <Button
                        color="primary"
                        className={classes.sendConfigButton}
                        onClick={() => this.executeSendConfig(classes)}
                    >
                        <SyncIcon className={classes.buttonIcon} />
                        <span className={classes.wrapButtonText}>UPDATE CONFIG</span>
                    </Button>
                    : ""}
            </div>
        )
    }

    render_serial = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.iconInputContainer}>
                <Tooltip title="Serial Number">
                    <span style={{
                        color: "white", backgroundColor: "grey", borderRadius: "4px",
                        width: "24px", height: "18px", fontSize: "12px", marginLeft: "0px",
                        marginRight: "7px", display: "flex",
                    }}>
                        <span style={{ margin: "auto" }}>
                            S\N
                        </span>
                    </span>
                </Tooltip>
                <span className={classes.infoText}>
                    {device.serial === "" ? <span className={classes.unset}>Unset</span> : <span className={classes.info}>{device.serial}</span>}
                </span>
            </div>
        );
    }

    render_firmware = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.iconInputContainer}>
                <Tooltip title="Firmware Version">
                    <span style={{
                        color: "white", backgroundColor: "grey", borderRadius: "4px",
                        width: "24px", height: "18px", fontSize: "12px", marginLeft: "0px",
                        marginRight: "7px", display: "flex",
                    }}>
                        <span style={{ margin: "auto" }}>
                            Ver
                        </span>
                    </span>
                </Tooltip>
                <span className={classes.infoText}>
                    {device.firmware_version === "" ? <span className={classes.unset}>Unset</span> : <span className={classes.info}>{device.firmware_version}</span>}
                </span>
            </div>
        );
    }

    renderDeviceInformation = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.leftColumn}>
                <div className={classes.topHalfLeftColumn}>
                    {this.render_unique_id(classes)}
                    {this.render_greengrass(classes)}
                    {this.render_health(classes)}
                    {this.render_active_inactive(classes)}
                    {this.render_device_buttons(classes)}
                    {this.render_device_type_image(classes)}
                </div>
                <div style={{ height: this.has_greengrass() ? "calc(100% - 264px)" : "calc(100% - 275px)" }} className={classes.bottomHalfLeftColumn}>
                    <div className={classes.iconInputContainer}>
                        <Tooltip title="Device Type">
                            <DvrIcon className={classes.inputIcon} />
                        </Tooltip>
                        <span className={classes.infoText}>
                            <Button
                                color="primary"
                                classes={{ label: classes.labelButton }}
                                className={classes.link}
                                onClick={() => this.props.tabHostProxy.addTab("type", device.nested_device_type)}
                            >
                                {device.nested_device_type.name}
                            </Button>
                        </span>
                    </div>
                    <div className={classes.iconInputContainer}>
                        <Tooltip key="System ID" title="System ID">
                            <SystemIDIcon className={classes.inputIcon} />
                        </Tooltip>
                        <span className={classes.infoText}>
                            <span className={classes.info}>{device._id}</span>
                        </span>
                    </div>
                    <div className={classes.iconInputContainer}>
                        <Tooltip key="Account" title="Account">
                            <AccountCircleIcon className={classes.inputIcon} />
                        </Tooltip>
                        <span className={classes.infoText}>
                            <span className={classes.info}>{device.nested_company.name}</span>
                        </span>
                    </div>
                    <div className={classes.iconInputContainer}>
                        <Tooltip key="Location" title="Location">
                            <LocationOnIcon className={classes.inputIcon} />
                        </Tooltip>
                        <span className={classes.infoText}>
                            {device.location === ""
                                ?
                                <span className={classes.unset}>Location Unset</span>
                                :
                                <span className={classes.info}>{device.location}</span>
                            }
                        </span>
                    </div>
                    {this.render_serial(classes)}
                    {this.render_firmware(classes)}
                    {this.render_network_config(classes)}
                    {this.render_integrations(classes)}
                    {this.render_attached_devices(classes)}
                    {this.render_edge_version(classes)}
                    {this.render_tags_area(classes)}
                </div>
            </div>
        );
    }

    reinstall_gg = () => {
        //todo
    }

    render_greengrass = (classes) => {
        const device = this.props.dataModel.editableCopy();
        let gg_icon = <img className={classes.ggImg} src={require("../../images/greengrass.png").default} />;
        let gg_status = "";
        let gg_color = null;
        let reinstall = null;
        if (this.has_greengrass()) {
            if (device.nested_device_ha_group && device.nested_device_ha_group.controller_device && device.nested_device_ha_group.controller_device.device_id === device._id) {
                return "";
            }
            if (device.config_status && device.config_status.greengrass_installed) {
                gg_status = "Greengrass: Installed";
                gg_color = this.props.theme.palette.green.main;
            } else if (device.config_status && !device.config_status.greengrass_installed) {
                gg_status = "Greengrass: Error";
                // todo add when reinstall shortcut made reinstall = <span className={classes.link} onClick={() => this.reinstall_gg()}>Re-Install?</span>;
                gg_color = this.props.theme.palette.red.main;
            } else {
                gg_status = "Greengrass: Pending";
                gg_color = this.props.theme.palette.grey.main;
            }
            return (
                <div className={classes.uniqueIDContainer}>
                    {gg_icon}
                    <div className={classes.ggText} style={{ color: gg_color }}>
                        {gg_status}
                        {reinstall}
                    </div>
                </div>
            );
        }
        else {
            return "";
        }
    }

    render_active_inactive = (classes) => {
        const device = this.props.dataModel.editableCopy();
        if (device.nested_device_type.type === "cloud_native") {
            const active_at = device.activated_at;
            const deactive_at = device.deactivated_at;
            const green = this.props.theme.palette.green.main;
            const red = this.props.theme.palette.red.main;
            const grey = this.props.theme.palette.grey.main;
            let active = "";
            let activeColor = green;
            if (active_at != null) {
                active = "Activated";
            } else if (deactive_at != null) {
                active = "Deactivated";
                activeColor = red;
            } else if (active_at == null && deactive_at == null) {
                active = "Inactive";
                activeColor = grey;
            }
            return (
                <div className={classes.iconInputContainer}>
                    <Tooltip key="Device Active State" title="Device Active State">
                        <CloudIcon className={classes.inputIcon} style={{ color: activeColor }} />
                    </Tooltip>
                    <span className={classes.infoText}>
                        <span className={classes.info}>{active}</span>
                    </span>
                </div>
            );
        } else {
            return "";
        }
    }

    render_health = (classes) => {
        const device = this.props.dataModel.editableCopy();
        const green = this.props.theme.palette.green.main;
        const yellow = this.props.theme.palette.caution.main;
        const red = this.props.theme.palette.red.main;
        const grey = this.props.theme.palette.grey.main;
        const healthStatusDisplayMap = {
            "normal": { color: green, label: "Normal" },
            "needs_attention": { color: yellow, label: "Needs Attention" },
            "critical": { color: red, label: "Critical" },
        };
        let healthStatusDisplay = healthStatusDisplayMap[device.health_status];
        if (device.health_status !== "") {
            return (
                <div className={classes.iconInputContainer}>
                    <Tooltip key="User Defined Health Status" title="User Defined Health Status">
                        <HealthStatusIcon className={classes.inputIcon} style={{ color: healthStatusDisplay.color }} />
                    </Tooltip>
                    <span className={classes.infoText}>
                        <span className={classes.info}>{healthStatusDisplay.label}</span>
                    </span>
                </div>
            );
        } else {
            return <React.Fragment />
        }
    }

    render_unique_id = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.uniqueIDContainer}>
                <Tooltip title="Unique ID">
                    <span style={{
                        color: "white", backgroundColor: "grey", borderRadius: "4px",
                        width: "18px", height: "18px", fontSize: "12px",
                        marginRight: "8px", display: "flex",
                    }}>
                        <span style={{ margin: "auto" }}>
                            ID
                        </span>
                    </span>
                </Tooltip>
                <span className={classes.uidText}>
                    {device.unique_id}
                </span>
            </div>
        );
    }

    remove_tag = (tag) => {
        let device = this.props.dataModel.editableCopy();
        device.tags.splice(device.tags.indexOf(tag), 1);
        this.props.dataModel.setData(device).save().then((result) => {
            this.props.tabHostProxy.closeSelf();
            this.props.tabHostProxy.addTab("device", result);
            this.props.tabHostProxy.refresh();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    renderTabs = (classes) => {
        return (
            <Paper square className={classes.tabsContainer}>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ scroller: classes.scrollerOverride }}
                >
                    {this.tabs.map((tab) => {
                        return (
                            <Tab
                                classes={{ root: classes.tabItem, labelIcon: classes.labelOverride }}
                                key={tab.title}
                                icon={tab.icon}
                                label={tab.title}
                            />

                        );
                    })}
                </Tabs>
            </Paper>
        );
    }

    render_tags_area = (classes) => {
        const device = this.props.dataModel.editableCopy();
        return (
            <div className={classes.iconInputContainer + " " + classes.tagsWrapper}>
                <div className={classes.tagData}>
                    <Tooltip title="Tags">
                        <StyleIcon className={classes.inputIcon + " " + (device.tags && device.tags.length === 0 ? "" : classes.tagsIcon)} />
                    </Tooltip>
                    {!device.tags || device.tags.length === 0 ? <div className={classes.unset}>No Tags</div> :
                        <div>
                            {device.tags.map((tag) => (
                                <Chip clickable={false} classes={{ root: classes.chipRoot }} key={tag} label={tag} onDelete={() => this.remove_tag(tag)} />
                            ))}
                        </div>
                    }
                </div>
                <div className={classes.tagLookupContainer}>
                    <LookupInput
                        priorState={{ values: this.state.tag_input, suggestions: this.get_tags() }}
                        single
                        placeholder="Add Tag"
                        emitChange={this.handleTagChange}
                        onEnter={this.add_tag}
                        onType={this.update_tag}
                    />
                    <AddCircleIcon onClick={this.add_tag} className={classes.addTagButton} />
                </div>
            </div>
        );
    }

    render_edge_version = (classes) => {
        const device = this.props.dataModel.editableCopy();
        if (device.nested_device_type.type !== "gateway") {
            return "";
        }
        return (
            <React.Fragment>
                <div className={classes.iconInputContainer}>
                    <Tooltip key="Edge Version" title="Edge Version">
                        <PermDeviceInformation className={classes.inputIcon} />
                    </Tooltip>
                    {device.edge_version === "" || device.edge_version === "_" ?
                        <div>
                            Edge Version: <span className={classes.unset}>Unavailable</span>
                        </div>
                        :
                        <div>
                            Edge Version: {device.edge_version}
                        </div>
                    }
                </div>

            </React.Fragment>
        );
    }

    render_attached_devices = (classes) => {
        const device = this.props.dataModel.editableCopy();
        const show_attach = false;
        if (!["gateway", "cloud_native"].includes(device.nested_device_type.type)) {
            return (
                <div className={classes.iconInputContainer}>
                    <Tooltip title={`${this.capitalize(Auth.currentCompany().aliases.gateway)} Device`}>
                        <RouterIcon className={classes.inputIcon} />
                    </Tooltip>
                    {device.parent_device_id && device.parent_device_id !== "" ?
                        <span className={classes.infoText}>
                            {this.capitalize(Auth.currentCompany().aliases.gateway)} Device: <Button onClick={() => this.props.tabHostProxy.addTab("device", device.nested_parent_device)} color="primary" classes={{ label: classes.labelButton }} className={classes.link}>{device.nested_parent_device.name}</Button>
                        </span>
                        :
                        <div className={classes.infoText}><span className={classes.unset}>{"No " + this.capitalize(Auth.currentCompany().aliases.gateway) + " Device"}</span>{show_attach ? <Button onClick={() => this.attachtoParent(true)} color="primary" classes={{ label: classes.labelButton }} className={classes.link}>Attach to Parent?</Button> : ""}</div>
                    }
                </div>
            );
        } else {
            return (
                <React.Fragment />
            );
        }
    }

    render_integrations = (classes) => {
        const device = this.props.dataModel.editableCopy();
        if (!Permissions.allow(["read"], "integration", device.company_id)) {
            return "";
        }
        const device_integration = device.nested_device_integration;
        const cloud_integration = device.nested_cloud_native_integration;
        const device_type = device.nested_device_type;
        let show_add = Permissions.allow(["update"], "device", device.company_id) ? true : false;
        let render_device_integration = (
            <div className={classes.infoText}>
                <span className={classes.unset}>No Device Integration</span>
                {show_add ? <Button onClick={this.add_integration} color="primary" classes={{ label: classes.labelButton }} className={classes.link}>Add device integration?</Button> : ""}
            </div>
        );
        if (device_integration) {
            render_device_integration = (
                <div key={device_integration._id} className={classes.integrationPreview}>
                    <Button color="primary" classes={{ label: classes.labelButton }} className={classes.link} onClick={() => this.props.tabHostProxy.addTab("integration", device_integration)}>
                        {device_integration.name}
                    </Button>
                </div>
            );
        }
        let render_cloud_integration = (
            <React.Fragment />
        );
        let render_cloud_integration_contents = (
            <div className={classes.infoText}>
                <span className={classes.unset}>No Cloud Integration</span>
                {show_add ? <Button onClick={this.add_cloud_integration} color="primary" classes={{ label: classes.labelButton }} className={classes.link}>Add cloud integration?</Button> : ""}
            </div>
        );
        if (device_type.type === 'cloud_native') {
            if (cloud_integration) {
                render_cloud_integration_contents = (
                    <div key={cloud_integration._id} className={classes.integrationPreview}>
                        <Button color="primary" classes={{ label: classes.labelButton }} className={classes.link} onClick={() => this.props.tabHostProxy.addTab("integration", cloud_integration)}>
                            {cloud_integration.name}
                        </Button>
                    </div>
                );
            }

            render_cloud_integration = (
                <div className={classes.iconInputContainer}>
                    <Tooltip key="Cloud Integration" title="Cloud Integration">
                        <CloudIcon className={classes.inputIcon} />
                    </Tooltip>
                    {render_cloud_integration_contents}
                </div>
            )
        }
        return (
            <React.Fragment>
                {render_cloud_integration}
                <div className={classes.iconInputContainer}>
                    <Tooltip key="Device Integration" title="Device Integration">
                        <ExtensionIcon className={classes.inputIcon} />
                    </Tooltip>
                    {render_device_integration}
                </div>
            </React.Fragment>
        );
    }

    add_config = () => {
        const device = this.props.dataModel.editableCopy();
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: () => (<AddConfig tabHostProxy={this.props.tabHostProxy} device={device} closeModal={this.closeModal} onSave={this.onSave} />)
            }
        });
    }

    add_integration = () => {
        const device = this.props.dataModel.editableCopy();
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: () => (<AddIntegration tabHostProxy={this.props.tabHostProxy} device={device} closeModal={this.closeModal} onSave={this.onSave} />)
            }
        });
    }


    render_network_config = (classes) => {
        let device_config = "No Network Config";
        const device = this.props.dataModel.editableCopy();
        if (device.nested_device_type.type !== "gateway" || (!Permissions.allow(["read"], "device_config", device.company_id))) {
            return "";
        } else if (device.device_config_id !== "" && device.nested_device_config && device.nested_device_config.name) {
            device_config = device.nested_device_config.name;
        }
        let show_add = false;
        if (Permissions.allow(["update"], "device", device.company_id)) {
            show_add = true;
        }
        return (
            <React.Fragment>
                <div className={classes.iconInputContainer}>
                    <Tooltip key="Network Config" title="Network Config">
                        <SettingsIcon className={classes.inputIcon} />
                    </Tooltip>
                    <span className={classes.infoText}>
                        {device_config === "No Network Config" ? <React.Fragment><span className={classes.unset}>No Network Config</span>{show_add ? <Button onClick={this.add_config} color="primary" classes={{ label: classes.labelButton }} className={classes.link}>Add a config?</Button> : ""}</React.Fragment> :
                            <Button color="primary" classes={{ label: classes.labelButton }} className={classes.link} onClick={this.open_config}>
                                {device_config}
                            </Button>
                        }
                    </span>
                </div>
            </React.Fragment>
        );
    }

    renderTab = (index, classes) => {
        return this.tabRenderFunctions.map((render_function, render_index) => {
            if (index !== render_index) {
                return (
                    <div key={render_index} className={classes.tile + "  " + classes.notShown}>
                        {render_function(classes)}
                    </div>
                );
            } else {
                return (
                    <div key={render_index} className={classes.tile + "  " + classes.shown}>
                        {render_function(classes)}
                    </div>
                );
            }
        });
    }

}

DeviceView.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceView));
