import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import NumberInput from '../Inputs/NumberInput';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
	rangesContainer: {
		display: "flex",
		flexWrap: "wrap",
	},
	rangeInput: {
		display: "inline",
		width: "100px",
		margin: "-16px 2px 0 2px"
	},
	menuTitleContainer: {
		display: "flex",
		alignItems: "center",
	},
	label: {
		textTransform: "uppercase",
		fontSize: "12px",
		display: "inline",
		fontFamily: "Inter",
		color: "grey",
	},
	menuIcon: {
		padding: "4px",
	},
	menuIconContainer: {
		flexGrow: 2,
		textAlign: "right"
	}
});

const initialState = {
	Years: {
		disabled: false,
		hidden: true,
		min: 0,
		max: null,
		value: 0
	},
	Months: {
		disabled: false,
		hidden: true,
		min: 0,
		max: null,
		value: 0
	},
	Weeks: {
		disabled: false,
		hidden: true,
		min: 0,
		max: null,
		value: 0
	},
	Days: {
		disabled: false,
		hidden: false,
		min: 0,
		max: null,
		value: 0
	},
	Hours: {
		disabled: false,
		hidden: false,
		min: 0,
		max: null,
		value: 0
	},
	Minutes: {
		disabled: false,
		hidden: false,
		min: 0,
		max: null,
		value: 0
	},
	Seconds: {
		disabled: false,
		hidden: true,
		min: 0,
		max: null,
		value: 0
	}
};

class OffsetInput extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			anchorEl: null,
		};
		this.ranges = this.formatOffset(this.props.priorState);
		this.props.emitChange(this.ranges);
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};
	
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	localChange = (range) => {
		this.ranges[range.label].value = range.value;
		this.props.emitChange(this.ranges);
	}

	toggleRangeVisibility = (label) => {
		this.ranges[label].hidden = !this.ranges[label].hidden;
		this.ranges[label].value = 0;
		this.props.emitChange(this.ranges);
	}

	formatOffset = (offset) => {
		if (typeof offset === "string") {
			offset = offset.split("P")[1];
			let new_offset = JSON.parse(JSON.stringify(initialState));
			if (offset.indexOf('W') !== -1) {
				new_offset.Weeks = {
					disabled: false,
					hidden: false,
					min: 0,
					max: null,
					value: parseInt(offset.split('W')[0])
				};
			} else {
				let front = offset.split("T")[0];
				let back = offset.split("T")[1];
				if (front && front !== "") {
					new_offset.Years = {
						disabled: false,
						hidden: front.indexOf('Y') !== -1 ? false : true,
						min: 0,
						max: null,
						value: front.indexOf('Y') !== -1 ? parseInt(front.split('Y')[0]) : 0
					};
					front = new_offset.Years.value !== 0 ? front.split("Y")[1] : front;
					new_offset.Months = {
						disabled: false,
						hidden: front.indexOf('M') !== -1 ? false : true,
						min: 0,
						max: null,
						value: front.indexOf('M') !== -1 ? parseInt(front.split('M')[0]) : 0
					};
					front = new_offset.Months.value !== 0 ? front.split("M")[1] : front;
					new_offset.Days = {
						disabled: false,
						hidden: front.indexOf('D') !== -1 ? false : true,
						min: 0,
						max: null,
						value: front.indexOf('D') !== -1 ? parseInt(front.split('D')[0]) : 0
					};
				}
				if (back && back !== "") {
					new_offset.Hours = {
						disabled: false,
						hidden: back.indexOf('H') !== -1 ? false : true,
						min: 0,
						max: null,
						value: back.indexOf('H') !== -1 ? parseInt(back.split('H')[0]) : 0
					};
					back = new_offset.Hours.value !== 0 ? back.split("H")[1] : back;
					new_offset.Minutes = {
						disabled: false,
						hidden: back.indexOf('M') !== -1 ? false : true,
						min: 0,
						max: null,
						value: back.indexOf('M') !== -1 ? parseInt(back.split('M')[0]) : 0
					};
					back = new_offset.Minutes.value !== 0 ? back.split("M")[1] : back;
					new_offset.Seconds = {
						disabled: false,
						hidden: back.indexOf('S') !== -1 ? false : true,
						min: 0,
						max: null,
						value: back.indexOf('S') !== -1 ? parseInt(back.split('S')[0]) : 0
					};
				}
			}
			return new_offset;
		} else {
			return JSON.parse(JSON.stringify(initialState));
		}
	}

	render() {
		const { priorState, classes } = this.props;
		return (
			<React.Fragment>
				{Object.entries(this.ranges).map((range) => {
					let label = range[0];
					range = range[1];
					if (!range.hidden) {
						return (
							<NumberInput
								rootClass={classes.rangeInput}
								key={label}
								disabled={this.props.disabled}
								label={label}
								minimum={range.min}
								maximum={range.max}
								priorState={range.value}
								emitChange={this.localChange}
							/>
						);
					} else {
						return "";
					}
				})}
				{this.props.disabled ? "" : this.renderMenu(classes)}
			</React.Fragment>
		);
	}

	renderMenu = (classes) => {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		return (
			<div className={classes.menuTitleContainer}>
				<div className={classes.menuIconContainer}>
					<IconButton
						className={classes.menuIcon}
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={this.handleClick}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
				>
					{Object.entries(this.ranges).map((range) => {
						let label = range[0];
						range = range[1];
						return (
							<MenuItem key={label} onClick={() => this.toggleRangeVisibility(label)}>
								<ListItemIcon className={classes.icon}>
									{range.hidden ?
										<VisibilityOffIcon />
										: <VisibilityIcon />
									}
								</ListItemIcon>
								<ListItemText inset>
									{label}
								</ListItemText>
							</MenuItem>
						);
					})}
				</Menu>
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(OffsetInput));
