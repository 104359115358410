import React from 'react';
import { withRouter } from 'react-router';
import Loading from '../DisplayOriented/Loading';

//npm
import { compose } from 'recompose';
import {
    FlexibleXYPlot, MarkSeries, XAxis,
    YAxis, VerticalGridLines, HorizontalGridLines,
} from 'react-vis';
import styled from "styled-components";

//inputs
import SelectInput from '../Inputs/SelectInput';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

//icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import WarningIcon from '@material-ui/icons/Warning';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import { GetAll } from '../../services/CLURDUtilities';
import Device from '../../services/DataModels/Device';

const styles = theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%"
    },
    title: {
        fontSize: "20px",
    },
    graphContainer: {
        position: "relative",
        height: "100%",
        width: "100%",
        marginTop: "18px",
        padding: "0 50px",
        boxSizing: "border-box"
    },
    filterContainer: {
        width: "50%",
        display: "flex",
        marginLeft: "6px",
        overflow: "hidden"
    },
    filterWrapper: {
        marginRight: "4px",
        width: "50%"
    },
    ctaText: {
        marginRight: "4px",
        height: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    linkButton: {
        marginLeft: "auto",
        color: theme.palette.pending.main
    },
    bottomRow: {
        marginTop: "auto",
        width: "100%",
        display: "flex",
        flexWrap: "nowrap"
    },
    time: {
        color: "#8e8e93",
    },
    date: {
        display: "block"
    },
    graphLine: {
        borderColor: "#e0e0e0",
        borderStyle: "solid"
    },
    iconsContainer: {
        position: "relative",
        width: "100%",
        boxSizing: "border-box"
    },
    labelsContainer: {
        position: "relative",
        width: "100%",
        boxSizing: "border-box"
    },
    label: {
        position: "absolute",
        top: "24px",
        fontSize: "12px",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        width: "100px",
        textAlign: "center"
    },
    timelineTime: {
        color: "#8e8e93",
    },
    timelineLine: {
        position: "absolute",
        width: "0px",
        top: "-34px",
        height: "30px",
        border: "solid #e0e0e0 1px"
    },
    noneFound: {
        position: "relative",
        top: "70px",
        fontSize: "14px",
    },
    errorIcon: {
        color: theme.palette.red.main
    },
    triangleBg: {
        position: "absolute",
        width: 0,
        height: 0,
        top: "-1px",
        left: "-2px",
        zIndex: 1,
        borderLeft: "14px solid transparent",
        borderRight: "14px solid transparent",
        borderBottom: `24px solid ${theme.palette.pending.main}`
    },
    triangleTinyBg: {
        position: "absolute",
        width: 0,
        height: 0,
        top: "2px",
        left: "4px",
        zIndex: 2,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderBottom: `18px solid white`
    },
    warningIcon: {
        color: theme.palette.caution.main,
        zIndex: 3,
        position: "relative"
    },
    errorIconContainer: {
        width: "24px",
        height: "24px",
        top: "-22px",
        position: "absolute",
        cursor: "pointer",
        zIndex: 2,
        margin: "2px"
    },
    errorBg: {
        background: "white",
        borderRadius: "50%",
    },
    warningBg: {
        zIndex: 3
    },
    errorSelected: {
        border: `solid ${theme.palette.pending.main} 2px`,
        margin: 0,
        zIndex: 3
    },
    selectedLine: {
        zIndex: 3,
        border: `solid ${theme.palette.pending.main} 1px`,
        position: "absolute",
        width: "0px",
        left: "11px",
        top: "22px",
        height: "110px",
    },
    hoverContainer: {
        padding: "12px",
        fontFamily: "Inter",
        border: "solid #2b8ceb 2px",
        zIndex: 3,
        marginBottom: "12px",
        position: "relative"
    },
    cycleArrowsContainer: {
        position: "absolute",
        top: "-32px",
        left: "-6px",
        color: theme.palette.pending.main
    },
    arrowError: {
        padding: "2px"
    },
    popper: {
        zIndex: 1,
        maxWidth: "33vw",
        minWidth: "33vw",
        position: "relative",
    },
    hoverRow: {
        padding: "6px",
        display: "flex",
        alignItems: "center"
    },
    hoverRowLabel: {
        textTransform: "uppercase",
        color: "#8e8e93",
        minWidth: "22%",
        maxWidth: "22%",
        fontSize: "14px",
        marginRight: "12px"
    },
    hoverRowValue: {
        overflowX: "auto",
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "10px"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            backgroundColor: "#d5d6d6"
        }
    },
    buttonRow: {
        margin: "12px 6px 6px 6px",
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    loadingWrapper: {
        padding: "0 16px",
        width: "64px"
    },
    hoverButton: {
        marginLeft: "12px",
        borderColor: theme.palette.pending.main,
        color: theme.palette.pending.main
    },
    hoverClose: {
        position: 'absolute',
        right: '10px',
        cursor: "pointer"
    }
});

class ErrorFeedWidget extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            data: null,
            severity_filter: "all",
            range_filter: "five",
            label_options: null,
            error_active: null
        };
        this.severity_filters = [
            { display: "All Severity Levels", value: "all" },
            { display: "Critical", value: 5 },
            { display: "Error", value: 4 },
            { display: "Warning", value: 3 },
        ];
        this.range_filters = [
            { display: "Past 5 Minutes", value: "five" },
            { display: "Past 30 Minutes", value: "thirty" },
            { display: "Past Hour", value: "hour" },
            { display: "Today", value: "today" }
        ];

        this.link = { display: "VIEW ERRORS", link: "/Messages?errors" };
        this.load();
    }

    load = () => {
        const now = new Date();
        let params = {
            page_meta: true,
            created_at_gt: this.get_time_frame(now, this.state.range_filter)
        };
        if (this.state.severity_filter !== "all") {
            if (this.state.severity_filter === 3) {
                params.level_lte = this.state.severity_filter;
            } else {
                params.level = this.state.severity_filter;
            }
        }
        GetAll("device_errors", params).then((items) => {
            this.set_times(now, items);
            items.forEach((error) => {
                if (error._id) {
                    error.id = error._id;
                } else if (error.id) {
                    error._id = error.id;
                }
            });
            let labels = this.build_labels(params.created_at_gt, now);
            this.setState({ data: items, error_active: items && items.length > 0 ? items[0] : null, labels: labels });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    build_labels = (start, end) => {
        start = new Date(start);
        const divisions = { "five": [6, 60000], "thirty": [6, 300000], "hour": [4, 900000], "today": [2, 0] }
        return build(start, end, divisions[this.state.range_filter]);
        function build(start, end, [section_count, milliseconds]) {
            let sections = [];
            let tz = start.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
            for (let i = 0; i < section_count; i++) {
                let percentage = 100 / (section_count - 1);
                let left = (percentage * i).toFixed(2);
                let time = new Date(start);
                if (i === 0) {
                    let time_string = start.toLocaleString().split(", ")[1];
                    time_string = `${time_string.split(":")[0]}:${time_string.split(":")[1]} ${time_string.split(" ")[1]} ${tz}`;
                    sections.push({
                        time: time_string,
                        day: start.toLocaleString().split(", ")[0],
                        left: "calc(0% - 50px)"
                    });
                } else if (i === section_count - 1) {
                    let time_string = end.toLocaleString().split(", ")[1];
                    time_string = `${time_string.split(":")[0]}:${time_string.split(":")[1]} ${time_string.split(" ")[1]} ${tz}`;
                    sections.push({
                        time: time_string,
                        day: end.toLocaleString().split(", ")[0],
                        left: "calc(100% - 50px)"
                    });
                } else {
                    time.setMilliseconds((i * milliseconds));
                    let time_string = time.toLocaleString().split(", ")[1];
                    time_string = `${time_string.split(":")[0]}:${time_string.split(":")[1]} ${time_string.split(" ")[1]} ${tz}`;
                    sections.push({
                        time: time_string,
                        day: time.toLocaleString().split(", ")[0],
                        left: `calc(${left}% - 50px)`
                    });
                }
            }
            return sections;
        }
    }

    set_times = (now, errors) => {
        let hour_ago = new Date(now);
        let five_ago = new Date(now);
        let thirty_ago = new Date(now);
        let today = new Date(now);
        hour_ago.setHours(hour_ago.getHours() - 1);
        five_ago.setMinutes(five_ago.getMinutes() - 5);
        thirty_ago.setMinutes(thirty_ago.getMinutes() - 30);
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        errors.forEach((error) => {
            let error_time = new Date(error.created_at);
            let difference_day = error_time - today;
            let percentage_day = difference_day / (now.getHours() * 3.6e+6 + now.getMinutes() * 60000 + now.getSeconds() * 1000 + now.getMilliseconds());
            error.today = `calc(${Math.round(percentage_day * 100)}% - 12px)`;
            error.hour = `calc(${Math.round((error_time - hour_ago) / 3.6e+6 * 100)}% - 12px)`;
            error.five = `calc(${Math.round((error_time - five_ago) / 300000 * 100)}% - 12px)`;
            error.thirty = `calc(${Math.round((error_time - thirty_ago) / 1.8e+6 * 100)}% - 12px)`;
        });
    }

    get_time_frame = (now, time_frame) => {
        let copy = new Date(now);
        switch (time_frame) {
            case "five":
                copy.setMinutes(copy.getMinutes() - 5);
                break;
            case "thirty":
                copy.setMinutes(copy.getMinutes() - 30);
                break;
            case "hour":
                copy.setHours(copy.getHours() - 1);
                break;
            case "today":
                copy.setHours(0);
                copy.setMinutes(0);
                copy.setSeconds(0);
                copy.setMilliseconds(0);
                break;
        }
        copy = copy ? copy.toISOString() : undefined;
        return copy;
    }

    render_title = () => {
        const { classes } = this.props;
        return (
            <div className={classes.title}>
                Device Errors
            </div>
        )
    }

    render_feed = () => {
        const { data, range_filter, labels, error_active } = this.state;
        const { classes, theme } = this.props;
        return (
            <div className={classes.graphContainer}>
                <hr className={classes.graphLine} />
                <div className={classes.iconsContainer}>
                    {data.map((error) => this.get_error_icon(error))}
                </div>
                <div className={classes.labelsContainer}>
                    {labels.map((label, index) => (
                        <div key={"label_" + index} className={classes.label} style={{ left: label.left }}>
                            <div className={classes.timelineTime}>{label.time}</div>
                            <div>{label.day}</div>
                            <div className={classes.timelineLine}>&nbsp;</div>
                        </div>
                    ))}
                </div>
                {null == error_active && data.length === 0 ? <div className={classes.noneFound}>
                    No errors found.
                </div> : ""}
            </div>
        );
    }

    get_error_icon = (error) => {
        const classes = this.props.classes;
        const error_active = this.state.error_active;
        const range_filter = this.state.range_filter;
        let icon = null;
        let selected_class = "";
        let triangle = null;
        let error_class = "";
        switch (error.level) {
            case 5:
                icon = <NewReleasesIcon className={classes.errorIcon} />;
                error_class = classes.errorBg;
                selected_class = error_active != null && error_active.id === error.id ? classes.errorSelected : "";
                break;
            case 4:
                icon = <ErrorIcon className={classes.errorIcon} />;
                error_class = classes.errorBg;
                selected_class = error_active != null && error_active.id === error.id ? classes.errorSelected : "";
                break;
            default:
                icon = <WarningIcon className={classes.warningIcon} />;
                error_class = classes.warningBg;
                triangle = error_active != null && error_active.id === error.id ? <React.Fragment><div className={classes.triangleBg}></div><div className={classes.triangleTinyBg}></div></React.Fragment> : <div className={classes.triangleTinyBg}></div>;
                break;
        }
        return (
            <div
                key={error.id}
                style={{ left: error[range_filter] }}
                className={classes.errorIconContainer + " " + error_class + " " + selected_class}
                onClick={(event) => this.setState({ error_active: error })}
            >
                {icon}
                {triangle}
                {error_active != null && error_active.id === error.id ?
                    <React.Fragment>
                        <div className={classes.selectedLine}>&nbsp;</div>
                    </React.Fragment>
                    : ""}
            </div>
        )
    }

    render_cycle_arrows = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.cycleArrowsContainer}>
                <IconButton
                    onClick={(event) => this.cycle(event)}
                    aria-label="previous_error"
                    color="inherit"
                    className={classes.arrowError}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => this.cycle(event, true)}
                    aria-label="next_error"
                    color="inherit"
                    className={classes.arrowError}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </div>
        );
    }

    cycle = (event, decrease) => {
        // event.stopPropagation();
        let index = this.state.data.findIndex((e) => this.state.error_active.id === e.id);
        index = decrease ? index - 1 : index + 1;
        if (!this.state.data[index]) {
            if (index < 0) {
                index = this.state.data.length - 1;
            } else {
                index = 0;
            }
        }
        this.setState((state) => {
            state.error_active = state.data[index];
            return state;
        });
    }

    render_date = (date) => {
        function showDate(utc) {
            let date_object = new Date(utc);
            return date_object.toLocaleDateString();
        }

        function showTime(utc) {
            let date_object = new Date(utc);
            return date_object.toLocaleTimeString();
        }
        return (
            <span>
                <span style={{ whiteSpace: "nowrap", marginRight: "12px" }}>
                    {showTime(date)}
                </span>
                <span style={{ color: "#8e8393" }}>
                    {showDate(date)}
                </span>
            </span>
        );
    }

    render_severity = (level, with_label) => {
        let title = "";
        let icon = "";
        if (level < 4) {
            title = "Warning";
            icon = <WarningIcon style={{ color: this.props.theme.palette.caution.main }} />;
        } else if (level === 4) {
            title = "Default";
            icon = <ErrorIcon style={{ color: this.props.theme.palette.red.main }} />;
        } else {
            title = "Critical";
            icon = <NewReleasesIcon style={{ color: this.props.theme.palette.red.main }} />;
        }
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Tooltip key={title} title={title}>
                    {icon}
                </Tooltip>
                {with_label ? <span style={{ marginLeft: "6px" }}>{title}</span> : ""}
            </div>
        );
    }

    open_device = (id) => {
        this.props.history.push("/Devices?device_id=" + id);
    }

    render_error_info = () => {
        const classes = this.props.classes;
        const { error_active } = this.state;
        if (null == error_active) return "";
        return (
            <Paper className={classes.hoverContainer}>
                {this.state.data.length > 1 ? this.render_cycle_arrows() : ""}
                <CloseIcon onClick={() => { this.setState({ error_active: undefined }) }} className={classes.hoverClose} />
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(error_active.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Severity</span>
                    <span className={classes.hoverRowValue}>{this.render_severity(error_active.level, true)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Error Message</span>
                    <span className={classes.hoverRowValue}>{error_active.error}</span>
                </div>
                {error_active.device_id ? <div className={classes.buttonRow}>
                    {!this.state.hover_action_happening ? <Button
                        className={classes.hoverButton}
                        variant="outlined"
                        onClick={() => this.device_action("log_upload", error_active.device_id)}
                    >
                        <span className={classes.buttonText}>UPLOAD LOGS</span>
                    </Button> : <div className={classes.loadingWrapper}><Loading /></div>}
                    <Button
                        className={classes.hoverButton}
                        variant="outlined"
                        onClick={() => this.open_device(error_active.device_id)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button>
                </div> : ""}
            </Paper>
        );
    }

    device_action = (action, id, is_unique_id) => {
        this.setState({ hover_action_happening: true });
        Device.issueGatewayCommand(action, null, id, null, is_unique_id).then((result) => {
            this.setState({ hover_action_happening: false });
            this.context.openSnackbar(`${Device.commandReadable(action)} command sent to device`, 'success');
        }).catch((error) => {
            this.setState({ hover_action_happening: false });
            this.context.openSnackbar(error, "error");
        });
    }

    handle_arrow_ref = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handle_change = ({ field, value }) => {
        this.setState((state) => {
            state[field] = value;
            state.data = null;
            return state;
        }, () => {
            this.load();
        });
    }

    render_filters = () => {
        const { classes } = this.props;
        return (
            <div className={classes.filterContainer}>
                <div className={classes.filterWrapper}>
                    <SelectInput
                        basic
                        priorState={this.state.severity_filter}
                        emitChange={this.handle_change}
                        options={this.severity_filters}
                        field="severity_filter"
                    />
                </div>
                <div className={classes.filterWrapper}>
                    <SelectInput
                        basic
                        priorState={this.state.range_filter}
                        emitChange={this.handle_change}
                        options={this.range_filters}
                        field="range_filter"
                    />
                </div>
            </div>
        );
    }

    follow_link = () => {
        this.props.history.push(this.link.link);
    }

    render_link = () => {
        const { classes } = this.props;
        return (
            <Button className={classes.linkButton} onClick={this.follow_link}>
                <span className={classes.ctaText}>
                    {this.link.display}
                </span>
                <ArrowForwardIcon />
            </Button>
        );
    }

    render() {
        const { data } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                {this.render_title()}
                {data === null ? <Loading />
                    :
                    <React.Fragment>
                        {this.render_feed()}
                        {this.render_error_info()}
                        <div className={classes.bottomRow}>
                            {this.render_filters()}
                            {this.render_link()}
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

ErrorFeedWidget.contextType = SnackbarContext;
export default compose(
    withRouter,
    withStyles(styles),
    withTheme()
)
    (ErrorFeedWidget);
