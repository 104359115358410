import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//inputs
import TextInput from '../../Inputs/TextInput';
import LookupInput from '../../Inputs/LookupInput';
import TimeInput from '../../Inputs/TimeInput';

//icons
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StyleIcon from '@material-ui/icons/Style';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';

//services
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Company from '../../../services/DataModels/Company';
import { GetAll } from '../../../services/CLURDUtilities';
import Device from '../../../services/DataModels/Device';

class CDStep3 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			updated_device: {
				location: "",
				tags: [],
				rules: [],
				heartbeat_data: {
					heartbeat_values: [],
					heartbeat_period: 4,
					heartbeat_time: "Hours"
				},
			},
			tag_options: null,
			rule_options: null,
			new_tag: "",
		};
		this.company_id = this.props.preset ? this.props.preset.company_id : this.props.device.company_id;
		this.load_tags();
		this.load_rules();
	}

	load_tags = () => {
		if (!this.company_id || this.company_id == '') return;
		new Company({_id: this.company_id}).readFromAPI().then( ({device_tags}) => {
			if (device_tags) {
				device_tags = device_tags.map( (tag) => ({label: tag, display: tag, value: tag}));
			} else {
				device_tags = [];
			}
			this.setState({tag_options: device_tags});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_rules = () => {
		if (!this.company_id || this.company_id == '') return;
		const params = {company_id: this.company_id};
		GetAll("rules", params).then( (rules) => {
			rules = rules.map( (option) => ({ label: option.description, value: option._id, whole: option }));
			this.setState({rule_options: rules});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_change = ({field, value}) => {
		this.setState( (state) => {
			state.updated_device[field] = value;
			return state;
		});
	}

	handle_rule_change = ({value}) => {
		this.setState( (state) => {
			if (value && value.length > 0) {
				state.updated_device.rules = value;
			} else {
				state.updated_device.rules = [];
			}
			return state;
		});
	}

	handle_tag_change = ({value}) => {
		this.setState( (state) => {
			if (value && value.length > 0) {
				state.updated_device.tags = value.map( ({value}) => value);
			} else {
				state.updated_device.tags = [];
			}
			return state;
		});
	}

	render_location = () => {
		const { classes } = this.props;
		const { updated_device } = this.state;
		return (
			<div className={classes.inputContainer + " " + classes.locationContainer}>
				<Tooltip className={classes.inputIcon} key="Location" title="Location">
					<LocationOnIcon />
				</Tooltip>
				<TextInput
					field="location"
					emitChange={this.handle_change}
					priorState={updated_device.location}
					label="Location"
					margin="none"
				/>
			</div>
		);
	}

	add_tag = () => {
		if (this.state.new_tag === "") return;
		this.setState( (state) => {
			const new_tag = state.new_tag;
			if (!state.tag_options.find( ({value}) => new_tag === value)) {
				state.tag_options.push({label: new_tag, value: new_tag});
			}
			if (state.updated_device.tags.indexOf(new_tag) === -1) {
				state.updated_device.tags.push(new_tag);
			}
			state.new_tag = "";
			return state;
		});
	}

	render_tags = () => {
		const { classes } = this.props;
		const { updated_device, tag_options } = this.state;
		let select_display = (
			<LookupInput
				priorState={{values: updated_device.tags.map( (tag) => ({value: tag, label: tag})), suggestions: tag_options}}
				placeholder="Select Tags"
				label="Tags"
				emitChange={this.handle_tag_change}
			/>
		);
		if (tag_options == null) {
			select_display = ( <Loading /> );
		} else if (Array.isArray(tag_options) && tag_options.length === 0) {
			select_display = ( <span className={classes.configText + " " + classes.tagText}>There are no device tags defined for this account. Create some below.</span> );
		}
		return (
			<React.Fragment>
				<div className={classes.inputContainer + " " + classes.lookupContainer}>
					<Tooltip className={classes.inputIcon + " " + classes.tagIcon} key="tags" title="Tags">
						<StyleIcon />
					</Tooltip>
					{select_display}
				</div>
				<div className={classes.inputContainer + " " + classes.tagsContainer}>
					<Tooltip style={{visibility: "hidden"}} className={classes.inputIcon} title="Tags">
						<StyleIcon />
					</Tooltip>
					<TextInput
						variant="standard"
						field="new_tag"
						emitChange={({value}) => this.setState({new_tag: value})}
						priorState={this.state.new_tag}
						placeholder="Create New Tag"
						margin="none"
						onEnter={this.add_tag}
					/>
					<AddCircleIcon onClick={this.add_tag} className={classes.addTagButton}/>
				</div>
			</React.Fragment>
		);
	}

	render_policies = () => {
		const { classes } = this.props;
		const { updated_device, rule_options } = this.state;
		let select_display = (
			<LookupInput
				priorState={{values: updated_device.rules, suggestions: rule_options}}
				placeholder="Select Policies"
				label="Policies"
				emitChange={this.handle_rule_change}
			/>
		);
		if (rule_options == null) {
			select_display = ( <Loading /> );
		} else if (Array.isArray(rule_options) && rule_options.length === 0) {
			select_display = ( <span className={classes.configText + " " + classes.tagText}>There are no policies available for this account.</span> );
		}
		return (
			<div className={classes.inputContainer + " " + classes.lookupContainer}>
				<Tooltip className={classes.inputIcon + " " + classes.tagIcon} key="policies" title="Policies">
					<SecurityIcon />
				</Tooltip>
				{select_display}
			</div>
		);
	}

	render_heartbeat = () => {
		const { classes, device } = this.props;
		const { updated_device } = this.state;
		const data = updated_device.heartbeat_data;
		const label = device.is_gateway ? "Generate a Heartbeat Report with the fields below every..." : "Expect a report at least every...";
		return (
			<div className={classes.heartbeatContainer}>
				<div className={classes.heartbeatContainerTitle}>
					<div className={classes.heartbeatIconsContainer}>
						<FavoriteIcon className={classes.heartbeatIcon} />
						<TimelineIcon className={classes.overlayIcon}/>
					</div>
					<span className={classes.generate}>
						{label}
					</span>
					<TimeInput
						margin="none"
						emitChange={this.handle_period_change}
						priorState={{ value: data.heartbeat_period, time: data.heartbeat_time }}
					/>
				</div>
				{this.render_heartbeat_values()}
			</div>
		);
	}

	handle_period_change = ({value, time}) => {
		this.setState( (state) => {
			state.updated_device.heartbeat_data.heartbeat_period = value;
			state.updated_device.heartbeat_data.heartbeat_time = time;
			return state;
		});
	}

	handle_heartbeat = (value) => {
		this.setState( (state) => {
			let index = state.updated_device.heartbeat_data.heartbeat_values.indexOf(value);
			if (index === -1) {
				state.updated_device.heartbeat_data.heartbeat_values.push(value);
			} else {
				state.updated_device.heartbeat_data.heartbeat_values.splice(index, 1);
			}
			return state;
		});
	}

	render_heartbeat_values = () => {
		const { classes, device } = this.props;
		const { updated_device } = this.state;
		if (!device.is_gateway) return "";
		return (
			<React.Fragment>
				<div className={classes.formControl}>
					{Device.getHeartbeatValues().map( ({value, label}) => (
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox
									checked={updated_device.heartbeat_data.heartbeat_values.indexOf(value) !== -1}
									onChange={() => this.handle_heartbeat(value)} value={value}
									color="primary"
								/>
							}
							key={value}
							label={label}
						/>
					))}

				</div>
			</React.Fragment>
		);
	}

	render() {
		const { renderButtons, renderTitle, classes } = this.props;
		const { updated_device, tag_options } = this.state;
		return (
			<React.Fragment>
				<div>
					{renderTitle()}
					<div className={classes.inputWrapper}>
						{this.render_tags()}
						{this.render_policies()}
						{this.render_location()}
						{this.render_heartbeat()}
					</div>
				</div>
				{renderButtons(() => true, updated_device)}
			</React.Fragment>
		);
	}
}

const styles = (theme) => {
	return ({
		inputWrapper: {
			height: "461px",
			overflowY: "auto",
			marginTop: "-12px",
			paddingRight: "16px",
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap",
			width: "100%",
		},
		locationContainer: {
			marginTop: "32px"
		},
		lookupContainer: {
			minHeight: "56px"
		},
		tagsContainer: {
			marginTop: 0,
			width: "40%"
		},
		inputIcon: {
			color: "#8e8e93", 
			minWidth: "22px", 
			height: "20px",
			margin: "8px 10px 32px 10px",
			paddingBottom: 0,
			display: "flex",
		},
		tagIcon: {
			margin: "12px 10px 0 10px"
		},
		configText: {
			margin: "auto 0 32px 0",
			color: "grey"
		},
		tagText: {
			minHeight: "56px",
			margin: 0,
			paddingTop: "24px",
			boxSizing: "border-box"
		},
		addTagButton: {
			cursor: "pointer",
			color: theme.palette.pending.main,
			margin: "0px 0 24px 8px",
			'&:hover': {
				color: darken(theme.palette.pending.main, .2),
			},
		},
		heartbeatContainerTitle: {
			display: "flex",
			width: "100%",
			alignItems: "center"
		},
		heartbeatIconsContainer: {
			position: "relative",
			color: theme.palette.greyIcon.main,
			display: "flex",
			margin: "8px 10px 12px 10px",
		},
		heartbeatIcon: {
			color: "grey", 
			minWidth: "22px", 
			height: "20px",
		},
		overlayIcon: {
			position: "absolute",
			left: 0,
			color: "white",
			minWidth: "22px", 
			height: "20px",
		},
		generate: {
			marginRight: "8px",
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.87)"
		},
		formControl: {
			marginLeft: "42px",
			display: "flex",
			flexWrap: "wrap"
		},
		formControlLabel: {
			width: "24%"
		}
	});
};
CDStep3.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CDStep3));
