import React from 'react';
import { compose } from 'recompose';
import Loading from '../../DisplayOriented/Loading';
import VerticalTabs from '../../DisplayOriented/VerticalTabs';
import TableList from '../../Table/TableList';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import CreateSoftwareUpdateForm from '../../SoftwareUpdatesSpecific/CreateSoftwareUpdateForm';
import PaginationContainer from '../../Containers/PaginationContainer';
import Paper from '@material-ui/core/Paper';
import HeartbeatStatus from '../../common/HeartbeatStatus/HeartbeatStatusComponent'

//services
import Permissions from '../../../services/Permissions';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import DiscoveredDevice from '../../../services/DataModels/DiscoveredDevice';
import Report from '../../../services/DataModels/Report';
import { GetAll } from '../../../services/CLURDUtilities';
import Humanize  from '../../../services/Humanize';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

//icons
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WarningIcon from '@material-ui/icons/Warning';
import BuildIcon from '@material-ui/icons/Build';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DevicesOtherIcon from '@material-ui/icons/DevicesOtherOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import RouterIcon from '@material-ui/icons/Router';
import NotesIcon from '@material-ui/icons/Notes';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import StyleIcon from '@material-ui/icons/Style';
import AddCircleIcon from '@material-ui/icons/AddCircle';

//inputs
import SwitchInput from '../../Inputs/SwitchInput';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';
import LookupInput from '../../Inputs/LookupInput';
import MetadataEditor from '../../common/MetadataEditor/MetadataEditorComponent'

const report_heading_info = [
	{label: "Payload", value: "table_payload", field: "payload", align: "center", disablePadding: true, sortable: false, content: "function"},
	{label: "Created At", field: "created_at", align: "left", disablePadding: true, sortable: true, content: "date"}
];

class DiscoveredDeviceTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			image: <Loading/>,
			subnav: 0,
			menus: {},
			tabs: null,
			tag_input: "",
			modal: {
				open: false,
				children: () => ""
			},

			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			editable_copy: {},
			updated_discovered_device: null,
			range: 0,
			verifying: false,
			ensuring: true,
			discovered_deviceIsInherited: false,
			can_edit: null,
			can_delete: null,
			show_errors: false,
			sort: null
		};
		this.props.tabHostProxy.setTabRefresh(this.refresh);
		this.load_and_ensure();
	}

	refresh = () => {
		this.setState({ensuring: true, subnav: 0});
		return new DiscoveredDevice({_id: this.state.updated_discovered_device._id}).readFromAPI().then( (result) => {
			let discovered_devices = [result];
			DiscoveredDevice.loadRequirements(discovered_devices).then( (discovered_devices) => {
				this.props.tabHostProxy.updateTitle(discovered_devices[0]._id, discovered_devices[0]);
				this.props.tabHostProxy.setTabRefresh(this.refresh);
				this.ensure_ready(discovered_devices[0]);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_and_ensure() {
		new DiscoveredDevice({_id: this.props.data._id}).readFromAPI().then( (result) => {
			let discovered_devices = [result];
			DiscoveredDevice.loadRequirements(discovered_devices).then( (discovered_devices) => {
				this.ensure_ready(discovered_devices[0]);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
			this.loadRecentReports();
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
			this.props.tabHostProxy.closeSelf();
		});
	}

	ensure_ready = (updated_discovered_device) => {
		this.setState( (state) => {
			state.discovered_deviceIsInherited = updated_discovered_device.nested_company == null;
			state.updated_discovered_device = updated_discovered_device;
			state.can_edit = Permissions.allow(["update"], "discovered_device", updated_discovered_device.company_id) && !state.discovered_deviceIsInherited;
			state.can_delete = Permissions.allow(["delete"], "discovered_device", updated_discovered_device.company_id) && !state.discovered_deviceIsInherited;
			state.tabs = [
				{label: `Overview`, render: this.render_overview},
				{label: `Reports`, render: this.render_reports},
			];
			state.editable_copy = { };
			state.ensuring = false;
			return state;
		});
	}

	loadRecentReports = () => {
		const {classes} = this.props;
		let filter = {
			"device_id" : this.props.data._id
		}
		let params = Object.assign(this.state.page_data, filter, this.state.sort);
		delete params.total; // API doesn't like trying to filter for fields that aren't in the table

		new Report()
			.listFromAPI(params)
			.then((result) => {
				let reports = result.items;
				reports.forEach( (report) => {
					report.table_payload = () => (<div onClick={() => this.openPayloadModal(report.payload, classes)} className={classes.link}>View Payload</div>);
				});
				this.setState({
					recent_reports: reports,
					page_data: {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: result.total
					}
				});
			})
			.catch((error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	render_overview = () => {
		const { updated_discovered_device:discovered_device } = this.state;
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Card className={classes.contentCard}>
					<div className={classes.contentHeader}>
						<div className={classes.contentTitle}>
							Details
						</div>
					</div>
					<div key={"device_overview_location"} className={classes.capabilityPair}>
						<span className={classes.capabilityLabel}>Manufacturer: </span>
						<span className={classes.value}>{discovered_device.manufacturer}</span>
					</div>
					<div key={"device_overview_serial"} className={classes.capabilityPair}>
						<span className={classes.capabilityLabel}>Model: </span>
						<span className={classes.value}>{discovered_device.model}</span>
					</div>
					<div key={"device_overview_location"} className={classes.capabilityPair}>
						<span className={classes.capabilityLabel}>Location: </span>
						<span className={classes.value}>{discovered_device.location}</span>
					</div>
					<div key={"device_overview_serial"} className={classes.capabilityPair}>
						<span className={classes.capabilityLabel}>Serial: </span>
						<span className={classes.value}>{discovered_device.serial}</span>
					</div>
					{this.render_tags_area(classes)}
				</Card>
				<div className={classes.metadataEditor}>
					<MetadataEditor model={discovered_device} can_edit={this.state.can_edit} submitMetadata={this.submit_edit_metadata}/>
				</div>
			</React.Fragment>
		);
	}

	render_tags_area = (classes) => {
		const { updated_discovered_device:discovered_device } = this.state;
		return (
			<div className={classes.iconInputContainer + " " + classes.tagsWrapper}>
				<div className={classes.tagData}>
					<Tooltip title="Tags">
						<StyleIcon className={classes.inputIcon + " " + (discovered_device.tags && discovered_device.tags.length === 0 ? "" : classes.tagsIcon)} />
					</Tooltip>
					{!discovered_device.tags || discovered_device.tags.length === 0 ? <div className={classes.unset}>No Tags</div> : 
					<div>
						{discovered_device.tags.map( (tag) => (
							<Chip clickable={false} classes={{root: classes.chipRoot}} key={tag} label={tag} onDelete={() => this.remove_tag(tag)}/>
						))}
					</div>
					}
				</div>
				<div className={classes.tagLookupContainer}>
					<LookupInput
						priorState={{values: this.state.tag_input, suggestions: this.get_tags()}}
						single
						placeholder="Add Tag"
						emitChange={this.handleTagChange}
						onEnter={this.add_tag}
						onType={this.update_tag}
					/>
					<AddCircleIcon onClick={this.add_tag} className={classes.addTagButton}/>
				</div>
			</div>
		);
	}

	handleTagChange = (input) => {
		this.setState({ tag_input: input.value });
	}

	update_tag = (tag) => {
		this.setState({ tag_input: tag });
	}

	add_tag = () => {
		let tag = this.state.tag_input;
		let saved_discovered_device = { _id: this.state.updated_discovered_device._id };
		let discovered_device = this.state.updated_discovered_device;
		saved_discovered_device.tags = discovered_device.tags;

		if (discovered_device.tags) {
			discovered_device.tags.push(tag);
		} else {
			discovered_device.tags = [];
			discovered_device.tags.push(tag);
		}
		new DiscoveredDevice(saved_discovered_device).saveOrCreate().then( (result) => {
			this.context.openSnackbar("Discovered Device tags saved.", "success");
			this.refresh();
			this.props.tabHostProxy.refresh();
			this.close_modal();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	remove_tag = (tag) => {
		let discovered_device = this.state.updated_discovered_device;
		discovered_device.tags.splice(discovered_device.tags.indexOf(tag), 1);
		new DiscoveredDevice(discovered_device).saveOrCreate().then( (result) => {
			this.context.openSnackbar("Discovered Device tags saved.", "success");
			this.refresh();
			this.props.tabHostProxy.refresh();
			this.close_modal();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	get_tags = () => {
		const { updated_discovered_device:discovered_device } = this.state;
		let tags = discovered_device.nested_company.device_tags;
		if (tags && tags.length > 0) {
			tags = tags.reduce( (new_tags, tag) => {
				if (discovered_device.tags && discovered_device.tags.indexOf(tag) === -1) {
					new_tags.push(({value: tag, label: tag}))
				}
				return new_tags;
			}, []);
		}
		if (tags && tags.length > 0) {
			return tags;
		} else {
			return [];
		}
	}

	openPayloadModal = (payload, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<pre className={classes.statusMessage}>
							{JSON.stringify(payload, null, 2)}
						</pre>
						<div className={classes.buttonContainer}>
							<Button
								color="primary" className={classes.button}
								onClick={this.closeModal}
							>
								CLOSE
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	closeModal = () => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.loadRecentReports();
		});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.loadRecentReports();
		});
	}

	handleChangePage = (event, page) => {
		this.page_change({ page: page + 1, per_page: this.state.page_data.per_page });
	};

	handleChangeRowsPerPage = event => {
		this.page_change({ page: this.state.page_data.page, per_page: event.target.value });
	};

	render_reports = () => {
		const { updated_discovered_device:discovered_device, recent_reports, page_data, modal } = this.state;
		const { classes } = this.props;
		return (
			<React.Fragment>
					{this.state.recent_reports ?
						this.state.recent_reports.length > 0 ?
						<Paper className={classes.container}>
							<div className={classes.paginationContainer}>
								<PaginationContainer
									count={page_data.total}
									rowsPerPage={page_data.per_page}
									currentPage={page_data.page}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</div>
							<div className={classes.contentContainer}>
								<TableList
									noCheckBox={true}
									noPadding={true}
									headings={report_heading_info}
									items={recent_reports}
									page_data={page_data}
									perform_sort={this.perform_sort}
								/>
							</div>
							<SimpleModalWrapped info={modal} closeModal={this.closeModal}>
								{this.state.modal.children(classes)}
							</SimpleModalWrapped>
						</Paper>
						:
						<div className={classes.noData}>
							No Recent Reports.
						</div>
					: <Loading />
					}
			</React.Fragment>
		);
	}

	submit_edit_metadata = (new_discovered_device) => {
		new DiscoveredDevice(new_discovered_device).saveOrCreate().then( (result) => {
			this.close_modal();
			this.context.openSnackbar("Metadata saved successfully.", "success");
			this.refresh();
			this.props.tabHostProxy.refresh();
		}).catch( (error) => {
			this.close_modal();
			this.context.openSnackbar(error, "error");
		});
	}

	on_update_create = () => {
		this.refresh();
		this.props.tabHostProxy.refresh();
		this.close_modal();
	}

	range_display = () => {
		const count = Object.entries(this.state.updated_discovered_device.capabilities.actions).length;
		const from = this.state.range + 1;
		const to = from + 9 > count ? count : from + 9;
		return `${from}-${to} of ${count}`;
	}

	nav_disabled = (change) => {
		const count = Object.entries(this.state.updated_discovered_device.capabilities.actions).length;
		const next = this.state.range + change;
		if (next < 0 || next > count) {
			return true;
		 } else {
			return false;
		 }
	}

	page_change_actions = (change) => {
		const next = this.state.range + change;
		this.setState({range: next});
	}

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	}

	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	}

	toggle = (field) => {
		this.setState( (state) => {
			state.editable_copy.actions[field] = !state.editable_copy.actions[field];
			return state;
		});
	}

	save_actions = () => {
		this.setState({verifying: true});
		let saved_discovered_device = { _id: this.state.updated_discovered_device._id, capabilities: this.state.updated_discovered_device.capabilities };
		let discovered_device = this.state.updated_discovered_device;
		saved_discovered_device.capabilities.actions = this.state.editable_copy.actions;
		new DiscoveredDevice(saved_discovered_device).saveOrCreate().then( (result) => {
			this.context.openSnackbar("Discovered Device abilities saved successfully.", "success");
			this.refresh();
			this.props.tabHostProxy.refresh();
			this.close_modal();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_modal_buttons = (onClick) => {
		const classes = this.props.classes;
		return (
			<div className={classes.modalButtonContainer}>
				<Button
					color="primary"
					className={classes.buttonOverride}
					onClick={this.close_modal}
					aria-label="cancel"
				>
					Cancel
				</Button>
				<Button
					onClick={onClick}
					disabled={this.state.verifying}
					variant="contained"
					color="primary"
					size="large"
					className={classes.buttonOverride}
					aria-label="create"
				>
					Save Changes
				</Button>
			</div>
		)
	}

	render_detail_area = () => {
		const { classes } = this.props;
		const { image, updated_discovered_device:discovered_device } = this.state;
		let discovered_device_icon = null;
		let discovered_device_text = null;
		let status = discovered_device.heartbeat_status;
		const account_text = discovered_device.nested_company ? discovered_device.nested_company.name : "Inherited";
		return (
			<div className={classes.detailArea}>
				<div className={classes.detailContainer}>
					<div className={classes.nameContainer}>
						<div className={classes.name}>
							<HeartbeatStatus status={status} className={classes.heartBeatstatusDot}/>
							{discovered_device.name}
						</div>
					</div>
					<div className={classes.infoContainer}>
						<div className={classes.detailAreaColumn}>
							<div className={classes.makeModel}>
								Unique ID: <span className={classes.value}>{discovered_device.unique_id}</span>
							</div>
							<div className={classes.makeModel}>
								ID: <span className={classes.value}>{discovered_device._id}</span>
							</div>
							<div className={classes.makeModel}>
								Account: <span className={classes.value}>{account_text}</span>
							</div>
						</div>
						<div className={classes.detailAreaColumn}>
							<div className={classes.iconLabelPair}>
								{discovered_device_icon}
								<span className={classes.detailValue}>{discovered_device_text}</span>
							</div>
						</div>
						{this.render_buttons()}
					</div>
				</div>
			</div>
		);
	}

	render_buttons = () => {
		const { classes } = this.props;
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={this.delete_discovered_device}
					variant="contained"
					color="primary"
					className={classes.buttonOverride}
					disabled={!this.state.can_delete}
				>
					<DeleteIcon/>
				</Button>
			</div>
		);
	}

	delete_discovered_device = () => {
		this.setState({
			modal: {
				open: true,
				prompt: `Are you sure you want to delete this discovered device?`,
				yesFunction: () => this.submit_delete_discovered_device(),
				functionText: "Delete",
				children: () => {}
			}
		});
	}

	submit_delete_discovered_device = () => {
		this.setState({verifying: true});
		new DiscoveredDevice({_id: this.state.updated_discovered_device._id}).deleteFromAPI().then( (result) => {
			this.context.openSnackbar("Discovered device successfully deleted.", "success");
			this.props.tabHostProxy.refresh();
			this.close_modal();
			this.props.tabHostProxy.closeSelf();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_sub_detail_area = () => {
		const { classes } = this.props;
		const { subnav, ingestors, policies, software_updates, tabs } = this.state;
		const loading = ( ingestors === null || policies === null || software_updates === null || tabs === null) ? true : false;
		return (
			<div className={classes.subdetailArea}>
				<div className={classes.verticalNavContainer}>
					<VerticalTabs tabs={this.state.tabs} onChange={this.subnav_change}/> 
				</div>
				<div className={classes.content}>
					{loading ? <Loading /> : this.state.tabs[subnav].render()}
				</div>
			</div>
		);
	}

	subnav_change = (newTab) => {
		this.setState( (state) => {
			const new_subnav = this.state.tabs.findIndex( ({label}) => label === newTab.label);
			state.subnav = new_subnav;
			return state;
		});
	}

	close_modal = () => {
		this.setState({show_errors: false, verifying: false, modal: {open: false, children: () => ""}});
	}

	render() {
		const { classes } = this.props;
		if (this.state.ensuring) return <Loading />;
		return (
			<div className={classes.topContainer}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.container}>
					{this.render_detail_area()}
					{this.render_sub_detail_area()}
				</div>
			</div>
		);
	}
}

DiscoveredDeviceTab.contextType = SnackbarContext;

const styles = (theme) => {
	return ({
		paginationContainer: {
			width: "100%"
		},
		modalWrapper: {
			padding: "24px"
		},
		statusMessage: {
			color: "silver",
			padding: "12px",
			backgroundColor: "black",
			fontFamily: "monospace",
			overflowX: "auto",
			"&::-webkit-scrollbar-track": {
				borderRadius: "10px",
			},
			"&::-webkit-scrollbar": {
				width: "12px",
			},
			"&::-webkit-scrollbar-thumb": {
				borderRadius: '10px',
				backgroundColor: "#ffffffc2"
			}
		},
		link: {
			color: theme.palette.pending.main,
			"-webkit-text-decoration": "underline transparent",
			textDecoration: "underline transparent",
			transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			"&:hover": {
				"-webkit-text-decoration-color": theme.palette.pending.main,
				textDecorationColor: theme.palette.pending.main,
				cursor: "pointer",
			}
		},
		topContainer: {
			display: "flex",
			width: "100%"
		},
		container: {
			fontFamily: "Inter",
			flexGrow: 2,
			display: "flex",
			flexWrap: "wrap",
			overflow: "hidden",
		},
		detailArea: {
			display: "flex",
			height: "140px",
			width: "100%",
			padding: "12px",
			boxSizing: "border-box"
		},
		tagsWrapper: {
			flexWrap: "wrap",
			borderTop: "solid lightgrey 1px",
			paddingTop: "12px"
		},
		tagData: {
			width: "100%",
			display: "flex",
			alignItems: "center"
		},
		tagLookupContainer: {
			display: "flex",
			width: "100%",
			alignItems: "center"
		},
		addTagButton: {
			cursor: "pointer"
		},
		tagsIcon: {
			paddingTop: "4px"
		},
		nameContainer: {
			width: "100%",
		},
		infoContainer: {
			width: "100%",
			display: "flex",
		},
		detailAreaColumn: {
			width: "40%"
		},
		subdetailArea: {
			height: "calc(100% - 180px)",
			width: "100%",
			display: "flex",
		},
		name: {
			fontSize: "24px",
			marginBottom: "12px",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis"
		},
		makeModel: {
			margin: "8px 0"
		},
		unset: {
			fontStyle: "italic"
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		editButton: {
			backgroundColor: theme.palette.green.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.green.main, .2),
			},
		},
		deleteButton: {
			backgroundColor: theme.palette.red.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.red.main, .2),
			},
		},
		addButton: {
			backgroundColor: theme.palette.pending.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.pending.main, .2),
			},
			width: "25%"
		},
		titleButton: {
			backgroundColor: theme.palette.pending.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.pending.main, .2),
			},
		},
		contentContainer: {
			overflowY: "auto",
			height: "calc(100% - 57px)",
			width: "100%",
			backgroundColor: "white"
		},
		buttonContainer: {
			width: "20%",
			alignItems: "center",
			display: "flex",
			flexWrap: "wrap",
			paddingLeft: "12px",
			justifyContent: "center",
		},
		verticalNavContainer: {
			display: "flex",
			width: "15%",
			boxSizing: "border-box",
			marginTop: "-1px",
			backgroundColor: "white",
			zIndex: 5,
			borderRight: "solid lightgrey 1px",
		},
		content: {
			overflowY: "auto",
			padding: "12px",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "85%",
			backgroundColor: "#f5f5f7",
			boxSizing: "border-box",
			borderTop: "solid lightgrey 1px",
			marginTop: "-1px"
		},
		imagePlaceholder: {
			height: "140px",
			width: "140px",
			color: "grey",
			border: "solid lightgrey 2px",
			borderRadius: "4px",
		},
		image: {
			maxHeight: "140px",
			maxWidth: "140px",
			border: "solid lightgrey 2px",
			borderRadius: "4px",
		},
		imageContainer: {
			display: "flex",
			alignItems: "center",
			marginRight: "12px"
		},
		detailContainer: {
			width: "100%",
			overflow: "hidden"
		},
		buttonIcon: {
			marginRight: "8px"
		},
		iconLabelPair: {
			display: "flex",
			alignItems: "center",
			margin: "8px 0",
			color: "#8e8e93"
		},
		value: {
			color: "#8e8e93",
			fontSize: "14px",
		},
		infoIcon: {
			marginRight: "8px"
		},
		detailValue: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			fontSize: "14px",
		},
		notFound: {
			color: theme.palette.red.main
		},
		unset: {
			fontStyle: "italic",
			paddingRight: "2px"
		},
		tableMenu: {
			color: "grey",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		addButtonContainer: {
			padding: "12px 0",
			textAlign: "right"
		},
		contentCard: {
			padding: "12px",
			marginBottom: "12px"
		},
		first: {
			borderTop: "solid lightgrey 2px"
		},
		contentTitle: {
			fontSize: "16px"
		},
		contentHeader: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			margin: "-12px -12px 12px -12px",
			padding: "12px",
			borderBottom: "solid lightgrey 1px",
		},
		contentHeaderNoMargin: {
			margin: 0,
			border: "none"
		},
		warningIcon: {
			color: theme.palette.caution.main,
			marginRight: "8px"
		},
		warningContainer: {
			borderBottom: "solid black 2px",
			padding: "12px",
			margin: "12px",
			display: "flex",
			alignItems: "center"
		},
		modalContainer: {
			width: "100%",
			height: "505px",
			overflowY: "auto",
		},
		modalTitle: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
		},
		switchWrapper: {
			width: "50%",
			margin: "auto"
		},
		modalButtonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			width: "50%",
			margin: "32px 0 0 auto"
		},
		cancelButton: {
			color: "grey"
		},
		switchNavContainer: {
			alignItems: "center",
			display: "flex",
			justifyContent: "flex-end",
			marginRight: "25%",
			fontSize: "14px",
			color: "#636366",
		},
		iconButtonNav: {
			padding: "4px",
			marginLeft: "8px",
		},
		switchItems: {
			height: "calc(80vh - 250px)",
			overflow: "auto"
		},
		secondaryButton: {
			margin: "0 12px 12px 12px",
			width: "15%",
		},
		newConnectionContainer: {
			height: "312px",
		},
		connectionWrapper: {
			marginTop: "52px",
		},
		deleteIconButton: {
			padding: "4px",
			"&:hover": {
				color: "#0263fc",
			}
		},
		errorText: {
			color: theme.palette.red.main
		},
		disabledTab: {
			color: "red"
		},
		metadataEditor: {
			width: "98%"
		},
		noConnection: {
			fontSize: "14px",
		},
		capabilityLabel: {
			marginRight: "8px"
		},
		capabilityPair: {
			margin: "12px"
		},
		first: {
			borderTop: "solid lightgrey 2px"
		},
		heartBeatstatusDot: {
			width: "16px",
			height: "16px",
			minWidth: "16px",
			minHeight: "16px",
			maxWidth: "16px",
			maxHeight: "16px",
			borderRadius: "50%",
			display: "inline-flex",
			marginRight: "12px",
			color: 'white'
		},
	});
};

export default compose(
	withStyles(styles),
	withTheme(),
)(DiscoveredDeviceTab);
