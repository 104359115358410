import React from 'react';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import CreateCommandForm from '../CommandSpecific/CreateCommandForm';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';
import Command from '../../services/DataModels/Command';
import Device from '../../services/DataModels/Device';
import { GetAll } from '../../services/CLURDUtilities';

//mui
import { darken } from '@material-ui/core/styles/colorManipulator';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

//icons
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AceEditor from "react-ace";

//inputs
import LookupInput from '../Inputs/LookupInput';
import { ADD_RELATIONS, GET_RELATIONS, REMOVE_RELATION } from '../../services/Relation';

class DeviceCommands extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.startingPerPage = 12;
        this.state = {
            commands: null,
            menus: {},
            modal: {
                open: false,
                children: () => "",
            },
            execute_payload: "",
            execute_command: {},
            show_errors: false
        };
        this.load_commands();
    }

    load_commands = () => {
        GET_RELATIONS("devices", this.props.device._id, "commands").then((commands) => {
            Command.loadRequirements(commands).then(() => {
                this.setState((state) => {
                    state.commands = commands;
                    return state;
                });
            }).catch((error) => {
                this.context.openSnackbar(error, 'error');
            });
        }).catch((error) => {
            this.context.openSnackbar(error, 'error');
        });
    }

    render_menu = (command, table) => {
        let anchorEl = this.state.menus[command._id];
        let open = Boolean(anchorEl);
        let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, command._id)} /> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, command._id)} />;
        return (
            <React.Fragment>
                {icon}
                {this.render_action_menu(open, anchorEl, command)}
            </React.Fragment>
        );
    }

    close_action_menu = (id) => {
        this.setState((state) => {
            state.menus[id] = null;
            return state;
        });
    }

    render_action_menu = (open, anchorEl, command) => {
        const { classes } = this.props;
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={() => this.close_action_menu(command._id)}
                onClose={() => this.close_action_menu(command._id)}
                PaperProps={{
                    style: { overflow: "visible" }
                }}
            >
                <div className={classes.actionListTitle}>
                    Perform Action...
                </div>
                <div className={classes.noOutline} onClick={(event) => this.executeModal(event, command)}>
                    <MenuItem disabled={!this.can_edit(command) || Boolean(command.error)} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <ArrowDownwardIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Execute Command
                        </Typography>
                    </MenuItem>
                </div>
                <div className={classes.noOutline} onClick={(event) => this.can_edit(command) && this.edit(event, command)}>
                    <MenuItem disabled={!this.can_edit(command) || Boolean(command.error)} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            View/Edit Command
                        </Typography>
                    </MenuItem>
                </div>
                <div className={classes.noOutline} onClick={(event) => this.can_edit_device() && this.remove_command(command)}>
                    <MenuItem disabled={!this.can_edit_device()} className={classes.actionMenuItem}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Remove Command
                        </Typography>
                    </MenuItem>
                </div>
            </Menu>
        );
    }
    close_modal = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    remove_command = (command) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to remove this command? It will not delete the command.`,
                yesFunction: () => this.submit_remove_command(command._id),
                functionText: "Remove",
                children: () => { }
            }
        });
    }

    submit_remove_command = (command_id) => {
        this.close_modal();
        REMOVE_RELATION("devices", this.props.device._id, "commands", command_id).then((result) => {
            this.props.tabHostProxy.closeSelf();
            this.props.tabHostProxy.addTab("device", this.props.device);
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar('Command successfully removed.', 'success');
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    on_save = () => {
        this.load_commands();
        this.close_modal();
    }

    edit = (event, command) => {
        const edit_flow =
            <CreateCommandForm
                onCreate={this.on_save}
                onCancel={this.close_modal}
                command={command}
            />;
        this.setState({
            modal: {
                open: true,
                children: (classes) =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Editing {command.name}
                        </div>
                        <div className={classes.editFormContainer}>
                            {edit_flow}
                        </div>
                    </div>
            }
        });
    }

    executeCommand = (event) => {
        event.preventDefault();
        Device.executeCommand(this.props.device._id, this.state.execute_command._id, this.state.execute_payload).then((result) => {
            this.context.openSnackbar('Command sent to device', 'success');
        }).catch((error) => {
            this.context.openSnackbar(error, 'error');
        });

        this.close_modal();
    }

    on_payload_change = (payload) => {
        this.setState((state) => {
            state.execute_payload = payload;
            return state;
        });
    }

    executeModal = (event, command) => {

        this.setState({
            execute_command: command,
            modal: {
                open: true,
                children: (classes) =>
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalTitle}>
                            Execute {command.name}
                        </div>
                        <form onSubmit={this.executeCommand}>
                            <div className={classes.editFormContainer}>
                                <div className={classes.prompt}>
                                    Payload
                                </div>
                                <div className={classes.textInputWrapper}>

                                    <AceEditor
                                        onChange={this.on_payload_change}
                                        value={this.state.execute_payload}
                                        placeholder="Payload JSON"
                                        mode="javascript"
                                        theme="monokai"
                                        fontSize={14}
                                        showPrintMargin={true}
                                        showGutter={true}
                                        padding={"12px"}
                                        highlightActiveLine={true}
                                        width={"100%"}
                                        height={"400px"}
                                        setOptions={{
                                            enableBasicAutocompletion: false,
                                            enableLiveAutocompletion: false,
                                            enableSnippets: false,
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }}
                                    />
                                </div>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        onClick={this.close_modal}
                                        color="primary"
                                        className={classes.buttonOverride}
                                        aria-label="cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.buttonOverride}
                                        aria-label="execute"
                                        type="submit"
                                    >
                                        Execute Command
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
            }
        });
    }

    can_edit = (command) => {
        return Permissions.allow(["update"], "command", command.company_id);
    }

    can_edit_device = () => {
        return Permissions.allow(["update"], "device", this.props.device.company_id);
    }

    open_action_menu = (event, id) => {
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }


    get_buttons = () => {

    }

    get_heading = () => {
        return [
            { label: "Name", value: "name", field: "name", align: "left", sortable: false },
            { label: "Listener Type", field: "listener_display", align: "left", sortable: false },
            { label: "Handler Type", field: "handler_display", align: "left", sortable: false },
            { label: "Translator", field: "translator_display", align: "left", sortable: false },
            { label: "Action", field: "action", align: "center", sortable: false }
        ];
    }

    submit_add_command = () => {
        this.setState({ verifying: true });
        if (this.state.new_command.values.length === 0) {
            this.setState({ show_errors: true, verifying: false });
            return;
        }
        let command_ids = this.state.new_command.values.map(({ value }) => value);
        ADD_RELATIONS("devices", this.props.device._id, "commands", command_ids).then((result) => {
            this.close_modal();
            this.props.tabHostProxy.closeSelf();
            this.props.tabHostProxy.addTab("device", this.props.device);
            this.props.tabHostProxy.refresh();
            this.context.openSnackbar("Command(s) successfully added.", "success");
        }).catch((error) => {
            this.close_modal();
            this.context.openSnackbar(error, "error");
        });
    }

    load_command_suggestions = () => {
        return new Promise((resolve, reject) => {
            let params = {};
            GET_RELATIONS("devices", this.props.device._id, "commands").then((commands) => {
                if (commands.length > 0) {
                    let ids = commands.map(function (command) {
                        return command._id
                    });
                    params._id_nin = ids.join(",");
                }
                GetAll("commands", params).then((commands) => {
                    this.setState({ new_command: { values: [], suggestions: commands } }, resolve);
                }).catch((error) => {
                    reject();
                });
            }).catch((error) => {
                reject();
            });
        });
    }

    add_command = () => {
        const classes = this.props.classes;
        const update_new_command = ({ value }) => {
            this.setState((state) => {
                state.new_command.values = value;
                return state;
            });
        }
        this.load_command_suggestions().then(() => {
            this.setState({
                modal: {
                    open: true,
                    children: () =>
                        <div className={classes.modalWrapper}>
                            <div className={classes.modalTitle}>
                                Attach Commands to this Device
                            </div>
                            <div className={classes.newConnectionContainer}>
                                {this.state.new_command.suggestions.length === 0 ?
                                    <span>There are no commands available. Go to the Command page to create some.</span> :
                                    <LookupInput
                                        priorState={{ values: this.state.new_command.values, suggestions: this.state.new_command.suggestions.map((m) => ({ label: m.name, value: m._id })) }}
                                        placeholder="Select Commands*"
                                        label="Commands"
                                        emitChange={update_new_command}
                                        error={this.state.show_errors && this.state.new_command.values.length === 0}
                                        error_message={"Please select at least one command."}
                                    />}
                            </div>
                            {this.render_modal_buttons(this.submit_add_command)}
                        </div>
                }
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    render_modal_buttons = (onClick) => {
        const classes = this.props.classes;
        return (
            <div className={classes.modalButtonContainer}>
                <Button onClick={this.close_modal} aria-label="cancel" color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={onClick}
                    disabled={this.state.verifying}
                    className={classes.editButton}
                    aria-label="create"
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Attach Command
                </Button>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const { commands, page_data, modal } = this.state;
        if (commands === null) return <Loading />;
        const buttons = this.get_buttons();
        const heading_info = this.get_heading();
        const commands_prepped = Command.prepareForTable(commands, this.render_menu, this.props.classes);
        return (
            <Paper className={classes.container}>
                <React.Fragment>
                    <SimpleModalWrapped info={modal} closeModal={this.close_modal}>
                        {modal.children(classes)}
                    </SimpleModalWrapped>
                    <div className={classes.contentContainer}>
                        <div className={classes.addButtonContainer}>
                            <Button
                                onClick={this.add_command}
                                className={classes.titleButton}
                                disabled={!this.can_edit_device()}
                                variant="contained"
                                color="primary"
                            >
                                <AddIcon className={classes.buttonIcon} />
                                Add Command
                            </Button>
                        </div>
                        <TableList
                            headings={heading_info}
                            items={commands_prepped}
                            noCheckBox
                            noBoxShadow
                            perPage={5}
                        />
                    </div>
                </React.Fragment>
            </Paper>
        );
    }
}
const styles = theme => ({
    actionListTitle: {
        marginBottom: "8px",
        padding: "11px 16px",
        borderBottom: "solid #80808073 1px",
        backgroundColor: "white",
        cursor: "unset",
        '&:hover': {
            backgroundColor: "white",
            cursor: "unset",
        },
        outline: "none",
        fontFamily: "Inter",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        width: "auto",
        height: "24px",
        whiteSpace: "nowrap",
        boxSizing: "content-box",
        fontWeight: 400,
        lineHeight: "1.5em"
    },
    noOutline: {
        outline: "none",
        "&:focus": {
            outline: "none"
        }
    },
    actionMenuItem: {
        outline: "none",
    },
    modalWrapper: {
        fontFamily: "Inter",
        minHeight: "450px",
        maxHeight: "643px",
        boxSizing: "border-box",
    },
    modalTitle: {
        fontSize: "20px",
        lineHeight: "32px",
        fontWeight: "700",
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom: "32px",
    },
    container: {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexWrap: "wrap",
    },
    contentContainer: {
        overflowY: "auto",
        height: "calc(100% - 57px)",
        width: "100%",
    },
    tableMenu: {
        color: "grey",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.pending.main
        }
    },
    notFound: {
        color: theme.palette.red.main
    },
    unset: {
        fontStyle: "italic",
        paddingRight: "2px"
    },
    addButtonContainer: {
        textAlign: "right",
        borderBottom: "solid lightgrey 1px",
    },
    buttonIcon: {
        marginRight: "8px"
    },
    titleButton: {
        margin: "12px"
    },
    newConnectionContainer: {
        height: "320px",
    },
    modalButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "24px 0 0 auto"
    },
    editButton: {
        marginLeft: "8px",
    },
    editFormContainer: {
        maxHeight: "574px",
        height: "574px",
        overflowY: "auto",
        paddingTop: "5px",
        paddingRight: "16px",
    },
    prompt: {
        fontSize: "20px",
        lineHeight: "32px",
        fontWeight: "700",
        color: "rgba(0, 0, 0, 0.87)",
        marginBottom: "32px",
    },
    buttonContainer: {
        marginTop: "24px",
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonOverride: {
        marginLeft: "8px"
    },
    textInputWrapper: {
        margin: "0 0 8px 0px",
        display: "flex",
        alignItems: "center"
    }
});

DeviceCommands.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceCommands));