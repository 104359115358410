import React from 'react';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';
import Device from '../../services/DataModels/Device';
import { GetAll } from '../../services/CLURDUtilities';

//mui
import { darken } from '@material-ui/core/styles/colorManipulator';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

//icons
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

//inputs
import LookupInput from '../Inputs/LookupInput';

//common 
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'
class DeviceAttachedDevices extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			attached_devices: null,
			menus: {},
			modal: {
				open: false,
				children: () => "",
			},
			show_errors: false
		};
		this.load_attached_devices();
	}

	load_attached_devices = () => {
		const attached_device_ids = this.props.device.attached_device_ids;
		let params = {};
		if ( Boolean(attached_device_ids) && Array.isArray(attached_device_ids) && attached_device_ids.length > 0 ) {
			params._id_in = attached_device_ids;
			params.per_page = attached_device_ids.length;
		} else {
			this.state.attached_devices = [];
			return;
		}
		new Device().listFromAPI(params).then( (result) => {
			let attached_devices = result.items;
			Device.loadRequirements(attached_devices).then( () => {
				this.setState( (state) => {
					state.attached_devices = attached_devices;
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
			});
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	render_menu = (attached_device, table) => {
		let anchorEl =  this.state.menus[attached_device._id];
		let open = Boolean(anchorEl);
		let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, attached_device._id)}/> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, attached_device._id)}/>;
		return (
			<React.Fragment>
				{icon}
				{this.render_action_menu(open, anchorEl, attached_device)}
			</React.Fragment>
		);
	}

	close_action_menu = (id) => {
		this.setState( (state) => {
			state.menus[id] = null;
			return state;
		});
	}

	render_action_menu = (open, anchorEl, attached_device) => {
		const { classes } = this.props;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={() => this.close_action_menu(attached_device._id)}
				onClose={() => this.close_action_menu(attached_device._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit(attached_device) && this.edit(event, attached_device)}>
					<MenuItem disabled={!this.can_edit(attached_device) || Boolean(attached_device.error)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							View/Edit Device
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit_device() && this.remove_attached_device(attached_device)}>
					<MenuItem disabled={!this.can_edit_device()} className={classes.actionMenuItem}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Detach Device
						</Typography>
					</MenuItem>
				</div>
			</Menu>
		);
	}
	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	remove_attached_device = (attached_device) => {
		this.setState({
			modal: {
				open: true,
				prompt: `Are you sure you want to remove this attached device? It will not delete the device.`,
				yesFunction: () => this.submit_remove_attached_device(attached_device._id),
				functionText: "Remove",
				children: () => {}
			}
		});
	}

	submit_remove_attached_device = (_id) => {
		this.close_modal();
		const device = this.props.device;
		let new_device = { _id: device._id, attached_device_ids: JSON.parse(JSON.stringify(device.attached_device_ids)) };
		let rem_index = device.attached_device_ids.findIndex( (id) => id === _id);
		new_device.attached_device_ids.splice(rem_index, 1);
		new Device(new_device).saveOrCreate().then( (result) => {
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("device", result);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar("Device successfully removed.", "success");
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	on_save = () => {
		this.load_attached_devices();
		this.close_modal();
	}

	edit = (event, attached_device) => {
		this.props.tabHostProxy.addTab("device", attached_device);
	}

	can_edit = (attached_device) => {
		return Permissions.allow(["update"], "device", attached_device.company_id);
	}

	can_edit_device = () => {
		return Permissions.allow(["update"], "device", this.props.device.company_id);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState( (state) => {
			state.menus[id] = element;
			return state;
		});
	}


	get_buttons = () => {

	}

	get_heading = () => {
		return [
			{label: "Status", value: "table_heartbeat", field: "status_display", align: "left", sortable: false, content: "function"},
			{label: "Name", value: "table_name", field: "name", align: "left", sortable: false, content: "function"},
			{label: "Unique ID", value:"unique_id", field: "unqiue_id_display", align: "left", sortable: false},
			{label: "Action", field: "action", align: "center", sortable: false}
		];
	}

	submit_add_attached_device = () => {
		this.setState({verifying: true});
		if (this.state.new_attached_device.values.length === 0) {
			this.setState({show_errors: true, verifying: false});
			return;
		}
		const device = this.props.device;
		let new_device = { _id: device._id, attached_device_ids: JSON.parse(JSON.stringify(device.attached_device_ids || [])) };
		new_device.attached_device_ids = new_device.attached_device_ids.concat(this.state.new_attached_device.values.map( ({value}) => value));
		new Device(new_device).saveOrCreate().then( (result) => {
			this.close_modal();
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("device", result);
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar("Device(s) successfully attached.", "success");
		}).catch( (error) => {
			this.close_modal();
			this.context.openSnackbar(error, "error");
		});
	}

	load_attached_device_suggestions = () => {
		//TODO: figure out a way to exclude non attachable devices like gateways and cloud native devices
		const device = this.props.device;
		return new Promise( (resolve, reject) => {
			let body = {company_id: device.company_id};
			let excluded_ids = [device._id];
			if (Boolean(device.attached_device_ids) && Array.isArray(device.attached_device_ids) && device.attached_device_ids.length > 0) {
				excluded_ids = excluded_ids.concat(device.attached_device_ids);
			}
			body._id_nin = excluded_ids.join(",");
			GetAll("devices", body).then( (attached_devices) => {
				this.setState({new_attached_device: {values: [], suggestions: attached_devices}}, resolve);
			}).catch( (error) => {
				reject();
			});
		});
	}

	add_attached_device = () => {
		const classes = this.props.classes;
		const update_new_attached_device = ({value}) => {
			this.setState( (state) => {
				state.new_attached_device.values = value;
				return state;
			});
		}
		this.load_attached_device_suggestions().then( () => {
			this.setState({
				modal: {
					open: true,
					children: () =>
						<div className={classes.modalWrapper}>
							<div className={classes.modalTitle}>
								Attach Endpoint Devices to this Device
							</div>
							<div className={classes.newConnectionContainer}>
								{this.state.new_attached_device.suggestions.length === 0 ?
									<span>There are no devices available to attach. Go to the Devices page to create some.</span> :
									<LookupInput
										priorState={{values: this.state.new_attached_device.values, suggestions: this.state.new_attached_device.suggestions.map( (m) => ({label: m.name, value: m._id}))}}
										placeholder="Select Endpoints*"
										label="Endpoints"
										emitChange={update_new_attached_device}
										error={this.state.show_errors && this.state.new_attached_device.values.length === 0}
										error_message={"Please select at least one attached device."}
									/>}
							</div>
							{this.render_modal_buttons(this.submit_add_attached_device)}
						</div>
				}
			});
		}).catch( (error) => {
			console.log(error);
			this.context.openSnackbar(error, "error");
		});
	}

	render_modal_buttons = (onClick) => {
		const classes = this.props.classes;
		return (
			<div className={classes.modalButtonContainer}>
				<Button onClick={this.close_modal} aria-label="cancel" color="primary">
					Cancel
				</Button>
				<Button
					onClick={onClick}
					disabled={this.state.verifying}
					className={classes.editButton}
					aria-label="create"
					variant="contained"
					color="primary"
					size="large"
				>
					Attach Endpoint
				</Button>
			</div>
		)
	}


	prepare_attached_devices = (attached_devices, render_menu, classes) => {
		return attached_devices.map( (device) => {
			let row = {...device}; // row is now a shallow copy of device
			row.table_name = () => (<div onClick={() => this.props.tabHostProxy.addTab("device", device)} className={classes.link}>{device.name}</div>);
			row.table_heartbeat = () => (this.render_table_heartbeat(device));
			row.action = (<span className={classes.tableMenu}>{render_menu(device, true)}</span>);
			return row;
		});
	}

	render_table_heartbeat = (device) => {
		const classes = this.props.classes;
		const display = device.heartbeat_status.replace(/_/g, " ");
		return (
			<div style={{display: "flex", alignItems: "center"}}>
				<HeartbeatStatus status={device.heartbeat_status} className={classes.heartBeatstatusDot}/>
				<div style={{textTransform: "capitalize"}}>
					{display}
				</div>
			</div>
		);
	}


	render() {
		const { classes } = this.props;
		const { attached_devices, page_data, modal } = this.state;
		if (attached_devices === null) return <Loading />;
		const buttons = this.get_buttons();
		const heading_info = this.get_heading();
		const attached_devices_prepped = this.prepare_attached_devices(attached_devices, this.render_menu, this.props.classes);
		return (
			<Paper className={classes.container}>
				<React.Fragment>
					<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
						{modal.children(classes)}
					</SimpleModalWrapped>
					<div className={classes.contentContainer}>
						<div className={classes.addButtonContainer}>
							<Button onClick={this.add_attached_device} className={classes.titleButton} disabled={!this.can_edit_device()} variant="contained" color="primary">
								<AddIcon className={classes.buttonIcon}/>
								Attach Endpoint
							</Button>
						</div>
						<TableList
							headings={heading_info}
							items={attached_devices_prepped}
							noCheckBox
							noBoxShadow
							perPage={25}
						/>
					</div>
				</React.Fragment>
			</Paper>
		);
	}
}
const styles = theme => ({
	actionListTitle: {
		marginBottom: "8px",
		padding: "11px 16px",
		borderBottom: "solid #80808073 1px",
		backgroundColor: "white",
		cursor: "unset",
		'&:hover': {
			backgroundColor: "white",
			cursor: "unset",
		},
		outline: "none",
		fontFamily: "Inter",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "1rem",
		width: "auto",
		height: "24px",
		whiteSpace: "nowrap",
		boxSizing: "content-box",
		fontWeight: 400,
		lineHeight: "1.5em"
	},
	noOutline: {
		outline: "none",
		"&:focus": {
			outline: "none"
		}
	},
	actionMenuItem: {
		outline: "none",
	},
	modalWrapper: {
		fontFamily: "Inter",
		minHeight: "450px",
		maxHeight: "615px",
		boxSizing: "border-box",
	},
	modalTitle: {
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
	},
	container: {
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
	},
	contentContainer: {
		overflowY: "auto",
		height: "calc(100% - 57px)",
		width: "100%"
	},
	tableMenu: {
		color: "grey",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.pending.main
		}
	},
	notFound: {
		color: theme.palette.red.main
	},
	unset: {
		fontStyle: "italic",
		paddingRight: "2px"
	},
	addButtonContainer: {
		textAlign: "right",
		borderBottom: "solid lightgrey 1px",
	},
	buttonIcon: {
		marginRight: "8px"
	},
	titleButton: {
		margin: "12px"
	},
	newConnectionContainer: {
		marginTop: "32px",
		height: "320px",
	},
	modalButtonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "24px 0 0 auto"
	},
	editButton: {
		marginLeft: "8px",
	},
	heartBeatstatusDot: {
		marginRight: "4px",
		minWidth: "10px",
		width: "10px",
		height: "10px",
		borderRadius: "50%",
		color: 'white'
	},
	link: {
		color: theme.palette.pending.main,
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": theme.palette.pending.main,
			textDecorationColor: theme.palette.pending.main,
			cursor: "pointer",
		}
	},
});

DeviceAttachedDevices.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceAttachedDevices));
