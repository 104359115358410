import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import ContentLoader from "react-content-loader"
import PaginationContainer from '../Containers/PaginationContainer';
import Loading from '../DisplayOriented/Loading';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import EnhancedTable from '../Table/EnhancedTable';
import DeviceCreationFlow from './DeviceCreationFlow';
import CSVUploadForm from './CSVUploadForm';
import CSVCommandForm from './CSVCommandForm';
import DownloadReportChooser from './DownloadReportChooser';
import VerticalTabs from '../DisplayOriented/VerticalTabs';
import BulkEditDevices from './BulkEditDevices';
import TableList from '../Table/TableList';
import SelectInput from '../Inputs/SelectInput';
import EscrowForm from '../DeviceSpecific/EscrowForm';
import TransferRequestForm from '../DeviceSpecific/TransferRequestForm';
import TransferRequestFlow from '../DeviceSpecific/TransferRequestFlow';
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'

//mui
import {withStyles, withTheme} from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';

//icons
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import StyleIcon from '@material-ui/icons/Style';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import SignalCellularOffIcon from '@material-ui/icons/SignalCellularOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import RouterIcon from '@material-ui/icons/Router';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SwapHorizIcon from '@material-ui/icons/SwapHorizOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HealthStatusIcon from '@material-ui/icons/LocalHospital';

//services
import { GetAll, AssignNestedModels } from '../../services/CLURDUtilities';
import Humanize from '../../services/Humanize';
import Permissions from '../../services/Permissions';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import WorkflowDefinition from '../../services/DataModels/WorkflowDefinition';
import EscrowDevice from '../../services/DataModels/EscrowDevice';
import DeviceTransferRequest from '../../services/DataModels/DeviceTransferRequest';
import { BULK_COMMAND, BULK_EDIT, BULK_WORKFLOW } from '../../services/CLURD';
import Auth from '../../services/Auth';
import { ADD_RELATIONS } from '../../services/Relation';

const ButtonInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing.unit * 3,
		},
	},
	input: {
		display: "flex",
		alignItems: "center",
		padding: "8px 16px",
		paddingRight: "24px",
		fontSize: "0.875rem",
		minWidth: "64px",
		boxSizing: "border-box",
		minHeight: "36px",
		borderTopRightRadius: 0,
		height: "100%",
		borderBottomRightRadius: 0,
		borderRadius: "4px",
		color: "white",
		maxHeight: "40px",
		boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)",
		position: 'relative',
		backgroundColor: theme.palette.primary.main,
		width: 'auto',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			color: "white",
		},
		'&:hover': {
			backgroundColor: darken(theme.palette.primary.main, .2),
			color: "white",
		},
	},
}))(InputBase);

class DevicesList extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this._ismounted = false;
		this.state = {
			action: "Select Action",
			modal: {
				open: false,
				singleDevice: {},
				children: () => ""
			},
			bulk_loading: false,
			bulk_response: null,
			bulk_devices: null,
			bulk_configure_shown: false,
			layout: "card",
			command_error: null,
			menus: {},
			selected_ids: [],
			workflow_definitions: []
		};
		this.heading_info = [
			{label: "Select", field: "select", align: "center", disablePadding: false, sortable: false, content: "function"},
			{label: "Online", value: "table_heartbeat", field: "heartbeat_status", align: "left", disablePadding: false, sortable: true, content: "function"},
			{label: "Health", value: "table_health", field: "health_status", align: "left", disablePadding: false, sortable: true, content: "function"},
			{label: "Active", value: "table_active", field: "active", align: "left", disablePadding: false, sortable: true, content: "function"},
			{label: "Name", value: "table_name", field: "name", align: "left", disablePadding: false, sortable: true, content: "function"},
			{label: "Unique ID", field: "unique_id", align: "left", disablePadding: false, sortable: true},
			{label: "Account", value: "nested_company.name", field: "company_id", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Location", field: "location", align: "left", disablePadding: false, sortable: true},
		];
		this.escrow_heading_info = [
			{label: "Select", field: "select", value: "select", align: "center", disablePadding: false, sortable: false},
			{label: "Unique ID", field: "unique_id", value: "unique_id", align: "left", disablePadding: false, sortable: true},
			{label: "Account", value: "account_name", field: "account_name", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Token", field: "token", align: "left", disablePadding: false, sortable: true},
			{label: "Action", field: "action", align: "center", sortable: false},
		];
		let button = {
			function: (classes) => this.issueUpdate(classes),
			display: "Issue Updates",
			onSelectShow: true
		};
		this.actions = [
			{label: "Select Action", action: "Select Action", confirm: ""},
			{label: "Update Config", action: "send_config", confirm: "Are you sure you'd like to send the most recent configuration to these devices?", icon: <RefreshIcon />},
			{label: "Upload Logs to Cloud", action: "log_upload", confirm: "Are you sure you'd like for these devices to upload their logs to the cloud?"},
			{label: "Trigger Heartbeat", action: "heartbeat", confirm: "Are you sure you want these devices to generate a heartbeat?"},
			{label: "Trigger Status", action: "status", confirm: "Are you sure you want these devices to generate a status?"},
			{label: "Activate Cloud Native Device", action: "activate_cloud_native_device", confirm: "Are you sure you want to activate these devices?"},
			{label: "Deactivate Cloud Native Device", action: "deactivate_cloud_native_device", confirm: "Are you sure you want deactivate these devices?"},
			{label: "Trigger Status", action: "status", confirm: "Are you sure you want these devices to generate a status?"},
			{label: "Reboot", action: "reboot", confirm: "Are you sure you'd like to reboot these devices?"},
			{label: "Reset to Factory Default", action: "reset", confirm: "Are you sure you'd like to reset all of these devices to their factory default settings?"},
			{label: "Disable Data Usage", action: "enable_data_restriction", confirm: "Are you sure you'd like to enable data on all of these devices?", icon: <DataUsageIcon />},
			{label: "Enable Data Usage", action: "disable_data_restriction", confirm: "Are you sure you'd like to disable data on all of these devices?"},
			{label: "Reintialize Greengrass", action: "greengrass_initialize", confirm: "Are you sure you'd like to reinitialize Greengrass on all of these devices?", icon: <GetAppIcon />},
		];
		this.set_tabs();
		this.set_menus();
		this.check_params();
		this.check_permissions();
		this.load_workflow_definitions();
	}

	set_tabs = () => {
		this.tabs = [
			{label: "Devices"},
			{label: "Escrow Devices"}
		];
	}

	set_menus = () => {
		if (!this.props.devices) return;
		this.props.devices.forEach( (device) => {
			this.state.menus[device._id] = null;
		});
	}

	check_permissions = () => {
		if (Permissions.allow(["send_config", "log_upload", "heartbeat", "status", "reboot"], "device")) {
			this.can_perform_action = true;
		} else {
			this.can_perform_action = false;
		}
		if (Permissions.allow_one_of(["send_config", "log_upload", "heartbeat", "status", "reboot", "update", "delete", "reset", "greengrass_initialize", "enable_data_restriction", "disable_data_restriction"], "device")) {
			this.can_perform_bulk_anything = true;
		} else {
			this.can_perform_bulk_anything = false;
		}
		if (Permissions.allow_one_of(["send_config", "log_upload", "heartbeat", "status", "reboot", "reset", "greengrass_initialize", "enable_data_restriction", "disable_data_restriction"], "device")) {
			this.can_perform_a_command = true;
		} else {
			this.can_perform_a_command = false;
		}
		if (Permissions.allow(["execute"], "workflow_definition")) {
			this.can_execute_workflow = true;
		} else {
			this.can_execute_workflow = false;
		}
	}

	set_buttons = () => {
		let buttons = [];
		const { classes, selections, all_selected, isEscrow } = this.props;
		if (isEscrow) {
			if (Permissions.allow(["create"], "escrow_device")) {
				buttons.push({onSelectShow: false, display: "Create Escrow Device", label: "Create Escrow Device", icon: (<AddIcon />), action: this.createEscowDevice, function: this.createEscrowDevice});
			}
			if (Permissions.allow(["create"], "device_transfer_request")) {
				buttons.push({onSelectShow: false, display: "Issue Transfer Request", label: "Issue Transfer Request", icon: (<SwapHorizIcon />), action: this.transfer_request_modal, function: this.transfer_request_modal});
			}
			if (Permissions.allow(["delete"], "escrow_device")) {
				buttons.push({onSelectShow: false, display: "Bulk Delete", label: "Bulk Delete", icon: (<DeleteIcon />), action: this.bulkConfigure, function: this.bulkConfigure});
			}
		} else {
			if (Permissions.allow(["create"], "device")) {
				buttons.push({onSelectShow: false, display: "Create Device", label: "Create Device", icon: (<AddIcon />), action: this.createDevice, function: this.createDevice});
				buttons.push({onSelectShow: false, display: "CSV Upload", label: "CSV Upload", icon: (<CloudUploadIcon />), action: this.csvCreate, function: this.csvCreate});
			}
			if (Permissions.allow_one_of(["send_config", "log_upload", "heartbeat", "status", "reboot", "reset", "greengrass_initialize", "enable_data_restriction", "disable_data_restriction"], "device")) {
				buttons.push({onSelectShow: false, display: "CSV Command", label: "CSV Command", icon: (<BuildIcon />), action: this.csvCommand, function: this.csvCommand});
			}
			buttons.push({onSelectShow: false, display: "CSV Reports", label: "CSV Reports", icon: (<CloudDownloadIcon />), action: this.displayReportModal, function: this.displayReportModal});
			if (buttons[2]) {
				buttons[2].style = (this.state.bulk_configure_shown || all_selected || selections.length > 0 ? classes.toggled : "");
			}
		}
		return buttons;
	}

	load_workflow_definitions = () => {
		new WorkflowDefinition().listFromAPI().then( (result) => {
			this.setState({
				workflow_definitions : result.items
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	createEscowDevice = () => {
		this.setState({
			modal: {
				open: true,
				children: () => <EscrowForm onCreate={this.on_escrow_creation} tabHostProxy={this.props.tabHostProxy} closeModal={this.closeModal}/>
			}
		});
	}

	on_escrow_creation = () => {
		this.closeModal();
		this.props.tabHostProxy.refresh();
	}

	check_params = () => {
		const qs = require('qs');
		const parsed = qs.parse(this.props.location.search.split("?")[1]);
		if (parsed.upload === "") {
			this.csvCreate();
		} else if (parsed.new === "") {
			this.createDevice();
		} else if (parsed.device_unique_id != null) {
			new Device().listFromAPI({unique_id: parsed.device_unique_id}).then( (result) => {
				if (result.items.length === 0) {
					this.context.openSnackbar("An error occurred attempting to open this device.", 'error');
				} else {
					this.openDeviceTab(result.items[0]);
				}
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else if (parsed.device_id != null) {
			new Device({_id: parsed.device_id}).readFromAPI().then( (result) => {
				this.openDeviceTab(result);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
		this.props.history.replace({
			pathname: '/Devices',
			search: ''
		});
	}

	get_bulk_devices = () => {
		return new Promise( (resolve, reject) => {
			let filter_data = this.props.filter_data || {};
			if (this.props.all_selected) {
				if (this.props.selections.length > 0) {
					filter_data._id_nin = this.props.selections.join();
				}
			} else {
				filter_data._id_in = this.props.selections.join();
			}
			const model = this.props.isEscrow ? "escrow_devices" : "devices";
			GetAll(model, filter_data).then( (devices) => {
				if (this.props.isEscrow) {
					resolve(devices);
					return;
				}
				AssignNestedModels("device_types", "device_type_id", devices).then( () => {
					devices.forEach( (device) => {
						if (!device.nested_device_type) {
							reject();
						}
					});
					resolve(devices);
				}).catch( (error) => {
					this.setState({bulk_loading: false});
					this.context.openSnackbar(error, 'error');
					this.closeModal();
				});
			}).catch( (error) => {
				this.setState({bulk_loading: false});
				this.context.openSnackbar(error, 'error');
				this.closeModal();
			});
		});
	}

	get_bulk_ids = () => {
		return new Promise( (resolve, reject) => {
			if (this.props.all_selected) {
				let filter_data = this.props.filter_data;
				if (this.props.selections.length > 0) {
					filter_data._ids_nin = this.props.selections.join();
				}
				GetAll("devices", filter_data).then( (devices) => {
					resolve(devices.map( ({_id, unique_id}) => _id));
				}).catch( (error) => {
					this.setState({bulk_loading: false});
					this.context.openSnackbar(error, 'error');
					this.closeModal();
				});
			} else {
				if (this.state.modal.singleDevice && this.state.modal.singleDevice._id) {
					resolve([this.state.modal.singleDevice._id]);
				} else {
					resolve(this.props.selections);
				}
			}
		});
	}

	quick_action = (action, device) => {
		this.setState( (state) => {
			state.menus[device._id] = null;
			state.modal = {
				open: true,
				yesFunction: () => this.quick_issue(action, device),
				functionText: action.label,
				prompt: action.confirm,
				children: () => {}
			};
			return state;
		});
	}

	quick_issue = (action, device) => {
		this.setState( (state) => {
			state.modal = {
				open: true,
				children: () => (<div style={{height: "100px", width: "100px", margin: "auto"}}><Loading /></div>)
			};
			return state;
		});
		if (action.action === "delete") {
			new Device({_id: device._id}).deleteFromAPI().then( () => {
				this.context.openSnackbar("Device deleted.", "success");
				this.closeModal();
				this.props.tabHostProxy.closeOther(device._id, true);
				this.props.tabHostProxy.refresh();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.closeModal();
			});
		} else {
			Device.issueGatewayCommand(action.action, null, device._id).then( () => {
				this.context.openSnackbar("Command issued. Monitor the status of the command by viewing this device's commands.", "success");
				this.closeModal();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.closeModal();
			});
		}
	}

	only_add_integration = (integration_id) => {
		let device_ids = this.state.bulk_devices.filter( ({device_integration_id}) => !device_integration_id || device_integration_id === '').map( ({_id}) => _id);
		const body = {
			ids: device_ids,
			device_integration_id: integration_id
		};
		return BULK_EDIT("devices", "save", body, Auth.token());
	}

	bulk_escrow_delete = (body) => {
		BULK_EDIT('escrow_devices', "delete", body, Auth.token()).then( () => {
			let message = `Command successfully issued to bulk delete these escrow devices.`;
			this.context.openSnackbar(message, 'success');
			this.closeModal();
		}).catch( (error) => {
			this.setState({bulk_loading: false});
			this.context.openSnackbar(error, "error");
		});
	}

	submit_action = (action, body) => {
		if (action === 'delete' || action === 'save' ) {
			if (action === "save") {
				this.setState({
					bulk_loading: true,
					modal: {
						"open": true,
						yesFunction: null,
						singleDevice: null,
						children: () => (<div style={{height: "100px", width: "100px", margin: "auto"}}><Loading /></div>)
					}
				});
			} else {
				this.setState({bulk_loading: true});
				if (this.props.isEscrow) {
					this.bulk_escrow_delete(body);
					return;
				}
			}
			let promises = [];
			if (body.device_integration_id) {
				let integration_id = body.device_integration_id;
				delete body.device_integration_id;
				promises.push(this.only_add_integration(integration_id));
			}
			promises.push(BULK_EDIT('devices', action, body, Auth.token()));
			if (body.rule_ids) {
				promises.push(this.attach_rules(body.ids, body.rule_ids));
			}
			if (body.ingestor_ids) {
				promises.push(this.attach_ingestors(body.ids, body.ingestor_ids));
			}
			Promise.all(promises).then( ([bulk_result, rule_result]) => {
				let message = `Command successfully sent to the selected devices.`;
				this.context.openSnackbar(message, 'success');
				if (Permissions.allow(["read"], "bulk_response")) {
					this.setState({
						bulk_loading: false, 
						bulk_response: bulk_result.bulk_response_id,
						modal: {
							"open": true,
							yesFunction: null,
							singleDevice: null,
							children: (classes) => (
								<div className={classes.modalWrapper}>
									<div className={classes.bulkResponse}>
										<CheckCircleIcon className={classes.checkCircleIcon}/>
										<span>The bulk process has successfully begun. Monitor the progress of bulk jobs&nbsp;<span onClick={() => this.props.history.push("messages?bulk_response_id=" + this.state.bulk_response)} className={classes.link}>here.</span></span>
									</div>
								</div>
							)
						}
					});
				} else {
					this.closeModal();
				}
			}).catch( (error) => {
				this.setState({bulk_loading: false});
				this.context.openSnackbar(error, "error");
			});
		} else {
			this.perform_command(action);
		}
	}

	attach_rules = (device_ids, rule_ids) => {
		let promises = [];
		device_ids.forEach( (device_id) => {
			promises.push(ADD_RELATIONS("devices", device_id, "rules", rule_ids));
		});
		return Promise.all(promises);
	}
	attach_ingestors = (device_ids, ingestor_ids) => {
		let promises = [];
		device_ids.forEach( (device_id) => {
			promises.push(ADD_RELATIONS("devices", device_id, "ingestors", ingestor_ids));
		});
		return Promise.all(promises);
	}

	execute_bulk_workflow = (selected_workflow) => {
		this.setState({bulk_loading: true});
		let ids_promise = this.get_bulk_ids();
		ids_promise.then( (ids) => {
			BULK_WORKFLOW(selected_workflow.id, ids, null, Auth.token()).then((result) => {
				let message = `Workflow execution queued.`;
				this.context.openSnackbar(message, 'success');
				if (result.bulk_response_id) {
					this.setState({bulk_loading: false, bulk_response: result.bulk_response_id});
				} else {
					this.closeModal();
					this.setState({bulk_loading: false, bulk_response: null});
				}
				this.clearSelections();
				this.props.tabHostProxy.refresh();
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
				this.setState({bulk_loading: false, bulk_response: null});
				this.clearSelections();
				this.closeModal();
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
			this.setState({bulk_loading: false, bulk_response: null});
			this.closeModal();
		});
	}

	perform_command = (action) => {
		this.setState({bulk_loading: true});
		let ids_promise = this.get_bulk_ids();
		ids_promise.then( (ids) => {
			BULK_COMMAND('devices', action.toLowerCase().replace(/ /g, "_"), ids, Auth.token()).then((result) => {
				let message = `Command successfully sent to the selected devices.`;
				this.context.openSnackbar(message, 'success');
				if (result.bulk_response_id) {
					this.setState({bulk_loading: false, bulk_response: result.bulk_response_id});
				} else {
					this.closeModal();
					this.setState({bulk_loading: false, bulk_response: null});
				}
				this.clearSelections();
				this.props.tabHostProxy.refresh();
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
				this.setState({bulk_loading: false, bulk_response: null});
				this.clearSelections();
				this.closeModal();
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
			this.setState({bulk_loading: false, bulk_response: null});
			this.closeModal();
		});
	}

	openBulkEditModal = () => {
		let device_count =  this.props.all_selected ? this.props.page_data.total - this.props.selections.length : this.props.selections.length;
		if (device_count === 0) {
			this.context.openSnackbar("Please select devices before selecting a bulk action.", "warning");
			return;
		}
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				singleDevice: null,
				children: () => (<div style={{height: "100px", width: "100px", margin: "auto"}}><Loading /></div>)
			}
		});
		let devices_promise = this.get_bulk_devices();
		devices_promise.then( (devices) => {
			this.setState({bulk_devices: devices});
			if (Auth.currentRole().abilities.device.update === "self") {
				let not_authorized = devices.find( ({company_id}) => company_id !== Auth.currentCompany()._id);
				if (not_authorized) {
					this.context.openSnackbar("One or more of the devices selected belong to another account. You don't have permission to edit devices that belong to other accounts.", "error");
					this.closeModal();
					return;
				}
			}
			this.setState({
				modal: {
					"open": true,
					yesFunction: null,
					singleDevice: null,
					children: (classes) => (<BulkEditDevices ids={devices.map(({_id}) => _id)} closeModal={this.closeModal} onSave={(body) => this.submit_action("save", body)}/>)
				}
			});
		});
	}

	openConfirmActionModal = (device, ids) => {
		this.setState({bulk_loading: true, command_error: null, action: "Select Action"});
		ids = { ids: ids };
		let device_count =  this.props.all_selected ? this.props.page_data.total : this.props.selections.length;
		if (!device && device_count === 0) {
			this.context.openSnackbar("Please select devices before selecting a bulk action.", "warning");
			return;
		}
		let devices_promise = this.get_bulk_devices();
		devices_promise.then( (devices) => {
			this.setState({bulk_devices: devices, bulk_loading: false});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.closeModal();
		});
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				singleDevice: device,
				children: (classes) => {
					let action_obj = this.actions.find( (action) => action.action === this.state.action);
					return (<div className={classes.modalWrapper}>
						{this.state.bulk_response && Permissions.allow(["read"], "bulk_response") ?
							<div className={classes.bulkResponse}>
								<CheckCircleIcon className={classes.checkCircleIcon}/>
								<span>The bulk process has successfully begun. Monitor the progress of bulk jobs&nbsp;<span onClick={() => this.props.history.push("messages?bulk_response_id=" + this.state.bulk_response)} className={classes.link}>here.</span></span>
							</div>
						:
							this.state.bulk_loading ? 
								<Loading />
								:
								<React.Fragment>
									<div className={classes.promptTitle}>
										Choose a command to issue to these devices.
									</div>
									<SelectInput
										priorState={this.state.action}
										emitChange={this.handleActionChange}
										options={this.actions.map( ({label, action}) => ({display: label, value: action}))}
									/>
									{this.state.command_error ? 
										<div className={classes.prompt}>
											{this.state.command_error}
										</div> 
									: 
										action_obj.confirm ? 
											<div className={classes.prompt}>
												{action_obj.confirm}
											</div> 
										: ""}
									<div className={classes.buttonContainer}>
										<Button
											color="primary"
											onClick={this.closeModal}
										>
											CANCEL
										</Button>
										{action_obj.confirm !== "" && this.state.command_error === null ? 
											<Button
												variant="contained"
												color="primary"
												size="large"
												onClick={() => this.submit_action(action_obj.action, ids)}
												className={(action_obj.action === "delete" ? classes.deleteButton : classes.saveButton)}
											>
												{action_obj.label}
											</Button> : "" }
									</div>
								</React.Fragment>
					}
					</div>);
				}
			}
		});
		
	}

	openExecuteWorkflowModal = (device, ids) => {
		let options = this.state.workflow_definitions.map( (wf) => ({display: wf.name, value: wf.id}))
		options.unshift({ display: "Choose a workflow", value: ""});
		this.setState({bulk_loading: true, command_error: null, action: "Select Workflow"});
		ids = { ids: ids };
		let device_count =  this.props.all_selected ? this.props.page_data.total : this.props.selections.length;
		if (!device && device_count === 0) {
			this.context.openSnackbar("Please select devices before selecting a bulk action.", "warning");
			return;
		}
		let devices_promise = this.get_bulk_devices();
		devices_promise.then( (devices) => {
			this.setState({bulk_devices: devices, bulk_loading: false});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.closeModal();
		});
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				singleDevice: device,
				children: (classes) => {
					return (<div className={classes.modalWrapper}>
						{this.state.bulk_response && Permissions.allow(["read"], "bulk_response") ?
							<div className={classes.bulkResponse}>
								<CheckCircleIcon className={classes.checkCircleIcon}/>
								<span>The bulk process has successfully begun. Monitor the progress of bulk jobs&nbsp;<span onClick={() => this.props.history.push("messages?bulk_response_id=" + this.state.bulk_response)} className={classes.link}>here.</span></span>
							</div>
						:
							this.state.bulk_loading ?
								<Loading />
								:
								<React.Fragment>
									<div className={classes.promptTitle}>
										Choose a workflow to execute using these devices.
									</div>
									<SelectInput
										priorState={this.state.workflow_prior_state}
										emitChange={this.handleWorkflowChange}
										options={options}
									/>
									{this.state.command_error ?
										<div className={classes.prompt}>
											{this.state.command_error}
										</div>
									: ""}
									<div className={classes.buttonContainer}>
										<Button
											color="primary"
											onClick={this.closeModal}
										>
											CANCEL
										</Button>
										{ this.state.command_error === null ?
											<Button
												variant="contained"
												disabled={this.state.selected_workflow == null}
												color="primary"
												size="large"
												onClick={() => this.execute_bulk_workflow(this.state.selected_workflow, ids)}
												className={classes.saveButton}
											>
												EXECUTE
											</Button> : "" }
									</div>
								</React.Fragment>
					}
					</div>);
				}
			}
		});
		
	}

	closeModal = () => {
		this.setState( (state) => {
			state = {
				modal: {
					open: false, children: () => "", singleDevice: {},
				},
				attached: null,
				parent_options: null,
				child_options: null,
				scheduleGatewayCommand: false,
				gatewayCommandSchedule: null,
				bulk_response: null,
			};
			return state;
		});
	}

	openDeviceTab = (device, detailView) => {
		if (detailView) {
			device.detail_view = detailView;
			this.props.tabHostProxy.addTab("device", device);
		} else {
			this.props.tabHostProxy.addTab("device", device);
		}
	};

	goToMessages = () => {
		this.props.history.push("Messages?errors");
	}

	createDevice = () => {
		this.setState({
			modal: {
				open: true,
				children: () => <DeviceCreationFlow onCreate={this.on_device_creation} tabHostProxy={this.props.tabHostProxy} closeModal={this.closeModal}/>
			}
		});
	};

	on_device_creation = (new_device) => {
		this.closeModal();
		this.props.tabHostProxy.refresh();
		this.props.tabHostProxy.addTab("device", new_device);
	}

	openDeviceTypeTab = (deviceType) => {
		this.props.tabHostProxy.addTab("deviceType", deviceType);
	}

	handleChangePage = (event, page) => {
		this.props.page_change({page: page + 1, per_page: this.props.page_data.per_page});
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({page: this.props.page_data.page, per_page: event.target.value});
	};

	selectAllFunction = () => {
		this.props.all_selected_toggle();
	}

	clearSelections = () => {
		// if (this.state.bulk_configure_shown === false) {
		// 	this.props.clear_selections();
		// } else {
			this.props.clear_selections();
		// }
	}

	componentDidMount() { 
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	displayReportModal = (classes) => {
		if (this._ismounted === true) {
			this.setState({
				modal: {
					"open": true,
					yesFunction: null,
					children: (classes) => (<DownloadReportChooser close={() => {
						this.closeModal();
					}}/>)
				}
			});
		} else {
			this.state.modal = {
				"open": true,
				yesFunction: null,
				children: (classes) => (<DownloadReportChooser close={() => {
					this.closeModal();
				}}/>)
			}
		}
	}

	csvCreate = (classes) => {
		if (this._ismounted === true) {
			this.setState({
				modal: {
					"open": true,
					yesFunction: null,
					children: (classes) => (<CSVUploadForm close={() => {
						this.closeModal();
						this.props.page_change({page: 1, per_page: this.props.page_data.per_page});
					}}/>)
				}
			});
		} else {
			this.state.modal = {
				"open": true,
				yesFunction: null,
				children: (classes) => (<CSVUploadForm close={() => {
					this.closeModal();
					this.props.page_change({page: 1, per_page: this.props.page_data.per_page});
				}}/>)
			}
		}
	}

	bulkConfigure = () => {
		if (this.state.bulk_configure_shown === true || this.props.all_selected || this.props.selections.length > 0) {
			this.setState({bulk_configure_shown: false}, this.props.clear_selections);
		} else {
			this.setState({bulk_configure_shown: true});
		}
	}

	csvCommand = (classes) => {
		if (this._ismounted === true) {
			this.setState({
				modal: {
					"open": true,
					yesFunction: null,
					children: (classes) => (<CSVCommandForm close={() => {
						this.closeModal();
						this.props.page_change({page: 1, per_page: this.props.page_data.per_page});
					}}/>)
				}
			});
		} else {
			this.state.modal = {
				"open": true,
				yesFunction: null,
				children: (classes) => (<CSVCommandForm close={() => {
					this.closeModal();
					this.props.page_change({page: 1, per_page: this.props.page_data.per_page});
				}}/>)
			}
		}
	}

	determine_valid_commands = (action) => {
		let type_restriction = [];
		let permission_restriction = [];
		if (Permissions.allow([action], "device")) {
			this.state.bulk_devices.forEach( (device) => {
				if (!Permissions.allow([action], "device", device.company_id)) {
					permission_restriction.push(device.name);
				} else if (!device.nested_device_type.capabilities.actions[action]) {
					type_restriction.push(device.name);
				}
			});
			if (permission_restriction.length === 0 && type_restriction.length === 0) {
				return null;
			} else {
				return this.render_invalid_command(type_restriction, permission_restriction);
			}
		} else {
			return "You have insufficient permissions to carry out this command.";
		}
	}

	render_invalid_command = (invalid_devices_by_type, invalid_devices_by_permission) => {
		return (
			<div>
				{invalid_devices_by_permission.length > 0 ? <div>
					You don't have permission to issue this action to these devices: {invalid_devices_by_permission.join()}
				</div> : ""}
				{invalid_devices_by_type.length > 0 ? <div>
					These devices don't support the action selected: {invalid_devices_by_type.join(", ")}
				</div> : ""}
			</div>
		);
	}

	handleWorkflowChange = ({value}) => {
		let wf = this.state.workflow_definitions.find((wf) => wf.id === value);
		let workflowPriorState = "";
		if (wf) {
			workflowPriorState = wf.id;
		}
		this.setState({
			selected_workflow: wf,
			workflow_prior_state: workflowPriorState
		});
	}

	handleActionChange = ({value}) => {
		let command_error = null;
		if (value !== "Select Action") {
			command_error = this.determine_valid_commands(value);
		}
		this.setState({action: value, command_error: command_error});
	}

	open_delete_modal = () => {
		let device_count =  this.props.all_selected ? this.props.page_data.total - this.props.selections.length : this.props.selections.length;
		if (device_count === 0) {
			this.context.openSnackbar("Please select devices to bulk delete.", "warning");
			return;
		}
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				singleDevice: null,
				children: () => (<div style={{height: "100px", width: "100px", margin: "auto"}}><Loading /></div>)
			}
		});
		const model = this.props.isEscrow ? "escrow_device" : "device";
		let devices_promise = this.get_bulk_devices();
		devices_promise.then( (devices) => {
			let body = {ids: devices.map(({_id}) => _id)};
			if (Auth.currentRole().abilities[model].delete === "self") {
				let not_authorized = devices.find( ({company_id}) => company_id !== Auth.currentCompany().company_id);
				if (not_authorized) {
					this.context.openSnackbar("One or more of the devices selected belong to another account. You don't have permission to delete devices that belong to other accounts.", "error");
					this.closeModal();
					return;
				}
			}
			this.setState({
				modal: {
					open: true,
					prompt: "Are you sure you want to delete these devices?",
					yesFunction: () => this.submit_action("delete", body),
					functionText: "DELETE",
					children: () => {}
				}
			});
		}).catch( (error) => {
			this.closeModal();
		});
	}

	render_selection_message = () => {
		const {selections, all_selected, page_data} = this.props;
		if (!all_selected) {
			if (selections.length > 0) {
				return (selections.length + " Device" + (selections.length > 1 ? "s" : "") + " Selected.");
			} else {
				return ("No Devices Selected.");
			}
		} else if (all_selected && selections.length > 0) {
			return (page_data.total - selections.length + " Device" + (page_data.total - selections.length > 1 ? "s" : "") + " Selected.");
		} else {
			return ("All Devices (" + page_data.total + ") Selected.");
		}
	}

	render() {
		const { items, selections, all_selected, page_data, modelChange, classes, isEscrow } = this.props;
		const { selected_items } = this.state;
		let buttons = this.set_buttons();
		const selected_shown = selections && selections.length > 0 ? classes.moreSpace : "";
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<PaginationContainer
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={(layout) => this.setState({layout: layout})}
					filtering={this.props.filtering}
					toggle_filter={this.props.show_filters}
				/>
				{this.render_select_wrapper()}
				<div className={classes.sideBarContainer}>
					<VerticalTabs tabs={this.tabs} onChange={this.model_change}/> 
				</div>
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer + " " + selected_shown}>
						{this.render_card_layout()}
					</div> :
					<div className={classes.tableItemsContainer + " " + selected_shown}>
						{this.render_table_layout()}
					</div>
				}
			</div>
		);
	}

	model_change = (new_tab) => {
		this.setState({bulk_configure_shown: false});
		this.props.modelChange(new_tab);
	}

	render_select_wrapper = () => {
		const {items, selections, all_selected, page_data, classes} = this.props;
		const { bulk_configure_shown } = this.state;
		if (bulk_configure_shown || selections.length > 0 || all_selected) {
			return (
				<div className={classes.selectWrapper}>
					<div className={classes.selectionCount}>
						{this.render_selection_message()}
						<div onClick={() =>( all_selected === false || (all_selected === true && selections.length > 0)) ? this.selectAllFunction() : ""} className={classes.secondaryButton + " " + ( (all_selected === true && selections.length === 0) ? classes.selection : "")}>
							Select All
						</div>
						<div onClick={() => selections.length > 0 || all_selected === true ? this.clearSelections() : ""} className={classes.secondaryButton + " " + (selections.length === 0 && all_selected === false ? classes.selection : "")}>
							Deselect All
						</div>
					</div>
					<div className={classes.selectButtonsWrapper}>
						<div className={classes.bulkButtonsWrapper}>
							{this.get_buttons()}
						</div>
					</div>
				</div>
			);
		}
	}

	get_buttons = () => {
		let buttons = [];
		const { isEscrow, classes } = this.props;
		let model = isEscrow ? "escrow_device" : "device";
		if (Permissions.allow(["delete"], model)) {
			buttons.push(
				<Button
					key="delete_device_command"
					className={classes.deleteBorderButton}
					variant="outlined"
					color="primary"
					onClick={() => this.open_delete_modal()}
				>
					<DeleteForeverIcon className={classes.bulkIcon}/>
					DELETE
				</Button>
			);
		}
		if (!isEscrow) {
			if (Permissions.allow(["update"], "device")) {
				buttons.push(
					<Button
						key="edit_device_button"
						className={classes.editButton}
						variant="outlined"
						color="primary"
						onClick={() => this.openBulkEditModal()}
					>
						<EditIcon className={classes.bulkIcon}/>
							EDIT
					</Button> 
				);
			}
			if (this.can_perform_a_command) {
				buttons.push(
					<Button
						key="issue_command_button"
						className={classes.actionButton}
						variant="outlined"
						color="primary"
						onClick={() => this.openConfirmActionModal()}
					>
						<BuildIcon className={classes.bulkIcon}/>
						ISSUE COMMAND
					</Button>
				);
			}
			if (this.can_execute_workflow && this.state.workflow_definitions && this.state.workflow_definitions.length > 0) {
				buttons.push(
					<Button
						key="execute_bulk_workflow_button"
						className={classes.actionButton}
						variant="outlined"
						color="primary"
						onClick={() => this.openExecuteWorkflowModal()}
					>
						<BuildIcon className={classes.bulkIcon}/>
						EXECUTE WORKFLOW
					</Button>
				);
			}
		}
		return buttons;
	}

	render_table_layout = () => {
		const { items, selections, all_selected, page_data, isEscrow } = this.props;
		var heading = isEscrow ? this.escrow_heading_info : this.heading_info;
		
		let prepped_items = this.prepare_items();
		return (
			<TableList
				headings={heading}
				items={prepped_items}
				selections={selections}
				all_selected={all_selected}
				page_data={page_data}
				perform_sort={this.props.perform_sort}
			/>
		);
	}

	render_card_layout = () => {
		const { items, isEscrow } = this.props;
		if (items) {
			let prepped_items = this.prepare_items();
			return prepped_items.map( (item, index) => (
				isEscrow ? this.render_escrow_card(item) : this.render_device_card(item)
			));
		} else {
			return this.render_placeholder_cards();
		}
	}

	prepare_items = () => {
		const { items, isEscrow } = this.props;
		if (!items) return;
		if (isEscrow) {
			return this.prepare_escrow();
		} else return items;
	}

	prepare_escrow = () => {
		const classes = this.props.classes;
		let prepped_items = JSON.parse(JSON.stringify(this.props.items));
		prepped_items.forEach( (item) => {
			if (!item.nested_company) {
				if (!Boolean(item.error)) item.error = "";
				item.error += " This escrow device does not have a valid acccount.";
				item.account_name = <span className={classes.notFound}>Account Not Found</span>;;
			} else {
				item.account_name = item.nested_company.name;
			}
			let checked = this.show_selected(item._id);
			let allowed = Permissions.allow(["delete"], "escrow_device", item.company_id);
			item.select = (allowed ? <CustomCheckbox
				onChange={(event) => this.escrow_select(item._id, event)}
				value="selected"
				color="primary"
				key={item._id}
				checked={checked}
			/> : "");
			item.action = (<span className={classes.tableMenu}>{this.render_escrow_menu(item, true)}</span>);
		});
		return prepped_items;
	}

	render_placeholder_cards = () => {
		const classes = this.props.classes;
		let placeholders = [0,0,0,0,0,0,0,0,0];
		let card_class = this.props.isEscrow ? classes.escrowCard : "";
		return placeholders.map( (item, index) => (
			<div key={"placeholder_" + index} className={classes.cardContainer}>
				<Card className={classes.card + " " + card_class}>
					<div className={classes.contentLoaderContainer}>
						<ContentLoader
							width={200}
							speed={2}
							interval={.25}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							{ !this.props.isEscrow ? <rect x="0" y="6" rx="4" ry="4" width={200} height="18" /> : ""}
							<rect x="12" y="33" rx="4" ry="4" width={200} height="16" /> 
							<rect x="12" y="63" rx="4" ry="4" width={200} height="16" /> 
							{ !this.props.isEscrow ? 
								<rect x="12" y="92" rx="4" ry="4" width={200} height="16" />
								: ""
							}
						</ContentLoader>
					</div>
				</Card>
			</div>
		));
	}

	show_selected = (id) => {
		const { selections, all_selected } = this.props;
		if (selections.includes(id) && !all_selected) {
			return true;
		}
		if (!selections.includes(id) && all_selected) {
			return true;
		}
		return false;
	}

	render_device_card = (device) => {
		const { selections, classes } = this.props;
		const selected = this.show_selected(device._id) ? classes.selected : "";
		return (
			<div key={device._id} className={classes.deviceCardContainer}>
				<Card
					className={selected + " " + classes.deviceCardOverride + " " + (this.state.hovering === device._id ? classes.deviceCardHovered : "")}
				>
					{this.render_floating_icons(device)}
					{this.render_device_info(device)}
				</Card>
			</div>
		);
	}

	render_error = (item) => {
		const classes = this.props.classes;
		if (!item.error) return "";
		return (
			<Tooltip classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{item.error}</span>}>
				<ErrorIcon className={classes.errorIcon}/>
			</Tooltip>
		);
	}

	escrow_transfer_status_for_device = (device_item) => {
		if (device_item.transfer_initiated_at != null) {
			if (device_item.transfer_completed_at != null) {
				return "Transfer Complete " + Humanize.formatDateStringForYMD(device_item.transfer_completed_at);
			} else {
				return "Transfer Initiated " + Humanize.formatDateStringForYMD(device_item.transfer_initiated_at);
			}
		}
		return "Device Transfer Not Yet Initiated";
	}

	render_escrow_card = (item) => {
		const { classes, selections } = this.props;
		const selected = this.show_selected(item._id) ? classes.selected : "";
		return (
			<div key={item._id} className={classes.cardContainer}>
				<Card className={classes.card + " " + selected + " " + classes.escrowCard}>
					<div className={classes.optionsIconsContainer}>
						<div className={classes.leftOptionsContainer}>
							<div className={classes.selectContainer}>{item.select}</div>
						</div>
						<div className={classes.rightOptionsContainer}>
							<Tooltip onClick={() => this.can_delete(item) && this.delete_escrow(item)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_delete(item) ? "Delete Escrow Device" : "Insufficient permissions for deleting"}</span>}>
								<span className={classes.optionsIcon + " " + (this.can_delete(item) ? "" : classes.tipDisabled)}>
									<DeleteIcon />
								</span>
							</Tooltip>
							<Tooltip onClick={(event) => this.can_edit(item) && this.edit_escrow(event, item)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_edit(item) ? "Edit Escrow Device" : "Insufficient permissions for editing"}</span>}>
								<span className={classes.optionsIcon + " " + (this.can_edit(item) && !Boolean(item.error) ? "" : classes.tipDisabled)}>
									<EditIcon />
								</span>
							</Tooltip>
							<Tooltip onClick={(event) => this.can_transfer(item) && this.transfer_request(item, event)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_transfer(item) ? "Issue Device Transfer Request" : "Insufficient permissions for issuing a transfer request."}</span>}>
								<span className={classes.optionsIcon + " " + (Boolean(item.error) ? classes.tipDisabled : "")}>
									<SwapHorizIcon />
								</span>
							</Tooltip>
						</div>
					</div>
					<div className={classes.escrowDeviceInfo}>
						<div className={classes.cardLabelContainer}>
							<div className={classes.cardLabel}>
								{this.render_unique_id(item, true)}
								{this.render_error(item)}
							</div>
						</div>
						<div className={classes.cardLabelContainer}>
							<div className={classes.cardLabel}>
								<Tooltip title="Token">
									<VpnKeyIcon className={classes.iconLabel}/>
								</Tooltip>
								<span className={classes.cardText}>{item.token}</span>
							</div>
						</div>
						<div className={classes.cardLabelContainer}>
							<div className={classes.cardLabel}>
								<Tooltip title="Account">
									<AccountCircleIcon className={classes.iconLabel}/>
								</Tooltip>
								<span className={classes.cardText}>{item.account_name}</span>
							</div>
						</div>
						<div className={classes.cardLabelContainer}>
							<div className={classes.cardLabel}>
								<Tooltip title="Transfer Status">
									<SwapHorizIcon className={classes.iconLabel}/>
								</Tooltip>
								<span className={classes.cardText}>{this.escrow_transfer_status_for_device(item)}</span>
							</div>
						</div>
					</div>
				</Card>
			</div>
		);
	}

	can_edit = (item) => {
		return Permissions.allow(["update"], "escrow_device", item.company_id);
	}

	edit_escrow = (event, item) => {
		if (item.error) {
			event.stopPropagation();
			return;
		}
		this.setState({
			modal: {
				open: true,
				children: () => <EscrowForm escrowDevice={item} onCreate={this.on_escrow_creation} tabHostProxy={this.props.tabHostProxy} closeModal={this.closeModal}/>
			}
		});
	}

	can_delete = (item) => {
		return Permissions.allow(["delete"], "escrow_device", item.company_id);
	}

	delete_escrow = (escrow_device) => {
		this.setState({
			modal: {
				open: true,
				prompt: "Are you sure you want to delete this escrow device?",
				yesFunction: () => this.perform_delete(escrow_device._id),
				functionText: "DELETE",
				children: () => {}
			}
		});
	}

	perform_delete = (_id) => {
		this.setState( (state) => {
			state.modal = {
				open: true,
				children: () => (<div style={{height: "100px", width: "100px", margin: "auto"}}><Loading /></div>)
			};
			return state;
		});
		new EscrowDevice({_id: _id}).deleteFromAPI().then( (result) => {
			this.context.openSnackbar("Escrow device deleted.", "success");
			this.on_escrow_creation();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	transfer_request = (item, event) => {
		this.setState({
			modal: {
				open: true,
				children: () => <TransferRequestForm onCreate={this.on_escrow_creation} escrowDevices={[item]} closeModal={this.closeModal}/>
			}
		});
	}

	transfer_request_modal = () => {
		this.setState({
			modal: {
				open: true,
				children: () => <TransferRequestFlow onCreate={this.on_escrow_creation} escrowDevices={[]} closeModal={this.closeModal}/>
			}
		});
	}

	render_device_info = (device) => {
		const classes = this.props.classes;
		return (
			<div
				onClick={() => device.nested_device_type && this.openDeviceTab(device)}
				onMouseLeave={() => this.setState({hovering: null})}
				onMouseEnter={() => this.setState({hovering: device._id})}
				className={classes.deviceInfo}
			>
				{this.render_title(device)}
				{this.render_unique_id(device)}
				{this.render_account(device)}
				{this.render_tags(device)}
			</div>
		)
	}

	render_title = (device) => {
		const classes = this.props.classes;
		return (
			<div className={classes.titleContainer}>
				<div className={classes.deviceTitle}>{device.name}</div>
				{this.render_alert(device)}
			</div>
		);
	}

	render_alert = (device) => {
		const classes = this.props.classes;
		const yellow = this.props.theme.palette.caution.main;
		const red = this.props.theme.palette.red.main;
		const grey = this.props.theme.palette.grey.main;
		if (!device.nested_device_type) {
			return (
				<div className={classes.errorContainer}>
					<ErrorOutlineIcon className={classes.errorIcon}/>
					<div className={classes.errorText}>Device Type Unavailable</div>
				</div>
			);
		}
		if (device.health_status !== '' && device.health_status !== 'normal') {
			let color = grey;
			let text = "Health Status";
			if (device.health_status === 'needs_attention') {
				text = "Health: Needs Attention"
				color = yellow;
			} else if (device.health_status === 'critical') {
				text = "Health: Critical"
				color = red;
			}
			return (
				<div className={classes.alertContainer}>
					<Tooltip key="User Defined Health Status" title={text}>
						<HealthStatusIcon className={classes.inputIcon} style={{color: color }} />
					</Tooltip>
				</div>
			)
		}
		if (this.has_gg_error(device)) {
			return (
				<div onClick={() => this.goToMessages()} className={classes.errorContainerLink}>
					<ErrorOutlineIcon className={classes.errorIcon}/>
					<div className={classes.errorText}>Greengrass Error</div>
				</div>
			);
		}
		if (device.nested_error_count && device.nested_error_count > 0) {
			return (
				<div onClick={() => this.goToMessages()} className={classes.errorContainerLink}>
					<div className={classes.countCircle}>
						{device.nested_error_count}
					</div>
					<div className={classes.errorText}>Error{device.nested_error_count > 1 ? "s" : ""}</div>
				</div>
			);
		}
		if (device.nested_alert_count && device.nested_alert_count > 0) {
			return (
				<div className={classes.alertContainer}>
					<div className={classes.countCircle}>
						{device.nested_alert_count}
					</div>
					<div>Alert{device.nested_alert_count > 1 ? "s" : ""}</div>
				</div>
			);
		}
	}

	render_unique_id = (device, escrow) => {
		const classes = this.props.classes;
		return (
			<div className={classes.idContainer + " " + (escrow ? classes.escrowId : "")}>
				<Tooltip title="Unique ID"><div className={classes.idLabel + " " + (escrow ? classes.escrowIdLabel : "")}>ID:</div></Tooltip>
				<div className={classes.idValue + " " + (escrow ? classes.escrowIdValue : "")}>{device.unique_id}</div>
			</div>
		);
	}

	render_account = (device) => {
		const classes = this.props.classes;
		if (!device.nested_company) {
			return (
				<div className={classes.idContainer}>
					<div className={classes.idValue + " " + classes.valueError}>Account Error</div>
				</div>
			);
		}
		return (
			<div className={classes.idContainer}>
				<div className={classes.idValue}>{device.nested_company.name}</div>
			</div>
		);
	}

	render_tags = (device) => {
		const classes = this.props.classes;
		return (
			<div className={classes.idContainer + " " + (device.tags && device.tags.length > 0 ? classes.chipSpecialContainer : "")}>
				{device.tags && device.tags.length > 0 &&
					<div className={classes.chipContainer}>
						{device.tags.map( (tag, index) => (
							<div key={device._id + "_" + tag + "_" + index} className={classes.chip}>
								{tag}
							</div>
						))}
					</div>
				}
			</div>
		);
	}

	render_type = (device) => {
		const classes = this.props.classes;
		if (!device.nested_device_type) {
			return (
				<div className={classes.idContainer}>
					<Tooltip title="Device Type"><DvrIcon className={classes.idLabel}/></Tooltip>
					<div className={classes.idValue}>Inherited Device Type</div>
				</div>
			);
		}
		return (
			<div className={classes.idContainer}>
				<Tooltip title="Device Type"><DvrIcon className={classes.idLabel}/></Tooltip>
				<div className={classes.idValue}>{device.nested_device_type.name}</div>
			</div>
		);
	}

	has_gg_error = (device) => {
		let has_gg = device.nested_device_integration && device.nested_device_integration.greengrass_core_install_url;
		let not_controller = !device.device_ha_group_id || (device.device_ha_group_id && device.nested_device_ha_group && device.nested_device_ha_group.controller_device.device_id !== device._id);
		if (has_gg && not_controller) {
			return device.config_status && !device.config_status.greengrass_installed;
		} else {
			return false;
		}
	}

	render_gg_status = (device) => {
		let has_gg = device.nested_device_integration && device.nested_device_integration.greengrass_core_install_url;
		let not_controller = !device.device_ha_group_id || (device.device_ha_group_id && device.nested_device_ha_group && device.nested_device_ha_group.controller_device.device_id !== device._id);
		if (has_gg && not_controller) {
			if (device.config_status && device.config_status.greengrass_installed) {
				return <CheckCircleIcon style={{marginLeft: "4px", color: this.props.theme.palette.green.main}}/>;
			} else if (device.config_status && !device.config_status.greengrass_installed) {
				return <ErrorOutlineIcon style={{marginLeft: "4px", color: this.props.theme.palette.red.main}}/>;
			} else if (device.status_at === "0001-01-01T00:00:00Z" && device.heartbeat_at === "0001-01-01T00:00:00Z") {
				return <AccessTimeIcon style={{marginLeft: "4px", color: this.props.theme.palette.grey.main}}/>;
			}
		} else {
			return "";
		}
	}

	render_integration = (device) => {
		const classes = this.props.classes;
		const integration = device.nested_device_integration;
		if (integration) {
			return (
				<div className={classes.idContainer}>
					<Tooltip title="Device Integration"><ExtensionIcon className={classes.idLabel}/></Tooltip>
					<div className={classes.idValue}>{integration.name}</div>
					{this.render_gg_status(device)}
				</div>
			);
		} else if (device.device_integration_id) {
			return (
				<div className={classes.idContainer}>
					<ExtensionIcon className={classes.idLabel}/>
					<div className={classes.idValue}>Inherited Integration</div>
				</div>
			);
		} else {
			return (
				<div className={classes.idContainer}>
					<ExtensionIcon className={classes.idLabel}/>
					<div className={classes.idValue + " " + classes.noValue}>No Device Integration</div>
				</div>
			);
		}
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState( (state) => {
			state.menus[id] = element;
			return state;
		});
	}

	render_escrow_menu = (item, table) => {
		let anchorEl =  this.state.menus[item._id];
		let open = Boolean(anchorEl);
		let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, item._id)}/> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, item._id)}/>;
		return (
			<React.Fragment>
				{icon}
				{this.render_escrow_action_menu(open, anchorEl, item)}
			</React.Fragment>
		);
	}

	can_transfer = (item) => {
		return Permissions.allow(["create"], "device_transfer_request", item.company_id);
	}

	render_escrow_action_menu = (open, anchorEl, item) => {
		const { classes } = this.props;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={() => this.close_action_menu(item._id)}
				onClose={() => this.close_action_menu(item._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit(item) && this.edit_escrow(event, item)}>
					<MenuItem disabled={!this.can_edit(item) || Boolean(item.error)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Edit Escrow Device
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={() => this.can_delete(item) && this.delete_escrow(item)}>
					<MenuItem disabled={!this.can_delete(item)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Delete Escrow Device
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={() => this.transfer_request(item)}>
					<MenuItem disabled={!this.can_transfer(item)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<SwapHorizIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Issue Device Transfer Request
						</Typography>
					</MenuItem>
				</div>
			</Menu>
		);
	}

	render_menu = (device) => {
		const classes = this.props.classes;
		let anchorEl = this.state.menus[device._id];
		let open = Boolean(anchorEl);
		return (
			<div style={{visibility: (this.get_actions(device).length === 0 ? "hidden" : "visible")}}>
				<div>
					<IconButton
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={(event) => this.open_action_menu(event, device._id)}
						className={classes.menuIconButton}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				{this.render_action_menu(open, anchorEl, device)}
			</div>
		);
	}

	render_action_menu = (open, anchorEl, device) => {
		const classes = this.props.classes;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => this.close_action_menu(device._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				{this.get_actions(device).map( (action, index) => (
					<div onClick={() => this.quick_action(action, device)} key={action.label} className={classes.menuItemContainer}>
						<MenuItem className={classes.actionMenuItem}>
							<ListItemIcon>
								{action.icon}
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								{action.label}
							</Typography>
						</MenuItem>
					</div>
				))}
			</Menu>
		);
	}

	get_actions = (device) => {
		let config_action = this.actions[1];
		config_action.confirm = "Send the most recent configuration to this device?";
		let data_action = this.actions[7];
		data_action.confirm = "Disable data usage on this device?";
		let possible_actions = [{label: "Delete", action: "delete", confirm: "Delete this device?", icon: <DeleteIcon />}]
		possible_actions = [...possible_actions, config_action, data_action];
		const has_gg = device.nested_device_integration && device.nested_device_integration.greengrass_core_install_url;
		if (has_gg) {
			let gg_action = this.actions[9];
			gg_action.confirm = "Reinitialize Greengrass on this device?";
			possible_actions.push(gg_action);
		}
		const type = device.nested_device_type;
		if (!type) return [];
		const role = Auth.currentRole();
		if (!role) return [];
		possible_actions = possible_actions.filter( ({action}) => (type.capabilities.actions[action] == null || type.capabilities.actions[action]) && Permissions.allow([action], "device", device.company_id));
		return possible_actions;
	}

	close_action_menu = (id) => {
		this.setState( (state) => {
			state.menus[id] = null;
			return state;
		});
	};

	render_floating_icons = (device) => {
		const classes = this.props.classes;
		const status = device.heartbeat_status;
		let select = null;
		if (!this.can_perform_bulk_anything || !device.nested_device_type) {
			select = "";
		} else {
			select = <CustomCheckbox
				checked={(this.props.all_selected && this.props.selections.indexOf(device._id) === -1) || (!this.props.all_selected && this.props.selections.indexOf(device._id) > -1)}
				onChange={this.select(device._id)}
				value="selected"
				color="primary"
				key={device._id}
			/>;
		}
		return (
			<div className={classes.optionsIconsContainer}>
				<div className={classes.leftOptionsContainer}>
					<div className={classes.selectContainer}>{select}</div>
					<HeartbeatStatus status={status} className={classes.heartBeatstatusDot}/>
				</div>
				<div className={classes.rightOptionsContainer}>
					{this.get_actions(device).map( (action, index) => (
						<div onClick={() => this.quick_action(action, device)} key={action.label} className={classes.optionsIcon}>
							{action.icon}
						</div>
					))}
				</div>
			</div>
		);
	}

	render_image_bg = (device) => {
		const classes = this.props.classes;
		let has_device_type_image = device.nested_device_type && device.nested_device_type.image && device.nested_device_type.image !== "";
		return (
			<div className={classes.deviceImageContainer}>
				{has_device_type_image ? <img className={classes.deviceImage} src={device.nested_device_type.image}/> : <DvrIcon className={classes.devicePlaceholderImage} />}
			</div>
		);
	}

	table_select = (title, ids, all) => {
		this.props.selection_change(ids, null, true);
	}

	escrow_select = (id, event) => {
		const checked = event.target.checked;
		if (this.state.bulk_configure_shown === false) {
			this.setState({bulk_configure_shown: true}, () => this.props.selection_change(id, checked));
		} else {
			this.props.selection_change(id, checked);
		}
	}

	select = id => event => {
		event.stopPropagation()
		const checked = event.target.checked;
		if (this.state.bulk_configure_shown === false) {
			this.setState({bulk_configure_shown: true}, () => this.props.selection_change(id, checked));
		} else {
			this.props.selection_change(id, checked);
		}
	}
}

DevicesList.contextType = SnackbarContext;

const CustomCheckbox = withStyles({
	root: {
		color: "rgba(0, 0, 0, 0.24)",
		padding: "0",
	},
	checked: {},
  })(props => <Checkbox color="default" {...props} />);


const styles = theme => ({
	container: {
		fontFamily: "Inter",
		flexGrow: 2,
		display: "flex",
		flexWrap: "wrap",
		overflow: "hidden"
	},
	heartBeatstatusDot: {
		width: "16px",
		height: "16px",
		minWidth: "16px",
		minHeight: "16px",
		maxWidth: "16px",
		maxHeight: "16px",
		borderRadius: "50%",
		display: "inline-flex",
		marginLeft: "16px",
		color: 'white'
	},
	titleContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "16px",
	},
	idContainer: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		marginBottom: "8px",
	},
	chipContainer: {
		display: "flex",
	},
	chipSpecialContainer: {
		marginTop: "16px",
	},
	idLabel: {
		fontSize: "14px",
		color: "#636366",
		marginRight: "8px"
	},
	idValue: {
		fontSize: "14px",
		color: "#636366",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	escrowId: {
		marginLeft: 0,
	},
	escrowIdLabel: {
		color: "#000000",
		opacity: "0.87",
		fontSize: "16px",
	},
	escrowIdValue: {
		color: "#000000",
		opacity: "0.87",
		fontSize: "16px",
	},
	noValue: {
		fontStyle: "italic",
		color: "rgba(0,0,0,.42)"
	},
	valueError: {
		color: theme.palette.red.main
	},
	menuContainer: {
		display: "flex",
		alignItems: "center",
		maxWidth: "calc(100% - 44px)",
		overflow: "hidden"
	},
	errorContainer: {
		display: "flex",
		color: theme.palette.red.main,
		alignItems: "center",
		marginRight: "12px",
		maxWidth: "calc(100% - 42px)"
	},
	errorContainerLink: {
		display: "flex",
		color: theme.palette.red.main,
		alignItems: "center",
		marginRight: "12px",
		maxWidth: "calc(100% - 42px)",
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer"
		}
	},
	countCircle: {
		fontSize: "12px",
		borderRadius: "50%",
		border: "solid 2px",
		padding: "4px",
		marginRight: "4px",
		textAlign: "center",
		minWidth: "12px",
		minHeight: "12px"
	},
	errorIcon: {
		marginRight: "4px"
	},
	errorText: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	alertContainer: {
		display: "flex",
		color: theme.palette.pending.main,
		alignItems: "center",
		marginRight: "4px"
	},
	actionMenuItem: {
		outline: "none"
	},
	chip: {
		marginRight: "16px",
		backgroundColor: theme.palette.pending.main,
		color: "#fbfafe",
		borderRadius: "16px",
		fontSize: "12px",
		marginBottom: "4px",
		marginTop: "4px",
		padding: "4px 8px 4px 8px",
	},
	devicesContainer: {
		overflowY: "scroll",
		height: "calc(100% - 81px)",
		padding: "12px",
		backgroundColor: "#ebebeb"
	},
	selectionsShown: {
		height: "calc(100% - 137px)",
	},
	deviceCardContainer: {
		width: "33.33%",
		display: "inline-flex",
		position: "relative",
		justifyContent: "center",
	},
	cardContainer: {
		width: "33.33%",
		display: "inline-flex",
		position: "relative",
		justifyContent: "center",
	},
	deviceCardOverride: {
		boxShadow: "0px 3px 7px 2px #00000017",
		backgroundColor: "#fafafa",
		margin: "12px",
		width: "100%",
		minWidth: "250px",
		maxWidth: "380px",
		height: "auto",
		minHeight: "209px",
		border: "#ffffff solid 1px",
		transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
	},
	deviceCardHovered: {
		border: "#1153B6 solid 1px",
	},
	deviceInfoHovered: {
		marginTop: "-64px"
	},
	deviceInfo: {
		position: "relative",
		padding: "16px 24px 24px 24px",
		cursor: "pointer",
	},
	deviceCard: {
		margin: "12px",
		width: "100%",
		backgroundColor: "white",
		position: "relative",
		height: "auto"
	},
	card: {
		boxShadow: "0px 3px 7px 2px #00000017",
		backgroundColor: "#fafafa",
		margin: "12px",
		width: "100%",
		minWidth: "250px",
		maxWidth: "380px",
		height: "auto",
		minHeight: "209px",
		border: "#ffffff solid 1px",
		transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
	},
	deviceImageContainer: {
		paddingTop: "12px",
		height: "112px",
		overflow: "hidden",
		boxSizing: "border-box",
		textAlign: "center",
		backgroundColor: "#00000003"
	},
	optionsIconsContainer: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		height: "36px",
		backgroundColor: "#ffffff",
		padding: "8px",
		boxSizing: "border-box",
	},
	leftOptionsContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		width: "25%",
	},
	rightOptionsContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		width: "75%",
	},
	optionsIcon: {
		marginLeft: "16px",
		color: "#8e8e93",
		width: "20px",
		height: "20px",
		"& svg": {
			fontSize: "20px",
		},
		cursor: "pointer",
		"&:hover": {
			color: "#0263fc",
		},
		transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
	},
	selectContainer: {
		"& svg":  {
			width: "20px",
			height: "20px",
		},
	},
	actionListTitle: {
		marginBottom: "8px",
		padding: "11px 16px",
		borderBottom: "solid #80808073 1px",
		backgroundColor: "white",
		cursor: "unset",
		'&:hover': {
			backgroundColor: "white",
			cursor: "unset",
		},
		outline: "none",
		fontFamily: "Inter",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "1rem",
		width: "auto",
		height: "24px",
		whiteSpace: "nowrap",
		boxSizing: "content-box",
		fontWeight: 400,
		lineHeight: "1.5em"
	},
	cardRows: {
		backgroundColor: "#f3f3f3c9",
		position: "absolute",
		height: "100%",
		width: "100%",
	},
	deviceImage: {
		margin: "auto",
		height: "auto",
		paddingTop: "24px",
		maxHeight: "100%",
		margin: "auto",
	},
	menuIconButton: {
		padding: "4px",
		marginRight: "4px"
	},
	devicePlaceholderImage: {
		width: "auto",
		height: "100%",
		padding: "12px",
		color: theme.palette.primary.main
	},
	selectRoot: {
		height: "37px"
	},
	dropdownArrow: {
		color: "white",
		position: "absolute",
		top: "5px",
		right: "0",
	},
	topRowIconsContainer: {
		boxSizing: "border-box",
		padding: "0 4px",
		width: "100%",
		display: "flex",
		flexWrap: "nowrap",
		whiteSpace: "nowrap"
	},
	statusContainer: {
		textAlign: "center",
		padding: "4px 0",
	},
	statusButton: {
		padding: "4px",
		marginTop: "10px",
		marginRight: "4px"
	},
	modalWrapper: {
		height: "100%",
		minHeight: "200px",
	},
	heartbeatIdle: {
		backgroundColor: theme.palette.caution.main,
		color: "white",
		fontSize: "18px",
		padding: 0
	},
	statusLabel: {
		fontSize: "12px",
		marginTop: "-8px"
	},
	statusIcon: {
		fontSize: "20px",
		color: "#f6f6f6"
	},
	icon: {
		fontSize: "18px"
	},
	configPending: {
		backgroundColor: theme.palette.pending.main,
		color: "white",
		fontSize: "18px",
		padding: 0
	},
	configValid: {
		backgroundColor: theme.palette.green.main,
		color: "white",
		fontSize: "18px",
		padding: 0
	},
	configNA: {
		color: "white",
		backgroundColor: "grey",
		fontSize: "18px",
		padding: 0
	},
	configError: {
		backgroundColor: theme.palette.red.main,
		color: "white",
		fontSize: "18px",
		padding: 0
	},
	overlayIcon: {
		position: "absolute",
		left: 0,
		color: "#f3f3f3"
	},
	heartbeatIconsContainer: {
		position: "relative",
		color: theme.palette.greyIcon.main,
		display: "flex"
	},
	iconsAtRight: {
		margin: "5px -10px 0 auto",
		flex: "50%",
		textAlign: "right"
	},
	signalButton: {
		padding: "7px",
		color: "grey",
	},	
	textRowContainer: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		padding: "8px",
		backgroundColor: "white"
	},
	deviceTypeWrapper: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		color: theme.palette.greyIcon.main,
		padding: "8px 0"
	},
	buttonLabel: {
		whiteSpace: "nowrap",
		alignItems: "center",
	},
	splitFab: {
		maxHeight: "40px"
	},
	actionFab: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderLeft: `solid ${darken(theme.palette.primary.main, .2)} 2px`,
		boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)"
	},
	performFab: {
		backgroundColor: theme.palette.green.main,
		color: "white",
		textTransform: "unset",
		"&:hover": {
			backgroundColor: darken(theme.palette.green.main, .2),
		}
	},
	deviceTypeText: {
		marginLeft: "4px",
		textTransform: "capitalize",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		display: "flex",
		alignItems: "center"
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "24px",
	},
	promptTitle: {
		fontFamily: "Inter",
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	deviceTitle: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontSize: "16px",
		color: "#000000",
		opacity: "0.87",
	},
	companyContainer: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		marginTop: "8px",
		width: "100%"
	},
	companyIcon: {
		fontSize: "30px",
		color: theme.palette.greyIcon.main
	},
	companyLabel: {
		color: "grey",
		fontWeight: "400",
		maxWidth: "78%",
		overflow: "hidden",
		marginLeft: "8px",
		fontSize: "18px",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	deviceType: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		position: "relative",
		top: "-20px",
		backgroundColor: "whitesmoke",
		width: "100%",
		color: "grey",
	},
	manageButtonContainer: {
		display: "block",
		width: "100%",
	},
	buttonContainer: {
		marginTop: "32px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	saveButton: {
		marginLeft: "8px",
	},
	viewDeviceButton: {
		padding: "8px",
		width: "100%",
	},
	categoriesContainer: {
		padding: "8px",
		display: "flex",
		width: "66%",
	},
	categoryContainer: {
		display: "flex",
		alignItems: "center",
		marginRight: "4px",
	},
	categoryIcon: {
		fontSize: "40px",
		color: "grey",
		margin: "0 4px",
	},
	categoryNoLink: {
		color: "black",
		fontWeight: 400,
		fontSize: "18px"
	},
	link: { 
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer"
		}
	},
	bottomRow: {
		display: "flex",
		padding: "8px",
		backgroundColor: "white"
	},
	noCursorChange: {
		cursor: "unset",
	},
	selected: {
		border: `solid ${theme.palette.pending.main} 1px`,
	},
	badge: {
		top: '1px',
		right: "-18px",
		border: `2px solid ${
			theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
		}`,
	},
	relationship: {
		position: "absolute",
		left: "8px",
		color: theme.palette.greyIcon.main,
		bottom: "17px"
	},
	endpointRelationship: {
		bottom: "14px"
	},
	selectWrapper: {
		display: "flex",
		width: "100%",
		padding: "12px",
		boxSizing: "border-box",
		alignItems: "center",
		backgroundColor: "white",
		position: "relative",
		zIndex: 5,
		marginTop: "-4px",
		borderBottom: "1px solid lightgrey",
	},
	selectionCount: {
		fontSize: "16px",
	},
	secondaryButton: {
		color: theme.palette.pending.main,
		display: "inline",
		cursor: "pointer",
		margin: "0 12px",
		textDecoration: "underline"
	},
	selectButtonsWrapper: {
		position: "relative",
		marginLeft: "auto",
		marginRight: 0,
		display: "flex"
	},
	bulkButtonsWrapper: {
		marginRight: "8px"
	},
	checkCircleIcon: {
		color: theme.palette.green.main,
		marginRight: "8px"
	},
	bulkResponse: {
		fontFamily: "Inter",
		fontSize: "20px",
		display: "flex",
		alignItems: "center"
	},
	toggled: {
		boxShadow: "none",
		color: theme.palette.primary.main,
		backgroundColor: "lightgrey",
		"&:hover": {
			boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)",
			backgroundColor: theme.palette.primary.main,
			color: "white"
		}
	},
	selection: {
		color: "grey",
		textDecoration: "unset",
		cursor: "unset"
	},
	deleteButton: {
		backgroundColor: theme.palette.red.main,
		color: "white",
		marginRight: "8px",
		'&:hover': {
			backgroundColor: darken(theme.palette.red.main, .2),
		}
	},
	deleteBorderButton: {
		marginLeft: "12px",
	},
	editButton: {
		marginLeft: "12px",
	},
	bulkIcon: {
		marginRight: "8px"
	},
	actionButton: {
		marginLeft: "12px",
	},
	contentLoaderContainer: {
		height: "206px"
	},
	sideBarContainer: {
		display: "flex",
		width: "15%",
		boxSizing: "border-box",
		marginTop: "-1px",
		backgroundColor: "white",
		zIndex: 5,
		borderRight: "solid lightgrey 1px",
		borderTop: "solid lightgrey 1px",
	},
	itemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 58px)",
		padding: "12px",
		alignItems: "flex-start",
		flexWrap: "wrap",
		width: "85%",
		backgroundColor: "#f5f5f7",
		boxSizing: "border-box",
	},
	cardItemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 58px)",
		padding: "12px",
		alignItems: "flex-start",
		flexWrap: "wrap",
		width: "85%",
		backgroundColor: "#f5f5f7",
		boxSizing: "border-box",
	},
	tableItemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 58px)",
		alignItems: "flex-start",
		flexWrap: "wrap",
		width: "85%",
		backgroundColor: "#ffffff",
		boxSizing: "border-box",
	},
	moreSpace: {
		height: "calc(100% - 112px)"
	},
	escrowCard: {
		height: "auto"
	},
	topRow: {
		display: "flex",
		flexWrap: "nowrap",
		margin: "6px 0",
		alignItems: "center",
		justifyContent: "space-between"
	},
	name: {
		width: "80%",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		fontSize: "18px"
	},
	tipOverride: {
		padding: "8px",
		boxSizing: "border-box",
		marginTop: "0px",
	},
	tip: {
		fontSize: "16px",
	},
	selectCardWrapper: {
		textAlign: "right",
	},
	cardLabelContainer: {
		marginBottom: "8px",
	},
	escrowDeviceInfo: {
		position: "relative",
		padding: "16px 24px 24px 24px",
	},
	cardLabel: {
		display: "flex",
		alignItems: "center",
		color: theme.palette.grey.main,
	},
	escrowBottomLabel: {
		marginBottom: "10px"
	},
	cardText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		fontSize: "14px",
		color: "#636366",
	},
	errorIcon: {
		color: theme.palette.red.main,
	},
	iconLabel: {
		color: "#636366",
		marginRight: "8px",
		fontSize: "20px",
	},
	cardIconRow: {
		margin: "-12px",
		padding: "12px 0",
		marginTop: "4px",
		display: "flex",
		color: "grey",
		borderTop: "solid lightgrey 1px",
		backgroundColor: "#FAFAFA"
	},
	cardIconButton: {
		width: "25%",
		textAlign: "center",
		cursor: "pointer",
		"&:hover": {
			color: "#0263FC"
		}
	},
	tipDisabled: {
		cursor: "not-allowed",
		color: "#9e9e9e",
		"&:hover": {
			color: "#9e9e9e"
		}
	},
	menuIcon: {
		border: "none",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.pending.main
		}
	},
	tableMenu: {
		color: "grey",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.pending.main
		}
	},
});

export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)
(DevicesList);
