/* eslint-disable no-unused-expressions */
import React from 'react';
import randomColor from 'randomcolor';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import SimpleModalWrapped from '../components/Containers/SimpleModalWrapped';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../components/DisplayOriented/Loading';
import SelectInput from '../components/Inputs/SelectInput';
import ListWidget from '../components/Widgets/ListWidget';
import PieGraph from '../components/Widgets/PieGraph';
import NewPieGraph from '../components/Widgets/NewPieGraph';
import BarGraph from '../components/Widgets/BarGraph';
import ErrorFeedWidget from '../components/Widgets/ErrorFeedWidget';

//services
import DeviceErrors from '../services/DataModels/DeviceErrors';
import Device from '../services/DataModels/Device';
import Report from '../services/DataModels/Report';
import GatewayCommand from '../services/DataModels/GatewayCommand';
import Notification from '../services/DataModels/Notification';
import PortalConfiguration from '../services/PortalConfiguration';
import { SnackbarContext } from '../services/ContextProviders/Snackbar';
import Auth from './../services/Auth';
import { LIST } from '../services/CLURD';
import { GetAll, AssignNestedModels } from '../services/CLURDUtilities';
import BulkResponse from '../services/DataModels/BulkResponse';

//mui
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

//icons
import BuildIcon from '@material-ui/icons/Build';
import ErrorIcon from '@material-ui/icons/Error';
import SyncIcon from '@material-ui/icons/Sync';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import WarningIcon from '@material-ui/icons/Warning';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import CheckIcon from '@material-ui/icons/Check';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const styles = theme => ({
    container: {
        fontFamily: "Inter",
        maxHeight: "calc(100% - 40px)",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        margin: "0 -24px 0 -24px",
    },
    widgetRow: {
        display: "flex",
        flexWrap: "nowrap",
        width: "100%",
        padding: "0 12px",
        boxSizing: "border-box"
    },
    thirdWidget: {
        width: "33%",
        position: "relative",
    },
    pieWidget: {
        width: "20%",
        position: "relative"
    },
    topRowWidget: {
        width: "40%",
        position: "relative"
    },
    singleRowWidget: {
        width: "100%",
        position: "relative"
    },
    twoThirdsWidget: {
        width: "calc(66% + 48px)",
        display: "inline-flex",
        position: "relative",
    },
    heartbeatPieWrapper: {
        width: "50%",
        margin: "auto",
        paddingTop: "12px"
    },
    widgetCard: {
        boxShadow: "0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24)",
        backgroundColor: "white",
        margin: "12px",
        padding: "12px",
        height: "430px",
    },
    consolidatedCard: {
        backgroundColor: theme.palette.pending.main,
        color: "white"
    },
    consolidatedLoading: {
        color: "white",
    },
    statsLoadingContainer: {
        height: "132px"
    },
    offlineLabel: {
        color: "white",
        borderRadius: "4px",
        padding: "6px",
        backgroundColor: theme.palette.heartbeat.offline.main
    },
    hoverContainer: {
        padding: "72px 48px 35px 48px",
        fontFamily: "Inter"
    },
    hoverRow: {
        padding: "6px",
        display: "flex",
        alignItems: "center"
    },
    buttonRow: {
        marginTop: "32px",
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    hoverRowLabel: {
        textTransform: "uppercase",
        color: "#8e8e93",
        minWidth: "22%",
        maxWidth: "22%",
        fontSize: "14px",
        marginRight: "12px"
    },
    hoverRowValue: {
        overflowX: "auto",
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "10px"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            backgroundColor: "#d5d6d6"
        },
        fontSize: "14px",
    },
    deviceName: {
        overflow: "hidden"
    },
    payloadArea: {
        backgroundColor: "#80808012",
        width: "100%",
        borderRadius: "4px",
        padding: "4px"
    },
    messagesLabel: {
        fontSize: "12px"
    },
    bulkStatusRow: {
        flexWrap: "wrap"
    },
    statusRow: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        marginBottom: "12px",
    },
    deletedRow: {
        alignItems: "center"
    },
    manageIconButton: {
        padding: "4px",
        color: theme.palette.pending.main
    },
    messagesContainer: {
        marginLeft: "48px"
    },
    messageContainer: {
        marginLeft: "12px"
    },
    bulkStatusRowLabel: {
        width: "100%",
        marginBottom: "12px",
    },
    manageDeviceLink: {
        color: theme.palette.pending.main,
        "-webkit-text-decoration": "underline transparent",
        textDecoration: "underline transparent",
        transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            "-webkit-text-decoration-color": theme.palette.pending.main,
            textDecorationColor: theme.palette.pending.main,
            cursor: "pointer",
        }
    },
    greenCheck: {
        color: theme.palette.green.main,
        marginRight: "4px"
    },
    redErrorOutline: {
        color: theme.palette.red.main,
        marginRight: "4px",
    },
    hoverButton: {
        marginLeft: "12px",
    },
    redButton: {
        borderColor: theme.palette.red.main,
        color: theme.palette.red.main
    },
    blueButton: {
        borderColor: theme.palette.pending.main,
        color: theme.palette.pending.main
    },
    onlineLabel: {
        color: "white",
        borderRadius: "4px",
        padding: "6px",
        display: "inline-flex",
        backgroundColor: theme.palette.green.main
    },
    neverReportedLabel: {
        color: "white",
        borderRadius: "4px",
        padding: "6px",
        display: "inline-flex",
        backgroundColor: "#c4c7c7"
    },
    idleLabel: {
        color: "white",
        borderRadius: "4px",
        padding: "6px",
        display: "inline-flex",
        backgroundColor: theme.palette.heartbeat.idle.main
    },
    statusCountLabel: {
    },
    offlineValue: {
        fontSize: "50px",
        marginRight: "6px",
        display: "inline-flex",
        minWidth: "15%",
    },
    statusCountValue: {
        minWidth: "15%",
        fontSize: "24px",
        marginRight: "6px",
        display: "inline-flex",
    },
    statusDot: {
        width: "12px",
        height: "12px",
        minWidth: "12px",
        minHeight: "12px",
        maxWidth: "12px",
        maxHeight: "12px",
        borderRadius: "50%",
        display: "inline-flex",
        marginRight: "8px"
    },
    countValue: {
        fontSize: "22px",
    },
    consolidatedDivider: {
        height: "2px",
        borderBottom: "solid 2px white",
        margin: "12px 0"
    },
    dataTitle: {
        fontSize: "20px",
    },
    dataIcon: {
        color: theme.palette.pending.main,
        fontSize: "32px",
        marginRight: "8px"
    },
    dataUsageUnit: {
        color: "#8e8e93",
        fontSize: "38px",
        marginLeft: "4px",
        display: "inline-flex"
    },
    usageContainer: {
        paddingTop: "12px",
        flexWrap: "wrap",
        height: "60%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "52px",
        borderBottom: `solid ${theme.palette.pending.main} 2px`,
        marginBottom: "12px"
    },
    dataTableTitle: {
        fontSize: "14px",
        color: "black"
    },
    consolidatedColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%"
    },
    heartbeatLegend: {
        background: theme.palette.pending.main,
        padding: "12px",
        margin: "-12px",
        paddingBottom: "6px"
    },
    topStatContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px"
    },
    consolidatedInput: {
        color: "white"
    },
    cellFilterContainer: {
        width: "100%"
    },
    filterContainer: {
        width: "50%",
        marginLeft: "6px"
    },
    countContainer: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        marginTop: "6px"
    },
    statsIcon: {
        margin: "0 12px 0 6px",
        fontSize: "24px"
    },
    statusContainer: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center"
    },
    statusDot: {
        width: "12px",
        height: "12px",
        minWidth: "12px",
        minHeight: "12px",
        maxWidth: "12px",
        maxHeight: "12px",
        borderRadius: "50%",
        display: "inline-flex",
        marginRight: "8px"
    },
});

class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.time_frame_options = [
            { display: "Past Hour", value: "hour" },
            { display: "Today", value: "today" },
            { display: "This Week", value: "week" },
            { display: "This Month", value: "month" },
        ];
        this.state = {
            consolidated: {
                filter: this.time_frame_options[1].value
            },
            modal: {
                open: false,
                children: () => ""
            },
            all_data: null,
            gateway_data: null,
            endpoint_data: null,
            cell_usage: {
                filter: "year"
            }
        };
        this.refresh_functions = {
            consolidated: this.get_stats_count
        };
        this.load_consolidated();
        this.load_types();
        this.load_cell_usage();
    }

    render_service_worker_frame = () => {
        const classes = this.props.classes
        const company = Auth.currentCompany()
        const grafanaUrl = company.metadata && company.metadata.grafana_url

        if (!grafanaUrl) {
            console.log("No grafana url, not loading service worker");
            return null
        }

        const serviceWorkerUrl = `${grafanaUrl}/sw/`
        console.log("Rendering iframe from:", grafanaUrl);

        return (
            <iframe src={serviceWorkerUrl} frameBorder={0} width={0} height={0} className={classes.hidden} />
        )
    }

    load_cell_usage = () => {
        Device.getCellUsage().then(({ items }) => {
            this.setState((state) => {
                Object.assign(state.cell_usage, {
                    year: items.year,
                    month: items.month,
                    week: items.week,
                    day: items.day
                });
                return state;
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    load_consolidated = () => {
        this.get_stats_count();
        Device.getHeartbeatStats().then((stats) => {
            stats = stats.items;
            let consolidated_state = {
                online: stats.as_expected,
                offline: stats.inconsistent,
                idle: stats.not_responsive,
                never_reported: stats.never_reported,
            };
            this.setState((state) => {
                state.consolidated = Object.assign(state.consolidated, consolidated_state);
                return state;
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    load_types = () => {
        Device.getTypesStats().then(({ items }) => {
            let all_types = [];
            let gateway_total = 0;
            let endpoint_total = 0;
            Object.entries(items).forEach(([type, id_map]) => {
                Object.entries(id_map).forEach(([type_id, { count, name }]) => {
                    let type_obj = {
                        id: type_id,
                        count: count,
                        name: name,
                        type: type
                    };
                    if (type === "gateway") {
                        gateway_total += count;
                    } else {
                        endpoint_total += count;
                    }
                    all_types.push(type_obj);
                });
            });
            let gateways = all_types.filter((type) => type.type === "gateway").sort((a, b) => b.count - a.count).slice(0, 3);
            let endpoints = all_types.filter((type) => type.type !== "gateway").sort((a, b) => b.count - a.count).slice(0, 3);
            let all = all_types.sort((a, b) => b.count - a.count).slice(0, 3);
            let gateway_other = gateway_total - gateways.reduce((sum, { count }) => sum + count, 0);
            let endpoint_other = endpoint_total - endpoints.reduce((sum, { count }) => sum + count, 0);
            let all_other = (endpoint_total + gateway_total) - all.reduce((sum, { count }) => sum + count, 0);
            let gateway_data = flatten_without_dupe_names({ Other: gateway_other }, gateways);
            let endpoint_data = flatten_without_dupe_names({ Other: endpoint_other }, endpoints);
            let all_data = flatten_without_dupe_names({ Other: all_other }, all);
            function flatten_without_dupe_names(object, array) {
                array.forEach(({ name, count }) => {
                    if (object[name]) {
                        let index = 2;
                        while (object[name + ` (${index})`]) {
                            index++;
                        }
                        object[name + ` (${index})`] = count;
                    } else {
                        object[name] = count;
                    }
                });
                return object;
            }
            this.setState({ gateway_data: gateway_data, endpoint_data: endpoint_data, all_data: all_data });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    handle_change = ({ field, value }) => {
        this.setState((state) => {
            state[field].filter = value;
            return state;
        }, () => {
            this.refresh_functions[field] ? this.refresh_functions[field]() : "";
        });
    }

    get_stats_count = () => {
        if (this.state.consolidated.errors != null) {
            this.setState((state) => {
                delete state.consolidated.errors;
                delete state.consolidated.alerts;
                delete state.consolidated.updates;
                return state;
            });
        }
        let models = ["device_errors", "notifications", "gateway_commands"];
        let promises = [];
        models.forEach((model) => {
            let params = { page_meta: true, per_page: 1 };
            if (model === "gateway_commands") {
                params.command_type = "software_update";
            }
            params.created_at_gt = this.get_time_frame(this.state.consolidated.filter);
            promises.push(LIST(model, params, Auth.token()));
        });
        Promise.all(promises).then((counts) => {
            let [errors, alerts, updates] = counts;
            let consolidated_state = {
                errors: errors.total,
                alerts: alerts.total,
                updates: updates.total
            };
            this.setState((state) => {
                state.consolidated = Object.assign(state.consolidated, consolidated_state);
                return state;
            });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    showDate = (utc) => {
        let date_object = new Date(utc);
        return date_object.toLocaleDateString();
    }

    showTime = (utc) => {
        let date_object = new Date(utc);
        return date_object.toLocaleTimeString();
    }

    get_time_frame = (time_frame) => {
        let now = new Date();
        switch (time_frame) {
            case "hour":
                now.setHours(now.getHours() - 1);
                break;
            case "today":
                now.setHours(0);
                now.setMinutes(0);
                now.setSeconds(0);
                now.setMilliseconds(0);
                break;
            case "week":
                now.setDate(now.getDate() - now.getDay());
                now.setHours(0);
                now.setMinutes(0);
                now.setSeconds(0);
                now.setMilliseconds(0);
                break;
            case "month":
                now.setDate(1);
                now.setHours(0);
                now.setMinutes(0);
                now.setSeconds(0);
                now.setMilliseconds(0);
                break;
        }
        now = now ? now.toISOString() : undefined;
        return now;
    }

    load_data_usage = () => {
        let params = { cell_usage_nin: "null" };
        return new Promise((resolve, reject) => {
            GetAll("devices", params,).then((devices) => {
                devices = devices.sort((device_a, device_b) => device_b.cell_usage.total - device_a.cell_usage.total).slice(0, 4);
                resolve(this.prepare_data_usage_table(devices));
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    prepare_data_usage_table = (devices) => {
        devices.forEach((device) => {
            device.table_cell_usage = this.show_data(device.cell_usage.total);
        });
        return devices;
    }

    load_software_updates = (filter) => {
        let params = { per_page: 6, command_type: "software_update" };
        if (filter === "all") filter = undefined;
        return new Promise((resolve, reject) => {
            GatewayCommand.listSoftwareUpdates(params, filter).then((updates) => {
                resolve(this.update_table_preparation(updates));
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_types_data = (filter) => {
        if (filter !== "all") {
            if (filter === "gateway") {
                return Promise.resolve(this.state.gateway_data);
            } else {
                return Promise.resolve(this.state.endpoint_data);
            }
        } else {
            return Promise.resolve(this.state.all_data);
        }
    }

    handle_device_widgets = (devices) => {
        let config_widget = {
            pending: 0,
            gateways: 0,
            received: 0,
            endpoints: 0
        };
        devices.forEach((device) => {
            if (this.state.type_map[device.device_type_id] && this.state.type_map[device.device_type_id].gateway === true) {
                config_widget.gateways++;
                if (device.status_at === "0001-01-01T00:00:00Z" && device.heartbeat_at === "0001-01-01T00:00:00Z") {
                    config_widget.pending++;
                } else {
                    config_widget.received++;
                }
            } else {
                config_widget.endpoints++;
            }
        });
        this.setState({ config_widget: config_widget });
    }

    convert_types_for_graph = (type_map) => {
        //color, value
        let data = [];
        let empties = [];
        Object.entries(type_map).forEach(([id, { name, count }]) => {
            if (count > 0) {
                data.push({ name: name, value: count, color: this.colors[data.length] ? this.colors[data.length] : randomColor() });
            } else {
                empties.push(name);
            }
        });
        let csv_empties = empties.join();
        return { data: data, empty_types: csv_empties };
    }

    open_rule = (id) => {
        this.props.history.push("/Policies?policy_id=" + id);

    }

    open_device = (id, is_unique_id) => {
        if (is_unique_id) {
            this.props.history.push("/Devices?device_unique_id=" + id);
        } else {
            this.props.history.push("/Devices?device_id=" + id);
        }
    }

    open_package = (id) => {
        this.props.history.push("/Software?software_update_id=" + id);
    }

    load_hb_data = (params) => {
        return new Promise((resolve, reject) => {
            let data = [{ text: "online", color: this.props.theme.palette.green.main, value: 0 }, { text: "offline", color: this.props.theme.palette.heartbeat.offline.main, value: 0 }, { text: "idle", color: this.props.theme.palette.heartbeat.idle.main, value: 0 }];
            let device_type_id_in = [];
            let company_id_in = [];
            let body = {};
            if (params) {
                params.forEach((param) => {
                    if (param.filter_name === "Device Type") {
                        device_type_id_in.push(param.option.value);
                    }
                    if (param.filter_name === "Account") {
                        company_id_in.push(param.option.value);
                    }
                });
                if (device_type_id_in.length > 0) {
                    body.device_type_id_in = device_type_id_in.join();
                }
                if (company_id_in.length > 0) {
                    body.company_id_in = company_id_in.join();
                }
            } else {
                body = {};
            }
            GetAll("devices", body)
                .then((devices) => {
                    devices.forEach((device) => {
                        if (this.state.type_map[device.device_type_id] && this.state.type_map[device.device_type_id].gateway === true) {
                            if (device.status_at !== "0001-01-01T00:00:00Z" && device.heartbeat_at !== "0001-01-01T00:00:00Z") {
                                if (device.heartbeat_status === "online") {
                                    data[0].value++;
                                } else if (device.heartbeat_status === "idle") {
                                    data[2].value++;
                                } else if (device.heartbeat_status === "offline") {
                                    data[1].value++;
                                }
                            }
                        }
                    });
                    let label = data.sort((a, b) => (a.value - b.value))[data.length - 1];
                    resolve({ data: data, label: label });
                })
                .catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject(error);
                });
        });

    }

    show_data = (data) => {
        let i = -1;
        let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        if (data == 0) {
            return "0" + byteUnits[1];
        }
        do {
            data = data / 1000;
            i++;
        } while (data > 1000);

        return Math.max(data, 0.1).toFixed(1) + byteUnits[i];
    }

    render_consolidated_widget = () => {
        const classes = this.props.classes;
        const { offline, idle, online, never_reported, errors, alerts, updates } = this.state.consolidated;
        return (
            <Card
                className={classes.widgetCard + " " + classes.consolidatedCard + " " + classes.thirdWidget}
            >
                <div className={classes.consolidatedColumn}>
                    <div>
                        {offline != null ?
                            <div className={classes.heartbeatLegend}>
                                <div className={classes.topStatContainer}>
                                    <div className={classes.statusCountValue}>{offline}</div>
                                    <div className={classes.offlineLabel}>Offline Devices</div>
                                </div>
                                <div className={classes.topStatContainer}>
                                    <div className={classes.statusCountValue}>{online}</div>
                                    <div className={classes.onlineLabel}>Online Devices</div>
                                </div>
                                <div className={classes.topStatContainer}>
                                    <div className={classes.statusCountValue}>{idle}</div>
                                    <div className={classes.idleLabel}>Idle Devices</div>
                                </div>
                                <div className={classes.topStatContainer}>
                                    <div className={classes.statusCountValue}>{never_reported}</div>
                                    <div className={classes.neverReportedLabel}>Devices Never Reported</div>
                                </div>
                            </div>
                            : <Loading color={"white"} className={classes.consolidatedLoading} />
                        }
                        <div className={classes.consolidatedDivider}>&nbsp;</div>
                        {this.render_heartbeat_pie()}
                    </div>
                </div>
            </Card>
        );
    }

    render_date = (date) => {
        function showDate(utc) {
            let date_object = new Date(utc);
            return date_object.toLocaleDateString();
        }

        function showTime(utc) {
            let date_object = new Date(utc);
            return date_object.toLocaleTimeString();
        }
        return (
            <span>
                <span style={{ whiteSpace: "nowrap", marginRight: "12px" }}>
                    {showTime(date)}
                </span>
                <span style={{ color: "#8e8e93" }}>
                    {showDate(date)}
                </span>
            </span>
        );
    }

    render_bulk_hover = (bulk) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(bulk.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Status</span>
                    <span className={classes.hoverRowValue}>{this.render_summary(bulk, true)}</span>
                </div>
                <React.Fragment>
                    {this.render_successes(bulk)}
                    {this.render_failures(bulk)}
                </React.Fragment>
            </Paper>
        );
    }

    render_successes = (bulk) => {
        if (bulk.bulk_response_statuses == null || !bulk.bulk_response_statuses || bulk.bulk_response_statuses.length === 0) return "";
        const status_code_map = { 200: "saved", 201: "created", 202: "sent" };
        const classes = this.props.classes;
        const success = bulk.bulk_response_statuses.filter(value => (value.status !== 'fail' && value.status_code !== 204)).map(value => ({ device: value.device_id, action: status_code_map[value.status_code] }))
        const deleted_count = Object.entries(bulk.bulk_response_statuses).filter((value) => value.status_code === 204).length;
        if (deleted_count > 0 || success.length > 0) {
            return (
                <div className={classes.hoverRow + " " + classes.bulkStatusRow}>
                    <span className={classes.hoverRowLabel + " " + classes.bulkStatusRowLabel}>Successes</span>
                    {deleted_count > 0 ?
                        <div className={classes.statusRow + " " + classes.deletedRow}>
                            <CheckIcon className={classes.greenCheck} />
                            <span>
                                {`${deleted_count} item${deleted_count > 1 ? 's' : ''} succesfully deleted.`}
                            </span>
                        </div>
                        : ''}
                    <div className={classes.statuses}>
                        {success.map((success) => (
                            <div key={success.device} className={classes.statusRow}>
                                <CheckIcon className={classes.greenCheck} />
                                <span>
                                    Item <span onClick={() => this.open_device(success.device)} className={classes.manageDeviceLink}>{success.device}</span> was succesfully {success.action}.
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    }

    render_failures = (bulk) => {
        if (bulk.bulk_response_statuses == null || !bulk.bulk_response_statuses || bulk.bulk_response_statuses === 0) return "";
        const classes = this.props.classes;
        const errors = bulk.bulk_response_statuses.filter(value => value.status === 'fail').map(value => ({ device: value.device_id, error: value.error, messages: value.messages }));
        if (errors.length > 0) {
            return (
                <div className={classes.hoverRow + " " + classes.bulkStatusRow}>
                    <span className={classes.hoverRowLabel + " " + classes.bulkStatusRowLabel}>Failures</span>
                    <div className={classes.statuses}>
                        {errors.map((error, index) => (
                            <div key={error.device + "_" + index}>
                                <div className={classes.statusRow + " " + (!error.messages || error.messages.length === 0 ? classes.deletedRow : "")}>
                                    <ErrorOutlineIcon className={classes.redErrorOutline} />
                                    <span>
                                        An error occurred for item {error.device}: {error.error}
                                    </span>
                                </div>
                                {error.messages && error.messages.length > 0 ? <div className={classes.messagesContainer}>
                                    <span className={classes.hoverRowLabel + " " + classes.messagesLabel}>Messages</span>
                                    {error.messages.map((message, index) => (
                                        <div className={classes.messageContainer} key={error.device + "_error_" + index}>
                                            {message}
                                        </div>
                                    ))}
                                </div> : ""}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    }

    render_update_hover = (update) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(update.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Status</span>
                    <span className={classes.hoverRowValue}>{this.render_status(update.status, true)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Software Package</span>
                    <span className={classes.hoverRowValue}>{update.package_name}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Device ID</span>
                    <span className={classes.hoverRowValue}>{update.unique_id}</span>
                </div>
                {update.unique_id ? <div className={classes.buttonRow}>
                    <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_package(update.package_id)}
                    >
                        <span className={classes.buttonText}>MANAGE SOFTWARE</span>
                    </Button>
                    <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_device(update.unique_id, true)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button>
                </div> : ""}
            </Paper>
        );
    }

    render_error_hover = (error) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(error.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Severity</span>
                    <span className={classes.hoverRowValue}>{this.render_severity(error.level, true)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Error Message</span>
                    <span className={classes.hoverRowValue}>{error.error}</span>
                </div>
                {error.device_id ? <div className={classes.buttonRow}>
                    {!this.state.hover_action_happening ? <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.device_action("log_upload", error.device_id)}
                    >
                        <span className={classes.buttonText}>UPLOAD LOGS</span>
                    </Button> : ""}
                    <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_device(error.device_id)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button>
                </div> : ""}
            </Paper>
        );
    }

    prompt_confirmation = (perform_action, command) => {
        this.setState({
            modal: {
                open: true,
                prompt: `Are you sure you want to re-issue a ${Device.commandReadable(command)} command to this device?`,
                yesFunction: () => {
                    perform_action();
                    this.close_modal();
                },
                functionText: `${Device.commandReadable(command)}`,
                children: () => ""
            }
        });
    }

    close_modal = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    device_action = (action, id, is_unique_id) => {
        this.setState({ hover_action_happening: true });
        Device.issueGatewayCommand(action, null, id, null, is_unique_id).then((result) => {
            this.setState({ hover_action_happening: false });
            this.context.openSnackbar(`${Device.commandReadable(action)} command sent to device`, 'success');
        }).catch((error) => {
            this.setState({ hover_action_happening: false });
            this.context.openSnackbar(error, "error");
        });
    }

    load_errors = (filter) => {
        let params = { per_page: 6 };
        if (filter !== "all") {
            if (filter === "warning") {
                params.level_lte = 3;
            } else {
                params.level = filter;
            }
        }
        return new Promise((resolve, reject) => {
            new DeviceErrors().listFromAPI(params).then(({ items }) => {
                let errors = items;
                resolve(this.error_table_preparation(errors));
            }).catch((error) => {
                //this isnt the error you're looking for...
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    command_table_preparation = (commands) => {
        commands.forEach((command) => {
            command.table_status = () => this.render_status(command.status);
            command.table_action = () => this.render_action(command.command_type);
        });
        return commands;
    }

    error_table_preparation = (errors) => {
        errors.forEach((error) => {
            const error_message = error.error;
            error.error = error_message;
            error.table_level = () => this.render_severity(error.level);
        });
        return errors;
    }

    update_table_preparation = (updates) => {
        updates.forEach((update) => {
            update.table_status = () => this.render_status(update.status);
        });
        return updates;
    }

    bulk_jobs_table_preparation = (bulk_jobs) => {
        bulk_jobs.forEach((bulk_job) => {
            bulk_job.brs_successes_by_device = {};
            bulk_job.brs_failures_by_device = {};
            const no_statuses = bulk_job.bulk_response_statuses == null || Object.entries(bulk_job.bulk_response_statuses).length === 0;
            if (no_statuses) {
                bulk_job.table_successes = <span style={{ color: "#8e8e93" }}>Unset</span>;
                bulk_job.table_failures = <span style={{ color: "#8e8e93" }}>Unset</span>;
                bulk_job.table_summary = () => this.render_summary(null);
            } else {
                if (bulk_job.bulk_response_statuses != null) {
                    bulk_job.bulk_response_statuses.forEach((bulk_response_status) => {
                        if (bulk_response_status.status == 'fail') {
                            bulk_job.brs_failures_by_device[bulk_response_status.device_id] = (bulk_response_status.device_id in bulk_job.brs_failures_by_device) ? [...bulk_job.brs_failures_by_device[bulk_response_status.device_id], bulk_response_status] : [bulk_response_status];
                        } else {
                            bulk_job.brs_successes_by_device[bulk_response_status.device_id] = (bulk_response_status.device_id in bulk_job.brs_successes_by_device) ? [...bulk_job.brs_successes_by_device[bulk_response_status.device_id], bulk_response_status] : [bulk_response_status];
                        }
                    });
                }
                bulk_job.table_successes = bulk_job.brs_successes_by_device ? Object.entries(bulk_job.brs_successes_by_device).length : 0;
                bulk_job.table_failures = bulk_job.brs_failures_by_device ? Object.entries(bulk_job.brs_failures_by_device).length : 0;
                bulk_job.table_summary = () => this.render_summary(bulk_job);
            }
        });
        return bulk_jobs;
    }

    render_status = (status, with_label) => {
        let icon = null;
        let title = null;
        if (status === "Success") {
            icon = <CheckCircleIcon style={{ color: this.props.theme.palette.green.main }} />;
            title = "Success";
        } else if (status === "Fail") {
            icon = <ErrorIcon style={{ color: this.props.theme.palette.red.main }} />;
            title = "Failure";
        } else {
            icon = <MailOutlineIcon style={{ color: "#8e8e93" }} />;
            title = "Sent";
        }
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title={title}>
                    {icon}
                </Tooltip>
                {with_label ? <span style={{ marginLeft: "6px" }}>{title}</span> : ""}
            </div>
        );
    }

    render_action = (action) => {
        let action_map = GatewayCommand.getActionMap();
        let text = action_map[action];
        let icon = null;
        switch (action) {
            case "send_config":
                icon = <SyncIcon style={{ color: "#8e8e93", fontSize: "20px", marginRight: "4px" }} />
                break;
            case "reboot":
                icon = <PowerSettingsNewIcon style={{ color: "#8e8e93", fontSize: "20px", marginRight: "4px" }} />
                break;
            case "log_upload":
                icon = <CloudUploadIcon style={{ color: "#8e8e93", fontSize: "20px", marginRight: "4px" }} />
                break;
            case "disable_data_restriction":
                icon = <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", position: "relative" }}><span style={{ position: "absolute", left: "-10px", top: "50%", right: 0, borderBottom: "solid white 1px", transform: "rotate(145deg)", backgroundColor: "black", padding: "1px" }}></span><DataUsageIcon style={{ color: "#8e8e93", fontSize: "20px", marginRight: "4px" }} /></div>;
                break;
            case "enable_data_restriction":
                icon = <DataUsageIcon style={{ color: "#8e8e93", fontSize: "20px", marginRight: "4px" }} />
                break;
            default:
                icon = <BuildIcon style={{ color: "#8e8e93", fontSize: "20px", marginRight: "4px" }} />
                break;
        }
        let action_rendered = <div style={{ display: "flex", "alignItems": "center", whiteSpace: "normal" }}>{icon}{text}</div>;
        return action_rendered;
    }

    render_severity = (level, with_label) => {
        let title = "";
        let icon = "";
        if (level < 4) {
            title = "Warning";
            icon = <WarningIcon style={{ color: this.props.theme.palette.caution.main }} />;
        } else if (level === 4) {
            title = "Default";
            icon = <ErrorIcon style={{ color: this.props.theme.palette.red.main }} />;
        } else {
            title = "Critical";
            icon = <NewReleasesIcon style={{ color: this.props.theme.palette.red.main }} />;
        }
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Tooltip key={title} title={title}>
                    {icon}
                </Tooltip>
                {with_label ? <span style={{ marginLeft: "6px" }}>{title}</span> : ""}
            </div>
        );
    }

    render_summary = (bulk_job, with_label) => {
        let title = "Pending";
        let icon = <AccessTimeIcon style={{ color: "#8e8e93" }} />;
        if (bulk_job != null) {
            let fail_count = bulk_job.table_failures;
            let success_count = bulk_job.table_successes;
            if ((fail_count + success_count) == bulk_job.total) {
                if (fail_count === 0) {
                    title = "Completed with No Failures";
                    icon = <CheckCircleIcon style={{ color: this.props.theme.palette.green.main }} />;
                } else if (fail_count > 0 && success_count > 0) {
                    title = "Completed with Some Failures";
                    icon = <WarningIcon style={{ color: this.props.theme.palette.caution.main }} />;
                } else if (fail_count > 0 && success_count === 0) {
                    title = "Completed with No Successes";
                    icon = <ErrorIcon style={{ color: this.props.theme.palette.red.main }} />;
                }
            }
        }
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title={title}>
                    {icon}
                </Tooltip>
                {with_label ? <span style={{ marginLeft: "6px" }}>{title}</span> : ""}
            </div>
        );
    }

    render_alerts_list = () => {
        const classes = this.props.classes;
        const headings = [
            [
                { label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date" },
                { label: "MESSAGE", field: "message", align: "left", disablePadding: false, sortable: false },
            ],
            [
                { label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date" },
                { label: "DEVICE", field: "device_name", align: "left", disablePadding: false, sortable: false },
            ],
            [
                { label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date" },
                { label: "DEVICE", field: "device_name", align: "left", disablePadding: false, sortable: false },
            ]
        ];
        const links = [{ display: "VIEW ALERTS", link: "/Alerts" }, { display: "VIEW HEARTBEATS", link: "/Messages?heartbeats" }, { display: "VIEW REPORTS", link: "/Messages?reports" }];
        return (
            <Card className={classes.widgetCard + " " + classes.thirdWidget}>
                <ListWidget hover={[this.render_alert_hover, this.render_heartbeat_hover, this.render_report_hover]} multiple={true} load={[this.load_alerts, this.load_heartbeats, this.load_reports]} headings={headings} link={links} title={["Alerts", "Heartbeats", "Reports"]} />
            </Card>
        );
    }

    render_alert_hover = (alert) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(alert.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Message</span>
                    <span className={classes.hoverRowValue}>{alert.message}</span>
                </div>
                <div className={classes.buttonRow}>
                    {alert.rule_id ? <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_rule(alert.rule_id)}
                    >
                        <span className={classes.buttonText}>MANAGE POLICY</span>
                    </Button> : ""}
                    {alert.device_id ? <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_device(alert.device_id)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button> : ""}
                </div>
            </Paper>
        );
    }

    render_heartbeat_hover = (heartbeat) => {
        return this.render_report_hover(heartbeat, true);
    }

    render_report_hover = (report, is_heartbeat) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(report.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Device ID</span>
                    <span className={classes.hoverRowValue}>{report.device_name}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Payload</span>
                    <pre className={classes.hoverRowValue + " " + classes.payloadArea}>{JSON.stringify(report.payload, null, 2)}</pre>
                </div>
                <div className={classes.buttonRow}>
                    {report.device_id && !this.state.hover_action_happening && is_heartbeat ? <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.device_action("heartbeat", report.device_id)}
                    >
                        <span className={classes.buttonText}>PROMPT HEARTBEAT</span>
                    </Button> : ""}
                    {report.device_id ? <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_device(report.device_id)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button> : ""}
                </div>
            </Paper>
        );
    }

    load_commands = (filter) => {
        let params = { per_page: 6 };
        if (filter !== "all") {
            params.command_type = filter;
        } else {
            params.command_type_ne = "software_update";
        }
        return new Promise((resolve, reject) => {
            new GatewayCommand().listFromAPI(params).then(({ items }) => {
                let gateway_commands = items;
                let commands = GatewayCommand.flatten(gateway_commands).slice(0, 6);
                resolve(this.command_table_preparation(commands));
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_bulk_jobs = (filter) => {
        let params = { per_page: 6 };
        if (filter !== "all") {
            switch (filter) {
                case "pending":
                    params.status_ne = "completed";
                    break;
                case "errors":
                    params.fail_ne = "null";
                    params.success = "null";
                    break;
                case "some_errors":
                    params.fail_ne = "null";
                    params.success_ne = "null";
                    break;
                case "no_errors":
                    params.fail = "null";
                    break;
            }
        }
        return new Promise((resolve, reject) => {
            new BulkResponse().listFromAPI(params).then(({ items }) => {
                resolve(this.bulk_jobs_table_preparation(items));
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_alerts = () => {
        let params = { per_page: 6 };
        return new Promise((resolve, reject) => {
            new Notification().listFromAPI(params).then(({ items }) => {
                let alerts = items;
                resolve(alerts);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_reports = (heartbeats_only) => {
        let params = { per_page: 6 };
        params.heartbeat = heartbeats_only ? true : false;
        return new Promise((resolve, reject) => {
            new Report().listFromAPI(params).then(({ items }) => {
                let reports = items;
                resolve(reports);
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    load_heartbeats = () => {
        return this.load_reports(true);
    }

    render_bulk_list = () => {
        const classes = this.props.classes;
        const headings = [
            { label: "Summary", field: "table_summary", align: "center", disablePadding: false, sortable: false, content: "function" },
            { label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date" },
            { label: "Successes", field: "table_successes", align: "right", disablePadding: false, sortable: false },
            { label: "Failures", field: "table_failures", align: "right", disablePadding: false, sortable: false },
        ];
        const filters = [
            { display: "All Results", value: "all" },
            { display: "Pending Jobs", value: "pending" },
            { display: "Partial Failure", value: "some_errors" },
            { display: "Total Failure", value: "errors" },
            { display: "Successful", value: "no_errors" },
        ];
        const link = { display: "VIEW BULK JOBS", link: "/Messages?bulk" }
        return (
            <Card className={classes.widgetCard + " " + classes.thirdWidget}>
                {/* <ListWidget hover={this.render_bulk_hover} load={this.load_bulk_jobs} filterOptions={filters} headings={headings} link={link} title="Bulk Jobs"/> */}
                <ListWidget hover={this.render_bulk_hover} load={this.load_bulk_jobs} headings={headings} link={link} title="Bulk Jobs" />
            </Card>
        );
    }

    render_commands_list = () => {
        const classes = this.props.classes;
        const headings = [
            { label: "Status", field: "table_status", align: "center", disablePadding: false, sortable: false, content: "function" },
            { label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date" },
            { label: "Action", field: "table_action", align: "left", disablePadding: false, sortable: false, content: "function" },
            { label: "Device", field: "unique_id", align: "left", disablePadding: false, sortable: false },
        ];
        const filters = [
            { display: "All Actions", value: "all" },
            { display: "Send Config", value: "send_config" },
            { display: "Disable Data", value: "disable_data_restriction" },
            { display: "Enable Data", value: "enable_data_restriction" },
            { display: "Upload Logs", value: "log_upload" },
            { display: "Reboot", value: "reboot" },
        ];
        return (
            <Card className={classes.widgetCard + " " + classes.singleRowWidget}>
                <ListWidget hover={this.render_command_hover} load={this.load_commands} filterOptions={filters} headings={headings} title="Commands" />
            </Card>
        );
    }

    render_command_hover = (command) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Date</span>
                    <span className={classes.hoverRowValue}>{this.render_date(command.created_at)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Status</span>
                    <span className={classes.hoverRowValue}>{this.render_status(command.status, true)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Action</span>
                    <span className={classes.hoverRowValue}>{this.render_action(command.command_type)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Device ID</span>
                    <span className={classes.hoverRowValue}>{command.unique_id}</span>
                </div>
                {command.unique_id && command.command_type !== "data_restriction" ? <div className={classes.buttonRow}>
                    {!this.state.hover_action_happening ?
                        <Button
                            className={classes.hoverButton}
                            color="primary"
                            onClick={() => this.prompt_confirmation(() => this.device_action(command.command_type, command.unique_id, true), command.command_type)}
                        >
                            <span className={classes.buttonText}>REISSUE COMMAND</span>
                        </Button> : ""}
                    <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_device(command.unique_id, true)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button>
                </div> : ""}
            </Paper>
        );
    }

    render_device_name = (device) => {
        const { classes, theme } = this.props;
        const status = device.heartbeat_status;
        const status_map = { offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: "#8e8e93" };
        return (
            <div className={classes.statusContainer}>
                <div style={{ backgroundColor: status_map[status] }} className={classes.statusDot}>
                    &nbsp;
                </div>
                <div>
                    {device.name}
                </div>
            </div>
        );
    }

    render_data_hover = (device) => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.hoverContainer}
            >
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Device</span>
                    <span className={classes.hoverRowValue + " " + classes.deviceName}>{this.render_device_name(device)}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Device ID</span>
                    <span className={classes.hoverRowValue}>{device.unique_id}</span>
                </div>
                <div className={classes.hoverRow}>
                    <span className={classes.hoverRowLabel}>Data Usage</span>
                    <span className={classes.hoverRowValue}>{device.table_cell_usage}</span>
                </div>
                <div className={classes.buttonRow}>
                    {!this.state.hover_action_happening ?
                        <Button
                            className={classes.hoverButton}
                            color="primary"
                            onClick={() => this.device_action("disable_data_restriction", device._id)}
                        >
                            <span className={classes.buttonText}>DISABLE DATA</span>
                        </Button> : ""}
                    {!this.state.hover_action_happening ?
                        <Button
                            className={classes.hoverButton}
                            color="primary"
                            onClick={() => this.device_action("enable_data_restriction", device._id)}
                        >
                            <span className={classes.buttonText}>ENABLE DATA</span>
                        </Button> : ""}
                    <Button
                        className={classes.hoverButton}
                        color="primary"
                        onClick={() => this.open_device(device._id)}
                    >
                        <span className={classes.buttonText}>MANAGE DEVICE</span>
                    </Button>
                </div>
            </Paper>
        );
    }

    render_data_usage = () => {
        if (Auth.currentCompany().portal_configuration.widgets.data_usage_hidden) return "";
        const classes = this.props.classes;
        const headings = [
            { label: "Name", field: "name", align: "center", disablePadding: false, sortable: false },
            { label: "ID", field: "unique_id", align: "center", disablePadding: false, sortable: false },
            { label: "Total Data Usage", field: "table_cell_usage", align: "right", disablePadding: false, sortable: false },
        ];
        const filters = [
            { display: "Year", value: "year" },
            { display: "Month", value: "month" },
            { display: "Week", value: "week" },
            { display: "Today", value: "day" },
        ];
        return (
            <Card className={classes.widgetCard + " " + classes.thirdWidget}>
                <div className={classes.consolidatedColumn}>
                    <div className={classes.dataTitle}>
                        Total Cell Data Usage
                    </div>
                    <div className={classes.usageContainer}>
                        {this.state.cell_usage ?
                            <React.Fragment>
                                <DataUsageIcon className={classes.dataIcon} />
                                <div>
                                    {this.show_data(this.state.cell_usage[this.state.cell_usage.filter]).split(" ")[0]}
                                    <div className={classes.dataUsageUnit}>
                                        {this.show_data(this.state.cell_usage[this.state.cell_usage.filter]).split(" ")[1]}
                                    </div>
                                </div>
                                <div className={classes.cellFilterContainer}>
                                    <div className={classes.filterContainer}>
                                        <SelectInput
                                            basic
                                            priorState={this.state.cell_usage.filter}
                                            emitChange={this.handle_change}
                                            options={filters}
                                            field="cell_usage"
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                            : <Loading />
                        }
                    </div>
                    <div className={classes.dataTableTitle}>
                        Devices with the Most Data Usage
                    </div>
                    <ListWidget hover={this.render_data_hover} load={this.load_data_usage} headings={headings} />
                </div>
            </Card>
        );
    }

    capitalize = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    render_heartbeat_pie = () => {
        const classes = this.props.classes;
        const link = { display: "VIEW HEARTBEATS", link: "/Messages" };
        let data = this.state.consolidated.offline != null ? [
            { title: "Online", value: this.state.consolidated.online, color: this.props.theme.palette.green.main },
            { title: "Offline", value: this.state.consolidated.offline, color: this.props.theme.palette.heartbeat.offline.main },
            { title: "Idle", value: this.state.consolidated.idle, color: this.props.theme.palette.heartbeat.idle.main },
            { title: "Never Reported", value: this.state.consolidated.never_reported, color: "#c4c7c7" }
        ] : null;
        return (
            <Card className={classes.widgetCard + " " + classes.pieWidget + " " + classes.consolidatedCard}>
                <NewPieGraph title={"Heartbeat Status"} data={data} />
            </Card>
        );
    }

    render_error_feed = () => {
        const classes = this.props.classes;
        return (
            <Card className={classes.widgetCard + " " + classes.singleRowWidget}>
                <ErrorFeedWidget />
            </Card>
        );
    }

    render_types_bar = () => {
        const classes = this.props.classes;
        let gateway_alias = this.capitalize(Auth.currentCompany().aliases.gateway);
        const filters = [
            { display: "All Devices", value: "all" },
            { display: gateway_alias + " Devices", value: "gateway" },
            { display: "Endpoint Devices", value: "endpoint" }
        ];
        // const links = [ { display: "VIEW DEVICES", link: "/Devices"}, { display: "VIEW INTEGRATIONS", link: "/Integrations"}];
        const link = { display: "VIEW DEVICES", link: "/Devices" };
        return (
            <Card className={classes.widgetCard + " " + classes.topRowWidget}>
                {this.state.gateway_data == null ?
                    <Loading />
                    :
                    <BarGraph load={this.load_types_data} title={"Devices by Type"} link={link} filterOptions={filters} />
                }
            </Card>
        );
    }

    load_integration_data = () => {
        return new Promise((resolve, reject) => {
            let params = { device_integration_id_nin: "null" };
            GetAll("devices", params).then((devices) => {
                let promises = [AssignNestedModels("integrations", "device_integration_id", devices), AssignNestedModels("device_ha_groups", "device_ha_group_id", devices)];
                Promise.all(promises).then(() => {
                    devices = devices.filter((device) => {
                        if (device.nested_device_ha_group && device.nested_device_ha_group.controller_device.device_id === device._id) {
                            return false;
                        } else { return true };
                    });
                    let data = {
                        "AWS Greengrass": { installed: 0, "not installed": 0, pending: 0 }, "AWS IoT": 0, Azure: 0, Bluemix: 0
                    };
                    let lookup = { aws_device_integrations: "AWS IoT", azure_device_integrations: "Azure", bluemix_device_integrations: "Bluemix" };
                    devices.forEach((device) => {
                        if (!device.nested_device_integration || lookup[device.nested_device_integration.type] === undefined) return;
                        if (!device.nested_device_integration.greengrass_core_install_url) {
                            data[lookup[device.nested_device_integration.type]]++;
                        } else {
                            if (!device.config_status || device.heartbeat_status === "never_reported") {
                                data["AWS Greengrass"].pending++;
                            } else if (device.config_status.greengrass_installed === true) {
                                data["AWS Greengrass"].installed++;
                            } else {
                                data["AWS Greengrass"]["not installed"]++;
                            }
                        }
                    });
                    resolve(data);
                }).catch((error) => {
                    this.context.openSnackbar(error, "error");
                    reject();
                });
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }


    render_updates_list = () => {
        const classes = this.props.classes;
        const headings = [
            { label: "Status", field: "table_status", align: "center", disablePadding: false, sortable: false, content: "function" },
            { label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date" },
            { label: "Device", field: "unique_id", align: "left", disablePadding: false, sortable: false },
        ];
        const filters = [
            { display: "All Results", value: "all" },
            { display: "Success", value: "Success" },
            { display: "Failure", value: "Fail" },
        ];
        const link = { display: "VIEW PACKAGES", link: "/Software" }
        return (
            <Card className={classes.widgetCard + " " + classes.thirdWidget}>
                <ListWidget hover={this.render_update_hover} load={this.load_software_updates} link={link} filterOptions={filters} headings={headings} title="Software Updates" />
            </Card>
        );
    }

    render_updates_pie = () => {
        const classes = this.props.classes;
        const link = { display: "VIEW DEVICES", link: "/Devices" }
        return (
            <Card className={classes.widgetCard + " " + classes.thirdWidget}>
                <NewPieGraph link={link} title={"Software Update Results"} load={this.load_software_pie} filterOptions={this.time_frame_options} map={this.map_software_pie} />
            </Card>
        );
    }

    load_software_pie = (filter) => {
        let params = { command_type: "software_update" };
        params.created_at_gt = this.get_time_frame(filter);
        return new Promise((resolve, reject) => {
            GetAll("gateway_commands", params).then((updates) => {
                resolve(GatewayCommand.flatten(updates, true));
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
                reject();
            });
        });
    }

    map_software_pie = (updates) => {
        let legend = [
            { title: "Successes", value: 0, color: this.props.theme.palette.green.main },
            { title: "Failures", value: 0, color: this.props.theme.palette.red.main },
            { title: "Pending", value: 0, color: "#d5d6d6" }
        ];
        updates.forEach((update) => {
            if (update.status === "Success") {
                legend[0].value++;
            } else if (update.status === "Fail") {
                legend[1].value++;
            } else {
                legend[2].value++;
            }
        });
        return legend;
    }

    render() {
        const { classes } = this.props;
        // Yes, this is unsustainable visibility logic.
        // TODO: add a flexible layout container here
        let renderFinalRows = () => {
            if (Auth.widgetHidden("software_updates")) {
                return (
                    <React.Fragment>
                        <div className={classes.widgetRow}>
                            {this.render_alerts_list()}
                            {this.render_commands_list()}
                        </div>
                        <div className={classes.widgetRow}>
                            {this.render_error_feed()}
                        </div>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <div className={classes.widgetRow}>
                            {this.render_alerts_list()}
                            {this.render_updates_list()}
                            {this.render_updates_pie()}
                        </div>
                        <div className={classes.widgetRow}>
                            {this.render_commands_list()}
                        </div>
                        <div className={classes.widgetRow}>
                            {this.render_error_feed()}
                        </div>
                    </React.Fragment>
                )
            }
        }
        return (
            <div className={classes.container}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                <div className={classes.widgetRow}>
                    {this.render_heartbeat_pie()}
                    {this.render_types_bar()}
                    {this.render_bulk_list()}
                </div>
                {renderFinalRows()}
                {this.render_service_worker_frame()}
            </div>
        );
    }
}

Overview.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(Overview));
