import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	numberField: {
		width: "100%",
	}
});

class NumberInput extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
	}

	localChange = (event) => {
		let { value } = function () {
			return {
				value: this.target.value
			};
		}.bind(event)();
		value = parseInt(value);
		if (value < this.props.minimum || (this.props.maximum && value > this.props.maximum)) {

		} else {
			this.value = value;
			this.props.emitChange({label: this.props.label, value: value, field: this.props.field});
		}
	}

	render() {
		const {disabled, variant, priorState, allowNull, label, rootClass, classes } = this.props;
		this.value = priorState || 0;
		if (allowNull && !priorState) {
			this.value = "";
		}
		let root_class = rootClass || classes.numberField;
		return (
			<TextField
				disabled={disabled}
				fullWidth={true}
				id={"numberField_" + label}
				label={label}
				name={label}
				type="number"
				margin={this.props.margin}
				className={root_class}
				variant={variant}
				value={this.value}
				onChange={this.localChange}
			/>
		);
	}
}

export default withStyles(styles)(withTheme()(NumberInput));
