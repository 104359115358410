import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';
import Permissions from "../Permissions";
import { API_PROXY } from '../CLURD';

import Auth from '../Auth';

class Rule extends DataModel {

    constructor(data, dontFormat) {
        super("rules", dontFormat ? data : format(data));
    }

    //should validate a rule for...
    // empty object is valid
    // keys in return objects are messages that explain the issue
    // also valid prop for boolean on save
    static validate(editableCopy, integrationTypes) {

    }

    static loadRequirements(policies) {
        return new Promise((resolve, reject) => {
            Promise.all([AssignNestedModels("companies", "company_id", policies)]).then((result) => {
                resolve(policies);
            }).catch((error) => reject(error));
        });
    }

    static getRelations(rule_id, relation) {
        const token = Auth.token();
        let env = window.API;
        if (env.charAt(env.length - 1) !== '/') {
            env += "/";
        }
        const options = {
            method: "GET",
            url: `${env}rules/${rule_id}/${relation}`,
            headers: {
                'accept': 'application/json',
                'authorization': token,
            }
        };
        return new Promise((resolve, reject) => {
            API_PROXY(options, token).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static addRelation(rule_id, relation_id, relation) {
        const token = Auth.token();
        let env = window.API;
        if (env.charAt(env.length - 1) !== '/') {
            env += "/";
        }
        const options = {
            method: "PUT",
            url: `${env}rules/${rule_id}/${relation}/${relation_id}`,
            headers: {
                'accept': 'application/json',
                'authorization': token,
            }
        };
        return new Promise((resolve, reject) => {
            API_PROXY(options, token).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static addRelations(rule_id, relation_ids, relation) {
        const token = Auth.token();
        let env = window.API;
        if (env.charAt(env.length - 1) !== '/') {
            env += "/";
        }
        let body = {
            ids: relation_ids
        };
        const options = {
            data: body,
            method: "PUT",
            url: `${env}rules/${rule_id}/${relation}/bulk`,
            headers: {
                'accept': 'application/json',
                'authorization': token,
            }
        };
        return new Promise((resolve, reject) => {
            API_PROXY(options, token).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static removeRelation(rule_id, relation_id, relation) {
        const token = Auth.token();
        let env = window.API;
        if (env.charAt(env.length - 1) !== '/') {
            env += "/";
        }
        const options = {
            method: "DELETE",
            url: `${env}rules/${rule_id}/${relation}/${relation_id}`,
            headers: {
                'accept': 'application/json',
                'authorization': token,
            }
        };
        return new Promise((resolve, reject) => {
            API_PROXY(options, token).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static prepForList(rules) {
        function check_for_integration_action(rule) {
            rules.forEach((rule) => {
                if (rule.then_actions) {
                    rule.then_actions.forEach((action) => {
                        if (action.integration_id && action.integration_id !== "" && !action.nested_integration) {
                            rule.view_disabled = true;
                        }
                    });
                }
                if (rule.else_actions) {
                    rule.else_actions.forEach((action) => {
                        if (action.integration_id && action.integration_id !== "" && !action.nested_integration) {
                            rule.view_disabled = true;
                        }
                    });
                }
            });
            return rules;
        }
        let promises = [];
        if (Permissions.allow(["read"], "integration")) {
            promises.push(new Promise((resolve, reject) => {
                AssignNestedModels("integrations", "integration_id", rules, "rules").then(() => {
                    rules = check_for_integration_action(rules);
                    resolve(rules);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });
            }));
        }
        else {
            rules = check_for_integration_action(rules);
            promises.push(Promise.resolve(rules));
        }
        return Promise.all(promises);
    }

    static prepareBody(rule, formatted) {
        let body = {
            active: rule.active,
            description: rule.description,
            cloud_rule: rule.cloud_rule,
            device_ids: rule.device_ids.map((device) => device.whole._id),
            device_type_ids: rule.device_type_ids.map((type) => type.whole._id),
            company_id: rule.company_id,
        };

        if (rule.conditionLogic && rule.conditionLogic !== null && (rule.conditionLogic.type === "and" || rule.conditionLogic.type === "or")) {
            let rule_conditions = [];
            rule.conditionLogic.rule_conditions.forEach((condition) => {
                conditionToAPIFormat(condition)
                rule_conditions.push(condition);
            });
            body.rule_condition = { type: rule.conditionLogic.type, rule_conditions: rule_conditions };
        } else if (rule.conditionLogic && rule.conditionLogic !== null) {
            conditionToAPIFormat(rule.conditionLogic);
            body.rule_condition = rule.conditionLogic;
        } else {
            body.rule_condition = { type: rule.condition };
            if (body.rule_condition.type === "device_error") {
                body.rule_condition.error_type = rule.conditionErrorType;
            }
        }

        if (rule.condition === "false") {
            body.elseActions = body.thenActions;
            body.thenActions = [];
        }

        if(rule.rule_condition && rule.rule_condition.derived_values) {
            body.rule_condition.derived_values = rule.rule_condition.derived_values;
        }
        //todo prevent api error by ensuring scheduled date is in the future, but only matters if the date wasn't loaded in as old
        //todo prevent error/confusion by ensuring schedule.repeats is greater than 2.
        if (!rule.reactive) {
            body.scheduled_only = true;
            body.schedule = getScheduleString(rule);
        } else {
            body.scheduled_only = false;
            body.schedule = "";
        }
        if (rule._id && rule._id !== "") {
            body._id = rule._id;
        }
        body.then_actions = rule.thenActions != null ? rule.thenActions.map(mapAction) : [];
        body.else_actions = rule.elseActions != null ? rule.elseActions.map(mapAction) : [];
        return body;

        function conditionToAPIFormat(condition) {

            if (Array.isArray(condition.value) && condition.value.length === 1) {
                condition.value = condition.value[0];
                if ((condition.type === "in" || condition.type === "not_in")) {
                    condition.type = condition.type === "in" ? "equal" : "not_equal";
                }
            }
            if (Array.isArray(condition.value) && (condition.type === "equal" || condition.type === "not_equal")) {
                condition.type = condition.type === "equal" ? "in" : "not_in";
            }
        }

        function mapAction(action) {
            let action_json = {};
            if (action.action_frequency) {
                action_json.action_frequency = action.action_frequency;
            }
            if (action.retries) {
                action_json.retries = action.retries;
                action_json.retry_timeout = action.retry_timeout;
            }
            action_json.type = action.type;

            action.fields.forEach((field) => {
                if (field.field === "headers") {
                    action_json[field.field] = field.value.reduce((acc, header) => { acc[header.key] = header.value; return acc; }, {});
                } else {
                    action_json[field.field] = field.value;
                }
            });

            return action_json;
        }

        function getScheduleString(rule) {
            let date_object = new Date(rule.schedule.date);
            let schedule_string = date_object.toISOString() + "/";
            if (rule.schedule.offset !== null) {
                if (rule.schedule.offset.Weeks.value !== '' && rule.schedule.offset.Weeks.value !== 0) {
                    schedule_string += "P" + rule.schedule.offset.Weeks.value + "W";
                } else {
                    let offset_string = 'P';
                    let labels = ['Y', 'M', 'D', 'T', 'H', 'M', 'S'];
                    let offsets = [rule.schedule.offset.Years.value, rule.schedule.offset.Months.value, rule.schedule.offset.Days.value, 'T',
                    rule.schedule.offset.Hours.value, rule.schedule.offset.Minutes.value, rule.schedule.offset.Seconds.value];
                    offsets.forEach((offset, index) => {
                        if (offset === 'T') {
                            offset_string += offset;
                        }
                        else if (offset !== '' && offset !== 0) {
                            offset_string += offset + labels[index];
                        }
                    });
                    if (offset_string.charAt(offset_string.length - 1) === 'T') {
                        offset_string = offset_string.slice(0, offset_string.length - 1);
                    }
                    schedule_string += offset_string;
                }
                if (rule.schedule.definite) {
                    let repeats = rule.schedule.repeats;
                    if (rule.schedule.repeats < 2) {
                        //todo
                    } else {
                        repeats -= 1;
                    }
                    schedule_string = "R" + repeats + "/" + schedule_string;
                } else {
                    schedule_string = "R/" + schedule_string;
                }
            } else {
                schedule_string = "R0/" + schedule_string;
            }
            return schedule_string;
        }
    }
}

function format(rule) {
    if (!rule || rule === null) {
        return blank;
    }
    let formatted = {};
    if (rule !== null) {
        formatted = {
            active: rule.active,
            _id: rule._id,
            description: rule.description,
            reactive: rule.scheduled_only ? false : true,
            device_ids: rule.device_ids,
            device_type_ids: rule.device_type_ids,
            cloud_rule: rule.cloud_rule,
            company_id: rule.company_id,
            actions: null,
            conditions: null,
            types: null,
            devices: null,
            deviceTypes: null,
            availableActions: null,
        };
        formatted = formatConditions(rule, formatted);
        formatted = formatActions(rule, formatted);
        formatted = formatSchedule(rule, formatted);
    }
    return formatted;

    function formatConditions(rule, formatted) {
        let condition = rule.rule_condition;
        if (!condition) return formatted;
        if (logicOnlyCondition(condition)) {
            //todo for when the rule conditions are nested more than one level deep
        } else {
            switch (condition.type) {
                case "false":
                case "heartbeat_status_changed":
                case "status_changed":
                case "true":
                    formatted.condition = condition.type;
                    break;
                case "device_error":
                    formatted.condition = "device_error";
                    formatted.conditionErrorType = condition.error_type;
                    break;
                default:
                    formatted.condition = "custom";
                    formatted.conditionLogic = buildConditionLogic(condition);
            }
        }
        return formatted;
    }

    function buildConditionLogic(condition) {
        let condition_logic = {};
        condition_logic = toUIConditionFormat(condition);
        condition_logic.type = condition.type;
        condition_logic.property = condition.property;
        condition_logic.value_type = condition.value_type || null;
        condition_logic.rule_conditions = condition.rule_conditions || [];
        condition_logic.rule_conditions.forEach((condition) => {
            condition = toUIConditionFormat(condition);
        });
        return condition_logic;
    }

    function toUIConditionFormat(condition) {
        if (condition.type === "contains" || condition.type === "greater_than" || condition.type === "less_than" || condition.type === "less_than_equal" || condition.type === "greater_than_equal" || condition.type === "equal" || condition.type === "not_equal" || condition.type === "in" || condition.type === "not_in") {
            condition.type = condition.type === "in" ? "equal" : condition.type;
            condition.type = condition.type === "not_in" ? "not_equal" : condition.type;
            if (!Array.isArray(condition.value)) {
                let value = JSON.parse(JSON.stringify(condition.value));
                condition.value = [];
                condition.value.push(value);
            }
        } else if (condition.type !== "true" && condition.type !== "false" && condition.value) {
            let value = JSON.parse(JSON.stringify(condition.value));
            condition.value = [];
            condition.value.push(value);
        }
        if (!condition.rule_conditions) {
            condition.rule_conditions = [];
        }
        return condition;
    }

    function formatActions(rule, formatted) {
        if (!rule.rule_condition) return formatted;
        if (formatted.condition === "custom" && rule.else_actions && rule.else_actions.length > 0) {
            formatted.thenActions = rule.then_actions;
            formatted.elseActions = rule.else_actions;
        } else if (formatted.condition === "custom") {
            formatted.thenActions = rule.then_actions;
            formatted.elseActions = null;
        } else if (rule.rule_condition.type === "false") {
            formatted.thenActions = rule.else_actions;
            formatted.elseActions = null;
        } else {
            formatted.thenActions = rule.then_actions;
            formatted.elseActions = null;
        }
        if (formatted.thenActions) {
            formatted.thenActions.forEach((action) => {
                action = formatActionDurations(action);
            });
        }
        if (formatted.elseActions) {
            formatted.elseActions.forEach((action) => {
                action = formatActionDurations(action);
            });
        }
        return formatted;
    }

    function formatActionDurations(action) {
        if (action.action_frequency) {
            var d = formatDuration(action.action_frequency);
            action.action_frequency_changed = d.duration
            action.action_frequency_time = d.unit
        } else {
            action.action_frequency = null;
            action.action_frequency_changed = null;
            action.action_frequency_time = "Seconds";
        }

        if (action.retries) {
            var d = formatDuration(action.retry_timeout);
            action.retry_timeout_changed = d.duration
            action.retry_timeout_time = d.unit
        } else {
            action.retries = 0;
            action.retry_timeout = 0;
            action.retry_timeout_changed = 0;
            action.retry_timeout_time = "Seconds";
        }
        function formatDuration(duration) {
            let unit = "";
            if (duration % 86400 === 0) {
                unit = "Days";
                duration = duration / 86400;
            } else if (duration % 3600 === 0) {
                unit = "Hours";
                duration = duration / 3600;
            } else if (duration % 60 === 0) {
                unit = "Minutes";
                duration = duration / 60;
            } else {
                unit = "Seconds";
                duration = duration;
            }
            return { duration, unit };
        }
        return action;
    }

    function formatSchedule(rule, formatted) {
        formatted.schedule = {};
        if (!rule.schedule) {
            formatted.reactive = true;
            formatted.schedule = {
                date: new Date(),
                repeats: 0,
                showRepeats: false,
                offset: null,
                definite: false
            };
            return formatted;
        }
        formatted.reactive = false;
        let sections = rule.schedule.split("/");
        let repeats = sections[0].charAt(1);
        if (repeats === "" || parseInt(repeats) === 0) {
            formatted.schedule.showRepeats = true;
            formatted.schedule.definite = false;
        } else if (parseInt(repeats) > 0) {
            formatted.schedule.showRepeats = true;
            formatted.schedule.definite = true;
            formatted.schedule.repeats = parseInt(repeats);
        }
        let offset = sections[2];
        if (offset === "") {
            formatted.schedule.offset = null;
        } else {
            formatted.schedule.offset = offset;
        }
        formatted.schedule.date = new Date(sections[1]);
        return formatted;
    }

    function logicOnlyCondition(condition) {
        return false;
    }
}

const blank = {
    _id: null,
    description: '',
    reactive: true,
    active: true,
    condition: 'true',
    company_id: null,
    conditionLogic: null,
    device_ids: [],
    device_type_ids: [],
    nested_devices: [],
    nested_devices_types: [],
    cloud_rule: true,
    thenActions: [],
    elseActions: null,
    schedule: {
        date: new Date(),
        repeats: 0,
        showRepeats: false,
        offset: null,
        definite: false
    },
    actions: null,
    conditions: null,
    devices: null,
    deviceTypes: null,
    types: null,
    availableActions: null,
};

export default Rule;
