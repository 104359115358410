import React from 'react';
import Loading from '../DisplayOriented/Loading';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import SwitchInput from "../Inputs/SwitchInput";

//icons
import ErrorIcon from '@material-ui/icons/Error';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import DeviceType from '../../services/DataModels/DeviceType';
import Company from '../../services/DataModels/Company';
import Auth from '../../services/Auth.js';
import Permissions from '../../services/Permissions';

class DeviceTypeForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			editable_copy: {
				name: '',
				image: '',
				manufacturer: '',
				model: '',
				firmware_upgrade: false,
				iptables: false,
				type: "",
				long_description: ''
			},
			show_errors: false,
			account_options: null,
			verifying: false,
			submitting: false
		};
		this.inherited = false;
		if (this.props.type) {
			this.inherited = this.props.type.nested_company == null;
			this.state.editable_copy = DeviceType.convertForUI(this.props.type);
		}
		this.state.editable_copy.company_id = Auth.currentCompany()._id;
		this.set_account_setting();
	}

	set_account_setting = () => {
		this.show_account_selection = false;
		let sub_accounts = Auth.currentUser().company_ids;
		if (sub_accounts.length > 1) {
			let valid_accounts = sub_accounts.filter( (account) => Permissions.allow(["create"], "device_type", account._id));
			if (valid_accounts.length > 1) {
				this.show_account_selection = true;
				this.load_account_options(valid_accounts);
			}
		} 
	}

	load_account_options = (account_ids) => {
		let params = {_id_in: account_ids };
		GetAll("companies", params).then( (result) => {
			let accounts = result;
			this.setState({account_options: accounts.map( (account) => ({display: account.name, value: account._id}) ).sort((a,b) => (a.display.toUpperCase() > b.display.toUpperCase()) ? 1 : ((b.display.toUpperCase() > a.display.toUpperCase()) ? -1 : 0))});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	on_form_update = ({field, value}, nested) => {
		this.setState( (state) => {
			let new_editable_copy = state.editable_copy;
			if (nested) {
				new_editable_copy[nested][field] = value;
			} else {
				new_editable_copy[field] = value;
			}
			return state;
		});
	}

	basic_validation_failed = () => {
		const type = this.state.editable_copy;
		return (
			type.type === '' ||
			type.name === '' ||
			type.model === '' ||
			type.manufacturer === '' ||
			type.name === '' ||
			type.long_description.length > 255 ||
			(this.show_account_selection && type.company_id == '')
		);
	}

	on_submit = (event) => {
		event.preventDefault();
		this.setState({verifying: true});
		if (this.basic_validation_failed()) {
			this.setState({show_errors: true, verifying: false});
			return;
		}
		this.setState({submitting: true});
		this.create();
	}

	create = () => {
		let modified_type = DeviceType.convertForAPI(this.state.editable_copy, this.props.type);
		if (this.props.type && this.props.type._id) {
			modified_type._id = this.props.type._id;
		}
		new DeviceType(modified_type).setData(modified_type).saveOrCreate().then((result) => {
			let message = this.props.type && this.props.type._id ? "Type saved." : "Type created.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate();
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	render_account_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors, account_options } = this.state;
		if (!this.show_account_selection) return "";
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon}/>
				<SelectInput
					error={show_errors && editable_copy.company_id === ""}
					error_message="Please select an account."
					label="Account*"
					field="company_id"
					emitChange={this.on_form_update}
					priorState={this.state.editable_copy.company_id}
					options={account_options}
				/>
			</div>
		);
	}

	render_name_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<DescriptionIcon className={classes.inputIcon}/>
				<TextInput
					label="Name*"
					emitChange={this.on_form_update}
					priorState={editable_copy.name}
					field="name"
					error={show_errors && editable_copy.name === ""}
					error_message={"Please define a name for this device type."}
				/>
			</div>
		);
	}

	render_model_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.typeWrapper}>
				<TextInput
					label="Model*"
					emitChange={this.on_form_update}
					priorState={editable_copy.model}
					field="model"
					error={show_errors && editable_copy.model === ""}
					error_message={"Please define a model for this device type."}
				/>
			</div>
		);
	}

	render_manufacturer_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.typeWrapper}>
				<TextInput
					label="Manufacturer*"
					emitChange={this.on_form_update}
					priorState={editable_copy.manufacturer}
					field="manufacturer"
					error={show_errors && editable_copy.manufacturer === ""}
					error_message={"Please define a manufacturer for this device type."}
				/>
			</div>
		);
	}

	render_description = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.typeWrapper}>
				<TextInput
					label="Description"
					multiline
					emitChange={this.on_form_update}
					priorState={editable_copy.long_description}
					field="long_description"
					error={show_errors && editable_copy.long_description.length >= 255}
					error_message={"Description should be fewer than 255 characters. (Currently " + editable_copy.long_description.length + " characters)"}
				/>
			</div>
		);
	}
	
	render_image = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.typeWrapper}>
				<TextInput
					label="Image URL"
					multiline
					emitChange={this.on_form_update}
					priorState={editable_copy.image}
					field="image"
				/>
			</div>
		);
	}
	
	render_buttons = () => {
		const { onCancel, classes, type } = this.props;
		const { verifying } = this.state;
		const editing = type && type._id;
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={onCancel} 
					className={classes.buttonOverride}
					aria-label="cancel"
					color="primary"
				>
					Cancel
				</Button>
				<Button
					disabled={verifying || this.inherited}
					variant="contained"
					className={classes.buttonOverride}
					aria-label="create"
					variant="contained"
					color="primary"
					size="large"
					type="submit"
				>
					{editing ? "Save" : "Create" } Type
				</Button>
			</div>
		)
	}

	render_firmware_upgrade = () => {
		const classes = this.props.classes;
		const toggle = () => {
			this.setState( (state) => {
				state.editable_copy.firmware_upgrade = !state.editable_copy.firmware_upgrade;
				return state;
			});
		}
		const editable_copy = this.state.editable_copy;
		return (
			<div className={classes.switchWrapper}>
				<SwitchInput
					initial={editable_copy.firmware_upgrade}
					emitChange={toggle}
					color={this.props.theme.palette.pending.main}
					onLabel="Firmware Upgrades Supported"
					offLabel="Firmware Upgrades Supported"
					location="start"
				/>
			</div>
		);
	}

	render_iptables = () => {
		const classes = this.props.classes;
		const toggle = () => {
			this.setState( (state) => {
				state.editable_copy.iptables = !state.editable_copy.iptables;
				return state;
			});
		}
		const editable_copy = this.state.editable_copy;
		return (
			<div className={classes.switchWrapper}>
				<SwitchInput
					initial={editable_copy.iptables}
					emitChange={toggle}
					color={this.props.theme.palette.pending.main}
					onLabel="IP Tables Supported"
					offLabel="IP Tables Supported"
					location="start"
				/>
			</div>
		);
	}
	render_type = () => {
		let options = DeviceType.getTypeOptions();
		const classes = this.props.classes;
		options.push({display: "Select a Type*", value: ''});
		const {show_errors, editable_copy } = this.state;
		return (
			<div className={classes.typeWrapper}>
				<SelectInput
					label="Type*"
					emitChange={this.on_form_update}
					priorState={editable_copy.type}
					field="type"
					options={options}
					error={show_errors && editable_copy.type === ""}
					error_message={"Please define a type designation for this device type."}
				/>
			</div>
		);
	}
	render_role = () => {
		let roles = DeviceType.getRoleOptions();
		const classes = this.props.classes;
		roles.unshift({display: "Select a Role*", value: ''});
		const {show_errors, editable_copy } = this.state;
		return (
			<div className={classes.typeWrapper}>
				<SelectInput
					label="Role*"
					emitChange={this.on_form_update}
					priorState={editable_copy.role}
					field="role"
					options={roles}
					error={show_errors && editable_copy.type === ""}
					error_message={"Please define a role for this device type."}
				/>
			</div>
		);
	}

	render() {
		const { classes, theme } = this.props;
		const { editable_copy, show_errors, account_options, submitting } = this.state;
		/*
				image: '',
		*/
		return(
			<div className={classes.container}>
				{submitting || (this.show_account_selection && account_options == null) ? <Loading />
				:
				<form onSubmit={this.on_submit}>
					<div className={classes.formContainer}>
						{this.render_account_input()}
						{this.render_name_input()}
						{this.render_description()}
						{this.render_image()}
						{this.render_manufacturer_input()}
						{this.render_model_input()}
						{this.render_type()}
						{this.render_role()}
						{this.render_firmware_upgrade()}
						{this.render_iptables()}
					</div>
					<div>
						{this.render_buttons()}
					</div>
				</form>}
			</div>
		)
	}
}

DeviceTypeForm.contextType = SnackbarContext;

const styles = (theme) => {
	return ({	
		container: {
			fontFamily: "Inter",
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "42px"
		},
		selectInputWrapper: {
			marginTop: "18px",
			minHeight: "78px",
			marginBottom: "26px"
		},
		set: {
			color: "grey",
			paddingBottom: "24px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		buttonContainer: {
			marginTop: "32px",
			display: "flex",
			justifyContent: "flex-end"
		},
		mainButton: {
			color: "white",
			backgroundColor: theme.palette.pending.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.pending.main, .2),
				borderColor: darken(theme.palette.pending.main, .2),
			},
			margin: "0 0 12px 12px"
		},
		deleteIcon: {
			cursor: "pointer",
			color: theme.palette.pending.main,
			marginBottom: "26px"
		},
		deleteOIDIcon: {
			cursor: "pointer",
			color: theme.palette.pending.main,
			marginBottom: "6px"
		},
		buttonIcon: {
			marginRight: "8px",
			display: "flex",
		},
		targetHostMapPairRow: {
			display: "flex",
			alignItems: "center",
			margin: "0 0 12px 12px"
		},
		inputsContainer: {
			marginLeft: "36px",
			borderLeft: "solid lightgrey 2px",
			paddingLeft: "12px",
			marginBottom: "48px",
			paddingTop: "12px"
		},
		infoText: {
			color: "black",
			marginBottom: "12px"
		},
		numberInput: {
			marginBottom: "16px"
		},
		generalWrapper: {
			marginTop: "34px"
		},
		label: {
			color: "grey",
			marginBottom: "12px"
		},
		targetHostMapItem: {
			marginBottom: "-18px",
			marginRight: "8px"
		},
		noPairings: {
			margin: "0 0 12px 12px"
		},
		selectInput: {
			marginTop: "24px"
		},
		requestSelectInput: {
			marginTop: "34px"
		},
		filterContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		filterContainerItem: {
			marginRight: "8px"
		},
		routeContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		routeInputSelect: {
			marginBottom: "14px",
			marginRight: "8px",
			width: "33%"
		},
		routeInput: {
			marginRight: "8px",
			width: "33%"
		},
		errorIcon: {
			color: theme.palette.red.main,
			marginRight: "4px"
		},
		errorContainer: {
			display: "flex",
			alignItems: "center",
		},
		switchWrapper: {
			marginLeft: "38px",
			marginBottom: "20px",
		},
		typeWrapper: {
			marginLeft: "38px",
		},
		formContainer: {
			height: "500px",
			overflowY: "auto",
			paddingTop: "5px",
			paddingRight: "16px",
		},
	})
};

export default withStyles(styles)(withTheme()(DeviceTypeForm));

