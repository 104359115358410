import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';
import Auth from '../Auth';
import Role from './Role';

class User extends DataModel {
	constructor(data = BLANK) {
		super('users', Object.assign({password: "", ui_password_confirmation: "" }, data));
	}

	static loadRequirements(users) {
		return new Promise( (resolve, reject) => {
			let promises = [AssignNestedModels("user_types", "user_type_id", users), AssignNestedModels("companies", "company_id", users), User.testImages(users)];
			Promise.all(promises).then( () => {
				users.forEach( (user) => {
					user.ui_name = user.first_name + " " + user.last_name + (user._id === Auth.user()._id ? " (Me)" : "");
					if (user.nested_user_type) {
						user.ui_user_type = Role.defaultRoleReadable(user.nested_user_type.name);
					}
					if (user.nested_company) {
						user.ui_company = user.nested_company.name;
					}
				});
				resolve(users);
			}).catch( (error) => {
				console.log(error);
				reject();
			});
		});
	}

	static testImages(users) {
		return Promise.all(users.map( (user) => {
			if (user.logo_url !== "") {
				User.testImage(user.logo_url).then( () => {
					user.ui_valid_profile_image = true;
					return Promise.resolve(user);
				}).catch( () => {
					user.ui_valid_profile_image = false;
					return Promise.resolve(user);
				});
			} else {
				user.ui_valid_profile_image = false;
				return Promise.resolve(user);
			}
		}));
	}

	static testImage(url, timeoutT) {
		return new Promise(function (resolve, reject) {
			var timeout = timeoutT || 5000;
			var timer, img = new Image();
			img.onerror = img.onabort = function () {
				clearTimeout(timer);
				reject("error");
			};
			img.onload = function () {
				clearTimeout(timer);
				resolve("success");
			};
			timer = setTimeout(function () {
				// reset .src to invalid URL so it stops previous
				// loading, but doesn't trigger new load
				img.src = "//!!!!/test.jpg";
				reject("timeout");
			}, timeout);
			img.src = url;
		});
	}
}

const BLANK = {
	first_name: "",
	last_name: "",
	email: "",
	password: "",
	ui_password_confirmation: "",
	user_type_id: "",
	company_id: "",
	logo_url: "",
	phone_number: "",
	company_ids: []
}

export default User;
