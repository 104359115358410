import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PollableAttributesGrid from "../../PollableAttributesSpecific/PollableAttributesGrid";
import PollableAttribute from "../../../services/DataModels/PollableAttribute";
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	}
};

class PollableAttributesTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			modal: {
				open: false
			},
			pollable_attributes: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			filter_data: {},
			sort: null
		};
		this.load_pollable_attributes();
		this.props.tabHostProxy.setRootRefresh(this.load_pollable_attributes);
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_pollable_attributes();
		});
	}

	promptModalActiveChange = (rule) => {
		this.props.promptModalActiveChange(rule);
	}

	closeModal = () => {
		this.setState({
			modal: {
				"open": false
			}
		});
	}

	load_pollable_attributes = () => {
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.pollable_attributes !== null) this.setState({pollable_attributes: null});
		new PollableAttribute().listFromAPI(params).then((results) => {
			this.setState( () => {
				return {
					pollable_attributes: results.items,
					page_data: {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: results.total
					}
				};
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return { 
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_pollable_attributes();
		});
	}

	render() {
		const { tabHostProxy, classes } = this.props;
		const { pollable_attributes, page_data } = this.state;

		return (
			<div className={classes.container}>
				{pollable_attributes == null ?
						<Loading/>
					: 
					<PollableAttributesGrid
						pollable_attributes={pollable_attributes}
						page_change={this.page_change}
						page_data={page_data}
						tabHostProxy={tabHostProxy}
						perform_sort={this.perform_sort}
					/>
				}
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
			</div>
		);
	}
}

PollableAttributesTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(PollableAttributesTab));
