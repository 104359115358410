import React from 'react';
import Loading from '../DisplayOriented/Loading';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import SwitchInput from "../Inputs/SwitchInput";

//icons
import ErrorIcon from '@material-ui/icons/Error';
import HearingIcon from '@material-ui/icons/Hearing';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';
import CodeIcon from '@material-ui/icons/Code';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MemoryIcon from '@material-ui/icons/Memory';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CommandTypeIcon from '@material-ui/icons/Equalizer';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Command from '../../services/DataModels/Command';
import Company from '../../services/DataModels/Company';
import Auth from '../../services/Auth.js';
import Permissions from '../../services/Permissions';

class CreateCommandForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			editable_copy: {
				type: 'edge',
				company_id: '',
				translator_id: '',
				name: '',
				sender_type: '',
				sender: {}
			},
			show_errors: false,
			editing: false,
			account_options: null,
			translator_options: null,
			verifying: false,
			submitting: false
		};
		if (this.props.command._id) {
			this.state.editing = true;
			let editable_command = Command.convert_for_ui(this.props.command);
			this.state.editable_copy = editable_command;
		}
		this.prep_options();
		this.set_account_setting();
		this.setup_translator();
	}

	prep_options = () => {
		this.command_type_options = [
			{ value: 'edge', display: "Edge" },
			// { value: 'cloud', display: "Cloud" }
		]
		this.sender_options = Object.entries(Command.senderTypes()).map(([key, { display }]) => ({ value: key, display: display }));
		this.sender_options.push({ value: '', display: "Select a Sender Type*" });

	}

	setup_translator = () => {
		this.load_translator_options();
	}

	translator_option_for_id = (translator_id) => {
		const translator_options = this.state.translator_options;
		if (translator_options) {
			return translator_options.find(opt => opt.value && opt.value === translator_id);
		}
		return null;
	}

	set_account_setting = () => {
		this.show_account_selection = false;
		this.state.editable_copy.company_id = this.state.editing ? this.props.command.company_id : Auth.currentCompany()._id;
		let sub_accounts = Auth.currentUser().company_ids;
		if (sub_accounts.length > 1) {
			let valid_accounts = sub_accounts.filter((account) => Permissions.allow(["create"], "command", account._id));
			if (valid_accounts.length > 1) {
				this.show_account_selection = true;
				this.load_account_options(valid_accounts);
			}
		}
	}

	load_account_options = (account_ids) => {
		let params = { _id_in: account_ids };
		GetAll("companies", params).then((result) => {
			let accounts = result.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
			this.setState({ account_options: accounts.map((account) => ({ display: account.name, value: account._id })) });
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_translator_options = () => {
		let params = { company_id: this.state.editable_copy.company_id, type_like: '_cmd' };
		GetAll("translators", params).then((result) => {
			let translators = result;
			translators.push({ name: "None", _id: '', type: 'none' });
			// translators.push({ name: "Select a Translator*", _id: '' });
			this.setState((state) => {
				state.translator_options = translators.map((translator) => ({ display: translator.name, value: translator._id, translator_type: translator.type }));
				if (!state.translator_options.find((tr) => tr.value === state.editable_copy.translator_id)) {
					state.editable_copy.translator_id = "";
				}
				return state;
			});
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	onFormUpdate = ({ field, value }, nested) => {
		this.setState((state) => {
			let new_editable_copy = state.editable_copy;
			if (nested) {
				new_editable_copy[nested][field] = value;
			} else {
				new_editable_copy[field] = value;
			}
			if (field === "sender_type") {
				new_editable_copy.sender = Command.getDefaultSender(new_editable_copy.sender_type);
			}
			return state;
		}, () => {
			if (field === "company_id" && value !== "") {
				this.load_translator_options();
			}
		});
	}

	basic_validation_failed = () => {
		const command = this.state.editable_copy;
		return (command.name === '' || (this.show_account_selection && command.company_id == '') || command.sender_type === '');
	}

	onSubmit = (event) => {
		event.preventDefault();
		this.setState({ verifying: true });
		let command = this.state.editable_copy;
		if (this.basic_validation_failed() || this.render_errors() !== null) {
			this.setState({ show_errors: true, verifying: false });
			return;
		}
		this.setState({ submitting: true });
		this.create(command);
	}

	create = (command, files) => {
		let modified_command = Command.convert_for_api(command);
		new Command(modified_command).setData(modified_command).saveOrCreate().then((result) => {
			let message = this.state.editing ? "Command saved." : "Command created.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate(result);
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	render_account_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors, account_options } = this.state;
		if (!this.show_account_selection) return "";
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.company_id === ""}
					error_message="Please select an account."
					label="Account"
					field="company_id"
					emitChange={this.onFormUpdate}
					priorState={this.state.editable_copy.company_id}
					options={account_options}
				/>
			</div>
		);
	}

	render_name_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<DescriptionIcon className={classes.inputIcon} />
				<TextInput
					id="name"
					label="Name*"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.name}
					field="name"
					error={show_errors && editable_copy.name === ""}
					error_message={"Please define a name for this command."}
				/>
			</div>
		);
	}

	render_command_type = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<CommandTypeIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.type === ""}
					error_message="Please select an command type."
					label="Command Type"
					field="type"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.type}
					options={this.command_type_options}
				/>
			</div>
		);
	}

	render_translator = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors, translator_options } = this.state;
		let input = null;
		if (editable_copy.company_id === "") {
			input = <span className={classes.set}>Select the account before selecting the translator.</span>;
		} else if (translator_options !== null && translator_options.length === 1) {
			input = <span className={classes.set}>There are no translators for this account.</span>;
		} else if (translator_options === null) {
			input = <Loading />;
		} else {
			input = <SelectInput
				error={false}
				error_message="Please select a translator."
				label="Translator"
				field="translator_id"
				emitChange={this.onFormUpdate}
				priorState={this.state.editable_copy.translator_id}
				options={translator_options}
			/>;
		}
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<SwapHorizIcon className={classes.inputIcon} />
				{input}
			</div>
		);
	}

	render_sender_type = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<HearingIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.sender_type === ""}
					error_message="Please select a sender type."
					label="Sender Type"
					field="sender_type"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.sender_type}
					options={this.sender_options}
				/>
			</div>
		);
	}

	render_sender = () => {
		const classes = this.props.classes;
		const { editable_copy, show_errors } = this.state;
		if (editable_copy.sender_type === '') return;
		const inputs = Command.senderTypes(editable_copy.sender_type).inputs;
		if (inputs.length === 0) {
			return <React.Fragment />
		} else {
			return (
				<div className={classes.inputsContainer}>
					{(inputs && inputs.length > 0) ? <div className={classes.infoText}>Please fill out the following inputs for the sender type you've selected.</div> : ""}
					{inputs.map((input) => Command.get_input(input, editable_copy.sender, (input_data) => this.onFormUpdate(input_data, "sender"), classes))}
				</div>
			);
		}
	}

	render_errors = () => {
		const classes = this.props.classes;
		const command = this.state.editable_copy;
		const translator_options = this.state.translator_options;
		let errors = [];
		let error_text = "";
		const icon = <ErrorIcon className={classes.errorIcon} />;

		if (errors.length === 0) return null;
		return errors.map((error) => (error));
	}

	render() {
		const { classes, theme } = this.props;
		const { editable_copy, show_errors, type_options, account_options, submitting } = this.state;
		this.validation = Command.validate(editable_copy);
		return (
			<div className={classes.container}>
				{submitting || (this.show_account_selection && account_options == null) ? <Loading />
					:
					<form onSubmit={this.onSubmit}>
						{this.render_account_input()}
						{this.render_name_input()}
						{this.render_command_type()}
						{this.render_sender_type()}
						{this.render_sender()}
						{this.render_translator()}
						{this.render_errors()}
						<div className={classes.buttonContainer}>
							<Button
								onClick={this.props.onCancel}
								color="primary"
								className={classes.buttonOverride}
								aria-label="cancel"
							>
								Cancel
							</Button>
							<Button
								disabled={this.state.verifying}
								variant="contained"
								color="primary"
								size="large"
								className={classes.buttonOverride}
								aria-label="create"
								type="submit"
							>
								{this.state.editing ? "Save" : "Create"} Command
							</Button>
						</div>
					</form>}
			</div>
		)
	}
}

CreateCommandForm.contextType = SnackbarContext;

const styles = (theme) => {
	return ({
		container: {
			width: "100%",
			fontFamily: "Inter"
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "42px"
		},
		selectInputWrapper: {
			marginTop: "18px",
			minHeight: "78px",
			marginBottom: "26px"
		},
		set: {
			color: "grey",
			paddingBottom: "24px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		button: {
			marginLeft: "8px"
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		buttonOverride: {
			marginLeft: "8px"
		},
		mainButton: {
			margin: "0 0 12px 12px"
		},
		deleteIcon: {
			marginBottom: "26px",
			cursor: "pointer",
			color: "#8e8e93",
			"&:hover": {
				color: "#0263fc",
			}
		},
		deleteOIDIcon: {
			cursor: "pointer",
			marginBottom: "6px",
			color: "#8e8e93",
			"&:hover": {
				color: "#0263fc",
			}
		},
		buttonIcon: {
			marginRight: "8px",
			display: "flex",
		},
		targetHostMapPairRow: {
			display: "flex",
			alignItems: "center",
			margin: "0 0 12px 12px"
		},
		inputsContainer: {
			marginLeft: "36px",
			borderLeft: "solid lightgrey 1px",
			paddingLeft: "12px",
			marginBottom: "48px",
			paddingTop: "12px"
		},
		infoText: {
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "12px",
			fontSize: "14px",
		},
		numberInput: {
			marginBottom: "16px"
		},
		generalWrapper: {
			marginTop: "34px"
		},
		label: {
			color: "#8e8e93",
			marginBottom: "12px",
			fontSize: "14px",
		},
		targetHostMapItem: {
			marginBottom: "-18px",
			marginRight: "8px"
		},
		noPairings: {
			margin: "0 0 12px 12px",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "14px",
		},
		selectInput: {
			marginTop: "24px"
		},
		requestSelectInput: {
			marginTop: "34px"
		},
		filterContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		filterContainerItem: {
			marginRight: "8px"
		},
		deviceTypeMappingContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		deviceTypeMappingContainerItem: {
			marginRight: "18px"
		},
		routeContainer: {
			display: "flex",
			alignItems: "center",
			marginLeft: "12px"
		},
		routeInputSelect: {
			marginBottom: "14px",
			marginRight: "8px",
			width: "33%"
		},
		routeInput: {
			marginRight: "8px",
			width: "33%"
		},
		errorIcon: {
			color: theme.palette.red.main,
			marginRight: "4px"
		},
		errorContainer: {
			display: "flex",
			alignItems: "center",
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.87)",
		}
	})
};

export default withStyles(styles)(withTheme()(CreateCommandForm));

