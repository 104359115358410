import { AssignNestedModels } from '../CLURDUtilities';
import DataModel from "./DataModel";

const BLANK = {
	company_id: '',
	unique_id: '',
	token: '',
};

class EscrowDevice extends DataModel {
	constructor(data = BLANK) {
		super("escrow_devices", data);
	}

	static loadRequirements(devices) {
		return new Promise( (resolve, reject) => {
			Promise.all([AssignNestedModels("companies", "company_id", devices)]).then( (result) => {
				resolve(devices);
			}).catch( (error) => reject(error));
		});
	}
}

export default EscrowDevice;