import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import ErrorBoundary from './components/ActionOriented/ErrorBoundary';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LoginPage from './pages/LoginPage';
import { Observable } from 'rxjs';
import Auth from './services/Auth';
import SimpleModalWrapped from './components/Containers/SimpleModalWrapped';
import {Data} from './services/ContextProviders/Data';
import {SnackbarContext} from './services/ContextProviders/Snackbar';
import Snackbar from '@material-ui/core/Snackbar';
import CustomSnackbar from './components/Containers/CustomSnackbar';
import Layout from './components/Navigation/Layout';
import ModalContent from './components/DisplayOriented/ModalContent';
import './index.css';
import ResetPasswordPage from './pages/ResetPasswordPage';
import EscrowLandingPage from './pages/EscrowLandingPage';

import icons from 'glyphicons';

const PrivateRoute = ({ component: Component, ...rest}) => (
	<Route {...rest} render={ (props) => {
		return Auth.token() != null
		? <Component {...rest} {...props} />
		: <Redirect to="/login"/>;
	}}/>
)
let primary = "#1153b6";

const DEFAULT_THEME_CONFIG = {
	primary: primary
}

const buildThemeConfig = function(primaryColor) {
	return {
		primary: primaryColor
	};
}

const buildTheme = function(themeConfig) {
	let t = {
		typography: {
			fontFamily: [
				"Inter",
				"-apple-system",
				"BlinkMacSystemFont",
				"Segoe UI",
				"Arial",
				"sans-serif"
			].join(","),
			useNextVariants: true
		},
		palette: {
			primary: {
				light: "#bbdefb",
				main: themeConfig.primary,
				dark: "#0d47a1",
			},
			greyIcon: {
				main: "#8e8e93"
			},
			grey: {
				main: "#8e8e93"
			},
			green: {
				light: "#d9f6ed",
				main: "#40d2a5",
			},
			red: {
				light: "#fce7e7",
				main: "#ff536d",
				icon: "#e53935",
			},
			pending: {
				main: "#1153b6"
			},
			caution: {
				light: "#fff9c4",
				main: "#f6ad40",
			},
			heartbeat: {
				idle: {
					main: "#ee5b54"
				},
				offline: {
					main: "#ffc400"
				},
			},
		},
		overrides: {
			MuiStepIcon: {
				root: {
					fontSize: "32px",
					backgroundColor: "#8e8e93",
					borderRadius: "50%",
					"& circle": {
						r: "11",
						fill: "white",
					}
				},
				text: {
					fill: "#8e8e93",
				},
				completed: {
					backgroundColor: "white",
					color: "#1153b6"
				},
				active: {
					"& circle": {
						r: "12",
						fill: "#1153b6",
					},
					"& text": {
						fill: "#fff",
					}
				}
			},
			MuiStep: {
				horizontal: {
					paddingLeft: 0,
					paddingRight: 0,
				}
			},
			MuiStepLabel: {
				iconContainer: {
					paddingRight: 0,
				}
			},
			MuiStepConnector: {
				line: {
					borderColor: "#8e8e93",
				},
				lineHorizontal: {
					borderTopWidth: "2px",
				},
				completed: {
					"& span": {
						borderColor: "#1153b6",
					}
				},
				active: {
					"& span": {
						borderColor: "#1153b6",
					}
				}
			},
			MuiSelect: {
				select: {
					'&:hover': {
						background: "unset"
					},
					'&:focus': {
						background: "unset"
					}
				},
				selectMenu: {
					color: "rgba(0, 0, 0, 0.87)",
				}
			},
			MuiTablePagination: {
				selectRoot: {
					paddingRight: 0
				},
				select: {
					paddingRight: "20px"
				}
			},
			MuiFormLabel: {
				root: {
					fontSize: "14px",
					color: "#8e8e93",
					lineHeight: "16px",
				},
			},
			MuiInputBase: {
				input: {
					fontSize: "14px",
				},
				inputType: {
					height: "1.35em",
				}
			},
			MuiCheckbox: {
				root: {
					color: "rgba(0, 0, 0, 0.24)",
					"&$checked": {
						color: "#1153b6",
					},
				},
				checked: {},
			},
			MuiChip: {
				label: {
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					display: "inline",
					textTransform: "capitalize",
				}
			},
			MuiButton: {
				root: {
					transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
				},
				containedPrimary: {
					boxShadow: "none",
					"&:hover": {
						backgroundColor: "#0263FC",
						borderColor: "#0263FC",
						color: "#ffffff",
					},
				},
				outlinedPrimary: {
					boxShadow: "none",
					border: `1px solid ${themeConfig.primary}`,
					"&:hover": {
						backgroundColor: "#ffffff",
						borderColor: "#0263FC",
						color: "#0263FC",
					},
				},
				textPrimary: {
					"&:hover": {
						backgroundColor: "#ffffff",
						color: "#0263FC",
					},
				},
			},
			MuiIconButton: {
				root: {
					color: "#8e8e93",
					transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
				}
			},
			MuiTableRow: {
				head: {
					height: "48px",
				},
			},
			MuiTableCell: {
				root: {
					borderBottom: "1px solid lightgrey",
					padding: "0 56px 0 24px",
				},
				body: {
					color: "#636366",
				}
			},
			MuiTab: {
				textColorPrimary: {
					color: "#8e8e93",
				}
			},
		}
	}
	return createMuiTheme(t);
}
/*
MuiButton: {
	root: {
		boxShadow: "none"
	},
	contained: {
		boxShadow: "none"
	},
	containedPrimary: {
		backgroundColor: "#2b8deb",
		color: "white",
	}
}
*/
class Base extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
	}
	render() {
		const { user } = this.props;
		return (
			<ErrorBoundary>
				<Switch>
					<Route path="/reset_password" component={ResetPasswordPage} />
					<Route path="/transfer_device" component={EscrowLandingPage} />
					<Route path="/login" component={LoginPage} />
					<PrivateRoute logout={Auth.logout} path="/" user={user} component={Layout} />
				</Switch>
			</ErrorBoundary>
		);
	}
}


class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			themeConfig: DEFAULT_THEME_CONFIG,
			user: null,
			types: null,
			snackbar: {
				open: false
			},
			help_modal: {
				open: false,
				children: () => ""
			}
		};
		Auth.addUserChangeListener(this);
	}

	open_snackbar = (message, result, action) => {
		let time = result == 'error' ? undefined : 4000;
		const message_shown = typeof message === "string" ? message : "An error has occurred. Please try refreshing the page.";
		this.setState({ snackbar: { open: true,  message: message_shown, result: result, time: time, action: action }});
	}

	close_snackbar = () => {
		this.setState( (prev_state) => ({ snackbar: { open: false, message: "", result:  prev_state.snackbar.result } }));
	}

	open_help = (content) => {
		content = <ModalContent content={content} />;
		this.setState({
			help_modal: {
				open: true,
				children: content
			}
		});
	}

	close_help = () => {
		this.setState({
			help_modal: {
				open: false,
				children: null
			}
		});
	}

	userDidChange(newUser) {
		let themeConfig = DEFAULT_THEME_CONFIG;
		let companyName = "EdgeIQ";
		if (newUser) {
			if (newUser.nested_company) {
				companyName = newUser.nested_company.name;
				let branding = newUser.nested_company.branding;
				if (branding) {
					let primaryColor = branding.primary_color;
					let iconUrl = branding.icon_url;
					if (primaryColor && primaryColor !== '') {
						themeConfig = buildThemeConfig(primaryColor);
					}
					if (iconUrl && iconUrl !== '') {
						var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
						link.type = 'image/x-icon';
						link.rel = 'shortcut icon';
						link.href = branding.icon_url;
						document.getElementsByTagName('head')[0].appendChild(link);
					} else {
						var link = document.createElement('link');
						link.type = 'image/x-icon';
						link.rel = 'shortcut icon';
						link.href ='public/ms_icon_new.png';
						document.getElementsByTagName('head')[0].appendChild(link);
					}
				}
			}
		}
		this.setState({user: newUser, themeConfig: themeConfig});
		document.title = companyName + " DRM Portal";
	}

	render() {
		let basename = '/'
		if (window.BASENAME) {
			basename = window.BASENAME
		}

		let { themeConfig, types, snackbar, user } = this.state;
		const theme = buildTheme(themeConfig);
		return (
			<MuiThemeProvider theme={theme}>
				<SnackbarContext.Provider value={{openSnackbar: this.open_snackbar, closeSnackbar: this.close_snackbar, openHelp: this.open_help, closeHelp: this.close_help}}>
						<Router basename={basename}>
							<div>
								<Base user={user}/>
							</div>
						</Router>
				</SnackbarContext.Provider>
				<SimpleModalWrapped info={this.state.help_modal} closeModal={this.close_help}>
					{this.state.help_modal.children}
				</SimpleModalWrapped>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={snackbar.open}
					autoHideDuration={snackbar.time}
					onClose={this.close_snackbar}
				>
					<CustomSnackbar
						onClose={this.close_snackbar}
						variant={snackbar.result}
						message={snackbar.message}
						action={snackbar.action}
					/>
				</Snackbar>
			</MuiThemeProvider>
		)
	}
}

// ========================================
ReactDOM.render((
	<App />
),
document.getElementById('root')
);
