import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Loading from '../DisplayOriented/Loading';
import { compose } from 'recompose';
import {
	FlexibleXYPlot,
	XAxis,
	YAxis,
	VerticalGridLines,
	HorizontalGridLines,
	VerticalBarSeries,
	VerticalBarSeriesCanvas,
	DiscreteColorLegend
  } from 'react-vis';

//mui
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Numeral from 'numeral';

//inputs
import SelectInput from '../Inputs/SelectInput';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

//icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
	graphContainer: {
		position: "relative",
		height: "100%",
		width: "100%",
		marginTop: "18px"
	},
	topRow: {
		height: "73%",
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "flex-start"
	},
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
		width: "100%"
	},
	title: {
		fontSize: "20px",
	},
	bottomRow: {
		width: "100%",
		display: "flex",
		flexWrap: "nowrap"
	},
	filterContainer: {
		width: "50%",
		marginLeft: "6px"
	},
	ctaText: {
		marginRight: "8px",
		height: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	linkButton: {
		marginLeft: "auto",
		color: theme.palette.pending.main,
		'&:hover': {
			backgroundColor: "white",
			color: '#0263FC',
		},
	},
	legendContainer: {
		fontSize: "14px",
		color: "#8e8e93",
		display: "flex",
		margin: "-24px auto",
		justifyContent: "center"
	},
	legendRow: {
		display: "flex",
		alignItems: "center",
		paddingRight: "12px",
		boxSizing: "border-box",
		maxWidth: "20%"
	},
	legendText: {
		maxWidth: "calc(100% - 14px)",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	legendDot: {
		minWidth: "14px",
		width: "14px",
		height: "14px",
		borderRadius: "50%",
		marginRight: "6px"
	},
	splitDot: {
		background: `linear-gradient(to bottom,  ${theme.palette.green.main} 0%,${theme.palette.green.main} 33%,${theme.palette.red.main} 33%,${theme.palette.red.main} 66%,#d5d6d6 66%,#d5d6d6 100%)`
	},
	popper: {
		zIndex: 1
	},
	popperRoot: {
		top: 0,
		right: 0
	},
	contents: {
		padding: "12px",
		fontFamily: "Inter",
		fontSize: "14px",
	}
});

class BarGraph extends React.Component {
	
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			data: null,
			filter: this.props.filterOptions ? this.props.multiple ? this.props.filterOptions[0][0].value : this.props.filterOptions[0].value : null,
			tab: 0,
			menu: null,
			contents: ""
		};
		this.gg_colors = [this.props.theme.palette.green.main, this.props.theme.palette.red.main, "rgb(213, 214, 214)"];
		this.colors = ["#2b8ceb", "#94d4ed", "#73a4b7", "#d0e0fb", "#72a4f7"];
		this.refresh();

	}

	convert = (map) => {
		return Object.entries(map).map( ([key, value]) => {
			if (typeof value === "number") {
				return {x: key, y: value};
			} else {
				let split_bar = [];
				Object.entries(value).forEach( ([secondary_state, secondary_value]) => {
					let section = {x: key, y: secondary_value, z: secondary_state};
					split_bar.push(section);
				});
				return {x: key, y_items: split_bar};
			}
		}).sort( (a, b) => {
			if (a.x === "Other") return 1;
			return -1;
		});
	}

	refresh = () => {
		if (this.props.multiple) {
			this.props.load[this.state.tab](this.state.filter).then((items) => this.setState({data: this.convert(items)}));
		} else {
			this.props.load(this.state.filter).then((items) => this.setState({data: this.convert(items)}));
		}
	}

	render_bottom_row = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.bottomRow}>
				{this.render_select_filter()}
				{this.render_link()}
			</div>
		);
	}

	render_select_filter = () => {
		const {filterOptions, multiple, classes } = this.props;
		if (!filterOptions || ( multiple && !filterOptions[this.state.tab]) ) return "";
		let options = multiple ? filterOptions[this.state.tab] : filterOptions;
		return (
			<div className={classes.filterContainer}>
				<SelectInput
					basic
					priorState={this.state.filter}
					emitChange={this.handle_change}
					options={options}
				/>
			</div>
		);
	}

	render_link = () => {
		let { link, classes } = this.props;
		if (!link) return "";
		if (this.props.multiple) {
			link = link[this.state.tab];
		}
		return (
			<Button className={classes.linkButton} onClick={this.link}>
				<span className={classes.ctaText}>
					{link.display}
				</span>
				<ArrowForwardIcon className={classes.ctaArrow}/>
			</Button>
		);
	}

	link = () => {
		this.props.history.push(this.props.link.link);
	}

	handle_change = ({field, value}) => {
		this.setState({data: null});
		this.setState( (state) => {
			state.filter = value;
			state.items = null;
			return state;
		}, this.refresh);
	}

	bar_hover = (data, event) => {
		const element = event.event.currentTarget;
		this.setState( (state) => {
			state.menu = element;
			if (data.z) {
				let amount = data.y0 != null ? data.y - data.y0 : data.y;
				state.contents = `${amount + " " + data.x} device${amount > 1 ? 's' : ''} with Greengrass status: ${data.z}`;
			} else {
				state.contents =  `${data.y + " " + data.x} device${data.y > 1 ? 's' : ''}`;
			}
			return state;
		});
	}

	bar_unhover = () => {
		this.setState({menu: null, contents: ""});
	}

	render_menu_area = () => {
		if (this.state.menu == null) return "";
		return this.render_menu();
	}

	render_menu = () => {
		const classes = this.props.classes;
		let anchorEl = this.state.menu;
		return (
			<Popper
				open={true}
				id="long-menu"
				anchorEl={anchorEl}
				className={classes.popper}
				placement='top'
			>
				<Paper className={classes.contents}>
					{this.state.contents}
				</Paper>
			</Popper>
		);
	}

	render_bar = () => {
		const { classes } = this.props;
		const data = this.state.data;
		const BarSeries = VerticalBarSeries;
		return (
			<div className={classes.graphContainer}>
				<FlexibleXYPlot
					style={{position: "relative"}}
					xType="ordinal"
					stackBy="y"
					margin={{left: 75}}
				>
					<VerticalGridLines style={{stroke:"#80808045", strokeWidth: 2}}/>
					<HorizontalGridLines style={{stroke:"#80808045", strokeWidth: 2}}/>
					<XAxis style={{text: {display: "none"}}}/>
					<YAxis tickFormat={tick => Numeral(tick).format('0a')} />
					{data.map( (entry, index) => {
						if (entry.y_items) {
							return entry.y_items.map( (section, index) => (
									<BarSeries
										key={"" + this.gg_colors[index]}
										color={this.gg_colors[index]}
										data={[{x: section.x, y: section.y, z: section.z, color: this.gg_colors[index]}]}
										style={{cursor: "pointer", position: "relative"}}
										onValueMouseOver={this.bar_hover}
										onValueMouseOut={this.bar_unhover}
										aria-owns={this.state.menu ? 'long-menu' : undefined}
										aria-describedby={'long-menu'}
										aria-haspopup="true"
									/>
							));
						} else {
							return (
								<BarSeries
									key={entry.x}
									color={this.colors[index]}
									data={[{x: entry.x, y: entry.y}]}
									style={{cursor: "pointer", position: "relative"}}
									onValueMouseOver={this.bar_hover}
									onValueMouseOut={this.bar_unhover}
									aria-owns={this.state.menu ? 'long-menu' : undefined}
									aria-describedby={'long-menu'}
									aria-haspopup="true"
								/>
							);
						}
					})}
				</FlexibleXYPlot>
				<div className={classes.legendContainer}>
					{data.map( (entry, index) => 
						<div
							className={classes.legendRow}
							key={entry.x + "_legend"}
						>
							{entry.y_items ?
								<span className={classes.legendDot + " " + classes.splitDot}>&nbsp;</span>
								:
								<span className={classes.legendDot} style={{backgroundColor: this.colors[index]}}>&nbsp;</span>
							}
							<div className={classes.legendText}>{entry.x}</div>
						</div>
					)}
				</div>
			</div>
		);
	}

	tab_change = (event, new_value) => {
		this.setState({tab: new_value}, this.refresh);
	}

	render_tabs = () => {
		const { title, classes } = this.props;
		return (
			<Tabs value={this.state.tab} onChange={this.tab_change} aria-label="tabs widget">
				{title.map( (section) => <Tab classes={{root: classes.tabRoot, labelContainer: classes.wrapper}} key={section} label={section} />)}
			</Tabs>
		);
		
	}

	render() {
		const {title, multiple, classes } = this.props;
		const { data } = this.state;
		return (
			<div className={classes.container}>
				<div className={classes.topRow}>
					{multiple ? 
						this.render_tabs() :
						title ? <div className={classes.title}>
							{title}
						</div> : ""
					}
					{data === null ? <Loading /> : this.render_bar()}
				</div>
				{this.render_menu_area()}
				{this.render_bottom_row()}
			</div>
		);
	}
}



// BarGraph.contextType = SnackbarContext;
export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)
(BarGraph);
