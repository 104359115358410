import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import List from '@material-ui/core/List';
import Auth from '../../services/Auth';
import Permissions from '../../services/Permissions';
import Notification from '../../services/DataModels/Notification';
import ListSubheader from '@material-ui/core/ListSubheader';
import ReportProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import WarningIcon from '@material-ui/icons/Warning';
import RouterIcon from '@material-ui/icons/Router';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import DiscoveredDeviceIcon from '@material-ui/icons/PermDeviceInformationOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponentOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import GroupWorkIcon from '@material-ui/icons/GroupWorkOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SwapHorizIcon from '@material-ui/icons/SwapHorizOutlined';
import PollIcon from '@material-ui/icons/PollOutlined';
import DevicesOtherIcon from '@material-ui/icons/DevicesOtherOutlined';
import NavItem from './NavItem';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TuneIcon from '@material-ui/icons/TuneOutlined';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const components = {
    ViewModuleIcon: ViewModuleIcon,
    GetAppIcon: GetAppIcon,
    DashboardIcon: DashboardIcon,
    InboxIcon: InboxIcon,
    ReportProblemIcon: ReportProblemIcon,
    WarningIcon: WarningIcon,
    RouterIcon: RouterIcon,
    AccountCircleIcon: AccountCircleIcon,
    PersonIcon: PersonIcon,
    DeveloperBoardIcon: DeveloperBoardIcon,
    CodeIcon: CodeIcon,
    SecurityIcon: SecurityIcon,
    DevicesOtherIcon: DevicesOtherIcon,
    SyncProblemIcon: SyncProblemIcon,
    PeopleIcon: PeopleIcon,
    ExtensionIcon: ExtensionIcon,
    DiscoveredDeviceIcon: DiscoveredDeviceIcon,
    DvrIcon: DvrIcon,
    SettingsIcon: SettingsIcon,
    GroupWorkIcon: GroupWorkIcon,
    SettingsInputComponentIcon: SettingsInputComponentIcon,
    ArrowDownwardIcon: ArrowDownwardIcon,
    SwapHorizIcon: SwapHorizIcon,
    PollIcon: PollIcon,
    TuneIcon: TuneIcon,
};

const styles = theme => ({
    root: {
        backgroundColor: 'white',
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
        color: "#000",
        opacity: "0.87",
    },
    navGroupIcon: {
        width: "24px",
        height: "24px",
        marginRight: "16px"
    },
    arrowIcon: {
        color: "grey",
        marginLeft: "auto",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(70, 142, 228, 0.19)",
            color: theme.palette.pending.main,
            borderRadius: "50%"
        },
    },
    scrollableArea: {
        maxHeight: "calc(100vh - 65px)",
        overflowY: "auto",
        overflowX: "hidden"
    },
    divider: {

    },
    firstDivider: {
        marginTop: "-2px",
        position: "relative",
        zIndex: "5"
    },
    minHeader: {
        cursor: "pointer",
        borderRight: "solid #e3e3e3 1px"
    }
});

class MenuItems extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            section_visibilities: [true, true, true, true],
            number: null
        };
    }

    render_collapse_icon = (index) => {
        const classes = this.props.classes;
        if (this.state.section_visibilities[index]) {
            return <ExpandLessIcon className={classes.arrowIcon} onClick={() => this.toggle(index)} />;
        } else {
            return <ExpandMoreIcon className={classes.arrowIcon} onClick={() => this.toggle(index)} />;
        }
    }

    toggle = (index) => {
        this.setState((state) => {
            state.section_visibilities[index] = !state.section_visibilities[index];
            return state;
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        if (this.props.items.alerts.hidden || !Permissions.allow(["read"], "notification")) return;
        let now = new Date();
        now.setMinutes(now.getMinutes() - 5);
        let params = {
            page_meta: true,
            created_at_gt: now.toISOString()
        };
        new Notification().listFromAPI(params).then((data) => {
            this.setState({ number: data.total });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    handle_toggle = () => {
        if (this.props.isMobile) {
            this.props.toggle();
        }
    }

    render() {
        const { items: {
            hub, overview, messages, alerts, devices, discovered_devices, types, rules, software, configs, integrations, ha_groups, models, users, roles, companies, ingest, command, transfers, reports, pollable_attributes
        }, classes, theme } = this.props;
        return (
            <div>
                <div className={classes.scrollableArea}>
                    {overview.hidden && messages.hidden && alerts.hidden ? "" : <List
                        component="nav"
                        subheader={
                            <ListSubheader onClick={() => this.props.min ? this.toggle(0) : ""} classes={{ root: classes.root + " " + (this.props.min ? classes.minHeader : "") }} component="div" disableSticky={true}>
                                Management
                            </ListSubheader>
                        }
                    >
                        {this.state.section_visibilities[0] ?
                            <React.Fragment>
                                <NavItem
                                    min={this.props.min}
                                    data={overview}
                                    icon={components[overview.icon]}
                                    hidden={overview.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={messages}
                                    icon={components[messages.icon]}
                                    hidden={messages.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={alerts}
                                    icon={components[alerts.icon]}
                                    hidden={alerts.hidden}
                                    number={this.state.number}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={transfers}
                                    icon={components[transfers.icon]}
                                    hidden={transfers.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={reports}
                                    icon={components[reports.icon]}
                                    hidden={reports.hidden}
                                    onClick={this.handle_toggle}
                                />
                            </React.Fragment>
                            : ""}
                    </List>}
                    {devices.hidden && software.hidden && configs.hidden && discovered_devices.hidden ? "" : <List
                        subheader={
                            <ListSubheader onClick={() => this.props.min ? this.toggle(1) : ""} classes={{ root: classes.root + " " + (this.props.min ? classes.minHeader : "") }} component="div">
                                DeviceOps
                            </ListSubheader>
                        }
                    >
                        {this.state.section_visibilities[1] ?
                            <React.Fragment>
                                <NavItem
                                    min={this.props.min}
                                    data={devices}
                                    icon={components[devices.icon]}
                                    hidden={devices.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={discovered_devices}
                                    icon={components[discovered_devices.icon]}
                                    hidden={discovered_devices.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={types}
                                    icon={components[types.icon]}
                                    hidden={types.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={software}
                                    icon={components[software.icon]}
                                    hidden={software.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={configs}
                                    icon={components[configs.icon]}
                                    hidden={configs.hidden}
                                    onClick={this.handle_toggle}
                                />
                            </React.Fragment>
                            : ""}
                    </List>}
                    {rules.hidden && integrations.hidden && ingest.hidden && pollable_attributes.hidden ? "" : <List
                        component="nav"
                        subheader={
                            <ListSubheader onClick={() => this.props.min ? this.toggle(2) : ""} classes={{ root: classes.root + " " + (this.props.min ? classes.minHeader : "") }} component="div">
                                Orchestration
                            </ListSubheader>
                        }
                    >
                        {this.state.section_visibilities[2] ?
                            <React.Fragment>
                                <NavItem
                                    min={this.props.min}
                                    data={ingest}
                                    icon={components[ingest.icon]}
                                    hidden={ingest.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={command}
                                    icon={components[command.icon]}
                                    hidden={command.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={pollable_attributes}
                                    icon={components[pollable_attributes.icon]}
                                    hidden={pollable_attributes.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={rules}
                                    icon={components[rules.icon]}
                                    hidden={rules.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={integrations}
                                    icon={components[integrations.icon]}
                                    hidden={integrations.hidden}
                                    onClick={this.handle_toggle}
                                />
                            </React.Fragment>
                            : ""}
                    </List>}
                    {users.hidden && roles.hidden && companies.hidden ? "" : <List
                        subheader={
                            <ListSubheader onClick={() => this.props.min ? this.toggle(3) : ""} classes={{ root: classes.root + " " + (this.props.min ? classes.minHeader : "") }} component="div">
                                Onboard
                            </ListSubheader>
                        }
                    >
                        {this.state.section_visibilities[3] ?
                            <React.Fragment>
                                <NavItem
                                    min={this.props.min}
                                    data={users}
                                    icon={components[users.icon]}
                                    hidden={users.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={roles}
                                    icon={components[roles.icon]}
                                    hidden={roles.hidden}
                                    onClick={this.handle_toggle}
                                />
                                <NavItem
                                    min={this.props.min}
                                    data={companies}
                                    icon={components[companies.icon]}
                                    hidden={companies.hidden}
                                    onClick={this.handle_toggle}
                                />
                            </React.Fragment>
                            : ""}
                    </List>}
                </div>
            </div>
        );
    }
}
MenuItems.contextType = SnackbarContext;

export default compose(
    withRouter,
    withStyles(styles),
)
    (MenuItems);
