import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PaginationContainer from '../Containers/PaginationContainer';
import Card from '@material-ui/core/Card';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Config from '../../services/DataModels/Config';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TableList from '../Table/TableList';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Loading from '../DisplayOriented/Loading';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
//icons
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AddIcon from '@material-ui/icons/Add';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';

const heading_info = [
	{label: "Config Name", value: "table_name", field: "name", align: "left", disablePadding: false, sortable: true, content: "function"},
	{label: "Account", value: "nested_company.name", field: "company_name", nested_field: true, align: "left", disablePadding: false, sortable: false},
	{label: "Device Type", value: "nested_device_type.name", field: "device_type_name", nested_field: true, align: "left", disablePadding: false, sortable: false},
];

class ConfigsList extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
			layout: "card",
			menus: {},
			configtodelete: null
		}
		this.set_menus();
	}

	set_menus = () => {
		if (!this.props.rules) return;
		this.props.rules.forEach((rule) => {
			this.state.menus[rule._id] = null;
		});
	}

	open_config_tab = (config) => {
		if (!config.nested_device_type._id) {
			this.context.openSnackbar("This network config's device type has been deleted or is not available. This network config cannot be edited.", "error");
		}
		this.props.tabHostProxy.addTab("config", config);
	}

	createConfig = () => {
		this.props.tabHostProxy.addTab("config");
	}

	handleChangePage = (event, page) => {
		this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
	};

	confirm_delete = (config, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Delete this config? This action cannot be reversed.
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								className={classes.deleteButton} color="primary" 
								onClick={() => this.delete_config(config)}
							>
								DELETE CONFIG
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	delete_config = (config) => {
		new Config(config)
			.deleteFromAPI()
			.then( () => {
				this.context.openSnackbar("Config deleted.", "success");
				this.props.tabHostProxy.refresh();
				this.close_modal();
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}
	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState((state) => {
			state.menus[id] = element;
			return state;
		});
	}

	close_action_menu = (id) => {
		this.setState((state) => {
			state.menus[id] = null;
			return state;
		});
	};

	render_menu = (config) => {
		const classes = this.props.classes;
		let anchorEl = this.state.menus[config._id];
		let open = Boolean(anchorEl);
		return (
			<div style={{ visibility: (this.get_actions(config).length === 0 ? "hidden" : "visible") }}>
				<div>
					<IconButton
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={(event) => this.open_action_menu(event, config._id)}
						className={classes.menuIconButton}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				{this.render_action_menu(open, anchorEl, config)}
			</div>
		);
	}

	render_action_menu = (open, anchorEl, config) => {
		const classes = this.props.classes;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => this.close_action_menu(config._id)}
				PaperProps={{
					style: { overflow: "visible" }
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				{this.get_actions().map((action, index) => (
					<div onClick={() => { this.executeAction(action, config) }} key={action.label} className={classes.menuItemContainer}>
						<MenuItem className={classes.actionMenuItem}>
							<ListItemIcon>
								{action.icon}
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								{action.label}
							</Typography>
						</MenuItem>
					</div>
				))}
			</Menu>
		);
	}

	get_actions = () => {
		let possible_actions = [];
		possible_actions.push({ label: "View", action: "view", confirm: "Delete this device?", icon: <EditIcon /> });
		possible_actions.push({ label: "Delete", action: "delete", confirm: "Delete this device?", icon: <DeleteIcon /> });
		return possible_actions;
	}

	executeAction = (action, config) => {
		if (action.action === 'view') {
			this.close_action_menu(config._id);
			this.open_config_tab(config)
		} else if (action.action === 'delete') {
			this.state.configtodelete = config;
			this.close_action_menu(config._id);
			this.openModal("Are you sure you want to delete this config?", "DELETE CONFIG", this.deleteConfig);
		}

	}

	deleteConfig = () => {
		new Config({_id:this.state.configtodelete._id})
			.deleteFromAPI()
			.then( () => {
				this.context.openSnackbar("Network config deleted.", "success");
				this.close_modal();
				this.props.tabHostProxy.closeOther(this.state.configtodelete._id, true);
				this.props.tabHostProxy.refresh();
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	openModal = (prompt, buttonText, fxn) => {
		this.setState({modal: {
			"open": true,
			prompt: prompt,
			yesFunction: fxn,
			functionText: buttonText,
		}});
	}

	closeModal = () => {
		let newState = {modal: {open: false}};
		this.setState(newState);
	}
	render_table_layout = () => {
		const {configs, page_data} = this.props;
		if (!configs) {
			return <Loading/>;
		}
		return (
			<TableList
				headings={heading_info} 
				items={configs}
				page_data={page_data}
				perform_sort={this.props.perform_sort}
			/>
		);
	}

	render_card_layout = () => {
		const { configs, classes } = this.props;
		if (!configs) {
			return <Loading/>;
		}
		return (
			<React.Fragment>
				{configs.map((config) => {
					return (
						<div key={config._id} className={classes.userCardContainer}>
							<Card className={classes.userCard}>
								<div className={classes.optionsIconsContainer}>
									<div className={classes.rightOptionsContainer}>
										{this.get_actions().map((action, index) => (
											<div onClick={() => { this.executeAction(action, config) }} key={action.label} className={classes.optionsIcon}>
												{action.icon}
											</div>
										))}
									</div>
								</div>
								{config.error ? 
									<div className={classes.errorContainer}>
										<ErrorOutlineIcon className={classes.errorIcon}/>
										<div className={classes.errorText}>Config Error</div>
									</div>
								: ""}
								<div onClick={()=>{this.open_config_tab(config)}} className={classes.configInfo}>
									<div className={classes.userTitleContainer}>
										<div className={classes.userTitle}>
											{config.name}
										</div>
									</div>
									<div className={classes.iconWithTextContainer}>
										<Tooltip key="Account" title="Account">
											<AccountCircleIcon className={classes.companyIcon} />
										</Tooltip>
										<div className={classes.iconLabel}>
											{config.nested_company.name}
										</div>
									</div>
								</div>
							</Card>
						</div>
					);
				})}
			</React.Fragment>
		);
	}

	render() {
		const { configs, page_data, classes } = this.props;
		let buttons = Permissions.allow(["create"], "device_config") ? [{ label: "CREATE CONFIG", icon: (<AddIcon />), action: this.createConfig }]: undefined;
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}/>
				<PaginationContainer
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={(layout) => this.setState({layout: layout})}
					toggle_filter={this.props.show_filters}
					filtering={this.props.filtering}
				/>
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer}>
						{this.render_card_layout()}
					</div> :
					<div className={classes.tableItemsContainer}>
						{this.render_table_layout()}
					</div>
				}
			</div>
		);
	}
}



const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			flexGrow: 2,
			width: "75%",
		},
		usersContainer: {
			overflowY: "auto",
			height: "calc(100% - 82px)",
			padding: "12px",
			backgroundColor: "#f5f5f7",
		},
		cardItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 82px)",
			padding: "12px",
			backgroundColor: "#f5f5f7",
		},
		tableItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 82px)",
			backgroundColor: "#ffffff",
		},
		prompt: {
			fontFamily: "Inter",
			fontSize: "22px",
			marginBottom: "24px",
		},
		modalWrapper: {
			padding: "24px"
		},
		userCardContainer: {
			width: "33.33%",
			display: "inline-flex",
			position: "relative",
			justifyContent: "center",
		},
		userCard: {
			boxShadow: "0px 3px 7px 2px #00000017",
			backgroundColor: "#fafafa",
			margin: "12px",
			width: "100%",
			minWidth: "250px",
			maxWidth: "380px",
			minHeight: "150px",
			height: "auto",
			boxSizing: "border-box",
			border: "#ffffff solid 1px",
			transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
			"&:hover": {
				border: `${theme.palette.primary.main} solid 1px`,
			},
		},
		userImageContainer: {
			display: "flex",
			padding: "8px",
			height: "200px",
		},
		userImage: {
			maxWidth: "100%",
			maxHeight: "200px",
			margin: "auto",
			display: "block",
		},
		configInfo: {
			position: "relative",
			padding: "16px 24px 24px 24px",
			"&:hover": {
				cursor: "pointer",
			},
		},
		deviceImageContainer: {
			display: "flex",
			padding: "8px",
			height: "200px",
		},
		errorContainer: {
			display: "flex",
			color: theme.palette.red.main,
			alignItems: "center",
			padding: "16px 24px",
			width: "100%",
			boxSizing: "border-box",
			justifyContent: "flex-start",
		},
		errorIcon: {
			marginRight: "4px"
		},
		errorText: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		configError: {
			position: "absolute",
			color: theme.palette.red.main
		},
		deviceImage: {
			maxWidth: "100%",
			maxHeight: "200px",
			margin: "auto",
			display: "block",
		},
		modalButtons: {
			display: "flex",
			justifyContent: "flex-end"
		},
		userTitleContainer: {
			marginBottom: "16px",
		},
		userTitle: {
			fontSize: "16px",
			color: "#000000",
			opacity: "0.87",
			width: "100%",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		iconWithTextContainer: {
			display: "flex",
			alignItems: "center",
			width: "100%",
		},
		companyIcon: {
			fontSize: "20px",
			color: "#636366",
			marginRight: "8px",
		},
		iconLabel: {
			color: "#636366",
			overflow: "hidden",
			fontSize: "14px",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		},
		buttonContainer: {
			display: "flex",
			margin: "auto",
			marginRight: 0
		},
		viewUserButton: {
			padding: "8px",
		},
		bottomRow: {
			display: "flex",
			backgroundColor: "white",
			padding: "8px",
		},
		profileImage: {
			maxWidth: "100%",
			maxHeight: "200px",
			margin: "auto",
			display: "block",
		},
		placeholderProfileImage: {
			width: "100%",
			height: "100%",
			color: theme.palette.primary.main
		},
		topRowIconsContainer: {
			boxSizing: "border-box",
			position: "absolute",
			justifyContent: "flex-end",
			top: 0,
			padding: "0 4px",
			left: 0,
			width: "100%",
			display: "flex",
		},
		iconButton: {
			padding: "7px",
			color: "grey",
		},
		deleteButton: {
			backgroundColor: theme.palette.red.main,
			color: "white",
			'&:hover': {
				backgroundColor: darken(theme.palette.red.main, .2),
			}
		},
		actionListTitle: {
			marginBottom: "8px",
			padding: "11px 16px",
			borderBottom: "solid #80808073 1px",
			backgroundColor: "white",
			cursor: "unset",
			'&:hover': {
				backgroundColor: "white",
				cursor: "unset",
			},
			outline: "none",
			fontFamily: "Inter",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "1rem",
			width: "auto",
			height: "24px",
			whiteSpace: "nowrap",
			boxSizing: "content-box",
			fontWeight: 400,
			lineHeight: "1.5em"
		},
		actionMenuItem: {
			outline: "none"
		},
		optionsIconsContainer: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			width: "100%",
			height: "36px",
			backgroundColor: "#ffffff",
			padding: "8px",
			boxSizing: "border-box",
		},
		rightOptionsContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			width: "75%",
		},
		optionsIcon: {
			marginLeft: "16px",
			color: "#8e8e93",
			width: "20px",
			height: "20px",
			"& svg": {
				fontSize: "20px",
			},
			cursor: "pointer",
			"&:hover": {
				color: "#0263fc",
			},
			transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
		},
	})
};

ConfigsList.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(ConfigsList));
