import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PaginationContainer from '../Containers/PaginationContainer';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TableList from '../Table/TableList';
import Permissions from '../../services/Permissions';
import Integration from '../../services/DataModels/Integration';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

//icons
import MemoryIcon from '@material-ui/icons/Memory';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

let heading_info = [
    { label: "Name", value: "table_name", field: "table_name", align: "left", disablePadding: false, sortable: true },
    { label: "Type", field: "readable_type", align: "left", disablePadding: false, sortable: false }
];

const styles = theme => ({
    container: {
        fontFamily: "Inter",
        flexGrow: 2,
    },
    integrationsContainer: {
        overflowY: "auto",
        padding: "12px",
        height: "calc(100% - 82px)",
        alignItems: "flex-start",
        backgroundColor: "#f5f5f7",
    },
    cardItemsContainer: {
        overflowY: "auto",
        height: "calc(100% - 82px)",
        padding: "12px",
        alignItems: "flex-start",
        backgroundColor: "#f5f5f7",
    },
    tableItemsContainer: {
        overflowY: "auto",
        height: "calc(100% - 82px)",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
    },
    integrationCardContainer: {
        width: "33.33%",
        display: "inline-flex",
        justifyContent: "center",
    },
    noShow: {
        visibility: "hidden"
    },
    imageContainer: {
        display: "flex",
        padding: "8px",
        height: "138px",
        backgroundColor: "#f5f5f7",
        cursor: "pointer",
    },
    image: {
        maxWidth: "100%",
        maxHeight: "138px",
        margin: "auto",
        display: "block",
    },
    textRowContainer: {
        position: "relative",
        padding: "16px 24px 24px 24px",
        cursor: "pointer",
    },
    companyContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "8px",
        width: "100%",
    },
    companyIcon: {
        fontSize: "30px",
        color: theme.palette.greyIcon.main
    },
    companyLabel: {
        color: "grey",
        fontWeight: "400",
        overflow: "hidden",
        marginLeft: "8px",
        fontSize: "18px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    title: {
        fontSize: "16px",
        color: "#000000",
        opacity: "0.87",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "8px",
    },
    integrationCard: {
        boxShadow: "0px 3px 7px 2px #00000017",
        backgroundColor: "#fafafa",
        margin: "12px",
        width: "100%",
        minWidth: "250px",
        maxWidth: "380px",
        height: "auto",
        minHeight: "209px",
        border: "#ffffff solid 1px",
        transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
        "&:hover": {
            border: `${theme.palette.pending.main} solid 1px`,
        },
    },
    linkText: {
        color: theme.primary,
        textDecoration: "underline",
        cursor: "pointer",
        marginLeft: "12px",
    },
    buttonContainer: {
        display: "flex",
        margin: "auto",
        marginRight: 0,
        justifyContent: "flex-end",
        height: "36px",
        padding: "8px",
        boxSizing: "border-box",
        alignItems: "center",
    },
    menuIconButton: {
        fontSize: "24px",
        padding: 0,
    },
    viewDeviceButton: {
        padding: "8px",
    },
    bottomRow: {
        display: "flex",
        backgroundColor: "white",
        padding: "8px",
    },
    typeContainer: {
        color: "#636366",
        fontSize: "14px",
        lineHeight: "22px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    unknown: {
        fontStyle: "italics"
    },
    actionListTitle: {
        marginBottom: "8px",
        padding: "11px 16px",
        borderBottom: "solid #80808073 1px",
        backgroundColor: "white",
        cursor: "unset",
        '&:hover': {
            backgroundColor: "white",
            cursor: "unset",
        },
        outline: "none",
        fontFamily: "Inter",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        width: "auto",
        height: "24px",
        whiteSpace: "nowrap",
        boxSizing: "content-box",
        fontWeight: 400,
        lineHeight: "1.5em"
    },
    actionMenuItem: {
        outline: "none"
    },
});

class IntegrationsList extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            layout: "card",
            menus: {},
            integrationtodelete: null,
            modal: {
                open: false,
                children: () => ""
            },
        };
        this.set_menus();
    }

    set_menus = () => {
        if (!this.props.integrations) return;
        this.props.integrations.forEach((integration) => {
            this.state.menus[integration._id] = null;
        });
    }

    handleChangePage = (event, page) => {
        this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
    };

    handleChangeRowsPerPage = event => {
        this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
    };

    openIntegrationTab = (integration) => {
        this.props.tabHostProxy.addTab("integration", integration);
    }

    createIntegration = () => {
        this.props.tabHostProxy.addTab("integration");
    }

    render_table_layout = () => {
        const { integrations, page_data } = this.props;
        return (
            <TableList
                headings={heading_info}
                items={integrations}
                page_data={page_data}
                perform_sort={this.props.perform_sort}
            />
        );
    }

    open_action_menu = (event, id) => {
        const element = event.target;
        this.setState((state) => {
            state.menus[id] = element;
            return state;
        });
    }

    close_action_menu = (id) => {
        this.setState((state) => {
            state.menus[id] = null;
            return state;
        });
    };

    render_menu = (integration) => {
        const classes = this.props.classes;
        let anchorEl = this.state.menus[integration._id];
        let open = Boolean(anchorEl);
        return (
            <div style={{ visibility: (this.get_actions(integration).length === 0 ? "hidden" : "visible") }}>
                <div>
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => this.open_action_menu(event, integration._id)}
                        className={classes.menuIconButton}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>
                {this.render_action_menu(open, anchorEl, integration)}
            </div>
        );
    }

    render_action_menu = (open, anchorEl, integration) => {
        const classes = this.props.classes;
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => this.close_action_menu(integration._id)}
                PaperProps={{
                    style: { overflow: "visible" }
                }}
            >
                <div className={classes.actionListTitle}>
                    Perform Action...
                </div>
                {this.get_actions().map((action, index) => (
                    <div onClick={() => { this.executeAction(action, integration) }} key={action.label} className={classes.menuItemContainer}>
                        <MenuItem className={classes.actionMenuItem}>
                            <ListItemIcon>
                                {action.icon}
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                {action.label}
                            </Typography>
                        </MenuItem>
                    </div>
                ))}
            </Menu>
        );
    }

    get_actions = () => {
        let possible_actions = [];
        possible_actions.push({ label: "View", action: "view", confirm: "Delete this device?", icon: <VisibilityIcon /> });
        possible_actions.push({ label: "Delete", action: "delete", confirm: "Delete this device?", icon: <DeleteForeverIcon /> });
        return possible_actions;
    }

    executeAction = (action, integration) => {
        if (action.action === 'view') {
            this.close_action_menu(integration._id);
            this.openIntegrationTab(integration)
        } else if (action.action === 'delete') {
            this.state.integrationtodelete = integration;
            this.close_action_menu(integration._id);
            this.openModal("Are you sure you want to delete this integration?", "DELETE INTEGRATION", this.deleteintegration);
        }

    }

    deleteintegration = () => {
        new Integration({ _id: this.state.integrationtodelete._id })
            .deleteFromAPI()
            .then(() => {
                this.context.openSnackbar("Integration deleted.", "success");
                this.closeModal();
                this.props.tabHostProxy.closeOther(this.state.integrationtodelete._id, true);
                this.props.tabHostProxy.refresh();
            })
            .catch((error) => {
                this.context.openSnackbar(error, "error");
            });
    }

    openModal = (prompt, buttonText, fxn) => {
        this.setState({
            modal: {
                "open": true,
                prompt: prompt,
                yesFunction: fxn,
                functionText: buttonText,
            }
        });
    }

    closeModal = () => {
        let newState = { modal: { open: false } };
        this.setState(newState);
    }

    render_card_layout = () => {
        const { integrations, classes } = this.props;
        return (
            <React.Fragment>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
                {integrations.map((integration) => (
                    <div key={integration._id} className={classes.integrationCardContainer}>
                        <Card className={classes.integrationCard}>
                            <div className={classes.buttonContainer}>
                                {integration.nested_type != null ? this.render_menu(integration) :
                                    <Button disabled={integration.nested_type == null} variant="contained" color="primary" className={classes.viewDeviceButton}>
                                        Unsupported Type
                                    </Button>
                                }
                            </div>
                            <div onClick={() => { this.openIntegrationTab(integration) }} className={classes.imageContainer}>
                                <img className={classes.image} src={require("../../images/" + integration.logo).default} />
                            </div>
                            <div onClick={() => { this.openIntegrationTab(integration) }} className={classes.textRowContainer}>
                                <div className={classes.title}>
                                    {integration.name}
                                </div>
                                <div className={classes.typeContainer}>
                                    Type: {integration.readable_type === '' ? <span className={classes.unknown}>Unknown Type</span> : integration.readable_type}
                                </div>
                                <div className={classes.descriptionContainer}>
                                    {integration.integration !== "" ?
                                        integration.description
                                        :
                                        <div className={classes.noDescription}>
                                            No description.
                                        </div>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                ))}
            </React.Fragment>
        );
    }

    render() {
        let { integrations, page_data, tabHostProxy, classes } = this.props;
        let buttons = Permissions.allow(["create"], "integration") ? [{ label: "CREATE INTEGRATION", icon: (<AddIcon />), action: this.createIntegration }] : undefined;
        return (
            <div className={classes.container}>
                <PaginationContainer
                    count={page_data.total}
                    buttons={buttons}
                    rowsPerPage={page_data.per_page}
                    currentPage={page_data.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    toggleView={(layout) => this.setState({ layout: layout })}
                />
                {this.state.layout === "card" ?
                    (<div className={classes.cardItemsContainer}>
                        {this.render_card_layout()}
                    </div>) :
                    (<div className={classes.tableItemsContainer}>
                        {this.render_table_layout()}
                    </div>)
                }
                {/* <div className={classes.integrationsContainer}>
					{this.state.layout === "card" ? 
						this.render_card_layout()
						:
						this.render_table_layout()
					}
				</div> */}
            </div>
        );
    }

}

IntegrationsList.contextType = SnackbarContext;
export default withStyles(styles)(IntegrationsList);

