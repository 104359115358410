import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class Role extends DataModel {
	constructor(data = BLANK) {
		super('user_types', data)
	}

	static defaultRoleReadable(role_name) {
		if (role_name === "users" || role_name === "limited_users" || role_name === "limited_report_users" || role_name === "read_only_users" || role_name === "user_managers" || role_name === "admins" || role_name === "system_admins" || role_name === "device_users" || role_name == "escrow_automation_users") {
			return role_name.replace(/_/g, " ").split(" ").reduce((formatted, word) => {
				formatted += word.charAt(0).toUpperCase() + word.slice(1) + " ";
				return formatted;
			}, "");
		} else {
			return role_name;
		}
	}

	static prepForList(roles) {
		return AssignNestedModels("companies", "company_id", roles, "roles");
	}
}

const BLANK = {
	abilities: null,
	name: "",
	description: "",
	company_id: ""
}

export default Role;
