import React from 'react';
import {withStyles, withTheme} from '@material-ui/core/styles';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import DeviceCSVUpload from '../../services/DataModels/DeviceCSVUpload'
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Loading from '../DisplayOriented/Loading';
import { withRouter } from 'react-router-dom';
import Permissions from '../../services/Permissions';
import Auth from '../../services/Auth';
import {
	REPORT_ALL_DEVICES,
	REPORT_CLOUD_NATIVE_INTEGRATIONS,
	BUILD_DOWNLOAD_DEVICE_REPORT_URL
} from '../../services/CLURD';

//icons
import AttachmentIcon from '@material-ui/icons/Attachment';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
	modalWrapper: {
		fontFamily: "Inter",
		overflowY: "auto",
		height: "100%",
		boxSizing: "border-box"
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		lineHeight: "32px",
		color: "rgba(0, 0, 0, 0.87)",
		fontWeight: 700,
		marginBottom: "32px",
	},
	subPrompt: {
		fontSize: "14px",
		color: "#8e8e93",
	},
	formatWrapper: {
		overflow: "scroll",
		paddingBottom: "12px",
		marginTop: "12px",
	},
	formatImage: {
		width: "100vw"
	},
	errorText: {
		fontFamily: "Inter",
		fontSize: "18px",
		marginRight: "14px",
		color: theme.palette.red.main
	},
	fabIcon: {
		marginRight: "4px"
	},
	button: {
		marginTop: 12,
	},
	linkOverride: {
		textDecoration: "none",
	},
	link: {
		fontFamily: "Inter",
		fontSize: "18px",
		margin: "14px 8px 14px 0",
		display: "inline",
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		}
	},
	label: {
		display: "flex",
		padding: "8px 16px",
		alignItems: "center",
		cursor: "pointer"
	},
	warningIcon: {
		color: theme.palette.caution.main,
		marginRight: "4px",
		fontSize: "38px"
	},
	errorIcon: {
		color: theme.palette.red.main,
		marginRight: "4px",
		fontSize: "38px"
	},
	errorTitle: {
		fontSize: "20px",
		fontFamily: "Inter",
		display: "flex",
		alignItems: "center",
		margin: "14px 0"
	},
	errorWrapper: {
		marginLeft: "44px"
	},
	errorMessage: {
		fontSize: "16px",
		fontFamily: "Inter",
		color: "#0000009c",
	}
});

class BulkUploadForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			loading: false,
			filename: "",
			show_error: false,
			error_message: null,
			response_id: null
		};
		this.domRef = React.createRef();
	}

	downloadReport(reportType) {
		let yurl = BUILD_DOWNLOAD_DEVICE_REPORT_URL(reportType, Auth.token());
    const newWindow = window.open(yurl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Choose a Report to Download
				</div>
				<div className={classes.subPrompt}>
					Download device data in a CSV format.
				</div>
				<div>
					<Button
						variant="outlined"
						aria-label="All Devices"
						color="primary"
						className={classes.button}
						onClick={() => this.downloadReport(REPORT_ALL_DEVICES)}
					>
						Download All Devices
					</Button>
				</div>
				<div>
					<Button
						aria-label="Cloud Native Integrations"
						color="primary"
						className={classes.button}
						onClick={() => this.downloadReport(REPORT_CLOUD_NATIVE_INTEGRATIONS)}
					>
						Cloud-Native Integration Device Report
					</Button>
				</div>
			</div>
		)
	}
}

BulkUploadForm.contextType = SnackbarContext;

export default compose(
	withStyles(styles),
	withTheme(),
	withRouter
)
(BulkUploadForm);
