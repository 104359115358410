import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AssignNestedModels } from '../../../services/CLURDUtilities';
import RulesGrid from "../../RuleSpecific/RulesGrid";
import Rule from "../../../services/DataModels/Rule";
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import SwitchInput from "../../Inputs/SwitchInput";
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import Permissions from '../../../services/Permissions';
import Auth from '../../../services/Auth';
import Card from '@material-ui/core/Card';
import FilterSidebar from '../../ActionOriented/FilterSidebar';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	},
	filterBg: {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		zIndex: 9999,
	},
	filterContainer: {
		position: "relative",
		zIndex: 3,
		width: "800px",
		backgroundColor: "white",
		padding: "72px 48px 35px 48px",
		overflowY: "auto",
		maxHeight: "750px",
		boxSizing: "border-box",
	},
	link: {
		color: "#1153b6",
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": "#1153b6",
			textDecorationColor: "#1153b6",
			cursor: "pointer",
		}
	}
};

class RulesTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			modal: {
				open: false
			},
			rules: null,
			selections: [],
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			filter_data: {},
			sort: null,
			show_filters: false,
		};
		this.set_filters();
		this.load_rules();
		this.props.tabHostProxy.setRootRefresh(this.load_rules);
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_rules();
		});
	}

	promptModalActiveChange = (rule) => {
		this.props.promptModalActiveChange(rule);
	}

	closeModal = () => {
		this.setState({
			modal: {
				"open": false
			}
		});
	}

	promptModalActiveChange = (rule) => {
		let toggle = rule.active ? "Deactivate" : "Activate";
		let prompt = `${toggle} the ${rule.description} policy?`;
		this.setState({
			modal: {
				"open": true,
				"prompt": prompt,
				"yesFunction": () => {
					this.closeModal();
					this.changeActive(rule);
				},
				"functionText": `${toggle} policy`
			}
		});
	}

	changeActive = (rule) => {
		let body = Object.assign({}, rule);
		body.active = !rule.active;
		let ruleHelper = new Rule(body, true);
		ruleHelper.createOrSave().then((result) => {
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar("Rule saved.", "success");
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	};

	render_switch = (rule) => {
		return (
			<div style={{marginRight: "16px"}}>
				<SwitchInput
					location="center"
					initial={rule.active}
					emitChange={() => this.promptModalActiveChange(rule)}
					disabled={!Permissions.allow(["update"], "rule", rule.company_id)}
				/>
			</div>
		);
	}

	prepare_rule = (rule) => {
		let rules = [rule];
		return new Promise( (resolve, reject) => {
			Rule.prepForList(rules)
				.then( () => {
					rules.forEach( (rule) => {
						rule.table_scheduled = rule.schedule ? "Scheduled" : "Reactive";
						rule.table_active = () => this.render_switch(rule);
						rule.table_description = () => (<div onClick={() => this.props.tabHostProxy.addTab("rule", rule)} style={{cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}}>{rule.description}</div>);
					});
					resolve(rules[0]);
				}).catch( (error) => {
					reject(error);
				});
		});
	}

	load_rules = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.rules !== null) this.setState({rules: null});
		new Rule().listFromAPI(params).then( (results) => {
			let rules = results.items;
			let total = results.total;
			Rule.prepForList(rules)
				.then( () => {
					rules.forEach( (rule) => {
						rule.table_scheduled = rule.schedule ? "Scheduled" : "Reactive";
						rule.table_active = () => this.render_switch(rule);
						rule.table_description = () => (
							<div
								onClick={() => this.props.tabHostProxy.addTab("rule", rule)}
								className={classes.link}
							>
									{rule.description}
							</div>
							);
					});
					this.setState( () => {
						return {
							rules: rules, 
							page_data: {
								page: params.page,
								per_page: params.per_page,
								page_meta: true,
								total: total
							}
						};
					});
				}).catch( (error) => {
					this.context.openSnackbar(error, 'error');
				});
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return { 
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_rules();
		});
	}

	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}

	set_filters = () => {
		this.filter_key = this.filter_key + 1;
		this.filters = [];
		this.filters = [
				{
					label: 'Policy Description',
					type: 'text',
					placeholder: "Filter by policy description",
					field: "description",
				},
				{
					label: 'Cloud/Edge',
					type: 'select',
					options: [
						{display: "All", value: ''},
						{display: "Cloud", value: "true"},
						{display: "Edge", value: "false"}
					],
					value: '',
					field: 'cloud_rule'
				},
				{
					label: 'Active',
					type: 'select',
					options: [
						{display: "All", value: ''},
						{display: "Yes", value: "true"},
						{display: "No", value: "false"},
					],
					value: '',
					field: 'active'
				},
			];
		if (Auth.currentUser().company_ids.length > 1) {
			this.filters.push({
				label: 'Account',
				type: 'lookup',
				placeholder: "Filter by account",
				field: "company_id",
				model: "companies",
				valueField: "_id",
				labelField: "name"
			});
		}	
	}

	prepare_filter(params) {
		let body = {};
		if (!params) {
			params = {};
		}
		if (params["Policy Description"] && params["Policy Description"] !== '') {
			body.description_like = params["Policy Description"];
		}
		if (params["Active"]) {
			body.active = params["Active"];
		}
		if (params["Cloud/Edge"]) {
			body.cloud_rule = params["Cloud/Edge"];
		}
		if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
			var companyIDs = params["Account"].values.map((option) => {
				return option.value;
			});
			body.company_id_in = companyIDs.join(",");
		}
		return body;
	}

	apply_filters = (filters) => {
		this.setState({selections: [], filter_data: this.prepare_filter(filters)}, () => {
			this.load_rules();
		});
	}

	render() {
		const { tabHostProxy, classes } = this.props;
		const { rules, page_data, filter_data  } = this.state;

		return (
			<div className={classes.container}>
				{rules == null ?
						<Loading/>
					:
					<RulesGrid
						filtering={this.is_filtering()}
						show_filters={() => this.setState({show_filters: true})}
						filter_data={filter_data}
						prepareRule={this.prepare_rule}
						rules={rules}
						page_change={this.page_change}
						page_data={page_data}
						tabHostProxy={tabHostProxy}
						perform_sort={this.perform_sort}
						promptModalActiveChange={this.promptModalActiveChange}
					/>
				}
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
				<div style={{visibility: this.state.show_filters ? "visible" : "hidden"}} onClick={() => this.setState({show_filters: false})} className={classes.filterBg}>
						<Card onClick={(event) => event.stopPropagation()} className={classes.filterContainer}						>
							<FilterSidebar
								filters={this.filters}
								triggerFilter={this.apply_filters}
								key={this.filter_key}
								closeFilters={() => this.setState({show_filters: false})}
							/>
						</Card>
				</div>
			</div>
		);
	}
}

RulesTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(RulesTab));
