import { UploadSoftwareUpdateFiles, AssignNestedModels } from '../CLURDUtilities';
import DataModel from "./DataModel";
import Permissions from '../Permissions';

class SoftwareUpdate extends DataModel {
	constructor(data = BLANK) {
		super("software_updates", data);
	}

	static loadRequirements(packages) {
		let promises = [AssignNestedModels("companies", "company_id", packages), AssignNestedModels("device_types", "device_type_id", packages)];
		if (Permissions.allow(["read"], "user")) {
			promises.push(AssignNestedModels("users", "user_id", packages));
		}
		return Promise.all(promises);
	}

	createAndUploadFiles(files) {
		let self = this;
		return new Promise((resolve, reject) => {
			self.save().then((newSoftwareUpdate) => {
				self.setData(newSoftwareUpdate);
				if (files) {
					let fileUploadPromise = UploadSoftwareUpdateFiles(this.endpoint + "/" + newSoftwareUpdate._id + "/upload", files);
					fileUploadPromise.then( () => {
						self.readFromAPI().then((readResult) => {
							resolve(readResult);
						}).catch( (error) => {
							console.log(error);
							reject(error);
						});
					}).catch( (error) => {
						console.log(error);
						reject(error);
					});
				} else {
					resolve(newSoftwareUpdate);
				}
			}).catch( (error) => {
				reject(error.response.data);
			});
		});
	}

	static validate(editableCopy) {
		let validationMessages = {};
		let o = editableCopy;
		if (o.name == null || o.name.length == 0) {
			validationMessages.name = ["Please name this software package."];
		}
		if (o.script == null || o.script.length == 0) {
			validationMessages.script = ["Please include the script that will execute the installation."];
		}
		if (o.filename == null || o.filename.length == 0) {
			validationMessages.filename = ["Please upload files to be used in the software installation."];
		}
		if (Object.keys(validationMessages).length > 0) {
			validationMessages.valid = false;
		} else {
			validationMessages.valid = true;
		}
		return validationMessages;
	}

}

const BLANK = {
	name: "",
	type: "",
	url: "",
	script: "",
	reboot: false,
	files: []
};

export default SoftwareUpdate;
