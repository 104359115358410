import React from 'react';
import { withStyles, withTheme} from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import Role from "../../../services/DataModels/Role";
import FilterSidebar from '../../ActionOriented/FilterSidebar';
import RolesList from '../../RoleSpecific/RolesList';
import Auth from '../../../services/Auth';
import Card from '@material-ui/core/Card';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	},
	filterBg: {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		zIndex: 9999
	},
	filterContainer: {
		position: "relative",
		zIndex: 3,
		width: "800px",
		backgroundColor: "white",
		padding: "72px 48px 35px 48px",
		maxHeight: "750px",
	},
	link: {
		color: "#1153b6",
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": "#1153b6",
			textDecorationColor: "#1153b6",
			cursor: "pointer",
		}
	}
};

class RolesTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			show_filters: false,
			roles: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			}
		};
		this.set_filters();
		this.load_roles();
		this.props.tabHostProxy.setRootRefresh(this.load_roles);
	}

	set_filters = () => {
		this.filters = [
		{
			label: 'Name',
			type: 'text',
			field: 'name_like',
		},
		{
			label: 'Description',
			type: 'text',
			field: "description_like",
		}
	];
		if (Auth.currentUser().company_ids.length > 1) {
			this.filters.push({
				label: 'Account',
				type: 'lookup',
				placeholder: "Filter by account",
				field: "company_id",
				model: "companies",
				valueField: "_id",
				labelField: "name"
			});
		}
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_roles();
		});
	}

	load_roles = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.roles !== null) this.setState({roles: null});
		new Role()
			.listFromAPI(params).then( (result) => {
				let roles = result.items;
				Role.prepForList(roles).then( (roles) => {
					roles.forEach( (role) => {
						if (!role.nested_company) {
							role.nested_company = { name: "Default Role" };
						}
						role.table_name = () => (<div onClick={() => this.props.tabHostProxy.addTab("role", role)} className={classes.link}>{role.name}</div>);
					});	
					this.setState( (state) => {
						state.roles = roles;
						state.page_data = {
							page: params.page,
							per_page: params.per_page,
							page_meta: true,
							total: result.total
						};
						return state;
					});
				})
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_roles();
		});
	}

	prepare_filter = (params) => {
		let body = {};
		if (params) {
			if (params["Name"]) {
				body.name_like = params["Name"];
			}
			if (params["Description"]) {
				body.description_like = params["Description"];
			}
			if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
				var companyIDs = params["Account"].values.map((option) => {
					return option.value;
				});
				body.company_id_in = companyIDs.join(",");
			}
		}
		return body;
	}

	apply_filters = (filters) => {
		this.setState({filter_data: this.prepare_filter(filters)}, () => {
			this.load_roles();
		});
	}


	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}

	render() {
		let { tabHostProxy, classes } = this.props;
		const { roles, page_data } = this.state;
		return (
			<div className={classes.container}>
				<div style={{visibility: this.state.show_filters ? "visible" : "hidden"}} onClick={() => this.setState({show_filters: false})} className={classes.filterBg}>
					<Card
						onClick={(event) => event.stopPropagation()}
						className={classes.filterContainer}
					>
						<FilterSidebar
							filters={this.filters}
							triggerFilter={this.apply_filters}
							closeFilters={() => this.setState({show_filters: false})}
						/>
					</Card>
				</div>
				<RolesList
					show_filters={() => this.setState({show_filters: true})}
					filtering={this.is_filtering()}
					page_change={this.page_change}
					roles={roles}
					perform_sort={this.perform_sort}
					page_data={page_data}
					tabHostProxy={tabHostProxy}
				/>
			</div>
		);
	}
}

RolesTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(RolesTab));
