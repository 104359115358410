import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import Card from '@material-ui/core/Card';
import Rule from '../../services/DataModels/Rule';
import Chip from '@material-ui/core/Chip';
import SwitchInput from "../Inputs/SwitchInput";
import TableList from '../Table/TableList';
import PaginationContainer from '../Containers/PaginationContainer';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';

import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
//icons
import CodeIcon from '@material-ui/icons/Code';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import EventIcon from '@material-ui/icons/Event';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';
import RepeatIcon from '@material-ui/icons/Repeat';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyIcon from '@material-ui/icons/FileCopy';


const heading_info = [
	{label: "Description", value: "table_description", field: "description", align: "left", disablePadding: false, sortable: true, content: "function"},
	{label: "Active", value: "table_active", field: "active", align: "center", disablePadding: false, sortable: true, content: "function"},
	{label: "Scheduled/Reactive", value: "table_scheduled", field: "scheduled", align: "left", disablePadding: false, sortable: false},
];

const styles = theme => ({
	container: {
		fontFamily: "Inter",
		flexGrow: 2,
		width: "75%"
	},
	rulesContainer: {
		overflowY: "auto",
		height: "calc(100% - 82px)",
		padding: "12px",
		backgroundColor: "#f5f5f7",
	},
	cardItemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 82px)",
		padding: "12px",
		backgroundColor: "#f5f5f7",
	},
	tableItemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 82px)",
		backgroundColor: "#ffffff",
	},
	ruleCardContainer: {
		width: "33.33%",
		display: "inline-flex",
		maxHeight: "300px",
		justifyContent: "center",
	},
	ruleCard: {
		boxShadow: "0px 3px 7px 2px #00000017",
		backgroundColor: "#fafafa",
		margin: "12px",
		width: "100%",
		minWidth: "250px",
		maxWidth: "380px",
		height: "auto",
		minHeight: "209px",
		border: "#ffffff solid 1px",
		transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
		"&:hover": {
			border: "#1153B6 solid 1px",
			border: `${theme.palette.pending.main} solid 1px`,
		}
	},
	labelGroup: {
		margin: "6px 0"
	},
	titleLabelGroup: {
		position: "relative",
		width: "100%",
		height: "88px",
		backgroundColor: "#ffffff",
		padding: "16px",
		boxSizing: "border-box",
	},
	label: {
		color: "#636366",
		fontSize: "14px",
		lineHeight: "22px",
		cursor: "pointer",
	},
	descriptionAndMenuContainer: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "8px",
	},
	menuIconButton: {
		fontSize: "24px",
		padding: "0",
	},
	titleContainer: {
		width: "75%",
	},
	description: {
		color: "#000000",
		opacity: "0.87",
		fontSize: "16px",
		lineHeight: "24px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		cursor: "pointer",
	},
	edit: {
		textTransform: "uppercase",
		textDecoration: "underline",
		color: "#468ee5",
		fontSize: "18px",
		textAlign: "right",
		cursor: "pointer"
	},
	rulesInfoContainer: {
		position: "relative",
		padding: "16px 16px 16px 16px",
	},
	activeRow: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	switchContainer: {
		width: "100%",
		"& label": {
			marginLeft: "8px",
			width: "20px",
			height: "20px",
			"& input": {
				width: "20px",
				height: "20px",
			}
		},
		"& div": {
			color: "rgba(0,0,0,.87) !important",
		},
	},
	deviceCountContainer: {
		width: "40%",
		height: "64px",
		marginTop: "0",
		textAlign: "center",
	},
	onDevices: {
		width: "20%",
		textAlign: "center",
		color: "grey",
		fontSize: "18px",
	},
	deviceCount: {
		fontSize: "32px",
		height: "48px",
	},
	devicesLabel: {
		textAlign: "center",
		fontSize: "12px",
	},
	recentAlertRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		bottom: "12px",
		position: "absolute",
		width: "100%",
	},
	alertIcon: {
		color: "grey",
		marginRight: "6px",
	},
	noAlerts: {
		color: "grey",
	},
	alertsLink: {
		color: "#468ee5",
		textDecoration: "underline",
		cursor: "pointer"
	},
	integrationIcon: {
		height: "32px",
		borderRadius: "16px"
	},
	ruleChipContainer: {
		overflowY: "auto",
		overflowX: "hidden",
		width: "100%",
		height: "109px",
		display: "flex",
		flexWrap: "wrap",
		alignContent: "flex-end",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	chip: {
		marginRight: "8px",
		backgroundColor: theme.palette.pending.main,
		color: "#fbfafe",
		borderRadius: "16px",
		fontSize: "10px",
		marginBottom: "4px",
		marginTop: "4px",
		padding: "2px 8px 2px 8px",
	},
	chipRoot: {
		height: "16px",
	},
	chipLabel: {
		lineHeight: "12px",
		paddingLeft: 0,
		paddingRight: 0,
	},
	linkChip: {
		color: "#fbfafe",
		cursor: "pointer",
		textDecoration: "underline",
		"&:hover": {
			backgroundColor: "#0263fc",
		},
	},
	heartbeatIconContainer: {
		position: "relative"
	},
	heartbeatOverlay: {
		position: "absolute",
		left: 0,
		color: "#e0e0e0"
	},
	ggImg: {
		height: "24px",
		width: "24px"
	},
	actionListTitle: {
		marginBottom: "8px",
		padding: "11px 16px",
		borderBottom: "solid #80808073 1px",
		backgroundColor: "white",
		cursor: "unset",
		'&:hover': {
			backgroundColor: "white",
			cursor: "unset",
		},
		outline: "none",
		fontFamily: "Inter",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "1rem",
		width: "auto",
		height: "24px",
		whiteSpace: "nowrap",
		boxSizing: "content-box",
		fontWeight: 400,
		lineHeight: "1.5em"
	},
	actionMenuItem: {
		outline: "none"
	},
});

class RulesGrid extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			layout: "card",
			menus: {},
			modal: {
				open: false
			},
			ruletodelete: null
		};
		this.check_params();
		this.set_menus();
	};

	set_menus = () => {
		if (!this.props.rules) return;
		this.props.rules.forEach((rule) => {
			this.state.menus[rule._id] = null;
		});
	}

	check_params = () => {
		const qs = require('qs');
		const parsed = qs.parse(this.props.location.search.split("?")[1]);
		if (parsed.policy_id != null) {
			new Rule({_id: parsed.policy_id}, true).readFromAPI().then( (result) => {
				this.props.prepareRule(result).then( (rule) => this.openRuleTab(rule));
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
		this.props.history.replace({
			pathname: '/Policies',
			search: ''
		});
	}

	handleChangePage = (event, page) => {
		this.props.page_change({page: page + 1, per_page: this.props.page_data.per_page});
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({page: this.props.page_data.page, per_page: event.target.value});
	};

	openIntegration = (integration) => {
		this.props.tabHostProxy.addTab("integration", integration);
	};

	renderCondition = (rule, classes) => {
		switch (rule.rule_condition.type) {
			case "device_error":
				if (rule.rule_condition.conditionErrorType === "greengrass_unresponsive") {
					return (
						<Chip
							label="Monitor Greengrass"
							classes={{root: classes.chipRoot, label: classes.chipLabel}}
							className={classes.chip}
						/>
					);
				} else {
					return (
						<Chip
							label="Device Error"
							classes={{root: classes.chipRoot, label: classes.chipLabel}}
							className={classes.chip}
						/>
					);
				}
			case "heartbeat_status_changed":
				return (
					<Chip
						label="Heartbeat Status Change"
						classes={{root: classes.chipRoot, label: classes.chipLabel}}
						className={classes.chip}
					/>);
			case "status_changed":
				return (
					<Chip
						label="Status Change"
						classes={{root: classes.chipRoot, label: classes.chipLabel}}
						className={classes.chip}
					/>);
			case "true":
			case "false":
				return "";
			default:
				return (
					<Chip
						label="Property Evaluation"
						classes={{root: classes.chipRoot, label: classes.chipLabel}}
						className={classes.chip}
					/>
				);
		}
	};

	renderAction = (rule, action, then, index, classes) => {
		if (action.integration_id && action.nested_integration) {
			if (action.nested_integration.logo) {
				return (
					<Chip
						key={rule._id + "_" + then + "_" + index}
						label={action.nested_integration.name}
						classes={{root: classes.chipRoot, label: classes.chipLabel}}
						className={classes.chip + " " + classes.linkChip}
						onClick={() => this.openIntegration(action.nested_integration)}
					/>
				);
			}

		} else {
			let label = action.type.split("_rule_action")[0].replace(/_/g, " ");
			return (
				<Chip
					key={rule._id + "_" + then + "_" + index}
					label={label}
					classes={{root: classes.chipRoot, label: classes.chipLabel}}
					className={classes.chip}
				/>
			);
		}
	};

	openRuleTab = (rule) => {
		this.props.tabHostProxy.addTab("rule", rule);
	};
	
	createRule = () => {
		this.props.tabHostProxy.addTab("rule");
	};

	render_table_layout = () => {
		const { rules, page_data } = this.props;
		return (
			<TableList
				headings={heading_info}
				items={rules}
				page_data={page_data}
				perform_sort={this.props.perform_sort}
			/>
		);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState((state) => {
			state.menus[id] = element;
			return state;
		});
	}

	close_action_menu = (id) => {
		this.setState((state) => {
			state.menus[id] = null;
			return state;
		});
	};

	render_menu = (rule) => {
		const classes = this.props.classes;
		let anchorEl = this.state.menus[rule._id];
		let open = Boolean(anchorEl);
		return (
			<div style={{ visibility: (this.get_actions(rule).length === 0 ? "hidden" : "visible") }}>
				<div>
					<IconButton
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={(event) => this.open_action_menu(event, rule._id)}
						className={classes.menuIconButton}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				{this.render_action_menu(open, anchorEl, rule)}
			</div>
		);
	}

	render_action_menu = (open, anchorEl, rule) => {
		const classes = this.props.classes;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => this.close_action_menu(rule._id)}
				PaperProps={{
					style: { overflow: "visible" }
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				{this.get_actions().map((action, index) => (
					<div onClick={() => { this.executeAction(action, rule) }} key={action.label} className={classes.menuItemContainer}>
						<MenuItem className={classes.actionMenuItem}>
							<ListItemIcon>
								{action.icon}
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								{action.label}
							</Typography>
						</MenuItem>
					</div>
				))}
			</Menu>
		);
	}

	get_actions = () => {
		let possible_actions = [];
		possible_actions.push({ label: "View", action: "view", confirm: "Delete this device?", icon: <VisibilityIcon /> });
		possible_actions.push({ label: "Delete", action: "delete", confirm: "Delete this device?", icon: <DeleteForeverIcon /> });
		possible_actions.push({ label: "Copy", action: "copy", confirm: "Copy this device?", icon: <FileCopyIcon /> });
		return possible_actions;
	}

	executeAction = (action, rule) => {
		if (action.action === 'view') {
			this.close_action_menu(rule._id);
			this.openRuleTab(rule);
		} else if (action.action === 'delete') {
			this.state.ruletodelete = rule;
			this.close_action_menu(rule._id);
			this.openModal("Are you sure you want to delete this policy?", "DELETE POLICY", this.deleteRule);
		} else if (action.action === 'copy') { 
			let rule_copy = { active: rule.active,
				cloud_rule: rule.cloud_rule,
				company_id: rule.company_id,
				description: rule.description + " Copy", 
				else_actions: rule.else_actions,
				fail_count: rule.fail_count,
				last_run_status: rule.last_run_status,
				origin: rule.origin,
				pass_count: rule.pass_count,
				plain_english: rule.plain_english,
				rule_condition: rule.rule_condition,
				table_active: rule.table_active,
				table_description: rule.table_description,
				table_scheduled: rule.table_scheduled,
				then_actions: rule.then_actions,
				user_id: rule.user_id,
				_id: rule._id,
				copy: true
			 }
			this.close_action_menu(rule._id);
			this.props.tabHostProxy.addTab("rule", rule_copy);
		}

	}

	deleteRule = () => {
		new Rule({_id: this.state.ruletodelete._id}).deleteFromAPI().then((result) => {
			this.context.openSnackbar('Policy deleted.', 'success');
			this.props.tabHostProxy.closeOther(this.state.ruletodelete._id, true);
			this.props.tabHostProxy.refresh();
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
			this.closeModal();
		});
	}

	openModal = (prompt, buttonText, fxn) => {
		this.setState({modal: {
			"open": true,
			prompt: prompt,
			yesFunction: fxn,
			functionText: buttonText,
		}});
	}

	closeModal = () => {
		let newState = {modal: {open: false}};
		this.setState(newState);
	}
	
	render_card_layout = () => {
		const { classes, rules } = this.props;
		return (
			<React.Fragment>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
				{rules.map((rule, index) => (
					<div key={rule._id} className={classes.ruleCardContainer}>
						<Card className={classes.ruleCard}>
							<div className={classes.titleLabelGroup}>
								<div className={classes.descriptionAndMenuContainer}>
									<div onClick={()=>{ this.openRuleTab(rule)}} className={classes.label}>
										Description
									</div>
									{rule.view_disabled ? "" :
										this.render_menu(rule)
									}
								</div>
								<div className={classes.titleContainer}>
									<div onClick={()=>{ this.openRuleTab(rule)}} className={classes.description}>
										{rule.description}
									</div>
								</div>
							</div>
							<div className={classes.rulesInfoContainer}>
								<div className={classes.activeRow}>
									<div className={classes.switchContainer}>
										<SwitchInput
											location="start"
											initial={rule.active}
											emitChange={() => this.props.promptModalActiveChange(rule)}
											onLabel="Active"
											disabled={!Permissions.allow(["update"], "rule", rule.company_id)}
											offLabel="Active"
										/>
									</div>
								</div>
								<div className={classes.ruleChipContainer}>
									{rule.schedule ?
										<Chip
											label="Scheduled"
											classes={{root: classes.chipRoot, label: classes.chipLabel}}
											className={classes.chip}
										/>
										:
										<Chip
											label="Reactive"
											classes={{root: classes.chipRoot, label: classes.chipLabel}}
											className={classes.chip}
										/>
									}
									{this.renderCondition(rule, classes)}
									{rule.rule_condition.type === "false" ?
										rule.else_actions && rule.else_actions.map((action, index) => (
											this.renderAction(rule, action, false, index, classes)
										))
										:
										rule.then_actions && rule.then_actions.map((action, index) => (
											this.renderAction(rule, action, true, index, classes)
										))
									}
								</div>
							</div>
						</Card>
					</div>
				))}
			</React.Fragment>
		);
	}

	render() {
		const { tabHostProxy, classes, rules, page_data, filtering } = this.props;
		let buttons = undefined;
		if (Permissions.allow(["create"], "rule")) {
			buttons = [{ label: "CREATE POLICY", icon: (<AddIcon />), action: this.createRule }];
		}
		return (
			<div className={classes.container}>
				<PaginationContainer
					filtering={filtering}
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={(layout) => this.setState({layout: layout})}
					toggle_filter={this.props.show_filters}
				/>
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer}>
						{this.render_card_layout()}
					</div> :
					<div className={classes.tableItemsContainer}>
						{this.render_table_layout()}
					</div>
				}
			</div>
		)
	};
}

RulesGrid.contextType = SnackbarContext;
export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)
	(RulesGrid);