import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import DeviceHAGroup from '../../services/DataModels/DeviceHAGroup';
import { GetAll, AssignNestedModels } from '../../services/CLURDUtilities';
import Auth from '../../services/Auth';
import Loading from '../DisplayOriented/Loading';
import Card from '@material-ui/core/Card';
import LookupInput from '../Inputs/LookupInput';
import SelectInput from '../Inputs/SelectInput';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Permissions from '../../services/Permissions';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import TableList from '../Table/TableList';

//icons
import RouterIcon from '@material-ui/icons/Router';
import ErrorIcon from '@material-ui/icons/Error';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = theme => ({
	container: {
		width: "100%",
		height: "100%"
	},
	rootContainer: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		paddingTop: "30px",
	},
	prompt: {
		fontSize: "22px",
		marginBottom: "24px",
	},
	subPrompt: {
		fontSize: "18px",
		marginBottom: "24px",
		color: "grey"
	},
	lookupContainer: {
		margin: "24px 0"
	},
	selectContainer: {
		margin: "24px 0",
		marginTop: "48px"
	},
	modalWrapper: {
		padding: "24px",
		fontFamily: "Inter",
	},
	haCardContainer: {
		position: "absolute"
	},
	haCard: {
		padding: "12px"
	},
	ports: {
		color: "grey",
	},
	nodesArea: {

	},
	noNodes: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		marginTop: "24px"
	},
	infoText: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		color: "grey",
		margin: "12px"
	},
	link: {
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		},
		overflow: "hidden",
	},
	outerLinks: {
		position: "absolute",
		right: "12%",
		top: "-100%",
	},
	closerLinks: {
		right: "12%",
		top: "-10px",
		position: "absolute"
	},
	nodesContainer: {
		marginBottom: "12px",
		display: "flex",
	},
	oneNodeNoParent: {
		justifyContent: "center",
		marginTop: "30px"
	},
	lineContainer: {
		width: "100%",
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "center",
		marginBottom: "30px",
		marginTop: "-40px",
		position: "relative"
	},
	leftLineArea: {
		height: "40px",
		width: "33%",
		borderTop: "solid grey 2px",
		borderLeft: "solid grey 2px",
	},
	rightLineArea: {
		height: "40px",
		width: "33%",
		borderTop: "solid grey 2px",
		borderRight: "solid grey 2px",
	},
	shortenedLineContainer: {
		width: "33%",
	},
	maxLineContainer: {
		position: "absolute",
		top: 0,
		right: "12%",
		width: "33%"
	},
	maxLine: {
		width: "100%",
		height: "40px",
		borderTop: "solid grey 2px",
	},
	shortenedLine: {
		height: "40px",
		width: "75%",
		borderTop: "solid grey 2px"
	},
	menuContainer: {
		position: "absolute",
		right: "4px",
		top: "6px"
	},
	iconButtonOverride: {
		padding: "4px"
	},
	childContainer: {
		width: "33%",
		display: "inline-flex",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	noInlineFlex: {
		display: "block"
	},
	nodeLineContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		marginBottom: "30px"
	},
	nodeLine: {
		width: "2px",
		height: "40px",
		backgroundColor: "#706f6f"
	},
	rootNodeLine: {
		width: "2px",
		height: "100px",
		backgroundColor: "#706f6f"
	},
	cardContainer: {
		padding: "12px",
		overflow: "visible",
		position: "relative",
		minWidth: "33.33%"
	},
	childCardContainer: {
		minWidth: "75%"
	},
	selected: {
		boxShadow: "none",
		border: `solid ${theme.palette.pending.main} 2px`
	},
	editButton: {
		borderColor: theme.palette.pending.main,
		color: theme.palette.pending.main
	},
	buttonSpacer: {
		height: "48px"
	},
	attachContainer: {
		display: "flex"
	},
	attachButtonContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "200px 12px 12px 12px",
	},
	deviceButtonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		margin: "12px 0 0 0"
	},
	designationContainer: {
		top: "-30px",
		position: "absolute",
		display: "flex",
		alignItems: "center",
		color: "#706f6f",
		left: 0
	},
	designationIcon: {
		marginRight: "4px"
	},
	designationText: {
		whiteSpace: "nowrap",

	},
	statusContainer: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center"
	},
	statusDot: {
		width: "12px",
		height: "12px",
		minWidth: "12px",
		minHeight: "12px",
		maxWidth: "12px",
		maxHeight: "12px",
		borderRadius: "50%",
		display: "inline-flex",
		marginRight: "8px"
	},
	uniqueIDContainer: {
		display: "flex",
		alignItems: "center",
		color: "grey",
		margin: "12px 0 12px 0"
	},
	ethIcon: {
		width: "18px", 
		height: "18px",
		marginRight: "8px"
	},
	uidText: {
		flexWrap: "wrap",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	deviceImage: {
		maxWidth: "100%",
		maxHeight: "100px",
		justifyContent: "center",
		display: "flex"
	},
	devicePlaceholderImage: {
		height: "60px",
		width: "auto",
		color: theme.palette.primary.main
	},
	deviceTypeImage: {
		maxHeight: "60px",
		maxWidth: "100%"
	},
});

class DeviceGroup extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			node_options: null,
			ha_group: {
				controller: null,
				nodes: null,
				ha_group: null
			},
			normal_group: {
				parent: null,
				endpoints: null
			},
			loading: true,
			anchorEl: null,
			modal: {
				open: false,
				children: () => ""
			},
			show_errors: false,
			ha_node: null,
			interface: null
		};
		this.gateway_alias = Auth.currentCompany().aliases.gateway;
		this.load_group();
		this.heading_info = [
			{label: "Heartbeat", value: "table_heartbeat", field: "heartbeat_status", align: "left", disablePadding: false, sortable: true, content: "function"},
			{label: "Name", value: "table_name", field: "name", align: "left", disablePadding: false, sortable: true, content: "function"},
			{label: "Unique ID", field: "unique_id", align: "left", disablePadding: false, sortable: true},
			{label: "Location", field: "location", align: "left", disablePadding: false, sortable: true},
		];
	}

	capitalize = (word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}

	load_group = () => {
		const device = this.props.device;
		if (!device.device_ha_group_id || device.device_ha_group_id === '') {
			if (device.nested_device_type.type === "gateway") {
				this.prep_gateway();
			} else {
				this.prep_endpoint();
			}
		}
		else if (device.nested_device_ha_group) {
			const ha_group = device.nested_device_ha_group;
			if (ha_group.controller_device.device_id === device._id) {
				this.prep_controller(ha_group);
			} else {
				this.prep_node(ha_group);
			}
		} else {
			new DeviceHAGroup({_id: device.device_ha_group_id}).readFromAPI().then( (ha_group) => {
				if (ha_group.controller_device.device_id === device._id) {
					this.prep_controller(ha_group);
				} else {
					this.prep_node(ha_group);
				}
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
	}

	prep_controller = (ha_group) => {
		let device = this.props.device;
		if (ha_group.node_devices && ha_group.node_devices.length > 0) {
			let ids = ha_group.node_devices.map( ({device_id}) => device_id).join();
			this.load_devices(ids).then( (nodes) => {
				nodes.forEach( (device) => {
					device = this.prepare_table_fields(device);
				});
				this.setState({
					loading: false,
					ha_group: {
						controller: device,
						nodes: nodes,
						ha_group: ha_group
					}
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else {
			this.setState({
				loading: false,
				ha_group: {
					controller: device,
					nodes: [],
					ha_group: ha_group
				}
			});
		}
	}

	prep_node = (ha_group) => {
		let device = this.props.device;
		let ids = ha_group.node_devices.map( ({device_id}) => device_id).filter( (id) => id !== device._id);
		ids.unshift(ha_group.controller_device.device_id);
		ids = ids.join();
		this.load_devices(ids).then( (devices) => {
			let controller = devices.find( ({_id}) => _id === ha_group.controller_device.device_id);
			let nodes = devices.filter( ({_id}) => _id !== ha_group.controller_device.device_id);
			nodes.push(device);
			nodes.forEach( (device) => {
				device = this.prepare_table_fields(device);
			});
			this.setState({
				loading: false,
				ha_group: {
					controller: controller,
					nodes: nodes,
					ha_group: ha_group
				}
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	prep_gateway = () => {
		let device = this.props.device;
		if (device.nested_attached_devices) {
			AssignNestedModels("device_types", "device_type_id", device.nested_attached_devices).then( () => {
				device.nested_attached_devices.forEach( (device) => {
					device = this.prepare_table_fields(device);
				});
				this.setState({
					loading: false, 
					normal_group: {
						parent: device,
						endpoints: device.nested_attached_devices
					}
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else if (device.attached_device_ids && device.attached_device_ids.length > 0) {
			let ids = device.attached_device_ids.join();
			this.load_devices(ids).then( (endpoints) => {
				endpoints.forEach( (device) => {
					device = this.prepare_table_fields(device);
				});
				this.setState({
					normal_group: {
						parent: device,
						endpoints: endpoints
					},
					loading: false
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else {
			this.state.normal_group = {
				parent: device,
				endpoints: []
			};
			this.state.loading = false;
		}
	}

	load_siblings = (ids) => {
		let device = this.props.device;
		if (!ids || ids === '') {
			device = this.prepare_table_fields(device);
			this.state.normal_group = {
				parent: device.nested_parent_device,
				endpoints: [device]
			};
			this.state.loading = false;
		} else {
			this.load_devices(ids).then( (siblings) => {
				if (!siblings) {
					siblings = [device];
				} else {
					siblings.unshift(device)
				}
				siblings.forEach( (device) => {
					device = this.prepare_table_fields(device);
				});
				this.setState({
					normal_group: {
						parent: device.nested_parent_device,
						endpoints: siblings
					},
					loading: false
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
	}

	prep_endpoint = () => {
		let device = this.props.device;
		if (device.nested_parent_device) {
			let ids = device.nested_parent_device.attached_device_ids.filter( (id) => id !== device._id).join();
			this.load_siblings(ids);
		} else if (device.parent_device_id && device.parent_device_id !== "") {
			new Device({_id: device.parent_device_id}).readFromAPI().then( (parent) => {
				let ids = parent.attached_device_ids.filter( (id) => id !== device._id).join();
				this.load_siblings(ids);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else {
			device = this.prepare_table_fields(device);
			this.state.normal_group = {
				parent: null,
				endpoints: [device]
			};
			this.state.loading = false;
		}
	}

	load_devices = (ids) => {
		if (!ids || ids === '') {
			return Promise.resolve([]);
		}
		let body = {_id_in: ids};
		return GetAll("devices", body).then(this.assign_types);
	}

	assign_types = (devices) => {
		return AssignNestedModels("device_types", "device_type_id", devices);
	}

	render_lines = () => {
		const { classes } = this.props;
		const group = this.state.ha_group.ha_group ? this.state.ha_group.nodes : this.state.normal_group.endpoints;
		const group_term = this.state.ha_group.ha_group ? "Node" : "Endpoint";
		const is_ha = this.state.ha_group.ha_group ? true : false;
		const root = is_ha ? this.state.ha_group.controller : this.state.normal_group.parent;
		if (!root && !is_ha) {
			return "";
		}
		switch (group.length) {
			case 0:
				return "";
			case 1:
				return (
					<div className={classes.lineContainer}>
						<div className={classes.leftLineArea}>
							&nbsp;
						</div>
						<div style={{visibility: "hidden"}} className={classes.rightLineArea}>
							&nbsp;
						</div>
					</div>
				);
			case 2:
				return (
					<div className={classes.lineContainer}>
						<div className={classes.leftLineArea}>
							&nbsp;
						</div>
						<div className={classes.rightLineArea}>
							&nbsp;
						</div>
					</div>
				);
			default:
				return (
					<div className={classes.lineContainer}>
						<div className={classes.leftLineArea}>
							&nbsp;
						</div>
						<div className={classes.rightLineArea}>
							&nbsp;
						</div>
						<div className={classes.outerLinks}>
							<div onClick={this.view_all} style={{display: (group.length > 3 ? "unset" : "none")}} className={classes.link}>
								{group.length - 3} more...
							</div>
							{this.show_attach ? <div onClick={this.attach_node} className={classes.link}>
								Add {group_term}?
							</div> : ""}
						</div>
					</div>
				);
		}
	}

	prepare_table_fields = (device) => {
		let name = device.name;
		if (!device.nested_device_type || !device.nested_device_type._id) {
			device.table_name = () => (<div style={{color: "grey"}}>{name} <span style={{color: "red"}}><ErrorIcon style={{fontSize: "12px", marginRight: "4px"}}/>Device Type Unavailable</span></div>);
		} else {
			device.table_name = () => (<div onClick={() => this.props.tabHostProxy.addTab("device", device)} style={{cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}}>{name}</div>);
		}
		device.table_heartbeat = () => (this.render_table_heartbeat(device));
		return device;
	}

	render_table_heartbeat = (device) => {
		const hb_map = {
			online: this.props.theme.palette.green.main,
			offline: this.props.theme.palette.heartbeat.offline.main,
			idle: this.props.theme.palette.heartbeat.idle.main,
			never_reported: "grey"
		};
		const color = hb_map[device.heartbeat_status];
		const display = device.heartbeat_status.replace(/_/g, " ");
		return (
			<div style={{display: "flex", alignItems: "center"}}>
				<div style={{marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color}}>
					&nbsp;
				</div>
				<div style={{textTransform: "capitalize"}}>
					{display}
				</div>
			</div>
		);
	}

	view_all = () => {
		this.setState({
			modal: {
				open: true,
				children: this.render_all_nodes
			}
		});
	}

	render_all_nodes = () => {
		const classes = this.props.classes;
		const title = this.state.ha_group.ha_group ? "Nodes" : "Endpoints";
		let devices = this.state.ha_group.ha_group ? this.state.ha_group.nodes : this.state.normal_group.endpoints;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					All {title}
				</div>
				<TableList
					headings={this.heading_info} 
					items={devices}
				/>
			</div>
		);
	}

	attach_ha_node = () => {
		if (this.state.ha_node === null) {
			this.setState({show_errors: true});
			return;
		} else {
			let new_node = {
				device_id: this.state.ha_node.value,
				interface: this.state.interface
			};
			let node_devices = this.state.ha_group.ha_group.node_devices;
			node_devices.push(new_node);
			new DeviceHAGroup({_id: this.state.ha_group.ha_group._id, node_devices: node_devices}).createOrSave().then( (result) => {
				this.setState({node_options: null});
				new Device({_id: new_node.device_id}).readFromAPI().then( (device) => {
					this.close_modal();
					this.props.tabHostProxy.addTab("device", device);
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
				})			
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
	}

	attach_node = () => {
		const is_ha = this.state.ha_group.ha_group ? true : false;
		const root = is_ha ? this.state.ha_group.controller : this.state.normal_group.parent;
		if (is_ha) {
			this.open_device_node_selection();
		} else {
			this.props.attachtoParent(false, root);
		}
	}

	load_nodes = () => {
		let node_ids = null;
		let node_filters = {device_ha_group_id: "null", device_config_id_ne: "null"};
		if (this.state.ha_group.ha_group.node_devices && this.state.ha_group.ha_group.node_devices.length > 0) {
			node_ids = this.state.ha_group.ha_group.node_devices.map( ({device_id}) => device_id);
		}
		if (node_ids && node_ids !== '') {
			node_filters._id_nin = node_ids;
		}
		node_ids ? node_ids.push(this.state.ha_group.ha_group.controller_device.device_id) : node_ids = [this.state.ha_group.ha_group.controller_device.device_id];
		node_ids = node_ids.join();
		GetAll("devices", node_filters).then( (devices) => {
			AssignNestedModels("device_configs", "device_config_id", devices).then( () => {
				devices = devices.filter( (device) => device.nested_device_config != null && device.nested_device_config.connections && device.nested_device_config.connections.length > 0);
				devices = devices.map( (device) => ({value: device._id, label: device.name, whole: device}));
				this.setState({node_options: devices});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	open_device_node_selection = () => {
		this.load_nodes();
		this.setState({
			modal: {
				open: true,
				children: this.render_node_modal
			}
		});
	}

	handle_node_change = ({value}) => {
		let interface_name = null;
		if (value && value.whole) {
			interface_name = value.whole.nested_device_config.connections[0].name
		}
		this.setState({ha_node: value || null, interface: interface_name, show_errors: value ? false : true});
	}

	handle_interface_change = ({value}) => {
		this.setState({interface: value || null});
	}

	render_node_modal = () => {
		const { classes } = this.props;
		if (this.state.node_options === null) {
			return (
				<div className={classes.modalWrapper}>
					<Loading />
				</div>
			);
		}
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Select a Device to Attach to this HA Group
				</div>
				<div className={classes.subPrompt}>
					HA nodes must have a network config. Devices without configs won't appear in the dropdown. Devices with configs that have no connections also won't appear.
				</div>
				<div className={classes.lookupContainer}>
					<LookupInput
						priorState={{ suggestions: this.state.node_options, values: this.state.ha_node }}
						label="Select a Device"
						model="devices"
						single
						emitChange={this.handle_node_change}
						error={this.state.ha_node === null && this.state.show_errors}
						error_message="Please select a device."
					/>
				</div>
				{this.state.ha_node !== null ? 
					<div className={classes.selectContainer}>
						<SelectInput
							error={this.state.interface === null && this.state.show_errors}
							error_message="Please select a connection interface."
							label="Select a Connection Interface"
							emitChange={this.handle_interface_change}
							priorState={this.state.interface}
							options={this.state.ha_node.whole.nested_device_config.connections.map( (connection) => ({display: connection.name, value: connection.name}))}
						/></div> : ""}
				<div className={classes.buttonContainer}>
					<Button
						aria-label="cancel"
						color="primary" className={classes.button}
						onClick={this.close_modal}
					>
						CANCEL
					</Button>
					<Button
						aria-label="attach"
						color="primary" className={classes.button}
						onClick={() => this.attach_ha_node()}
					>
						Attach
					</Button>
				</div>
			</div>
		);
	}

	render_nodes = () => {
		const { classes } = this.props;
		const group = this.state.ha_group.ha_group ? this.state.ha_group.nodes : this.state.normal_group.endpoints;
		if (group.length === 0) {
			if (!this.show_attach) return "";
			return (
				<div className={classes.noNodes}>
					{this.render_attach_node()}
				</div>
			);
		}
		return (
			<div className={classes.nodesArea}>
				{this.render_lines()}
				{this.render_nodes_cards()}
			</div>
		);
	}

	render_attach_node = () => {
		const { classes } = this.props;
		const group_term = this.state.ha_group.ha_group ? "Node" : "Endpoint";
		if (!this.show_attach) return "";
		return (
			<div className={classes.childContainer}>
				<Card className={classes.cardContainer + " " + classes.childCardContainer +" " + classes.attachContainer}>
					<div className={classes.attachButtonContainer}>
						<Button
							className={classes.editButton}
							variant="outlined"
							onClick={this.attach_node}
						>
							ATTACH {group_term}
						</Button>
					</div>
					
				</Card>
			</div>
		);
	}

	render_attach_parent = () => {
		const { classes } = this.props;
		const group_term = this.state.ha_group.ha_group ? "Controller" : this.capitalize(this.gateway_alias);
		if (!this.show_attach) return "";
		return (
			<div className={classes.childContainer}>
				<Card className={classes.cardContainer + " " + classes.childCardContainer +" " + classes.attachContainer}>
					<div className={classes.attachButtonContainer}>
						<Button
							className={classes.editButton}
							variant="outlined"
							onClick={() => this.props.attachtoParent(true)}
						>
							SELECT {group_term}
						</Button>
					</div>
					
				</Card>
			</div>
		);
	}

	render_nodes_cards = () => {
		const { classes } = this.props;
		const is_ha = this.state.ha_group.ha_group ? true : false;
		const group = is_ha ? this.state.ha_group.nodes.slice(0, 3) : this.state.normal_group.endpoints.slice(0, 3);
		const root = is_ha ? this.state.ha_group.controller : this.state.normal_group.parent;
		return (
			<div className={classes.nodesContainer + " " + (!is_ha && !root ? classes.oneNodeNoParent : "")}>
				{group.map( (device, index) => (
					this.render_child(device, group.length === 1)
				))}
				{is_ha ?
					group.length < 3 ? this.render_attach_node() : ""
					:
					!root ? "" : group.length < 3 ? this.render_attach_node() : ""
				}
			</div>
		);
	}

	add_child = () => {

	}

	render_child = (device, single) => {
		const { classes } = this.props;
		const is_ha = this.state.ha_group.ha_group ? true : false;
		const detail_device = device._id === this.props.device._id;
		const designation =  is_ha ? (<div className={classes.designationContainer}><SettingsRemoteIcon className={classes.designationIcon}/><span className={classes.designationText}>Node Device</span></div>) : (<div className={classes.designationContainer}><SettingsRemoteIcon className={classes.designationIcon}/><span className={classes.designationText}>Endpoint Device</span></div>);
		const connection = !is_ha ? "" : this.state.ha_group.ha_group.node_devices.find( (node_device) => node_device.device_id === device._id).interface;
		return (
			<div key={device._id} className={classes.childContainer}>
				<Card className={classes.cardContainer + " " + classes.childCardContainer + " " + (detail_device ? classes.selected : "")}>
					{designation}
					{this.render_device_status(device)}
					{this.render_device_unique_id(device)}
					{this.render_connection(connection)}
					{this.render_device_image(device)}
					{this.render_manage_button(device)}
					{/* {this.render_menu()} */}
				</Card>
			</div>
		);
	}

	render_connection = (connection_name) => {
		const { classes } = this.props;
		if (!connection_name) return "";
		return (
			<div className={classes.uniqueIDContainer}>
				<SettingsEthernetIcon className={classes.ethIcon}/>
				<div className={classes.uidText}>
					{connection_name}
				</div>
			</div>	
		);
	}

	render_manage_button = (device) => {
		const { classes } = this.props;
		const detail_device = device._id === this.props.device._id;
		if (detail_device) return <div className={classes.buttonSpacer}>&nbsp;</div>;
		return (
			<div className={classes.deviceButtonContainer}>	
				<Button
					className={classes.editButton}
					variant="outlined"
					onClick={() => this.open_device(device)}
				>
					MANAGE
				</Button>
			</div>
		);
	}

	open_device = (device) => {
		this.props.tabHostProxy.addTab("device", device);
	}

	render_ha_card = () => {
		const { classes } = this.props;
		const is_ha = this.state.ha_group.ha_group ? true : false;
		const ha_group = this.state.ha_group.ha_group;
		if (!is_ha) return "";
		if (!ha_group.ports) ha_group.ports = [];
		return (
			<div className={classes.haCardContainer}>
				<Card className={classes.haCard}>
					<div className={classes.deviceTitle}>
						HA Group: {ha_group.name}
					</div>
					<div className={classes.ports}>
						Ports: {ha_group.ports.join()}
					</div>
					{/* <div className={classes.deviceButtonContainer}>
						<Button
							className={classes.editButton}
							variant="outlined"
							onClick={() => this.props.tabHostProxy.addTab("haGroup", ha_group)}
						>
							MANAGE
						</Button>
					</div> */}
				</Card>
			</div>
		);
	}

	render_root = () => {
		const { classes } = this.props;
		const is_ha = this.state.ha_group.ha_group ? true : false;
		const root = is_ha ? this.state.ha_group.controller : this.state.normal_group.parent;
		const connection = is_ha ? this.state.ha_group.ha_group.controller_device.interface : null;
		if (!root) {
			return (
				<div className={classes.rootContainer}>
					{this.render_attach_parent()}
					<div style={{marginBottom: "0"}} className={classes.nodeLineContainer}>
						<div className={classes.rootNodeLine}>
							&nbsp;
						</div>
					</div>
				</div>
			);
		}
		const detail_device = root._id === this.props.device._id;
		const group = is_ha ? this.state.ha_group.nodes.slice(0, 3) : this.state.normal_group.endpoints.slice(0, 3);
		const designation = is_ha ? (<div className={classes.designationContainer}><RouterIcon className={classes.designationIcon}/><span>Controller Device</span></div>) : (<div className={classes.designationContainer}><RouterIcon className={classes.designationIcon}/><span>{this.capitalize(this.gateway_alias) + " Device"}</span></div>);
		return (
			<div className={classes.rootContainer}>
				<Card className={classes.cardContainer + " " + (detail_device ? classes.selected : "")}>
					{designation}
					{this.render_device_status(root)}
					{this.render_device_unique_id(root)}
					{this.render_connection(connection)}
					{this.render_device_image(root)}
					{this.render_manage_button(root)}
					{/* {this.render_menu()} */}
				</Card>
				<div style={{marginBottom: "0"}} className={classes.nodeLineContainer}>
					<div className={classes.rootNodeLine}>
						&nbsp;
					</div>
				</div>
			</div>
		);
	}

	render_device_status = (device) => {
		const { classes, theme } = this.props;
		const status = device.heartbeat_status;
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		return (
			<div className={classes.statusContainer}>
				<div style={{backgroundColor: status_map[status]}} className={classes.statusDot}>
					&nbsp;
				</div>
				<div className={classes.deviceTitle}>
					{device.name}
				</div>
			</div>
		);
	}

	render_device_image = (device) => {
		const { classes } = this.props;
		let image = device.nested_device_type && device.nested_device_type.image && device.nested_device_type.image !== "" ? device.nested_device_type.image : null;
		return (
			<div>
				{image === null ?
					<div className={classes.deviceImage}>
						<DvrIcon className={classes.devicePlaceholderImage} />
					</div>
					:
					<div className={classes.deviceImage}>
						<img className={classes.deviceTypeImage} src={image}/>
					</div>
				}
			</div>
		);
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};
	
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	render_menu = () => {
		const { anchorEl } = this.state;
		const { classes } = this.props;
		const open = Boolean(anchorEl);
		return (
			<div className={classes.menuContainer}>
				<div className={classes.p}>
					<IconButton
						className={classes.iconButtonOverride}
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={this.handleClick}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
				>
					{this.quick_actions.map( (item) => (
						<MenuItem key={item.label} onClick={() => {}}>
							<ListItemText inset>
								{item.label}
							</ListItemText>
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}

	render_device_unique_id = (device) => {
		const { classes } = this.props;
		return (
			<div className={classes.uniqueIDContainer}>
				<Tooltip title="Unique ID">
					<span style={{
						color: "white", backgroundColor: "grey", borderRadius: "4px",
						width: "18px", height: "18px", fontSize: "12px",
						marginRight: "8px", display: "flex",
					}}>
						<span style={{ margin: "auto" }}>
							ID
						</span>
					</span>
				</Tooltip>
				<span className={classes.uidText}>
					{device.unique_id}
				</span>
			</div>
		);
	}

	close_modal = () => {
		this.setState({modal: {
			open: false,
			children: () => {}
		}});
	}

	render() {
		const { classes } = this.props;
		const { loading } = this.state;
		if (loading) return <Loading />;
		const is_ha = this.state.ha_group.ha_group ? true : false;
		this.show_attach = is_ha ? (Permissions.allow(["update"], "device_ha_group", this.state.ha_group.ha_group.company_id) && Permissions.allow(["update"], "device", this.props.device.company_id)) : Permissions.allow(["update"], "device", this.props.device.company_id);
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				{this.render_ha_card()}
				{this.render_root()}
				{this.render_nodes()}
			</div>
		);
	}
}

DeviceGroup.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceGroup));