import React from 'react';
import Auth from '../../services/Auth';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
	title: {
		fontFamily: "Saira,sans-serif",
		fontWeight: 600,
		color: "#334e5e",
		fontSize: "32px",
		lineHeight: "1.2",
		width: "100%",
		fontSize: "28px",
		textAlign: "center",
		paddingTop: "15%"
	},
	subtitle: {
		fontFamily: "Saira,sans-serif",
		color: "white",
		fontSize: "22px",
		textAlign: "center",
		padding: "20px"
	},
	container: {
		width: "100vw",
		height: "100vh",
		backgroundImage: "linear-gradient(#52a2b5, #32618c)",
	},
	imageWrapper: {
		width: "100%",
		textAlign: "center"
	},
	image: {
		width: "50%"
	}
});

class ErrorBoundary extends React.Component {
		constructor(props) {
			super(props);
			this.props = props;
			this.state = { hasError: false };
		}
	
		componentDidCatch(error, info) {
			let user = "unknown user";
			if (Auth.currentUser()) {
				user = Auth.currentUser().email;
			}
			let body = {
				user: user,
				ui_error: `on page: ${window.location.href}, ` + error.stack.split(")")[0] + ")"
			}
			var options = {
				method: 'POST',
				url: `/support`,
				data: JSON.stringify(body),
				json: true,
			};
			// axios(options).then( (response) => {
			// 	console.log(response);
			// }).catch( (error) => {
			// 	console.log(error);
			// });
			this.setState({ hasError: true });
		}
	
		render() {
			const { classes } = this.props;
			if (this.state.hasError) {
				return (
					<div className={classes.container}>
						<div className={classes.title}>
							Oops... We've encountered an error.
						</div>
						<div className={classes.subtitle}>
							We've collected the info and sent it to support. We'll get things fixed ASAP. Try refreshing the page, if possible.
						</div>
						<div className={classes.imageWrapper}>
							<img className={classes.image} src="https://edgeiq.io/hubfs/home-slide-1-1--green.png?noresize"/>
						</div>
					</div>
				);
			}
			return this.props.children;
		}
	}
	
export default withStyles(styles)(ErrorBoundary);
