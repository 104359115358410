import DataModel from "./DataModel";
import { AssignNestedModels, GetAll } from '../CLURDUtilities';
import { LIST, API_PROXY } from '../CLURD';
import Auth from '../Auth';
import Permissions from '../Permissions';

class DiscoveredDevice extends DataModel {
	constructor(data) {
		super('discovered_devices', data);
	}

	static loadRequirements(types) {
		return new Promise( (resolve, reject) => {
			Promise.all([AssignNestedModels("companies", "company_id", types)]).then( (result) => {
				resolve(types);
			}).catch( (error) => reject(error));
		});
	}
}

export default DiscoveredDevice;
