import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RuleConditionType from '../../services/DataModels/RuleConditionType';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SelectInput from '../Inputs/SelectInput';
import NumberInput from '../Inputs/NumberInput';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = theme => ({
    root: {
        width: "100%",
    },
    ofthefollowing: {
        margin: "20px",
        marginLeft: 0,
        fontSize: "14px",
        color: "#8e8e93",
    },
    addConditionContainer: {
        width: "100%",
    },
    conditionContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        margin: "10px 8px",
    },
    textInput: {
        display: "inline",
        width: "25%",
        margin: "-21px 2px 0 2px"
    },
    smallTextInput: {
        display: "inline",
        width: "25%",
        fontSize: "8px",
        margin: "-21px 2px 0 2px"
    },
    selectInput: {
        display: "inline",
        margin: 0,
        width: "auto",
    },
    inlineSelectInput: {
        display: "inline",
        width: "auto",
        margin: "-1px 2px 0 2px"
    },
    valueCloseIcon: {
        padding: 0
    },
    outlinedButton: {
        margin: "0 8px",
        height: "100%",
        minHeight: "unset",
    },
    commaSpan: {
        width: "30px",
        fontSize: "18px",
        textAlign: "center"
    },
    menuIcon: {
        padding: "4px",
    }
});

const emptyCondition = {
    type: "equal",
    property: "",
    value: [""],
    value_type: "",
    rule_conditions: [],
}

class RuleConditionInput extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            anchorEl: null,
            menuIndex: null,
        };
        if (this.props.conditions === null) {
            this.conditions = null;
            this.prepareConditionData();
        }
        if (this.props.condition === null) {
            this.condition = emptyCondition;
            this.preserveData();
        }
    }

    prepareConditionData = () => {
        return new RuleConditionType().listFromAPI().then((data) => {
            this.conditions = data.items;
            this.preserveData();
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    preserveData = () => {
        console.log(this.condition);
        this.props.emitChange({ condition: this.condition, conditions: this.conditions });
    };

    changeAndorAll = (event) => {
        this.condition.type = event.value;
        this.preserveData();
    };

    openMenu = (event, index) => {
        this.setState({ anchorEl: event.currentTarget, menuIndex: index });
    };

    closeMenu = () => {
        this.setState({ anchorEl: null, menuIndex: null });
    };

    localChange = (event, condition_index) => {
        let condition = null;
        if (condition_index !== null) {
            condition = this.condition.rule_conditions[condition_index];
        } else {
            condition = this.condition
        }
        if (event.field && event.field === "scenario") {
            condition.type = event.value;
            if (condition.value.length > 1 && condition.type !== "equal" && condition.type !== "not_equal") {
                condition.value = condition.value.splice(1);
            }
            if (condition.type === "value_changed") {
                condition.value = [];
            } else if (condition.value.length === 0) {
                condition.value = [""];
            }
            if (condition.type === "moving_average") {
                condition.value_type = "absolute";
                condition.value = [0];
            } else {
                condition.value_type = "";
            }
        } else if (event.id && event.id.startsWith("cold_start")) { // cold start checkbox
            let checked = event.checked;
            condition.cold_start_trigger = checked;
        } else {
            let { value, input } = function () {
                return {
                    value: this.target.value,
                    input: this.target.id,
                };
            }.bind(event)();
            if (input.indexOf("_") !== -1) {
                condition.value[parseInt(input.split("_")[1], 10)] = value;
            } else {
                condition.property = value;
            }
        }
        this.preserveData();
    }

    addValue = () => {
        let condition_index = this.state.menuIndex;
        let condition = null;
        if (condition_index !== null) {
            condition = this.condition.rule_conditions[condition_index];
        } else {
            condition = this.condition
        }
        condition.value.push('');
        this.closeMenu();
        this.preserveData();
    }

    removeValue = (index, condition_index) => {
        let condition = null;
        if (condition_index !== null) {
            condition = this.condition.rule_conditions[condition_index];
        } else {
            condition = this.condition
        }
        condition.value.splice(index, 1);
        this.preserveData();
    }

    addCondition = () => {
        if (this.condition.type !== "and" && this.condition.type !== "or") {
            let old_condition = Object.assign({}, this.condition);
            this.condition = {};
            this.condition.type = "and";
            this.condition.rule_conditions = [old_condition];
        }
        this.condition.rule_conditions.push({
            type: "equal",
            property: "",
            value: [""],
            rule_conditions: []
        });
        this.preserveData();
    }

    removeCondition = () => {
        let condition_index = this.state.menuIndex;
        this.condition.rule_conditions.splice(condition_index, 1);
        if (this.condition.rule_conditions.length === 1) {
            this.condition = this.condition.rule_conditions[0];
        }
        this.closeMenu();
        this.preserveData();
    }

    render() {
        const { condition, conditions, classes } = this.props;
        this.condition = condition || emptyCondition;
        this.conditions = conditions ? conditions : [];
        if (this.props.conditions === null) {
            this.conditions = null;
            this.prepareConditionData();
        }
        if (this.props.condition === null) {
            this.condition = emptyCondition;
            this.preserveData();
        }
        //todo add animation or something for when the conditions are loading
        return (
            <React.Fragment>
                {(this.conditions === null || this.conditions.length === 0) ?
                    "loading conditions"
                    :
                    <React.Fragment>
                        {(this.condition.type !== "and" && this.condition.type !== "or") ?
                            this.renderCondition(this.condition, null, classes)
                            :
                            <React.Fragment>
                                <SelectInput
                                    disabled={this.props.disabled}
                                    basic={true}
                                    className={classes.selectInput}
                                    emitChange={this.changeAndorAll}
                                    priorState={this.condition.type}
                                    options={[{ display: "ALL", value: 'and' }, { display: "ANY", value: 'or' }]}
                                />
                                <span className={classes.ofthefollowing}>
                                    of the following conditions are true:
                                </span>
                                {this.condition.rule_conditions.map((condition, index) => (
                                    this.renderCondition(condition, index, classes)
                                ))}
                            </React.Fragment>
                        }
                        {this.props.disabled ? "" : <div className={classes.addConditionContainer}>
                            <Button className={classes.outlinedButton} onClick={this.addCondition} variant="outlined" color="primary" >
                                <AddIcon />
                                Add Condition
                            </Button>
                        </div>}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    handleValueType = (input, condition_index) => {
        let condition = null;
        if (condition_index !== null) {
            condition = this.condition.rule_conditions[condition_index];
        } else {
            condition = this.condition
        }
        if (input.field === "value") {
            condition[input.field] = [input.value];
        } else {
            condition[input.field] = input.value;
        }
        this.preserveData();
    };

    renderCondition = (condition, index, classes) => {
        if (condition.type === "in") {
            condition.type = "equal";
        } else if (condition.type === "not_in") {
            condition.type = "not_equal";
        } else if (condition.type === "value_changed") {
            condition.value = [];
        }
        let showColdStart = false;
        if (condition.type === "value_changed" || condition.type === "moving_average") {
            showColdStart = true;
        }

        return (
            <span className={classes.conditionContainer} key={"condition_" + index}>
                <TextField
                    disabled={this.props.disabled}
                    className={classes.textInput + " " + classes.root}
                    id="property"
                    fullWidth={true}
                    label="Property"
                    name="Property"
                    type="text"
                    margin="none"
                    value={condition.property}
                    onChange={(input) => this.localChange(input, index)}
                />
                <SelectInput
                    disabled={this.props.disabled}
                    id="scenario"
                    label=""
                    field="scenario"
                    className={classes.inlineSelectInput}
                    emitChange={(input) => this.localChange(input, index)}
                    priorState={condition.type}
                    options={this.formatConditions()}
                    basic={true}
                />
                {condition.type === "moving_average" ?
                    <React.Fragment>
                        <SelectInput
                            disabled={this.props.disabled}
                            label=""
                            className={classes.inlineSelectInput}
                            field="value_type"
                            key={"condition_" + index + "value_type"}
                            basic={true}
                            emitChange={(input) => this.handleValueType(input, index)}
                            priorState={condition.value_type}
                            options={[{ display: "an absolute value of", value: "absolute" }, { display: "a percentage of", value: "percent" }]}
                        />
                        <NumberInput
                            disabled={this.props.disabled}
                            rootClass={classes.textInput}
                            label="Value"
                            field="value"
                            emitChange={(input) => this.handleValueType(input, index)}
                            variant="standard"
                            key={"condition_" + index + "value_type_value"}
                            priorState={condition.value[0]}
                        />
                    </React.Fragment>
                    :
                    condition.value.map((val, value_index) => (
                        <TextField
                            disabled={this.props.disabled}
                            className={classes.textInput}
                            id={"value_" + value_index}
                            label="Value"
                            name="Value"
                            key={"value_" + value_index}
                            type="text"
                            margin="none"
                            value={val}
                            onChange={(input) => this.localChange(input, index, value_index)}
                            InputProps={value_index !== 0 && !this.props.disabled ? {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={classes.valueCloseIcon}
                                            aria-label="Remove value"
                                            onClick={() => this.removeValue(value_index, index)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            } : null}
                        />
                    ))
                }
                {showColdStart && <FormControlLabel
                    control={
                        <Checkbox
                            disabled={this.props.disabled}
                            id="cold_start_trigger"
                            size="small"
                            value="cold_start_trigger"
                            onChange={(event) => this.localChange(event.target, index)}
                            checked={condition.cold_start_trigger || false}
                        />
                    }
                    className={classes.formControlLabel}
                    classes={{ label: classes.smallTextInput }}
                    label="Cold Start Trigger"
                    key={"cold_start_trigger_label_" + index}
                />
                }
                {this.props.disabled ? "" : this.renderMenu(condition, index, classes)}
                {(this.condition.rule_conditions.length > 0 && index !== this.condition.rule_conditions.length - 1) ?
                    <span className={classes.commaSpan}>,</span>
                    : ""}
            </span>
        )
    }

    renderMenuList = () => {
        /*
        !!!IMPORTANT NOTE WHEN EDITING/READING!!!
        when the condition's menu is opened, a state event is triggered to change the dom and show the menu list, this dom
        change will force a re-render of ALL of the conditions, and the index that is passed into each of them uses the
        same reference, and so when the menu opens (asynchronously!) the index will be the last index in the rule_conditions array.
        This means that the index cannot be trusted when passed in OTHER than via the state that IS set correctly.
        */
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        let condition = null;
        if (this.condition.rule_conditions.length === 0) {
            condition = this.condition;
        } else {
            if (this.state.menuIndex === null) {
                return "";
            } else {
                condition = this.condition.rule_conditions[this.state.menuIndex];
            }
        }
        return (
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.closeMenu}
            >
                {(condition.type === "equal" || condition.type === "not_equal") ?
                    <MenuItem key="add_value" onClick={() => this.addValue()}>
                        <ListItemText>
                            Add value
                        </ListItemText>
                    </MenuItem>
                    : ""}
                {this.condition.rule_conditions.length > 1 ?
                    <MenuItem key="remove_condition" onClick={() => this.removeCondition()}>
                        <ListItemText>
                            Remove this condition
                        </ListItemText>
                    </MenuItem>
                    : ""}
            </Menu>
        );
    }

    renderMenu = (condition, index, classes) => {
        if (this.condition.rule_conditions.length === 1 && condition.type !== "equal" && condition.type !== "not_equal") {
            return "";
        }
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div>
                <div>
                    <IconButton
                        className={classes.menuIcon}
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => this.openMenu(event, index)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>
                {this.renderMenuList()}
            </div>
        );
    }

    formatConditions = () => {
        if (this.conditions === null) return [];
        let condition_options = [];
        Object.entries(this.conditions).map((conditionKeyValuePair) => {
            let condition_type = conditionKeyValuePair[0];
            if (condition_type !== "in" &&
                condition_type !== "not_in" &&
                condition_type !== "heartbeat_status_changed" &&
                condition_type !== "device_error" &&
                condition_type !== "status_changed" &&
                condition_type !== "true" &&
                condition_type !== "false" &&
                condition_type !== "and" &&
                condition_type !== "device_created" &&
                condition_type !== "device_deleted" &&
                condition_type !== "device_location_transition" &&
                condition_type !== "or") {
                condition_options.push({ display: this.getConditionLabel(condition_type), value: condition_type });
            }
        });
        return condition_options;
    }

    getConditionLabel = (condition) => {
        switch (condition) {
            case "equal":
                return "equals";
            case "not_equal":
                return "does not equal";
            case "greater_than":
                return "is greater than";
            case "greater_than_equal":
                return "is greater than or equal to";
            case "less_than":
                return "is less than";
            case "less_than_equal":
                return "is less than or equal to";
            case "moving_average":
                return "changes in value by";
            case "value_changed":
                return "changes in value";
            case "contains":
                return "contains";
        }
    }
}

RuleConditionInput.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(RuleConditionInput));
