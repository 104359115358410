import { UploadSoftwareUpdateFiles, AssignNestedModels } from '../CLURDUtilities';
import DataModel from "./DataModel";
import Permissions from '../Permissions';

class Translator extends DataModel {
	constructor(data = BLANK) {
		super("translators", data);
	}

	static loadRequirements(translators) {
		return new Promise((resolve, reject) => {
			Promise.all([AssignNestedModels("companies", "company_id", translators)]).then((result) => {
				resolve(translators);
			}).catch((error) => reject(error));
		});
	}

	static types(type) {
		//removed, won't be supported at this time.
		//"csv": "CSV",
		//"anko": "Anko",
		const map = {
			"javascript": {
				display: "JavaScript",
				editable: true,
				default_value:
					`var Translator={
	isValid: function(e) {
		//evaluate validity and return true or false
		return true;
	},
	getMfrId: function(e) {
		//extract the device's id and return it
		return e.split(" ")[0];
	},
	translate: function(e) {
		//perform translation, return JSON
		var parts = e.split(",");
		var ret = {
			"device_id": deviceId,
			"device_datetime": ISODateString(new Date()),
			"payload": {
				"device_name": parts[1],
				"data_type": parts[3],
				"ph_value": Number(parts[4]),
				"orp_value": Number(parts[5]),
				"temperature": {
					"value": Number(parts[6].slice(0,5)),
					"unit": parts[6].slice(5,6)
				}
			},
			"raw_data": e
		};
		return JSON.stringify(ret);
	}
};`
			},
			"template": {
				display: "Template",
				editable: true,
				default_value:
					`{
		"device_id": "[YOUR_DEVICE_ID]",
		"payload": {
			//your payload here
		}
}`
			},
			"altair": {
				display: "SSI Smart Label Translator",
				editable: false,
				default_value: null
			},
			"gs": {
				display: "Globalstar Device Translator",
				editable: false,
				default_value: null
			},
			"inmarsat": {
				display: "Inmarsat Device Translator",
				editable: false,
				default_value: null
			},
			"geoforce": {
				display: "Geoforce Device Translator",
				editable: false,
				default_value: null
			},
			"orbcomm": {
				display: "Orbcomm Device Translator",
				editable: false,
				default_value: null
			},
			"assetlink": {
				display: "Assetlink Device Translator",
				editable: false,
				default_value: null
			}
		};
		if (Boolean(type)) {
			if (!map[type]) return null;
			return map[type]
		} else {
			return map;
		}
	}


	static cmd_types(type) {

		const map = {

			"template_cmd": {
				display: "Template",
				editable: true,
				default_value:
					`{
    // formatted data that your device expects
}`
			}
		};
		if (Boolean(type)) {
			if (!map[type]) return null;
			return map[type]
		} else {
			return map;
		}
	}

}
const BLANK = {
	name: "",
	script: "",
	reboot: false,
	files: []
};

export default Translator;
