import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import TableList from '../../Table/TableList';
import { GetAll } from '../../../services/CLURDUtilities';
import Auth from '../../../services/Auth';
import Config from '../../../services/DataModels/Config';
import Device from '../../../services/DataModels/Device';
import CreateDeviceForm from '../../DeviceSpecific/CreateDeviceForm';
import CreateConfigForm from '../../ConfigsSpecific/CreateConfigForm';

//inputs
import LookupInput from '../../Inputs/LookupInput';
import SelectInput from '../../Inputs/SelectInput';

//mui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

//icons
import HelpIcon from '@material-ui/icons/Help';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import SettingsEthernetIcon  from '@material-ui/icons/SettingsEthernet';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import InfoIcon  from '@material-ui/icons/Info';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		title: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
			display: "flex",
			alignItems: "center"
		},
		addIcon: {
			height: "28px",
			width: "28px",
			color: theme.palette.greyIcon.main,
			margin: "0 0 0 12px",
			padding: 0,
			cursor: "pointer"
		},
		lookupNodeWrapper: {
			width: "80%",
			display: "flex",
			flexWrap: "nowrap"
		},
		inputIcon: {
			color: theme.palette.greyIcon.main,
			margin: "8px",
			paddingBottom: "20px"
		},
		addNodeIcon: {
			margin: "auto",
			marginLeft: "12px",
			padding: 0
		},
		nodeRenderArea: {
			marginLeft: "8px",
			marginTop: "12px"
		},
		nodeAreaTitle: {
			display: "flex",
			alignItems: "center",
			color: "#8e8e93",
			marginBottom: "24px",
			fontSize: "14px"
		},
		tableListContainer: {
		},
		nodeIcon: {
			marginRight: "8px"
		},
		noNodes: {
			color: "#8e8e93",
			fontSize: "14px",
			marginLeft: "32px"
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap"
		},
		configInput: {
			marginTop: "32px"
		},
		configLinks: {
			marginTop: "-18px",
			marginBottom: "32px"
		},
		link: {
			marginLeft: "32px",
		},
		actionIcon: {
			height: "28px",
			width: "28px",
			color: theme.palette.greyIcon.main,
			padding: 0,
			cursor: "pointer"
		},
		editing: {
			color: theme.palette.pending.main
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		editNodeButtonContainer: {
			marginTop: "12px",
			display: "flex",
			justifyContent: "flex-end"
		},
		button: {
			marginLeft: "12px",
		},
		iconsContainer: {
			whiteSpace: "nowrap"
		},
		editNodeArea: {
			marginTop: "42px",
			borderBottom: "solid lightgrey 2px",
			paddingBottom: "24px" 
		},
		editNodeAreaTitle: {
			marginLeft: "12px",
			fontSize: "18px"
		},
		borderButton: {
			marginLeft: "12px",
		},
		saveNodeButton: {
			marginLeft: "12px",
		},
		buttonIcon: {
			marginRight: "4px"
		},
		invalidConfig: {
			marginLeft: "40px",
			marginTop: "-18px",
			color: theme.palette.red.main,
			fontSize: "14px",
		},
		invalidNodes: {
			marginLeft: "40px",
			marginTop: "24px",
			color: theme.palette.red.main,
			fontSize: "14px",
		},
		subTitle: {
			fontSize: "14px",
			color: "#8e8e93",
			display: "flex",
			alignItems: "center",
			marginBottom: "16px",
		},
		infoIcon: {
			marginRight: "6px"
		},
		stepContainer: {
			height: "505px",
			overflowY: "auto",
		},
	});
};

class Step3 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			node_interface_options: null,
			edit_node_options: null,
			node_device: null,
			node_devices: [],
			edit_node: null,
			edit_node_config: null,
			edit_node_interface: null,
			loading: false,
			show_errors: false,
			newly_created_nodes: [],
			creating_config: false,
			creating_node: false
		};
		this.heading_info = [
			{label: "Actions", value: "device.table_actions", nested_field: true, field: "actions", align: "center", disablePadding: false, sortable: false, content: "function"},
			{label: "Name", value: "device.table_name", nested_field: true, field: "name", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "Unique ID", value: "device.unique_id", field: "unique_id", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Config", value: "device.table_config", nested_field: true, field: "config", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "Interface", value: "interface", field: "interface", nested_field: false, align: "left", disablePadding: false, sortable: false}
		];
	}

	render_ha_group_help = () => {
		return {
			title: "HA Group Nodes",
			content: "Each node must have a network configuration, so these devices must also be of type " + Auth.currentCompany().aliases.gateway + ". Select or create new devices to act as nodes. Select or create network configurations for each node and then choose the appropriate connection interface.",
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
	}

	get_node_options = () => {
		const node_ids = this.state.node_devices.map( (device) => device.device._id);
		let options = this.props.gateway_options.concat(this.state.newly_created_nodes);
		return options.filter( (option) => {
			return node_ids.indexOf(option.value) === -1 && option.value !== this.props.controller_device.value
		});
	}
	
	render_table_config = (config) => {
		if (!config) return null;
		return config.name;
	}

	render_table_heartbeat = (device) => {
		const hb_map = {
			online: this.props.theme.palette.green.main,
			offline: this.props.theme.palette.heartbeat.offline.main,
			idle: this.props.theme.palette.heartbeat.idle.main,
			never_reported: "grey"
		};
		const color = hb_map[device.heartbeat_status];
		const display = device.name;
		return (
			<div style={{display: "flex", marginLeft: "-14px", alignItems: "center"}}>
				<div style={{marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color}}>
					&nbsp;
				</div>
				<div>
					{display}
				</div>
			</div>
		);
	}

	load_node_configs = (type_id, config_id) => {
		let params = {device_type_id: type_id};
		GetAll("device_configs", params).then( (configs) => {
			configs = configs.map( (config) => ({value: config._id, display: config.name, whole: config}));
			let config = configs.find( (config) => (config.value === config_id));
			configs.push({display: "Select a Network Config", value: ''});
			this.setState( (state) => {
				state.edit_node_options = configs || [];
				state.edit_node_config = config ? config.value : '';
				if (config) {
					state.node_interface_options = this.props.getInterfaceOptions(config.whole);
					state.edit_node_interface = state.node_interface_options ? state.node_interface_options[0].value : null
				}
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_node_config = (node) => {
		const classes = this.props.classes;
		if (node.config === null) return "";
		return (
			<div>
				{node.config.name}
			</div>
		);
	}

	edit_node = (node) => {
		this.setState( (state) => {
			state.node_devices.forEach( (option) => {
				if (option.device._id === node.device._id) {
					option.device.table_highlighted = true;
				} else {
					option.device.table_highlighted = false;
				}
			});
			state.show_errors = false;
			state.edit_node = node;
			return state;
		});
		this.load_node_configs(node.device.device_type_id, node.device.device_config_id);
	}

	remove_node = (node) => {
		this.setState( (state) => {
			state.node_devices.splice(state.node_devices.findIndex( (option) => option.device._id === node.device._id), 1);
			if (node.device.table_highlighted) {
				state.edit_node = null;
				node.device.table_highlighted = false;
			}
			return state;
		});
	}

	table_formatting = (node) => {
		node.device.table_actions = () => this.render_table_actions(node);
		node.device.table_name = () => this.render_table_heartbeat(node.device);
		node.device.table_config = () => this.render_table_config(node.config)
		return node;
	}

	render_table_actions = (node) => {
		const classes = this.props.classes;
		return (
			<div className={classes.iconsContainer}>
				<Tooltip title="Edit Node">
					<IconButton onClick={() => node.device.table_highlighted ? this.cancel_edit() : this.edit_node(node)} className={classes.actionIcon + " " +(node.device.table_highlighted ? classes.editing : "")} aria-label="edit">
						<EditIcon/>
					</IconButton>
				</Tooltip>
				<Tooltip title="Remove Node">
					<IconButton onClick={() => this.remove_node(node)} className={classes.actionIcon} aria-label="remove">
						<DeleteIcon/>
					</IconButton>
				</Tooltip>
			</div>
		);
	}

	add_node = () => {
		if (!this.state.node_device) {
			return;
		}
		let config = this.state.node_device.whole.nested_device_config ? this.state.node_device.whole.nested_device_config : null;
		this.setState( (state) => {
			let node = this.table_formatting({device: state.node_device.whole, interface: this.state.node_device.whole.interface || null, config: config});
			state.node_devices.push(node);
			return state;
		}, () => {
			this.setState({node_device: null});
		});
	}

	handle_node_change = (input) => {
		!input.value ? this.setState({node_device: null}) : this.setState({node_device: input.value});
	}

	handle_node_config_change = (input) => {
		let config_id = input.value;
		let config = null;
		let interface_options = null;
		if (config_id && config_id !== '') {
			config = this.state.edit_node_options.find( ({value}) => value === config_id).whole;
			interface_options = this.props.getInterfaceOptions(config);
		}
		this.setState( (state) => {
			state.edit_node_config = config_id;
			state.node_interface_options = interface_options;
			state.edit_node_interface = state.node_interface_options ? state.node_interface_options[0].value : null
			return state;
		});
	}

	handle_node_interface_change = (input) => {
		this.setState( (state) => {
			state.edit_node.interface = input.value;
			return state;
		});
	}

	create_config = () => {
		this.setState({creating_config: true});
	}

	render_node_select_and_create = () => {
		const classes = this.props.classes;
		return (
			<React.Fragment>
				<div className={classes.lookupNodeWrapper}>
					<Tooltip className={classes.inputIcon} title="Node">
						<DvrIcon />
					</Tooltip>
					<LookupInput
						priorState={{ suggestions: this.get_node_options(), values: this.state.node_device }}
						label="Select a Node Device"
						field="node_device"
						single
						model="devices"
						emitChange={this.handle_node_change}
					/>
					<IconButton onClick={this.add_node} className={classes.addNodeIcon} aria-label="Add">
						<AddCircleIcon/>
					</IconButton>
				</div>
				<Button
					color="primary"
					onClick={this.create_node}
					className={classes.link}
				>
					Create a new node device
				</Button>
			</React.Fragment>
		);
	}

	render_node_table = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.nodeRenderArea}>
				<div className={classes.nodeAreaTitle}>
					<SettingsRemoteIcon className={classes.nodeIcon}/>
					Nodes
				</div>
				{this.state.node_devices.length === 0 ? <span className={classes.noNodes}>No Nodes Added</span> :
					<div className={classes.tableListContainer}>
						<TableList
							headings={this.heading_info} 
							items={this.state.node_devices}
						/>
					</div>
				}
			</div>	
		);
	}

	render_edit_node_area = () => {
		const classes = this.props.classes;
		if (!this.state.edit_node) return;
		if (this.state.edit_node && !this.state.edit_node_options) return <Loading />;
		return (
			<div className={classes.editNodeArea}>
				<div className={classes.editNodeAreaTitle}>
					Editing {this.state.edit_node.device.name}
				</div>
				<div className={classes.inputContainer + " " + classes.configInput}>
					<Tooltip className={classes.inputIcon} title="Network Config">
						<SettingsIcon />
					</Tooltip>
					<SelectInput
						field="node_config"
						emitChange={this.handle_node_config_change}
						label="Network Config*"
						priorState={this.state.edit_node_config}
						options={this.state.edit_node_options}
					/>
				</div>
				<div className={classes.configLinks}>
					<Button
						color="primary"
						onClick={this.create_config}
						className={classes.link}
					>
						Create a new network config
					</Button>
				</div>
				{this.state.edit_node_config && this.state.edit_node_config !== '' && this.state.node_interface_options ?
					<div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon} title="Connection Interface">
							<SettingsEthernetIcon />
						</Tooltip>
						<SelectInput
							field="node_interface"
							emitChange={this.handle_node_interface_change}
							label="Connection Interface*"
							priorState={this.state.edit_node_interface}
							options={this.state.node_interface_options}
						/>
					</div>
					:
						this.state.edit_node_config && this.state.edit_node_config !== '' && !this.state.node_interface_options ?
							<div className={classes.invalidConfig}>This network config does not have any of the necessary connection interfaces. Please edit this config or assign a different network config to this device.</div>
							:
							""
				}
				{this.state.show_errors && (!this.state.edit_node_config || this.state.edit_node_config === '') ? <div className={classes.invalidConfig}>Please select a network config.</div> : ""}
				<div className={classes.editNodeButtonContainer}>
					<Button
						className={classes.borderButton}
						color="primary"
						onClick={() => this.cancel_edit()}
					>
						discard changes
					</Button>
					<Button
						className={classes.saveNodeButton}
						variant="contained"
						color="primary"
						size="large"
						onClick={() => this.save_edit_node()}
					>
						<SaveIcon className={classes.buttonIcon}/>
						SAVE
					</Button>
				</div>
			</div>
		);
	}

	save_edit_node = () => {
		if (!this.state.edit_node_config || this.state.edit_node_config === ''|| !this.state.edit_node_interface || this.state.edit_node_interface === '') {
			this.setState({show_errors: true});
			return;
		}
		new Device().setData({_id: this.state.edit_node.device._id, device_config_id: this.state.edit_node_config}).createOrSave().then( (result) => {
			this.setState( (state) => {
				state.node_devices.forEach( (node) => {
					if (node.device._id === this.state.edit_node.device._id) {
						node.device.device_config_id = this.state.edit_node_config;
						node.config = this.state.edit_node_options.find( (config) => (config.value === this.state.edit_node_config)).whole;
						node.interface = this.state.edit_node_interface;
						node.device.table_highlighted = false;
					}
				});
				return state;
			});
			this.cancel_edit();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.cancel_edit();
		});
	}

	cancel_edit = () => {
		this.setState( (state) => {
			state.edit_node = null;
			state.show_errors = false;
			state.edit_node_interface = null;
			state.edit_node_config = '';
			state.node_devices.forEach( (node) => node.device.table_highlighted = false);
			return state;
		});
	}

	no_nodes = () => {
		return this.state.node_devices.length === 0;
	}

	missing_reqs = () => {
		let missing_reqs = false;
		this.state.node_devices.forEach( (node) => {
			if (!node.config || !node.interface) {
				missing_reqs = true;
			}
		});
		return missing_reqs;
	}

	next = () => {
		if (this.no_nodes()) {
			this.setState({show_errors: true});
			return;
		}
		if (this.missing_reqs()) {
			this.setState({show_errors: true});
			return;
		}
		this.props.next({node_devices: this.state.node_devices});
	}

	create_node = () => {
		this.setState({creating_node: true});
	}

	on_config_create = (config) => {
		this.setState( (state) => {
			state.loading = true;
			return state;
		});
		new Config(config).createOrSave().then( (result) => {
			this.setState( (state) => {
				state.loading = false;
				state.creating_config = false;
			});
			this.load_node_configs(this.state.edit_node.device_type_id, result._id);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_node_create = (node) => {
		this.setState({loading: true});
		new Device(node).createOrSave().then( (result) => {
			let created_node = result;
			created_node.nested_device_config = node.nested_device_config.whole;
			created_node.interface = node.interface;
			this.setState( (state) => {
				let new_option = {value: created_node._id, label: created_node.name + ": " + created_node.unique_id, display: created_node.name + ": " + created_node.unique_id, whole: created_node};
				state.newly_created_nodes.push(new_option)
				state.node_device = new_option;
				state.loading = false;
				state.creating_node = false;
			}, this.add_node);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_button_area = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.buttonContainer}>
				<Button
					color="primary"
					onClick={this.props.back}
					className={classes.button}
				>
					back
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={this.next}
					className={classes.button}
				>
					next
				</Button>
			</div>
		);
	}
	
	render() {
		const classes = this.props.classes;
		if (!this.props.gateway_options || !this.props.controller_device || this.state.loading) return <Loading />;
		if (this.state.creating_config) return (<CreateConfigForm getInterfaceOptions={this.props.getInterfaceOptions} onCreate={this.on_config_create} cancel={() => this.setState({creating_config: false})} company={this.props.company} type={this.state.edit_node.device.nested_device_type} />);
		if (this.state.creating_node) return (<CreateDeviceForm getInterfaceOptions={this.props.getInterfaceOptions} onCreate={this.handle_node_create} cancel={() => this.setState({creating_node: false})} types={this.props.types} integration={this.props.integration} company={this.props.company} />);
		return (
			<React.Fragment>
				<div className={classes.stepContainer}>
					<div className={classes.title}>
						Assign Node Devices
						<IconButton onClick={() => this.context.openHelp(this.render_ha_group_help())} className={classes.addIcon} aria-label="help">
							<HelpIcon/>
						</IconButton>
					</div>
					<div className={classes.subTitle}>
						<InfoIcon className={classes.infoIcon}/>
						Select a controller device. Suggestions below will only include devices of type {Auth.currentCompany().aliases.gateway} and devices that have the same Greengrass integration or no integrations at all.
					</div>
					{this.state.loading ? <Loading /> :
						<React.Fragment>
							{this.render_node_select_and_create()}
							{this.render_node_table()}
							{this.render_edit_node_area()}
						</React.Fragment>
					}
					{this.no_nodes() && this.state.show_errors ? <div className={classes.invalidNodes}>Please add nodes to this HA Group.</div> : ""}
					{this.missing_reqs() && this.state.show_errors ? <div className={classes.invalidNodes}>Ensure each node has both a network config and interface defined. Click the edit icon to make the necessary changes.</div> : ""}
				</div>
				{this.render_button_area()}
			</React.Fragment>
		);
	}
}
Step3.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(Step3));
