import React from 'react';
import { withStyles, withTheme} from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import User from "../../../services/DataModels/User";
import FilterSidebar from '../../ActionOriented/FilterSidebar';
import UsersList from '../../UserSpecific/UsersList';
import Auth from '../../../services/Auth';
import Card from '@material-ui/core/Card';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Permissions from '../../../services/Permissions';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	},
	filterBg: {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		zIndex: 9999
	},
	filterContainer: {
		position: "relative",
		zIndex: 3,
		width: "800px",
		backgroundColor: "white",
		padding: "72px 48px 35px 48px",
		overflowY: "auto",
		maxHeight: "750px",
		boxSizing: "border-box",
	},
	link: {
		color: "#1153b6",
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": "#1153b6",
			textDecorationColor: "#1153b6",
			cursor: "pointer",
		}
	}
};

class UsersTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			users: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			show_filters: false
		};
		this.set_filters();
		this.load_users();
		this.props.tabHostProxy.setRootRefresh(this.load_users);
	}

	set_filters = () => {
		this.filters = [
			{
				label: 'First Name',
				type: 'text',
				field: 'first_name_like',
			},
			{
				label: 'Last Name',
				type: 'text',
				field: "last_name_like",
			},
			{
				label: 'Email/Username',
				type: 'text',
				field: "email_like",
			},
			{
				label: 'Role',
				type: 'lookup',
				placeholder: "Filter by role",
				field: "user_type_id",
				model: "user_types",
				valueField: "_id",
				labelField: "name"
			},
		];
		if (Auth.currentUser().company_ids.length > 1) {
			this.filters.push({
				label: 'Account',
				type: 'lookup',
				placeholder: "Filter by account",
				field: "company_id",
				model: "companies",
				valueField: "_id",
				labelField: "name"
			});
		}
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_users();
		});
	}

	load_users = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.users !== null) this.setState({users: null});
		new User()
			.listFromAPI(params)
			.then(this.load_requirements)
			.then( ([users, total]) => {
				users.forEach( (user) => {
					user.ui_user_type = user.ui_user_type ? user.ui_user_type : Permissions.allow(["read"], "user_type", user.company_id) ? "Role Not Found" : "Insufficient Permissions";
					user.table_name = () => (<div onClick={() => this.props.tabHostProxy.addTab("user", user)} className={classes.link}>{user.ui_name}</div>);
				});	
				this.setState( (state) => {
					state.users = users;
					state.page_data = {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: total
					};
					return state;
				});
			})
			.catch( (error) => {
				this.context.openSnackbar(error, 'error');
			});
	}

	load_requirements = ({items, total}) => {
		return Promise.all([User.loadRequirements(items), total]);
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_users();
		});
	}

	prepare_filter = (params) => {
		let body = {};
		if (params) {
			if (params["First Name"]) {
				body.first_name_like = params["First Name"];
			}
			if (params["Last Name"]) {
				body.last_name_like = params["Last Name"];
			}
			if (params["Email/Username"]) {
				body.email_like = params["Email/Username"];
			}
			if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
				var companyIDs = params["Account"].values.map((option) => {
					return option.value;
				});
				body.company_id_in = companyIDs.join(",");
			}
			if (params["Role"] && params["Role"].values && params["Role"].values.length > 0) {
				var roleIDs = params["Role"].values.map((option) => {
					return option.value;
				});
				body.user_type_id_in = roleIDs.join(",");
			}
		}
		return body;
	}

	apply_filters = (filters) => {
		this.setState({filter_data: this.prepare_filter(filters)}, () => {
			this.load_users();
		});
	}

	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}

	render() {
		let { tabHostProxy, classes } = this.props;
		const { users, page_data } = this.state;
		return (
			<div className={classes.container}>
				<div style={{visibility: this.state.show_filters ? "visible" : "hidden"}} onClick={() => this.setState({show_filters: false})} className={classes.filterBg}>
					<Card
						onClick={(event) => event.stopPropagation()}
						className={classes.filterContainer}
					>
						<FilterSidebar
							filters={this.filters}
							triggerFilter={this.apply_filters}
							closeFilters={() => this.setState({show_filters: false})}
						/>
					</Card>
				</div>
				<UsersList
					show_filters={() => this.setState({show_filters: true})}
					filtering={this.is_filtering()}
					page_change={this.page_change}
					users={users}
					perform_sort={this.perform_sort}
					page_data={page_data}
					tabHostProxy={tabHostProxy}
				/>
			</div>
		);
	}
}

UsersTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(UsersTab));
