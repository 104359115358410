import { CREATE, LIST, UPDATE, READ, DELETE, UPLOAD_FILE } from '../CLURD';
import Auth from '../Auth';

class DataModel {

	constructor(endpoint, data = {}, idField = "_id") {
		if (endpoint == null) {
			throw Error(`Can't construct data model ${this.constructor.name} with no endpoint`);
		}
		this.endpoint = endpoint;
		this.data = data;
		this.idField = idField;
	}

	id() {
		return this.data[this.idField];
	}

	isNew() {
		var currentId = this.id();
		return currentId == null || currentId === "";
	}

	setData(newData) {
		this.data = newData;
		return this;
	}

	// Use this method to get a copy of the initial data
	// passed to this data model.
	// Suitable for use in an edit form
	editableCopy() {
		return JSON.parse(JSON.stringify(this.data));
	}

	//======================
	// API Helpers
	//======================

	// GETs a list of objects from this.endpoint.
	// Returns a Promise.
	// Does not store anything in this instance; meant only as a helper
	listFromAPI(params = {}) {
		return LIST(this.endpoint, params, Auth.token());
	}
	
	// Reads from this.endpoint using the current id.
	// Returns a Promise.
	// Throws an error if no id exists.
	// Does not store anything in this instance; meant only as a helper
	readFromAPI() {
		if (this.isNew()) {
			return new Promise((resolve, reject) => {
				throw Error(`Can't read ${this.constructor.name} from ${this.endpoint} with no ID.`);
			});
		} else {
			return READ(this.endpoint, this.id(), Auth.token());
		}
	}

	// Deletes the object from this.endpoint using the current id.
	// Returns a Promise.
	// Throws an error if no id exists.
	// Does not store anything in this instance; meant only as a helper
	deleteFromAPI() {
		if (this.isNew()) {
			return new Promise((resolve, reject) => {
				throw Error("Can't delete model from API with no ID.", this);
			});
		} else {
			return DELETE(this.endpoint, this.id(), Auth.token());
		}
	}

	// If this.data's id is null, POSTs this.data to this.endpoint.
	// If this.data's id is not null, PUTs this.data to this.endpoint/this.data.id.
	// Returns a Promise.
	// Does not store anything in this instance; meant only as a helper
	create()				{ return this.createOrSave() }
	save()					{ return this.createOrSave() }
	saveOrCreate()	{ return this.createOrSave() }
	createOrSave() {
		if (this.isNew()) {
			return CREATE(this.endpoint, this.data, Auth.token());
		} else {
			return UPDATE(this.endpoint, this.id(), this.data, Auth.token());
		}
	}

	// `file` is expected to be a File - https://www.w3.org/TR/FileAPI/#dfn-file
	uploadFiles(fileName, file) {
		return UPLOAD_FILE(this.endpoint, fileName, file, Auth.token());
	}

}

export default DataModel;
