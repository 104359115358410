import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SystemIDIcon from '@material-ui/icons/Code';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-monokai";

import Auth from '../../services/Auth';
import PollableAttribute from '../../services/DataModels/PollableAttribute';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import { convert_objects_indexes_for_ui } from './helpers';
import './PollableAttributesForm.css';
import MIBForm from './MIBForm';
import PollableAttributeNotificationsForm from './PollableAttributeNotifications';

const styles = theme => ({
    formContainer: {
        overflowY: "hidden",
        width: "100%",
        marginRight: "12px",
        fontFamily: "Inter",
        color: "grey",
        fontSize: "18px"
    },
    pollableAttributeFormButtonArea: {
        margin: "32px 0 8px auto",
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: "8px",
    },
    inputsContainer: {
        display: "flex",
        padding: "20px 0",
        justifyContent: "space-between",
    },
    inputContainer: {
        margin: "12px 0 12px 0",
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
        flexGrow: 1,
        "&:not(:last-child)": {
            marginRight: "20px",
        },
    },
    deleteButton: {
        marginLeft: "6px",
    },
    leftContainer: {
        fontFamily: "Inter",
        color: "grey",
        fontSize: "12px",
        flexWrap: "nowrap",
        margin: "20px",
    },
    iconInputContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        marginTop: "16px",
        color: "grey"
    },
    infoText: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        marginLeft: "6px",
    },
    info: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    attributesRow: {
        display: "flex",
        margin: "2px 0 5px 0"
    },
    attributeLabelWrapper: {
        display: "flex",
        alignItems: "center"
    },
    attributeLabel: {
        color: "#8e8e93",
        textTransform: "uppercase",
        margin: "10px",
        fontSize: "14px",
    },
    attributeInput: {
        marginRight: "8px",
    },
    closeAttributeIcon: {
        padding: "4px",
        margin: "auto",
    },
    addAttributeButton: {
        margin: "8px 8px",
    },
    tableContainer: {
        marginTop: "12px"
    },
    paginationContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: "4px 0"
    },
    pageArrowsContainer: {
        marginLeft: "12px"
    },
    paginationText: {
        fontSize: "14px",
        color: "#636366",
    },
    pageButton: {
        fontSize: "16px",
        color: "#8e8e93",
    },
    error: {
        color: theme.palette.red.main,
    },
    mibLabel: {
        color: '#8e8e93',
        fontSize: '16px',
        textTransform: 'uppercase',
        marginBottom: '12px',
    },
    validationError: {
        color: "red",
        fontSize: "14px",
        marginTop: "12px"
    },
    sectionContainer: {
        marginBottom: "30px",
    },
    mibObjectsContainer: {
        paddingTop: "20px",
        position: "relative",
    },
    mibLabel: {
        margin: "10px 0",
    },
    mibButton: {
        margin: "10px 0",
    },
    mibObjectContainer: {
        padding: "20px 0 20px 30px",
        position: "relative",
    },
    mibObjectRow: {
        display: "flex",
        marginBottom: "10px",
        position: "relative",
    },
    mibObjectData: {
        display: "flex",
    },
    mibLine: {
        width: "2px",
        position: "absolute",
        left: "0",
        height: "calc(100% - 110px)",
    },
    mibSubLine: {
        left: "30px",
        height: "calc(100% - 220px)",
    },
    mibMainInput: {
        width: "450px",
    },
    mibInput: {
        width: "250px",
        marginRight: "10px",
    },
    mibHorizontalLine: {
        position: "absolute",
        height: "2px",
        width: "20px",
        left: "-30px",
        top: "27px",
    },
    removeMibIcon: {
        padding: "4px",
        margin: "auto 5px",
    },
    formControlLabel: {
        margin: 0,
        marginRight: "30px",
        display: "flex",
        overflow: "hidden"
    },
    notifDataContainer: {
        display: "flex",
        flexDirection: "column",
    },
    notifData: {
        display: "flex",
        marginBottom: "10px",
    },
    descInput: {
        marginRight: "10px",
    },
    notifLine: {
        height: "calc(100% - 80px)",
    },
    notifSubline: {
        height: "calc(100% - 290px)",
    },
});

class PollableAttributesForm extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        let editable_pollable_attribute = {
            ...this.props.dataModel.editableCopy(),
            attributes: this.props.dataModel.editableCopy().attributes || [],
            type: this.props.dataModel.editableCopy().type || this.props.pollableattributesTypes[0].value,
            mib: this.props.dataModel.editableCopy().mib || null,
            notifications: this.props.dataModel.editableCopy().notifications || null,
        }
        if (editable_pollable_attribute.mib && editable_pollable_attribute.mib.objects) {
            convert_objects_indexes_for_ui(editable_pollable_attribute.mib.objects);
        }
        this.state = {
            show_errors: false,
            modal: {
                open: false,
                children: () => ""
            },
            editable_pollable_attribute: editable_pollable_attribute,
            validation: PollableAttribute.validate(editable_pollable_attribute),
            defaults: [],
            page: 1,
            per_page: 20,
            total: null,
            attributes_shown: [],
        };

        if (!this.state.editable_pollable_attribute.company_id || this.state.editable_pollable_attribute.company_id === '') {
            this.state.editable_pollable_attribute.company_id = Auth.currentCompany()._id;
        }
        if (this.state.editable_pollable_attribute.defaults) {
            this.state.defaults = this.defaultToArray(this.state.editable_pollable_attribute.defaults)
        }
    }

    componentDidMount() {
        this.paginate_attributes();
    }

    paginate_attributes = () => {
        let { page, per_page, editable_pollable_attribute } = this.state;
        const offset = (page - 1) * per_page;
        const attributes_shown = editable_pollable_attribute.attributes.slice(offset, offset + per_page);
        const total = editable_pollable_attribute.attributes.length;
        this.setState((state) => {
            state.attributes_shown = attributes_shown;
            state.total = total;
            return state;
        });
    }

    can_save = () => {
        let show_errors = false;
        const editable_pollable_attribute = this.state.editable_pollable_attribute;
        const new_validation = PollableAttribute.validate(editable_pollable_attribute);
        show_errors = !new_validation.valid;
        this.setState({ show_errors: show_errors, validation: new_validation });
        return !show_errors;
    }

    save_pollable_attribute = () => {
        if (this.can_save()) {
            const save = PollableAttribute.convertForSave(this.state.editable_pollable_attribute, this.state.defaults)
            console.log(save);
            this.props.onSave(save);
        }
    }

    handleFieldChange = (input) => {
        let new_pollable_attribute = this.state.editable_pollable_attribute;
        new_pollable_attribute[input.field] = input.value;
        let new_validation = PollableAttribute.validate(new_pollable_attribute);
        this.setState({ editable_pollable_attribute: new_pollable_attribute, validation: new_validation });
    }

    buttonsForPollableAttribute = () => {
        let buttons = [];
        const pollable_attribute = this.state.editable_pollable_attribute;
        const validation = this.state.validation;
        const classes = this.props.classes;

        if (pollable_attribute._id != null) {
            buttons.push(
                <Button
                    disabled={this.delete_disabled}
                    color="primary"
                    key="deleteButton"
                    className={classes.deleteButton}
                    onClick={this.openDeleteConfirmModal}
                >
                    DELETE
                </Button>
            );
        }

        if (this.props.cancel) {
            buttons.push(
                <Button
                    key="cancelbutton"
                    color="primary"
                    className={classes.button}
                    onClick={this.props.cancel}
                >
                    CANCEL
                </Button>
            );
        }

        buttons.push(
            <React.Fragment key="save-button-fragment">
                <Button
                    key="saveButton"
                    disabled={validation.editable == false || this.editing_disabled}
                    className={classes.button}
                    onClick={this.save_pollable_attribute}
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    {this.state.editable_pollable_attribute._id ? "SAVE" : "CREATE"} POLLABLE ATTRIBUTE
                </Button>
            </React.Fragment>
        );

        return (
            <div className={classes.pollableAttributeFormButtonArea}>
                {buttons}
            </div>
        )
    }

    openDeleteConfirmModal = () => {
        this.setState({
            modal: {
                open: true,
                prompt: "Are you sure you want to delete this pollable attribute?",
                yesFunction: this.props.onDelete,
                functionText: "DELETE POLLABLE ATTRIBUTE",
                children: () => ""
            }
        });
    }

    closeDeleteConfirmModel = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    validationMessagesFor = (field) => {
        let messages = this.state.validation[field];
        if (messages != null && messages.length > 0) {
            return messages.map((msg) => msg += " ");
        } else {
            return null;
        }
    }

    addAttribute = (editable_pollable_attribute) => {
        const { total, per_page } = this.state;
        const totalPages = Math.ceil((total + 1) / per_page);
        this.setState({
            page: totalPages,
            editable_pollable_attribute: {
                ...editable_pollable_attribute,
                attributes: [...editable_pollable_attribute.attributes, { name: "", value: "", data_type: "" }],
            }
        }, this.paginate_attributes);
    }

    removeAttribute = (attribute_index, editable_pollable_attribute) => {
        const { total, per_page, page } = this.state;
        let currentPage = page;
        const totalPages = Math.ceil((total - 1) / per_page);
        editable_pollable_attribute.attributes.splice(attribute_index, 1);
        if (totalPages < page) {
            currentPage = totalPages;
        }
        this.setState({
            page: currentPage,
            editable_pollable_attribute: editable_pollable_attribute
        }, this.paginate_attributes);
    }

    addDefault = (defaults) => {
        defaults.push({ name: "", value: "" });
        this.setState({ defaults: defaults })
    }

    removeDefault = (default_index, defaults) => {
        defaults.splice(default_index, 1);
        this.setState({ defaults: defaults });
    }

    actionAttributesChange = (input, attribute_index, attributes, editable_pollable_attribute) => {
        if (input.label === "Value") {
            attributes[attribute_index].value = input.value;
        } else if (input.label === "Name") {
            attributes[attribute_index].name = input.value;
        } if (input.label === "Data Type") {
            attributes[attribute_index].data_type = input.value;
        }
        let duplicate = attributes.find((attribute, index) => ((attribute.value === input.value) && (index !== attribute_index)));
        if (duplicate) {
            attributes.error = "Each attribute must have a unique value.";
        } else {
            attributes.error = false;
        }
        let empty = attributes.find((attribute, index) => attribute.name === '');
        if (empty) {
            attributes.error = attributes.error === false ? "Attribute name cannot be empty." : attributes.error + " Attribute name cannot be empty.";
        }
        let empty_value = attributes.find((attribute, index) => attribute.value === '');
        if (empty_value) {
            attributes.error = attributes.error === false ? "Attribute value cannot be empty." : attributes.error + " Attribute value cannot be empty.";
        }
        this.setState({
            editable_pollable_attribute: {
                ...editable_pollable_attribute,
                attributes
            }
        });
    }

    actionDefaultsChange = (input, default_index, defaults) => {
        if (input.label === "Value") {
            defaults[default_index].value = input.value;
        }
        if (input.label === "Name") {
            defaults[default_index].name = input.value;
        }
        this.setState({ defaults: defaults })
    }

    buildAttributes = () => {
        const { classes } = this.props;
        const { editable_pollable_attribute } = this.state;
        const { attributes } = editable_pollable_attribute;
        return (
            <div>
                <div className={classes.attributeLabelWrapper}>
                    <div className={classes.attributeLabel}>Attributes</div>
                    {attributes.error !== undefined && attributes.error !== false ?
                        <Tooltip className={classes.actionIcon + " " + classes.error} title={attributes.error}>
                            <IconButton color="inherit">
                                <ErrorIcon />
                            </IconButton>
                        </Tooltip>
                        : ""}
                    {this.props.disabled ? "" : <Button className={classes.addAttributeButton} onClick={() => this.addAttribute(editable_pollable_attribute)} variant="outlined" color="primary" >
                        <AddIcon />
                        ATTRIBUTE
                    </Button>}
                </div>
                {this.render_table()}
            </div>
        );
    }

    update_pollable_attribute = (new_pollable_attribute) => {
        this.setState({ editable_pollable_attribute: new_pollable_attribute });  
    };

    render_table = () => {
        const { attributes_shown, per_page, page } = this.state;
        const { classes } = this.props;
        let start = 0;
        let end = 0;
        if (attributes_shown.length !== 0) {
            start = (per_page * (page - 1)) + 1;
            end = attributes_shown.length + start - 1;
        }
        return (
            <div className={classes.tableContainer}>
                {attributes_shown ?
                    <React.Fragment>
                        <div>
                            {attributes_shown.map((attribute, attribute_index) => {
                                const index = (start - 1) + attribute_index;
                                return (
                                    this.render_row_attribute(attribute, index)
                                )
                            })}
                        </div>
                        {this.render_pagination_info(start, end)}
                    </React.Fragment>
                    :
                    this.render_table_loading()
                }

            </div>
        );
    }

    render_pagination_info = (start, end) => {
        const { total } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.paginationContainer}>
                <div className={classes.paginationText}>
                    {start} - {end} of {total}
                </div>
                <div className={classes.pageArrowsContainer}>
                    <IconButton disabled={start <= 1} className={classes.pageButton} onClick={() => this.page_change(-1)} aria-label="page_left">
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton disabled={end === total} className={classes.pageButton} onClick={() => this.page_change(1)} aria-label="page_right">
                        <NavigateNextIcon />
                    </IconButton>
                </div>
            </div>
        );
    }

    page_change = (difference) => {
        this.setState((state) => {
            state.page += difference;
            state.attributes_shown = [];
            return state;
        }, this.paginate_attributes);
    }

    render_row_attribute = (attribute, attribute_index) => {
        const { classes, pollableattributesDataTypes } = this.props;
        const { editable_pollable_attribute } = this.state;
        const { attributes } = editable_pollable_attribute;
        let key = attribute_index;
        return (
            <div key={key + "_row"} className={classes.attributesRow}>
                <div className={classes.textWrapper}>
                    <TextInput
                        disabled={this.props.disabled}
                        className={classes.attributeInput}
                        label="Name"
                        name={key + "_name"}
                        type="text"
                        margin="none"
                        priorState={attribute.name}
                        emitChange={(input) => this.actionAttributesChange(input, attribute_index, attributes, editable_pollable_attribute)}
                    />
                </div>
                <div className={classes.textWrapper}>
                    <TextInput
                        disabled={this.props.disabled}
                        className={classes.attributeInput}
                        label="Value"
                        name={key + "_value"}
                        type="text"
                        margin="none"
                        priorState={attribute.value}
                        emitChange={(input) => this.actionAttributesChange(input, attribute_index, attributes, editable_pollable_attribute)}
                    />
                </div>
                <div className={classes.textWrapper}>
                    <SelectInput
                        disabled={this.props.disabled}
                        emitChange={(input) => this.actionAttributesChange(input, attribute_index, attributes, editable_pollable_attribute)}
                        priorState={attribute.data_type}
                        label="Data Type"
                        name={key + "_data_type"}
                        value={attribute.data_type}
                        error={attributes.error ? true : false}
                        error_message={this.validationMessagesFor('data_type')}
                        options={pollableattributesDataTypes}
                    />
                </div>
                {attribute_index > 0 ?
                    <Tooltip className={classes.closeAttributeIcon} title="Remove Attribute">
                        <IconButton root={{ root: classes.closeAttributeIcon }} onClick={() => this.removeAttribute(attribute_index, editable_pollable_attribute)} color="inherit">
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    : ''
                }
            </div>
        )
    }

    render_table_loading = () => {
        return "";
    }

    defaultToArray = (defaults) => {
        return Object.entries(defaults).map((item) => {
            return {
                name: item[0],
                value: item[1]
            }
        })
    }

    buildDefaults = () => {
        const { classes } = this.props;
        const { editable_pollable_attribute, show_errors, defaults } = this.state;
        return (
            <div>
                <div className={classes.attributeLabelWrapper}>
                    <div className={classes.attributeLabel}>Defaults</div>
                    {this.props.disabled ? "" : <Button className={classes.addAttributeButton} onClick={() => this.addDefault(this.state.defaults)} variant="outlined" color="primary" >
                        <AddIcon />
                        DEFAULT
                    </Button>}
                </div>
                {
                    (defaults.map((item, item_index) => {
                        let key = item_index;
                        return (
                            <div key={key} className={classes.attributesRow}>
                                <div className={classes.textWrapper}>
                                    <TextInput
                                        disabled={this.props.disabled}
                                        className={classes.attributeInput}
                                        label="Name"
                                        field="name"
                                        name={key + "_name"}
                                        type="text"
                                        margin="none"
                                        priorState={item.name}
                                        emitChange={(input) => this.actionDefaultsChange(input, item_index, defaults)}
                                    />
                                </div>
                                <div className={classes.textWrapper}>
                                    <TextInput
                                        disabled={this.props.disabled}
                                        className={classes.attributeInput}
                                        label="Value"
                                        name={key + "_value"}
                                        type="text"
                                        margin="none"
                                        priorState={item.value}
                                        emitChange={(input) => this.actionDefaultsChange(input, item_index, defaults)}
                                    />
                                </div>
                                {item_index > 0 ?
                                    <Tooltip className={classes.closeAttributeIcon} title="Remove Default">
                                        <IconButton root={{ root: classes.closeAttributeIcon }} onClick={() => this.removeDefault(item_index, defaults)} color="inherit">
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                    : ''
                                }
                            </div>
                        )
                    }))
                }
            </div>
        );
    }

    render() {
        const { classes, pollableattributesTypes } = this.props;
        const { modal, validation, editable_pollable_attribute, show_errors } = this.state;
        return (
            <div id="pollable-attribute-edit-form" className={classes.formContainer}>
                {!this.props.dataModel.isNew() ?
                    <div className={classes.leftContainer}>
                        <div className={classes.iconInputContainer}>
                            <Tooltip key="System ID" title="System ID">
                                <SystemIDIcon className={classes.inputIcon} />
                            </Tooltip>
                            <span className={classes.infoText}>
                                <span className={classes.info}>{this.props.dataModel.data._id}</span>
                            </span>
                        </div>
                    </div>
                    : ""}
                <div className={classes.inputsContainer}>
                    <div className={classes.inputContainer}>
                        <TextInput
                            margin="none"
                            error={show_errors && this.validationMessagesFor('name') !== null}
                            error_message={this.validationMessagesFor('name')}
                            disabled={validation.editable == false || this.editing_disabled}
                            emitChange={this.handleFieldChange}
                            priorState={editable_pollable_attribute.name}
                            label={"Name"}
                            field={"name"}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <SelectInput
                            disabled={validation.editable == false || this.editing_disabled}
                            emitChange={this.handleFieldChange}
                            priorState={editable_pollable_attribute.type}
                            field={"type"}
                            label="Type"
                            value={editable_pollable_attribute.type}
                            error={show_errors && this.validationMessagesFor('type') !== null}
                            error_message={this.validationMessagesFor('type')}
                            options={pollableattributesTypes}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextInput
                            margin="none"
                            disabled={validation.editable == false || this.editing_disabled}
                            emitChange={this.handleFieldChange}
                            priorState={editable_pollable_attribute.interval}
                            label={"Interval"}
                            field={"interval"}
                            error={show_errors && this.validationMessagesFor('interval') !== null}
                            error_message={this.validationMessagesFor('interval')}
                        />
                    </div>
                </div>
                <div className={classes.inputContainer}>{this.buildDefaults()}</div>
                <div className={classes.inputContainer}>{this.buildAttributes()}</div>
                {this.validationMessagesFor("pollable_attribute")}
                <MIBForm
                    updateState={this.update_pollable_attribute}
                    {...this.props}
                    {...this.state}
                />
                <div className={classes.validationError}>{this.validationMessagesFor("mib")}</div>
                <PollableAttributeNotificationsForm
                    updateState={this.update_pollable_attribute}
                    {...this.props}
                    {...this.state}
                />
                <div className={classes.validationError}>{this.validationMessagesFor("notifications")}</div>
                {this.buttonsForPollableAttribute()}

                <SimpleModalWrapped info={modal} closeModal={this.closeDeleteConfirmModel}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
            </div>
        )
    }
}

PollableAttributesForm.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(PollableAttributesForm));
