import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CommandList from '../../CommandSpecific/CommandList';
import Loading from '../../DisplayOriented/Loading';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';

//services
import Auth from '../../../services/Auth';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import { AssignNestedModels, GetAll } from '../../../services/CLURDUtilities';
import DeviceType from '../../../services/DataModels/DeviceType';
import Translator from '../../../services/DataModels/Translator';
import Command from '../../../services/DataModels/Command';
import DataModel from '../../../services/DataModels/DataModel';


class CommandTab extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            items: null,
            page_data: {
                page: 1,
                per_page: 12,
                page_meta: true,
                total: null
            },
            model: "commands",
        };
        this.set_filters();
        this.load();
        this.props.tabHostProxy.setRootRefresh(this.load);
    }


    load = () => {
        if (this.state.items !== null) this.setState({ items: null });
        if (this.state.model === "commands") {
            this.load_commands();
        } else {
            this.load_translators();
        }
    }

    set_filters = () => {
        this.filters = [
            {
                label: 'Device Type Name',
                type: 'text',
                placeholder: "Filter by device name",
                field: "name",
            },
            {
                label: 'Make',
                type: 'text',
                placeholder: "Filter by make",
                field: "make",
            },
            {
                label: 'Model',
                type: 'text',
                placeholder: "Filter by model",
                field: "model",
            }
        ];
        if (Auth.currentUser().company_ids.length > 1 && Auth.currentRole().abilities.device_type.read === "self_and_all_children") {
            this.filters.push({
                label: 'Account',
                type: 'lookup',
                placeholder: "Filter by account",
                field: "company_id",
                model: "companies",
                valueField: "_id",
                labelField: "name"
            });
        }
    }

    model_change = (new_tab) => {
        this.setState((state) => {
            state.items = null;
            state.model = new_tab.label === "Commands" ? "commands" : "translators";
            return state;
        }, this.load);
    }

    load_commands = () => {
        const classes = this.props.classes;
        let params = Object.assign(this.state.page_data);
        delete params.total;
        new Command().listFromAPI(params).then((result) => {
            let total = result.total;
            let commands = result.items;
            Command.loadRequirements(commands).then(() => {
                this.setState((state) => {
                    state.model = "commands";
                    state.items = commands;
                    state.page_data = {
                        page: params.page,
                        per_page: params.per_page,
                        page_meta: true,
                        total: total
                    };
                    return state;
                });
            }).catch((error) => {
                this.context.openSnackbar(error, 'error');
            });
        }).catch((error) => {
            this.context.openSnackbar(error, 'error');
        });
    }

    load_translators = () => {
        let params = Object.assign(this.state.page_data, this.state.packages_filter_data);
        delete params.total;
        params.type_like = '_cmd';
        new Translator().listFromAPI(params).then((result) => {
            let total = result.total;
            let translators = result.items;
            Translator.loadRequirements(translators).then(() => {
                this.setState(() => {
                    return {
                        model: "translators",
                        items: translators,
                        page_data: {
                            page: params.page,
                            per_page: params.per_page,
                            page_meta: true,
                            total: total
                        }
                    };
                });
            }).catch((error) => {
                this.context.openSnackbar(error, 'error');
            });
        }).catch((error) => {
            this.context.openSnackbar(error, 'error');
        });
    }

    page_change = (params) => {
        this.setState((state) => {
            state.page_data.page = params.page;
            state.page_data.per_page = params.per_page;
            return state;
        }, this.load);
    }

    prepare_filter = (params) => {
        //todo handle for both scenarios
        let body = {};
        // if (params) {
        // 	if (params["Device Type Name"]) {
        // 		body.name_like = params["Device Type Name"];
        // 	}
        // 	if (params["Make"]) {
        // 		body.manufacturer_like = params["Make"];
        // 	}
        // 	if (params["Model"]) {
        // 		body.model_like = params["Model"];
        // 	}
        // 	if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
        // 		var companyIDs = params["Account"].values.map((option) => {
        // 			return option.value;
        // 		});
        // 		body.company_id_in = companyIDs.join(",");
        // 	}
        // }
        return body;
    }

    filter_change = (filters) => {
        this.setState((state) => {
            state.packages_filter_data = Object.assign(state.packages_filter_data, filters);
            return state;
        }, this.load)
    }

    render() {
        const { tabHostProxy, classes } = this.props;
        const { model, items, page_data } = this.state;
        return (
            <div className={classes.container}>
                <CommandList
                    page_change={this.page_change}
                    filter_change={this.filter_change}
                    page_data={page_data}
                    items={items}
                    model={model}
                    tabHostProxy={tabHostProxy}
                    modelChange={this.model_change}
                />
            </div>
        );
    }
}

const styles = (theme) => {
    return ({
        container: {
            display: "flex",
            width: "100%",
        },
    });
};



CommandTab.contextType = SnackbarContext;

export default compose(
    withRouter,
    withStyles(styles),
)(CommandTab);