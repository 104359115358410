import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import styled from "styled-components";
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import FolderIcon from '@material-ui/icons/FolderOutlined';

import EditSelf from '../UserSpecific/EditSelf';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
//Pages
import ActionHubPage from '../../pages/ActionHubPage';
import AlertsPage from '../../pages/AlertsPage';
import OverviewPage from '../../pages/OverviewPage';
import TransfersPage from '../../pages/TransfersPage';
import ReportsPage from '../../pages/ReportsPage';
import MessagesPage from '../../pages/MessagesPage';
import RulesPage from '../../pages/RulesPage';
import SoftwarePage from '../../pages/SoftwarePage';
import IngestPage from '../../pages/IngestPage';
import CommandPage from '../../pages/CommandPage';
import IntegrationsPage from '../../pages/IntegrationsPage';
import TypesPage from '../../pages/TypesPage';
import UsersPage from '../../pages/UsersPage';
import ConfigsPage from '../../pages/ConfigsPage';
import HAGroupsPage from '../../pages/HAGroupsPage';
import RolesPage from '../../pages/RolesPage';
import AccountsPage from '../../pages/AccountsPage';
import DevicesPage from '../../pages/DevicesPage';
import DiscoveredDevicesPage from '../../pages/DiscoveredDevicesPage';
import CreateCompanyPage from '../../pages/Onboarding/CreateCompanyPage';
import CreateCustomersPage from '../../pages/Onboarding/CreateCustomersPage';
import PollableAttributesPage from '../../pages/PollableAttributesPage';

//mui
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Tabs
import SimpleNewTabs from './SimpleNewTabs';
import MenuItems from './MenuItems';

import Auth from '../../services/Auth';
import PortalConfiguration from '../../services/PortalConfiguration';
import Loading from '../DisplayOriented/Loading';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 240;
const minDrawerWidth = 54;
const appBarHeight = 64;

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    anchor: {
        color: '#636366',
    },
    appBar: {
        position: 'fixed',
        zIndex: 1300,
        height: appBarHeight,
        backgroundColor: '#f5f5f7',
        boxShadow: 'none',
        color: '#636366',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        transition: "margin .5s, width .5s",
        borderLeft: "1px solid #e3e3e3",
    },
    appBarShrunk: {
        marginLeft: minDrawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${minDrawerWidth}px)`,
        },
    },
    appBarActionContainer: {
        display: 'flex',
    },
    infoIconBg: {
        position: "absolute",
        width: "16px",
        height: "16px",
        background: "white",
        borderRadius: "50%",
    },
    infoIcon: {
        color: '#636366',
        position: "relative",
        zIndex: 1
    },
    appBarHeader: {
        height: appBarHeight,
    },
    appBarHeaderIconImage: {
        margin: "0 auto",
        maxHeight: "50px",
        maxWidth: "40px",
    },
    appBarHeaderImage: {
        maxWidth: "200px",
        maxHeight: "30px",
    },
    shrunkHeader: {
        padding: "0",
        paddingLeft: "4px"
    },
    profileDefault: {
        width: "1.25rem",
        height: "1.25rem",
        maxHeight: "1.25rem",
        maxWidth: "1.25rem",
        borderRadius: "50%",
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        minHeight: "40px",
    },
    noTabsToolbar: {
        height: "56px"
    },
    drawer: {
        position: 'fixed',
        height: '100vh',
        zIndex: '1200',
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },
    expandIcon: {
        padding: "4px"
    },
    hoverContainer: {
        padding: "12px",
        fontFamily: "Inter",
        boxShadow: "0 3px 6px 0 rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.23)"
    },
    shrinkToggleContainer: {
        position: "absolute",
        bottom: "5px",
        zIndex: 5,
        right: "5px",
        backgroundColor: "white"
    },
    basicDrawer: {
        overflow: "hidden",
        height: '100vh',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        border: "none",
        transition: "width .5s"
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: "hidden",
    },
    shrunk: {
        width: minDrawerWidth,
        overflow: "hidden",
    },
    content: {
        flexGrow: 1,
        marginLeft: 0,
        overflow: "hidden",
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth,
            maxWidth: "calc(100vw - 241px)",
        },
        height: 'calc(100vh - 48px)',
        padding: theme.spacing.unit * 3,
        transition: "margin .5s, width .5s"
    },
    contentShrunk: {
        [theme.breakpoints.up('md')]: {
            marginLeft: minDrawerWidth,
            maxWidth: `calc(100vw - ${minDrawerWidth}px)`,
        },
    },
    arrow: {
        fontSize: "18px",
        marginLeft: "12px"
    },
    popper: {
        zIndex: 1,
        maxWidth: "33vw",
        minWidth: "33vw",
        position: "relative",
    },
    popperClose: {
        position: "absolute",
        top: "12px",
        right: "12px",
        width: "18px",
        height: "18px",
        cursor: "pointer"
    },
    helpTitle: {
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "12px"
    },
    helpMessage: {
        color: "#0000009c",
        marginBottom: "12px",
        fontSize: "14px"
    }
});

class Layout extends React.Component {
    state = {
        shrunk: false,
        mobileOpen: false,
        menuAnchorEl: null,
        helpAnchorEl: null,
        mobileMoreAnchorEl: null,
        user: null,
        modal: {
            open: false,
            children: () => ""
        },
        arrowRef: null
    };

    openEditSelfModal = (payload, classes) => {
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: (classes) => (
                    <EditSelf update_self={() => Auth.reloadUser()} close={this.closeModal} />
                )
            }
        });
    }

    closeModal = () => {
        this.setState({
            modal: {
                open: false, children: () => ""
            }
        });
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    logoutRedirect = () => {
        this.props.history.push('/login');
    };

    setTitle = () => {
        let path = this.props.location.pathname;
        path = path.toLowerCase().substring(1);
        let portalTitle = Auth.currentPortalTitle()
        switch (path) {
            case "":
            case "dashboard":
            case "messages":
            case "alerts":
            case "transfers":
            case "reports":
                return "Management";
            case "devices":
            case "discovered_devices":
            case "software":
            case "device_types":
            case "configs":
                return "DeviceOps";
            case "ingestors":
            case "commands":
            case "pollable_attributes":
            case "policies":
            case "integrations":
            case "ha_groups":
                return "Orchestration";
            case "users":
            case "roles":
            case "accounts":
                return "Onboard";
        }
    };

    handleProfileMenuOpen = event => {
        this.setState({ menuAnchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ menuAnchorEl: null });
    };

    handleHelpClose = () => {
        this.setState({ helpAnchorEl: null });
    };

    manageSelf = () => {
        this.handleMenuClose();
        this.openEditSelfModal();
    };

    hasTabs = (title) => {
        if (title === "Onboarding: Create Account" ||
            title === "Onboarding: Create Users" ||
            title === "Onboarding: Onboard Devices" ||
            title === "Onboarding" ||
            title === Auth.currentPortalTitle()
        ) {
            return false;
        } else {
            return true;
        }
    };

    buildRoutes() {
        let routes = [];
        if (PortalConfiguration.navigationItemVisible('overview')) {
            routes.push(<Route key={"/dashboard"} path="/dashboard" component={OverviewPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('messages')) {
            routes.push(<Route key={"/messages"} path="/messages" component={MessagesPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('alerts')) {
            routes.push(<Route key={"/alerts"} path="/alerts" component={AlertsPage} />);
        }
        // if (PortalConfiguration.navigationItemVisible('transfers')) {
        routes.push(<Route key={"/transfers"} path="/transfers" component={TransfersPage} />);
        // }
        if (PortalConfiguration.navigationItemVisible('reports')) {
            routes.push(<Route key={"/reports"} path="/reports" component={ReportsPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('devices')) {
            routes.push(<Route key={"/devices"} path="/devices" component={DevicesPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('discovered_devices')) {
            routes.push(<Route key={"/discovered_devices"} path="/discovered_devices" component={DiscoveredDevicesPage} />);
        }
        // if (PortalConfiguration.navigationItemVisible('types')) {
        routes.push(<Route key={"/device_types"} path="/device_types" component={TypesPage} />);
        // }
        if (PortalConfiguration.navigationItemVisible('policies')) {
            routes.push(<Route key={"/policies"} path="/policies" component={RulesPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('software')) {
            routes.push(<Route key={"/software"} path="/software" component={SoftwarePage} />);
        }
        // if (PortalConfiguration.navigationItemVisible('ingest')) {
        routes.push(<Route key={"/ingestors"} path="/ingestors" component={IngestPage} />);
        // }
        // if (PortalConfiguration.navigationItemVisible('command')) {
        routes.push(<Route key={"/commands"} path="/commands" component={CommandPage} />);
        // }
        if (PortalConfiguration.navigationItemVisible('configs')) {
            routes.push(<Route key={"/configs"} path="/configs" component={ConfigsPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('integrations')) {
            routes.push(<Route key={"/integrations"} path="/integrations" component={IntegrationsPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('users')) {
            routes.push(<Route key={"/users"} path="/users" component={UsersPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('roles')) {
            routes.push(<Route key={"/roles"} path="/roles" component={RolesPage} />);
        }
        if (PortalConfiguration.navigationItemVisible('companies')) {
            routes.push(<Route key={"/accounts"} path="/accounts" component={AccountsPage} />);
        }
        routes.push(<Route key={"/pollable_attributes"} path="/pollable_attributes" component={PollableAttributesPage} />);
        routes.push(<Route key={"/"} exact path="/" component={OverviewPage} />);
        return routes;
    }

    buildNavItems() {
        let navItems = {
            "overview": {
                display: "Dashboard",
                icon: "DashboardIcon",
                hidden: false
            },
            "messages": {
                display: "Messages",
                icon: "InboxIcon",
                hidden: false
            },
            "alerts": {
                display: "Alerts",
                icon: "ReportProblemIcon",
                hidden: false
            },
            "transfers": {
                display: "Transfers",
                icon: "SwapHorizIcon",
                hidden: false
            },
            "reports": {
                display: "Reports",
                icon: "PollIcon",
                hidden: false
            },
            "devices": {
                display: "Devices",
                icon: "DvrIcon",
                hidden: false
            },
            "discovered_devices": {
                display: "Discovered Devices",
                icon: "DiscoveredDeviceIcon",
                hidden: false
            },
            "rules": {
                display: "Policies",
                icon: "SecurityIcon",
                hidden: false
            },
            "software": {
                display: "Software",
                icon: "CodeIcon",
                hidden: false
            },
            "ingest": {
                display: "Ingestors",
                icon: "SettingsInputComponentIcon",
                hidden: false
            },
            "command": {
                display: "Commands",
                icon: "ArrowDownwardIcon",
                hidden: false
            },
            "configs": {
                display: "Configs",
                icon: "SettingsIcon",
                hidden: false
            },
            "integrations": {
                display: "Integrations",
                icon: "ExtensionIcon",
                hidden: false
            },
            "ha_groups": {
                display: "HA Groups",
                icon: "GroupWorkIcon",
                hidden: false
            },
            "types": {
                display: "Device Types",
                icon: "DevicesOtherIcon",
                hidden: false
            },
            "users": {
                display: "Users",
                icon: "PersonIcon",
                hidden: false
            },
            "roles": {
                display: "Roles",
                icon: "PeopleIcon",
                hidden: false
            },
            "companies": {
                display: "Accounts",
                icon: "AccountCircleIcon",
                hidden: false
            },
            "pollable_attributes": {
                display: "Pollable Attributes",
                icon: "TuneIcon",
                hidden: false
            },
        }
        Object.keys(navItems).forEach((navItemType) => {
            let visible = PortalConfiguration.navigationItemVisible(navItemType);
            navItems[navItemType].hidden = !visible
        });
        return navItems;
    }

    show_help = (event) => {
        this.setState({ helpAnchorEl: event.currentTarget });
    }

    buildLayout(title, classes, theme, menuAnchorEl, helpAnchorEl, user, isMenuOpen, isHelpOpen, renderMenu, renderHelp, drawer) {
        if (user == null) {
            Auth.loadMe().then(() => {
                this.setState(state => ({ user: Auth.user() }));
            });
            return (
                <Loading />
            );
        } else {
            let currentBranding = Auth.currentBranding();
            return (
                <React.Fragment>
                    <SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
                        {this.state.modal.children(classes)}
                    </SimpleModalWrapped>
                    {/* header bar */}
                    <AppBar className={classes.appBar + " " + (this.state.shrunk ? classes.appBarShrunk : "")}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerToggle}
                                className={classes.navIconHide}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.grow} />
                            <div className={classes.appBarActionContainer}>
                                <Tooltip key="Docs" title="Docs">
                                    <a className={classes.anchor} href="http://dev.edgeiq.io/docs/">
                                        <IconButton color="inherit">
                                            <FolderIcon />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                                <Tooltip title="Page Info" onClick={this.show_help} >
                                    <IconButton color="inherit">
                                        <InfoIcon className={classes.infoIcon} />
                                    </IconButton>
                                </Tooltip>
                                {user.logo_url == '' ? (
                                    <IconButton aria-owns={isMenuOpen ? 'material-appbar' : null} onClick={this.handleProfileMenuOpen} color="inherit">
                                        <AccountCircleIcon />
                                    </IconButton>
                                ) :
                                    <IconButton aria-owns={isMenuOpen ? 'material-appbar' : null} onClick={this.handleProfileMenuOpen} color="inherit">
                                        <img src={user.logo_url} className={classes.profileDefault} />
                                    </IconButton>
                                }
                            </div>
                            {renderMenu}
                            {renderHelp}
                        </Toolbar>
                    </AppBar>
                    {/* small width overlay nav */}
                    <Hidden mdUp className={classes.drawer}>
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    {/* full width nav */}
                    <Hidden smDown implementation="css" className={classes.drawer}>
                        <Drawer
                            variant="permanent"
                            open
                            className={classes.basicDrawer}
                            classes={{
                                paper: this.state.shrunk ? classes.shrunk : classes.drawerPaper,
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    {/* PAGE CONTENT */}
                    <main className={classes.content + " " + (this.state.shrunk ? classes.contentShrunk : "")}>
                        <div className={classes.toolbar} />
                        <Switch>
                            {this.buildRoutes()}
                            <Route component={OverviewPage} />
                        </Switch>
                    </main>
                </React.Fragment>
            );
        }
    }

    handle_arrow_ref = node => {
        this.setState({
            arrowRef: node,
        });
    };

    render_help_info = () => {
        const classes = this.props.classes;
        let path = this.props.location.pathname.split("/")[1].split("?")[0];
        let title = "";
        let message = "";
        switch (path) {
            case "":
            case "Dashboard":
                title = "The Dashboard";
                message = "The Dashboard helps you monitor the online status of your IoT and keep track of errors, alerts, and software updates. You can also customize the widgets by managing your account settings.";
                break;
            case "Messages":
                title = "Messages";
                message = "A variety of messages carry information critical to your IoT. Heartbeats determine online status, reports provide data, errors enable troubleshooting, and bulk jobs contextualize provisioning and configuration operations.";
                break;
            case "Alerts":
                title = "Alerts";
                message = "Automate your IoT by creating policies that respond to conditions and generate alerts. Learn more about rule-based orchestration at the Policies page.";
                break;
            case "Transfers":
                title = "Transfers";
                message = "Keep track and take action on device transfer requests that you or your account has issued or received.";
                break;
            case "Reports":
                title = "Reports";
                message = "See your reports";
                break;
            case "Devices":
                title = "Devices";
                message = "This is your IoT. You can get started by provisioning devices via a bulk CSV operation or through the creation workflow. Use the filters to find devices that need configuration and perform bulk changes as you see fit. Click on a device to manage and monitor that device.";
                break;
            case "Types":
                title = "Device Types";
                message = "Create and configure device blueprints that determine networking capabilities and supported actions and gateway commands.";
                break;
            case "Software":
                title = "Software";
                message = "Manage software packages, upload files, define executable scripts, and issue updates to your devices.";
                break;
            case "Ingestors":
                title = "Data Ingestion";
                message = "Configure the ingestion pipeline for your devices by defining listeners, handlers, and translators.";
                break;
            case "Configs":
                title = "Configs";
                message = "Create a variety of network configurations that can be assigned to one or more devices.";
                break;
            case "Policies":
                title = "Policies";
                message = "Policies attached to devices will execute actions based on a variety of criteria and can computed at the edge, or in the cloud. Create and manage policies to orchestrate your IoT.";
                break;
            case "Integrations":
                title = "Integrations";
                message = "Integrate your IoT management with an external 3rd party like AWS IoT or Azure. You can also create integrations that are used by policies to execute specific actions.";
                break;
            case "HA_Groups":
                title = "High-Availability Groups";
                message = "Configure high-availability groupings of devices in order to ensure reliable operations.";
                break;
            case "Users":
                title = "Users";
                message = "Create and manage user profiles and assign them permission-based roles.";
                break;
            case "Roles":
                title = "Roles";
                message = "Define the exact permissions that users should have by creating and managing roles.";
                break;
            case "Accounts":
                title = "Accounts";
                message = "Define and manage your customer accounts, their company's branding, and any shared data between your account and theirs.";
                break;
        }
        return (
            <div>
                <div className={classes.helpTitle}>
                    {title}
                </div>
                <div className={classes.helpMessage}>
                    {message}
                </div>
            </div>
        )
    }

    render() {
        const title = this.setTitle();
        const { classes, theme, user } = this.props;
        const { menuAnchorEl, helpAnchorEl } = this.state;
        const isMenuOpen = Boolean(menuAnchorEl);
        const isHelpOpen = Boolean(helpAnchorEl);
        const defaultHeaderImage = require('../../images/EdgeIQ_logo.svg').default;
        const defaultHeaderIconImage = require('../../images/ms_icon_new.png').default;
        const defaultLogoBackground = "#FFF";
        let headerImage = defaultHeaderImage;
        let headerIconImage = defaultHeaderIconImage;
        let logoBackgroundColor = defaultLogoBackground;
        let currentBranding = Auth.currentBranding();
        if (currentBranding && currentBranding.logo_url && currentBranding.logo_url !== '') {
            headerImage = currentBranding.logo_url;
        }
        if (currentBranding && currentBranding.logo_url && currentBranding.icon_url !== '') {
            headerIconImage = currentBranding.icon_url;
        }
        if (currentBranding && currentBranding.logo_background_color && currentBranding.logo_background_color !== '') {
            logoBackgroundColor = currentBranding.logo_background_color;
        }
        const drawer = (
            <div>
                <ListItem divider={false} className={classes.appBarHeader + " " + (this.state.shrunk ? classes.shrunkHeader : "")} style={{ backgroundColor: logoBackgroundColor }}>
                    {this.state.shrunk ?
                        <img className={classes.appBarHeaderIconImage} src={headerIconImage} />
                        :
                        <img className={classes.appBarHeaderImage} src={headerImage} />
                    }
                </ListItem>
                <MenuItems toggle={this.handleDrawerToggle} isMobile={this.state.mobileOpen} min={this.state.shrunk} items={this.buildNavItems()} />
            </div>
        );
        const renderMenu = (
            <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                {/* todo <MenuItem onClick={this.handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={this.manageSelf}>Manage My Profile</MenuItem>
                <MenuItem onClick={() => this.props.logout(this.logoutRedirect)}>Logout</MenuItem>
            </Menu>
        );
        const renderHelp = (
            <StyledPopper
                open={isHelpOpen}
                id="hover"
                anchorEl={helpAnchorEl}
                className={classes.popper}
                placement='bottom'
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    arrow: {
                        enabled: true,
                        element: this.state.arrowRef,
                    },
                }}
            >
                <span className="arrow" ref={this.handle_arrow_ref} />
                <CloseIcon onClick={this.handleHelpClose} className={classes.popperClose} />
                <Paper
                    className={classes.hoverContainer}
                >
                    {this.render_help_info()}
                </Paper>
            </StyledPopper>
        );

        return (
            <div className={classes.root}>
                {this.buildLayout(title, classes, theme, menuAnchorEl, helpAnchorEl, user, isMenuOpen, isHelpOpen, renderMenu, renderHelp, drawer)}
            </div>
        );
    }
}
const StyledPopper = styled(Popper)`&&{

	z-index: 1;
	&[x-placement*="bottom"] .arrow{

	  width: 0;
	  height: 0;
	  border-left: 1em solid transparent;
	  border-right: 1em solid transparent;
	  border-bottom: 1em solid white;
	  margin-top: -0.9em;

	  &:before {
		border-width: '0 1em 1em 1em';
		border-color: 'transparent transparent white transparent';
	  }
	}

	&[x-placement*="top"] .arrow{

	  bottom: 0;
	  width: 0;
	  height: 0;
	  border-left: 1em solid transparent;
	  border-right: 1em solid transparent;
	  border-top: 1em solid white;
	  margin-bottom: -0.9em;

	  &:before {
		border-width: 1em 1em 0 1em;
		border-color: white transparent transparent transparent;
	  }
	}

	&[x-placement*="right"] .arrow{

	  left: 0;
	  width: 0;
	  height: 0;
	  border-top: 1em solid transparent;
	  border-bottom: 1em solid transparent;
	  border-right: 1em solid white;
	  margin-left: -0.9em;

	  &:before {
		border-width: 1em 1em 1em 0;
		border-color: transparent white transparent transparent;
	  }
	}

	&[x-placement*="left"] .arrow{

	  right: 0;
	  width: 0;
	  height: 0;
	  border-top: 1em solid transparent;
	  border-bottom: 1em solid transparent;
	  border-left: 1em solid white;
	  margin-right: -0.9em;

	  &:before {
		border-width: 1em 0 1em 1em;
		border-color: transparent transparent transparent white;
	  }
	}

	.arrow {
	  position: absolute;
	  font-size: 7px;
	  width: 3em;
	  height: 3em;

	  &:before {
		content: '""',
		margin: auto;
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
	  }
	}

	.popper-content {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  background-color: white;
	  color: white;
	  height: 90px;
	  width: 160px;
	}

  }`;

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Layout.contextType = SnackbarContext;
export default compose(
    withRouter,
    withStyles(styles),
    withTheme()
)
    (Layout);
