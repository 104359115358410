import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import classNames from 'classnames';
import User from '../../../services/DataModels/User';
import Role from '../../../services/DataModels/Role';
import Company from '../../../services/DataModels/Company';
import Tooltip from '@material-ui/core/Tooltip';
import { GetAll, AssignNestedModels } from '../../../services/CLURDUtilities';
import Button from '@material-ui/core/Button';
import Loading from '../../DisplayOriented/Loading';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Permissions from '../../../services/Permissions';
import Auth from '../../../services/Auth';
import Card from '@material-ui/core/Card';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton'

//inputs
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';
import PasswordInput from '../../Inputs/PasswordInput';
import LookupInput from '../../Inputs/LookupInput'; 

//icons
import EmailIcon from '@material-ui/icons/Email';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PersonIcon from '@material-ui/icons/PersonOutline';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

import MetadataEditor from '../../common/MetadataEditor/MetadataEditorComponent'

const styles = theme => ({
	scrollContainer: {
		width: "inherit",
		overflowY: "scroll",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	container: {
		margin: "32px 20%",
		display: "flex",
		flexWrap: "wrap",
		fontFamily: "Inter",
		flexGrow: 2,
	},
	leftColumn: {
		flex: "33%",
		boxSizing: "border-box",
		padding: "12px 6px 0 0"
	},
	rightColumn: {
		flex: "66%",
		boxSizing: "border-box",
		padding: "12px 0 0 6px"
	},
	buttonContainer: {
		width: "100%",
		justifyContent: "flex-end",
		display: "flex",
		margin: "32px 0 8px 0",
	},
	leftButtons: {
		flex: "66%",
		display: "flex",
		alignItems: "center"
	},
	helpIcon: {
		color: theme.palette.primary.main,
		position: "relative",
		zIndex: 1
	},
	button: {
		marginLeft: "8px",
		whiteSpace: "nowrap"
	},
	iconSmall: {
		fontSize: "20px",
		marginRight: "4px"
	},
	deleteButton: {
		marginRight: "8px",
	},
	profileContainer: {
		border: "solid lightgrey 1px",
		borderRadius: "5px",
		width: "100%",
		textAlign: "center",
		boxSizing: "border-box"
	},
	modalWrapper: {
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		fontWeight: "700",
		lineHeight: "32px",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	profileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto"
	},
	placeholderProfileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto",
		color: theme.palette.primary.main
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap"
	},
	modalButtons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	inputIcon: {
		color: "#8e8e93",
		margin: "8px",
		paddingBottom: "20px"
	},
	noIcon: {
		visibility: "hidden"
	},
	secondaryContainer: {
		marginLeft: "44px",
		marginBottom: "24px",
		marginTop: "-12px"
	},
	rightButtons: {
		flex: "50%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	leftIcon: {
		marginRight: "12px",
	},
	obscuredRole: {
		fontFamily: "Inter",
		fontSize: "14px",
		color: "#8e8e93",
		paddingBottom: "20px"
	},
	cssRoot: {
		whiteSpace: "nowrap"
	},
	metadataEditor: {
		width: "97%"
	},
	noMarginBottom: {
		marginBottom: 0,
	}
});


class UserTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
			ready: false,
			candidate_companies: null,
			company_options: null,
			role_options: null,
			valid_profile_image: null,
			email_valid: new User(this.props.data).editableCopy()._id ? true : false,
			user_visible_companies: null, // all companies user can see, coresponding to company_ids
			primary_company: null, // company corresponding to company_ids[0]
			secondary_companies: null, // companies corresponding to company_ids[1..len-1]
			editable_user: new User(this.props.data).editableCopy(),
			metadata: null
		};
		this.obscure_role = false;
		if (!this.state.editable_user.nested_user_type) {
			if (Permissions.allow(["read"], "user_type", this.state.editable_user.company_id)) {
				this.state.editable_user.user_type_id = '';
			} else {
				this.obscure_role = true;
			}
		}
		this.delete_disabled = !Permissions.allow(["delete"], "user", this.state.editable_user.company_id);
		this.editing_disabled = this.state.editable_user._id && !Permissions.allow(["update"], "user", this.state.editable_user.company_id);
		if (this.state.editable_user.logo_url && this.state.editable_user.logo_url !== "") {
			User.testImage(this.state.editable_user.logo_url).then( () => {
				this.setState( {valid_profile_image: true} );
			}).catch( (error) => {
				this.setState( {valid_profile_image: false} );
			});
		}
		//this.state.editable_user.company_id = Auth.currentCompany()._id;
		this.load_option_data();
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	prepare = () => {
		if (this.state.editable_user._id) {
			this.setState({email_valid: true});
		}
		this.obscure_role = false;
		if (!this.state.editable_user.nested_user_type) {
			if (Permissions.allow(["read"], "user_type", this.state.editable_user.company_id)) {
				this.setState( (state) => {
					state.editable_user.user_type_id = '';
					return state;
				});
			} else {
				this.obscure_role = true;
			}
		}
		if (this.state.editable_user.logo_url && this.state.editable_user.logo_url !== "") {
			User.testImage(this.state.editable_user.logo_url).then( () => {
				this.setState( {valid_profile_image: true} );
			}).catch( (error) => {
				this.setState( {valid_profile_image: false} );
			});
		}
		this.delete_disabled = !Permissions.allow(["delete"], "user", this.state.editable_user.company_id);
		this.editing_disabled = this.state.editable_user._id && !Permissions.allow(["update"], "user", this.state.editable_user.company_id);
	}

	refresh = () => {
		this.setState({editable_user: null});
		new User({_id: this.props.data._id}).readFromAPI().then( (user) => {
			this.props.tabHostProxy.updateTitle(user._id, user);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			AssignNestedModels("user_types", "user_type_id", [user]).then( () => {
				this.setState({editable_user: new User(user).editableCopy()}, () => {
					this.prepare();
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
			
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_option_data = () => {
		Promise.all([GetAll("companies"), GetAll("user_types")])
			.then( ([companies, roles]) => {
				this.state.candidate_companies = companies;
				let user_visible_companies = this.state.editable_user.company_ids.map((company_id) => {
					let company = companies.find((c) => c._id === company_id);
					if (company == null) {
						company = {label: company_id, display: company_id, name: company_id, _id: company_id, value: company_id };
					} else {
						company.label = company.name;
						company.value = company._id;
					}
					return company;
				});
				let primary_company = (user_visible_companies.length > 0) ? user_visible_companies[0] : null;
				let secondary_companies = (user_visible_companies.length > 1) ? user_visible_companies.slice(1) : [];
				roles = roles.filter((role) => role.name !== 'admins');
				roles.forEach( (role) => role.ui_name = Role.defaultRoleReadable(role.name));
				this.setState( {
					company_options: companies,
					role_options: roles,
					ready: true,
					primary_company: primary_company,
					secondary_companies: secondary_companies,
					user_visible_companies: user_visible_companies
				});
			})
			.catch( (error) => {
				console.error(error);
				this.context.openSnackbar(error, 'error');
			});
	}

	can_save = () => {
		let show_errors = false;
		const editable_user = this.state.editable_user;
		if (editable_user.first_name === "" || this.password_error() || editable_user.email === "" || /\s/.test(editable_user.email) || this.company_error() || this.role_error() ) {
			show_errors = true;
		}
		this.setState({ show_errors: show_errors });
		return !show_errors;
	}

	send_email = (email) => {
		Auth.promptPasswordEmail(email).then( () => {
			this.context.openSnackbar("Email sent successfully.", "success");
		}).catch( (error) => {
			this.context.openSnackbar("An error occurred sending the password reset.", "error");
		});
	}

	save_user = () => {
		if (this.can_save()) {
			if (!this.state.editable_user._id) {
				new User(this.format_user())
					.createOrSave()
					.then((result) => User.loadRequirements([result]))
					.then( ([result]) => {
						this.context.openSnackbar("User created. Send them an email to reset their password?", "success", {action: () => this.send_email(result.email), display: "Send Email"});
						this.props.tabHostProxy.refresh();
						this.props.tabHostProxy.closeSelf();
						this.props.tabHostProxy.addTab("user", result);
					})
					.catch( (error) => {
						if (error === "An error occurred while saving this profile. The email is already in use.") {
							this.setState({ email_valid: false });
						} else {
							if(error.error === 'Unable to validate resource') {
								this.context.openSnackbar(error.messages[0], "error");
							} else {
							this.context.openSnackbar(error, "error");
						}
						}
					});
			} else {
				const user = { ...this.format_user(), metadata: null }
				new User(user)
					.createOrSave()
					.then( (result) => {
						const user = { ...this.format_user(), metadata: this.state.editable_user.metadata }
						new User(user)
						.createOrSave()
						.then((result) => User.loadRequirements([result]))
						.then( ([result]) => {
							this.context.openSnackbar("User saved.", "success");
							this.props.tabHostProxy.refresh();
							this.props.tabHostProxy.closeSelf();
							this.props.tabHostProxy.addTab("user", result);
						})
						.catch( (error) => {
							if (error === "An error occurred while saving this profile. The email is already in use.") {
								this.setState({ email_valid: false });
							} else {
								if(error.error === 'Unable to validate resource') {
									this.context.openSnackbar(error.messages[0], "error");
								} else {
								this.context.openSnackbar(error, "error");
							}
							}
						});
					})
					.catch( (error) => {
						if (error === "An error occurred while saving this profile. The email is already in use.") {
							this.setState({ email_valid: false });
						} else {
							if(error.error === 'Unable to validate resource') {
								this.context.openSnackbar(error.messages[0], "error");
							} else {
							this.context.openSnackbar(error, "error");
						}
						}
					});
			}
		}
	}

	format_user = () => {
		let body = new User(this.state.editable_user).editableCopy();
		body.company_ids = [];
		this.state.user_visible_companies.forEach( (company) => {
			body.company_ids.push(company._id);
		});
		if (body._id && body.password === "") {
			delete body.ui_password_confirmation;
			delete body.password;
		} else {
			body.password_confirmation = body.ui_password_confirmation;
			delete body.ui_password_confirmation;
		}
		return body;
	}

	confirm_delete = (user, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Delete this user? This action cannot be reversed.
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								className={classes.button}
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								color="primary"
								variant="contained"
								size="large"
								className={classes.button}
								onClick={() => this.delete_user(user)}
							>
								DELETE USER
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	render_password = () => {
		const classes = this.props.classes;
		const { editable_user, show_errors } = this.state;
		if (this.editing_disabled || editable_user._id) return "";
		return (
			<React.Fragment>
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} key="Password" title="Password">
						<LockIcon />
					</Tooltip>
					<PasswordInput
						error={ this.password_error() && show_errors}
						emitChange={this.handleTextChange}
						priorState={editable_user.password}
						error_message={this.password_error_message()}
						label={(editable_user._id ? "Reset ": "") + "Password" + (editable_user._id ? "": "*")}
						key="password"
						field="password"
					>
					</PasswordInput>
				</div>
				<div className={classes.inputContainer + " " + classes.noMarginBottom}>
					<Tooltip className={classes.inputIcon + " " + classes.noIcon} key="Password Confirmation" title="Password Confirmation">
						<LockIcon />
					</Tooltip>
					<PasswordInput
						error={ this.password_error() && show_errors}
						emitChange={this.handleTextChange}
						priorState={editable_user.ui_password_confirmation}
						error_message={this.password_error_message()}
						label={"Password Confirmation"+ (editable_user._id ? "": "*")}
						key="ui_password_confirmation"
						field="ui_password_confirmation"
					>
					</PasswordInput>
				</div>
			</React.Fragment>
		);
	}

	render_owning_account = () => {
		const classes = this.props.classes;
		const { editable_user, show_errors, company_options } = this.state;
		let options = company_options
									.map( (company) => ({display: company.name, value: company._id}))
									.sort((a,b) => (a.display.toUpperCase() > b.display.toUpperCase()) ? 1 : ((b.display.toUpperCase() > a.display.toUpperCase()) ? -1 : 0));
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Parent Account" title="Parent Account">
					<AccountCircleIcon />
				</Tooltip>
				<SelectInput
					disabled={this.editing_disabled || editable_user._id != null}
					error={this.company_error() && show_errors}
					error_message="Please assign this user to an owning account."
					emitChange={this.handleTextChange}
					priorState={editable_user.company_id}
					key="company_id"
					field="company_id"
					label={editable_user._id != null ? "'Parent Account' cannot be changed after the user has been created." : "Parent Account"}
					options={options}
				>
				</SelectInput>
				<Tooltip key="About Accounts" title="About Accounts">
					<a className={classes.anchor} href="https://dev.edgeiq.io/docs/account-administration" target="_blank">
						<IconButton color="inherit">
							<HelpIcon className={classes.helpIcon}/>
						</IconButton>
					</a>
				</Tooltip>
			</div>
		);
	}

	render_primary_account = () => {
		const classes = this.props.classes;
		const { editable_user, show_errors, candidate_companies, primary_company } = this.state;
		let control = (
			<div className={classes.obscuredRole}>
				{"Please choose an 'Parent Account' first"}
			</div>
		);
		if (editable_user.company_id !== "") {
			let foundOwning = false;
			let foundPrimary = false;
			let owning = candidate_companies.find((c) => c._id === editable_user.company_id);
			let suggestions = Company
												.flattenedListOfDescendants(editable_user.company_id, candidate_companies)
												.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
												.map( (company) => {
				company.display = company.name;
				company.value = company._id;
				if (primary_company && primary_company._id && company._id === primary_company._id) {
					foundPrimary = true;
				}
				if (owning && owning._id && company._id === owning._id) {
					foundOwning = true;
				}
				return company;
			});
			if (!foundPrimary) {
				primary_company.display = primary_company.name;
				primary_company.value = primary_company._id;
				suggestions.unshift(primary_company);
			}
			if (!foundOwning) {
				let owningIsPrimary = primary_company != null && owning._id === primary_company._id;
				if (!owningIsPrimary) {
					owning.display = owning.name;
					owning.value = owning._id;
					suggestions.unshift(owning);
				}
			}
			control = (
				<SelectInput
					disabled={this.editing_disabled}
					label={"Portal Branding Account"}
					emitChange={this.handlePrimaryCompanyChange}
					priorState={primary_company._id}
					key="primary_company_id"
					field="primary_company_id"
					options={suggestions}
				>
				</SelectInput>
			);
		}
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Portal Branding Account" title="Portal Branding Account">
					<AccountCircleIcon />
				</Tooltip>
				{control}
			</div>
		);
	}

	render_other_visible_accounts = () => {
		const classes = this.props.classes;
		const { user_visible_companies, secondary_companies, primary_company, candidate_companies, editable_user } = this.state;
		let owning_company = null;
		let suggestions = null;
		if (editable_user.company_id !== "") {
			let companies = this.state.candidate_companies;
			suggestions = Company
										.flattenedListOfDescendants(editable_user.company_id, companies)
										.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
										.map( (company) => {
				company.label = company.name;
				company.value = company._id;
				return company;
			});
			if (primary_company) {
				suggestions = suggestions.filter((c) => c._id !== primary_company._id);
			}
		} else {
			return (
				<div className={classes.secondaryContainer}>
					<LookupInput
						params={{company_id: editable_user.company_id}}
						placeholder={"Select Parent Account First"}
						priorState={{values: [], suggestions: []}}
						label="Additional Managed Accounts"
						model="companies"
						emitChange={this.handle_lookup_change}
						valueField="_id"
						labelField="name"
						disabled={true}
					/>
				</div>
			);
		}
		return (
			<div className={classes.secondaryContainer}>
				<LookupInput
					params={{company_id: editable_user.company_id}}
					priorState={{values: secondary_companies, suggestions: suggestions}}
					label="Additional Managed Accounts"
					model="companies"
					emitChange={this.handle_lookup_change}
					valueField="_id"
					labelField="name"
				/>
			</div>
		);
	}

	render_role = () => {
		const classes = this.props.classes;
		const { role_options, show_errors, editable_user } = this.state;
		let options = role_options.filter( (role) => role.company_id === "" || role.company_id === editable_user.company_id);
		options.push({ui_name: "Select Role*", _id: ""});
		options = options.map( (role) => ({display: role.ui_name, value: role._id}))
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Role" title="Role">
					<PeopleIcon />
				</Tooltip>
				{this.obscure_role ? 
						<div className={classes.obscuredRole}>
							You don't have permission to view the user's role.
						</div>
					:
						<SelectInput
							disabled={this.editing_disabled}
							error={this.role_error() && show_errors}
							error_message="Please assign a role to this user."
							emitChange={this.handleTextChange}
							priorState={editable_user.user_type_id}
							key="user_type_id"
							field="user_type_id"
							options={options}
						/>
				}
			</div>
		);
	}

	handle_lookup_change = ({value: new_secondary_companies}) => {
		let new_visible = [this.state.primary_company].concat(new_secondary_companies);
		this.setState({
			user_visible_companies: new_visible,
			secondary_companies: new_secondary_companies
		});
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	delete_user = (user) => {
		new User(user)
			.deleteFromAPI()
			.then( () => {
				this.context.openSnackbar("User deleted.", "success");
				this.close_modal();
				this.props.tabHostProxy.refresh();
				this.props.tabHostProxy.closeSelf();
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	password_error = () => {
		if (this.state.editable_user._id) {
			if (this.state.editable_user.ui_password_confirmation === "" && this.state.editable_user.password === "") {
				return false;
			} else {
				if (this.state.editable_user.ui_password_confirmation.length >= 8 && this.state.editable_user.ui_password_confirmation === this.state.editable_user.password) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			if ((this.state.editable_user.ui_password_confirmation !== "" && this.state.editable_user.ui_password_confirmation.length >= 8) && this.state.editable_user.ui_password_confirmation === this.state.editable_user.password) {
				return false;
			} else {
				return true;
			}
		}
	}

	company_error = () => {
		if (this.state.editable_user.company_id === "") {
			return true;
		} else {
			return false;
		}
	}

	role_error = () => {
		if (this.state.editable_user.user_type_id === "") {
			return true;
		} else {
			return false;
		}
	}

	password_error_message = () => {
		const pwd = this.state.editable_user.password;
		const pwd_confirm = this.state.editable_user.ui_password_confirmation;
		if ( pwd !== pwd_confirm || pwd.length < 8 ) {
			return "Passwords must match and have at least 8 characters."
		}
	}

	copy_token = (e) => {
		this.textArea.select();
		document.execCommand('copy');
		e.target.focus();
		this.context.openSnackbar("Token copied to clipboard.", "success");
	}

	prompt_reset = () => {
		const { data, classes } = this.props;
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Would you like to send the user an email to reset their password?
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								className={classes.button}
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="large"
								className={classes.button}
								onClick={() => {
									this.send_email(this.props.data.email);
									this.close_modal();
								}}
							>
								SEND EMAIL
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	render() {
		const { classes, data } = this.props;
		const { email_valid, modal, editable_user, valid_profile_image, show_errors, company_options, role_options, ready } = this.state;
		return (
			<div className={classes.scrollContainer}>
				<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
					{modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.container}>
					{ready && editable_user != null ?
						<React.Fragment>
							<div className={classes.leftColumn}>
								<div className={classes.profileContainer}>
									{valid_profile_image ?
										<img className={classes.profileImage} src={editable_user.logo_url}/>
									:
										<PersonIcon className={classes.placeholderProfileImage} />
									}
								</div>
								<div className={classes.inputContainer}>
									<Tooltip className={classes.inputIcon} key="Profile Image" title="Profile Image">
										<CameraAltIcon />
									</Tooltip>
									<TextInput
										disabled={this.editing_disabled}
										error={valid_profile_image === false}
										error_message="This URL does not load an image."
										emitChange={this.handleImageUrlChange}
										priorState={editable_user.logo_url}
										label="Profile Photo (URL)"
										key="logo_url"
										field="logo_url"
									>
									</TextInput>
								</div>
							</div>
							<div className={classes.rightColumn}>
								<div className={classes.inputContainer}>
									<Tooltip className={classes.inputIcon} key="Name" title="Name">
										<PersonIcon />
									</Tooltip>
									<TextInput
										disabled={this.editing_disabled}
										emitChange={this.handleTextChange}
										priorState={editable_user.first_name}
										error_message="A user's first name cannot be blank."
										error={editable_user.first_name === "" && show_errors}
										label="First Name*"
										key="first_name"
										field="first_name"
									>
									</TextInput>
								</div>
								<div className={classes.inputContainer}>
									<Tooltip className={classes.inputIcon + " " + classes.noIcon} key="Name" title="Name">
										<PersonIcon />
									</Tooltip>
									<TextInput
										disabled={this.editing_disabled}
										emitChange={this.handleTextChange}
										priorState={editable_user.last_name}
										label="Last Name"
										key="last_name"
										field="last_name"
									>
									</TextInput>
								</div>
								<div className={classes.inputContainer} style={{paddingBottom:'10px'}}>
									<Tooltip className={classes.inputIcon} key="Email/Username*" title="Email/Username">
										<AlternateEmailIcon />
									</Tooltip>
									<TextInput
										disabled={this.editing_disabled}
										margin="none"
										emitChange={this.handleTextChange}
										priorState={editable_user.email}
										error={(editable_user.email === "" || /\s/.test(editable_user.email)) && show_errors}
										error_message="Invalid username or email"
										label="Email/Username*"
										key="email"
										model="users"
										field="email"
										original={data ? data.email : undefined}
									>
									</TextInput>
								</div>
								{this.render_owning_account()}
								{this.render_primary_account()}
								{this.render_other_visible_accounts()}
								{this.render_role()}
								<div className={classes.metadataEditor}>
									<MetadataEditor model={editable_user} can_edit={!this.editing_disabled} submitMetadata={this.submit_edit_metadata}/>
								</div>
								{this.render_password()}
							</div>
							<div className={classes.buttonContainer}>
								{this.state.editable_user._id ? <div className={classes.leftButtons}>
									<Button
										onClick={this.prompt_reset}
										variant="outlined"
										color="primary"
										className={classes.button}
									>
										<EmailIcon className={classes.iconSmall} />
										PROMPT PASSWORD RESET EMAIL
									</Button>
								</div> : "" }
								<div className={classes.rightButtons}>
									{editable_user._id ?
										<Button
											disabled={this.delete_disabled}
											onClick={() => this.confirm_delete(editable_user)}
											color="primary"
											className={classes.button}
										>
											DELETE
										</Button>
									:""}
									<Button
										disabled={this.editing_disabled}
										onClick={this.save_user}
										variant="contained"
										color="primary"
										size="large"
										className={classes.button}
									>
										{editable_user._id ? "SAVE USER" : "CREATE USER"}
									</Button>
								</div>
							</div>
						</React.Fragment>
					:
						<Loading />
					}
				</div>
			</div>
		);
		
	}
	
	submit_edit_metadata = (new_user) => {
		this.setState( (state) => {
			state.editable_user = new_user;
			return state;
		});
	}

	handleImageUrlChange = ({field, value}) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState( (prev_state) => {
			return Object.assign(prev_state.editable_user, overwrite);
		}, () => {
			User.testImage(this.state.editable_user.logo_url).then( () => {
				this.setState( {valid_profile_image: true} );
			}).catch( () => {
				this.setState( {valid_profile_image: false} );
			});
		});
	}

	handleEmailChange = ({valid, value}) => {
		if (valid) {
			this.setState( (prev_state) => {
				let user = prev_state.editable_user;
				user.email = value;
				return { editable_user: user, email_valid: true };
			});
		} else {
			this.setState({email_valid: false});
		}
	}

	handlePrimaryCompanyChange = ({field, value}) => {
		let newPrimary = this.state.candidate_companies.find((c) => c._id === value);
		this.setState( (prev_state) => {
			prev_state.primary_company = newPrimary;
			prev_state.user_visible_companies = [newPrimary];
			prev_state.secondary_companies = [];
			return prev_state;
		});
	}

	handleTextChange = ({field, value}) => {
		let overwrite = {};
		let newOwningCompany = null;
		let newPrimaryCompany = null;
		overwrite[field] = value;
		if (field === "company_id") {
			overwrite.user_type_id = '';
			newOwningCompany = this.state.candidate_companies.find((c) => c._id === value);
		}
		this.setState( (prev_state) => {
			if (newOwningCompany) {
				prev_state.primary_company = newOwningCompany;
				prev_state.user_visible_companies = [newOwningCompany];
				prev_state.secondary_companies = [];
			}
			prev_state = Object.assign(prev_state.editable_user, overwrite);
			return prev_state;
		});
	}
}

UserTab.contextType = SnackbarContext;

export default compose(withStyles(styles),withTheme())(UserTab);
