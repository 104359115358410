import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import TextInput from '../../Inputs/TextInput';
import Role from '../../../services/DataModels/Role';
import Tooltip from '@material-ui/core/Tooltip';
import { GetAll } from '../../../services/CLURDUtilities';
import Button from '@material-ui/core/Button';
import Loading from '../../DisplayOriented/Loading';
import SelectInput from '../../Inputs/SelectInput';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Auth from '../../../services/Auth';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Permissions from '../../../services/Permissions';

//icons
import DescriptionIcon from '@material-ui/icons/Description';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PersonIcon from '@material-ui/icons/Person';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
	scrollContainer: {
		width: "inherit",
		overflowY: "scroll",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	container: {
		margin: "32px 20%",
		display: "flex",
		flexWrap: "wrap"
	},
	viewTabs: {
		marginBottom: "12px"
	},
	leftColumn: {
		flex: "33%",
		boxSizing: "border-box",
		padding: "12px 6px 12px 0"
	},
	rightColumn: {
		width: "100%",
		boxSizing: "border-box",
	},
	buttonContainer: {
		width: "100%",
		justifyContent: "flex-end",
		display: "flex",
		marginTop: "24px",
	},
	button: {
		margin: "8px 0",
		whiteSpace: "nowrap"
	},
	deleteButton: {
		marginLeft: "16px",
	},
	profileContainer: {
		border: "solid lightgrey 1px",
		borderRadius: "5px",
		width: "100%",
		textAlign: "center",
		boxSizing: "border-box"
	},
	modalWrapper: {
	},
	notShown: {
		display: "none",
	},
	shown: {
		display: "flex",
		width: "100%",
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		fontWeight: "700",
		lineHeight: "32px",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	profileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto"
	},
	placeholderProfileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto",
		color: theme.palette.primary.main
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap"
	},
	modalButtons: {
		display: "flex",
		justifyContent: "flex-end"
	},
	inputIcon: {
		color: "#8e8e93",
		margin: "8px",
		paddingBottom: "20px"
	},
	roleReserved: {
		color: "#8e8e93",
		fontSize: "14px",
		textAlign: "right",
		fontFamily: "Inter",
		width: "100%"
	},
	noIcon: {
		visibility: "hidden"
	},
	labelOverride: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "88%"
	},
	rightButtons: {
		flex: "50%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	leftIcon: {
		marginRight: "12px",
	},
	permissionsTitle: {
		fontSize: "14px",
		color: "#8e8e93",
		fontFamily: "Inter",
		marginLeft: "8px"
	},
	tile: {
		width: "100%",
		overflow: "auto",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap"
	},
	sectionWrapper: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		marginLeft: "44px",
		overflow: "hidden"
	},
	sectionTitle: {
		width: "100%",
		fontFamily: "Inter",
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	abilityTitle: {
		width: "50%",
		alignItems: "center",
		textTransform: "uppercase",
		alignItems: "center",
		fontFamily: "Inter",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflowX: "hidden",
		fontSize: "14px",
		color: "#8e8e93",
	},
	abilityWrapper: {
		width: "50%",
		display: "flex",
		alignItems: "center",
		padding: "12px",
		boxSizing: "border-box",
		justifyContent: "space-between"
	},
	selectWrapper: {
		marginBottom: "-16px",
		overflow: "hidden"
	},
	marginRight: {
		marginRight: "16px",
	}
});


class RoleTab extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
			ready: false,
			company_options: null,
			editable_role: new Role(this.props.data).editableCopy(),
			tab_index: 0
		};
		this.ability_options = [
			{display: "Only their account's data", value: "self"},
			{display: "Not Allowed", value: "not_allowed"},
			{display: "Their account's data AND accounts they manage", value: "self_and_all_children"},
		];
		this.tabs = [
			{ title: "Admin", sections: [{display: "Users", value: "user"}, {display: "Roles", value: "user_type"}, {display: "Accounts", value: "company"}, {display: "Audit Logs", value: "audit"}], icon: (classes) => (<img style={{width: "24px", height: "24px"}} src={require('../../../images/onboard.png')}/>)}, 
			{ title: "Monitoring", sections: [{display: "Alerts", value: "notification"}, {display: "Device Errors", value: "device_error"}, {display: "Bulk Job Responses", value: "bulk_response"}, {display: "Heartbeats & Reports", value: "report"}, {display: "Commands", value: "gateway_command"}], icon: () => (<img style={{width: "24px", height: "24px"}} src={require('../../../images/monitor.png')}/>) },
			{ title: "Device", sections: [{display: "Devices", value: "device"}, {display: "Device Types", value: "device_type"}, {display: "Device Network Configs", value: "device_config"}, {display: "Software Update Packages", value: "software_update"}, {display: "Escrow Devices", value:"escrow_device"},{display: "Device Transfers", value: "device_transfer_request"}, {display: "Health Status Events", value: "device_health_status_event"}], icon: () => (<img style={{width: "24px", height: "24px"}} src={require('../../../images/manage.png')}/>) },
			{ title: "Orchestration", sections: [{display: "Policies", value: "rule"}, {display: "Integrations", value: "integration"}, {display: "Workflow Definitions", value: "workflow_definition"},{display: "Workflow Actions", value: "workflow_action"},{display: "Workflow Trees", value: "workflow_tree"},{display: "Workflow Records", value: "workflow_record"},{display: "Workflow Job Results", value: "workflow_job_result"},], icon: () => (<img style={{width: "24px", height: "24px"}} src={require('../../../images/orchestrate.png')}/>) },
			{ title: "Edge", sections: [{display: "Ingestors", value: "ingestor"}, {display: "Edge Commands", value: "command"}, {display: "Translators", value: "translator"},{display: "Pollable Attributes", value: "pollable_attribute"}], icon: () => (<img style={{width: "24px", height: "24px"}} src={require('../../../images/edge.png')}/>) }
		];
		this.build_roles_map();
		this.correct_empty_strings();
		this.load_option_data();
		// temporarily disable role editing for non-admins
		this.editing_disabled = (!Auth.currentRoleIsAdmin())
		// this.editing_disabled = this.props.data && !Permissions.allow(["update"], "user_type", this.props.data.company_id);
		this.delete_disabled = this.props.data && !Permissions.allow(["delete"], "user_type", this.props.data.company_id);
		this.is_reserved = this.state.editable_role.company_id === "" && this.state.editable_role.name !== "" && this.name_error();
		this.state.editable_role.company_id = this.state.editable_role.company_id === "" ? Auth.currentCompany()._id : this.state.editable_role.company_id;
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	refresh = () => {
		this.setState({editable_role: null});
		new Role({_id: this.props.data._id}).readFromAPI().then( (role) => {
			this.props.tabHostProxy.updateTitle(role._id, role);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			this.setState({editable_role: new Role(role).editableCopy()}, this.prepare);
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	prepare = () => {
		this.is_reserved = this.state.editable_role.company_id === "" && this.state.editable_role.name !== "" && this.name_error();
		this.build_roles_map(true);
		this.editing_disabled = this.props.data && !Permissions.allow(["update"], "user_type", this.props.data.company_id);
		this.delete_disabled = this.props.data && !Permissions.allow(["delete"], "user_type", this.props.data.company_id);
		this.is_reserved = this.state.editable_role.company_id === "" && this.state.editable_role.name !== "" && this.name_error();
	}

	correct_empty_strings = () => {
		let new_abilities = this.state.editable_role.abilities;
		Object.entries(new_abilities).forEach( ([model_name, model]) => {
			Object.entries(model).forEach( ([ability, ability_value]) => {
				if (ability_value === "" || undefined === this.ability_options.find( ({value}) => ability_value === value)) {
					new_abilities[model_name][ability] = "not_allowed";
				}
			});
		});
		this.state.editable_role.abilities = new_abilities;
	}

	build_roles_map = (asynchronous) => {
		let abilities = this.state.editable_role.abilities ? JSON.parse(JSON.stringify(this.state.editable_role.abilities)) : {};
		let role_template = JSON.parse(JSON.stringify(Auth.currentRole()));
		this.tabs.forEach( (tab) => {
			tab.abilities = [];
			tab.sections.forEach( ({display, value}, index) => {
				if (role_template.abilities[value]) {
					tab.abilities[index] = Object.entries(role_template.abilities[value]).map( ([key]) => key);
					if (this.state.editable_role.abilities === null || null == this.state.editable_role.abilities[value] || '' == this.state.editable_role.abilities[value]) {
						abilities[value] = role_template.abilities[value];
						Object.entries(abilities[value]).forEach( ([ability_name]) => {
							abilities[value][ability_name] = "self";
						});
					}
				} else {
					console.log("Unable to build abilities list for: " + value);
				}
			});
		});
		if (asynchronous) {
			this.setState( (state) => {
				state.editable_role.abilities = abilities;
				return state;
			}, this.correct_empty_strings);
		} else {
			this.state.editable_role.abilities = abilities;
		}
	}

	load_option_data = () => {
		GetAll("companies")
			.then( (companies) => {
				this.setState({
					company_options: companies,
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	name_error = () => {
		const reserved_names = ["", "system_admins", "admins", "users", "limited_users", "limited_report_users", "user_managers", "publishers", "consumers", "read_only_users", "device_users", "escrow_automation_user"];
		return (reserved_names.indexOf(this.state.editable_role.name) !== -1);
	}

	can_save = () => {
		let show_errors = false;
		const editable_role = this.state.editable_role;
		if (this.name_error()) show_errors = true;
		this.setState({ show_errors: show_errors });
		return !show_errors;
	}

	save_role = () => {
		if (this.can_save()) {
			new Role(this.state.editable_role)
				.createOrSave()
				.then( (result) => {
					this.props.tabHostProxy.refresh();
					this.props.tabHostProxy.closeSelf();
					this.props.tabHostProxy.addTab("role", result);
					if (this.state.editable_role._id) {
						this.context.openSnackbar("Role saved.", "success");
					} else {
						this.context.openSnackbar("Role created.", "success");
					}
				})
				.catch( (error) => {
					this.context.openSnackbar(error, "error");
				});
		}
	}

	confirm_delete = (classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Delete this role? This action cannot be reversed.
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								className={classes.deleteButton}
								color="primary"
								variant="contained"
								size="large"
								onClick={this.delete_role}
							>
								DELETE ROLE
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	delete_role = () => {
		new Role(this.state.editable_role)
			.deleteFromAPI()
			.then(() => {
				this.context.openSnackbar("Role deleted.", "success");
				this.close_modal();
				this.props.tabHostProxy.refresh();
				this.props.tabHostProxy.closeSelf();
			}).catch((error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	changeAction = ({ field, value }) => {
		let model = field.split(" ")[0];
		let ability = field.split(" ")[1];
		let new_role = this.state.editable_role;
		new_role.abilities[model][ability] = value;
		this.setState({editable_role: new_role});
	}

	renderTab = (classes) => {
		return this.tabs.map( (tab, render_index) => {
			if (this.state.tab_index !== render_index) {
				return;
			} else {
				return (
					<div key={tab.title + "_shown"} className={classes.tile + "  " + classes.shown}>
						{tab.sections.map( (section, section_index) => (
							<div className={classes.sectionWrapper} key={tab.title + "_shown_" + section.value}>
								<div className={classes.sectionTitle} >
									{section.display}
								</div>
								{tab.abilities[section_index] && tab.abilities[section_index].map( (ability) => {
									if (section.display === "Accounts" && ability === "read") {
										return "";
									 } else {
										return (
											<div className={classes.abilityWrapper}  key={tab.title + "_shown_" + section.value + "_" + ability }>
												<div className={classes.abilityTitle} >
													{ability.replace(/_/g, " ")}
												</div>
												<div className={classes.selectWrapper}>
													<SelectInput
														disabled={this.is_reserved || this.editing_disabled}
														field={section.value + " " + ability}
														emitChange={this.changeAction}
														priorState={this.state.editable_role.abilities[section.value][ability]}
														options={this.ability_options}
													/>
												</div>
											</div>
										);
									}
								})}
							</div>
						))}
					</div>
				);
			}
		});
	}


	handleTabChange = (event, value) => {
		this.setState({ tab_index: value });
	};

	render() {
		const { classes } = this.props;
		const { modal, editable_role, show_errors, company_options } = this.state;
		return (
			<div className={classes.scrollContainer}>
				<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
					{modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.container}>
					{company_options && editable_role?
						<React.Fragment>
							<div className={classes.rightColumn}>
								<div className={classes.inputContainer}>
									<Tooltip className={classes.inputIcon} key="Description" title="Description">
										<PeopleIcon />
									</Tooltip>
									<TextInput
										disabled={this.is_reserved || this.editing_disabled}
										emitChange={this.handleTextChange}
										priorState={editable_role.name}
										error_message="A role's name cannot be blank or one of the reserved role names: system_admins, admins, users, limited_users, limited_report_users, user_managers, publishers, consumers, read_only_users, device_users, escrow_automation_users."
										error={this.name_error() && show_errors}
										label="Role Name*"
										key="name"
										field="name"
									>
									</TextInput>
								</div>
								<div className={classes.inputContainer}>
									<Tooltip className={classes.inputIcon} key="Description" title="Description">
										<DescriptionIcon />
									</Tooltip>
									<TextInput
										disabled={this.is_reserved || this.editing_disabled}
										emitChange={this.handleTextChange}
										priorState={editable_role.description}
										label="Description"
										key="description"
										field="description"
									>
									</TextInput>
								</div>

								<div className={classes.inputContainer}>
									<Tooltip className={classes.inputIcon} key="Account" title="Account">
										<AccountCircleIcon />
									</Tooltip>
									<SelectInput
										disabled={this.is_reserved || this.editing_disabled}
										emitChange={this.handleTextChange}
										priorState={editable_role.company_id}
										key="company_id"
										field="company_id"
										options={company_options.map((company) => ({ display: company.name, value: company._id }))}
									>
									</SelectInput>
								</div>
								<div className={classes.permissionsTitle}>
									Data Permissions
								</div>
								<Tabs
									className={classes.viewTabs}
									value={this.state.tab_index}
									onChange={this.handleTabChange}
									variant="scrollable"
									scrollButtons="auto"
									indicatorColor="primary"
									textColor="primary"
								>
									{this.tabs.map((tab) => {
										return (
											<Tab
												key={tab.title}
												icon={tab.icon(classes)}
												label={tab.title}
											/>

										);
									})}
								</Tabs>
								{this.renderTab(classes)}
							</div>
							<div className={classes.buttonContainer}>
								<div className={classes.rightButtons}>
									{editable_role._id ?
										<Button
											disabled={this.is_reserved || this.delete_disabled}
											className={classes.button + " " + classes.marginRight}
											onClick={() => this.confirm_delete(classes)}
											color="primary"
										>
											DELETE
										</Button>
										: ""}
									<Button
										disabled={this.is_reserved || this.editing_disabled}
										className={classes.button}
										onClick={this.save_role}
										variant="contained"
										color="primary"
										size="large"
									>
										{editable_role._id ? "SAVE ROLE" : "CREATE ROLE"}
									</Button>
								</div>
							</div>
							{this.is_reserved ? <div className={classes.roleReserved}>
								This role is a preset role and cannot be edited.
							</div> : ""}
						</React.Fragment>
						:
						<Loading />
					}
				</div>
			</div>
		);

	}

	handleTextChange = ({ field, value }) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState((prev_state) => {
			return Object.assign(prev_state.editable_role, overwrite);
		});
	}
}

RoleTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(RoleTab));
