import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TextInput from '../Inputs/TextInput';
import DateSelection from '../Inputs/DateSelection';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import TimeInput from '../Inputs/TimeInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Loading from '../DisplayOriented/Loading';
import LookupInput from '../Inputs/LookupInput';
import SelectInput from '../Inputs/SelectInput';
import { GetAll } from '../../services/CLURDUtilities';

//icons
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import CloudIcon from '@material-ui/icons/Cloud';

const styles = theme => ({
	prompt: {
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	modalWrapper: {
		fontFamily: "Inter",
		height: "643px",
	},
	heartbeatIconsContainer: {
		position: "relative",
		color: theme.palette.greyIcon.main,
		display: "flex",
		margin: "0 8px"
	},
	heartbeatIcon: {
		color: "#8e8e93",
		fontSize: "24px",
	},
	overlayIcon: {
		position: "absolute",
		left: 0,
		color: "white",
		fontSize: "24px"
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
	},
	inputDatesContainer: {
		margin: "45px 0 12px 42px",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
	},
	inputIcon: {
		color: "#8e8e93",
		margin: "8px",
		paddingBottom: "20px"
	},
	noIcon: {
		visibility: "hidden"
	},
	buttonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "32px",
	},
	saveButton: {
		marginLeft: "8px",
	},
	timeContainer: {
		display: "flex",
		alignItems: "center"
	},
	generate: {
		marginRight: "8px",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "14px",
	},
	formControl: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		paddingLeft: "30px",
		marginBottom: "12px"
	},
	formControlLabel: {
		margin: 0,
		flex: "50%",
		display: "flex",
		overflow: "hidden"
	},
	formControlLabelText: {
		overflow: "hidden",
		whiteSpace: "no-wrap",
		textOverflow: "ellipsis"
	},
	logLevelsIconsContainer: {
		position: "relative",
		color: theme.palette.greyIcon.main,
		display: "flex",
		margin: "8px 0",
	},
	logLevelsContainer: {
		margin: "0 8px"
	},
	actionInfo: {
		fontFamily: "Inter",
		color: "#8e8e93",
		alignItems: "center",
		fontSize: "14px",
		margin: "5px 0"
	},
	inputWrapper: {
		width: "100%",
		height: "505px",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "16px",
		boxSizing: "border-box",
	},
});

const INITIAL_HEARTBEAT_VALUES = {
	"connections": { enabled: false, label: "Connections"},
	"wifi_clients": { enabled: false, label: "WIFI Clients"},
	"disk_size": { enabled: false, label: "Disk Size"},
	"disk_usage": { enabled: false, label: "Disk Space Usage"},
	"disk_free": { enabled: false, label: "Disk Space Free"},
	"cell_signal": { enabled: false, label: "Cell Signal"},
	"cell_usage": { enabled: false, label: "Cell Usage"}, 
	"cpu_usage": { enabled: false, label: "CPU Usage"},
	"ram_usage": { enabled: false, label: "RAM Usage"},
	"sim_card": { enabled: false, label: "SIM Card"},
};

class EditDevice extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			device_type: this.props.device.nested_device_type,
			name: this.props.device.name,
			serial: this.props.device.serial,
			location: this.props.device.location,
			show_errors: false,
			device_config: null,
			device_integration: null,
			cloud_native_integration: null,
			device_integration_options: null,
			cloud_integration_options: null,
			config_options: null,
			heartbeat_values: JSON.parse(JSON.stringify(INITIAL_HEARTBEAT_VALUES)),
			heartbeat_period_changed: this.props.device.heartbeat_period_changed,
			heartbeat_time: this.props.device.heartbeat_time,
			log_config: this.props.device.log_config,
			activated_at: this.props.device.activated_at,
			deactivated_at: this.props.device.deactivated_at
		};
		if (this.props.device.log_config != null) {
			this.state.log_config = this.state.log_config;
			this.state.local_level = this.state.log_config.local_level;
			this.state.forward_level = this.state.log_config.forward_level;
		} else {
			this.state.log_config = {};
			this.state.local_level = "";
			this.state.forward_level = "";
		};
		this.localLogLevelOptions = [
			{ display: "Trace", value: "trace" },
			{ display: "Debug", value: "debug"},
			{ display: "Info", value: "info" },
			{ display: "Warning", value: "warn" },
			{ display: "Error", value: "error" },
			{ display: "Critical", value: "critical" }
		];
		this.forwardLogLevelOptions = [
			{ display: "Warning", value: "warn" },
			{ display: "Error", value: "error" },
			{ display: "Critical", value: "critical" }
		];
		if (this.props.device.heartbeat_values) {
			this.props.device.heartbeat_values.forEach( (hb) => {
				this.state.heartbeat_values[hb].enabled = true;
			});
		}
		
		if (this.props.device.nested_device_type.type === "cloud_native" && this.props.device.cloud_native_integration_id == null) {
			this.hide_cloud_integration = false;
		} else {
			this.hide_cloud_integration = true;
		}

		if (this.props.device.device_integration_id) {
			this.hide_device_integration = true;
		} else {
			this.hide_device_integration = false;
		}

		if (!this.hide_cloud_integration || !this.hide_device_integration) {
			this.load_device_integrations();
		}

		if (this.props.device.nested_device_type.type === "gateway") this.load_configs();
	}

	load_device_integrations = () => {
		GetAll("integrations", {archetype_in: "device,cloud"}).then( (both_integrations) => {
			let device_integrations = both_integrations
																.filter((di) => di.archetype === 'device')
																.map( (di) => ({value: di._id, label: di.name}));
			let cloud_integrations = both_integrations
																.filter((di) => di.archetype === 'cloud')
																.map( (di) => ({value: di._id, label: di.name}));
			this.setState({device_integration_options: device_integrations, cloud_integration_options: cloud_integrations});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_configs = () => {
		if (this.props.device.nested_device_config) {
			this.state.device_config = {value: this.props.device.device_config_id, label: this.props.device.nested_device_config.name};
		}
		GetAll("device_configs", {device_type_id: this.props.device.device_type_id}).then( (configs) => {
			configs = configs.map( (config) => ({value: config._id, label: config.name}));
			this.setState({config_options: configs});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handleConfigChange = ({value}) => {
		this.setState({device_config: value});
	}

	handleDeviceIntegrationChange = ({value}) => {
		this.setState({device_integration: value});
	}
	handleCloudIntegrationChange = ({value}) => {
		this.setState({cloud_integration: value});
	}
	
	handleChange = ({ value, field }) => {
		let new_state = {};
		new_state[field] = value;
		this.setState(new_state);
	}

	handleDateChange = ({ value, field }) => {
		let new_state = {};
		new_state[field] = value;
		if(field === "activated_at")
		{
			new_state["deactivated_at"] = null;
		} else {
			new_state["activated_at"] = null;
		}
		this.setState(new_state);
	}

	handleHeartbeatPeriodChange = (change) => {
		this.setState({editing: true, heartbeat_period_changed: change.value, heartbeat_time: change.time });
	}

	handleHBValueChange = (key, enabled) => {
		let heartbeat_values = this.state.heartbeat_values;
		heartbeat_values[key].enabled = enabled;
		if (!this.state.editing) {
			this.setState({editing: true, heartbeat_values: heartbeat_values});
		} else {
			this.setState({heartbeat_values: heartbeat_values});
		}
	}

	renderHeartbeatValues = (classes) => {
		return (
			<React.Fragment>
				<div className={classes.formControl}>
					{Object.entries(this.state.heartbeat_values).map( ([key, {enabled, label}]) => (
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox color="primary" checked={enabled} onChange={() => this.handleHBValueChange(key, !enabled)} value={key} />
							}
							key={key}
							label={label}
							classes={{label: classes.formControlLabelText}}
						/>
					))}
				</div>
			</React.Fragment>
		);
	}

	render() {
		const { classes, closeModal } = this.props;
		const { device_type, device_config, device_integration, cloud_integration, config_options, device_integration_options, cloud_integration_options, name, serial, location, show_errors, heartbeat_period_changed, heartbeat_time } = this.state;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Editing Device
				</div>
				<div className={classes.inputWrapper}>
					<div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon + " " + classes.noIcon} key="Name" title="Name">
							<PersonIcon />
						</Tooltip>
						<TextInput
							field="name"
							emitChange={this.handleChange}
							priorState={name}
							error={name === "" && show_errors}
							label="Name*"
							error_message="Please give a name to this device."
							margin="none"
						/>
					</div>
					<div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon + " " + classes.noIcon} key="Name" title="Name">
							<PersonIcon />
						</Tooltip>
						<TextInput
							field="serial"
							emitChange={this.handleChange}
							priorState={serial}
							label="Serial Number"
							margin="none"
						/>
					</div>
					<div className={classes.inputContainer}>
						{/* todo replace with react-geosuggest */}
						<Tooltip className={classes.inputIcon} key="Location" title="Location">
							<LocationOnIcon />
						</Tooltip>
						<TextInput
							field="location"
							emitChange={this.handleChange}
							priorState={location}
							label="Location"
							margin="none"
						/>
					</div>
					{this.props.device.nested_device_type.type === "gateway" ? <div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Network Config">
							<SettingsIcon />
						</Tooltip>
						{config_options === null ? <Loading /> :
							<LookupInput
								priorState={{ suggestions: config_options, values: device_config }}
								label="Network Config"
								single
								emitChange={this.handleConfigChange}
							/>
						}
					</div> : "" }
					{this.hide_cloud_integration ? "" :
					<div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Cloud Integration">
							<CloudIcon />
						</Tooltip>
						{cloud_integration_options === null ? <Loading /> :
							<LookupInput
								priorState={{ suggestions: cloud_integration_options, values: cloud_integration }}
								label="Cloud Integration"
								single
								emitChange={this.handleCloudIntegrationChange}
							/>
						}
					</div>}
					{this.hide_device_integration ? "" :
					<div className={classes.inputContainer}>
						<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Device Integration">
							<ExtensionIcon />
						</Tooltip>
						{device_integration_options === null ? <Loading /> :
							<LookupInput
								priorState={{ suggestions: device_integration_options, values: device_integration }}
								label="Device Integration"
								single
								emitChange={this.handleDeviceIntegrationChange}
							/>
						}
					</div>}
					<div>
						<div className={classes.timeContainer}>
							<div className={classes.heartbeatIconsContainer}>
								<FavoriteIcon className={classes.heartbeatIcon} />
								<TimelineIcon className={classes.overlayIcon}/>
							</div>
							<span className={classes.generate}>
								{ device_type.type === 'gateway' ? "Generate a heartbeat with the fields below every" : "Expect report every at least every" }
							</span>
							<TimeInput
								margin="none"
								emitChange={this.handleHeartbeatPeriodChange}
								priorState={{ value: heartbeat_period_changed, time: heartbeat_time }}
							/>
						</div>
						{ device_type.type === 'gateway' ? this.renderHeartbeatValues(classes) : "" }
					</div>
					{this.props.device.nested_device_type.type === "gateway" ?  <div className={classes.logLevelsContainer}>
						<div className={classes.logLevelsIconsContainer}>
							<span className={classes.generate}>
								Set new log levels for the device below
							</span>
						</div>
						<div className={classes.actionInfoContainer}>
								<div className={classes.actionInfo}>
									Local Log Level
								</div>
							</div>
						<SelectInput
							field="local_level"
							emitChange={this.handleChange}
							priorState={this.state.local_level === "" ? "error" : this.state.local_level}
							options={this.localLogLevelOptions}
						/>
						<div className={classes.actionInfoContainer}>
							<div className={classes.actionInfo}>
								Forward Log Level
							</div>
						</div>
						<SelectInput
							field="forward_level"
							emitChange={this.handleChange}
							priorState={this.state.forward_level === "debug" || this.state.forward_level === "info" || this.state.forward_level === "" ? "error" : this.state.forward_level}
							options={this.forwardLogLevelOptions}
						/>
					</div> : "" }
					<div className={classes.inputDatesContainer}>
					{this.props.device.nested_device_type.type !== "gateway" && !this.props.device.activated_at ? 
						<DateSelection
								label={"Activated at"}
								emitChange={this.handleDateChange}
								priorState={{firstDate: this.state.activated_at ? this.state.activated_at.firstDate : null }}
								field="activated_at"
						/>
					: '' }
					{this.props.device.nested_device_type.type !== "gateway" && !this.props.device.deactivated_at ? 
						<DateSelection
								label={"Deactivated at"}
								emitChange={this.handleDateChange}
								priorState={{firstDate: this.state.deactivated_at ? this.state.deactivated_at.firstDate : null }}
								field="deactivated_at"
						/>
					: '' }
					</div>
				</div>
				<div className={classes.buttonsContainer}>
					<Button color="primary" onClick={closeModal}>
						cancel
					</Button>
					<Button variant="contained" color="primary" size="large" className={classes.saveButton} onClick={this.saveDevice}>
						Save
					</Button>
				</div> 
			</div> 
		);
	}

	saveDevice = () => {
		if (this.state.name === '') {
			this.setState({show_errors: true});
			return;
		}
		let device_model = new Device(this.props.device);
		let new_device = device_model.editableCopy();
		new_device.name = this.state.name;
		new_device.serial = this.state.serial;
		new_device.log_config = {};
		new_device.log_config.local_level = this.state.local_level === "" ? "error" : this.state.local_level;
		new_device.log_config.forward_level = this.state.forward_level === "" ? "error" : this.state.forward_level;
		if (this.props.device.nested_device_type.type === "gateway") {
			new_device.device_config_id = this.state.device_config ? this.state.device_config.value : null;
		}
		new_device.location = this.state.location;
		if (this.state.device_integration) {
			new_device.device_integration_id = this.state.device_integration.value;
		}
		if (this.state.cloud_integration) {
			new_device.cloud_native_integration_id = this.state.cloud_integration.value;
		}
		let heartbeat_values = [];
		Object.entries(this.state.heartbeat_values).forEach( ([key, {enabled}]) => {
			if (enabled) {
				heartbeat_values.push(key);
			}
		});
		switch (this.state.heartbeat_time) {
			case "Seconds":
				new_device.heartbeat_period = parseInt(this.state.heartbeat_period_changed);
				break;
			case "Minutes":
				new_device.heartbeat_period = this.state.heartbeat_period_changed * 60;
				break;
			case "Hours":
				new_device.heartbeat_period = this.state.heartbeat_period_changed * 3600;
				break;
			case "Days":
				new_device.heartbeat_period = this.state.heartbeat_period_changed * 86400;
				break;
		}
		new_device.heartbeat_values = heartbeat_values;
		new_device.activated_at = this.state.activated_at ? this.state.activated_at.firstDate : null;
		new_device.deactivated_at = this.state.deactivated_at ? this.state.deactivated_at.firstDate : null;
		device_model.setData(new_device).save().then( (result) => {
			this.props.onSave(result);
			this.context.openSnackbar('Device saved.', 'success');
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}
}
EditDevice.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(EditDevice));
