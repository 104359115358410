import React from 'react';
import TabsContainer from '../components/Containers/TabsContainer';
import { withStyles } from '@material-ui/core/styles';


const styles = {
	container: {
		height: "calc(100vh - 112px)",
	}
}

class PollableAttributesPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.props = props;
		this.initial = {type: "pollable_attributes"}
	}

	render() {
		const { classes } = this.props;
		return(
			<div className={classes.container}>
				<TabsContainer 
					initial={this.initial}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(PollableAttributesPage);
