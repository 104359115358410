import React from 'react';
import Translator from '../../../services/DataModels/Translator';
import CreateTranslatorForm from '../../IngestSpecific/CreateTranslatorForm';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
	container: {
		width: "100%",
		overflowY: "scroll",
		overflowX: "hidden",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	contentContainer: {
		display: "flex",
		flexWrap: "nowrap",
		margin: "12px 10%",
	}
});

class TranslatorDetailTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			dataModel: null,
		};
		this.props.tabHostProxy.setTabRefresh(this.refresh);
	}

	componentDidMount() {
		this.prepare_translator();
	}

	refresh = () => {
		this.setState({
			dataModel: null
		});
		new Translator({ _id: this.props.data._id }).readFromAPI().then((translator) => {
			this.props.tabHostProxy.updateTitle(translator._id, translator);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			this.prepare_translator(translator);
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	prepare_translator = (translator) => {
		let dataModel = translator ? new Translator(translator) : new Translator(this.props.data);
		this.setState({
			dataModel: dataModel
		});
	}

	
	cancel = () => {
		this.props.tabHostProxy.closeSelf();
	}

	create = (result) => {
		this.props.tabHostProxy.closeSelf();
		this.props.tabHostProxy.addTab("translator", result);
		this.props.tabHostProxy.refresh();
	}

	render() {
		const { classes, tabHostProxy } = this.props;
		const { dataModel } = this.state;
		return (
			<div className={classes.container}>
				{ dataModel === null ?
					<Loading />
					:
					<div className={classes.contentContainer}>
						<CreateTranslatorForm
							className={classes.formContainer}
							tabHostProxy={tabHostProxy}
							onCreate={this.create}
							onCancel={this.cancel}
							translator={dataModel.data}
						/>;
					</div>
				}
			</div>
		)
	}
}

TranslatorDetailTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(TranslatorDetailTab));
