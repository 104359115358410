import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Notification from '../../services/DataModels/Notification';
import Report from '../../services/DataModels/Report';
import GatewayCommand from '../../services/DataModels/GatewayCommand';
import CardContainer from '../Containers/CardContainer';
import Button from '@material-ui/core/Button';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import { AssignNestedModels } from '../../services/CLURDUtilities';

//icons
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import DraftsIcon from '@material-ui/icons/Drafts';
import MessageIcon from '@material-ui/icons/Message';
import BuildIcon from '@material-ui/icons/Build';
import Loading from '../DisplayOriented/Loading';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import Permissions from '../../services/Permissions';



const styles = theme => ({
	tileContent: {
		height: "calc(100% - 65px)",
		overflow: "auto",
	},
	modalWrapper: {
		padding: "24px"
	},
	statusMessage: {
		color: "silver",
		padding: "12px",
		backgroundColor: "black",
		fontFamily: "monospace",
		overflowX: "auto",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#ffffffc2"
		}
	},
	recentAlert: {
		display: "flex",
		alignItems: "center",
		margin: "12px",
	},
	dateContainer: {
		marginLeft: "12px",
		textAlign: "center",
		width: "20%"
	},
	date: {
		fontSize: "14px",
		fontWeight: 500
	},
	time: {
		fontSize: "14px",
		color: theme.palette.greyIcon.main,
	},
	contentContainer: {
		margin: "auto",
		marginLeft: 0
	},
	messageContainer: {
		display: "flex",
		flexWrap: "wrap",
		color: theme.palette.greyIcon.main,
		alignItems: "center",
		marginLeft: "12px",
	},
	commandTitle: {
		color: "black"
	},
	commandStatus: {
		textTransform: "capitalize"
	},
	inputIcon: {
		color: theme.palette.greyIcon.main,
		fontSize: "22px",
		marginRight: "8px",
	},
	iconContainer: {
		color: theme.palette.greyIcon.main,
	},
	link: {
		color: "#4a90e2",
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		}
	},
	payloadLink: {
		textDecoration: "underline"
	},
	noData: {
		margin: "6px 12px",
	},
	noPackage: {
		
	},
	mostRecent: {
		marginTop: "12px",
		marginLeft: "12px",
	},
	recentSoftwareCommand: {
		display: "flex",
		alignItems: "center",
		margin: "12px",
	},
	overviewContainer: {
		width: "100%",
		height: "calc(100% - 87px)",
		minHeight: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
	},
	overviewTile: {
		width: "50%",
		height: "50%",
		display: "flex",
	},
	overviewTileMargin1: {
		marginRight: "6px",
		marginBottom: "6px",
		width: "100%",
	},
	overviewTileMargin2: {
		marginLeft: "6px",
		marginBottom: "6px",
		width: "100%",
	},
	overviewTileMargin3: {
		marginRight: "6px",
		marginTop: "6px",
		width: "100%",
	},
	overviewTileMargin4: {
		marginLeft: "6px",
		marginTop: "6px",
		width: "100%",
	},
	cardContainerRoot: {
		height: "100%",
	},
	buttonContainer: {
		marginTop: "32px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	heartbeatIconsContainer: {
		position: "relative",
		color: theme.palette.greyIcon.main,
	},
	overlayIcon: {
		position: "absolute",
		left: 0,
		color: "white"
	},
});

class DeviceOverview extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			recent_alerts: null,
			recent_commands: null,
			recent_software_commands: null,
			recent_reports: null,
			modal: {
				open: false,
				children: () => ""
			},
		}
		this.loadRecents();
	}

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	};

	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	};

	loadRecents = () => {
		let promises = this.determine_allowed_data();
		Promise.all(promises);
	}

	determine_allowed_data = () => {
		let promises = [];
		if (Permissions.allow(["read"], "gateway_command", this.props.device.company_id)) {
			promises = [this.loadRecentSoftwareCommands(), this.loadRecentCommands()];
		}
		if (Permissions.allow(["read"], "report", this.props.device.company_id)) {
			promises.push(this.loadRecentReports());
		}
		if (Permissions.allow(["read"], "notification", this.props.device.company_id)) {
			promises.push(this.loadRecentAlerts());
		}
		return promises;
	}

	getGatewayCommandId = () => {
		let device_id = null;
		if (this.props.device.parent_device_id && this.props.device.parent_device_id !== "") {
			device_id = this.props.device.parent_device_id;
		} else if (["gateway", "cloud_native", "lwm2m"].includes(this.props.device.nested_device_type.type)) {
			device_id = this.props.device._id;
		} 
		return device_id;
	}

	readable = (string) => {
		if (string.indexOf("_") !== -1) {
			let formatted = "";
			string.split("_").forEach( (section) => {
				formatted += section.charAt(0).toUpperCase() + section.slice(1) + " ";
			});
			string = formatted;
		} else {
			string = string.charAt(0).toUpperCase() + string.slice(1);
		}
		return string;
	}

	loadRecentAlerts = () => {
		return new Promise( (resolve, reject) => {
			let params = { device_id: this.props.device._id };
			new Notification().listFromAPI(params).then( (results) => {
				let alerts = results.items;
				if (Permissions.allow(["read"], "rule", this.props.device.company_id)) {
					AssignNestedModels("rules", "rule_id", alerts).then( () => {
						this.setState({ recent_alerts: alerts }, resolve);
					}).catch( (error) => {
						this.context.openSnackbar(error, "error");
					});
				} else {
					this.setState({ recent_alerts: alerts });
					resolve();
				}
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		});
	}
	
	loadRecentReports = () => {
		return new Promise( (resolve, reject) => {
			let params = { device_id: this.props.device._id };
			new Report().listFromAPI(params).then( (results) => {
				let reports = results.items;
				this.setState({ recent_reports: reports });
				resolve();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		});
	}

	loadRecentSoftwareCommands = () => {
		let device_id = this.getGatewayCommandId();
		if (!device_id) {
			this.state.recent_software_commands = [];
			return Promise.resolve();
		}
		return new Promise( (resolve, reject) => {
			let params = {
				per_page: 5,
				order_by: "-created_at",
				device_id: device_id,
				command_type: "software_update"
			};
			if (device_id === this.props.device._id) {
				params.attached_device_unique_ids = "null";
			} else {
				params.attached_device_unique_ids_inc = this.props.device.unique_id;
			}
			let recent_software_commands = [];
			new GatewayCommand().listFromAPI(params).then( ({items}) => {
				items.forEach( (cmd) => {
					if (cmd.statuses !== null) {
						if (!cmd.statuses[this.props.device.unique_id]) {
							recent_software_commands.push({_id: cmd._id, created_at: cmd.created_at, status_message: "Pending", status: "Pending", package_id: cmd.software_update_id});
						}
						Object.entries(cmd.statuses).forEach( (status) => {
							if (status[0] === this.props.device.unique_id) {
								recent_software_commands.push({_id: cmd._id, created_at: cmd.created_at, status_message: status[1].status_message, status: status[1].status, package_id: cmd.software_update_id});
							}
						});
					}
				});
				this.setState( {recent_software_commands: recent_software_commands});
				resolve();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		});
	}

	loadRecentCommands = () => {
		let device_id = this.getGatewayCommandId();
		if (!device_id) {
			this.state.recent_commands = [];
			return Promise.resolve();
		}
		return new Promise( (resolve, reject) => {
			let params = {
				per_page: 5,
				order_by: "-created_at",
				device_id: device_id,
			};
			if (device_id !== this.props.device._id) {
				params.attached_device_unique_ids_inc = this.props.device.unique_id;
			}
			let recent_commands = [];
			new GatewayCommand().listFromAPI(params).then( ({items}) => {
				items.forEach( (cmd) => {
					if (cmd.statuses !== null) {
						let command = {};
						//if the device is not the gateway
						if (device_id !== this.props.device._id) {
							let attached_device_status = Object.entries(cmd.statuses).find( (status) => {
								return status[0] === this.props.device.unique_id;
							});
							if (attached_device_status) {
								command = { command_type: cmd.command_type, created_at: cmd.created_at, status: attached_device_status[1].status, status_message: attached_device_status[1].status_message};
							} else {
								command = { command_type: cmd.command_type, created_at: cmd.created_at, status: "Pending Response"};
							}
							command.command_type = this.readable(command.command_type);
							recent_commands.push(command);
						} else {
							if (cmd.attached_device_unique_ids && cmd.attached_device_unique_ids.length > 0) {
								//for each of the attached devices, find the status
								cmd.attached_device_unique_ids.forEach( (uid) => {
									if (cmd.statuses[uid]) {
										command = { command_type: cmd.command_type, created_at: cmd.created_at, device: uid, status: cmd.statuses[uid].status, status_message: cmd.statuses[uid].status_message};
									} else {
										command = { command_type: cmd.command_type, created_at: cmd.created_at, device: uid, status: "Pending Response"};
									}
									command.command_type = this.readable(command.command_type);
									recent_commands.push(command);
								});
							} else {
								let gw_command = Object.entries(cmd.statuses)[0][1];
								let command = { command_type: cmd.command_type, created_at: cmd.created_at, status: gw_command.status, status_message: gw_command.status_message};
								command.command_type = this.readable(command.command_type);
								recent_commands.push(command);
							}
						}
					}
				});
				this.setState({recent_commands: recent_commands});
				resolve();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		});
	}

	openPayloadModal = (payload, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<pre className={classes.statusMessage}>
							{JSON.stringify(payload, null, 2)}
						</pre>
						<div className={classes.buttonContainer}>
							<Button
								color="primary" className={classes.button}
								onClick={this.closeModal}
							>
								CLOSE
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	closeModal = () => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	}

	renderRecentAlertsTile = (classes) => {
		return (
			<div className={classes.tileContent}>
				{this.state.recent_alerts ?
					this.state.recent_alerts.length > 0 ?
						this.state.recent_alerts.map( (alert) => (
							<div key={alert._id} className={classes.recentAlert}>
								<div className={classes.dateContainer}>
									<div className={classes.date}>
										{this.showDate(alert.created_at)}
									</div>
									<div className={classes.time}>
										{this.showTime(alert.created_at)}
									</div>
								</div>
								<div className={classes.contentContainer}>
									<div className={classes.messageContainer}>
										<Tooltip key="Alert Message" title="Alert Message">
											<MessageIcon className={classes.inputIcon}/>
										</Tooltip>
										{alert.message}
									</div>
									{Permissions.allow(["read"], "rule", alert.company_id) ? <div className={classes.messageContainer}>
										<Tooltip title="Policy">
											<SecurityIcon className={classes.inputIcon}/>
										</Tooltip>
										{alert.rule_id && alert.rule_id !== "" && alert.nested_rule ?
											<Button color="primary" onClick={() => this.props.tabHostProxy.addTab("rule", alert.nested_rule)}>
												{alert.nested_rule.description}
											</Button>
										:
											"Alert triggered without a policy."
										}
									</div> : ""}
								</div>
							</div>
						))
						:
						<div className={classes.noData}>
							None.
						</div>
					: <Loading/>
				}
			</div>
		);
	}

	renderRecentReportsTile = (classes) => {
		return (
			<div className={classes.tileContent}>
				{this.state.recent_reports ?
					this.state.recent_reports.length > 0 ?
						this.state.recent_reports.map( (report) => (
							<div key={report._id} className={classes.recentAlert}>
								<Tooltip key="Message Type" title={report.heartbeat ? "Heartbeat" : "Report"}>
									{report.heartbeat ?
										<div className={classes.heartbeatIconsContainer}>
											<FavoriteIcon />
											<TimelineIcon className={classes.overlayIcon} />
										</div>
										:
										<div className={classes.iconContainer}>
											<InboxIcon />
										</div>
									}
								</Tooltip>
								<div className={classes.dateContainer}>
									<div className={classes.date}>
										{this.showDate(report.created_at)}
									</div>
									<div className={classes.time}>
										{this.showTime(report.created_at)}
									</div>
								</div>
								<div className={classes.contentContainer}>
									<div className={classes.messageContainer}>
										<Button color="primary" onClick={() => this.openPayloadModal(report.payload, classes)}>
											{report.heartbeat ?
												"View Heartbeat"
											:
												"View Payload"
											}
										</Button>
									</div>
								</div>
							</div>
						))
					:
					<div className={classes.noData}>
						None.
					</div>
				: <Loading />
				}
			</div>
		);
	}

	renderSoftwareTile = (classes) => {
		return (
			<div className={classes.tileContent}>
				{this.state.recent_software_commands ?
					this.state.recent_software_commands.length > 0 ?
						this.state.recent_software_commands.map( (cmd, index) => {
							let softwareUpdate = this.props.softwareUpdatesByID[cmd.package_id];
							return (
								<div key={cmd._id}>
									<div  className={classes.recentSoftwareCommand}>
										<Tooltip key="Software Package" title="Software Update">
											<CodeIcon className={classes.inputIcon}/>
										</Tooltip>
										<div className={classes.dateContainer}>
											<div className={classes.date}>
												{this.showDate(cmd.created_at)}
											</div>
											<div className={classes.time}>
												{this.showTime(cmd.created_at)}
											</div>
										</div>
										<div className={classes.contentContainer}>
											<div className={classes.messageContainer}>
												{softwareUpdate != null ?
													<React.Fragment>
														<span className={classes.commandTitle}>Package:&nbsp;</span>
														<Button color="primary" onClick={() => this.props.openSoftwareUpdateTab(this.props.device.nested_device_type)}>
															{softwareUpdate.name}
														</Button>
													</React.Fragment>
													:
													<span className={classes.noPackage}>
														<span className={classes.commandTitle}>Package:</span> Not Found
													</span>
												}
												
											</div>
											<div className={classes.messageContainer + " " + classes.commandStatus}>
												<span className={classes.commandTitle}>Update Status:&nbsp;</span>
												<div>
													{cmd.status === "fail" ?
														<Button color="primary" onClick={() => this.openPayloadModal(cmd.status_message)}>
															Fail
														</Button>
														:
														" " + cmd.status
													}
													{cmd.status === "downloaded" ?
														<Button color="primary" onClick={() => this.openPayloadModal(cmd.status_message)}>
															Execute
														</Button>
														:
														" " + cmd.status
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})
						:
						<div className={classes.noData}>
							None.
						</div>
				: <Loading/>
				}
			</div>
		);
	}

	renderCommandStatusTile = (classes) => {
		const { recent_commands } = this.state;
		if (!recent_commands) {
			return ( <Loading />);
		} else if (recent_commands.length === 0) {
			return (
				<div className={classes.noData}>
					None.
				</div>
			);
		}
		return (
			<div className={classes.tileContent}>
				{recent_commands.map( (cmd, index) => (
					<div key={"recent_command_" + index} className={classes.recentSoftwareCommand}>
						<Tooltip key="Command Type" title={"Command Type: " + cmd.command_type}>
							<BuildIcon className={classes.inputIcon}/>
						</Tooltip>
						<div className={classes.dateContainer}>
							<div className={classes.date}>
								{this.showDate(cmd.created_at)}
							</div>
							<div className={classes.time}>
								{this.showTime(cmd.created_at)}
							</div>
						</div>
						<div className={classes.contentContainer}>
							<div className={classes.messageContainer + " " + classes.commandTitle}>
								{cmd.command_type}
							</div>
							
							<div className={classes.messageContainer + " " + classes.commandStatus}>
								{cmd.device ?
									<div>
										<span className={classes.commandTitle}>Child: </span>{cmd.device}&nbsp;
									</div>
									: ""
								}
								<span className={classes.commandTitle}>Status:&nbsp;</span>
								{cmd.status === "fail" ?
									<Button color="primary" onClick={() => this.openPayloadModal(cmd.status_message)}>
										Fail
									</Button>
									:
									" " + cmd.status
								}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		const company_id = this.props.device.company_id;
		const cards = [];
		if (Permissions.allow(["read"], "notification", company_id)) {
			cards.push((classes, index) => (
				<div key="alertcard" className={classes.overviewTile}>
					<div className={classes["overviewTileMargin" + index]}>
						<CardContainer
							title="Recent Alerts"
							rootClass={classes.cardContainerRoot}
						>
							{this.renderRecentAlertsTile(classes)}
						</CardContainer>
					</div>
				</div>
			));
		}
		if (Permissions.allow(["read"], "gateway_command", company_id)) {
			cards.push((classes, index) => (
				<div key="commandcard" className={classes.overviewTile}>
					<div className={classes["overviewTileMargin" + index]}>
						<CardContainer
							title="Recent Command Status"
							rootClass={classes.cardContainerRoot}
						>
							{this.renderCommandStatusTile(classes)}
						</CardContainer>
					</div>
				</div>
			));
			cards.push((classes, index) => (
				<div key="softwarecard" className={classes.overviewTile}>
					<div className={classes["overviewTileMargin" + index]}>
						<CardContainer
							title="Recent Software Updates"
							rootClass={classes.cardContainerRoot}
						>
							{this.renderSoftwareTile(classes)}
						</CardContainer>
					</div>
				</div>
			));
		}
		if (Permissions.allow(["read"], "report", company_id)) {
			cards.push((classes, index) => (
				<div key="reportcard" className={classes.overviewTile}>
					<div className={classes["overviewTileMargin" + index]}>
						<CardContainer
							title="Recent Heartbeats and Reports"
							rootClass={classes.cardContainerRoot}
						>
							{this.renderRecentReportsTile(classes)}
						</CardContainer>
					</div>
				</div>
			));
		}
		return (
			<div className={classes.overviewContainer}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				{cards.map( (card, index) => card(classes, index + 1))}
			</div>
		);
	}
}

DeviceOverview.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceOverview));
