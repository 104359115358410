import React from 'react';
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import SwitchInput from '../Inputs/SwitchInput';
import Permissions from '../../services/Permissions';
import Integration from '../../services/DataModels/Integration';
import Humanize from '../../services/Humanize';
import { GetAll } from '../../services/CLURDUtilities';
import { Button } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Auth from '../../services/Auth';
import Loading from '../DisplayOriented/Loading';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

import { darken } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    formContainer: {
        overflowY: "auto",
        width: "100%",
        marginRight: "12px",
        fontFamily: "Inter",
        color: "grey",
        fontSize: "18px"
    },
    integrationFormButtonArea: {
        margin: "32px 0 16px auto",
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
    },
    typeInput: {
        marginTop: "16px",
        marginBottom: "8px",
    },
    details: {
        fontFamily: "Inter",
        color: "grey",
        fontSize: "18px",
    },
    inputContainer: {
        margin: "12px 0 12px 0",
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap"
    },
    inputGreengrassVersion:{
        margin: "32px 0 12px 0",
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap"
    },
    switchInputContainer: {
        marginBottom: "12px",
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap"
    },
    deleteButton: {
        margin: "6px",
    },
    invalidGreengrass: {
        color: theme.palette.red.main,
        fontSize: "16px",
        marginBottom: "28px"
    },
    link: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: "24px"
    },
    ggInfoText: {
        marginBottom: "8px",
        fontSize: "14px",
        color: "#8e8e93",
    },
    modalWrapper: {
        padding: "24px",
        fontFamily: "Inter",
    },
    img: {
        width: "100%",
        margin: "12px"
    },
    ggModalHeadline: {
        margin: "12px 0 12px 0",
        fontFamily: "Inter",
        fontSize: "26px"
    },
    ggModalGreengrassV2LatestLink: {
        margin: "12px 0 12px 0",
        fontFamily: "Inter",
        color: "#3a5868",
        fontSize: "16px"
    },
    ggModalText: {
        fontFamily: "Inter",
        color: "#8e8e93",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "16px",
        marginBottom: "12px"
    },
    validationMessage: {
        fontSize: "16px",
        color: "#8e8e93",
    }
});


class IntegrationForm extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            show_errors: false,
            modal: {
                open: false,
                children: () => ""
            },
            company_options: null,
            editable_integration: this.props.dataModel.editableCopy(),
            validation: Integration.validate(this.props.dataModel.editableCopy(), this.props.integrationTypes)
        };
        this.editing_disabled = this.state.editable_integration._id && !Permissions.allow(["update"], "integration", this.state.editable_integration.company_id);
        this.delete_disabled = !(this.state.editable_integration._id && Permissions.allow(["delete"], "integration", this.state.editable_integration.company_id));
        if (!this.state.editable_integration.company_id || this.state.editable_integration.company_id === '') {
            this.state.editable_integration.company_id = Auth.currentCompany()._id;
        }
        this.load_companies();
    }

    load_companies = () => {
        GetAll("companies").then((companies) => {
            this.setState({ company_options: companies.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0)) });
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    can_save = () => {
        let show_errors = false;
        const editable_integration = this.state.editable_integration;
        const new_validation = Integration.validate(editable_integration, this.props.integrationTypes);
        show_errors = !new_validation.valid;
        this.setState({ show_errors: show_errors, validation: new_validation });
        return !show_errors;
    }

    save_integration = () => {
        if (this.can_save()) {
            this.props.onSave(this.state.editable_integration);
        }
    }

    gg_URL_instructions = () => {
        this.setState({
            modal: {
                open: true,
                prompt: null,
                yesFunction: null,
                children: () => this.render_gg_instructions()
            }
        });
    }

    render_gg_instructions = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.modalWrapper}>
                <div className={classes.ggModalHeadline}>Greengrass V2</div>
                <div className={classes.ggModalText}>
                    To install the latest Greengrass 2, you can copy and paste the following download URL:
                </div>
                <div className={classes.ggModalGreengrassV2LatestLink}>https://d2s8p88vqu9w66.cloudfront.net/releases/greengrass-nucleus-latest.zip</div>
                <div className={classes.ggModalText}>
                    For specific Greengrass 2.x versions please follow the link below on how to assemble the download URL:
                </div>
                <Button
                    color="primary"
                    className={classes.link}
                    target="_blank"
                    href="https://docs.aws.amazon.com/greengrass/v2/developerguide/manual-installation.html#download-greengrass-core-v2"
                >
                    AWS Greengrass Docs - Download Instructions (V2)
                </Button>
                <div className={classes.ggModalHeadline}>Greengrass V1</div>
                <div className={classes.ggModalText}>
                    Follow this link to AWS's Greengrass Download Page
                </div>
                <Button
                    color="primary"
                    className={classes.link}
                    target="_blank"
                    href="https://docs.aws.amazon.com/greengrass/latest/developerguide/what-is-gg.html#gg-core-download-tab"
                >
                    AWS Greengrass Docs - Download Instructions (V1)
                </Button>
                
                <div >
                    <img className={classes.img} src={require("../../images/gg_url.png").default} />
                </div>
                <div className={classes.ggModalText}>
                    You'll see something similar to the image above. Versions are specified in the tabs. Download URLs for the specific architectures are in the table. Right-click on the link and select "Copy Link Address" and paste the URL into the text field.
                </div>
            </div>
        );
    }

    toggleGreengrass = ({ field, value }) => {
        this.setState((prev_state) => {
            let new_integration = prev_state.editable_integration;
            new_integration[field] = value ? "" : null;
            let new_validation = Integration.validate(new_integration, this.props.integrationTypes);
            return { editable_integration: new_integration, validation: new_validation };
        });
    }

    handleGreenGrassVersionChange = ({ field, value }) => {
        this.setState((prev_state) => {
            let new_integration = prev_state.editable_integration;
            new_integration[field] = value;
            let new_validation = Integration.validate(new_integration, this.props.integrationTypes);
            return { editable_integration: new_integration, validation: new_validation };
        });
    }

    handleFieldChange = (input) => {
        let new_integration = this.state.editable_integration;
        if (input.field === 'type') {
            if (this.props.integrationTypes != null && this.state.editable_integration != null && this.state.editable_integration.type != '') {
                let integrationType = this.props.integrationTypes[this.state.editable_integration.type];
                integrationType.fields.forEach((field) => {
                    new_integration[field] = null;
                });
            }
        }
        new_integration[input.field] = input.value;
        let new_validation = Integration.validate(new_integration, this.props.integrationTypes);
        this.setState({ editable_integration: new_integration, validation: new_validation });
    }

    displayFieldsForIntegration = (integration, integrationTypes) => {
        if (integrationTypes != null) {
            return integrationTypes[integration.type].fields.map((field) =>
                <div>{Humanize.humanizeSnakeCaseString(field)}: {integration[field]}</div>
            );
        }
    }

    integrationTypeForm = (classes) => {
        if (this.props.integrationTypes != null && this.state.editable_integration.type !== '') {
            let integrationType = this.props.integrationTypes[this.state.editable_integration.type];
            return (
                <React.Fragment>
                    {integrationType.fields.map((field) => {
                        let key = `val-field-${field}`
                        if (field == 'greengrass_core_install_url') {
                            return (
                                <div key={key}>
                                    <div className={classes.switchInputContainer}>
                                        <SwitchInput
                                            disabled={this.state.validation.editable == false || this.editing_disabled || this.props.cancel != null}
                                            containerClass={classes.switchContainer}
                                            initial={this.state.editable_integration.greengrass_core_install_url == null ? false : true}
                                            emitChange={this.toggleGreengrass}
                                            onLabel="Include AWS Greengrass"
                                            offLabel="Include AWS Greengrass"
                                            field="greengrass_core_install_url"
                                            location="start"
                                        />
                                    </div>
                                    {this.state.editable_integration.greengrass_core_install_url != null ?

                                        <div>
                                            <div className={classes.inputGreengrassVersion}>
                                                <SelectInput
                                                    disabled={this.state.validation.editable == false || this.editing_disabled}
                                                    emitChange={this.handleFieldChange}
                                                    priorState={this.state.editable_integration.greengrass_version}
                                                    field={"greengrass_version"}
                                                    label="Greengrass Version*"
                                                    value={this.state.editable_integration.greengrass_version}
                                                    error={this.state.show_errors && this.validationMessagesFor('greengrass_version') !== null}
                                                    error_message={this.validationMessagesFor('greengrass_version')}
                                                    options={[{
                                                        display: "Greengrass v2",
                                                        value: "v2"
                                                    }, {
                                                        display: "Greengrass v1",
                                                        value: "v1"
                                                    }]}
                                                />
                                            </div>
                                            <div className={classes.ggInfoText}>
                                                In order to setup the Greengrass integration we need a URL to the installation file.
                                            </div>
                                            <div>
                                                <Button color="primary" className={classes.link} onClick={this.gg_URL_instructions}>
                                                    These instructions will help you locate your version's URL.
                                                </Button>
                                            </div>
                                            <div className={classes.inputContainer}>
                                                <TextInput
                                                    margin="none"
                                                    disabled={this.state.validation.editable == false || this.editing_disabled}
                                                    emitChange={this.handleFieldChange}
                                                    priorState={this.state.editable_integration.greengrass_core_install_url}
                                                    label={"AWS IoT Greengrass Core Install URL*"}
                                                    field="greengrass_core_install_url"
                                                />
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                            );
                        } else {
                            return (
                                <div className={classes.inputContainer} key={key}>
                                    <TextInput
                                        margin="none"
                                        disabled={this.state.validation.editable == false || this.editing_disabled}
                                        emitChange={this.handleFieldChange}
                                        priorState={this.state.editable_integration[field]}
                                        label={Humanize.humanizeSnakeCaseString(field) + "*"}
                                        key={field}
                                        field={field}
                                        error={this.state.show_errors && this.validationMessagesFor(field) !== null}
                                        error_message={this.validationMessagesFor(field)}
                                    />
                                </div>
                            );
                        }
                    })}
                </React.Fragment>
            );
        }
    }

    buttonsForIntegration = () => {
        let buttons = [];
        const integration = this.state.editable_integration;
        const validation = this.state.validation;
        const classes = this.props.classes;

        if (integration._id != null) {
            buttons.push(
                <Button
                    disabled={this.delete_disabled}
                    key="deleteButton"
                    className={classes.deleteButton}
                    onClick={this.openDeleteConfirmModal}
                    color="primary"
                >
                    DELETE
                </Button>
            );
        }

        if (this.props.cancel) {
            buttons.push(
                <Button
                    key="cancelbutton"
                    className={classes.button}
                    onClick={this.props.cancel}
                    color="primary"
                >
                    CANCEL
                </Button>
            );
        }

        buttons.push(
            <React.Fragment key="save-button-fragment">
                <Button
                    key="saveButton"
                    disabled={validation.editable == false || this.editing_disabled}
                    className={classes.button}
                    onClick={this.save_integration}
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    {this.state.editable_integration._id ? "SAVE" : "CREATE"} INTEGRATION
                </Button>
            </React.Fragment>
        );

        return (
            <div className={classes.integrationFormButtonArea}>
                {buttons}
            </div>
        )
    }

    openDeleteConfirmModal = () => {
        this.setState({
            modal: {
                open: true,
                prompt: "Are you sure you want to delete this integration?",
                yesFunction: this.props.onDelete,
                functionText: "DELETE INTEGRATION",
                children: () => ""
            }
        });
    }

    closeDeleteConfirmModel = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    validationMessagesFor = (field) => {
        let messages = this.state.validation[field];
        if (messages != null && messages.length > 0) {
            return messages.map((msg) => msg += " ");
        } else {
            return null;
        }
    }

    render() {
        const { classes, integrationOptions } = this.props;
        const { modal, company_options, validation, editable_integration, show_errors } = this.state;
        return (
            <div id="integration-edit-form" className={classes.formContainer}>
                <div className={classes.inputContainer}>
                    <TextInput
                        margin="none"
                        error={show_errors && this.validationMessagesFor('name') !== null}
                        error_message={this.validationMessagesFor('name')}
                        disabled={validation.editable == false || this.editing_disabled}
                        emitChange={this.handleFieldChange}
                        priorState={editable_integration.name}
                        label={"Name*"}
                        field={"name"}
                    />
                </div>
                <div className={classes.inputContainer}>
                    <TextInput
                        margin="none"
                        disabled={validation.editable == false || this.editing_disabled}
                        emitChange={this.handleFieldChange}
                        priorState={editable_integration.long_description}
                        label={"Description"}
                        field={"long_description"}
                    />
                </div>
                {Auth.currentUser().company_ids.length > 1 ?
                    <div className={classes.inputContainer}>
                        {company_options === null ? <Loading /> : <SelectInput
                            field="company_id"
                            disabled={validation.editable == false || this.editing_disabled || this.props.cancel != null}
                            emitChange={this.handleFieldChange}
                            label="Account"
                            priorState={editable_integration.company_id}
                            options={company_options.map((option) => ({ display: option.name, value: option._id }))}
                        />}
                    </div>
                    : ""}
                <div className={classes.inputContainer}>
                    <SelectInput
                        disabled={validation.editable == false || this.editing_disabled}
                        emitChange={this.handleFieldChange}
                        priorState={editable_integration.type}
                        field={"type"}
                        label="Integration Type*"
                        value={editable_integration.type}
                        error={show_errors && this.validationMessagesFor('type') !== null}
                        error_message={this.validationMessagesFor('type')}
                        options={integrationOptions}
                    />
                </div>
                {this.integrationTypeForm(classes)}
                <div className={classes.validationMessage}>{this.validationMessagesFor("integration")}</div>
                {this.buttonsForIntegration()}
                <SimpleModalWrapped info={modal} closeModal={this.closeDeleteConfirmModel}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
            </div>
        )
    }
}

IntegrationForm.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(IntegrationForm));
