import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';

class BulkResponse extends DataModel {
	constructor() {
		super("bulk_responses");
	}

	static getHeadings(){
		return [
			{label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date"},
			{label: "Summary", field: "table_summary", align: "center", disablePadding: false, sortable: false, content: "function"},
			{label: "Successes", field: "table_successes", align: "right", disablePadding: false, sortable: false},
			{label: "Failures", field: "table_failures", align: "right", disablePadding: false, sortable: false},
			{label: "Detail", field: "table_detail", align: "left", disablePadding: false, sortable: false},
			{label: "ACCOUNT", value: "nested_company.name", field: "name", align: "left", nested_field: true, disablePadding: false, sortable: true},
		];
	}

	static getBulkResponses(params) {
		return new Promise( (resolve, reject) => {
			new BulkResponse().listFromAPI(params).then( (result) => {
				let responses = result.items;
				AssignNestedModels("companies", "company_id", responses).then( () => {
					result.items = responses;
					resolve(result);
				}).catch( (error) => {
					reject(error);
				});
			}).catch( (error) => {
				reject(error);
			});
		});
	}
}

export default BulkResponse;
