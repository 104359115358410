import React from 'react';

//services
import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';
import Permissions from "../Permissions";
import Auth from '../Auth';
import { LIST } from '../CLURD';

//mui
import Tooltip from '@material-ui/core/Tooltip';

//icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

function capitalize(word){
	return word.charAt(0).toUpperCase() + word.slice(1);
}
class GatewayCommand extends DataModel {
	constructor() {
		super('gateway_commands')
	}

	static getHeadings() {
		return [
			{label: "DATE/TIME", field: "created_at", align: "center", disablePadding: false, sortable: false, content: "date"},
			{label: "Status", field: "table_status", align: "center", disablePadding: false, sortable: false},
			{label: "Action", field: "table_action", align: "left", disablePadding: false, sortable: false},
			{label: "Detail", field: "table_detail", align: "left", disablePadding: false, sortable: false},
			{label: "ACCOUNT", value: "nested_company.name", field: "name", align: "left", nested_field: true, disablePadding: false, sortable: true},
		];
	}

	static getCommands(params, theme){
		return new Promise( (resolve, reject) => {
			new GatewayCommand().listFromAPI(params).then( (result) => {
				let commands = result.items;
				AssignNestedModels("companies", "company_id", commands).then( () => {
					commands.forEach( (command) => {
						command.table_status = GatewayCommand.renderStatusSummary(command, false, theme);
						command.table_action = GatewayCommand.renderAction(command.command_type);
					});
					result.items = commands;
					resolve(result);
				}).catch( (error) => {
					reject(error);
				});
			}).catch( (error) => {
				reject(error);
			});
		});
	}

	static renderStatusSummary(command, with_label, theme) {
		let icon = null;
		let title = null;
		const for_endpoints = command.attached_device_unique_ids && command.attached_device_unique_ids.length > 0;
		let status = "Sent";
		if (for_endpoints) {
			if (Object.entries(command.statuses).length !== command.attached_device_unique_ids + 1) {
				status = "Sent";
			}
			command.attached_device_unique_ids.forEach( (unique_id) => {
				if (command.statuses[unique_id].status === "fail") {
					status = "Failure";
				}
			});
			if (status === "Sent" && Object.entries(command.statuses).length === command.attached_device_unique_ids + 1) {
				status = "Success";
			}
		} else {
			status = command.statuses[command.device_unique_id] === "sent" ? "Sent" : capitalize(command.statuses[command.device_unique_id].status);
		}
		if (status === "Success") {
			icon = <CheckCircleIcon style={{color: theme.palette.green.main}}/>;
			title = "Success";
		} else if (status === "Fail") {
			icon = <ErrorIcon style={{color: theme.palette.red.main}}/>;
			title = "Failure";
		} else {
			icon = <MailOutlineIcon style={{color: theme.palette.grey.main}}/>;
			title = "Sent";
		}
		return (
			<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
				<Tooltip title={title}>
					{icon}
				</Tooltip>
				{with_label ? <span style={{marginLeft: "6px"}}>{title}</span> : ""}
			</div>
		);
	}

	static renderAction(action) {
		let action_map = GatewayCommand.getActionMap();
		let text = action_map[action];
		let icon = null;
		let action_rendered = <div style={{display: "flex", "alignItems": "center", whiteSpace: "normal"}}>{text}</div>;
		return action_rendered;
	}

	static listSoftwareUpdates(params, status){
		const count = params.per_page;
		params.per_page = 30;
		return new Promise( (resolve, reject) => {
			LIST("gateway_commands", params, Auth.token()).then( (result) => {
				let updates = this.flatten(result.items, true);
				if (status !== undefined) {
					resolve(updates.filter( (update) => update.status === status).slice(0, count));
				} else {
					resolve(updates.slice(0, count));
				}
			}).catch( (error) => {
				console.log(error);
				reject(error);
			})
		});
	}

	static flatten(commands, is_software, endpoint_unique_id) {
		let flattened = [];
		commands.forEach( (command) => {
			if (command.attached_device_unique_ids && command.attached_device_unique_ids.length > 0) {
				let gateway_status = command.statuses && command.statuses[command.device_unique_id] ? capitalize(command.statuses[command.device_unique_id].status) : "Sent";
				if (endpoint_unique_id == null) {
					command.attached_device_unique_ids.forEach( (unique_id) => {
						let endpoint_status = command.statuses && command.statuses[unique_id] ? capitalize(command.statuses[unique_id].status) : "Sent";
						let flattened_command = {
							endpoint_status: endpoint_status,
							created_at: command.created_at,
							unique_id: unique_id,
							command_type: command.command_type,
							gateway_status: gateway_status,
							user_id: command.user_id,
							status: endpoint_status,
							_id: command._id + "_" + unique_id
						};
						if (is_software) {
							flattened_command.package_name = command.payload ? command.payload.name : "Package Unknown";
							flattened_command.package_id = command.software_update_id;
						}
						flattened.push(flattened_command);
					});
				} else {
					let endpoint_status = command.statuses && command.statuses[endpoint_unique_id] ? capitalize(command.statuses[endpoint_unique_id].status) : "Sent";
					let flattened_command = {
						gateway_status: gateway_status,
						created_at: command.created_at,
						unique_id: endpoint_unique_id,
						command_type: command.command_type,
						endpoint_status: endpoint_status,
						user_id: command.user_id,
						status: endpoint_status,
						_id: command._id + "_" + endpoint_unique_id
					};
					if (is_software) {
						flattened_command.package_name = command.payload ? command.payload.name : "Package Unknown";
						flattened_command.package_id = command.software_update_id;
					}
					flattened.push(flattened_command);
				}
			} else {
				let flattened_command = {
					status: command.statuses && command.statuses[command.device_unique_id] ? capitalize(command.statuses[command.device_unique_id].status) : "Sent",
					gateway_status: command.statuses && command.statuses[command.device_unique_id] ? capitalize(command.statuses[command.device_unique_id].status) : "Sent",
					created_at: command.created_at,
					unique_id: command.device_unique_id,
					command_type: command.command_type,
					endpoint_status: "N/A",
					user_id: command.user_id,
					_id: command._id + "_" + command.device_unique_id
				};
				if (is_software) {
					flattened_command.package_name = command.payload ? command.payload.name : "Package Unknown";
					flattened_command.package_id = command.software_update_id;
				}
				flattened.push(flattened_command);
			}
		});
		return flattened;
	}

	static getActionMap() {
		const action_map = {
			software_update: "Software Update",
			send_config: "Send Config",
			data_restriction: "Data Restriction",
			reboot: "Reboot",
			log_upload: "Log Upload",
			reset: "Reset",
			data_reset: "Data Reset",
			notification: "Notification",
			deploy_deployment: "Deploy Deployment",
			request_deployment_status: "Request Deployment Status",
			data_restore: "Restore Data",
			update_edge: "Update Edge",
			update_firmware: "Update Firmware",
			restore_backup: "Restore Backup",
			backup: "Backup",
			deploy: "Deploy",
			log_level: "Log Level",
			log_config: "Log Config",
			heartbeat: "Manual Heartbeat",
			status: "Manual Status",
			provision_marmon_button: "Provision Marmon Button",
			greengrass_initialize: "Initialize Greengrass",
			greengrass_restart: "Restart Greengrass",
			deactivate_cloud_native_device: "Deactivate Cloud Native Device",
			activate_cloud_native_device: "Activate Cloud Native Device",
		};
		return action_map;
	}

	static loadRequirements(packages) {
		return new Promise( (resolve, reject) => {
			let promises = [];
			if (Permissions.allow(["read"], "user")) {
				promises.push(AssignNestedModels("users", "user_id", packages));
			}
			if (Permissions.allow(["read"], "software_update")) {
				promises.push(AssignNestedModels("software_updates", "software_update_id", packages));
			}
			Promise.all(promises).then( () => {
				resolve(packages);
			});
		});
	}
}

export default GatewayCommand;
