import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import classNames from 'classnames';
import TextInput from '../Inputs/TextInput';
import User from '../../services/DataModels/User';
import Role from '../../services/DataModels/Role';
import Tooltip from '@material-ui/core/Tooltip';
import { GetAll } from '../../services/CLURDUtilities';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Loading from '../DisplayOriented/Loading';
import SelectInput from '../Inputs/SelectInput';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import PasswordInput from '../Inputs/PasswordInput';
import Auth from '../../services/Auth';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PersonIcon from '@material-ui/icons/PersonOutline';
import EmailIcon from '@material-ui/icons/Email';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BusinessIcon  from '@material-ui/icons/Business';
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';


//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';


const styles = theme => ({
	container: {
		display: "flex",
		flexWrap: "wrap",
		boxSizing: "border-box",
		maxHeight: '80vh',
		padding: "32px",
		overflow: "scroll",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	leftColumn: {
		flex: "33%",
		boxSizing: "border-box",
		padding: "12px 6px 12px 0"
	},
	modalWrapper: {
		padding: "24px"
	},
	rightColumn: {
		flex: "66%",
		boxSizing: "border-box",
		padding: "12px 0 12px 6px"
	},
	buttonContainer: {
		width: "100%",
		justifyContent: "flex-end",
		display: "flex"
	},
	deleteMe: {
		backgroundColor: theme.palette.red.main,
		color: "white",
		whiteSpace: "nowrap",
		'&:hover': {
			backgroundColor: darken(theme.palette.red.main, .2),
		},
	},
	button: {
		marginLeft: "24px"
	},
	profileContainer: {
		border: "solid lightgrey 2px",
		borderRadius: "5px",
		width: "100%",
		textAlign: "center",
		boxSizing: "border-box"
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "22px",
		marginBottom: "24px",
	},
	profileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto"
	},
	placeholderProfileImage: {
		fontSize: "140px",
		maxWidth: "100%",
		maxHeight: "140px",
		display: "flex",
		margin: "auto",
		color: theme.palette.primary.main
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap"
	},
	modalButtons: {
		display: "flex",
		justifyContent: "flex-end"
	},
	inputIcon: {
		color: theme.palette.greyIcon.main,
		margin: "8px",
		paddingBottom: "20px"
	},
	noIcon: {
		visibility: "hidden"
	},
	cssRoot: {
		color: theme.palette.getContrastText("#009688"),
		backgroundColor: "#009688",
		'&:hover': {
			backgroundColor: "#037e73",
		},
		whiteSpace: "nowrap"
	},
	labelOverride: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "88%"
	},
	leftButtons: {
		flex: "66%",
		display: "flex",
		alignItems: "center"
	},
	rightButtons: {
		flex: "33%",
		display: "flex",
		margin: "auto",
		alignItems: "center",
		justifyContent: "flex-end"
	},
	token_text: {
		height: "50%",
		display: "flex",
		margin: "8px 8px 13px 0",
	},
	textarea: {
		border: "none",
		fontSize: "18px",
		resize: "none",
		height: "28px",
		width: "80%",
		display: "inline-block",
		verticalAlign: "middle",
		borderBottom: "solid #009988 2px",
		backgroundColor: "#e2e2e2",
		borderTopRightRadius: "5px",
		borderBottomRightRadius: "5px",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		marginBottom: "-5px",
		paddingTop: "5px",
		paddingLeft: "5px"
	},
	iconSmall: {
		fontSize: "20px",
		marginRight: "4px"
	}
});

class EditSelf extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		let editable_user = new User(Auth.user()).editableCopy();
		let is_admin_user = Auth.currentRoleIsAdmin();
		this.state = {
			modal: {
				open: false,
				children: () => ""
			},
			ready: true,
			company_options: null,
			role_options: null,
			valid_profile_image: null,
			email_valid: null,
			editable_user: editable_user,
			allow_editing: !is_admin_user,
			prompt_email: '',
			prompt_password: '',
			reset_token: false,
			reset_prompt: false,
			show_errors: null,
			prompt_errors: null
		};
		this.original_user = new User(Auth.user()).editableCopy();
		if (this.state.editable_user._id) {
			this.state.email_valid = true;
		}
		if (this.state.editable_user.logo_url && this.state.editable_user.logo_url !== "") {
			User.testImage(this.state.editable_user.logo_url).then( () => {
				this.setState( {valid_profile_image: true} );
			}).catch( (error) => {
				this.setState( {valid_profile_image: false} );
			});
		}
		
	}

	can_save = () => {
		let show_errors = false;
		const editable_user = this.state.editable_user;
		if (editable_user.first_name === "" || this.password_error() || editable_user.email === "" || this.company_error() || this.role_error() ) {
			show_errors = true;
		}
		this.setState({ show_errors: show_errors });
		return !show_errors;
	}

	save_user = () => {
		if (this.can_save()) {
			new User(this.format_user())
				.createOrSave()
				.then((result) => User.loadRequirements([result]))
				.then( ([result]) => {
					this.context.openSnackbar("Your profile has been saved.", "success");
					this.props.update_self();
					this.props.close();
				})
				.catch( (error) => {
					if (error === "An error occurred while saving this profile. The email is already in use.") {
						this.setState({ email_valid: false });
					} else {
						this.context.openSnackbar(error, "error");
					}
				});
		}
	}

	format_user = () => {
		let body = new User(this.state.editable_user).editableCopy();
		if (body._id && body.password === "") {
			delete body.ui_password_confirmation;
			delete body.password;
		} else {
			body.password_confirmation = body.ui_password_confirmation;
			delete body.ui_password_confirmation;
		}
		return body;
	}

	confirm_delete = (user, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Are you sure you want to delete your own profile? 
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								color="primary"
								onClick={() => this.delete_user(user)}
								classes={{root: classes.deleteMe}}
							>
								DELETE MY PROFILE
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	close_modal = () => {
		this.setState({
			reset_prompt: false,
			loading_token: false,
			token_ready: false,
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	delete_user = (user) => {
		new User(user)
			.deleteFromAPI()
			.then( () => {
				Auth.logout(() => this.props.history.push('/login'));
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	password_error = () => {
		if (this.state.editable_user._id) {
			if (this.state.editable_user.ui_password_confirmation === "" && this.state.editable_user.password === "") {
				return false;
			} else {
				if (this.state.editable_user.ui_password_confirmation.length >= 8 && this.state.editable_user.ui_password_confirmation === this.state.editable_user.password) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			if ((this.state.editable_user.ui_password_confirmation !== "" && this.state.editable_user.ui_password_confirmation.length >= 8) && this.state.editable_user.ui_password_confirmation === this.state.editable_user.password) {
				return false;
			} else {
				return true;
			}
		}
	}

	company_error = () => {
		if (this.state.editable_user.company_id === "") {
			return true;
		} else {
			return false;
		}
	}

	role_error = () => {
		if (this.state.editable_user.user_type_id === "") {
			return true;
		} else {
			return false;
		}
	}

	password_error_message = () => {
		const pwd = this.state.editable_user.password;
		const pwd_confirm = this.state.editable_user.ui_password_confirmation;
		if ( pwd !== pwd_confirm || pwd.length < 8 ) {
			return "Passwords must match and have at least 8 characters."
		}
		
	}

	loading_token = () => {
		const { classes } = this.props;
		return (
			<div className={classes.modalWrapper}>
				<Loading />
			</div>
		);
	}

	prompt_reset = () => {
		const { classes } = this.props;
		const email = Auth.user().email;
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.prompt}>
							Send yourself an email to reset your password?
						</div>
						<div className={classes.modalButtons}>
							<Button
								color="primary"
								onClick={this.close_modal}
							>
								CANCEL
							</Button>
							<Button
								className={classes.button} color="primary"
								onClick={() => {
									this.send_email(email);
									this.close_modal();
								}}
							>
								SEND EMAIL
							</Button>
						</div>
					</div>
				)
			}
		});
	}

	send_email = (email) => {
		Auth.promptPasswordEmail(email).then( () => {
			this.context.openSnackbar("Email sent successfully.", "success");
		}).catch( (error) => {
			this.context.openSnackbar("An error occurred sending the password reset.", "error");
		});
	}
	
	render() {
		const { classes } = this.props;
		const { allow_editing, reset_prompt, token_ready, loading_token, email_valid, modal, editable_user, valid_profile_image, show_errors, company_options, role_options, ready } = this.state;
		if (reset_prompt) {
			return this.reset_prompt();
		}
		if (loading_token) {
			return this.loading_token();
		}
		if (token_ready) {
			return this.token_ready();
		}
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={modal} closeModal={this.close_modal}>
					{modal.children(classes)}
				</SimpleModalWrapped>
				{ready ?
					<React.Fragment>
						<div className={classes.leftColumn}>
							<div className={classes.profileContainer}>
								{valid_profile_image ?
									<img className={classes.profileImage} src={editable_user.logo_url}/>
								:
									<AccountCircleIcon className={classes.placeholderProfileImage} />
								}
							</div>
							<div className={classes.inputContainer}>
								<Tooltip className={classes.inputIcon} key="Profile Image" title="Profile Image">
									<CameraAltIcon />
								</Tooltip>
								<TextInput
									disabled={!allow_editing}
									error={valid_profile_image === false}
									error_message="This URL does not load an image."
									emitChange={this.handleImageUrlChange}
									priorState={editable_user.logo_url}
									label="Profile Photo (URL)"
									key="logo_url"
									field="logo_url"
								>
								</TextInput>
							</div>
						</div>
						<div className={classes.rightColumn}>
							<div className={classes.inputContainer}>
								<Tooltip className={classes.inputIcon} key="Name" title="Name">
									<PersonIcon />
								</Tooltip>
								<TextInput
									margin="none"
									disabled={!allow_editing}
									emitChange={this.handleTextChange}
									priorState={editable_user.first_name}
									error_message="A user's first name cannot be blank."
									error={editable_user.first_name === "" && show_errors}
									label="First Name*"
									key="first_name"
									field="first_name"
								>
								</TextInput>
							</div>
							<div className={classes.inputContainer}>
								<Tooltip className={classes.inputIcon + " " + classes.noIcon} key="Name" title="Name">
									<PersonIcon />
								</Tooltip>
								<TextInput
									disabled={!allow_editing}
									emitChange={this.handleTextChange}
									priorState={editable_user.last_name}
									label="Last Name"
									key="last_name"
									field="last_name"
								>
								</TextInput>
							</div>
							<div className={classes.inputContainer}>
								<Tooltip className={classes.inputIcon} key="Email/Username*" title="Email/Username">
									<AlternateEmailIcon />
								</Tooltip>
								<TextInput
									disabled={!allow_editing}
									margin="none"
									emitChange={this.handleTextChange}
									priorState={editable_user.email}
									error={editable_user.email === "" || this.state.email_valid === false || show_errors}
									error_message="Invalid username or email"
									label="Email/Username*"
									key="email"
									model="users"
									field="email"
									original={this.original_user.email}
								>
								</TextInput>
							</div>
						</div>
						<div className={classes.buttonContainer}>
							<div className={classes.leftButtons}>
								<Button disabled={!allow_editing} className={classNames(classes.cssRoot, classes.button)} onClick={() => this.setState({reset_prompt: true})} variant="contained" color="primary">
									<VpnKeyIcon className={classes.leftIcon + " " + classes.iconSmall} />
									RESET API TOKEN
								</Button>
								<Button disabled={!allow_editing} className={classNames(classes.cssRoot, classes.button)} onClick={this.prompt_reset} variant="contained" color="primary">
									<EmailIcon className={classes.iconSmall} />
									PROMPT PASSWORD RESET EMAIL
								</Button>
							</div>
							<div className={classes.rightButtons}>
								{editable_user._id ?
									<Button disabled={!allow_editing} classes={{root: classes.deleteMe}} onClick={() => this.confirm_delete(editable_user)} variant="contained" >
										DELETE MY PROFILE
									</Button>
								:""}
								<Button disabled={!allow_editing} className={classes.button} onClick={this.save_user} variant="contained" color="primary">
									{"SAVE"}
								</Button>
							</div>
						</div>
					</React.Fragment>
				:
					<Loading />
				}
			</div>
		);
	}

	token_ready = () => {
		const { classes } = this.props;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Your New API Token.
				</div>
				<form className={classes.token_text}>
					<Button className={classNames(classes.cssRoot, classes.button)} onClick={this.copy_token} variant="contained" color="primary">
						<AssignmentReturnIcon className={classes.leftIcon + " " + classes.iconSmall} />
						COPY API TOKEN
					</Button>
					<textarea
						readOnly
						className={classes.textarea}
						ref={(textarea) => this.textArea = textarea}
						value={this.state.token_ready}
					/>
				</form>
			</div>
			
		);
	}

	reset_prompt = () => {
		const { classes } = this.props;
		const { prompt_email, prompt_password, prompt_errors } = this.state;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Please enter your username/email and password in order to reset your API token.
				</div>
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} title="Email/Username">
						<AlternateEmailIcon />
					</Tooltip>
					<TextInput
						margin="none"
						emitChange={({value}) => this.setState({prompt_email: value})}
						priorState={prompt_email}
						error={prompt_email === "" && prompt_errors}
						error_message="Please enter your username/email."
						label="Email/Username*"
						field="email"
					/>
				</div>
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} title="Password">
						<LockIcon />
					</Tooltip>
					<PasswordInput
						margin="none"
						emitChange={({value}) => this.setState({prompt_password: value})}
						error={prompt_password === "" && prompt_errors}
						error_message="Please enter your password."
						priorState={prompt_password}
						label="Password*"
						field="password"
					/>
				</div>
				<div className={classes.modalButtons}>
					<Button
						color="primary"
						onClick={this.close_modal}
					>
						CANCEL
					</Button>
					<Button
						color="primary"
						onClick={this.reset_token}
					>
						RESET MY API TOKEN
					</Button>
				</div>
			</div>
		);
	}

	reset_token = () => {
		if (this.state.prompt_email === "" || this.state.prompt_password === "") {
			this.setState({prompt_errors: true});
			return;
		}
		this.setState({reset_prompt: false, loading_token: true});
		Auth.resetToken({email: this.state.prompt_email, password: this.state.prompt_password}).then( (result) => {
			this.setState({loading_token: false, reset_prompt: false, token_ready: result});
		}).catch( (error) => {
			this.setState({reset_prompt: true, loading_token: false});
			this.context.openSnackbar(error, "error");
		});
	}

	copy_token = (e) => {
		this.textArea.select();
		document.execCommand('copy');
		e.target.focus();
		this.context.openSnackbar("Token copied to clipboard.", "success");
	};

	handleImageUrlChange = ({field, value}) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState( (prev_state) => {
			return Object.assign(prev_state.editable_user, overwrite);
		}, () => {
			User.testImage(this.state.editable_user.logo_url).then( () => {
				this.setState( {valid_profile_image: true} );
			}).catch( () => {
				this.setState( {valid_profile_image: false} );
			});
		});
	}

	handleEmailChange = ({valid, value}) => {
		if (valid) {
			this.setState( (prev_state) => {
				let user = prev_state.editable_user;
				user.email = value;
				return { editable_user: user, email_valid: true };
			});
		} else {
			this.setState({email_valid: false});
		}
	}

	handleTextChange = ({field, value}) => {
		let overwrite = {};
		overwrite[field] = value;
		this.setState( (prev_state) => {
			return Object.assign(prev_state.editable_user, overwrite);
		});
	}
}

EditSelf.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(EditSelf));
