import DataModel from "./DataModel";
import { AssignNestedModels, GetAll } from '../CLURDUtilities';

class DeviceHAGroup extends DataModel {
	constructor(data = BLANK) {
		super('device_ha_groups', data);
	}

	static loadRequirements(ha_groups) {
		return new Promise( (resolve, reject) => {
			let promises = [];
			promises.push(AssignNestedModels("companies", "company_id", ha_groups));
			promises.push(get_integration_ids(ha_groups));
			Promise.all(promises).then( () => {
				resolve(ha_groups);
			});
		})
	}
}

function get_integration_ids(ha_groups) {
	if (!ha_groups || ha_groups.length === 0) {
		return Promise.resolve();
	}
	let controller_ids = ha_groups.map( ({controller_device}) => controller_device.device_id).join(",");
	let params = {_id_in: controller_ids};
	return new Promise( (resolve, reject) => {
		GetAll("devices", params).then( (controllers) => {
			let device_to_int_map = {};
			let integration_id_map = {};
			controllers.forEach( (controller) => {
				device_to_int_map[controller._id] = {device: controller, integration_id: controller.device_integration_id};
				integration_id_map[controller.device_integration_id] = null;
			});
			let integration_ids = Object.entries(integration_id_map).map( ([key, value]) => key).join(",");
			let params = {_id_in: integration_ids};
			GetAll("integrations", params).then( (integrations) => {
				integrations.forEach( (integration) => {
					integration_id_map[integration._id] = integration;
				});
				ha_groups.forEach( (ha_group) => {
					let controller = device_to_int_map[ha_group.controller_device.device_id];
					if (controller) {
						ha_group.nested_controller = controller.device;
						let integration = integration_id_map[controller.integration_id];
						if (integration) {
							ha_group.nested_integration = integration;
						} else {
							ha_group.error = "Integration not found.";
						}
					} else {
						ha_group.error = "Controller Device not found.";
					}
				});
				resolve(ha_groups);
			}).catch( (error) => {
				console.log(error);
				reject(error);
			});
		}).catch( (error) => {
			console.log(error);
			reject(error);
		});
	});
}

const BLANK = {
	name: "",
	ports: [],
	controller_device: null,
	node_devices: [],
	company_id: ''
}

export default DeviceHAGroup;
