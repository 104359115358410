import React from 'react';
import Command from '../../../services/DataModels/Command';
import CreateCommandForm from '../../CommandSpecific/CreateCommandForm';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => ({
    container: {
        width: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '10px',
            backgroundColor: "#5555552b"
        }
    },
    contentContainer: {
        display: "flex",
        flexWrap: "nowrap",
        margin: "32px 20%",
    }
});

class CommandDetailTab extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            dataModel: null,
        };
        this.props.tabHostProxy.setTabRefresh(this.refresh);
    }

    componentDidMount() {
        this.prepare_command();
    }

    refresh = () => {
        this.setState({
            dataModel: null
        });
        new Command({ _id: this.props.data._id }).readFromAPI().then((command) => {
            this.props.tabHostProxy.updateTitle(command._id, command);
            this.props.tabHostProxy.setTabRefresh(this.refresh);
            this.props.tabHostProxy.refresh();
            this.prepare_command(command);
        }).catch((error) => {
            this.context.openSnackbar(error, "error");
        });
    }

    prepare_command = (command) => {
        let dataModel = command ? new Command(command) : new Command(this.props.data);
        this.setState({
            dataModel: dataModel
        });
    }

    cancel = () => {
        this.props.tabHostProxy.closeSelf();
    }

    create = (result) => {
        this.props.tabHostProxy.closeSelf();
        this.props.tabHostProxy.addTab("command", result);
        this.props.tabHostProxy.refresh();
    }

    render() {
        const { classes, tabHostProxy } = this.props;
        const { dataModel } = this.state;

        return (
            <div className={classes.container}>
                {dataModel === null ?
                    <Loading />
                    :
                    <div className={classes.contentContainer}>
                        <CreateCommandForm
                            className={classes.formContainer}
                            tabHostProxy={tabHostProxy}
                            onCreate={this.create}
                            onCancel={this.cancel}
                            command={dataModel.data}
                        />
                    </div>
                }
            </div>
        )
    }
}

CommandDetailTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CommandDetailTab));
