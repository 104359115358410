import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import Card from '@material-ui/core/Card';
import PollableAttribute from '../../services/DataModels/PollableAttribute';
import TableList from '../Table/TableList';
import PaginationContainer from '../Containers/PaginationContainer';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
//icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';


const heading_info = [
	{label: "Name", field: "name", align: "left", disablePadding: false, sortable: true},
	{label: "Type", field: "type", align: "left", disablePadding: false, sortable: true},
	{label: "Interval", field: "interval", align: "left", disablePadding: false, sortable: true},
	{label: "Action", field: "action", align: "left", disablePadding: false, sortable: true } 
];

const styles = theme => ({
	container: {
		fontFamily: "Inter",
		flexGrow: 2,
		width: "75%"
	},
	pollable_attributesContainer: {
		overflowY: "auto",
		height: "calc(100% - 82px)",
		padding: "12px",
		backgroundColor: "#f5f5f7",
	},
	cardItemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 82px)",
		padding: "12px",
		backgroundColor: "#f5f5f7",
	},
	tableItemsContainer: {
		overflowY: "auto",
		height: "calc(100% - 82px)",
		backgroundColor: "#ffffff",
	},
	pollable_attributeCardContainer: {
		width: "33.33%",
		display: "inline-flex",
		maxHeight: "300px",
		justifyContent: "center",
	},
	pollable_attributeCard: {
		boxShadow: "0px 3px 7px 2px #00000017",
		backgroundColor: "#fafafa",
		margin: "12px",
		width: "100%",
		minWidth: "250px",
		maxWidth: "380px",
		height: "auto",
		border: "#ffffff solid 1px",
		transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
		"&:hover": {
			border: "#1153B6 solid 1px",
		}
	},
	labelGroup: {
		margin: "6px 0"
	},
	titleLabelGroup: {
		padding: "16px",
		margin: 0,
		backgroundColor: "white"
	},
	label: {
		color: "#636366",
		fontSize: "14px",
		lineHeight: "22px",
		cursor: "pointer",
	},
	titleAndEditContainer: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "8px",
	},
	menuIconButton: {
		fontSize: "24px",
		padding: 0,
		color: "#636366",
	},
	description: {
		width: "75%",
		color: "#000000",
		opacity: "0.87",
		fontSize: "16px",
		lineHeight: "24px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		cursor: "pointer",
	},
	edit: {
		textTransform: "uppercase",
		textDecoration: "underline",
		color: "#468ee5",
		fontSize: "18px",
		textAlign: "right",
		cursor: "pointer"
	},
	activeRow: {
		zIndex: "3",
		position: "relative",
		padding: "16px 16px 16px 16px",
		cursor: "pointer",
	},
	switchContainer: {
		height: "46px",
		color: "#000000",
		opacity: "0.87",
		fontSize: "16px",
		lineHeight: "24px",
		cursor: "pointer",
	},
	deviceCountContainer: {
		width: "40%",
		height: "64px",
		marginTop: "0",
		textAlign: "center",
	},
	onDevices: {
		width: "20%",
		textAlign: "center",
		color: "grey",
		fontSize: "18px",
	},
	deviceCount: {
		fontSize: "32px",
		height: "48px",
	},
	devicesLabel: {
		textAlign: "center",
		fontSize: "12px",
	},
	recentAlertRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		bottom: "12px",
		position: "absolute",
		width: "100%",
	},
	alertIcon: {
		color: "grey",
		marginRight: "6px",
	},
	noAlerts: {
		color: "grey",
	},
	alertsLink: {
		color: "#468ee5",
		textDecoration: "underline",
		cursor: "pointer"
	},
	integrationIcon: {
		height: "32px",
		borderRadius: "16px"
	},
	pollable_attributeChipContainer: {
		overflowY: "auto",
		padding: "6px 0",
		overflowX: "hidden",
		width: "100%",
		height: "90px",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	chip: {
		margin: "3px",
		maxWidth: "100%",
	},
	linkChip: {
		color: theme.palette.pending.main,
		cursor: "pointer",
		textDecoration: "underline"
	},
	heartbeatIconContainer: {
		position: "relative"
	},
	heartbeatOverlay: {
		position: "absolute",
		left: 0,
		color: "#e0e0e0"
	},
	ggImg: {
		height: "24px",
		width: "24px"
	},
	actionListTitle: {
		marginBottom: "8px",
		padding: "11px 16px",
		borderBottom: "solid #80808073 1px",
		backgroundColor: "white",
		cursor: "unset",
		'&:hover': {
			backgroundColor: "white",
			cursor: "unset",
		},
		outline: "none",
		fontFamily: "Inter",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "1rem",
		width: "auto",
		height: "24px",
		whiteSpace: "nowrap",
		boxSizing: "content-box",
		fontWeight: 400,
		lineHeight: "1.5em"
	},
	actionMenuItem: {
		outline: "none"
	},
});

class PollableAttributesGrid extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			layout: "table",
			menus: {},
			modal: {
				open: false
			},
			pollable_attributetodelete: null
		};
		this.set_menus();
		this.check_params();
	};

	set_menus = () => {
		if (!this.props.pollable_attributes) return;
		this.props.pollable_attributes.forEach((pollable) => {
			this.state.menus[pollable._id] = null;
		});
	}

	check_params = () => {
		const qs = require('qs');
		const parsed = qs.parse(this.props.location.search.split("?")[1]);
		if (parsed.pollable_attribute_id != null) {
			new PollableAttribute({_id: parsed.pollable_attribute_id}, true).readFromAPI().then( (result) => {
				this.openPollableAttribute(result);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}
		this.props.history.replace({
			pathname: '/Pollable_Attributes',
			search: ''
		});
	}

	handleChangePage = (event, page) => {
		this.props.page_change({page: page + 1, per_page: this.props.page_data.per_page});
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({page: this.props.page_data.page, per_page: event.target.value});
	};

	openPollableAttribute = (pollable_attribute) => {
		this.props.tabHostProxy.addTab("pollable_attribute", pollable_attribute);
	};

	createPollableAttribute = () => {
		this.props.tabHostProxy.addTab("pollable_attribute");
	};

	render_table_layout = (items) => {
		const { page_data } = this.props;
		return (
			<TableList
				headings={heading_info}
				items={items}
				page_data={page_data}
				perform_sort={this.props.perform_sort}
			/>
		);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState((state) => {
			state.menus[id] = element;
			return state;
		});
	}

	close_action_menu = (id) => {
		this.setState((state) => {
			state.menus[id] = null;
			return state;
		});
	};

	render_menu = (pollable_attribute) => {
		const classes = this.props.classes;
		let anchorEl = this.state.menus[pollable_attribute._id];
		let open = Boolean(anchorEl);
		return (
			<div style={{ visibility: (this.get_actions(pollable_attribute).length === 0 ? "hidden" : "visible") }}>
				<div>
					<IconButton
						aria-label="More"
						aria-owns={open ? 'long-menu' : undefined}
						aria-haspopup="true"
						onClick={(event) => this.open_action_menu(event, pollable_attribute._id)}
						className={classes.menuIconButton}
					>
						<MoreVertIcon />
					</IconButton>
				</div>
				{this.render_action_menu(open, anchorEl, pollable_attribute)}
			</div>
		);
	}

	render_action_menu = (open, anchorEl, pollable_attribute) => {
		const classes = this.props.classes;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => this.close_action_menu(pollable_attribute._id)}
				PaperProps={{
					style: { overflow: "visible" }
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				{this.get_actions().map((action, index) => (
					<div onClick={(event) => { this.executeAction(action, pollable_attribute) }} key={action.label} className={classes.menuItemContainer}>
						<MenuItem className={classes.actionMenuItem}>
							<ListItemIcon>
								{action.icon}
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								{action.label}
							</Typography>
						</MenuItem>
					</div>
				))}
			</Menu>
		);
	}

	get_actions = () => {
		let possible_actions = [];
		possible_actions.push({ label: "View", action: "view", confirm: "Delete this device?", icon: <VisibilityIcon /> });
		possible_actions.push({ label: "Delete", action: "delete", confirm: "Delete this device?", icon: <DeleteForeverIcon /> });
		return possible_actions;
	}

	executeAction = (action, pollable_attribute) => {
		if (action.action === 'view') {
			this.close_action_menu(pollable_attribute._id);
			this.openPollableAttribute(PollableAttribute.convertForUI(pollable_attribute));
		} else if (action.action === 'delete') {
			this.state.pollable_attributetodelete = pollable_attribute;
			this.close_action_menu(pollable_attribute._id);
			this.openModal("Are you sure you want to delete this pollable attribute?", "DELETE POLLABLE ATTRIBUTE", this.deletePollableAttribute);
		}

	}

	deletePollableAttribute = () => {
		new PollableAttribute({_id: this.state.pollable_attributetodelete._id}).deleteFromAPI().then((result) => {
			this.context.openSnackbar('Pollable Attribute deleted.', 'success');
			this.props.tabHostProxy.closeOther(this.state.pollable_attributetodelete._id, true);
			this.props.tabHostProxy.refresh();
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
			this.closeModal();
		});
	}

	openModal = (prompt, buttonText, fxn) => {
		this.setState({modal: {
			"open": true,
			prompt: prompt,
			yesFunction: fxn,
			functionText: buttonText,
		}});
	}

	closeModal = () => {
		let newState = {modal: {open: false}};
		this.setState(newState);
	}
	
	render_card_layout = (items) => {
		const { classes } = this.props;
		return (
			<React.Fragment>
				{items.map((pollable_attribute, index) => (
					<div key={pollable_attribute._id} className={classes.pollable_attributeCardContainer}>
						<Card className={classes.pollable_attributeCard}>
							<div className={classes.labelGroup + " " + classes.titleLabelGroup}>
								<div className={classes.titleAndEditContainer}>
									<div onClick={()=>{this.openPollableAttribute(PollableAttribute.convertForUI(pollable_attribute));}} className={classes.label}>
										Name
									</div>
									{pollable_attribute.view_disabled ? "" :
										this.render_menu(pollable_attribute)
									}
								</div>
								<div onClick={()=>{this.openPollableAttribute(PollableAttribute.convertForUI(pollable_attribute));}} className={classes.description}>
									{`${pollable_attribute.name} 	${pollable_attribute.interval} `}
								</div>
							</div>
							 <div onClick={()=>{this.openPollableAttribute(PollableAttribute.convertForUI(pollable_attribute));}} className={classes.activeRow}>
								<div className={classes.switchContainer}>
									{`${pollable_attribute.type}`}
								</div>
							</div>
						</Card>
					</div>
				))}
			</React.Fragment>
		);
	}
	
	prepare_items = () => {
		const { pollable_attributes } = this.props;
		if (!pollable_attributes) return;
		return this.prepare_types();
	}

	prepare_types = () => {
		let pollable_attributes = this.props.pollable_attributes;
		const classes = this.props.classes;
		pollable_attributes.forEach( (pollable_attribute) => {
			pollable_attribute.action = (<span className={classes.tableMenu}>{this.render_menu(pollable_attribute)}</span>);
		});
		return pollable_attributes;
	}

	render() {
		const { classes, page_data } = this.props;
		let buttons = undefined;
		let items = this.prepare_items();
		// if (Permissions.allow(["create"], "pollable_attributes")) {
			buttons = [{ label: "CREATE POLLABLE ATTRIBUTE", icon: (<AddIcon />), action: this.createPollableAttribute }];
		// }
		return (
			<div className={classes.container}>
				<PaginationContainer
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={(layout) => this.setState({layout: layout})}
					layout={this.state.layout}
				/>
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer}>
						{this.render_card_layout(items)}
					</div> :
					<div className={classes.tableItemsContainer}>
						{this.render_table_layout(items)}
					</div>
				}
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
			</div>
		)
	};
}

PollableAttributesGrid.contextType = SnackbarContext;
export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)(PollableAttributesGrid);