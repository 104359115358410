import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../DisplayOriented/Loading';
import Auth from '../../services/Auth';
import { GetAll } from '../../services/CLURDUtilities';
import Device from '../../services/DataModels/Device';
import Config from '../../services/DataModels/Config';
import CreateConfigForm from '../ConfigsSpecific/CreateConfigForm';

//inputs
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import TimeInput from '../Inputs/TimeInput';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import SettingsEthernetIcon  from '@material-ui/icons/SettingsEthernet';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		title: {
			fontSize: "24px",
			margin: "0 0 12px 12px",
			display: "flex",
		},
		subTitle: {
			fontSize: "16px",
			color: "grey",
			margin: "0 0 12px 40px",
			display: "flex",
			alignItems: "center"
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap",
			position: "relative"
		},
		uidInput: {
			marginBottom: "30px"
		},
		accountInput: {
			marginBottom: "16px"
		},
		nameContainer: {
			marginBottom: "16px"
		},
		inputIcon: {
			color: theme.palette.greyIcon.main,
			margin: "8px",
			paddingBottom: "20px"
		},
		configLinks: {
			marginTop: "-18px",
			marginBottom: "32px",
			marginLeft: "40px",
			color: theme.palette.pending.main,
		},
		link: {
			cursor: "pointer"
		},
		uidIcon: {
			color: "white", 
			backgroundColor: "grey", 
			borderRadius: "4px",
			width: "22px", 
			height: "20px",
			fontSize: "12px",
			margin: "8px 10px 32px 10px",
			paddingBottom: 0,
			display: "flex",
		},
		uidIconText: {
			margin: "auto"
		},
		configInput: {
			marginTop: "32px"
		},
		invalidConfig: {
			marginLeft: "40px",
			marginTop: "-18px",
			color: "red"
		},
		buttonContainer: {
			margin: "0 0 0 auto",
			display: "flex",
			justifyContent: "flex-end",
		},
		button: {
			marginLeft: "12px"
		},
		heartbeatIconsContainer: {
			position: "relative",
			color: theme.palette.greyIcon.main,
			display: "flex",
			margin: "0 8px",
			padding: 0
		},
		generate: {
			marginRight: "8px",
			color: "black"
		},
		heartbeatIcon: {
			color: theme.palette.greyIcon.main,
			fontSize: "24px",
		},
		overlayIcon: {
			position: "absolute",
			left: 0,
			color: "white",
			fontSize: "24px"
		},
		formControl: {
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			paddingLeft: "30px",
			marginBottom: "36px"
		},
		formControlLabelText: {
			overflow: "hidden",
			whiteSpace: "no-wrap",
			textOverflow: "ellipsis"
		},
		formControlLabel: {
			margin: 0,
			flex: "50%",
			display: "flex",
			overflow: "hidden"
		},
	});
};

class CreateDeviceForm extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			name: '',
			company_id: this.props.company.value,
			integration_id: this.props.integration.value,
			unique_id: '',
			device_type_id: '',
			config_id: '',
			config_options: null,
			show_errors: false,
			saving: false,
			interface: null,
			interface_options: null,
			creating_config: false,
			heartbeat_values: [],
			heartbeat_period_changed: 6,
			heartbeat_time: "Minutes",
			heartbeat_values: Device.getHeartbeatOptions()
		};
	}

	load_configs = () => {
		let params = {device_type_id: this.state.device_type_id};
		if (this.state.config_options !== null) this.setState({config_options: null});
		GetAll("device_configs", params).then( (configs) => {
			configs = configs.map( (config) => ({value: config._id, display: config.name, whole: config}));
			configs.push({display: "Select a Network Config*", value: ''});
			this.setState({config_options: configs, config_id: '', interface: null, interface_options: null});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_config = () => {
		const classes = this.props.classes;
		if (!this.state.device_type_id || this.state.device_type_id === '' || this.invalid_type(this.props.types.find( (type) => type._id === this.state.device_type_id))) return "";
		return (
			<React.Fragment>
				<div className={classes.inputContainer + " " + classes.configInput}>
					<Tooltip className={classes.inputIcon} title="Network Config">
						<SettingsIcon />
					</Tooltip>
					{this.state.config_options === null ?
						<React.Fragment>
							<div>Loading device network configs for this device type...</div>
							<Loading/>
						</React.Fragment>
					:
						<SelectInput
							field="config_id"
							emitChange={this.handle_change}
							label="Network Config*"
							priorState={this.state.config_id}
							options={this.state.config_options}
							error={this.config_error() && this.state.show_errors}
						/>
					}
				</div>
				<div className={classes.configLinks}>
					<span
						onClick={this.create_config}
						className={classes.link}
					>
						Create a new network config
					</span>
				</div>
				{this.state.show_errors && this.state.config_id === '' ? <div className={classes.invalidConfig}>Please select a network config.</div> : ""}
			</React.Fragment>
		);
	}

	create_config = () => {
		this.setState({creating_config: true});
	}

	render_interface = () => {
		const classes = this.props.classes;
		if (this.state.config_id === '' || this.state.interface_options === null) return "";
		if (this.state.interface_options.length === 0) return (<div className={classes.invalidConfig}>This network config does not have any of the necessary connection interfaces. Please edit this config or assign a different network config to this device.</div>);
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} title="Connection Interface">
					<SettingsEthernetIcon />
				</Tooltip>
				<SelectInput
					field="interface"
					emitChange={this.handle_change}
					label="Connection Interface*"
					priorState={this.state.interface}
					options={this.state.interface_options}
				/>
			</div>
		);
	}

	prepare_interfaces = () => {
		let config = this.state.config_options.find( ({value}) => value === this.state.config_id).whole;
		let interface_options = this.props.getInterfaceOptions(config);
		this.setState({interface_options: interface_options ? interface_options : [], interface: interface_options ? interface_options[0].value : null});
	}

	handle_change = (input) => {
		let field = input.field;
		this.setState( (state) => {
			state[field] = input.value;
			return state;
		}, () => {
			if (field === "device_type_id") {
				this.setState({interface_options: null, interface: null});
				if (input.value !== '') {
					this.load_configs();
				}
			} else if (field === 'config_id') {
				this.setState({interface_options: null, interface: null});
				if (input.value !== '') {
					this.prepare_interfaces();
				}
			}
		});
	}

	invalid_type = (type) => {
		let invalid = true;
		if (type.capabilities.network_connections && type.capabilities.network_connections.length > 0) {
			type.capabilities.network_connections.forEach( (connection) => {
				if (connection.type === "ethernet-wan" || connection.type === "ethernet-wan-lan" || connection.type === "wifi") {
					invalid = false;
					return;
				}
			});
		}
		return invalid;
	}

	render_name = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer + " " + classes.nameContainer}>
				<Tooltip className={classes.inputIcon} title="Name">
					<DescriptionIcon />
				</Tooltip>
				<TextInput
					emitChange={this.handle_change}
					priorState={this.state.name}
					error_message="A device's name cannot be blank."
					error={this.state.name === "" && this.state.show_errors}
					label="Name*"
					key="name"
					field="name"
				/>
			</div>
		);
	}

	render_company = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer + " " + classes.accountInput}>
				<Tooltip className={classes.inputIcon} title="Account">
					<AccountCircleIcon />
				</Tooltip>
				<SelectInput
					disabled={true}
					field="company_id"
					label="Account"
					emitChange={this.handle_change}
					priorState={this.state.company_id}
					options={[this.props.company]}
				/>
			</div>
		);
	}

	render_integration = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer  + " " + classes.accountInput}>
				<Tooltip className={classes.inputIcon} title="Greengrass Integration">
					<ExtensionIcon />
				</Tooltip>
				<SelectInput
					disabled
					field="greengrass_integration"
					emitChange={this.handle_change}
					label="Greengrass Integration"
					priorState={this.state.integration_id}
					options={[this.props.integration]}
				/>
			</div>
		);
	}

	render_type = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} title="Device Type">
					<DvrIcon />
				</Tooltip>
				<SelectInput
					label="Device Type"
					field="device_type_id"
					emitChange={this.handle_change}
					priorState={this.state.device_type_id}
					options={this.prepare_types()}
					error_message="Please select a device type."
					error={this.state.device_type_id === '' && this.state.show_errors}
				/>
			</div>
		);
	}

	prepare_types = () => {
		let types = this.props.types.map( (type) => ({display: type.name, value: type._id}));
		types.push({display: "Select a Device Type*", value: ""});
		return types;
	}

	render_unique_id = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer + " " + classes.uidInput}>
				<Tooltip className={classes.uidIcon} title="Unique ID">
					<span className={classes.uidIcon}>
						<span className={classes.uidIconText}>
							ID
						</span>
					</span>
				</Tooltip>
				<TextInput
					margin="none"
					emitChange={this.handle_change}
					priorState={this.state.unique_id}
					error={this.unique_id_error() && this.state.show_errors}
					error_message="A unique ID can only contain lower case letters, numbers or the special charaters - , : , or _ and it must be unique."
					label={"Unique ID*"}
					model={"devices"}
					field={"unique_id"}
				/>
			</div>
		);
	}

	unique_id_error = () => {
		if (this.state.unique_id === "" || this.state.unique_id.indexOf(" ") !== -1 || /[A-Z]/.test(this.state.unique_id)) {
			return true;
		} else {
			return false;
		}
	}

	render_buttons = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={this.props.cancel}
					className={classes.button}
				>
					cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={this.create_device}
					className={classes.button}
				>
					CREATE DEVICE
				</Button>
			</div>
		);
	}

	create_device = () => {
		if (this.state.name === "" || this.unique_id_error() || this.state.device_type_id === '' || this.invalid_type(this.props.types.find( (type) => type._id === this.state.device_type_id)) || this.state.config_id === null || this.state.interface_options === null) {
			this.setState({show_errors: true});
			return;
		}
		this.setState({saving: true});
		let body = {
			name: this.state.name,
			unique_id: this.state.unique_id,
			company_id: this.state.company_id,
			device_integration_id: this.state.integration_id,
			device_type_id: this.state.device_type_id,
			nested_device_type: this.props.types.find( ({_id}) => _id === this.state.device_type_id),
			nested_device_config: this.state.config_options.find( ({value}) => value === this.state.config_id),
			device_config_id: this.state.config_id,
			interface: this.state.interface
		};
		body.heartbeat_values = [];
		Object.entries(this.state.heartbeat_values).forEach( ([key, {enabled}]) => {
			if (enabled) {
				body.heartbeat_values.push(key);
			}
		});
		switch (this.state.heartbeat_time) {
			case "Seconds":
				body.heartbeat_period = parseInt(this.state.heartbeat_period_changed);
				break;
			case "Minutes":
				body.heartbeat_period = this.state.heartbeat_period_changed * 60;
				break;
			case "Hours":
				body.heartbeat_period = this.state.heartbeat_period_changed * 3600;
				break;
			case "Days":
				body.heartbeat_period = this.state.heartbeat_period_changed * 86400;
				break;
		}
		this.props.onCreate(body);
	}

	config_error = () => {
		return this.state.config_id === '';
	}

	render_type_error = () => {
		const classes = this.props.classes;
		if (this.state.device_type_id !== '' && this.invalid_type(this.props.types.find( (type) => type._id === this.state.device_type_id))) {
			return (
				<div className={classes.invalidConfig}>
					This device is of a device type that does not support any of the necessary connections (ethernet-wan, ethernet-wan-lan, wifi) to be used in an HA Group. Please select a different device.
				</div>
			);
		}
	}

	render_heartbeat_period = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.inputContainer} >
				<Tooltip className={classes.inputIcon} title="Network Config">
					<div className={classes.heartbeatIconsContainer}>
						<FavoriteIcon className={classes.heartbeatIcon} />
						<TimelineIcon className={classes.overlayIcon}/>
					</div>
				</Tooltip>
				<span className={classes.generate}>
					Generate a heartbeat with the fields below every...
				</span>
				<TimeInput
					margin="none"
					emitChange={this.handle_heartbeat_period_change}
					priorState={{ value: this.state.heartbeat_period_changed, time: this.state.heartbeat_time }}
				/>
			</div>
		);
	}

	handle_heartbeat_period_change = ({value, time}) => {
		this.setState({heartbeat_period_changed: value, heartbeat_time: time });
	}

	handle_heartbeat_value_change = (key, enabled) => {
		this.setState( (state) => {
			state.heartbeat_values[key].enabled = enabled;
			return state;
		});
	}

	render_heartbeat_values = () => {
		const classes = this.props.classes;
		return (
			<React.Fragment>
				<div className={classes.formControl}>
					{Object.entries(this.state.heartbeat_values).map( ([key, {enabled, label}]) => (
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox checked={enabled} onChange={() => this.handle_heartbeat_value_change(key, !enabled)} value={key} />
							}
							key={key}
							label={label}
							classes={{label: classes.formControlLabelText}}
						/>
					))}
				</div>
			</React.Fragment>
		);
	}

	on_config_create = (config) => {
		this.setState({saving: true});
		new Config(config).createOrSave().then( (result) => {
			this.setState( (state) => {
				state.saving = false;
				state.config_id = result._id;
				state.creating_config = false;
				state.config_options.push(({value: result._id, display: result.name, whole: result}));
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render() {
		const classes = this.props.classes;
		let type = this.props.types.find( (type) => type._id === this.state.device_type_id);
		if (this.state.creating_config) return (<CreateConfigForm getInterfaceOptions={this.props.getInterfaceOptions} onCreate={this.on_config_create} cancel={() => this.setState({creating_config: false})} company={this.props.company} type={type} />);
		return (
			<div>
				<div className={classes.title}>
					Create a Device
				</div>
				{this.state.saving ? <Loading /> :
					<React.Fragment>
						{this.render_name()}
						{this.render_unique_id()}
						{this.render_company()}
						{this.render_heartbeat_period()}
						{this.render_heartbeat_values()}
						{this.render_integration()}
						{this.render_type()}
						{this.render_type_error()}
						{this.render_config()}
						{this.render_interface()}
						{this.render_buttons()}
					</React.Fragment>
				}
			</div>
		)
	}
}

CreateDeviceForm.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CreateDeviceForm));