import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Integration from "../../../services/DataModels/Integration";
import IntegrationsList from '../../IntegrationsSpecific/IntegrationsList'
import Loading from '../../DisplayOriented/Loading';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = theme => {
	return ({
		container: {
			display: "flex",
			width: "100%",
		},
		link: {
			color: theme.palette.pending.main,
			"-webkit-text-decoration": "underline transparent",
			textDecoration: "underline transparent",
			transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			cursor: "pointer",
			"&:hover": {
				"-webkit-text-decoration-color": "#1153b6",
				textDecorationColor: theme.palette.pending.main,
				cursor: theme.palette.pending.main,
			}
		},
	})
	
};

class IntegrationsTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			integrations: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			sort: null
		};
		
		this.load_types();
		this.props.tabHostProxy.setRootRefresh(this.load_integrations);
	}

	load_types = () => {
		new Integration()
			.loadIntegrationTypesAndOptions()
			.then( ({integrationTypes}) => {
				this.integration_types = integrationTypes;
				this.load_integrations();
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	load_integrations = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.sort);
		delete params.total;
		if (this.state.integrations != null) this.setState({integrations: null});
		new Integration().listFromAPI(params).then( (results) => {
			results.items.forEach( (integration) => {
				integration.table_name = <div onClick={() => this.props.tabHostProxy.addTab("integration", integration)} className={classes.link}>{integration.name}</div>;
				integration = Integration.assignIntegrationLogo(integration);
				integration.nested_type = this.integration_types[integration.type];
			});
			this.setState( (prev_state) => {
				return {
					integrations: results.items,
					page_data: {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: results.total
					}
				};
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_integrations();
		});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_integrations();
		});
	}

	render() {
		let { tabHostProxy, classes } = this.props;
		const { integrations, page_data } = this.state;
		return (
			<div className={classes.container}>
				{integrations === null ?
					<Loading/>
				:
					<IntegrationsList
						page_change={this.page_change}
						integrations={integrations}
						page_data={page_data}
						perform_sort={this.perform_sort}
						tabHostProxy={tabHostProxy}
					/>
				}
			</div>
		);
	}
}

IntegrationsTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(IntegrationsTab));
