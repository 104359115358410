import React from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import green from '@material-ui/core/colors/green';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Company from '../../services/DataModels/Company';

const styles = theme => ({
	matchMessage: {
		color: "grey",
		fontFamily: "Inter,sans-serif",
		textDecoration: "none",
		fontSize: "16px",
	},
	unique: {
		top: "30px",
		right: "15px",
		position: "absolute",
		width: "30px",
		height: "30px",
		color: green[600],
	},
	notUnique: {
		top: "30px",
		right: "15px",
		position: "absolute",
		width: "30px",
		height: "30px",
		color: "red",
	},
	loading: {
		top: "30px",
		right: "15px",
		position: "absolute",
	},
	inputContainer: {
		position: "relative",
	}
});

class CompanyIDInput extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {};
		if (this.props.initial !== '') {
			this.state.id = this.props.initial;
			this.state.idInUse = false;
		} else {
			this.state.id = '';
			this.state.idInUse = null;
		}
	}

	handleChange = (event) => {
		let id = function(){
			return this.target.value;
		}.bind(event)();
		let pattern = /^[a-z0-9_:-]+$/
		if (id === "") {
			//allows for clearing it out but it is still invalid w/o sending request
			this.setState({id: id, idInUse: true});
			this.props.updateID(false);
		}
		else if (!pattern.test(id)) {
			//blocks characters from being entered.
			return;
		} else {
			this.setState({id: id, idInUse: null}, () => {
				new Company({ _id: id }).readFromAPI().then( (data) => {
					this.setState({idInUse: true});
					this.props.updateID(false);
				}).catch( () => {
					this.setState({idInUse: false}, () => {
						this.props.updateID(this.state.id);
					});
				});
			});
		}
	}

	uniqueIdResult = (classes) => {
		if (this.state.idInUse === null && this.state.id !== "") {
			return (
				<CircularProgress size={30} className={classes.loading}/>
			);
		} else if (this.state.idInUse === true) {
			return (
				<CancelIcon className={classes.notUnique}/>
			);
		} else if (this.state.idInUse === false) {
			return (
				<CheckCircleIcon className={classes.unique}/>
			);
		}
	}

	render() {
		const {classes} = this.props;
		const { id } = this.state;
		return (
			<React.Fragment>
				<div className={classes.inputContainer}>
					<TextField
						fullWidth={true}
						id="id"
						label="ID"
						name="id"
						margin="normal"
						variant="outlined"
						value={id}
						onChange={this.handleChange}
					/>
					{this.uniqueIdResult(classes)}
				</div>
				<div className={classes.matchMessage}>
					{`The ID for your customer's account should only contain lowercase letters and numbers.
					Special characters like spaces and symbols are not allowed, excluding colons, hyphens,
					and underscores. The ID must also be unique.`}
				</div>
			</React.Fragment>
		);
	}

}

export default withStyles(styles)(withTheme()(CompanyIDInput));
