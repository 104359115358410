import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PaginationContainer from '../Containers/PaginationContainer';
import Card from '@material-ui/core/Card';
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import User from '../../services/DataModels/User';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableList from '../Table/TableList';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Loading from '../DisplayOriented/Loading';

//icons
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/PersonOutline';
import EditIcon from '@material-ui/icons/Edit';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Permissions from '../../services/Permissions';

const heading_info = [
    { label: "Name", value: "table_name", field: "last_name", align: "left", disablePadding: false, sortable: true, content: "function" },
    { label: "Email/Username", field: "email", align: "left", disablePadding: false, sortable: true },
    { label: "Account", field: "ui_company", align: "left", disablePadding: false, sortable: false },
    { label: "Role", field: "ui_user_type", align: "left", disablePadding: false, sortable: false },
];

class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            modal: {
                open: false,
                children: () => ""
            },
            layout: "card"
        }
    }

    openUserTab(user) {
        this.props.tabHostProxy.addTab("user", user);
    }

    createUser = () => {
        this.props.tabHostProxy.addTab("user");
    }

    handleChangePage = (event, page) => {
        this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
    };

    handleChangeRowsPerPage = event => {
        this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
    };

    confirm_delete = (user, classes) => {
        this.setState({
            modal: {
                "open": true,
                yesFunction: null,
                children: (classes) => (
                    <div className={classes.modalWrapper}>
                        <div className={classes.prompt}>
                            Delete this user? This action cannot be reversed.
                        </div>
                        <div className={classes.modalButtons}>
                            <Button
                                color="primary"
                                onClick={this.close_modal}
                            >
                                CANCEL
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                className={classes.deleteButton}
                                onClick={() => this.delete_user(user)}
                            >
                                DELETE USER
                            </Button>
                        </div>
                    </div>
                )
            }
        });
    }

    delete_user = (user) => {
        new User(user)
            .deleteFromAPI()
            .then(() => {
                this.context.openSnackbar("User deleted.", "success");
                this.props.tabHostProxy.closeOther(user._id, true);
                this.props.tabHostProxy.refresh();
                this.close_modal();
            }).catch((error) => {
                this.context.openSnackbar(error, "error");
            });
    }

    close_modal = () => {
        this.setState({
            modal: {
                open: false,
                children: () => ""
            }
        });
    }

    render_table_layout = () => {
        const { users, page_data } = this.props;
        if (!users) {
            return <Loading />;
        }
        return (
            <TableList
                headings={heading_info}
                items={users}
                page_data={page_data}
                perform_sort={this.props.perform_sort}
            />
        );
    }

    render_card_layout = () => {
        const { users, classes } = this.props;
        if (!users) {
            return <Loading />;
        }
        const defaultUserImage = require('../../images/placeholder_users.svg').default;
        return (
            <React.Fragment>
                {users.map((user) => {
                    return (
                        <div key={user._id} className={classes.userCardContainer}>
                            <Card className={classes.userCard}>
                                <div className={classes.optionsIconsContainer}>
                                    <div className={classes.rightOptionsContainer}>
                                        <div className={classes.optionsIcon} onClick={() => this.openUserTab(user)}>
                                            <Tooltip classes={{ tooltip: classes.tipOverride }} title={<span className={classes.tip}>Edit User</span>}>
                                                <EditIcon />
                                            </Tooltip>
                                        </div>
                                        {Permissions.allow(["delete"], "user", user.company_id) ? <div className={classes.optionsIcon}>
                                            <Tooltip
                                                classes={{ tooltip: classes.tipOverride }}
                                                onClick={() => this.confirm_delete(user, classes)}
                                                key="Delete User"
                                                title={<span className={classes.tip}>Delete User</span>}
                                            >
                                                <DeleteIcon />
                                            </Tooltip>
                                        </div> : ""}
                                    </div>
                                </div>
                                <div onClick={() => this.openUserTab(user)} className={classes.userImageContainer}>
                                    {user.ui_valid_profile_image === true ?
                                        <img className={classes.profileImage} src={user.logo_url} />
                                        :
                                        <img className={classes.placeholderProfileImage} src={defaultUserImage} />
                                    }
                                </div>
                                <div onClick={() => this.openUserTab(user)} className={classes.textRowContainer}>
                                    <div className={classes.userTitle}>
                                        {user.ui_name}
                                    </div>
                                    <div className={classes.iconWithTextContainer}>
                                        <Tooltip key="Email Address" title="Email Address">
                                            <AlternateEmailIcon className={classes.companyIcon} />
                                        </Tooltip>
                                        <div className={classes.iconLabel}>
                                            {user.email}
                                        </div>
                                    </div>
                                    <div className={classes.iconWithTextContainer}>
                                        <Tooltip key="Parent Account" title="Parent Account">
                                            <AccountCircleIcon className={classes.companyIcon} />
                                        </Tooltip>
                                        <div className={classes.iconLabel}>
                                            {user.ui_company}
                                        </div>
                                    </div>
                                    <div className={classes.iconWithTextContainer}>
                                        <Tooltip key="Role" title="Role">
                                            <PeopleIcon className={classes.companyIcon} />
                                        </Tooltip>
                                        <div className={classes.iconLabel}>
                                            {user.ui_user_type}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }


    render() {
        const { users, page_data, classes } = this.props;
        let buttons = Permissions.allow(["create"], "user") ? [{ label: "CREATE USER", icon: (<AddIcon />), action: this.createUser }] : undefined;
        return (
            <div className={classes.container}>
                <SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
                    {this.state.modal.children(classes)}
                </SimpleModalWrapped>
                <PaginationContainer
                    buttons={buttons}
                    count={page_data.total}
                    rowsPerPage={page_data.per_page}
                    currentPage={page_data.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    toggleView={(layout) => this.setState({ layout: layout })}
                    toggle_filter={this.props.show_filters}
                    filtering={this.props.filtering}
                />
                {this.state.layout === "card" ?
                    <div className={classes.cardItemsContainer}>
                        {this.render_card_layout()}
                    </div> :
                    <div className={classes.tableItemsContainer}>
                        {this.render_table_layout()}
                    </div>
                }
            </div>
        );
    }
}



const styles = (theme) => {
    return ({
        container: {
            fontFamily: "Inter",
            flexGrow: 2,
            width: "75%",
        },
        usersContainer: {
            overflowY: "auto",
            height: "calc(100% - 82px)",
            padding: "12px",
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: "#f5f5f7",
        },
        cardItemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 82px)",
            padding: "12px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            backgroundColor: "#f5f5f7",
        },
        tableItemsContainer: {
            overflowY: "auto",
            height: "calc(100% - 82px)",
            alignItems: "flex-start",
            flexWrap: "wrap",
            backgroundColor: "#ffffff",
        },
        prompt: {
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "32px",
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "32px",
        },
        modalWrapper: {
        },
        userCardContainer: {
            width: "33.33%",
            display: "inline-flex",
            position: "relative",
            justifyContent: "center",
        },
        userCard: {
            boxShadow: "0px 3px 7px 2px #00000017",
            backgroundColor: "#fafafa",
            margin: "12px",
            width: "100%",
            minWidth: "250px",
            maxWidth: "380px",
            height: "auto",
            minHeight: "209px",
            border: "#ffffff solid 1px",
            transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
            "&:hover": {
                border: `${theme.palette.pending.main} solid 1px`,
            },
        },
        userImageContainer: {
            display: "flex",
            height: "138px",
            cursor: "pointer",
            backgroundColor: "#f5f5f7",
        },
        userImage: {
            maxWidth: "100%",
            maxHeight: "138px",
            margin: "auto",
            display: "block",
        },
        textRowContainer: {
            position: "relative",
            padding: "16px 24px 24px 24px",
            cursor: "pointer",
        },
        modalButtons: {
            display: "flex",
            justifyContent: "flex-end"
        },
        userTitle: {
            fontSize: "16px",
            color: "#000000",
            opacity: "0.87",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: "16px",
        },
        iconWithTextContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
            width: "100%",
        },
        companyIcon: {
            fontSize: "20px",
            color: "#636366",
            marginRight: "8px",
        },
        iconLabel: {
            color: "#636366",
            overflow: "hidden",
            fontSize: "14px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        buttonContainer: {
            display: "flex",
            margin: "auto",
            marginRight: 0
        },
        viewUserButton: {
            padding: "8px",
        },
        bottomRow: {
            display: "flex",
            backgroundColor: "white",
            padding: "8px",
        },
        profileImage: {
            maxWidth: "100%",
            maxHeight: "138px",
            margin: "auto",
            display: "block",
        },
        placeholderProfileImage: {
            width: "60px",
            height: "60px",
            margin: "auto",
        },
        topRowIconsContainer: {
            boxSizing: "border-box",
            position: "absolute",
            justifyContent: "flex-end",
            top: 0,
            padding: "0 4px",
            left: 0,
            width: "100%",
            display: "flex",
        },
        iconButton: {
            padding: "7px",
            color: "#8e8e93",
        },
        deleteButton: {
            marginLeft: "8px",
        },
        optionsIconsContainer: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            height: "36px",
            backgroundColor: "#ffffff",
            padding: "8px",
            boxSizing: "border-box",
        },
        rightOptionsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "75%",
        },
        optionsIcon: {
            marginLeft: "16px",
            color: "#8e8e93",
            width: "20px",
            height: "20px",
            "& svg": {
                fontSize: "20px",
            },
            cursor: "pointer",
            "&:hover": {
                color: "#0263fc",
            },
            transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        tip: {
            fontSize: "14px",
        },
        tipOverride: {
            padding: "8px",
            boxSizing: "border-box",
            marginTop: "0px",
        },
    })
};

UsersList.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(UsersList));
