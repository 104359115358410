import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';
import Permissions from "../Permissions";
import Auth from '../Auth';
import { LIST } from '../CLURD';

class IntegrationEvent extends DataModel {
	constructor() {
		super('integration_events')
	}

	static getHeadingsForMessages() {
		return [
			{label: "Created At", value: "created_at", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date"},
			{label: "Type", value: "type", field: "type", align: "left", disablePadding: false, sortable: true},
			{label: "Message", value: "message", field: "message", align: "left", disablePadding: false, sortable: false},
			{label: "Integration", value: "nested_integration.name", field: "name", align: "left", nested_field: true, disablePadding: false, sortable: false},
			{label: "Account", value: "nested_company.name", field: "name", align: "left", nested_field: true, disablePadding: false, sortable: false},
		];
	}

	static loadForMessageDisplay(params) {
		return new Promise( (resolve, reject) => {
			new IntegrationEvent().listFromAPI(params).then( (result) => {
				let events = result.items;
				AssignNestedModels("companies", "company_id", events).then( () => {
					result.items = events;
					AssignNestedModels("integrations", "integration_id", events).then( () => {
						result.items = events.filter((item) => item.nested_integration != null);
						resolve(result);
					}).catch( (error) => {
						reject(error);
					});
				}).catch( (error) => {
					reject(error);
				});
			}).catch( (error) => {
				reject(error);
			});
		});
	}

}

export default IntegrationEvent;
