import React from 'react';
import moment from 'moment';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Device from '../../services/DataModels/Device';
import { GetAll, AssignNestedModels } from '../../services/CLURDUtilities';

//inputs
import PackageSelectionInput from '../Inputs/PackageSelectionInput';
import DeviceSelectionInput from '../Inputs/DeviceSelectionInput';
import DateSelection from '../Inputs/DateSelection';
import SwitchInput from '../Inputs/SwitchInput';
import SelectInput from '../Inputs/SelectInput';

//mui
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

//icons
import DvrIcon from '@material-ui/icons/DvrOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventIcon from '@material-ui/icons/Event';
import TodayIcon from '@material-ui/icons/Today';

class IssueUpdateFlow extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			active_step: 0,
			scheduled: false,
			device_selection: {
				status: "normal",
				devices: [],
				device_ids: [],
				devices_prepped: false
			},
			package_ids: null,
			packages: null,
			start_datetime: null,
			end_datetime: null,
			issuing: false,
			device_type_id: this.props.pkg ? this.props.pkg.device_type_id : null,
			steps: [],
			step_render_functions: [],
			retry_interval: "daily"
		};
		if (!this.state.device_type_id) {
			this.state.steps = ["Select Packages", "Select Devices to Update", "Schedule this Update", "Review & Submit"];
			this.state.step_render_functions = [this.render_step_0];
		} else {
			this.state.steps = ["Select Devices to Update", "Schedule this Update", "Review & Submit"];
			this.state.step_render_functions = [this.render_step_1, this.render_step_2, this.render_step_3];
		}
		this.schedule_options = [
			{display:"Daily", value:"daily"},
			{display:"Weekly", value:"weekly"},
		];
		this.set_date_info();
		this.set_help();
		this.set_heading_info();
	}

	set_heading_info = () => {
		this.heading_info = [
			{label: "Name", value: "table_name", nested_field: false, field: "table_name", align: "left", disablePadding: false, sortable: false},
			{label: "Unique ID", value: "unique_id", field: "unique_id", nested_field: false, align: "left", disablePadding: false, sortable: false},
			{label: "Account", value: "nested_company.name", nested_field: true, field: "company_name", align: "left", disablePadding: false, sortable: false},
			{label: "Tags", value: "table_tags", field: "table_tags", nested_field: false, align: "left", disablePadding: false, sortable: false},
		];
	}

	set_date_info = () => {
		this.tz = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
		let tonight = moment().endOf('day').toDate();
		let tomorrowEarlyMorning = moment(tonight).add(6, 'hours').toDate();
		this.state.start_datetime = tonight;
		this.state.end_datetime = tomorrowEarlyMorning;
	}

	set_help = () => {
		this.step_0_help = {
			title: "Selecting Software Packages",
			content: `Select one or more software packages to send to one or more devices. Note that the packages you select must all be for the same type of device.`,
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
		this.step_1_help = {
			title: "Updating Devices",
			content: `A software package is created with a certain type of device in mind. You can select these kinds of devices in this step. If you don't see the device you'd like to select, ensure your filters are set correctly, and also make sure that the device type matches the package's type. If you'd like to issue several updates using more than one package, use the checkboxes on the package selection screen.`,
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
		this.step_2_help = {
			title: "Scheduling Software Updates",
			content: `The device(s) you'd like to update might not be online at this very moment, so scheduling a window for the update gives leeway to the update process.`,
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
	}

	render_step_0 = () => {
		const { classes } = this.props;
		const { show_errors, device_type_id } = this.state;
		let params = device_type_id ? { device_type_id: device_type_id } : null;
		return (
			<div className={classes.stepContent}>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Select One or More Packages
					</div>
				</div>
				<div className={classes.description}>
					Select packages to send to one or more devices. <span className={classes.emphasis}>The initial package you select will determine the device type and the list of packages will be refreshed.</span>
				</div>
				<div className={classes.helpLinkContainer}>
					<Button color="primary" className={classes.helpLink} onClick={() => this.context.openHelp(this.step_0_help)} aria-label="Read More About Software Packages">
						Read More About Software Packages
					</Button>
				</div>
				<div className={classes.deviceSelectionWrapper}>
					<PackageSelectionInput onChange={this.package_selection_change} params={params}/>
				</div>
				{show_errors ? 
					<div className={classes.error}>
						Please select one or more packages to continue.
					</div>
				:
					""
				}
			</div>
		);
	}


	render_step_1 = () => {
		const { classes } = this.props;
		const { show_errors, device_type_id } = this.state;
		const params = { device_type_id: device_type_id };
		return (
			<div className={classes.stepContent}>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Select One or More Devices to Update
					</div>
					{this.render_package_info()}
				</div>
				<div className={classes.description}>
					Find and select devices to update. Using the filter will deselect selected devices.
				</div>
				<div className={classes.helpLinkContainer}>
					<Button color="primary" className={classes.helpLink} onClick={() => this.context.openHelp(this.step_1_help)} aria-label="Read More About Software Updates">
						Read More About Software Updates
					</Button>
				</div>
				<div className={classes.deviceSelectionWrapper}>
					<DeviceSelectionInput onChange={this.device_selection_change} params={params}/>
				</div>
				{show_errors ? 
					<div className={classes.error}>
						Please select one or more devices to receive the software update.
					</div>
					:
					""
				}
			</div>
		);
	}

	package_selection_change = (package_ids, packages) => {
		this.setState( (state) => {
			if (state.show_errors && (package_ids && package_ids.length > 0)) {
				state.show_errors = false;
			}
			state.packages = packages;
			state.package_ids = package_ids;
			state.device_type_id = packages && packages.length > 0 ? packages[0].device_type_id : null;
			return state;
		});
	}

	device_selection_change = (device_ids, devices, all_selected, formerly_all_selected, filters) => {
		this.setState( (state) => {
			let devices_selected = all_selected || formerly_all_selected || devices.length > 0;
			if (state.show_errors && devices_selected) {
				state.show_errors = false;
			}
			state.device_selection = {
				status: all_selected === true ? "all_selected" : formerly_all_selected  === true ? "formerly_all_selected" : "normal",
				device_ids: device_ids,
				devices: this.assign_table_fields(devices),
				devices_prepped: all_selected === true || formerly_all_selected  === true ? false : true,
				filters: filters
			}
			return state;
		});
	}

	assign_table_fields = (devices) => {
		const {theme, classes } = this.props;
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		if (!devices || devices.length === 0) return [];
		devices.forEach( (device) => {
			device.table_tags = device.tags && device.tags.length > 0 ? device.tags.join(", ") : "No Tags";
			device.table_name = (
				<div className={classes.nameContainer}>
					<div style={{backgroundColor: status_map[device.heartbeat_status]}} className={classes.statusDot}>
						&nbsp;
					</div>
					<span className={classes.name}>{device.name}</span>
				</div>
			);
		});
		return devices;
	}

	render_package_info = () => {
		const { classes, pkg } = this.props;
		const { packages } = this.state;
		const pkg_name = pkg ? pkg.name : packages ? packages.map( ({name}) => name).join(", ") : "";
		return (
			<div className={classes.packageInfoWrapper}>
				<CodeIcon className={classes.packageIcon}/>
				<span className={classes.packageText}>Package(s) Selected:</span>
				<span className={classes.packageName}>{pkg_name}</span>
			</div>
		);
	}

	render_step_2 = () => {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<div className={classes.stepContent}>
					<div className={classes.titleRow}>
						<div className={classes.title}>
							Schedule this Update, or Update ASAP
						</div>
						{this.render_package_info()}
					</div>
					<div className={classes.description}>
						Set a window for this update to take place, or untoggle the schedule option so that as soon as the device is available the update will begin. The times selected are in your current timezone: {this.tz}
						<div className={classes.helpLinkContainer}>
							<Button color="primary" className={classes.helpLink} onClick={() => this.context.openHelp(this.step_2_help)} aria-label="Read More About Scheduling Updates">
								Read More About Scheduling Updates
							</Button>
						</div>
					</div>
					{this.render_schedule_toggle()}
					{this.render_date_range()}
				</div>
			</React.Fragment>
		);
	}

	schedule_toggle = ({value}) => {
		this.setState( (state) => {
			state.scheduled = value;
			return state;
		});
	}

	render_schedule_toggle = () => {
		const { classes, theme } = this.props;
		const { scheduled } = this.state;
		return (
			<div className={classes.switchInputWrapper}>
				<EventAvailableIcon className={classes.inputIcon}/>
				<div className={classes.switchWrapper}>
					<SwitchInput
						containerClass={classes.switchContainer}
						initial={scheduled}
						color={theme.palette.pending.main}
						emitChange={this.schedule_toggle}
						onLabel="Schedule Update"
						offLabel="Schedule Update"
						field="scheduled"
						location="start"
					/>
				</div>
			</div>
		);
	}

	handle_repeat_change = ({field, value}) => {
		this.setState( (state) => {
			state.retry_interval = value;
			return state;
		});
	}

	render_repeat = () => {
		const { classes } = this.props;
		const { retry_interval } = this.state;
		return (
			<div className={classes.schedulingWrapper}>
				<div className={classes.schedulingLabel}>
					<AutorenewIcon className={classes.inputIcon}/>
					<div className={classes.scheduleLabel}>If the device is unreachable, retry</div>
				</div>
				<div className={classes.dateInputsWrapper + " " + classes.repeatWrapper}>
					<SelectInput
					emitChange={this.handle_repeat_change}
					priorState={retry_interval}
					options={this.schedule_options}
					field="retry_interval"
				/>
				</div>
				
			</div>
		);
	}

	render_date_range = () => {
		const { classes } = this.props;
		const { start_datetime, end_datetime, scheduled } = this.state;
		const scheduled_text = scheduled ? " Between..." : " Right Away";
		return (
			<div className={classes.schedulingWrapper}>
				<div className={classes.schedulingLabel}>
					<DateRangeIcon className={classes.inputIcon}/>
					<span className={classes.scheduleLabel}>Perform Update{scheduled_text}</span>
				</div>
				{scheduled ? 
					<React.Fragment>
						<div className={classes.dateInputsWrapper}>
							<DateSelection
								priorState={{firstDate: start_datetime, secondDate: end_datetime, range: "Between"}}
								field="start_datetime"
								emitChange={this.date_change}
								disablePast
								range
								rangeLocked
							/>
						</div>
						{this.render_repeat()}
					</React.Fragment>
					: ""
				}
			</div>
		);
	}

	date_change = ({field, value}) => {
		if (typeof value === 'object') {
			this.setState( (state) => {
				state.start_datetime = value.firstDate;
				state.end_datetime = value.secondDate;
				return state;
			});
		}
	}

	prep_devices = () => {
		const { device_selection, device_type_id } = this.state;
		let params = { device_type_id: device_type_id };
		if (device_selection.status === "all_selected") {
			params = Object.entries(device_selection.filters).reduce( (map, [key, value]) => {
				if (value != null && value != '') {
					if (key == "company_id_in" || key == "tags_incall") {
						map[key] = value.map( ({value}) => value);
					} else {
						map[key] = value;
					}
				}
				return map;
			}, params);
		} else if (device_selection.status === "formerly_all_selected") {
			params = Object.entries(device_selection.filters).reduce( (map, [key, value]) => {
				if (value != null && value != '') {
					if (key == "company_id_in" || key == "tags_incall") {
						map[key] = value.map( ({value}) => value);
					} else {
						map[key] = value;
					}
				}
				return map;
			}, params);
			params._id_nin = device_selection.device_ids;
		} else {
			return;
		}
		GetAll("devices", params).then( (devices) => {
			AssignNestedModels("companies", "company_id", devices).then( () => {
				this.setState( (state) => {
					state.device_selection.devices = this.assign_table_fields(devices);
					state.device_selection.device_ids = devices.map( (device) => device._id);
					state.device_selection.devices_prepped = true;
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
			
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_step_3 = () => {
		const { classes, pkg } = this.props;
		const { packages, device_selection, start_datetime, end_datetime, scheduled, retry_interval } = this.state;
		const pkg_name = pkg ? pkg.name : packages ? packages.map( ({name}) => name).join(", ") : "";
		const schedule_text = "Perform Update" + (scheduled ? " Between..." : " Right Away");
		if (device_selection.devices_prepped === false) {
			return <Loading />;
		}
		return (
			<React.Fragment>
				<div className={classes.stepContent}>
					<div className={classes.titleRow}>
						<div className={classes.title}>
							Review
						</div>
					</div>
					<div className={classes.description}>
						Please review the software package, devices selected, and schedule information. Click the 'Submit' button to kick off the update process.
					</div>
					<div className={classes.reviewTitle}>
						<CodeIcon className={classes.reviewIcon}/>
						<span className={classes.reviewField}>Package(s) Selected:</span>
						<span className={classes.packageName}>{pkg_name}</span>
					</div>
					<div className={classes.reviewTitle}>
						<DateRangeIcon className={classes.reviewIcon}/>
						<span className={classes.reviewField}>{schedule_text}</span>
					</div>
					{scheduled ? <div className={classes.scheduleReview}>
						<div className={classes.scheduleContainer}>
							<TodayIcon className={classes.reviewIcon}/>
							{moment(start_datetime).format("MMMM Do h:mm a") + " " + this.tz}
						</div>
						<span>and</span>
						<div className={classes.scheduleContainer}>
							<EventIcon className={classes.reviewIcon}/>
							{moment(end_datetime).format("MMMM Do h:mm a") + " " + this.tz}
						</div>
					</div> : ""}
					{scheduled ? 
						<div className={classes.reviewTitle}>
							<AutorenewIcon className={classes.reviewIcon}/>
							<span className={classes.reviewField}>If the device is unreachable, retry: <span className={classes.packageName + " " + classes.retryInterval}>{retry_interval}</span></span>
						</div>
					: ""}
					<div className={classes.reviewTitle}>
						<DvrIcon className={classes.reviewIcon}/>
						<span>Devices Selected</span>
					</div>
					<div className={classes.reviewTableWrapper}>
						<TableList
							headings={this.heading_info} 
							items={device_selection.devices}
							perPage={5}
						/>
					</div>
					
				</div>
			</React.Fragment>
		);
	}

	add_steps = () => {
		this.setState( (state) => {
			state.step_render_functions = state.step_render_functions.concat([this.render_step_1, this.render_step_2, this.render_step_3]);
			return state;
		});
	}

	remove_steps = () => {
		this.setState( (state) => {
			this.state.step_render_functions = [this.render_step_0];
			return state;
		});
	}

	render_step_buttons = () => {
		const { active_step, device_selection, package_ids } = this.state;
		const { closeModal, classes } = this.props;
		let back = null;
		let next = null;
		let can_progress = true;
		let action = (new_step) => {
			this.setState({active_step: new_step});
		}
		let step = active_step;
		let device_can_progress = device_selection.status === "all_selected" || device_selection.status === "all_formerly_selected" || device_selection.devices.length > 0;
		if (!this.props.pkg) {
			switch (step) {
				case 0:
					back = { label: "Cancel", action: closeModal };
					next = { label: "Next", action: () => {
						this.add_steps();
						action(1);
					 }};
					can_progress = package_ids && package_ids.length > 0;
					break;
				case 1:
					back = { label: "Back", action: () => {
						this.remove_steps();
						action(0);
					}};
					next = { label: "Next", action: () => action(2) };
					can_progress = device_can_progress;
					break;
				case 2:
					back = { label: "Back", action: () => action(1) };
					next = { label: "Review", action: () => {
						this.prep_devices();
						action(3);
					}};
					break;
				case 3:
					back = { label: "Back", action: () => action(2) };
					next = { label: "Submit", action: this.submit };
					break;
			}
		} else {
			switch (step) {
				case 0:
					back = { label: "Cancel", action: closeModal };
					next = { label: "Next", action: () => action(1) };
					can_progress = device_can_progress;
					break;
				case 1:
					back = { label: "Back", action: () => action(0) };
					next = { label: "Review", action: () => {
						this.prep_devices();
						action(2);
					}};
					break;
				case 2:
					back = { label: "Back", action: () => action(1) };
					next = { label: "Submit", action: this.submit };
					break;
			}
		}
		
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={() => back.action()}
					className={classes.buttonOverride}
					color="primary"
				>
					{back.label}
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={() => can_progress ? next.action() : this.setState({show_errors: true})}
					className={classes.buttonOverride}
				>
					{next.label}
				</Button>
			</div>
		);
	}

	render_stepper_display = () => {
		const { active_step, steps } = this.state;
		const { classes } = this.props;
		return (
			<div>
				<Stepper
					classes={{root: classes.stepperRoot}}
					className={classes.stepper}
					activeStep={active_step}
				>
					{steps.map( (label, index) => (
						<Step key={label}>
							<StepLabel></StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
		);
	}

	render_active_step = () => {
		const { classes } = this.props;
		const { active_step, steps, step_render_functions } = this.state;
		return (
			<React.Fragment>
				{steps.map( (step, index) => {
					return index !== active_step ? 
						<div key={step + "_content"} className={classes.stepContentContainer} style={{display: "none"}}>
							{step_render_functions[index] ? step_render_functions[index]() : ""}
							{this.render_step_buttons()}
						</div>
					: 
						<div className={classes.stepContentContainer} key={step + "_content"} style={{display: "flex"}}>
							{step_render_functions[index] ? step_render_functions[index]() : ""}
							{this.render_step_buttons()}
						</div>
				})}
			</React.Fragment>
		);
	}

	submit = () => {
		const { device_selection, package_ids, scheduled, start_datetime, end_datetime, retry_interval} = this.state;
		const schedule_data = scheduled ? {
			start_datetime: start_datetime.toISOString(),
			end_datetime: end_datetime.toISOString(),
			retry_interval: retry_interval
		} : null;
		this.setState({issuing: true});
		if (this.props.pkg) {
			Device.bulkCommand("software_update", device_selection.device_ids, schedule_data, this.props.pkg._id).then( (result) => {
				this.setState({issuing: false});
				this.context.openSnackbar("The update process has successfully begun.", "success");
				this.props.closeModal();
			}).catch( (error) => {
				this.setState({issuing: false});
				this.context.openSnackbar(error, "error")
			});
		} else {
			let updates = package_ids.map( (id) => Device.bulkCommand("software_update", device_selection.device_ids, schedule_data, id));
			Promise.all(updates).then( () => {
				this.setState({issuing: false});
				this.context.openSnackbar("The update processes have successfully begun.", "success");
				this.props.closeModal();
			}).catch( (error) => {
				this.setState({issuing: false});
				this.context.openSnackbar(error, "error")
			});
		}
		
	}

	render_issuing = () => {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Beginning the Update Process...
					</div>
				</div>
				<Loading />
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		const { issuing } = this.state;
		return (
			<div className={classes.container}>
				{issuing ? this.render_issuing() : 
					<React.Fragment>
						{this.render_stepper_display()}
						{this.render_active_step()}
					</React.Fragment>
				}
			</div>
		)
	}
}

IssueUpdateFlow.contextType = SnackbarContext;
const styles = (theme) => {
	return ({
		stepContentContainer: {
			fontFamily: "Inter",
			justifyContent: "space-between",
			flexDirection: "column",
			minHeight: "528px",
		},
		renderFunctionsContainer: {
			maxHeight: "550px",
			overflowY: "auto",
		},
		title: {
			display: "flex",
			marginRight: "12px",
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
		},
		description: {
			color: "#8e8e93",
			fontSize: "14px",
		},
		titleRow: {
			display: "flex",
			marginBottom: "32px"
		},
		reviewField: {
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "14px",
		},
		reviewIcon: {
			marginRight: "8px",
			color: "#8e8e93",
		},
		scheduleReview: {
			marginLeft: "34px",
			color: "#8e8e93",
		},
		scheduleContainer: {
			display: "flex",
			alignItems: "center",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "16px",
			margin: "12px 0"
		},
		reviewTitle: {
			display: "flex",
			alignItems: "center",
			color: "rgba(0, 0, 0, 0.87)",
			margin: "24px 0",
			fontSize: "14px"
		},
		packageInfoWrapper: {
			display: "flex",
			alignItems: "center"
		},
		packageIcon: {
			color: "#8e8e93",
			marginRight: "6px"
		},
		packageText: {
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "14px",
		},
		packageName: {
			color: "#8e8e93",
			fontSize: "14px",
			marginLeft: "6px",
		},
		retryInterval: {
			textTransform: "capitalize"
		},
		helpLinkContainer: {
			display: "block",
			margin: "12px 0",
		},
		helpLink: {
			paddingLeft: 0,
		},
		emphasis: {
			textDecoration: "underline",
			display: "block",
			margin: "12px 0"
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "24px"
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		stepper: {
		},
		error: {
			color: theme.palette.red.main,
		},
		switchInputWrapper: {
			margin: "12px 0",
			display: "flex",
			alignItems: "center"
		},
		scheduleLabel: {
			color: "#8e8e93",
			fontSize: "14px",
		},
		inputIcon: {
			marginRight: "12px",
			color: "#8e8e93",
		},
		switchWrapper: {
			marginTop: "-2px"
		},
		switchContainer: {
			marginBottom: "6px",
			marginTop: 0,
		},
		dateInputsWrapper: {
			marginLeft: "34px",
			width: "66%"
		},
		repeatWrapper: {
			marginTop: "16px"
		},
		schedulingLabel: {
			display: "flex",
			alignItems: "center",
			color: "#8e8e93",
		},
		label: {
			color: "#8e8e93",
			marginLeft: "2px"
		},
		nameContainer: {
			display: "flex",
			alignItems: "center",
		},
		name: {
			whiteSpace: "nowrap"
		},
		statusDot: {
			width: "8px",
			height: "8px",
			minWidth: "8px",
			minHeight: "8px",
			maxWidth: "8px",
			maxHeight: "8px",
			borderRadius: "50%",
			display: "inline-flex",
			marginRight: "4px"
		},
		scheduleText: {
			fontFamily: "Inter",
			fontSize: "18px",
		},
		stepperRoot: {
			padding: 0,
			marginBottom: "32px",
		},
		stepContent: {
			height: "513px",
			maxHeight: "513px",
		},
		deviceSelectionWrapper: {
			height: "360px",
			overflowY: "auto",
			paddingRight: "16px",
		},
		reviewTableWrapper: {
			height: "250px",
			overflowY: "auto",
			paddingRight: "16px",
		}
	});
};
export default compose(
	withStyles(styles),
	withTheme(),
)(IssueUpdateFlow);
