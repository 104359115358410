import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { darken } from '@material-ui/core/styles/colorManipulator';
import PaginationContainer from '../Containers/PaginationContainer';
import ContentLoader from "react-content-loader"
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import CreateSoftwareUpdateForm from '../SoftwareUpdatesSpecific/CreateSoftwareUpdateForm';
import TableList from '../Table/TableList';
import VerticalTabs from '../DisplayOriented/VerticalTabs';
import Loading from '../DisplayOriented/Loading';
import IssueUpdateFlow from './IssueUpdateFlow';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import SoftwareUpdate from '../../services/DataModels/SoftwareUpdate';
import Permissions from '../../services/Permissions';
import Auth from '../../services/Auth';
import { BULK_EDIT } from '../../services/CLURD';
import { GetAll } from '../../services/CLURDUtilities';

//mui
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

//icons
import EventIcon from '@material-ui/icons/Event';
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import UpdateIcon from '@material-ui/icons/Update';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';

const styles = (theme) => {
	return ({
		container: {
			fontFamily: "Inter",
			flexGrow: 2,
			display: "flex",
			flexWrap: "wrap",
			overflow: "hidden"
		},
		actionListTitle: {
			marginBottom: "8px",
			padding: "11px 16px",
			borderBottom: "solid #80808073 1px",
			backgroundColor: "white",
			cursor: "unset",
			'&:hover': {
				backgroundColor: "white",
				cursor: "unset",
			},
			outline: "none",
			fontFamily: "Inter",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "1rem",
			width: "auto",
			height: "24px",
			whiteSpace: "nowrap",
			boxSizing: "content-box",
			fontWeight: 400,
			lineHeight: "1.5em"
		},
		actionMenuItem: {
			outline: "none",
		},
		noOutline: {
			outline: "none",
			"&:focus": {
				outline: "none"
			}
		},
		modalWrapper: {
			fontFamily: "Inter",
			boxSizing: "border-box",
		},
		tableMenu: {
			color: "grey",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		modalTitle: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
		},
		tableListWrapper: {
			margin: "24px 0 32px 0"
		},
		titleWithLoading: {
			marginBottom: "12px"
		},
		noDevices: {
			marginTop: "18px"
		},
		itemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			padding: "12px",
			width: "85%",
			backgroundColor: "#f5f5f7",
			boxSizing: "border-box",
		},
		cardItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			padding: "12px",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "85%",
			backgroundColor: "#f5f5f7",
			boxSizing: "border-box",
		},
		tableItemsContainer: {
			overflowY: "auto",
			height: "calc(100% - 58px)",
			alignItems: "flex-start",
			flexWrap: "wrap",
			width: "85%",
			backgroundColor: "#ffffff",
			boxSizing: "border-box",
		},
		moreSpace: {
			height: "calc(100% - 112px)"
		},
		sideBarContainer: {
			display: "flex",
			width: "15%",
			boxSizing: "border-box",
			backgroundColor: "white",
			zIndex: 5,
			borderRight: "solid lightgrey 1px",
		},
		cardContainer: {
			width: "33.33%",
			display: "inline-flex",
			position: "relative",
			justifyContent: "center",
		},
		selectCardWrapper: {
			marginLeft: "12px"
		},
		placeholder: {
			opacity: .8,
			zIndex: 10,
			position: "absolute"
		},
		card: {
			boxShadow: "0px 3px 7px 2px #00000017",
			backgroundColor: "#fafafa",
			margin: "12px",
			width: "100%",
			minWidth: "250px",
			maxWidth: "380px",
			height: "auto",
			minHeight: "209px",
			border: "#ffffff solid 1px",
			transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 2) 0ms",
		},
		updateCard: {
			height: "207px"
		},
		selected: {
			border: `solid ${theme.palette.pending.main} 1px`,
		},
		checkbox: {
			marginLeft: "auto",
			marginRight: "6px",
		},
		link: {
			color: theme.palette.pending.main,
			"-webkit-text-decoration": "underline transparent",
			textDecoration: "underline transparent",
			transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			"&:hover": {
				"-webkit-text-decoration-color": theme.palette.pending.main,
				textDecorationColor: theme.palette.pending.main,
				cursor: "pointer",
			}
		},
		deviceLink: {
			marginLeft: "8px",
		},
		topRow: {
			display: "flex",
			flexWrap: "nowrap",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom: "16px",
		},
		name: {
			width: "80%",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			fontSize: "16px",
			color: "#000000",
			opacity: "0.87",
		},
		fileCount: {
			width: "33.33%",
			display: "flex",
			alignItems: "center"
		},
		fileCountText: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},
		iconLabel: {
			color: "#636366",
			marginRight: "8px",
			fontSize: "22px"
		},
		attachmentIcon: {
			marginLeft: "auto"
		},
		contentLoaderContainer: {
			height: "206px"
		},
		cardIconRow: {
			margin: "-12px",
			padding: "12px 0",
			marginTop: "4px",
			display: "flex",
			color: "grey",
			borderTop: "solid lightgrey 1px",
			backgroundColor: "#FAFAFA"
		},
		cardIconButton: {
			width: "25%",
			textAlign: "center",
			borderRight: "solid lightgrey 1px",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		updateIconButton: {
			width: "100%",
			justifyContent: "center",
			display: "flex",
			alignItems: "center",
			borderRight: "unset"
		},
		viewDevices: {
			marginLeft: "4px",
		},
		tip: {
			fontSize: "16px",
		},
		cardTextLabel: {
			color: "black"
		},
		tipOverride: {
			padding: "8px",
			boxSizing: "border-box",
			marginTop: "0px",
		},
		tipDisabled: {
			cursor: "not-allowed",
			color: "#bababa",
			"&:hover": {
				color: "#bababa"
			}
		},
		nameContainer: {
			display: "flex",
			alignItems: "center",
		},
		deviceName: {
			whiteSpace: "nowrap"
		},
		statusDot: {
			width: "8px",
			height: "8px",
			minWidth: "8px",
			minHeight: "8px",
			maxWidth: "8px",
			maxHeight: "8px",
			borderRadius: "50%",
			display: "inline-flex",
			marginRight: "4px"
		},
		menuIcon: {
			border: "none",
			cursor: "pointer",
			"&:hover": {
				color: theme.palette.pending.main
			}
		},
		cardLabelContainer: {
			marginLeft: "12px"
		},
		cardLabel: {
			display: "flex",
			alignItems: "center",
			marginBottom: "8px",
		},
		cardText: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			fontSize: "14px",
			color: "#636366",
		},
		errorIcon: {
			color: theme.palette.red.main,
		},
		scriptContainer: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			backgroundColor: theme.palette.pending.main,
			color: "#ffffff",
			padding: "0 8px",
			borderRadius: "4px",
			fontFamily: "Inconsolata",
			cursor: "pointer",
		},
		script: {
			backgroundColor: theme.palette.pending.main,
			color: "#ffffff",
			lineHeight: "30px",
			borderRadius: "4px",
			width: "85%",
		},
		scriptVisibilityIcon: {
			width: "10%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		scriptInModal: {
			overflow: "auto",
			padding: "12px",
			width: "auto",
		},
		notFound: {
			color: theme.palette.red.main
		},
		selectWrapper: {
			display: "flex",
			width: "100%",
			padding: "12px",
			paddingLeft: "15%",
			boxSizing: "border-box",
			alignItems: "center",
			backgroundColor: "white",
			position: "relative",
			zIndex: 5,
			marginTop: "-4px",
			borderBottom: "1px solid lightgrey",
		},
		selectionCount: {
			fontSize: "16px",
		},
		selectButtonsWrapper: {
			position: "relative",
			marginLeft: "auto",
			marginRight: 0,
			display: "flex"
		},
		bulkButtonsWrapper: {
			marginRight: "8px"
		},
		deleteBorderButton: {
			marginLeft: "12px",
		},
		issueButton: {
			marginLeft: "12px",
			borderColor: theme.palette.pending.main,
			color: theme.palette.pending.main
		},
		bulkIcon: {
			marginRight: "4px"
		},
		optionsIconsContainer: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			width: "100%",
			height: "36px",
			backgroundColor: "#ffffff",
			padding: "8px",
			boxSizing: "border-box",
		},
		leftOptionsContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-start",
			width: "25%",
		},
		rightOptionsContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			width: "75%",
		},
		selectContainer: {
			"& svg":  {
				width: "20px",
				height: "20px",
			},
		},
		optionsIcon: {
			marginLeft: "16px",
			color: "#8e8e93",
			width: "20px",
			height: "20px",
			"& svg": {
				fontSize: "20px",
			},
			cursor: "pointer",
			"&:hover": {
				color: "#0263fc",
			},
			transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
		},
		softwareInfo: {
			position: "relative",
			padding: "16px 24px 24px 24px",
		},
	})
};

class SoftwareUpdatesList extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			layout: "card",
			tab_index: 0,
			modal: {
				open: false,
				children: () => ""
			},
			menus: {},
			package_ids: [],
			packages: [],
			device_type_id: null,
			devices_shown: null
		};
		this.set_headings();
		this.set_tabs();
	}

	issue_update = () => {
		this.setState({
			modal: {
				open: true,
				children: (classes) => 
					<div className={classes.modalWrapper}>
						<IssueUpdateFlow
							closeModal={this.close_modal}
						/>
					</div>
			}
		});
	}

	get_buttons = () => {
		let buttons = [];
		let accounts = Auth.currentUser().company_ids;
		let valid_accounts = accounts.filter( (account) => Permissions.allow(["create"], "software_update", account._id));
		if (valid_accounts.length > 0) {
			buttons.push(
				{onSelectShow: false, display: "Create Package", label: "Create Package", icon: (<AddIcon />), action: this.create_package, function: this.create_package},
			);
		}
		valid_accounts = null;
		valid_accounts = accounts.filter( (account) => Permissions.allow(["software_update"], "device", account._id));
		if (valid_accounts.length > 0) {
			buttons.push(
				{onSelectShow: false, display: "Issue Update", label: "Issue Update", icon: (<GetAppIcon />), action: this.issue_update, function: this.issue_update}
			);
		}
		return buttons;
	}

	set_tabs = () => {
		this.tabs = [
			{label: "Packages"},
			{label: "Update Log"}
		];
	}

	set_headings = () => {
		this.package_headings = [
			{label: "Select", field: "select", align: "center", sortable: false},
			{label: "Name", value: "name", field: "name", align: "left", sortable: false},
			{label: "Account", field: "account_name", align: "left", sortable: false},
			{label: "File Count", field: "file_count_string", align: "left", sortable: false},
			{label: "Device Type", field: "type_name", align: "left", sortable: false},
			{label: "Script", field: "script_link", align: "left", sortable: false},
			{label: "Action", field: "action", align: "center", sortable: false},
		];
		this.update_headings = [
			{label: "DATE/TIME", value: "created_at", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date"},
			{label: "Device Count", value: "device_count", field: "unique_id", align: "right", sortable: false},
			{label: "Summary", value: "summary", field: "status", align: "left", sortable: false},
			{label: "Package", value: "package_name", field: "package_name", align: "left", sortable: false},
		];
		this.device_heading_info = [
			{label: "Name", value: "table_name", nested_field: false, field: "table_name", align: "left", disablePadding: false, sortable: false},
			{label: "Unique ID", value: "table_unique_id", field: "table_unique_id", nested_field: false, align: "left", disablePadding: false, sortable: false},
			{label: "Update Status", value: "update_status", field: "update_status", nested_field: false, align: "left", disablePadding: false, sortable: false},
			{label: "Tags", value: "table_tags", field: "table_tags", nested_field: false, align: "left", disablePadding: false, sortable: false},
		];
	}

	openSoftwareUpdateTab(deviceType) {
		this.props.tabHostProxy.addTab("softwareUpdate", deviceType);
	}

	handleChangePage = (event, page) => {
		this.props.page_change({ page: page + 1, per_page: this.props.page_data.per_page });
	};

	handleChangeRowsPerPage = event => {
		this.props.page_change({ page: this.props.page_data.page, per_page: event.target.value });
	};

	create_package = () => {
		this.setState({
			modal: {
				open: true,
				children: (classes) => 
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle}>
							Creating a New Software Package
						</div>
						<CreateSoftwareUpdateForm
							onCreate={this.on_create}
							onCancel={this.close_modal}
						/>
					</div>
			}
		});
	}

	on_create = () => {
		this.close_modal();
		this.props.tabHostProxy.refresh();
	}

	close_modal = () => {
		this.setState({
			modal: {
				open: false,
				children: () => ""
			}
		});
	}

	prepare_packages = () => {
		const { items, classes } = this.props;
		let packages = items;
		if (!packages) return;
		packages.forEach( (pkg) => {
			pkg.file_count_string = pkg.files !== null && pkg.files.length !== 0 ? pkg.files.length > 1 ? pkg.files.length + " Files" : "1 File" : "No Files";
			if (!pkg.nested_company) {
				pkg.error = "This package's account could not be found.";
			} else if (!pkg.nested_device_type) {
				pkg.error = "This package's device type could not be found. Actions with this package are not possible, please delete or resolve with your system administrator.";
			}
			pkg.type_name = pkg.nested_device_type ? pkg.nested_device_type.name : <span className={classes.notFound}>Device Type Not Found</span>;
			pkg.account_name = pkg.nested_company ? pkg.nested_company.name : <span className={classes.notFound}>Account Not Found</span>;
			pkg.script_link = (<span onClick={() => this.open_script_modal(pkg)} className={classes.link}>View Script</span>);
			let checked = this.state.package_ids.includes(pkg._id);
			let allowed = Permissions.allow(["delete"], "software_update", pkg.company_id);
			pkg.select = (allowed ? <CustomCheckbox
				onChange={() => this.table_select(pkg)}
				value="selected"
				color="primary"
				key={pkg._id}
				checked={checked}
			/> : "");
			pkg.action = (<span className={classes.tableMenu}>{this.render_menu(pkg, true)}</span>);
		});
		return items;
	}

	prepare_updates = () => {
		const { items, classes } = this.props;
		let updates = items;
		if (!updates) return;
		updates.forEach( (update) => {
			update.device_count = update.attached_device_unique_ids && update.attached_device_unique_ids.length > 0 ? update.attached_device_unique_ids.length : 1;
			let summary = "All Updates Successfull";
			if (update.statuses) {
				let fail_count = 0;
				if (update.attached_device_unique_ids && update.attached_device_unique_ids.length > 0 && Object.entries(update.statuses).length === 1) {
					summary = "Pending";
				} else {
					Object.entries(update.statuses).forEach( ([key, {status}]) => {
						if (status !== "sent" && status !== "success") {
							fail_count++;
						} else if ( (status === "sent" && key !== update.device_unique_id) || (status === "sent" && key === update.device_unique_id && (!update.attached_device_unique_ids || update.attached_device_unique_ids.length === 0)) ) {
							summary = "Pending";
						}
					});
					if (fail_count > 0) {
						summary = "Errors";
					}
				}
			} else {
				summary = "Pending";
			}
			update.summary = summary;
			update.package_name = update.nested_software_update ? update.nested_software_update.name : "Package Unknown";
		});
		// console.log(updates);
		return updates;
	}

	table_select = (pkg) => {
		this.setState( (state) => {
			let index = state.package_ids.indexOf(pkg._id);
			if (index >= 0) {
				state.packages.splice(index, 1);
				state.package_ids.splice(index, 1);
			} else {
				state.packages.push(pkg);
				state.package_ids.push(pkg._id);
			}
			return state;
		});
	}

	open_script_modal = (pkg) => {
		this.setState({modal: {
			open: true,
			children: (classes) => 
				<div className={classes.modalWrapper}>
					<div className={classes.modalTitle}>
						Script for {pkg.name}
					</div>
					<div className={classes.script + " " + classes.scriptInModal}>
						{pkg.script}
					</div>
				</div>
			}
		});
	}

	prepare_items = () => {
		const { items, model } = this.props;
		if (model === "packages") {
			return this.prepare_packages();
		} else {
			return this.prepare_updates();
		}
	}

	render_select_wrapper = () => {
		const { classes } = this.props;
		const { packages, package_ids } = this.state;
		if (!package_ids || package_ids.length === 0) {
			return "";
		}
		let selection_message = package_ids.length + " Package" + (package_ids.length > 1 ? 's' : '') + " Selected.";
		return (
			<div className={classes.selectWrapper}>
				<div className={classes.selectionCount}>
					{selection_message}
					{/* <div onClick={() =>( all_selected === false || (all_selected === true && selections.length > 0)) ? this.selectAllFunction() : ""} className={classes.secondaryButton + " " + ( (all_selected === true && selections.length === 0) ? classes.selection : "")}>
						Select All
					</div>
					<div onClick={() => selections.length > 0 || all_selected === true ? this.clearSelections() : ""} className={classes.secondaryButton + " " + (selections.length === 0 && all_selected === false ? classes.selection : "")}>
						Deselect All
					</div> */}
				</div>
				<div className={classes.selectButtonsWrapper}>
					<div className={classes.bulkButtonsWrapper}>
						{Permissions.allow(["delete"], "software_update") ? <Button
							variant="outlined"
							color="primary"
							className={classes.deleteBorderButton}
							onClick={() => this.delete_packages()}
						>
							<DeleteForeverIcon className={classes.bulkIcon}/>
							DELETE
						</Button> : ""}
					</div>
				</div>
			</div>
		);
	}

	model_change = (change_data) => {
		this.setState( (state) => {
			state.package_ids = [];
			state.packages = [];
		});
		this.props.modelChange(change_data);
	}

	render() {
		const { page_data, model, classes } = this.props;
		let items = this.prepare_items();
		let selected_shown = this.state.packages && this.state.packages.length > 0 ? classes.moreSpace : "";
		let buttons = this.get_buttons();
		return (
			<div className={classes.container}>
				<SimpleModalWrapped info={this.state.modal} closeModal={this.close_modal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<PaginationContainer
					buttons={buttons}
					count={page_data.total}
					rowsPerPage={page_data.per_page}
					currentPage={page_data.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					toggleView={layout =>
						this.setState({ layout: layout })
					}
					toggle_filter={this.props.show_filters}
				/>
				{this.render_select_wrapper()}
				<div className={classes.sideBarContainer}>
					<VerticalTabs tabs={this.tabs} onChange={this.model_change}/> 
				</div>
				{this.state.layout === "card" ? 
					<div className={classes.cardItemsContainer + " " + selected_shown}>
						{this.render_card_layout(items)}
					</div> :
					<div className={classes.tableItemsContainer + " " + selected_shown}>
						{this.render_table_layout(items)}
					</div>
				}
			</div>
		);
	}

	filters_change = () => {

	}

	edit_package = (event, pkg) => {
		if (pkg.error) {
			event.stopPropagation();
			return;
		}
		this.setState({
			modal: {
				open: true,
				children: (classes) => 
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle}>
							Editing a Software Package
						</div>
						<CreateSoftwareUpdateForm
							onCreate={this.on_create}
							onCancel={this.close_modal}
							package={pkg}
						/>
					</div>
			}
		});
	}

	delete_packages = () => {
		const classes = this.props.classes;
		this.setState({
			modal: {
				open: true,
				yesFunction: () => this.perform_deletes(),
				functionText: "Delete Packages",
				prompt: "Are you sure you want to delete these packages?",
				children: () => {}
			}
		});
	}

	delete_package = (pkg) => {
		const classes = this.props.classes;
		this.setState({
			modal: {
				open: true,
				yesFunction: () => this.perform_delete(pkg),
				functionText: "Delete Package",
				prompt: "Are you sure you want to delete this package?",
				children: () => {}
			}
		});
	}

	perform_delete = (pkg) => {
		this.setState({
			modal: {
				open: true,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle + " " + classes.titleWithLoading}>
							Deleting this Software Package...
						</div>
						<Loading />
					</div>
				)
			}
		});
		new SoftwareUpdate(pkg).deleteFromAPI().then( (result) => {
			this.close_modal();
			this.props.tabHostProxy.refresh();
			this.context.openSnackbar("Package deleted.", "success");
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.close_modal();
		});
	}

	perform_deletes = () => {
		this.setState({
			modal: {
				open: true,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle + " " + classes.titleWithLoading}>
							Deleting these Software Packages...
						</div>
						<Loading />
					</div>
				)
			}
		});
		let body = { ids: this.state.package_ids };
		BULK_EDIT('software_updates', "delete", body, Auth.token()).then( (result) => {
			let message = `The bulk delete process has successfully begun.`;
			this.context.openSnackbar(message, 'success');
			this.props.tabHostProxy.refresh();
			this.clear_selections();
			this.close_modal();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.clear_selections();
			this.close_modal();
		});
	}

	clear_selections = () => {
		this.setState( (state) => {
			state.packages = [];
			state.package_ids = [];
			return state;
		});
	}

	render_error = (pkg) => {
		const classes = this.props.classes;
		if (!pkg.error) return "";
		return (
			<Tooltip classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{pkg.error}</span>}>
				<ErrorIcon className={classes.errorIcon}/>
			</Tooltip>
		);
	}

	render_update_card = (update) => {
		const { classes } = this.props;
		return (
			<div key={update._id} className={classes.cardContainer}>
				<Card onClick={() => this.open_devices(update)} className={classes.card + " " + classes.updateCard}>
					<div className={classes.softwareInfo}>
						<div className={classes.cardLabel}>
							<Tooltip title="Created At">
								<EventIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}><span className={classes.cardTextLabel}>Created: </span>{update.created_at}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Device Count">
								<DvrIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}><span className={classes.cardTextLabel}>Recipient Devices: </span>{update.device_count}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Status">
								<GetAppIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}><span className={classes.cardTextLabel}>Status: </span>{update.summary}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Package">
								<CodeIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}><span className={classes.cardTextLabel}>Package: </span>{update.package_name}</span>
						</div>
					</div>
					<div className={classes.cardIconRow}>
						<span onClick={() => this.open_devices(update)} className={classes.cardIconButton + " " + classes.updateIconButton}>
							<DvrIcon />
							<span className={classes.viewDevices}>View Devices</span>
						</span>
					</div>
				</Card>
			</div>
		);
	}

	open_devices = (update) => {
		const { classes } = this.props;
		this.setState({devices_shown: null});
		this.load_devices_from_update(update);
		this.setState({
			modal: {
				open: true,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<div className={classes.modalTitle}>
							Devices in this Update
						</div>
						{!this.state.devices_shown ? <Loading />
						:
							this.state.devices_shown.length === 0 ?
							<div className={classes.noDevices} >The devices in this update are not available. They have either been deleted or are not visible to you.</div>
							: 
							<div className={classes.tableListWrapper}>
								<TableList
									headings={this.device_heading_info} 
									items={this.state.devices_shown}
									perPage={5}
								/>
							</div>
						}
					</div>
				)
			}
		});
	}

	go_to_device = (device_id) => {
		this.props.history.push("/Devices?device_id=" + device_id);
	}

	load_devices_from_update = (update) => {
		const { theme, classes } = this.props;
		const params = {unique_id_in: update.attached_device_unique_ids.concat(update.device_unique_id)};
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		GetAll("devices", params).then( (devices) => {
			devices.forEach( (device) => {
				device.update_status = update.statuses && update.statuses[device.unique_id] ? update.statuses[device.unique_id].status : "Pending";
				let is_gateway = update.device_unique_id === device.unique_id && update.attached_device_unique_ids && update.attached_device_unique_ids.length > 0;
				device.table_unique_id = (
					<span>{device.unique_id}<span onClick={() => this.go_to_device(device._id)} className={classes.link + " " + classes.deviceLink}>View Device</span></span>
				);
				device.table_name = (
					<div className={classes.nameContainer}>
						<div style={{backgroundColor: status_map[device.heartbeat_status]}} className={classes.statusDot}>
							&nbsp;
						</div>
						<span className={classes.deviceName}>{device.name + (is_gateway ? ` (${Auth.currentCompany().aliases.gateway})` : "")}</span>
					</div>
				);
				device.table_tags = device.tags && device.tags.length > 0 ? device.tags.join(", ") : "No Tags";
			});
			this.setState({devices_shown: devices});
		});
	}

	render_package_card = (pkg) => {
		const { classes } = this.props;
		let reboot_string = pkg.reboot ? "Will Reboot Device upon Competion" : "Won't Reboot Device upon Completion";
		let selected = this.state.package_ids.includes(pkg._id) ? classes.selected : "";
		return (
			<div key={pkg._id} className={classes.cardContainer}>
				<Card className={classes.card + " " + selected}>
					<div className={classes.optionsIconsContainer}>
						<div className={classes.leftOptionsContainer}>
							<div className={classes.selectContainer}>{pkg.select}</div>
						</div>
						<div className={classes.rightOptionsContainer}>
							<Tooltip onClick={() => this.open_script_modal(pkg)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>View Script</span>}>
								<span className={classes.optionsIcon + " " + (Boolean(pkg.error) ? classes.tipDisabled : "")}>
									<CodeIcon />
								</span>
							</Tooltip>
							<Tooltip onClick={(event) => this.single_issue(event, pkg)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>Issue Update</span>}>
								<span className={classes.optionsIcon + " " + (Boolean(pkg.error) ? classes.tipDisabled : "")}>
									<GetAppIcon />
								</span>
							</Tooltip>
							<Tooltip onClick={(event) => this.can_edit(pkg) && this.edit_package(event, pkg)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_edit(pkg) ? "Edit Package" : "Insufficient permissions for editing"}</span>}>
								<span className={classes.optionsIcon + " " + (this.can_edit(pkg) && !Boolean(pkg.error) ? "" : classes.tipDisabled)}>
									<EditIcon />
								</span>
							</Tooltip>
							<Tooltip onClick={() => this.can_delete(pkg) && this.delete_package(pkg)} classes={{tooltip: classes.tipOverride}} title={<span className={classes.tip}>{this.can_delete(pkg) ? "Delete Package" : "Insufficient permissions for deleting"}</span>}>
								<span className={classes.optionsIcon + " " + (this.can_delete(pkg) ? "" : classes.tipDisabled)}>
									<DeleteIcon />
								</span>
							</Tooltip>
						</div>
					</div>
					<div className={classes.softwareInfo}>
						<div className={classes.topRow}>
							<span className={classes.name}>
								{pkg.name}
							</span>
							{this.render_error(pkg)}
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Device Type">
								<DvrIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>{pkg.type_name}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Account">
								<AccountCircleIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>{pkg.account_name}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="Reboot">
								<PowerSettingsNewIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>{reboot_string}</span>
						</div>
						<div className={classes.cardLabel}>
							<Tooltip title="File Count">
								<AttachIcon className={classes.iconLabel}/>
							</Tooltip>
							<span className={classes.cardText}>{pkg.file_count_string}</span>
						</div>
						<div className={classes.cardLabel} onClick={() => this.open_script_modal(pkg)}>
							<div className={classes.cardText + " " + classes.scriptContainer}>
								<div className={classes.cardText + " " + classes.script}>
									{pkg.script}
								</div>
								<div className={classes.scriptVisibilityIcon}>
									<VisibilityIcon />
								</div>
							</div>
						</div>
					</div>
				</Card>
			</div>
		);
	}

	render_menu = (item, table) => {
		let anchorEl =  this.state.menus[item._id];
		let is_packages = this.props.model === "packages" ? true : false;
		let open = Boolean(anchorEl);
		let icon = !table ? <MoreHorizIcon onClick={(event) => this.open_action_menu(event, item._id)}/> : <MoreVertIcon onClick={(event) => this.open_action_menu(event, item._id)}/>;
		return (
			<React.Fragment>
				{icon}
				{is_packages ? this.render_packages_action_menu(open, anchorEl, item) : this.render_updates_action_menu(open, anchorEl, item)}
			</React.Fragment>
		);
	}

	open_action_menu = (event, id) => {
		const element = event.target;
		this.setState( (state) => {
			state.menus[id] = element;
			return state;
		});
	}

	can_edit = (pkg) => {
		return Permissions.allow(["update"], "software_update", pkg.company_id);
	}

	can_delete = (pkg) => {
		return Permissions.allow(["delete"], "software_update", pkg.company_id);
	}

	close_action_menu = (id) => {
		this.setState( (state) => {
			state.menus[id] = null;
			return state;
		});
	};

	single_issue = (event, pkg) => {
		if (pkg.error) {
			event.stopPropagation();
			return;
		}
		this.setState({
			modal: {
				open: true,
				children: (classes) => 
					<div className={classes.modalWrapper}>
						<IssueUpdateFlow
							pkg={pkg}
							closeModal={this.close_modal}
						/>
					</div>
			}
		});
	}

	preselect_issue = () => {
		const { classes } = this.props;
		const { packages } = this.state;
		this.setState({
			modal: {
				open: true,
				children: (classes) => 
					<div className={classes.modalWrapper}>
						<IssueUpdateFlow
							pkg={packages}
							closeModal={this.close_modal}
						/>
					</div>
			}
		});
	}

	render_updates_action_menu = (open, anchorEl, update) => {
		const { classes } = this.props;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={() => this.close_action_menu(update._id)}
				onClose={() => this.close_action_menu(update._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				<div className={classes.noOutline}>
					<MenuItem className={classes.actionMenuItem}>
						<ListItemIcon>
							<DvrIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							View Device
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline}>
					<MenuItem className={classes.actionMenuItem}>
						<ListItemIcon>
							<CodeIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							View Package
						</Typography>
					</MenuItem>
				</div>
			</Menu>
		);
	}

	render_packages_action_menu = (open, anchorEl, pkg) => {
		const { classes } = this.props;
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={() => this.close_action_menu(pkg._id)}
				onClose={() => this.close_action_menu(pkg._id)}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.actionListTitle}>
					Perform Action...
				</div>
				<div className={classes.noOutline} onClick={(event) => this.single_issue(event, pkg)}>
					<MenuItem disabled={Boolean(pkg.error)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<GetAppIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Issue Update
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={(event) => this.can_edit(pkg) && this.edit_package(event, pkg)}>
					<MenuItem disabled={!this.can_edit(pkg) || Boolean(pkg.error)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Edit Package
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={() => this.can_delete(pkg) && this.delete_package(pkg)}>
					<MenuItem disabled={!this.can_delete(pkg)} className={classes.actionMenuItem}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							Delete Package
						</Typography>
					</MenuItem>
				</div>
				<div className={classes.noOutline} onClick={() => this.open_script_modal(pkg)}>
					<MenuItem className={classes.actionMenuItem}>
						<ListItemIcon>
							<CodeIcon />
						</ListItemIcon>
						<Typography variant="inherit" noWrap>
							View Script
						</Typography>
					</MenuItem>
				</div>
			</Menu>
		);
	}

	render_card_layout = (items) => {
		const { model } = this.props;
		return (
			<React.Fragment>
				{items ? items.map( (item, index) => (
					model === "packages" ? this.render_package_card(item) : this.render_update_card(item)
				)) : this.render_placeholder_cards()}
			</React.Fragment>
		);
	}

	render_placeholder_cards = () => {
		const classes = this.props.classes;
		let placeholders = [0,0,0,0,0,0,0,0,0];
		let update_class = this.props.model === "packages" ? "" : classes.updateCard;
		return (
			<React.Fragment>
				{placeholders.map( (item, index) => (
					<div key={"placeholder_" + index} className={classes.cardContainer}>
						<Card className={classes.card + " " + update_class}>
							<div className={classes.contentLoaderContainer}>
								<ContentLoader
									width={200}
									speed={2}
									interval={.25}
									primaryColor="#f3f3f3"
									secondaryColor="#ecebeb"
								>
									<rect x="0" y="6" rx="4" ry="4" width={200} height="18" /> 
									<rect x="12" y="33" rx="4" ry="4" width={200} height="16" /> 
									<rect x="12" y="63" rx="4" ry="4" width={200} height="16" /> 
									<rect x="12" y="92" rx="4" ry="4" width={200} height="16" />
								</ContentLoader>
							</div>
							<div className={classes.cardIconRow}>
								<Tooltip style={{visibility: "hidden"}} title={<span className={classes.tip}>Issue Update</span>}>
									<span className={classes.cardIconButton}>
										<GetAppIcon />
									</span>
								</Tooltip>
							</div>
						</Card>
					</div>
				))}
			</React.Fragment>
		)
	}

	render_table_layout = (items) => {
		const { model } = this.props;
		let headings = null;
		if (model === "packages") {
			headings = this.package_headings;
		} else {
			headings = this.update_headings;
		}
		return (
			<TableList
				headings={headings} 
				items={items}
				noCheckBox
			/>
		);
	}

	renderDeviceCount = (type) => {
		if (type.nested_device_count) {
			if (type.nested_device_count > 1) {
				return type.nested_device_count + " devices"
			} else {
				return "1 device";
			}
		} else {
			return "No devices"
		}
	}
}

SoftwareUpdatesList.contextType = SnackbarContext;

const CustomCheckbox = withStyles({
	root: {
		padding: 0,
		color: "rgba(0, 0, 0, 0.24)",
		'&$checked': {
			color: "#1153b6",
		},
	},
	checked: {},
  })(props => <Checkbox color="default" {...props} />);

export default compose(
	withRouter,
	withStyles(styles),
	withTheme(),
)(SoftwareUpdatesList);

