import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import TodayIcon from '@material-ui/icons/Today';
import EventIcon from '@material-ui/icons/Event';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DateTimePicker } from 'material-ui-pickers';
import { withStyles, withTheme } from '@material-ui/core';
import SelectInput from './SelectInput';
import moment from 'moment';


class DateFnsUtilsExtended extends DateFnsUtils {
    startOfMonth(date) {
        return moment(date).startOf('month').toDate();
    }
}

const styles = theme => ({
	container: {
		fontFamily: "Inter",
		position: "relative",
	},
	label: {
		marginBottom: "-12px",
		display: "flex",
		color: "grey",
		fontWeight: 100,
		fontSize: "14px",
		position: "absolute",
		top: "-20px"
	},
	range: {
		width: "50%",
	},
	dateContainer: {
		display: "flex",
		alignItems: "center",
	},
	dateIcon: {
		color: "grey",
	},
	dateSelect: {
		margin: "16px 6px",
		flexGrow: 2,
		cursor: "pointer",
	},
	and: {
		color: "grey",
	}
});

const ranges = [{display: "After", value: "After"}, {display: "Before", value: "Before"}, {display: "Between", value: "Between"}];

class DateSelection extends React.Component {

	handleChange = (input) => {
		if (input) {
			this.date.range = input.value;
		}
		this.props.emitChange({label: this.props.label, value: this.date, field: this.props.field});
	};

	handleFirstDateChange = (date) => {
		this.date.firstDate = date;
		if (this.date.range === "Between" && this.date.firstDate > this.date.secondDate) {
			this.date.secondDate = new Date(this.date.firstDate).setHours(24);
		}
		this.handleChange();
	};

	handleSecondDateChange = (date) => {
		this.date.secondDate = date;
		this.handleChange();
	};

	handleKeyPress = (event) => {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		if (keycode == '13') {
			this.props.onEnter ? this.props.onEnter() : "";
		}
	}

	render() {
		let { priorState, disablePast, range, rangeLocked, label, noIcon, classes} = this.props;
		disablePast = disablePast || false;
		if (priorState) {
			this.date = priorState;
		} else {
			this.date = {
				firstDate: null,
				secondDate: null,
				range: ranges[0].value
			};
		}
		return (
			<div className={classes.container}>
				<MuiPickersUtilsProvider utils={DateFnsUtilsExtended}>
					<React.Fragment>
						<div className={classes.label}>
							{label}
						</div>
						{range && !rangeLocked ?
							<SelectInput
								className={classes.range}
								emitChange={this.handleChange}
								priorState={this.date.range}
								options={ranges}
								note={""}
							/>
						: ""}
						<div className={classes.dateContainer}>
							{noIcon ? "" : <TodayIcon className={classes.dateIcon} />}
							<DateTimePicker
								disabled={this.props.disabled}
								className={classes.dateSelect}
								value={this.date.firstDate}
								onChange={this.handleFirstDateChange}
								placeholder={"Select a date"}
								disablePast={disablePast}
								showTodayButton={true}
							/>
						</div>
						{this.date.range === "Between" ?
							<div>
								<div className={classes.and}>
									and
								</div>
								<div className={classes.dateContainer}>
									{noIcon ? "" : <EventIcon className={classes.dateIcon} />}
									<DateTimePicker
										className={classes.dateSelect}
										value={this.date.secondDate}
										onChange={this.handleSecondDateChange}
										placeholder={"And another date"}
										disablePast={disablePast}
										showTodayButton={true}
										minDate={this.date.firstDate}
									/>
								</div>
							</div>
						: ""}
					</React.Fragment>
				</MuiPickersUtilsProvider>
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(DateSelection));
