import React from 'react';
import TransferRequestForm from './TransferRequestForm';
import Loading from '../DisplayOriented/Loading';
import { compose } from 'recompose';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import { GetAll } from '../../services/CLURDUtilities';
import EscrowDevice from '../../services/DataModels/EscrowDevice';

//inputs
import DeviceSelectionInput from '../Inputs/DeviceSelectionInput';

//mui
import { darken } from '@material-ui/core/styles/colorManipulator';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

class TransferRequestFlow extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			active_step: 0,
			scheduled: false,
			device_selection: {
				status: "normal",
				devices: [],
				device_ids: [],
			},
			escrow_devices: [],
			show_errors: false,
			loading_devices: false
		};
		this.set_steps();
	}

	set_steps = () => {
		this.state.steps = ["Select Escrow Devices", "Set the Account and Device Type"];
		this.state.step_render_functions = [this.render_device_selection, this.render_request_form];
	}

	device_selection_change = (device_ids, devices, all_selected, formerly_all_selected) => {
		this.setState( (state) => {
			let devices_selected = all_selected || formerly_all_selected || devices.length > 0;
			if (state.show_errors && devices_selected) {
				state.show_errors = false;
			}
			state.device_selection = {
				status: all_selected === true ? "all_selected" : formerly_all_selected  === true ? "formerly_all_selected" : "normal",
				device_ids: device_ids,
				devices: devices,
			}
			return state;
		});
	}

	render_device_selection = () => {
		const title = this.state.steps[0];
		const show_errors = this.state.show_errors;
		const classes = this.props.classes;
		return (
			<React.Fragment>
				{this.render_title(title)}
				<div className={classes.deviceSelectionWrapper}>
					<DeviceSelectionInput
						onChange={this.device_selection_change}
						hideSearch
						hideFilters
						isEscrow
					/>
					{show_errors ? 
						<div className={classes.error}>
							Please select one or more escrow devices to continue.
						</div>
						:
						""
					}
				</div>
			</React.Fragment>
		);
	}

	render_request_form = () => {
		const title = this.state.steps[1];
		const status = this.state.status;
		let escrow_devices = this.state.escrow_devices;
		const close = this.props.closeModal;
		return (
			<React.Fragment>
				{this.render_title(title)}
				<TransferRequestForm
					onCreate={close}
					hideTitle={true}
					escrowDevices={escrow_devices}
					closeModal={close}
					backOverride={() => this.setState({active_step: 0})}
				/>
			</React.Fragment>
		);
	}

	render_title = (title) => {
		const classes = this.props.classes;
		return (
			<div className={classes.title}>
				{title}
			</div>
		)
	}

	render_issuing = () => {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Issuing the transfer request...
					</div>
				</div>
				<Loading />
			</div>
		);
	}

	load_and_progress = () => {
		const { device_selection } = this.state;
		this.setState({loading_devices: true});
		if (device_selection.status !== "normal") {
			let params = {};
			if (device_selection.status === "formerly_all_selected") {
				params._id_nin = device_selection.device_ids.join(",");
			}
			GetAll("escrow_devices", params).then( (devices) => {
				EscrowDevice.loadRequirements(devices).then( () => {
					this.setState({escrow_devices: devices, active_step: 1, loading_devices: false});
				}).catch( (error) => {
					this.setState({loading_devices: false});
					this.context.openSnackbar(error, "error");
				});
			}).catch( (error) => {
				this.setState({loading_devices: false})
				this.context.openSnackbar(error, "error");
			});
		} else {
			this.setState({escrow_devices: device_selection.devices, active_step: 1, loading_devices: false});
		}
	}

	render_step_buttons = () => {
		const { active_step, device_selection, loading_devices } = this.state; 
		const { closeModal, classes } = this.props;
		if (active_step === 0) {
			const back = { label: "Cancel", action: closeModal };
			const next = { label: "Next", action: this.load_and_progress };
			const can_progress = device_selection.status === "all_selected" || device_selection.status === "all_formerly_selected" || device_selection.devices.length > 0;
			return (
				<div className={classes.buttonContainer}>
					<Button
						onClick={() => back.action()}
						className={classes.buttonOverride}
						color="primary"
					>
						{back.label}
					</Button>
					<Button
						variant="contained"
						color="primary"
						size="large"
						disabled={loading_devices}
						onClick={() => can_progress ? next.action() : this.setState({show_errors: true})}
						className={classes.buttonOverride}
					>
						{next.label}
					</Button>
				</div>
			);
		}
	}

	render_active_step = () => {
		const { classes } = this.props;
		const { active_step, steps, step_render_functions } = this.state;
		return (
			<React.Fragment>
				{steps.map( (step, index) => {
					return index !== active_step ? 
						<div key={step + "_content"} className={classes.stepContentContainer} style={{display: "none"}}>
							{step_render_functions[index] ? step_render_functions[index]() : ""}
							{this.render_step_buttons()}
						</div>
					: 
						<div className={classes.stepContentContainer} key={step + "_content"} style={{display: "initial"}}>
							{step_render_functions[index] ? step_render_functions[index]() : ""}
							{this.render_step_buttons()}
						</div>
				})}
			</React.Fragment>
		);
	}
	
	render_stepper_display = () => {
		const { active_step, steps } = this.state;
		const { classes } = this.props;
		return (
			<div>
				<Stepper
					classes={{root: classes.stepperRoot}}
					activeStep={active_step}
				>
					{steps.map( (label, index) => (
						<Step key={label}>
							<StepLabel></StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		const { issuing } = this.state;
		return (
			<div className={classes.container}>
				{issuing ? this.render_issuing() : 
					<React.Fragment>
						{this.render_stepper_display()}
						{this.render_active_step()}
					</React.Fragment>
				}
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		title: {
			fontSize: "20px",
			lineHeight: "32px",
			fontWeight: "700",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
			display: "flex",
			marginRight: "12px",
		},
		titleRow: {
			display: "flex",
			marginBottom: "12px"
		},
		stepContentContainer: {
			fontFamily: "Inter",
			justifyContent: "space-between",
			flexDirection: "column",
		},
		container: {
			fontFamily: "Inter",
			boxSizing: "border-box",
			height: "643px",
		},
		error: {
			color: theme.palette.red.main,
			fontSize: "14px",
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "24px"
		},
		deviceSelectionWrapper: {
			marginTop: "24px",
			height: "449px",
			overflowY: "auto",
		},
		stepperRoot: {
			padding: 0,
			marginBottom: "32px",
		},
	});
};

TransferRequestFlow.contextType = SnackbarContext;

export default compose(
	withStyles(styles),
)(TransferRequestFlow);