import React from 'react';
import { withStyles, withTheme} from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import DeviceHAGroup from "../../../services/DataModels/DeviceHAGroup";
import Config from "../../../services/DataModels/Config";
import FilterSidebar from '../../ActionOriented/FilterSidebar';
import HAGroupsList from '../../HAGroupsSpecific/HAGroupsList';
import Auth from '../../../services/Auth';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	}
};

class HAGroupsTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			ha_groups: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			}
		};
		this.load_ha_groups();
		this.filters = [];
		this.set_filters();
		this.props.tabHostProxy.setRootRefresh(this.load_ha_groups);
	}

	set_filters = () => {
		//todo revisit
		// if (Auth.currentUser().company_ids.length > 1) {
		// 	this.filters.push({
		// 		label: 'Account',
		// 		type: 'lookup',
		// 		placeholder: "Filter by account",
		// 		field: "company_id",
		// 		model: "companies",
		// 		valueField: "_id",
		// 		labelField: "name"
		// 	});
		// }
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_ha_groups();
		});
	}

	handle_table_name_click = (ha_group) => {
		this.props.tabHostProxy.addTab("haGroup", ha_group);
	}

	handle_controller_click = (controller) => {
		this.props.tabHostProxy.addTab("device", controller);
	}

	render_controller = (ha_group) => {
		if (ha_group.nested_controller) return (<div onClick={() => this.handle_controller_click(ha_group.nested_controller)} style={{cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}}>{ha_group.nested_controller.name}</div>);
		return (<div style={{color: this.props.theme.palette.red.main}}>Controller Not Found</div>);
	}

	load_ha_groups = () => {
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.ha_groups != null) this.setState({ha_groups: null});
		new DeviceHAGroup()
			.listFromAPI(params)
			.then( ({items, total}) => {
				DeviceHAGroup.loadRequirements(items)
				.then( (items) => {
					let ha_groups = items;
					ha_groups.forEach( (ha_group) => {
						ha_group.table_controller = () => this.render_controller(ha_group);
						ha_group.table_integration = !ha_group.error && ha_group.nested_integration ? ha_group.nested_integration.name : <div style={{color: this.props.theme.palette.red.main}}>Integration Not Found</div>;
						ha_group.table_ports = ha_group.ports ? ha_group.ports.join(", ") : null;
						ha_group.table_name = () => (<div onClick={() => !ha_group.error && this.handle_table_name_click(ha_group)} style={{cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}}>{ha_group.name}</div>);
					});
					this.setState( () => {
						return {
							ha_groups: ha_groups,
							page_data: {
								page: params.page,
								per_page: params.per_page,
								page_meta: true,
								total: total
							}
						};
					});
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
				});
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_ha_groups();
		});
	}

	prepare_filter = (params) => {
		let body = {};
		if (params) {
			if (params["Name"]) {
				body.name_like = params["Name"];
			}
			if (params["Device Type"] && params["Device Type"].values && params["Device Type"].values.length > 0) {
				var deviceTypeIDs = params["Device Type"].values.map((option) => {
					return option.value;
				});
				body.device_type_id_in = deviceTypeIDs.join(",");
			}
			if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
				var companyIDs = params["Account"].values.map((option) => {
					return option.value;
				});
				body.company_id_in = companyIDs.join(",");
			}
			
		}
		return body;
	}

	apply_filters = (filters) => {
		this.setState({filter_data: this.prepare_filter(filters)}, () => {
			this.load_ha_groups();
		});
	}

	render() {
		let { tabHostProxy, classes } = this.props;
		const { ha_groups, page_data } = this.state;
		return (
			<div className={classes.container}>
				{ha_groups === null ?
					<Loading />
				:
				<React.Fragment>
					<HAGroupsList
						page_change={this.page_change}
						ha_groups={ha_groups}
						perform_sort={this.perform_sort}
						page_data={page_data}
						tabHostProxy={tabHostProxy}
					/>
				</React.Fragment>
				}
			</div>
		);
	}
}

HAGroupsTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(HAGroupsTab));
