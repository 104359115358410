import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import AddIcon from '@material-ui/icons/Add';
import TextInput from '../../Inputs/TextInput';
import DateSelection from '../../Inputs/DateSelection';
import SwitchInputRadio from '../../Inputs/SwitchInputRadio';
import Button from '@material-ui/core/Button';
import NumberInput from '../../Inputs/NumberInput';
import SwitchInput from '../../Inputs/SwitchInput';
import LookupInput from '../../Inputs/LookupInput';
import SelectInput from '../../Inputs/SelectInput';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import OffsetInput from '../../RuleSpecific/OffsetInput';
import RuleConditionInput from '../../RuleSpecific/RuleConditionInput';
import RuleActions from '../../RuleSpecific/RuleActions';
import DeviceType from '../../../services/DataModels/DeviceType';
import Rule from '../../../services/DataModels/Rule';
import Integration from '../../../services/DataModels/Integration';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Loading from '../../DisplayOriented/Loading';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Permissions from '../../../services/Permissions';
import Auth from '../../../services/Auth';
import Chip from '@material-ui/core/Chip';
import { GetAll, AssignNestedModels } from '../../../services/CLURDUtilities';
import { InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
	saveButton: {
		marginLeft: "8px",
	},
	edgeCloudContainer: {
	},
	container: {
		overflowY: "scroll",
		width: "100%",
		padding: "12px",
	},
	title: {
		flex: "100%",
		fontSize: "1.25rem",
		fontFamily: "Inter",
	},
	topRowContainer: {
		display: "flex",
		alignItems: "center"
	},
	descriptionContainer: {
		marginTop: "-4px",
		width: "100%",
		marginRight: "12px",
	},
	accountContainer: {
		marginTop: "16px",
		width: "50%",
		marginRight: "12px",
	},
	topRowRightSection: {
		height: "38px",
		display: "flex",
		margin: "0 auto",
		position: "relative"
	},
	configurationContainer: {
		display: "flex",
		marginTop: "8px",
		marginBottom: "8px",
	},
	configurationGeneralContainer: {
		marginRight: "12px",
		paddingRight: "12px",
		borderRight: "solid lightgrey 1px",
	},
	errorConditionsWrapper: {
		paddingLeft: "20px"
	},
	activeInactive: {
		margin: "auto",
		flexWrap: "nowrap"
	},
	timingContainer: {
		width: "20%"
	},
	conditionContainer: {
		width: "40%"
	},
	propertyContainer: {
		width: "100%",
	},
	recipientContainer: {
		width: "40%"
	},
	whereConditionContainer: {
		width: "30%"
	},
	hideDevices: {
		color: "grey",
		fontSize: "16px",
		fontFamily: "Inter"
	},
	offsetContainer: {
		marginBottom: "12px",
	},
	previewContainer: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		fontFamily: "Inter",
		minHeight: "64px",
		fontSize: "14px",
		color: "#8e8e93",
	},
	actionIcon: {
		padding: "4px",
		margin: "auto",
		color: "#8e8e93",
	},
	label: {
		textTransform: "uppercase",
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.87)",
		display: "inline",
		fontFamily: "Inter",
	},
	fieldLabel : {
		fontSize: "14px",
		color: "#8e8e93",
		display: "inline",
		fontFamily: "Inter",
	},
	chipRoot: {
		margin: "0 4px 4px 0"
	},
	lookupContainer: {
		marginTop: "12px",
		marginBottom: "12px",
	},
	previewSpan: {
		margin: "auto 8px",
		minHeight: "64px",
		alignItems: "center",
		display: "flex",
		fontSize: "14px",
		color: "#8e8e93",
	},
	firstSpan: {
		marginLeft: 0,
	},
	repeatOverrride: {
		display: "inline",
		width: "90px",
		margin: 0,
	},
	description: {
		// width: "90%",
	},
	descriptionInput: {
		fontSize: "22px",
	},
	buttonContainer: {
		display: "flex",
		marginTop: "32px",
		marginBottom: "16px",
		marginRight: "16px",
		justifyContent: "flex-end"
	},
	deleteButton: {
		backgroundColor: theme.palette.red.main,
		'&:hover': {
			backgroundColor: darken(theme.palette.red.main, .2),
		},
	},
	inlineSelectInput: {
		margin: "0 0 -8px 2px"
	},
	outlinedButton: {
		margin: "0 8px",
		height: "100%",
		minHeight: "unset",
		marginBottom: "10px"
	},
	whereConditionDivider: {
		borderBottom: "solid lightgrey 1px",
		marginBottom: "20px",
	},
	addDerivedValueButton: {
		marginBottom: "20px",
	},
	closeButton: {
    display: "flex",
    justifyContent: "end"
	}
});


class RuleDetailTab extends React.Component {
	constructor(props) {
		super(props);
		RuleDetailTab.contextType = SnackbarContext;
		this.props = props;
		this.state = {
			show_errors: false,
			modal: {
				open: false
			},
			ruleHelper: null,
			device_suggestions: null,
			type_suggestions: null,
			initial_device_relations: [],
			initial_type_relations: [],
			account_options: [],
			derived_values: false
		};
		if (this.props.data && this.props.data._id && this.props.data._id !== "") {
			this.state.ruleHelper = new Rule(this.props.data)
			this.state.rule = this.state.ruleHelper.editableCopy();
		} else {
			this.state.ruleHelper = new Rule();
			if (this.props.data) {
				this.state.ruleHelper.setData(Object.assign(this.state.ruleHelper.editableCopy(), this.props.data));
			}
			this.state.rule = this.state.ruleHelper.editableCopy();
		}
		this.error_options = [{value: "system", display: "System"}, {value: "rule_action", display: "Policy Action"}, {value: "config", display: "Configuration"},  {value: "aws_iot", display: "AWS IoT"}, {value: "aws_greengrass_iot", display: "AWS Greengrass IoT"}, {value: "azure_iot", display: "Azure IoT"}, {value: "bluemix_iot", display: "Bluemix IoT"}];
		this.set_account_setting();
		this.loadAndEnsureRule();
		this.load_suggestions();
		if (!this.state.rule.reactive) {
			this.state.rule.reactive = true;
			this.state.must_delete = true;
			const prompt = "This policy is using a deprecated scheduling system. The policy cannot be migrated. Please delete the policy.";
			this.state.modal = {
				"open": true,
				prompt: prompt,
				yesFunction: this.closeModal,
				functionText: "Got it",
			};
		}
		this.props.tabHostProxy.setTabRefresh(this.refresh);
		if (this.props.data && this.props.data.copy){
			this.state.rule.copy = true;
		} else {
			this.state.rule.copy = false;
		}

		this.derivedValuesTypesOptions =[
			{value: "count", display: "Count"},
			{value: "min", display: "Min"},
			{value: "max", display: "Max"},
			{value: "avg", display: "Average"},
			{value: "sum", display: "Sum"},
			{value: "convert_indexed_keys", display: "Convert Indexes"},
		]
		this.whereConditionsTypesOptions =[
			{value: "where", display: "Where"},
			{value: "or_where", display: "Or Where"},
		]
		this.whereConditionsOperatorOptions =[
			{value: "equal", display: "Equal"},
			{value: "not_equal", display: "Not Equal"},
			{value: "greater_than", display: "Greater Than"},
			{value: "greater_than_equal", display: "Greater Than/Equal"},
			{value: "less_than", display: "Less Than"},
			{value: "less_than_equal", display: "Less Than/Equal"},
			{value: "in", display: "In"},
			{value: "not_in", display: "Not In"},
			{value: "starts_with", display: "Starts With"},
			{value: "ends_with", display: "Ends With"},
			{value: "contains", display: "Contains"},
			{value: "strict_contains", display: "Strict Contains"},
			{value: "length_equals", display: "Length Equals"},
			{value: "length_not_equals", display: "Length Not Equals"},
			{value: "length_equals", display: "Length Equals"},
			{value: "length_greater_than", display: "Length Greater Than"},
			{value: "length_greater_than_equal", display: "Length Greater Than/Equals"},
			{value: "length_less_than", display: "Length Less Than"},
			{value: "length_less_than_equal", display: "Length Less Than/Equals"},
		]
	}

	refresh = () => {
		this.setState({loading: true});
		new Rule({_id: this.props.data._id}).readFromAPI().then( (rule) => {
			this.props.tabHostProxy.updateTabData('rule', rule._id, rule, false);
			this.props.tabHostProxy.setTabRefresh(this.refresh);
			this.setState( (state) => {
				state.ruleHelper = new Rule(this.props.data);
				state.rule = state.ruleHelper.editableCopy();
				return state;
			}, () => {
				this.loadAndEnsureRule();
				this.load_suggestions();
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	load_suggestions = () => {
		let promises = [GetAll("devices"), GetAll("device_types")];
		Promise.all(promises).then( ([devices, types]) => {
			AssignNestedModels("device_types", "device_type_id", devices).then( () => {
				devices = devices.filter( (device) => device.nested_device_type != null);
				devices = devices.map( (device) => ({value: device._id, label: device.name + ": " + device.unique_id, whole: device}));
				this.setState( (state) => {
					state.device_suggestions = devices;
					state.type_suggestions = types.map( (type) => ({value: type._id, label: type.name, whole: type}));
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.props.tabHostProxy.closeSelf();
			});
			
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.props.tabHostProxy.closeSelf();
		})
	}

	loadAndEnsureRule() {
		let od = this.props.data;
		let typeOfData = typeof od;
		if (typeOfData === 'object' || od === undefined ) {
			let id = od ? od._id : null;
			this.get_device_relations(id).then( () => {
				this.check_integration_actions();
				this.check_devices();
				this.editing_disabled = this.state.rule._id && !Permissions.allow(["update"], "rule", this.state.rule.company_id);
				this.can_delete = Permissions.allow(["delete"], "rule", this.state.rule.company_id);
				if(this.state.rule.copy) {
					this.state.rule._id = null
				}
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else {
			new Rule({_id: od}).readFromAPI().then( (result) => {
				let rules = [result];
				Rule.prepForList(rules).then( () => {
					let rule = rules[0];
					this.props.tabHostProxy.updateTabData('rule', rule._id, rule, false);
					this.setState({rule: new Rule(rule).editableCopy(), ruleHelper: new Rule(rule)});
					this.get_device_relations(rule._id).then( () => {
						this.check_integration_actions();
						this.check_devices();
						this.editing_disabled = this.state.rule._id && !Permissions.allow(["update"], "rule", this.state.rule.company_id);
						this.can_delete = Permissions.allow(["delete"], "rule", this.state.rule.company_id);
						});
				}).catch((error) => {
					this.context.openSnackbar(error, "error");
					this.props.tabHostProxy.closeSelf();
				});
			}).catch((error) => {
				this.context.openSnackbar(error, "error");
				this.props.tabHostProxy.closeSelf();
			});
		}
	}

	get_device_relations = (id) => {
		if (!id) {
			return Promise.resolve();
		}
		return new Promise( (resolve, reject) => {
			let promises = [Rule.getRelations(id, "devices"), Rule.getRelations(id, "device_types")];
			Promise.all(promises).then( ([devices, device_types]) => {
				AssignNestedModels("device_types", "device_type_id", devices).then( () => {
					let valid_devices = devices.filter( (device) => device.nested_device_type != null);
					valid_devices = valid_devices.map( (device) => ({value: device._id, label: device.name + ": " + device.unique_id, whole: device}));
					if (valid_devices.length !== devices.length) {
						this.context.openSnackbar("One or more devices attached to this rule are without device types. Those devices won't appear here and will be removed upon saving.", "warning");
					}
					this.setState( (state) => {
						state.initial_device_relations = devices.map( ({_id}) => _id);
						state.initial_type_relations = device_types.map( ({_id}) => _id);
						state.rule.device_type_ids = device_types.map( (type) => ({whole: type, value: type._id, label: type.name})) || [];
						state.rule.device_ids = valid_devices;
						state.rule.nested_device_types = device_types || [];
						state.rule.nested_devices = valid_devices || [];
						return state;
					}, () => resolve());
				}).catch( (error) => {
					reject(error);
				});
			}).catch( (error) => {
				reject(error);
			});
		});
	}

	check_devices = () => {
		//if no nested devices or if the number of nested devices is not equal to the number or ids or if we don't have permission to read devices in that company
		if (this.props.data && !Permissions.allow(["read"], "device", this.state.rule.company_id) ) {
			this.hide_devices = true;
		} else if (Permissions.allow(["read"], "device", this.state.rule.company_id)) {
			this.hide_devices = false;
		} else {
			this.hide_devices = true;
		}
	}

	check_integration_actions = () => {
		let integration_map = {};
		if (this.state.rule.thenActions) {
			this.state.rule.thenActions.forEach( (action) => {
				if (action.integration_id) {
					integration_map[action.integration_id] = action.integration_id;
				}
			});
		}
		if (this.state.rule.elseActions) {
			this.state.rule.elseActions.forEach( (action) => {
				if (action.integration_id) {
					integration_map[action.integration_id] = action.integration_id;
				}
			});
		}
		let integration_ids = Object.entries(integration_map).map( ([key, value]) => key);
		if (integration_ids.length === 0) {
			return;
		}
		let ids = integration_ids.join();
		//need to tag those integration actions that we can't load nor edit nor remove...
		if (Permissions.allow(["read"], "integration")) {
			new Integration().listFromAPI({_id_in: ids, per_page: 1000}).then( ({items, total}) => {
				let new_rule = this.state.rule;
				if (total !== integration_ids.length) {
					if (this.state.rule.thenActions) {
						let new_then_actions = [];
						this.state.rule.thenActions.forEach( (action) => {
							if (action.integration_id) {
								let match = items.find( ({_id}) => {
									return _id === action.integration_id;
								});
								if (match) {
									new_then_actions.push(action);
								}
							} else {
								new_then_actions.push(action);
							}
						});
						new_rule.thenActions = new_then_actions;
					}
					if (this.state.rule.elseActions) {
						let new_else_actions = [];
						this.state.rule.elseActions.forEach( (action) => {
							if (action.integration_id) {
								let match = items.find( ({_id}) => {
									return _id === action.integration_id;
								});
								if (match) {
									new_else_actions.push(action);
								}
							} else {
								new_else_actions.push(action);
							}
						});
						new_rule.elseActions = new_else_actions;
					}
					this.context.openSnackbar("One of the integrations used in an action is no longer available, and has been removed. Please save the policy to keep these changes.", "error");
					this.setState({rule: new_rule});
				}
			});
		}
	}

	render_chips = () => {
		let label = this.state.rule.device_ids.length > 0 ? "Devices" : this.state.rule.device_type_ids.length > 0 ? "Device Types" : "On no devices.";
		let list = this.state.rule.device_ids.length > 0 ? this.state.rule.nested_devices : this.state.rule.nested_device_types;
		return (
			<div>
				<div className={this.props.classes.label}>{label}</div>
				<div>
					{list ? list.map( (item) => (
						<Chip
							classes={{root: this.props.classes.chipRoot}}
							key={item._id}
							clickable={false}
							label={item.name}
						/>
					)) : ""}
				</div>
			</div>
		);
	}

	set_account_setting = () => {
		this.state.rule.company_id = this.state.rule.company_id ? this.state.rule.company_id : Auth.currentCompany()._id;
		this.show_account_selection = true;
		this.load_account_options();
	}

	load_account_options = (account_ids) => {
		GetAll("companies", {}).then( (result) => {
			let accounts = result.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
			this.setState({account_options: accounts.map( (account) => ({display: account.name, value: account._id}) )});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render() {
		const { tabHostProxy, classes } = this.props;
		const { rule, ruleHelper, show_errors, account_options } = this.state;
		return (
			<div className={classes.container}>
				{rule.nested_devices == null ?
					<Loading/>
				:
					<React.Fragment>
						<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal} />
						<div className={classes.topRowContainer}>
							<div className={classes.descriptionContainer} >
								<TextInput
									disabled={this.editing_disabled}
									error={show_errors && (rule.description === '' || /^\s*$/.test(this.state.rule.description))}
									error_message={'Please describe this policy.'}
									className={classes.description}
									overrideClass={classes.descriptionInput}
									emitChange={this.handleDescriptionChange}
									priorState={rule.description}
									label="Policy Description*"
									variant="standard"
								/>
							</div>
							<div className={classes.topRowRightSection}>
								<SwitchInput
									disabled={this.editing_disabled}
									containerClass={classes.activeInactive}
									initial={rule.active}
									emitChange={this.handleActiveChange}
									onLabel="Active"
									offLabel="Active"
									location="start"
								/>
								<div className={classes.edgeCloudContainer}>
									<SelectInput
										disabled={this.editing_disabled}
										basic
										emitChange={this.handleCloudEdgeChange}
										priorState={rule.cloud_rule}
										options={[{ display: "On the Edge", value: false }, { display: "In the Cloud", value: true }]}
									/>
								</div>
								<Tooltip className={classes.actionIcon} title="Not all of the options below can be done on both the Edge and the Cloud. Edge policies cannot be scheduled, heartbeat and status change policies conditions are Cloud-only, and certain actions are only valid on the Cloud or Edge.">
									<IconButton color="inherit">
										<HelpIcon />
									</IconButton>
								</Tooltip>
							</div>
						</div>
						<div className={classes.topRowContainer}>
							<div className={classes.accountContainer} >
								<SelectInput
									label="ACCOUNT"
									disabled={!ruleHelper.isNew() && !rule.copy}
									error={show_errors && rule.company_id === ""}
									error_message="Please select an account."
									field="company_id"
									emitChange={this.handleAccountChange}
									priorState={this.state.rule.company_id}
									options={account_options}
								/>
							</div>
						</div>
						<div className={classes.configurationContainer}>
							<div className={classes.configurationGeneralContainer + " " + classes.conditionContainer}>
								<div className={classes.label}>
									CONDITION
								</div>
								<SwitchInputRadio
									disabled={this.editing_disabled}
									initial={rule.condition === "true" || rule.condition === "false"}
									emitChange={() => this.handleConditionChange("true")}
									label="Always Execute"
								/>
								<SwitchInputRadio
									disabled={this.editing_disabled || !rule.reactive || !rule.cloud_rule}
									initial={rule.condition === "heartbeat_status_changed"}
									emitChange={() => this.handleConditionChange("heartbeat_status_changed")}
									label="On Heartbeat Status Change"
								/>
								<SwitchInputRadio
									disabled={this.editing_disabled || !rule.reactive}
									initial={rule.condition === "device_error"}
									emitChange={() => this.handleConditionChange("device_error")}
									label="On Device Error"
								/>
								<div className={classes.errorConditionsWrapper}>
									{rule.condition === "device_error" ? this.error_options.map( (error_option) => (
										<SwitchInputRadio
											key={"Error_" + error_option.value}
											disabled={this.editing_disabled || !rule.reactive}
											initial={rule.conditionErrorType === error_option.value}
											emitChange={() => this.handleErrorChange(error_option)}
											label={error_option.display + " Error"}
										/>
									)) : ""}
								</div>
								<SwitchInputRadio
									disabled={this.editing_disabled || !rule.reactive || !rule.cloud_rule}
									initial={rule.condition === "status_changed"}
									emitChange={() => this.handleConditionChange("status_changed")}
									label="On Status Change"
								/>
								<SwitchInputRadio
									disabled={this.editing_disabled}
									initial={this.isCustomCondition()}
									emitChange={() => this.handleConditionChange("custom")}
									label="Via Property Evaluation"
								/>
								{rule.condition === "custom" ?
									<SwitchInputRadio
										disabled={this.editing_disabled}
										initial={rule.elseActions !== null}
										emitChange={this.handleElseToggle}
										label="Include 'otherwise' Actions"
									/>
									: ""
								}
							</div>
							<div className={classes.recipientContainer}>
								{this.hide_devices ? 
									<div>
										<div className={classes.label}>
											ON EITHER DEVICES OR TYPES
										</div>
										<div className={classes.hideDevices}>
											You don't have sufficient permissions to choose or edit the devices that this policy can be applied to.
										</div>
									</div> 
								: 
									this.editing_disabled ? this.render_chips() :
										<div>
											<div className={classes.lookupContainer}>
												{this.state.device_suggestions ? <LookupInput
													disabled={this.editing_disabled}
													priorState={{ suggestions: this.state.device_suggestions, values: rule.device_ids }}
													key={"LookupInput_device_ids"}
													label="Devices"
													model="devices"
													emitChange={this.handleDeviceIdsChange}
												/> : <Loading />}
											</div>
											<div className={classes.lookupContainer}>
												{this.state.type_suggestions ? <LookupInput
													disabled={this.editing_disabled}
													priorState={{ suggestions: this.state.type_suggestions, values: rule.device_type_ids }}
													key={"LookupInput_device_types"}
													label="Device Types"
													model="device_types"
													emitChange={this.handleDeviceTypeIdsChange}
												/> : <Loading />}
											</div>
										</div>
								}
							</div>
						</div>
						{/* Derived Values Section  */}

						<div className={classes.label}>
							DERIVED VALUES
						</div>
						<div className={classes.conditionContainer}>
							<SwitchInputRadio
								disabled={this.editing_disabled}
								initial={this.state.derived_values}
								emitChange={() => this.handleDerivedValuesState()}
								label="Set Derived values"
							/>
						</div>
						{this.state.derived_values && 
							<div>
								{Array.isArray(this.state.rule.rule_condition?.derived_values) && this.state.rule.rule_condition?.derived_values.map((item, index) => (
									<div key={index}>
										{this.state.rule.rule_condition?.derived_values.length > 1 && 
											<div className={classes.closeButton}>
												<IconButton
													aria-label="Remove derived value"
													onClick={() => this.handleRemoveDerivedValue(index)}
												>
													<CloseIcon />
												</IconButton>
											</div>
										}
										<div className={classes.topRowContainer}>
											<div className={classes.conditionContainer}>
												<TextInput
													disabled={this.editing_disabled}
													overrideClass={classes.descriptionInput}
													emitChange={(input) => this.handleDerivedValuesChange(input, index)}
													priorState={item.name}
													label="Name"
													variant="standard"
													field="name"
												/>
											</div>
											<div className={classes.conditionContainer}>
												<SelectInput
													disabled={this.props.disabled}
													id="type"
													label=""
													field="type"
													overrideClass={classes.inlineSelectInput}
													emitChange={(input) => this.handleDerivedValuesChange(input, index)}
													priorState={item.type}
													options={this.derivedValuesTypesOptions}
													basic={true}
												/>
											</div>
											<div className={classes.conditionContainer}>
												<TextInput
													disabled={this.editing_disabled}
													overrideClass={classes.descriptionInput}
													emitChange={(input) => this.handleDerivedValuesChange(input, index)}
													priorState={item.property}
													label="Property"
													variant="standard"
													field="property"
												/>
											</div>
										</div>

										<div className={classes.fieldLabel}>
											Properties
										</div>
										{Array.isArray(item.properties) && item.properties.map((property, propertyIndex) => (
											<div key={propertyIndex} className={classes.propertyContainer}>
												<TextInput
													disabled={this.editing_disabled}
													overrideClass={classes.descriptionInput}
													emitChange={(input) => this.handleDerivedValuesPropertiesChange(input, index, propertyIndex)}
													priorState={property}
													label=""
													variant="standard"
													field="properties"
													InputProps={item.properties.length > 1 && !this.editing_disabled ? {
														endAdornment: (
																<InputAdornment position="end">
																		<IconButton
																				aria-label="Remove property"
																				onClick={() => this.handleRemoveProperty(index, propertyIndex)}
																		>
																				<CloseIcon />
																		</IconButton>
																</InputAdornment>
														),
												} : null}
												/>
											</div>
										))}

										<Button className={classes.outlinedButton} onClick={() => this.handleAddProperty(index)} variant="outlined" color="primary" >
											<AddIcon />
											Add Property
										</Button>
										
										<TextInput
											disabled={this.editing_disabled}
											overrideClass={classes.descriptionInput}
											emitChange={(input) => this.handleDerivedValuesChange(input, index)}
											priorState={item.data_set}
											label="Dataset"
											variant="standard"
											field="data_set"
										/>

										<div className={classes.fieldLabel}>
											Where Conditions
										</div>
										{Array.isArray(item.where_conditions) && item.where_conditions.map((whereCondition, whereConditionIndex) => (
											<div key={whereConditionIndex} className={classes.topRowContainer}>
												<div className={classes.whereConditionContainer}>
													<SelectInput
														disabled={this.props.disabled}
														id="type"
														label=""
														field="type"
														overrideClass={classes.inlineSelectInput}
														emitChange={(input) => this.handleWhereConditionChange(input, index, whereConditionIndex)}
														priorState={whereCondition.type}
														options={this.whereConditionsTypesOptions}
														basic={true}
													/>
												</div>
												<div className={classes.whereConditionContainer}>
													<TextInput
														disabled={this.editing_disabled}
														overrideClass={classes.descriptionInput}
														emitChange={(input) => this.handleWhereConditionChange(input, index, whereConditionIndex)}
														priorState={whereCondition.property}
														label="Property"
														variant="standard"
														field="property"
													/>
												</div>
												<div className={classes.whereConditionContainer}>
													<SelectInput
														disabled={this.props.disabled}
														id="operator"
														label=""
														field="operator"
														overrideClass={classes.inlineSelectInput}
														emitChange={(input) => this.handleWhereConditionChange(input, index, whereConditionIndex)}
														priorState={whereCondition.operator}
														options={this.whereConditionsOperatorOptions}
														basic={true}
													/>
												</div>
												<div className={classes.whereConditionContainer}>
													<TextInput
														disabled={this.editing_disabled}
														overrideClass={classes.descriptionInput}
														emitChange={(input) => this.handleWhereConditionChange(input, index, whereConditionIndex)}
														priorState={whereCondition.value}
														label="Value"
														variant="standard"
														field="value"
													/>
												</div>
												{item.where_conditions.length > 1 && 
													<IconButton
														aria-label="Remove where condition"
														onClick={() => this.handleRemoveWhereCondition(index, whereConditionIndex)}
													>
														<CloseIcon />
													</IconButton>}
											</div>
										))}
										<div className={classes.whereConditionDivider}>
											<Button className={classes.outlinedButton} onClick={() => this.handleAddWhereCondition(index)} variant="outlined" color="primary" >
												<AddIcon />
												Add Where Condition
											</Button>
										</div>
									</div>)
								)}
								<Button className={classes.addDerivedValueButton} onClick={this.handleAddDerivedValue} variant="contained" color="primary" size="large">
									ADD DERIVED VALUE
								</Button>
							</div>}

						{/* Policy Detail Section */}
						<div className={classes.label}>
							Policy Detail
						</div>
						<div className={classes.previewContainer}>
							{this.generatePreview(classes)}
						</div>
						<div className={classes.buttonContainer}>
								{ruleHelper.isNew() || rule.copy?
									<Button onClick={this.createOrSaveRule} variant="contained" color="primary" size="large">
										CREATE POLICY
									</Button>
									:
									<div>
										<Button disabled={!this.can_delete} onClick={() => this.openModal("Are you sure you want to delete this policy?", "DELETE POLICY", this.deleteRule)} color="primary">
											DELETE POLICY
										</Button>
										{this.state.must_delete ? "" : <Button disabled={this.editing_disabled} className={classes.saveButton} onClick={this.createOrSaveRule} variant="contained" color="primary" size="large">
											SAVE POLICY
										</Button>}
									</div>
								}
							</div>
					</React.Fragment>
				}
			</div>
		);
	}

	updateTypeActions = (types) => {
		if (types === null || types.length === 0 || types[0] == null) {
			let new_rule = this.state.rule;
			new_rule.availableActions = null;
			this.setState({rule: new_rule});
			return;
		};
		let action_abilities = types[0].capabilities.actions;
		types.forEach( (type) => {
			Object.entries(type.capabilities.actions).forEach((actionKeyValue) => {
				let action = actionKeyValue[0];
				let enabled = actionKeyValue[1];
				if (enabled === false) {
					action_abilities[action] = false;
				}
			})
		});
		let new_rule = this.state.rule;
		new_rule.availableActions = action_abilities;
		this.setState({rule: new_rule});
		this.setHelperAndRuleState();
	}

	isCustomCondition = () => {
		if (
			this.state.rule.condition !== "heartbeat_status_changed" &&
			this.state.rule.condition !== "device_error" &&
			this.state.rule.condition !== "status_changed" &&
			this.state.rule.condition !== "true" &&
			this.state.rule.condition !== "false"
		) {
			return true;
		} else {
			return false;
		}
	}

	openModal = (prompt, buttonText, fxn) => {
		this.setState({modal: {
			"open": true,
			prompt: prompt,
			yesFunction: fxn,
			functionText: buttonText,
		}});
	}

	closeModal = () => {
		let newState = {modal: {open: false}};
		this.setState(newState);
	}

	deleteRule = () => {
		this.state.ruleHelper.deleteFromAPI().then( () => {
			this.context.openSnackbar('Policy deleted.', 'success');
			this.props.tabHostProxy.refresh();
			this.props.tabHostProxy.closeSelf();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.closeModal();
		});
	}

	canCreateOrSave = () => {
		if (this.state.rule.description === "" || /^\s*$/.test(this.state.rule.description)) {
			return false;
		}
		if (!this.state.rule.actions) {
			return false;
		}
		var headerValid = (action) => {
			let headers = action.fields.find((field) => {
				return field.field === "headers";
			});
			if (headers) {
				let hash = {};
				let error_message = '';
				headers.value.forEach( (header) => {
					if (hash[header.key]) {
						hash = false;
						return false;
					} else {
						hash[header.key] = true;
					}
				});
				if (hash === false) {
					error_message = "Please ensure any headers belonging to any actions are unique.";
				}
				let empty = headers.value.find( (header, index) => header.key === '');
				if (empty) {
					error_message += " Please ensure any headers belonging to any actions do not have empty keys.";
				}
				let empty_value = headers.value.find( (header, index) => header.value === '');
				if (empty_value) {
					error_message += " Header values cannot be empty.";
				}
				if (error_message === '') {
					return true;
				} else {
					this.context.openSnackbar(error_message, "error");
					return false;
				}
			}
			return true;
		}

		var findError = (action) => {
			return ((!headerValid(action)) || (action.executed[execution] === false));
		};

		var findCapabilityConflict = (action) => {
			return (this.state.rule.availableActions && this.state.rule.availableActions[action.type] === false);
		};

		let execution = this.state.rule.cloud_rule ? "cloud" : "edge";
		if (this.state.rule.thenActions !== null && (undefined !== this.state.rule.thenActions.find(findError))) {
			return false;
		}
		if (this.state.rule.elseActions !== null && (undefined !== this.state.rule.elseActions.find(findError))) {
			return false;
		}
		if (this.state.rule.thenActions && undefined !== this.state.rule.thenActions.find(findCapabilityConflict) || (this.state.rule.elseActions && undefined !== this.state.rule.elseActions.find(findCapabilityConflict))) {
			this.openModal("One or more actions is not supported by one or more of the devices or device types attached to this policy. Some devices may not be able to carry out these actions. Continue saving?", "SAVE POLICY", this.overrideSave);
			return false;
		}
		return true;
	}

	overrideSave = () => {
		this.createOrSaveRule(true);
	}

	change_relations = (rule_id) => {
		let promises = [];
		this.state.initial_device_relations.forEach( (_id) => {
			if (!this.state.rule.device_ids.find( (new_device) => new_device.value === _id)) {
				promises.push(Rule.removeRelation(rule_id, _id, "devices"));
			}
		});
		this.state.rule.device_ids.forEach( (device) => {
			if (!this.state.initial_device_relations.find( (old_id) => old_id === device.value)) {
				promises.push(Rule.addRelation(rule_id, device.value, "devices"));
			}
		});
		this.state.initial_type_relations.forEach( (type_id) => {
			if (!this.state.rule.device_type_ids.find( (new_type) => new_type.value === type_id)) {
				promises.push(Rule.removeRelation(rule_id, type_id, "device_types"));
			}
		});
		this.state.rule.device_type_ids.forEach( (device_type) => {
			if (!this.state.initial_type_relations.find( (old_id) => old_id === device_type.value)) {
				promises.push(Rule.addRelation(rule_id, device_type.value, "device_types"));
			}
		});
		return Promise.all(promises);
	}

	createOrSaveRule = (override) => {
		if (override !== true && !this.canCreateOrSave()) {
			if (this.state.show_errors === false) {
				this.setState({show_errors: true});
			}
			return;
		}
		let body = Rule.prepareBody(this.state.rule);
		this.state.ruleHelper.setData(body).createOrSave().then( (result) => {
			this.change_relations(result._id).then( () => {
				this.state.ruleHelper = new Rule(result);
				let types = this.state.rule.deviceTypes;
				let devices = this.state.rule.devices;
				this.state.rule = JSON.parse(JSON.stringify(this.state.ruleHelper.data));
				this.state.rule.deviceTypes = types;
				this.state.rule.devices = devices;
				this.context.openSnackbar('Policy saved.', 'success');
				let rules = [result];
				this.props.tabHostProxy.closeSelf();
				this.props.tabHostProxy.refresh();
				Rule.prepForList(rules).then( () => {
					this.props.tabHostProxy.addTab("rule", rules[0]);
				}).catch( (error) => {
					this.closeModal();
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
				this.closeModal();
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
			this.closeModal();
		});
	}

	generateDevicesOrTypesInfo = () => {
		if (this.hide_devices) {
			return " one of the devices";
		}
		let id_list = '';
		if (this.state.rule.device_ids.length > 0) {
			let list = this.editing_disabled ? this.state.rule.nested_devices : this.state.rule.device_ids;
			list.forEach( (device, index) => {
				if (device) {
					let label = this.editing_disabled ? device.unique_id : device.label;
					id_list += (index === 0) ? label : ", " + label;
				}
			});
		} else if (this.state.rule.device_type_ids.length > 0) {
			id_list = "any device of type ";
			let list = this.editing_disabled ? this.state.rule.nested_device_types : this.state.rule.device_type_ids;
			list.forEach( (type, index) => {
				if (type) {
					let label = this.editing_disabled ? type.name : type.label;
					id_list += (index === 0) ? label : ", " + label;
				}
			});
		} else {
			id_list = "[select a device or type above]";
		}
		return id_list;
	}

	generatePreview = (classes) => {
		let previewSections = [];
		let dateSelectionComponent = <DateSelection
			disabled={this.editing_disabled}
			range={false}
			key="dateComponent"
			emitChange={this.handleDateChange}
			priorState={{ firstDate: this.state.rule.schedule.date }}
			disablePast={true}
		/>;
		let offsetComponent = <OffsetInput
			disabled={this.editing_disabled}
			key="offsetComponent"
			priorState={this.state.rule.schedule.offset}
			emitChange={this.handleOffsetChange}
		/>;
		let repeatComponent = <NumberInput
			minimum={0}
			key="repeatComponent"
			disabled={this.editing_disabled}
			rootClass={classes.repeatOverrride}
			priorState={this.state.rule.schedule.repeats}
			emitChange={this.handleRepeatChange}
		/>;
		let ruleConditionComponent = <RuleConditionInput
			key="ruleConditionComponent"
			disabled={this.editing_disabled}
			condition={this.state.rule.conditionLogic}
			conditions={this.state.rule.conditions}
			emitChange={this.handleConditionLogicChange}
		/>;
		let actionsComponent = <RuleActions
			disabled={this.editing_disabled}
			key="ruleActionsComponent"
			otherwisePlural={(this.state.rule.conditionLogic && (this.state.rule.conditionLogic.type === "and" || this.state.rule.conditionLogic.type === "or")) ? this.state.rule.conditionLogic.type : null}
			emitChange={this.handleActionsChange}
			validIntegrations={this.getDeviceIntegrations()}
			typeActionAbilities={this.state.rule.availableActions}
			cloudRule={this.state.rule.cloud_rule}
			priorState={{ thenActions: this.state.rule.thenActions, elseActions: this.state.rule.elseActions, actions: this.state.rule.actions }}
		/>;
		let devicesOrTypesList = this.generateDevicesOrTypesInfo();
		if (!this.state.rule.reactive) {
			if (this.state.rule.schedule.showRepeats) {
				previewSections.push(React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "scheduled" }, "Starting on"));
				previewSections.push(dateSelectionComponent);
				previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "offset" }, ", activate this policy every"));
				previewSections.push(offsetComponent);
				previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "device_list" }, `on ${devicesOrTypesList + (!this.state.rule.schedule.definite ? "." : "")}`));
				if (this.state.rule.schedule.definite) {
					previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "repeats" }, ", but stop activating after"));
					previewSections.push(repeatComponent);
					previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "activations" }, "total activations, including the scheduled date."));
				}
			} else {
				previewSections.push(React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "scheduled" }, "On"));
				previewSections.push(dateSelectionComponent);
				previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "offset" }, `activate this policy on ${devicesOrTypesList + (!this.state.rule.schedule.definite ? "." : "")}`));
			}
		}
		switch (this.state.rule.condition) {
			case "device_error":
				previewSections = [React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "condition" }, `If a ${this.error_options.find(({value}) => value === this.state.rule.conditionErrorType).display} error occurs on ${devicesOrTypesList}, execute the following action(s): `)];
				break;
			case "heartbeat_status_changed":
				previewSections = [React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "condition" }, `Every time a report is generated by ${devicesOrTypesList} and the heartbeat status changes, execute the following action(s): `)];
				break;
			case "status_changed":
				previewSections = [React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "condition" }, `Every time a report is generated by ${devicesOrTypesList} and the status changes, execute the following action(s): `)];
				break;
			case "custom":
				if (this.state.rule.reactive) {
					previewSections.push(React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "condition" }, `Every time a report is generated by ${devicesOrTypesList}, if`));
					previewSections.push(ruleConditionComponent);
					previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "actions" }, "execute the following action(s): "));
				} else {
					previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "condition" }, "Upon activation, if"));
					previewSections.push(ruleConditionComponent);
					previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "actions" }, "execute the following action(s): "));
				}
				break;
			case "true":
			case "false":
				if (this.state.rule.reactive) {
					previewSections.push(React.createElement("span", { className: classes.previewSpan + " " + classes.firstSpan, key: "condition" }, `Every time a report is generated by ${devicesOrTypesList}, execute the following action(s): `));
				} else {
					previewSections.push(React.createElement("span", { className: classes.previewSpan, key: "condition" }, "Upon activation, always execute the following action(s): "));
				}
				break;
		};
		previewSections.push(actionsComponent);
		return previewSections.map( (section) => (section));
	}

	getDeviceIntegrations = () => {
		if (this.state.rule.device_ids.length === 0) return null;
		let unique_ids = {};
		if (!this.state.rule.device_ids[0].whole) return null;
		this.state.rule.device_ids.forEach(({ whole }) => {
			if (whole.device_integration_id) {
				unique_ids[whole.device_integration_id] = whole.device_integration_id;
			}
		});
		return Object.entries(unique_ids).map((key_value) => key_value[0]);
	}

	setHelperAndRuleState = (extraState, callback) => {
		if (extraState == null) {
			extraState = {};
		}
		let newState = { ruleHelper: this.state.ruleHelper, rule: this.state.rule };
		let combinedState = { ...newState, ...extraState };
		this.setState(combinedState, callback);
	}

	//========================
	//ALL INPUT HANDLERS BELOW
	//========================
	handleRepeatChange = (input) => {
		this.state.rule.schedule.repeats = input.value;
		this.setHelperAndRuleState();
	}

	handleDescriptionChange = (input) => {
		this.state.rule.description = input.value;
		this.setHelperAndRuleState();
	}

	handleAccountChange = (input) => {
		this.state.rule.company_id = input.value;
		this.setHelperAndRuleState();
	}

	handleDeviceIdsChange = (input) => {
		this.state.rule.devices = input.suggestions;
		this.state.rule.device_ids = input.value  ? input.value : [];
		if (this.state.rule.device_ids && this.state.rule.device_ids.length > 0) {
			this.state.rule.device_type_ids = [];
		}
		if (this.state.rule.device_ids.indexOf(undefined) !== -1) {
			this.setState( (prev_state) => {
				let new_rule = prev_state.rule;
				new_rule.device_ids = [];
				prev_state.rule.device_ids.map( (id) => {
					if (id !== undefined) {
						new_rule.device_ids.push(id);
					}
				});
				return new_rule;
			});
			this.context.openSnackbar("One or more of the devices attached to this policy have been deleted, please save this policy to resolve this issue.", 'error');
		}
		this.updateTypeActions(this.state.rule.device_ids.map((device) => device.whole.nested_device_type));
		this.setHelperAndRuleState();
	}

	handleDeviceTypeIdsChange = (input) => {
		this.state.rule.deviceTypes = input.suggestions;
		this.state.rule.device_type_ids = input.value ? input.value : this.state.rule.device_type_ids;
		if (this.state.rule.device_type_ids && this.state.rule.device_type_ids.length > 0) {
			this.state.rule.device_ids = [];
		}
		if (this.state.rule.device_type_ids.indexOf(undefined) !== -1) {
			this.setState( (prev_state) => {
				let new_rule = prev_state.rule;
				new_rule.device_type_ids = [];
				prev_state.rule.device_type_ids.map( (id) => {
					if (id !== undefined) {
						new_rule.device_type_ids.push(id);
					}
				});
				let types = this.state.rule.device_type_ids.map((type) => type.whole);
				this.updateTypeActions(types);
				return new_rule;
			});
			this.context.openSnackbar("One or more of the devices types attached to this policy have been deleted, please save this policy to resolve this issue.", 'error');
		} else {
			let types = this.state.rule.device_type_ids.map((type) => type.whole);
			this.updateTypeActions(types);
		}
		this.setHelperAndRuleState();
	}

	handleTimingChange = () => {
		this.state.rule.reactive = !this.state.rule.reactive;
		if (!this.state.rule.reactive && (this.state.rule.condition === "heartbeat_status_changed" || this.state.rule.condition === "status_changed" || this.state.rule.condition === "device_error")) {
			this.state.rule.condition = "true";
		}
		if (!this.state.rule.reactive) {
			this.state.rule.cloud_rule = true;
		}
		this.setHelperAndRuleState();
	}

	handleCloudEdgeChange = (input) => {
		this.state.rule.cloud_rule = input.value;
		if (!this.state.rule.cloud_rule) {
			this.state.rule.reactive = true;
			if (this.state.rule.condition === "heartbeat_status_changed" || this.state.rule.condition === "status_changed") {
				this.state.rule.condition = "true";
			}
		}
		this.setHelperAndRuleState();
	}

	handleRepeatToggle = () => {
		this.state.rule.schedule.showRepeats = !this.state.rule.schedule.showRepeats;
		if (!this.state.rule.schedule.showRepeats) {
			this.state.rule.schedule.offset = null;
			this.state.rule.schedule.definite = false;
			this.state.rule.schedule.repeats = 0;
			this.state.rule.schedule.showRepeats = false;
		}
		this.setHelperAndRuleState();
	}

	handleIndefiniteToggle = () => {
		this.state.rule.schedule.definite = !this.state.rule.schedule.definite;
		this.setHelperAndRuleState();
	}

	handleErrorChange = (error_option) => {
		this.state.rule.conditionErrorType = error_option.value;
		this.setHelperAndRuleState();
	}

	handleConditionChange = (new_condition) => {
		if (this.state.rule.condition === new_condition) {
			this.state.rule.condition = "true";
		} else {
			this.state.rule.condition = new_condition;
		}
		if (this.state.rule.condition === "heartbeat_status_changed" || this.state.rule.condition === "status_changed" || this.state.rule.condition === "device_error") {
			this.state.rule.reactive = true;
		}
		if (new_condition !== "custom" && this.state.rule.elseActions !== null) {
			this.state.rule.elseActions = null;
		}
		if (new_condition !== "custom") {
			this.state.rule.conditionLogic = null;
		}
		if (new_condition === "device_error" && !this.state.rule.conditionErrorType) {
			this.state.rule.conditionErrorType = "system";
		}
		this.setHelperAndRuleState();
	}

	handleDateChange = (input) => {
		this.state.rule.schedule.date = input.value.firstDate;
		this.setHelperAndRuleState();
	}

	handleElseToggle = () => {
		if (this.state.rule.elseActions !== null) {
			this.state.rule.elseActions = null;
		} else {
			this.state.rule.elseActions = [];
		}
		this.setHelperAndRuleState();
	}

	handleOffsetChange = (ranges) => {
		this.state.rule.schedule.offset = ranges;
		this.setHelperAndRuleState();
	}

	handleConditionLogicChange = ({ condition, conditions }) => {
		this.state.rule.conditionLogic = condition;
		this.state.rule.conditions = conditions;
		this.setHelperAndRuleState();
	}

	handleActiveChange = () => {
		this.state.rule.active = !this.state.rule.active;
		this.setHelperAndRuleState();
	}

	handleActionsChange = ({ thenActions, elseActions, actions }) => {
		this.state.rule.thenActions = thenActions;
		this.state.rule.elseActions = elseActions;
		this.state.rule.actions = actions;
		this.setHelperAndRuleState();
	}

	handleDerivedValuesState = () => {
		this.state.derived_values = !this.state.derived_values;

		if(!this.state.rule.rule_condition) {
			this.state.rule.rule_condition = {
				derived_values: [{
					name: "", 
					type: "count", 
					property: "", 
					properties: [""], 
					data_set: "", 
					where_conditions: [
						{
							type: "where",
							operator: "equal",
							property: "",
							value: ""
						}
					]
				}]
			}
		} else if (!this.state.rule.rule_condition.derived_values) {
			this.state.rule.rule_condition.derived_values = [{
				name: "", 
				type: "count", 
				property: "", 
				properties: [""], 
				data_set: "", 
				where_conditions: [
					{
						type: "where",
						operator: "equal",
						property: "",
						value: ""
					}
				]
			}]
		}
		this.setHelperAndRuleState();
	}

	handleDerivedValuesChange = (input, derivedValueIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex][input.field] = input.value;
		this.setHelperAndRuleState();
	}

	handleDerivedValuesPropertiesChange = (input, derivedValueIndex, propertyIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex].properties[propertyIndex] = input.value;
		this.setHelperAndRuleState();
	}

	handleAddProperty = (derivedValueIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex].properties.push("");
		this.setHelperAndRuleState();
	}

	handleRemoveProperty = (derivedValueIndex, propertyIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex].properties.splice(propertyIndex, 1);
		this.setHelperAndRuleState();
	}

	handleWhereConditionChange = (input, derivedValueIndex, whereConditionIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex].where_conditions[whereConditionIndex][input.field] = input.value;
		this.setHelperAndRuleState();
	}

	handleAddWhereCondition = (derivedValueIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex].where_conditions.push({
			type: "where",
			operator: "equal",
			property: "",
			value: ""
		});
		this.setHelperAndRuleState();
	}

	handleRemoveWhereCondition = (derivedValueIndex, whereConditionIndex) => {
		this.state.rule.rule_condition.derived_values[derivedValueIndex].where_conditions.splice(whereConditionIndex, 1);
		this.setHelperAndRuleState();
	}

	handleAddDerivedValue = () => {
		this.state.rule.rule_condition.derived_values.push({
			name: "", 
			type: "count", 
			property: "", 
			properties: [""], 
			data_set: "", 
			where_conditions: [
				{
					type: "where",
					operator: "equal",
					property: "",
					value: ""
				}
			]
		});
		this.setHelperAndRuleState();
	}

	handleRemoveDerivedValue = (derivedValueIndex) => {
		this.state.rule.rule_condition.derived_values.splice(derivedValueIndex, 1);
		this.setHelperAndRuleState();
	}
}

export default withStyles(styles)(withTheme()(RuleDetailTab));
