import axios from 'axios';
import qs from 'qs';
import Auth from './Auth';

let env = window.API;
//ensures a lack of a trailing backslash doesn't break everything.
if (env.charAt(env.length - 1) !== '/') {
    env += "/";
}

// Intercept all errors
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (!error.response) {
        return handle_disconnected(error);
    }
    const status = error.response.status;
    const url = error.response.request.responseURL;
    if ((status === 401 || status === 404) && url.indexOf('user/authenticate') >= 0) {
        return handle_credentials_problem(error);
    }
    if (status === 401 && url.indexOf('user/api_token_reset') >= 0) {
        return handle_credentials_problem(error);
    }
    if (status === 400 && url.indexOf('user/password_reset') >= 0) {
        return handle_pwd_reset_problem(error);
    }
    if (status === 401) {
        return handle_session_timeout(error);
    }
    if (status === 403) {
        return handle_unauthorized();
    }
    return handle_other_errors(error);
});

function handle_unauthorized() {
    return Promise.reject("You are not authorized to perform this action. Logging you out. Login to refresh your session permissions.");
}

function handle_disconnected(error) {
    return Promise.reject("Please check your internet connection. If your internet appear to be fine, our services may be undergoing maintenance. Please try again in a few minutes.");
}

function handle_session_timeout(error) {
    Auth.logout(() => "");
    return Promise.reject("Your session has expired. Please login to resume your session.");
}

function handle_credentials_problem(error) {
    let ui_error_message = "There was a problem checking these credentials. Please try again.";
    let response_error_message = error.response.data.messages[0];
    if (response_error_message == "not found") {
        ui_error_message = "That username wasn’t found in the system";
    } else if (response_error_message == "Invalid password") {
        ui_error_message = "Invalid password.";
    }
    return Promise.reject(ui_error_message);
}

function handle_pwd_reset_problem(error) {
    if (error.response.data.error === "Invalid token") {
        return Promise.reject("Invalid token");
    } else if (error.response.data.error === "Invalid password") {
        return Promise.reject("Invalid password");
    } else {
        return Promise.reject(error.response.data.error);
    }
}

function handle_other_errors(error) {
    let path = null;
    let model = null;
    let action = null;
    if (
        error.response.request &&
        error.response.request.responseURL &&
        error.response.config &&
        error.response.config.method) {
        path = error.response.request.responseURL.split("platform/")[1];
        model = path.split("?")[0];
        model = model.split('/')[0];
    }
    switch (error.response.config.method) {
        case "put":
            if (model === "device_configs" && error.response.data.messages) {
                error.response.data = error.response.data.messages.join('\n');
                return Promise.reject(error.response.data);
            }
            action = "updating";
            break;
        case "post":
            if (path.indexOf("gateway_command") !== -1) {
                return Promise.reject("There was an error issuing this command. Please try again.");
            } else if (path.indexOf("gateway_csv_upload") !== -1) {
                return Promise.reject("There was an error performing this CSV operation. Please try again.");
            } else if (path.indexOf("/files") !== -1) {
                return Promise.reject("There was an error uploading files for this software package. Please try again.");
            } else {
                if (model === "devices" && error.response.data.messages) {
                    let string = error.response.data.messages[0];
                    let substring = "A Device with unique id";
                    if (string.indexOf(substring) !== -1) {
                        error.response.data = "This device ID already exists. Please choose another one.";
                    } else if (error.response.data.messages[0] === "'unique_id' can only contain lower case letters, numbers or the special charaters '-', ':', or '_'") {
                        error.response.data = "A unique ID can only contain lower case letters, numbers or special characters -, : or _";
                    }
                    return Promise.reject(error.response.data);
                } else if (model === "users" && error.response.data.messages) {
                    let string = error.response.data.messages[0];
                    let substring = "duplicate key error collection";
                    if (string.indexOf(substring) !== -1) {
                        error.response.data = "An error occurred while saving this profile. The email is already in use.";
                    }
                    return Promise.reject(error.response.data);
                } else if (model === "device_configs" && error.response.data.messages) {
                    error.response.data = error.response.data.messages.join("\n");
                    return Promise.reject(error.response.data);
                }
            }
            action = "creating";
            break;
        case "delete":
            action = "deleting";
            break;
        case "get":
            action = "loading";
            break;
    }
    let error_message = map_path_to_model(model, action);
    return Promise.reject(error_message);
}

function map_path_to_model(path, action) {
    let prefix = "There was an error " + action + " ";
    let models = {
        "devices": "device ",
        "user_types": "role ",
        "users": "user ",
        "rules": "policy ",
        "integrations": "integration ",
        "device_types": "device type ",
        "device_errors": "device error ",
        "device_configs": "device config ",
        "software_updates": "software update ",
        "device_ha_groups": "device HA group ",
        "reports": "report ",
        "notifications": "alert ",
        "gateway_commands": "command ",
        "bulk_responses": "bulk job ",
        "companies": "account "
    };
    let postfix = "data. Please refresh the page.";
    if (models[path]) {
        return prefix + models[path] + postfix;
    } else {
        return "An error has occurred. Please refresh the page.";
    }
}

export function readMeIntoAuth(token) {
    const authorization = token;
    function getMyRole(roleId) {
        const options = {
            method: 'GET',
            headers: { 'accept': 'application/json', 'authorization': authorization },
            url: env + 'user_types/' + roleId
        };
        return axios(options);
    }
    function getMyCompany(companyId) {
        const options = {
            method: 'GET',
            headers: { 'accept': 'application/json', 'authorization': authorization },
            url: env + 'companies/' + companyId
        };
        return axios(options);
    }
    return new Promise((resolve, reject) => {
        if (Auth.user() != null) {
            resolve();
        } else {
            const options = {
                method: 'GET',
                headers: { 'accept': 'application/json', 'authorization': authorization },
                url: env + 'me'
            };
            axios(options).then((response) => {
                let me = response.data;
                axios.all([getMyRole(me.user_type_id), getMyCompany(me.company_ids[0])]).then(axios.spread((roleResponse, companyResponse) => {
                    me.nested_company = companyResponse.data;
                    me.nested_user_type = roleResponse.data;
                    me.session_token = token;
                    Auth.setUser(me);
                    resolve();
                })).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        }
    });
}

export function CREATE(model, body, token) {
    const authorization = token;
    const options = {
        method: 'POST',
        headers: { 'accept': 'application/json', 'authorization': authorization },
        data: body,
        url: env + model
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios(options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function LIST(model, params = {}, token) {
    const authorization = token;
    const options = {
        method: 'GET',
        headers: { 'accept': 'application/json', 'authorization': authorization },
        url: env + model
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            params.page_meta = true;
            params = convertArrayParams(params);
            params = "?" + qs.stringify(params, { encode: false });
            options.url += params;
            axios(options).then((response) => {
                let items = response.data.resources || response.data;
                let total = response.data.total || 0;
                if (model === "integrations") {
                    assignIntegrationLogos(items);
                }
                resolve({ items: items, total: total });
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function UPDATE(model, id, body, token) {
    const authorization = token;
    const options = {
        method: 'PUT',
        headers: { 'accept': 'application/json', 'authorization': authorization },
        data: body,
        url: env + model + '/' + id
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios(options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function READ(model, id, token) {
    const authorization = token;
    const options = {
        headers: { 'accept': 'application/json', 'authorization': authorization },
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios.get(`${env + model + "/" + id}`, options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function DELETE(model, id, token) {
    const authorization = token;
    const options = {
        headers: { 'accept': 'application/json', 'authorization': authorization },
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios.delete(`${env + model + "/" + id}`, options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function BULK_EDIT(model, action, body, token) {
    const authorization = token;
    const options = {
        method: '',
        url: `${env}${model}/bulk`,
        headers: { 'accept': 'application/json', 'authorization': authorization },
        data: JSON.stringify(body),
    };
    options.method = action === 'delete' ? 'DELETE' : 'PUT';
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios(options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function BULK_PROMOTE(body, token) {
    const authorization = token;
    const options = {
        method: 'POST',
        url: `${env}discovered_devices/bulk_promote`,
        headers: { 'accept': 'application/json', 'authorization': authorization },
        data: JSON.stringify(body),
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios(options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function EXECUTE_COMMAND(body, model, device_id, command_id, token) {
    const authorization = token;
    const options = {
        method: "POST",
        data: body,
        url: `${env}${model}/${device_id}/commands/${command_id}/execute`,
        headers: {
            'accept': 'application/json',
            'authorization': authorization,
        }
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios(options).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function ISSUE_GATEWAY_COMMAND(body, id, token) {
    const authorization = token;
    const options = {
        method: "POST",
        data: body,
        url: `${env}devices/${id}/gateway_command`,
        headers: {
            'accept': 'application/json',
            'authorization': authorization,
        }
    };
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios(options).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function API_PROXY(options, token) {
    return new Promise((resolve, reject) => {
        if (token) {
            options.headers.authorization = token;
            readMeIntoAuth(token).then(() => {
                axios(options).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        } else {
            axios(options).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        }

    });
}

export function BULK_WORKFLOW(workflow_definition_id, ids, initial_context_user_data, token) {
    if (ids.length > 1000) {
        let id_groups = [];
        var i, j, temparray, chunk = 1000;
        for (i = 0, j = ids.length; i < j; i += chunk) {
            id_groups.push(ids.slice(i, i + chunk));
        }
        let promises = id_groups.map((group) => bulk_action_promise(group));
        return Promise.all(promises);
    } else {
        return bulk_action_promise(ids);
    }
    function bulk_action_promise(ids) {
        const authorization = token;
        const options = {
            method: 'POST',
            url: `${env}devices/bulk_workflow`,
            headers: { 'accept': 'application/json', 'authorization': authorization },
            data: JSON.stringify({
                'definition_id': workflow_definition_id,
                'context': {
                    'user_data': initial_context_user_data
                },
                'ids': ids
            }),
        };
        return new Promise((resolve, reject) => {
            readMeIntoAuth(token).then(() => {
                axios(options).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }
}

export function BULK_COMMAND(model, action, ids, token) {
    if (ids.length > 1000) {
        let id_groups = [];
        var i, j, temparray, chunk = 1000;
        for (i = 0, j = ids.length; i < j; i += chunk) {
            id_groups.push(ids.slice(i, i + chunk));
        }
        let promises = id_groups.map((group) => bulk_action_promise(group));
        return Promise.all(promises);
    } else {
        return bulk_action_promise(ids);
    }
    function bulk_action_promise(ids) {
        const authorization = token;
        const options = {
            method: 'POST',
            url: `${env}${model}/bulk/${action}`,
            headers: { 'accept': 'application/json', 'authorization': authorization },
            data: JSON.stringify({ 'ids': ids }),
        };
        return new Promise((resolve, reject) => {
            readMeIntoAuth(token).then(() => {
                axios(options).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }
}

export const REPORT_ALL_DEVICES = "csv_bulk_download"
export const REPORT_CLOUD_NATIVE_INTEGRATIONS = "cloud_native_integration_report"
export function BUILD_DOWNLOAD_DEVICE_REPORT_URL(reportType, token) {
    let URL = `${env}devices/${reportType}?Authorization=${token}`
    return URL;
}
export const REPORT_DISOVERED_DEVICES = "csv_bulk_download"
export function BUILD_DOWNLOAD_DISCOVERED_DEVICE_REPORT_URL(reportType, token) {
    let URL = `${env}discovered_devices/${reportType}?Authorization=${token}`
    return URL;
}

// `file` is expected to be a File - https://www.w3.org/TR/FileAPI/#dfn-file
export function UPLOAD_FILE(endpoint, fileName, file, token) {
    const authorization = token;
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': authorization
        }
    };
    let fd = new FormData();
    fd.append(fileName, file);
    return new Promise((resolve, reject) => {
        readMeIntoAuth(token).then(() => {
            axios.post(`${env + endpoint}`, fd, options).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

function assignIntegrationLogos(integrations) {
    integrations.forEach((integration) => {
        switch (integration.type) {
            case "aws_device_integrations":
                integration.logo = "aws_icon.jpg";
                break;
            case "azure_device_integrations":
                integration.logo = "azure_logo.png";
                break;
            case "bluemix_device_integrations":
                integration.logo = "bluemix_icon.png";
                break;
            case "twilio_rule_action_integrations":
                integration.logo = "twilio_icon.png";
                break;
            case "postmark_rule_action_integrations":
                integration.logo = "postmark_icon.jpg";
                break;
        }
    });
}

function convertArrayParams(params) {
    let new_params = {};
    Object.entries(params).forEach((param) => {
        let key = param[0];
        let value = param[1];
        if (Array.isArray(value)) {
            let hash = {};
            let id_list = '';
            value.forEach((item) => {
                if (id_list == '') {
                    id_list = item;
                } else if (!hash[item]) {
                    id_list += "," + item;
                }
                hash[item] = true;

            });
            new_params[key] = id_list;
        } else {
            new_params[key] = value;
        }
    });
    return new_params;
}
