import axios from 'axios';

let env = window.API;
//ensures a lack of trailing backslash doesn't break everything.
if (env.charAt(env.length - 1) !== '/') {
	env += "/";
}

class Enquiries {

	static createEscrowDeviceEnquiry(body) {
		return new Promise( (resolve, reject) => {
			var options = {
				method: 'POST',
				url: `${env}enquiries/escrow_device`,
				data: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				json: true
			};
			axios(options).then( () => {
				resolve();
			}).catch( (error) => {
				reject(error);
			});
		});
		
	}

}

export default Enquiries;
