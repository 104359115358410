import React from 'react';
import { Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import classNames from 'classnames';
import {
    get as _get,
    set as _set,
} from 'lodash';

import TextInput from '../Inputs/TextInput';
import {
    get_children_objects_state,
    get_children_objects_half_state,
    get_nested_indexes,
    re_index_objects_array,
    toggle_children_objects_state
} from './helpers';

const mibLinesColors = {
    0: "#1153B6",
    1: "#F27027",
    2: "#0BBD48",
    3: "#AF639A",
};

const newMIBOject = {
    name: "",
    oid: "",
    type: "",
    enabled: true,
};

class MIBForm extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            mib_objects_shown: {},
        }
    }

    handle_mib_change = (input, index, checked) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        if (!new_pollable_attribute.mib) {
            new_pollable_attribute.mib = {
                identity: '',
                objects: [],
            };
        }
        if (index) {
            _set(
                new_pollable_attribute.mib.objects,
                index,
                typeof checked !== 'undefined' ? checked : input.value,
            );
        } else {
            new_pollable_attribute.mib[input.field] = input.value;
        }
        this.props.updateState(new_pollable_attribute);
    }

    add_mib_object = (index) => {
        // console.info("INDEX IN ADD MIB OBJECT: ", index);
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        if (!new_pollable_attribute.mib) {
            // If it is the first object added.
            new_pollable_attribute.mib = {
                identity: '',
                objects: [],
            };
        }
        let objectsIndex = '';
        let newObject = null;
        if (!index) {
            // If no index is passed then it is an object of the root level
            objectsIndex = `objects_${new_pollable_attribute.mib.objects.length}`;
            newObject = { ...newMIBOject };
            newObject[objectsIndex] = [];
            new_pollable_attribute.mib.objects.push(newObject);
        } else {
            const {
                objectsArrayKey,
                objectsArrayIndex,
            } = get_nested_indexes(index);
            const objectsArray = _get(new_pollable_attribute.mib.objects, objectsArrayIndex);
            objectsIndex = `${objectsArrayKey}_${objectsArray.length}`;
            newObject = { ...newMIBOject };
            newObject[objectsIndex] = [];
            _set(new_pollable_attribute.mib.objects, `${objectsArrayIndex}.${objectsArray.length}`, newObject);
        }
        this.props.updateState(new_pollable_attribute);
    };

    remove_mib_object = (index) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        const {
            parentObjectsArrayIndex,
            objectRealIndex,
        } = get_nested_indexes(index);
        if (index === parentObjectsArrayIndex) {
            new_pollable_attribute.mib.objects =
                new_pollable_attribute.mib.objects.filter(
                    (_, index) => index !== objectRealIndex
                );
        } else {
            let objectsArray = _get(new_pollable_attribute.mib.objects, parentObjectsArrayIndex);
            objectsArray = objectsArray.filter((_, index) => index !== objectRealIndex);
            _set(
                new_pollable_attribute.mib.objects,
                index === parentObjectsArrayIndex
                    ? ''
                    : parentObjectsArrayIndex,
                objectsArray,
            );
        }
        re_index_objects_array(new_pollable_attribute.mib.objects);
        this.props.updateState(new_pollable_attribute);
    };

    toggle_mib_children_enabled_state = (objects, index, previousState) => {
        let new_pollable_attribute = this.props.editable_pollable_attribute;
        const { objectsArrayIndex } = get_nested_indexes(index);
        toggle_children_objects_state(objects, index, previousState);
        _set(new_pollable_attribute.mib.objects, objectsArrayIndex, objects);
        console.info(new_pollable_attribute);
        this.props.updateState(new_pollable_attribute);
    };

    toggle_mib_object_view = (index) => {
        let new_mib_objects_shown = this.state.mib_objects_shown;
        if (typeof new_mib_objects_shown[index] === "undefined") {
            new_mib_objects_shown[index] = false;
        } else {
            new_mib_objects_shown[index] = !new_mib_objects_shown[index];
        }
        this.setState({ mib_objects_shown: new_mib_objects_shown });
    };

    render_mib_object = (mibObject, index) => {
        const { classes, validation } = this.props;
        const { mib_objects_shown } = this.state;
        const indexLevels = index?.split(".");
        const {
            objectsArrayKey,
            objectIndex,
        } = get_nested_indexes(index);
        const objects = mibObject[objectsArrayKey];
        const shown = typeof mib_objects_shown[index] === "undefined"
            ? true : mib_objects_shown[index];
        let childrenEnabled = false;
        let indeterminateEnabled = false;
        if (objects && objects.length !== 0) {
            childrenEnabled = get_children_objects_state(objects, index);
            indeterminateEnabled = get_children_objects_half_state(objects, index);
        }
        return (
            <>
                <div className={classes.mibObjectRow}>
                    <div
                        className={classes.mibHorizontalLine}
                        style={{ backgroundColor: mibLinesColors[indexLevels.length - 1] }}>
                    </div>
                    <div className={classes.mibObjectData}>
                        <TextInput
                            label={"Name"}
                            field={"name"}
                            className={classes.mibInput}
                            priorState={mibObject.name}
                            disabled={validation.editable == false || this.editing_disabled}
                            emitChange={(input) => this.handle_mib_change(input, `${objectIndex}.name`)}
                        />
                        <TextInput
                            label={"Type"}
                            field={"type"}
                            className={classes.mibInput}
                            priorState={mibObject.type}
                            disabled={validation.editable == false || this.editing_disabled}
                            emitChange={(input) => this.handle_mib_change(input, `${objectIndex}.type`)}
                        />
                        <TextInput
                            label={"OID"}
                            field={"oid"}
                            className={classes.mibInput}
                            priorState={mibObject.oid}
                            disabled={validation.editable == false || this.editing_disabled}
                            emitChange={(input) => this.handle_mib_change(input, `${objectIndex}.oid`)}
                        />
                    </div>
                    <Tooltip className={classes.removeMibIcon} title="Remove Object">
                        <IconButton
                            color="inherit"
                            root={{ root: classes.removeMibIcon }}
                            onClick={() => this.remove_mib_object(index)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    {objects && objects.length !== 0 && (
                        <Tooltip className={classes.removeMibIcon} title={shown ? "Hide children" : "Show children"}>
                            <IconButton
                                color="inherit"
                                root={{ root: classes.removeMibIcon }}
                                onClick={() => this.toggle_mib_object_view(index)}>
                                {shown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                <div className={classes.mibObjectRow}>
                    <FormControlLabel
                        className={classes.formControlLabel}
                        control={
                            <Checkbox
                                color="primary"
                                checked={mibObject.enabled}
                                onChange={() => this.handle_mib_change(
                                    null,
                                    `${objectIndex}.enabled`,
                                    !mibObject.enabled
                                )}
                            />
                        }
                        key={`mib-object-${index}-enabled`}
                        label="Enabled"
                    />
                    {objects && objects.length !== 0 && (
                        <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={childrenEnabled}
                                    indeterminate={!childrenEnabled && indeterminateEnabled}
                                    onChange={() => this.toggle_mib_children_enabled_state(
                                        objects,
                                        index,
                                        childrenEnabled
                                    )}
                                />
                            }
                            key={`mib-object-${index}-enable-children`}
                            label={`${childrenEnabled ? 'Disable' : 'Enable'} children`}
                        />
                    )}
                </div>
                {objects && objects.length !== 0 && (
                    <div
                        className={classNames(classes.mibLine, classes.mibSubLine)}
                        style={{ backgroundColor: mibLinesColors[indexLevels.length] }}>
                    </div>
                )}
                {shown && objects?.map((mibSubObject, subIndex) => (
                    <div
                        className={classes.mibObjectContainer}
                        key={`mib-object-${index}-${subIndex}`}
                    >
                        {this.render_mib_object(mibSubObject, `${index}.${subIndex}`)}
                    </div>
                ))}
                {this.props.disabled || indexLevels.length >= 4
                    ? "" :
                    <Button
                        className={classes.mibButton}
                        variant="outlined"
                        color="primary"
                        onClick={() => this.add_mib_object(index)}>
                        <AddIcon />
                        New object
                    </Button>}
            </>
        );
    };

    render() {
        const {
            classes,
            validation,
            editable_pollable_attribute,
        } = this.props;
        const { mib } = editable_pollable_attribute;
        return (
            <div className={classes.sectionContainer}>
                <div className={classes.mibLabel}>
                    MIB
                </div>
                <TextInput
                    margin="none"
                    disabled={validation.editable == false || this.editing_disabled}
                    emitChange={this.handle_mib_change}
                    priorState={editable_pollable_attribute.mib?.identity}
                    label={"Identity"}
                    field={"identity"}
                    className={classes.mibMainInput}
                />
                <div className={classes.mibObjectsContainer}>
                    <div className={classNames(classes.attributeLabel, classes.mibLabel)}>
                        Objects
                    </div>
                    {mib && mib?.objects.length !== 0 && (
                        <div
                            className={classes.mibLine}
                            style={{ backgroundColor: mibLinesColors[0] }}>
                        </div>
                    )}
                    {mib?.objects.map((mibObject, index) => (
                        <div
                            className={classes.mibObjectContainer}
                            key={`mib-object-${index}`}
                        >
                            {this.render_mib_object(mibObject, `${index}`)}
                        </div>
                    ))}
                    {this.props.disabled
                        ? "" :
                        <Button
                            className={classes.mibButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.add_mib_object()}>
                            <AddIcon />
                            New object
                        </Button>}
                </div>
            </div>
        );
    }
}

export default MIBForm;