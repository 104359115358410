import DataModel from "./DataModel";
import Humanize from "../Humanize";
import { GetAll } from '../CLURDUtilities';

class Integration extends DataModel {

	constructor(data = blank) {
		super("integrations", data);
	}

	listAllFromAPI() {
		return GetAll(this.endpoint);
	}

	loadIntegrationTypesAndOptions() {
		return new Promise( (resolve, reject) => {
			new DataModel("integrations/types")
			.listFromAPI()
			.then( (results) => {
				let integrationTypesJSON = results.items;
				let integrationTypes = integrationTypesJSON.reduce((map, typeJSON) => {
					map[typeJSON.type] = typeJSON;
					return map;
				}, {});
				let integrationOptions = this.buildIntegrationOptions(integrationTypes);
				resolve({integrationTypes: integrationTypes, integrationOptions: integrationOptions});
			})
			.catch( (error) => {
				reject(error);
			});
		});
	}

	static getIntegrationTypes(params) {
		return new Promise( (resolve, reject) => {
			new DataModel("integrations/types")
			.listFromAPI(params)
			.then( ({items}) => {
				resolve(items);
			}).catch( (error) => {
				reject(error);
			});
		});
	}

	buildIntegrationOptions(integrationTypes) {
		let integrationOptions = [];
		if (integrationTypes != null) {
			let types = Object.keys(integrationTypes);
			integrationOptions = types.map((key) => {
				return {display: integrationTypes[key].readable_type, value: key};
			});
		}
		integrationOptions.unshift({display:'Choose an Integration Type', value:''});
		return integrationOptions;
	}

	static assignIntegrationLogo(integration) {
		switch (integration.type) {
			case "aws_device_integrations":
				integration.logo = "aws_icon.jpg";
				break;
			case "azure_device_integrations":
				integration.logo = "azure_logo.png";
				break;
			case "bluemix_device_integrations":
				integration.logo = "bluemix_icon.png";
				break;
			case "twilio_rule_action_integrations":
				integration.logo = "twilio_icon.png";
				break;
			case "postmark_rule_action_integrations":
				integration.logo = "postmark_icon.jpg";
				break;
			case "gcp_cloud_native_device_integrations":
				integration.logo = "gcp.png";
				break;
			default:
				integration.logo = "integration_placeholder.png";
				break;
		}
		return integration;
	}

	static validate(editableCopy, integrationTypes) {
		let validationMessages = {};
		let o = editableCopy;
		if (o.name == null || o.name.length == 0) {
			validationMessages.name = ["Name is required"];
		}
		if (o.type == null || o.type.length == 0) {
			validationMessages.type = ["Type is required"];
		}
		let integrationType = editableCopy.type;
		let type = integrationTypes[integrationType];
		if (type) {
			let typeFields = type.fields;
			typeFields.forEach((f) => {
				if (f === "greengrass_core_install_url") {
					if (o[f] === "") {
						validationMessages[f] = ["Please specify a Greengrass installer package URL."];
					}
				} else if (f === "role_arn") {
					if (!o[f] || o[f].length < 20) {
						validationMessages[f] = ["The Role ARN must be at least 20 characters long."];
					}
				// TODO: I am flying blind here until the staging endpoint is updated to include greengrass_version, but choices are limited through dropdown anyway
				} else if (f === "greengrass_version") {
					if (!(o[f] === "v1" || o[f] === "v2")) {
						validationMessages[f] = ["The Greengrass version must be 'v1' or 'v2'."];
					}
				} else {
					if (o[f] == null || o[f].length == 0) {
						validationMessages[f] = [`${Humanize.humanizeSnakeCaseString(f)} is required`];
					}
				}
			});
		}

		if (editableCopy._id != null && type.device_integration_type) {
			validationMessages.integration = ["Integrations of this type cannot be edited."]
			validationMessages.editable = false;
		}	
		if (Object.keys(validationMessages).length > 0) {
			validationMessages.valid = false;
		} else {
			validationMessages.valid = true;
		}
		return validationMessages;
	}

}

const blank = {
	name:'',
	type:'',
	long_description:'',
	
	// AWS
	region:null,
	external_id:null,
	role_arn:null,
	device_gateway_url:null,
	certificate_gateway_url:null,
	keys_and_certificate:null,
	greengrass_core_install_url: '',
	greengrass_version: 'v2',

	// Azure
	iot_hub_hostname:null,
	shared_access_key:null,
	access_policy_name:null,
	sas_token:null,
	
	// Bluemix
	org_id:null,
	api_key:null,
	auth_token:null,
	
	// Twilio / Postmark
	sid:null,
	token:null,
	from:null,
	callback_to_machineshop:null,
	
	// SMTP / PRTG
	server:null,
	username:null,
	password:null,
	
	// PRTG
	identification_token:null
};

export default Integration;
