import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Permissions from '../../services/Permissions';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import MetadataEditor from '../common/MetadataEditor/MetadataEditorComponent'
import Device from '../../services/DataModels/Device';

class DeviceMetadata extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			show_errors: false
		};
		this.can_edit = Permissions.allow(["update"], "device", this.props.device.company_id);
	}

	submitDeviceMetadata = (new_device) => {
        new Device(new_device).saveOrCreate().then( (result) => {
        	this.context.openSnackbar("Metadata saved successfully.", "success");
        	this.props.tabHostProxy.closeSelf();
        	this.props.tabHostProxy.addTab("device", result);
        	this.props.tabHostProxy.refresh();
        }).catch( (error) => {
        	this.context.openSnackbar(error, "error");
        });
	}

	render() {
		const { device } = this.props;
		return (<MetadataEditor model={device} can_edit={this.can_edit} submitMetadata={this.submitDeviceMetadata}/>);
	}
}

const styles = theme => ({});

DeviceMetadata.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceMetadata));