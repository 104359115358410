import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import ReportsList from '../../MessagesSpecific/ReportsList'
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	},
	filterWrapper: {
		paddingTop: "15px"
	}
};

class MessagesTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
	}

	render() {
		let { tabHostProxy, classes } = this.props;

		return (
			<div className={classes.container}>
				<ReportsList
					tabHostProxy={tabHostProxy}
				/>
			</div>
		);
	}
}

MessagesTab.contextType = SnackbarContext;
export default withStyles(styles)(MessagesTab);
