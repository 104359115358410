import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Loading from '../DisplayOriented/Loading';
import Paper from '@material-ui/core/Paper';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

//icons
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

//todo no bueno font sizes, use different unit
const styles = theme => ({
	container: {
		display: "inline-flex",
		overflowY: "auto",
		width: "100%",
		height: "33vh",
		padding: "12px",
		flexWrap: "wrap",
		marginTop: "24px",
		boxSizing: "border-box",
		alignItems: "flex-start",
		position: "relative"
	},
	filterMenu: {
		overflow: "visible"
	},
	menuWrapper: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 1,
		padding: "6px"
	},
	subMenuWrapper: {
		position: "absolute",
		top: 0,
		maxHeight: "100vh",
		overflowY: "auto"
	},
	chipsWrapper: {
		display: "inline",
	},
	chipRoot: {
		margin: "0 4px 4px 0"
	},
	subMenu: {

	},
	subMenuItem: {
		height: "45px"
	},
	filterMenuItem: {
		width: "inherit",
	},
	menuItemActive: {
		backgroundColor: "rgba(0, 0, 0, 0.08)"
	},
	menuItemContainer: {
		position: "relative"
	},
	menuOverride: {
		zIndex: 1301
	},
	menuIconButton: {
		padding: "6px"
	},
	filterListTitle: {
		marginBottom: "8px",
		padding: "11px 16px",
		borderBottom: "solid #80808073 1px",
		backgroundColor: "white",
		cursor: "unset",
		'&:hover': {
			backgroundColor: "white",
			cursor: "unset",
		},
		outline: "none",
		fontFamily: "Inter",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "1rem",
		width: "auto",
		height: "24px",
		whiteSpace: "nowrap",
		boxSizing: "content-box",
		fontWeight: 400,
		lineHeight: "1.5em"
	},
	arrowRight: {
		marginLeft: "auto"
	},
	ctaWrapper: {
		display: "flex",
		textTransform: "uppercase",
		alignItems: "center",
		bottom: "10px",
		fontSize: "1.3vw",
		right: "10px",
		position: "absolute",
		color: theme.palette.pending.main,
		cursor: "pointer",
		"&:hover": {
			"&:first-child": {
				textDecoration: "underline"
			}
		}
	},
	ctaArrow: {
		fontSize: "1.5vw",
		marginLeft: "10px"
	},
	titleWrapper: {
		color: "#7c7c7c",
		fontSize: "1.5vw",
		paddingBottom: "4px",
		width: "100%",
		textTransform: "capitalize",
		position: "relative",
		marignRight: "36px"
	},
	title: {
		display: "inline",
		marginRight: "12px"
	},
	defaultLabelStyle: {
		fontSize: '12px',
		fontFamily: 'sans-serif',
		textTransform: "capitalize"
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'sans-serif',
		fill: "#8080806e",
		textTransform: "capitalize"
	},
	pieChartWrapper: {
		display: "flex",
		height: "calc(100% - 54px)",
		width: "66%",
		margin: "auto",
		position: "relative"
	},
	labelText: {
		position: "absolute",
		paddingTop: "6px",
		height: "50%",
		top: "50%",
		bottom: 0,
		left: "33%",
		right: "33%",
		textAlign: "center",
		textTransform: "capitalize",
		color: "grey",
		boxSizing: "border-box",
		width: "33%",
		fontSize: "1.3vw"
	},
	labelValue: {
		position: "absolute",
		bottom: "50%",
		left: "33%",
		right: "33%",
		textAlign: "center",
		textTransform: "capitalize",
		boxSizing: "border-box",
		width: "33%",
		fontSize: "2.8em"
	},
	pieChartKeyWrapper: {
		width: "33%",
		display: "flex",
		flexWrap: "wrap",
		fontSize: "1.5vw",
		alignItems: "flex-start",
		height: "calc(100% - 40px)"
	},
	pieChartKeyTitle: {
		width: "100%",
		fontSize: "1.5vw",
		color: "#7c7c7c",
		borderBottom: "solid #7c7c7c8a 1px",
		paddingBottom: "8px",
	},
	pieChartKeyItem: {
		width: "100%",
		display: "flex",
		fontSize: "1.5vw",
		flexWrap: "nowrap",
		alignItems: "center",
		color: "#7c7c7c",
		margin: "8px 0",
		textTransform: "capitalize"
	},
	keyItemsWrapper: {
		overflowY: "scroll",
		height: "100%",
		maxHeight: "calc(100% - 44px)",
		marginTop: "-4px"
	},
	pieChartKeyColor: {
		borderRadius: '50%',
		width: "20px",
		minWidth: "20px",
		height: "20px",
		marginRight: "8px",
		marginTop: "auto",
		marginBottom: "auto"
	}
});

class PieGraph extends React.Component {
	
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			anchorEl: null,
			data: null,
			label: null,
			chips: [],
			subs: {},
			refs: []
		};
		let params = undefined;
		if (this.props.initial_chip === true) {
			this.state.chips = [{filter_name: this.props.filters[0].title, can_delete: false, option: {value: this.props.filters[0].options[0].value, name: this.props.filters[0].options[0].name} }];
			params = this.state.chips;
		}
		if (this.props.filters) {
			this.props.filters.forEach( (filter) => {
				this.state.subs[filter.title] = null;
				this.state.refs.push(React.createRef());
			});
		}
		this.props.refresh(params)
			.then( (result) => {
				this.setState({data: result.data, label: result.label});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	format_pie_text = (text) => {
		if (text.length > 18 ) {
			return text.slice(0, 18) + "...";
		} else {
			return text;
		}
	}

	change_label = (event, data, data_index) => {
		let new_label = { color: data[data_index].color, value: data[data_index].value, text: data[data_index].text};
		this.setState({label: new_label});
	}

	open_filter_menu = (event) => {
		this.setState({ anchorEl: event.currentTarget});
	};

	close_filter_menu = () => {
		let new_subs = Object.entries(this.state.subs).reduce( (total, [key]) => {
			total[key] = null;
			return total;
		}, {});
		this.setState({ anchorEl: null, subs: new_subs });
	};

	open_filter_submenu = (event, filter_title) => {
		let new_subs = {};
		this.props.filters.forEach( (filter) => {
			if (filter.title !== filter_title) {
				new_subs[filter.title] = null;
			} else {
				new_subs[filter.title] = event.currentTarget;
			}
		});
		this.setState({subs: new_subs});
	};

	render() {
		const { filters, classes, title, key_title, cta} = this.props;
		const { data, label, chips, anchorEl } = this.state;
		const total = data ? data.reduce( (total, section) => total + section.value, 0) : null;
		const open = Boolean(anchorEl);
		return (
			<Paper className={classes.container}>
				{(filters && filters.length > 0) ?
					<div className={classes.menuWrapper}>
						<div className={classes.menuIconWrapper}>
							<IconButton
								aria-label="More"
								aria-owns={open ? 'long-menu' : undefined}
								aria-haspopup="true"
								onClick={(event) => this.open_filter_menu(event)}
								className={classes.menuIconButton}
							>
								<FilterListIcon className={classes.menuIcon}/>
							</IconButton>
						</div>
						{this.render_filter_menu(open, anchorEl, classes)}
					</div>
					: ""
				}
				<div className={classes.titleWrapper}>
					<div className={classes.title}>{title}</div>
					<div className={classes.chipsWrapper}>
						{chips.map( (chip) => (
							<Chip clickable={false} classes={{root: classes.chipRoot}} key={chip.option.value} label={chip.option.name} onDelete={(false === chip.can_delete) ? undefined : () => this.remove_chip(chip)}/>
						))}
					</div>
				</div>
				<div className={classes.pieChartWrapper}>
					{data === null ?
						<Loading />
					:
						total === 0 ?
							<ReactMinimalPieChart
								animate
								style={{width: "100%"}}
								data={[{value: 1, color: "#8080806e"}]}
								totalValue={1}
								lineWidth={20}
								label={() => "No Data"}
								labelPosition={0}
								segmentsStyle={{cursor: "pointer"}}
							/>
						:
							<React.Fragment>
								<ReactMinimalPieChart
									animate
									style={{width: "100%"}}
									data={data}
									totalValue={total}
									lineWidth={30}
									label={false}
									labelStyle={{fill: label.color}}
									onClick={this.change_label}
									labelPosition={0}
									segmentsStyle={{cursor: "pointer"}}
								/>
								<div className={classes.labelValue} style={{color: label.color}}>
									{label.value}
								</div>
								<div className={classes.labelText}>
									{this.format_pie_text(label.text)}
								</div>
							</React.Fragment>
					}
				</div>
				<div className={classes.pieChartKeyWrapper}>
					<div className={classes.pieChartKeyTitle}>
						{key_title}
					</div>
					<div className={classes.keyItemsWrapper}>
						{data === null ?
							<Loading />
						:
							data.map( (section, index) => (
								<div key={section.text + "_" + index} className={classes.pieChartKeyItem}>
									<div style={{backgroundColor: section.color}} className={classes.pieChartKeyColor}>
										&nbsp;
									</div>
									{section.text}
								</div>
							))
						}
					</div>
				</div>
				{/*onClick={() => this.props.history.push(cta.link)} */}
				{cta ?
					<Link to={cta.link}>
						<div className={classes.ctaWrapper} >
							
							<div className={classes.ctaText}>
								{cta.display}
							</div>
							<ArrowForwardIcon className={classes.ctaArrow}/>
						</div>
					</Link>
				:
					""
				}
			</Paper>
		);
	}

	render_filter_menu = (open, anchorEl, classes) => {
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={this.close_filter_menu}
				PaperProps={{
					style: {overflow: "visible"}
				}}
			>
				<div className={classes.filterListTitle}>
					Filter...
				</div>
				{this.props.filters.map( (filter, index) => {
					const anchorSubEl = this.state.subs[filter.title];
					const subOpen = Boolean(anchorSubEl);
					return (
						<div ref={this.state.refs[index]} key={filter.title} className={classes.menuItemContainer}>
							<MenuItem className={classes.filterMenuItem + " " + (subOpen ? classes.menuItemActive : "")} onMouseOver={(event) => (subOpen === false && open === true) ? this.open_filter_submenu(event, filter.title) : ""} >
								<React.Fragment>
									{filter.title}
									<ArrowRightIcon className={classes.arrowRight}/>
								</React.Fragment>
							</MenuItem>
							{this.render_filter_submenu(subOpen, anchorSubEl, filter, classes, index)}
						</div>
					);
				})}
			</Menu>
		);
	}

	render_filter_submenu = (open, anchorEl, filter, classes, index) => {
		let space = 0;
		let displace = 0;
		let left = undefined;
		let right = undefined;
		let location_y = 0;
		let location_x = 0;
		let window_width = 0;
		let subMenuHeight = filter.options.length * 45;
		if (this.state.refs[index] && this.state.refs[index].current) {
			let window_height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			window_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
			location_y = Math.ceil(this.state.refs[index].current.getBoundingClientRect().top);
			location_x = Math.ceil(this.state.refs[index].current.getBoundingClientRect().right);
			space = window_height - location_y;
		}
		if (subMenuHeight > space) {
			displace = -1 * location_y;
		}
		let menu_width = anchorEl ? window.getComputedStyle(anchorEl).width : 0;
		if (typeof menu_width === "string" && menu_width.indexOf("px") !== -1) {
			left = 36 + parseInt(menu_width.split("px"));
			if (location_x > (window_width / 2 + 300)) {
				left = undefined;
				right = 36 + parseInt(menu_width.split("px"));
			}
		} else if (menu_width !== 0) {
			open = false;
			left = 0;
		}
		
		if (open) {
			return (
				<Paper
					id="long-menu"
					elevation={2}
					className={classes.subMenuWrapper}
					style={{left: left, top: displace, right: right}}
				>
					{filter.options.map( (option, index) => {
						return (
							<MenuItem classes={{root: classes.subMenuItem}} onClick={() => this.filter(filter.title, option)} key={option.value}>
								{option.name}
							</MenuItem>
						);
					})}
				</Paper>
			);
		}
	}

	filter = (filter, option) => {
		this.close_filter_menu();
		let limit = Boolean(this.props.filters.find((current_filter) => current_filter.title === filter).limit);
		if (limit) {
			let new_chips = this.state.chips.filter( (current_chip) => current_chip.filter_name !== filter);
			new_chips.push({filter_name: filter, option: option, can_delete: false});
			this.setState({chips: new_chips}, () => {
				this.props.refresh(new_chips)
					.then( (result) => {
						this.setState({data: result.data, label: result.label});
					})
					.catch( (error) => {
						console.log(error);
					});
			});
		} else {
			if (this.state.chips.length > 0 ) {
				for (let i = 0; i < this.state.chips.length; i++) {
					let chip = this.state.chips[i];
					if (chip.filter_name === filter) {
						if (chip.option.value === option.value) {
							return;
						}
					}
				}
			}
			let new_chips = this.state.chips
			new_chips.push({filter_name: filter, option: option});
			this.setState({chips: new_chips}, () => {
				this.props.refresh(new_chips)
					.then( (result) => {
						this.setState({data: result.data, label: result.label});
					})
					.catch( (error) => {
						console.log(error);
					});
			});
		}
		
	}

	remove_chip = (chip) => {
		let new_chips = this.state.chips;
		let index = this.state.chips.findIndex( (current_chip) => {
			if (current_chip.filter_name === chip.filter_name) {
				if (current_chip.option.value === chip.option.value) {
					return true;
				}
			}
			return false;
		});
		new_chips.splice(index, 1);
		this.setState({chips: new_chips}, () => {
			this.props.refresh(new_chips)
				.then( (result) => {
					this.setState({data: result.data, label: result.label});
				})
				.catch( (error) => {
					this.context.openSnackbar(error, "error");
				});
		});
	}

}


PieGraph.contextType = SnackbarContext;
export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)
(PieGraph);