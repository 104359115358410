import React from 'react';
import { compose } from 'recompose';
import Loading from '../DisplayOriented/Loading';

//inputs
import SelectInput from '../Inputs/SelectInput';

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import DeviceTransferRequest from '../../services/DataModels/DeviceTransferRequest';

//icons
import DvrIcon from '@material-ui/icons/DvrOutlined';

class IssueTransferForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			device_type_id: '',
			device_type_options: null
		};
		this.load_device_types();
	}

	load_device_types = () => {
		GetAll("device_types").then( (result) => {
			let device_types = result || [];
			device_types.push({name: "Select a Device Type", _id: ''});
			this.setState({device_type_options: device_types.map( (type) => ({display: type.name, value: type._id}) )});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_device_type = () => {
		const { classes } = this.props;
		const { device_type_id, device_type_options, show_errors } = this.state;
		let input = (<SelectInput
			label="Device Type"
			field="device_type_id"
			emitChange={this.on_form_change}
			priorState={device_type_id}
			options={device_type_options}
		/>);
		return (
			<div className={classes.textInputWrapper + " " + classes.typeInputWrapper}>
				<DvrIcon className={classes.inputIcon}/>
				{input}
			</div>
		);
	}

	on_form_change = ({value}) => {
		this.setState( (state) => {
			state.device_type_id = value;
			return state;
		});
	}

	render_title = () => {
		const classes = this.props.classes;
		return (
			<React.Fragment>
				<div className={classes.modalTitle}>
					Accepting a Device Transfer
				</div>
				<div className={classes.modalSubtitle}>
					Choose a device type for the devices being transferred to your account. If you don't choose a device type a type will be automatically created and assigned to these devices.
				</div>
			</React.Fragment>
			
		);
	}

	render_buttons = () => {
		const { onClose, classes } = this.props;
		return (
			<div className={classes.buttonContainer}>
				<Button onClick={onClose} className={classes.button} aria-label="cancel">
					Cancel
				</Button>
				<Button onClick={this.submit} variant="contained" classes={{root: classes.buttonOverride}} className={classes.button} aria-label="create" color="primary">
					Issue Transfer Request
				</Button>
			</div>
		);
	}

	submit = () => {
		const device_type_id = this.state.device_type_id;
		const id = this.props.transferRequest._id;
		DeviceTransferRequest.acceptTransfer(id, device_type_id).then( (result) => {
			this.context.openSnackbar("Transfer request succesfully accepted.", "success");
			this.props.onComplete();
		}).catch ( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}
		
	render() {
		const { classes } = this.props;
		const { submitting, device_type_options } = this.state;
		if (submitting || device_type_options == null ) {
			return (
				<div className={classes.container}>
					<Loading />
				</div>
			);
		}
		return (
			<div className={classes.container}>
				{this.render_title()}
				{this.render_device_type()}
				{this.render_buttons()}
			</div>
		);
	}
}

const styles = (theme) => {
	return ({
		container: {
			padding: "24px",
			fontFamily: "Inter",
			boxSizing: "border-box",
			width: "66vw"
		},
		modalTitle: {
			fontSize: "24px",
			marginBottom: "12px"
		},
		modalSubtitle: {
			color: "#1a1a1ab0",
			marginBottom: "24px"
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		typeInputWrapper: {
			marginTop: "32px",
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		buttonContainer: {
			marginTop: "62px",
			display: "flex",
			justifyContent: "flex-end"
		},
		button: {
			marginRight: "8px",
			textTransform: "unset"
		},
		buttonOverride: {
			textTransform: "unset",
			boxShadow: "none",
			color: "white",
			backgroundColor: theme.palette.pending.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.pending.main, .2),
				borderColor: darken(theme.palette.pending.main, .2),
			},
			cursor: "pointer"
		},
	});
}

IssueTransferForm.contextType = SnackbarContext;

export default compose(
	withStyles(styles),
)(IssueTransferForm);