import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import { darken } from '@material-ui/core/styles/colorManipulator';
import InfoIcon from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	root: {

	},
	container: {
		width: "100%",
	},
	shaded: {
		backgroundColor: "#fafbfb",
	},
	titleContainer: {
		width: "100%",
		borderBottom: "solid lightgrey 1px",
		backgroundColor: "white",
		fontFamily: "Inter",
		padding: "12px 0",
		textTransform: "uppercase",
		minHeight: "40px",
		display: "flex",
		color: "grey",
		alignItems: "center"
	},
	title: {
		marginLeft: "12px",
		display: "flex",
		flex: "50%",
	},
	buttonWrapper: {
		padding: 0
	},
	buttonLabel: {
		whiteSpace: "nowrap",
		alignItems: "center"
	},
	buttonIcon: {
		marginRight: "8px",
		display: "flex"
	},
	splitFab: {
		maxHeight: "40px"
	},
	fabButton: {
		borderLeft: `solid ${darken(theme.palette.primary.main, .2)} 2px`,
	},
	lastButton: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0
	},
	middleButton: {
		borderRadius: 0
	},
	firstButton: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	infoBlurb: {
		display: "flex",
		margin: "0 12px",
	},
	arrowPopper: {
		'&[x-placement*="bottom"] $arrowArrow': {
			top: 0,
			left: 0,
			marginTop: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '0 1em 1em 1em',
				borderColor: `transparent transparent ${theme.palette.grey[700]} transparent`,
			},
		},
		'&[x-placement*="top"] $arrowArrow': {
			bottom: 0,
			left: 0,
			marginBottom: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '1em 1em 0 1em',
				borderColor: `${theme.palette.grey[700]} transparent transparent transparent`,
			},
		},
		'&[x-placement*="right"] $arrowArrow': {
			left: 0,
			marginLeft: '-0.9em',
			height: '3em',
			width: '1em',
			'&::before': {
				borderWidth: '1em 1em 1em 0',
				borderColor: `transparent ${theme.palette.grey[700]} transparent transparent`,
			},
		},
		'&[x-placement*="left"] $arrowArrow': {
			right: 0,
			marginRight: '-0.9em',
			height: '3em',
			width: '1em',
			'&::before': {
				borderWidth: '1em 0 1em 1em',
				borderColor: `transparent transparent transparent ${theme.palette.grey[700]}`,
			},
		},
	},
	arrowArrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
	tooltip: {
		fontSize: "16px",
	}

});

class CardContainer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			arrowRef: null
		}
	}

	handleArrowRef = node => {
		this.setState({
			arrowRef: node,
		});
	};
	
	//todo utilize the title icon and actions dropdown
	render() {
		const {title, infoText, buttons, actions, children, shaded, classes, rootClass} = this.props;
		return (
			<Card className={(rootClass || "") + " " + classes.container + (shaded ? " " + classes.shaded : "")}>
				<div className={classes.titleContainer}>
					<span className={classes.title}>{title}</span>
					{buttons && buttons.length ? buttons.map( (button, index) => 
						<div key={"pagination_button_" + index} className={classes.buttonWrapper}>
							<Button classes={{label: classes.buttonLabel}} className={classes.fabButton + " " + ( index === 0 ? ( buttons.length > 1 ? classes.firstButton : ""): (index === buttons.length - 1 ? classes.lastButton: classes.middleButton))} onClick={button.function} variant="contained" color="primary">
								<span className={classes.buttonIcon}>{button.icon}</span>
								{button.display}
							</Button>
						</div>
					) : ""}
					{infoText && infoText !== "" ? 
						<Tooltip
							title={
								<React.Fragment>
									{infoText}
									<span className={classes.arrowArrow} ref={this.handleArrowRef} />
								</React.Fragment>
							}
							classes={{ popper: classes.arrowPopper, tooltip: classes.tooltip }}
							PopperProps={{
								popperOptions: {
									modifiers: {
										arrow: {
											enabled: Boolean(this.state.arrowRef),
											element: this.state.arrowRef,
										},
									},
								},
							}}
						>
							<InfoIcon className={classes.infoBlurb}/>
						</Tooltip>
					: ""}
					{actions && actions.length > 0 ?
						<MoreVertIcon />
					: ""}
				</div>
				{children}
			</Card>
		);
	}
}

export default withStyles(styles)(withTheme()(CardContainer));
