import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import MemoryIcon from '@material-ui/icons/Memory';
import ErrorIcon from '@material-ui/icons/Error';
import { AssignNestedModels } from '../../services/CLURDUtilities';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import PaginationContainer from '../Containers/PaginationContainer';
import Notification from '../../services/DataModels/Notification';
import Permissions from '../../services/Permissions';
import Paper from '@material-ui/core/Paper';
import TableList from '../Table/TableList';
import Loading from '../DisplayOriented/Loading';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const heading_info = [
	{ label: "Created At", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date" },
	{ label: "Message", value: "table_message", field: "table_message", align: "left", disablePadding: false, sortable: false, content: "function" },
	{ label: "Policy", field: "table_rule", align: "left", disablePadding: false, sortable: false, content: "function" },
	{ label: "Account", field: "company_id", align: "left", disablePadding: false, sortable: true },
];

const styles = theme => ({
	container: {
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
	},
	paginationContainer: {
		width: "100%"
	},
	alertsContainer: {
		overflowY: "auto",
		height: "calc(100% - 57px)",
		width: "100%",
		backgroundColor: "white"
	},
	alertContainer: {
		display: "flex",
		margin: "12px",
	},
	left: {
		width: "15%",
		textAlign: "center"
	},
	date: {
		fontSize: "14px",
		fontWeight: 500
	},
	time: {
		fontSize: "14px",
		color: "#8e8e93",
	},
	right: {
		width: "80%",
		padding: "12px",
		backgroundColor: "#f1f1f1",
		borderRadius: "5px"
	},
	alertIcon: {
		fontSize: "32px",
		color: "white",
		padding: "12px",
		borderRadius: "4px",
	},
	green: {
		backgroundColor: "#009688"
	},
	red: {
		backgroundColor: "#f60362"
	},
	blue: {
		backgroundColor: "#32419B"
	},
	line: {
		height: "40px",
		width: "1px",
		margin: "10px auto 0 auto",
		backgroundColor: "grey"
	},
	message: {
		color: "grey",
	},
	linkRow: {
		display: "flex",
		marginTop: "12px",
		marginLeft: "12px",
	},
	linkIcon: {
		color: "grey"
	},
	linkText: {
		color: theme.palette.pending.main,
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": theme.palette.pending.main,
			textDecorationColor: theme.palette.pending.main,
			cursor: "pointer",
		},
		marginLeft: "12px",
	},
	unread: {
		display: "flex",
		fontSize: "12px",
		alignItems: "center"
	},
	read: {
		color: "grey",
		display: "flex",
		fontSize: "12px",
		alignItems: "center"
	},
	unreadIcon: {
		fontSize: "18px",
		color: "red",
		marginRight: "4px",
	},
	categoryContainer: {
		position: "relative",
	},
	categoryLabel: {
		color: "white",
		position: "absolute",
		fontSize: "8px",
		margin: "0 auto",
		width: "100%",
		bottom: "5px"
	},
	none: {
		margin: "12px",
	}
});

class DeviceAlerts extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			alerts: null,
			pagination: {
				per_page: 12,
				current_page: 1,
				total: 0
			},
			device_id: this.props.device_id,
			layout: "card",
			sort: null
		};
		this.loadDeviceAlerts();
	}

	render_message = (alert) => {
		let formatted = alert.message;
		let section = `ObjectIdHex("${alert.device_id}")`;
		if (alert.message.indexOf(section) !== -1) {
			formatted = this.props.device.name + " " + alert.message.split(section)[1]
		}
		return formatted;
	}

	loadDeviceAlerts = () => {
		let params = {
			device_id: this.props.device_id,
			page_meta: true,
			per_page: this.state.pagination.per_page,
			page: this.state.pagination.current_page
		};
		params = Object.assign(params, this.state.sort);
		new Notification().listFromAPI(params).then( (response) => {
			let alerts = response.items;
			if (Permissions.allow(["read"], "rule", this.props.device.company_id)) {
				AssignNestedModels("rules", "rule_id", alerts).then( () => {
					alerts.forEach( (alert) => {
						alert.table_rule = () => (alert.nested_rule ? <div onClick={() => this.props.tabHostProxy.addTab("rule", alert.nested_rule)} style={{cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}}>{alert.nested_rule.description}</div> : <div>Policy Unavailable</div>);
						alert.table_message = () => this.render_message(alert);
					});
					this.setState({ 
						alerts: alerts,
						pagination: {
							per_page: this.state.pagination.per_page,
							current_page: this.state.pagination.current_page,
							total: response.total
						} 
					});
				});
			} else {
				this.setState({ 
					alerts: alerts,
					pagination: {
						per_page: this.state.pagination.per_page,
						current_page: this.state.pagination.current_page,
						total: response.total
					} 
				});
			}
			
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	openRuleTab = (alert) => {
		if (alert.nested_rule) {
			this.props.tabHostProxy.addTab("rule", alert.nested_rule);
		}
	}

	handleChangePage = (event, page) => {
		this.setState({ 
			alerts: null,
			pagination: {
				per_page: this.state.pagination.per_page,
				current_page: page + 1,
				total:  this.state.pagination.total
			} 
		}, this.loadDeviceAlerts);
	};

	handleChangeRowsPerPage = event => {
		this.setState({ 
			alerts: null,
			pagination: {
				per_page: event.target.value,
				current_page: this.state.pagination.current_page,
				total: this.state.pagination.total
			} 
		}, this.loadDeviceAlerts);
	};

	renderRule = (alert, classes) => {
		if (alert.nested_rule && Permissions.allow(["read"], "rule", alert.company_id) && alert.nested_rule) {
			return (
				<div className={classes.linkRow}>
					<SecurityIcon className={classes.linkIcon} />
					<div onClick={() => this.openRuleTab(alert)} className={classes.linkText}>
						{alert.nested_rule.description}
					</div>
				</div>
			)
		}
	}

	render() {
		const { classes } = this.props;
		const { alerts, pagination } = this.state;
		const { per_page, current_page, total } = pagination;
		const DEMO = false;
		return (
			<Paper className={classes.container}>
				<div className={classes.paginationContainer}>
					<PaginationContainer
						count={total}
						rowsPerPage={per_page}
						currentPage={current_page}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						toggleView={(layout) => this.setState({layout: layout})}
					/>
				</div>
				<div className={classes.alertsContainer}>
					{alerts ?
						alerts.length > 0 ?
							this.state.layout === "card" ?
								this.render_card_layout()
								:
								this.render_table_layout()
						:
							<div className={classes.none}>
								No Alerts.
							</div>
					: <Loading />
					}
				</div>
			</Paper>
		)
	}

	render_card_layout = () => {
		const { alerts } = this.state;
		const { classes } = this.props
		return (
			<React.Fragment>
				{alerts.map((alert) => (
					<div key={alert._id} className={classes.alertContainer}>
						<div className={classes.left}>
							{this.alertCategory(alert, classes)}
							<div className={classes.date}>
								{this.showDate(alert.created_at)}
							</div>
							<div className={classes.time}>
								{this.showTime(alert.created_at)}
							</div>
							<div className={classes.line}>
							</div>
						</div>
						<div className={classes.right}>
							{this.showRead(false, alert.read, classes)}
							<div className={classes.message}>
								{this.render_message(alert)}
							</div>
							{this.renderRule(alert, classes)}
						</div>
					</div>
				))}
			</React.Fragment>
		);
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.loadDeviceAlerts();
		});
	}

	render_table_layout = () => {
		const {alerts, page_data} = this.state;
		return (
			<TableList
				headings={heading_info} 
				items={alerts}
				page_data={page_data}
				perform_sort={this.perform_sort}
			/>
		);
	}

	showRead = (demo, read, classes) => {
		if (demo) {
			//todo incorporate the seen/unseen field
			read = Math.floor(Math.random() * 2);
			if (read === 0) {
				return (
					<div className={classes.unread}>
						<ErrorIcon className={classes.unreadIcon} />
						UNREAD
				</div>);
			} else {
				return (
					<div className={classes.read}>
						READ
					</div>
				);
			}
		}
	}

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	}

	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	}

	alertCategory = (alert, classes) => {
		//random for now, todo once category set
		// category = categories[Math.floor(Math.random() * 3)];

		//instead of random the current implementation looks at the message contents and
		// just pins the category to software, online/offline, or the default as rule.
		let categories = ["lifecycle", "software", "rule"];
		let category = null;
		if (alert.message.indexOf("software") !== -1) {
			category = categories[1];
		} else if (alert.message.indexOf("line") !== -1) {
			category = categories[0];
		} else {
			category = categories[2];
		}
		switch (category) {
			case "lifecycle":
				return (
					<div className={classes.categoryContainer}>
						<div className={classes.categoryLabel}>
							LIFECYCLE
						</div>
						<SyncProblemIcon className={classes.alertIcon + " " + classes.blue} />
					</div>
				);
			case "software":
				return (
					<div className={classes.categoryContainer}>
						<div className={classes.categoryLabel}>
							SOFTWARE
						</div>
						<CodeIcon className={classes.alertIcon + " " + classes.green} />
					</div>
				);
			case "rule":
				return (
					<div className={classes.categoryContainer}>
						<div className={classes.categoryLabel}>
							CONDITION
						</div>
						<SecurityIcon className={classes.alertIcon + " " + classes.red} />
					</div>
				);
		}
	}
}

DeviceAlerts.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceAlerts));
