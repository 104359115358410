import React from 'react';
import {withStyles, withTheme} from '@material-ui/core/styles';
import { AssignNestedModels } from '../../../services/CLURDUtilities';
import FilterSidebar from '../../ActionOriented/FilterSidebar';
import AlertFeed from "../../AlertSpecific/AlertFeed";
import Notification from "../../../services/DataModels/Notification";
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Permissions from '../../../services/Permissions';
import Card from '@material-ui/core/Card';

const styles = {
	container: {
		display: "flex",
		width: "100%",
		position: "relative",
	},
	filterBg: {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		zIndex: 9999
	},
	filterContainer: {
		position: "relative",
		zIndex: 3,
		width: "800px",
		backgroundColor: "white",
		padding: "72px 48px 35px 48px",
		overflowY: "auto",
		maxHeight: "750px",
	},
	link: {
		color: "#1153b6",
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": "#1153b6",
			textDecorationColor: "#1153b6",
			cursor: "pointer",
		},
	}
};

class AlertsTab extends React.Component {

	constructor(props) {
		super(props);
		//==============================================
		//SET THE DEMO HERE TO SHOW THE SPOOFED FEATURES
		this.DEMO = false;
		//==============================================
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			show_filters: false,
			alerts: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			filter_data: {},
			sort: null
		};
		this.filters = [];
		this.props.tabHostProxy.setRootRefresh(this.load_alerts);
		this.load_alerts();
		this.alter_filters();
		this.filters = [
			{
				label: 'Message Contents',
				type: 'text',
				pattern: null,
				initial: '',
				field: 'message_like'
			},
			{
				label: 'Date',
				type: 'date',
				field: 'date',
				range: true
			},
		];
	}

	alter_filters = () => {
		if (Permissions.allow(["read"], "device")) {
			this.filters.push({
				label: 'Device(s)',
				type: 'lookup',
				placeholder: "Device unique ID",
				model: "devices",
			});
		}
		if (Permissions.allow(["read"], "rule")) {
			this.filters.push({
				label: 'Policies',
				type: 'lookup',
				placeholder: "Policy description",
				model: "rules"
			});
		}
		if (this.DEMO) {
			this.filters.push({
				label: 'Severity',
				type: 'select',
				options: [
					{display: "Select Severity", value: ''},
					{display: "Critical", value: "critical"},
					{display: "Standard", value: "standard"},
					{display: "Minor", value: "minor"}
				],
				field: 'severity'
			});
			this.filters.push({
				label: 'Category',
				type: 'select',
				options: [
					{display: "Select Category", value: ''},
					{display: "Lifecycle", value: "lifecycle"},
					{display: "Software", value: "software"},
					{display: "Policy", value: "rule"}
				],
				field: 'category'
			});
		}
	}

	render_date = (date) => {
		function showDate(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleDateString();
		}

		function showTime(utc) {
			let date_object = new Date(utc);
			return date_object.toLocaleTimeString();
		}
		return (
			<div >
				<div style={{ whiteSpace: "nowrap" }}>
					{showTime(date)}
				</div>
				<div style={{ color: "#8e8e93" }}>
					{showDate(date)}
				</div>
			</div>
		);
	}

	load_alerts = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.alerts !== null) this.setState({alerts: null});
		new Notification().listFromAPI(params).then( (results) => {
			let alerts = results.items;
			alerts.forEach( (alert) => {
				alert.table_device = () => (Permissions.allow(["read"], "device", alert.company_id) && Permissions.allow(["read"], "device_type", alert.company_id) && alert.device_id ? <div onClick={() => this.props.tabHostProxy.addTab("device", alert.device_id)} className={classes.link}>View Device</div> : <div>Device Unavailable</div>);
				alert.table_rule = () => (Permissions.allow(["read"], "rule", alert.company_id) && alert.rule_id ? <div onClick={() => this.props.tabHostProxy.addTab("rule", alert.rule_id)} className={classes.link}>View Policy</div> : <div>Policy Unavailable</div>);
				alert.table_message = () => this.render_message(alert);
			});
			this.setState( (prev_state) => {
				return {
					alerts: alerts,
					page_data: {
						page: params.page,
						per_page: params.per_page,
						page_meta: true,
						total: results.total
					}
				};
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_alerts();
		});
	}

	render_message = (alert) => {
		// let formatted = alert.message;
		// let section = `ObjectIdHex("${alert.device_id}")`;
		// if (alert.message.indexOf(section) !== -1) {
		// 	formatted = alert.nested_device.name + " " + alert.message.split(section)[1]
		// }
		return alert.message;
	}

	apply_filters = (filters) => {
		this.setState({filter_data: this.prepare_filter(filters)}, () => {
			this.load_alerts();
		});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return { 
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_alerts();
		});
	}

	prepare_filter = (params) => {
		let body = {};
		let devices_param = params["Device(s)"];
		if (devices_param && devices_param.values && devices_param.values.length > 0) {
			let device_ids = params["Device(s)"].values.map( (device) => device.value);
			body.device_id_in = device_ids.slice(1).reduce( (item, total) => total += "," + item, device_ids[0]);
		}
		if (params["Device(s)"] && params["Device(s)"].values && params["Device(s)"].values.length > 0) {
			let device_ids = params["Device(s)"].values.map( (device) => device.value);
			body.device_id_in = device_ids.slice(1).reduce( (item, total) => total += "," + item, device_ids[0]);
		}
		//todo change to use severity, category, rules once supported
		// body.device_id_in = params["Device(s)"];
		// body.rule_id_in = params["Rule(s)"];
		if (params["Message Contents"] && params["Message Contents"] !== "") {
			body.message_like = params["Message Contents"];
		}
		if (params["Date"]) {
			if (params["Date"].range === "Before" && params["Date"].firstDate !== null) {
				body.created_at_lt = params["Date"].firstDate.toISOString();
			}
			else if (params["Date"].range === "After" && params["Date"].firstDate !== null) {
				body.created_at_gt = params["Date"].firstDate.toISOString();
			}
			else if (params["Date"].range === "Between" && (params["Date"].firstDate !== null && params["Date"].secondDate !== null)) {
				//if they make the dates equal they're gonna get the results they deserve
				if (params["Date"].firstDate >= params["Date"].secondDate) {
					body.created_at_lt = params["Date"].firstDate.toISOString();
					body.created_at_gt = params["Date"].secondDate.toISOString();
				} else {
					body.created_at_lt = params["Date"].secondDate.toISOString();
					body.created_at_gt = params["Date"].firstDate.toISOString();
				}
			}
		}

		return body;
	}

	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}


	render() {
		const { tabHostProxy, classes} = this.props;
		const { alerts, page_data, filter_data } = this.state;
		return (
			<div className={classes.container}>
				<div style={{visibility: this.state.show_filters ? "visible" : "hidden"}} onClick={() => this.setState({show_filters: false})} className={classes.filterBg}>
					<Card
						onClick={(event) => event.stopPropagation()}
						className={classes.filterContainer}
					>
						<FilterSidebar
							filters={this.filters}
							triggerFilter={this.apply_filters}
							closeFilters={() => this.setState({show_filters: false})}
						/>
					</Card>
				</div>
				<AlertFeed
					filtering={this.is_filtering()}
					DEMO={this.DEMO}
					show_filters={() => this.setState({show_filters: true})}
					page_change={this.page_change}
					alerts={alerts}
					perform_sort={this.perform_sort}
					page_data={page_data}
					tabHostProxy={tabHostProxy}
				/>
			</div>
		);
	}
}

AlertsTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(AlertsTab));
