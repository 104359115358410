import React from 'react';
import { withStyles, withTheme} from '@material-ui/core/styles';
import Config from "../../../services/DataModels/Config";
import FilterSidebar from '../../ActionOriented/FilterSidebar';
import ConfigsList from '../../ConfigsSpecific/ConfigsList';
import Auth from '../../../services/Auth';
import Card from '@material-ui/core/Card';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = {
	container: {
		display: "flex",
		width: "100%",
	},
	filterBg: {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		zIndex: 9999
	},
	filterContainer: {
		position: "relative",
		zIndex: 3,
		width: "800px",
		backgroundColor: "white",
		padding: "72px 48px 35px 48px",
		overflowY: "auto",
		maxHeight: "750px",
		boxSizing: "border-box",
	},
	link: {
		color: "#1153b6",
		"-webkit-text-decoration": "underline transparent",
		textDecoration: "underline transparent",
		transition: "text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		"&:hover": {
			"-webkit-text-decoration-color": "#1153b6",
			textDecorationColor: "#1153b6",
			cursor: "pointer",
		}
	}
};

class ConfigsTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.startingPerPage = 12;
		this.state = {
			configs: null,
			page_data: {
				page: 1,
				per_page: this.startingPerPage,
				page_meta: true,
				total: null
			},
			show_filters: false,
		};
		//todo maybe a connection filter?
		this.filters = [
			{
				label: 'Name',
				type: 'text',
				field: 'name_like',
			},
			{
				label: 'Device Type',
				type: 'lookup',
				placeholder: "Filter by device type",
				field: "device_type_id",
				model: "device_types",
				valueField: "_id",
				labelField: "name"
			}
		];
		this.load_configs();
		this.set_filters();
		this.props.tabHostProxy.setRootRefresh(this.load_configs);
	}

	set_filters = () => {
		if (Auth.currentUser().company_ids.length > 1) {
			this.filters.push({
				label: 'Account',
				type: 'lookup',
				placeholder: "Filter by account",
				field: "company_id",
				model: "companies",
				valueField: "_id",
				labelField: "name"
			});
		}
	}

	perform_sort = (sort_info) => {
		let sort = { order_by: ""};
		sort.order_by = sort_info.order === "desc" ? "-" + sort_info.order_by : sort_info.order_by;
		this.setState({sort: sort}, () => {
			this.load_configs();
		});
	}

	handle_table_name_click = (config) => {
		if (!config.nested_device_type._id) {
			this.context.openSnackbar("This network config's device type has been deleted or is not available. This network config cannot be edited.", "error");
		}
		this.props.tabHostProxy.addTab("config", config)
	}

	load_configs = () => {
		const {classes} = this.props;
		let params = Object.assign(this.state.page_data, this.state.filter_data, this.state.sort);
		delete params.total;
		if (this.state.configs !== null) this.setState({configs: null});
		new Config()
			.listFromAPI(params)
			.then( ({items, total}) => {
				Config.loadRequirements(items)
				.then( (items) => {
					let configs = items;
					configs.forEach( (config) => {
						if (config.nested_device_type == null || ( config.nested_device_type.capabilities.network_connections == null && config.connections && config.connections.length > 0) ) {
							config.error = "This device config does not have connection settings that match its device type. Please delete this config or properly configure this config.";
						}
						config.nested_device_type = config.nested_device_type ? config.nested_device_type : {};
						config.nested_company = config.nested_company ? config.nested_company : {};
						config.table_name = () => (
							<div
								onClick={() => !config.error && this.handle_table_name_click(config)}
								className={classes.link}
							>
								{config.name}
							</div>
						);
					});
					this.setState( () => {
						return {
							configs: configs,
							page_data: {
								page: params.page,
								per_page: params.per_page,
								page_meta: true,
								total: total
							}
						};
					});
				}).catch( (error) => {
					this.context.openSnackbar(error, "error");
				});
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	page_change = (params) => {
		this.setState( (prev_state) => {
			return {
				page_data: {
					page: params.page,
					per_page: params.per_page,
					total: prev_state.page_data.total,
					page_meta: true
				}
			};
		}, () => {
			this.load_configs();
		});
	}

	prepare_filter = (params) => {
		let body = {};
		if (params) {
			if (params["Name"]) {
				body.name_like = params["Name"];
			}
			if (params["Device Type"] && params["Device Type"].values && params["Device Type"].values.length > 0) {
				var deviceTypeIDs = params["Device Type"].values.map((option) => {
					return option.value;
				});
				body.device_type_id_in = deviceTypeIDs.join(",");
			}
			if (params["Account"] && params["Account"].values && params["Account"].values.length > 0) {
				var companyIDs = params["Account"].values.map((option) => {
					return option.value;
				});
				body.company_id_in = companyIDs.join(",");
			}
			
		}
		return body;
	}

	apply_filters = (filters) => {
		this.setState({filter_data: this.prepare_filter(filters)}, () => {
			this.load_configs();
		});
	}

	is_filtering = () => {
		const filter_data = this.state.filter_data;
		if (filter_data == null || Object.entries(filter_data).length === 0) {
			return false
		} else {
			return true;
		}
	}

	render() {
		let { tabHostProxy, classes } = this.props;
		const { configs, page_data } = this.state;
		return (
			<div className={classes.container}>
				<div style={{visibility: this.state.show_filters ? "visible" : "hidden"}} onClick={() => this.setState({show_filters: false})} className={classes.filterBg}>
					<Card
						onClick={(event) => event.stopPropagation()}
						className={classes.filterContainer}
					>
						<FilterSidebar
							filters={this.filters}
							triggerFilter={this.apply_filters}
							closeFilters={() => this.setState({show_filters: false})}
						/>
					</Card>
				</div>
				<ConfigsList
					filtering={this.is_filtering()}
					show_filters={() => this.setState({show_filters: true})}
					page_change={this.page_change}
					configs={configs}
					perform_sort={this.perform_sort}
					page_data={page_data}
					tabHostProxy={tabHostProxy}
				/>
			</div>
		);
	}
}

ConfigsTab.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(ConfigsTab));
