import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CreateUserForm from "../../components/UserSpecific/CreateUserForm";
import Snackbar from '@material-ui/core/Snackbar';
import Role from '../../services/DataModels/Role';
import CustomSnackbar from '../../components/Containers/CustomSnackbar';
import { GetAll } from '../../services/CLURDUtilities';
import CardContainer from '../../components/Containers/CardContainer';
import UserContainer from '../../components/UserSpecific/UserContainer';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';

const styles = theme => ({
    stepContentTitle: {
        fontFamily: "Inter",
        fontSize: "1.25rem",
        marginBottom: "8px",
        flex: "100%",
    },
    note: {
        width: "90%",
        fontFamily: "Inter",
        fontStyle: "italic",
        color: "black",
        fontSize: "18px",
        margin: "10px 0 10px 16px"
    },
    container: {
    },
    stepContentSubtitle: {
        fontFamily: "Inter",
        fontWeight: 400,
        color: "grey",
        flex: "100%",
        marginBottom: "12px",
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "12px",
        height: "calc(100vh - 180px)",
    },
    companyOptionContainer: {
        height: "calc(100% - 66px)",
        overflow: "scroll"
    },
    contentCard: {
        width: "32%",
        display: "flex",
    },
    companyRow: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: "12px",
        minHeight: "64px",
        height: "64px",
        '&:hover': {
            backgroundColor: "#1e55963d"
        }
    },
    companyIcon: {
        width: "50px",
        display: "flex",
        margin: "6px",
    },
    companyPlaceholderIcon: {
        width: "50px",
        display: "flex",
        margin: "6px",
        color: theme.palette.primary.main
    },
    companyText: {
        fontFamily: "Inter",
        fontSize: "18px",
    },
    customerRow: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Inter",
        padding: "6px 0",
        marginLeft: "24px",
    },
    activeCompany: {
        backgroundColor: "#1e55963d"
    },
});

class CreateCustomers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company_id: "",
            roles: [],
            snackbar: {
                open: false
            },
            companies: [],
            customers: [],
            activeCompany: ''
        };
        this.loadCompanies();
        this.loadRoles();
    }

    loadRoles = () => {
        GetAll("user_types").then((roles) => {
            let rolesMap = {};
            roles.forEach((role) => {
                role.ui_role_name = Role.defaultRoleReadable(role.name);
                rolesMap[role._id] = role;
            });
            this.setState({ roles: roles, rolesMap: rolesMap });
        }).catch((error) => {
            //todo
        });
    }

    loadCompanies = () => {
        GetAll("companies").then((companies) => {
            this.setState({ companies: companies });
        }).catch((error) => {
            //todo
        });
    }

    getCustomers = (company_id) => {
        let body = {
            company_id: company_id
        };
        return GetAll("users", body);
    }

    refreshCustomers = () => {
        let body = {
            company_id: this.state.activeCompany._id
        };
        GetAll("users", body).then((customers) => {
            customers = this.assignRoles(customers);
            this.setState({ customers: customers });
        }).catch((error) => {
            //todo
        })
    }

    assignRoles = (customers) => {
        customers.forEach((customer) => {
            if (this.state.rolesMap[customer.user_type_id]) {
                customer.ui_role_name = this.state.rolesMap[customer.user_type_id].name;
            } else {
                customer.ui_role_name = null;
            }
        });
        return customers;
    }

    selectCompany = (company) => {
        this.getCustomers(company._id).then((customers) => {
            customers = this.assignRoles(customers);
            this.setState({ activeCompany: company, customers: customers });
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.stepContentTitle}>
                    Onboarding
                </div>
                <div className={classes.stepContentSubtitle}>
                    Choose an account in order to view and create user profiles.
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.contentCard}>
                        <CardContainer
                            title="Select an Account"
                            infoText="Choose an account to begin creating users."
                        >
                            <div className={classes.companyOptionContainer}>
                                {this.state.companies.map((company) => (
                                    company._id !== "machineshop" ?
                                        <div key={company._id} className={classes.companyRow + " " + (this.state.activeCompany._id === company._id ? classes.activeCompany : "")} onClick={() => this.selectCompany(company)}>
                                            {company.branding.icon_url !== "" ?
                                                <img src={company.branding.icon_url} className={classes.companyIcon} />
                                                :
                                                <AccountCircleIcon className={classes.companyPlaceholderIcon} />
                                            }
                                            <span className={classes.companyText}>
                                                {company.name}
                                            </span>
                                        </div>
                                        :
                                        <div key={company._id} className={classes.companyRow + " " + (this.state.activeCompany._id === company._id ? classes.activeCompany : "")} onClick={this.selectCompany}>
                                            <img src={require('../../images/ms_icon_new.png').default} className={classes.companyIcon} />
                                            <span className={classes.companyText}>
                                                {company.name}
                                            </span>
                                        </div>
                                ))}
                            </div>
                        </CardContainer>

                    </div>
                    <div className={classes.contentCard}>
                        {this.state.activeCompany ?
                            <CardContainer
                                title="Create User"
                                infoText="Create a new user for this account."
                                shaded={false}
                            >
                                <CreateUserForm afterCreate={this.refreshCustomers} openSnackbar={this.openSnackbar} roles={this.state.roles} company_id={this.state.activeCompany._id} />
                            </CardContainer>
                            :
                            <CardContainer
                                title="Create User"
                                infoText="Create a new user for this account."
                                shaded={false}
                            >
                                <div className={classes.note}>
                                    No account selected. Select one in order to create user profiles.
                                </div>
                            </CardContainer>
                        }
                    </div>
                    <div className={classes.contentCard}>
                        <CardContainer
                            title="Users"
                            infoText="These are the users that belong to this account."
                            shaded={true}
                        >
                            <div className={classes.companyOptionContainer}>
                                {this.state.activeCompany ?
                                    this.state.customers && this.state.customers.length > 0 ?
                                        this.state.customers.map((customer) => (
                                            <UserContainer
                                                key={customer._id}
                                                name={customer.first_name + " " + customer.last_name}
                                                role={customer.ui_role_name}
                                                image={customer.logo_url}
                                            />
                                        ))
                                        :
                                        <div className={classes.note}>
                                            No users found. Create one using the form.
                                        </div>
                                    :
                                    <div className={classes.note}>
                                        No account selected. Select one to see its users.
                                    </div>
                                }
                            </div>
                        </CardContainer>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackbar}
                >
                    <CustomSnackbar
                        onClose={this.closeSnackbar}
                        variant={this.state.snackbar.result}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
            </div>
        );
    }

    openSnackbar = (message, result) => {
        result ? result = "success" : result = "error";
        this.setState({
            snackbar: {
                message: message,
                result: result,
                open: true,
            }
        });
    }

    closeSnackbar = () => {
        this.setState({
            snackbar: {
                message: "",
                open: false,
                result: this.state.snackbar.result
            }
        });
    }
}

export default withStyles(styles)(withTheme()(CreateCustomers));
