import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import AlertsTab from '../TabComponents/Alert/AlertsTab';
import RulesTab from '../TabComponents/Rule/RulesTab';
import RolesTab from '../TabComponents/Role/RolesTab';
import RoleTab from '../TabComponents/Role/RoleTab';
import RuleDetailTab from '../TabComponents/Rule/RuleDetailTab';
import UsersTab from '../TabComponents/User/UsersTab';
import UserTab from '../TabComponents/User/UserTab';
import ConfigsTab from '../TabComponents/Configs/ConfigsTab';
import ConfigTab from '../TabComponents/Configs/ConfigTab';
import DeviceDetailTab from '../TabComponents/Device/DeviceDetailTab';
import DevicesTab from '../TabComponents/Device/DevicesTab';
import DiscoveredDevicesTab from '../TabComponents/DiscoveredDevices/DiscoveredDevicesTab';
import DiscoveredDeviceTab from '../TabComponents/DiscoveredDevices/DiscoveredDeviceTab';
import MessagesTab from '../TabComponents/Messages/MessagesTab';
import AccountsTab from '../TabComponents/Accounts/AccountsTab';
import AccountTab from '../TabComponents/Accounts/AccountTab';
import HAGroupTab from '../TabComponents/HAGroups/HAGroupTab';
import HAGroupsTab from '../TabComponents/HAGroups/HAGroupsTab';
import SoftwareUpdatesTab from '../TabComponents/SoftwareUpdates/SoftwareUpdatesTab';
import IngestTab from '../TabComponents/Ingest/IngestTab.js';
import IngestDetailTab from '../TabComponents/Ingest/IngestDetailTab';
import CommandTab from '../TabComponents/Command/CommandTab.js';
import CommandDetailTab from '../TabComponents/Command/CommandDetailTab';
import TranslatorDetailTab from '../TabComponents/Ingest/TranslatorDetailTab';
import CommandTranslatorDetailTab from '../TabComponents/Command/CommandTranslatorDetailTab';
import TypesTab from '../TabComponents/Types/TypesTab.js';
import TypeTab from '../TabComponents/Types/TypeTab.js';
import TransfersTab from '../TabComponents/Transfers/TransfersTab.js';
import ReportsTab from '../TabComponents/Reports/ReportsTab.js';
import SoftwareUpdateDetailTab from '../TabComponents/SoftwareUpdates/SoftwareUpdateDetailTab';
import IntegrationsTab from '../TabComponents/Integrations/IntegrationsTab';
import IntegrationDetailTab from '../TabComponents/Integrations/IntegrationDetailTab';
import PollableAttributesTab from '../TabComponents/PollableAttributes/PollableAttributesTab';
import PollableAttributesDetailTab from '../TabComponents/PollableAttributes/PollableAttributesDetailTab';
import Tab from '../DisplayOriented/Tab';
import HeartbeatStatus from '../common/HeartbeatStatus/HeartbeatStatusComponent'

//icons
import DevicesOtherIcon from '@material-ui/icons/DevicesOtherOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHorizOutlined';
import PollIcon from '@material-ui/icons/PollOutlined';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponentOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import ReportProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import DvrIcon from '@material-ui/icons/DvrOutlined';
import DiscoveredDeviceIcon from '@material-ui/icons/DvrOutlined';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import GroupWorkIcon from '@material-ui/icons/GroupWorkOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import TuneIcon from '@material-ui/icons/TuneOutlined';

const styles = {
    notShown: {
        display: "none",
    },
    shown: {
        display: "flex",
        width: "100%",
    },
    container: {
        marginTop: "12px",
        height: "100%",
    },
    activeTabContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        display: "flex",
        backgroundColor: "white",
        border: "solid lightgrey 1px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        borderTop: "solid lightgrey 1px",
        borderTopRightRadius: "4px"
    },
    tabFiller: {
        display: "flex",
        flexGrow: 3,
        backgroundColor: "#f5f5f7",
        marginLeft: "-3px",
    },
    iconContainer: {
        position: "relative"
    },
    heartBeatstatusDot: {
        position: "absolute",
        top: "-8px",
        right: 0,
        width: "10px",
        height: "10px",
        minWidth: "10px",
        minHeight: "10px",
        maxWidth: "10px",
        maxHeight: "10px",
        borderRadius: "50%",
        display: "inline-flex",
        marginTop: "8px",
        color: 'white'
    },
    allTabsContainer: {
        position: "relative",
        zIndex: 4,
        width: "100%",
        height: "40px",
        display: "flex",
        backgroundColor: "#ececec",
        borderRadius: "4px 0 0 0",
    },
};

const TAB_MAP = {
    "accounts": {
        icon: (key) => (<AccountCircleIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<AccountsTab tabHostProxy={proxy} key={key} title={"Accounts"} />)
    },
    "account": {
        icon: (key) => (<AccountCircleIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<AccountTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Account" : data.name} />)
    },
    "devices": {
        icon: (key) => (<DvrIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<DevicesTab tabHostProxy={proxy} key={key} title={"Devices"} />)
    },
    "discovered_devices": {
        icon: (key) => (<DiscoveredDeviceIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<DiscoveredDevicesTab tabHostProxy={proxy} key={key} title={"Discovered Devices"} />)
    },
    "device": {
        component: (data, proxy, is_new) => (<DeviceDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Device" : data.name} />)
    },
    "discovered_device": {
        icon: (key) => (<DiscoveredDeviceIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<DiscoveredDeviceTab tabHostProxy={proxy} data={data} key={data._id} title={data.name} />)
    },
    "type": {
        icon: (key) => (<DevicesOtherIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<TypeTab tabHostProxy={proxy} data={data} key={data._id} title={data.name} />)
    },
    "haGroups": {
        icon: (key) => (<GroupWorkIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<HAGroupsTab tabHostProxy={proxy} key={key} title={"HA Groups"} />)
    },
    "haGroup": {
        icon: (key) => (<GroupWorkIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<HAGroupTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New HA Group" : data.name} />)
    },
    "configs": {
        icon: (key) => (<SettingsIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<ConfigsTab tabHostProxy={proxy} key={key} title={"Configs"} />)
    },
    "config": {
        icon: (key) => (<SettingsIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<ConfigTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Config" : data.name} />)
    },
    "users": {
        icon: (key) => (<PersonIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<UsersTab tabHostProxy={proxy} key={key} title={"Users"} />)
    },
    "user": {
        icon: (key) => (<PersonIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<UserTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New User" : data.first_name + " " + data.last_name} />)
    },
    "roles": {
        icon: (key) => (<PeopleIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<RolesTab tabHostProxy={proxy} key={key} title={"Roles"} />)
    },
    "role": {
        icon: (key) => (<PeopleIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<RoleTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Role" : data.name} />)
    },
    "integrations": {
        icon: (key) => (<ExtensionIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<IntegrationsTab tabHostProxy={proxy} key={key} title={"Integrations"} />)
    },
    "integration": {
        icon: (key) => (<ExtensionIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<IntegrationDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Integration" : data.name} />)
    },
    "softwareUpdates": {
        icon: (key) => (<CodeIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<SoftwareUpdatesTab tabHostProxy={proxy} key={key} title={"Software Packages"} />)
    },
    "ingestors": {
        icon: (key) => (<SettingsInputComponentIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<IngestTab tabHostProxy={proxy} key={key} title={"Ingestors"} />)
    },
    "ingestor": {
        icon: (key) => (<SettingsInputComponentIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<IngestDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Ingestor" : data.name} />)
    },
    "commands": {
        icon: (key) => (<ArrowDownwardIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<CommandTab tabHostProxy={proxy} key={key} title={"Commands"} />)
    },
    "command": {
        icon: (key) => (<ArrowDownwardIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<CommandDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Command" : data.name} />)
    },
    "translator": {
        icon: (key) => (<SettingsInputComponentIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<TranslatorDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Translator" : data.name} />)
    },
    "command_translator": {
        icon: (key) => (<SettingsInputComponentIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<CommandTranslatorDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Command Translator" : data.name} />)
    },
    "types": {
        icon: (key) => (<DevicesOtherIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<TypesTab tabHostProxy={proxy} key={key} title={"Device Types"} />)
    },
    "transfers": {
        icon: (key) => (<SwapHorizIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<TransfersTab tabHostProxy={proxy} key={key} title={"Transfers"} />)
    },
    "reports": {
        icon: (key) => (<PollIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<ReportsTab tabHostProxy={proxy} key={key} title={"Reports"} />)
    },
    "rules": {
        icon: (key) => (<SecurityIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<RulesTab tabHostProxy={proxy} key={key} title={"Policies"} />)
    },
    "rule": {
        icon: (key) => (<SecurityIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<RuleDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Policy" : data.description} />)
    },
    "alerts": {
        icon: (key) => (<ReportProblemIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<AlertsTab tabHostProxy={proxy} key={key} title={"Alerts"} />)
    },
    "messages": {
        icon: (key) => (<InboxIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<MessagesTab tabHostProxy={proxy} key={key} title={"Messages"} />)
    },
    "pollable_attributes": {
        icon: (key) => (<TuneIcon key={`${key}_icon`} />),
        component: (key, proxy) => (<PollableAttributesTab tabHostProxy={proxy} key={key} title={"Pollable Attributes"} />)
    },
    "pollable_attribute": {
        icon: (key) => (<TuneIcon key={`${key}_icon`} />),
        component: (data, proxy, is_new) => (<PollableAttributesDetailTab tabHostProxy={proxy} data={is_new ? undefined : data} key={data._id} title={is_new ? "New Pollable Attribute" : data.name} />)
    },
};

class TabHostProxy {
    constructor(container, tab, index) {
        this.container = container;
        this.title = tab.title;
        this.tab = tab;
        this.type = tab.type;
        this.index = index;
    }

    closeSelf() {
        this.container.closeTab(this._id);
    }

    closeOther(id, rootOpen) {
        this.container.closeTab(id, rootOpen);
    }

    updateTitle(id, previous_type, new_title, new_data) {
        this.title = new_title;
        this.container.updateTitle(id, previous_type, new_title, new_data);
    }

    updateTabData = (type, current_id, new_data, dataIsIdentifier = false) => {
        this.container.updateTabData(type, current_id, new_data, dataIsIdentifier);
    }

    addTab(type, data, dataIsIdentifier = false) {
        this.container.addTab(type, data, dataIsIdentifier);
    }

    refresh() {
        this.container.refresh();
    }

    setRootRefresh(refreshFunction) {
        this.container.setRootRefresh(refreshFunction);
    }

    setTabRefresh(refreshFunction) {
        this.container.setTabRefresh(refreshFunction);
    }

}

class TabsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: null,
            tabs: {}
        };
        let initial_tab = this.buildTabComponent(this.props.initial, 0);
        this.state.tabs[initial_tab.component.props.tabHostProxy._id] = initial_tab;
        this.state.active = initial_tab.component.props.tabHostProxy._id;
    }

    // id, previous_type, new_title, new_item
    // Deprecated
    updateTitle = (id, previous_type, new_title, new_data) => {
        return;
        this.setState((prev_state) => {
            let targetTab = prev_state.tabs[id];
            if (targetTab == null) {
                let targetType = prev_state.tabs[previous_type];
                if (targetType) {
                    targetTab = targetType[id];
                    //console.log("target type");
                    //console.log(targetType);
                    //console.log("target tab");
                    //console.log(targetTab);
                }
            }

            if (targetTab) {
                delete prev_state.tabs[id];
                let placeholder = { type: targetTab.component.props.tabHostProxy.type, data: new_data };
                let new_tab = this.buildTabComponent(placeholder, targetTab.index);
                console.log(new_tab); // blows up after here
                prev_state.tabs[previous_type._id] = new_tab;
            } else {
                console.log("Unable to find tab to update title");
                console.log(prev_state);
                //uh oh
            }
            return prev_state;
        });
    }

    updateTabData = (type, current_id, new_data, dataIsIdentifier = false) => {
        this.setState((prev_state) => {
            let targetTab = prev_state.tabs[current_id];
            let new_identifier = this.tabIdForData(type, new_data, dataIsIdentifier);

            if (targetTab) {
                delete prev_state.tabs[current_id];
                let tabOptions = { type: type, data: new_data };
                let new_tab = this.buildTabComponent(tabOptions, targetTab.index);
                prev_state.tabs[new_identifier] = new_tab;
            } else {
                console.log("Unable to find tab to update data");
                console.log(prev_state);
                //uh oh
            }
            return prev_state;
        });
    }

    refreshTab = () => {
        this.state.tabs[this.state.active].refreshFunction();
    }

    refresh = () => {
        if (this.refreshFunction != null) {
            this.refreshFunction();
        }
    }

    setRootRefresh = (refreshFunction) => {
        this.refreshFunction = refreshFunction;
    }

    setTabRefresh = (refreshFunction) => {
        this.setState((state) => {
            state.tabs[state.active].refreshFunction = refreshFunction;
            return state;
        });
    }

    tabIdForData = (type, data, dataIsIdentifier) => {
        let identifier = dataIsIdentifier ? data : type;
        if (data && data._id) {
            identifier = data._id; // in this case data HAS identifier
        }
        return identifier;
    }

    addTab = (type, data, dataIsIdentifier = false) => {
        this.setState((prev_state) => {
            let new_tabs = prev_state.tabs;
            let tab = {
                type: type,
                data: data
            };
            let identifier = this.tabIdForData(type, data, dataIsIdentifier);
            let exists = Boolean(new_tabs[identifier]);
            if (exists) {
                return { active: identifier };
            } else {
                let built_tab = this.buildTabComponent(tab, Object.entries(new_tabs).length, identifier);
                new_tabs[identifier] = built_tab;
                return { tabs: new_tabs, active: identifier };
            }
        });
    }

    closeTab = (key, rootOpen) => {
        this.setState((prev_state) => {
            let new_tabs = prev_state.tabs;
            let closed_index = prev_state.tabs[key] ? prev_state.tabs[key].index : 0;
            let new_index = rootOpen ? prev_state.tabs[prev_state.active].index : prev_state.tabs[prev_state.active].index - 1;
            let new_active = Object.entries(prev_state.tabs).find(([key, tab]) => tab.index === new_index)[0];
            if (new_tabs[key]) {
                delete new_tabs[key];
            }
            Object.entries(prev_state.tabs).forEach(([key, tab]) => {
                if (tab.index > closed_index) {
                    tab.index -= 1;
                }
            });
            return {
                tabs: new_tabs,
                active: new_active
            };
        });
    }

    toggleTab = (new_active) => {
        if (this.state.active !== new_active) {
            this.setState({ active: new_active });
        }
    }

    setTabLocation = (tab) => {
        let index = tab.index;
        let active_index = this.state.tabs[this.state.active].index;
        if (index === active_index - 1) {
            return "left";
        } else if (index === active_index + 1) {
            return "right";
        } else {
            return "middle";
        }
    }

    buildTabComponent = (tab, index, identifier = null) => {
        let proxy = new TabHostProxy(this, tab, index);
        if (identifier) {
            proxy._id = identifier;
        } else {
            proxy._id = tab.data && tab.data._id ? tab.data._id : tab.type;
        }
        let is_new = index === 0 ? false : !Boolean(tab.data);
        let component = TAB_MAP[tab.type].component(tab.data || tab.type, proxy, is_new);
        let icon = null;
        if (tab.type === "device") {
            icon = this.build_device_icon(tab.data);
        } else {
            icon = TAB_MAP[tab.type].icon();
        }
        return {
            component: component,
            icon: icon,
            isNew: is_new,
            index: index,
            hideRefresh: tab.hideRefresh ? true : false
        };
    }

    render() {
        const { active, tabs } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.allTabsContainer}>
                    {Object.entries(this.state.tabs).sort(([key_a, tab_a], [key_b, tab_b]) => tab_a.index - tab_b.index).map(([key, tab]) => (
                        <Tab
                            isNew={tab.isNew}
                            key={"tab_" + tab.index}
                            index={tab.index}
                            icon={tab.icon}
                            title={tab.component.props.title}
                            active={active === key}
                            location={this.setTabLocation(tab)}
                            toggle={() => this.toggleTab(key)}
                            closeTab={tab.index !== 0 ? () => this.closeTab(key) : null}
                            refresh={tab.index === 0 ? this.refresh : this.refreshTab}
                            hideRefresh={tab.hideRefresh}
                            totalTabs={Object.keys(tabs).length}
                        />
                    ))}
                    <div className={classes.tabFiller}>
                    </div>
                </div>
                <div className={classes.activeTabContainer}>
                    {this.showTabs()}
                </div>
            </div>
        );
    }

    build_device_icon = (device) => {
        const classes = this.props.classes;
        if (device && device._id) {
            return (
                <div className={classes.iconContainer}>
                    <DvrIcon />
                    <HeartbeatStatus status={device.heartbeat_status} className={classes.heartBeatstatusDot} />
                </div>
            );
        } else {
            return (
                <DvrIcon />
            );
        }
    }

    showTabs = () => {
        return Object.entries(this.state.tabs).sort(([key_a, tab_a], [key_b, tab_b]) => tab_a.index - tab_b.index).map(([key, tab], index) => {
            if (key !== this.state.active) {
                return (
                    <div key={key} className={this.props.classes.notShown}>
                        {tab.component}
                    </div>
                )
            } else {
                return (
                    <div key={key} className={this.props.classes.shown}>
                        {tab.component}
                    </div>
                );
            }
        });
    }
}

export default withStyles(styles)(withTheme()(TabsContainer));
