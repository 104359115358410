import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TabsContainer from '../components/Containers/TabsContainer';

const styles = (theme) => {
	return ({
		container: {
			height: "calc(100vh - 112px)"
		}
	});
};


class Users extends React.Component {

	constructor(props) {
		super(props);
		this.initial = {type: "users"}
	}

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.container}>
				<TabsContainer initial={this.initial} />
			</div>
		);
	}
}

export default withStyles(styles)(withTheme()(Users));
