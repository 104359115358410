import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import DeviceDataUsageRecords from "../../services/DataModels/DeviceDataUsageRecords";
import CardContainer from "../Containers/CardContainer";
import Button from "@material-ui/core/Button";
import SimpleModalWrapped from '../Containers/SimpleModalWrapped';
import EnhancedTable from '../Table/EnhancedTable';
import Permissions from '../../services/Permissions';
import { SnackbarContext } from "../../services/ContextProviders/Snackbar";

const styles = theme => ({
  tileContent: {
    height: "calc(100% - 49px)",
    overflow: "auto",
  },
  recentAlert: {
    display: "flex",
    alignItems: "center",
    margin: "12px"
  },
  dateContainer: {
    marginLeft: "12px",
    textAlign: "center",
    width: "20%"
  },
  date: {
    fontSize: "14px",
    fontWeight: 500
  },
  time: {
    fontSize: "14px",
    color: "grey",
    fontStyle: "italic"
  },
  contentContainer: {
    margin: "auto",
    marginLeft: 0
  },
  messageContainer: {
    display: "flex",
    flexWrap: "wrap",
    color: "grey",
    alignItems: "center",
    marginLeft: "12px"
  },
  commandTitle: {
    color: "black"
  },
  commandStatus: {
    textTransform: "capitalize"
  },
  inputIcon: {
    color: "grey",
    fontSize: "22px",
    marginRight: "8px"
  },
  iconContainer: {
    color: theme.palette.greyIcon.main
  },
  link: {
    color: "#4a90e2",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },
  payloadLink: {
    textDecoration: "underline"
  },
  noData: {
    margin: "6px 12px"
  },
  noPackage: {},
  mostRecent: {
    marginTop: "12px",
    marginLeft: "12px"
  },
  recentSoftwareCommand: {
    display: "flex",
    alignItems: "center",
    margin: "12px"
  },
  overviewContainer: {
    width: "100%",
    height: "calc(100% - 87px)",
    minHeight: "100%",
    boxSizing: "border-box",
    backgroundColor: "rgba(216,216,216,.2)",
    display: "flex",
    flexWrap: "wrap"
  },
  overviewTile: {
    width: "50%",
    height: "50%",
    display: "flex",
    flexWrap: "wrap",
	  boxSizing: "border-box",
  },
  overviewTileMargin: {
    margin: "6px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  cardContainerRoot: {
    color: "#7c7c7c",
    fontSize: "1.5vw",
    width: "100%",
    height: "calc(100% - 18px)",
    textTransform: "capitalize",
    textAlign: "center",
  },
  cardContainerData: {
    fontSize: "3.5em",
	  color: "black",
	  display: "inline-flex",
    flexWrap: "noWrap",
    marginTop: "10%",
  },
  cardContainerZero: {
	  width: "100%",
    height: "100% - 87px",
    backgroundColor: "#FFFFFF",
	  display: "flex",
	  fontSize: "1em",
	  color: "black",
	  padding: "50px",
  },
  cellUsageUnit: {
    color: "#7c7c7c",
    fontSize: "1em",
    alignItems: "center",
    display: "inline-flex",
    flexwrap: "nowrap"
  },
	modalWrapper: {
		padding: "24px",
		fontFamily: "Roboto"
	},
	scriptInModal: {
		overflow: "auto",
		padding: "12px",
		backgroundColor: "#0f0f0fd4",
		color: "white",
		borderRadius: "4px",
		fontFamily: "Courier New"
	},
  buttonContainer: {
    marginTop: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  heartbeatIconsContainer: {
    position: "relative",
    color: theme.palette.greyIcon.main
  },
  overlayIcon: {
    position: "absolute",
    left: 0,
    color: "white"
  },
  dataUsageValue: {
    fontSize: "7em",
    marginRight: "auto",
    color: "black"
  },
  dataUsageUnit: {
    color: "#7c7c7c",
    fontSize: ".5em",
    display: "inline"
  },
  dataUsageContent: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    height: "calc(100% - 38px)",
    alignItems: "center"
	},
	container: {
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexWrap: "wrap",
		boxShadow: "none"
	},
	tableContainer: {
		overflowY: "scroll",
		height: "calc(100% - 57px)",
		width: "100%",
		backgroundColor: "white"
	},
	errorsContainer: {
		width: "100%",
		height: "100%"
	},
	link: {
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		},
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		margin: "8px"
	},
});

class DeviceCellUsage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      editable_device: this.props.device,
			modal: {
				open: false,
				children: () => ""
			}
    }
  }
		
  show_data = data => {
    let i = -1;
    let byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    if (data == 0) {
      return "0" + byteUnits[1];
    }
    do {
      data = data / 1000;
      i++;
    } while (data > 1000);
    return Math.max(data, 0.1).toFixed(1) + byteUnits[i];
  };

	showDate = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleDateString();
	}
	
	showTime = (utc) => {
		let date_object = new Date(utc);
		return date_object.toLocaleTimeString();
	}

	closeModal = () => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	};

	showOriginalData = (original, classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => (
					<div className={classes.modalWrapper}>
						<pre className={classes.scriptInModal}>
							{JSON.stringify(JSON.parse(original), null, 2)}
						</pre>
						<div className={classes.buttonContainer}>
							<Button
								onClick={this.closeModal}
							>
								CLOSE
							</Button>
						</div>
					</div>
				)
			}
		});
	}
	
	render_original_data = (original, classes) => {
		return (
			<div>
				{
					<span style={{marginLeft: "12px", cursor: "pointer", textDecoration: "underline", color: this.props.theme.palette.pending.main}} onClick={() => this.showOriginalData(original, classes)} className={classes.link}>
						View Original Data
					</span>
				}
			</div>
		);
	}
	
	loadDataRecords = (args) => {
		delete args.total;
		return new Promise( (resolve, reject) => {
			let params = {
				...args,
				device_id: this.props.device._id
			};
			new DeviceDataUsageRecords().listFromAPI(params).then( (result) => {
				let records = result.items;
				records.forEach( (record) => {
					record._id = record.id;
					record.show_original = (classes) => this.render_original_data(record.original_data, classes);
				});
				resolve({items: records, total: result.total});
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
				reject();
			});
		});
	};

	renderEdgeCellUsage() {
    const { classes } = this.props;
    if (!this.state.editable_device.cell_usage) {
			if (this.props.device_type.type === "cloud_native") {
				return <React.Fragment/>
			} else {
				return (
					<div className={classes.cardContainerZero}>
						Cell usage is not being reported from the edge for this device.
					</div>
				)
			}
		};
		return (
			<div className={classes.overviewContainer}>
    	  <div className={classes.overviewTile}>
    	    <div className={classes.overviewTileMargin}>
    	      <CardContainer
    	        title="Total Usage"
    	        rootClass={classes.cardContainerRoot}
    	      >
    	        <div className={classes.cardContainerData}>
    	          {this.show_data(this.state.editable_device.cell_usage.total).split(" ")[0]}
    	        </div>
			  <div className={classes.cellUsageUnit}>
			  	{this.show_data(this.state.editable_device.cell_usage.total).split(" ")[1]}
			  </div>
    	      </CardContainer>
    	    </div>
    	  </div>
    	  <div className={classes.overviewTile}>
    	    <div className={classes.overviewTileMargin}>
    	      <CardContainer
    	        title="This Month's Usage"
    	        rootClass={classes.cardContainerRoot}
    	      >
    	        <div className={classes.cardContainerData}>
    	          {this.show_data(this.state.editable_device.cell_usage.month).split(" ")[0]}
    	        </div>
			  <div className={classes.cellUsageUnit}>
			  	{this.show_data(this.state.editable_device.cell_usage.month).split(" ")[1]}
			  </div>
    	      </CardContainer>
    	    </div>
    	  </div>
    	  <div className={classes.overviewTile}>
    	    <div className={classes.overviewTileMargin}>
    	      <CardContainer
    	        title="This Week's Usage"
    	        rootClass={classes.cardContainerRoot}
    	      >
    	        <div className={classes.cardContainerData}>
    	          {this.show_data(this.state.editable_device.cell_usage.week).split(" ")[0]}
    	        </div>
			  <div className={classes.cellUsageUnit}>
			  	{this.show_data(this.state.editable_device.cell_usage.week).split(" ")[1]}
			  </div>
    	      </CardContainer>
    	    </div>
    	  </div>
    	  <div className={classes.overviewTile}>
    	    <div className={classes.overviewTileMargin}>
    	      <CardContainer
    	        title="Today's Usage"
    	        rootClass={classes.cardContainerRoot}
    	      >
    	        <div className={classes.cardContainerData}>
    	          {this.show_data(this.state.editable_device.cell_usage.day).split(" ")[0]}
    	        </div>
			  <div className={classes.cellUsageUnit}>
				{this.show_data(this.state.editable_device.cell_usage.day).split(" ")[1]}
			  </div>
    	      </CardContainer>
    	    </div>
    	  </div>
    	</div>
		)
	}
		
  render() {
    const { classes } = this.props;
		const heading_info = [
			{label: "DATE/TIME", value: "created_at", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date"},
			{label: "TYPE", value: "type", field: "type", align: "left", disablePadding: false, sortable: true},
			{label: "DATA USAGE", value: "data_usage", field: "data_usage", align: "left", disablePadding: false, sortable: true},
			{label: "SESSION START", value: "session_start", field: "session_start", align: "left", disablePadding: false, sortable: true, content: "date"},
			{label: "SESSION END", value: "session_end", field: "session_end", align: "left", disablePadding: false, sortable: true, content: "date"},
			{label: "SOURCE", value: "source", field: "source", align: "left", disablePadding: false, sortable: false},
			{label: "ORIGINAL DATA", value: "show_original", field: "show_original", align: "left", disablePadding: false, sortable: false, content: "function"},
		];
	
    return (
			<Paper className={classes.container}>
				{this.renderEdgeCellUsage()}
				<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
					{this.state.modal.children(classes)}
				</SimpleModalWrapped>
				<div className={classes.tableContainer}>
					<EnhancedTable
						noSelect={true}
						headings={heading_info}
						loadFunction={this.loadDataRecords}
						actions={[]}
						filters={[]}>
					</EnhancedTable>
				</div>
			</Paper>
    );
  }

}


DeviceCellUsage.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(DeviceCellUsage));
