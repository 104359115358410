import React from 'react';
import {withStyles, withTheme} from '@material-ui/core/styles';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import DiscoveredDeviceCSVUpload from '../../services/DataModels/DiscoveredDeviceCSVUpload'
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Loading from '../DisplayOriented/Loading';
import { withRouter } from 'react-router-dom';
import Permissions from '../../services/Permissions';

//icons
import AttachmentIcon from '@material-ui/icons/Attachment';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
	modalWrapper: {
		fontFamily: "Inter",
		overflowY: "auto",
		height: "100%",
		maxHeight: "94vh",
		boxSizing: "border-box"
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "32px",
	},
	formatWrapper: {
		overflow: "scroll",
		paddingBottom: "12px",
		marginTop: "12px",
	},
	formatImage: {
		width: "100vw"
	},
	errorText: {
		fontFamily: "Inter",
		fontSize: "14px",
		marginRight: "14px",
		color: theme.palette.red.main
	},
	fabIcon: {
		marginRight: "4px"
	},
	linkOverride: {
		textDecoration: "none",
	},
	link: {
		fontFamily: "Inter",
		fontSize: "18px",
		margin: "14px 8px 14px 0",
		display: "inline",
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		}
	},
	subPrompt: {
		fontSize: "14px",
		color: "#8e8e93",
		marginBottom: "14px"
	},
	submitButtonWrapper: {
		width: "100%",
		textAlign: "right",
		marginTop: "32px",
	},
	responseWrapper: {
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "14px",
		width: "90%",
		display: "flex",
		justifyContent: "flex-start",
		margin: "12px 0"
	},
	responseContainer: {
		display: "block",
		textAlign: "center"
	},
	uploadSuccess: {
		color: theme.palette.green.main,
		marginRight: "8px",
		lineHeight: "35px",
		marginTop: "5px",
	},
	responseText: {
		fontSize: "14px",
		lineHeight: "35px",
		alignContent: "center",
	},
	response: {
		marginLeft: "12px",
	},
	fileSelectContainer: {
		marginBottom: "8px",
		marginTop: "16px",
	},
	fileSelectLabel: {
		fontSize: "18px",
		fontFamily: "Inter",
		color: "black",
		display: "flex",
		alignItems: "center",
		border: "solid white 1px",
		borderBottom: "1px solid rgba(0, 0, 0, 0.24)",
	},
	fileSelectError: {
		border: "solid white 1px",
		borderRadius: "4px",
		borderColor: theme.palette.red.main
	},
	label: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer"
	},
	fileSelectInput: {
		width: "0.1px",
		height: "0.1px",
		opacity: 0,
		overflow: "hidden",
		position: "absolute",
		zIndex: -1,
	},
	fileName: {
		fontSize: "18px",
		fontFamily: "Inter",
		color: "black",
		marginLeft: "12px",
		marginRight: "auto",
	},
	warningIcon: {
		color: theme.palette.caution.main,
		marginRight: "4px",
		fontSize: "38px"
	},
	errorIcon: {
		color: theme.palette.red.main,
		marginRight: "4px",
		fontSize: "38px"
	},
	errorTitle: {
		fontSize: "20px",
		fontFamily: "Inter",
		display: "flex",
		alignItems: "center",
		margin: "14px 0"
	},
	errorWrapper: {
		marginLeft: "44px"
	},
	rowNumber: {
		fontSize: "18px",
		fontFamily: "Inter",
	},
	errorMessage: {
		fontSize: "16px",
		fontFamily: "Inter",
		color: "#0000009c",
	},
	buttonOverride: {
		marginLeft: "8px",
	},
});

class CSVPromoteForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			loading: false,
			filename: "",
			show_error: false,
			error_message: null,
			response_id: null
		};
		this.domRef = React.createRef();
	}

	go_to_bulk_response = () => {
		this.props.history.push("Messages?bulk_response_id=" + this.state.response_id);
	}

	submit_files = () => {
		if (this.state.filename === "") {
			this.setState({show_error: true, error_message: "Please upload a file."})
			return;
		}
		this.setState({loading: true});
		let files = this.domRef.current.files;
		let csv = files[0];
		new DiscoveredDeviceCSVUpload().uploadCSV(csv).then( (response) => {
			this.context.openSnackbar("CSV upload initiated. Refer to the link below to monitor the promotion process.", "success");
			this.setState({loading: false, filename: "", response_id: response.bulk_response_id});
		}).catch( (error) => {
			if (error === "Your session has expired. Please login.") {
				this.context.openSnackbar(error, "error");
				return;
			}
			let error_message = "An error occurred. Please ensure the uploaded file was valid.";
			if (error && error.response && error.response.data && error.response.data.error) {
				error_message = error.response.data.error;
			}
			this.setState({show_error: true, loading: false, filename: "", error_message: error_message});
			this.context.openSnackbar("An error occurred while uploading the CSV file. Please try again.", "error");
		});
	}

	handle_file_change = (event) => {
		let id = function() {
			let ids = [];
			if (this.target.files && this.target.files.length > 1) {
				Object.entries(this.target.files).forEach( (entry) => {
					if (entry[1] && entry[1].name) {
						ids.push(entry[1].name);
					}
				});
				return ids;
			}
			return this.target.value;
		}.bind(event)();
		let filename = "";
		if (!Array.isArray(id)) {
			filename = id.split("\\")[id.split("\\").length - 1];
		} else {
			filename = id.reduce( (total, current) => {
				if (total === "") {
					return current;
				} else {
					return total + ", " + current;
				}
			}, "");
		}
		this.setState({filename: filename, show_error: false, error_message: null});
	}

	render() {
		const { classes } = this.props;
		const { loading, response_id } = this.state;
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					CSV - Promote Discovered Devices
				</div>
				<div className={classes.subPrompt}>
					Upload one or more CSV files to bulk promote discovered devices.
				</div>
				{loading === false ?
					<React.Fragment>
						<div className={classes.fileSelectContainer}>
							<div className={classes.fileSelectInput}>
								<input
									id="files"
									type="file"
									multiple
									name="upload_file"
									label="Device Upload Files"
									ref={this.domRef}
									onChange={this.handle_file_change}
								/>
							</div>
							<div className={classes.fileSelectLabel + " " + (this.state.show_error === true ? classes.fileSelectError: "")}>
								<Button color="primary" aria-label="Upload File">
									<label className={classes.label} htmlFor="files">
										<AttachmentIcon className={classes.fabIcon} />
										UPLOAD FILES
									</label>
								</Button>
								<div className={classes.fileName}>
									{this.state.filename}
								</div>
								{this.state.show_error === true ? 
									<div className={classes.errorText}>
										{this.state.error_message}
									</div>
									: ""
								}
							</div>
						</div>
						{response_id !== null && Permissions.allow(["read"], "bulk_response") ?
							<div className={classes.responseContainer}>
								<div className={classes.responseWrapper}>
									<CheckCircleIcon className={classes.uploadSuccess}/>
									<span className={classes.responseText}>Upload successful.</span>
									<Button color="primary" onClick={this.go_to_bulk_response} className={classes.response}>
										Monitor the onboarding process here.
									</Button>
								</div>
							</div>
							: ""
						}
						<div className={classes.submitButtonWrapper}>
							<Button
								color="primary"
								href="https://edgeiq-assets.s3.amazonaws.com/sample_bulk_promote_discovered_devices.csv"
								target="_blank"
								className={classes.buttonOverride}
							>
								Download Example File
							</Button>
							<Button
								onClick={this.submit_files}
								color="primary"
								variant="contained"
								size="large"
								aria-label="Upload"
								className={classes.buttonOverride}
							>
								submit
							</Button>
						</div>
					</React.Fragment>
					: <Loading />
				}
			</div>
		)
	}
}

CSVPromoteForm.contextType = SnackbarContext;

export default compose(
	withStyles(styles),
	withTheme(),
	withRouter
)
(CSVPromoteForm);

