import React from 'react';
import Loading from '../DisplayOriented/Loading';

import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-monokai";

//mui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

//inputs
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import SwitchInput from "../Inputs/SwitchInput";

//icons
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import CodeIcon from '@material-ui/icons/Code';

//services
import { GetAll } from '../../services/CLURDUtilities';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Translator from '../../services/DataModels/Translator';
import Company from '../../services/DataModels/Company';
import Auth from '../../services/Auth.js';
import Permissions from '../../services/Permissions';

class CreateCommandTranslatorForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.domRef = React.createRef();
		this.state = {
			filename: "",
			editable_copy: {
				company_id: '',
				name: '',
				script: Translator.cmd_types("template_cmd").default_value,
				type: 'template_cmd'
			},
			show_errors: false,
			editing: false,
			account_options: null,
			verifying: false,
			submitting: false
		};
		if (this.props.translator._id) {
			this.state.editing = true;
			this.state.editable_copy = {
				name: this.props.translator.name,
				script: this.props.translator.script,
				company_id: this.props.translator.company_id,
				type: this.props.translator.type,
				_id: this.props.translator._id
			};
			if (!Boolean(Translator.cmd_types(this.props.translator.type))) {
				this.state.editable_copy.type = "template_cmd";
			}
		}
		this.set_account_setting();
		this.script_type_options = Object.entries(Translator.cmd_types()).map(([key, value]) => ({ display: value.display, value: key }));
	}

	set_account_setting = () => {
		this.show_account_selection = false;
		this.state.editable_copy.company_id = this.state.editing ? this.props.translator.company_id : Auth.currentCompany()._id;
		let sub_accounts = Auth.currentUser().company_ids;
		if (sub_accounts.length > 1) {
			let valid_accounts = sub_accounts.filter((account) => Permissions.allow(["create"], "translator", account._id));
			if (valid_accounts.length > 1) {
				this.show_account_selection = true;
				this.load_account_options(valid_accounts);
			}
		}
	}

	load_account_options = (account_ids) => {
		let params = { _id_in: account_ids };
		GetAll("companies", params).then((result) => {
			let accounts = result.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
			this.setState({ account_options: accounts.map((account) => ({ display: account.name, value: account._id })) });
		}).catch((error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	onFormUpdate = ({ field, value }) => {
		this.setState((state) => {
			state.editable_copy[field] = value;
			if (field === "type") {
				state.editable_copy.script = Translator.cmd_types(value).default_value;
			}
			return state;
		});
	}

	basic_validation_failed = () => {
		const translator = this.state.editable_copy;
		return (translator.name === '' || (this.show_account_selection && translator.company_id == ''));
	}

	onSubmit = (event) => {
		event.preventDefault();
		this.setState({ verifying: true });
		let translator = this.state.editable_copy;
		if (this.basic_validation_failed()) {
			this.setState({ show_errors: true, verifying: false });
			return;
		}
		this.setState({ submitting: true });
		this.create(translator);
	}

	create = (translator) => {
		// let modified_translator = Translator.convert_for_api(translator);
		let modified_translator = translator;
		new Translator(modified_translator).setData(modified_translator).saveOrCreate().then((result) => {
			let message = this.state.editing ? "Translator saved." : "Translator created.";
			this.context.openSnackbar(message, "success");
			this.props.onCreate(result);
		}).catch((error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	render_account_input = () => {
		const { classes, theme } = this.props;
		const { editable_copy, show_errors, account_options } = this.state;
		if (!this.show_account_selection) return "";
		return (
			<div className={classes.textInputWrapper + " " + classes.accountInputWrapper}>
				<AccountCircleIcon className={classes.inputIcon} />
				<SelectInput
					error={show_errors && editable_copy.company_id === ""}
					error_message="Please select an account."
					label="Account"
					field="company_id"
					emitChange={this.onFormUpdate}
					priorState={this.state.editable_copy.company_id}
					options={account_options}
				/>
			</div>
		);
	}

	render_name_input = () => {
		const { classes } = this.props;
		const { editable_copy, show_errors } = this.state;
		return (
			<div className={classes.textInputWrapper}>
				<DescriptionIcon className={classes.inputIcon} />
				<TextInput
					id="name"
					label="Name*"
					emitChange={this.onFormUpdate}
					priorState={editable_copy.name}
					field="name"
					error={show_errors && editable_copy.name === ""}
					error_message={"Please define a name for this translator."}
				/>
			</div>
		);
	}

	render_script_type = () => {
		const { classes } = this.props;
		return (
			<div className={classes.textInputWrapper + " " + classes.selectInputWrapper}>
				<CodeIcon className={classes.inputIcon} />
				<SelectInput
					label="Command Translator Type"
					field="type"
					emitChange={this.onFormUpdate}
					priorState={this.state.editable_copy.type}
					options={this.script_type_options}
				/>
			</div>
		);
	}

	on_script_change = (new_script) => {
		this.setState((state) => {
			state.editable_copy.script = new_script;
			return state;
		});
	}

	render_script = () => {
		const { classes } = this.props;
		const { editable_copy } = this.state;

		return (
			<div className={classes.scriptInput}>
				<div className={classes.scriptLabel}>
					Script
				</div>
				<div className={classes.editorWrapper}>
					<AceEditor
						placeholder="Placeholder Text"
						mode="javascript"
						theme="monokai"
						onChange={this.on_script_change}
						fontSize={14}
						showPrintMargin={true}
						showGutter={true}
						padding={"12px"}
						highlightActiveLine={true}
						value={editable_copy.script}
						width={"100%"}
						setOptions={{
							enableBasicAutocompletion: false,
							enableLiveAutocompletion: false,
							enableSnippets: false,
							showLineNumbers: true,
							tabSize: 2,
						}}
					/>
				</div>
			</div>

		);

	}

	render_buttons = () => {
		const { classes } = this.props;
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={this.props.onCancel}
					color="primary"
					className={classes.buttonOverride}
					aria-label="cancel"
				>
					Cancel
				</Button>
				<Button
					disabled={this.state.verifying}
					variant="contained"
					color="primary"
					size="large"
					className={classes.buttonOverride}
					aria-label="create"
					type="submit"
				>
					{this.state.editing ? "Edit" : "Create"} Translator
				</Button>
			</div>
		)
	}

	render() {
		const { classes } = this.props;
		const { editable_copy, show_errors, account_options, submitting } = this.state;
		return (
			<div className={classes.container}>
				{submitting || (this.show_account_selection && account_options == null) ? <Loading />
					:
					<form onSubmit={this.onSubmit}>
						{this.render_account_input()}
						{this.render_name_input()}
						{this.render_script_type()}
						{this.render_script()}
						{this.render_buttons()}
					</form>}
			</div>
		)
	}
}

CreateCommandTranslatorForm.contextType = SnackbarContext;

const styles = (theme) => {
	return ({
		container: {
			margin: "24px auto 12px auto",
			width: "80%",
			fontFamily: "Inter"
		},
		textInputWrapper: {
			margin: "0 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		accountInputWrapper: {
			marginTop: "42px"
		},
		fabIcon: {
			fontSize: "22px",
			marginRight: "4px"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		powerIcon: {
			margin: "0 12px 0 0"
		},
		helpLink: {
			color: theme.palette.pending.main,
			cursor: "pointer",
			display: "flex",
			position: "relative",
			zIndex: 5,
			marginTop: "-18px",
			justifyContent: "flex-end",
		},
		buttonOverride: {
			marginLeft: "8px",
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		switchInputWrapper: {
			margin: "-10px 0 24px 0px",
			display: "flex",
			alignItems: "center"
		},
		switchWrapper: {
			marginTop: "-2px"
		},
		switchContainer: {
			marginBottom: "6px",
			marginTop: 0,
		},
		fileSelectContainer: {
			marginBottom: "28px",
			marginTop: "10px",
		},
		fileSelectLabel: {
			fontSize: "18px",
			fontFamily: "Inter",
			color: "black",
			display: "flex",
			alignItems: "center",
			borderBottom: "solid grey 2px",
		},
		uploadButton: {
			padding: 0,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0
		},
		label: {
			display: "flex",
			alignItems: "center",
			padding: "6px 16px",
			cursor: "pointer"
		},
		fileSelectInput: {
			width: "0.1px",
			height: "0.1px",
			opacity: 0,
			overflow: "hidden",
			position: "absolute",
			zIndex: -1,
		},
		fileName: {
			fontSize: "18px",
			fontFamily: "Inter",
			color: "black",
			marginLeft: "12px",
			marginRight: "auto",
		},
		fileError: {
			border: "solid red 1px",
			borderRadius: "4px",
			padding: "6px"
		},
		filesContainer: {
			marginTop: "24px"
		},
		filesTitle: {
			fontSize: "16px",
			fontFamily: "Inter",
			color: "grey",
			textTransform: "uppercase"
		},
		file: {
			fontSize: "14px",
			fontFamily: "Inter",
			color: "grey",
		},
		struck: {
			textDecoration: "line-through",
			fontSize: "14px",
			fontFamily: "Inter",
			color: "grey",
		},
		deleteFile: {
			color: "grey",
			cursor: "pointer"
		},
		fileRow: {
			display: "flex",
			flexWrap: "nowrap",
			justifyContent: "space-between",
			alignItems: "center",
			height: "36px"
		},
		iconButtonOverride: {
			padding: "4px"
		},
		undoButton: {
			margin: 0,
			color: theme.palette.pending.main
		},
		selectInputWrapper: {
			marginTop: "18px",
			minHeight: "78px",
			marginBottom: "26px"
		},
		editorWrapper: {
			backgroundColor: "#2f3129",
			padding: "12px 0"
		},
		scriptLabel: {
			color: "grey",
			fontSize: "18px",
			marginBottom: "12px"
		},
		scriptInput: {
			borderLeft: "solid lightgrey 1px",
			paddingLeft: "12px"
		}
	})
};

export default withStyles(styles)(withTheme()(CreateCommandTranslatorForm));

