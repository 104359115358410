import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CardContainer from '../../../components/Containers/CardContainer';
import SoftwareUpdateInfoView from "../../../components/SoftwareUpdatesSpecific/SoftwareUpdateInfoView";
import CreateSoftwareUpdateForm from "../../../components/SoftwareUpdatesSpecific/CreateSoftwareUpdateForm";
import IssueSoftwareUpdateForm from "../../../components/SoftwareUpdatesSpecific/IssueSoftwareUpdateForm";
import SoftwareUpdate from "../../../services/DataModels/SoftwareUpdate";
import EnhancedTable from '../../Table/EnhancedTable';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import SimpleModalWrapped from '../../Containers/SimpleModalWrapped';
import GatewayCommand from '../../../services/DataModels/GatewayCommand';
import Device from '../../../services/DataModels/Device';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Permissions from '../../../services/Permissions';

//icons
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import DvrIcon from '@material-ui/icons/DvrOutlined';

const styles = theme => ({
	container: {
		overflow: "hidden",
		width: "100%",
		padding: "12px",
		fontFamily: "Inter",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
			height: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#5555552b"
		}
	},
	pageContainer: {
		height: "100%",
	},
	contentContainer: {
		display: "flex",
		maxWidth: "100%",
		height: "calc(100% - 75px)",
		padding: "6px",
		overflow: "hidden",
		marginTop: "12px",
	},
	stepContentTitle: {
		fontFamily: "Inter",
		fontSize: "22px",
		marginBottom: "8px",
		flex: "100%",
	},
	modalWrapper: {
		padding: "24px"
	},
	stepContentSubtitle: {
		fontFamily: "Inter",
		fontWeight: 400,
		color: "grey",
		flex: "100%",
		marginBottom: "12px",
	},
	updatesCard: {
		width: "24%",
		display: "flex",
	},
	updateDetailCard: {
		width: "76%",
		display: "flex",
	},
	updateRow: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		padding: "12px",
		minHeight: "64px",
		height: "64px",
		'&:hover': {
			backgroundColor: "#1e55963d"
		}
	},
	selectedUpdate: {
		backgroundColor: "#1e55963d"
	},
	updateTitle: {
		fontFamily: "Inter",
		fontSize: "18px",
	},
	note: {
		fontFamily: "Inter",
		fontStyle: "italic",
		color: "black",
		fontSize: "18px",
		margin: "10px 0 10px 16px"
	},
	tabsContainer: {
		overflow: "scroll",
		display: "flex",
		width: "100%",
		position: "relative",
		boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, .14), 0px 3px 1px -2px rgba(0, 0, 0, .12)",
	},
	areaContainer: {
		width: "100%",
		display: "flex",
		flexWrap: "nowrap",
		overflow: "scroll",
		padding: "12px",
		backgroundColor: "#efefef"
	},
	devicesContainer: {
		flexDirection: "column",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
		marginLeft: "12px",
	},
	packagesContainer: {
		display: "flex",
		flexWrap: "wrap",
		width: "50%",
	},
	editPrompt: {
		marginBottom: "24px",
		fontFamily: "Inter",
		fontSize: "22px",
	},
	prompt: {
		fontFamily: "Inter",
		fontSize: "22px",
		marginBottom: "16px",
	},
	notShown: {
		display: "none",
	},
	shown: {
		display: "flex",
		width: "100%",
	},
	softwarePanel: {
		height: "calc(100% - 72px)",
	},
	link: {
		marginLeft: "4px",
		color: theme.palette.pending.main,
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer",
		},
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		width: "90%",
		textTransform: "unset"
	},
	noStatus: {
		marginLeft: "4px",
		color: "grey",
		textTransform: "unset"
	},
	status: {
		textTransform: "capitalize",
	},
	statusMessage: {
		color: "silver",
		padding: "12px",
		backgroundColor: "black",
		fontFamily: "monospace",
		overflowX: "auto",
		"&::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"&::-webkit-scrollbar": {
			width: "12px",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: '10px',
			backgroundColor: "#ffffffc2"
		}
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "24px"
	}
});

class SoftwareUpdateDetailTab extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			value: 0,
			modal: {
				open: false,
				children: () => ""
			},
			snackbar: {
				open: false
			},
			packages_selected: [],
			devices_selected: []
		}
		this.tabs = [ { title: "Packages", icon: (<CodeIcon />) }];
		this.tabRenderFunctions = [this.renderPackages];
		this.packageHeadingInfo = [
			{label: "Name", field: "name", disablePadding: false, sortable: false},
			{label: "File Count", field: "ui_file_count", disablePadding: false, sortable: false},
			{label: "Script", field: "script", disablePadding: false, sortable: false},
		];
		this.deviceHeadingInfo = [
			{label: "Name", field: "name", disablePadding: false, sortable: false},
			{label: "Unique ID", field: "unique_id", disablePadding: false, sortable: false},
			{label: "Heartbeat", field: "ui_status", disablePadding: false, sortable: false},
		];
		this.commandHeadingInfo = [
			{ label: "Created At", field: "created_at", align: "left", disablePadding: false, sortable: true, content: "date" },
			{label: "Device ID", field: "ui_device_unique_id", disablePadding: false, sortable: false},
			{label: "Software Package", field: "ui_package_name", disablePadding: false, sortable: false},
			{label: "Status", field: "ui_status", disablePadding: false, sortable: false},
		];
		this.set_tabs();
		this.set_table_headings();
		this.packages_actions = [];
		if (Permissions.allow(["update"], "software_update", this.props.deviceType.company_id)) {
			this.packages_actions.push({
				icon: <EditIcon />,
				fxn: this.edit_package,
				label: "Edit"
			});
		}
		if (Permissions.allow(["delete"], "software_update", this.props.deviceType.company_id)) {
			this.packages_actions.push({
				icon: <DeleteForeverIcon />,
				fxn: this.delete_package,
				label: "Delete"
			});
		}
	}

	edit_package = (pkg) => {
		return new Promise( (resolve, reject) => {
			this.setState({
				modal: {
					"open": true,
					yesFunction: null,
					children: (classes) => this.buildEditPackageView(classes, pkg)
				}
			});
		});
		
	}

	delete_package = (pkg) => {
		return new Promise( (resolve, reject) => {
			this.setState({
				modal: {
					open: true,
					prompt: "Are you sure you want to delete this package?",
					yesFunction: () => this.submit_delete(pkg, resolve),
					functionText: "DELETE",
					children: () => {}
				}
			});
		});
	}

	submit_delete = (pkg, resolve) => {
		new SoftwareUpdate().setData({_id: pkg._id}).deleteFromAPI().then( (result) => {
			this.context.openSnackbar("Package deleted.", "success");
			this.closeModal();
			resolve();
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
			this.closeModal();
			resolve();
		});
	}

	set_table_headings = () => {
		if (Permissions.allow(["read"], "user", this.props.deviceType.company_id)) {
			this.packageHeadingInfo.push({label: "Created By", field: "ui_username", disablePadding: false, sortable: false});
			this.commandHeadingInfo.push({label: "Issuer", field: "ui_username", disablePadding: false, sortable: false});
		}
	}

	set_tabs = () => {
		if (Permissions.allow(["read"], "device", this.props.deviceType.company_id)) {
			this.tabs.push({ title: "Devices", icon: (<DvrIcon />) });
			this.tabRenderFunctions.push(this.renderDevices);
		}
		if (Permissions.allow(["read"], "gateway_command", this.props.deviceType.company_id)) {
			this.tabs.push({ title: "Recent Updates", icon: (<SyncProblemIcon />) });
			this.tabRenderFunctions.push(this.renderCommands);
		}
	}
	
	createNewSoftwareUpdate = (editableCopy, files, id) => {
		editableCopy.device_type_id = this.props.deviceType._id;
		let fileArray = [];
		for (var i = 0; i < files.length; i++) {
			fileArray.push(files[i]);
		}
		if (id) {
			editableCopy._id = id;
		}
		new SoftwareUpdate(editableCopy).setData(editableCopy).createAndUploadFiles(fileArray).then((result) => {
			this.props.tabHostProxy.closeSelf();
			this.props.tabHostProxy.addTab("softwareUpdate", this.props.deviceType);
			this.props.tabHostProxy.refresh();
			let message = id ? "Package updated.": "Package created.";
			this.context.openSnackbar(message, "success");
		}).catch( (error) => {
			this.context.openSnackbar(error, 'error');
		});
	}

	buildEditPackageView = (classes,pkg) => {
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.editPrompt}>
					Edit this Software Package
				</div>
				<CreateSoftwareUpdateForm
					package={pkg}
					onCreate={this.createNewSoftwareUpdate}
					closeModal={this.closeModal}
				/>
			</div>
		
		);
	}

	buildNewPackageView = (classes) => {
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Create a New Software Package
				</div>
				<CreateSoftwareUpdateForm
					onCreate={this.createNewSoftwareUpdate}
					closeModal={this.closeModal}
				/>
			</div>
		
		);
	}

	buildUpdateView = (classes) => {
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Issue Software Updates
				</div>
				<IssueSoftwareUpdateForm
					type_id={this.props.deviceType._id}
					devices={this.state.devices_selected}
					package_id={this.state.packages_selected}
					closeModal={this.closeModal}
				/>
			</div>
		
		);
	}

	load_packages = (params = {}) => {
		return new Promise( (resolve) => {
			params.device_type_id = this.props.deviceType._id;
			new SoftwareUpdate().listFromAPI(params).then( (results) => {
				let packages = results.items;
				SoftwareUpdate.loadRequirements(packages).then( (packages) => {
					packages.forEach( (pkg) => {
						pkg.ui_username = pkg.nested_user ? pkg.nested_user.first_name + " " + pkg.nested_user.last_name : "Unknown User";
						pkg.ui_file_count = pkg.files && pkg.files.length > 0 ? pkg.files.length > 1 ? pkg.files.length + " files" : "1 file" : "No Files";
					});
					resolve({items: packages, total: results.total});
				});
			});
		});
	}

	view_status_message = (message) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: () => this.build_status_modal(message)
			}
		});
	}

	build_status_modal = ( message) => {
		return (
			<div className={this.props.classes.modalWrapper}>
				<div className={this.props.classes.statusMessage}>
					{message}
				</div>
				<div className={this.props.classes.buttonContainer}>
					<Button
						size="medium" color="primary" onClick={this.closeModal}
					>
						CLOSE
					</Button>
				</div>
			</div>
			
		);
	}

	load_commands = (params = {}) => {
		return new Promise( (resolve) => {
			params.command_type = "software_update";
			params.device_type_id = this.props.deviceType._id;
			new GatewayCommand().listFromAPI(params).then( (results) => {
				let commands = results.items;
				GatewayCommand.loadRequirements(commands).then( (commands) => {
					let individual_commands = [];
					commands.forEach( (cmd) => {
						let ui_username = cmd.nested_user ? cmd.nested_user.first_name + " " + cmd.nested_user.last_name : "Unknown User";
						let ui_package_name = cmd.nested_software_update ? cmd.nested_software_update.name : "Unknown Package";
						let ui_device_unique_id = "";
						let ui_status = "";
						let message = null;
						if (!cmd.statuses || Object.entries(cmd.statuses).length === 0) {
							individual_commands.push({
								ui_status: "Corrupted Data",
								ui_device_unique_id: ui_device_unique_id,
								ui_username: ui_username,
								ui_package_name: ui_package_name,
								created_at: cmd.created_at,
								_id: cmd._id
							});
						} else {
							if (cmd.attached_device_unique_ids && cmd.attached_device_unique_ids.length > 0) {
								cmd.attached_device_unique_ids.forEach( (uid) => {
									ui_device_unique_id = uid;
									if (cmd.statuses[uid]) {
										ui_status = cmd.statuses[uid].status;
										if (cmd.statuses[uid].status_message && cmd.statuses[uid].status_message !== '') {
											message = cmd.statuses[uid].status_message;
										}
									} else {
										ui_status = cmd.statuses[cmd.device_unique_id].status;
										if (cmd.statuses[cmd.device_unique_id].status_message && cmd.statuses[cmd.device_unique_id].status_message !== '') {
											message = cmd.statuses[cmd.device_unique_id].status_message;
										}
									}
									individual_commands.push({
										ui_status: message ? <span className={this.props.classes.status}>{ui_status}<span onClick={() => this.view_status_message(message)} className={this.props.classes.link}>View Status Message</span></span> : <span className={this.props.classes.status}>{ui_status}<span className={this.props.classes.noStatus}>No Status Message</span></span>,
										ui_device_unique_id: ui_device_unique_id,
										ui_username: ui_username,
										ui_package_name: ui_package_name,
										created_at: cmd.created_at,
										_id: cmd._id
									});
								});
							} else {
								if (cmd.statuses[cmd.device_unique_id].status_message && cmd.statuses[cmd.device_unique_id].status_message !== '') {
									message = cmd.statuses[cmd.device_unique_id].status_message;
								}
								individual_commands.push({
									ui_status: message ? <span className={this.props.classes.status}>{cmd.statuses[cmd.device_unique_id].status}<span onClick={() => this.view_status_message(message)} className={this.props.classes.link}>View Status Message</span></span> : <span className={this.props.classes.status}>{cmd.statuses[cmd.device_unique_id].status}<span className={this.props.classes.noStatus}>No Status Message</span></span>,
									ui_device_unique_id: cmd.device_unique_id,
									ui_username: ui_username,
									ui_package_name: ui_package_name,
									created_at: cmd.created_at,
									_id: cmd._id
								});
							}
						}
					});
					resolve({items: individual_commands, total: results.total});
				}).catch( (error) => {
					this.context.openSnackbar(error, 'error');
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, 'error');
			});
		});
	}

	load_devices = (params = {}) => {
		return new Promise( (resolve) => {
			params.device_type_id = this.props.deviceType._id;
			new Device().listFromAPI(params).then( (results) => {
				results.items.forEach( (device) => {
					device.ui_status = this.render_table_heartbeat(device);
				});
				resolve(results);
			});
		});
	}

	render_table_heartbeat = (device) => {
		const hb_map = {
			online: this.props.theme.palette.green.main,
			offline: this.props.theme.palette.heartbeat.offline.main,
			idle: this.props.theme.palette.heartbeat.idle.main,
			never_reported: "grey"
		};
		const color = hb_map[device.heartbeat_status];
		const display = device.heartbeat_status.replace(/_/g, " ");
		return (
			<div style={{display: "flex", alignItems: "center"}}>
				<div style={{marginRight: "4px", minWidth: "8px", width: "8px", height: "8px", borderRadius: "50%", backgroundColor: color}}>
					&nbsp;
				</div>
				<div style={{textTransform: "capitalize"}}>
					{display}
				</div>
			</div>
		);
	}

	issueUpdate = () => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => this.buildUpdateView(classes)
			}
		});
	}

	openModal = (classes) => {
		this.setState({
			modal: {
				"open": true,
				yesFunction: null,
				children: (classes) => this.buildNewPackageView(classes)
			}
		});
	}

	closeModal = (after_close) => {
		this.setState({ modal: {
			open: false, children: () => ""
		}});
	}

	closeSnackbar = () => {
		let newState = { snackbar: { open: false } };
		this.setState(newState);
	}


	render() {
		const {deviceType, classes, tabHostProxy} = this.props;
		return (
			<div className={classes.container}>
				<div className={classes.pageContainer}>
					<SimpleModalWrapped info={this.state.modal} closeModal={this.closeModal}>
						{this.state.modal.children(classes)}
					</SimpleModalWrapped>
					<div className={classes.stepContentTitle}>
						{deviceType.name} Software Management {this.state.test}
					</div>
					<div className={classes.stepContentSubtitle}>
						Upload new software packages, or select devices to push software updates.
					</div>
					<div className={classes.contentContainer}>
						<div className={classes.devicesContainer}>
							{this.renderTabs(classes)}
							{this.renderTab(this.state.value, classes)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	handleTabChange = (event, value) => {
		this.setState({ value });
	};

	handlePackageSelect = (table, id) => {
		this.setState( {packages_selected: id ? [id] : []} );
	}

	handleSelect = (table, id, addition) => {
		let field = "";
		if (table === "Packages") {
			field = "packages_selected";
		} else if (table === "Devices") {
			field = "devices_selected";
		}
		this.setState( (prev_state) => {
			let new_selections = prev_state[field];
			if (addition === true) {
				new_selections.push(id);
			} else if (addition === false) {
				let index = prev_state[field].indexOf(id);
				if (index !== -1) {
					new_selections.splice(index, 1);
				}
			} else if (addition === "all") {
				new_selections = id;
			}
			let new_state = {};
			new_state[field] = new_selections;
			return new_state;
		});
	}

	renderPackages = (classes) => {
		let buttons = Permissions.allow(["create"], "software_update", this.props.deviceType.company_id) ? [{
			icon: (<AddIcon />),
			function: (classes) => this.openModal(classes),
			display: "Create Package",
			onSelectShow: false,
		}] : undefined;
		return (
			<div className={classes.areaContainer}>
				<EnhancedTable noSelect={true} tableId={this.props.deviceType._id} title="Packages" headings={this.packageHeadingInfo} loadFunction={this.load_packages} buttons={buttons} actions={this.packages_actions} filters={[]}>
				</EnhancedTable>
			</div>
		);
	}

	renderDevices = (classes) => {
		let button = {
			function: (classes) => this.issueUpdate(classes),
			display: "Issue Updates",
			onSelectShow: true
		};
		return (
			<div className={classes.areaContainer}>
				<EnhancedTable title="Devices" tableId={this.props.deviceType._id} headings={this.deviceHeadingInfo} onSelect={this.handleSelect} buttons={[button]} loadFunction={this.load_devices} actions={[]} filters={[]}>
				</EnhancedTable>
			</div>
		);
	}

	renderCommands = (classes) => {
		return (
			<div className={classes.areaContainer}>
				<EnhancedTable noSelect={true} tableId={this.props.deviceType._id} title="Commands" headings={this.commandHeadingInfo} loadFunction={this.load_commands} actions={[]} filters={[]}>
				</EnhancedTable>
			</div>
		);
	}

	renderTabs = (classes) => {
		return (
			<Paper square className={classes.tabsContainer}>
				<Tabs
					className={classes.viewTabs}
					value={this.state.value}
					onChange={this.handleTabChange}
					variant="scrollable"
					scrollButtons="auto"
					indicatorColor="secondary"
					textColor="secondary"
				>
					{this.tabs.map((tab) => {
						return (
							<Tab
								key={tab.title}
								icon={tab.icon}
								label={tab.title}
							/>

						);
					})}
				</Tabs>
			</Paper>
		);
	}

	renderTab = (index, classes) => {
		return this.tabRenderFunctions.map( (render, render_index) => {
			if (index !== render_index) {
				return (
					<div key={render_index} className={classes.softwarePanel + " " + classes.notShown}>
						{render(classes)}
					</div>
				)
			} else {
				return (
					<div key={render_index} className={classes.softwarePanel + " " + classes.shown}>
						{render(classes)}
					</div>
				);
			}
		});
	}
}

SoftwareUpdateDetailTab.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(SoftwareUpdateDetailTab));
