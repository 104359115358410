import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import LookupInput from '../Inputs/LookupInput';
import { GetAll } from '../../services/CLURDUtilities';
import Device from '../../services/DataModels/Device';
import Integration from '../../services/DataModels/Integration';
import Tooltip from '@material-ui/core/Tooltip';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Loading from '../DisplayOriented/Loading';
import Button from '@material-ui/core/Button';
import Permissions from '../../services/Permissions';
import IntegrationForm from '../IntegrationsSpecific/IntegrationForm';

//icons
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';

const styles = theme => ({
	prompt: {
		fontSize: "20px",
		lineHeight: "32px",
		fontWeight: "700",
		color: "rgba(0, 0, 0, 0.87)",
		marginBottom: "20px",
	},
	modalWrapper: {
		fontFamily: "Inter",
		height: "100%",
		minHeight: "300px",
	},
	noPadding: {
		padding: 0,
	},
	inputContainer: {
		margin: "12px 0 12px 0",
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
	},
	inputIcon: {
		color: theme.palette.greyIcon.main,
		margin: "8px",
	},
	buttonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "200px",
	},
	saveButton: {
		marginLeft: "12px"
	},
	link: {
		marginLeft: "40px",
		paddingLeft: 0,
		paddingRight: 0,
	},
});

class AddIntegration extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			device_integration: null,
			integration_options: null,
			show_errors: false,
			no_integration: true,
			modal: "selecting",
			integration_type_options: null,
			saving: false
		};
		if (this.props.creating) {
			this.state.modal = "creating";
			this.open_create_integration();
		} else {
			this.load_integrations();
		}
		this.can_create = Permissions.allow(["create"], "integration", this.props.device.company_id);
	}

	load_integrations = () => {
		let device_integration_types = this.props.device.integration_types.map( (int) => {
			if (int.device_integration_type === true) {
				return int.type;
			} else {
				return undefined;
			}
		}).reduce( (total, current) => {
			if (!current) {
				return total;
			} else if (total === "" && current) {
				return current;
			} else if (current) {
				return total + "," + current;
			}
		}, "");
		GetAll("integrations", {type_in: device_integration_types}).then( (integrations) => {
			integrations = integrations.map( (integration) => ({value: integration._id, label: integration.name}));
			this.setState({integration_options: integrations});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_integration_change = ({value}) => {
		this.setState({device_integration: value || null, show_errors: value ? false : true});
	}

	save_device = () => {
		if (this.state.device_integration === null) {
			this.setState({show_errors: true});
			return;
		} else {
			this.setState({integration_options: null});
			let device = {_id: this.props.device._id};
			device.device_integration_id = this.state.device_integration.value;
			new Device().setData(device).saveOrCreate().then( (result) => {
				this.props.closeModal();
				this.context.openSnackbar("Integration added to this device.", "success");
				this.props.onSave(result);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			})
		}
	}

	open_create_integration = () => {
		if (this.state.modal !== "creating") this.setState({modal: "creating"});
		new Integration().loadIntegrationTypesAndOptions().then( (result) => {
			let { integrationTypes, integrationOptions } = result;
			integrationOptions = integrationOptions.filter( (option) => {
				return option.value === '' || integrationTypes[option.value].device_integration_type;
			});
			if (this.props.creating) {
				integrationOptions = [integrationOptions.find( (int) => int.value === 'aws_device_integrations')];
			}
			this.setState({
				integration_types: integrationTypes,
				integration_type_options: integrationOptions
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	create_integration = (integration) => {
		this.setState({saving: true});
		new Integration()
			.setData(integration)
			.createOrSave()
			.then( (integration) => {
				if (this.props.creating) {
					this.setState({integration_type_options: null, saving: false});
					this.context.openSnackbar("Greengrass integration created.", 'success');
					this.props.onSave(integration._id);
				} else {
					this.context.openSnackbar("Integration created. Click the 'SAVE' button to attach this integration to the device.", 'success');
					this.setState({integration_options: null});
					this.load_integrations();
					this.setState({device_integration: {value: integration._id, label: integration.name}, modal: "selecting"});
				}
			})
			.catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
	}

	render() {
		const { classes, closeModal } = this.props;
		const { device_integration, integration_type_options, integration_options, modal, saving} = this.state;
		const data_model = this.props.creating ? new Integration({type: 'aws_device_integrations', greengrass_core_install_url: ''}) : new Integration();
		if (modal === "creating") {
			// was not null and was not saving
			if (integration_type_options === null || saving) {
				return (
					<div className={classes.modalWrapper}>
						<Loading></Loading>
					</div>
				);
			}
			return (
				<div className={classes.modalWrapper + " " + (this.props.creating ? classes.noPadding : "")}>
					<div className={classes.prompt}>
						Create an Integration
					</div>
					<IntegrationForm
						className={classes.formContainer}
						integrationTypes={this.state.integration_types}
						integrationOptions={this.state.integration_type_options}
						dataModel={data_model}
						onSave={(integration) => this.create_integration(integration)}
						onDelete={this.deleteIntegration}
						cancel={this.props.cancel}
					/>
				</div>
			);
		}
		if (integration_options === null) {
			return (
				<div className={classes.modalWrapper}>
					<Loading></Loading>
				</div>);
		}
		if (integration_options.length === 0 && !this.can_create) {
			return (
				<div className={classes.modalWrapper}>
					<div className={classes.prompt}>
						Add an Integration
					</div>
					<div className={classes.lesserPrompt}>
						No integrations exist and you don't have the permissions to create one. You'll need to ask your account manager for help.
					</div>
					<div className={classes.buttonsContainer}>
						<Button variant="contained" color="primary" size="large" onClick={closeModal}>
							cancel
						</Button>
					</div>
				</div>
			)
		}
		return (
			<div className={classes.modalWrapper}>
				<div className={classes.prompt}>
					Add an Integration
				</div>
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon + " " + classes.lookupInputIcon} title="Integration">
						<ExtensionIcon />
					</Tooltip>
					{integration_options === null ? <Loading /> :
						<LookupInput
							priorState={{ suggestions: integration_options, values: device_integration }}
							label="Integration"
							single
							emitChange={this.handle_integration_change}
							error={this.state.show_errors}
							error_message="Please select an integration"
						/>
					}
				</div>
				{this.can_create ? <Button color="primary" onClick={this.open_create_integration} className={classes.link}>
					Create New Integration
				</Button> : ""}
				<div className={classes.buttonsContainer}>
					<Button color="primary" onClick={closeModal}>
						cancel
					</Button>
					<Button className={classes.saveButton} variant="contained" color="primary" size="large" onClick={this.save_device}>
						Save
					</Button>
				</div>
			</div>
		);
	}
}
AddIntegration.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(AddIntegration));