import React from 'react';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Device from '../../services/DataModels/Device';
import LookupInput from '../Inputs/LookupInput';
import Loading from '../DisplayOriented/Loading';
import DateSelection from '../Inputs/DateSelection';
import SwitchInput from "../Inputs/SwitchInput";
import SelectInput from '../Inputs/SelectInput';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = theme => ({
	actionInfo: {
		paddingTop: "22px",
		fontFamily: "Inter",
		color: "grey",
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		fontSize: "20px",
	},
	buttonContainer: {
		marginTop: "82px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	scheduleContainer: {
		marginBottom: "8px",
		marginTop: "16px",
		color: "grey",
	},
	scheduleText: {
		fontFamily: "Inter",
		fontSize: "18px",
	},
	label: {
		fontFamily: "Inter",
		fontSize: "18px",
	},
});

const SCHEDULE_REPEAT_OPTIONS = [
	{display:"Daily", value:"daily"},
	{display:"Weekly", value:"weekly"},
]

class IssueSoftwareUpdateForm extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			loading: false,
			schedule: false,
			lookup_package: null,
			suggestions: null
		};
	}

	handleLookupChange = (input) => {
		this.setState(() => {
			let new_lookup_package = this.state.lookup_package;
			let new_suggestions = this.state.suggestions;
			new_lookup_package = input.value;
			new_suggestions = input.suggestions;
			return {lookup_package: new_lookup_package, suggestions: new_suggestions};
		});
	}

	preSubmit = () => {
		let package_id = this.props.package_id.length > 0 ? this.props.package_id[0] : null;
		if (this.state.lookup_package) {
			package_id = this.state.lookup_package.value;
		}
		this.setState({loading: true});
		this.sendSoftwareUpdate(package_id, this.props.devices, this.state.schedule_data);
	}


	sendSoftwareUpdate = (package_id, device_ids, schedule_data) => {
		Device.bulkCommand("software_update", device_ids, schedule_data, package_id).then( (result) => {
			this.setState({loading: false});
			this.context.openSnackbar("The update process has successfully begun. Be sure to monitor the progress of the updates on the device detail page.", "success");
			this.props.closeModal();
		}).catch( (error) => {
			this.setState({loading: false});
			this.context.openSnackbar(error, "error")
		});
	}

	scheduleCommandChanged = (switchInput) => {
		if (switchInput.value) {
			let tonight = moment().endOf('day').toDate();
			let tomorrowEarlyMorning = moment(tonight).add(6, 'hours').toDate();
			this.setState({
				schedule: true,
				schedule_data: {
					start_datetime: tonight,
					end_datetime: tomorrowEarlyMorning,
					retry_interval: "daily"
				}
			});
		} else {
			this.setState({
				schedule: undefined,
				schedule_data: undefined
			});
		}
	}

	handleGatewayCommandScheduleChange = (control) => {
		let field = control.field;
		let val = control.value;
		if (typeof val === 'object') {
			// it's a date
			val = val.firstDate;
		}
		let currentScheduleOptions = this.state.schedule_data;
		currentScheduleOptions[field] = val;
		this.setState({
			schedule_data: currentScheduleOptions
		});
	}

	render() {
		const {classes, type_id, devices, package_id, closeModal, submit} = this.props;
		const { lookup_package, suggestions, loading } = this.state;
		let pkg_id = package_id.length > 0 ? package_id[0] : null;
		if (loading) {
			return <Loading />
		}
		return (
			<div className={classes.container}>
				{pkg_id ?
					<React.Fragment>
						<span className={classes.actionInfo}>
							{"Push this software package to " + devices.length + " device(s)?"}
						</span>
					</React.Fragment>
					:
					<React.Fragment>
						<LookupInput
							params={{device_type_id: type_id}}
							priorState={{values: lookup_package, suggestions: suggestions}}
							label="Package"
							model="software_updates"
							emitChange={this.handleLookupChange}
							valueField="_id"
							labelField="name"
							single
						/>
						<span className={classes.actionInfo}>
							{"Push this package to " + devices.length + " device(s)?"}
						</span>
					</React.Fragment>
				}
				<div className={classes.scheduleContainer}>
					<SwitchInput
						initial={this.state.schedule || false}
						emitChange={this.scheduleCommandChanged}
						color={"#009688"}
						onLabel="Schedule Software Update"
						offLabel="Schedule Software Update"
						field="schedule"
						location="start"
					/>
					{this.state.schedule ?
						<React.Fragment>
							<div className={classes.label}>Attempt update between</div>
							<DateSelection
								priorState={{firstDate: this.state.schedule_data.start_datetime}}
								key={"CommandScheduleStart"}
								field="start_datetime"
								emitChange={this.handleGatewayCommandScheduleChange}
							/>
							<div className={classes.label}>And</div>
							<DateSelection
								priorState={{firstDate: this.state.schedule_data.end_datetime}}
								key={"CommandScheduleEnd"}
								field="end_datetime"
								emitChange={this.handleGatewayCommandScheduleChange}
							/>
							<div className={classes.scheduleText}>If the device is unreachable, retry</div>
							<SelectInput
								emitChange={this.handleGatewayCommandScheduleChange}
								priorState={this.state.schedule_data.retry_interval}
								options={SCHEDULE_REPEAT_OPTIONS}
								field="retry_interval"
							/>
						</React.Fragment>
						:
						""
					}
				</div>
				<div className={classes.buttonContainer}>
					<Button
						color="primary"
						onClick={closeModal}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={this.preSubmit}
						disabled={ !pkg_id && (!lookup_package || lookup_package.length === 0) }
					>
						Issue Update(s)
					</Button>
				</div>
			</div>
		)
	}
}

IssueSoftwareUpdateForm.contextType = SnackbarContext;
export default withStyles(styles)(IssueSoftwareUpdateForm);