import React from 'react';
import { withStyles } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from '@material-ui/icons/Refresh';

const theme = {
	
};

const styles = {
	firstTab: {
		borderRadius: "4px 0 0 0",
	},
	tabTitle: {
		width: "85%",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		flex: "85%",
		height: "100%",
		fontSize: "14px",
		lineHeight: "24px",
	},
	inactiveTab: {
		color: "#8e8e93",
		position: "relative",
		backgroundColor: "#ececec",
		cursor: "pointer",
		"&:after": {
			right: 0,
			content: "''",
			position: "absolute",
			outline: "solid grey 1px",
			height: "40%",
			zIndex: 1,
		},
		"&:hover": {
			color: "black",
		},
	},
	lastTab: {
		borderRadius: "0 4px 0 0",
		"&:after": {
			display: "none",
		},
	},
	tab: {
		overflow: "hidden",
		fontFamily: "Inter",
		display: "flex",
		padding: "8px 14px 8px 24px",
		whiteSpace: "nowrap",
		flexGrow: 1,
		alignItems: "center",
	},
	activeTab: {
		color: "rgba(0,0,0,0.87)",
		backgroundColor: "#ffffff",
		marginBottom: "-1px",
		border: "1px solid lightgrey",
		borderBottom: "solid white 0px",
		boxShadow: "6px 0 6px -6px rgba(0, 0, 0, 0.25)",
		zIndex: "1",
		borderRadius: "4px 4px 0 0",
	},
	left: {
		"&:after": {
			display: "none",
		}
	},
	right: {
	},
	middle: {
	},
	closeTabContainer: {
		flex: "15%",
		display: "flex",
		flexWrap: "nowrap"
	},
	closeIcon: {
		marginLeft: "12px"
	},
	tabIcon: {
		color: "grey",
		display: "flex",
		marginLeft: "auto",
		marginRight: 0,
		fontSize: "20px",
		"&:hover": {
			backgroundColor: "lightgrey",
			borderRadius: "50%",
			color: "white",
			cursor: "pointer",
		}
	},
	invisible: {
		display: "none"
	},
};

class Tab extends React.Component {
	constructor(props) {
		super(props);
	}

	setTabClasses = (classes) => {
		let classString = classes.tab + " ";
		if (this.props.active == true) {
			classString += classes.activeTab;
		} else {
			classString += classes.inactiveTab;
		}
		if (this.props.location === "right") {
			classString += " " + classes.right;
		} else if (this.props.location === "left") {
			classString += " " + classes.left;
		} else {
			classString += " " + classes.middle;
		}
		if(this.props.index===0){
			classString += " " + classes.firstTab;
		}else if(this.props.totalTabs-1 === this.props.index) {
			classString += " " + classes.lastTab;
		}
		return classString;
	}

	render() {
		const {toggle, icon, active, title, index, refresh, isNew, classes, closeTab, hideRefresh} = this.props;
		return (
			<div onClick={toggle} className={this.setTabClasses(classes)}>
				<span className={classes.tabTitle}>
					{title}
				</span>
				<div className={classes.closeTabContainer}>
					<RefreshIcon className={active && !isNew && !hideRefresh ? classes.tabIcon : classes.invisible} onClick={() => refresh(index)}/>
					<CloseIcon className={classes.tabIcon + " " + classes.close + " " + (index !== 0 && active ? "" : classes.invisible)} onClick={() => closeTab(index)}/>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(Tab);
