import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';

//mui
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

//services
import SoftwareUpdate from '../../services/DataModels/SoftwareUpdate';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import { AssignNestedModels } from '../../services/CLURDUtilities';

//icons
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ErrorIcon from '@material-ui/icons/Error';

class PackageSelectionIput extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			page: 1,
			per_page: 5,
			device_type_id: null,
			total: null,
			packages_shown: null,
			package_ids: [],
			packages: []
		};
		this.load();
	}

	load = () => {
		const { page, per_page, device_type_id } = this.state;
		let params = { page: page, per_page: per_page, page_meta: true }
		if (device_type_id) {
			params.device_type_id = device_type_id;
		}
		new SoftwareUpdate().listFromAPI(params).then( (result) => {
			let packages = result.items;
			packages.forEach( (pkg) => {
				pkg.file_count_string = pkg.files !== null && pkg.files.length !== 0 ? pkg.files.length > 1 ? pkg.files.length + " Files" : "1 File" : "No Files";
			});
			Promise.all([AssignNestedModels("device_types", "device_type_id", packages), AssignNestedModels("companies", "company_id", packages)]).then( () => {
				this.setState( (state) => {
					state.packages_shown = packages;
					state.total = result.total;
					return state;
				});
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	filter = () => {

	}

	filter_change = () => {

	}
	
	page_change = (difference) => {
		this.setState( (state) => {
			state.page += difference;
			state.packages_shown = null;
			return state;
		}, this.load);
	}

	render_search = () => {

	}

	render_filter_button = () => {

	}

	render_selection_info = () => {
		const { package_ids, packages_shown } = this.state;
		const { classes } = this.props;
		if (package_ids.length === 0 || packages_shown == null) {
			return "";
		}
		return (
			<div className={classes.packagesSelected}>
				{package_ids.length} Package{package_ids.length > 1 ? 's' : ''} Selected.
			</div>
		);
	}

	render_table = () => {
		const { packages_shown } = this.state;
		const { classes } = this.props;
		return (
			<div className={classes.tableContainer}>
				{this.render_headings()}
				{packages_shown ? 
					<React.Fragment>
						<div>
							{packages_shown.map( (pkg) => (
								this.render_row(pkg)
							))}
						</div>
						{this.render_pagination_info()}
					</React.Fragment>
					:
					this.render_table_loading()
				}

			</div>
		);
	}

	render_pagination_info = () => {
		const { packages_shown, page, per_page, total } = this.state;
		const { classes } = this.props;
		let start = 0;
		let end = 0;
		if (packages_shown.length !== 0) {
			start = (per_page * (page - 1)) + 1;
			end = packages_shown.length + start - 1; 
		}
		return (
			<div className={classes.paginationContainer}>
				<div className={classes.paginationText}>
					{start} - {end} of {total}
				</div>
				<div className={classes.pageArrowsContainer}>
					<IconButton disabled={start <= 1} className={classes.pageButton} onClick={() => this.page_change(-1)} aria-label="page_left">
						<NavigateBeforeIcon />
					</IconButton>
					<IconButton disabled={end === total} className={classes.pageButton} onClick={() => this.page_change(1)} aria-label="page_right">
						<NavigateNextIcon />
					</IconButton>
				</div>
			</div>
		);
	}

	render_checkbox = (pkg) => {
		const { package_ids } = this.state;
		const { classes } = this.props;
		if (!pkg.nested_device_type || !pkg.nested_company) {
			return (
				<Tooltip title={"This package cannot be selected. It is missing necessary information."}>
					<ErrorIcon className={classes.errorIcon}/>
				</Tooltip>
			);
		}
		const checked = Boolean(package_ids.find( (id) => id === pkg._id));
		return (
			<CustomCheckbox
				checked={checked}
				onChange={this.handle_checkbox(pkg._id)}
				value={pkg._id}
				color={"primary"}
				className={classes.checkbox}
				inputProps={{
					'aria-label': 'selection checkbox',
				}}
			/>
		);
	}

	handle_checkbox = name => event => {
		let should_load = false;
		this.setState( (state) => {
			let index = state.package_ids.indexOf(name);
			if (index >= 0) {
				state.packages.splice(index, 1);
				state.package_ids.splice(index, 1);
				if (state.packages.length === 0) {
					state.device_type_id = null;
					should_load = true;
				}
			} else {
				should_load = true;
				let pkg = state.packages_shown.find( (pkg) => pkg._id === name);
				if (state.packages.length === 0) {
					state.device_type_id = pkg.device_type_id;
					state.page = 1;
				}
				state.packages.push(pkg);
				state.package_ids.push(name);
			}
			return state;
		}, () => {
			if (should_load) this.load();
			this.props.onChange(this.state.package_ids, this.state.packages);
		});
	}

	render_row = (pkg) => {
		const { classes, theme } = this.props;
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		const company_name = pkg.nested_company ? pkg.nested_company.name : <span className={classes.notFound}>Account Not Found</span>;
		const device_type_name = pkg.nested_device_type ? pkg.nested_device_type.name : <span className={classes.notFound}>Type Not Found</span>;
		return (
			<div key={pkg._id} className={classes.row + " " + classes.packageRow}>
				<div className={classes.selectedColumn}>
					{this.render_checkbox(pkg)}
				</div>
				<div className={classes.column + " " + classes.nameColumn}>
					{pkg.name}
				</div>
				<div className={classes.column + " " + classes.typeColumn}>
					{device_type_name}
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					{company_name}
				</div>
				<div className={classes.column + " " + classes.fileColumn}>
					{pkg.file_count_string}
				</div>
			</div>
		);
	}

	render_headings = () => {
		const { classes } = this.props;
		/*
		selection box, name and status, unique id, account, tags
		*/
		return (
			<div className={classes.row}>
				<div className={classes.selectedColumn}>
					SELECTED
				</div>
				<div className={classes.column + " " + classes.nameColumn}>
					NAME
				</div>
				<div className={classes.column + " " + classes.typeColumn}>
					Device Type
				</div>
				<div className={classes.column + " " + classes.accountColumn}>
					ACCOUNT
				</div>
				<div className={classes.column + " " + classes.fileColumn}>
					File Count
				</div>
			</div>
		);
	}

	render_table_loading = () => {
		return "";
	}

	render() {
		return (
			<div>
				<div>
					{this.render_search()}
					{this.render_filter_button()}
				</div>
				{this.render_table()}
				{this.render_selection_info()}
			</div>
		)
	}

}

PackageSelectionIput.contextType = SnackbarContext;
const styles = (theme) => {
	return ({
		pageButton: {
			padding: "4px",
			color: "#8e8e93",
		},
		errorIcon: {
			color: theme.palette.red.main
		},
		notFound: {
			color: theme.palette.red.main
		},
		pageButtonDisabled: {
			color: "grey",
			cursor: "unset"
		},
		paginationContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			margin: "4px 0"
		},
		paginationText: {
			fontSize: "14px",
			color: "#636366",
		},
		pageArrowsContainer: {
			marginLeft: "12px"
		},
		row: {
			display: "flex",
			alignItems: "center",
			color: "grey",
			padding: "12px 0",
			fontSize: "14px",
			borderBottom: "solid lightgrey 1px",
			boxSizing: "border-box",
			position: "relative"
		},
		packageRow: {
			color: "black"
		},
		column: {
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			padding: "0 4px",
			boxSizing: "border-box",
		},
		selectedColumn: {
			width: "15%",
			textAlign: "center",
		},
		nameColumn: {
			width: "25%",
			textAlign: "left"
		},
		accountColumn: {
			width: "20%",
			textAlign: "left"
		},
		typeColumn: {
			width: "20%",
			textAlign: "left"
		},
		fileColumn: {
			width: "20%",
			textAlign: "left"
		},
		checkbox: {
			padding: 0
		},
		tableContainer: {
			marginTop: "12px"
		},
		packagesSelected: {
			color: theme.palette.pending.main,
			marginLeft: "12px",
			fontSize: "16px"
		},
	});
};

const CustomCheckbox = withStyles({
	root: {
		color: "rgba(0, 0, 0, 0.24)",
	  '&$checked': {
		color: "#1153b6",
	  },
	},
	checked: {},
  })(props => <Checkbox color="default" {...props} />);

export default compose(
	withStyles(styles),
	withTheme(),
)(PackageSelectionIput);
