import React from 'react';
import TransferSelectionInput from '../Inputs/TransferSelectionInput';
import { compose } from 'recompose';
import Loading from '../DisplayOriented/Loading';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TableList from '../Table/TableList';

//inputs
import SelectInput from '../Inputs/SelectInput';

//services
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Auth from '../../services/Auth';
import { GetAll } from '../../services/CLURDUtilities';
import DeviceTransferRequest from '../../services/DataModels/DeviceTransferRequest';

//mui
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { withStyles, withTheme } from '@material-ui/core/styles';

//icons
import DevicesOtherIcon from '@material-ui/icons/DevicesOtherOutlined';

class BulkTransferFlow extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			issuing: false,
			submitting: false,
			loading_transfers: false,
			steps: [],
			step_render_functions: [],
			transfer_selection: {
				status: "normal",
				transfers: [],
				transfer_ids: [],
				transfers_prepped: false,
				filters: {}
			},
			show_errors: false,
			active_step: 0,
			selections: [],
			device_type_id: '',
			device_type_options: null
		};
		this.user_company_id = Auth.currentCompany()._id;
		this.set_steps();
		this.load_device_types();
	}

	load_device_types = () => {
		const params = {company_id: this.user_company_id};
		GetAll("device_types", params).then( (types) => {
			types.push({_id: '', name: "Select a Device Type"});
			this.setState({device_type_options: types.map( ({_id, name}) => ({display: name, value: _id}))});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	set_steps = () => {
		this.state.steps = ["Select Transfers", "Confirm & Submit"];
		this.state.step_render_functions = [this.render_step_0, this.render_step_1];
	}

	render_step_0 = () => {
		const { classes } = this.props;
		const { show_errors } = this.state;
		return (
			<div>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Select Pending Transfers
					</div>
				</div>
				<div className={classes.description}>
					Select transfer requests to issue. Only requests in the pending state can be selected for transfer.
				</div>
				<div className={classes.transferContainer}>
					<TransferSelectionInput hideFilters={true} onChange={this.selection_change}/>
				</div>
				{show_errors ? 
					<div className={classes.error}>
						Please select one or more transfer requests to continue.
					</div>
					:
					""
				}
			</div>
		);
	}

	selection_change = (transfer_ids, transfers, all_selected, formerly_all_selected, filters) => {
		this.setState( (state) => {
			if (state.show_errors && (all_selected || formerly_all_selected || transfers.length > 0)) {
				state.show_errors = false;
			}
			if (all_selected) {
				state.transfer_selection.status = "all_selected";
			} else if (formerly_all_selected) {
				state.transfer_selection.status = "formerly_all_selected";
			} else {
				state.transfer_selection.status = "normal";
			}
			state.transfer_selection.transfers = transfers;
			state.transfer_selection.transfer_ids = transfer_ids;
			state.transfer_selection.filters = filters;
			return state;
		});
	}

	load_and_progress = () => {
		const { transfer_selection } = this.state;
		this.setState({loading_transfers: true});
		if (transfer_selection.status !== "normal") {
			let params = { from_company_id_ne: this.user_company_id, status: "pending"};
			if (transfer_selection.status === "formerly_all_selected") {
				params._id_nin = transfer_selection.transfer_ids.join(",");
			}
			GetAll("device_transfer_requests", params).then( (transfers) => {
				DeviceTransferRequest.loadRequirements(transfers).then( () => {
					let selections = this.format_selections(transfers);
					this.setState({selections: selections, active_step: 1, loading_transfers: false});
				}).catch( (error) => {
					this.setState({loading_transfers: false});
					this.context.openSnackbar(error, "error");
				});
			}).catch( (error) => {
				this.setState({loading_transfers: false})
				this.context.openSnackbar(error, "error");
			});
		} else {
			let selections = this.format_selections(transfer_selection.transfers);
			this.setState({selections: selections, active_step: 1, loading_transfers: false});
		}
	}

	format_selections = (transfers) => {
		transfers.forEach( (transfer) => {
			transfer.device_count = Boolean(transfer.escrow_device_ids) && Array.isArray(transfer.escrow_device_ids) ? transfer.escrow_device_ids.length : 0;
		});
		return transfers;
	}

	render_step_1 = () => {
		const { classes } = this.props;
		const { show_errors, transfer_selection, selections, device_type_options } = this.state;
		const heading_info = [
			{label: "From Company", value: "from_company_id", nested_field: false, field: "from_company_id", align: "left", disablePadding: false, sortable: false},
			{label: "Device Count", value: "device_count", nested_field: true, field: "device_count", align: "right", disablePadding: false, sortable: false},
		];
		const on_change = ({field, value}) => {
			this.setState({device_type_id: value});
		};
		return (
			<div>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Select an Optional Device Type, Confirm Transfers, and Submit
					</div>
				</div>
				<div>
					<div className={classes.textInputWrapper}>
						<DevicesOtherIcon className={classes.inputIcon}/>
						{device_type_options ? (device_type_options.length > 0 ?
							<SelectInput
								label="Device Type"
								field="device_type_id"
								emitChange={on_change}
								priorState={this.state.device_type_id}
								options={device_type_options}
							/> : <span>Your account has no device types.</span>) : <Loading />
						}
					</div>
				</div>
				<div className={classes.tableContainer}>
					<TableList 
						headings={heading_info} 
						items={selections}
						perPage={5}/>
				</div>
			</div>
		);
	}

	render_issuing = () => {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.titleRow}>
					<div className={classes.title}>
						Beginning the Transfer...
					</div>
				</div>
				<Loading />
			</div>
		);
	}

	render_stepper_display = () => {
		const { active_step, steps } = this.state;
		const { classes } = this.props;
		return (
			<div>
				<Stepper
					classes={{root: classes.stepperRoot}}
					className={classes.stepper}
					activeStep={active_step}
				>
					{steps.map( (label, index) => (
						<Step key={label}>
							<StepLabel></StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
		);
	}

	render_step_buttons = () => {
		const { active_step, transfer_selection, loading_transfers, submitting } = this.state;
		const { onClose, classes } = this.props;
		let back = null;
		let next = null;
		let can_progress = transfer_selection.status === "all_selected" || transfer_selection.status === "formerly_all_selected" || transfer_selection.transfers.length > 0;
		const action = (new_step) => {
			this.setState({active_step: new_step});
		};
		if (active_step === 0) {
			back = { label: "Cancel", action: onClose };
			next = { label: "Next", action: this.load_and_progress
			};
		} else {
			back = { label: "Back", action: () => {
				action(0);
			}};
			next = { label: "Submit", action: this.submit };
			can_progress = true;
		}
		return (
			<div className={classes.buttonContainer}>
				<Button
					onClick={() => back.action()}
					color="primary"
					className={classes.button}
				>
					{back.label}
				</Button>
				<Button
					disabled={loading_transfers || submitting}
					color="primary"
					variant="contained"
					size="large"
					onClick={() => can_progress ? next.action() : this.setState({show_errors: true})}
					className={classes.button}
				>
					{next.label}
				</Button>
			</div>
		);
	}

	submit = () => {
		this.setState({ "submitting" : true });
		let ids = this.state.selections.map( ({_id}) => _id);
		DeviceTransferRequest.acceptTransfers(ids, this.state.device_type_id).then( () => {
			this.context.openSnackbar("Bulk transfer process beginning.", "success");
			this.props.onComplete();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		})

	}

	render_active_step = () => {
		const { classes } = this.props;
		const { active_step, steps, step_render_functions } = this.state;
		return (
			<React.Fragment>
				{steps.map( (step, index) => {
					return index !== active_step ? 
						<div key={step + "_content"} className={classes.stepContentContainer} style={{display: "none"}}>
							{step_render_functions[index] ? step_render_functions[index]() : ""}
							{this.render_step_buttons()}
						</div>
					: 
						<div className={classes.stepContentContainer} key={step + "_content"} style={{display: "flex"}}>
							{step_render_functions[index] ? step_render_functions[index]() : ""}
							{this.render_step_buttons()}
						</div>
				})}
			</React.Fragment>
		);
	}
	
	render() {
		const { classes } = this.props;
		const { issuing } = this.state;
		return (
			<div className={classes.container}>
				{issuing ? this.render_issuing() : 
					<React.Fragment>
						{this.render_stepper_display()}
						{this.render_active_step()}
					</React.Fragment>
				}
			</div>
		);
	}
}

BulkTransferFlow.contextType = SnackbarContext;
const styles = (theme) => {
	return ({
		container: {
		},
		titleRow: {
			display: "flex",
			marginBottom: "32px"
		},
		title: {
			display: "flex",
			marginRight: "12px",
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
		},
		description: {
			color: "#8e8e93",
			fontSize: "14px",
		},
		error: {
			color: theme.palette.red.main
		},
		stepper: {
		},
		buttonOverride: {
			textTransform: "unset",
			boxShadow: "none",
			color: "white",
			backgroundColor: theme.palette.pending.main,
			'&:hover': {
				backgroundColor: darken(theme.palette.pending.main, .2),
				borderColor: darken(theme.palette.pending.main, .2),
			},
			cursor: "pointer"
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "24px"
		},
		button: {
			marginLeft: "12px",
		},
		stepContentContainer: {
			fontFamily: "Inter",
			justifyContent: "space-between",
			flexDirection: "column",
			minHeight: "528px"
		},
		textInputWrapper: {
			margin: "50px 0 8px 0px",
			display: "flex",
			alignItems: "center"
		},
		inputIcon: {
			margin: "-24px 12px 0 0",
			color: theme.palette.grey.main
		},
		stepperRoot: {
			padding: 0,
			marginBottom: "32px",
		},
		transferContainer: {
			height: "415px",
			maxHeight: "415px",
			overflowY: "auto",
			paddingRight: "16px",
		},
		tableContainer: {
			height: "349px",
			maxHeight: "349px",
			overflowY: "auto",
			paddingRight: "16px",
		}
	});
};

export default compose(
	withStyles(styles),
	withTheme(),
)(BulkTransferFlow);
