import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';

//mui
import Tooltip from '@material-ui/core/Tooltip';

//inputs
import TextInput from '../../Inputs/TextInput';
import SelectInput from '../../Inputs/SelectInput';

//icons
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import InfoIcon from '@material-ui/icons/Info';

//services
import { GetAll } from '../../../services/CLURDUtilities';
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';
import Permissions from '../../../services/Permissions';
import Auth from '../../../services/Auth';

class CDStep1 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			updated_device: {
				name: "",
				serial: "",
				unique_id: "",
				company_id: "",
				nested_company: null
			},
			show_errors: false,
			company_options: null
		};
		this.load_companies();
	}

	load_companies = () => {
		GetAll("companies").then( (companiesunsorted) => {
			let companies = companiesunsorted.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
			companies = companies.map( (option) => ({ display: option.name, value: option._id, whole: option }));
			this.setState( (state) => {
				state.company_options = companies;
				if (this.props.preset) {
					state.updated_device.nested_company = companies.find( (company) => company.value === this.props.preset.company_id).whole;
				}
				state.updated_device.nested_company = state.company_options.find( (company) => company.value === Auth.currentCompany()._id).whole;
				state.updated_device.company_id = Auth.currentCompany()._id;
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	can_progress = () => {
		const passing = ( this.uid_is_valid() && this.account_is_valid() && this.name_is_valid() );
		if (passing) {
			return true;
		} else {
			if (this.state.show_errors !== true) this.setState({show_errors: true});
			return false;
		}
	}

	account_is_valid = () => {
		const updated_device = this.state.updated_device;
		const preset = this.props.preset;
		if (preset && preset.company_id) {
			return true;
		}
		return ( updated_device.company_id !== "" && Permissions.allow(["create"], "device", updated_device.company_id));
	}

	name_is_valid = () => {
		return this.state.updated_device.name !== "";
	}

	uid_is_valid = () => {
		const updated_device = this.state.updated_device;
		const pattern = /^[A-Za-z0-9_:-]{1,255}$/g
		if (updated_device.unique_id === "" || updated_device.unique_id.indexOf(" ") !== -1 || !pattern.test(updated_device.unique_id)) {
			return false;
		} else {
			return true;
		}
	}

	handle_change = ({field, value}) => {
		this.setState( (state) => {
			state.updated_device[field] = value;
			if (field === "company_id" && value !== "") {
				state.updated_device.nested_company = state.company_options.find( (company) => company.value === value).whole;
			}
			return state;
		});
	}

	render_name = () => {
		const { classes } = this.props;
		const { updated_device, show_errors } = this.state;
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Name" title="Name">
					<DescriptionIcon />
				</Tooltip>
				<TextInput
					field="name"
					emitChange={this.handle_change}
					priorState={updated_device.name}
					error={!this.name_is_valid() && show_errors}
					label="Name*"
					error_message="Please name this device."
					margin="none"
				/>
			</div>
		);
	}

	render_unique_id = () => {
		const { show_errors, updated_device } = this.state;
		const { classes } = this.props;
		const icon = (
			<Tooltip className={classes.uidIcon} key="Unique ID" title="Unique ID">
				<span className={classes.uidIcon}>
					<span className={classes.uidIconText}>
						ID
					</span>
				</span>
			</Tooltip>
		);
		return (
			<div className={classes.uidWrapper}>
				<div className={classes.uidInputContainer}>
					{icon}
					<TextInput
						margin="none"
						emitChange={this.handle_change}
						priorState={updated_device.unique_id}
						error={!this.uid_is_valid() && show_errors}
						error_message="A unique ID can only contain 1 to 255 letters, numbers, or the special charaters - , : , or _"
						label={"Unique ID*"}
						model={"devices"}
						field={"unique_id"}
						margin="none"
						helperText="Unique ID is case-sensitive. Note that if creating a gateway type device and using the default auto discovered unique id, the unique ID should be all lowercase."
					/>
				</div>
			</div>
		);
	}

	render_account = () => {
		const { classes, preset } = this.props;
		const { updated_device, show_errors } = this.state;
		return (
			<div className={classes.inputContainer}>
				<Tooltip className={classes.inputIcon} key="Account" title="Account">
					<AccountCircleIcon />
				</Tooltip>
				<SelectInput
					disabled={false}
					label="Account"
					field="company_id"
					emitChange={this.handle_change}
					priorState={updated_device.company_id}
					options={this.state.company_options}
					error={!this.account_is_valid() && show_errors}
					error_message={"Please select an account. If you have selected an account and see this error, then your role does not have permission to create devices for this account."}
				/>
			</div>
		);
	}

	render_serial = () => {
		const { classes } = this.props;
		const updated_device = this.state.updated_device;
		const icon = (
			<Tooltip title="Serial Number">
				<span className={classes.serialWrapper}>
					<span className={classes.serialText}>
						S\N
					</span>
				</span>
			</Tooltip>
		);
		return (
			<div className={classes.inputContainer}>
				{icon}
				<TextInput
					field="serial"
					emitChange={this.handle_change}
					priorState={updated_device.serial}
					label="Serial Number"
					margin="none"
				/>
			</div>
		);
	}

	render() {
		const { renderButtons, renderTitle, classes } = this.props;
		const { updated_device, company_options } = this.state;
		if (company_options == null) return <Loading />;
		return (
			<React.Fragment>
				<div>
					{renderTitle()}
					<div className={classes.inputWrapper}>
						{this.render_name()}
						{this.render_unique_id()}
						{this.render_serial()}
						{this.render_account()}
					</div>
				</div>
				{renderButtons(this.can_progress, updated_device)}
			</React.Fragment>
		);
	}
}

const styles = (theme) => {
	return ({
		inputWrapper: {
			height: "449px",
			overflowY: "auto",
		},
		uidWrapper: {
			margin: "12px 0 12px 0",
		},
		uidInputContainer: {
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap",
			width: "100%",
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap",
			width: "100%",
		},
		noteContainer: {
			marginLeft: "24px",
			display: "flex",
			fontSize: "14px",
			lineHeight: "22px",
			color: "#8e8e93",
		},
		uidIcon: {
			color: "#8e8e93",
			minWidth: "22px",
			maxWidth: "22px",
			height: "20px",
			fontSize: "14px",
			margin: "8px 10px 55px 0",
			paddingBottom: 0,
			display: "flex",
		},
		inputIcon: {
			color: "#8e8e93", 
			minWidth: "22px", 
			height: "20px",
			fontSize: "12px",
			margin: "8px 10px 32px 0",
			paddingBottom: 0,
			display: "flex",
		},
		uidIconText: {
			margin: "auto"
		},
		serialWrapper: {
			color: "#8e8e93",
			minWidth: "22px",
			maxWidth: "22px",
			height: "20px",
			fontSize: "14px",
			margin: "8px 10px 32px 0",
			display: "flex",
		},
		serialText: {
			margin: "auto"
		},
		infoIcon: {
			color: theme.palette.greyIcon.main,
			margin: "8px",
		},
	});
};

CDStep1.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(CDStep1));
