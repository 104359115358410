import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

import {withRouter} from 'react-router';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PortalConfiguration from '../../services/PortalConfiguration';

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

const styles = theme => ({
	root: {
		flexGrow: 1,
		boxShadow: "none",
		backgroundColor: "unset",
	},
	tabsRoot: {
		display: "flex",
		alignItems: "center"
	},
	tab: {
		opacity: 1,
		margin: "0 12px",
		minWidth: "120px",
		width: "unset",
		color: "white",
		minHeight: "unset",
		borderRadius: "6px",
		"&:hover": {
			backgroundColor: darken(theme.palette.primary.main, .4),
			cursor: "pointer"
		}
	},
	selected: {
		backgroundColor: darken(theme.palette.primary.main, .2),
		color: "white",
		"&:hover": {
			backgroundColor: darken(theme.palette.primary.main, .4),
			cursor: "pointer"
		}
	}
});

const tabs = [
	[
		{link: "/Dashboard", display: "Dashboard", key: "overview"},
		{link: "/Messages", display: "Messages", key: "messages"},
		{link: "/Alerts", display: "Alerts", key: "alerts"},
		{link: "/Transfers", display: "Transfers", key: "transfers"},
		{link: "/Reports", display: "Reports", key: "reports"},
	],
	[
		{link: "/Devices", display: "Devices", key: "devices"},
		{link: "/Device_Types", display: "Device Types", key: "types"},
		{link: "/Software", display: "Software", key: "software"},
		{link: "/Configs", display: "Configs", key: "configs"},
	],
	[
		{link: "/Ingestors", display: "Ingestors", key: "ingest"},
		{link: "/Pollable_Attributes", display: "Pollable Attributes", key: "pollable_attributes"},
		{link: "/Policies", display: "Policies", key: "policies"},
		{link: "/Integrations", display: "Integrations", key: "integrations"},
		{link: "/HA_Groups", display: "HA Groups", key: "ha_groups"},
	],
	[
		{link: "/Users", display: "Users", key: "users"},
		{link: "/Roles", display: "Roles", key: "roles"},
		{link: "/Accounts", display: "Accounts", key: "companies"}
	]
];

class SimpleNewTabs extends React.Component {
	render() {
		const { classes } = this.props;
		let currentPath = this.props.location.pathname;
		const current_tabs = tabs.find( (group) => {
			let indexInGroup = group.findIndex( (tab) => {
				return tab.link === currentPath;
			});
			return indexInGroup > -1;
		});
		const visible_tabs = current_tabs ? current_tabs.filter(tab => PortalConfiguration.navigationItemVisible(tab.key)) : [];
		let currentIndex = visible_tabs.findIndex( tab => tab.link === currentPath);
		return (
			<div className={classes.root}>
				<AppBar className={classes.root} position="static">
					{ visible_tabs.length > 0 ? <Tabs classes={{root: classes.tabsRoot}} TabIndicatorProps={{style: {display: "none"}}} value={currentIndex}>
							{visible_tabs.map( (tab) => {
								return (
									<Tab className={classes.tab} classes={{wrapper: classes.wrapper, selected: classes.selected}} component={Link} to={tab.link} key={tab.link} label={tab.display} >
									</Tab>
								);
							})}
						</Tabs>
						:
						<React.Fragment />
					}
				</AppBar>
			</div>
		);
	}
}



SimpleNewTabs.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	withTheme()
)(SimpleNewTabs);
