import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TableList from '../../Table/TableList';
import Loading from '../../DisplayOriented/Loading';

//mui
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

//icons
import HelpIcon from '@material-ui/icons/Help';
import RouterIcon from '@material-ui/icons/Router';
import InfoIcon from '@material-ui/icons/Info';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		title: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
			display: "flex",
			alignItems: "center"
		},
		addIcon: {
			height: "28px",
			width: "28px",
			color: theme.palette.greyIcon.main,
			margin: "0 0 0 12px",
			padding: 0,
			cursor: "pointer"
		},
		infoLabel: {
			color: "#8e8e93",
			marginRight: "4px",
		},
		infoContent: {
			color: "rgba(0, 0, 0, 0.87)",
		},
		nodeRenderArea: {
			margin: "12px 0 12px 0",
		},
		nodeAreaTitle: {
			display: "flex",
			alignItems: "center",
			color: "#8e8e93",
			marginBottom: "8px",
			fontSize: "14px",
		},
		tableListContainer: {
		},
		nodeIcon: {
			marginRight: "8px"
		},
		cardContents: {
			margin: "6px"
		},
		cardRow: {
			display: "flex",
			fontSize: "14px",
			padding: "6px"
		},
		subTitle: {
			fontSize: "14px",
			color: "#8e8e93",
		},
		buttonContainer: {
			marginTop: "24px",
			display: "flex",
			justifyContent: "flex-end"
		},
		button: {
			marginLeft: "12px"
		},
		greengrass: {
			color: theme.palette.green.main
		},
		loadingContainer: {
			textAlign: "center"
		},
		loadingText: {
			marginTop: "12px"
		},
		stepContainer: {
			height: "505px",
			overflowY: "auto",
		},
	});
};

class Step4 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			creating: false
		};
		this.controller_heading_info = [
			{label: "Name", value: "table_name", field: "table_name", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "Unique ID", value: "whole.unique_id", field: "unique_id", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Config", value: "nested_device_config.name", nested_field: true, field: "config", align: "left", disablePadding: false, sortable: false},
			{label: "Interface", value: "interface", field: "interface", nested_field: false, align: "left", disablePadding: false, sortable: false},
		];
		this.node_heading_info = [
			{label: "Name", value: "device.table_name", nested_field: true, field: "name", align: "left", disablePadding: false, sortable: false, content: "function"},
			{label: "Unique ID", value: "device.unique_id", field: "unique_id", nested_field: true, align: "left", disablePadding: false, sortable: false},
			{label: "Config", value: "config.name", nested_field: true, field: "config", align: "left", disablePadding: false, sortable: false},
			{label: "Interface", value: "interface", field: "interface", nested_field: false, align: "left", disablePadding: false, sortable: false},
		];
	}

	render_ha_group_help = () => {
		return {
			title: "Review",
			content: ["Review the information you've set for this new HA Group. Click the back button to go back to previous steps."],
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
	}
	
	render_info = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.nodeRenderArea}>
				<div className={classes.nodeAreaTitle}>
					<InfoIcon className={classes.nodeIcon}/>
					Information
				</div>
				<div className={classes.tableListContainer}>
					<Card
					>	
						<div className={classes.cardContents}>
							<div className={classes.cardRow}>
								<div className={classes.infoLabel}>
									HA Group Name:
								</div>
								<div className={classes.infoContent}>
									{this.props.ha_group.name}
								</div>
							</div>
							<div className={classes.cardRow}>
								<div className={classes.infoLabel}>
									Account:
								</div>
								<div className={classes.infoContent}>
									{this.props.ha_group.account_name}
								</div>
							</div>
							<div className={classes.cardRow}>
								<div className={classes.infoLabel}>
									Ports:
								</div>
								<div className={classes.infoContent}>
									{this.props.ha_group.ports.join(', ')}
								</div>
							</div>
							<div className={classes.cardRow}>
								<div className={classes.infoLabel}>
									Greengrass Integration:
								</div>
								<div className={classes.infoContent + " " + classes.greengrass}>
									{this.props.ha_group.greengrass_integration.name}
								</div>
							</div>
						</div>
					</Card>
				</div>
			</div>
		);
	}
	
	prep_controller = () => {
		let controller = this.props.ha_group.controller_device;
		controller.table_name = () => this.render_table_heartbeat({heartbeat_status: controller.whole.heartbeat_status, name: controller.whole.name});
		return [controller];
	}

	render_table_heartbeat = (device) => {
		const hb_map = {
			online: this.props.theme.palette.green.main,
			offline: this.props.theme.palette.heartbeat.offline.main,
			idle: this.props.theme.palette.heartbeat.idle.main,
			never_reported: "grey"
		};
		const color = hb_map[device.heartbeat_status];
		const display = device.name;
		return (
			<div style={{display: "flex", marginLeft: "-14px", alignItems: "center"}}>
				<div style={{marginRight: "4px", minWidth: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color}}>
					&nbsp;
				</div>
				<div>
					{display}
				</div>
			</div>
		);
	}

	render_controller = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.nodeRenderArea}>
				<div className={classes.nodeAreaTitle}>
					<RouterIcon className={classes.nodeIcon}/>
					Controller Device
				</div>
				<div className={classes.tableListContainer}>
					<TableList
						headings={this.controller_heading_info} 
						items={this.prep_controller()}
					/>
				</div>
			</div>
		);
	}

	render_nodes = () => {
		const classes = this.props.classes;
		return (
			<div className={classes.nodeRenderArea}>
				<div className={classes.nodeAreaTitle}>
					<SettingsRemoteIcon className={classes.nodeIcon}/>
					Nodes
				</div>
				<div className={classes.tableListContainer}>
					<TableList
						headings={this.node_heading_info} 
						items={this.props.ha_group.node_devices}
					/>
				</div>
			</div>
		);
	}

	create_ha_group = () => {
		this.setState({creating: true}, () => {
			this.props.create_ha_group();
		});
	}
	
	render() {
		const classes = this.props.classes;
		if (!this.props.ha_group) return "";
		if (this.state.creating) {
			return (
				<div className={classes.loadingContainer}>
					<Loading />
					<div className={classes.loadingText}>
						Creating your new HA Group...
					</div>
				</div>
			);
		}
		return (
			<React.Fragment>
				<div className={classes.stepContainer}>
					<div className={classes.title}>
						Review
						<IconButton onClick={() => this.context.openHelp(this.render_ha_group_help())} className={classes.addIcon} aria-label="help">
							<HelpIcon/>
						</IconButton>
					</div>
					<div className={classes.subTitle}>
						Review the configuration for this new HA Group.
					</div>
					{this.render_info()}
					{this.render_controller()}
					{this.render_nodes()}
				</div>
				<div className={classes.buttonContainer}>
					<Button
						color="primary"
						onClick={this.props.back}
						className={classes.button}
					>
						back
					</Button>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={this.create_ha_group}
						className={classes.button}
					>
						CREATE HA GROUP
					</Button>
				</div>
			</React.Fragment>
		);
	}
}
Step4.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(Step4));
