import DataModel from "./DataModel";
import { AssignNestedModels } from '../CLURDUtilities';
import { API_PROXY } from '../CLURD';
import Auth from '../Auth';

class DeviceTransferRequest extends DataModel {
	
	constructor() {
		super("device_transfer_requests");
	}

	static loadRequirements(transfers) {
		return new Promise( (resolve, reject) => {
			Promise.all([
				AssignNestedModels("companies", "from_company_id", transfers),
				AssignNestedModels("companies", "to_company_id", transfers),
				AssignNestedModels("device_types", "device_type_id", transfers),
			]).then( (result) => {
				resolve(transfers);
			}).catch( (error) => reject(error));
		});
	}

	static acceptTransfer(id, deviceTypeId) {
		const token = Auth.token();
		let env = window.API;
		if (env.charAt(env.length - 1) !== '/') {
			env += "/";
		}
		let body = {};
		if (Boolean(deviceTypeId)) {
			body.device_type_id = deviceTypeId;
		}
		const options = {
			method: "POST",
			url: `${env}device_transfer_requests/${id}/transfer`,
			headers: {
				'accept': 'application/json',
				'authorization': token,
			},
			data: body
		};
		return new Promise( (resolve, reject) => {
			API_PROXY(options, token).then( (result) => {
				resolve(result.data);
			}).catch( (error) => {
				reject(error);
			});
		});
	}

	static acceptTransfers(ids, deviceTypeId) {
		const token = Auth.token();
		let env = window.API;
		if (env.charAt(env.length - 1) !== '/') {
			env += "/";
		}
		let body = {ids: ids};
		if (Boolean(deviceTypeId)) {
			body.device_type_id = deviceTypeId;
		}
		const options = {
			method: "POST",
			url: `${env}device_transfer_requests/bulk/transfer`,
			headers: {
				'accept': 'application/json',
				'authorization': token,
			},
			data: body
		};
		return new Promise( (resolve, reject) => {
			API_PROXY(options, token).then( (result) => {
				resolve(result.data);
			}).catch( (error) => {
				reject(error);
			});
		});
	}
	
}

export default DeviceTransferRequest;