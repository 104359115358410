import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Loading from '../../DisplayOriented/Loading';
import Auth from '../../../services/Auth';
import { GetAll, AssignNestedModels } from '../../../services/CLURDUtilities';
import CreateDeviceForm from '../../DeviceSpecific/CreateDeviceForm';
import CreateConfigForm from '../../ConfigsSpecific/CreateConfigForm';
import Device from '../../../services/DataModels/Device';
import Config from '../../../services/DataModels/Config';

//inputs
import LookupInput from '../../Inputs/LookupInput';
import SelectInput from '../../Inputs/SelectInput';

//mui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

//icons
import HelpIcon  from '@material-ui/icons/Help';
import InfoIcon  from '@material-ui/icons/Info';
import RouterIcon from '@material-ui/icons/Router';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SettingsEthernetIcon  from '@material-ui/icons/SettingsEthernet';

//contexts
import { SnackbarContext } from '../../../services/ContextProviders/Snackbar';

const styles = (theme) => {
	return ({
		title: {
			fontSize: "20px",
			fontWeight: "700",
			lineHeight: "32px",
			color: "rgba(0, 0, 0, 0.87)",
			marginBottom: "32px",
			display: "flex",
		},
		addIcon: {
			height: "28px",
			width: "28px",
			color: "#8e8e93",
			margin: "0 0 0 12px",
			padding: 0,
			cursor: "pointer"
		},
		inputIcon: {
			color: theme.palette.greyIcon.main,
			margin: "8px",
			paddingBottom: "20px"
		},
		inputContainer: {
			margin: "12px 0 12px 0",
			alignItems: "center",
			display: "flex",
			flexWrap: "nowrap"
		},
		link: {
			marginLeft: "32px",
		},
		configLinks: {
			marginTop: "-18px",
			marginBottom: "32px"
		},
		configInput: {
			marginTop: "32px"
		},
		invalidConfig: {
			marginLeft: "40px",
			marginTop: "-18px",
			color: "red"
		},
		invalidController: {
			marginLeft: "40px",
			marginTop: "12px",
			color: theme.palette.red.main,
			fontSize: "14px",
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end"
		},
		button: {
			marginLeft: "12px"
		},
		infoIcon: {
			marginRight: "6px"
		},
		subTitle: {
			fontSize: "14px",
			color: "#8e8e93",
			display: "flex",
			alignItems: "center"
		},
		stepContainer: {
			height: "505px",
			overflowY: "auto",
		},
	});
};

class Step2 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			controller_device: null,
			config_controller_options: null,
			controller_config: '',
			interface_options: null,
			show_errors: false,
			creating_controller: false,
			creating_config: false,
			gateway_options: [],
			loading: false
		};
	}

	render_ha_group_help = () => {
		return {
			title: "Controller, Network Config, Interface",
			content: ["In this step we need to select or create a device to act as the controller node in the HA Group. The controller must be a " + Auth.currentCompany().aliases.gateway + " device because endpoints do not have network configurations.", "The controller's network configuration can be created or selected in this step, but it must have a least one connection interface that meets certain criteria. These criteria will be listed if you choose to create a network configuration. Once a valid network configuration is selected, you'll need to select the controller device's connection interface."],
			link: "https://dev.edgeiq.io/docs/",
			linkText: "Read More"
		};
	}

	create_controller = () => {
		this.setState({creating_controller: true});
	}

	create_config = () => {
		this.setState({creating_config: true});
	}

	edit_config = () => {
		//todo
	}

	create_integration = () =>{
		//todo
	}

	edit_integration = () => {
		//todo
	}

	handle_config_change = (input) => {
		let config_id = input.value;
		let config = null;
		let interface_options = null;
		if (config_id && config_id !== '') {
			config = this.state.config_controller_options.find( ({value}) => value === config_id).whole;
			interface_options = this.props.getInterfaceOptions(config);
		}
		this.setState( (state) => {
			state.controller_config = config_id;
			state.controller_device.nested_device_config = config;
			state.interface_options = interface_options;
			state.controller_interface = interface_options ? interface_options[0].value : null;
			return state;
		});
	}

	handle_interface_change = (input) => {
		this.setState( (state) => {
			state.controller_device.interface = input.value;
			return state;
		});
	}

	render_controller_errors = () => {
		const classes = this.props.classes;
		if (!this.state.show_errors) return "";
		let message = '';
		if (this.state.controller_device && this.invalid_type(this.state.controller_device.whole.nested_device_type)) {
			return (
				<div className={classes.invalidController}>
					This device is of a device type that does not support any of the necessary connections (ethernet-wan, ethernet-wan-lan, wifi) to be used in an HA Group. Please select a different device.
				</div>
			);
		} else if (!this.state.controller_device) {
			return (<div className={classes.invalidController}>Please select or create a controller device.</div>);
		} else if (this.state.controller_config === '') {
			return (<div className={classes.invalidConfig}>Please select a network config.</div>);
		} else {
			return "";
		}
	}

	load_configs = (preset) => {
		let params = {device_type_id: this.state.controller_device.whole.device_type_id};
		GetAll("device_configs", params).then( (configs) => {
			configs = configs.map( (config) => ({value: config._id, display: config.name, whole: config}));
			let config = configs.find( (config) => (config.value === this.state.controller_device.whole.device_config_id));
			configs.push({display: "Select a Network Config", value: ''});
			this.setState( (state) => {
				state.config_controller_options = configs || [];
				state.controller_config = config ? config.value : '';
				state.controller_device.nested_device_config = config ? config.whole : null;
				if (config) {
					let interface_options = this.props.getInterfaceOptions(config.whole);
					state.interface_options = interface_options;
					state.controller_interface = interface_options ? interface_options[0].value : null;
				}
				return state;
			});
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	handle_controller_change = (input) => {
		let value = input.value;
		if (!value) {
			this.setState( (state) => {
				state.controller_device = null;
				state.controller_config = '';
				state.show_errors = true;
				return state;
			});
			return;
		}
		this.setState( (state) => {
			state.controller_device = value;
			state.show_errors = this.invalid_type(value.whole.nested_device_type);
			return state;
		}, () => {
			this.load_configs();
		});
	}

	invalid_type = (type) => {
		let invalid = true;
		if (type && type.capabilities.network_connections && type.capabilities.network_connections.length > 0) {
			type.capabilities.network_connections.forEach( (connection) => {
				if (connection.type === "ethernet-wan" || connection.type === "ethernet-wan-lan" || connection.type === "wifi") {
					invalid = false;
					return;
				}
			});
		}
		return invalid;
	}

	next = (body) => {
		if (body) {
			this.setState({loading: true});
			new Device(body).createOrSave().then( (result) => {
				result.interface = body.interface;
				result.value = result._id;
				result.label = result.name + ": " + result.unique_id;
				result.nested_device_type = body.nested_device_type;
				result.nested_device_config = body.nested_device_config;
				result.whole = result;
				this.props.next({controller_device: result});
				this.setState({loading: false, creating_controller: false, controller_config: result.device_config_id, controller_device: result, controller_interface: result.interface}, this.load_configs);
			}).catch( (error) => {
				this.context.openSnackbar(error, "error");
			});
		} else if (!this.state.controller_device || this.state.controller_config === '' || (!this.state.interface_options || this.state.interface_options.length === 0)) {
			this.setState({show_errors: true});
		} else {
			this.props.next(this.map_state());
		}
	}

	map_state = () => {
		let controller_device = this.state.controller_device;
		controller_device.interface = this.state.controller_interface;
		return {
			controller_device: controller_device
		};
	}

	render_controller_select = () => {
		const classes = this.props.classes;
		if (!this.props.gateway_options) return <Loading/>;
		const gateway_options = this.props.gateway_options.concat(this.state.gateway_options);
		return (
			<React.Fragment>
				<div className={classes.subTitle}>
					<InfoIcon className={classes.infoIcon}/>
					Select a controller device. Suggestions below will only include devices of type {Auth.currentCompany().aliases.gateway} and devices that either don't have an integration or have the selected Greengrass integration.
				</div>
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} title="Controller">
						<RouterIcon />
					</Tooltip>
					<LookupInput
						priorState={{ suggestions: gateway_options, values: this.state.controller_device }}
						label="Select a Controller"
						field="controller_device"
						single
						model="devices"
						emitChange={this.handle_controller_change}
						error={this.state.show_errors && !this.state.controller_device}
					/>
				</div>
				<Button
					color="primary"
					onClick={this.create_controller}
					className={classes.link}
				>
					Create a new controller device
				</Button>
			</React.Fragment>
		);
	}

	on_config_create = (config) => {
		this.setState( (state) => {
			state.loading = true;
			return state;
		});
		new Config(config).createOrSave().then( (result) => {
			this.setState( (state) => {
				state.loading = false;
				state.creating_config = false;
				state.config_controller_options = null;
				state.controller_device.whole.device_config_id = result._id;
				state.controller_config = result._id;
			});
			this.load_configs();
		}).catch( (error) => {
			this.context.openSnackbar(error, "error");
		});
	}

	render_config_select = () => {
		const classes = this.props.classes;
		if (!this.state.controller_device || this.invalid_type(this.state.controller_device.whole.nested_device_type) ) return "";
		if (!this.state.config_controller_options) return <Loading />;
		return (
			<React.Fragment>
				<div className={classes.inputContainer + " " + classes.configInput}>
					<Tooltip className={classes.inputIcon} title="Network Config">
						<SettingsIcon />
					</Tooltip>
					<SelectInput
						field="controller_config"
						emitChange={this.handle_config_change}
						label="Network Config*"
						priorState={this.state.controller_config}
						options={this.state.config_controller_options}
						error={this.state.show_errors && this.state.controller_config === ''}
					/>
				</div>
				<div className={classes.configLinks}>
					<Button
						color="primary"
						onClick={this.create_config}
						className={classes.link}
					>
						Create a new network config
					</Button>
				</div>
				{this.render_interface_select()}
			</React.Fragment>
		);
	}

	render_interface_select = () => {
		const classes = this.props.classes;
		if (!this.state.controller_config || this.state.controller_config == '') return "";
		if (this.state.interface_options) {
			return (
				<div className={classes.inputContainer}>
					<Tooltip className={classes.inputIcon} title="Connection Interface">
						<SettingsEthernetIcon />
					</Tooltip>
					<SelectInput
						field="controller_interface"
						emitChange={this.handle_interface_change}
						label="Connection Interface*"
						priorState={this.state.controller_interface}
						options={this.state.interface_options}
					/>
				</div>
			);
		} else {
			return (
				<div className={classes.invalidConfig}>
					This network config does not have any of the necessary connection interfaces. Please edit this config or assign a different network config to this device.
				</div>
			);
		}
	}
	
	render() {
		const classes = this.props.classes;
		if (this.state.creating_controller) return (<CreateDeviceForm getInterfaceOptions={this.props.getInterfaceOptions} onCreate={this.next} cancel={() => this.setState({creating_controller: false})} types={this.props.types} integration={this.props.integration} company={this.props.company} />);
		if (this.state.creating_config) return (<CreateConfigForm getInterfaceOptions={this.props.getInterfaceOptions} onCreate={this.on_config_create} cancel={() => this.setState({creating_config: false})} company={this.props.company} type={this.state.controller_device.whole.nested_device_type} />);
		return (
			<React.Fragment>
				<div className={classes.stepContainer}>
					<div className={classes.title}>
						Select a Controller Device
						<IconButton onClick={() => this.context.openHelp(this.render_ha_group_help())} className={classes.addIcon} aria-label="help">
							<HelpIcon/>
						</IconButton>
					</div>
					{this.state.loading ? <Loading /> : ""}
					{this.render_controller_select()}
					{this.render_config_select()}
					{this.render_controller_errors()}
				</div>
				{this.state.loading ? "" : 
					<div className={classes.buttonContainer}>
						<Button
							color="primary"
							onClick={this.props.back}
							className={classes.button}
						>
							back
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={() => this.next()}
							className={classes.button}
						>
							next
						</Button>
					</div>
				}
			</React.Fragment>
		);
	}
}
Step2.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(Step2));
