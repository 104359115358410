import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import { darken } from '@material-ui/core/styles/colorManipulator';
import LookupInput from '../Inputs/LookupInput';
import MultiSelectInput from '../Inputs/MultiSelectInput';
import DateSelection from '../Inputs/DateSelection';
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';
import Company from '../../services/DataModels/Company';
import Tooltip from '@material-ui/core/Tooltip';
import StyleIcon from '@material-ui/icons/Style';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';

//icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ClearIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
	lookupContainer: {
		marginTop: "8px",
		marginBottom: '28px'
	},
	tagInputContainer: {
		display: "flex",
		alignItems: "center",
		width: "25%"
	},
	inputContainer: {
		margin: "12px 0 12px 0",
	},
	selectContainer: {
		marginTop: "18px",
	},
	container: {
		position: "relative",
		minHeight: "100%"
	},
	titleContainer: {
		paddingTop: "16px",
	},
	titleTab: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "grey",
		flexGrow: 1,
		paddingBottom: "16px",
	},
	activeTab: {
		borderBottom: "solid #549ae6 4px"
	},
	title: {
		fontFamily: "Inter",
		textTransform: "uppercase",
	},
	icon: {
		padding: "0 8px",
		marginRight: "4px",
	},
	filterArea: {
		maxHeight: "643px",
		backgroundColor: "white",
		boxSizing: "border-box",
		overflowY: "auto",
	},
	filterOptions:{
		maxHeight: "593px",
		minHeight: "450px",
		overflowY: "auto",
		paddingRight: "16px",
	},
	filterButtonArea: {
		marginTop: "8px",
		marginBottom: 0,
		display: "flex",
		justifyContent: "flex-end",
		flexWrap: "wrap"
	},
	buttonOverride: {
		marginLeft: "8px",
	},
	selectButton: {
		backgroundColor: theme.palette.green.main,
		color: "white",
		"&:hover": {
			backgroundColor: darken(theme.palette.green.main, .2),
			color: "white",
		}
	},
	closeModalIcon: {
		position: "absolute",
		top: "-51px",
		right: "-21px",
		cursor: "pointer",
		color: "rgba(0, 0, 0, 0.87)",
	},
	metaContainer: {
		display: "flex",
		flexWrap: "wrap",
		fontFamily: "Inter"
	},
	metaInput: {
		width: "40%",
		margin: "12px 12px 0 0 "
	},
	infoArea: {
		color: "#8e8e93",
		display: "flex",
		alignItems: "center",
		fontSize: "14px",
		letterSpacing: "0.4px",

	},
	infoIcon: {
		marginRight: "12px"
	},
	addIcon: {
		marginRight: "8px"
	},
	deleteIcon: {
		marginBottom: "14px",
		"&:hover": {
			color: "#0263fc",
		}
	},
	metaItem: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		paddingTop: "12px",
		marginBottom: "-24px"
	},
	addContainer: {
		display: "flex",
		alignItems: "center",
		marginTop: "18px"
	},
	tagsWrapper: {
		flexWrap: "wrap",
		borderTop: "solid lightgrey 1px",
		paddingTop: "12px"
	},
	tagData: {
		width: "100%",
		display: "flex",
		alignItems: "center"
	},
	tagLookupContainer: {
		display: "flex",
		width: "100%",
		alignItems: "center"
	},
	addTagButton: {
		cursor: "pointer"
	},
	tagsIcon: {
		paddingTop: "4px"
	}
});

class FilterSidebar extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			tab: 0,
			filters: {},
		};
		this.props.filters.forEach( (filter) => {
			if (filter.type === "select") {
				if (this.props.value) {
					this.state.filters[filter.label] =this.props.value[filter.field];
				} else {
					this.state.filters[filter.label] = filter.options[0].value;
				}
			}
			else if (filter.type === "checkbox") {
				this.state.filters[filter.label] = [];
				filter.options.forEach( (option, index) => {
					this.state.filters[filter.label][index] = true;
				});
			} else if (filter.type === "tags") {
				this.state.filters[filter.label] = {};
				this.state.filters[filter.label].text = "";
				this.state.filters[filter.label].key = 0;
				this.state.filters[filter.label].values = [];
			} else if (filter.type === "metadata") {
				this.state.filters["Metadata"] = [{key: '', value: ''}];
			} else {
				this.state.filters[filter.label] = null;
			}
		});
	}

	clearFilters = () => {
		let filters = {};
		this.props.filters.forEach( (filter) => {
			if (filter.type === "lookup" && this.state.filters[filter.label]) {
				filters[filter.label] = this.state.filters[filter.label];
				filters[filter.label].values = null;
			} else if (filter.type === "checkbox") {
				filters[filter.label] = [];
				filter.options.forEach( (option, index) => {
					filters[filter.label][index] = true;
				});
			} else if (filter.type === "select") {
				filters[filter.label] = filter.options[0].value;
			} else if (filter.type === "metadata") {
				filters[filter.label] = [{key: '', value: ''}];
			} else if (filter.type === "tags") {
				filters[filter.label] = this.state.filters[filter.label];
				filters[filter.label].values = null;
			} else {
				filters[filter.label] = null;
			}
		});
		this.setState(
			{filters: filters},
			() => {
				this.props.triggerFilter(this.state.filters);
				this.props.closeFilters();
			}
		);
	}

	handleChange = (input) => {
		this.setState( (state) => {
			let new_filters = state.filters;
			new_filters[input.label] = input.value;
			return {filters: new_filters};
		});
	}

	handleCheckboxChange = (label, option_index) => {
		this.setState( () => {
			let new_filters = this.state.filters;
			new_filters[label][option_index] = !new_filters[label][option_index];
			return {filters: new_filters};
		});
	}

	handleLookupChange = (input) => {
		this.setState( () => {
			let new_filters = this.state.filters;
			new_filters[input.label] = {};
			new_filters[input.label].values = input.value;
			new_filters[input.label].suggestions = input.suggestions;
			return {filters: new_filters};
		});
	}

	addTag = () => {
		this.setState( (state) => {
			if (state.filters["Tags"].values === null) {
				state.filters["Tags"].values = [];
			}
			state.filters["Tags"].values.push({label: state.filters["Tags"].text, value: state.filters["Tags"].text});
			state.filters["Tags"].text = "";
			state.filters["Tags"].key = state.filters["Tags"].key + 1;
			return state;
		})
	}

	handleTagChange = ({value}) => {
		this.setState( (state) => {
			if (value && value.length > 0) {
				state.filters["Tags"].values = value;
			} else {
				state.filters["Tags"].values = [];
			}
			state.filters["Tags"].key = state.filters["Tags"].key + 1;
			return state;
		})
	}

	buildInput = (input) => {
		const classes = this.props.classes;
		switch (input.type) {
			case "select":
				return (
					<div key={"SelectInput_" + input.label} className={this.props.classes.inputContainer + " " + this.props.classes.selectContainer}>
						<SelectInput
							emitChange={this.handleChange}
							priorState={this.state.filters[input.label]}
							options={input.options}
							note={""}
							label={input.label}
							onEnter={this.sendFilters}
							field={input.field}
						/>
					</div>
				);
			case "metadata":
				const remove_pair = (index) => {
					this.setState( (state) => {
						state.filters["Metadata"].splice(index, 1);
						return state;
					});
				};
				const add_pair = () => {
					this.setState( (state) => {
						state.filters["Metadata"].push({key: '', value: ''});
						return state;
					})
				};
				const change = (input, index) => {
					this.setState( (state) => {
						state.filters["Metadata"][index][input.field] = input.value;
						return state;
					});
				}
				return (
					<div key={"TextInput_" + input.label} className={this.props.classes.inputContainer + " " + this.props.classes.metaContainer}>
						<div className={this.props.classes.infoArea}>
							<InfoIcon className={this.props.classes.infoIcon}/>
							<span>Leaving one of the inputs empty will omit that key/value pair from the filter.</span>
						</div>
						{this.state.filters["Metadata"].map( ({key, value}, index) =>
							<div className={this.props.classes.metaItem} key={`filter_metadata_${index}`}>
								<div className={this.props.classes.metaInput}>
									<TextInput
										margin="none"
										emitChange={(input) => change(input, index)}
										priorState={this.state.filters["Metadata"][index].key}
										label={"Metadata Key"}
										field={"key"}
										onEnter={this.sendFilters}
									/>
								</div>
								<div className={this.props.classes.metaInput}>
									<TextInput
										margin="none"
										emitChange={(input) => change(input, index)}
										priorState={this.state.filters["Metadata"][index].value}
										label={"Metadata Value"}
										field="value"
										onEnter={this.sendFilters}
									/>
								</div>
								{this.state.filters["Metadata"].length === 1 ? "" : <IconButton onClick={remove_pair} className={classes.deleteIcon}><DeleteIcon/></IconButton>}
							</div>
						)}
						<div className={classes.addContainer} onClick={add_pair}>
							<Button color="primary" aria-label="Add Metadata Key Pair">
								<AddCircleIcon className={classes.addIcon}/>
								Add Metadata Key Pair
							</Button>
						</div>
					</div>
				);
			case "text":
				return (
					<div key={"TextInput_" + input.label} className={this.props.classes.inputContainer}>
						<TextInput
							margin="none"
							emitChange={this.handleChange}
							priorState={this.state.filters[input.label]}
							label={input.label}
							note={""}
							onEnter={this.sendFilters}
						/>
					</div>
				);
			case "tags":

				const handleChange = (value) => {
					this.setState( (state) => {
						state.filters[value.label].text = value.value;
						return state;
					})
				}
				let select_display = null
				if (this.state.filters[input.label].values) {
					if (this.state.filters[input.label].values.length > 0) {
						select_display = (
							<LookupInput
								priorState={this.state.filters[input.label]}
								placeholder="Add Tags Below"
								label="Tags"
								key={this.state.filters[input.label].key}
								emitChange={this.handleTagChange}
							/>
						)
					}
				}

				return (
					<div key={"TextInput_" + input.label} className={this.props.classes.lookupContainer}>
						{select_display}
						<div className={this.props.classes.tagInputContainer}>
							<TextInput
								variant="standard"
								label={input.label}
								emitChange={handleChange}
								priorState={this.state.filters[input.label].text}
								placeholder="Add Tag Filter"
								margin="none"
							/>
							<AddCircleIcon onClick={this.addTag} className={this.props.classes.addTagButton}/>
						</div>
					</div>
				);
			case "lookup":
				return (
					<div key={"LookupInput_" + input.label} className={this.props.classes.lookupContainer}>
						<LookupInput
							priorState={this.state.filters[input.label]}
							label={input.label}
							placeholder={input.placeholder}
							model={input.model}
							emitChange={this.handleLookupChange}
							valueField={input.valueField}
							labelField={input.labelField}
							onEnter={this.sendFilters}
						/>
					</div>
				);
			case "date":
				return (
					<div key={"DateInput_" + input.label} className={this.props.classes.lookupContainer}>
						<DateSelection
							range={input.range}
							priorState={this.state.filters[input.label]}
							label={input.label}
							emitChange={this.handleChange}
							onEnter={this.sendFilters}
						/>
					</div>
				);
			case "checkbox":
				return (
					<div key={"CheckboxInput_" + input.label} className={this.props.classes.inputContainer}>
						{input.options.map( (option, index) => (
							<FormControlLabel
								className={this.props.classes.formControlLabel}
								control={
									<Checkbox checked={this.state.filters[input.label][index]} onChange={() => this.handleCheckboxChange(input.label, index)} value={option} />
								}
								key={option}
								label={option}
								classes={{label: this.props.classes.formControlLabelText}}
							/>
						))}
					</div>
				);
		};
	}

	sendFilters = () => {
		this.props.triggerFilter(this.state.filters);
		this.props.closeFilters();
	}

	render() {
		const {filters, bulkActions, classes} = this.props;
		return (
			<div className={classes.container}>
				<ClearIcon onClick={this.props.closeFilters} className={classes.closeModalIcon}/>
				<div className={classes.filterArea}>
					<div className={classes.filterOptions}>
						{filters.map( (filter) => {
							return this.buildInput(filter);
						})}
					</div>
					<div className={classes.filterButtonArea}>
						<Button className={classes.buttonOverride} onClick={this.clearFilters} color="primary">
							CLEAR
						</Button>
						<Button className={classes.buttonOverride} onClick={this.sendFilters} variant="contained" color="primary" size="large">
							FILTER
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
FilterSidebar.contextType = SnackbarContext;
export default withStyles(styles)(withTheme()(FilterSidebar));
