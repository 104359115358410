import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import VerticalTabs from '../../DisplayOriented/VerticalTabs';
import TableList from '../../Table/TableList';

//mui
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

//icons
import DvrIcon from '@material-ui/icons/DvrOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StyleIcon from '@material-ui/icons/Style';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TimelineIcon from '@material-ui/icons/Timeline';
import CloudIcon from '@material-ui/icons/Cloud';

//services
import Auth from '../../../services/Auth';
import Device from '../../../services/DataModels/Device';

class CDStep5 extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			active_tab: 0,
		};
		this.gateway_alias = Auth.currentCompany().aliases.gateway;
		this.set_tabs();
		this.heading_info = [
			{label: "Name", value: "table_name", nested_field: false, field: "table_name", align: "left", disablePadding: false, sortable: false},
			{label: "Unique ID", value: "unique_id", field: "unique_id", nested_field: false, align: "left", disablePadding: false, sortable: false},
			{label: "Account", value: "nested_company.name", nested_field: true, field: "company_name", align: "left", disablePadding: false, sortable: false},
			{label: "Tags", value: "table_tags", field: "table_tags", nested_field: false, align: "left", disablePadding: false, sortable: false},
		];
		this.company_id = this.props.preset ? this.props.preset.company_id : this.props.device.company_id;
	}

	set_tabs = () => {
		this.tabs = [
			{label: "Basic Info", content: this.render_basic},
			{label: "Configuration", content: this.render_config},
			{label: "Extra Info", content: this.render_info},
			{label: "Device Relations", content: this.render_relations}
		];
	}

	render_basic = () => {
		const { device, classes } = this.props;
		if (!this.company_id || this.company_id === '' || !device.nested_company) return;
		const name_icon = (<DescriptionIcon />);
		const uid_icon = (
			<span className={classes.uidIcon}>
				<span className={classes.uidIconText}>
					ID
				</span>
			</span>
		);
		const comp_icon = (<AccountCircleIcon />);
		const sn_icon = (
			<span className={classes.serialWrapper}>
				<span className={classes.serialText}>
					S\N
				</span>
			</span>
		);
		return (
			<div>
				{this.render_value(name_icon, "Name", device.name)}
				{this.render_value(uid_icon, "Unique ID", device.unique_id)}
				{this.render_value(comp_icon, "Account", device.nested_company.name)}
				{this.render_value(sn_icon, "Serial Number", device.serial)}
			</div>
		);
	}

	render_config = () => {
		const { device, classes } = this.props;
		if (!device.log_config) return;
		const type_icon = (<DvrIcon />);
		const config_icon = (<SettingsIcon />);
		const int_icon = (<ExtensionIcon />);
		const forward_icon = (<CloudUploadIcon />);
		const local_icon = (<MoveToInboxIcon />);
		const cloud_icon = (<CloudIcon />);
		const integration_display = device.nested_integration ? device.nested_integration.name : "";
		const cloud_integration_display = device.nested_cloud_integration ? device.nested_cloud_integration.name : "";
		const config_display = device.nested_config ? device.nested_config.name : "";
		return (
			<div>
				{this.render_value(type_icon, "Device Type", device.nested_device_type.name)}
				{this.render_value(config_icon, "Network Config", config_display)}
				{this.render_value(cloud_icon, "Cloud Integration", cloud_integration_display)}
				{this.render_value(int_icon, "Integration", integration_display)}
				{this.render_value(forward_icon, "Forwarded Logs", this.capitalize(device.log_config.forward_level))}
				{this.render_value(local_icon, "Local Logs", this.capitalize(device.log_config.local_level))}
			</div>
		);
	}

	capitalize = (word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}

	render_info = () => {
		const { device, classes } = this.props;
		const locat_icon = (<LocationOnIcon />);
		const tags_icon = (<StyleIcon />);
		const pol_icon = (<SecurityIcon />);
		return (
			<div>
				{this.render_value(locat_icon, "Location", device.location)}
				{this.render_value(tags_icon, "Tags", this.render_chips())}
				{this.render_value(pol_icon, "Policies", this.render_policies())}
				{this.render_heartbeat()}
			</div>
		);
	}

	render_heartbeat = () => {
		const { device, classes } = this.props;
		const icon = (
			<div className={classes.heartbeatIconsContainer}>
				<FavoriteIcon className={classes.heartbeatIcon} />
				<TimelineIcon className={classes.overlayIcon}/>
			</div>
		);
		const label = "Heartbeat";
		const message = device.heartbeat_data.heartbeat_period == 0 ? <span className={classes.unset}>"Heartbeat Period Unset"</span> : <span className={classes.value}>{`Generate Heartbeat Report Every ${device.heartbeat_data.heartbeat_period} ${device.heartbeat_data.heartbeat_time}`}</span>;
		let display = <span className={classes.unset}>Heartbeat Fields Unset</span>;
		if (device.heartbeat_data.heartbeat_values && device.heartbeat_data.heartbeat_values.length > 0) {
			display = device.heartbeat_data.heartbeat_values.map( (value) => (
					<Chip
						clickable={false}
						classes={{root: classes.chipRoot}}
						key={value}
						label={Device.getHeartbeatOptions()[value].label}
					/>
				)
			);
		}
		return (
			<React.Fragment>
				<div className={classes.reviewSection}>
					<Tooltip className={classes.inputIcon} key={label} title={label}>
						{icon}
					</Tooltip>
					<div className={classes.labelAndValue}>
						<div className={classes.label}>Heartbeat Period</div>
						{message}
					</div>
				</div>
				{device.is_gateway ?
					<div className={classes.heartbeatFieldsContainer}>
						<div className={classes.label}>Heartbeat Fields</div>
						{display}
					</div>
					: ""
				}
			</React.Fragment>
			
		);
	}

	render_devices = () => {
		const { device, classes } = this.props;
		if (device.prepped_devices && device.prepped_devices.length > 0) {
			return (
				<TableList
					headings={this.heading_info} 
					items={device.prepped_devices}
					perPage={5}
				/>
			);
		} else {
			return (<div className={classes.unset + " " + classes.unsetDevices}>None</div>);
		}
	}

	render_relations = () => {
		const { device, classes } = this.props;
		const device_icon = (<DvrIcon />);
		const label = !device.is_gateway ? this.gateway_alias + " Device" : "Attached Endpoints";
		return (
			<div>
				<div className={classes.reviewSection}>
					<Tooltip className={classes.inputIcon} key={label} title={label}>
						{device_icon}
					</Tooltip>
					<div className={classes.devicesLabel}>
						{label}
					</div>
				</div>
				{this.render_devices()}
			</div>
		);
	}

	assign_table_fields = (devices) => {
		const { theme, classes } = this.props;
		const status_map = {offline: theme.palette.heartbeat.offline.main, online: theme.palette.green.main, idle: theme.palette.heartbeat.idle.main, never_reported: theme.palette.grey.main};
		if (!devices || devices.length === 0) return [];
		devices.forEach( (device) => {
			device.table_tags = device.tags && device.tags.length > 0 ? device.tags.join(", ") : "No tags";
			device.table_name = (
				<div className={classes.nameContainer}>
					<div style={{backgroundColor: status_map[device.heartbeat_status]}} className={classes.statusDot}>
						&nbsp;
					</div>
					<span className={classes.name}>{device.name}</span>
				</div>
			);
		});
		return devices;
	}

	render_chips = () => {
		const { device, classes } = this.props;
		if (!device.tags || device.tags.length === 0) return ( <span className={classes.unset}>No tags set</span> );
		return (
			<div className={classes.chipContainer}>
				{device.tags.map( (tag) => (
					<Chip
						clickable={false}
						classes={{root: classes.chipRoot}}
						key={tag}
						label={tag}
					/>
				))}
			</div>
		);
	}
	
	render_policies = () => {
		const { device, classes } = this.props;
		if (!device.rules || device.rules.length === 0) return ( <span className={classes.unset}>No policies set</span> );
		return (
			<div className={classes.ruleContainer}>
				{device.rules.map( ({whole}) => (
					<Chip
						clickable={false}
						classes={{root: classes.chipRoot}}
						key={whole._id}
						label={whole.description}
					/>
				))}
			</div>
		);
	}

	render_value = (icon, label, value) => {
		const { classes } = this.props;
		let value_shown = ( <div className={classes.value}>{value}</div> );
		if (!value || value === '') {
			value_shown = ( <div className={classes.unset}>Unset</div> );
		}
		return (
			<div className={classes.reviewSection}>
				<Tooltip className={classes.inputIcon} key={label} title={label}>
					{icon}
				</Tooltip>
				<div className={classes.labelAndValue}>
					<div className={classes.label}>{label}</div>
					{value_shown}
				</div>
			</div>
		);
	}

	tab_change = (tab, index) => {
		this.setState({active_tab: index});
	}

	render() {
		const { renderButtons, renderTitle, device, classes } = this.props;
		const { active_tab } = this.state;
		return (
			<React.Fragment>
				<div>
					{renderTitle()}
					<div className={classes.inputWrapper}>
						<div className={classes.verticalWrapper}>
							<VerticalTabs tabs={this.tabs} onChange={this.tab_change}/>
						</div> 
						<div className={classes.contentWrapper}>
							{this.tabs[active_tab].content()}
						</div>
					</div>
				</div>
				{renderButtons(() => true, device)}
			</React.Fragment>
		);
	}
}

const styles = (theme) => {
	return ({
		inputWrapper: {
			display: "flex",
			height: "449px",
			overflowY: "auto",
			overflowX: "hidden",
		},
		chipRoot: {
			margin: "0 4px 4px 0"
		},
		verticalWrapper: {
			width: "33%"
		},
		contentWrapper: {
			width: "66%",
			padding: "0 32px",
			boxSizing: "border-box",
			borderLeft: "solid #d3d3d35c 1px",
			backgroundColor: "#f5f5f7",
		},
		inputIcon: {
			color: "#8e8e93", 
			minWidth: "22px", 
			height: "20px",
			fontSize: "12px",
			margin: "8px 10px",
			paddingBottom: 0,
			display: "flex",
			alignItems: "center"
		},
		labelAndValue: {
			display: "flex",
			flexDirection: "column",
		},
		heartbeatFieldsContainer: {
			margin: "0 0 12px 42px"
		},
		label: {
			width: "100%",
			color: "#8e8e93",
			textTransform: "uppercase",
			fontSize: "14px"
		},
		devicesLabel: {
			color: "grey",
			textTransform: "uppercase",
			fontSize: "14px"
		},
		value: {
			width: "100%",
			fontSize: "14px",
		},
		unset: {
			color: "#9e9e9e",
			width: "100%",
			fontSize: "14px",
		},
		unsetDevices: {
			marginLeft: "42px"
		},
		reviewSection: {
			display: "flex",
			margin: "12px 0",
			alignItems: "center",
		},
		uidIconText: {
			margin: "auto"
		},
		heartbeatIconsContainer: {
			position: "relative",
			color: theme.palette.greyIcon.main,
			display: "flex",
			margin: "8px 10px 12px 10px",
		},
		heartbeatIcon: {
			color: "#8e8e93", 
			minWidth: "22px", 
			height: "20px",
		},
		overlayIcon: {
			position: "absolute",
			left: 0,
			color: "white",
			minWidth: "22px", 
			height: "20px",
		},
	});
};

export default withStyles(styles)(withTheme()(CDStep5));
