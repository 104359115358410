/* eslint-disable no-unused-expressions, react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import Role from '../../services/DataModels/Role';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { GetAll } from '../../services/CLURDUtilities';

//contexts
import { SnackbarContext } from '../../services/ContextProviders/Snackbar';

const styles = theme => ({
    root: {
        width: "100%",
    },
    error: {
        borderBottom: `solid ${theme.palette.red.main} 2px`
    },
    errorMessage: {
        color: theme.palette.red.main,
        fontFamily: "Inter",
        marginTop: "8px",
        fontSize: "14px",
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipWarning: {
        padding: "2px",
        color: "red",
        marginRight: "4px"
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    loadingText: {
        fontFamily: "Inter"
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 14,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "75%",
        color: "#8e8e93",
    },
    paper: {
        position: 'absolute',
        zIndex: 10,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    labelOverride: {
        textTransform: "unset"
    }
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            onChange={props.selectProps.handleTypeChange}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    let label = props.children;
    if (props.selectProps.warningFunction) {
        let warning = props.selectProps.warningFunction.check(props.data.whole, props.selectProps.warningFunction.data);
        if (warning !== null) {
            label = (
                <React.Fragment>
                    <Tooltip title={warning}>
                        <IconButton
                            className={props.selectProps.classes.chipWarning}
                            aria-label={warning}
                        >
                            <ErrorIcon />
                        </IconButton>
                    </Tooltip>
                    {props.children}
                </React.Fragment>
            );
        }
    }
    return (
        <Chip
            tabIndex={-1}
            label={label}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            classes={{ label: props.selectProps.classes.labelOverride }}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class LookupInput extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        if (this.props.priorState === null || this.props.priorState.suggestions === null) {
            this.suggestions = null;
            this.loadModels();
        }
    }

    handleKeyPress = (event) => {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            this.props.onEnter ? this.props.onEnter(this.multi) : "";
        }
    }

    loadModels = () => {
        let fetch = null;
        let params = this.props.params || {};
        if (!this.props.custom && this.props.model) {
            GetAll(this.props.model, params)
                .then(this.processSuggestions)
                .catch((error) => {
                    this.context.openSnackbar(error, "error");
                });
        } else {
            switch (this.props.custom) {
                case "parent":
                    params.type = "gateway";
                    GetAll("device_types", params).then((types) => {
                        let hash = types.reduce((hashed, type) => { hashed[type._id] = true; return hashed; }, {});
                        let id_list = Object.entries(hash).map((pair) => pair[0]);
                        let params = { device_type_id_in: id_list };
                        GetAll("devices", params).then(this.processSuggestions);
                    }).catch((error) => {
                        //todo
                        console.log(error);
                    });
                    break;
                case "child":
                    params.type_ne = "gateway";
                    GetAll("device_types", params).then((types) => {
                        let hash = types.reduce((hashed, type) => { hashed[type._id] = true; return hashed; }, {});
                        let id_list = Object.entries(hash).map((pair) => pair[0]);
                        let params = { device_type_id_in: id_list };
                        if (this.props.filters) {
                            params = Object.assign(params, this.props.filters);
                        }
                        GetAll("devices", params).then(this.processSuggestions);
                    }).catch((error) => {
                        //todo
                        console.log(error);
                    });
                    break;
            }
        }
    };

    processSuggestions = (results) => {
        let value_field = "";
        let label_field = "";
        switch (this.props.model) {
            case "devices":
                value_field = "_id";
                label_field = "unique_id";
                break;
            case "rules":
                value_field = "_id";
                label_field = "description";
                break;
            case "companies":
                value_field = "_id";
                label_field = "name";
                break;
            case "device_types":
                value_field = "_id";
                label_field = "name";
                break;
            case "integrations":
                value_field = "_id";
                label_field = "name";
                break;
            case "software_updates":
                value_field = "_id";
                label_field = "name";
                break;
            case "user_types":
                value_field = "_id";
                label_field = "name";
                break;
            case "device_configs":
                value_field = "_id";
                label_field = "name";
                break;
        };
        this.suggestions = results.map((model) => ({
            value: model[value_field],
            label: model[label_field],
            whole: model
        }));
        if (this.props.model === "user_types") {
            this.suggestions = results.map((model) => ({
                value: model[value_field],
                label: Role.defaultRoleReadable(model[label_field]),
                whole: model
            }));
        }
        if (this.props.priorState && this.props.priorState.values) {
            this.multi = this.props.priorState.values;
        } else {
            this.multi = this.props.single ? null : [];
        }
        this.mapReferences();
    }

    handleChange = (name) => (value) => {
        if (name === 'multi') {
            this.multi = value;
            this.props.emitChange({ label: this.props.label, value: value, suggestions: this.suggestions, field: this.props.field });
        } else {
            this.multi = value[value.length - 1];
            this.props.emitChange({ label: this.props.label, value: this.multi, suggestions: this.suggestions, field: this.props.field });
        }

    };

    mapReferences = () => {
        if (this.multi && Array.isArray(this.multi)) {
            this.multi = this.multi.map((id) => {
                return this.suggestions.find((suggestion) => {
                    return id === suggestion.value;
                });
            })
        } else if (this.multi) {
            this.multi = this.suggestions.find((suggestion) => {
                return this.multi === suggestion.value;
            });
        } else {
            this.multi = null;
        }
        this.props.emitChange({ label: this.props.label, value: this.multi, suggestions: this.suggestions });
    }

    handleTypeChange = (value) => {
        this.setState({ value: value.target.value }, () => this.props.onType ? this.props.onType(this.state.value) : "");
    }


    render() {
        const { priorState, label, noLabel, placeholder, classes, theme, error, error_message, disabled } = this.props;
        if (priorState && priorState !== null) {
            this.multi = priorState.values;
            this.suggestions = priorState.suggestions;
            if (this.multi !== null && this.multi !== undefined && !this.props.single && this.multi[0] && typeof this.multi[0] === "string" && this.suggestions !== null) {
                // this.mapReferences();
            }
        }
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                    zIndex: 10,
                },
            }),
        };
        return (
            <div className={classes.root}>
                {this.suggestions === null ?
                    <span className={classes.loadingText}>Loading</span>
                    :
                    <NoSsr>
                        <Select
                            error={error || false}
                            classes={classes}
                            className={(error === true ? classes.error : "")}
                            styles={selectStyles}
                            textFieldProps={{
                                label: noLabel ? "" : label,
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            handleTypeChange={this.handleTypeChange}
                            onKeyDown={this.handleKeyPress}
                            warningFunction={this.props.warningFunction}
                            options={this.suggestions}
                            components={components}
                            value={this.multi}
                            onChange={this.handleChange(this.props.single || 'multi')}
                            placeholder={placeholder}
                            isMulti
                        />
                        {error === true ? <div className={classes.errorMessage}>{error_message}</div> : ""}
                    </NoSsr>
                }
            </div>
        );
    }
}

LookupInput.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

LookupInput.contextType = SnackbarContext;

export default withStyles(styles)(withTheme()(LookupInput));
